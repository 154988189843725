import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type { RootState } from 'af-reducers';

import type { JobTableViewModel, ProjectVM } from 'ab-viewModels/jobTable.viewModel';
import JobTableCSVViewModel from 'ab-viewModels/jobTableCSV.viewModel';

import * as JobActions from 'af-actions/jobs';

import type { TabProps, Column, ButtonData, RowInfo, TableFinalState } from 'af-components/Table6';
import Table from 'af-components/Table6';
import DateCell from 'af-components/Table6/Cells/DateCell';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import PriorityCell from 'af-components/Table6/Cells/PriorityCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import Label from 'af-components/Label';
import Tooltip from 'af-components/Tooltip';

import CLIENT from 'af-constants/routes/client';

import { stateAbbreviation } from 'ab-enums/states.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { downloadCSV } from 'af-utils/csv.utils';
import { isAllowed } from 'ab-utils/auth.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';
import * as ColorUtils from 'ab-utils/color.util';

import CopyJobModal from '../Shared/CopyModal';
import WorkRequestCopyModal from '../../WorkRequests/Shared/WorkRequestCopyModal';

import styles from './styles.module.scss';

type OwnProps = CustomRouteComponentProps;
type Props = OwnProps & ConnectedProps<typeof connector>;

const BREADCRUMBS = [{ label: 'Jobs' }];

const _generateTooltipMessage = (project: ProjectVM) => {
	return (
		<>
			Project : {project.mainWorkRequest.jobCode} | {project.mainWorkRequest.title}
			<br />
			Subjobs :
			<br />
			{project.subjobs.map((subjob) => (
				<div key={subjob.id}>
					{subjob.jobCode} | {subjob.title}
					<br />
				</div>
			))}
		</>
	);
};

const _finishJobModalBody = () => (
	<>
		You will no longer be able to create Work Orders for this Job.
		<br />
		If you change your mind, you can always unfinish the job afterwards.
	</>
);

const _unfinishJobModalBody = () => (
	<>
		You will be able to create Work Orders for this Job.
	</>
);

const _finishJobModalText = () => ('Finish Job');
const _unfinishJobModalText = () => ('Unfinish Job');

const _unfinishJobModalTitle = (original: JobTableViewModel) => (`Are you sure you want to unfinish this Job (${original.jobCode})?`);
const _finishJobModalTitle = (original: JobTableViewModel) => (`Are you sure you want to finish this Job (${original.jobCode})?`);

const _isJobFinished = (original: JobTableViewModel) => (original.status === WorkRequestStatus.FINISHED);

const JobsTable: React.FC<Props> = (props) => {
	const {
		getJobsTable,
		companyName,
		history,
		isAllowedToCreate,
		findForCSVExport,
		finishJob,
		unfinishJob,
		location: { state: { orgAlias } },
	} = props;

	const [copyJobModalVisible, setCopyJobModalVisible] = React.useState(false);
	const [copyAsWorkRequestModalVisible, setCopyAsWorkRequestModalVisible] = React.useState(false);
	const [selectedJob, setSelectedJob] = React.useState<Nullable<JobTableViewModel>>(null);

	const columns: Column<JobTableViewModel>[] = [
		{
			Header: 'Status',
			accessor: 'jobStatus',
			width: 95,
			Cell: ({ original }) => {
				const jobStatus = original.jobStatus;

				if (!jobStatus) {
					return (
						<EmptyCell message="None" />
					);
				}

				return (
					<div className={ColorUtils.getColorTextClass(jobStatus.color)}>{jobStatus.name}</div>
				);
			},
		},
		{
			Header: 'Priority',
			accessor: 'priority',
			Cell: ({ original }) => <PriorityCell priority={original.priority} />,
		},
		{
			Cell: ({ original }) => (
				<div>
					{
						!!original.projectId &&
						process.env.FTD_PROJECT !== 'true' &&
						<Tooltip
							message={_generateTooltipMessage(original.project!)}
						>
							<Label
								className={original.isProject ? undefined : 'subjob'}
								text={original.isProject ? 'P' : 'S'}
							/>
						</Tooltip>
					}
				</div>
			),
			sortable: false,
			width: 60,
		},
		{
			Header: 'Job ID / Name',
			headerClassName: 'text-left',
			accessor: 'jobCode',
			Cell: ({ original }) => (
				<div>
					<div>{original.jobCode}</div>
					<div className="cell-additional-info">{original.title}</div>
				</div>
			),
		},
		{
			Header: 'Customer Company',
			accessor: 'customerCompanyName',
			Cell: ({ original }) => original.customerCompanyName ?? <EmptyCell />,
		},
		{
			Header: 'City / State',
			headerClassName: 'text-left',
			accessor: 'cityAndState',
			Cell: ({ original }) => (
				<div>
					<div>{original?.travelLocation?.city ?? <EmptyCell />}</div>
					<div className="cell-additional-info">
						{
							(original?.travelLocation?.state && stateAbbreviation[original?.travelLocation?.state])
							?? original?.travelLocation?.state
							?? <EmptyCell />
						}
					</div>
				</div>
			),
		},
		{
			Header: 'Office',
			accessor: 'office',
			Cell: ({ original }) => original.office ?? <EmptyCell />,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager',
			Cell: ({ original }) => original.projectManager ?? <EmptyCell />,
		},
		{
			Header: 'Start Date',
			accessor: 'startDate',
			Cell: ({ original }) => (
				<DateCell
					date={original.startDate}
					dateSourceFormat={TimeFormat.DB_DATE_ONLY}
					format={TimeFormat.DATE_ONLY}
					isLeftAligned={true}
				/>
			),
		},
		{
			Header: 'Target Completion / Guar. Completion',
			headerClassName: 'text-left',
			accessor: 'completionDates',
			Cell: ({ original }) => (
				<div>
					<div>
						<DateCell
							date={original.targetCompletionDate}
							dateSourceFormat={TimeFormat.DB_DATE_ONLY}
							format={TimeFormat.DATE_ONLY}
							isLeftAligned={true}
						/>
					</div>
					<div className="cell-additional-info">
						<DateCell
							date={original.guaranteedCompletionDate}
							dateSourceFormat={TimeFormat.DB_DATE_ONLY}
							format={TimeFormat.DATE_ONLY}
							isLeftAligned={true}
						/>
					</div>
				</div>
			),
		},
		{
			Header: 'Billing Total / Estimate Total',
			headerClassName: 'text-left',
			accessor: 'estimateTotal',
			Cell: ({ original }) => (
				<div>
					<div><EmptyCell /></div>
					<div className="cell-additional-info">
						{original.estimateTotal
							? moneyNormalizer(original.estimateTotal)
							: <EmptyCell />
						}
					</div>
				</div>
			),
		},
		{
			Header: 'Updated',
			width: 200,
			accessor: 'updatedAt',
			headerClassName: 'align-right',
			Cell: ({ original }) => (
				<LastUpdatedByCell
					dateSourceFormat={TimeFormat.ISO_DATETIME}
					updatedAt={original.updatedAt}
					updatedBy={original.updatedBy}
				/>
			),
		},
	];

	const onFinishJob = React.useCallback(async (original: JobTableViewModel) => {
		await finishJob(original.id);
	}, [finishJob]);
	const hideFinishAction = React.useCallback((original: JobTableViewModel) => _isJobFinished(original) || !isAllowedToCreate, [isAllowedToCreate]);
	const hideUnfinishAction = React.useCallback((original: JobTableViewModel) => !_isJobFinished(original) || !isAllowedToCreate, [isAllowedToCreate]);

	const onUnfinishJob = React.useCallback(async (original: JobTableViewModel) => {
		await unfinishJob(original.id);
	}, [unfinishJob]);

	const goToEditJob = React.useCallback(async (original: JobTableViewModel) => {
		history.push(CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, original.id.toString()));
	}, [companyName, history, orgAlias]);

	const goToPreviewJob = React.useCallback(async (original: JobTableViewModel) => {
		if (original.id) {
			history.push(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, original.id.toString()));

		}
	}, [companyName, history, orgAlias]);

	const goToPreviewWorkRequest = React.useCallback(async (workRequestId: number) => {
		history.push(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${workRequestId}`));
	}, [companyName, history, orgAlias]);

	const openCopyJobModal = React.useCallback((original: JobTableViewModel) => {
		setCopyJobModalVisible(true);
		setSelectedJob(original);
	}, []);

	const closeCopyJobModal = React.useCallback(() => {
		setCopyJobModalVisible(false);
		setSelectedJob(null);
	}, []);

	const openCopyAsWorkRequestModal = React.useCallback((original: JobTableViewModel) => {
		setCopyAsWorkRequestModalVisible(true);
		setSelectedJob(original);
	}, []);

	const closeCopyAsWorkRequestModal = React.useCallback(() => {
		setCopyAsWorkRequestModalVisible(false);
		setSelectedJob(null);
	}, []);

	const onRowClick = React.useCallback(({ original }: { original: JobTableViewModel; }) => goToPreviewJob(original), [goToPreviewJob]);

	const getRowClassName = React.useCallback((state: TableFinalState<JobTableViewModel>, rowInfo: RowInfo<JobTableViewModel>) => {
		return rowInfo.original.status === WorkRequestStatus.FINISHED ? styles['job-table__row--finished'] : '';
	}, []);

	const onDownloadCSVClick = React.useCallback(async () => {
		const jobs = await findForCSVExport();

		const csvData = JobTableCSVViewModel.toCSVData(jobs);

		downloadCSV(csvData, `${companyName}_jobs.csv`);
	}, [companyName, findForCSVExport]);

	const tabs = (): TabProps<JobTableViewModel>[] => {
		const buttons: ButtonData[] = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
			},
		];

		if (isAllowedToCreate) {
			buttons.push(
				{
					type: TableButtonType.PRIMARY,
					hasPermission: true,
					label: 'New Job',
					onClick: async () => history.push(CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName)),
				}
			);
		}

		return [
			{
				label: 'Jobs',
				columns: columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Jobs',
				fetch: getJobsTable,
				onRowClick: onRowClick,
				getRowClassName: getRowClassName,
				buttons,
				rowActions: [
					{
						label: 'Preview',
						action: goToPreviewJob,
						shouldRefresh: false,
					},
					{
						label: 'Unfinish',
						action: onUnfinishJob,
						hasModal: true,
						modalTitle: _unfinishJobModalTitle,
						modalBody: _unfinishJobModalBody,
						modalText: _unfinishJobModalText,
						shouldRefresh: true,
						hide: hideUnfinishAction,
					},
					{
						label: 'Edit',
						action: goToEditJob,
						shouldRefresh: false,
						hide: _isJobFinished,
					},
					{
						label: 'Copy',
						action: openCopyJobModal,
						shouldRefresh: false,
					},
					{
						label: 'Copy as Work Request',
						action: openCopyAsWorkRequestModal,
						shouldRefresh: false,
					},
					{
						label: 'Finish',
						action: onFinishJob,
						hasModal: true,
						hide: hideFinishAction,
						modalTitle: _finishJobModalTitle,
						modalBody: _finishJobModalBody,
						modalText: _finishJobModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	return (
		<div className="form-segment form-segment--maxi">
			{selectedJob &&
				<>
					<CopyJobModal
						close={closeCopyJobModal}
						jobToCopyCode={selectedJob.jobCode}
						jobToCopyId={selectedJob.id}
						showModal={copyJobModalVisible}
					/>
					<WorkRequestCopyModal
						closeModal={closeCopyAsWorkRequestModal}
						currentWorkRequestCode={selectedJob.jobCode}
						currentWorkRequestId={selectedJob.id}
						redirectToCopiedWorkRequestPage={goToPreviewWorkRequest}
						showModal={copyAsWorkRequestModalVisible}
					/>
				</>
			}
			<Breadcrumbs items={BREADCRUMBS} />
			<Table
				keepResizing={true}
				tableName={TableNameEnum.JOB}
				tabs={tabs()}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		isAllowedToCreate: isAllowed(PagePermissions.COMPANY.JOBS.CREATE, companyData.permissions, companyData.isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		finishJob: JobActions.finishJob,
		unfinishJob: JobActions.unfinishJob,
		findForCSVExport: JobActions.findForCSVExport,
		getJobsTable: JobActions.getJobsTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(JobsTable);
