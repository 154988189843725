import * as chalk from 'chalk';
import { S3Client } from '@aws-sdk/client-s3';

export let s3Client: Nullable<S3Client> = null;

const _onError = () => {
	console.info('AWS S3 connection error.');
	process.exit(1);
};

export const initBlobStorage = async () => {
	try {
		if (!s3Client) {
			const useAccelerateEndpoint = process.env.AWS_S3_ACCELERATED === 'true';
			const secretAccessKey = process.env.AWS_S3_SECRET;
			const accessKeyId = process.env.AWS_S3_ACCESS_KEY;
			const region = process.env.AWS_REGION;
			if (!secretAccessKey || !accessKeyId || !region) {
				throw new Error('Missing S3 client credentials');
			}
			s3Client = new S3Client({
				credentials: {
					accessKeyId,
					secretAccessKey,
				},
				useAccelerateEndpoint,
				region,
			});
		}
		console.info('Successfully connected to ' + chalk.bold('AWS S3 blob storage'));
	} catch {
		_onError();
	}
};
