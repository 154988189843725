import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';
import DocumentViewer from 'af-components/DocumentViewer';

import styles from './styles.module.scss';

interface Props {
	data: Buffer;
	showModal: boolean;
	setModalVisible: (arg0: boolean) => void;
}

const DocumentPreviewModal = React.memo((props: Props) => {
	const { data, showModal, setModalVisible } = props;

	const baseClassName = 'modal';
	const headerClassName = `${baseClassName}__big-header`;
	const bodyClassName = `${baseClassName}__body`;

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={setModalVisible.bind(this, false)}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<span>PDF PREVIEW</span>
				</CustomModal.Header>
				<CustomModal.Body className={styles[bodyClassName]}>
					<DocumentViewer
						className={styles['document-preview']}
						data={data}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Close"
						onClick={setModalVisible.bind(this, false)}
						style="secondary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
});

export default DocumentPreviewModal;
