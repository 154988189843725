import * as React from 'react';

import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';

import * as TimeSheetUtils from '@acceligentllc/shared/utils/timeSheet';

import type { TimeSheetInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type WorkOrderReviewRecapVM from 'ab-viewModels/workOrder/workOrderReviewRecap.viewModel';

import type { SignatureRequestModel } from 'ab-requestModels/signature.requestModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SignatureModal from '../../../Shared/SignatureModal';

import SuccessModalBody from '../Shared/SuccessModalBody';

import FinalizeModal from './FinalizeModal';

import SignatureForm from '../../../Shared/SignatureModal/FormModel';

interface TimeSheetsByStatus {
	approvedTimeSheets: TimeSheetInfoVM[];
	signedUnapprovedTimeSheets: TimeSheetInfoVM[];
	unsignedTimeSheets: TimeSheetInfoVM[];
}

interface OwnProps {
	closeModal: () => void;
	dueDate: string;
	fieldReports: FieldReportListItemVM[];
	onSubmit: (form?: SignatureRequestModel) => Promise<void>;
	showModal: boolean;
	workOrderId: number;
	workOrderReviewRecap: Nullable<WorkOrderReviewRecapVM>;
	timezone: Nullable<string>;
}

type Props = OwnProps;

interface State extends TimeSheetsByStatus {
	showSignatureModal: boolean;
	showFinalizeModal: boolean;
	showSuccessModal: boolean;
}

class FinalizeModalHandler extends React.PureComponent<Props, State> {

	state: State = {
		showFinalizeModal: this.props.showModal,
		showSignatureModal: false,
		showSuccessModal: false,
		approvedTimeSheets: [],
		signedUnapprovedTimeSheets: [],
		unsignedTimeSheets: [],
	};

	static MODAL_TITLE = 'Finalize Report';
	static SIGNATURE_MODAL_TITLE = 'Finalize Report and Time Sheets';
	static SIGNATURE_MODAL_DESCRIPTION = 'Signing and approving time sheets requires a signature';

	static SUCCESS_MODAL_TITLE = 'Field Reports Finalized';
	static SUCCESS_MODAL_DESCRIPTION = 'Report has been finalized and locked from further changes';

	static readonly SUCCESS_MODAL_BODY = (
		<SuccessModalBody
			description={FinalizeModalHandler.SUCCESS_MODAL_DESCRIPTION}
			isReadOnly={false}
			reviewStatus={WorkOrderReviewStatus.FINAL}
			title={FinalizeModalHandler.SUCCESS_MODAL_TITLE}
		/>
	);

	static timeSheetReducer = (acc: TimeSheetsByStatus, timeSheet: TimeSheetInfoVM) => {
		const { approvalStatus, signatureStatus, isSignedBySI } = timeSheet;
		if (approvalStatus === TimeSheetApprovalStatus.APPROVED) {
			acc.approvedTimeSheets.push(timeSheet);
		} else if (TimeSheetUtils.isTimeSheetSigned(signatureStatus, isSignedBySI)) {
			acc.signedUnapprovedTimeSheets.push(timeSheet);
		} else {
			acc.unsignedTimeSheets.push(timeSheet);
		}
		return acc;
	};

	static timeSheetStateSetter = (timeSheets: TimeSheetInfoVM[]) => {
		return timeSheets?.reduce(
			FinalizeModalHandler.timeSheetReducer,
			{ approvedTimeSheets: [], signedUnapprovedTimeSheets: [], unsignedTimeSheets: [] } as TimeSheetsByStatus
		);
	};

	componentDidMount() {
		const { workOrderReviewRecap } = this.props;
		if (workOrderReviewRecap) {
			this.setState(() => FinalizeModalHandler.timeSheetStateSetter(workOrderReviewRecap.timeSheets));
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { showModal, workOrderReviewRecap } = this.props;
		if ((!prevProps.showModal && showModal) || (prevProps.showModal && !showModal)) {
			this.setState(() => ({ showFinalizeModal: showModal, showSignatureModal: false, showSuccessModal: false }));
		}
		if (workOrderReviewRecap?.timeSheets && prevProps.workOrderReviewRecap?.timeSheets !== workOrderReviewRecap.timeSheets) {
			this.setState(() => FinalizeModalHandler.timeSheetStateSetter(workOrderReviewRecap.timeSheets));
		}
	}

	finalize = async (form?: SignatureForm) => {
		const { onSubmit } = this.props;

		await onSubmit(form ? SignatureForm.toSignatureRM(form) : undefined);
		this.setState(() => ({ showSuccessModal: true, showFinalizeModal: false, showSignatureModal: false }));
	};

	onConfirmFinalization = async () => {
		const { unsignedTimeSheets, signedUnapprovedTimeSheets } = this.state;

		if (!!unsignedTimeSheets.length || !!signedUnapprovedTimeSheets.length) {
			this.setState(() => ({ showSignatureModal: true, showFinalizeModal: false }));
			return;
		} else {
			this.finalize();
		}
	};

	onSuccessModalConfirm = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState(() => ({ showSuccessModal: false }));
	};

	render() {
		const { fieldReports, closeModal, workOrderReviewRecap, timezone } = this.props;
		const {
			approvedTimeSheets,
			showSignatureModal,
			showFinalizeModal,
			showSuccessModal,
			signedUnapprovedTimeSheets,
			unsignedTimeSheets,
		} = this.state;

		return (
			<>
				{showFinalizeModal &&
					<FinalizeModal
						approvedTimeSheets={approvedTimeSheets}
						closeModal={closeModal}
						fieldReports={fieldReports}
						onConfirm={this.onConfirmFinalization}
						showModal={showFinalizeModal}
						signedUnapprovedTimeSheets={signedUnapprovedTimeSheets}
						timeSheetEntriesByAccountIdMap={workOrderReviewRecap?.timeSheetEntriesByAccountId ?? null}
						timezone={timezone}
						unsignedTimeSheets={unsignedTimeSheets}
					/>}
				{showSignatureModal &&
					<SignatureModal
						closeModal={closeModal}
						description={FinalizeModalHandler.SIGNATURE_MODAL_DESCRIPTION}
						onSubmit={this.finalize}
						showModal={showSignatureModal}
						showNameInput={false}
						title={FinalizeModalHandler.SIGNATURE_MODAL_TITLE}
					/>}
				{showSuccessModal &&
					<ConfirmationModal
						body={FinalizeModalHandler.SUCCESS_MODAL_BODY}
						closeModal={this.onSuccessModalConfirm}
						confirmAction={this.onSuccessModalConfirm}
						confirmText="Done"
						modalStyle="info"
						showCancel={false}
						showModal={showSuccessModal}
						size="md"
						title={FinalizeModalHandler.MODAL_TITLE}
					/>}
			</>
		);
	}
}

export default FinalizeModalHandler;
