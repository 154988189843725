import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { SCREEN_BREAKPOINT_M } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

interface Props {
	/** MM-DD-YYYY */
	value?: string;
	onClick?: () => void;
}

interface State {
	isTimeFormatShort: boolean;
}

export default class DailyDatePicker extends React.PureComponent<Props, State> {

	state: State = {
		isTimeFormatShort: false,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState(() => ({ isTimeFormatShort: window.innerWidth < SCREEN_BREAKPOINT_M }));
	};

	render() {
		const { onClick, value } = this.props;
		const { isTimeFormatShort } = this.state;

		let formattedDate: string = TimeUtils.formatDate(value, isTimeFormatShort ? TimeFormat.SB_DATE_SHORT : TimeFormat.SB_DATE_LONG, TimeFormat.DATE_ONLY);
		const commaIndex: number = formattedDate.indexOf(',');
		formattedDate = formattedDate.replace(
			formattedDate.substring(commaIndex, formattedDate.length),
			formattedDate.substring(commaIndex, formattedDate.length).toUpperCase()
		);

		return (
			<Tooltip message="Select Day from Calendar" placement="bottom">
				<span className="schedule-board-daily-date-picker" onClick={onClick} role="button">
					<span className="schedule-board-date-format">{formattedDate}</span>
					<span className="btn btn--rectangle btn--icon" >
						<span className="icon-calendar" />
					</span>
				</span>
			</Tooltip>
		);
	}
}
