import * as React from 'react';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import TimelineBar from './TimelineBar';

import styles from './styles.module.scss';

interface Props {
	entities: TimelineEntitesForAccount[];
	overlaps: Nullable<Record<string, OverlapMeta>>;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
	isSuperIntendent: boolean;
	dueDate: string;
	showTimeline: boolean;
}

const Timeline: React.FC<Props> = (props: Props) => {
	const {
		entities,
		overlaps,
		employeeApprovalStatus,
		isManagerOrAdmin,
		superintendentApprovalStatus,
		dueDate,
		showTimeline,
	} = props;

	const shouldShowTimeline = !!entitiesWithNonZeroDuration().length && showTimeline;

	return (
		shouldShowTimeline ?
			(<div className={styles.timeline}>
				<div className={styles.timeline__title}>
					TIMELINE
				</div>
				<TimelineBar
					dueDate={dueDate}
					employeeApprovalStatus={employeeApprovalStatus}
					entities={entities}
					isManagerOrAdmin={isManagerOrAdmin}
					overlaps={overlaps}
					superintendentApprovalStatus={superintendentApprovalStatus}
				/>
			</div>)
			: <div className={styles['empty-timeline']} />
	);

	function entitiesWithNonZeroDuration() {
		return entities.filter((entity) => {
			const entry = entity.entry;
			return entry.startTime !== entry.endTime;
		});
	}
};

export default Timeline;
