import * as React from 'react';
import type { ConnectedProps, ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { FormErrors, FormErrorsWithArray, InjectedFormProps } from 'redux-form';
import { reduxForm, getFormValues, formValueSelector, getFormSubmitErrors, getFormSyncErrors } from 'redux-form';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

import * as WorkRequestActions from 'af-actions/workRequests';

import Priority from '@acceligentllc/shared/enums/priority';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import { EMAIL_REGEX } from '@acceligentllc/shared/constants/regex';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import type { RootState } from 'af-reducers';

import JobUpsertFM, { SubjobFM } from 'af-components/SharedForms/Job/formModel';
import Breadcrumbs from 'af-components/Breadcrumbs';
import SubmitButton from 'af-components/SubmitButton';
import TabNavigation from 'af-components/TabNavigation';
import ConfirmationModal from 'af-components/ConfirmationModal';

import * as JobActions from 'af-actions/jobs';

import { useToggle } from 'af-utils/react.util';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { TABS } from '../helpers';
import styles from '../styles.module.scss';
import type { FormJobOutletContext } from '../types';
import { JobCreateTabRoutes } from './types';

interface DispatchProps {
	createJob: typeof JobActions.createJob;
	findAllAvailableSubjobs: typeof WorkRequestActions.findAllAvailableSubjobs;
}

type FormOwnProps = ConnectedProps<typeof connector> & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<JobUpsertFM, FormOwnProps>;

const _isInvalidBillingCode = (billingCode) => {
	return Object.keys(billingCode).length > 0;
};

const CreateJob: React.FC<Props> = (props) => {
	const {
		createJob,
		companyName,
		change,
		formJobCode,
		title,
		customerCompanyName,
		priority,
		office,
		handleSubmit,
		jobHazardAssessmentStatus,
		submitting,
		isProject,
		subjobs,
		projectId,
		currentFormValues,
		billToEmail,
		jobCodeSubmitError,
		findAllAvailableSubjobs,
		hasPermissionsToManageBillingCodes,
		initialized,
		syncErrors,
		billingCodesSubmitErrors = [],
	} = props;

	const { state: { orgAlias, originUrl, defaultDueDate, jobCode, previousFormState, subjob, redirectUrl } } = useLocation();
	const { '*': tabId } = useParams();
	const navigate = useNavigate();

	const dueDate = React.useMemo(() =>
		defaultDueDate ?? TimeUtils.formatDate(defaultDueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.JS_TIME),
		[defaultDueDate]);

	const [availableSubjobs, setAvailableSubjobs] = React.useState<SubjobFM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Nullable<SubjobFM>>(null);
	const [subjobSearchText, setSubjobSearchText] = React.useState<string>('');

	const activeTabId = React.useMemo(() => {
		switch (tabId) {
			case JobCreateTabRoutes.DETAILS:
				return TABS[0].id;
			case JobCreateTabRoutes.JOB_HAZARD_ASSESSMENT:
				return TABS[1].id;
			case JobCreateTabRoutes.BILLING_CODES:
				return TABS[2].id;
			case JobCreateTabRoutes.ATTACHMENTS:
				return TABS[3].id;
			default:
				return TABS[0].id;
		}
	}, [tabId]);

	const {
		value: showTurnToProjectConfirmationModal,
		setToTrue: openTurnToProjectConfirmationModal,
		setToFalse: hideTurnToProjectConfirmationModal,
	} = useToggle(false);

	const {
		value: showAddSubjobConfirmationModal,
		setToTrue: openAddSubjobConfirmationModal,
		setToFalse: hideAddSubjobConfirmationModal,
	} = useToggle(false);

	const {
		value: showSubmitWithSubjobsConfirmationModal,
		setToTrue: openSubmitWithSubjobsConfirmationModal,
		setToFalse: hideSubmitWithSubjobsConfirmationModal,
	} = useToggle(false);

	const {
		value: hasAddedSujobs,
		setToTrue: setAddedSujobs,
	} = useToggle(false);

	const {
		value: turnToProjectDisabled,
		setToTrue: disableTurnToProject,
	} = useToggle(isProject);

	React.useEffect(() => {
		if (jobCode) {
			change('jobCode', jobCode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeTurnToProjectConfirmationModal = React.useCallback(async () => {
		change('isProject', false);
		hideTurnToProjectConfirmationModal();
	}, [change, hideTurnToProjectConfirmationModal]);

	const closeAddSubjobConfirmationModal = React.useCallback(async () => {
		hideAddSubjobConfirmationModal();
	}, [hideAddSubjobConfirmationModal]);

	const addSubjob = React.useCallback(() => {
		setAddedSujobs();
		if (selectedSubjob) {
			change('subjobs', [...(subjobs ?? []), selectedSubjob]);
			setAvailableSubjobs([...availableSubjobs.filter((_sj) => _sj.id !== selectedSubjob.id)]);
			setSelectedSubjob(null);
		}
	}, [availableSubjobs, change, selectedSubjob, setAddedSujobs, subjobs]);

	const turnToProject = React.useCallback(() => {
		change('isProject', true);
	}, [change]);

	const goToJobForm = React.useCallback(() => {
		// redirects user to job form. when user fills the form and save it, he/she will be
		// redirected back to original job form with new subjob and all other changes

		navigate(CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName), {
			state: {
				redirectUrl: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
				defaultDueDate: TimeUtils.parseDate(dueDate),
				orgAlias,
				jobCode: subjobSearchText,
				previousFormState: currentFormValues,
			},
		});
	}, [companyName, currentFormValues, dueDate, navigate, orgAlias, subjobSearchText]);

	const handleSubjobSelect = React.useCallback((selectedOption: Nullable<SubjobFM>) => {
		setSelectedSubjob(selectedOption);
	}, []);

	const onTurnToProjectChange = React.useCallback((value: boolean) => {
		if (value) {
			openTurnToProjectConfirmationModal();
		}
	}, [openTurnToProjectConfirmationModal]);

	const onSubmit = React.useCallback(async (form: Partial<JobUpsertFM>) => {
		const job = await createJob(JobUpsertFM.toRM(form));
		if (redirectUrl) {
			if (!job) {
				throw new Error('Job not defined');
			}
			const formJob = new JobUpsertFM(job);
			if (redirectUrl?.includes('jobs/edit') || redirectUrl?.includes('jobs/create')) {
				navigate(redirectUrl, {
					state: { subjob: SubjobFM.fromJobUpsertFM(formJob), defaultDueDate, orgAlias, previousFormState },
				});
			} else {
				navigate(redirectUrl, { state: { job: formJob, defaultDueDate, orgAlias } });
			}

		} else if (originUrl) {
			navigate(originUrl);
		} else {
			navigate(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, `${job.id}`));
		}
	}, [companyName, createJob, defaultDueDate, navigate, orgAlias, originUrl, previousFormState, redirectUrl]);

	const onCreateJob = React.useCallback(async (form: Partial<JobUpsertFM>) => {
		await onSubmit(form);
	}, [onSubmit]);

	const beforeCreateJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!hasAddedSujobs) {
			await onCreateJob(form);
			return;
		} else {
			openSubmitWithSubjobsConfirmationModal();
		}
	}, [hasAddedSujobs, onCreateJob, openSubmitWithSubjobsConfirmationModal]);

	const onTabSelect = React.useCallback((newTabId: number) => {
		let tabName = '';
		switch (newTabId) {
			case TABS[0].id:
				tabName = JobCreateTabRoutes.DETAILS;
				break;
			case TABS[1].id:
				tabName = JobCreateTabRoutes.JOB_HAZARD_ASSESSMENT;
				break;
			case TABS[2].id:
				tabName = JobCreateTabRoutes.BILLING_CODES;
				break;
			case TABS[3].id:
				tabName = JobCreateTabRoutes.ATTACHMENTS;
				break;
			default:
				tabName = JobCreateTabRoutes.DETAILS;
				break;
		}

		navigate(CLIENT.COMPANY.JOBS.CREATE_TAB(orgAlias, companyName, tabName));
	}, [companyName, navigate, orgAlias]);

	const outletContext = React.useMemo<FormJobOutletContext>(() => ({
		availableSubjobs: availableSubjobs,
		change,
		disableTurnToProject,
		formName: FORMS.JOB_CREATE,
		goToJobForm,
		handleSubjobSelect,
		isProject,
		onTurnToProjectChange: onTurnToProjectChange,
		openAddSubjobConfirmationModal,
		orgAlias: orgAlias,
		projectId: projectId,
		redirectOnSubmit: !!redirectUrl,
		selectedSubjob,
		setSubjobSearchText,
		subjobs,
		subjobSearchText,
		turnToProjectDisabled,
		initialized,
		billingCodesSyncErrors: syncErrors.billingCodes ?? [],
		billingCodesSubmitErrors,
	}), [availableSubjobs,
		change,
		disableTurnToProject,
		goToJobForm,
		handleSubjobSelect,
		isProject,
		onTurnToProjectChange,
		openAddSubjobConfirmationModal,
		orgAlias,
		projectId,
		redirectUrl,
		selectedSubjob,
		subjobSearchText,
		subjobs,
		turnToProjectDisabled,
		initialized,
		syncErrors.billingCodes,
		billingCodesSubmitErrors,
	]);

	const isBillToEmailValid = billToEmail ? EMAIL_REGEX.test(billToEmail) : true;
	const isWorkRequestTabValid = React.useMemo(() => {
		return (
			!!formJobCode
			&& !!title
			&& !!customerCompanyName
			&& !!priority
			&& !!office
			&& isBillToEmailValid
			&& !jobCodeSubmitError
		);
	}, [formJobCode, title, customerCompanyName, priority, office, isBillToEmailValid, jobCodeSubmitError]);

	const areBillingCodesValid = React.useMemo(() => !syncErrors.billingCodes?.some(_isInvalidBillingCode), [syncErrors.billingCodes]);

	const renderTabLabel = React.useCallback((id: number) => {
		const areRequiredHazardAssessmentFieldsFilled = !!jobHazardAssessmentStatus;
		switch (id) {
			case TABS[0].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!isWorkRequestTabValid && <span className="icon-warning" />}
						Job
					</div>
				) as JSX.Element;
			}
			case TABS[1].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredHazardAssessmentFieldsFilled && <span className="icon-warning" />}
						Job Hazard Assessment
					</div>
				) as JSX.Element;
			}
			case TABS[2].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areBillingCodesValid && <span className="icon-warning" />}
						Billing Codes
					</div>
				) as JSX.Element;
			}
			case TABS[3].id: {
				return (
					<div className={styles['job-form__tab']}>
						Attachments
					</div>
				) as JSX.Element;
			}

			default: {
				return <></>;
			}
		}
	}, [jobHazardAssessmentStatus, isWorkRequestTabValid, areBillingCodesValid]);

	const isFormValid = (isBillToEmailValid
		&& isValidTextInput(formJobCode)
		&& isValidTextInput(title)
		&& isValidTextInput(customerCompanyName)
		&& !!priority
		&& !!office
		&& !!jobHazardAssessmentStatus
		&& !syncErrors.billingCodes?.some(_isInvalidBillingCode));

	React.useEffect(() => {
		if (previousFormState && !(redirectUrl?.includes('jobs/create') || redirectUrl?.includes('jobs/edit'))) {
			// Directly handle the special case for 'subjobs'
			if (previousFormState.hasOwnProperty('subjobs')) {
				const updatedSubjobs = subjob ? [...(previousFormState.subjobs ?? []), subjob] : previousFormState.subjobs;
				change('subjobs', updatedSubjobs);
			}

			// Iterate over and update the rest of the form state
			Object.entries(previousFormState).forEach(([key, value]) => {
				if (key !== 'subjobs') { // Skip 'subjobs' since it's already handled
					change(key, value);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchAvailableSubjobs = React.useCallback(async () => {
		const _subjobs = await findAllAvailableSubjobs();
		const subjobIds: number[] = subjobs?.map((_s) => _s.id) ?? [];
		setAvailableSubjobs(_subjobs.filter((_sj) => !subjobIds.includes(_sj.id)));
	}, [findAllAvailableSubjobs, setAvailableSubjobs, subjobs]);

	React.useEffect(() => {
		fetchAvailableSubjobs();
	}, [fetchAvailableSubjobs]);

	const tabsAdjustedForPermissions = React.useMemo(() => {
		const resolvedTabs: typeof TABS = [];
		for (const _tab of TABS) {
			if (_tab.id === TABS[2].id && !hasPermissionsToManageBillingCodes) {
				continue;
			}
			resolvedTabs.push(_tab);
		}
		return resolvedTabs;
	}, [hasPermissionsToManageBillingCodes]);

	return (
		<>
			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: 'New Job' },
				]}
			/>
			<div className={styles['job-form__submit-section']}>
				<div className={styles['job-form__submit-section__hint']}>
					Please Fill out both Job and Job Hazard Assessment forms to submit.
				</div>
				<SubmitButton
					disabled={!isFormValid}
					label="Submit"
					onClick={handleSubmit(beforeCreateJob)}
					reduxFormSubmitting={submitting}
					variant="primary"
				/>
			</div>
			<TabNavigation
				active={activeTabId}
				navigationClassName={styles['job-form__tabs-navigation']}
				onClick={onTabSelect}
				renderLabel={renderTabLabel}
				tabs={tabsAdjustedForPermissions}
			/>
			<Outlet context={outletContext} />
			<ConfirmationModal
				body="Convert this Job to Project. This action is irreversible. Continue?"
				closeModal={closeTurnToProjectConfirmationModal}
				confirmAction={turnToProject}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showTurnToProjectConfirmationModal}
				title={'Convert Job to Project?'}
			/>
			<ConfirmationModal
				body={`Associate ${selectedSubjob?.jobCode} with ${formJobCode ?? 'Current Job'}. Continue?`}
				closeModal={closeAddSubjobConfirmationModal}
				confirmAction={addSubjob}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showAddSubjobConfirmationModal}
				title={`Associate ${selectedSubjob?.jobCode} with ${formJobCode ?? 'Current Job'}?`}
			/>
			<ConfirmationModal
				body={'You have associated Sub-jobs with this Project. After submiting the form Sub-jobs cannot be detached from the Project. Submit?'}
				closeModal={hideSubmitWithSubjobsConfirmationModal}
				confirmAction={handleSubmit(onSubmit)}
				confirmText="Submit"
				modalStyle="grey-warning"
				showModal={showSubmitWithSubjobsConfirmationModal}
				title={'Submit project with Sub-jobs?'}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(FORMS.JOB_CREATE);
	const formSelector = formValueSelector(FORMS.JOB_CREATE);
	const currentFormValues = getFormValues(FORMS.JOB_CREATE)(state) as JobUpsertFM;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const hasPermissionsToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		permissions,
		isCompanyAdmin,
		role
	);

	const {
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		id: workRequestId,
		projectId,
		billToEmail,
	} = formState(state) as JobUpsertFM ?? ({} as Partial<JobUpsertFM>);

	/** DB_DATE_ONLY "YYYY-MM-DD" */
	const dueDate: string = formSelector(state, 'startDate');

	const getSubmitErrors = getFormSubmitErrors(FORMS.JOB_CREATE);

	const { jobCode: jobCodeSubmitError, billingCodes: billingCodesSubmitErrors } = getSubmitErrors(state) as FormErrors<JobUpsertFM, string>;
	const syncErrors = getFormSyncErrors(FORMS.JOB_CREATE)(state) as FormErrorsWithArray<JobUpsertFM, string>;

	return {
		companyName: companyData.name,
		formJobCode: jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		workRequestId,
		projectId,
		dueDate,
		currentFormValues,
		billToEmail,
		jobCodeSubmitError,
		hasPermissionsToManageBillingCodes,
		syncErrors,
		billingCodesSubmitErrors: billingCodesSubmitErrors as unknown as FormErrorsWithArray<JobUpsertFM['billingCodes'][0], string>[],
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		createJob: JobActions.createJob,
		findAllAvailableSubjobs: WorkRequestActions.findAllAvailableSubjobs,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	reduxForm<JobUpsertFM, FormOwnProps>({
		form: FORMS.JOB_CREATE,
		validate: JobUpsertFM.validate,
		warn: JobUpsertFM.warn,
		shouldError: () => true, // show errors even when switching tabs
		initialValues: {
			priority: Priority.MEDIUM,
			estimateTotal: 0,
			numberOfEmployees: 0,
		},
	}),
	connector
);
export default enhance(CreateJob);
