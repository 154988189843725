import * as React from 'react';
import type { DroppableProvided } from 'react-beautiful-dnd';

import * as ResourceUtil from '@acceligentllc/shared/utils/resource';

import type { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';

import type { MechanicViewUnavailableEquipmentDataVM } from 'ab-viewModels/mechanicView/mechanicView.viewModel';

import type { MatchedEquipmentIdMap } from 'af-models/scheduleBoard.models';

import EquipmentCard from './EquipmentCard';

type OwnProps = {
	equipment: MechanicViewUnavailableEquipmentDataVM[];
	focusedEquipmentId: Nullable<number>;
	isAutomaticReturnDate: boolean;
	matchedEquipment: MatchedEquipmentIdMap;
	onEquipmentDownEdit: (equipment: EquipmentDownRequestModel) => void;
	provided: DroppableProvided;
};

type Props = OwnProps;

interface State {
	returnDateTitle: string;
}

class EquipmentCards extends React.PureComponent<Props, State> {

	state: State = {
		returnDateTitle: ResourceUtil.getReturnDateTitle(this.props.isAutomaticReturnDate),
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.isAutomaticReturnDate !== this.props.isAutomaticReturnDate) {
			this.setState(() => ({ returnDateTitle: ResourceUtil.getReturnDateTitle(this.props.isAutomaticReturnDate) }));
		}
	}

	_renderEquipmentCard = (_equipment: MechanicViewUnavailableEquipmentDataVM, _index: number) => {
		const { matchedEquipment, onEquipmentDownEdit, focusedEquipmentId } = this.props;
		const { returnDateTitle } = this.state;

		return (
			<EquipmentCard
				equipment={_equipment}
				index={_index}
				isActive={_equipment.id === focusedEquipmentId}
				isMatch={matchedEquipment[_equipment.id]}
				key={_equipment.id}
				onEquipmentDownEdit={onEquipmentDownEdit}
				returnDateTitle={returnDateTitle}
			/>
		);
	};

	render() {
		const { equipment, provided } = this.props;

		return (
			<div
				{...provided?.droppableProps}
				className="mechanic-view-body__unavailable__list"
				ref={provided?.innerRef}
			>
				{equipment.map(this._renderEquipmentCard)}
				{provided?.placeholder}
			</div>
		);
	}
}

export default EquipmentCards;
