import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import { TextColor, ColorPalette } from '@acceligentllc/shared/enums/color';

import type { DeliverableStatusRequestModel } from 'ab-requestModels/deliverableStatus.requestModel';

import DeliverableStatusForm from 'af-components/SharedForms/DeliverableStatus';
import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import { DELIVERABLE_STATUS } from 'af-constants/reduxForms';

import { validateStatus as validate } from './validation';

interface OwnProps {
	isEdit: boolean;
	showModal: boolean;
	isJobStatus: boolean;
	onCancel: () => void;
	onSubmit: (form: DeliverableStatusRequestModel) => void;
	initialValues: Nullable<DeliverableStatusRequestModel>;
}

type Props = OwnProps & InjectedFormProps<DeliverableStatusRequestModel, OwnProps>;

class DeliverableStatusModal extends React.PureComponent<Props> {

	componentDidUpdate(prevProps: Props) {
		const { showModal, destroy, initialValues, initialize } = this.props;
		if (!showModal && prevProps.showModal) {
			destroy();
		}

		if (showModal && !prevProps.showModal) {
			initialize(initialValues);
		}
	}

	render() {
		const {
			showModal,
			onSubmit,
			handleSubmit,
			submitting,
			isEdit,
			onCancel,
			invalid,
			isJobStatus,
		} = this.props;

		return (
			<CustomModal
				closeModal={onCancel}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={onCancel}
					title={`${isEdit ? 'Edit' : 'Create'} Deliverable Status`}
				/>
				<CustomModal.Body padding="none">
					<DeliverableStatusForm isJobStatus={isJobStatus} />
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={onCancel}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={DELIVERABLE_STATUS}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default reduxForm<DeliverableStatusRequestModel, OwnProps>({
	form: DELIVERABLE_STATUS,
	validate,
	initialValues: {
		textColor: TextColor.BLACK,
		statusColor: ColorPalette.BLUE,
	},
})(DeliverableStatusModal);
