import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';
import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import type { TimeSheetEntryFormModel } from './TimeSheets/TimeSheetEditModal/formModel';

/**
 * @returns offset in minutes
 */
function _getTimezoneOffset(timezoneInUse: Nullable<string>): number {
	if (!timezoneInUse) {
		return 0;
	}

	return TimeUtils.getOffset(timezoneInUse) + (new Date()).getTimezoneOffset(); // in minutes
}

interface FindEntriesTimeRange {
	/** ISO_DATETIME */
	minStartTime: Nullable<string>;
	/** ISO_DATETIME */
	maxEndTime: Nullable<string>;
}
export function findEntriesTimeRange(entries: TimeSheetEntryFormModel[], timeZone: Nullable<string>, timeZoneInUse: Nullable<string>): FindEntriesTimeRange {
	const entryWithMaxEndTime = entries.reduce<Nullable<TimeSheetEntryFormModel>>((_maxEntry, _currEntry) => {
		if (_maxEntry === null || !_maxEntry.endTime) {
			return _currEntry;
		}
		if (!_currEntry.endTime) {
			return _maxEntry;
		}

		return new Date(_currEntry.endTime).getTime() > new Date(_maxEntry.endTime).getTime() ? _currEntry : _maxEntry;
	}, null);

	/** Format: ISO_DATETIME */
	const maxEndTime = entryWithMaxEndTime?.endTime ?? null;
	/** Format: ISO_DATETIME */
	const minStartTime = entries[entries.length - 1]?.startTime ?? null;

	const offset = !!timeZone && timeZone === timeZoneInUse
		? -1 * _getTimezoneOffset(timeZone)
		: _getTimezoneOffset(timeZone);

	return {
		minStartTime: minStartTime ? TimeUtils.offsetTime(minStartTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
		maxEndTime: maxEndTime ? TimeUtils.offsetTime(maxEndTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
	};
}

/**
 *
 * @param time ISO_DATETIME
 * @returns datetime in a given timezone, output format
 */
export function getDateTimeWithOffset(time: Nullable<string>, timezone: string, outputFormat: TimeFormat) {
	if (!time || !timezone) {
		return null;
	}

	// Using offset time now to make sure we're in correct date. We want to make sure our display date is correct for the timezone we're showing
	// which means mutating initial start time string so that desired date is provided in its UTC format (where it's converted)
	const timezoneOffsetForDate = TimeUtils.getOffset(timezone) + (new Date()).getTimezoneOffset(); // in minutes

	return TimeUtils.offsetTime(time, timezoneOffsetForDate, 'minutes', outputFormat);
}

interface OffsetTimeSheetEntry {
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	/** DATE_ONLYL MM-DD-YYYY */
	startDate: string;
}
/**
 * Use the function when preparing information to feed into the edit time sheet modal form.
 * Reverse these changes just before sending to the server using the `revertOffsetTimeSheetEntry` function
 * @param startTime ISO_DATETIME
 * @param endTime ISO_DATETIME
 */
export function offsetTimeSheetEntry(startTime: string, endTime: Nullable<string>, timeZone: Nullable<string>): OffsetTimeSheetEntry {
	const browserOffset = (new Date()).getTimezoneOffset();

	if (!timeZone) {
		// We want to extract date at that time zone, and conversion to date takes place in UTC
		const dateOffset = browserOffset;

		const startDate = TimeUtils.offsetTime(startTime, dateOffset, 'minutes', TimeFormat.DATE_ONLY);
		if (!startDate) {
			throw new Error('Failed to offset startDate');
		}

		return {
			startTime,
			endTime,
			startDate,
		};
	}
	const timeZoneOffset = TimeUtils.getOffset(timeZone);

	const timeOffset = timeZoneOffset + browserOffset;
	const dateOffset = timeZoneOffset + browserOffset;

	const startDate = TimeUtils.offsetTime(startTime, dateOffset, 'minutes', TimeFormat.DATE_ONLY);
	if (!startDate) {
		throw new Error('Failed to offset startDate');
	}
	const startTimeAfterOffset = TimeUtils.offsetTime(startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME);
	if (!startTimeAfterOffset) {
		throw new Error('Failed to offset startTime');
	}

	return {
		startDate,
		startTime: startTimeAfterOffset,
		endTime: endTime ? TimeUtils.offsetTime(endTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null,
	};
}

/**
 * Use the function when preparing information to feed into the edit time sheet modal form.
 * Reverse these changes just before sending to the server using the `revertOffsetTimeSheetEntry` function
 *
 * @param startTime ISO_DATETIME in *UTC* - we need this because it's preparation for REST sending
 * @param endTime ISO_DATETIME in *UTC* - we need this because it's preparation for REST sending
 */
export function revertOffsetTimeSheetEntry(startTime: string, endTime: Nullable<string>, timeZone: Nullable<string>): OffsetTimeSheetEntry {
	const browserOffset = (new Date()).getTimezoneOffset();

	if (!timeZone) {
		// We want to extract date at that time zone, and conversion to date takes place in UTC
		const dateOffset = browserOffset;
		const startDate = TimeUtils.offsetTime(startTime, dateOffset, 'minutes', TimeFormat.DATE_ONLY);
		if (!startDate) {
			throw new Error('Failed to calculate startDate');
		}
		return {
			startTime,
			endTime,
			startDate,
		};
	}
	const timeZoneOffset = TimeUtils.getOffset(timeZone);

	const timeOffset = -1 * (timeZoneOffset + browserOffset);

	const startDate = TimeUtils.offsetTime(startTime, timeOffset, 'minutes', TimeFormat.DATE_ONLY);
	if (!startDate) {
		throw new Error('Failed to offset startDate');
	}
	const startTimeAfterOffset = TimeUtils.offsetTime(startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME);
	if (!startTimeAfterOffset) {
		throw new Error('Failed to offset startTime');
	}

	const startTimeAfterConvertingToUTC = TimeUtils.adjustDateToTimezone(startTimeAfterOffset, 'UTC', TimeFormat.ISO_DATETIME);
	if (!startTimeAfterConvertingToUTC) {
		throw new Error('Failed to offset startTime');
	}

	const endTimeAfterOffset = endTime ? TimeUtils.offsetTime(endTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null;
	const endTimeAfterConvertingToUTC = endTimeAfterOffset ? TimeUtils.adjustDateToTimezone(endTimeAfterOffset, 'UTC', TimeFormat.ISO_DATETIME) : null;

	return {
		startDate,
		startTime: startTimeAfterConvertingToUTC,
		endTime: endTimeAfterConvertingToUTC,
	};
}

/**
 * Used when adding offset to time sheets (change time zone function and initial load with custom time zone)
 */
export function offsetAllTimes(timeSheets: TimeSheetVM[], timeZone: Nullable<string>) {
	if (!timeZone) {
		return timeSheets;
	}

	const browserOffset = (new Date()).getTimezoneOffset();
	const timeZoneOffset = TimeUtils.getOffset(timeZone);

	const timeOffset = timeZoneOffset + browserOffset;

	return timeSheets.map((_ts) => ({
		..._ts,
		startDate: _ts.startTime ? TimeUtils.offsetTime(_ts.startTime, timeOffset, 'minutes', TimeFormat.DATE_ONLY) : null,
		startTime: _ts.startTime ? TimeUtils.offsetTime(_ts.startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null,
		endTime: _ts.endTime ? TimeUtils.offsetTime(_ts.endTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null,
	}));
}

/**
 * Used when reverting already offset time sheets (change time zone function)
 * Reverse of `offsetAllTimes`
 */
export function revertOffsetAllTimes(timeSheets: TimeSheetVM[], timeZone: Nullable<string>) {
	if (!timeZone) {
		return timeSheets;
	}

	const browserOffset = (new Date()).getTimezoneOffset();
	const timeZoneOffset = TimeUtils.getOffset(timeZone);

	const timeOffset = (timeZoneOffset + browserOffset) * -1;

	return timeSheets.map((_ts) => ({
		..._ts,
		startDate: _ts.startTime ? TimeUtils.offsetTime(_ts.startTime, timeOffset, 'minutes', TimeFormat.DATE_ONLY) : null,
		startTime: _ts.startTime ? TimeUtils.offsetTime(_ts.startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null,
		endTime: _ts.endTime ? TimeUtils.offsetTime(_ts.endTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME) : null,
	}));
}

/**
 * Used when adding offset to occupied slots to have them match ordering on time sheet edit modal
 * Reverse not needed as it's recalculated on every modal opening
 */
export function offsetOccupiedSlots(occupiedSlots: OccupiedSlotsForWorkOrderVM, timeZone: Nullable<string>) {
	if (!timeZone) {
		return occupiedSlots;
	}

	const browserOffset = (new Date()).getTimezoneOffset();
	const timeZoneOffset = TimeUtils.getOffset(timeZone);

	const timeOffset = (timeZoneOffset + browserOffset);

	return {
		...occupiedSlots,
		slots: occupiedSlots.slots.map((_slot) => ({
			..._slot,
			startTime: TimeUtils.offsetTime(_slot.startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME)!,
			endTime: TimeUtils.offsetTime(_slot.endTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME)!,
		})),
	};
}

/**
 * Used in cases where we need to use time for calculation of ticking timer instead of visually displaying offset time
 * @param startTime ISO_DATETIME
 * @returns start time with original date stamp. Format: ISO_DATETIME
 */
export function calculateRealStartTime(startTime: string, timeZoneInUse: Nullable<string>): string {
	if (!timeZoneInUse) {
		return startTime;
	}

	const browserOffset = (new Date()).getTimezoneOffset();
	const timeZoneOffset = TimeUtils.getOffset(timeZoneInUse);

	const timeOffset = (timeZoneOffset + browserOffset) * -1;

	const realStartTime = TimeUtils.offsetTime(startTime, timeOffset, 'minutes', TimeFormat.ISO_DATETIME);
	if (!realStartTime) {
		throw new Error('Failed to calculate real start time');
	}

	return realStartTime;
}

/**
 * Used when request to the backed should be send one after the other to prevent DB deadlock issues
 * when two or more concurrent requests try to modify the same DB tables.
 */
export class RequestQueue {
	queue: Array<() => Promise<void>> = [];
	executing: boolean = false;

	add = async (promise: (() => Promise<void>)) => {
		this.queue.push(promise);
		this.execute();
	};

	execute = async () => {
		if (this.executing) {
			return;
		}

		const fn = this.queue.shift();

		if (!fn) {
			return;
		}

		this.executing = true;
		await fn();
		this.executing = false;

		await this.execute();
	};
}
