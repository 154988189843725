// User
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const UPDATE_CURRENT_COMPANY = 'UPDATE_CURRENT_COMPANY';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_PENDING_PHONE_NUMBER = 'SET_PENDING_PHONE_NUMBER';
export const SET_FORGOTTEN_EMAIL = 'SET_FORGOTTEN_EMAIL';

// Organizations
export const ORGANIZATION_PUBLIC = 'ORGANIZATION_PUBLIC';
export const MULTIPLE_ORGANIZATION_PUBLIC = 'MULTIPLE_ORGANIZATION_PUBLIC';

// Company
export const GET_COMPANY = 'GET_COMPANY';
export const SET_ACCEPTED_MEMBERS = 'SET_MEMBERS_EMAILS';
export const SET_REJECTED_MEMBERS = 'SET_REJECTED_MEMBERS';
export const GET_COMPANY_NOTIFICATION_SETTINGS = 'GET_COMPANY_NOTIFICATION_SETTINGS';

// Contacts
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const LOAD_CONTACT_ROLES = 'LOAD_CONTACT_ROLES';
export const LOAD_CONTACTS = 'LOAD_CONTACTS';
export const LOAD_EMBEDDED_CONTACTS = 'LOAD_EMBEDDED_CONTACTS';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';

// Http Utils
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_PROGRESS_SIZE = 'UPLOAD_PROGRESS_SIZE';
export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS';
export const DOWNLOAD_PROGRESS_SIZE = 'DOWNLOAD_PROGRESS_SIZE';
export const SUBMIT_START = 'SUBMIT_START';
export const SUBMIT_END = 'SUBMIT_END';

// Table

// Table: Resources

// Work Requests
export const CLEAR_APPROVAL_LINK = 'CLEAR_APPROVAL_LINK';
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';
export const CLEAR_REQUEST = 'CLEAR_REQUEST';
export const CLEAR_ESTIMATE_TASKS = 'CLEAR_ESTIMATE_TASKS';
export const CLEAR_OTHER_CONTACT = 'CLEAR_OTHER_CONTACT';
export const CLEAR_REVIEW_TASKS = 'CLEAR_REVIEW_TASKS';
export const CLEAR_REQUEST_CONTACT = 'CLEAR_REQUEST_CONTACT';
export const CLEAR_REQUEST_INFO = 'CLEAR_REQUEST_INFO';
export const CLEAR_REQUEST_VALIDATION = 'CLEAR_REQUEST_VALIDATION';
export const DELETE_OTHER_CONTACT = 'DELETE_OTHER_CONTACT';
export const ESTIMATE_VALIDATION_ERRORS = 'ESTIMATE_VALIDATION_ERRORS';
export const GET_APPROVAL_LINK = 'GET_APPROVAL_LINK';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_ESTIMATE_TASKS = 'GET_ESTIMATE_TASKS';
export const GET_ESTIMATE_SCROLL_LABEL = 'GET_ESTIMATE_SCROLL_LABEL';
export const GET_REVIEW_SCROLL_LABEL = 'GET_REVIEW_SCROLL_LABEL';
export const GET_REVIEW_TASKS = 'GET_REVIEW_TASKS';
export const GET_WORK_REQUEST = 'GET_WORK_REQUEST';
export const GET_REQUEST_INFO = 'GET_REQUEST_INFO';
export const GET_REQUEST_SCROLL_LABEL = 'GET_REQUEST_SCROLL_LABEL';
export const REFRESH_ESTIMATE_TASKS = 'REFRESH_ESTIMATE_TASKS';
export const REFRESH_REVIEW_TASKS = 'REFRESH_REVIEW_TASKS';
export const REQUEST_VALIDATION_ERRORS = 'REQUEST_VALIDATION_ERRORS';
export const REVIEW_VALIDATION_ERRORS = 'REVIEW_VALIDATION_ERRORS';

// General
export const APP_READY = 'APP_READY';

// Resources

// Document
export const ADD_APPROVAL_DOCUMENT = 'ADD_APPROVAL_DOCUMENT';
export const DELETE_APPROVAL_DOCUMENT = 'DELETE_APPROVAL_DOCUMENT';
export const REORDER_APPROVAL_DOCUMENTS = 'REORDER_APPROVAL_DOCUMENTS';

// Employee Status
export const FIND_ALL_EMPLOYEE_STATUSES_FOR_COMPANY = 'FIND_ALL_EMPLOYEE_STATUSES_FOR_COMPANY';
export const CREATE_EMPLOYEE_STATUS = 'CREATE_EMPLOYEE_STATUS';
export const DELETE_EMPLOYEE_STATUS = 'DELETE_EMPLOYEE_STATUS';
export const UPDATE_EMPLOYEE_STATUS_INDICES = 'UPDATE_EMPLOYEE_STATUS_INDICES';

// Equipment Status
export const FIND_ALL_EQUIPMENT_STATUSES_FOR_COMPANY = 'FIND_ALL_EQUIPMENT_STATUSES_FOR_COMPANY';
export const CREATE_EQUIPMENT_STATUS = 'CREATE_EQUIPMENT_STATUS';
export const DELETE_EQUIPMENT_STATUS = 'DELETE_EQUIPMENT_STATUS';
export const UPDATE_EQUIPMENT_STATUS_INDICES = 'UPDATE_EQUIPMENT_STATUS_INDICES';

// Work Order
export const FIND_ALL_WORK_ORDERS = 'FIND_ALL_WORK_ORDERS';
export const FIND_WORK_ORDER = 'FIND_WORK_ORDER';
export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER';
export const GET_WORK_ORDER_CONFIRMATION_DATA = 'GET_WORK_ORDER_CONFIRMATION_DATA';
export const CLEAR_WORK_ORDER_CONFIRMATION_DATA = 'CLEAR_WORK_ORDER_CONFIRMATION_DATA';
export const CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS = 'CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS';
export const CLEAR_WORK_ORDER_LIST = 'CLEAR_WORK_ORDER_LIST';
export const RESET_WORK_ORDER = 'RESET_WORK_ORDER';
export const REVERT_WORK_ORDER = 'REVERT_WORK_ORDER';
export const SAVE_WORK_ORDER = 'SAVE_WORK_ORDER';
export const LOCK_WORK_ORDER = 'LOCK_WORK_ORDER';
export const UNLOCK_WORK_ORDER = 'UNLOCK_WORK_ORDER';
export const UPDATE_WORK_ORDER_HAS_ATTACHMENTS = 'UPDATE_WORK_ORDER_HAS_ATTACHMENTS';
export const UPDATE_WORK_ORDER_NOTE = 'UPDATE_WORK_ORDER_NOTE';
export const SET_REFRESH_MODAL_VISIBILITY = 'SET_REFRESH_MODAL_VISIBILITY';
export const OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL = 'OPEN_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL';
export const REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL = 'REFRESH_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL';
export const CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL = 'CLOSE_AVAILABLE_EMPLOYEES_NOTIFICATION_MODAL';
export const CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL = 'CHECK_EMPLOYEE_IN_AVAILABLE_EMPLOYEE_MODAL';
export const CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL = 'CHECK_ALL_IN_AVAILABLE_EMPLOYEE_MODAL';
export const RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL = 'RESET_ALL_IN_AVAILABLE_EMPLOYEE_MODAL';
export const CLEAR_WORK_ORDERS_BY_DATE = 'CLEAR_WORK_ORDERS_BY_DATE';
export const SET_PER_DIEM_FOR_WORK_ORDERS = 'SET_PER_DIEM_FOR_WORK_ORDERS';
export const SET_COPY_WORK_ORDER_MODAL_DATA = 'SET_COPY_WORK_ORDER_MODAL_DATA';
export const UPDATE_INTERNAL_REVIEW_STATUS = 'UPDATE_INTERNAL_REVIEW_STATUS';

// Logs

// Schedule board
export const GET_SCHEDULE_BOARD = 'GET_SCHEDULE_BOARD';
export const GET_SCHEDULE_BOARD_RESOURCES = 'GET_SCHEDULE_BOARD_RESOURCES';
export const GET_SCHEDULE_BOARD_REJOIN = 'GET_SCHEDULE_BOARD_REJOIN';
export const RELOAD_SCHEDULE_BOARD = 'RELOAD_SCHEDULE_BOARD';
export const RELOAD_SCHEDULE_BOARD_RESOURCES = 'RELOAD_SCHEDULE_BOARD_RESOURCES';
export const GET_WEEKLY_VIEW_SCHEDULE_BOARD = 'GET_WEEKLY_VIEW_SCHEDULE_BOARD';
export const ADD_BLANK_WORK_ORDER = 'ADD_BLANK_WORK_ORDER';
export const REMOVE_BLANK_WORK_ORDER = 'REMOVE_BLANK_WORK_ORDER';
export const SET_SCHEDULE_BOARD_VIEW = 'SET_SCHEDULE_BOARD_VIEW';
export const GET_SOCKET_CONNECTION_COUNT = 'GET_SOCKET_CONNECTION_COUNT';
export const UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST = 'UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST';
export const ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST = 'ADD_TO_SCHEDULE_BOARD_DROPPABLE_LIST';
export const UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES = 'UPDATE_SCHEDULE_BOARD_WORK_ORDER_INDICES';
export const UPDATE_SCHEDULE_BOARD_WORK_ORDER = 'UPDATE_SCHEDULE_BOARD_WORK_ORDER';
export const UPDATE_SCHEDULE_BOARD_WORK_ORDER_MODAL = 'UPDATE_SCHEDULE_BOARD_WORK_ORDER_MODAL';
export const REMOVE_SCHEDULE_BOARD_WORK_ORDER = 'REMOVE_SCHEDULE_BOARD_WORK_ORDER';
export const CANCEL_SCHEDULE_BOARD_WORK_ORDER = 'CANCEL_SCHEDULE_BOARD_WORK_ORDER';
export const PAUSE_SCHEDULE_BOARD_WORK_ORDER = 'PAUSE_SCHEDULE_BOARD_WORK_ORDER';
export const RESUME_SCHEDULE_BOARD_WORK_ORDER = 'RESUME_SCHEDULE_BOARD_WORK_ORDER';
export const REVERT_SCHEDULE_BOARD_WORK_ORDER = 'REVERT_SCHEDULE_BOARD_WORK_ORDER';
export const LOCK_SCHEDULE_BOARD_WORK_ORDER = 'LOCK_SCHEDULE_BOARD_WORK_ORDER';
export const UNLOCK_SCHEDULE_BOARD_WORK_ORDER = 'UNLOCK_SCHEDULE_BOARD_WORK_ORDER';
export const COPY_MULTIPLE_WORK_ORDERS = 'COPY_MULTIPLE_WORK_ORDERS';
export const UPDATE_SCHEDULE_BOARD_DATE = 'UPDATE_SCHEDULE_BOARD_DATE';
export const UPDATE_DISPLAY_VIEW_DATE = 'UPDATE_DISPLAY_VIEW_DATE';
export const UPDATE_SCHEDULE_BOARD_WEEK = 'UPDATE_SCHEDULE_BOARD_WEEK';
export const UPDATE_SCHEDULE_BOARD_QUERY = 'UPDATE_SCHEDULE_BOARD_QUERY';
export const SET_SB_ACTIVE_SEARCH_ITEM_INDEX = 'SET_SB_ACTIVE_SEARCH_ITEM_INDEX';
export const CLEAR_UNAVAILABILITY_REASON = 'CLEAR_UNAVAILABILITY_REASON';
export const ASSIGN_UNAVAILABILITY_REASON = 'ASSIGN_UNAVAILABILITY_REASON';
export const CHANGE_RETURN_DATE = 'CHANGE_RETURN_DATE';
export const UPDATE_EMPLOYEE_PER_DIEM = 'UPDATE_EMPLOYEE_PER_DIEM';
export const SET_SCHEDULE_BOARD_LABOR_STATISTICS = 'SET_SCHEDULE_BOARD_LABOR_STATISTICS';
export const CLEAR_SCHEDULE_BOARD_DAILY_VIEW = 'CLEAR_SCHEDULE_BOARD_DAILY_VIEW';
export const CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW = 'CLEAR_SCHEDULE_BOARD_WEEKLY_VIEW';
export const DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM = 'DISABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM';
export const ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM = 'ENABLE_SCHEDULE_BOARD_EQUIPMENT_ITEM';
export const DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM = 'DISABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM';
export const ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM = 'ENABLE_SCHEDULE_BOARD_EMPLOYEE_ITEM';
export const DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM = 'DISABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM';
export const ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM = 'ENABLE_SCHEDULE_BOARD_TEMPORARY_EMPLOYEE_ITEM';
export const DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM = 'DISABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM';
export const ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM = 'ENABLE_SCHEDULE_BOARD_WORK_ORDER_ITEM';
export const REMOVE_EMPLOYEE_ASSIGNMENT = 'REMOVE_EMPLOYEE_ASSIGNMENT';
export const ADD_EMPLOYEE_ASSIGNMENT = 'ADD_EMPLOYEE_ASSIGNMENT';
export const REMOVE_EQUIPMENT_ASSIGNMENT = 'REMOVE_EQUIPMENT_ASSIGNMENT';
export const REMOVE_PLACEHOLDER_ASSIGNMENT = 'REMOVE_PLACEHOLDER_ASSIGNMENT';
export const ADD_EQUIPMENT_ASSIGNMENT = 'ADD_EQUIPMENT_ASSIGNMENT';
export const ADD_PLACEHOLDER_ASSIGNMENT = 'ADD_PLACEHOLDER_ASSIGNMENT';
export const ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT = 'ADD_TEMPORARY_EMPLOYEE_ASSIGNMENT';
export const REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT = 'REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT';
export const ADD_TOOLBAR_EMPLOYEE = 'ADD_TOOLBAR_EMPLOYEE';
export const ADD_TOOLBAR_EMPLOYEE_FROM_DATE = 'ADD_TOOLBAR_EMPLOYEE_FROM_DATE';
export const REMOVE_TOOLBAR_EMPLOYEE = 'REMOVE_TOOLBAR_EMPLOYEE';
export const REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE = 'REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE';
export const REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE = 'REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE';
export const ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS = 'ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS';
export const REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS = 'REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_TEMPORARY_EMPLOYEE = 'UPDATE_TEMPORARY_EMPLOYEE';
export const ADD_TOOLBAR_EQUIPMENT = 'ADD_TOOLBAR_EQUIPMENT';
export const ADD_EQUIPMENT_DOWN_DETAILS = 'ADD_EQUIPMENT_DOWN_DETAILS';
export const ADD_EMPLOYEE_DOWN_DETAILS = 'ADD_EMPLOYEE_DOWN_DETAILS';
export const SET_MECHANIC_VIEW_SORT_BY = 'SET_MECHANIC_VIEW_SORT_BY';
export const REMOVE_TOOLBAR_EQUIPMENT = 'REMOVE_TOOLBAR_EQUIPMENT';
export const REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT = 'REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT';
export const ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS = 'ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS';
export const REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS = 'REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY = 'ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICTIONARY';
export const ADD_TEMPORARY_EMPLOYEE = 'ADD_TEMPORARY_EMPLOYEE';
export const REMOVE_ALL_WORK_ORDER_EMPLOYEES = 'REMOVE_ALL_WORK_ORDER_EMPLOYEES';
export const REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS = 'REMOVE_ALL_WORK_ORDER_EMPLOYEE_ON_DRAFTS';
export const REMOVE_ALL_WORK_ORDER_EQUIPMENT = 'REMOVE_ALL_WORK_ORDER_EQUIPMENT';
export const REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS = 'REMOVE_ALL_WORK_ORDER_EQUIPMENT_ON_DRAFTS';
export const SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_CODE = 'SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_ID';
export const SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID = 'SET_SCHEDULE_BOARD_DRAGGED_RESOURCE_ID';
export const SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE = 'SET_SCHEDULE_BOARD_DRAGGED_EMPLOYEE';
export const SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT = 'SET_SCHEDULE_BOARD_DRAGGED_EQUIPMENT';
export const SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE = 'SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EMPLOYEE';
export const SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT = 'SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_EQUIPMENT';
export const SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER = 'SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_PLACEHOLDER';
export const SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE = 'SET_SCHEDULE_BOARD_DRAGGED_WORK_ORDER_TEMPORARY_EMPLOYEE';
export const CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER = 'CLEAR_SCHEDULE_BOARD_DRAGGED_WORK_ORDER';
export const CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE = 'CLEAR_SCHEDULE_BOARD_DRAGGED_RESOURCE';
export const SET_DRAGGING_LABOR_PLACEHOLDER = 'SET_DRAGGING_LABOR_PLACEHOLDER';
export const SET_DRAGGING_EQUIPMENT_PLACEHOLDER = 'SET_DRAGGING_EQUIPMENT_PLACEHOLDER';
export const SET_SCHEDULE_BOARD_ZOOM_LEVEL = 'SET_SCHEDULE_BOARD_ZOOM_LEVEL';
export const LOCK_WORK_ORDER_ON_SCHEDULE_BOARD = 'LOCK_WORK_ORDER_ON_SCHEDULE_BOARD';
export const UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD = 'UNLOCK_WORK_ORDER_ON_SCHEDULE_BOARD';
export const CHANGE_WORK_ORDERS_SORT = 'CHANGE_WORK_ORDERS_SORT';
export const SET_LABOR_MODAL_VISIBILITY = 'SET_LABOR_MODAL_VISIBILITY';
export const SET_LABOR_MODAL_DATA = 'SET_LABOR_MODAL_DATA';
export const SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY = 'SET_EQUIPMENT_PLACEHOLDER_MODAL_VISIBILITY';
export const SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA = 'SET_EQUIPMENT_PLACEHOLDER_MODAL_DATA';
export const SHOW_REFRESH_MODAL = 'SHOW_REFRESH_MODAL';
export const HIDE_REFRESH_MODAL = 'HIDE_REFRESH_MODAL';
export const CLEAR_ALL_SCHEDULE_BOARD_FILTERS = 'CLEAR_ALL_SCHEDULE_BOARD_FILTERS';
export const APPLY_SCHEDULE_BOARD_FILTERS = 'APPLY_SCHEDULE_BOARD_FILTERS';
export const ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE = 'ACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE';
export const DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE = 'DEACTIVATE_SCHEDULE_BOARD_MULTI_SELECT_MODE';
export const ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT = 'ADD_ORDER_TO_SCHEDULE_BOARD_MULTI_SELECT';
export const REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT = 'REMOVE_ORDER_FROM_SCHEDULE_BOARD_MULTI_SELECT';
export const SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS = 'SET_SCHEDULE_BOARD_MULTI_SELECT_ORDERS';
export const SET_SHOW_NOTES_MODE = 'SET_SHOW_NOTES_MODE';
export const SET_COPIED_EMPLOYEE = 'SET_COPIED_EMPLOYEE';
export const SET_COPIED_EQUIPMENT = 'SET_COPIED_EQUIPMENT';
export const SET_COPIED_PLACEHOLDER = 'SET_COPIED_PLACEHOLDER';
export const SET_COPIED_TEMPORARY_EMPLOYEE = 'SET_COPIED_TEMPORARY_EMPLOYEE';
export const SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING_PERCENTAGE = 'SET_WEEKLY_VIEW_HORIZONTAL_SCROLLING';
export const SET_METRICS_HORIZONTAL_SCROLLING_PERCENTAGE = 'SET_METRICS_HORIZONTAL_SCROLLING';
export const SET_COPIED_WORK_ORDER = 'SET_COPIED_WORK_ORDER';
export const SET_COPIED_RESOURCE_PLACEHOLDER = 'SET_COPIED_RESOURCE_PLACEHOLDER';
export const SET_COPIED_WORK_ORDER_PLACEHOLDER = 'SET_COPIED_WORK_ORDER_PLACEHOLDER';
export const SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR = 'SET_WEEKLY_VIEW_DATE_WITH_TOOLBAR';
export const TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE = 'TOGGLE_WEEKLY_VIEW_SELECT_MULTIPLE';
export const TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW = 'TOGGLE_WORK_ORDER_ON_WEEKLY_VIEW';
export const TOGGLE_DAY_ON_WEEKLY_VIEW = 'TOGGLE_DAY_ON_WEEKLY_VIEW';
export const SYNC_NOTIFICATION_STATUS = 'SYNC_NOTIFICATION_STATUS';
export const UPDATE_DAILY_TIP = 'UPDATE_DAILY_TIP';
export const UPDATE_DAILY_PER_DIEM_TIP = 'UPDATE_DAILY_PER_DIEM_TIP';
export const SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT = 'SET_MECHANIC_VIEW_AVAILABLE_EQUIPMENT';
export const SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT = 'SET_MECHANIC_VIEW_UNAVAILABLE_EQUIPMENT';
export const SET_MECHANIC_VIEW_SEARCH_QUERY = 'SET_MECHANIC_VIEW_SEARCH_QUERY';
export const SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX = 'SET_MECHANIC_VIEW_FOCUSED_SEARCH_INDEX';
export const ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT = 'ADD_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT';
export const REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT = 'REMOVE_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT';
export const ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT = 'ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT';
export const REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT = 'REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT';
export const UPDATE_CREW_TYPE = 'UPDATE_CREW_TYPE';
export const UPDATE_WORK_ORDERS_AND_STATISTICS_ON_JOB_WORK_SUMMARY_UPDATE = 'UPDATE_WORK_ORDERS_AND_STATISTICS_ON_JOB_WORK_SUMMARY_UPDATE';
export const UPDATE_TIME_DATA_ON_PAYROLL_UPDATE = 'UPDATE_TIME_DATA_ON_PAYROLL_UPDATE';
export const ADD_LATEST_PUBLISHED_REVISIONS = 'ADD_LATEST_PUBLISHED_REVISIONS';
export const CLEAR_PUBLISHED_REVISIONS = 'CLEAR_PUBLISHED_REVISIONS';

// Report block
export const SET_NEW_REPORT_BLOCK = 'SET_NEW_REPORT_BLOCK';

// Field Reports
export const SET_FIELD_REPORT_NEW = 'SET_FIELD_REPORT_NEW';
export const SET_FIELD_REPORT_TYPES = 'SET_FIELD_REPORT_TYPES';
export const SET_FIELD_REPORT_BLOCK_LOCKS = 'SET_FIELD_REPORT_BLOCK_LOCKS';
export const REMOVE_FIELD_REPORT_BLOCK_LOCK = 'REMOVE_FIELD_REPORT_BLOCK_LOCK';
export const SET_SHARED_FIELD_REPORT_BLOCK_LOCKS = 'SET_SHARED_FIELD_REPORT_BLOCK_LOCKS';
export const REMOVE_SHARED_FIELD_REPORT_BLOCK_LOCK = 'REMOVE_SHARED_FIELD_REPORT_BLOCK_LOCK';
export const SET_FIELD_REPORT_BLOCK_VALUE_NEW = 'SET_FIELD_REPORT_BLOCK_VALUE_NEW';
export const ADD_INSTANCE = 'ADD_INSTANCE';
export const ADD_SEGMENT = 'ADD_SEGMENT';
export const REMOVE_INSTANCE = 'REMOVE_INSTANCE';
export const REMOVE_SEGMENT = 'REMOVE_SEGMENT';
export const SET_CONNECTION_COUNT = 'SET_CONNECTION_COUNT';
export const SET_FR_ACTIVE_SEARCH_ITEM_INDEX = 'SET_FR_ACTIVE_SEARCH_ITEM_INDEX';
export const SET_FR_BLOCK_COMPLETED = 'SET_FR_BLOCK_COMPLETED';
export const SET_SEGMENT_DELETING = 'SET_SEGMENT_DELETING';
