import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext, Row } from '@tanstack/react-table';
import { useLocation, useHistory } from 'react-router';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import * as EmployeeActions from 'af-actions/employee';

import type { RootState } from 'af-reducers';

import type { EmployeeTableVM } from 'ab-viewModels/employee/table.viewModel';
import { EmployeeListViewModel } from 'ab-viewModels/employee/list.viewModel';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';
import { TableQuery } from 'ab-common/dataStructures/tableQuery';
import type { TableContent } from 'ab-common/dataStructures/tableContent';

import ImageTag from 'af-components/Image';
import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import TabNavigation from 'af-components/TabNavigation';
import type { TableProps } from 'af-components/Table/types';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import { AccountPermissionTemplateLabel } from 'ab-enums/accountPermissionTemplates.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { downloadCSV } from 'af-utils/csv.utils';

import { isAllowed } from 'ab-utils/auth.util';
import { W_Employee_FindDeactivatedList_VM } from 'ab-api/web/employee/findDeactivatedList';

type Props = ConnectedProps<typeof connector>;

const ACTIVE_LABOR_VIEW_TAB = { ID: 1, LABEL: 'LABOR' };
const DEACTIVATED_LABOR_VIEW_TAB = { ID: 2, LABEL: 'RECENTLY DEACTIVATED' };

const TABS = [
	{ id: ACTIVE_LABOR_VIEW_TAB.ID, label: ACTIVE_LABOR_VIEW_TAB.LABEL },
	{ id: DEACTIVATED_LABOR_VIEW_TAB.ID, label: DEACTIVATED_LABOR_VIEW_TAB.LABEL },
];

const breadcrumbs = [{ label: 'Labor' }];

const Employees: React.FC<Props> = (props) => {
	const {
		companyName,
		findAllForCompanyList,
		hasManagePermission,
		findAllDeactivatedForCompanyTable,
		findAllForCompanyTable,
		findAllDeactivatedForCompanyList,
	} = props;

	const tableRef = React.useRef<TableRef<EmployeeTableVM>>(null);
	const [activeTab, setActiveTab] = React.useState<number>(1);

	const location = useLocation<{ orgAlias: string; }>();
	const history = useHistory();

	const editEmployee = React.useCallback(async (original: EmployeeTableVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.EDIT(original.id.toString(), location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias]);

	const previewEmployee = React.useCallback(async (original: EmployeeTableVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(original.id.toString(), location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias]);

	const statusHistoryEmployee = React.useCallback(async (original: EmployeeTableVM) => {
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.STATUS_HISTORY(original.id.toString(), location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias]);

	const onDownloadCSVClick = React.useCallback(async () => {
		if (activeTab === ACTIVE_LABOR_VIEW_TAB.ID) {
			const listViewModel = await findAllForCompanyList();
			downloadCSV(EmployeeListViewModel.toCSVData(listViewModel), `${companyName}_employees.csv`);
		} else {
			const listViewModel = await findAllDeactivatedForCompanyList(tableRef!.current!.getTableQuery());
			downloadCSV(W_Employee_FindDeactivatedList_VM.toCSVData(listViewModel), `${companyName}_deactivated_employees.csv`);
		}
	}, [activeTab, companyName, findAllDeactivatedForCompanyList, findAllForCompanyList]);

	const onRegisterUserClick = React.useCallback(async () => {
		history.push(
			CLIENT.COMPANY.SETTINGS.MEMBERS.INVITE(location.state.orgAlias, companyName),
			{ from: CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST(), orgAlias: location.state.orgAlias }
		);
	}, [companyName, history, location.state.orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<EmployeeTableVM, unknown>) => {
		const options = [
			{ onClick: () => previewEmployee(_cell.row.original), label: 'Preview' },
		];

		if (activeTab === ACTIVE_LABOR_VIEW_TAB.ID) {
			options.push({ onClick: () => editEmployee(_cell.row.original), label: 'Edit' });
			options.push({ onClick: () => statusHistoryEmployee(_cell.row.original), label: 'Status History' });
		}

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [activeTab, editEmployee, previewEmployee, statusHistoryEmployee]);

	const columns: TableProps<EmployeeTableVM>['columns'] = React.useMemo(() => [
		{
			id: 'imageUrl',
			accessor: 'imageUrl',
			enableSorting: false,
			size: 100,
			header: '',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => (
				<div className="rt-thumbnail">
					<ImageTag
						fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
						minSize={BlobStorageImageSizeContainer.SIZE_50X50}
						src={_cell.getValue() ?? null}
						tryOriginal={true}
						tryRoot={true}
					/>
				</div>
			),
		},
		{
			id: 'id',
			header: 'ID',
			accessor: 'account.user.uniqueId',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => _cell.row.original.code,
		},
		{
			id: 'fullName',
			header: 'Full Name',
			accessor: 'fullName',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => <strong>{_cell.row.original.fullName}</strong>,
		},
		{
			id: 'userRole',
			header: 'User Role',
			accessor: 'account.accountTemplate',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => <strong>{AccountPermissionTemplateLabel[_cell.row.original.accountTemplate]}</strong>,
		},
		{
			id: 'laborType',
			header: 'Labor Type',
			accessor: 'wageRate.type',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => _cell.row.original.wageRate.type,
		},
		{
			id: 'wageClassification',
			header: 'Wage Classification',
			accessor: 'wageRate.wageClassification',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => _cell.row.original.wageRate.wageClassification,
		},
		{
			id: 'homeOffice',
			header: 'Home Office',
			accessor: 'account.location.nickname',
			cell: (_cell: CellContext<EmployeeTableVM, string>) => _cell.row.original?.location?.nickname ?? <EmptyCell />,
		},
		{
			id: 'skills',
			header: 'Skills',
			accessor: 'skills',
			sortable: false,
			cell: (_cell: CellContext<EmployeeTableVM, string>) => <SkillsCell skills={_cell.row.original.skills} />,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [resolveActionsButton]);

	const onRowClick = React.useCallback((row: Row<EmployeeTableVM>) => {
		if (row.original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(row.original.id.toString(), location.state.orgAlias, companyName));
		}
	}, [companyName, history, location.state.orgAlias]);

	const buttons = React.useMemo(() => {

		const _buttons = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				label: 'CSV Export',
				onClick: onDownloadCSVClick,
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasManagePermission,
				label: 'Register User',
				onClick: onRegisterUserClick,
			},
		];

		if (activeTab === DEACTIVATED_LABOR_VIEW_TAB.ID) {
			_buttons.pop();
		}

		return _buttons;
	}, [activeTab, hasManagePermission, onDownloadCSVClick, onRegisterUserClick]);

	const tableName = React.useMemo(() => activeTab === ACTIVE_LABOR_VIEW_TAB.ID ? TableNameEnum.LABOR : TableNameEnum.DEACTIVATED_LABOR, [activeTab]);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<EmployeeTableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		const result = activeTab === ACTIVE_LABOR_VIEW_TAB.ID
			? await findAllForCompanyTable(
				new TableQuery({ page, pageSize, sortBy, filterByText })
			)
			: await findAllDeactivatedForCompanyTable(
				new TableQuery({ page, pageSize, sortBy, filterByText })
			);
		return result;
	}, [activeTab, findAllDeactivatedForCompanyTable, findAllForCompanyTable]);

	const onTabChange = React.useCallback((id: number) => setActiveTab(id), []);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={breadcrumbs} />
			<TabNavigation
				active={activeTab}
				onClick={onTabChange}
				tabs={TABS}
			/>
			<TableNew
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				hasSearchInput={true}
				hasSubRows={false}
				offsetHeight={60}
				onRowClick={onRowClick}
				ref={tableRef}
				selectable={false}
				tableName={tableName}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { userData, companyData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasManagePermission,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findAllForCompanyTable: EmployeeActions.findAllForCompanyTable,
		findAllForCompanyList: EmployeeActions.findAllForCompanyList,
		findAllDeactivatedForCompanyList: EmployeeActions.findAllDeactivatedForCompanyList,
		findAllDeactivatedForCompanyTable: EmployeeActions.findAllDeactivatedForCompanyTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Employees);
