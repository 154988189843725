import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';

export const resolveFieldValue = (value: Nullable<string | { street: string; }>, type: Nullable<ReportBlockFieldEnum.Type>) => {
	if (!value) {
		return 'N/A';
	}

	let resolvedValue = value;

	if (type === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = value === 'true' ? 'Yes' : 'No';
	}

	if (type === ReportBlockFieldEnum.Type.ADDRESS) {
		// since non repeatable address fields are saved as stringified objects
		const isNonRepeatingField = (typeof value === 'string');

		return isNonRepeatingField
			? JSON.parse(value).street
			: (value as unknown as { street: string; })?.street;
	}

	return resolvedValue;
};
