import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';

import * as TimeSheetActions from 'af-actions/timeSheet';

import CustomModal from 'af-components/CustomModal';

import UnassignedEntry from './UnassignedEntry';
import styles from './styles.module.scss';

interface OwnProps {
	accountId: number;
	closeModal: () => void;
	timeSheetId: number;
	userFullName: string;
	unassignedEntries: TimeCardEntryVM[];
	workOrderId: number;
	hasEntryChanges: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const AssignUnassignedEntriesModal: React.FC<Props> = (props: Props) => {
	const {
		accountId,
		assignEntriesToWo,
		closeModal,
		userFullName,
		unassignedEntries = [],
		workOrderId,
	} = props;
	const [selectedEntriesIds, setSelectedEntriesIds] = React.useState<Record<string, true>>({});

	const onEntrySelect = React.useCallback((entryId: number) => {
		setSelectedEntriesIds((prev) => {
			const updatedEntries = { ...prev };

			if (updatedEntries[entryId]) {
				delete updatedEntries[entryId]; // Remove entryId if it exists
			} else {
				updatedEntries[entryId] = true; // Add entryId if it doesn't exist
			}

			return updatedEntries;
		});
	}, []);

	const assignToWo = React.useCallback(async () => {
		if (Object.keys(selectedEntriesIds).length) {
			const entryIds = Object.keys(selectedEntriesIds).map(Number);
			await assignEntriesToWo(workOrderId, accountId, { entryIds });
			closeModal();
		}
	}, [accountId, assignEntriesToWo, closeModal, selectedEntriesIds, workOrderId]);

	return (
		<CustomModal
			modalStyle="info"
			showModal={true}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
			>
				<div className={styles.modalHeader}>
					<div> UNASSIGNED TIME ENTRIES | </div>
					<div className={styles.userName}>{`${userFullName}`}</div>
				</div>
			</CustomModal.Header>
			<CustomModal.Body
				className={styles.inheritedBodyProps}
			>
				<div className={styles.modalBody}>
					<div className={styles.modalMessage}>
						Select time entries you want to assign to this Field Report
						<div className={styles.numberOfSelected}>
							{Object.keys(selectedEntriesIds).length} item(s) selected
						</div>
					</div>
					{unassignedEntries.map((_entry: TimeCardEntryVM) => {
						return (
							<React.Fragment key={_entry.id}>
								<UnassignedEntry
									entry={_entry}
									entrySelected={!!selectedEntriesIds[_entry.id]}
									onEntrySelect={onEntrySelect}
								/>
							</React.Fragment>
						);
					})}
				</div>
			</CustomModal.Body>
			<CustomModal.Footer className={styles.footerButtons}>
				<Button
					label="Close"
					onClick={closeModal}
					style="secondary"
				/>
				<Button
					disabled={!Object.entries(selectedEntriesIds).length}
					label="Assign to this Field Report"
					onClick={assignToWo}
					style="primary"
				/>
			</CustomModal.Footer>
		</CustomModal >
	);
};

function mapDispatchToProps() {
	return {
		assignEntriesToWo: TimeSheetActions.assignEntriesToWorkOrder,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);
export default enhance(AssignUnassignedEntriesModal);
