import * as React from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

import { isNullOrUndefined } from '@acceligentllc/shared/utils/extensions';

import LoadingIndicator from 'af-components/LoadingIndicator';
import Label from 'af-components/LockedValue/Label';
import type { OwnProps as TooltipProps } from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

import type { FlatListProps } from './DropdownMenu/FlatListMenu';
import FlatListMenu from './DropdownMenu/FlatListMenu';
import type { SectionListProps, SectionType } from './DropdownMenu/SectionListMenu';
import SectionListMenu from './DropdownMenu/SectionListMenu';

export type DropdownOptionType = {
	[key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	disabled?: boolean;
	onClick?: () => void;
};

interface DropdownStyle {
	top: number | 'auto';
	bottom: number | 'auto';
	left: number;
	width: number;
	a?: number & string;
}

export interface PropsWithLabel<T extends DropdownOptionType> extends SharedOwnProps<T> {
	labelKey: KeyOfType<T, string>;
}

export type Section<T extends DropdownOptionType> = SectionType<T>;

export interface PropsWithCustomRender<T extends DropdownOptionType> extends SharedOwnProps<T> {
	renderMenuItem?: (option: Nullable<T>, searchText?: string, index?: number) => JSX.Element;
	renderSelected?: (option: Nullable<T>) => JSX.Element;
}

interface SharedOwnProps<T extends DropdownOptionType> {
	id: string;
	valueKey: keyof T;
	className?: string;
	inputClassName?: string;
	containerClassName?: string;
	defaultValue?: Nullable<T>;
	disabled?: boolean;
	dropdownClassName?: string;
	dropdownId?: string;
	/** Enables search */
	filterable?: boolean;
	/** Function that filters options according to user's input text in the search box */
	filterBy?: (keyof T)[] | readonly (keyof T)[] | ((option: Nullable<T>, searchText: string) => boolean);
	/** Function that filters options at the mounting of Dropdown component, **not used if section list** */
	filterOptions?: (option: T, index: number) => boolean;
	fixed?: boolean;
	fullWidth?: boolean;
	hasBlankOption?: boolean;
	isButtonFullWidth?: boolean;
	isMenuItemUnlimited?: boolean;
	isWhite?: boolean;
	label?: string;
	menuItemClassName?: string;
	onClear?: () => void;
	onFocus?: () => void;
	onLazyLoad?: (isLazyLoaded: boolean) => Promise<void>;
	onMenuOpen?: () => void;
	onValueChange?: (selectedOption: Nullable<T>, selectedValue: string) => Promise<void> | void;
	placeholder?: string;
	tooltipMessage?: TooltipProps['message'];
	withCaret?: boolean;
	withBorder?: boolean;
	forcePlaceholder?: boolean;
	isActionDropdown?: boolean;
	isControlled?: boolean;
	/** handle opening of dropdown from outside of component */
	open?: boolean;
}

export type OwnProps<T extends DropdownOptionType> = (PropsWithLabel<T> | PropsWithCustomRender<T>) & (SectionListProps<T> | FlatListProps<T>);

type Props<T extends DropdownOptionType> = OwnProps<T>;

interface DropdownStyle {
	top: number | 'auto';
	bottom: number | 'auto';
	left: number;
	width: number;
}

interface State<T extends DropdownOptionType> {
	changed: boolean;
	dropdownStyle: DropdownStyle | undefined;
	dropup: boolean;
	isLazyLoading: boolean;
	isOpen: boolean;
	lazyLoaded: boolean;
	searchText: string;
	selected: Nullable<T>;
}

class Dropdown<T extends DropdownOptionType> extends React.PureComponent<Props<T>, State<T>> {

	static MAX_DROPDOWN_MENU_HEIGHT = 300 * 1.1; // 10% more

	_dropdownToggle = React.createRef<HTMLDivElement>();
	_dropdownInput = React.createRef<HTMLDivElement>();
	_searchInput = React.createRef<HTMLInputElement>();

	static defaultProps: Partial<Props<DropdownOptionType>> = {
		options: [],
		dropdownClassName: '',
		hasBlankOption: false,
		placeholder: '',
		filterable: false,
		defaultValue: null,
		fullWidth: true,
		withCaret: false,
		isMenuItemUnlimited: false,
		isButtonFullWidth: false,
		isWhite: false,
		fixed: false,
		withBorder: true,
		forcePlaceholder: false,
	};

	state: State<T> = {
		isLazyLoading: false,
		lazyLoaded: false,
		changed: false,
		dropup: false,
		isOpen: this.props.open ?? false,
		searchText: '',
		dropdownStyle: undefined,
		selected: this.props.defaultValue ?? null,
	};

	calculatedPropName: keyof T;

	constructor(props: Props<T>) {
		super(props);
		this.calculatedPropName = props.valueKey ?? (props as PropsWithLabel<T>).labelKey;
	}

	componentDidUpdate(prevProps: Props<T>) {
		const { defaultValue, open } = this.props;

		// Necessary for when field sends a value down the line
		if (
			(!prevProps.defaultValue && !!defaultValue)
			|| prevProps.defaultValue !== defaultValue
		) {
			this.setState(() => ({ selected: defaultValue ?? null }));
		}

		if (open !== undefined && prevProps.open !== open) {
			this.setState({ isOpen: open });
		}

		this.calculatedPropName = this.props.valueKey ?? (this.props as PropsWithLabel<T>).labelKey;
	}

	getSectionsMapper = (key: string) => (section: SectionType<T>) => section[key];

	handleChange = (item: string, options: T[]) => {
		const { onValueChange, defaultValue, isControlled } = this.props;

		const originalItem = options.find((_option) => {
			if (_option[this.calculatedPropName] === null && item === null) {
				// Special case where we're using an item with value of null
				// Usually happens when adding an "other" option on top of existing ones
				return true;
			}

			return _option[this.calculatedPropName]?.toString() === item;
		});

		const selectedValue = isControlled ? defaultValue : originalItem;
		this.setState(() => ({ selected: selectedValue ?? null }), async () => {
			// on change callback, returns entire option that got selected
			if (onValueChange) {
				await onValueChange(originalItem ?? null, item);
			}
		});
	};

	handleFocus = () => {
		const { onLazyLoad, onFocus } = this.props;

		// Called before anything else because we want to place focus first in most cases (field)
		if (onFocus) {
			onFocus();
		}

		let options: T[] | null = null;
		if ('useSectionList' in this.props) {
			const { sections, sectionOptionsKey } = this.props;
			const sectionMapper = this.getSectionsMapper(sectionOptionsKey);
			options = sections.flatMap(sectionMapper);
		} else {
			options = this.props.options;
		}

		if (onLazyLoad) {
			const lazyLoaded = options && options.length > 0;
			this.setState(() => ({ isLazyLoading: true, lazyLoaded }), async () => {
				await onLazyLoad(lazyLoaded);
				this.setState(() => ({ isLazyLoading: false }));
			});
		}
	};

	handleToggle = (isOpen: boolean) => {
		const { onMenuOpen, fixed, open } = this.props;

		if (isOpen) {
			this.handleFocus();
		}

		if (isOpen && this._dropdownToggle?.current) {
			const { height: bodyHeight } = document.body.getClientRects()[0];
			const { y: dropdownTogglePositionY = 0 } = this._dropdownToggle.current.getClientRects()[0] ?? {};

			const isDropup = dropdownTogglePositionY > (bodyHeight - Dropdown.MAX_DROPDOWN_MENU_HEIGHT);

			if (fixed) {
				const {
					x: inputPositionX = 0,
					y: inputPositionY = 0,
					bottom: inputBottom = 0,
					width: inputWidth = 0,
					height: inputHeight = 0,
				} = this._dropdownInput.current?.getClientRects?.()?.[0] ?? {};

				this.setState(() => ({
					dropdownStyle: {
						top: isDropup ? 'auto' : inputBottom,
						bottom: isDropup ? bodyHeight - inputPositionY - inputHeight : 'auto',
						left: inputPositionX,
						width: inputWidth,
					},
				}));
			} else {
				this.setState(() => ({ dropdownStyle: undefined }));
			}
			if (this.state.dropup !== isDropup) {
				this.setState(() => ({ dropup: isDropup }));
			}
		}

		if (open !== undefined) {
			if (open) {
				onMenuOpen?.();
				this._searchInput?.current?.focus();
			}
		} else {
			this.setState(() => ({ isOpen }), () => {
				if (isOpen) {
					onMenuOpen?.();
					this._searchInput?.current?.focus();
				}
			});
		}
	};

	onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchText = event.target.value;
		this.setState(() => ({ searchText }));
	};

	clearSearchInput = () => this.setState(() => ({ searchText: '' }));

	defaultRenderOption = (option: T | null): JSX.Element => {
		const { labelKey } = this.props as PropsWithLabel<T>;

		return (option === null)
			? <span className="dropdown-menu__blank-item__label">None</span>
			: <>{option[labelKey]}</>;
	};

	filter = (option: Nullable<T>) => {
		const { filterBy, hasBlankOption } = this.props;
		const { searchText: _searchText } = this.state;

		if (!_searchText || (hasBlankOption && !option)) {
			return true;
		}
		const searchText = _searchText.toLowerCase();

		if (Array.isArray(filterBy)) {
			const searchWords = searchText.replace(/\s\s+/g, ' ').split(' ');
			const searchableValues: string[] = [];

			for (const _filterField of filterBy) {
				const _value = option?.[_filterField];
				if (_value && typeof _value === 'string') {
					searchableValues.push(_value.toLowerCase());
				}
			}

			for (const _searchWord of searchWords) {
				let _matched = false;
				for (const _value of searchableValues) {
					if (_value.includes(_searchWord)) {
						_matched = true;
						break;
					}
				}
				if (!_matched) {
					// All search words must match at least one filter by prop
					return false;
				}
			}
			return true;
		} else if (typeof filterBy === 'function') {
			return filterBy(option, searchText);
		}

		return true;
	};

	filterEnum = (option: string) => {
		const { filterBy, hasBlankOption } = this.props;
		const { searchText } = this.state;

		if (!searchText || (hasBlankOption && !option)) {
			return true;
		}

		if (typeof filterBy === 'function') {
			// cast to T simply because we're searching enums and T === string
			return filterBy(option as unknown as T, searchText.toLowerCase());
		}
		return true;
	};

	renderMenuItem = (item: Nullable<T>, index: number, searchText: string, onChangeHandler: (item: string) => void) => {
		const {
			filterable,
			renderMenuItem = this.defaultRenderOption,
			isMenuItemUnlimited,
		} = this.props as PropsWithCustomRender<T>;

		const isFiltered = !filterable || this.filter(item);
		let className = isMenuItemUnlimited ? 'dropdown-menu__unlimited-item' : '';
		className = item === null ? `${className} dropdown-menu__blank-item` : className;

		return isFiltered
			? (
				<BootstrapDropdown.Item
					className={className}
					disabled={!!item?.disabled}
					eventKey={item?.[this.calculatedPropName] as string}
					key={`menuItem#${index + 1}`}
					onClick={item?.onClick ?? undefined}
					onSelect={onChangeHandler}
				>
					{item === null
						? this.defaultRenderOption(null)
						: renderMenuItem(item, searchText, index)
					}
				</BootstrapDropdown.Item>
			)
			: <React.Fragment key={`menuItem#${index + 1}`} />;
	};

	renderSelected = () => {
		const {
			placeholder,
			forcePlaceholder,
		} = this.props;
		const { selected } = this.state;

		if (forcePlaceholder || isNullOrUndefined(selected)) {
			return <span className="dropdown-toggle__placeholder">{placeholder}</span>;
		}

		if ('renderMenuItem' in this.props) { // TS infer props type
			const { renderSelected, renderMenuItem } = this.props;

			const renderItem = renderSelected ?? renderMenuItem;
			if (renderItem) {
				return renderItem(selected);
			} else {
				// eslint-disable-next-line no-console
				console.warn('[Dropdown]: `renderMenuItem` is used as a prop but value is undefined, falling back to `labelKey`');
			}
		}
		if ('labelKey' in this.props && !!this.props.labelKey) {
			const { labelKey } = this.props;
			return selected[labelKey];
		}
		console.error('[Dropdown]: Cannot render selected without `renderMenuItem` or `labelKey` defined');
		return null;
	};

	renderClearButton = () => {
		const { onClear, disabled } = this.props;
		const { selected } = this.state;

		if (!selected || !onClear) {
			return null;
		}

		return (
			<span className={`icon-close dropdown-toggle__clear ${disabled ? 'disabled' : ''}`} onClick={onClear} />
		);
	};

	renderToggle = () => {
		const { isActionDropdown } = this.props;
		if (isActionDropdown) {
			return <span className="icon-actions" ref={this._dropdownToggle} />;
		}
		return (
			<>
				<div className="dropdown-menu__selected" ref={this._dropdownToggle}>
					{this.renderSelected()}
				</div>
				{this.renderClearButton()}
			</>
		);
	};

	renderMenuList = () => {
		const { defaultValue, hasBlankOption, filterable } = this.props;
		const { selected, searchText, isLazyLoading } = this.state;

		if ('useSectionList' in this.props) {
			const { useSectionList, sections, sectionOptionsKey, sectionTitleKey, renderSectionHeader } = this.props;
			return (
				<SectionListMenu
					calculatedPropName={this.calculatedPropName}
					defaultValue={defaultValue}
					filterable={!!filterable}
					hasBlankOption={!!hasBlankOption}
					isLazyLoading={isLazyLoading}
					onChange={this.handleChange}
					renderMenuItem={this.renderMenuItem}
					renderSectionHeader={renderSectionHeader}
					searchText={searchText}
					sectionOptionsKey={sectionOptionsKey}
					sections={sections}
					sectionTitleKey={sectionTitleKey}
					selected={selected}
					useSectionList={useSectionList}
				/>
			);
		} else {
			const { filterOptions, options } = this.props;
			return (
				<FlatListMenu
					calculatedPropName={this.calculatedPropName}
					defaultValue={defaultValue}
					filterOptions={filterOptions}
					hasBlankOption={!!hasBlankOption}
					isLazyLoading={isLazyLoading}
					onChange={this.handleChange}
					options={options}
					renderMenuItem={this.renderMenuItem}
					searchText={searchText}
					selected={selected}
				/>
			);
		}
	};

	render() {
		const {
			className,
			containerClassName,
			disabled,
			dropdownClassName,
			dropdownId,
			filterable,
			fixed,
			fullWidth,
			id,
			isButtonFullWidth,
			isWhite,
			label,
			tooltipMessage,
			withCaret,
			withBorder,
			isActionDropdown,
			inputClassName,
		} = this.props;
		const { isLazyLoading, dropup, searchText, isOpen, dropdownStyle } = this.state;

		const dropdownMenuClassName = bemBlock('dropdown-menu', {
			'scroll-container': true,
			'full-width': !!fullWidth,
			fixed: !!fixed,
			...(!!dropdownClassName ? { [dropdownClassName]: !!dropdownClassName } : {}),
		});

		const dropdownToggleClassName = bemBlock('dropdown-toggle', {
			'with-caret': !!withCaret,
			'fullwidth-button': !!isButtonFullWidth,
			'lazy-loading': !!isLazyLoading,
			white: !!isWhite,
			loading: !!isLazyLoading,
			bordered: !!withBorder && !isActionDropdown,
			action: !!isActionDropdown,
		});

		return (
			<div className={containerClassName} ref={this._dropdownInput}>
				{label &&
					<div className="input-header">
						<Label
							label={label}
							tooltipMessage={tooltipMessage}
							tooltipPlacement="top"
							withMargin={true}
						/>
					</div>
				}
				<BootstrapDropdown
					className={className}
					drop={dropup ? 'up' : undefined}
					id={id}
					onToggle={this.handleToggle}
					show={isOpen}
				>
					<BootstrapDropdown.Toggle
						className={`${inputClassName ?? ''} ${dropdownToggleClassName}`}
						disabled={disabled}
					>
						{this.renderToggle()}
					</BootstrapDropdown.Toggle>
					<BootstrapDropdown.Menu
						className={dropdownMenuClassName}
						id={dropdownId}
						style={dropdownStyle}
					>
						{filterable &&
							<a className="dropdown-item dropdown-menu__search-input-container">
								<input
									className="dropdown-menu__search-input"
									onChange={this.onSearchInputChange}
									placeholder="Search"
									ref={this._searchInput}
									type="text"
									value={searchText}
								/>
								{searchText
									? (
										<span
											className="dropdown-menu__icon icon-close"
											onClick={this.clearSearchInput}
											role="button"
										/>
									)
									: <span className="dropdown-menu__icon icon-search" />
								}
							</a>
						}
						{isLazyLoading &&
							<BootstrapDropdown.Item
								className="dropdown-menu__loading-item"
								disabled={true}
								key="menuItem#-2"
							>
								<LoadingIndicator color="black" size="small" />
							</BootstrapDropdown.Item>
						}
						{this.renderMenuList()}
					</BootstrapDropdown.Menu>
				</BootstrapDropdown>
			</div>
		);
	}
}

export default Dropdown;
