import * as React from 'react';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';
import { useModalNavigate, withModalNavigation } from 'af-components/ModalNavigation/ModalRouter';

import { flattenTree, mergeLinesForSearch, searchFlatTreeForText } from 'af-utils/userGuideModal.util';

import UserGuideModalBody from './UserGuideModalBody';
import type { TreeElement, SearchablePageText, RawPages, SearchPageResults } from './types';
import { CACHE_BUST, mdFiles, routes } from './routes';

interface Props {
	isModalOpen: boolean;
	closeModal: () => void;
	startGuideUri: string;
}

const MINIMUMSEARCH = 3;
const SHOWHEADINGS = 5;
const UserGuideModal: React.FC<Props> = (props) => {

	const { isModalOpen, closeModal, startGuideUri } = props;

	const modalNavigate = useModalNavigate();

	const modalBodyRef = React.useRef<HTMLDivElement>(null);

	const [isInitialLoadCompleted, setIsInitialLoadCompleted] = React.useState(false);
	const [isSearchTreeBuilt, setIsSearchTreeBuilt] = React.useState(false);
	const [searchablePages, setSearchablePages] = React.useState<SearchablePageText>({});
	const [rawPages, setRawPages] = React.useState<RawPages>({});

	React.useEffect(() => {
		// We want to have this check so when a user closes and then reopens the guide, the correct start page is shown again
		if (isModalOpen) {
			modalNavigate(startGuideUri);
		}
	}, [isModalOpen, modalNavigate, startGuideUri]);

	React.useEffect(() => {
		if (!isModalOpen || isInitialLoadCompleted) {
			return;
		}

		// First get the initial file requested so it is shown
		const loadInitialPage = async () => {
			const _rawPages: RawPages = {};

			const file = mdFiles.find((f) => f.toLowerCase() === startGuideUri.toLowerCase().split('/')[1]);
			if (!file) {
				setIsInitialLoadCompleted(true);
				return;
			}

			await fetch(`/documents/${file}.md${CACHE_BUST}`)
				.then((response) => {
					return response.text();
				})
				.then(async (text) => {
					_rawPages[file] = text;
				})
				.catch((error) => {
					console.error('Error fetching the Markdown file:', error);
				});
			setRawPages(_rawPages);
			setIsInitialLoadCompleted(true);
		};
		loadInitialPage();
	}, [isInitialLoadCompleted, isModalOpen, searchablePages, startGuideUri]);

	// Build search tree after loading the initial file
	React.useEffect(() => {
		if (!isModalOpen || isSearchTreeBuilt || !isInitialLoadCompleted) {
			return;
		}
		const loadPages = async () => {
			const _rawPages: RawPages = {};
			const _searchablePages: SearchablePageText = {};
			for (const file of mdFiles) {
				await fetch(`/documents/${file}.md${CACHE_BUST}`)
					.then((response) => response.text())
					.then(async (text) => {
						const result = unified().use(remarkParse).parse(text);
						const flatenedTree = flattenTree(result as TreeElement);
						const mergedTree = mergeLinesForSearch(flatenedTree);
						_searchablePages[file] = mergedTree;
						if (file !== startGuideUri.split('/')[1]) {
							_rawPages[file] = text;
						}
					})
					.catch((error) => {
						console.error('Error fetching the Markdown file:', error);
					});
			}
			setIsSearchTreeBuilt(true);
			setRawPages({ ...rawPages, ..._rawPages });
			setSearchablePages(_searchablePages);
		};
		loadPages();
	}, [isInitialLoadCompleted, isModalOpen, isSearchTreeBuilt, rawPages, searchablePages, startGuideUri]);

	const searchResults = React.useCallback((text: string): SearchPageResults | undefined => {

		if (text.length < MINIMUMSEARCH) {
			return undefined;
		}

		const result: SearchPageResults = {};
		for (const page of Object.keys(searchablePages)) {
			result[page] = searchFlatTreeForText(searchablePages[page], text, page, SHOWHEADINGS);
		}
		return result;
	}, [searchablePages]);

	const scrollToTop = React.useCallback(() => {
		modalBodyRef.current?.scrollTo({
			top: 0,
			behavior: 'auto',
		});
	}, []);

	const getModalBodyBottomPositionForRecalculation = React.useCallback((): number | undefined => {
		if (!modalBodyRef.current) {
			return undefined;
		}

		const paddingsAndBordersOffset = 65 + 16;
		const boundingBox = modalBodyRef.current.getBoundingClientRect();
		return boundingBox.bottom + paddingsAndBordersOffset;
	}, []);

	return <>
		<CustomModal
			className="user-guide-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={isModalOpen}
			size="lg"
		>
			<CustomModal.Header closeModal={closeModal}>
				<div className="user-guide-header-title">
					<span className="icon-help" />
					<span className="header-text">User Guide</span>
				</div>
			</CustomModal.Header>
			<CustomModal.RefBody className="user-guide-modal-body" padding="none" ref={modalBodyRef}>
				<UserGuideModalBody
					getModalBodyBottomPositionForRecalculation={getModalBodyBottomPositionForRecalculation}
					rawPages={rawPages}
					scrollToTop={scrollToTop}
					search={searchResults}
					searchablePages={searchablePages}
				></UserGuideModalBody>
			</CustomModal.RefBody>
			<CustomModal.Footer>
				<Button
					label="Close"
					onClick={closeModal}
					style="secondary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	</>;
};

export default withModalNavigation(UserGuideModal, routes);
