import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

interface Props {
	disabled: boolean;
	label: string;
	propName: string;
	addWorkOrderResourceLookup: (propName: string) => void;
}

export default class AddResourceLookupButton extends React.PureComponent<Props> {

	addResource = () => {
		const { addWorkOrderResourceLookup, propName } = this.props;
		addWorkOrderResourceLookup(propName);
	};

	render() {
		const { label, disabled } = this.props;

		return !disabled && (
			<Button
				icon="plus"
				label={label}
				onClick={this.addResource}
				style="link"
			/>
		);
	}

}
