import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as ArrayUtils from '@acceligentllc/shared/utils/array';

import Dropdown from 'af-components/Controls/Dropdown';

import UI_LABELS from 'af-constants/uiLabels';

type MomentType = ReturnType<typeof TimeUtils.parseMoment>;

const MONTH_OPTIONS: DropdownItem[] = Object.keys(UI_LABELS.MONTH)
	.map((_month, _index) => ({
		id: _index,
		label: UI_LABELS.MONTH[_month] as string,
	}));

const YEAR_OPTION_START = 2017;
const NUMBER_OF_FUTURE_YEAR_OPTIONS = 4;
const YEAR_OPTIONS = ArrayUtils
	.range(YEAR_OPTION_START, (new Date()).getFullYear() + 1 + NUMBER_OF_FUTURE_YEAR_OPTIONS)
	.map((_year: number, _index: number) => ({ id: _index, label: _year.toString() }));

interface DropdownItem {
	id: number;
	label: string;
}

interface OwnProps {
	selected: Date;
	onChange: (selected: Date, startDate: Date, endDate: Date) => void;
}

type Props = OwnProps;

interface State {
	selected: MomentType;
}

class MonthlyPicker extends React.PureComponent<Props, State> {
	state: State = {
		selected: TimeUtils.parseMoment(this.props.selected),
	};

	onMonthChange = async (item: DropdownItem) => {
		const { onChange } = this.props;
		const { selected } = this.state;

		if (!selected) {
			throw new Error('No date used as reference point');
		}

		const newSelected = selected.clone().month(item.id);
		onChange(newSelected.toDate(), newSelected.clone().startOf('month').toDate(), newSelected.clone().endOf('month').toDate());
		this.setState(() => ({ selected: newSelected }));
	};

	onYearChange = async (item: DropdownItem) => {
		const { onChange } = this.props;
		const { selected } = this.state;

		if (!selected) {
			throw new Error('No date used as reference point');
		}

		const newSelected = selected.clone().year(+item.label);
		onChange(newSelected.toDate(), newSelected.clone().startOf('month').toDate(), newSelected.clone().endOf('month').toDate());
		this.setState(() => ({ selected: newSelected }));
	};

	renderDropdownElement = (item: DropdownItem) => {
		return item ? <div>{item.label}</div> : <div />;
	};

	render() {
		const { selected } = this.state;

		if (!selected) {
			throw new Error('No date used as reference point');
		}

		return (
			<div className="table-filter__date">
				<Dropdown<DropdownItem>
					className="btn btn--filter btn--filter-left"
					defaultValue={{ id: selected.month(), label: UI_LABELS.MONTH[selected.month() + 1] }}
					id="month"
					labelKey="label"
					onValueChange={this.onMonthChange}
					options={MONTH_OPTIONS}
					renderMenuItem={this.renderDropdownElement}
					valueKey="id"
					withBorder={false}
				/>
				<Dropdown<DropdownItem>
					className="btn btn--filter btn--filter-middle"
					defaultValue={{ id: 0, label: selected.year().toString() }}
					id="year"
					labelKey="label"
					onValueChange={this.onYearChange}
					options={YEAR_OPTIONS}
					renderMenuItem={this.renderDropdownElement}
					valueKey="label"
					withBorder={false}
				/>
				<span className="btn btn--filter btn--filter-right btn--icon ">
					<span className="icon-calendar" />
				</span>
			</div>
		);
	}
}

export default MonthlyPicker;
