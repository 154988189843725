import * as React from 'react';
import type { DraggableProvided } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import CDLStatus from '@acceligentllc/shared/enums/cdlStatus';
import type { ExtendedColorValue } from '@acceligentllc/shared/enums/color';
import { DefaultColor } from '@acceligentllc/shared/enums/color';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';

import type { RootState } from 'af-reducers';

import type ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import EmployeeUnavailabilityReason from 'af-root/scenes/Company/ScheduleBoard/Shared/UnavailabilityReason';
import ReturnDateDatePicker from 'af-root/scenes/Company/ScheduleBoard/Shared/ReturnDateDatepicker';

import DraggableItemBody from './DraggableItemBody';

import { generateEmployeeSearchItemId } from 'af-utils/scheduleBoard.util';

interface StateProps {
	employee: ScheduleBoardEmployeeViewModel;
	draggableId: string;
	isActive: boolean;
	isDisabled: boolean;
}

interface OwnProps extends DraggableProvided {
	employeeId: number;
	dueDate: string;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isToolbar: boolean;
	isDragging: boolean;
	isPerDiem?: boolean;
	onClick: () => void;
	workOrderCode?: string;
}

type Props = StateProps & OwnProps;

interface State {
	draggableItemClassName: string;
	officeColor: ExtendedColorValue;
}

class Draggable extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		employee: {} as ScheduleBoardEmployeeViewModel,
	};

	state: State = {
		draggableItemClassName: '',
		officeColor: DefaultColor.LOCATION,
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			isActive,
			isDisabled,
			isToolbar,
			employee: { isMatched, cdlStatus, isFilteredOnToolbar, isFilteredOnBoard, office },
			workOrderCode,
		} = nextProps;

		let draggableItemClassName = 'sb-resource-item sb-resource-item--employee';
		draggableItemClassName += isDisabled ? ' sb-resource-item--disabled' : '';
		draggableItemClassName += isMatched && !workOrderCode ? ' sb-resource-item--highlighted' : '';
		draggableItemClassName += cdlStatus === CDLStatus.NO_CDL ? ' sb-resource-item--no-cdl' : '';
		draggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
		draggableItemClassName += isActive ? ' sb-resource-item--active' : '';
		draggableItemClassName = draggableItemClassName.trim();

		const officeColor = office?.color;

		return prevState.draggableItemClassName !== draggableItemClassName || prevState.officeColor !== officeColor ? {
			draggableItemClassName,
			officeColor,
		} : null;
	}

	render() {
		const {
			draggableProps,
			dragHandleProps,
			innerRef,
			onClick,
			employee,
			isDragging,
			dueDate,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			isPerDiem,
		} = this.props;
		const { id: employeeId } = employee;
		const { draggableItemClassName, officeColor } = this.state;
		const { draggableId } = this.props;

		return (
			<div
				ref={innerRef}
				{...draggableProps}
				{...dragHandleProps}
				className="sb-resource-item--with-reason"
			>
				<div className="sb-resource-item--with-datepicker">
					<div
						className={draggableItemClassName}
						id={draggableId}
					>
						<a className="sb-resource-item__content" onClick={onClick} role="button">
							<DraggableItemBody
								employee={employee}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								isDragAndDropDisabled={isDragAndDropDisabled}
								isPerDiem={isPerDiem}
								officeColor={officeColor}
							/>
						</a>
					</div>
					<div className={isDragging ? 'sb-resource-item--opaque' : ''}>
						<ReturnDateDatePicker
							dueDate={dueDate}
							hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
							itemId={employeeId}
							scope={UnavailabilityReasonScope.EMPLOYEE}
						/>
					</div>
				</div>
				<EmployeeUnavailabilityReason
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					isDragging={isDragging}
					itemId={employeeId}
					scope={UnavailabilityReasonScope.EMPLOYEE}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { employees: employeeDict, draggedEmployeeId, searchResultItems, activeSearchItemIndex } = state.scheduleBoard;
	const { employeeId, isToolbar } = ownProps;
	if (!employeeDict) {
		throw new Error('Employee dictionary not initialized');
	}

	const draggableId = generateEmployeeSearchItemId(employeeId.toString());
	const employee = employeeDict[employeeId] || undefined;
	const isActive = searchResultItems[activeSearchItemIndex] === draggableId;
	const isDraggedFromToolbarInOtherTab = isToolbar && employee?.isDisabled && !draggedEmployeeId;

	return {
		draggableId,
		employee,
		isDisabled: draggedEmployeeId === employeeId || isDraggedFromToolbarInOtherTab,
		isActive,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(Draggable);
