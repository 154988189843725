import baseRoute from '../baseRoute';

export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/${number | ':fieldReportId'}/billableWork/exists`;

export const ROUTE: RouteDefinition = `${baseRoute}/:fieldReportId/billableWork/exists`;

export const URL = (fieldReportId: number): RouteDefinition =>
	`${baseRoute}/${fieldReportId}/billableWork/exists`;
