import * as React from 'react';
import { compose } from 'redux';
import type { WrappedFieldProps } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';

import type BlobStorageContainer from '@acceligentllc/shared/enums/blobStorageContainer';
import type { DirectoryIcon } from '@acceligentllc/shared/enums/directoryIcon';
import { ALLOWED_FILE_TYPES_LIST } from '@acceligentllc/shared/enums/fileType';

import { getId, toLookupById } from '@acceligentllc/shared/utils/array';

import { fromFileType } from 'ab-enums/mimeType.enum';
import type { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import type { PendingAttachmentVM } from 'ab-viewModels/attachment/pendingAttachment.viewModel';

import * as AttachmentActions from 'af-actions/attachment';

import ConfirmationModal from 'af-components/ConfirmationModal';
import DirectoriesAttachmentTree from 'af-components/DocumentsAttachmentsTree';
import type { AttachmentEntity, DirectoryEntity } from 'af-components/DocumentsAttachmentsTree/types';

import CreateNewDirectoryModal from 'af-root/scenes/Company/Jobs/Preview/Attachments/CreateNewDirectoryModal';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import type { RootState } from 'af-reducers';

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

export interface DirectoryRM {
	id: number;
	name: string;
	parentId: Nullable<number>;
	icon: Nullable<DirectoryIcon>;
	lastModifiedAt: Nullable<Date>;
	lastModifiedBy: DirectoryEntity['lastModifiedBy'];
	attachmentIds: number[];
}

function createMapToVMDirectory(childrenMap: Record<string, DirectoryRM[]>, attachmentMap: Record<string, AttachmentEntity>) {
	return function mapToVMDirectory(directory: DirectoryRM): DirectoryEntity {
		const data = {
			id: directory.id,
			name: directory.name,
			icon: directory.icon,
			directories: childrenMap[directory.id]?.map(mapToVMDirectory) ?? [],
			attachments: directory.attachmentIds.reduce<AttachmentEntity[]>((_acc, _id) => {
				if (attachmentMap[_id]) {
					_acc.push(attachmentMap[_id]);
				}
				return _acc;
			}, []),
			lastModifiedBy: directory.lastModifiedBy,
			lastModifiedAt: directory.lastModifiedAt,
			numberOfFiles: 0,
		};
		data.numberOfFiles = data.directories.reduce((_acc, _child) => _acc + _child.numberOfFiles, data.attachments.length);
		return data;
	};
}

export interface Column {
	header: string;
	id?: string;
	width?: number;
}

interface RowActionOptions {
	actionName: string;
	actionFunction: (params: unknown) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface OwnProps {
	containerId: number;
	directories: Nullable<DirectoryRM[]>;
	attachments: Nullable<AttachmentEntity[]>;
	uploadedAttachmentIds: Nullable<number[]>;
	tableColumns: Column[];
	storageContainer: BlobStorageContainer;
	onDirectoriesChanged: (directories: DirectoryRM[]) => void;
	onAttachmentsUpload: (attachmentIds: number[]) => void;
	onAttachmentsChanged: (attachments: AttachmentEntity[]) => void;
	onAttachmentUploadStart?: (correlationId: string) => void;
	onAttachmentUploadEnd?: (correlationId: string) => void;
	isReadOnly: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & WrappedFieldProps;

const DirectoriesAndAttachmentsForm = React.memo((props: Props) => {
	const {
		accountId,
		attachments,
		containerId,
		directories,
		downloadAttachment,
		onAttachmentUploadEnd,
		onAttachmentUploadStart,
		onAttachmentsChanged,
		onAttachmentsUpload,
		onDirectoriesChanged,
		storageContainer,
		tableColumns,
		uploadPendingAttachments,
		uploadedAttachmentIds,
		user,
		isReadOnly,
	} = props;

	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const tempIdRef = React.useRef(-1);

	const [directoryIdForDelete, setDirectoryIdForDelete] = React.useState<Nullable<number>>(null);
	const [deleteDirectoryModalOpen, setdeleteDirectoryModalOpen] = React.useState(false);
	const [uploadDirectoryId, setUploadDirectoryId] = React.useState<Nullable<number>>(null);
	const [parentDirectoryForCreate, setParentDirectoryForCreate] = React.useState<Nullable<DirectoryEntity>>(null);

	const notificationSnackbar = useNotificationSnackbar();

	const attachmentMap = React.useMemo<Record<string, AttachmentEntity>>(() => {
		if (!attachments) {
			return {};
		}
		return attachments.reduce<Record<string, AttachmentEntity>>(toLookupById, {});
	}, [attachments]);

	const childrenMap = React.useMemo(() => {
		if (!directories) {
			return {};
		}
		return directories.reduce<Record<string, DirectoryRM[]>>((_acc, _dir) => {
			if (_dir.parentId) {
				if (!_acc[_dir.parentId]) {
					_acc[_dir.parentId] = [];
				}
				_acc[_dir.parentId].push(_dir);
			}
			return _acc;
		}, {});
	}, [directories]);

	const allowedFileTypes = React.useMemo(() => [ALLOWED_FILE_TYPES_LIST.map(fromFileType)].join(', '), []);

	const directoriesAndAttachments = React.useMemo<DirectoryEntity[]>(() => {
		if (!directories) {
			return [];
		}
		const mapToVMDirectory = createMapToVMDirectory(childrenMap, attachmentMap);
		return directories.filter((_dir) => !_dir.parentId).map(mapToVMDirectory);
	}, [attachmentMap, childrenMap, directories]);

	const getNextTemporaryId = React.useCallback(() => {
		const nextId = tempIdRef.current;
		tempIdRef.current -= 1;
		return nextId;
	}, []);

	const createNewDirectory = React.useCallback(async (parent: DirectoryEntity, name: string) => {
		if (!directories) {
			return;
		}
		const nextFormDirectories = [...directories];
		nextFormDirectories.push({
			id: getNextTemporaryId(),
			name,
			icon: null,
			parentId: parent.id,
			lastModifiedAt: new Date(),
			lastModifiedBy: user ? {
				accountId,
				userId: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				fullName: `${user.firstName} ${user.lastName}`,
			} : null,
			attachmentIds: [],
		});
		onDirectoriesChanged(nextFormDirectories);
	}, [accountId, directories, getNextTemporaryId, onDirectoriesChanged, user]);

	const callDownloadAttachment = React.useCallback(async (original: AttachmentEntity) => {
		await downloadAttachment(original.id, original.name);
	}, [downloadAttachment]);

	const removeAttachmentFromDirectory = React.useCallback(async (original: AttachmentEntity) => {
		const { directoryId: directoryIdToRemoveFrom, id } = original;
		if (!directoryIdToRemoveFrom || !directories) {
			return;
		}
		const nextFormDirectories = directories.map((_dir) => {
			if (_dir.id !== directoryIdToRemoveFrom) {
				return _dir;
			}
			const rv = { ..._dir, attachmentIds: _dir.attachmentIds.filter((_id) => _id !== id) };
			return rv;
		});
		onDirectoriesChanged(nextFormDirectories);
		notificationSnackbar.success('Attachment successfully deleted', new Date());
	}, [directories, notificationSnackbar, onDirectoriesChanged]);

	const recursivelyFindChildrenIdMap = React.useCallback((directoryId: number): Record<string, true> => {
		let idMap: Record<string, true> = { [directoryId]: true };
		const children = childrenMap[directoryId] ?? [];
		for (const child of children) {
			idMap = { ...idMap, ...recursivelyFindChildrenIdMap(child.id) };
		}
		return idMap;
	}, [childrenMap]);

	const deleteSelectedDirectory = React.useCallback(async () => {
		if (directoryIdForDelete && directories) {
			const idMapForDelete = recursivelyFindChildrenIdMap(directoryIdForDelete);
			const nextFormDirectories = directories.filter((_dir) => !idMapForDelete[_dir.id]);
			onDirectoriesChanged(nextFormDirectories);
		}
		notificationSnackbar.success('Directory successfully deleted', new Date());
		setdeleteDirectoryModalOpen(false);
	}, [directoryIdForDelete, directories, notificationSnackbar, recursivelyFindChildrenIdMap, onDirectoriesChanged]);

	const uploadFileToDirectory = React.useCallback((directory: DirectoryEntity) => {
		setUploadDirectoryId(directory.id);
		fileInputRef.current?.click();
	}, []);

	const handleFileUpload = React.useCallback(async (directoryId: number, files: File[]) => {
		if (!directories) {
			return;
		}
		const correlationId = nanoid();
		onAttachmentUploadStart?.(correlationId);
		let uploadedAttachments: PendingAttachmentVM[] = [];

		const notification: Nullable<NotificationData> = notificationSnackbar.loading(`Uploading ${files[0].name}`, new Date());

		try {
			uploadedAttachments = await uploadPendingAttachments({ requestFiles: files }, storageContainer);

			if (notification) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = `${files[0].name} has successfully been uploaded.`;
				notificationSnackbar.success(notificationText, new Date());
			}
		} finally {
			onAttachmentUploadEnd?.(correlationId);
		}
		const nextFormDirectories = directories.map((_dir) => {
			if (_dir.id !== directoryId) {
				return _dir;
			} else {
				return { ..._dir, attachmentIds: [..._dir.attachmentIds, ...uploadedAttachments.map(getId)] };
			}
		});
		const nextUploadedAttachmentIds = [...(uploadedAttachmentIds ?? []), ...uploadedAttachments.map(getId)];
		onAttachmentsChanged([...(attachments ?? []), ...uploadedAttachments]);
		onDirectoriesChanged(nextFormDirectories);
		onAttachmentsUpload(nextUploadedAttachmentIds);
	}, [attachments,
		directories,
		notificationSnackbar,
		onAttachmentUploadEnd,
		onAttachmentUploadStart,
		onAttachmentsChanged,
		onAttachmentsUpload,
		onDirectoriesChanged,
		storageContainer,
		uploadPendingAttachments,
		uploadedAttachmentIds]);

	const handleAttachmentUpload = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files || !uploadDirectoryId) {
			return;
		}
		const requestFiles: File[] = [];
		for (let i = 0; i < files.length; i += 1) {
			const file = files.item(i);
			if (file) {
				requestFiles.push(file);
			}
		}
		const directoryId = uploadDirectoryId;
		setUploadDirectoryId(null);
		handleFileUpload(directoryId, requestFiles);
	}, [handleFileUpload, uploadDirectoryId]);

	const deleteDirectory = React.useCallback(async (original: DirectoryEntity) => {
		const { id } = original;

		setdeleteDirectoryModalOpen(true);
		setDirectoryIdForDelete(id);
	}, []);

	const handleCopyToWorkOrder = React.useCallback((attachmentId: number, copyToWorkOrder: boolean) => {
		const attachment = attachmentMap[attachmentId];
		if (!attachment) {
			return;
		}
		const nextAttachmentMap = { ...attachmentMap };
		nextAttachmentMap[attachmentId] = { ...attachment, copyToWorkOrderFlag: copyToWorkOrder };
		onAttachmentsChanged(Object.values(nextAttachmentMap));
	}, [attachmentMap, onAttachmentsChanged]);

	const closeCreateModal = React.useCallback(() => {
		setParentDirectoryForCreate(null);
	}, []);

	const handleAttachmentParentChange = React.useCallback(async (attachmentId: number, sourceDirectoryId: number, destinationDirectoryId: number) => {
		if (sourceDirectoryId === destinationDirectoryId) {
			return;
		}

		const attachment = attachmentMap[attachmentId];
		if (!attachment) {
			return;
		}
		const nextAttachmentMap = { ...attachmentMap };
		nextAttachmentMap[attachmentId] = { ...attachment, directoryId: destinationDirectoryId };
		onAttachmentsChanged(Object.values(nextAttachmentMap));

		if (!sourceDirectoryId || !directories) {
			return;
		}
		const nextFormDirectories = directories.map((_dir) => {
			if (_dir.id === sourceDirectoryId) {
				return { ..._dir, attachmentIds: _dir.attachmentIds.filter((_id) => _id !== attachmentId) };
			} else if (_dir.id === destinationDirectoryId) {
				return { ..._dir, attachmentIds: [..._dir.attachmentIds, attachmentId] };
			}
			return _dir;
		});
		onDirectoriesChanged(nextFormDirectories);
	}, [attachmentMap, directories, onAttachmentsChanged, onDirectoriesChanged]);

	const rowActions = React.useMemo<RowActions>(() => ({
		parentDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			/**
			 * To grey out (disable) action
			 */
			disabled: false,
		}],
		childDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			disabled: false,
			/**
			 * Child level 3 doesn't have option to create new folder
			 */
			show: true,
		},
		{
			actionName: 'Upload file',
			actionFunction: uploadFileToDirectory,
			disabled: false,
			show: false,
		},
		{
			actionName: 'Delete',
			actionFunction: deleteDirectory,
			disabled: false,
			show: true,
		}],
		attachmentLevel: [{
			actionName: 'Download',
			actionFunction: callDownloadAttachment,
			show: true,
		},
		{
			actionName: 'Delete',
			actionFunction: removeAttachmentFromDirectory,
			show: true,
		}],
	}), [deleteDirectory, callDownloadAttachment, removeAttachmentFromDirectory, uploadFileToDirectory]);

	return (
		<>
			<DirectoriesAttachmentTree
				columns={tableColumns}
				dataTree={directoriesAndAttachments}
				handleAttachmentParentChange={handleAttachmentParentChange}
				id={containerId}
				isReadOnly={isReadOnly}
				onCopyToWOChange={handleCopyToWorkOrder}
				onFileUpload={handleFileUpload}
				rowActions={rowActions}
			/>
			<input
				accept={allowedFileTypes}
				onChange={handleAttachmentUpload}
				ref={fileInputRef}
				style={{ display: 'none' }}
				type="file"
			/>
			{
				<CreateNewDirectoryModal
					closeModal={closeCreateModal}
					createDirectory={createNewDirectory}
					parent={parentDirectoryForCreate}
				/>
			}
			{
				deleteDirectoryModalOpen &&
				<ConfirmationModal
					body="You are about to permanently delete the Directory and all the files in it. Are you sure you want to continue?"
					closeModal={setdeleteDirectoryModalOpen.bind(this, false)}
					confirmAction={deleteSelectedDirectory.bind(this)}
					confirmText="Delete"
					modalStyle="danger"
					showModal={deleteDirectoryModalOpen}
					title={'Delete Directory'}
				/>
			}
		</>
	);
});

function mapStateToProps(state: RootState) {
	const { user } = state;

	if (!user.companyData) {
		throw new Error('Company Data not present');
	}

	return {
		user: user.userData,
		accountId: user.companyData.accountId,
	};
}

function mapDispatchToProps() {
	return {
		downloadAttachment: AttachmentActions.downloadAttachment,
		uploadPendingAttachments: AttachmentActions.uploadPendingAttachments,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps & WrappedFieldProps>>(connector);

export default enhance(DirectoriesAndAttachmentsForm);
