import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import type { SignatureFieldVM } from '@acceligentllc/shared/utils/fieldReport';
import { Dimension, Type } from '@acceligentllc/shared/enums/reportBlockField';
import { formatDate } from '@acceligentllc/shared/utils/time';
import { ExtendedColorPalette } from '@acceligentllc/shared/enums/color';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import SignatureStatusLabel from 'af-root/scenes/Company/FieldReports/Shared/SignatureStatusLabel';

import styles from './styles.module.scss';
import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';

type FieldReportBlockFieldVM = FieldReportTypeSidePanelVM['fieldReportBlocks'][0]['fieldReportBlockFields'][0];

type RepeatableProps = {
	isRepeatable: true;
	repeatableIndex: number;
} | {
	isRepeatable: false;
	repeatableIndex?: never;
};

type Props = {
	fieldReportBlockField: FieldReportBlockFieldVM;
} & RepeatableProps;

const _renderSignatureField = (_value: Nullable<string>) => {
	if (!_value) {
		return 'None';
	}

	const signature = JSON.parse(_value) as SignatureFieldVM;

	if (signature.id < 1) {
		return 'None';
	}

	return (
		<div className={styles['side-panel-modal__signature-field']}>
			<span>
				<SignatureStatusLabel status={TimeSheetSignatureStatus.SIGNED} />
				by {signature.name}
				<br />
				{formatDate(signature.signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
			</span>
			<img src={signature.imageUrl ?? undefined} />
		</div>
	);

};

const _renderCalculatedField = (value: Nullable<string>, unit: Nullable<QuantityUnitType>) => {
	return (
		<div className={styles['side-panel-modal__calculated-field']}>
			{!!value ? `${value} ${unit ?? ''}` : 'None'}
		</div>
	);
};

const _renderLineBreakField = () => <div className={styles['side-panel-modal__field--largest']} />;

const _renderImageField = (_value: Nullable<string>) => !!_value ? <img src={_value} /> : 'None';

const _renderImmutableText = (fieldReportBlockField: FieldReportBlockFieldVM) => {
	const blackText = fieldReportBlockField.reportBlockField.descriptiveTextColor === ExtendedColorPalette.BLACK;
	const classNames = [styles['side-panel-modal__descriptive-text'], styles['side-panel-modal__field--largest'], styles['side-panel-modal__field']];
	if (blackText) {
		classNames.push(styles['side-panel-modal__descriptive-text--black-text']);
	}

	if (fieldReportBlockField.reportBlockField.isDescriptiveTextBold) {
		classNames.push(styles['side-panel-modal__descriptive-text--bold-text']);
	}

	return (
		<div className={classNames.join(' ')}>
			{fieldReportBlockField.reportBlockField.defaultValue}
		</div>
	);
};

const resolveClassName = (dimension: Dimension) => {
	const fieldClassName = [styles['side-panel-modal__field']];

	switch (dimension) {
		case Dimension.ADJUSTABLE: {
			fieldClassName.push(styles['side-panel-modal__field--adjustable']);
			break;
		}
		case Dimension.LARGEST:
			fieldClassName.push(styles['side-panel-modal__field--largest']);
			break;
		case Dimension.LARGE:
			fieldClassName.push(styles['side-panel-modal__field--large']);
			break;
		case Dimension.MEDIUM:
			fieldClassName.push(styles['side-panel-modal__field--medium']);
			break;
		case Dimension.SMALL:
			fieldClassName.push(styles['side-panel-modal__field--small']);
			break;
		case Dimension.VERY_SMALL:
			fieldClassName.push(styles['side-panel-modal__field--very-small']);
			break;
		default:
			fieldClassName.push(styles['side-panel-modal__field--small']);
			break;
	}

	return fieldClassName.join(' ');
};

const resolveFieldValue = (fieldReportBlockField: FieldReportBlockFieldVM, value: Nullable<string>) => {
	if (fieldReportBlockField.fieldType === Type.IMAGE || fieldReportBlockField.fieldType === Type.IMMUTABLE_IMAGE) {
		return _renderImageField(value);
	}

	if (fieldReportBlockField.fieldType === Type.SIGNATURE) {
		return (
			_renderSignatureField(value)
		);
	}

	if (fieldReportBlockField.fieldType === Type.CALCULATED) {
		return _renderCalculatedField(value, fieldReportBlockField.reportBlockField.unit);
	}

	return value !== null && value !== ''
		? `${value} ${fieldReportBlockField.reportBlockField.unit ?? ''}`
		: 'None';
};

const FieldReportBlockFieldSidePanelModal: React.FC<Props> = (props) => {
	const { fieldReportBlockField, isRepeatable } = props;

	const fieldClassName = resolveClassName(fieldReportBlockField.reportBlockField.dimension);

	if (fieldReportBlockField.isCompletion) {
		// we are rendering it in footer of block
		return null;
	}

	if (fieldReportBlockField.fieldType === Type.LINE_BREAK) {
		return _renderLineBreakField();
	}

	if (fieldReportBlockField.fieldType === Type.IMMUTABLE_TEXT) {
		return _renderImmutableText(fieldReportBlockField);
	}

	if (isRepeatable) {
		const currentFieldValue = (fieldReportBlockField.value as Nullable<string>[])?.[props.repeatableIndex];
		const fieldValue = resolveFieldValue(fieldReportBlockField, currentFieldValue);
		return (
			<div className={fieldClassName} key={fieldReportBlockField.id}>
				<div className={styles['side-panel-modal__field__header']}>
					{fieldReportBlockField.reportBlockField.name}
				</div>
				<div className={styles['side-panel-modal__field__value']}>
					{fieldValue ?? 'None'}
				</div>
			</div>
		);
	}

	const fieldValue = resolveFieldValue(fieldReportBlockField, fieldReportBlockField.value as Nullable<string>);
	const hideHeader = fieldReportBlockField.fieldType === Type.IMMUTABLE_IMAGE;

	return (
		<div className={fieldClassName} key={fieldReportBlockField.id}>
			{!hideHeader && (
				<div className={styles['side-panel-modal__field__header']}>
					{fieldReportBlockField.reportBlockField.name}
				</div>
			)}
			<div className={styles['side-panel-modal__field__value']}>
				{fieldValue}
			</div>
		</div>
	);
};

export default React.memo(FieldReportBlockFieldSidePanelModal);
