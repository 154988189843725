import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, initialize } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import type { RootState } from 'af-reducers';

import { LOCATION_EDIT } from 'af-constants/reduxForms';

import * as LocationActions from 'af-actions/location';

import EditLocationForm from 'af-components/SharedForms/Resources/Location';
import { validate } from 'af-components/SharedForms/Resources/Location/validation';
import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';

import { toRequestModelWithRawPhoneNumbers, toRequestModelWithFormattedPhoneNumbers } from 'ab-utils/form.util';

import { fromVMtoRM } from './formModel';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	update: typeof LocationActions.update;
	findById: typeof LocationActions.findById;
	initializeForm: (formName: string, form: LocationRequestModel) => void;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<LocationRequestModel, FormOwnProps>;

class Edit extends React.Component<Props> {

	async componentDidMount() {
		const { initializeForm, findById, match: { params: { id } } } = this.props;
		const location = await findById(id);
		const form = toRequestModelWithFormattedPhoneNumbers(
			fromVMtoRM(location),
			['phone', 'phoneCountryCode'],
			['fax', 'faxCountryCode']
		);
		initializeForm(LOCATION_EDIT, form);
	}

	editLocation = async (form: LocationRequestModel) => {
		const { history, update, location: { state: { orgAlias } }, companyName } = this.props;
		const data: LocationRequestModel = toRequestModelWithRawPhoneNumbers(form, 'phone', 'fax');
		await update(data);
		history.push(CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			match: { params: { id } },
			companyName,
		} = this.props;
		return (
			<div className="form-segment">
				<Breadcrumbs
					items={[
						{ label: 'Location', url: CLIENT.COMPANY.RESOURCES.LOCATION.LIST(orgAlias, companyName) },
						{ label: 'Preview', url: CLIENT.COMPANY.RESOURCES.LOCATION.PREVIEW(id, orgAlias, companyName) },
						{ label: 'Edit' },
					]}
				/>
				<EditLocationForm
					{...this.props}
					onSubmit={this.editLocation}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findById: LocationActions.findById,
		update: LocationActions.update,
		initializeForm: initialize,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<LocationRequestModel, FormOwnProps>({ form: LOCATION_EDIT, validate })
);
export default enhance(Edit);
