import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import { PASSWORD_LENGTH_MIN, PASSWORD_CATEGORIES } from 'ab-common/constants/value';

import * as AuthenticationActions from 'af-actions/authentication';

import CLIENT from 'af-constants/routes/client';
import { USER_FINALIZE } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import type * as User from 'ab-viewModels/user.viewModel';

import validate from 'af-root/scenes/Authentication/FinalizeUser/validations';

import { defaultRedirectUrl } from 'af-utils/actions.util';

import styles from './styles.module.scss';

type OwnProps = CustomRouteComponentProps;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<UserRequestModel.FinalizeUser, FormOwnProps>;

enum PwCategories {
	UPPERCASE = 'uppercase letters',
	LOWERCASE = 'lowercase letters',
	NUMBERS = 'numbers',
	SYMBOLS = 'symbols'
}

class FinalizeUser extends React.Component<Props> {

	static defaultProps: Partial<Props> = {
		userData: {} as User.UserData,
		organizationData: {} as User.OrganizationData,
	};

	componentDidUpdate() {
		const { userData, organizationData, history, location: { state: { orgAlias } } } = this.props;
		if (!userData?.isFinalized || userData?.isPasswordExpired || !organizationData) {
			return;
		}
		if (organizationData.isPlatformAdmin) {
			return history.push(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
		}
		if (organizationData.companies.length) {
			// Consider feature toggles before redirecting
			return history.push(defaultRedirectUrl(orgAlias, organizationData.companies[0].name, [], true, userData.role));
		}
		return history.push(CLIENT.COMPANY.CREATE(orgAlias));
	}

	finalize = async (form: UserRequestModel.FinalizeUser) => {
		const { finalize, organizationData, location: { state: { orgAlias } } } = this.props;
		if (!organizationData) {
			throw new Error('Organization not defined');
		}

		await finalize(form, organizationData, orgAlias);
	};

	render(): JSX.Element {
		const { handleSubmit, submitting, invalid, formData } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className={`form-box form-box--standalone ${styles.container__padded}`}>
					<Row>
						<Col sm={24}>
							<div className={styles.marginBottom__l}>
								<h2>Welcome to Acceligent!</h2>
								<h4><b>Powerful. Fast. Easy-to-use.</b></h4>
								<h4>
									Acceligent is a comprehensive solution that helps businesses bid smarter,
									utilize resources more efficiently and improve job performance.
								</h4>
							</div>
							<h4>Please enter a new password to finalize your account.</h4>
						</Col>
					</Row>
					<Form className={styles.formPadding} onSubmit={handleSubmit(this.finalize)}>
						<Row className={styles.marginBottom__s}>
							<Col sm={24}>
								<Field
									component={Input}
									disableErrors={!(formData?.submitErrors && Object.keys(formData.submitErrors).length)}
									id="password"
									label="Password *"
									name="password"
									type="password"
								/>
							</Col>
						</Row>
						<Row className={styles.marginBottom__s}>
							<Col sm={24}>
								<Field
									component={Input}
									id="repeatedPassword"
									label="Repeat Password *"
									name="repeatedPassword"
									type="password"
								/>
							</Col>
						</Row>
						<Row>
							<Col className={styles.container} sm={24}>
								<div className={`${styles.pwRequirementsElement}`}>Password requirements:</div>
								<div className={`${styles.pwRequirementsContainer}`}>{
									formData?.values?.password?.length >= PASSWORD_LENGTH_MIN ?
										<span className={`${styles.icon} icon-check_circle text-green`} /> :
										<span className={`${styles.icon} icon-dot ${styles.textGrey}`} />
								}
									<p>At least {PASSWORD_LENGTH_MIN} characters long</p>
								</div>
								<div>Contains characters from&nbsp;<strong>at least three</strong>&nbsp;of these categories:</div>
								{Object.keys(PwCategories).map((key) => {
									return <div className={`${styles.pwRequirementsContainer}`} key={key}>
										{validateCategory(formData?.values?.password, PwCategories[key]) ?
											<span className={`${styles.icon} icon-check_circle text-green`} /> :
											<span className={`${styles.icon} icon-dot ${styles.textGrey}`} />
										}
										<p>{PwCategories[key]}</p>
									</div>;
								})}
							</Col>
						</Row>
						<Row className={`${styles.submitButton} row--submit`}>
							<SubmitButton
								disabled={invalid}
								fullWidth={true}
								label="Submit"
								reduxFormSubmitting={submitting}
								size="big"
								variant="primary"
							/>
						</Row>
					</Form>
				</div>
			</div>
		);
	}
}

function validateCategory(values: string | undefined, category: PwCategories): boolean {
	const isValid = values && PASSWORD_CATEGORIES[category].test(values);
	return !!isValid;
}

function mapStateToProps(state: RootState) {
	return {
		organizationData: state.user.organizationData,
		userData: state.user.userData,
		formData: state.form.finalizeUserForm,
	};
}

function mapDispatchToProps() {
	return {
		finalize: AuthenticationActions.finalize,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UserRequestModel.FinalizeUser, FormOwnProps>({ form: USER_FINALIZE, validate })
);

export default enhance(FinalizeUser);
