import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type { EquipmentCostRequestModel } from 'ab-requestModels/equipmentCost.requestModel';
import type { EquipmentCostCategoryForm as ECCForm } from 'ab-requestModels/equipmentCostCategory.requestModel';

export function validateEquipmentCategory(values: ECCForm) {
	const errors: ValidationErrors = {};

	if (!values.color) {
		errors.color = 'Color is required';
	}

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	return errors;
}

export function validate(values: EquipmentCostRequestModel) {
	const errors: ValidationErrors = {};

	if (!values.category) {
		errors.category = 'Category is required';
	}

	if (!isValidTextInput(values.subcategory)) {
		errors.subcategory = 'Subcategory is required';
	}

	return errors;
}
