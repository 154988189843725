import * as React from 'react';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import { type getSegmentCount } from 'ab-utils/fieldReport.util';

import LoadingIndicator from 'af-components/LoadingIndicator';

import FieldReportBlock from './FieldReportBlock';
import styles from './styles.module.scss';

type Props = {
	fieldReportId: number;
	fieldReportTypeId: number;
	findFieldReportTypeForSidePanel: (fieldReportId: number, fieldReportTypeId: number) => Promise<FieldReportTypeSidePanelVM>;
	fieldReportTypeToPreview: Nullable<FieldReportTypeSidePanelVM>;
	setFieldReportTypeToPreview: (frt: Nullable<FieldReportTypeSidePanelVM>) => void;
	segmentCountMap: ReturnType<typeof getSegmentCount>;
	instanceCount: number;
};

const FieldReportTypeSidePanel: React.FC<Props> = (props) => {
	const {
		fieldReportId,
		fieldReportTypeId,
		findFieldReportTypeForSidePanel,
		fieldReportTypeToPreview,
		setFieldReportTypeToPreview,
		instanceCount,
		segmentCountMap,
	} = props;
	const [isLoading, setIsLoading] = React.useState(false);

	const fetchAndSetData = React.useCallback(async () => {
		setIsLoading(true);
		const fetchedFieldReportType = await findFieldReportTypeForSidePanel(fieldReportId, fieldReportTypeId);
		setFieldReportTypeToPreview(fetchedFieldReportType);
		setIsLoading(false);
	}, [fieldReportId, fieldReportTypeId, findFieldReportTypeForSidePanel, setFieldReportTypeToPreview]);

	React.useEffect(() => {
		fetchAndSetData();
	}, [fetchAndSetData]);

	const fieldReportBlocksMapper = React.useCallback((_fieldReportBlock: FieldReportTypeSidePanelVM['fieldReportBlocks'][0]) => {
		const segmentCount = _fieldReportBlock.instanceIndex !== null ? segmentCountMap[_fieldReportBlock.instanceIndex] : 0;

		return (
			<FieldReportBlock
				fieldReportBlock={_fieldReportBlock}
				instanceCount={instanceCount}
				key={_fieldReportBlock.id}
				segmentCount={segmentCount}
			/>
		);
	}, [instanceCount, segmentCountMap]);

	if (isLoading || !fieldReportTypeToPreview) {
		return (
			<div className={styles['side-panel__loading']}>
				<LoadingIndicator color="orange" />
			</div>
		);
	}

	return (
		<div className={styles['side-panel']}>
			{fieldReportTypeToPreview.fieldReportBlocks.map(fieldReportBlocksMapper)}
		</div>
	);
};

export default React.memo(FieldReportTypeSidePanel);
