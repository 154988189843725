import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import WorkOrderStatusEnum from '@acceligentllc/shared/enums/workOrderStatus';
import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import type { EmployeeVM } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import OrderInfo from '../OrderInfo';
import ResourceDroppable from '../ResourceDroppable';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';
import { Button } from '@acceligentllc/storybook';

interface OwnProps {
	// Card OwnProps
	/** `MM-DD-YYYY` */
	dueDate: string;
	hasPermissionsToEditScheduleBoard: boolean;
	ignorePlaceholders?: boolean;
	isCalculationsView?: boolean;
	isDragAndDropDisabled: boolean;
	maxResourceItems: number;
	workOrderCode: string;
	/** prop not used if `isCalculationsView = true` */
	lastOpenedOrderCode?: string;
	// Card StateProps
	isCardDisabled: boolean;
	notificationStatus: Nullable<NotificationStatusEnum>;
	// CardData specific props
	isCopyPlaceholder?: boolean;
	isDragging: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getEmployeeLabel = (employee: Nullable<EmployeeVM>, isDuplicate: boolean) => {
	if (!employee) {
		return 'N/A';
	}
	const { firstName, lastName } = employee;
	return `${firstName[0]}${isDuplicate ? firstName[1] : ''} ${lastName}`;
};

const CardData: React.FC<Props> = (props) => {
	const {
		dueDate,
		hasPermissionsToEditScheduleBoard,
		hasProjectManagerDuplicateLastName,
		hasSuperintendentDuplicateLastName,
		ignorePlaceholders,
		isCalculationsView,
		isCardDisabled,
		isCopyPlaceholder = false,
		isDragAndDropDisabled,
		isDragging,
		isShowNotesActive,
		lastOpenedOrderCode,
		maxResourceItems,
		notificationStatus,
		resourceIds = [],
		workOrder,
		workOrderCode,
		projectCode,
	} = props;

	const { setWorkOrderNoteModalData } = useScheduleBoardModals();

	const openWorkOrderNoteModal = React.useCallback(() => {
		if (
			workOrder &&
			workOrder.status !== WorkOrderStatusEnum.LOCKED
			&& !workOrder.isPaused
			&& workOrder.status !== WorkOrderStatusEnum.CANCELED
		) {
			setWorkOrderNoteModalData(workOrder.code, workOrder.dueDate);
		}
	}, [setWorkOrderNoteModalData, workOrder]);

	if (!workOrder) {
		return null;
	}
	const supervisorName = _getEmployeeLabel(workOrder.supervisor, hasSuperintendentDuplicateLastName);
	const projectManagerName = _getEmployeeLabel(workOrder.projectManager, hasProjectManagerDuplicateLastName);
	const shouldShowNotes = isShowNotesActive && !isCalculationsView;
	const isWorkOrderCanceled: boolean = workOrder.status === WorkOrderStatusEnum.CANCELED;
	const droppableId = ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.BOARD, ScheduleBoardProperty.RESOURCE, dueDate, workOrder.code);

	const isResourcesContainerReadOnly = isDragAndDropDisabled || !hasPermissionsToEditScheduleBoard;
	const resourcesContainerClassName = `schedule-board-card-resources ${isResourcesContainerReadOnly ? `items-${maxResourceItems}` : ''}`;
	const dragAndDropDisabled = isDragAndDropDisabled || workOrder.isPaused;

	const className = projectCode && process.env.FTD_PROJECT !== 'true' ? 'project' : '';

	return (
		<div className={className}>
			<OrderInfo
				dueDate={dueDate}
				isCalculationsView={!!isCalculationsView}
				isDragging={isDragging}
				lastOpenedOrderCode={lastOpenedOrderCode}
				notificationStatus={notificationStatus}
				projectManagerName={projectManagerName}
				supervisorName={supervisorName}
				workOrder={workOrder}
			/>
			<div className={resourcesContainerClassName}>
				<ResourceDroppable
					draggablePrefix={ScheduleBoardContext.BOARD}
					droppableId={droppableId}
					dueDate={dueDate}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					ignorePlaceholders={!!ignorePlaceholders}
					isCalculationsView={!!isCalculationsView}
					isCardDisabled={isCardDisabled}
					isCopyPlaceholder={isCopyPlaceholder}
					isDragAndDropDisabled={dragAndDropDisabled}
					isWOLocked={workOrder.status === WorkOrderStatusEnum.LOCKED}
					isWorkOrderCanceled={isWorkOrderCanceled}
					resourceIds={resourceIds}
					workOrderCode={workOrderCode}
					wrappedColumns={true}
				/>
			</div>
			{shouldShowNotes &&
				<div className="schedule-board-card-notes" onClick={!!workOrder.workOrderNotes ? openWorkOrderNoteModal : undefined}>
					{
						!!workOrder.workOrderNotes ?
							(workOrder.workOrderNotes) :
							<Button
								fullWidth={true}
								icon="plus"
								label="Add a Note"
								onClick={openWorkOrderNoteModal}
								size="small"
								style="link"
							/>
					}
				</div>
			}
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate, workOrderCode } = ownProps;
	const {
		employees,
		isShowNotesActive,
		workOrdersByDateDictionary,
	} = state.scheduleBoard;
	const workOrdersOnDateDict: ScheduleBoardOnDateViewModel = workOrdersByDateDictionary[dueDate];
	const workOrder: ScheduleBoardWorkOrderViewModel = workOrdersOnDateDict?.workOrders?.[workOrderCode] ?? null;
	const employeesDict = employees ?? {};

	return {
		hasProjectManagerDuplicateLastName: !!(workOrder?.projectManagerId && employeesDict[workOrder?.projectManagerId]?.duplicateDisplayNameExists) || false,
		hasSuperintendentDuplicateLastName: !!(workOrder?.supervisorId && employeesDict[workOrder?.supervisorId]?.duplicateDisplayNameExists) || false,
		isShowNotesActive: isShowNotesActive,
		resourceIds: workOrder?.workOrderResourceLookups ?? undefined,
		workOrder,
		projectCode: workOrder?.projectJobCode ?? null,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(CardData);
