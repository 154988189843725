import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import Dropdown from 'af-components/Controls/Dropdown';
import LabelWithColor from 'af-components/LabelWithColor';

import type ScheduleBoardWorkOrderVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

interface OwnProps {
	assignmentNumber: number;
	createAssignment: (index: number, workOrder: ScheduleBoardWorkOrderVM) => void;
	removeAssignment: (index: number) => void;
	workOrders: ScheduleBoardWorkOrderVM[];
}

type Props = OwnProps;

class AssignableResourceAssignment extends React.PureComponent<Props> {

	static renderWorkOrderOption = (option: ScheduleBoardWorkOrderVM) => {
		return (
			<div>
				<div>{option.code}</div>
				<LabelWithColor
					color={option.crewTypeColor}
					text={<span className="text-grey">{option.crewTypeName}</span>}
				/>
			</div>
		);
	};

	onCreateAssignment = async (workOrder: ScheduleBoardWorkOrderVM) => {
		const { createAssignment, assignmentNumber } = this.props;
		createAssignment(assignmentNumber, workOrder);
	};

	onRemoveAssignment = () => {
		const { removeAssignment, assignmentNumber } = this.props;
		removeAssignment(assignmentNumber);
	};

	render() {
		const { assignmentNumber, workOrders } = this.props;

		return (
			<div
				className="schedule-board-modal__resource__assignment"
				key={`assignmentForm-${assignmentNumber}`}
			>
				<Dropdown<ScheduleBoardWorkOrderVM>
					containerClassName="schedule-board-modal__resource__assignment__dropdown"
					id={`assignmentForm-${assignmentNumber}`}
					isWhite={true}
					onValueChange={this.onCreateAssignment}
					options={workOrders}
					renderMenuItem={AssignableResourceAssignment.renderWorkOrderOption}
					valueKey="id"
					withCaret={true}
				/>
				<Button
					icon="delete"
					onClick={this.onRemoveAssignment}
					style="link-danger"
					tooltip="Delete"
				/>
			</div>
		);
	}
}

export default AssignableResourceAssignment;
