import * as React from 'react';

import { formatPhoneNumber } from '@acceligentllc/shared/utils/phone';

import type { CSVBulkAccountVM } from 'ab-viewModels/account/upload.viewModel';

import RejectReason from './RejectReason';

interface OwnProps {
	account: CSVBulkAccountVM['invalid'][0];
}

type Props = OwnProps;

const RejectedAccount: React.FC<Props> = ({ account }) => {
	const { errors } = account;

	const invalidUniqueId = errors.isUniqueIdUsed || errors.isUniqueIdMissing || errors.isDuplicateUniqueIdInCSV || errors.isSameAccountInCompany;

	const invalidEmail = errors.isEmailUsed
		|| errors.isEmailIncorrect
		|| errors.isPhoneOrEmailMissing
		|| errors.isDuplicateEmailInCSV
		|| errors.isMultipleUsers
		|| errors.isSameAccountInCompany;

	const invalidPhoneNumber = errors.isPhoneUsed
		|| errors.isPhoneIncorrect
		|| errors.isPhoneOrEmailMissing
		|| errors.isDuplicatePhoneNumberInCSV
		|| errors.isMultipleUsers
		|| errors.isSameAccountInCompany;

	const rejectionReason = React.useMemo(() => {
		const rejectionReasons: string[] = [];

		if (errors.isSameAccountInCompany) {
			rejectionReasons.push('Account already exists in the company.');
		} else {
			if (errors.isUniqueIdUsed) {
				rejectionReasons.push('Unique ID in use');
			}
			if (errors.isEmailUsed) {
				rejectionReasons.push('Email in use');
			}
			if (errors.isPhoneUsed) {
				rejectionReasons.push('Phone in use');
			}
		}

		if (errors.isUniqueIdMissing) {
			rejectionReasons.push('Unique ID missing');
		}

		if (errors.isEmailIncorrect) {
			rejectionReasons.push('Email not valid');
		}

		if (errors.isPhoneIncorrect) {
			rejectionReasons.push('Phone not valid');
		}

		if (errors.isPhoneOrEmailMissing) {
			rejectionReasons.push('Phone or email should be provided');
		}

		if (errors.isNameMissing) {
			rejectionReasons.push('First and last name should be provided');
		}

		if (errors.isDuplicateEmailInCSV) {
			rejectionReasons.push('Duplicate email defined in CSV file');
		}

		if (errors.isDuplicateUniqueIdInCSV) {
			rejectionReasons.push('Duplicate unique ID defined in CSV file');
		}

		if (errors.isDuplicatePhoneNumberInCSV) {
			rejectionReasons.push('Duplicate mobile phone defined in CSV file');
		}

		if (errors.isMultipleUsers) {
			rejectionReasons.push('User with the same email, as well as phone already exists in the organization. Please contact the company admin for more info.');
		}
		return rejectionReasons.join(', ');
	}, [errors]);

	return (
		<tr>
			<RejectReason invalid={invalidUniqueId} value={account.uniqueId} />
			<RejectReason invalid={invalidEmail} value={account.email} />
			<RejectReason invalid={invalidPhoneNumber} value={formatPhoneNumber(account.phoneNumber)} />
			<RejectReason invalid={errors.isNameMissing} value={errors.isNameMissing ? null : `${account.firstName} ${account.lastName}`} />
			<td>
				<span>{rejectionReason}</span>
			</td>
		</tr>
	);
};

export default React.memo(RejectedAccount);
