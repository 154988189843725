import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import TableButtonType from 'ab-enums/tableButtonType.enum';

import { bemElement } from 'ab-utils/bem.util';
import { filterMap } from 'ab-utils/array.util';

import type { ButtonData } from '../../types';
import type TableTypeEnum from '../../tableType.enum';

import SubmitButton from 'af-components/SubmitButton';

interface OwnProps {
	buttons: ButtonData[];
	tableType: TableTypeEnum;
	exportAsZip?: boolean;
}

type Props = OwnProps;

const buttonHasPermission = (button: ButtonData) => button.hasPermission;

const Buttons: React.FC<Props> = (props) => {

	const { exportAsZip = false, buttons } = props;

	const renderButton = React.useCallback(({ type, onClick, label, icon, actionKey }: ButtonData) => {

		const key = `${type}#${label}`;

		switch (type) {
			case TableButtonType.LINK:
				return (
					<Button
						icon={icon}
						key={key}
						label={label ?? ' '}
						onClick={onClick}
						style="link"
					/>
				);
			case TableButtonType.EXPORT:
				return (
					<Button
						icon="download"
						key={key}
						label={`Export as ${exportAsZip ? 'ZIP' : 'CSV'}`}
						onClick={onClick}
						style="link"
					/>
				);
			case TableButtonType.PRIMARY:
			default:
				return (
					<SubmitButton
						icon={icon}
						label={label ?? ''}
						onClick={onClick}
						submitKey={actionKey}
						variant="primary"
					/>
				);
		}
	}, [exportAsZip]);

	return (
		<div className={bemElement('table-container', 'buttons')}>
			{filterMap(buttons, buttonHasPermission, renderButton)}
		</div>
	);
};

export default React.memo(Buttons);
