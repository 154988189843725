import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import styles from './styles.module.scss';
import type { PercentagesOfDateChanges } from './types';

interface Props {
	dateChangePercentageData: PercentagesOfDateChanges;
	willOverlapWihtDateText: boolean;
	translatePercentageVariables: React.CSSProperties;
}

const Bar: React.FC<Props> = (props: Props) => {

	const {
		dateChangePercentageData,
		willOverlapWihtDateText,
		translatePercentageVariables,
	} = props;

	const dateToShow = formatDateToShowOnDateChange(dateChangePercentageData.dateToShow);
	const dateToShowContainerStlye = getDateToShowContainerStyle(dateChangePercentageData.isBeforeDueDate);
	const coverRightSide = dateChangePercentageData.isBetweenSections && dateChangePercentageData.isBeforeDueDate;

	return (
		<div
			className={styles['timeline__bar-container__date-change-bar']}
			style={getStyleVariablesForDateChange(
				dateChangePercentageData.leftOffsetInPercentage,
				dateChangePercentageData.isBetweenSections,
				dateChangePercentageData.isBeforeDueDate
			)}
		>
			{coverRightSide && <div className={styles['timeline__bar-container__date-change-bar__background-cover']} />}
			<div
				className={dateToShowContainerStlye}
				style={translatePercentageVariables}
			>
				{willOverlapWihtDateText ? '' : dateToShow}
			</div>
		</div>
	);

	function getStyleVariablesForDateChange(percentage: number, isBetweenSections: boolean, isBeforeDueDate: boolean) {
		const offset = isBeforeDueDate ? -3 : -1;
		return {
			'--percentageFromLeft': `${percentage}%`,
			'--adjustForGap': `${isBetweenSections ? `${offset}` : '0'}px`,
		} as React.CSSProperties;
	}

	function getDateToShowContainerStyle(isBeforeDueDate: boolean) {

		const dateTextContainerStyles = ['timeline__bar-container__date-change-bar__date-text'];

		const textPosition = isBeforeDueDate ?
			'timeline__bar-container__date-change-bar__date-text__left' :
			'timeline__bar-container__date-change-bar__date-text__right';

		dateTextContainerStyles.push(textPosition);
		return dateTextContainerStyles.map((style) => styles[style]).join(' ');
	}

	function formatDateToShowOnDateChange(date: Date) {
		return TimeUtils.formatDate(date.toISOString(), TimeFormat.SB_DATE_SHORT, TimeFormat.ISO_DATETIME);
	}
};

export default Bar;
