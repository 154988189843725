import * as React from 'react';

type Props = React.PropsWithChildren<unknown>;

import styles from './styles.module.scss';

const EquipmentDraggableBadgesContainer = (props: Props) => {
	const { children } = props;

	return (
		<div className={styles['badges-container']}>
			{children}
		</div>
	);
};

export default EquipmentDraggableBadgesContainer;
