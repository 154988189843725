import * as React from 'react';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import Tooltip from 'af-components/Tooltip';

import TimeSheetInfoWarningMessage from './TimeSheetInfoWarning';

import styles from './styles.module.scss';

interface OwnProps {
	userFullName: string;
	active: boolean;
	isApproved: boolean;
	isOwner: boolean;
	isTemporaryEmployee: boolean;
	overlaps: Nullable<Record<string, OverlapMeta>>;
	timeSheetEntities: TimelineEntitesForAccount[];
	showOverlaps: boolean;
	userUniqueId: Nullable<string>;
	isDuplicateName: boolean;
}

type Props = OwnProps;

const TimeSheetInfo: React.FC<Props> = (props: Props) => {
	const {
		userFullName,
		active,
		isApproved,
		isOwner,
		isTemporaryEmployee,
		overlaps,
		timeSheetEntities,
		showOverlaps,
		userUniqueId,
		isDuplicateName,
	} = props;

	const [overlappedEntriesByWO, setOverlappedEntriesByWo] = React.useState<Record<string, TimelineEntitesForAccount[]>>({});

	const hasWarning = !active || Object.keys(overlappedEntriesByWO).length > 0;
	const showUniqueId = isTemporaryEmployee && isDuplicateName;
	const ownerStyle = isOwner ? ` ${styles['time-sheet-info__user-name--owner']}` : '';
	const notApprovedIconStyle = !isApproved ? ` ${styles['time-sheet-info__user-name__warning-icon--not-approved']}` : '';

	React.useEffect(() => {
		const tempOverlappingEntities: Record<string, TimelineEntitesForAccount[]> = {};
		for (const _key of Object.keys(overlaps ?? {})) {
			const tse = timeSheetEntities.find((_tse) => {
				if (_tse.type !== TimelineEntityType.GAP && _tse.entry.id === +_key) {
					return _tse;
				}
			});
			if (tse && tse.type !== TimelineEntityType.GAP) {
				const code = overlaps?.[_key]?.workOrderCode ?? 'Unassociated WO';
				if (tempOverlappingEntities[code]) {
					tempOverlappingEntities[code].push(tse);
				} else {
					tempOverlappingEntities[code] = [tse];
				}
			}
		}
		setOverlappedEntriesByWo(tempOverlappingEntities);
	}, [overlaps, timeSheetEntities]);

	return (
		<>
			<div className={`${styles['time-sheet-info__user-name']}${ownerStyle}`}>
				{hasWarning &&
					<Tooltip message={
						<TimeSheetInfoWarningMessage
							overlappedEntriesByWO={overlappedEntriesByWO}
							showNotAssignedWarning={!active}
							showOverlaps={showOverlaps}
						/>
					}
					>
						<span className={`icon-warning ${styles['time-sheet-info__user-name__warning-icon']}${notApprovedIconStyle}`} />
					</Tooltip>
				}
				{
					isTemporaryEmployee &&
					<span className={`icon-temp_labor ${styles['time-sheet-info__temporary-employee-icon']}`} />
				}
				{userFullName}
				{
					showUniqueId &&
					<div className={styles['time-sheet-info__uniqueId']}>
						({userUniqueId})
					</div>
				}
			</div >
		</>
	);
};

export default React.memo(TimeSheetInfo);
