import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';

import type WorkSummaryDetailsVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import { downloadCSV } from 'af-utils/csv.utils';
import { dollarFormatter } from 'af-utils/format.util';

import BillableAndNonBillableWorkTable from '../Table/BillableAndNonBillableWorkTable';
import UserGuideModal from './UserGuide';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

interface OwnProps {
	workSummaryDetails: WorkSummaryDetailsVM[];
	workSummary: WorkSummaryVM[];
	billingCodes: Record<string, BillingCodeVM[]>;
	onBillingCodeSelect: (billingCode: Nullable<BillingCodeVM>, workSummaryDetailIds: number[]) => Promise<void>;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	onWorkSummaryStatusChange: (wss: WorkSummaryStatus) => () => Promise<void>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailsVM[]>;
	areFRsReadOnly: boolean;
	isWorkSummaryStatusCompleted: boolean;
	isWorkSummaryStatusReviewed: boolean;
	canCompleteWorkSummary: boolean;
	areAllWorkSummariesBilled: boolean;
	workOrderCode: string;
	alternativeWorkSummary: Set<WorkSummaryVM>;
	belongsToProject: boolean;
	subJobs: SiblingSubjobVM[];
	onReallocateAll: (subJob: SiblingSubjobVM) => void;
	handleJobReallocation: (subJob: SiblingSubjobVM, workSummaryDetailIds: number[]) => Promise<void>;
}

type Props = OwnProps;

const _reduceTotalBillableWork = (_acc: number, _curr: WorkSummaryVM) => {
	return _acc + (_curr.total ?? 0);
};

const WorkSummaryBillableWork: React.FC<Props> = (props) => {
	const {
		workSummaryDetails,
		workSummary,
		billingCodes,
		onBillingCodeSelect,
		onRowHighlight,
		highlightedGroup,
		onWorkSummaryStatusChange,
		canCompleteWorkSummary,
		areFRsReadOnly,
		isWorkSummaryStatusCompleted,
		isWorkSummaryStatusReviewed,
		areAllWorkSummariesBilled,
		workOrderCode,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
		belongsToProject,
		subJobs,
		handleJobReallocation,
		onReallocateAll,
	} = props;

	const [isUserGuideOpened, setIsUserGuideOpened] = React.useState(false);

	const onUserGuideClick = React.useCallback(() => {
		setIsUserGuideOpened(true);
	}, []);

	const onUserGuideModalClose = React.useCallback(() => {
		setIsUserGuideOpened(false);
	}, []);

	const onExportClick = React.useCallback(() => {
		const csvData = WorkSummaryVM.toCSVData(workSummary, belongsToProject);
		downloadCSV(csvData, `${workOrderCode}_billable_work.csv`);
	}, [belongsToProject, workOrderCode, workSummary]);

	const totalBillableWork = workSummary.reduce(_reduceTotalBillableWork, 0);
	const showHint = totalBillableWork === 0;

	const billableWorkClassName = 'field-report__work-summary__billable-work';
	const headerClassName = bemElement(billableWorkClassName, 'header');

	const isWorkSummaryReadonly = isWorkSummaryStatusCompleted || areFRsReadOnly || isWorkSummaryStatusReviewed;
	const isCompleteButtonDisabled = areFRsReadOnly || !areAllWorkSummariesBilled;

	return (
		<>
			<div className={billableWorkClassName}>
				<div className={bemElement(billableWorkClassName, 'legend')}>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
						<span className={bemElement('field-report__work-summary__billable-work__legend__single', 'empty-cell', { selected: true })} />
						Selected
					</span>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
						<span className={bemElement('field-report__work-summary__billable-work__legend__single', 'empty-cell', { suggested: true })} />
						Alternative billable work
					</span>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
						<span className={bemElement('field-report__work-summary__billable-work__legend__single', 'recently-used')}>
							<span className="icon-star" />
						</span>
						Recently used
					</span>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
						<span className={bemElement('field-report__work-summary__billable-work__legend__single', 'billable')}>
							<span className="icon-dollar" />
						</span>
						Included in calculation
					</span>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'single')}>
						<span className={bemElement('field-report__work-summary__billable-work__legend__single', 'non-billable')}>
							<span className="icon-dollar" />
						</span>
						Excluded from calculation
					</span>
					<span className={bemElement('field-report__work-summary__billable-work__legend', 'user-guide')}>
						<Button
							icon="help"
							label="User guide"
							onClick={onUserGuideClick}
							style="secondary"
						/>
					</span>
				</div>
				<div className={headerClassName}>
					<span className={bemElement(headerClassName, 'title')}>
						Billable Work
					</span>
					<Button
						disabled={!workSummary.length}
						icon="download"
						label="Export"
						onClick={onExportClick}
						style="link"
					/>
				</div>
				<BillableAndNonBillableWorkTable
					alternativeWorkSummary={alternativeWorkSummary}
					belongsToProject={belongsToProject}
					billingCodes={billingCodes}
					handleJobReallocation={handleJobReallocation}
					highlightedGroup={highlightedGroup}
					highlightedGroupKey={highlightedGroupKey}
					isBillable={true}
					isReadOnly={isWorkSummaryReadonly}
					onBillingCodeSelect={onBillingCodeSelect}
					onReallocateAll={onReallocateAll}
					onRowHighlight={onRowHighlight}
					subJobs={subJobs}
					workSummary={workSummary}
					workSummaryDetails={workSummaryDetails}
					workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
				/>
				<div className={bemElement('field-report__work-summary__billable-work', 'total')}>
					{showHint && (
						<span className={bemElement('field-report__work-summary__billable-work__total', 'hint')}>
							Please add a billing code to at least one row to calculate the total
						</span>
					)}
					{`Total ${dollarFormatter.format(totalBillableWork)}`}
				</div>
				<div className={bemElement(billableWorkClassName, 'review-actions')}>
					{isWorkSummaryStatusReviewed && canCompleteWorkSummary && (
						<Button
							disabled={isCompleteButtonDisabled}
							icon="check"
							label="Complete"
							onClick={onWorkSummaryStatusChange(WorkSummaryStatus.COMPLETED)}
							style="secondary"
						/>
					)}
					{!(isWorkSummaryStatusReviewed || isWorkSummaryStatusCompleted) && (
						<Button
							disabled={isWorkSummaryReadonly}
							icon="visibility_on"
							label="Review"
							onClick={onWorkSummaryStatusChange(WorkSummaryStatus.REVIEWED)}
							style="secondary"
						/>
					)}
					{!areFRsReadOnly && (isWorkSummaryStatusReviewed || isWorkSummaryStatusCompleted) &&
						<Button
							icon="edit"
							label="Edit"
							onClick={onWorkSummaryStatusChange(WorkSummaryStatus.OUTDATED)}
							style="secondary"
						/>
					}
				</div>
			</div>
			<UserGuideModal
				closeModal={onUserGuideModalClose}
				showModal={isUserGuideOpened}
			/>
		</>
	);
};

export default React.memo(WorkSummaryBillableWork);
