import { CUSTOMER_SERVICE_NAME } from '@acceligentllc/shared/constants/value';

import type WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { getPlainFullName } from '@acceligentllc/shared/utils/user';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type FieldReportBase from 'ab-domain/models/fieldReport/base';

export class W_FieldReport_GetWorkSummaryStatus_VM {
	status: WorkSummaryStatus;
	lastUpdatedAt: Nullable<string>;
	lastUpdatedBy: Nullable<string>;

	constructor(fieldReport: FieldReportBase) {
		this.status = fieldReport.workSummaryStatus;
		this.lastUpdatedAt = fieldReport.workSummaryLastUpdatedAt
			? TimeUtils.formatDate(fieldReport.workSummaryLastUpdatedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)
			: null;
		if (this.lastUpdatedAt) {
			this.lastUpdatedBy = fieldReport.workSummaryLastUpdatedBy
				? getPlainFullName(fieldReport.workSummaryLastUpdatedBy.user.firstName, fieldReport.workSummaryLastUpdatedBy.user.lastName)
				: CUSTOMER_SERVICE_NAME;
		} else {
			this.lastUpdatedBy = null;
		}
	}
}
