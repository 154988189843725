import type ItemBase from 'ab-domain/models/item/base';
import type ItemDepartmentBase from 'ab-domain/models/itemDepartment/base';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';

import * as BlobStorageUtils from '@acceligentllc/shared/utils/blobStorage';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type ItemVendorBase from 'ab-domain/models/itemVendor/base';
import type CrewTypeBase from 'ab-domain/models/crewType/base';
import type ItemItemCategoryLookupBase from 'ab-domain/models/itemItemCategoryLookup/base';

export class W_Item_FindById_VM {
	id: number;
	imageUrl: Nullable<string>;
	modelNumber: string;
	manufacturer: string;
	name: string;
	description: Nullable<string>;
	stockRegularly: boolean;
	itemCategories: W_Item_FindById_VM_Category[];
	departments: W_Item_FindById_VM_Department[];
	vendor: Nullable<W_Item_FindById_VM_Vendor>;
	crewType: Nullable<W_Item_FindById_VM_CrewType>;

	constructor(item: ItemBase) {
		this.id = item.id;
		this.imageUrl = item.imageUrl ? BlobStorageUtils.tryGetStorageUrlForSize(item.imageUrl, BlobStorageImageSizeContainer.SIZE_200X200) : null;
		this.name = item.name;
		this.manufacturer = item.manufacturer;
		this.modelNumber = item.modelNumber;
		this.itemCategories = item.itemItemCategoryLookup.map(W_Item_FindById_VM_Category._bulkConstructor);
		this.description = item.description;
		this.stockRegularly = item.stockRegularly;
		this.departments = item.itemDepartments?.map(W_Item_FindById_VM_Department._bulkConstructor);

		this.vendor = item.itemVendor ? new W_Item_FindById_VM_Vendor(item.itemVendor) : null;
		this.crewType = item.crewType ? {
			id: item.crewType.id,
			color: item.crewType.color,
			name: item.crewType.name,
		} : null;
	}
}

class W_Item_FindById_VM_Category {
	itemCategoryId: number;
	name: string;

	constructor(category: ItemItemCategoryLookupBase) {
		this.itemCategoryId = category.itemCategoryId;
		this.name = category.itemCategory.name;
	}

	static _bulkConstructor = (itemCategoryLookup: ItemItemCategoryLookupBase): W_Item_FindById_VM_Category => {
		return new W_Item_FindById_VM_Category(itemCategoryLookup);
	};
}

class W_Item_FindById_VM_Department {
	id: number;
	departmentId: number;
	name: string;
	locationName: string;
	locationId: number;
	includeDepartment: boolean;
	currentStock: number;
	minimumStock: number;
	maximumStock: number;

	constructor(itemDepartment: ItemDepartmentBase) {
		this.id = itemDepartment.id;
		this.departmentId = itemDepartment.departmentId;
		this.name = itemDepartment.department.name;
		this.locationName = itemDepartment.department.location.nickname;
		this.locationId = itemDepartment.department.location.id;
		this.includeDepartment = true;
		this.currentStock = +itemDepartment.quantity;
		this.minimumStock = itemDepartment.minimumQuantity;
		this.maximumStock = itemDepartment.maximumQuantity;
	}

	static _bulkConstructor = (itemDepartment: ItemDepartmentBase): W_Item_FindById_VM_Department => {
		return new W_Item_FindById_VM_Department(itemDepartment);
	};
}

class W_Item_FindById_VM_Vendor {
	id: number;
	vendorId: number;
	name: string;
	partNumber: string;
	url: Nullable<string>;
	packageType: Nullable<VendorPackageType>;
	packageQuantity: Nullable<string>;
	price: number;
	note: Nullable<string>;

	constructor(vendor: ItemVendorBase) {
		this.id = vendor.id;
		this.vendorId = vendor.vendorId;
		this.name = vendor.vendor.name;
		this.partNumber = vendor.partNumber;
		this.url = vendor.url;
		this.packageType = vendor.packageType;
		this.packageQuantity = vendor.packageSize;
		this.price = +vendor.price;
		this.note = vendor.note;
	}
}

class W_Item_FindById_VM_CrewType {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(crewType: CrewTypeBase) {
		this.id = crewType.id;
		this.color = crewType.color;
		this.name = crewType.name;
	}
}

