import * as React from 'react';
import type { FinalState } from 'react-table-6';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as WageRateActions from 'af-actions/wageRate';

import type { WageRateTableRowVM } from 'ab-viewModels/wageRate/tableRow.viewModel';

import CLIENT from 'af-constants/routes/client';

import { DEFAULT_WAGE_RATE_TYPE } from 'ab-common/constants/value';

import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import type { TabProps, Column, RowInfo, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import * as FormattingUtils from 'ab-utils/formatting.util';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	findAllForCompanyTable: typeof WageRateActions.findAllForCompanyTable;
	deleteWageRateFromTable: typeof WageRateActions.deleteWageRateFromTable;
	bulkDeleteWageRateFromTable: typeof WageRateActions.bulkDeleteWageRateFromTable;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

const UPDATED_COLUMN_WIDTH = 200;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the wage rate(s) will no longer be assignable to new Labor.
		<br />
		Labor already using it will not be affected.
		<br />
		This action cannot be undone.
	</>
);

class WageRateList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Wage Rate' }];

	static readonly COLUMNS: Column<WageRateTableRowVM>[] = [
		{
			Header: 'Labor Type',
			accessor: 'type',
			Cell: ({ original }) => original.type,
		},
		{
			Header: 'Wage Classification',
			accessor: 'wageClassification',
			Cell: ({ original }) => original.wageClassification,
		},
		{
			Header: 'Hourly Rate',
			accessor: 'hourlyRate',
			Cell: ({ original }) => FormattingUtils.moneyNormalizer(original.hourlyRate),
		},
		{
			Header: 'Updated',
			width: UPDATED_COLUMN_WIDTH,
			headerClassName: 'align-right',
			accessor: 'updatedAt',
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
		},
	];

	static deleteWageRateModalTitle = () => 'Are you sure you want to delete this Wage Rate?';
	static deleteWageRateModalBody = () => DELETE_CONFIRMATION_BODY;
	static deleteWageRateModalText = () => 'Delete Wage Rate';

	static hideWageRateOption = (original: WageRateTableRowVM) => original.type === DEFAULT_WAGE_RATE_TYPE;

	static getRowClassName = (state: FinalState<WageRateTableRowVM>, rowInfo: RowInfo<WageRateTableRowVM>) => {
		return rowInfo.original.type === DEFAULT_WAGE_RATE_TYPE ? DEFAULT_WAGE_RATE_TYPE.toLowerCase() : '';
	};

	deleteWageRate = async (original: WageRateTableRowVM) => {
		const { deleteWageRateFromTable } = this.props;
		await deleteWageRateFromTable(original.id);
	};

	editWageRate = async (original: WageRateTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.WAGE_RATE.EDIT(original.id.toString(), orgAlias, companyName));
	};

	previewWageRate = async (original: WageRateTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.WAGE_RATE.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	onRowClick = ({ original }: { original: WageRateTableRowVM; }) => {
		if (original.id) {
			this.previewWageRate(original);
		}
	};

	tabs = (): TabProps<WageRateTableRowVM>[] => {
		const {
			bulkDeleteWageRateFromTable,
			findAllForCompanyTable,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.LINK,
				hasPermission: true,
				label: 'CSV Import',
				icon: 'upload',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.WAGE_RATE.BULK_UPLOAD(orgAlias, companyName)),
			},
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Wage Rate',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.WAGE_RATE.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Wage Rate',
				columns: WageRateList.COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Wage Rate',
				getRowClassName: WageRateList.getRowClassName,
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDeleteWageRateFromTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: this.previewWageRate,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: this.editWageRate,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						hide: WageRateList.hideWageRateOption,
						action: this.deleteWageRate,
						hasModal: true,
						modalTitle: WageRateList.deleteWageRateModalTitle,
						modalBody: WageRateList.deleteWageRateModalBody,
						modalText: WageRateList.deleteWageRateModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={WageRateList.BREADCRUMBS} />
				<Table
					tableName={TableNameEnum.WAGE_RATE}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTable: WageRateActions.findAllForCompanyTable,
		deleteWageRateFromTable: WageRateActions.deleteWageRateFromTable,
		bulkDeleteWageRateFromTable: WageRateActions.bulkDeleteWageRateFromTable,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(WageRateList);
