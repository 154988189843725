import * as React from 'react';
import { Link } from 'react-router-dom';

import type UserPermission from '@acceligentllc/shared/enums/userPermission';

import { isAllowed } from 'ab-utils/auth.util';

import ValidationState from 'ab-enums/validationState.enum';

export interface Props {
	label: string;
	icon?: string;
	url?: string;
	visibleFor: Metadata; // PagePermissionType | PagePermissionsTree
	companyPermissions?: string[];
	isCompanyAdmin?: boolean;
	userRole?: UserPermission;
	errorKey?: string;
	errors?: { [errorKey: string]: { validationState: ValidationState; }; };
	changeLabel?: (hash: string) => void;
	hash?: string;
	hasStates?: boolean;
	isActive?: boolean;
	counter?: number;
	isLoading?: boolean;
	onClick?: () => void;
	isForceHidden?: boolean;
	id?: string;
}

const FSNLink: React.FC<Props> = (props: Props) => {
	const {
		changeLabel,
		companyPermissions,
		counter,
		errorKey,
		errors,
		hash,
		hasStates = false,
		icon,
		id,
		isActive = false,
		isCompanyAdmin,
		isForceHidden = false,
		isLoading,
		label,
		onClick,
		url,
		userRole,
		visibleFor,
	} = props;

	const isHidden = React.useMemo(
		() => isForceHidden || !isAllowed(visibleFor, companyPermissions, isCompanyAdmin, userRole),
		[isForceHidden, visibleFor, companyPermissions, isCompanyAdmin, userRole]
	);

	const stateIcon = React.useMemo(() => {
		if (!hasStates) {
			return null;
		}

		const state: ValidationState = (errorKey ? errors?.[errorKey]?.validationState : null) ?? ValidationState.INCOMPLETE;
		let validationIcon = '/images/sidebar/ic_state_incomplete.svg';

		if (isLoading) {
			validationIcon = '/images/sidebar/ic_state_default.svg';
		} else if (state === ValidationState.INVALID) {
			validationIcon = '/images/sidebar/ic_state_error.svg';
		} else if (state === ValidationState.COMPLETE) {
			validationIcon = '/images/sidebar/ic_state_complete.svg';
		}

		return (<span className="floating-sidenav-icon" style={{ backgroundImage: `url(${validationIcon})` }} />);

	}, [hasStates, errors, errorKey, isLoading]);

	const className = React.useMemo(() => {
		let _className = 'link';
		_className += hasStates ? ' connected' : ' standalone';
		_className += isActive ? ' active' : '';
		_className += icon ? ' with-icon' : '';
		return _className;
	}, [hasStates, isActive, icon]);

	const onHashClick = React.useCallback(() => {
		if (changeLabel && hash) {
			changeLabel(hash);
		}

		if (onClick) {
			onClick();
		}
	}, [changeLabel, onClick, hash]);

	if (isHidden) {
		return null;
	}

	const labelElement = (
		<span>
			{stateIcon}
			{icon && <span className={icon} />}
			{label}
			{!!counter && <span className="counter-bubble">{counter}</span>}
		</span>
	);
	return (
		<li className={className} id={id}>
			{hash
				? <a href={`#${hash}`} onClick={onHashClick}>{labelElement}</a>
				: <Link onClick={onClick} to={url!}>{labelElement}</Link>
			}
		</li>
	);
};

export default FSNLink;
