import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type { ReportTypeRM } from 'ab-requestModels/reportType/reportType.requestModel';

export function validate(values: ReportTypeRM) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	return errors;
}
