import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import { bemElement } from 'ab-utils/bem.util';

interface Props {
	closeModal: () => void;
	additionalMessage?: string;
}

const SuccessStep: React.FC<Props> = (props: Props) => {
	const { closeModal, additionalMessage } = props;

	return (
		<div className={bemElement('public-report__mobile-signature-modal', 'success-step')}>
			<div className={bemElement('public-report__mobile-signature-modal__success-step', 'title')}>
				Report Signed
			</div>
			<span className={`${bemElement('public-report__mobile-signature-modal__success-step', 'icon')} icon-check_circle text-green`} />
			<div className={bemElement('public-report__mobile-signature-modal__success-step', 'description')}>
				Your signature has been successfully submitted. <br />
				{additionalMessage}
			</div>
			<Button
				label="Close"
				onClick={closeModal}
				style="secondary"
			/>
		</div>
	);
};

export default SuccessStep;
