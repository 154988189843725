import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import type LocationRequestModel from 'ab-requestModels/location.requestModel';

import Pills from 'af-components/Pills';

import DepartmentInput from './DepartmentInput';

type Props = WrappedFieldArrayProps<LocationRequestModel['departments'][0]>;

const MODAL_BODY = (item: LocationRequestModel['departments'][0]) => (
	<>
		You are about to remove <b>{item.name}</b> department.
		Are you sure you want to continue?
	</>
);

const Department = (props: Props) => {
	const { fields } = props;

	const [showInput, setShowInput] = React.useState(false);

	const onAdd = React.useCallback(() => {
		setShowInput(true);
	}, []);
	const onClose = React.useCallback(() => {
		setShowInput(false);
	}, []);
	const onSubmit = React.useCallback((event: { name: string; }) => {
		fields.push(event);
		setShowInput(false);
	}, [fields]);
	const onRemove = React.useCallback((index: number) => {
		fields.remove(index);
	}, [fields]);

	return (
		<>
			Departments
			<Pills
				destructiveAction={onRemove}
				emptyLabel="No departments found"
				items={fields}
				labelKey="name"
				modalBody={MODAL_BODY}
				modalTitle="Remove Department"
			/>
			<div className="flex">
				{showInput ? (
					<DepartmentInput
						close={onClose}
						onSubmit={onSubmit}
					/>
				) : (
					<Button
						icon="plus"
						label="Add Department"
						onClick={onAdd}
						style="link"
					/>
				)}
			</div>
		</>
	);
};

export default Department;
