import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type UserPermission from '@acceligentllc/shared/enums/userPermission';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type MemberInviteEnum from '@acceligentllc/shared/enums/memberInvite';
import type SystemNotificationType from '@acceligentllc/shared/enums/systemNotificationType';
import type { TimeOptionValues } from '@acceligentllc/shared/enums/timeOption';

import * as BlobStorageUtil from '@acceligentllc/shared/utils/blobStorage';

import type AccountBase from 'ab-domain/models/account/base';
import type PermissionBase from 'ab-domain/models/permission/base';
import type UserBase from 'ab-domain/models/user/base';
import type OrganizationBase from 'ab-domain/models/organization/base';
import type SystemNotificationBase from 'ab-domain/models/systemNotification/base';
import { getStorageUrl } from '@acceligentllc/shared/utils/blobStorage';

import { LoginMethod } from 'ab-enums/auth.enum';

import * as BlobStorageUtil2 from 'ab-utils/blobStorage.util';

class W_Authentication_EmailLogin_VM_SystemNotification {
	id: number;
	type: SystemNotificationType;
	content: string;
	startsAt: Date;
	startsAtDate?: string; // only on unparsed version
	startsAtTime?: TimeOptionValues; // only on unparsed version
	endsAt: Date;
	endsAtDate?: string; // only on unparsed version
	endsAtTime?: TimeOptionValues; // only on unparsed version

	public static async initialize(systemNotification: SystemNotificationBase) {
		return {
			id: systemNotification.id,
			type: systemNotification.type,
			content: systemNotification.content,
			startsAt: systemNotification.startsAt,
			endsAt: systemNotification.endsAt,
		};
	}

	public static async bulkInitialize(systemNotification: SystemNotificationBase[]) {
		return await Promise.all(systemNotification.map(W_Authentication_EmailLogin_VM_SystemNotification.initialize));
	}
}

class W_Authentication_EmailLogin_VM_OrganizationData {
	organizationId: number;
	name: string;
	alias: string;
	/** Can be empty if accounts not passed */
	companies: W_Authentication_EmailLogin_VM_CompanyData[];
	isPlatformAdmin: boolean;

	public static async initialize(organization: OrganizationBase, accounts: AccountBase[]): Promise<W_Authentication_EmailLogin_VM_OrganizationData> {
		const companies = await Promise.all(
			(accounts ?? [])
				.map(async (_account: AccountBase) => await W_Authentication_EmailLogin_VM_CompanyData.initialize(_account))
		);

		return {
			organizationId: organization.id,
			name: organization.name,
			alias: organization.alias,
			isPlatformAdmin: organization.isPlatformAdmin,
			companies: companies
				.sort((_company1: W_Authentication_EmailLogin_VM_CompanyData, _company2: W_Authentication_EmailLogin_VM_CompanyData) =>
					(_company1.name.localeCompare(_company2.name))),
		};
	}
}

class W_Authentication_EmailLogin_VM_UserData {
	/** -1 if platform admin */
	id: number;
	firstName: string;
	lastName: string;
	token: Nullable<string>;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	isFinalized: boolean;
	status: MemberInviteEnum;
	role: UserPermission;
	imageUrl: string;
	offlinePin: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	digitalSignatureUrl: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	showCreateDigitalSignature: boolean;
	isPasswordExpired: boolean;
	loginMethod: LoginMethod;
	passwordResetRequired: boolean;
	activeInLMS: boolean;

	private constructor(user: UserBase, token?: string) {
		this.id = user.id;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.token = token ?? null;
		this.email = user.email;
		this.phoneNumber = user.phoneNumber;
		this.countryCode = user.countryCode;
		this.isFinalized = user.isFinalizedByEmail;
		this.status = user.status;
		this.role = user.role;
		this.offlinePin = user.offlinePin;
		this.isDigitalSignatureEnabled = user.isDigitalSignatureEnabled;
		this.showCreateDigitalSignature = user.showCreateDigitalSignature;
		this.isPasswordExpired = user.isPasswordExpired();
		this.loginMethod = LoginMethod.EMAIL;
		this.passwordResetRequired = user.passwordResetRequired;
		this.activeInLMS = user.activeInLMS;

		const signature = user.digitalSignature;
		this.digitalSignatureUrl = (user.isDigitalSignatureEnabled && signature)
			? getStorageUrl(signature.signatureAttachment.storageContainer, signature.signatureAttachment.storageName)
			: null;
		this.digitalSignatureId = (user.isDigitalSignatureEnabled && signature)
			? signature.id
			: null;

		const result = user.imageUrl && BlobStorageUtil.parseStorageUrl(user.imageUrl);
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}
	}

	public static async initialize(user: UserBase, token?: string) {
		return new W_Authentication_EmailLogin_VM_UserData(user, token);
	}
}

class W_Authentication_EmailLogin_VM_CompanyData {
	id: number;
	name: string;
	permissions: string[];
	accountId: number;
	accountTemplate: AccountPermissionTemplate;
	logo: string;
	isCompanyAdmin: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	assignableAsAccounting: boolean;
	complianceEhsUrl: Nullable<string>;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	purchaseOrderNumberPrefix: Nullable<string>;
	customPDFHeaderUrl: Nullable<string>;
	website: Nullable<string>;
	location: Nullable<string>;

	public static async initialize(account: AccountBase): Promise<W_Authentication_EmailLogin_VM_CompanyData> {
		const parseResult = account.company.imageUrl ? BlobStorageUtil.parseStorageUrl(account.company.imageUrl) : null;
		const customPDFHeaderParseResult = account.company.customPDFHeaderUrl ? BlobStorageUtil.parseStorageUrl(account.company.customPDFHeaderUrl) : null;

		return {
			name: account.company.name,
			id: account.company.id,
			permissions: account.permissions.map((_permission: PermissionBase) => (_permission.permission.toString())),
			accountId: account.id,
			accountTemplate: account.accountTemplate,
			logo: parseResult ? BlobStorageUtil.getStorageUrl(parseResult.directories, parseResult.filename) : '',
			isCompanyAdmin: account.isCompanyAdmin,
			assignableToWorkOrder: account.assignableToWorkOrder,
			assignableAsSuperintendent: account.assignableAsSuperintendent,
			assignableAsProjectManager: account.assignableAsProjectManager,
			assignableAsQAQC: account.assignableAsQAQC,
			assignableAsManagement: account.assignableAsManagement,
			assignableAsTechnician: account.assignableAsTechnician,
			assignableAsAccounting: account.assignableAsAccounting,
			complianceEhsUrl: account.company.complianceEhsUrl,
			areWorkRequestsEnabled: account.company.areWorkRequestsEnabled,
			arePortalFunctionalitiesEnabled: account.company.arePortalFunctionalitiesEnabled,
			isSendingInvoiceNotificationsEnabled: account.company.isSendingInvoiceNotificationsEnabled,
			purchaseOrderNumberPrefix: account.company.purchaseOrderNumberPrefix,
			customPDFHeaderUrl: customPDFHeaderParseResult ? await BlobStorageUtil2.generatePresignedGetUrl(customPDFHeaderParseResult.directories, customPDFHeaderParseResult.filename) : '',
			website: account.company.website,
			location: account.company.primaryAddress?.street,
		};
	}
}

/** NOTE: This view model is kept in localstorage on FE. Do not send entire User data from here */
export class W_Authentication_EmailLogin_VM {
	organization: W_Authentication_EmailLogin_VM_OrganizationData;
	user: W_Authentication_EmailLogin_VM_UserData;
	systemNotifications: W_Authentication_EmailLogin_VM_SystemNotification[];

	public static async initialize(user: UserBase, token: string, systemNotifications: SystemNotificationBase[] = []): Promise<W_Authentication_EmailLogin_VM> {
		const _user = await W_Authentication_EmailLogin_VM_UserData.initialize(user, token);
		const _organization = await W_Authentication_EmailLogin_VM_OrganizationData.initialize(user.organization, user.accounts);
		const _notifications = await W_Authentication_EmailLogin_VM_SystemNotification.bulkInitialize(systemNotifications);

		return {
			user: _user,
			organization: _organization,
			systemNotifications: _notifications,
		};
	}
}
