import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Cropper = require('cropperjs');
import type CropperTyping from 'cropperjs';

import { ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_STR, IMAGE_FILE_TYPES } from '@acceligentllc/shared/enums/fileType';

import Dropzone from 'af-components/Dropzone';
import FileUploadMessage from 'af-components/Dropzone/FileUploadMessage';
import CustomModal from 'af-components/CustomModal';

import { bemBlock } from 'ab-utils/bem.util';

const INITIAL_FULL_CROP = 1;
const INITIAL_CROP = 0.8;

// check https://github.com/fengyuanchen/cropperjs/blob/main/README.md#viewmode for details
const VIEW_MODE_2 = 2;
const VIEW_MODE_3 = 3;

interface Props {
	showModal: boolean;
	onSave: (imageFile: File, imageUrl: string) => void;
	freeAspectRatio?: boolean;
	disableActions?: boolean;
	closeModal: () => void;
}

interface State {
	image: string | File | null;
	imageUrl: string;
	ready: boolean;
	uploadError: string | null;
}

const initialState: State = {
	image: null,
	imageUrl: '',
	ready: false,
	uploadError: null,
};

export default class ImageUpload extends React.PureComponent<Props, State> {
	state: State = initialState;

	private _imageRef: Nullable<React.RefObject<HTMLImageElement>> = null;
	private _cropper: Nullable<CropperTyping> = null;
	private _fileName = '';

	constructor(props: Props) {
		super(props);
		this._imageRef = React.createRef();
	}

	componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;

		if (showModal && !prevProps.showModal && this._imageRef?.current) {
			// modal opened
			this._cropper = this.createCropper(this._imageRef.current);
		} else if (!showModal && prevProps.showModal) {
			// modal closed
			this.setState(() => initialState);
			this._imageRef = React.createRef();
			this._cropper?.destroy();
		}
	}

	onDrop = (accepted: File[]) => {
		const [file] = accepted;

		if (file && ALLOWED_IMAGE_TYPES.includes(file.type)) {
			this._fileName = file.name;
			const reader = new FileReader();
			reader.onloadend = () => {
				const _result = reader.result as string;
				this.setState(() => ({
					image: file,
					imageUrl: _result,
				}), () => {
					if (!this._imageRef?.current) {
						throw new Error('Image reference not found');
					}

					this._imageRef.current.onload = () => {
						this._cropper = this.createCropper(this._imageRef!.current!);
					};
					this._imageRef.current.src = _result;
				});
			};
			reader.readAsDataURL(file);
		} else {
			this.setState(() => ({ uploadError: `Invalid file format. Only ${ALLOWED_IMAGE_TYPES_STR} allowed.` }));
		}
	};

	save = () => {
		if (this._cropper) {
			this._cropper.getCroppedCanvas()?.toBlob((blob) => {
				const imageFile = new File([blob!], this._fileName);
				const reader = new FileReader();
				reader.onloadend = () => {
					this.props.onSave(imageFile, reader.result as string);
					this.hide();
				};
				reader.readAsDataURL(imageFile);
			});
		}
	};

	rotateLeft = () => this._cropper?.rotate(-90);

	rotateRight = () => this._cropper?.rotate(90);

	zoomOut = () => this._cropper?.zoom(-0.1);

	zoomIn = () => this._cropper?.zoom(0.1);

	createCropper = (imageElement: HTMLImageElement) => {
		const { freeAspectRatio, disableActions } = this.props;
		if (imageElement) {
			return new Cropper(imageElement, {
				aspectRatio: freeAspectRatio ? null : 1,
				autoCropArea: disableActions ? INITIAL_FULL_CROP : INITIAL_CROP,
				viewMode: freeAspectRatio ? VIEW_MODE_2 : VIEW_MODE_3,
				dragMode: 'move',
				movable: !disableActions,
				rotatable: !disableActions,
				scalable: !disableActions,
				zoomable: !disableActions,
				checkOrientation: true,
				ready: this.onCropperReady,
			});
		}
	};

	hide = () => {
		const { closeModal } = this.props;
		closeModal();
	};

	onCropperReady = () => this.setState({ ready: true });

	renderDropzone = () => {
		const { uploadError } = this.state;

		return (
			<Dropzone
				allowedTypes={{ 'image/*': IMAGE_FILE_TYPES }}
				className={bemBlock('dropzone-element', ['column'])}
				onDrop={this.onDrop}
			>
				<FileUploadMessage error={uploadError} />
			</Dropzone>
		);
	};

	renderCropper = () => {
		const { disableActions } = this.props;
		const { imageUrl } = this.state;

		return (
			<div className="crop-container">
				<img ref={this._imageRef} src={imageUrl} />
				{!disableActions &&
					<div className="cropper-actions">
						<div className="cropper-action-group">
							<a onClick={this.zoomOut} role="button"><span className="icon-zoom_out a-icon20 text-black" /></a>
							<a onClick={this.zoomIn} role="button"><span className="icon-zoom_in a-icon20 text-black" /></a>
						</div>
						<div className="cropper-action-group">
							<a onClick={this.rotateLeft} role="button"><span className="icon-rotate_left a-icon20 text-black" /></a>
							<a onClick={this.rotateRight} role="button"><span className="icon-rotate_right a-icon20 text-black" /></a>
						</div>
					</div>
				}
			</div>
		);
	};

	render() {
		const { showModal } = this.props;
		const { ready, image } = this.state;

		return (
			<CustomModal
				closeModal={this.hide}
				id="image-upload"
				showModal={showModal}
			>
				<div className={`modal-upload-container ${!ready ? '--loading' : ''}`}>
					{!image ?
						this.renderDropzone() :
						this.renderCropper()
					}
					<div className="modal-upload-action">
						<Button
							label="Cancel"
							onClick={this.hide}
							style="secondary"
						/>
						<Button
							disabled={!image}
							label="Save"
							onClick={this.save}
							style="primary"
						/>
					</div>
				</div>
			</CustomModal>
		);
	}
}
