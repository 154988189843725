import * as React from 'react';

import type { ColorPalette } from '@acceligentllc/shared/enums/color';

import * as ColorUtils from 'ab-utils/color.util';

interface Props {
	color: ColorPalette | string | null;
	text: string | JSX.Element | null;
	className?: string;
}

export default ({ color, text, className }: Props): JSX.Element => {
	let labelClassName = 'label-with-color';
	labelClassName = className ? `${labelClassName} ${className}` : labelClassName;

	return (
		<div className={labelClassName}>
			<span className={`color-square ${ColorUtils.getColorBackgroundClass(color, true)}`} />
			<span>{text}</span>
		</div>
	);
};
