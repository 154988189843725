import * as React from 'react';

import { resolveBlockDisplayIndex } from 'ab-utils/fieldReport.util';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import FieldReportBlockField from './FieldReportBlockField';
import styles from './styles.module.scss';

type FieldReportBlockFieldVM = FieldReportTypeSidePanelVM['fieldReportBlocks'][0]['fieldReportBlockFields'][0];

type Props = {
	fieldReportBlock: FieldReportTypeSidePanelVM['fieldReportBlocks'][0];
	instanceCount: number;
	segmentCount: number;
};

const renderCompletionField = (completed: boolean) => {
	return (
		<div className={styles['side-panel-modal__completed-field']}>
			<span>Completed: </span>
			<b>{completed ? 'Yes' : 'No'}</b>
		</div>
	);
};

const FieldReportBlockSidePanelModal: React.FC<Props> = ({ fieldReportBlock, instanceCount, segmentCount }) => {
	const [showFields, setShowFields] = React.useState(true);
	const onHeaderClick = React.useCallback(() => setShowFields(!showFields), [showFields]);

	const fieldReportBlockFieldsMapper = React.useCallback((fieldReportBlockField: FieldReportBlockFieldVM) => {

		return (
			<FieldReportBlockField fieldReportBlockField={fieldReportBlockField} isRepeatable={false} />
		);
	}, []);

	const fieldReportRepeatableBlockFieldsMapper = React.useCallback((repeatableIndex: number) =>
		(fieldReportBlockField: FieldReportBlockFieldVM) => {
			return (
				<FieldReportBlockField fieldReportBlockField={fieldReportBlockField} isRepeatable={true} repeatableIndex={repeatableIndex} />
			);
		}, []);

	const blockDisplayIndex = React.useMemo(() =>
		(fieldReportBlock.instanceIndex !== null
			&& fieldReportBlock.segmentIndex !== null
			&& fieldReportBlock.index !== null)
			? resolveBlockDisplayIndex(
				fieldReportBlock.instanceIndex ?? 0,
				fieldReportBlock.segmentIndex ?? 0,
				fieldReportBlock.index ?? 0,
				instanceCount,
				segmentCount
			)
			: '',
		[fieldReportBlock.index, fieldReportBlock.instanceIndex, fieldReportBlock.segmentIndex, instanceCount, segmentCount]);

	const renderBlockName = React.useCallback(() => {
		const headerClassName = fieldReportBlock.reportBlock.isMain ? [styles['side-panel-modal__block__header-main']] : [styles['side-panel-modal__block__header-secondary']];
		const headerTitle = `${fieldReportBlock.reportBlock.name} ${blockDisplayIndex ?? ''}`;
		const titleClassName = styles['side-panel-modal__block__title'];
		const iconName = showFields ? 'icon-up' : 'icon-down';

		return (
			<div className={headerClassName.join(' ')} onClick={onHeaderClick}>
				<span className={titleClassName}>
					{headerTitle}
				</span>
				<span className={iconName} />
			</div>
		);
	}, [blockDisplayIndex, fieldReportBlock.reportBlock.isMain, fieldReportBlock.reportBlock.name, onHeaderClick, showFields]);

	const containerClassName = React.useMemo(() => {
		const containerStyles: string[] = [];
		if (!fieldReportBlock.segmentIndex) {
			containerStyles.push(styles['side-panel-modal__block-container-primary']);
			if (fieldReportBlock.reportBlock.isMain) {
				containerStyles.push(styles['side-panel-modal__block-container-primary--main']);
			}
		} else {
			containerStyles.push(styles['side-panel-modal__block-container-secondary']);
			if (fieldReportBlock.reportBlock.isMain) {
				containerStyles.push(styles['side-panel-modal__block-container-secondary--main']);
			}
		}
		return containerStyles.join(' ');
	}, [fieldReportBlock.reportBlock.isMain, fieldReportBlock.segmentIndex]);

	if (fieldReportBlock.reportBlock.isRepeating) {
		const repeatableBlocks: JSX.Element[] = [];
		for (let index = 0; index < fieldReportBlock.repeatingCount; index++) {
			repeatableBlocks.push(
				<div className={styles['side-panel-modal__block']} key={`${fieldReportBlock.id}#${index}`} >
					{fieldReportBlock.fieldReportBlockFields.map(fieldReportRepeatableBlockFieldsMapper(index))}
				</div>
			);
		}

		if (fieldReportBlock.reportBlock.hasCompletionStatus) {
			repeatableBlocks.push((
				<div className={styles['side-panel-modal__block']} key={`${fieldReportBlock.id}#completed`} >
					{renderCompletionField(fieldReportBlock.completed)}
				</div>
			));
		}

		return (
			<div className={containerClassName}>
				{renderBlockName()}
				{showFields && (
					<div className={styles['side-panel-modal__block--repeating']}>
						{repeatableBlocks}
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={containerClassName}>
			{renderBlockName()}
			{showFields && (
				<div className={styles['side-panel-modal__block']} key={fieldReportBlock.id} >
					{fieldReportBlock.fieldReportBlockFields.map(fieldReportBlockFieldsMapper)}
					{fieldReportBlock.reportBlock.hasCompletionStatus && renderCompletionField(fieldReportBlock.completed)}
				</div>
			)}
		</div>
	);
};

export default React.memo(FieldReportBlockSidePanelModal);
