import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

interface OwnProps {
	/** defaults to "Submit", should be probably changed not to have default value */
	label?: string;
	/** defaults to primary */
	variant?: 'primary' | 'secondary' | 'danger';
	disabled?: boolean;
	reduxFormSubmitting?: boolean;
	/** often form name value */
	submitKey?: Nullable<string>;
	/** Click handler. Will not trigger if disabled or submitting. */
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	/** Optional custom styling that can be applied on top of default styling */
	className?: string;
	size?: 'small' | 'regular' | 'big';
	/** Should button stretch to fill its parent element in full width? */
	fullWidth?: boolean;
	/** Content of a tooltip */
	tooltip?: JSX.Element | string;
	icon?: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class SubmitButton extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		disabled: false,
		label: 'Submit',
		variant: 'primary',
		submitKey: null,
		className: '',
	};

	onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const { onClick, isSubmitting } = this.props;
		if (onClick && !isSubmitting) {
			onClick(e);
		} else if (!onClick && isSubmitting) {
			e.preventDefault();
		}
	};

	render() {
		const {
			variant,
			className,
			disabled,
			isFormValid,
			isSubmitting,
			label,
			onClick,
			size,
			fullWidth,
			tooltip,
			icon,
		} = this.props;

		const isDisabled = !!disabled || !isFormValid;

		return (
			<Button
				customStyleClass={className}
				disabled={isDisabled}
				fullWidth={fullWidth}
				icon={icon}
				label={label}
				onClick={isDisabled || isSubmitting ? undefined as unknown as () => void : onClick as () => void}
				size={size}
				style={variant!}
				submitting={isSubmitting}
				tooltip={tooltip}
				type="submit"
			/>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { http: { submitting }, form } = state;
	const { submitKey, reduxFormSubmitting } = ownProps;

	const formSyncErrors = submitKey ? form[submitKey]?.syncErrors : null;
	const isFormValid = !!formSyncErrors ? Object.keys(formSyncErrors).length === 0 : true;
	const isSubmitting = submitKey ? submitting.includes(submitKey) : reduxFormSubmitting;

	return {
		isFormValid,
		isSubmitting,
	};
}

const connector = connect(mapStateToProps);

export default connector(SubmitButton);
