import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { bemElement } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';

import { BLOCK_FIELD_LIST_ID } from '../../values';

import FieldInfoDraggable from '../../../Shared/Draggable/DraggableField';
import type { ReportBlockFieldFormModel } from '../../../Shared/formModel';
import { Button } from '@acceligentllc/storybook';

const MAX_COLSPAN_BREACHED_TOOLTIP_MESSAGE =
	<>
		Maximum field size sum for tables:
		<br />
		&emsp;- Inline table : 8
		<br />
		&emsp;- Appendix table : 16
		<br />
		Field size values:
		<br />
		&emsp;- Very small/Small : 1
		<br />
		&emsp;- Medium : 2
		<br />
		&emsp;- Large : 3
		<br />
		&emsp;- Largest: Max
	</>;

const INCOMPATIBLE_FIELDS_TOOLTIP_MESSAGE =
	<>
		Compatible fields for inline and appendix tables:
		<br />
		&emsp;- Unique ID
		<br />
		&emsp;- Numeric
		<br />
		&emsp;- Textual
		<br />
		&emsp;- Yes/No
		<br />
		&emsp;- Calculated
		<br />
		&emsp;- Info
		<br />
		&emsp;- Address
		<br />
		&emsp;- Dropdown List
		<br />
		&emsp;- Time
	</>;

interface OwnProps {
	fieldIds: string[];
	fieldsByIdMap: Nullable<{ [fieldId: string]: ReportBlockFieldFormModel; }>;
	isDraggable: boolean;
	openFieldModal: (id: Nullable<string>) => void;
	removeField: (id: string) => void;
	upsertField: (form: ReportBlockFieldFormModel, fieldIndex: Nullable<number>) => void;
	maxColumnspanBreached: boolean;
	hasIncompatibleFields: boolean;
}

type Props = OwnProps;

const BlockInfo: React.FC<Props> = (props) => {
	const {
		openFieldModal,
		fieldIds,
		fieldsByIdMap,
		upsertField,
		removeField,
		isDraggable,
		maxColumnspanBreached,
		hasIncompatibleFields,
	} = props;

	const shouldShowSubtitle = React.useMemo(() => (isDraggable && !fieldIds.length), [fieldIds.length, isDraggable]);

	const openAddFieldModal = React.useCallback(() => openFieldModal(null), [openFieldModal]);
	const openEditFieldModal = React.useCallback((id: string) => openFieldModal(id), [openFieldModal]);

	const toggleFieldIsVisibleToCustomer = React.useCallback((id: string) => {
		const field = fieldsByIdMap?.[id];
		if (!field) {
			return;
		}
		upsertField({ ...field, isVisibleToCustomer: !field.isVisibleToCustomer }, null);
	}, [fieldsByIdMap, upsertField]);

	const renderNewFieldButton = React.useCallback(() => {
		return (
			<Button
				icon="plus"
				label="Add New Field"
				onClick={openAddFieldModal}
				style="link"
			/>
		);
	}, [openAddFieldModal]);

	const renderDraggableField = React.useCallback((fieldId: string, index: number) => {
		const field = fieldsByIdMap?.[fieldId];
		if (!field) {
			return null;
		}

		return (
			<FieldInfoDraggable
				fieldId={field.virtualId}
				fieldIsVisibleToCustomer={field.isVisibleToCustomer}
				fieldName={field.name}
				fieldType={field.fieldType}
				hasTooltip={field.hasTooltip}
				index={index}
				key={field.id ?? `${field.name}@${index}`}
				onFieldClick={openEditFieldModal}
				onFieldVisibleToCustomerIconClick={toggleFieldIsVisibleToCustomer}
				operation={field.operationType}
				options={field.options}
				removeField={removeField}
				tooltipText={field.tooltipText}
				unit={field.unit}
			/>
		);
	}, [openEditFieldModal, removeField, toggleFieldIsVisibleToCustomer, fieldsByIdMap]);

	const renderList = React.useCallback((isDraggingOver: boolean) => {
		// 32px for a field + 16px for margin
		// 2px to mitigate the border appearing and disappearing
		const listHeight = ((fieldIds.length + 1) * 48) + (isDraggingOver ? 2 : 0);

		const containerClassName = bemElement(
			'report-block-form',
			'droppable-container',
			{ empty: !fieldIds.length, hover: isDraggingOver && !!fieldIds.length }
		);

		return (
			<div
				className={containerClassName}
				onClick={!fieldIds.length ? openAddFieldModal : undefined}
				style={{ height: listHeight }}
			>
				{!!fieldIds.length && fieldIds.map(renderDraggableField)}
				{shouldShowSubtitle &&
					<>
						{renderNewFieldButton()}
						<span className="report-block-form__droppable-container__action-description">Drag and drop Field Type</span>
					</>
				}
			</div>
		);
	}, [fieldIds, openAddFieldModal, renderDraggableField, renderNewFieldButton, shouldShowSubtitle]);

	return (
		<>
			<div className="report-block-form">
				{
					maxColumnspanBreached &&
					<>
						<div className="report-block-form__warning">
							<Tooltip message={MAX_COLSPAN_BREACHED_TOOLTIP_MESSAGE}>
								<span className="icon-warning text-red" />
								&emsp;You have reached maximum sum of field sizes
							</Tooltip>
						</div>
					</>
				}
				{
					hasIncompatibleFields &&
					<>
						<div className="report-block-form__warning">
							<Tooltip message={INCOMPATIBLE_FIELDS_TOOLTIP_MESSAGE}>
								<span className="icon-warning text-red" />
								&emsp;Some fields are incompatible
							</Tooltip>
						</div>
					</>
				}
				<div className="report-block-form__title">
					Fields
				</div>
				<Droppable direction="vertical" droppableId={BLOCK_FIELD_LIST_ID}>
					{(droppableProvided, snapshot) => (
						<div ref={droppableProvided.innerRef}>
							{renderList(snapshot.isDraggingOver)}
							{droppableProvided.placeholder}
						</div>
					)}
				</Droppable>
				{!shouldShowSubtitle &&
					<div className="report-block-form__add-button-container">
						{renderNewFieldButton()}
					</div>
				}
			</div>
		</>
	);
};

export default React.memo(BlockInfo);
