import { filterMap } from '@acceligentllc/shared/utils/array';

type ModifiersObject = { [modifier: string]: boolean; };
type ModifiersArray = string[];
type ModifiersType = ModifiersObject | ModifiersArray;

const modifiersToString = (item: string, modifiers?: ModifiersType): string => {
	const isModifiersArray = Array.isArray(modifiers);
	const modifiersArray = (isModifiersArray ? modifiers as ModifiersArray : Object.keys(modifiers as ModifiersObject)) as string[];

	return filterMap(
		modifiersArray,
		(mod: string) => isModifiersArray ? !!mod : (modifiers as ModifiersObject)[mod],
		(mod: string) => `${item}--${mod}`
	).join(' ');
};

const noModifiers = (modifiers?: ModifiersType): boolean => {
	if (!modifiers) {
		return true;
	}
	const modifiersArray = Array.isArray(modifiers) ? modifiers : Object.keys(modifiers);
	return modifiersArray.length === 0;
};

export const bemBlock = (block: string, modifiers?: ModifiersType) => {
	if (noModifiers(modifiers)) {
		return block;
	}

	return `${block} ${modifiersToString(block, modifiers)}`;
};

export const bemElement = (block: string, element: string, modifiers?: ModifiersType) => {
	const elementName = `${block}__${element}`;

	return bemBlock(elementName, modifiers);
};
