import * as React from 'react';
import { Col } from 'react-bootstrap';

import styles from './styles.module.scss';
import { Button } from '@acceligentllc/storybook';

interface OwnProps {
	redirectToFieldReport: () => void;
	redirectToWorkOrder: () => void;
	redirectToJob: (jobId: number) => void;
	jobId: number;
}

type Props = OwnProps;

const WorkOrderLinks: React.FC<Props> = (props) => {

	const {
		redirectToFieldReport,
		redirectToWorkOrder,
		redirectToJob,
		jobId,
	} = props;

	const jobRedirect = React.useCallback(() => {
		redirectToJob(jobId);
	}, [redirectToJob, jobId]);

	return (
		<Col className={styles['originals-container']} sm={3}>
			<Button
				icon="external"
				label="Original Field Report"
				onClick={redirectToFieldReport}
				style="link"
			/>
			<Button
				icon="search"
				label="Original Work Order"
				onClick={redirectToWorkOrder}
				style="link"
			/>
			<Button
				icon="external"
				label="View Job"
				onClick={jobRedirect}
				style="link"
			/>
		</Col>
	);
};

export default React.memo(WorkOrderLinks);
