import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { InjectedFormProps } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

import SubmitButton from 'af-components/SubmitButton';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import { defaultRedirectUrl } from 'af-utils/actions.util';

import styles from './styles.module.scss';

type OwnProps = CustomRouteComponentProps;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & InjectedFormProps<UserRequestModel.FinalizeUser, FormOwnProps>;

const PasswordOutdated = (props: Props) => {
	const { userData, organizationData, history, location: { state: { orgAlias } } } = props;
	const navigate = useNavigate();

	const continueToFinalize = React.useCallback(() => {
		orgAlias && navigate(CLIENT.AUTH.FINALIZE(orgAlias));
	}, [orgAlias, navigate]);

	React.useEffect(() => {
		if (!userData?.isFinalized || userData?.isPasswordExpired || !organizationData) {
			return;
		}
		if (organizationData.isPlatformAdmin) {
			return history.push(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
		}
		if (organizationData.companies.length) {
			return history.push(defaultRedirectUrl(orgAlias, organizationData.companies[0].name, [], true, userData.role));
		}
		return history.push(CLIENT.COMPANY.CREATE(orgAlias));
	}, [userData, organizationData, history, orgAlias]);

	return (
		<div className="form-segment form-segment--mini">
			<div className={`form-box form-box--standalone ${styles.containerPadded}`}>
				<Row className={`row ${styles.centeredRow}`}>
					<Col sm={16}>
						<div className={styles.marginBottom__m}>
							<h2 className={styles.titleText}>Password Outdated</h2>
						</div>
						<div className={`form-box__icon-container ${styles.marginBottom__m}`}><span className={`${styles.icon} icon-lock`}></span></div>
						<h4 className={styles.subtitleText}>
							With the goal of further improving security of the Acceligent app,
							we have added new password requirements.
						</h4>
						<div className={styles.marginBottom__l}>
							<h4 className={styles.subtitleText}>Please update your password.</h4>
						</div>
					</Col>
				</Row>
				<Row className={`row ${styles.centeredRow}`}>
					<Col className={styles.marginBottom__l} sm={16}>
						<SubmitButton
							disabled={false}
							fullWidth={true}
							label="Continue"
							onClick={continueToFinalize}
							size="big"
							variant="primary"
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		organizationData: state.user.organizationData,
		userData: state.user.userData,
	};
}

const connector = connect(mapStateToProps, null);

export default compose<React.ComponentClass<OwnProps>>(connector)(PasswordOutdated);
