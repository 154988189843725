import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { formatDate } from '@acceligentllc/shared/utils/time';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type UserBase from 'ab-domain/models/user/base';

import type WorkRequestBidStatus from '@acceligentllc/shared/enums/workRequestBidStatus';
import { WorkRequestBidStatusLabel } from '@acceligentllc/shared/enums/workRequestBidStatus';
import type Priority from '@acceligentllc/shared/enums/priority';

import type AccountBase from 'ab-domain/models/account/base';

import { FAKE_ACCOUNT_DATA } from '@acceligentllc/shared/constants/value';

import { getUserName } from '@acceligentllc/shared/utils/user';

interface UpdatedByViewModel {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class UpdatedByAccountViewModel implements UpdatedByViewModel {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}
}

const WORK_REQUEST_TABLE_HEADERS = [
	'Bid Status',
	'Priority',
	'Work Request ID',
	'Title',
	'Customer Company',
	'City',
	'State',
	'Office',
	'Project Manager',
	'Start Date',
	'Target Completion Date',
	'Guaranteed Completion Date',
	'Billing Total / Estimate Total',
];

class WorkRequestTableViewModel {
	id: number;
	bidStatus: WorkRequestBidStatus;
	jobCode: string;
	title: string;
	customerCompanyName: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	projectManager: Nullable<string>;
	/** YYYY-MM-DDTHH:mm:ss.SSSZ */
	updatedAt: Nullable<string>;
	priority: Priority;
	updatedBy: UpdatedByViewModel;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	estimateTotal: Nullable<number>;
	convertedToJobId: Nullable<number>;

	constructor(workRequest: WorkRequestBase) {
		const _projectManager: Nullable<UserBase> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.title = workRequest.title;
		this.startDate = workRequest.startDate;
		this.updatedBy = new UpdatedByAccountViewModel(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt ? formatDate(workRequest.updatedAt, TimeFormat.ISO_DATETIME, TimeFormat.ISO_DATETIME) : null;
		this.office = workRequest?.office?.nickname ?? null;

		this.targetCompletionDate = workRequest.targetCompletionDate;
		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;
		this.customerCompanyName = workRequest.customerCompanyName;
		this.bidStatus = workRequest.bidStatus;

		this.city = workRequest.travelLocation?.locality ?? null;
		this.state = workRequest.travelLocation?.aa1 ?? null;
		this.priority = workRequest.priority;

		this.convertedToJobId = workRequest.convertedToJobId;

	}

	static bulkConstructor = (_workRequests: WorkRequestBase[]) => _workRequests.map((_wr) => new WorkRequestTableViewModel(_wr));

	static toCSVData = (workRequests: WorkRequestTableViewModel[]): string[][] => {

		const headers = WORK_REQUEST_TABLE_HEADERS;
		const rows = workRequests.map((_wr) => {
			return [
				WorkRequestBidStatusLabel[_wr.bidStatus],
				_wr.priority,
				_wr.jobCode,
				_wr.title,
				_wr.customerCompanyName ?? '',
				_wr.city ?? '',
				_wr.state ?? '',
				_wr.office ?? '',
				_wr.projectManager ?? '',
				_wr.startDate ?? '',
				_wr.targetCompletionDate ?? '',
				_wr.guaranteedCompletionDate ?? '',
				_wr.estimateTotal ? `${_wr.estimateTotal}` : '',
			];
		});

		return [headers, ...rows];
	};
}

export default WorkRequestTableViewModel;
