import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext } from '@tanstack/react-table';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import MemberInviteEnum from '@acceligentllc/shared/enums/memberInvite';

import * as CompanyActions from 'af-actions/companies';

import type { OrganizationMemberVM } from 'ab-viewModels/organization/member.viewModel';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';

import DateCell from 'af-components/Table6/Cells/DateCell';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import ImageTag from 'af-components/Image';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusCell from 'af-components/Table6/Cells/StatusCell';
import CompanyStatusCell from 'af-components/Table6/Cells/CompanyStatusCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import type { TableProps } from 'af-components/Table/types';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import ConfirmationModal from 'af-components/ConfirmationModal';

import { formatPhoneNumber } from 'ab-utils/phone.util';

type Props = ConnectedProps<typeof connector>;

const BREADCRUMBS = [{ label: 'Users' }];

const deleteUserModalBody = <>This action cannot be undone. </>;
const deleteUserModalText = 'Delete User';

const OrganizationMembers: React.FC<Props> = (props) => {
	const {
		deleteUserAction,
		getMembersForOrganization,
	} = props;

	const tableRef = React.useRef<TableRef<OrganizationMemberVM>>(null);

	const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
	const [userToDelete, setUserToDelete] = React.useState<Nullable<OrganizationMemberVM>>(null);

	const deleteUser = React.useCallback(async (userId: number) => {
		await deleteUserAction(userId);
	}, [deleteUserAction]);

	const confirmDeletion = React.useCallback(async () => {
		if (userToDelete) {
			await deleteUser(userToDelete.userId);
			if (tableRef.current) {
				tableRef.current.refreshTable();
			}
		}
	}, [userToDelete, deleteUser]);

	const openDeleteModal = React.useCallback((original: OrganizationMemberVM) => {
		setUserToDelete(original);
		setShowDeleteModal(true);
	}, [setShowDeleteModal]);

	const closeDeleteModal = React.useCallback(() => {
		setUserToDelete(null);
		setShowDeleteModal(false);
	}, [setShowDeleteModal]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<OrganizationMemberVM, unknown>) => {
		if (_cell.row.original.status === MemberInviteEnum.DELETED) {
			return null;
		}

		const actions = [
			{
				onClick: () => openDeleteModal(_cell.row.original),
				label: 'Deactivate for Organization',
				shouldRefresh: true,
			},
		];

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={actions}
				valueKey="label"
			/>
		);
	}, [openDeleteModal]);

	const deleteUserModalTitle = React.useMemo(() => (`Are you sure you want to delete this User for organization (${userToDelete?.fullName})?`), [userToDelete]);

	const columns: TableProps<OrganizationMemberVM>['columns'] = React.useMemo(() => [
		{
			id: 'image',
			accessor: 'imageUrl',
			enableSorting: false,
			header: () => <EmptyCell isHeader />,
			size: 100,
			cell: ({ cell }) => (
				<div className="rt-thumbnail">
					<ImageTag
						fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
						minSize={BlobStorageImageSizeContainer.SIZE_50X50}
						src={cell.row.original.imageUrl}
						tryOriginal={true}
						tryRoot={true}
					/>
				</div>
			),
		},
		{
			id: 'uniqueId',
			header: 'Unique ID',
			accessor: 'uniqueId',
			enableSorting: true,
			cell: ({ cell }) => <div className="text-strong">
				{cell.row.original.uniqueId}
			</div>,
		},
		{
			id: 'fullName',
			header: 'Full Name',
			accessor: 'fullName',
			enableSorting: true,
			cell: ({ cell }) => <div className="text-strong">{cell.row.original.fullName}</div>,
		},
		{
			id: 'email',
			header: 'Email Address',
			accessor: 'email',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.email ?? <EmptyCell />,
		},
		{
			id: 'phone',
			header: 'Mobile Phone',
			accessor: 'phoneNumber',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.phoneNumber
				? formatPhoneNumber(cell.row.original.phoneNumber, cell.row.original.countryCode)
				: <EmptyCell />,
		},
		{
			id: 'lastLoginAt',
			header: 'Last Login',
			accessor: 'lastLoginAt',
			enableSorting: true,
			cell: ({ cell }) => <DateCell date={cell.row.original.lastLoginAt} format={TimeFormatEnum.DATE_ONLY} isLeftAligned={true} withTime={true} />,
		},
		{
			id: 'status',
			header: 'Status',
			accessor: 'status',
			enableSorting: true,
			cell: ({ cell }) => <StatusCell {...cell.row.original} />,
		},
		{
			id: 'companyStatus',
			header: 'Company Status',
			accessor: 'companyStatus',
			enableSorting: false,
			cell: ({ cell }) => <CompanyStatusCell companyStatus={cell.row.original.companyStatus} />,
		},
		{
			id: 'activeInLMS',
			header: 'LMS access',
			accessor: 'activeInLMS',
			enableSorting: true,
			cell: ({ cell }) => {
				if (cell.row.original.activeInLMS) {
					return (<span className="text-green">Yes</span>);
				}
				return (<span className="text-grey">No</span>);
			},
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [resolveActionsButton]);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={BREADCRUMBS} />
			<TableNew
				columns={columns}
				fetch={getMembersForOrganization}
				hasSearchInput={true}
				hasSubRows={false}
				ref={tableRef}
				searchLabel="Members"
				selectable={true}
				tableName={TableNameEnum.ORGANIZATION_MEMBERS}
			/>
			<ConfirmationModal
				body={deleteUserModalBody}
				closeModal={closeDeleteModal}
				confirmAction={confirmDeletion}
				confirmText={deleteUserModalText}
				modalStyle="danger"
				showModal={showDeleteModal}
				title={deleteUserModalTitle}
			/>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		deleteUserAction: CompanyActions.deleteUser,
		getMembersForOrganization: CompanyActions.getMembersForOrganization,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(OrganizationMembers);
