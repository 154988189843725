import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type { PercentagesOfDateChanges, PercentagesForChangeToDueDate } from './types';

const EMPTY_OBJECT = {
	startTime: false,
	endTime: false,
	occupied: false,
};

export const _calculateTotalMinutesInSection = (timelineEntity: TimelineEntitesForAccount) => {
	if (timelineEntity.type === TimelineEntityType.GAP) {
		return undefined;
	}
	const endTime = timelineEntity.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
	return TimeUtils.getDiff(endTime, timelineEntity.entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);
};

/**
 *
 * @param startTime ISO_DATETIME
 * @param endTime ISO_DATETIME
 * @param dueDate YYYY-MM-DD
 */
const _doesIntervalIncludeDueDate = (startTime: string, endTime: string, dueDate: string) => {

	const startDate = TimeUtils.formatDate(startTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
	const endDate = TimeUtils.formatDate(endTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);

	if (endDate === dueDate || startDate === dueDate) {
		return true;
	}

	if (TimeUtils.isBefore(dueDate, startDate, TimeFormat.DB_DATE_ONLY)) {
		return false;
	}

	if (TimeUtils.isBefore(endDate, dueDate, TimeFormat.DB_DATE_ONLY)) {
		return false;
	}

	return true;
};

/**
 *
 * @param dueDate YYYY-MM-DD
 */
const _checkIsEntireSectionOnDueDate = (entity: TimelineEntitesForAccount, dueDate: string) => {
	const { startTime, endTime } = entity.entry;

	const startDate = TimeUtils.formatDate(startTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
	const endDate = endTime ? TimeUtils.formatDate(endTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME) : null;

	if (startDate !== dueDate) {
		return false;
	}

	if (endDate && endDate !== dueDate) {
		return false;
	}

	return true;
};

/**
 *
 * @param dueDate YYYY-MM-DD
 */
const _isEntryTimeSameAsDueDate = (entryTime: string, dueDate: string) => {
	const startDate = TimeUtils.formatDate(entryTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);

	if (startDate === dueDate) {
		return true;
	}

	return false;
};

/**
 *
 * @param sectionPartStartTime ISO_DATETIME
 * @param sectionPartEndTime ISO_DATETIME
 */
const _calculateSectionPercentage = (sectionPartStartTime: Date, sectionPartEndTime: Date, sectionTotalTime: number) => {
	const minutesBetweenStartAndMidnight = TimeUtils.getDiff(sectionPartEndTime, sectionPartStartTime, 'minutes', TimeFormat.ISO_DATETIME);

	if (minutesBetweenStartAndMidnight > sectionTotalTime) {
		throw new Error('Unexpected duration of section.');
	}

	return (minutesBetweenStartAndMidnight / sectionTotalTime * 100);
};

const _percentagesAtWhichTheDateChangeTookPlace = (percentagesOnSection: PercentagesOfDateChanges[]) => {
	let sum = 0;
	return percentagesOnSection.map((sectionData) => {
		sum += sectionData.leftOffsetInPercentage;
		return {
			...sectionData,
			leftOffsetInPercentage: sum,
		};
	});
};

/**
 *
 * @param dueDate  YYYY-MM-DD
 */
const _percentagesOfSectionsOnDateChanges = (dates: Date[], totalSectionMinutes: number, dueDate: string): PercentagesOfDateChanges[] => {
	return dates.slice(1).map((date, index) => {
		const parsedDate = TimeUtils.formatDate(dates[index].toISOString(), TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
		const isDayBeforeDueDate = TimeUtils.isBefore(parsedDate, dueDate, TimeFormat.DB_DATE_ONLY);

		if (isDayBeforeDueDate === null) {
			throw new Error('Cannot determine if date on section is before due date');
		}

		return {
			isBeforeDueDate: isDayBeforeDueDate,
			dateToShow: isDayBeforeDueDate ? dates[index] : date,
			leftOffsetInPercentage: _calculateSectionPercentage(dates[index], TimeUtils.positionDate(date, 'start', 'day'), totalSectionMinutes),
			isBetweenSections: false,
		};
	});
};

/**
 *
 * @param startTime ISO_DATETIME
 * @param endTime ISO_DATETIME
 * @returns undefined or percentages for date changes on the section, the percentage represents at what percentage from start time did the date change occur
 */
const _calculateSectionPercentagesAtWhichDateChangesTookPlace = (
	timelineEntity: TimelineEntitesForAccount,
	totalSectionMinutes: number | undefined,
	dueDate: string
) => {

	if (timelineEntity.type === TimelineEntityType.GAP) {
		return undefined;
	}

	if (totalSectionMinutes === undefined) {
		throw new Error('Total section minutes must be defined');
	}

	const startTime = timelineEntity.entry.startTime;
	let endTime = timelineEntity.entry.endTime;

	if (startTime === endTime) {
		return undefined;
	}

	if (!endTime) {
		endTime = TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
	}

	const _startTime = TimeUtils.formatDate(startTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
	const _endTime = TimeUtils.formatDate(endTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
	const daysBetween = TimeUtils.getDaysBetween(
		_startTime,
		_endTime
	);

	if (daysBetween === null) {
		return undefined;
	}

	if (!daysBetween.length && _startTime === _endTime) {
		return undefined;
	}

	const datesToCalculatePercentagesOnChange = daysBetween.map((day) => TimeUtils.positionDate(TimeUtils.parseDate(day, TimeFormat.DB_DATE_ONLY), 'start', 'day'));

	if (_startTime !== daysBetween[0]) {
		datesToCalculatePercentagesOnChange.unshift(TimeUtils.parseDate(startTime, TimeFormat.ISO_DATETIME));
	}
	if (_endTime !== daysBetween[daysBetween.length - 1]) {
		datesToCalculatePercentagesOnChange.push(TimeUtils.parseDate(endTime, TimeFormat.ISO_DATETIME));
	}
	const differencesBetweenDates = _percentagesOfSectionsOnDateChanges(datesToCalculatePercentagesOnChange, totalSectionMinutes, dueDate);

	return _percentagesAtWhichTheDateChangeTookPlace(differencesBetweenDates);
};

/**
 *
 * @param startTime ISO_DATETIME
 * @param endTime ISO_DATETIME
 * @param dueDate YYYY-MM-DD
 */
const _calculateSectionDateChangePercentagesOnDueDate = (
	startTime: string,
	endTime: string,
	dueDate: string,
	totalSectionMinutes: number
): PercentagesForChangeToDueDate => {

	const sectionDateChangePercentages: PercentagesForChangeToDueDate = {
		percentageOfSectionBeforeDueDate: undefined,
		percentageOfSectionAfterDueDate: undefined,
	};

	if (!_isEntryTimeSameAsDueDate(startTime, dueDate)) {
		sectionDateChangePercentages.percentageOfSectionBeforeDueDate = _calculateSectionPercentage(
			TimeUtils.parseDate(startTime, TimeFormat.ISO_DATETIME),
			TimeUtils.positionDate(TimeUtils.parseDate(dueDate, TimeFormat.DB_DATE_ONLY), 'start', 'day'),
			totalSectionMinutes
		);
	}

	if (!_isEntryTimeSameAsDueDate(endTime, dueDate)) {
		const _dueDate = TimeUtils.parseDate(dueDate, TimeFormat.DB_DATE_ONLY);
		sectionDateChangePercentages.percentageOfSectionAfterDueDate = _calculateSectionPercentage(
			TimeUtils.positionDate(_dueDate, 'end', 'day'),
			TimeUtils.parseDate(endTime, TimeFormat.ISO_DATETIME),
			totalSectionMinutes
		);
	}

	return sectionDateChangePercentages;
};

/**
 *
 * @param dueDate YYYY-MM-DD
 */
const _getPercentagesForChangeToDueDate = (
	timelineEntity: TimelineEntitesForAccount,
	dueDate: string,
	totalSectionMinutes: number | undefined
): PercentagesForChangeToDueDate | undefined => {
	const startTime = timelineEntity.entry.startTime;
	let endTime = timelineEntity.entry.endTime;

	if (timelineEntity.type === TimelineEntityType.GAP) {
		return undefined;
	}

	if (totalSectionMinutes === undefined) {
		throw new Error('Total section minutes must be defined');
	}

	if (!endTime) {
		endTime = TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
	}

	if (_checkIsEntireSectionOnDueDate(timelineEntity, dueDate)) {
		return undefined;
	}

	if (!_doesIntervalIncludeDueDate(startTime, endTime, dueDate)) {
		return undefined;
	}

	const sectionDateChangePercentages = _calculateSectionDateChangePercentagesOnDueDate(
		startTime,
		endTime,
		dueDate,
		totalSectionMinutes
	);

	return sectionDateChangePercentages;
};

export const totalDurationWithoutGapReducer = (_totalDuration: number, _currEntity: TimelineEntitesForAccount) => {
	if (_currEntity.type === TimelineEntityType.GAP) {
		return _totalDuration;
	}

	const minutesInSection = _calculateTotalMinutesInSection(_currEntity);

	if (minutesInSection === undefined) {
		throw new Error('Minutes in section must be defined.');
	}

	return _totalDuration + minutesInSection;
};

const getSectionDateData = (
	tse: TimelineEntitesForAccount,
	dueDate: string,
	totalDurationInMinutesWithoutGaps: number
) => {
	let percentageOfTimeline: Nullable<number>;
	if (tse.type !== TimelineEntityType.GAP) {
		const endTime = tse.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
		percentageOfTimeline = (TimeUtils.getDiff(endTime, tse.entry.startTime, 'minutes', TimeFormat.ISO_DATETIME) / totalDurationInMinutesWithoutGaps);
	} else {
		percentageOfTimeline = 0;
	}
	percentageOfTimeline *= 100;
	const _totalSectionMinutes = _calculateTotalMinutesInSection(tse);
	const sectionPercentagesForDueDate = _getPercentagesForChangeToDueDate(tse, dueDate, _totalSectionMinutes);
	const isEntireSectionOnDueDate = _checkIsEntireSectionOnDueDate(tse, dueDate);
	const dateChangesOnSectionInPercentages = _calculateSectionPercentagesAtWhichDateChangesTookPlace(
		tse,
		_totalSectionMinutes,
		dueDate
	);

	return {
		percentageOfTimeline,
		sectionPercentagesForDueDate,
		dateChangesOnSectionInPercentages,
		isEntireSectionOnDueDate,
	};
};

/**
 *
 * @param dueDate YYYY-MM-DD
 */
export const getEntitySectionData = (
	entities: TimelineEntitesForAccount[],
	overlaps: Nullable<Record<string, OverlapMeta>>,
	totalDurationInMinutesWithoutGaps: number,
	tse: TimelineEntitesForAccount,
	_index: number,
	dueDate: string
) => {
	let prevEntryIsGap = false;
	let nextEntryIsGap = false;
	let hasOverlap = false;
	if (tse.type !== TimelineEntityType.GAP) {
		prevEntryIsGap = entities[_index - 1]?.type === TimelineEntityType.GAP;
		nextEntryIsGap = entities[_index + 1]?.type === TimelineEntityType.GAP;
		const {
			startTime: overlapStartTime = false,
			endTime: overlapEndTime = false,
			occupied: overlapOccupied = false,
		} = overlaps?.[tse.entry.id] ?? EMPTY_OBJECT;
		hasOverlap = overlapStartTime || overlapEndTime || overlapOccupied;
	}

	const {
		percentageOfTimeline,
		sectionPercentagesForDueDate,
		dateChangesOnSectionInPercentages,
	} = getSectionDateData(tse, dueDate, totalDurationInMinutesWithoutGaps);

	const isFirst = _index === 0;
	const isLast = _index === entities.length - 1;
	const roundedLeft = prevEntryIsGap || isFirst;
	const roundedRight = nextEntryIsGap || isLast;
	const entityKey = `${tse.type}#${_index}#${percentageOfTimeline}`;

	return {
		percentageOfTimeline,
		hasOverlap,
		roundedLeft,
		roundedRight,
		sectionPercentagesForDueDate,
		dateChangesOnSectionInPercentages,
		entityKey,
		nextEntryIsGap,
	};
};

export const calculateDateChangeDataForEntireTimeline = (
	entities: TimelineEntitesForAccount[],
	totalDurationInMinutesWithoutGaps: number,
	dueDate: string,
	dateChangeManager: AbstractDateChangeManager
) => {
	let isFirst = true;

	for (const entity of entities) {

		if (entity.type === TimelineEntityType.GAP) {
			continue;
		}

		const {
			percentageOfTimeline,
			sectionPercentagesForDueDate,
			dateChangesOnSectionInPercentages,
			isEntireSectionOnDueDate,
		} = getSectionDateData(entity, dueDate, totalDurationInMinutesWithoutGaps);

		dateChangeManager.assignChanges(
			percentageOfTimeline,
			sectionPercentagesForDueDate,
			dateChangesOnSectionInPercentages,
			isEntireSectionOnDueDate,
			entity,
			isFirst,
			dueDate
		);

		isFirst = false;
	}

	return {
		percentagesForChangeToDueDate: dateChangeManager.getPercentagesForChangeToDueDate(),
		percentagesOfDateChanges: dateChangeManager.getPercentagesOfDateChanges(),
		wasAnySectionOnDueDate: dateChangeManager.getWasAnySectionOnDueDate(),
	};
};

abstract class AbstractDateChangeManager {

	protected dateChangePercentagesForDueDate: PercentagesForChangeToDueDate;
	protected percentagesOfDateChanges: PercentagesOfDateChanges[];
	protected percentageSoFar: number;
	protected anySectionWasOnDueDate: boolean;

	constructor() {
		this.dateChangePercentagesForDueDate = {
			percentageOfSectionBeforeDueDate: undefined,
			percentageOfSectionAfterDueDate: undefined,
		};
		this.percentagesOfDateChanges = [];
		this.percentageSoFar = 0;
		this.anySectionWasOnDueDate = false;
	}

	/**
	 *
	 * @param dueDate YYYY-MM-DD
	 */
	assignChanges(
		percentage: number,
		sectionPercentagesForChangeToDueDate: PercentagesForChangeToDueDate | undefined,
		percentagesOfDateChanges: PercentagesOfDateChanges[] | undefined,
		isEntireSectionOnDueDate: boolean,
		entity: TimelineEntitesForAccount,
		isFirst: boolean,
		dueDate: string
	) {
		this.assignPercentagesOfDateChanges(
			percentage,
			percentagesOfDateChanges,
			TimeUtils.parseDate(entity.entry.startTime, TimeFormat.ISO_DATETIME),
			isEntireSectionOnDueDate,
			dueDate
		);
		this.assignDateChangePercentagesForDueDate(
			percentage,
			sectionPercentagesForChangeToDueDate,
			isEntireSectionOnDueDate,
			isFirst
		);
		this.assignPercentageSoFar(percentage);
		this.setCurrentEndDate(entity.entry.endTime ? TimeUtils.parseDate(entity.entry.endTime, TimeFormat.ISO_DATETIME) : null);
		this.setFoundSectionOnDueDate(
			isEntireSectionOnDueDate
			|| sectionPercentagesForChangeToDueDate?.percentageOfSectionBeforeDueDate !== undefined
			|| sectionPercentagesForChangeToDueDate?.percentageOfSectionAfterDueDate !== undefined
		);
	}

	getPercentagesForChangeToDueDate(): PercentagesForChangeToDueDate {
		return this.dateChangePercentagesForDueDate;
	}

	getPercentagesOfDateChanges(): PercentagesOfDateChanges[] {
		return this.percentagesOfDateChanges;
	}

	getWasAnySectionOnDueDate() {
		return this.anySectionWasOnDueDate;
	}

	protected abstract setCurrentEndDate(endDate: Nullable<Date>): void;
	protected abstract assignPercentageSoFar(percentage: number): void;
	protected abstract assignDateChangePercentagesForDueDate(
		sectionWidthPercentage: number,
		sectionPercentagesForChangeToDueDate: PercentagesForChangeToDueDate | undefined,
		isEntireSectionOnDueDate: boolean,
		isFirst: boolean): void;
	/**
	 *
	 * @param dueDate YYYY-MM-DD
	 */
	protected abstract assignPercentagesOfDateChanges(
		sectionWidthPercentage: number,
		percentagesOfDateChanges: PercentagesOfDateChanges[] | undefined,
		startDate: Date,
		isEntireSectionOnDueDate: boolean,
		dueDate: string): void;
	protected abstract setFoundSectionOnDueDate(isOnDueDate: boolean): void;
}

export class DateChangeManager extends AbstractDateChangeManager {

	private endDate: Nullable<Date>;
	private foundDueDateEnd: boolean = false;
	private foundDueDateStart: boolean = false;

	constructor() {
		super();
		this.endDate = null;
	}

	private toDecimalPercentage(percentage: number): number {
		return percentage / 100;
	}

	protected setCurrentEndDate(endDate: Nullable<Date>): void {
		this.endDate = endDate;
	}

	protected assignPercentageSoFar(percentage: number) {
		this.percentageSoFar += percentage;
	}

	// Assigns the due date changes if we have no date changes on sections but we do have entire sections on the due date
	private assignEntireSection(sectionWidthPercentage: number) {
		if (!this.dateChangePercentagesForDueDate.percentageOfSectionBeforeDueDate) {
			this.dateChangePercentagesForDueDate.percentageOfSectionBeforeDueDate = this.percentageSoFar;
		}

		if (!this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate) {
			this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate = 100 - this.percentageSoFar - sectionWidthPercentage;
		}
	}

	private assignEverythingBeforeAsBeforeDueDate() {
		if (!this.dateChangePercentagesForDueDate.percentageOfSectionBeforeDueDate) {
			this.dateChangePercentagesForDueDate.percentageOfSectionBeforeDueDate = this.percentageSoFar;
		}
	}

	private isFirstSectionOnDueDate(isEntireSectionOnDueDate: boolean, isFirst: boolean) {
		return !this.anySectionWasOnDueDate && isEntireSectionOnDueDate && isFirst;
	}

	private isSectionOnDueDateAfterFirst(isEntireSectionOnDueDate: boolean, isFirst: boolean) {
		return isEntireSectionOnDueDate && !isFirst;
	}

	private wasEverythingBeforeDueDate(isEntireSectionOnDueDate: boolean) {
		return !this.anySectionWasOnDueDate && isEntireSectionOnDueDate;
	}

	protected assignDateChangePercentagesForDueDate(
		sectionWidthPercentage: number,
		sectionPercentagesForChangeToDueDate: PercentagesForChangeToDueDate | undefined,
		isEntireSectionOnDueDate: boolean,
		isFirst: boolean
	) {

		if (this.isFirstSectionOnDueDate(isEntireSectionOnDueDate, isFirst)) {
			this.assignEntireSection(sectionWidthPercentage);
		}

		if (this.isSectionOnDueDateAfterFirst(isEntireSectionOnDueDate, isFirst)) {
			this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate = (100 - this.percentageSoFar - sectionWidthPercentage);
		}

		if (this.wasEverythingBeforeDueDate(isEntireSectionOnDueDate)) {
			this.assignEverythingBeforeAsBeforeDueDate();
		}

		if (!sectionPercentagesForChangeToDueDate) {
			return;
		}

		// Assign percentages before the section
		if (sectionPercentagesForChangeToDueDate.percentageOfSectionBeforeDueDate) {
			this.foundDueDateStart = true;
			this.dateChangePercentagesForDueDate.percentageOfSectionBeforeDueDate = this.percentageSoFar +
				sectionPercentagesForChangeToDueDate.percentageOfSectionBeforeDueDate * this.toDecimalPercentage(sectionWidthPercentage);
		}

		// Assign percentages taking into account ones that will come after
		if (sectionPercentagesForChangeToDueDate.percentageOfSectionAfterDueDate) {
			this.foundDueDateEnd = true;
			this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate = (100 - this.percentageSoFar - sectionWidthPercentage) +
				sectionPercentagesForChangeToDueDate.percentageOfSectionAfterDueDate * this.toDecimalPercentage(sectionWidthPercentage);
		}

		// Assign percentages after section
		if (!sectionPercentagesForChangeToDueDate.percentageOfSectionAfterDueDate && this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate) {
			this.foundDueDateEnd = true;
			this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate += sectionWidthPercentage;
		}

	}

	private wereEntirePreviousSectionsOnDueDate(isEntireSectionOnDueDate: boolean) {
		return this.anySectionWasOnDueDate
			&& !isEntireSectionOnDueDate
			&& this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate;
	}

	private wasSomePreviousEntireSectionBeforeDueDate() {
		return this.endDate && !this.dateChangePercentagesForDueDate.percentageOfSectionAfterDueDate;
	}

	/**
	 *
	 * @param dueDate YYYY-MM-DD
	 */
	private sectionStartsOnDueDate(startDate: Date, dueDate: string) {
		const _startDate = TimeUtils.formatDate(startDate.toISOString(), TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
		if (_startDate === dueDate) {
			return true;
		}
		return false;
	}

	protected assignPercentagesOfDateChanges(
		sectionWidthPercentage: number,
		percentagesOfDateChanges: PercentagesOfDateChanges[] | undefined,
		startDate: Date,
		isEntireSectionOnDueDate: boolean,
		dueDate: string
	) {

		if (this.wereEntirePreviousSectionsOnDueDate(isEntireSectionOnDueDate)
			&& !this.sectionStartsOnDueDate(startDate, dueDate)
			&& !this.foundDueDateEnd) {
			this.foundDueDateEnd = true;
			this.percentagesOfDateChanges.push({
				isBeforeDueDate: false,
				dateToShow: startDate,
				leftOffsetInPercentage: this.percentageSoFar,
				isBetweenSections: true,
			});
		}

		if (this.wasSomePreviousEntireSectionBeforeDueDate() && !this.foundDueDateStart && isEntireSectionOnDueDate) {
			if (!this.endDate) {
				throw new Error('Must have last end date to show');
			}
			this.foundDueDateStart = true;
			this.percentagesOfDateChanges.push({
				isBeforeDueDate: true,
				dateToShow: this.endDate,
				leftOffsetInPercentage: this.percentageSoFar,
				isBetweenSections: true,
			});
		}

		if (!percentagesOfDateChanges) {
			return;
		}

		this.percentagesOfDateChanges.push(
			...percentagesOfDateChanges.map((percentageData) => {
				return {
					...percentageData,
					leftOffsetInPercentage: this.percentageSoFar + percentageData.leftOffsetInPercentage * this.toDecimalPercentage(sectionWidthPercentage),
				};
			})
		);
	}

	protected setFoundSectionOnDueDate(isOnDueDate: boolean) {
		if (this.anySectionWasOnDueDate) {
			return;
		}
		this.anySectionWasOnDueDate = isOnDueDate;
	}
}

export const getEntitiesWithMergedOverlaps = (
	entities: TimelineEntitesForAccount[],
	overlaps: Nullable<Record<string, OverlapMeta>>
) => {
	if (!overlaps || !Object.keys(overlaps).length) {
		return entities;
	}

	const result: TimelineEntitesForAccount[] = [];
	let currentOverlapedEntry: Nullable<TimelineEntitesForAccount> = null;
	for (const entity of entities) {

		if (entity.type === TimelineEntityType.GAP && currentOverlapedEntry) {
			result.push(currentOverlapedEntry);
			currentOverlapedEntry = null;
		}

		if (entity.type === TimelineEntityType.GAP) {
			result.push(entity);
			continue;
		}

		if (!overlaps[entity.entry.id]) {
			result.push(entity);
			continue;
		}

		if (!currentOverlapedEntry) {
			currentOverlapedEntry = JSON.parse(JSON.stringify(entity)); // let's not mess with the passed reference
			continue;
		}

		const currentEndTime = currentOverlapedEntry.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
		const newEntryEndTime = entity.entry.endTime ?? TimeUtils.formatDate(new Date(), TimeFormat.ISO_DATETIME);
		if (TimeUtils.isBefore(currentEndTime, newEntryEndTime, TimeFormat.ISO_DATETIME)) {
			currentOverlapedEntry.entry.endTime = newEntryEndTime;
		}
	}

	if (currentOverlapedEntry) {
		result.push(currentOverlapedEntry);
	}

	return result;
};
