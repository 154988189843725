import * as React from 'react';

import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import { formatDate } from '@acceligentllc/shared/utils/time';

import ImageTag from 'af-components/Image';

import SignatureStatusLabel from '../../../../Shared/SignatureStatusLabel';

import styles from './styles.module.scss';

interface OwnProps {
	signatureStatus: TimeSheetVM['employeeApprovalStatus'];
	approvalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isOwner: boolean;
	isTemporaryEmployee: boolean;
}

type Props = OwnProps;

const TimeSheetSignature: React.FC<Props> = ({ approvalStatus, signatureStatus, isOwner, isTemporaryEmployee }: Props) => {
	const {
		userName: approvedBy,
		userNameShort: approvedByShort,
		updatedAt: approvedAt,
		approvalStatus: timeSheetApprovalStatus,
		signatureUrl: approvalSignatureUrl,
		note: rejectionNote,
	} = approvalStatus;
	const {
		userName: signedBy,
		updatedAt: signedAt,
		signatureStatus: timeSheetSignatureStatus,
		signatureUrl,
	} = signatureStatus;

	const isSignatureDateABlockStyle = !!signedBy ? ` ${styles['time-sheet-signature__section__date--block']}` : '';
	const signatureDateClassName = `${styles['time-sheet-signature__section__date']}${isSignatureDateABlockStyle}`;

	const isapprovalDateABlockStyle = !!approvedBy ? ` ${styles['time-sheet-signature__section__date--block']}` : '';
	const approvalDateClassName = `${styles['time-sheet-signature__section__date']}${isapprovalDateABlockStyle}`;

	// If time sheet was not signed and SI still gave the approval
	const isSignedBySI = timeSheetSignatureStatus !== TimeSheetSignatureStatus.SIGNED
		&& timeSheetApprovalStatus === TimeSheetApprovalStatus.APPROVED && !!approvalSignatureUrl;

	const showSignatureStatus = (
		!!timeSheetSignatureStatus
		&& (timeSheetSignatureStatus !== TimeSheetSignatureStatus.UNSIGNED || !isOwner)
		&& !isTemporaryEmployee
	);
	const showApprovalStatus = timeSheetApprovalStatus && timeSheetApprovalStatus !== TimeSheetApprovalStatus.PENDING;

	const showSignature = timeSheetSignatureStatus === TimeSheetSignatureStatus.SIGNED && signatureUrl;
	const showImage = isSignedBySI && approvalSignatureUrl;
	const showPlaceHolderSignature = !showSignature && showImage;
	const showPlaceHolderImage = showSignature && !showImage;

	return (
		<div className={styles['time-sheet-signature']}>
			{showSignatureStatus &&
				<div className={styles['time-sheet-signature__section']}>
					{showPlaceHolderSignature && <div className={styles['time-sheet-signature__section__placeholder']} />}
					{showSignature && <ImageTag className={styles['time-sheet-signature__section__image']} src={signatureUrl} />}
					<div>
						<div>
							<SignatureStatusLabel status={timeSheetSignatureStatus} />
							{signedBy &&
								<span className={styles['time-sheet-signature__section__name']}>
									by {signedBy}
								</span>
							}
							{signedAt &&
								<span className={signatureDateClassName}>
									{formatDate(signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
								</span>
							}
						</div>
						{isSignedBySI && <div className={styles['time-sheet-signature__section__superintendent']}>Signed By {approvedByShort}</div>}
					</div>
				</div>
			}
			{showApprovalStatus &&
				<div className={styles['time-sheet-signature__section']}>
					{showPlaceHolderImage && <div className={styles['time-sheet-signature__section__placeholder']} />}
					{showImage && <ImageTag className={styles['time-sheet-signature__section__image']} src={approvalSignatureUrl} />}
					<div>
						<div>
							<SignatureStatusLabel status={timeSheetApprovalStatus} />
							{approvedBy &&
								<span className={styles['time-sheet-signature__section__name']}>
									by {approvedBy}
								</span>
							}
							{approvedAt &&
								<span className={approvalDateClassName}>
									{formatDate(approvedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
								</span>
							}
						</div>
						{rejectionNote && <div className={styles['time-sheet-signature__section__superintendent']}>{rejectionNote}</div>}
					</div>
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheetSignature);
