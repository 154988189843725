import { getUserName } from '@acceligentllc/shared/utils/user';

import type UserBase from 'ab-domain/models/user/base';

import { checkPassword } from 'ab-utils/encryption.util';

type UserNameObject = { firstName: string; lastName: string; };

/** @deprecated use shared utils directly */
export const getFullName = (user: UserBase | UserNameObject, isFirstNameShort: boolean = false, defaultValue: string = '-', firstNameLength: number = 1) => {
	// This function exists because of defaultValue backwards compatibility
	return getUserName(user, isFirstNameShort, defaultValue, firstNameLength);
};

export const getFormattedFullName = (firstName: string, lastName: string): string => {
	return `${lastName}, ${firstName}`;
};

export const isValidPassword = (nonHashedPassword: string, hashedPassword: string) => {
	const isValid = checkPassword(nonHashedPassword, hashedPassword);
	return {
		isValid,
		errors: { password: 'Password is not valid' },
	};
};
