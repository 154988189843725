import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { compose } from 'redux';

import type FileType from '@acceligentllc/shared/enums/fileType';
import { ALLOWED_FILE_TYPES_LIST } from '@acceligentllc/shared/enums/fileType';

import { fromFileType } from 'ab-enums/mimeType.enum';
import type { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import type WorkOrderDirectoryVM from 'ab-viewModels/directory/workOrderDirectory.viewModel';
import type AttachmentVM from 'ab-viewModels/attachment/attachment.viewModel';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as AttachmentActions from 'af-actions/attachment';

import DirectoriesAttachmentTree from 'af-components/DocumentsAttachmentsTree';
import EditAttachmentModal from 'af-components/DocumentsAttachmentsTree/TreeTableBody/EditAttachmentModal';
import ConfirmationModal from 'af-components/ConfirmationModal';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import CreateNewDirectoryModal from 'af-root/scenes/Company/Jobs/Preview/Attachments/CreateNewDirectoryModal';
import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

type AttachmentVMExtended = AttachmentVM & {
	directoryId?: number;
};

export interface TypedCellInfo<T> {
	original: T;
}

export interface TypedColumn {
	header: string;
	id?: string;
	width?: number;
}

interface RowActionOptions {
	actionName: string;
	actionFunction: (params: unknown) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

interface OwnProps {
	forwardedWorkOrderId?: number;
	isReadOnly: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const DirectoriesAndAttachments = React.memo((props: Props) => {
	const {
		getWorkOrderDirectoriesAttachments,
		renameAttachment,
		downloadAttachment,
		deleteAttachment,
		createDirectory,
		deleteDirectory,
		formWorkOrderId,
		forwardedWorkOrderId,
		uploadAttachmentsToDirectory,
		changeAttachmentParent,
		isReadOnly,
	} = props;

	const fileInputRef = React.useRef<HTMLInputElement>(null);

	const [directoriesAndAttachments, setDirectoriesAndAttachments] = React.useState<Nullable<WorkOrderDirectoryVM[]>>(null);
	const [fileToRename, setFileToRename] = React.useState<{
		attachmentId: Nullable<number>;
		attachmentName: string;
		attachmentType: Nullable<FileType>;
	}>({ attachmentId: null, attachmentName: '', attachmentType: null });
	const [directoryId, setDirectoryId] = React.useState<Nullable<number>>(null);
	const [editAttachmentNameModalOpen, setEditAttachmentNameModalOpen] = React.useState(false);
	const [deleteDirectoryModalOpen, setdeleteDirectoryModalOpen] = React.useState(false);
	const [uploadDirectoryId, setUploadDirectoryId] = React.useState<Nullable<number>>(null);
	const [parentDirectoryForCreate, setParentDirectoryForCreate] = React.useState<Nullable<WorkOrderDirectoryVM>>(null);
	const notificationSnackbar = useNotificationSnackbar();

	const workOrderId: number = formWorkOrderId ?? forwardedWorkOrderId;

	const fetchData = React.useCallback(async () => {
		const fetchedData = await getWorkOrderDirectoriesAttachments(workOrderId);
		setDirectoriesAndAttachments(fetchedData);
	}, [getWorkOrderDirectoriesAttachments, workOrderId]);

	React.useEffect(() => {
		if (workOrderId || forwardedWorkOrderId) { fetchData(); }
	}, [fetchData, workOrderId, forwardedWorkOrderId]);

	const createNewDirectory = React.useCallback(async (parent: WorkOrderDirectoryVM, name: string) => {
		await createDirectory(workOrderId, parent.id, name);
		await fetchData();
	}, [createDirectory, fetchData, workOrderId]);

	const updateAttachmentName = async (id: number, name: string) => {
		await renameAttachment(id, name);
		await fetchData();
	};

	const allowedFileTypes = React.useMemo(() => [ALLOWED_FILE_TYPES_LIST.map(fromFileType)].join(', '), []);

	const callChangeName = async (original: AttachmentVMExtended) => {
		const { id, name } = original;
		const dotIndex = original.name.lastIndexOf('.');
		const attachmentNameWithoutType = (dotIndex !== -1) ? name.substring(0, dotIndex) : name;
		setFileToRename({ attachmentId: id, attachmentName: attachmentNameWithoutType, attachmentType: original.type });
		setEditAttachmentNameModalOpen(!editAttachmentNameModalOpen);
	};

	const callDownloadAttachment = (original: AttachmentVM) => {
		const dotIndex = original.name.lastIndexOf('.');
		const attachmentNameWithoutType = (dotIndex !== -1) ? original.name.substring(0, dotIndex) : original.name;
		const fileName = `${attachmentNameWithoutType}${original.type}`;
		downloadAttachment(original.id, fileName);
	};

	const removeAttachmentFromDirectory = async (original: AttachmentVMExtended) => {
		const { id, directoryId: attachmentDirectoryId } = original;
		if (attachmentDirectoryId) {
			await deleteAttachment(id, attachmentDirectoryId);
		}
		notificationSnackbar.success('Attachment deleted', new Date());
		await fetchData();
	};

	const deleteOneDirectory = async () => {
		if (directoryId) {
			await deleteDirectory(workOrderId, directoryId);
		}
		setdeleteDirectoryModalOpen(false);
		notificationSnackbar.success('Directory deleted', new Date());
		await fetchData();
	};

	const callDeleteDirectory = async (original: WorkOrderDirectoryVM) => {
		const { id } = original;

		setdeleteDirectoryModalOpen(true);
		setDirectoryId(id);
	};

	const handleUploadPress = React.useCallback((original: WorkOrderDirectoryVM) => {
		setUploadDirectoryId(original.id);
	}, []);

	const uploadAttachments = React.useCallback(async (selectedDirectoryId: number, files: File[]) => {
		const notification: Nullable<NotificationData> = notificationSnackbar.loading(`Uploading ${files[0].name}`, new Date());
		await uploadAttachmentsToDirectory(selectedDirectoryId, { requestFiles: files });
		if (notification) {
			notificationSnackbar.removeNotificationSnackbar(notification);
			const notificationText = `${files[0].name} has successfully been uploaded.`;
			notificationSnackbar.success(notificationText, new Date());
		}
		await fetchData();
	}, [fetchData, notificationSnackbar, uploadAttachmentsToDirectory]);

	const handleAttachmentUpload = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files || !uploadDirectoryId) {
			return;
		}
		const requestFiles: File[] = [];
		for (let i = 0; i < files.length; i += 1) {
			const file = files.item(i);
			if (file) {
				requestFiles.push(file);
			}
		}

		await uploadAttachments(uploadDirectoryId, requestFiles);
		setUploadDirectoryId(null);
	}, [uploadAttachments, uploadDirectoryId]);

	const closeCreateModal = React.useCallback(() => {
		setParentDirectoryForCreate(null);
	}, []);

	const handleAttachmentParentChange = React.useCallback(async (attachmentId: number, sourceDirectoryId: number, destinationDirectoryId: number) => {
		await changeAttachmentParent(attachmentId, sourceDirectoryId, destinationDirectoryId);
		await fetchData();
	}, [changeAttachmentParent, fetchData]);

	const rowActions: RowActions = {
		parentDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			/**
			 * To grey out (disable) action
			 */
			disabled: false,
		}],
		childDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			disabled: false,
			/**
			 * Child level 3 doesn't have option to create new folder
			 */
			show: true,
		},
		{
			actionName: 'Upload file',
			actionFunction: handleUploadPress,
			disabled: !isReadOnly,
			show: !isReadOnly,
		},
		{
			actionName: 'Delete',
			actionFunction: callDeleteDirectory,
			disabled: false,
			show: !isReadOnly,
		}],
		attachmentLevel: [{
			actionName: 'Rename',
			actionFunction: callChangeName,
			show: true,
		},
		{
			actionName: 'Download',
			actionFunction: callDownloadAttachment,
			show: true,
		},
		{
			actionName: 'Delete',
			actionFunction: removeAttachmentFromDirectory,
			show: !isReadOnly,
		}],
	};

	const columns: TypedColumn[] = [
		{
			header: 'Category folder',
			id: 'categoryFolder',
			width: 6,
		},
		{
			header: 'Files',
			id: 'files',
			width: 1,
		},
		{
			header: 'Download',
			id: 'download',
			width: 1,
		},
		...(!isReadOnly ? [{
			header: 'Upload',
			id: 'upload',
			width: 1,
		}] : []),
		{
			header: 'Preview',
			id: 'preview',
			width: 1,
		},
		{
			header: 'Last modified',
			id: 'lastModified',
			width: 2,
		},
	];

	return (
		<>
			{
				workOrderId ?
					<>
						<DirectoriesAttachmentTree
							columns={columns}
							dataTree={directoriesAndAttachments}
							fetchData={fetchData}
							handleAttachmentParentChange={handleAttachmentParentChange}
							id={workOrderId}
							isReadOnly={isReadOnly}
							onFileUpload={uploadAttachments}
							rowActions={rowActions}
						/>
						<input
							accept={allowedFileTypes}
							onChange={handleAttachmentUpload}
							ref={fileInputRef}
							style={{ display: 'none' }}
							type="file"
						/>
						{
							editAttachmentNameModalOpen &&
							<EditAttachmentModal
								attachmentData={fileToRename}
								setModalVisible={setEditAttachmentNameModalOpen}
								showModal={editAttachmentNameModalOpen}
								updateAttachment={updateAttachmentName.bind(this)}
							/>
						}
						<CreateNewDirectoryModal
							closeModal={closeCreateModal}
							createDirectory={createNewDirectory}
							parent={parentDirectoryForCreate}
						/>
						{
							deleteDirectoryModalOpen &&
							<ConfirmationModal
								body="You are about to permanently delete the Directory and all the files in it. Are you sure you want to continue?"
								closeModal={setdeleteDirectoryModalOpen.bind(this, false)}
								confirmAction={deleteOneDirectory.bind(this)}
								confirmText="Delete"
								modalStyle="danger"
								showModal={deleteDirectoryModalOpen}
								title={'Delete Directory'}
							/>
						}
					</>
					:
					<div className="p-l">In order to attach files, work order has to be saved first.</div>
			}
		</>
	);
});

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const workOrderId = selector(state, 'id');

	return {
		formWorkOrderId: workOrderId,
		companyName: companyData.name,
		selector: (fieldName: string) => selector(state, fieldName),
	};
}

function mapDispatchToProps() {
	return {
		getWorkOrderDirectoriesAttachments: WorkOrderActions.getWorkOrderDirectoriesAttachments,
		createDirectory: WorkOrderActions.createDirectory,
		deleteDirectory: WorkOrderActions.deleteDirectory,
		renameAttachment: AttachmentActions.renameAttachment,
		downloadAttachment: AttachmentActions.downloadAttachment,
		deleteAttachment: AttachmentActions.deleteAttachment,
		uploadAttachmentsToDirectory: AttachmentActions.uploadAttachmentsToDirectory,
		changeAttachmentParent: AttachmentActions.changeAttachmentParent,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(connector);

export default enhance(DirectoriesAndAttachments);
