import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';
import type WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import type SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import { downloadCSV } from 'af-utils/csv.utils';
import { dollarFormatter } from 'af-utils/format.util';

import BillableAndNonBillableWorkTable from '../Table/BillableAndNonBillableWorkTable';

interface Props {
	workSummary: WorkSummaryVM[];
	billingCodes: Record<string, BillingCodeVM[]>;
	workSummaryDetails: WorkSummaryDetailVM[];
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	areFRsReadOnly: boolean;
	isWorkSummaryStatusCompleted: boolean;
	isWorkSummaryStatusReviewed: boolean;
	alternativeWorkSummary: Set<WorkSummaryVM>;
	workOrderCode: string;
	belongsToProject: boolean;
	subJobs: SiblingSubjobVM[];
	handleJobReallocation: (subJob: SiblingSubjobVM, workSummaryDetailIds: number[]) => Promise<void>;
	onReallocateAll: (subJob: SiblingSubjobVM) => void;
}

const NonBillableWork: React.FC<Props> = (props) => {
	const {
		workSummary,
		billingCodes,
		workSummaryDetails,
		onRowHighlight,
		highlightedGroup,
		isWorkSummaryStatusCompleted,
		areFRsReadOnly,
		isWorkSummaryStatusReviewed,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		alternativeWorkSummary,
		workOrderCode,
		belongsToProject,
		handleJobReallocation,
		subJobs,
		onReallocateAll,
	} = props;

	const totalNonBillableWork = workSummary.reduce((_acc, _curr) => {
		return _acc + (_curr.total ?? 0);
	}, 0);
	const showHint = totalNonBillableWork === 0;

	const headerClassName = bemElement('field-report__work-summary__billable-work', 'header');
	const nonBillableWorkClassName = bemBlock('field-report__work-summary__billable-work', { 'non-billable': true });

	const onExportClick = React.useCallback(() => {
		const csvData = WorkSummaryVM.toCSVData(workSummary, belongsToProject);
		downloadCSV(csvData, `${workOrderCode}_non_billable_work.csv`);
	}, [belongsToProject, workOrderCode, workSummary]);

	const isWorkSummaryReadonly = isWorkSummaryStatusCompleted || areFRsReadOnly || isWorkSummaryStatusReviewed;

	return (
		<div className={nonBillableWorkClassName}>
			<div className={headerClassName}>
				<span className={bemElement(headerClassName, 'title')}>
					Non-Billable Work
				</span>
				<Button
					disabled={!workSummary.length}
					icon="download"
					label="Export"
					onClick={onExportClick}
					style="link"
				/>
			</div>
			<BillableAndNonBillableWorkTable
				alternativeWorkSummary={alternativeWorkSummary}
				belongsToProject={belongsToProject}
				billingCodes={billingCodes}
				handleJobReallocation={handleJobReallocation}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isBillable={false}
				isReadOnly={isWorkSummaryReadonly}
				onReallocateAll={onReallocateAll}
				onRowHighlight={onRowHighlight}
				subJobs={subJobs}
				workSummary={workSummary}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
			<div className={bemElement('field-report__work-summary__billable-work', 'total')}>
				{showHint && (
					<span className={bemElement('field-report__work-summary__billable-work__total', 'hint')}>
						Non-billable total is calculated after work with a billing code is moved to non-billable table
					</span>
				)}
				{`Non-billable total ${dollarFormatter.format(totalNonBillableWork)}`}
			</div>
		</div>
	);
};

export default React.memo(NonBillableWork);
