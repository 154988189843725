import { PlainUnitEnum } from '@acceligentllc/shared/enums/quantityUnit';

import type ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import type { CSVData, CSVRow } from 'ab-viewModels/csv.viewModel';

const PROPS = {
	LINE_ITEM_NUMBER: 'lineItemNumber',
	CUSTOMER_NUMBER: 'customerNumber',
	CUSTOMER_ID: 'customerId',
	OWNER_NUMBER: 'ownerNumber',
	OWNER_ID: 'ownerId',
	UNIT: 'unit',
	UNIT_PRICE: 'unitPrice',
	BID_QUANTITY: 'bidQuantity',
	GROUP: 'group',
	DESCRIPTION: 'description',
};

const parseRow = (row: CSVRow) => {
	const billingCode: ImportBillingCodeRM = {
		lineItemNumber: 0,
		customerNumber: null,
		customerId: '',
		ownerNumber: null,
		ownerId: null,
		unit: PlainUnitEnum.IN,
		unitPrice: '',
		bidQuantity: null,
		group: null,
		description: '',
	};

	Object.entries(row).forEach(([_column, _value]) => {
		if (!_value) {
			return;
		}

		const properties = _column.split('.');
		const [ccProp] = properties;

		switch (ccProp) {
			case PROPS.LINE_ITEM_NUMBER:
			case PROPS.CUSTOMER_NUMBER:
			case PROPS.BID_QUANTITY:
				billingCode[ccProp] = parseFloat(_value);
				break;
			default:
				billingCode[ccProp] = _value;
				break;
		}

	});

	return billingCode;
};

export default (data: CSVData): ImportBillingCodeRM[] => data.map(parseRow);
