import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	endTime: Nullable<string>;
	startTime: Nullable<string>;
	totalTime: number;
}

type Props = OwnProps;

const formatTime = (time: Nullable<string>) => {
	const formatted = time ? TimeUtils.formatDate(time, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : null;

	return !!formatted ? formatted : '-';
};

const TimeSheetTime: React.FC<Props> = (props) => {
	const { endTime, startTime, totalTime } = props;

	return (
		<div className="time-sheet-list__row__info__total-time-container">
			<div>
				<LockedValue label="Start" value={formatTime(startTime)} />
			</div>
			<div>
				{!!endTime && (
					<LockedValue
						label={'.'/** hack to align values from Locked values */}
						labelClassName="time-sheet-list__row__info__total-time-container-empty-label"
						value="-"
					/>
				)}
			</div>
			<div>
				<LockedValue label="Finish" value={formatTime(endTime)} />
			</div>
			<div /> {/* used to double the gap between items */}
			<div>
				<LockedValue label="Total" value={TimeUtils.minutesToHoursAndMinutes(totalTime)} />
			</div>
		</div>
	);
};

export default React.memo(TimeSheetTime);
