import * as React from 'react';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import { useToggle } from 'af-utils/react.util';

import TimeSheetSignatures from './TimeSheetSignatures';
import Timeline from '../TimeSheetRow/Timeline';
import OriginalShiftTime from './OriginalShiftTime';
import TimeSheetDurations from '../TimeSheetRow/TimeSheetDurations/TimeSheetDurations';
import TimelineHistoryModal from '../../Modals/TimelineHistoryModal';
import TimeSheetTimeSplits from '../TimeSheetRow/TimeSheetTimeSplits/TimeSheetTimeSplits';
import TimeSheetNoteModal from '../../Modals/TimeSheetNoteModal';

import styles from './styles.module.scss';

interface OwnProps {
	/** False if the user isn't on the latest published version of WO */
	timeSheet: TimeSheetVM;
	isOwner: boolean;
	isTemporaryEmployee: boolean;
	timeSheetEntities?: TimelineEntitesForAccount[];
	isManagerOrAdmin: boolean;
	isSuperintendent: boolean;
	overlaps: Nullable<Record<string, OverlapMeta>>;
	associatedJobId: number;
	timeZoneInUse: Nullable<string>;
	workOrderId: number;
	onPreview: (timeSheet: TimeSheetVM) => void;
}

type Props = OwnProps;

const TimeSheetVirtualRow: React.FC<Props> = (props) => {
	const {
		timeSheet,
		isOwner,
		isTemporaryEmployee,
		timeSheetEntities,
		isManagerOrAdmin,
		isSuperintendent,
		overlaps,
		associatedJobId,
		timeZoneInUse,
		workOrderId,
		onPreview,
	} = props;

	const {
		value: showTimelineHistoryModal,
		setToTrue: openTimelineHistoryModal,
		setToFalse: hideTimelineHistoryModal,
	} = useToggle(false);

	const {
		value: showNoteModal,
		setToTrue: openNoteModal,
		setToFalse: hideNoteModal,
	} = useToggle(false);

	const openPreview = React.useCallback(() => {
		onPreview(timeSheet);
	}, [onPreview, timeSheet]);

	const totalBreakDuration = React.useMemo(() => {
		return timeSheet.timeAllocationEntries.reduce((acc, entry) => {
			if (entry.workType === TimeSheetEntryWorkType.BREAK && entry.allocatedWorkRequestId === associatedJobId) {
				acc += entry.time;
			}
			return acc;
		}, 0);
	}, [timeSheet.timeAllocationEntries, associatedJobId]);

	const totalJobDuration = React.useMemo(() => {
		return timeSheet.timeAllocationEntries.reduce((acc, entry) => {
			if (entry.workType === TimeSheetEntryWorkType.JOB && entry.allocatedWorkRequestId === associatedJobId) {
				acc += entry.time;
			}
			return acc;
		}, 0);
	}, [timeSheet.timeAllocationEntries, associatedJobId]);

	const totalShopDuration = React.useMemo(() => {
		return timeSheet.timeAllocationEntries.reduce((acc, entry) => {
			if (entry.workType === TimeSheetEntryWorkType.SHOP && entry.allocatedWorkRequestId === associatedJobId) {
				acc += entry.time;
			}
			return acc;
		}, 0);
	}, [timeSheet.timeAllocationEntries, associatedJobId]);

	const totalTravelDuration = React.useMemo(() => {
		return timeSheet.timeAllocationEntries.reduce((acc, entry) => {
			if (entry.workType === TimeSheetEntryWorkType.TRAVEL && entry.allocatedWorkRequestId === associatedJobId) {
				acc += entry.time;
			}
			return acc;
		}, 0);
	}, [timeSheet.timeAllocationEntries, associatedJobId]);

	const totalTime = React.useMemo(() =>
		(timeSheet.totalJobDuration + timeSheet.totalShopDuration + timeSheet.totalTravelDuration),
		[timeSheet.totalJobDuration, timeSheet.totalShopDuration, timeSheet.totalTravelDuration]);

	const timeSplitEntries = React.useMemo(
		() => timeSheet.timeSplitEntries.filter((_tse) => _tse.allocatedWorkRequestId === associatedJobId),
		[associatedJobId, timeSheet.timeSplitEntries]);

	return (
		<div className={styles['virtual-row-container']}>
			<div className={styles['virtual-row-header']}>
				<span className={styles['virtual-row-header-name']}>
					{
						isTemporaryEmployee &&
						<span className={`icon-temp_labor ${styles['temp-labor-icon']}`} />
					}
					{timeSheet.userFullName}
				</span>
				<div className={styles['virtual-row-header-links']}>
					<div className={styles['virtual-row-header-link']} onClick={openTimelineHistoryModal}>
						<span className="icon-list" />
						View Timesheet history
					</div>
					<div className={styles['virtual-row-header-link']} onClick={openPreview}>
						<span className="icon-search" />
						Preview
					</div>
				</div>
			</div>
			<div className={styles['virtual-row-content-container']}>
				<div className={styles['virtual-row-signatures-container']}>
					<span className={styles['virtual-row-signatures-header']}>
						SIGNATURES
					</span>
					<TimeSheetSignatures
						approvalStatus={timeSheet.superintendentApprovalStatus}
						isOwner={isOwner}
						isTemporaryEmployee={isTemporaryEmployee}
						signatureStatus={timeSheet.employeeApprovalStatus}
					/>
				</div>
				<OriginalShiftTime
					endTime={timeSheet.endTime}
					note={timeSheet.note}
					showNoteModal={openNoteModal}
					startTime={timeSheet.startTime}
					totalTime={totalTime}
				/>
				<div className={styles['time-card-container']}>
					<div className={styles['time-card-header']}>TIME CARD</div>
					<TimeSheetDurations
						breakDuration={totalBreakDuration}
						jobDuration={totalJobDuration}
						shopDuration={totalShopDuration}
						showEquipmentTimeError={false}
						timeSplitEntries={timeSplitEntries}
						trackedEntry={null}
						travelDuration={totalTravelDuration}
					/>
					<TimeSheetTimeSplits
						isManagerOrAdmin={isManagerOrAdmin}
						isSuperintendent={isSuperintendent}
						showEquipmentActionButton={false}
						showEquipmentTimeError={false}
						timeSplitEntries={timeSplitEntries}
						totalJobDuration={totalJobDuration}
					/>
				</div>
			</div>
			<Timeline
				dueDate={timeSheet.dueDate}
				employeeApprovalStatus={timeSheet.employeeApprovalStatus}
				entities={timeSheetEntities!}
				isManagerOrAdmin={isManagerOrAdmin}
				isSuperIntendent={isSuperintendent}
				overlaps={overlaps}
				showTimeline={true}
				superintendentApprovalStatus={timeSheet.superintendentApprovalStatus}
			/>
			<TimelineHistoryModal
				accountId={timeSheet.accountId}
				closeModal={hideTimelineHistoryModal}
				showModal={showTimelineHistoryModal}
				timeZoneInUse={timeZoneInUse}
				workOrderId={workOrderId}
			/>
			<TimeSheetNoteModal
				accountId={timeSheet.accountId}
				closeModal={hideNoteModal}
				showModal={showNoteModal}
				workOrderId={workOrderId}
			/>
		</div>
	);
};

export default TimeSheetVirtualRow;
