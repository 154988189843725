import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import type * as TimeSheetEntryUtils from '@acceligentllc/shared/utils/timeSheetEntry';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';
import type TimeCardRejectedTimeSheetVM from 'ab-viewModels/timeSheet/timeCardRejectedTimeSheet.viewModel';

import CustomModal from 'af-components/CustomModal';

import TimeCard from './TimeCard';
import styles from './styles.module.scss';

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	openAssignUnassignedModal: () => void;
	accountId: number;
	userFullName: string;
	workOrderId: number;
	hasAvailableUnassignedEntries: number;
	hasUnassignedEntriesForMessage: number;
	allAccountEntries: TimeCardEntryVM[];
	showEquipmentTimeError: boolean;
	rejectedTSForAccount: TimeCardRejectedTimeSheetVM[];
	isApproved: boolean;
	overlaps: Nullable<Record<string, TimeSheetEntryUtils.OverlapMeta>>;
	timeOffset: number;
	timeZoneInUse: Nullable<string>;
}

type Props = OwnProps;

const MODAL_ALERT_TEXT = 'This view enables you to see field worker’s time card.\nFor any further actions and adjustments, click on the “Edit” button on time sheet.';

const TimeCardTroubleshootModal: React.FC<Props> = (props: Props) => {
	const {
		closeModal,
		openAssignUnassignedModal,
		userFullName,
		hasAvailableUnassignedEntries = 0,
		hasUnassignedEntriesForMessage = 0,
		allAccountEntries,
		rejectedTSForAccount,
		isApproved,
		overlaps,
		timeOffset,
		timeZoneInUse,
	} = props;

	return (
		<CustomModal
			modalStyle="info"
			showModal={true}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
			>
				<div className={styles.modalHeader}>
					<div>TIME CARD TROUBLESHOOTING VIEW | </div>
					<div className={styles.userName}>{`${userFullName}`}</div>
				</div>
			</CustomModal.Header>
			<CustomModal.Body
				className={styles.inheritedBodyProps}
			>
				{allAccountEntries === null || allAccountEntries === undefined ?
					<div className={styles.modalBody}>
						<div className={styles.noEntriesToShow}>
							NO ENTRIES TO SHOW
						</div>
					</div>
					:
					<div className={styles.modalBody}>
						<div className={styles.modalAlert}>
							{MODAL_ALERT_TEXT}
						</div>
						<TimeCard
							allAccountEntries={allAccountEntries}
							hasUnassignedEntries={hasAvailableUnassignedEntries}
							hasUnassignedEntriesForMessage={hasUnassignedEntriesForMessage}
							overlaps={overlaps}
							rejectedTSForAccount={rejectedTSForAccount}
							timeOffset={timeOffset}
							timeZoneInUse={timeZoneInUse}
						/>
					</div>
				}
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Close"
					onClick={closeModal}
					style="secondary"
				/>
				{!!hasAvailableUnassignedEntries && !isApproved &&
					<Button
						label="Assign Unassigned Entries"
						onClick={openAssignUnassignedModal}
						style="primary"
					/>
				}
			</CustomModal.Footer>
		</CustomModal >
	);
};

export default React.memo(TimeCardTroubleshootModal);
