import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import type { LogViewModel } from 'ab-viewModels/log.viewModel';

import * as LogActions from 'af-actions/log';

import type { TabProps, Column, RowInfo } from 'af-components/Table6';
import Table from 'af-components/Table6';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import DateCell from 'af-components/Table6/Cells/DateCell';
import ValueChangeCell from 'af-components/Table6/Cells/ValueChangeCell';
import Breadcrumbs from 'af-components/Breadcrumbs';

type OwnProps = CustomRouteComponentProps<void>;

type Props = ConnectedProps<typeof connector> & OwnProps;

class Logs extends React.Component<Props> {
	columns: Column<LogViewModel>[] = [
		{
			Header: 'Timestamp',
			accessor: 'timestamp',
			Cell: ({ original }) => (
				<DateCell
					date={original.timestamp}
					format={TimeFormatEnum.DATE_ONLY}
					isLeftAligned={true}
					withTime={true}
				/>
			),
		},
		{
			Header: 'Company',
			accessor: 'companyName',
			className: 'text-strong',
			Cell: ({ original }) => original.companyName,
		},
		{
			Header: 'Full Name',
			accessor: 'userName',
			Cell: ({ original }) => original.userName || <EmptyCell />,
		},
		{
			Header: 'Section',
			accessor: 'section',
			Cell: ({ original }) => {
				return (
					<div className="cell-multirow">
						<div>{original.section}</div>
						<div className="cell-additional-info">{original.action}</div>
					</div>
				);
			},
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ original }) => original.description ? <span className="cell-description">{original.description}</span> : <EmptyCell />,
		},
		{
			Header: 'Field',
			accessor: 'field',
			Cell: ({ original }) => {
				return (
					original.field || original.newValue || original.oldValue
						? (
							<ValueChangeCell
								label={original.field}
								newValue={original.newValue}
								oldValue={original.oldValue}
							/>
						)
						: <EmptyCell message="No change" />
				);
			},
		},
	];

	onRowClick = ({ original }: RowInfo<LogViewModel>) => {
		const { location: { state: { orgAlias } }, history } = this.props;

		if (original?.id) {
			history.push(CLIENT.ORGANIZATION.SETTINGS.LOGS.PREVIEW(orgAlias, original.id.toString()));
		}
	};

	tabs = (): TabProps<LogViewModel>[] => {
		const { download, fetch, location: { state: { orgAlias } } } = this.props;
		const fileName = `${orgAlias}.logs.zip`;

		return [
			{
				label: 'Organization Logs',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Logs',
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: async () => download(fileName),
				}],
				fetch,
				onRowClick: this.onRowClick,
			},
		];
	};

	breadcrumbs = () => [{ label: 'Logs' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.ORGANIZATION_LOGS}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		download: LogActions.downloadForOrganization,
		fetch: LogActions.findAllForOrganizationTable,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(Logs);
