import * as React from 'react';

import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import ConfirmationModal from 'af-components/ConfirmationModal';

interface OwnProps {
	showModal: boolean;
	dueDate?: string;
	onClose: () => void;
	onSubmit: () => void;
	type: Nullable<UnavailabilityReasonScope>;
}

type Props = OwnProps;

class ResourceDownConfirmationModal extends React.PureComponent<Props> {

	static readonly TITLE: { [T in keyof typeof UnavailabilityReasonScope]: string; } = {
		[UnavailabilityReasonScope.EQUIPMENT]: 'Down Equipment',
		[UnavailabilityReasonScope.EMPLOYEE]: 'Set Labor to Unavailable Status',
	};

	static readonly BODY: { [T in keyof typeof UnavailabilityReasonScope]: (string) => JSX.Element; } = {
		[UnavailabilityReasonScope.EQUIPMENT]: (dueDate: string) => (
			TimeUtils.formatDate(new Date()) === dueDate
				? <span>This will remove all <b>unpublished</b> assignments from this equipment piece from this moment on.</span>
				: <span>This will remove all <b>unpublished</b> assignments from this equipment piece for <b>{dueDate}</b>.</span>
		),
		[UnavailabilityReasonScope.EMPLOYEE]: (dueDate: string) => (
			TimeUtils.formatDate(new Date()) === dueDate
				? <span>This will remove all <b>unpublished</b> assignments from this laborer from this moment on.</span>
				: <span>This will remove all <b>unpublished</b> assignments from this laborer for <b>{dueDate}</b>.</span>
		),
	};

	render() {
		const {
			dueDate,
			showModal,
			onClose,
			onSubmit,
			type,
		} = this.props;

		if (!showModal || !type) {
			return null;
		}

		const message = ResourceDownConfirmationModal.BODY[type]?.(dueDate);

		return (
			<ConfirmationModal
				body={message}
				closeModal={onClose}
				confirmAction={onSubmit}
				confirmText="Confirm"
				hideOnConfirm={false}
				modalStyle="danger"
				showModal={showModal}
				title={ResourceDownConfirmationModal.TITLE[type]}
			/>
		);
	}
}

export default ResourceDownConfirmationModal;
