import * as React from 'react';
import type { WrappedFieldProps, WrappedFieldInputProps } from 'redux-form';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import HorizontalAlignment from 'ab-enums/horizontalAlignment.enum';

import type { Props as PlainDateInputProps } from '../Controls/DateInput';
import PlainDateInput from '../Controls/DateInput';

type Props = WrappedFieldProps & WrappedFieldInputProps & PlainDateInputProps;

class DateInput extends React.Component<Props> {

	static defaultProps: Partial<Props> = {
		dateFormat: TimeFormatEnum.DATE_ONLY,
		originalDateFormat: TimeFormatEnum.ISO_DATETIME,
		locale: enUs,
		horizontalAlignment: HorizontalAlignment.LEFT,
		popperPlacement: 'bottom',
	};

	handleChange = (changedDate: Date, event: React.ChangeEvent<HTMLInputElement>) => {
		const { input, onValueChange } = this.props;

		input.onChange(event);

		onValueChange?.(changedDate);
	};

	handleBlur = () => {
		const { input } = this.props;
		input.onBlur(undefined);
	};

	handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const { input } = this.props;
		input.onFocus(event);
	};

	getClassName = () => {
		const { meta: { error, touched }, datePickerClassName } = this.props;
		const cn = `form-control ${datePickerClassName}`;

		return touched && error ? `${cn} with-error` : cn;
	};

	getBlock = () => {
		const { meta: { touched, error, warning } } = this.props;
		return (
			touched && (
				(error && <span className="help-block"><span className="icon-info" /> {error}</span>) ||
				(warning && <span className="help-block text-orange"><span className="icon-info" /> {warning}</span>)
			)
		);
	};

	render() {
		const {
			id,
			disabled,
			input,
			label,
			isRequired,
			dateFormat,
			originalDateFormat,
			locale,
			horizontalAlignment,
			calendarSettings,
			placeholderText,
			suggestionText,
			isConnected,
			popperPlacement,
			wrapperId,
			fixed,
			tooltipMessage,
			isClearable,
			isStandalone,
			withAppend,
			wrapperClassName,
			defaultValue,
		} = this.props;

		return (
			<PlainDateInput
				block={this.getBlock()}
				calendarSettings={calendarSettings}
				dateFormat={dateFormat}
				datePickerClassName={this.getClassName()}
				defaultValue={defaultValue}
				disabled={disabled}
				fixed={fixed}
				handleSelect={this.handleChange}
				horizontalAlignment={horizontalAlignment}
				id={id}
				isClearable={isClearable}
				isConnected={isConnected}
				isRequired={isRequired}
				isStandalone={isStandalone}
				label={label}
				locale={locale}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				originalDateFormat={originalDateFormat}
				placeholderText={placeholderText}
				popperPlacement={popperPlacement}
				selectedValue={input.value}
				suggestionText={suggestionText}
				tooltipMessage={tooltipMessage}
				withAppend={withAppend}
				wrapperClassName={wrapperClassName}
				wrapperId={wrapperId}
			/>
		);
	}
}

export default DateInput;
