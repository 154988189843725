import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

interface Props {
	disabled: boolean;
	index: number;
	removeWorkOrderResourceLookup: (index: number) => void;
}

export default class RemoveResourceLookupButton extends React.PureComponent<Props> {

	removeWorkOrderResourceLookup = () => {
		const { index, removeWorkOrderResourceLookup } = this.props;
		removeWorkOrderResourceLookup(index);
	};

	render() {
		const { disabled } = this.props;

		return !disabled && (
			<Button
				icon="delete"
				onClick={this.removeWorkOrderResourceLookup}
				style="link-danger"
				tooltip="Delete"
			/>
		);
	}

}
