import type FieldReportAccessRoleEnum from '@acceligentllc/shared/enums/fieldReportAccessRole';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';
import type TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import { mapTimeSheetApprovalStatus } from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import type { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import type TimeSheetBase from 'ab-domain/models/timeSheet/base';

import type ReportTypeViewTableWebBase from 'ab-domain/views/reportTypeTable/base';

export class W_ReportType_FindReportTypeViewTable_VM extends TableContent<W_ReportType_FindReportTypeViewTable_VM_Row>{
	constructor(
		timeSheetsByWorkOrderId: Record<string, TimeSheetBase[]>,
		reportTypeWorkOrders: ReportTypeViewTableWebBase[],
		pages: number,
		totalCount: number
	) {
		super(
			W_ReportType_FindReportTypeViewTable_VM_Row.bulkConstructor(reportTypeWorkOrders, timeSheetsByWorkOrderId),
			pages,
			totalCount
		);
	}
}

class W_ReportType_FindReportTypeViewTable_VM_TimeSheet {
	accountId: number;
	approvalStatus: TimeSheetApprovalStatus;

	constructor(timeSheet: TimeSheetBase) {
		this.accountId = timeSheet.accountId;
		this.approvalStatus = mapTimeSheetApprovalStatus(timeSheet.approvalStatus);
	}

	static bulkConstructor = (timeSheets: TimeSheetBase[]) => timeSheets.map(W_ReportType_FindReportTypeViewTable_VM_TimeSheet._constructorMap);

	private static _constructorMap = (timeSheet: TimeSheetBase) => new W_ReportType_FindReportTypeViewTable_VM_TimeSheet(timeSheet);
}

export class W_ReportType_FindReportTypeViewTable_VM_Row {
	id: number;
	fieldReportId: number;
	reportTypeId: number;
	reportTypeName: string;
	workOrderId: number;
	companyId: number;
	companyName: number;
	/** YYYY-MM-DD */
	startDate: string;
	fieldReportAccessRole: Nullable<FieldReportAccessRoleEnum>;
	jobCode: string;
	reviewLevel: WorkOrderReviewLevel;
	reviewStatus: WorkOrderReviewStatus;
	status: WorkOrderStatus;
	calculatedReportDisplayStatus: CalculatedReportDisplayStatus;
	workOrderCode: string;
	workRequestId: number;
	assignedToIds: number[];
	timeSheets: W_ReportType_FindReportTypeViewTable_VM_TimeSheet[];

	constructor(reportTypeWorkOrder: ReportTypeViewTableWebBase, timeSheets: TimeSheetBase[]) {
		this.id = reportTypeWorkOrder.id;
		this.fieldReportId = reportTypeWorkOrder.fieldReportId;
		this.reportTypeId = reportTypeWorkOrder.reportTypeId;
		this.reportTypeName = reportTypeWorkOrder.reportTypeName;
		this.workOrderId = reportTypeWorkOrder.workOrderId;
		this.companyId = reportTypeWorkOrder.companyId;
		this.companyName = reportTypeWorkOrder.companyName;
		this.jobCode = reportTypeWorkOrder.jobCode;
		this.startDate = reportTypeWorkOrder.dueDate;
		this.fieldReportAccessRole = reportTypeWorkOrder.fieldReportAccessRole;
		this.reviewLevel = reportTypeWorkOrder.reviewLevel;
		this.reviewStatus = reportTypeWorkOrder.reviewStatus;
		this.status = reportTypeWorkOrder.status;
		this.calculatedReportDisplayStatus = reportTypeWorkOrder.calculatedReportDisplayStatus;
		this.workOrderCode = reportTypeWorkOrder.workOrderCode;
		this.workRequestId = reportTypeWorkOrder.workRequestId;
		this.assignedToIds = reportTypeWorkOrder.assignedToIds.reduce((_acc, _curr) => {
			if (_curr) {
				_acc.push(_curr);
			}

			return _acc;
		}, [] as number[]);
		this.timeSheets = W_ReportType_FindReportTypeViewTable_VM_TimeSheet.bulkConstructor(timeSheets);
	}

	static bulkConstructor = (
		reportTypeWorkOrders: ReportTypeViewTableWebBase[],
		timeSheetsByWorkOrderId: Record<string, TimeSheetBase[]>
	) =>
		reportTypeWorkOrders.map((_wr) => new W_ReportType_FindReportTypeViewTable_VM_Row(_wr, timeSheetsByWorkOrderId[`${_wr.workOrderId}`] ?? [])
		);
}

