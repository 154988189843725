import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type TimeSheetEntryHistoryVM from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';
import { TimeSheetEntryHistoryItemVM } from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';

import * as TimeSheetActions from 'af-actions/timeSheet';

import CustomModal from 'af-components/CustomModal';
import LoadingIndicator from 'af-components/LoadingIndicator';

import TimeSheetEntryHistorySection from './TimeSheetEntryHistorySection';
import TimeSheetEntryHistoryDeletedSection from './TimeSheetEntryHistoryDeletedSection';

interface OwnProps {
	accountId: Nullable<number>;
	closeModal: () => void;
	showModal: boolean;
	timeZoneInUse: Nullable<string>;
	workOrderId: Nullable<number>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const revertOffsetTimelineForTimeZone = (history: TimeSheetEntryHistoryVM, timeZone: Nullable<string>): TimeSheetEntryHistoryVM => {
	const deletedEntries = history?.deletedEntries
		? TimeSheetEntryHistoryItemVM.bulkRevertOffsetTimelineEntriesForTimeZone(history.deletedEntries, timeZone)
		: null;
	const activeEntries = history.activeEntries
		? TimeSheetEntryHistoryItemVM.bulkRevertOffsetTimelineEntriesForTimeZone(history.activeEntries, timeZone)
		: null;

	return {
		deletedEntries,
		activeEntries,
	};
};

const TimelineHistoryModal: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, findHistory, workOrderId, accountId, timeZoneInUse } = props;

	const [isStateInitialized, setIsStateInitialized] = React.useState(workOrderId !== null || accountId !== null);
	const [history, setHistory] = React.useState<Nullable<TimeSheetEntryHistoryVM>>(null);

	React.useEffect(() => {
		// Reset initial state on workRequestId change

		if (workOrderId !== null && accountId !== null) {
			setIsStateInitialized(true);
		} else {
			setIsStateInitialized(false);
			setHistory(null);
		}
	}, [workOrderId, accountId]);

	React.useEffect(() => {

		if (!isStateInitialized || workOrderId === null || accountId === null || !showModal) {
			// Not ready to fetch data
			return;
		}

		const fetchData = async () => {
			setHistory(null);

			const response = await findHistory(workOrderId, accountId);
			setHistory(revertOffsetTimelineForTimeZone(response, timeZoneInUse));
		};
		fetchData();
	}, [workOrderId, accountId, isStateInitialized, findHistory, showModal, timeZoneInUse]);

	const isLoading = showModal && (!isStateInitialized || !history);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Timeline History"
			/>
			<CustomModal.Body>
				{isLoading
					? <LoadingIndicator color="orange" size="small" />
					:
					<div className="timeline-history-modal__container">
						<TimeSheetEntryHistorySection
							items={history?.activeEntries ?? null}
						/>
						<TimeSheetEntryHistoryDeletedSection
							items={history?.deletedEntries ?? null}
						/>
					</div>
				}
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Close"
					onClick={closeModal}
					style="secondary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		findHistory: TimeSheetActions.findWorkOrderTimeSheetEntriesHistoryForAccount,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(TimelineHistoryModal);
