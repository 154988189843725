import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';

import AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';

import * as AccountActions from 'af-actions/accounts';
import * as LocationActions from 'af-actions/location';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import { MEMBER_EDIT } from 'af-constants/reduxForms';

import { validate } from './validations';

import Breadcrumbs from 'af-components/Breadcrumbs';

import MemberForm from '../Form';
import MemberInfo from '../Form/UserInfo';
import FormModel from '../Form/formModel';

interface PathParams {
	memberId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & InjectedFormProps<FormModel>;

interface State {
	image: File | string | null;
	imageUrl: string | null;
	deleteImage: boolean;
}

class CompanyMemberEdit extends React.PureComponent<Props, State> {
	state: State = {
		image: null,
		imageUrl: null,
		deleteImage: false,
	};

	async componentDidMount() {
		const { getMember, match: { params: { memberId } }, initialize } = this.props;
		const form = await getMember(+memberId);

		if (!form) {
			throw new Error('Unable to initialize form');
		}

		this.setState(() => ({ imageUrl: form.user.imageUrl }));
		initialize(FormModel.fromViewModel(form));
	}

	submit = async (data: FormModel) => {
		const { editMember } = this.props;
		const { deleteImage, image } = this.state;

		const form = FormModel.toEditRM(data);
		form.imageUrl = deleteImage ? null : image as string;
		form.deleteImage = deleteImage;
		await editMember(form);
		this.goBack();
	};

	goBack = () => {
		const { location: { state: { orgAlias } }, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName));
	};

	deleteImage = () => {
		this.setState(() => ({ image: null, imageUrl: null, deleteImage: true }));
	};

	onImageUploadSave = (image: File, imageUrl: string) => {
		this.setState(() => ({
			image,
			imageUrl,
			deleteImage: false,
		}));
	};

	render() {
		const {
			handleSubmit,
			submitting,
			change,
			invalid,
			offlinePin,
			companyName,
			subscriptionStatus,
			isCompanyAdmin,
			permissions,
			accountTemplate,
			assignableToWorkOrder,
			assignableAsSuperintendent,
			assignableAsAccounting,
			assignableAsManagement,
			assignableAsTechnician,
			findAllLocations,
			location: { state: { orgAlias } },
			invalidEmail,
			invalidPhoneNumber,
			activeInLMS,
		} = this.props;
		const { image, imageUrl } = this.state;

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Users', url: CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName) },
							{ label: 'Edit' },
						]
					}
				/>
				<MemberForm
					accountTemplate={accountTemplate}
					activeInLMS={activeInLMS}
					assignableAsAccounting={assignableAsAccounting}
					assignableAsManagement={assignableAsManagement}
					assignableAsSuperintendent={assignableAsSuperintendent}
					assignableAsTechnician={assignableAsTechnician}
					assignableToWorkOrder={assignableToWorkOrder}
					change={change}
					invalid={invalid}
					invalidEmail={invalidEmail}
					invalidPhoneNumber={invalidPhoneNumber}
					isCompanyAdmin={isCompanyAdmin}
					onCancel={this.goBack}
					onSubmit={handleSubmit(this.submit)}
					permissions={permissions}
					showActivateImmediately={false}
					submitting={submitting}
				>
					<MemberInfo
						deleteImage={this.deleteImage}
						image={image}
						imageUrl={imageUrl}
						isEmailInvalid={invalidEmail}
						isPhoneNumberInvalid={invalidPhoneNumber}
						loadLocations={findAllLocations}
						offlinePin={offlinePin}
						onImageUploadSave={this.onImageUploadSave}
						subscriptionStatus={subscriptionStatus}
					/>
				</MemberForm>
			</div>
		);
	}

}

const selector = formValueSelector(MEMBER_EDIT);
const errorSelector = getFormSyncErrors(MEMBER_EDIT);

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const {
		subscriptionStatus,
		permissions,
		offlinePin = null,
		accountTemplateId,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		user,
		existingUser,
	} = selector(
		state,
		'subscriptionStatus',
		'permissions',
		'offlinePin',
		'accountTemplateId',
		'assignableToWorkOrder',
		'assignableAsSuperintendent',
		'assignableAsAccounting',
		'assignableAsManagement',
		'assignableAsTechnician',
		'user',
		'existingUser'
	);

	const phoneNumber = user?.phoneNumber ?? existingUser?.phoneNumber;
	const email = user?.email ?? existingUser?.email;
	const { user: userErrors } = errorSelector(state) as FormModel;
	return {
		companyName: companyData.name,
		offlinePin,
		isCompanyAdmin: accountTemplateId === AccountPermissionTemplate.ADMIN,
		subscriptionStatus,
		accountTemplate: accountTemplateId,
		permissions,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		invalidEmail: !email || !!userErrors?.email,
		invalidPhoneNumber: !phoneNumber || !!userErrors?.phoneNumber,
		activeInLMS: user?.activeInLMS,
	};
}

function mapDispatchToProps() {
	return {
		findAllLocations: LocationActions.findList,
		getMember: AccountActions.findById,
		editMember: AccountActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<FormModel, FormOwnProps>({ form: MEMBER_EDIT, validate })
);

export default enhance(CompanyMemberEdit);
