import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { Option } from 'react-select/src/filters';

import { EquipmentCostCategoryLabel } from '@acceligentllc/shared/enums/equipmentCostCategory';

import type { EquipmentCostViewModel } from 'ab-viewModels/equipmentCost.viewModel';
import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type { LocationViewModel } from 'ab-viewModels/location/location.viewModel';
import type * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import StatusLabel from 'af-components/StatusLabel';
import SegmentLabel from 'af-components/SegmentLabel';

// Equipment Cost

export const getEquipmentCostOptionValue = (option: EquipmentCostViewModel) => `${option.id}`;

export const getEquipmentCostOptionLabel = (option: EquipmentCostViewModel) => {
	const text = `${option.subcategory}`;
	const smallText = `${option.type && EquipmentCostCategoryLabel[option.type]}, ${option.groupName}, ${option.categoryName} `;
	return `${text} ${smallText}`;
};

export const formatEquipmentCostOptionLabel = (option: EquipmentCostViewModel) => {
	const text = `${option.subcategory}`;
	const smallText = `${option.type && EquipmentCostCategoryLabel[option.type]}, ${option.groupName}, ${option.categoryName} `;
	return (
		<div>
			<div>{text}</div>
			<small className="text-grey">{smallText}</small>
		</div>
	);
};

export const getTextForEquipmentCost = (data: EquipmentCostViewModel) => {
	const { subcategory } = data;
	return `${subcategory}`;
};

export const filterEquipmentCosts = (option: Option, text: string) => {
	const loweredText = text ? text.toLowerCase() : '';
	const searchableText = getTextForEquipmentCost(option.data);
	return searchableText.toLowerCase().includes(loweredText);
};

// Location

export const getLocationOptionValue = (option: LocationViewModel) => `${option.id}`;

export const getLocationOptionLabel = (option: LocationViewModel) => `${option.nickname} ${option?.address?.street ?? ''}`;

export const formatLocationOptionLabel = (option: LocationViewModel) => {
	return (
		<div>
			<div>{option.nickname}</div>
			<small>{option?.address?.street ?? ''}</small>
		</div>
	);
};

export const getTextForLocation = (data: LocationViewModel) => `${data.nickname}`;

export const filterLocations = (option: Option, text: string) => {
	const loweredText = text ? text.toLowerCase() : '';
	const searchableText = getTextForLocation(option.data);
	return searchableText.toLowerCase().includes(loweredText);
};

// Skills

export const getSkillOptionValue = (option: SkillViewModel) => `${option.id}`;

export const getSkillOptionLabel = (option: SkillViewModel) => option.name;

// Equipment Status

export const renderResourceStatusMenuItem = ({ available, name }: ResourceStatusesViewModel.Item) => <StatusLabel isAvailable={available} label={name} />;

// Other

export const renderSectionTitle = (title: string) => {
	return (
		<Row>
			<Col sm={12}>
				<SegmentLabel label={title} />
			</Col>
		</Row>
	);
};
