import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import CustomModal from 'af-components/CustomModal';

import * as EmployeeActions from 'af-actions/employee';

import RelatedWorkOrders from 'af-root/scenes/Company/Resources/Shared/RelatedWorkOrders';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import TableNameEnum from 'ab-enums/tableName.enum';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import styles from './styles.module.scss';

interface OwnProps {
	companyName: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EmployeeWorkOrderHistoryModal: React.FC<Props> = (props: Props) => {
	const {
		companyName,
		findRelatedWorkOrders,
	} = props;

	const { employee, showEmployeeWOHistoryModal, setEmployeeWOHistoryModalVisibility, setEmployeeModalVisibility } = useScheduleBoardModals();

	const fetchWorkOrders = React.useCallback((tableRequestModel: TableQuery, startDate: Date, endDate: Date) => {
		if (!employee?.id) {
			throw new Error('Missing employee data');
		}
		return findRelatedWorkOrders(employee.id, tableRequestModel, startDate, endDate);
	}, [employee?.id, findRelatedWorkOrders]);

	const closeModal = React.useCallback(() => {
		setEmployeeWOHistoryModalVisibility(false);
		setEmployeeModalVisibility(true);
	}, [setEmployeeWOHistoryModalVisibility, setEmployeeModalVisibility]);

	if (!employee) {
		return null;
	}

	const { firstName, lastName, formattedCode } = employee;

	const baseClassName = 'employee-wo-history-modal';
	const headerClassName = `${baseClassName}__big-header`;
	const headerLeft = `${baseClassName}__big-header-left`;
	const headerRight = `${baseClassName}__big-header-right`;
	const iconClassName = 'header-close-icon';
	const modalBody = 'modal-body';

	return (
		<>
			<CustomModal
				className={styles[baseClassName]}
				closeModal={closeModal}
				modalStyle="info"
				showModal={showEmployeeWOHistoryModal}
			>
				<CustomModal.Header className={styles[headerClassName]}>
					<div className={`${styles[headerLeft]} `}>
						<span>{` WORK ORDER HISTORY | ${firstName} ${lastName}`}</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<span
							className={`icon-close ${styles[iconClassName]}`}
							onClick={closeModal}
						/>
					</div>
				</CustomModal.Header>
				<CustomModal.Body className={`${styles[modalBody]} `}>
					<RelatedWorkOrders
						companyName={companyName}
						csvName={`${companyName}_${formattedCode}_work_orders.csv`}
						fetchWorkOrders={fetchWorkOrders}
						offsetHeight={260}
						tableName={TableNameEnum.EMPLOYEE_WORK_ORDERS}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Close"
						onClick={closeModal}
						style="secondary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		</>
	);
};

const mapDispatchToProps = () => {
	return {
		findRelatedWorkOrders: EmployeeActions.findRelatedWorkOrders,
	};
};

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(EmployeeWorkOrderHistoryModal);
