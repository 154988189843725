import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useOutletContext } from 'react-router-dom-v5-compat';

import type FileType from '@acceligentllc/shared/enums/fileType';

import * as WorkRequestActions from 'af-actions/job';
import * as AttachmentActions from 'af-actions/attachment';

import DirectoriesAttachmentTree from 'af-components/DocumentsAttachmentsTree';
import EditAttachmentModal from 'af-components/DocumentsAttachmentsTree/TreeTableBody/EditAttachmentModal';
import ConfirmationModal from 'af-components/ConfirmationModal';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import type WorkRequestDirectoryVM from 'ab-viewModels/directory/workRequestDirectory.viewModel';
import type AttachmentVM from 'ab-viewModels/attachment/attachment.viewModel';

import CreateNewDirectoryModal from '../../../scenes/Company/Jobs/Preview/Attachments/CreateNewDirectoryModal';

type AttachmentVMExtended = AttachmentVM & {
	directoryId?: number;
};

interface TypedColumn {
	header: string;
	id?: string;
	width?: number;
}

interface DirectoriesAttachmentsOutletContext {
	jobId: number;
	hideCopyToWO?: boolean;
	hideAttachedToWO?: boolean;
	isReadOnly?: boolean;
}
interface RowActionOptions {
	actionName: string;
	actionFunction: (params: unknown) => void;
	disabled?: boolean;
	show?: boolean;
}

interface RowActions {
	parentDirectory: RowActionOptions[];
	childDirectory: RowActionOptions[];
	attachmentLevel: RowActionOptions[];
}

type Props = ConnectedProps<typeof connector>;

const DirectoriesAttachments = React.memo((props: Props) => {
	const {
		getWorkRequestDirectoriesAttachments,
		renameAttachment,
		downloadAttachment,
		deleteAttachment,
		createDirectory,
		deleteDirectory,
		changeAttachmentParent,
	} = props;
	const [directoriesAndAttachments, setDirectoriesAndAttachments] = React.useState<Nullable<WorkRequestDirectoryVM[]>>(null);
	const [fileToRename, setFileToRename] = React.useState<{
		attachmentId: Nullable<number>;
		attachmentName: string;
		attachmentType: Nullable<FileType>;
	}>({ attachmentId: null, attachmentName: '', attachmentType: null });
	const [directoryId, setDirectoryId] = React.useState<Nullable<number>>(null);
	const [editAttachmentNameModalOpen, setEditAttachmentNameModalOpen] = React.useState(false);
	const [deleteDirectoryModalOpen, setdeleteDirectoryModalOpen] = React.useState(false);
	const [parentDirectoryForCreate, setParentDirectoryForCreate] = React.useState<Nullable<WorkRequestDirectoryVM>>(null);

	const notificationSnackbar = useNotificationSnackbar();
	const { jobId, hideAttachedToWO, hideCopyToWO, isReadOnly = false } = useOutletContext<DirectoriesAttachmentsOutletContext>();

	const fetchData = React.useCallback(async () => {
		const fetchedData = await getWorkRequestDirectoriesAttachments(+jobId);
		setDirectoriesAndAttachments(fetchedData);
	}, [getWorkRequestDirectoriesAttachments, jobId]);

	React.useEffect(() => {
		fetchData();
	}, [fetchData]);

	const createNewDirectory = React.useCallback(async (parent: WorkRequestDirectoryVM, name: string) => {
		await createDirectory(jobId, parent.id, name);
		fetchData();
	}, [createDirectory, fetchData, jobId]);

	const updateAttachmentName = async (id: number, name: string) => {
		await renameAttachment(id, name);
		fetchData();
	};

	const callChangeName = async (original: AttachmentVMExtended) => {
		const { id, name } = original;
		const dotIndex = original.name.lastIndexOf('.');
		const attachmentNameWithoutType = (dotIndex !== -1) ? name.substring(0, dotIndex) : name;
		setFileToRename({ attachmentId: id, attachmentName: attachmentNameWithoutType, attachmentType: original.type });
		setEditAttachmentNameModalOpen(!editAttachmentNameModalOpen);
	};

	const callDownloadAttachment = (original: AttachmentVM) => {
		const dotIndex = original.name.lastIndexOf('.');
		const attachmentNameWithoutType = (dotIndex !== -1) ? original.name.substring(0, dotIndex) : original.name;
		const fileName = `${attachmentNameWithoutType}${original.type}`;
		downloadAttachment(original.id, fileName);
	};

	const removeAttachmentFromDirectory = async (original: AttachmentVMExtended) => {
		const { id, directoryId: attachmentDirectoryId } = original;
		if (attachmentDirectoryId) {
			await deleteAttachment(id, attachmentDirectoryId);
		}
		fetchData();
		notificationSnackbar.success('Attachment successfully deleted', new Date());
	};

	const deleteOneDirectory = async () => {
		if (directoryId) {
			await deleteDirectory(jobId, directoryId);
		}
		setdeleteDirectoryModalOpen(false);
		notificationSnackbar.success('Directory successfully deleted', new Date());
		fetchData();
	};

	const callDeleteDirectory = async (original: WorkRequestDirectoryVM) => {
		const { id } = original;

		setdeleteDirectoryModalOpen(true);
		setDirectoryId(id);
	};

	const closeCreateModal = React.useCallback(() => {
		setParentDirectoryForCreate(null);
	}, []);

	const handleAttachmentParentChange = React.useCallback(async (attachmentId: number, sourceDirectoryId: number, destinationDirectoryId: number) => {
		await changeAttachmentParent(attachmentId, sourceDirectoryId, destinationDirectoryId);
		fetchData();
	}, [changeAttachmentParent, fetchData]);

	const rowActions: RowActions = {
		parentDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			/**
			 * To grey out (disable) action
			 */
			disabled: isReadOnly,
		}],
		childDirectory: [{
			actionName: 'New folder',
			actionFunction: setParentDirectoryForCreate,
			disabled: isReadOnly,
			show: true,
		},
		{
			actionName: 'Delete',
			actionFunction: callDeleteDirectory,
			disabled: isReadOnly,
			show: true,
		}],
		attachmentLevel: [{
			actionName: 'Rename',
			actionFunction: callChangeName,
			show: true,
		},
		{
			actionName: 'Download',
			actionFunction: callDownloadAttachment,
			show: true,
		},
		{
			actionName: 'Delete',
			actionFunction: removeAttachmentFromDirectory,
			show: true,
		}],
	};

	const columns = React.useMemo<TypedColumn[]>(() => {
		const columnData = [
			{
				header: 'Category folder',
				id: 'categoryFolder',
				width: 6,
			},
			{
				header: 'Files',
				id: 'files',
				width: 1,
			},
			{
				header: 'Download',
				id: 'download',
				width: 1,
			},
		];
		if (!isReadOnly) {
			columnData.push({
				header: 'Upload',
				id: 'upload',
				width: 1,
			});
		}
		columnData.push({
			header: 'Preview',
			id: 'preview',
			width: 1,
		});
		if (!hideCopyToWO) {
			columnData.push({
				header: 'Copy to Work Orders',
				id: 'copyToWO',
				width: 2,
			});
		}
		if (!hideAttachedToWO) {
			columnData.push({
				header: 'Attached to WO',
				id: 'attachedToWO',
				width: 2,
			});
		}
		columnData.push({
			header: 'Last modified',
			id: 'lastModified',
			width: 2,
		});
		return columnData;
	}, [hideAttachedToWO, hideCopyToWO, isReadOnly]);

	return (
		<>
			<DirectoriesAttachmentTree
				columns={columns}
				dataTree={directoriesAndAttachments}
				fetchData={fetchData}
				handleAttachmentParentChange={handleAttachmentParentChange}
				id={jobId}
				isReadOnly={isReadOnly}
				rowActions={rowActions}
			/>
			{editAttachmentNameModalOpen &&
				<EditAttachmentModal
					attachmentData={fileToRename}
					setModalVisible={setEditAttachmentNameModalOpen}
					showModal={editAttachmentNameModalOpen}
					updateAttachment={updateAttachmentName.bind(this)}
				/>
			}
			<CreateNewDirectoryModal
				closeModal={closeCreateModal}
				createDirectory={createNewDirectory}
				parent={parentDirectoryForCreate}
			/>
			{deleteDirectoryModalOpen &&
				<ConfirmationModal
					body="You are about to permanently delete the Directory and all the files in it. Are you sure you want to continue?"
					closeModal={setdeleteDirectoryModalOpen.bind(this, false)}
					confirmAction={deleteOneDirectory.bind(this)}
					confirmText="Delete"
					modalStyle="danger"
					showModal={deleteDirectoryModalOpen}
					title={'Delete Directory'}
				/>
			}
		</>
	);
});

function mapDispatchToProps() {
	return {
		getWorkRequestDirectoriesAttachments: WorkRequestActions.getWorkRequestDirectoriesAttachments,
		renameAttachment: AttachmentActions.renameAttachment,
		downloadAttachment: AttachmentActions.downloadAttachment,
		deleteAttachment: AttachmentActions.deleteAttachment,
		changeAttachmentParent: AttachmentActions.changeAttachmentParent,
		uploadAttachmentsToDirectory: AttachmentActions.uploadAttachmentsToDirectory,
		createDirectory: WorkRequestActions.createDirectory,
		deleteDirectory: WorkRequestActions.deleteDirectory,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(DirectoriesAttachments);
