/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { HALF_MINUTE, TIMEOUT_DELAY } from 'af-constants/values';

export default class Timeout {
	private _timeoutTimer: number;
	private _onTimeout: () => void;
	private _timeoutDelay: number;

	constructor(onTimeout: () => void, timeoutDelay: number = TIMEOUT_DELAY) {
		this._onTimeout = onTimeout;
		this._timeoutDelay = timeoutDelay;
		this.reset();
	}

	cancel = (): void => {
		if (this._timeoutTimer) {
			window.clearTimeout(this._timeoutTimer);
		}
	};

	reset = (defaultTimeoutOverride?: () => void): void => {
		this.cancel();

		if (defaultTimeoutOverride) {
			this._onTimeout = defaultTimeoutOverride;
		}

		this._timeoutTimer = window.setTimeout(() => {
			if (this._onTimeout) {
				this._onTimeout();
			}
		}, this._timeoutDelay);
	};
}

export class Interval {
	private _intervalTimer: number;
	private _onInterval: () => void;
	private _intervalDelay: number;

	constructor(onInterval: () => void, intervalDelay: number = HALF_MINUTE) {
		this._onInterval = onInterval;
		this._intervalDelay = intervalDelay;
		this.reset();
	}

	cancel = (): void => {
		if (this._intervalTimer) {
			window.clearInterval(this._intervalTimer);
		}
	};

	reset = (): void => {
		this.cancel();

		this._intervalTimer = window.setInterval(() => {
			if (this._onInterval) {
				this._onInterval();
			}
		}, this._intervalDelay);
	};
}

/**
 * Do not use to return value
 */
export const awaitableTimeout = (callback: () => Promise<void | string>, ms: number) => {
	return new Promise((resolve) => setTimeout(async () => {
		const value = await callback();
		if (value) {
			resolve(value);
		} else {
			resolve(true);
		}
	}, ms));
};

/**
 * Function that executes API polling with a return value
 *
 * @param action action to perform http request
 * @param untilCondition condition when polling should stop
 * @param ms timeout value
 * @param cleanup action to perform when condition is satisfied
 * @returns returns http response
 */
export const pollAPI = async <T,>(action: () => Promise<T>, untilCondition: (arg: T) => boolean, ms: number, cleanup?: () => Promise<void>): Promise<T> => {
	return new Promise(async (resolve, reject) => {
		try {
			let isPolling = true;
			while (isPolling) {
				const value = await action();
				if (untilCondition(value)) {
					isPolling = false;
					if (cleanup) {
						await cleanup();
					}
					return resolve(value);
				}
				await new Promise((_resolve) => setTimeout(_resolve, ms));
			}
		} catch (error) {
			console.error(error);
			reject(error);
		}
	});
};
