import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-constants/routes/client';

const Error500: React.FC = () => {

	const location = useLocation();
	const navigate = useNavigate();
	const orgAlias = location.state.orgAlias;

	const goToLogin = React.useCallback(() => {
		navigate(CLIENT.HOME(orgAlias));
	}, [navigate, orgAlias]);

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon icon-settings rotate" />
				</div>
				<h2>Something went wrong</h2>
				<h4>There was a problem on our end!</h4>
				<h4>Our team is working hard to fix the issue. Please come back later.</h4>
				<div className="form-box__footer">
					<Button
						label="Back to Home"
						onClick={goToLogin}
						style="primary"
						type="button"
					/>
				</div>
			</div>
		</div>
	);
};

export default Error500;
