import * as React from 'react';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import WorkOrderSettingsEnum, { WorkOrderSettingsHint } from 'ab-enums/workOrderSettings.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface OwnProps {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

type Props = OwnProps;

class WorkOrderSettingsPreview extends React.PureComponent<Props> {

	static label = (start: number, end: number) => {
		return `${TimeOptionUtils.toValueString(start)} - ${TimeOptionUtils.toValueString(end)}`;
	};

	render() {
		const { toggleEdit, company } = this.props;
		const {
			dayShiftStart,
			dayShiftEnd,
			nightShiftStart,
			nightShiftEnd,
		} = company;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<PreviewItem
						checked={true}
						hint={WorkOrderSettingsHint.dayShift}
						indent={false}
						label={WorkOrderSettingsEnum.dayShift}
						showCheckbox={false}
						value={WorkOrderSettingsPreview.label(dayShiftStart, dayShiftEnd)}
					/>
					<PreviewItem
						checked={true}
						hint={WorkOrderSettingsHint.nightShift}
						indent={false}
						label={WorkOrderSettingsEnum.nightShift}
						showCheckbox={false}
						value={WorkOrderSettingsPreview.label(nightShiftStart, nightShiftEnd)}
					/>
				</div>
			</SettingPreview>
		);
	}
}

export default WorkOrderSettingsPreview;
