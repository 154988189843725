import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import { bemElement } from 'ab-utils/bem.util';

import { DeviceType } from '../ReportConfirmation/helpers';
import PrintButton from './PrintButton';

interface OwnProps {
	allowCustomerSignature: boolean;
	areReportsLoaded: boolean;
	areAllReportsSigned: boolean;
	isWebview: boolean;
	onAllReportSigning: () => void;
	printReport: () => Promise<void>;
	printAllReports: () => Promise<void>;
	fetchWebViewPdfURL: () => Promise<string | undefined>;
	deviceType: DeviceType;
	reportsRefs: React.MutableRefObject<HTMLDivElement[]>;
	currentReportIndex: number;
	noOfReports: number;
	noOfSignedReports: Nullable<number>;
}

type Props = OwnProps;

const FieldReportsPublicReportFooter: React.FC<Props> = (props: Props) => {
	const {
		allowCustomerSignature,
		areReportsLoaded,
		areAllReportsSigned,
		isWebview,
		onAllReportSigning,
		printReport,
		printAllReports,
		deviceType,
		currentReportIndex,
		reportsRefs,
		noOfReports,
		noOfSignedReports,
		fetchWebViewPdfURL,
	} = props;

	const [isPrintingReport, setIsPrintingReport] = React.useState(false);
	const [isPrintingAllReports, setIsPrintingAllReports] = React.useState(false);

	const isPrintDisabled = !areReportsLoaded || isPrintingReport;
	const isPrintingAllReportsDisabled = !areReportsLoaded || isPrintingAllReports;

	const onPrintClick = React.useCallback(async () => {
		if (isPrintDisabled) {
			return;
		}
		setIsPrintingReport(true);
		await printReport();
		setIsPrintingReport(false);
	}, [printReport, isPrintDisabled]);

	const onPrintAllReportsClick = React.useCallback(async () => {
		if (isPrintingAllReportsDisabled) {
			return;
		}
		setIsPrintingAllReports(true);
		await printAllReports();
		setIsPrintingAllReports(false);
	}, [isPrintingAllReportsDisabled, printAllReports]);

	const onWebViewPrintAllReportsClick = React.useCallback(async () => {
		setIsPrintingReport(true);
		const url = await fetchWebViewPdfURL();
		setIsPrintingReport(false);
		return url;
	}, [fetchWebViewPdfURL]);

	const onPreviousClick = React.useCallback(() => {
		reportsRefs.current[currentReportIndex - 1].scrollIntoView();
	}, [currentReportIndex, reportsRefs]);

	const onNextClick = React.useCallback(() => {
		reportsRefs.current[currentReportIndex + 1].scrollIntoView();
	}, [currentReportIndex, reportsRefs]);

	const isSignDisabled = !areReportsLoaded || !allowCustomerSignature;
	const isMobileOrTablet = (deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET);

	const isPreviousDisabled = (currentReportIndex <= 0);
	const isNextDisabled = (currentReportIndex >= reportsRefs.current?.length - 1);

	const hasMultipleReports = noOfReports > 1;

	const reportsSignedLabel = hasMultipleReports ? 'Reports Signed' : 'Report Signed';
	const primarySignButtonName = hasMultipleReports ? 'Sign All Reports' : 'Sign Report';

	const footerFlexDirection = hasMultipleReports ? 'column' : 'row';

	const showCloseButton = (!isWebview && noOfReports === 1) || !isMobileOrTablet;
	const showReportsSignedLabel = areAllReportsSigned && !isSignDisabled;
	const showPrintSingleButton = !isMobileOrTablet || isWebview;
	const showPrintAllButton = hasMultipleReports && showPrintSingleButton;

	return (
		<div className="public-report__footer" style={{ flexDirection: footerFlexDirection }}>
			{showCloseButton &&
				(<div className="public-report__footer__close">
					<Button
						label="Cancel"
						onClick={window.close}
						style="secondary"
					/>
				</div>)
			}
			{hasMultipleReports && (
				<div className="public-report__footer__previous-next">
					<Button
						disabled={isPreviousDisabled}
						icon={isMobileOrTablet ? 'left' : undefined}
						label={isMobileOrTablet ? undefined : 'Previous Report'}
						onClick={onPreviousClick}
						style="secondary"
					/>
					{isMobileOrTablet && (
						<div className="public-report__footer__previous-next__reports-status">
							<div className={`public-report__footer__previous-next__reports-status__signed ${areAllReportsSigned ? 'text-green' : ''}`}>
								Signed <b>{noOfSignedReports}</b> of <b>{noOfReports}</b>
							</div>
							<div className="public-report__footer__previous-next__reports-status__position">
								Report <b>{currentReportIndex + 1}</b> of <b>{noOfReports}</b>
							</div>
						</div>
					)}
					<Button
						disabled={isNextDisabled}
						icon={isMobileOrTablet ? 'right' : undefined}
						label={isMobileOrTablet ? undefined : 'Next Report'}
						onClick={onNextClick}
						style="secondary"
					/>
				</div>
			)}
			<div className={bemElement('public-report__footer', 'print-sign', { 'web-view': isWebview && hasMultipleReports })}>
				{showPrintSingleButton &&
					<PrintButton
						fetchWebViewPdfURL={onWebViewPrintAllReportsClick}
						handlePrintClick={onPrintClick}
						isPrintDisabled={isPrintDisabled}
						isWebView={isWebview}
						isWebViewFetchAvailable={true}
						name="Print"
					/>
				}
				{showPrintAllButton &&
					<PrintButton
						handlePrintClick={onPrintAllReportsClick}
						isPrintDisabled={isPrintingAllReportsDisabled}
						isWebView={isWebview}
						name="Print All Reports"
					/>
				}
				<div className="public-report__footer__print-sign__signings">
					{showReportsSignedLabel &&
						<div className="public-report__footer__print-sign__label text-green"><span className="icon-check" />
							{reportsSignedLabel}
						</div>
					}

					{!areAllReportsSigned && !isSignDisabled &&
						<Button
							disabled={isSignDisabled}
							label={primarySignButtonName}
							onClick={onAllReportSigning}
							style="primary"
						/>
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(FieldReportsPublicReportFooter);
