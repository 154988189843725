import type CountryCode from '@acceligentllc/shared/enums/countryCode';

import type { Device } from 'ab-domain/schemas/connection/base';

export interface FinalizeUser {
	password: string;
	repeatedPassword?: string; // Only on FE
}

export interface ResetPassword {
	password: string;
	repeatedPassword?: string;
	email: string;
	activationCode: string;
	orgAlias: string;
}

export interface RegisterUser {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}

export class UserEmailCredentials {
	email: string;
	password: string;
	orgAlias: string;
	device: Device;

	constructor(email: string, password: string, orgAlias: string) {
		this.email = email;
		this.password = password;
		this.orgAlias = orgAlias;
	}
}

export class UserEmailLMSCredentials {
	email: string;
	password: string;
	orgAlias: string;
	authnRequestId: string;

	constructor(email: string, password: string, orgAlias: string, authnRequestId: string) {
		this.email = email;
		this.password = password;
		this.orgAlias = orgAlias;
		this.authnRequestId = authnRequestId;
	}
}

export interface EmailLogin {
	email: string;
	password: string;
}

export interface UserPhoneCode {
	activationCode: string;
}

export interface UserPhoneCredentials extends UserPhoneCode {
	phoneNumber: string;
	countryCode: CountryCode;
	activationCode: string;
	orgAlias: string;
}

export interface UserPhoneOrganizationsCredentials {
	phoneNumber: string;
	countryCode: CountryCode;
	activationCode: string;
}

export interface UserPhoneCodeRequest {
	phoneNumber: string;
	countryCode: CountryCode;
	orgAlias: string;
}

export interface UserPhoneCodeOrganizationsRequest {
	phoneNumber: string;
	countryCode: CountryCode;
}

export type UserCredentials = UserEmailCredentials & UserPhoneCredentials;

export interface VerifyEmail {
	activationCode: string;
	email: string;
}

export interface PasswordForgotten {
	email: string;
	orgAlias: string;
}

export interface GetOrganizationsByEmail {
	email: string;
}
