import * as React from 'react';
import type { Accept, FileError, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Button } from '@acceligentllc/storybook';

import styles from './styles.module.scss';

interface OwnProps {
	onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
	allowedTypes?: Accept;
	className?: string;
	disabled?: boolean;
	style?: React.CSSProperties;
	noKeyboard?: boolean;
	noDrag?: boolean;
	errorText?: FileError[];
}

type Props = React.PropsWithChildren<OwnProps>;

const DropzoneWithButton = (props: Props) => {
	const { children, allowedTypes, noKeyboard, noDrag, onDrop, style, className } = props;
	const {
		getRootProps,
		getInputProps,
		open,
	} = useDropzone({
		accept: allowedTypes,
		noClick: true,
		noKeyboard,
		noDrag,
		onDrop,
	});

	return (
		<section>
			<div {...getRootProps({ className: className ?? `${styles['dropzone-element']}`, style: style })}>
				<input {...getInputProps()} />
				{children}
				<Button
					customStyleClass={styles['dropzone-element__select-files-button']}
					label="Select files"
					onClick={open}
					style="secondary"
				/>
			</div>
		</section>
	);
};

export default DropzoneWithButton;
