import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';
import Text from 'af-fields/Text';

import ImageUploadFull from 'af-components/ImageUploadFull';
import Fieldset from 'af-components/Fieldset';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import { phoneNormalizer } from 'ab-utils/reduxForms.util';

import { useLazyLoad } from 'af-utils/react.util';

interface OwnProps {
	image: File | string | null;
	imageUrl: string | null;
	offlinePin?: string;
	subscriptionStatus?: string;
	onImageUploadSave: (image: File, imageUrl: string) => void;
	deleteImage: () => void;
	loadLocations: (id: string, name: number) => Promise<LocationOptionVM[] | undefined>;
	isEmailInvalid: boolean;
	isPhoneNumberInvalid: boolean;
}

type Props = OwnProps;

const LOCATION_FILTER_BY = ['nickname'];

const renderSelectedLocation = (option: LocationOptionVM) => (<div>{option.nickname}</div>);
const renderLocationOption = (option: LocationOptionVM) => (
	<div>
		<div>{option.nickname}</div>
		<small>{option.street}</small>
	</div>
);

const UserInfo: React.FC<Props> = (props) => {
	const {
		image,
		imageUrl,
		onImageUploadSave,
		deleteImage,
		loadLocations,
		isEmailInvalid,
		isPhoneNumberInvalid,
	} = props;

	const {
		options: locationOptions,
		lazyLoad: lazyLoadLocations,
	} = useLazyLoad(loadLocations, '', 0);

	return (
		<Row className="row--final-padding">
			<Col className="user-upsert__avatar" lg={6}>
				<ImageUploadFull
					deleteImage={deleteImage}
					fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
					image={image}
					imageUrl={imageUrl}
					minSize={BlobStorageImageSizeContainer.SIZE_200X200}
					onImageUploadSave={onImageUploadSave}
				/>
			</Col>
			<Col className="user-upsert__details" lg={18}>
				<Row>
					<Col sm={10}>
						<Field
							component={Input}
							cursorControl={true}
							label="Unique ID *"
							name="user.uniqueId"
							placeholder="Enter Unique ID"
							type="text"
						/>
					</Col>
					<Col sm={10}>
						<Field
							component={Dropdown}
							filterable={true}
							filterBy={LOCATION_FILTER_BY}
							hasBlankOption={true}
							id="location_select"
							label="Home Office"
							name="locationId"
							onLazyLoad={lazyLoadLocations}
							options={locationOptions}
							placeholder="Select Home Office"
							propName="location"
							renderMenuItem={renderLocationOption}
							renderSelected={renderSelectedLocation}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={4} />
				</Row>
				<Row>
					<Col sm={10}>
						<Field
							component={Input}
							cursorControl={true}
							label="First Name *"
							name="user.firstName"
							placeholder="Enter First Name"
							type="text"
						/>
					</Col>
					<Col sm={10}>
						<Field
							component={Input}
							cursorControl={true}
							label="Last Name *"
							name="user.lastName"
							placeholder="Enter Last Name"
							type="text"
						/>
					</Col>
					<Col sm={4} />
					<Col sm={4}>
						<Field
							component={Text}
							label="Offline PIN"
							name="user.offlinePin"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={20}>
						<Fieldset
							showError={isEmailInvalid && isPhoneNumberInvalid}
							text="Please enter at least one type of contact information"
						>
							<Col sm={12}>
								<Field
									component={Input}
									cursorControl={true}
									label="Email *"
									name="user.email"
									placeholder="Enter email"
									type="text"
								/>
							</Col>
							<Col sm={12}>
								<Field
									component={Input}
									id="phoneNumber"
									label="Mobile Phone *"
									name="user.phoneNumber"
									normalize={phoneNormalizer}
									placeholder="Enter mobile phone"
									type="text"
								/>
							</Col>
						</Fieldset>
					</Col>
					<Col sm={4}>
						<Field
							component={Input}
							label="Extension"
							name="user.extension"
							placeholder="Extension"
							type="text"
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default React.memo(UserInfo);
