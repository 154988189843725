import NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';
import NotificationType from '@acceligentllc/shared/enums/notificationType';

export const DEFAULT_TIME_TO_NOTIFY = 1320; // 5pm EST in UTC
export const DEFAULT_SCALE_FOR_NOTIFY_CRON = 15; // we run cron job every 15 mins

export const WORK_ORDERS_IN_EMAIL_ROW = 5;
export const RESOURCES_IN_EMAIL_ROW = 5;

export const EMAIL_STATUS = {
	status: NotificationStatusEnum.UNKNOWN,
	type: NotificationType.EMAIL,
} as const;

export const SMS_STATUS = {
	status: NotificationStatusEnum.QUEUED,
	type: NotificationType.SMS,
} as const;
