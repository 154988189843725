import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Button } from '@acceligentllc/storybook';
import { Row, Col } from 'react-bootstrap';

import CDLStatus, { CDLStatusLabel } from '@acceligentllc/shared/enums/cdlStatus';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import type { RootState } from 'af-reducers';

import type { EmployeeRM } from 'ab-requestModels/employee/update.requestModel';

import type { SkillViewModel } from 'ab-viewModels/skill.viewModel';
import type { WageRateVM } from 'ab-viewModels/wageRate/wageRate.viewModel';
import type UnionTypeVM from 'ab-viewModels/unionType/unionType.viewModel';
import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import CLIENT from 'af-constants/routes/client';

import * as WageRateActions from 'af-actions/wageRate';
import * as UnionTypeActions from 'af-actions/unionType';
import * as EmployeeStatusActions from 'af-actions/employeeStatus';
import * as SkillActions from 'af-actions/skill';

import SubmitButton from 'af-components/SubmitButton';
import ImageTag from 'af-components/Image';
import SkillOption from 'af-components/SharedForms/Resources/Shared/SkillOption';
import SkillMultiValueLabel from 'af-components/SharedForms/Resources/Shared/SkillMultiValueLabel';

import Select from 'af-fields/SelectField';
import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';
import type { OwnProps as MultiTagSelectProps } from 'af-fields/MultiTagSelect';
import MultiTagSelect from 'af-fields/MultiTagSelect';
import TextField from 'af-fields/Text';

import * as Helpers from './helpers';

interface PathParams {
	id: string;
}

interface OwnProps extends CustomRouteComponentProps<PathParams>, InjectedFormProps<EmployeeRM> {
	companyName: string;
	onSubmit: (form: EmployeeRM) => void;
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	companyEmployeeStatuses: ResourceStatusesViewModel.List;
	skillList: SkillViewModel[];
	wageRateList: WageRateVM[];
	unionTypeList: UnionTypeVM[];
	isLoaded: boolean;
}

const CDLOptions = Object.keys(CDLStatus).map((_cdlStatus) => ({ name: CDLStatusLabel[_cdlStatus], id: _cdlStatus }));

class EmployeeForm extends React.PureComponent<Props, State> {

	state: State = {
		companyEmployeeStatuses: [],
		skillList: [],
		wageRateList: [],
		unionTypeList: [],
		isLoaded: false,
	};

	static getUnionTypeOptionValue = (option: UnionTypeVM) => option.id;
	static getUnionTypeOptionLabel = (option: UnionTypeVM) => option.name;

	async componentDidMount() {
		const { findAllStatusesForCompany, findAllSkillsForCompanyByQuery, findAllWageRatesByQuery, findAllUnionTypes } = this.props;
		const [
			{ available, unavailable },
			skills,
			wageRateList,
			unionTypeList,
		] = await Promise.all([
			findAllStatusesForCompany(),
			findAllSkillsForCompanyByQuery(''),
			findAllWageRatesByQuery(''),
			findAllUnionTypes(),
		]);
		this.setState(() => ({
			companyEmployeeStatuses: [ResourceStatusesViewModel.Item.DEFAULT_AVAILABLE, ...available, ...unavailable],
			skillList: skills,
			wageRateList,
			unionTypeList,
			isLoaded: true,
		}));
	}

	onWageRateChange = (wageRate: WageRateVM) => {
		const { change } = this.props;
		if (!wageRate) {
			change('wageRate', null);
		}
	};

	goBack = () => {
		const {
			location: { state: { orgAlias } },
			match: { params: { id } },
			companyName,
			history,
		} = this.props;

		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(id, orgAlias, companyName));
	};

	render() {
		const {
			handleSubmit,
			submitting,
			invalid,
			imageUrl,
			onSubmit,
		} = this.props;

		const { companyEmployeeStatuses, skillList, wageRateList, isLoaded, unionTypeList } = this.state;

		if (!isLoaded) {
			return null;
		}

		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-box">
					<Row className="row--flex">
						<div className="avatar__container">
							<div className="avatar">
								<ImageTag
									fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
									minSize={BlobStorageImageSizeContainer.SIZE_200X200}
									src={imageUrl}
									tryOriginal={true}
									tryRoot={true}
								/>
							</div>
						</div>
						<div className="avatar__neighbour">
							<Row className="row--padded-top">
								<Col md={6}>
									<Field
										component={TextField}
										defaultValue="N/A"
										label="Name"
										name="fullName"
									/>
								</Col>
								<Col md={6}>
									<Field
										component={Dropdown}
										disabled={false}
										id="employeeStatusId"
										label="Current Status *"
										name="employeeStatusId"
										options={companyEmployeeStatuses}
										renderMenuItem={Helpers.renderResourceStatusMenuItem}
										valueKey="id"
										withCaret={true}
									/>
								</Col>
								<Col md={6}>
									<Field
										component={TextField}
										defaultValue="N/A"
										label="Mobile Phone"
										name="phoneNumber"
									/>
								</Col>
								<Col md={6}>
									<Field
										component={TextField}
										defaultValue="N/A"
										label="SMS Subscription"
										name="subscriptionStatus"
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Field
										component={TextField}
										defaultValue="N/A"
										label="Location"
										name="locationNickname"
									/>
								</Col>
								<Col md={6}>
									<Field
										component={Dropdown}
										disabled={false}
										id="cdlStatus"
										initialValue="cdlStatus"
										label="CDL Status *"
										labelKey="name"
										name="cdlStatus"
										options={CDLOptions}
										valueKey="id"
										withCaret={true}
									/>
								</Col>
								<Col md={12}>
									<Field
										component={TextField}
										defaultValue="N/A"
										label="E-mail"
										name="email"
									/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Field
										component={Select}
										filterOption={Helpers.filterWageRates}
										formatOptionLabel={Helpers.formatWageRateOptionLabel}
										getOptionLabel={Helpers.getWageRateOptionLabel}
										getOptionValue={Helpers.getWageRateOptionValue}
										label="Labor type *"
										name="wageRate"
										onValueChange={this.onWageRateChange}
										options={wageRateList}
										placeholder="Enter Labor Type"
									/>
								</Col>
								<Col md={6}>
									<Field
										component={Select}
										getOptionLabel={EmployeeForm.getUnionTypeOptionLabel}
										getOptionValue={EmployeeForm.getUnionTypeOptionValue}
										label="Union type"
										name="unionType"
										options={unionTypeList}
										placeholder="Select Union Type"
									/>
								</Col>
								<Col md={12}>
									<Field
										component={Checkbox}
										grouped={true}
										label="Show on schedule board"
										name="showOnScheduleBoard"
									/>
								</Col>
							</Row>
						</div>
					</Row>
					<Row>
						<Col md={24}>
							<FieldArray<MultiTagSelectProps<SkillViewModel>>
								component={MultiTagSelect}
								getOptionLabel={Helpers.getSkillOptionLabel}
								getOptionValue={Helpers.getSkillOptionValue}
								label="Skills"
								MultiValueLabel={SkillMultiValueLabel}
								name="skills"
								Option={SkillOption}
								options={skillList}
								placeholder="Choose skills"
							/>
						</Col>
					</Row>
					<Row className="row--submit">
						<Button
							label="Back"
							onClick={this.goBack}
							style="secondary"
							type="button"
						/>
						<SubmitButton
							disabled={invalid}
							reduxFormSubmitting={submitting}
						/>
					</Row>
				</div>
			</form>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { imageUrl } = formValueSelector(ownProps.form)(state, 'isStatusAvailable', 'imageUrl');

	return {
		imageUrl,
	};
}

function mapDispatchToProps() {
	return {
		findAllWageRatesByQuery: WageRateActions.findAllByQuery,
		findAllSkillsForCompanyByQuery: SkillActions.findAllForCompanyByQuery,
		findAllStatusesForCompany: EmployeeStatusActions.findAllForCompany,
		findAllUnionTypes: UnionTypeActions.findAll,
	};
}

export default connect(mapStateToProps, mapDispatchToProps())(EmployeeForm);
