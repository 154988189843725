import * as React from 'react';

import styles from './styles.module.scss';

export default class DownEquipmentBadge extends React.PureComponent {

	render() {
		return (
			<div className={styles['down-resource-badge']}>
				<span className="icon-equipment" />
			</div>
		);
	}
}
