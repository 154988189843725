import * as React from 'react';
import type { HandleThunkActionCreator } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import type * as CompanyActions from 'af-actions/companies';

import type { DefaultNotificationTimesForm } from '../formModel';
import { DefaultNotificationTimes } from '../formModel';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import { DAILY_NOTIFICATIONS_SCHEDULE } from 'af-constants/reduxForms';

import NotificationTimesForm from './Form';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
	editDefaultNotificationSendTimes: HandleThunkActionCreator<typeof CompanyActions.editDefaultNotificationSendTimes>;
}

type Props = OwnProps & InjectedFormProps<DefaultNotificationTimesForm, OwnProps>;

class NotificationTimesEdit extends React.PureComponent<Props> {

	editNotificationTimes = async (form: DefaultNotificationTimesForm) => {
		const { toggleEdit, editDefaultNotificationSendTimes } = this.props;

		await editDefaultNotificationSendTimes(DefaultNotificationTimes.fromForm(form));
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, isVisible, invalid, submitting } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Default Notification Times"
				/>
				<CustomModal.Body padding="none">
					<NotificationTimesForm
						{...this.props}
						onSubmit={this.editNotificationTimes}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.onClose}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editNotificationTimes)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default reduxForm<DefaultNotificationTimesForm, OwnProps>({ form: DAILY_NOTIFICATIONS_SCHEDULE, enableReinitialize: true })(NotificationTimesEdit);
