import { isValidEmail } from '@acceligentllc/shared/utils/email';
import { isValidFormattedPhoneNumber } from '@acceligentllc/shared/utils/phone';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type { AccountRM } from 'ab-requestModels/account/updateActive.requestModel';

import { PASSWORD_LENGTH_MIN, PASSWORD_CATEGORIES, MIN_PASSWORD_CATEGORY_REQUIREMENTS } from 'ab-common/constants/value';

export function validate(values: AccountRM) {
	const errors: ValidationErrors = {};

	if (!values.email && !values.phoneNumber) {
		errors.email = 'Email or mobile phone required';
	}

	if (values.email && !isValidEmail(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!isValidTextInput(values.firstName)) {
		errors.firstName = 'First name is required';
	}

	if (!isValidTextInput(values.lastName)) {
		errors.lastName = 'Last name is required';
	}

	if (values.phoneNumber) {
		if (!isValidFormattedPhoneNumber(values.phoneNumber, values.countryCode ?? undefined)) {
			errors.phoneNumber = 'Invalid mobile phone';
		}
	}

	if (values.changeSignature && values.isDigitalSignatureEnabled) {
		if (!values.signatureImage) {
			errors.signatureImage = 'Signature is required';
		}
	}

	if (values.changePassword) {
		if (values.isFinalized && !values.oldPassword) {
			errors.oldPassword = 'Old password is required';
		}

		let pwComplexity = 0;
		for (const category of Object.values(PASSWORD_CATEGORIES)) {
			if (category.test(values.newPassword as string)) pwComplexity++;
		}

		if (!values.newPassword) {
			errors.newPassword = 'New password is required';
		} else if (values.newPassword.length < PASSWORD_LENGTH_MIN) {
			errors.newPassword = `New password requires at least ${PASSWORD_LENGTH_MIN} characters`;
		} else if (pwComplexity < MIN_PASSWORD_CATEGORY_REQUIREMENTS) {
			errors.newPassword = 'Minimum password requirements not satisfied';
		}

		if (!values.confirmNewPassword) {
			errors.confirmNewPassword = 'Repeated password is required';
		} else if (values.newPassword !== values.confirmNewPassword) {
			errors.confirmNewPassword = 'Password and repeated password do not match';
		}

	}

	return errors;
}
