import baseRoute from '../baseRoute';

export * from './requestModel';

type RouteDefinition = `${typeof baseRoute}/${number | ':fieldReportId'}/fieldReportTypes`;

export const ROUTE: RouteDefinition = `${baseRoute}/:fieldReportId/fieldReportTypes`;

export const URL = (fieldReportId: number): RouteDefinition =>
	`${baseRoute}/${fieldReportId}/fieldReportTypes`;
