import { isValidEmail } from '@acceligentllc/shared/utils/email';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import { ALIAS_REGEX } from '@acceligentllc/shared/constants/regex';

import type OrganizationRequestModel from 'ab-requestModels/organization.requestModel';

export function validate(values: OrganizationRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Organization name is required.';
	}

	if (!values.alias) {
		errors.alias = 'Alias is required.';
	} else if (!ALIAS_REGEX.test(values.alias)) {
		errors.alias = 'Alias can only contain lowercase letters and numbers.';
	}

	if (!isValidTextInput(values.firstName)) {
		errors.firstName = 'First name is required.';
	}

	if (!isValidTextInput(values.lastName)) {
		errors.lastName = 'Last name is required.';
	}

	if (!values.email) {
		errors.email = 'Email is required.';
	} else if (!isValidEmail(values.email)) {
		errors.email = 'Invalid email address.';
	}

	return errors;
}
