import * as React from 'react';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import NavigationItem from 'af-components/Header/ScheduleBoardNavigation/NavigationItem';

import OfficeInfo from '../../Shared/Header/OfficeInfo';

import WorkOrderCards from './WorkOrderCards';

type OwnProps = {
	notificationsEnabled: boolean;
	dueDate: string;
	showOfficeInfo: boolean;
};

type Props = OwnProps;

class Board extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		notificationsEnabled: false,
	};

	render() {
		const { notificationsEnabled, dueDate, showOfficeInfo } = this.props;

		return (
			<>
				<div className="display-view-header">
					<span className="display-view-header-title">{TimeUtils.formatDate(dueDate, TimeFormatEnum.SB_DATE_LONG)}</span>
					<div className="display-view-statistics-wrapper">
						{showOfficeInfo &&
							<OfficeInfo
								dueDate={dueDate}
							/>
						}
					</div>
					<div className="display-view-header-navigation">
						<NavigationItem />
					</div>
				</div>
				<div className="display-view-board">
					<WorkOrderCards dueDate={dueDate} notificationsEnabled={notificationsEnabled} />
				</div>
			</>
		);
	}
}

export default Board;
