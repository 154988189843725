import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, getFormValues } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { InvalidArgumentError } from 'restify-errors';

import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';
import OperandType from '@acceligentllc/shared/enums/operand';

import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';

import TabNavigation from 'af-components/TabNavigation';

import type { RootState } from 'af-reducers';

import { REPORT_BLOCK_PREVIEW } from 'af-constants/reduxForms';

import * as BuilderCalculationUtils from 'af-utils/builderCalculation.utils';

import ReportBlock from '../../Shared/Preview/ReportBlock';
import PreviewTabsEnum, { PREVIEW_TABS } from '../../Shared/previewTabs.enum';
import type { ReportBlockFormModel, ReportBlockFieldFormModel, CalculatedFieldOptionFormModel } from '../../Shared/formModel';

const DEFAULT_FORM_VALUES = {};

interface OwnProps {
	completionFieldId: Nullable<string>;
	fieldIds: string[];
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	reportBlockByIdMap: { [id: string]: ReportBlockFormModel; };
	hasCompletionStatus: boolean;
	id: string;
	isMain: boolean;
	isRepeating: boolean;
	name: string;
	listOfEquipment?: Nullable<EquipmentListViewModel>;
}

type Props = OwnProps & InjectedFormProps<void, OwnProps> & ConnectedProps<typeof connector>;

const _getOperandMapper = (blockId: string, fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; }, isRepeating: boolean) => {
	return (option: CalculatedFieldOptionFormModel) => {
		const { id, constant } = option;

		if (!id) {
			throw new Error('Missing field id');
		}

		if (constant) {
			return {
				virtualId: id,
				blockId,
				fieldType: null,
				unit: null,
				isRepeating,
				constant,
				type: OperandType.CONSTANT,
			};
		}

		const _field = fieldsByIdMap[id];

		return {
			virtualId: id,
			blockId,
			fieldType: _field.fieldType,
			unit: _field.unit,
			isRepeating,
			constant: null,
			type: OperandType.FIELD,
		};
	};
};

const _getCalculationReducer = (
	field: ReportBlockFieldFormModel,
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; },
	blockId: string,
	isRepeating: boolean
) => {
	const mapper = _getOperandMapper(blockId, fieldsByIdMap, isRepeating);
	const fieldId = field.virtualId;

	return (acc: BuilderCalculationUtils.CalculationMapType, option: CalculatedFieldOptionFormModel) => {
		if (!field.operationType || !field.calculatedFieldOptions) {
			throw new InvalidArgumentError('Field must contain operation type and operands');
		}
		const { id, constant } = option;

		if (!id) {
			throw new Error('Missing field id');
		}

		if (constant) {
			// Skip adding constants
			return acc;
		}

		if (!acc[id]) {
			acc[id] = {};
		}
		acc[id][fieldId] = {
			operationType: field.operationType,
			blockId,
			isRepeating,
			unit: field.unit,
			operands: field.calculatedFieldOptions.map(mapper),
		};
		return acc;
	};
};

const _getCalculationMapReducer = (
	fieldsByIdMap: Nullable<{ [id: string]: ReportBlockFieldFormModel; }>,
	blockId: string,
	isRepeating: boolean
) => {
	return (acc: BuilderCalculationUtils.CalculationMapType, fieldId: string) => {
		const field = fieldsByIdMap?.[fieldId];
		if (!field || field.fieldType !== ReportBlockFieldEnum.Type.CALCULATED) {
			return acc;
		}
		field.calculatedFieldOptions?.reduce(_getCalculationReducer(field, fieldsByIdMap, blockId, isRepeating), acc);
		return acc;
	};
};

const _getCalculationsMap = (
	fieldIds: string[],
	fieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; },
	blockId: string,
	isRepeating: boolean
) => {
	return fieldIds.reduce(_getCalculationMapReducer(fieldsByIdMap, blockId, isRepeating), {});
};

const Preview: React.FC<Props> = (props: Props) => {
	const {
		id,
		name,
		isRepeating,
		hasCompletionStatus,
		completionFieldId,
		fieldIds,
		fieldsByIdMap,
		reportBlockByIdMap,
		formValues,
		change,
		listOfEquipment,
	} = props;

	const [activeTab, setActiveTab] = React.useState<PreviewTabsEnum>(PreviewTabsEnum.PREVIEW);
	// Create a map of calculations
	const [calculationMap, setCalculationMap] = React.useState<BuilderCalculationUtils.CalculationMapType>(
		_getCalculationsMap(fieldIds, fieldsByIdMap, id, isRepeating)
	);

	React.useEffect(() => {
		const newCalculationMap = _getCalculationsMap(fieldIds, fieldsByIdMap, id, isRepeating);
		setCalculationMap(newCalculationMap);
	}, [fieldIds, fieldsByIdMap, activeTab, id, isRepeating]);

	const changeActiveTab = React.useCallback((tabId: number) => setActiveTab(tabId), []);

	const onFieldValueChange = React.useCallback((fieldId: string, newValue: string, index?: number) => {
		BuilderCalculationUtils.calculateOnFieldValueChange(fieldId, newValue, calculationMap, formValues, change, index);
	}, [calculationMap, change, formValues]);

	const onCalculatedFieldChange = React.useCallback((fieldId: string, isRepeatableBlock: boolean) => {
		const field = fieldsByIdMap[fieldId];
		if (!field) {
			throw new Error('Missing field');
		}
		BuilderCalculationUtils.calculateOnFieldChange(field, fieldsByIdMap, reportBlockByIdMap, isRepeatableBlock, calculationMap, formValues, change);
	}, [calculationMap, change, formValues, fieldsByIdMap, reportBlockByIdMap]);

	const onCompletionFieldChange = React.useCallback((fieldId: string, value: boolean) => {
		BuilderCalculationUtils.calculateOnCompletionFieldChange(fieldId, value, calculationMap, formValues, change);
	}, [calculationMap, formValues, change]);

	const highlightVisibleToCustomer = activeTab === PreviewTabsEnum.PREVIEW;

	return (
		<>
			<TabNavigation
				active={activeTab}
				onClick={changeActiveTab}
				tabs={PREVIEW_TABS}
			/>
			<div className="report-block-form report-block-form--single-block report-block-form__sticky-sidebar__content">
				{highlightVisibleToCustomer && (
					<div className="report-block-form__legend">
						<span className="text-grey">Note: Red fields are visible to customer</span>
					</div>
				)}
				<ReportBlock
					completionFieldId={completionFieldId}
					filterVisibleToCustomer={activeTab === PreviewTabsEnum.CUSTOMER_VIEW}
					form={REPORT_BLOCK_PREVIEW}
					formValues={formValues}
					hasCompletionStatus={hasCompletionStatus}
					highlightVisibleToCustomer={highlightVisibleToCustomer}
					id={id}
					isMain={false}
					isRepeating={isRepeating}
					listOfEquipment={listOfEquipment}
					name={name}
					onCalculatedFieldChange={onCalculatedFieldChange}
					onCompletionFieldChange={onCompletionFieldChange}
					onFieldValueChange={onFieldValueChange}
					reportBlockFieldIds={fieldIds}
					reportFieldsByIdMap={fieldsByIdMap}
				/>
			</div>
		</>
	);
};

const getForm = getFormValues(REPORT_BLOCK_PREVIEW);

function mapStateToProps(state: RootState) {
	const formValues = (getForm(state) ?? DEFAULT_FORM_VALUES) as Record<string, string>[];
	return {
		formValues,
	};
}

const connector = connect(mapStateToProps, null, null, {
	areStatesEqual: (nextState, prevState) => nextState.form[REPORT_BLOCK_PREVIEW] === prevState.form[REPORT_BLOCK_PREVIEW],
});

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<void, OwnProps>({ form: REPORT_BLOCK_PREVIEW, enableReinitialize: true })
);

export default enhance(Preview);
