import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Row, Form, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { AdditionalColors } from '@acceligentllc/shared/enums/color';
import DisplayViewToggle, { DisplayViewToggleLabel } from '@acceligentllc/shared/enums/displayViewToggle';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import ScheduleBoardSettingsEnum, { ScheduleBoardSettingsHint } from 'ab-enums/scheduleBoardSettings.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import { SCHEDULE_BOARD_SETTINGS } from 'af-constants/reduxForms';

import { ScheduleBoardSettingsForm as ScheduleBoardSettingsRequestForm } from 'ab-requestModels/scheduleBoard.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Label from 'af-components/LockedValue/Label';

import ColorPicker from 'af-fields/ColorPicker';
import Input from 'af-fields/Input';
import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';

import { validate } from './validation';

interface DisplayViewToggleOption {
	id: DisplayViewToggle;
	name: string;
}

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
}

type FormProps = InjectedFormProps<ScheduleBoardSettingsRequestForm, FormOwnProps>;

interface StateProps {
	companyId: number | undefined;
	initialValues: FormProps['initialValues'] | null;
	cdlInSkillsGrid: boolean;
	showRotationTime: boolean;
}

interface DispatchProps {
	editScheduleBoardSettings: typeof CompanyActions.editScheduleBoardSettings;
}

type ConnectOwnProps = CustomRouteComponentProps & OwnProps;
type FormOwnProps = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

interface State {
	showRotationTime: boolean;
}

class ScheduleBoardEdit extends React.PureComponent<Props, State> {
	state: State = {
		showRotationTime: this.props.showRotationTime,
	};

	static displayViewToggleOptions: DisplayViewToggleOption[] = Object.keys(DisplayViewToggle)
		.map((_option: DisplayViewToggle) => ({ id: _option, name: DisplayViewToggleLabel[_option] as string }));

	componentDidUpdate(prevProps: Props) {
		const { isVisible, showRotationTime } = this.props;
		if (isVisible && !prevProps.isVisible) {
			this.setState(() => ({ showRotationTime }));
		}
	}

	editScheduleBoardSettings = async (form: ScheduleBoardSettingsRequestForm) => {
		const { toggleEdit, editScheduleBoardSettings } = this.props;

		await editScheduleBoardSettings(form);
		toggleEdit();
	};

	renderSkillGridRow = (index: number) => {
		const { cdlInSkillsGrid } = this.props;
		return (
			<div className="skills__grid-row">
				<Field
					align="end"
					component={ColorPicker}
					disabled={index === 0 && cdlInSkillsGrid}
					name={`skillColorGrid[${index}][0]`}
					position="right"
				/>
				<Field
					align="end"
					component={ColorPicker}
					name={`skillColorGrid[${index}][1]`}
					position="right"
				/>
			</div>
		);
	};

	onDisplayViewTypeChange = (option: DisplayViewToggleOption) => {
		this.setState(() => ({ showRotationTime: option.id === DisplayViewToggle.TOGGLE }));
	};

	onCDLSettingChange = (value: boolean) => {
		this.props.change('skillColorGrid[0][0]', value ? AdditionalColors.BLACK : null);
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { isVisible, handleSubmit, submitting, invalid } = this.props;
		const { showRotationTime } = this.state;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Schedule Board Settings"
				/>
				<CustomModal.Body padding="none">
					<Form onSubmit={handleSubmit(this.editScheduleBoardSettings)}>
						<Row className="row--padded-top">
							<Col sm={12}>
								<Field
									component={Dropdown}
									disabled={false}
									id="displayViewChangeTime"
									label={ScheduleBoardSettingsEnum.displayViewChangeTime}
									labelKey="name"
									name="displayViewChangeTime"
									options={TimeOptionUtils.enumWithoutNullValue()}
									tooltipMessage={ScheduleBoardSettingsHint.displayViewChangeTime}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={12} />
						</Row>
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Field
									component={Checkbox}
									isStandaloneRow={true}
									label={ScheduleBoardSettingsEnum.includeNonWorkingDaysOnDisplayView}
									name="includeNonWorkingDaysOnDisplayView"
									tooltipMessage={ScheduleBoardSettingsHint.includeNonWorkingDaysOnDisplayView}
									type="text"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<Field
									component={Dropdown}
									disabled={false}
									id="displayViewType"
									label={ScheduleBoardSettingsEnum.displayViewType}
									labelKey="name"
									name="displayViewType"
									onValueChange={this.onDisplayViewTypeChange}
									options={ScheduleBoardEdit.displayViewToggleOptions}
									tooltipMessage={ScheduleBoardSettingsHint.displayViewType}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={12} />
						</Row>
						{showRotationTime &&
							<Row>
								<Col sm={12}>
									<Field
										component={Input}
										disabled={false}
										id="displayViewRotationTime"
										label={ScheduleBoardSettingsEnum.displayViewRotationTime}
										name="displayViewRotationTime"
										tooltipMessage={ScheduleBoardSettingsHint.displayViewRotationTime}
										type="number"
									/>
								</Col>
								<Col sm={12} />
							</Row>
						}
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Field
									component={Checkbox}
									isStandaloneRow={true}
									label={ScheduleBoardSettingsEnum.cdlInSkillsGrid}
									name="cdlInSkillsGrid"
									onValueChange={this.onCDLSettingChange}
									tooltipMessage={ScheduleBoardSettingsHint.cdlInSkillsGrid}
									type="text"
								/>
							</Col>
						</Row>
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Label
									label="Skills color grid"
									tooltipMessage={ScheduleBoardSettingsHint.skillGrid}
									withMargin={true}
								/>
								<div className="skills__grid-container">
									{this.renderSkillGridRow(0)}
									{this.renderSkillGridRow(1)}
									{this.renderSkillGridRow(2)}
									{this.renderSkillGridRow(3)}
								</div>
							</Col>
						</Row>
					</Form>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.onClose}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editScheduleBoardSettings)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	const selector = formValueSelector(SCHEDULE_BOARD_SETTINGS);

	return {
		companyId: company?.id,
		initialValues: company && new ScheduleBoardSettingsRequestForm(company),
		cdlInSkillsGrid: selector(state, 'cdlInSkillsGrid'),
		showRotationTime: selector(state, 'displayViewType') === DisplayViewToggle.TOGGLE,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		editScheduleBoardSettings: CompanyActions.editScheduleBoardSettings,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withRouter,
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<ScheduleBoardSettingsRequestForm, FormOwnProps>({ form: SCHEDULE_BOARD_SETTINGS, validate, enableReinitialize: true })
);

export default enhance(ScheduleBoardEdit);
