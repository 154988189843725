import * as React from 'react';

import RepairStatus, { RepairStatusLabel } from '@acceligentllc/shared/enums/repairStatus';

import styles from './styles.module.scss';

type OwnProps = {
	status: RepairStatus;
	forTable: boolean;
};

type Props = OwnProps;

const StatusLabel = (props: Props) => {
	const {
		status,
		forTable,
	} = props;

	const { classname, label } = React.useMemo(() => {
		let _classname = 'new';

		switch (status) {
			case RepairStatus.OPEN:
				_classname = 'open';
				break;
			case RepairStatus.ON_HOLD:
			case RepairStatus.OUT_FOR_REPAIR:
				_classname = 'pending';
				break;
			case RepairStatus.COMPLETED:
				_classname = 'completed';
				break;
			case RepairStatus.NEW:
			default:
				break;
		}

		if (forTable) {
			_classname += '_t';
		}

		const _label = RepairStatusLabel[status];

		return { classname: _classname, label: _label };
	}, [forTable, status]);

	return (
		<div className={styles[`label__${classname}`]}>
			{label}
		</div>
	);
};

export default React.memo(StatusLabel);
