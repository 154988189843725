import { CompoundUnitEnum, PlainUnitEnum, type ConstrainedUnitEnum } from '@acceligentllc/shared/enums/quantityUnit';
import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';

import type { ClassUnitsType } from 'ab-enums/unitConversion.enum';
import { UnitClass, UnitClasses } from 'ab-enums/unitConversion.enum';

/** Don't and convert QUANTITY */
type UnitsForConversion = Omit<ClassUnitsType, UnitClass.QUANTITY>;
type ConversionsForClass<K extends keyof UnitsForConversion> = { [key in UnitsForConversion[K]]: number };
type Conversion = { [key in keyof UnitsForConversion]: ConversionsForClass<key>; };

const Conversions: Conversion = {
	[UnitClass.LENGTH]: {
		[PlainUnitEnum.M]: 1,
		[PlainUnitEnum.LM]: 1,
		[PlainUnitEnum.KM]: 1000,
		[PlainUnitEnum.IN]: 0.0254,
		[PlainUnitEnum.LIN]: 0.0254,
		[PlainUnitEnum.FT]: 0.3048,
		[PlainUnitEnum.LF]: 0.3048,
		[PlainUnitEnum.YD]: 0.9144,
		[PlainUnitEnum.MIL]: 0.0000254,
		[PlainUnitEnum.MI]: 1609.34,
		[CompoundUnitEnum.FT_IN]: 0.0254,
		[CompoundUnitEnum.LFT_LIN]: 0.0254,
	},
	[UnitClass.AREA]: {
		[PlainUnitEnum.SM]: 1,
		[PlainUnitEnum.SF]: 0.0929,
		[PlainUnitEnum.SY]: 0.9144,
		[PlainUnitEnum.AC]: 4046.86,
		[PlainUnitEnum.HA]: 10000,
		[PlainUnitEnum.SIN]: 0.000645,
		[CompoundUnitEnum.SFT_SIN]: 0.000645,
	},
	[UnitClass.VOLUME]: {
		[PlainUnitEnum.BF]: 0.00235974,
		[PlainUnitEnum.MB]: 2.35974,
		[PlainUnitEnum.CF]: 0.0283168,
		[PlainUnitEnum.CY]: 0.764555,
		[PlainUnitEnum.CM]: 1,
		[PlainUnitEnum.GAL]: 0.00378541,
		[PlainUnitEnum.HGA]: 0.378541,
		[PlainUnitEnum.L]: 0.001,
		[CompoundUnitEnum.CFT_CIN]: 0.0283168,
	},
	[UnitClass.WEIGHT]: {
		[PlainUnitEnum.LB]: 0.453592,
		[PlainUnitEnum.KG]: 1,
		[PlainUnitEnum.TN]: 907.185,
	},
	[UnitClass.TIME]: {
		[PlainUnitEnum.HR]: 60,
		[PlainUnitEnum.MIN]: 1,
		[CompoundUnitEnum.HH_MM]: 1,
	},
	[UnitClass.PRESSURE]: {
		[PlainUnitEnum.PSI]: 0.0689476,
		[PlainUnitEnum.BAR]: 1,
	},
	[UnitClass.TEMPERATURE]: {
		[PlainUnitEnum.F]: 1,
	},
};

export function convertUnits(
	value: number,
	source: ConstrainedUnitEnum | PlainUnitEnum | QuantityUnitType,
	destination: ConstrainedUnitEnum | PlainUnitEnum | QuantityUnitType
): number | undefined {
	if (!Object.keys(UnitClasses).includes(source) || !Object.keys(UnitClasses).includes(destination)) {
		return undefined;
	}

	if (UnitClasses[source] === UnitClass.QUANTITY && UnitClasses[destination] === UnitClass.QUANTITY) {
		return value;
	}

	if (!Object.keys(Conversions).includes(UnitClasses[source]) || !Object.keys(Conversions).includes(UnitClasses[destination])) {
		return undefined;
	}

	if (UnitClasses[source] !== UnitClasses[destination]) {
		return undefined;
	}

	const unitClass: UnitClass = UnitClasses[source];
	const sourceConversionValue: number = Conversions[unitClass][source];
	const destinationConversionValue: number = Conversions[unitClass][destination];

	if (sourceConversionValue === undefined || destinationConversionValue === undefined) {
		return undefined;
	}

	return value * sourceConversionValue / destinationConversionValue;
}
