import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import type { InjectedFormProps } from 'redux-form';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'redux';
import type { FormatOptionLabelMeta } from 'react-select';
import type { Option } from 'react-select/src/filters';
import { Button } from '@acceligentllc/storybook';

import { ColorHex } from '@acceligentllc/shared/enums/color';
import WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import type { RootState } from 'af-reducers';

import type WorkOrderUpsertVM from 'ab-viewModels/workOrder/workOrderUpsert.viewModel';
import { JobViewModel } from 'ab-viewModels/job.viewModel';

import Select from 'af-fields/SelectField';

import BadgeCell from 'af-components/Table6/Cells/BadgeCell';
import LockedValue from 'af-components/LockedValue';
import CanceledRibbon from 'af-components/CanceledRibbon';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import * as JobActions from 'af-actions/jobs';

import { bemBlock } from 'ab-utils/bem.util';
import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type WorkOrderFM from '../formModel';

const JobSelect = Select as unknown as new () => Select<JobViewModel>;

interface OwnProps {
	createNewJob: (jobCode: string) => void;
	resetTimer: () => void;
	setIsJobChanged: () => void;
}

interface State {
	showDropdown: boolean;
	jobs: JobViewModel[];
	showChangeJobWarningModal: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkOrderFM>;

class JobPicker extends React.PureComponent<Props, State> {
	state: State = {
		showDropdown: this.props.isCreate,
		jobs: [],
		showChangeJobWarningModal: false,
	};

	static _renderJobCodeValue = (job: WorkOrderUpsertVM['job']) => {
		const { status, title, jobCode, isInternal } = job;
		const inProgress = status === WorkRequestStatus.SHORT_CIRCUITED;

		return (
			<div className="work-order-upsert__job-info">
				<div className="work-order-upsert__job-title">
					<div className={bemBlock('rt-status-icon', [inProgress ? 'orange' : 'green'])}>
						{inProgress ?
							<span className="icon-in_progress" /> :
							<span className="icon-check" />
						}
					</div>
					<div className="title-name">
						{title}
					</div>
					{isInternal &&
						<div className="internal-badge">
							<BadgeCell
								badgeColor={ColorHex.BLACK}
								colorNegative={false}
								text="INTERNAL"
							/>
						</div>
					}
				</div>
				<div className="work-order-upsert__job-code">
					{jobCode}
				</div>
			</div>
		);
	};

	static getOptionValue = (option: JobViewModel) => option.id;

	static getNewOptionData = (inputValue: string) => {
		return { jobCode: inputValue };
	};

	static highlightText = (text: string, highlight: string) => {
		const _lowerText = text?.toLowerCase?.();
		const _lowerHighLight = highlight?.toLowerCase?.();
		const index = _lowerText.indexOf(_lowerHighLight);

		if (!highlight || index === -1) {
			return <span>{text}</span>;
		}
		const _highlightText = text.slice(index, index + highlight.length);

		return (
			<>
				{text.split(_highlightText).map((_subtext, _index, _list) => (
					<span key={_index}>
						{_subtext}{_index + 1 !== _list.length && <mark>{_highlightText}</mark>}
					</span>)
				)}
			</>
		);
	};

	static renderJobMenuItem = (option: JobViewModel, labelMeta: FormatOptionLabelMeta<JobViewModel, boolean>) => {
		const { id, jobCode, isInternal, title, customerCompany, customerFormatted, travelLocationShort } = option;

		if (!id) {
			return (
				<div>
					<span className="work-order-upsert__job-picker-create-label">
						<span className="icon-plus" />
						Create New Job:
					</span>
					<span>{jobCode}</span>
				</div>
			);
		}

		if (labelMeta.selectValue?.[0]?.id === id) {
			return <strong>{jobCode}</strong>;
		}

		return (
			<div className="work-order-upsert__job-picker-option">
				<div>
					<strong>{JobPicker.highlightText(jobCode, labelMeta.inputValue)}</strong>
					<small><br /></small>
					{!!customerCompany && <small>{JobPicker.highlightText(customerCompany, labelMeta.inputValue)}</small>}
					{!!title && <small>{(!!customerCompany) && ' |'} {JobPicker.highlightText(title, labelMeta.inputValue)}</small>}
					{!!customerFormatted &&
						<small>
							{(!!title || !!customerCompany) && ' |'} {JobPicker.highlightText(customerFormatted, labelMeta.inputValue)}
						</small>
					}
					{!!travelLocationShort &&
						<small>
							{(!!customerFormatted || !!title || !!customerCompany) && ' |'} {JobPicker.highlightText(travelLocationShort, labelMeta.inputValue)}
						</small>
					}
				</div>
				{isInternal &&
					<BadgeCell
						badgeColor={ColorHex.BLACK}
						colorNegative={false}
						text="INTERNAL"
					/>
				}
			</div>
		);
	};

	static jobCodeFilterBy = (option: Option, text: string) => {
		let { jobCode, title, customerCompany, customerFormatted, travelLocationShort } = option.data;
		const { isInternal } = option.data;

		if (!text) {
			return true;
		}

		const searchText = text.toLowerCase();
		customerCompany = customerCompany ?? '';
		jobCode = jobCode?.toLowerCase() ?? '';
		title = title?.toLowerCase() ?? '';
		customerFormatted = customerFormatted?.toLowerCase() ?? '';
		travelLocationShort = travelLocationShort?.toLowerCase() ?? '';
		const jobTypeText = isInternal ? 'internal' : '';
		return jobCode.includes(searchText)
			|| title.includes(searchText)
			|| customerCompany.includes(searchText)
			|| customerFormatted.includes(searchText)
			|| travelLocationShort.includes(searchText)
			|| jobTypeText.includes(searchText);
	};

	async componentDidMount() {
		const { loadJobs } = this.props;
		const jobs = await loadJobs();
		this.setState(() => ({ jobs }));
	}

	componentDidUpdate(prevProps: Props) {
		const { job } = this.props;
		const { showDropdown } = this.state;

		if (!prevProps.job && job && showDropdown) {
			this.setState(() => ({ showDropdown: false }));
		}
	}

	changeJob = () => this.setState(() => ({ showDropdown: true }));

	selectJobsProjectManager = (job: WorkOrderFM['job']) => {
		const { change } = this.props;

		if (!job?.projectManager) {
			return;
		}

		change('projectManagerId', job.projectManager.id);
		change('projectManager', job.projectManager);
	};

	onValueChange = async (job: JobViewModel) => {
		const { resetTimer, change, setIsJobChanged } = this.props;
		resetTimer();

		if (!job) {
			this.setState(() => ({ showDropdown: true }), () => {
				change('job', null);
				change('jobId', null);
				change('crewType', null);
				change('crewTypeId', null);
				change('customCrewType', null);
			});
			return;
		}

		const upsertJob = JobViewModel.toWorkOrderJobRequestModel(job);

		if (upsertJob.isInternal) {
			change('crewType', null);
			change('crewTypeId', null);
		} else {
			change('customCrewType', null);
		}
		this.selectJobsProjectManager(upsertJob);
		change('job', upsertJob);
		change('jobId', upsertJob.id);
		this.setState(() => ({ showDropdown: false }));
		setIsJobChanged();
	};

	lazyLoadJobs = async (val: number, isLazyLoaded: boolean) => {
		const { loadJobs } = this.props;
		if (!isLazyLoaded) {
			await loadJobs();
		}
	};

	renderPicker = () => {
		const { createNewJob } = this.props;
		const { jobs } = this.state;
		return (
			<Row className="row--padded">
				<Col lg={16}>
					<Field
						allowNew={true}
						component={JobSelect}
						containerClassName="work-order-upsert__job-dropdown-container"
						containerId="work-order__job"
						disableErrorMessage={true}
						filterOption={JobPicker.jobCodeFilterBy}
						formatOptionLabel={JobPicker.renderJobMenuItem}
						getNewOptionData={JobPicker.getNewOptionData}
						getOptionValue={JobPicker.getOptionValue}
						name="job"
						onCreateNew={createNewJob}
						onValueChange={this.onValueChange}
						options={jobs}
						placeholder="Select Job or Create New"
					/>
				</Col>
			</Row>
		);
	};

	renderDetails = () => {
		const { job, disabled, cancellationReason, updatedAt, updatedBy, status } = this.props;
		const {
			travelLocationShort,
			office,
			customerCompany,
		} = job;

		return (
			<>
				<div className="work-order-upsert__info work-order-upsert__section">
					<Row className="row--non-padded">
						<Col lg={6}>
							<LockedValue
								label="Job Title / Job ID"
								value={JobPicker._renderJobCodeValue(job)}
							/>
						</Col>
						<Col lg={4}>
							<LockedValue
								label="Location"
								value={travelLocationShort}
							/>
						</Col>
						<Col lg={4}>
							<LockedValue
								label="Office"
								value={office}
							/>
						</Col>
						<Col lg={4}>
							<LockedValue
								label="Customer"
								value={customerCompany}
							/>
						</Col>
					</Row>
					{!disabled &&
						<Button
							label="Change Job"
							onClick={this.changeJob}
							style="secondary"
						/>
					}
				</div>
				{status === WorkOrderStatus.CANCELED &&
					<CanceledRibbon
						cancellationReason={cancellationReason}
						updatedAt={updatedAt}
						updatedBy={updatedBy}
					/>
				}
			</>
		);
	};

	render() {
		const { job } = this.props;
		const { showDropdown } = this.state;
		return (!job || showDropdown) ? this.renderPicker() : this.renderDetails();
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { workOrder: { order }, user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isCreate = !order?.id;
	const {
		job: woJob,
		status,
		cancellationReason,
		updatedAt,
		updatedBy,
		crewTypeId,
		customCrewType,
	} = selector(state, 'job', 'status', 'cancellationReason', 'updatedAt', 'updatedBy', 'crewTypeId', 'customCrewType');
	const hasCreateJobPermission = isAllowed(PagePermissions.COMPANY.JOBS.CREATE, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isCreate,
		disabled: status === WorkOrderStatus.CANCELED || status === WorkOrderStatus.LOCKED || order?.isPaused,
		job: woJob,
		hasCreateJobPermission,
		cancellationReason,
		updatedAt,
		updatedBy,
		crewTypeId,
		customCrewType,
		status,
	};
}

function mapDispatchToProps() {
	return {
		loadJobs: JobActions.getAllInProgressJobCodes,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<WorkOrderFM>({ form: WORK_ORDER_FORM })
);

export default enhance(JobPicker);
