import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { connect } from 'react-redux';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import WorkOrderReviewStatus, { isReviewable, isApprovable, isRejectable, isFinalizeable } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';

import { formatDate } from '@acceligentllc/shared/utils/time';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type TimeAllocationEntryVM from 'ab-viewModels/timeAllocationEntry/timeAllocationEntry';

import type WorkOrderFieldReportCardVM from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import SocketEvent from 'ab-enums/socketEvent.enum';

import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';
import Dropdown from 'af-components/Controls/Dropdown';
import ProjectSubJobIndicator from 'af-components/ProjectSubJobIndicator';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as JobWorkSummaryActions from 'af-actions/jobWorkSummary';

import { bemElement } from 'ab-utils/bem.util';
import { isAllowed } from 'ab-utils/auth.util';

import socket from 'af-utils/socket.util';

import type { RootState } from 'af-reducers';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';
import { useTaskRecovery } from 'af-root/hooks/useTaskRecovery';

import { withTaskRecovery } from 'af-root/context/taskRecoveryContext';

import { useToggle } from 'af-utils/react.util';

import AccessModal from '../FieldReportAccess';

import WorkOrderRejectionReason from './WorkOrderRejectionReason';
import WorkOrderReviewedByLabel from './WorkOrderReviewedByLabel';
import WorkOrderPauseReason from './WorkOrderPauseReason';
import DisplayReviewStatusLabel from '../../Shared/DisplayReviewStatusLabel';
import WorkOrderAssociatedSubJobData from './WorkOrderAssociatedSubJobData';
import WorkOrderLinks from './WorkOrderLinks';

const ALLOWED_RETRIES = 4;

// Function to compare time allocation entries
const areEntriesDifferent = (prevEntries: TimeAllocationEntryVM[], newEntries: TimeAllocationEntryVM[]) => {
	if (prevEntries.length !== newEntries.length) {
		return true;
	}

	for (let i = 0; i < prevEntries.length; i++) {
		const prevEntry = prevEntries[i];
		const newEntry = newEntries[i];
		if (
			prevEntry.id !== newEntry.id ||
			prevEntry.accountId !== newEntry.accountId ||
			prevEntry.workOrderId !== newEntry.workOrderId ||
			prevEntry.workType !== newEntry.workType ||
			prevEntry.time !== newEntry.time ||
			prevEntry.allocatedWorkRequestId !== newEntry.allocatedWorkRequestId
		) {
			return true;
		}
	}

	return false;
};

interface DropdownOption {
	label: string;
	onClick: () => void;
}

interface OwnProps {
	workOrder: WorkOrderFieldReportCardVM;
	fieldReportAccessDayDuration: number;
	isManagerAdminSI: boolean;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	canApproveOrReject: boolean;
	canCreate: boolean;
	canSubmit: boolean;
	canFinalize: boolean;
	isCanceled: boolean;
	isFRStatusOfInterest: boolean;
	isReadOnly: boolean;
	createFieldReport: () => void;
	submitForReview: () => void;
	approve: () => void;
	reject: () => void;
	finalize: () => void;
	orgAlias: string;
	companyName: string;
	timeAllocationEntries: TimeAllocationEntryVM[];
	jobId: number;
	redirectToVirtualFieldReport: (subjob: AssociatedSubjobVM) => void;
	associatedWRId: Nullable<number>;
	setShowedAssociatedSubjob: (subjob: Nullable<AssociatedSubjobVM>) => void;
	redirectToFieldReport: () => void;
	redirectToWorkOrder: () => void;
	redirectToJob: (jobId: number) => void;
	reviewVirtual: () => void;
	virtualReportReviewedAt: Nullable<Date>;
	virtualReportLastReviewedBy: Nullable<string>;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const WorkOrderInfoCard = (props: Props) => {
	const {
		workOrder,
		fieldReportAccessDayDuration,
		isCanceled,
		calculatedReportDisplayStatus,
		isFRStatusOfInterest,
		canApproveOrReject,
		canFinalize,
		canSubmit,
		isManagerAdminSI,
		isReadOnly,
		canCreate,
		createFieldReport,
		downloadCompleteReportsPdf,
		downloadPublicReportsPdf,
		findOrCreateCompleteReportBulkGroupCode,
		findOrCreatePublicReportLink,
		submitForReview,
		approve,
		reject,
		finalize,
		findAssociatedSubjobs,
		timeAllocationEntries,
		setShowedAssociatedSubjob,
		associatedWRId,
		redirectToJob,
		redirectToFieldReport,
		redirectToVirtualFieldReport,
		redirectToWorkOrder,
		reviewVirtual,
		virtualReportReviewedAt,
		virtualReportLastReviewedBy,
		canSeeAssociatedSubJobData,
	} = props;

	const {
		value: showAccessModal,
		setToTrue: openAccessModal,
		setToFalse: closeAccessModal,
	} = useToggle(false);

	const [allocations, setAllocations] = React.useState<Nullable<AssociatedSubjobVM[]>>(null);

	const prevTimeAllocationEntriesRef = React.useRef(timeAllocationEntries);

	const fetchAssociatedSubJobs = React.useCallback(async () => {
		if (!workOrder.isProjectOrSubjob || !canSeeAssociatedSubJobData) {
			return;
		}
		const _allocations = await findAssociatedSubjobs(workOrder.id);

		setAllocations(_allocations);
	}, [findAssociatedSubjobs, workOrder.id, workOrder.isProjectOrSubjob, canSeeAssociatedSubJobData]);

	React.useEffect(() => {
		fetchAssociatedSubJobs();
	}, [fetchAssociatedSubJobs]);

	React.useEffect(() => {
		if (!workOrder.isProjectOrSubjob || !canSeeAssociatedSubJobData) {
			return;
		}
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.ASSOCIATED_SUB_JOB_DATA_UPDATED, fetchAssociatedSubJobs);

		return () => {
			if (!workOrder.isProjectOrSubjob) {
				return;
			}
			socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT_LIST.ASSOCIATED_SUB_JOB_DATA_UPDATED);
		};
	}, [fetchAssociatedSubJobs, workOrder.isProjectOrSubjob, canSeeAssociatedSubJobData]);

	React.useEffect(() => {
		if (allocations) {
			setShowedAssociatedSubjob(allocations.find((all) => all.id === associatedWRId)!);
		}
	}, [allocations, associatedWRId, setShowedAssociatedSubjob]);

	React.useEffect(() => {
		if (areEntriesDifferent(prevTimeAllocationEntriesRef.current, timeAllocationEntries)) {
			fetchAssociatedSubJobs();
		}
		// Update the ref to the current entries after comparison
		prevTimeAllocationEntriesRef.current = timeAllocationEntries;
	}, [fetchAssociatedSubJobs, timeAllocationEntries]);

	const createReport = React.useCallback(() => {
		createFieldReport();
	}, [createFieldReport]);

	const notificationSnackbar = useNotificationSnackbar();
	const taskRecovery = useTaskRecovery();

	const exportToCustomerPDF = React.useCallback(async () => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const publicLink = await findOrCreatePublicReportLink([workOrder.id]);
		if (!!publicLink) {
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadPublicReportsPdf(publicLink, `${workOrder.code}_report`, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
		}
	}, [notificationSnackbar, findOrCreatePublicReportLink, workOrder.id, workOrder.code, downloadPublicReportsPdf, taskRecovery]);

	const exportToCompletePDF = React.useCallback(async () => {
		const msg = notificationSnackbar.info('Started preparing for download');
		const bulkGroupCode = await findOrCreateCompleteReportBulkGroupCode([workOrder.id]);
		if (!!bulkGroupCode) {
			notificationSnackbar.removeNotificationSnackbar(msg);
			await downloadCompleteReportsPdf(bulkGroupCode, `${workOrder.code}_report`, notificationSnackbar, taskRecovery, ALLOWED_RETRIES);
		}
	}, [notificationSnackbar, findOrCreateCompleteReportBulkGroupCode, workOrder.id, workOrder.code, downloadCompleteReportsPdf, taskRecovery]);

	const submitTitle = React.useMemo(() => {
		return isManagerAdminSI && workOrder.reviewStatus === WorkOrderReviewStatus.DRAFT ? 'Submit' : 'Submit for Review';
	}, [isManagerAdminSI, workOrder.reviewStatus]);

	const actionOptions = React.useMemo(() => {

		const options: DropdownOption[] = [];

		if (isManagerAdminSI) {
			options.push({ label: 'Manage Access', onClick: openAccessModal });
		}

		if (!isReadOnly && canCreate && !isCanceled) {
			options.push({ label: 'New Field Report', onClick: createReport });
		}

		if (!isCanceled) {
			options.push({ label: 'Export for Customer', onClick: exportToCustomerPDF });
			options.push({ label: 'Export Complete', onClick: exportToCompletePDF });
		}

		return options;
	}, [canCreate, createReport, exportToCompletePDF, exportToCustomerPDF, isCanceled, isManagerAdminSI, isReadOnly, openAccessModal]);

	const renderDropdownOptionsButton = () => {

		if (!actionOptions.length) {
			return null;
		}

		return (
			<div className={bemElement('field-report-work-order-info-card', 'dropdown-options')}>
				<Dropdown<DropdownOption>
					containerClassName="field-report-list-table__action-dropdown"
					id="actions"
					isActionDropdown={true}
					labelKey="label"
					options={actionOptions}
				/>
			</div>
		);
	};

	const renderButtonsAndActions = () => {
		const isFinalizeDisabled = !isFinalizeable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canFinalize || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isSubmitForReviewDisabled = !isReviewable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canSubmit || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isApprovalDisabled = !isApprovable(workOrder.reviewStatus)
			|| !canApproveOrReject || isCanceled || workOrder.isPaused || !!associatedWRId;
		const isRejectDisabled = !isRejectable(workOrder.reviewStatus, workOrder.reviewLevel)
			|| !canApproveOrReject || isCanceled || workOrder.isPaused || !!associatedWRId;
		const showReviewButton = !!associatedWRId && (!virtualReportReviewedAt || virtualReportReviewedAt < workOrder.reportLastUpdatedAt!);
		const hasReviewInfo = !!virtualReportReviewedAt;
		const isReviewOutdated = hasReviewInfo && virtualReportReviewedAt < workOrder.reportLastUpdatedAt!;

		return (
			<div className="field-report-work-order-info-card__header__actions">
				{hasReviewInfo &&
					<div className="field-report-work-order-info-card__header__virtual_review_info">
						<div className="field-report-work-order-info-card__header__virtual_reviewed_by">
							Last reviewed {formatDate(virtualReportReviewedAt, TimeFormat.FULL_DATE)}
						</div>
						By {virtualReportLastReviewedBy}
						{isReviewOutdated && <div className="field-report-work-order-info-card__header__virtual_review_outdated">
							Changes have been made since last review
						</div>}
					</div>
				}
				{
					showReviewButton &&
					<Button
						customStyleClass="field-report-work-order-info-card__header__virtual_review_button"
						icon="display_view"
						label="Review"
						onClick={reviewVirtual}
						style="secondary"
					/>
				}
				{!isSubmitForReviewDisabled &&
					<Button
						disabled={isSubmitForReviewDisabled}
						label={submitTitle}
						onClick={submitForReview}
						style="primary"
					/>
				}
				{!isApprovalDisabled &&
					<Button
						disabled={isApprovalDisabled}
						label="Approve"
						onClick={approve}
						style="primary"
					/>
				}
				{!isRejectDisabled &&
					<Button
						disabled={isRejectDisabled}
						label="Reject"
						onClick={reject}
						style="danger"
					/>
				}
				{!isFinalizeDisabled &&
					<Button
						disabled={isFinalizeDisabled}
						label="Finalize"
						onClick={finalize}
						style="primary"
					/>
				}
				{!associatedWRId && renderDropdownOptionsButton()}
			</div>
		);
	};

	return (
		<div className="form-box field-report-work-order-info-card">
			<div className="field-report-work-order-info-card__header">
				<div className="field-report-work-order-info-card__header__title">
					<DisplayReviewStatusLabel
						calculatedReportDisplayStatus={calculatedReportDisplayStatus}
						isCondensedView={false}
						isOfInterest={isFRStatusOfInterest}
					/>
					{isCanceled &&
						<div className="field-report-work-order-info-card__header__title__canceled">
							CANCELED
						</div>
					}
					<div className="field-report-work-order-info-card__header__title__name">
						{workOrder.code}
					</div>
					<div className="field-report-work-order-info-card__header__title__project-indicator">
						<ProjectSubJobIndicator isProject={workOrder.isProjectMainJob} isSubJob={workOrder.isProjectSubJob} />
					</div>
				</div>
				{renderButtonsAndActions()}
			</div>
			<Row className="row--padded">
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Date of Work"
						value={formatDate(workOrder.dueDate, TimeFormat.SB_DATE_SHORT, TimeFormat.ISO_DATETIME)}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Job Title"
						value={workOrder.jobTitle}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Location"
						value={workOrder.workLocationAddress}
					/>
				</Col>
				{workOrder.customerWorkOrder &&
					<Col sm={3}>
						<LockedValue
							label="Customer WO"
							value={workOrder.customerWorkOrder}
						/>
					</Col>
				}
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Superintendent"
						value={workOrder.supervisorFullName}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Crew Type"
						value={
							workOrder.crewTypeColor
								? <LabelWithColor color={workOrder.crewTypeColor} text={workOrder.crewTypeName} />
								: null
						}
					/>
				</Col>
				<Col sm={3}>
					<LockedValue
						defaultValue="N/A"
						label="Last Update"
						value={
							<LockedValue
								inverse={true}
								label={formatDate(workOrder.updatedAt, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}
								value={`by ${workOrder.updatedBy.fullNameShort}`}
							/>
						}
					/>
				</Col>
				<Col sm={3}>
					<WorkOrderReviewedByLabel
						currentReviewHistoryItem={workOrder.currentReviewHistoryItem}
						reviewLevel={workOrder.reviewLevel}
						reviewStatus={workOrder.reviewStatus}
					/>
				</Col>
				{
					associatedWRId &&
					<WorkOrderLinks
						jobId={associatedWRId!}
						redirectToFieldReport={redirectToFieldReport}
						redirectToJob={redirectToJob}
						redirectToWorkOrder={redirectToWorkOrder}
					/>
				}
			</Row>
			<WorkOrderRejectionReason
				workOrderReviewHistoryItem={workOrder.currentReviewHistoryItem}
			/>
			<WorkOrderPauseReason
				pauseReason={workOrder.pauseReason}
			/>
			{
				!associatedWRId
				&& workOrder.isProjectOrSubjob
				&& canSeeAssociatedSubJobData
				&& process.env.FTD_PROJECT !== 'true' &&
				<WorkOrderAssociatedSubJobData
					allocations={allocations}
					fetchAssociatedSubJobs={fetchAssociatedSubJobs}
					redirectToVirtualFieldReport={redirectToVirtualFieldReport}
				/>
			}
			<AccessModal
				close={closeAccessModal}
				fieldReportAccessDayDuration={fieldReportAccessDayDuration}
				showModal={showAccessModal}
				workOrderId={workOrder.id}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { user: { companyData, userData } } = state;
	const { isManagerAdminSI } = ownProps;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasPermissionsToAccessWorkSummary = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_WORK_SUMMARY,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		canSeeAssociatedSubJobData: isManagerAdminSI || hasPermissionsToAccessWorkSummary,
	};
}

function mapDispatchToProps() {
	return {
		downloadCompleteReportsPdf: WorkOrderActions.downloadCompleteReportPDF,
		downloadPublicReportsPdf: WorkOrderActions.downloadPublicReportPDF,
		findOrCreateCompleteReportBulkGroupCode: WorkOrderActions.findOrCreateCompleteGroupCode,
		findOrCreatePublicReportLink: WorkOrderActions.findOrCreatePublicGroupCode,
		findAssociatedSubjobs: WorkOrderActions.findAssociatedSubjobs,
		findJWSWorkRequestIds: JobWorkSummaryActions.findJWSWorkRequestIds,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	withTaskRecovery
);

export default enhance(WorkOrderInfoCard);
