import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import FileType from '@acceligentllc/shared/enums/fileType';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as BlobFileUtil from 'ab-utils/blobFile.util';

import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import Checkbox from 'af-components/Controls/Checkbox';

import * as AttachmentActions from 'af-actions/attachment';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-routes/client';

import type { AttachmentEntity, AttachmentWorkOrderEntity } from '../types';

import ImagePreviewModal from './ImagePreviewModal';

import styles from './styles.module.scss';

const FILE_TYPE_ICONS = {
	[FileType.DOCX]: 'file_type_docx',
	[FileType.DOC]: 'file_type_docx',
	[FileType.PDF]: 'file_type_pdf',
	[FileType.XLS]: 'file_type_xls',
	[FileType.XLSX]: 'file_type_xls',
	[FileType.ZIP]: 'file_type_zip',
};

interface AttachmentRowActions {
	actionName: string;
	actionFunction: (original: AttachmentEntity) => void;
	disabled?: boolean;
	show?: boolean;
}

interface TypedColumn {
	header: string;
	accessor?: string;
	id?: string;
	className?: string;
	width?: number;
}

interface OwnProps {
	attachment: AttachmentEntity;
	attachmentRowActions?: AttachmentRowActions[];
	directoryId: number;
	columns: TypedColumn[];
	level: number;
	visibleRows?: number[];
	id: number;
	fetchData?: () => void;
	onCopyToWOChange?: (attachmentId: number, copyToWorkOrder: boolean) => void;
	isReadOnly?: boolean;
}

function mapStateToProps(state: RootState) {
	const { user: { companyData } } = state;
	if (!companyData) {
		throw new Error('Company Data not present.');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		downloadAttachment: AttachmentActions.downloadAttachment,
		copyAttachmentToWO: AttachmentActions.copyAttachmentToWO,
		deleteAttachment: AttachmentActions.deleteAttachment,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = OwnProps & ConnectedProps<typeof connector>;

const AttachmentTableRowComponent = (props: Props) => {
	const {
		attachment,
		companyName,
		directoryId,
		columns,
		downloadAttachment,
		copyAttachmentToWO,
		fetchData,
		visibleRows,
		level,
		id,
		attachmentRowActions,
		onCopyToWOChange,
		isReadOnly = false,
	} = props;
	const [modalPreviewImageVisible, setModalPreviewImageVisible] = React.useState(false);
	const [linkedWorkOrderListExpanded, setLinkedWorkOrderListExpanded] = React.useState(false);

	const { state: { orgAlias } } = useLocation<{ orgAlias: string; }>();

	const hasAttachedToWOColumn: boolean = columns.some((col) => col.id === 'attachedToWO');
	const hasCopyToWOColumn: boolean = columns.some((col) => col.id === 'copyToWO');

	const dotIndex = attachment.name.lastIndexOf('.');
	const attachmentNameWithoutType = (dotIndex !== -1) ? attachment.name.substring(0, dotIndex) : attachment.name;

	// Classes for styling
	const tableRow = 'tree-table-row';
	const cellFlex1 = 'cell-flex-1';
	const cellFlex2 = 'cell-flex-2';
	const settingsActions = 'settings-actions';
	const dropdownToggle = `${settingsActions}__dropdown-toggle`;

	const handleCopyToWO = React.useCallback(async () => {
		if (onCopyToWOChange) {
			onCopyToWOChange(attachment.id, !attachment.copyToWorkOrderFlag);
			return;
		}
		await copyAttachmentToWO(attachment.id, directoryId, id, !attachment.copyToWorkOrderFlag);
		fetchData?.();
	}, [attachment.copyToWorkOrderFlag, attachment.id, copyAttachmentToWO, directoryId, fetchData, id, onCopyToWOChange]);

	const onClickHandler = (rowAction: (arg0: AttachmentEntity) => void) => {
		const attExtended: AttachmentEntity = attachment;
		attExtended.directoryId = directoryId;
		rowAction(attExtended);
	};

	const callDownloadAttachment = () => {
		const fileName = `${attachmentNameWithoutType}${attachment.type}`;
		downloadAttachment(attachment.id, fileName);
	};

	const toggleLinkedWorkOrderLinks = React.useCallback(() => {
		setLinkedWorkOrderListExpanded((expanded) => !expanded);
	}, []);

	const renderWorkOrderLink = React.useCallback((workOrder: AttachmentWorkOrderEntity) => {
		const href = CLIENT.COMPANY.WORK_ORDERS.ORDER(workOrder.id.toString(), orgAlias, companyName);
		return (
			<a className={styles['wo-link']} href={href} key={workOrder.id} rel="noreferrer" target="_blank">
				<span className={`icon-external ${styles['external-icon']}`} />{workOrder.code}
			</a>
		);
	}, [companyName, orgAlias]);

	const resolvedFileIcon = React.useMemo(() => {
		if (attachment.type in FILE_TYPE_ICONS) {
			return `icon-${FILE_TYPE_ICONS[attachment.type]}`;
		}
		return 'icon-notes';
	}, [attachment.type]);

	const renderWorkOrderLinks = React.useCallback(() => {
		if (!attachment.workOrders?.length) {
			return null;
		}

		const collapseIcon = linkedWorkOrderListExpanded ? 'icon-collapse' : 'icon-expand';

		return (
			<div className={styles['linked-wo-list-container']}>
				{/* eslint-disable-next-line dot-notation */}
				<div className={styles['expander']} onClick={toggleLinkedWorkOrderLinks}>
					<span className={`${collapseIcon} ${styles['expander-icon']}`} />
					<span>{linkedWorkOrderListExpanded ? 'Hide All' : 'Show All'}</span>
					<span className={styles['wo-counter']}>({attachment.workOrders.length})</span>
				</div>
				{linkedWorkOrderListExpanded && (
					<div className={styles['wo-list']}>{attachment.workOrders?.map(renderWorkOrderLink)}</div>
				)}
			</div>
		);
	}, [attachment.workOrders, linkedWorkOrderListExpanded, renderWorkOrderLink, toggleLinkedWorkOrderLinks]);

	const childFlexLevel = (level * 0.5) + 1;
	const childDirectoryNameFlexLevel = 6 - (level * 0.5) - 0.5;

	return (
		<React.Fragment key={attachment.id}>
			<div className={`${styles[tableRow]}`}
				style={{ display: visibleRows?.includes(directoryId) ? undefined : 'none' }}
			>
				<div style={{ flex: childFlexLevel }} />
				{/* Attachment icon and name */}
				<div className={`${styles['category-name']}`} style={{ flex: childDirectoryNameFlexLevel }}>
					<div className={`${styles['directory-attachment-icon']}`}>
						<span className={resolvedFileIcon}>
						</span>
					</div>
					<div className={`${styles['directory-attachment-name']}`}>
						{attachment.name}
					</div>
				</div>
				{/* Number of Files */}
				<div className={`${styles[cellFlex1]}`} >-</div>
				{/* Dowlnoad icon */}
				<div className={`${styles[cellFlex1]}`} >
					<div className={styles['download-icon']}>
						<div className="icon-download" onClick={callDownloadAttachment.bind(this)} />
					</div>
				</div>
				{/* Upload icon */}
				{!isReadOnly && <div className={`${styles[cellFlex1]}`} />}
				{/* Preview icon */}
				<div className={`${styles[cellFlex1]}`} >
					<div className={styles['preview-icon']}>
						{
							BlobFileUtil.isImageFile(attachment.storageName) && (
								<>
									<div className="icon-zoom_in" onClick={setModalPreviewImageVisible.bind(this, !modalPreviewImageVisible)} />
									{modalPreviewImageVisible &&
										<ImagePreviewModal
											attachment={attachment}
											setModalVisible={setModalPreviewImageVisible}
											showModal={modalPreviewImageVisible}
										/>
									}
								</>
							)
						}
					</div>
				</div>

				{/* Copy to WO */}
				{hasCopyToWOColumn &&
					<div className={`${styles[cellFlex2]}`}>
						<Checkbox
							handleChange={handleCopyToWO}
							isChecked={attachment.copyToWorkOrderFlag ?? false}
						/>
					</div>
				}
				{/* Attached to WO */}
				{hasAttachedToWOColumn &&
					<div className={`${styles[cellFlex2]}`}>
						{renderWorkOrderLinks()}
					</div>
				}

				<div className={`${styles[cellFlex2]}`} >
					<div>
						<LastUpdatedByCell
							updatedAt={TimeUtils.formatDate(attachment.lastModifiedAt, TimeFormat.FULL_DATE)}
							updatedBy={attachment.uploadedBy}
						/>
					</div>
				</div>
				{!isReadOnly && (
					<div className={`${styles[cellFlex1]}`}>
						<Dropdown
							className={`${styles[settingsActions]}`}
							id={`table-row-${attachment.id}`}
						>
							<Dropdown.Toggle className={`${styles[dropdownToggle]}`}>
								<span className="icon-actions icon" />
							</Dropdown.Toggle>
							<Dropdown.Menu >
								{attachmentRowActions?.map((_rowAction) => {
									return (
										<React.Fragment key={`${_rowAction.actionName}_${attachment.id}`}>
											<Dropdown.Item
												disabled={_rowAction.disabled}
												onClick={onClickHandler.bind(this, _rowAction.actionFunction)}
												open={false}
											>
												{_rowAction.actionName}
											</Dropdown.Item>
										</React.Fragment>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default connector(AttachmentTableRowComponent);
