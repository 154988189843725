import type { ColorPalette } from '@acceligentllc/shared/enums/color';

import type EquipmentBase from 'ab-domain/models/equipment/base';

export class W_FieldReport_GetEquipmentList_VM {
	suggested: W_FieldReport_GetEquipmentList_VM_Item[];
	all: W_FieldReport_GetEquipmentList_VM_Item[];

	constructor(suggested: EquipmentBase[], all: EquipmentBase[]) {
		this.suggested = W_FieldReport_GetEquipmentList_VM_Item.bulkConstructor(suggested);
		this.all = W_FieldReport_GetEquipmentList_VM_Item.bulkConstructor(all);
	}
}

class W_FieldReport_GetEquipmentList_VM_Item {
	id: number;
	code: string;
	color: ColorPalette;
	specification: Nullable<string>;

	constructor(equipment: EquipmentBase) {
		if (!equipment.equipmentCost.category.categoryColor) {
			throw new Error('Equipment information incomplete');
		}

		this.id = equipment.id;
		this.code = equipment.code;
		this.color = equipment.equipmentCost.category.categoryColor;
		this.specification = equipment.specification;
	}

	private static _constructorMap = (equipment: EquipmentBase) =>
		new W_FieldReport_GetEquipmentList_VM_Item(equipment);

	static bulkConstructor = (equipment: EquipmentBase[]) =>
		equipment.map(W_FieldReport_GetEquipmentList_VM_Item._constructorMap);
}
