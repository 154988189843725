import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import styles from './styles.module.scss';

type Props = {
	onDeleteClick: () => void;
};

const DeleteCell: React.FC<Props> = ({ onDeleteClick }) => {
	return (
		<span className={styles['delete-cell']}>
			<Button icon="delete" onClick={onDeleteClick} style="link-danger" />
		</span>
	);
};

export default React.memo(DeleteCell);
