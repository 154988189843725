import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import type { RootState } from 'af-reducers';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import { bemBlock } from 'ab-utils/bem.util';
import { isFirstRevision } from 'ab-utils/scheduleBoard.util';

import StatusIcon from 'af-components/StatusIcon';

import WorkOrderAction from './WorkOrderActions';

interface OwnProps {
	isFetching: boolean;
	resetTimer: () => void;
	goBack: (checkIfFormIsDirty?: boolean) => void;
	isJobChanged: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class WorkOrderHeader extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		workOrderStatus: WorkOrderStatus.DRAFT,
		workOrderCode: 'NEW WORK ORDER',
	};

	render() {
		const {
			workOrderId,
			workOrderCode,
			workOrderStatus,
			notificationsEnabled,
			notificationStatus,
			showInfo,
			isCreate,
			hasJob,
			revision,
			statusBasedRevision,
			isFetching,
			resetTimer,
			goBack,
			isJobChanged,
		} = this.props;

		const iconClassName = bemBlock('work-order-upsert__status-icon', {
			draft: workOrderStatus === WorkOrderStatus.DRAFT,
			published: workOrderStatus === WorkOrderStatus.PUBLISHED,
			canceled: workOrderStatus === WorkOrderStatus.CANCELED,
			outdated: workOrderStatus === WorkOrderStatus.OUTDATED,
		});
		const codeClassName = bemBlock('work-order-upsert__code', { extended: !showInfo });
		const infoClassName = bemBlock('work-order-upsert__additional-info', { extended: showInfo });

		const workOrderCodeTitle = `${workOrderCode}${revision ? `, Rev. ${revision}` : ''}${statusBasedRevision ? `.${statusBasedRevision}` : ''}`;

		return (
			<div className="form-box work-order-upsert__sticky-header">
				<div className={codeClassName}>
					<StatusIcon
						className={iconClassName}
						firstRevision={isFirstRevision(revision)}
						notificationsEnabled={notificationsEnabled}
						notificationStatus={notificationStatus}
						status={workOrderStatus}
					/>
					{workOrderCodeTitle}
				</div>
				{showInfo &&
					<div className={infoClassName}>
						({!isCreate ? 'New ' : ''}ID will be generated after saving)
					</div>
				}
				<WorkOrderAction
					hasJob={hasJob}
					isCreate={isCreate}
					isFetching={isFetching}
					isJobChanged={isJobChanged}
					redirect={goBack}
					resetTimer={resetTimer}
					status={workOrderStatus}
					workOrderId={workOrderId}
				/>
			</div>
		);
	}
}

const selector = formValueSelector(WORK_ORDER_FORM);
function mapStateToProps(state: RootState) {
	const { workOrder: { order }, company: { company } } = state;

	const {
		jobId,
		status,
		code,
		revision,
		statusBasedRevision,
		notificationStatus,
	} = selector(state, 'jobId', 'status', 'code', 'revision', 'statusBasedRevision', 'notificationStatus') as NonNullable<RootState['workOrder']['order']>;
	const isCreate = !order?.id;
	const showInfo = jobId !== order?.jobId || isCreate;

	return {
		showInfo,
		isCreate,
		hasJob: !!jobId,
		notificationsEnabled: company?.notification?.isEnabled ?? false,
		workOrderId: order?.id,
		workOrderCode: code ?? order?.code,
		revision: revision ?? order?.revision,
		statusBasedRevision: statusBasedRevision ?? order?.statusBasedRevision,
		workOrderStatus: status ?? order?.status,
		notificationStatus: notificationStatus ?? order?.notificationStatus,
	};
}

const connector = connect(mapStateToProps);

export default connector<React.ComponentClass<OwnProps>>(WorkOrderHeader);
