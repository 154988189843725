import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from '@acceligentllc/storybook';

import * as ReportBlockFieldEnums from '@acceligentllc/shared/enums/reportBlockField';
import type QuantityUnit from '@acceligentllc/shared/enums/quantityUnit';
import type OperationType from '@acceligentllc/shared/enums/operation';
import { OperationName } from '@acceligentllc/shared/enums/operation';

import Tooltip from 'af-components/Tooltip';

import { bemBlock } from 'ab-utils/bem.util';

interface OwnProps {
	fieldId: string;
	fieldIsVisibleToCustomer: boolean;
	fieldName: string;
	fieldType: ReportBlockFieldEnums.Type;
	hasTooltip: boolean;
	index: number;
	onFieldClick: (id: string) => void;
	onFieldVisibleToCustomerIconClick: (id: string) => void;
	operation: Nullable<OperationType>;
	options?: string[];
	removeField: (id: string) => void;
	tooltipText?: Nullable<string>;
	unit: Nullable<QuantityUnit>;
}

type Props = OwnProps;

const FieldInfoDraggable: React.FC<Props> = (props: Props) => {
	const {
		fieldId,
		fieldIsVisibleToCustomer,
		fieldName,
		fieldType,
		hasTooltip,
		index,
		onFieldClick,
		onFieldVisibleToCustomerIconClick,
		operation,
		options,
		removeField,
		tooltipText,
		unit,
	} = props;

	const handleRemoveClick = React.useCallback(() => {
		removeField(fieldId);
	}, [removeField, fieldId]);

	const handleLabelClick = React.useCallback(() => {
		onFieldClick(fieldId);
	}, [onFieldClick, fieldId]);

	const handleVisibleToCustomerIconClick = React.useCallback(() => {
		onFieldVisibleToCustomerIconClick(fieldId);
	}, [onFieldVisibleToCustomerIconClick, fieldId]);

	const stopPropagation = React.useCallback((e: React.MouseEvent) => { e.stopPropagation(); }, []);

	const draggableLabelClass = bemBlock('report-block-form__draggable-label', { stretched: true, danger: fieldIsVisibleToCustomer });

	let typeInfo = ReportBlockFieldEnums.PrimaryTypeNamed[fieldType];
	if (fieldType === ReportBlockFieldEnums.Type.NUMERIC_ATTRIBUTE && unit) {
		typeInfo = `${typeInfo}: ${unit}`;
	} else if (fieldType === ReportBlockFieldEnums.Type.DROPDOWN) {
		typeInfo = `${typeInfo}: ${options?.length ?? 0}`;
	} else if (fieldType === ReportBlockFieldEnums.Type.CALCULATED && operation) {
		typeInfo = `${OperationName[operation]}${unit ? `: ${unit}` : ''}`;
	}

	return (
		<Draggable
			draggableId={fieldId?.toString() ?? fieldName}	// important
			index={index}
		>
			{(provided) => (
				<div
					{...provided?.draggableProps}
					{...provided?.dragHandleProps}
					className="report-block-form__draggable-field"
					ref={provided?.innerRef}
				>
					<span className="icon-drag_indicator report-block-form__draggable-indicator" />
					<span className={draggableLabelClass} onClick={handleLabelClick}>
						<span>
							{fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK
								? <>
									{fieldName}
									<span className="report-block-form__draggable-label__type-info">({typeInfo})</span>
								</>
								: 'Line break'
							}
						</span>
						<span className="report-block-form__draggable-label__field_info" onClick={stopPropagation}>
							{hasTooltip &&
								<Tooltip className="tooltip--center-text" message={tooltipText}>
									<span className="icon-help_fill" />
								</Tooltip>
							}
							{
								fieldType !== ReportBlockFieldEnums.Type.LINE_BREAK &&
								<Button
									icon={fieldIsVisibleToCustomer ? 'visibility_on' : 'visibility_off'}
									onClick={handleVisibleToCustomerIconClick}
									style={fieldIsVisibleToCustomer ? 'link-danger' : 'link'}
									tooltip={ReportBlockFieldEnums.ReportBlockFieldFlagHint.IS_VISIBLE_TO_CUSTOMER}
								/>
							}
						</span>
					</span>
					<Button
						icon="delete"
						onClick={handleRemoveClick}
						style="link-danger"
						tooltip="Delete"
					/>
				</div>
			)}
		</Draggable>
	);
};

export default React.memo(FieldInfoDraggable);
