import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	showModal: boolean;
	onClose: () => void;
}

type Props = OwnProps;

class InfoModal extends React.PureComponent<Props> {
	render() {
		const { showModal, onClose } = this.props;

		return (
			<CustomModal
				closeModal={onClose}
				id="notify-employees__info-modal"
				modalStyle="warning"
				showModal={showModal}
				size="sm"
			>
				<CustomModal.Header
					closeModal={onClose}
					title="Notification Queued"
				/>
				<CustomModal.Body>
					The notification message is queued and will be sent to all recipients in a couple of minutes.
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Understood"
						onClick={onClose}
						style="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default InfoModal;
