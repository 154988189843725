import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import styles from './styles.module.scss';

interface Props {
	isManagerOrAdmin: boolean;
	isSuperIntendent: boolean;
	isOwner: boolean;
	isReadOnly: boolean;
	handleEdit: () => void;
	openViewHistoryModal?: () => void;
	showHistory?: boolean;
}

const TimeSheetRowActions: React.FC<Props> = (props: Props) => {
	const {
		isManagerOrAdmin,
		isSuperIntendent,
		isOwner,
		isReadOnly,
		handleEdit,
		openViewHistoryModal,
		showHistory = true,
	} = props;

	const showEdit = isOwner || isSuperIntendent || isManagerOrAdmin;
	const showHistoryButton = showHistory && (isManagerOrAdmin || isSuperIntendent);

	return (
		<div className={styles['actions-container']}>
			{showHistoryButton && !!openViewHistoryModal &&
				<Button
					icon="list"
					label="View Timesheet History"
					onClick={openViewHistoryModal}
					style="link"
				/>
			}
			{showEdit && (
				<Button
					icon={isReadOnly ? 'search' : 'edit'}
					label={isReadOnly ? 'Preview' : 'Edit'}
					onClick={handleEdit}
					style="link"
				/>
			)}
		</div>
	);
};

export default TimeSheetRowActions;
