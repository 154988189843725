import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

import type { BreadcrumbItem } from 'af-components/Breadcrumbs';
import Breadcrumbs from 'af-components/Breadcrumbs';

interface OwnProps {
	editUrl?: Nullable<string>;
	editLabel?: string;
	backToListUrl?: string;
	backToListLabel?: string;
	breadcrumbs: BreadcrumbItem[];
	isSmall?: boolean;
}

type Props = React.PropsWithChildren<OwnProps> & CustomRouteComponentProps<void>;

const ResourcePreview: React.FC<Props> = React.memo((props) => {
	const {
		breadcrumbs,
		backToListUrl,
		editUrl,
		backToListLabel,
		editLabel = 'Edit',
		isSmall = false,
		history,
	} = props;

	let segmentClassName = 'form-segment';
	segmentClassName = isSmall ? `${segmentClassName} form-segment--mini` : segmentClassName;

	const backToListAction = React.useCallback(() => {
		if (!backToListUrl) {
			return;
		}
		history.push(backToListUrl);
	}, [backToListUrl, history]);

	const editRedirectAction = React.useCallback(() => {
		if (!editUrl) {
			return;
		}
		history.push(editUrl);
	}, [editUrl, history]);

	return (
		<div className={segmentClassName}>
			<Breadcrumbs items={breadcrumbs} />
			<div className="form-box">
				{props.children}
				<Row className="row--submit">
					{
						backToListLabel && backToListUrl &&
						<Button
							label={backToListLabel}
							onClick={backToListAction}
							style="secondary"
							type="button"
						/>
					}
					{
						editUrl &&
						<Button
							label={editLabel}
							onClick={editRedirectAction}
							style="secondary"
							type="button"
						/>
					}
				</Row>
			</div>
		</div>
	);

});

export default withRouter(ResourcePreview);
