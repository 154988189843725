/* eslint-disable @typescript-eslint/no-explicit-any */
import * as io from 'socket.io-client';

import type ChangeCompanyRM from '@acceligentllc/shared/dtos/socket/request/connection/changeCompany';
import type HandshakeRM from '@acceligentllc/shared/dtos/socket/request/connection/handshake';

import type * as User from 'ab-viewModels/user.viewModel';

import type ClientToServerHandlers from 'ab-socketModels/handlerModels/client';
import type SeverToClientHandlers from 'ab-socketModels/handlerModels/server';
import type { PredefinedManagerEventClass } from 'ab-socketModels/handlerModels/predefined';

import SocketEvent from 'ab-enums/socketEvent.enum';

import { FORBIDDEN_ALIASES } from 'ab-common/constants/value';

import { SUBMIT_START, SUBMIT_END } from 'af-reducers/http.reducer';

import Timeout, { Interval } from 'af-utils/timeout.util';
import { getCurrentOrgAlias } from 'af-utils/window.util';
import { getSignInForOrg } from 'af-utils/localStorage.util';
import NR from 'af-utils/newRelic.util';
import AppStore from 'af-root/store';

const _nr = NR.init();

type SocketCallback = (...args: any) => void | Promise<void>;

type SocketOptions = {
	cb?: SocketCallback;
	submitting?: string;
};

type ExtendedClientToServerHandlers = {
	[key in keyof ClientToServerHandlers]: (...args: [...Parameters<ClientToServerHandlers[key]>, SocketCallback]) => ReturnType<ClientToServerHandlers[key]>;
};

interface SocketConnectionData {
	token: string;
	orgAlias: string;
	companyId: number;
	userId: number;
}

export default class SocketConnection {
	static connection: undefined | SocketConnection;
	static id: undefined | string;
	private static _onConnect: undefined | (() => void);
	private static _onDisconnect: undefined | (() => void);
	private static _onConnectError: undefined | (() => void);

	private _socket: undefined | io.Socket<SeverToClientHandlers, ClientToServerHandlers>;
	private _timeout: undefined | Timeout;
	private _customPing: undefined | Interval;
	private _data: undefined | SocketConnectionData;

	private _pingCount: number = 0;

	private constructor(onConnect?: () => void, onDisconnect?: () => void, onConnectError?: () => void) {
		this.connect(onConnect, onDisconnect, onConnectError);
	}

	static initConnection(onConnect?: () => void, onDisconnect?: () => void, onConnectError?: () => void) {
		SocketConnection._onConnect = onConnect;
		SocketConnection._onDisconnect = onDisconnect;
		SocketConnection._onConnectError = onConnectError;
		return SocketConnection.getConnection();
	}

	static getConnection() {
		if (!SocketConnection.connection) {
			try {
				SocketConnection.connection = new SocketConnection(
					SocketConnection._onConnect,
					SocketConnection._onDisconnect,
					SocketConnection._onConnectError
				);
			} catch (error) {
				// TODO: remove logging for development mode once we have figured out the problem with initializing sockets
				// if (isDevelopment()) {
				console.info(error);
				// }
				SocketConnection.connection = undefined;
			}
		}
		return SocketConnection.connection;
	}

	private extendedEmit = <E extends keyof ExtendedClientToServerHandlers>(eventName: E, ...args: Parameters<ExtendedClientToServerHandlers[E]>) => {
		if (this._timeout && eventName !== SocketEvent.V2.FE.GENERAL.PING_WITH_DATA) {
			this._timeout.reset();
		}
		if (this._customPing) {
			this._pingCount = 0;
			this._customPing.reset();
		}
		_nr.addToTrace({
			name: eventName,
			start: Date.now(),
			origin: 'socket emit',
		});
		this._socket?.emit(eventName, ...args);
	};

	/**
	 * Emits socket event.
	 */
	emit = <E extends keyof ClientToServerHandlers>(eventName: E, ...args: Parameters<ClientToServerHandlers[E]>) => {
		this.extendedEmit(eventName, ...args);
	};

	/**
	 * Emits socket event but returns promise.
	 * NOTE: Ack function is mandatory on server side!!!
	 */
	emitWithPromise = async <E extends keyof ClientToServerHandlers>(
		eventName: E,
		param: Nullable<SocketOptions>,
		...args: Parameters<ClientToServerHandlers[E]>
	): Promise<any> => {
		let cb: SocketOptions['cb'];
		let submitting: SocketOptions['submitting'];
		switch (typeof param) {
			case 'function':
				cb = param;
				break;
			case 'object':
				cb = param?.cb;
				submitting = param?.submitting;
				break;
		}

		return new Promise((resolve) => {
			if (this._timeout) {
				this._timeout.reset();
			}
			if (this._customPing) {
				this._pingCount = 0;
				this._customPing.reset();
			}

			this.dispatchSubmitStart(submitting);

			const ack: SocketCallback = async (arg) => {
				if (cb) {
					await cb(arg);
				}
				this.dispatchSubmitEnd(submitting);
				resolve(arg);
			};

			this.extendedEmit(eventName, ...([...args, ack]) as unknown as Parameters<ExtendedClientToServerHandlers[E]>);
		});
	};

	/**
	 * Subscribes to an event only once.
	 * In case override set to true, it can subscribe multiple times.
	 * Override is added because because Mechanic view and Daily View are subscribing to same events.
	 * @param event Event to subscribe to
	 * @param handler Handler for the event
	 * @param override Flag that determines if event can have multiple handlers
	 */
	// subscribe = (event: keyof SeverToClientHandlers, handler: SeverToClientHandlers[typeof event], override: boolean = false) => {
	subscribe = <K extends keyof SeverToClientHandlers>(event: K | 'connect_error', handler: SeverToClientHandlers[K], override: boolean = false) => {
		if (override || !this._socket?.hasListeners(event)) {
			this._socket?.on(event as keyof SeverToClientHandlers, handler);
		}
	};

	/**
	 * Subscribes to Manager event.
	 */
	subscribeToManager = <K extends keyof PredefinedManagerEventClass>(event: K, handler: PredefinedManagerEventClass[K]) => {
		if (!this._socket?.io?.hasListeners(event)) {
			this._socket?.io?.on(event as keyof PredefinedManagerEventClass, handler);
		}
	};

	/**
	 * Unsubscribes from an event only once.
	 */
	unsubscribe = <K extends keyof SeverToClientHandlers>(event: K) => {
		if (this._socket?.hasListeners(event)) {
			this._socket.off(event);
		}
	};

	/**
	 * Remove handler from an event
	 */
	removeHandler = <K extends keyof SeverToClientHandlers>(event: K, handler: SeverToClientHandlers[K]) => {
		if (this._socket?.hasListeners(event)) {
			this._socket.off(event as keyof SeverToClientHandlers, handler);
		}
	};

	/**
	 * Disconnects the socket and sets singleton instance to `undefined`
	 */
	disconnect = () => {
		if (this._socket?.connected) {
			this._socket.disconnect();
			if (this._timeout) {
				this._timeout.cancel();
			}
		}
	};

	/**
	 * Resets the timeout used for marking connection idle
	 * @param defaultTimeoutOverride - override function of default timeout
	 */
	resetTimeout = (defaultTimeoutOverride?: () => void) => {
		if (this._timeout) {
			this._timeout.reset(defaultTimeoutOverride);
		}
	};

	/**
	 * Emits a `FE.COMPANY.CHANGE_COMPANY` request and updates the connection data
	 */
	changeCompany = async (requestModel: ChangeCompanyRM) => {
		await this.emitWithPromise(SocketEvent.V2.FE.COMPANY.CHANGE_COMPANY, null, requestModel);

		if (!this._data) {
			return;
		}

		this._data.orgAlias = requestModel.orgAlias;
		this._data.companyId = requestModel.companyId;
	};

	/**
	 * Returns `true` if socket is connected
	 */
	isConnected = () => this._socket?.connected;

	/**
	 * Returns data submitted as handshake params in `connect` or changed later on via `changeCompany`
	 */
	getData = (): Nullable<SocketConnectionData> => {
		if (!this._data || !SocketConnection.connection?._data) {
			// this should never happen
			return null;
		}
		return { ...SocketConnection.connection._data };
	};

	getId = (): Nullable<string> => this.isConnected() ? this._socket!.id! : null;

	private connect = (onConnect?: () => void, onDisconnect?: () => void, onConnectError?: () => void) => {
		const orgAlias = getCurrentOrgAlias();

		if (!orgAlias || FORBIDDEN_ALIASES.includes(orgAlias)) {
			throw new Error('[Socket IO]: Prevented establishing connection. Invalid org alias.');
		}

		const currentSignIn: Nullable<User.SignInData> = getSignInForOrg(orgAlias);

		if (!currentSignIn) {
			throw new Error('[Socket IO]: Stop establishing connection. Current sign in does not exist.');
		}
		if (!currentSignIn.companyId) {
			throw new Error('[Socket IO]: Stop establishing connection. User needs to create company first.');
		}

		const handshakeParams: HandshakeRM = {
			token: currentSignIn.token,
			companyId: currentSignIn.companyId,
		};

		const options = {
			transports: ['websocket'],
			auth: {
				query: handshakeParams,
			},
		};

		console.info('[Socket IO]: Establishing connection.');

		let socketUrl = process.env.SOCKET_URL;
		if (process.env.REVIEW_APP === 'true') {
			socketUrl = `https://${process.env.HEROKU_APP_NAME}.herokuapp.com`;
		}

		if (!socketUrl) {
			console.info('Socket URL not configured, check SOCKET_URL');
			return null;
		}

		// Initialize the connection
		this._socket = io.io(socketUrl, options);
		this._data = { token: currentSignIn.token, companyId: currentSignIn.companyId, orgAlias, userId: currentSignIn.userId };

		this._socket.on(SocketEvent.PREDEFINED.CONNECT, () => {
			this.handleConnect();
			if (onConnect) {
				onConnect();
			}
		});
		this._socket.on(SocketEvent.PREDEFINED.DISCONNECT, (reason: string) => {
			console.info('socket disconnect:', reason);
			if (reason === 'transport close') {
				window.location.reload();
				return; // you can't be certain when reload will trigger
			}
			this.handleDisconnect();
			if (onDisconnect) {
				onDisconnect();
			}
		});

		// handler for SocketEvent.PREDEFINED.ERROR needs to be on this._socket.io.on
		// this means that this logic was never called
		// changing to that will force login on every server refresh (on production at least once a day)

		// will leave it here for the reference

		// this._socket.on(SocketEvent.PREDEFINED.ERROR, (err) => {
		// 	console.info('predefined error: disconnect and logout');
		// 	this.disconnect();
		// 	localStorage.clear();
		// 	history.push(CLIENT.AUTH.LOGIN(orgAlias));
		// });

		type CustomSocketError = Error & {
			data: {
				message: string;
				statusCode: number;
			};
		};
		this._socket.on(SocketEvent.PREDEFINED.CONNECT_ERROR, (error: CustomSocketError) => {
			if (error.data?.statusCode === 401 && onConnectError) {
				console.info('auth error: disconnect and logout');
				// clearing storage is enough to redirect user to login page
				// we need to call onConnectError which triggers socketHoc component rerender
				// otherwise, app (socketHoc) won't refresh, and user will get stuck on loading page
				localStorage.clear();
				onConnectError();
			} else {
				console.info('on socket-connect-error:', error);
			}
		});
	};

	private handleConnect = () => {
		// Once the connection is established, start the timeout
		this._timeout = new Timeout(this.markConnectionIdle);
		this._customPing = new Interval(this.pingWithData);

		// if (isDevelopment()) {
		console.info('[Socket IO]: Successfully connected.');
		// }
	};

	private handleDisconnect = () => {
		// Cancel timeout if socket disconnects
		if (this._timeout) {
			this._timeout.cancel();
		}

		if (this._customPing) {
			this._pingCount = 0;
			this._customPing.cancel();
		}

		// if (isDevelopment()) {
		console.info('[Socket IO]: Disconnected.');
		// }

		this._socket = undefined;
		this._data = undefined;
		SocketConnection.connection = undefined;
	};

	private markConnectionIdle = () => this._socket?.emit(SocketEvent.V2.FE.GENERAL.MARK_CONNECTION_IDLE);

	private pingWithData = () => this._socket?.emit(SocketEvent.V2.FE.GENERAL.PING_WITH_DATA, this._pingCount++);

	private dispatchSubmitStart = (submitting) => AppStore.getStore() && submitting && AppStore.getStore().dispatch(SUBMIT_START(submitting));

	private dispatchSubmitEnd = (submitting) => AppStore.getStore() && submitting && AppStore.getStore().dispatch(SUBMIT_END(submitting));
}
