import * as React from 'react';
import { nanoid } from 'nanoid';
import { Button } from '@acceligentllc/storybook';
import type { WrappedFieldArrayProps } from 'redux-form';

import OperationType, { MinOperandCount, CalculationsWithUnit } from '@acceligentllc/shared/enums/operation';
import OperandType from '@acceligentllc/shared/enums/operand';

import { range } from '@acceligentllc/shared/utils/array';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import CalculatedFieldOption from './CalculatedFieldOption';

import type { ReportBlockFieldFormModel, CalculatedFieldOptionFormModel, BlockDropdownSection } from '../formModel';

const CalculationsWithConstantMap: { [T in keyof typeof OperationType]: boolean } = {
	[OperationType.SUM]: true,
	[OperationType.SUBTRACT]: true,
	[OperationType.MULTIPLY]: true,
	[OperationType.DIVIDE]: true,
	[OperationType.COUNT]: false,
	[OperationType.COUNT_FILLED]: false,
	[OperationType.COUNT_EMPTY]: false,
};

/** Id of selected field -> option index */
type SelectedFieldsMap = { [key: string]: number; };

interface SharedProps {
	additionalFilter?: (option: ReportBlockFieldFormModel) => boolean;
	change: (fieldName: string, value: string | number | Metadata | null) => void;
	fieldsByIdMap: { [fieldId: string]: ReportBlockFieldFormModel; };
	formValues: ReportBlockFieldFormModel;
}

/** Props for Calculated Field Options in Report Type Builder */
interface GroupedOptionProps extends SharedProps {
	grouped: true;
	options: BlockDropdownSection<CalculatedFieldOptionFormModel>[];
}

/** Props for Calculated Field Options in Report Block Builder */
interface SingleOptionProps extends SharedProps {
	grouped: false;
	options: CalculatedFieldOptionFormModel[];
}

export type OwnProps = GroupedOptionProps | SingleOptionProps;

type Props = WrappedFieldArrayProps<CalculatedFieldOptionFormModel> & OwnProps;

const _selectedFieldMapReducer = (acc: SelectedFieldsMap, option: CalculatedFieldOptionFormModel, index: number) => {
	if (option.id) {
		acc[option.id] = index;
	}
	return acc;
};

const CalculatedFieldOptions: React.FC<Props> = (props: Props) => {
	const { fields, fieldsByIdMap, additionalFilter, change, formValues } = props;

	const getSelectedFields = React.useCallback(() => {
		return (formValues.calculatedFieldOptions ?? []).reduce(_selectedFieldMapReducer, {});
	}, [formValues]);

	const [selectedFieldsMap, setSelectedFieldsMap] = React.useState<SelectedFieldsMap>({});

	const [showConstant, setShowConstant] = React.useState<boolean>(formValues.operationType ? CalculationsWithConstantMap[formValues.operationType] : false);

	/*
	* If there are no selected fields with unit, remove unit from calculated field
	*/
	const checkUnit = React.useCallback(() => {
		let hasUnit = false;
		for (const _option of (formValues.calculatedFieldOptions ?? [])) {
			const { id, type } = _option;

			if (type === OperandType.CONSTANT) {
				// Skip unit check for constant
				continue;
			}

			if (!id) {
				throw new Error('Operand field is missing id');
			}

			const _field = fieldsByIdMap[id];

			if (_field.unit === formValues.unit) {
				hasUnit = true;
				break;
			}
		}
		if (!hasUnit) {
			change('unit', null);
		}
	}, [change, fieldsByIdMap, formValues]);

	React.useEffect(() => {
		if (formValues.unit) {
			checkUnit();
		}
		setSelectedFieldsMap(getSelectedFields());
	}, [fields, formValues.unit, checkUnit, setSelectedFieldsMap, getSelectedFields]);

	const addField = React.useCallback(() => {
		fields.push({ id: null, type: OperandType.FIELD, constant: null, index: fields.length, isOperandBlockInPrimarySegment: null });
	}, [fields]);

	const addConstant = React.useCallback(() => {
		fields.push({ id: nanoid(UNIQUE_ID_SIZE), type: OperandType.CONSTANT, constant: null, index: fields.length, isOperandBlockInPrimarySegment: null });
	}, [fields]);

	React.useEffect(() => {
		if (formValues.operationType && (!fields.length || fields.length < MinOperandCount[formValues.operationType])) {
			range(0, MinOperandCount[formValues.operationType]).forEach(addField);
		}
		setShowConstant(formValues.operationType ? CalculationsWithConstantMap[formValues.operationType] : false);
	}, [formValues.operationType, addField, fields.length]);

	const onRemove = React.useCallback((index: number) => {
		fields.remove(index);

		if (!fields.length) {
			change('unit', null);
		}
	}, [fields, change]);

	const onFieldChange = React.useCallback((index: number, value: CalculatedFieldOptionFormModel) => {
		if (!value.id) {
			throw new Error('Missing id');
		}

		change(`calculatedFieldOptions[${index}]`, value);

		if (!value.constant) {
			const field = fieldsByIdMap[value.id];

			if (formValues.operationType && !formValues.unit && field.unit && CalculationsWithUnit[formValues.operationType]) {
				change('unit', field.unit);
			}
		}
	}, [change, fieldsByIdMap, formValues]);

	const renderSectionOption = React.useCallback((
		options: BlockDropdownSection<CalculatedFieldOptionFormModel>[],
		type: CalculatedFieldOptionFormModel['type'],
		field: string,
		index: number
	) => {
		return (
			<div className="report-block-form-field-modal__option-item" key={index}>
				<CalculatedFieldOption
					additionalFilter={additionalFilter}
					fieldName={field}
					fieldsByIdMap={fieldsByIdMap}
					grouped={true}
					index={index}
					onChange={onFieldChange}
					onRemove={onRemove}
					operandCount={fields.length}
					operation={formValues.operationType}
					options={options}
					selectedFieldsMap={selectedFieldsMap}
					type={type}
					unit={formValues.unit}
				/>
			</div>
		);
	}, [fields.length, fieldsByIdMap, onFieldChange, onRemove, formValues, additionalFilter, selectedFieldsMap]);

	const renderListOption = React.useCallback((
		options: CalculatedFieldOptionFormModel[],
		type: CalculatedFieldOptionFormModel['type'],
		field: string,
		index: number
	) => {
		return (
			<div className="report-block-form-field-modal__option-item" key={index}>
				<CalculatedFieldOption
					additionalFilter={additionalFilter}
					fieldName={field}
					fieldsByIdMap={fieldsByIdMap}
					grouped={false}
					index={index}
					onChange={onFieldChange}
					onRemove={onRemove}
					operandCount={fields.length}
					operation={formValues.operationType}
					options={options}
					selectedFieldsMap={selectedFieldsMap}
					type={type}
					unit={formValues.unit}
				/>
			</div>
		);
	}, [fields.length, fieldsByIdMap, onFieldChange, onRemove, formValues, additionalFilter, selectedFieldsMap]);

	/**
	 * Renders field option dropdowns
	 * 1. First Dropdown only filters by Operation and additionalFilter cause he always shows all fields
	 * 2. Other Dropdowns filter by selected fields also
	 */
	const renderOption = React.useCallback((field: string, index: number) => {
		const option = formValues?.calculatedFieldOptions?.[index];
		if (!option) {
			throw new Error('Missing option');
		}
		if (props.grouped === true) {
			return renderSectionOption(props.options, option.type, field, index);
		}
		return renderListOption(props.options, option.type, field, index);
	}, [props, renderSectionOption, renderListOption, formValues]);

	return (
		<>
			<div className="report-block-form-field-modal__option-title">Fields Used in Calculation:*</div>
			<div className="report-block-form-field-modal__options">
				{fields.map(renderOption)}
				<div className="report-block-form-field-modal__actions">
					<Button
						icon="plus"
						label="Add Field"
						onClick={addField}
						style="link"
					/>
					{showConstant &&
						<Button
							icon="plus"
							label="Add Constant"
							onClick={addConstant}
							style="link"
						/>
					}
				</div>
			</div>
		</>
	);
};

export default React.memo(CalculatedFieldOptions);
