import type { Dispatch, AnyAction } from 'redux';

import type { TimeSheetsBulkUpdateRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetUpdateRM from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type RejectRM from 'ab-requestModels/timeSheet/reject';
import type { AssignEntriesToWorkOrderRM } from 'ab-requestModels/timeSheet/timeCard.requestModel';

import type { SignatureRequestModel } from 'ab-requestModels/signature.requestModel';
import type EndShiftRM from 'ab-requestModels/endShift.requestModel';

import type TimeSheetNoteVM from 'ab-viewModels/timeSheet/timeSheetNote.viewModel';
import type { TimeSheetAndSplitEntriesForAccountVM, TimeSheetEntriesForTimelineVM } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type TimeSheetEntryVM from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type TimeSheetEntryHistoryVM from 'ab-viewModels/timeSheet/timeSheetEntryHistory.viewModel';
import type TimeSheetCurrentTrackedEntryVM from 'ab-viewModels/timeSheet/currentTrackedEntry.viewModel';
import type TimeSheetTrackedEntriesVM from 'ab-viewModels/timeSheet/trackedEntries.viewModel';
import type TimeSheetEntryWithAccessVM from 'ab-viewModels/timeSheet/timeSheetEntryWithAccess.viewModel';
import type TimeSheetHasOverlappingEntryVM from 'ab-viewModels/timeSheet/timeSheetHasOverlappingEntry.viewModel';
import type WorkOrderHasOverlappingEntryVM from 'ab-viewModels/timeSheet/workOrderHasOverlappingEntry.viewModel';
import type OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';
import type TimeSheetsVM from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type TimeSheetEntryWithAccountVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';
import type TimeCardRejectedTimeSheetVM from 'ab-viewModels/timeSheet/timeCardRejectedTimeSheet.viewModel';
import type WorkOrderHasActiveShiftVM from 'ab-viewModels/workOrder/hasActiveShift.viewModel';

import API from 'af-constants/routes/api';

import { SIGNATURE_FIELD_REPORT } from 'af-constants/reduxForms';

import type { GetRootState } from 'af-reducers';

import { errorHandler } from 'af-utils/actions.util';
import { http } from 'af-utils/http.util';

export function findWorkOrderTimeSheets(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetsVM>(API.V1.TIME_SHEET.FIND_ALL_FOR_WORK_ORDER(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrderTimeSheetEntriesForAccount(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetEntryVM[]>(API.V1.TIME_SHEET.FIND_TIME_SHEET_ENTRIES_FOR_WORK_ORDER_AND_ACCOUNT(workOrderId, accountId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrderTimeSheetAndSplitEntriesForAccount(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetAndSplitEntriesForAccountVM>(
				API.V1.TIME_SHEET.FIND_WORK_ORDER_TIME_SHEET_AND_SPLIT_ENTRIES_FOR_ACCOUNT(workOrderId, accountId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrderTimeSheetEntriesHistoryForAccount(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetEntryHistoryVM>(
				API.V1.TIME_SHEET.FIND_TIME_SHEET_ENTRIES_HISTORY_FOR_WORK_ORDER_AND_ACCOUNT(workOrderId, accountId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findOccupiedSlots(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<OccupiedSlotsForWorkOrderVM>(API.V1.TIME_SHEET.FIND_OCCUPIED_SLOTS(accountId, workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findOccupiedSlotsWithInterval(workOrderId: number, accountId: number, startTime: string, endTime: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<OccupiedSlotsForWorkOrderVM>(API.V1.TIME_SHEET.FIND_OCCUPIED_SLOTS_WITH_INTERVAL(accountId, workOrderId, startTime, endTime));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrderTimeSheetEntries(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetEntriesForTimelineVM>(API.V1.TIME_SHEET.FIND_TIME_SHEET_ENTRIES_FOR_WORK_ORDER(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTrackedEntriesForWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetTrackedEntriesVM>(API.V1.TIME_SHEET.FIND_TRACKED_ENTRIES_FOR_WORK_ORDER(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findCurrentTrackedEntry(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetCurrentTrackedEntryVM>(API.V1.TIME_SHEET.FIND_CURRENT_TRACKED_ENTRY(accountId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllEntriesWithAccess(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetEntryWithAccessVM[]>(API.V1.TIME_SHEET.FIND_ALL_ENTRIES_WITH_ACCESS(accountId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function hasWorkOrderOverlaps(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkOrderHasOverlappingEntryVM>(API.V1.TIME_SHEET.HAS_WORK_ORDER_OVERLAPS(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function hasWorkOrderOverlapsForAccount(workOrderId: number, accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetHasOverlappingEntryVM>(API.V1.TIME_SHEET.HAS_WORK_ORDER_OVERLAPS_FOR_ACCOUNT(workOrderId, accountId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function hasTimeSheetOverlaps(timeSheetId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetHasOverlappingEntryVM>(API.V1.TIME_SHEET.HAS_TIME_SHEET_OVERLAPS(timeSheetId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkFindAllEntries(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeSheetEntryWithAccountVM[]>(API.V1.TIME_SHEET.BULK_FIND_ENTRIES(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkFindRejectedEntries(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TimeCardRejectedTimeSheetVM[]>(API.V1.TIME_SHEET.BULK_FIND_REJECTED_TS_FOR_ACCOUNTS(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkUpdateWorkOrderTimeSheetEntries(workOrderId: number, rm: TimeSheetsBulkUpdateRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.BULK_UPDATE_WORK_ORDER_TIME_SHEETS(workOrderId), rm);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateWorkOrderTimeSheetEntriesForAccount(workOrderId: number, accountId: number, rm: TimeSheetUpdateRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.UPDATE_WORK_ORDER_TIME_SHEET_FOR_ACCOUNT(workOrderId, accountId), rm);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrderTimeSheetNoteForAccount(workOrderId: number, accountId: number) {
	return async () => {
		return await http.get<TimeSheetNoteVM>(API.V1.TIME_SHEET.FIND_TIME_SHEET_NOTE_FOR_WORK_ORDER_AND_ACCOUNT(workOrderId, accountId));
	};
}

export function updateWorkOrderTimeSheetNoteForAccount(workOrderId: number, accountId: number, note: TimeSheetUpdateRM) {
	return async () => {
		return await http.post<void>(API.V1.TIME_SHEET.UPDATE_TIME_SHEET_NOTE_FOR_WORK_ORDER_AND_ACCOUNT(workOrderId, accountId), note);
	};
}

export function signTimeSheetForAccount(workOrderId: number, accountId: number, signatureForm: SignatureRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				submitting: SIGNATURE_FIELD_REPORT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'image/png',
				},
			};
			return await http.post<void>(API.V1.TIME_SHEET.SIGN(workOrderId, accountId), signatureForm, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reject(workOrderId: number, accountId: number, rm: RejectRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.REJECT(workOrderId, accountId), rm);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function endShiftsForWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.END_SHIFTS_FOR_WORK_ORDER(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function endShift(accountId: number, data: EndShiftRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.END_SHIFT(accountId), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function assignEntriesToWorkOrder(workOrderId: number, accountId: number, data: AssignEntriesToWorkOrderRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<void>(API.V1.TIME_SHEET.ASSIGN_ENTRIES_TO_WORK_ORDER(workOrderId, accountId), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function workOrderHasActiveShifts(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkOrderHasActiveShiftVM>(API.V1.TIME_SHEET.WORK_ORDER_HAS_ACTIVE_SHIFTS(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
