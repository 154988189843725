import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import { formatDate } from '@acceligentllc/shared/utils/time';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type WorkOrderEmployeeBase from 'ab-domain/models/workOrderEmployee/base';

import { getFullName } from 'ab-utils/user.util';
import { workOrderCode, workRequestCode } from 'ab-utils/codes.util';

const PER_DIEM_REPORT_CSV_KEYS = [
	'Full Name',
	'Date',
	'Week Day',
	'Job',
	'Work Order',
	'Amount $',
	'Location',
	'Is Cancelled',
];

export default class PerDiemWorkOrderEmployee {
	fullName: string;
	dueDate: string;
	weekDay: string;
	jobCode: string;
	workOrderCode: string;
	perDiemAmount: string;
	location: string;
	isWorkOrderCanceled: boolean;

	constructor(woe: WorkOrderEmployeeBase) {
		const dueDate = woe?.workOrder?.dueDate;
		const workRequest = woe?.workOrder?.workRequest;

		this.fullName = getFullName(woe?.employee?.account?.user);
		this.dueDate = formatDate(dueDate);
		this.weekDay = formatDate(dueDate, TimeFormatEnum.DAY_OF_WEEK);
		this.jobCode = workRequest?.jobCode ?? workRequestCode(workRequest?.year, workRequest?.code);
		this.workOrderCode = workOrderCode(woe?.workOrder, workRequest ?? {} as WorkRequestBase);
		this.perDiemAmount = woe.perDiemAmount ?? '0';
		this.isWorkOrderCanceled = woe.workOrder.status === WorkOrderStatus.CANCELED;

		const location = workRequest?.travelLocation;
		this.location = `${location?.locality ?? ''}, ${location?.aa1 ?? ''}`;
	}
}

export class PerDiemWorkOrderEmployeeListViewModel {
	list: PerDiemWorkOrderEmployee[];

	constructor(woes: WorkOrderEmployeeBase[]) {
		this.list = woes.map((_woe: WorkOrderEmployeeBase) => new PerDiemWorkOrderEmployee(_woe));
	}

	static toCSVData(viewModel: PerDiemWorkOrderEmployeeListViewModel): string[][] {
		const header: string[] = PER_DIEM_REPORT_CSV_KEYS;

		const rows: string[][] = viewModel.list.map((_item: PerDiemWorkOrderEmployee) => {
			const _row: string[] = [
				_item.fullName,
				_item.dueDate,
				_item.weekDay,
				_item.jobCode,
				_item.workOrderCode,
				_item.perDiemAmount,
				_item.location,
				_item.isWorkOrderCanceled.toString(),
			];
			return _row;
		});

		return [header, ...rows];
	}
}
