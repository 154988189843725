import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { FieldReportBlocksTotals } from '@acceligentllc/shared/enums/fieldReportBlockType';

import type { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';

import { bemElement } from 'ab-utils/bem.util';
import { isValidNumber } from 'ab-utils/validation.util';

import ConfirmationModal from 'af-components/ConfirmationModal';
import Tooltip from 'af-components/Tooltip';

const DELETE_SEGMENT_CONFIRMATION_BODY = (
	<>
		If deleted, all filled data will be lost.
		<br />
		This action cannot be undone.
	</>
);

const DELETE_INSTANCE_CONFIRMATION_BODY = (
	<>
		If deleted, all filled data will be lost.
		<br />
		This action will delete all Secondary Segments connected to this Primary Segment.
		<br />
		This action cannot be undone.
	</>
);

const SELF_LOCK_MESSAGE = (
	<div>
		<div>This row is now locked and only you can make the changes to it.</div>
		<br />
		<div>If your session lasts more than five minutes without an input, the row will unlock for the others to edit.</div>
	</div>
);

interface OwnProps {
	fieldReportId: number;
	fieldReportTypeId: number;
	isPreview: boolean;
	blockId: string;
	showInstanceCount: boolean;
	showSegmentCount: boolean;
	secondaryCount: number;
	hidden: boolean;
	highlighted: boolean;
	locked: boolean;
	removable: boolean;
	hide: () => void;
	unlock: () => void;
	forceUnlock: (blockId: string) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	showRemoveModal: boolean;
}

class Header extends React.PureComponent<Props, State> {
	state: State = {
		showRemoveModal: false,
	};

	openRemoveModal = () => this.setState(() => ({ showRemoveModal: true }));
	closeRemoveModal = () => this.setState(() => ({ showRemoveModal: false }));

	remove = () => {
		const {
			remove,
			fieldReportId,
			fieldReportTypeId,
			instanceIndex,
			segmentIndex,
		} = this.props;

		remove(fieldReportId, fieldReportTypeId, instanceIndex, segmentIndex);
		this.closeRemoveModal();
	};

	forceUnlock = () => {
		const { forceUnlock, blockId } = this.props;
		forceUnlock(blockId);
	};

	renderHeaderRight = () => {
		const {
			fieldReportBlock,
			secondaryCount,
			hide,
			hidden,
			secondaryName,
		} = this.props;
		const { isMain, isPrimary } = fieldReportBlock;

		if (FieldReportBlocksTotals.includes(fieldReportBlock.type)) {
			return null;
		}
		const className = bemElement('field-report-block', 'header-right', { link: isMain && !isPrimary });
		return (
			<div className={className}>
				{(isPrimary && isMain && !!secondaryCount) &&
					<span>(+{secondaryCount} {secondaryName})</span>
				}
				{(!isPrimary && isMain) &&
					<Tooltip message={`${hidden ? 'Show' : 'Hide'} Segment Data`}>
						<span className="field-report-block__header__clickable" onClick={hide}>
							<span className={`icon-${hidden ? 'down' : 'up'}`} />
							<span>{`${hidden ? 'Expand' : 'Collapse'}`} All</span>
						</span>
					</Tooltip>
				}
				{(!isMain) &&
					<Tooltip message={`${hidden ? 'Show' : 'Hide'} Row Data`}>
						<span className={`icon-${hidden ? 'down' : 'up'} field-report-block__header__clickable`} onClick={hide} />
					</Tooltip>
				}
			</div>
		);
	};

	renderHeaderLeft = () => {
		const {
			blockName,
			fieldReportBlock,
			showSegmentCount,
			showInstanceCount,
			instanceIndex,
			segmentIndex,
			highlighted,
			locked,
			removable,
			unlock,
			isPreview,
		} = this.props;
		const { isMain, isPrimary } = fieldReportBlock;

		const showCounter = (isPrimary && showInstanceCount) || (!isPrimary && showSegmentCount);

		const leftElementClassName = bemElement('field-report-block', 'header-left', { main: isMain, highlighted, locked });
		const deleteClassName = `${leftElementClassName} field-report-block__header__clickable icon-delete`;
		const lockClassName = `${leftElementClassName} field-report-block__header__clickable icon-lock`;

		const showDeleteIcon = removable && !highlighted && !locked && !isPreview;

		return (
			<div className={leftElementClassName}>
				<span>{blockName}</span>
				{(isMain && showCounter) &&
					<>
						<span>
							{`${instanceIndex + 1}${isPrimary ? '' : `.${segmentIndex}`}`}
						</span>
						{showDeleteIcon && <span className={deleteClassName} onClick={this.openRemoveModal} />}
					</>
				}
				{highlighted &&
					<Tooltip className="tooltip--center-text" message={SELF_LOCK_MESSAGE}>
						<span className={lockClassName} onClick={unlock} />
					</Tooltip>
				}
				{locked && <span className={lockClassName} onDoubleClick={this.forceUnlock} />}
			</div>
		);
	};

	render() {
		const { fieldReportBlock } = this.props;
		const { showRemoveModal } = this.state;
		const { isPrimary } = fieldReportBlock;

		return (
			<div className="field-report-block__header">
				{this.renderHeaderLeft()}
				{this.renderHeaderRight()}
				<ConfirmationModal
					body={isPrimary ? DELETE_INSTANCE_CONFIRMATION_BODY : DELETE_SEGMENT_CONFIRMATION_BODY}
					closeModal={this.closeRemoveModal}
					confirmAction={this.remove}
					confirmText="Delete"
					hideOnConfirm={false}
					modalStyle="danger"
					showModal={showRemoveModal}
					size="md"
					title={`Are you sure you want to delete ${isPrimary ? 'Primary' : 'Secondary'} segment from the Field Report?`}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport }, user: { userData } } = state;
	const { blockId } = ownProps;

	if (!userData) {
		throw new Error('User not logged in');
	}

	const fieldReportBlock = fieldReport?.fieldReportBlockMap?.[blockId];
	if (!fieldReportBlock) {
		throw new Error('fieldReportBlock not found');
	}
	const reportBlock = fieldReport?.blockMap?.[fieldReportBlock.reportBlockId];
	const fieldReportType = fieldReport?.typeMap?.[fieldReportBlock?.fieldReportTypeId];
	const fieldReportInstance = fieldReport?.instanceMap?.[fieldReportBlock?.instanceId];

	const instance = fieldReport?.instanceMap[fieldReportBlock?.instanceId];
	const secondarySegment = fieldReport?.segmentMap?.[instance?.segments?.[1]];
	const secondaryMainBlock = fieldReport?.fieldReportBlockMap?.[secondarySegment?.blocks?.[0]];
	const secondaryBlockDefinition = fieldReport?.blockMap?.[secondaryMainBlock?.reportBlockId];

	return {
		secondaryName: secondaryBlockDefinition?.name,
		userId: userData.id,
		blockName: reportBlock?.name,
		instanceIndex: fieldReportType?.instances?.indexOf(fieldReportBlock.instanceId),
		segmentIndex: fieldReportInstance?.segments?.indexOf(fieldReportBlock.segmentId),
		fieldReportBlock,
		instanceIdNumber: isValidNumber(fieldReportBlock.instanceId) ? +fieldReportBlock.instanceId : null,
	};
}

function mapDispatchToProps() {
	return {
		remove: FieldReportActions.removeSegment,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Header);
