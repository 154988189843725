import * as React from 'react';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { TimeSheetVM, EmployeeVM } from 'ab-viewModels/workOrder/workOrderForReportsTable.viewModel';

import TimeSheet from './TimeSheet';

interface OwnProps {
	assignedTo: Nullable<EmployeeVM[]>;
	lastUpdatedAt: Nullable<string>;
	reviewLevel: WorkOrderReviewLevel;
	reviewStatus: WorkOrderReviewStatus;
	timeSheets: Nullable<TimeSheetVM[]>;
}

type Props = OwnProps;

const getDescription = (
	reviewStatus: WorkOrderReviewStatus,
	reviewLevel: WorkOrderReviewLevel,
	assignedTo: Nullable<EmployeeVM[]>
) => {
	switch (reviewStatus) {
		case WorkOrderReviewStatus.DRAFT:
			return 'Last Edited';
		case WorkOrderReviewStatus.IN_REVIEW:
		case WorkOrderReviewStatus.APPROVED:
			if (reviewLevel === WorkOrderReviewLevel.LEVEL_1) {
				return 'Submitted';
			}
			return 'Approved';
		case WorkOrderReviewStatus.REJECTED:
			return `Rejected${assignedTo && ` to ${assignedTo.map((_user) => _user.fullName).join(', ') ?? ''}`}`;
		case WorkOrderReviewStatus.FINAL:
			return 'Finalized';
	}
};

const WorkOrderStatusSummaryTooltip: React.FC<Props> = (props: Props) => {
	const {
		reviewStatus,
		reviewLevel,
		timeSheets,
		assignedTo,
		lastUpdatedAt,
	} = props;

	const description = React.useMemo(
		() => getDescription(reviewStatus, reviewLevel, assignedTo),
		[reviewStatus, reviewLevel, assignedTo]
	);

	const renderTimeSheet = (timeSheet: TimeSheetVM, index: number) => {
		return (
			<TimeSheet
				approvalStatus={timeSheet.approvalStatus}
				fullName={timeSheet.fullName}
				key={index}
				signatureStatus={timeSheet.signatureStatus}
			/>
		);
	};

	return (
		<div className="work-order-status-summary">
			<div className="work-order-status-summary__section">
				<div className="work-order-status-summary__section-title">REPORT STATUS</div>
				<div className="work-order-status-summary__review-info">
					<div>{description}</div>
					{lastUpdatedAt && <div>{`Last updated At: ${TimeUtils.formatDate(lastUpdatedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}`}</div>}
				</div>
			</div>
			{timeSheets?.length &&
				<div className="work-order-status-summary__section">
					<div className="work-order-status-summary__section-title">TIME SHEETS</div>
					{timeSheets.map(renderTimeSheet)}
				</div>
			}
		</div>
	);
};

export default React.memo(WorkOrderStatusSummaryTooltip);
