import AccountPermission from '@acceligentllc/shared/enums/accountPermission';

/** If key permission is `true`, the listed permissions must be `true as well` */
export const IMPLICATIONS: Readonly<{ [permission in AccountPermission]?: ReadonlyArray<AccountPermission> }> = {
	[AccountPermission.UA_MANAGE]: [AccountPermission.UA_READ],
	[AccountPermission.JB_MANAGE]: [AccountPermission.JB_MANAGE_DELIVERABLE],
	[AccountPermission.JB_MANAGE_BILLING_CODES]: [AccountPermission.JB_MANAGE, AccountPermission.JB_MANAGE_DELIVERABLE],
	[AccountPermission.JB_MANAGE_PROJECTS]: [AccountPermission.JB_MANAGE, AccountPermission.JB_MANAGE_DELIVERABLE],
	[AccountPermission.FR_MANAGEMENT_MANAGE]: [AccountPermission.FR_FILL, AccountPermission.FR_VIEW_ALL, AccountPermission.WS_MANAGE],
	[AccountPermission.FR_ACCOUNTING_MANAGE]: [
		AccountPermission.FR_FILL,
		AccountPermission.FR_VIEW_ALL,
		AccountPermission.FR_MANAGEMENT_MANAGE,
		AccountPermission.WS_MANAGE,
		AccountPermission.AC_JOB_PAYROLL,
		AccountPermission.AC_PREVAILING,
		AccountPermission.AC_WORK_SUMMARY,
	],
	[AccountPermission.PD_MANAGE]: [AccountPermission.AC_PER_DIEM],
	[AccountPermission.AC_ALL]: [
		AccountPermission.AC_JOB_PAYROLL,
		AccountPermission.AC_PER_DIEM,
		AccountPermission.AC_PREVAILING,
		AccountPermission.AC_WORK_SUMMARY,
		AccountPermission.AC_MANAGE_INVOICES,
	],
	[AccountPermission.FR_SUPERINTENDENT_UNASSIGNED_MANAGE]: [
		AccountPermission.FR_VIEW_ALL,
	],
	[AccountPermission.PROD_DATA_VIEW]: [AccountPermission.SB_VIEW],
	[AccountPermission.CT_MANAGE]: [AccountPermission.CT_VIEW],
	[AccountPermission.WS_MANAGE]: [AccountPermission.FR_FILL],
	[AccountPermission.JB_MANAGE_WORK_SUMMARY]: [
		AccountPermission.WS_MANAGE,
		AccountPermission.FR_FILL,
		AccountPermission.JB_MANAGE_BILLING_CODES,
		AccountPermission.JB_MANAGE,
		AccountPermission.JB_MANAGE_DELIVERABLE,
	],
	[AccountPermission.JB_MANAGE_INVOICES]: [
		AccountPermission.JB_MANAGE_WORK_SUMMARY,
		AccountPermission.WS_MANAGE,
		AccountPermission.FR_FILL,
		AccountPermission.JB_MANAGE_BILLING_CODES,
		AccountPermission.JB_MANAGE,
		AccountPermission.JB_MANAGE_DELIVERABLE,
	],
	[AccountPermission.AC_WORK_SUMMARY]: [
		AccountPermission.JB_MANAGE_INVOICES,
		AccountPermission.JB_MANAGE_WORK_SUMMARY,
		AccountPermission.WS_MANAGE,
		AccountPermission.FR_FILL,
		AccountPermission.JB_MANAGE_BILLING_CODES,
		AccountPermission.JB_MANAGE,
		AccountPermission.JB_MANAGE_DELIVERABLE,
	],
	[AccountPermission.PO_MANAGE]: [AccountPermission.PO_CREATE],
	[AccountPermission.PO_VIEW_AND_MANAGE_ALL]: [AccountPermission.PO_CREATE, AccountPermission.PO_MANAGE],
	[AccountPermission.OR_MANAGE]: [AccountPermission.OR_CREATE],
	[AccountPermission.TR_MANAGE]: [AccountPermission.TR_CREATE],
	[AccountPermission.AC_MANAGE_INVOICES]: [
		AccountPermission.JB_MANAGE_INVOICES,
		AccountPermission.JB_MANAGE_WORK_SUMMARY,
		AccountPermission.WS_MANAGE,
		AccountPermission.FR_FILL,
		AccountPermission.JB_MANAGE_BILLING_CODES,
		AccountPermission.JB_MANAGE,
		AccountPermission.JB_MANAGE_DELIVERABLE,
	],
	[AccountPermission.RS_ACCESSORY_MANAGE]: [AccountPermission.RS_ACCESSORY_VIEW],
	[AccountPermission.RS_SMALL_TOOL_MANAGE]: [AccountPermission.RS_SMALL_TOOL_VIEW],
	[AccountPermission.FR_REPORT_TYPE_VIEW]: [AccountPermission.FR_VIEW_ALL],
};

export const permissionDependencies: Readonly<{ [permission in AccountPermission]?: AccountPermission[]; }> =
	Object.keys(IMPLICATIONS).reduce((_acc, _permission) => {
		for (const _implication of IMPLICATIONS[_permission]) {
			if (!_acc[_implication]) {
				_acc[_implication] = [];
			}
			_acc[_implication].push(_permission);
		}
		return _acc;
	}, {});

export const FLAG_IMPLICATIONS = {
	assignableToWorkOrder: {
		[AccountPermission.FR_FILL]: true,
	},
	assignableAsSuperintendent: {
		[AccountPermission.FR_FILL]: true,
	},
	assignableAsManagement: {
		[AccountPermission.FR_FILL]: true,
		[AccountPermission.FR_VIEW_ALL]: true,
		[AccountPermission.FR_MANAGEMENT_MANAGE]: true,
		[AccountPermission.FR_REPORT_TYPE_VIEW]: true,
	},
	assignableAsAccounting: {
		[AccountPermission.FR_FILL]: true,
		[AccountPermission.FR_VIEW_ALL]: true,
		[AccountPermission.FR_REPORT_TYPE_VIEW]: true,
		[AccountPermission.FR_MANAGEMENT_MANAGE]: true,
		[AccountPermission.FR_ACCOUNTING_MANAGE]: true,
		[AccountPermission.JB_MANAGE_INVOICES]: true,
		[AccountPermission.JB_MANAGE_WORK_SUMMARY]: true,
		[AccountPermission.WS_MANAGE]: true,
		[AccountPermission.JB_MANAGE_BILLING_CODES]: true,
		[AccountPermission.JB_MANAGE]: true,
		[AccountPermission.JB_MANAGE_DELIVERABLE]: true,
		[AccountPermission.AC_MANAGE_INVOICES]: true,
	},
	assignableAsTechnician: {
		[AccountPermission.TR_MANAGE]: true,
		[AccountPermission.TR_CREATE]: true,
		[AccountPermission.OR_MANAGE]: true,
		[AccountPermission.OR_CREATE]: true,
		[AccountPermission.PO_CREATE]: true,
	},
};

export const LOCKING_IMPLICATIONS = {
	assignableAsTechnician: {
		[AccountPermission.OR_MANAGE]: true,
		[AccountPermission.OR_CREATE]: true,
	},
};

export const LABEL: Readonly<{ [permission in AccountPermission]: string; }> = {
	[AccountPermission.UA_MANAGE]: 'Invite / Add / Edit / Delete Company User',
	[AccountPermission.UA_READ]: 'Preview Company Users List',
	[AccountPermission.UG_MANAGE]: 'Manage Company User Groups',
	[AccountPermission.LG_VIEW]: 'View Company Logs',
	[AccountPermission.NTFY_MANAGE]: 'Manage Company Notifications',
	[AccountPermission.RS_WAGERATE_MANAGE]: 'Manage Wage Rates',
	[AccountPermission.RS_FIELD_WORK_CLASSIFICATION_CODE_MANAGE]: 'Manage Classification Codes',
	[AccountPermission.RS_EMPLOYEE_MANAGE]: 'Manage Employees',
	[AccountPermission.RS_EMPLOYEE_STATUS_MANAGE]: 'Manage Employee Status',
	[AccountPermission.RS_EQUIPMENTCOST_MANAGE]: 'Manage Equipment Costs',
	[AccountPermission.RS_EQUIPMENT_MANAGE]: 'Manage Equipment',
	[AccountPermission.RS_SMALL_TOOL_MANAGE]: 'Manage Small Tools',
	[AccountPermission.RS_SMALL_TOOL_VIEW]: 'View Small Tools',
	[AccountPermission.RS_ACCESSORY_VIEW]: 'View Accessories',
	[AccountPermission.RS_ACCESSORY_MANAGE]: 'Manage Accessories',
	[AccountPermission.TR_MANAGE]: 'Manage Tool Repairs',
	[AccountPermission.TR_CREATE]: 'Create Tool Repairs',
	[AccountPermission.PO_VIEW_AND_MANAGE_ALL]: 'Manage and View All Purchase Orders',
	[AccountPermission.PO_MANAGE]: 'Manage Purchase Orders',
	[AccountPermission.PO_CREATE]: 'Create Purchase Orders',
	[AccountPermission.OR_MANAGE]: 'Manage Orders',
	[AccountPermission.OR_CREATE]: 'Create Orders',
	[AccountPermission.RS_EQUIPMENT_STATUS_MANAGE]: 'Manage Equipment Status',
	[AccountPermission.RS_SKILL_MANAGE]: 'Manage Skills',
	[AccountPermission.RS_CREWTYPE_MANAGE]: 'Manage Crew Types',
	[AccountPermission.RS_LOCATION_MANAGE]: 'Manage Locations',
	[AccountPermission.RS_DOCUMENT_MANAGE]: 'Manage Documents',
	[AccountPermission.RS_DIVISION_MANAGE]: 'Manage Divisions',
	[AccountPermission.RS_SALE_MANAGE]: 'Manage Sales',
	[AccountPermission.RS_DELIVERABLE_MANAGE]: 'Manage Deliverable',
	[AccountPermission.RS_VENDORS_MANAGE]: 'Manage Vendors',
	[AccountPermission.MV_VIEW]: 'Access Mechanic View',
	[AccountPermission.JB_MANAGE]: 'Manage Jobs',
	[AccountPermission.JB_MANAGE_DELIVERABLE]: 'Manage Deliverable Data',
	[AccountPermission.JB_MANAGE_BILLING_CODES]: 'Manage Billing Codes',
	[AccountPermission.JB_MANAGE_PROJECTS]: 'Manage Projects',
	[AccountPermission.JB_MANAGE_WORK_SUMMARY]: 'Manage Work Summary',
	[AccountPermission.JB_MANAGE_INVOICES]: 'Manage Invoices',
	[AccountPermission.WO_MANAGE]: 'Manage Work Orders',
	[AccountPermission.SB_VIEW]: 'Access and Export Schedule Board',
	[AccountPermission.PROD_DATA_VIEW]: 'View Production Data',
	[AccountPermission.PD_MANAGE]: 'View / Edit Per Diem',
	[AccountPermission.WO_SETTINGS]: 'Edit Work Order Settings',
	[AccountPermission.SB_SETTINGS]: 'Edit Schedule Board Settings',
	[AccountPermission.WR_MANAGE]: 'Manage Work Requests',
	[AccountPermission.FR_MANAGEMENT_MANAGE]: 'Manage Field Reports on Management Level',
	[AccountPermission.FR_ACCOUNTING_MANAGE]: 'Manage Field Reports on Accounting Level',
	[AccountPermission.FR_ORDER]: 'Manage Report Types ordering',
	[AccountPermission.FR_FILL]: 'Fill Field Reports',
	[AccountPermission.FR_VIEW_ALL]: 'View All Field Reports',
	[AccountPermission.FR_SUPERINTENDENT_UNASSIGNED_MANAGE]: 'Manage unassigned Field Reports on Superintendent level',
	[AccountPermission.FR_SI_SKIP_MANAGEMENT]: 'Allow user to skip Management review level',
	[AccountPermission.FR_FINALIZE]: 'Allow user to finalize Field Reports',
	[AccountPermission.FR_EDIT_IN_FINALIZING]: 'Allow User to edit Field Report and Work Summary until Final State',
	[AccountPermission.FR_REPORT_TYPE_VIEW]: 'View Report Type View',
	[AccountPermission.RB_MANAGE]: 'Manage Report Blocks',
	[AccountPermission.RT_MANAGE]: 'Manage Report Types',
	[AccountPermission.CT_MANAGE]: 'Manage Contacts',
	[AccountPermission.CT_VIEW]: 'View Contacts',
	[AccountPermission.CR_ALL]: 'View Company Dashboard',
	[AccountPermission.CR_EQUIPMENT_UTILIZATION]: 'View Equipment Utilization',
	[AccountPermission.CR_EMPLOYEE_UTILIZATION]: 'View Employee Utilization',
	[AccountPermission.COMM_MANAGE]: 'Send Notifications',
	[AccountPermission.COMM_HISTORY_VIEW]: 'View communication history',
	[AccountPermission.EQ_MANAGE]: 'Manage Unavailable Equipment return date',
	[AccountPermission.EM_MANAGE]: 'Manage Labor',
	[AccountPermission.TEM_MANAGE]: 'Manage Temporary Labor',
	[AccountPermission.WS_MANAGE]: 'Manage Work Summary',
	[AccountPermission.AC_ALL]: 'View All Accounting Reports',
	[AccountPermission.AC_JOB_PAYROLL]: 'View Job Payroll Report',
	[AccountPermission.AC_WORK_SUMMARY]: 'View Job Work Summary Report',
	[AccountPermission.AC_PER_DIEM]: 'View Per Diem Report',
	[AccountPermission.AC_PREVAILING]: 'View Prevailing Wage Reports',
	[AccountPermission.AC_MANAGE_INVOICES]: 'Manage Invoices',
	[AccountPermission.PROD_DATA_EDIT]: '',
};

export enum GROUPS {
	GLOBAL = 'GLOBAL',
	RESOURCES_1 = 'RESOURCES_1',
	RESOURCES_2 = 'RESOURCES_2',
	EQUIPMENT = 'EQUIPMENT',
	JOBS = 'JOBS',
	WO = 'WO',
	WR = 'WR',
	FR = 'FR',
	CONTACTS = 'CONTACTS',
	CR = 'CR',
	COMM = 'COMM',
	LABOR = 'LABOR',
	ACCOUNTING = 'Accounting',
}

export const GROUP_NAME = {
	[GROUPS.GLOBAL]: 'Global',
	[GROUPS.RESOURCES_1]: 'Resources #1',
	[GROUPS.RESOURCES_2]: 'Resources #2',
	[GROUPS.EQUIPMENT]: 'Equipment',
	[GROUPS.JOBS]: 'Jobs',
	[GROUPS.WO]: 'Work Orders',
	[GROUPS.WR]: 'Work Requests',
	[GROUPS.FR]: 'Field Reports',
	[GROUPS.CONTACTS]: 'Contacts',
	[GROUPS.CR]: 'Cost Reports',
	[GROUPS.COMM]: 'Communication',
	[GROUPS.LABOR]: 'Labor',
	[GROUPS.ACCOUNTING]: 'Accounting',
};

export const PERMISSIONS_PER_GROUP: Readonly<Record<GROUPS, AccountPermission[]>> = {
	[GROUPS.GLOBAL]: [
		AccountPermission.UA_MANAGE,
		AccountPermission.UA_READ,
		AccountPermission.UG_MANAGE,
		AccountPermission.LG_VIEW,
		AccountPermission.NTFY_MANAGE,
	],
	[GROUPS.RESOURCES_1]: [
		AccountPermission.RS_WAGERATE_MANAGE,
		AccountPermission.RS_FIELD_WORK_CLASSIFICATION_CODE_MANAGE,
		AccountPermission.RS_EMPLOYEE_MANAGE,
		AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
		AccountPermission.RS_EQUIPMENTCOST_MANAGE,
		AccountPermission.RS_EQUIPMENT_MANAGE,
		AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
		AccountPermission.RS_SMALL_TOOL_VIEW,
		AccountPermission.RS_SMALL_TOOL_MANAGE,
		AccountPermission.RS_ACCESSORY_VIEW,
		AccountPermission.RS_ACCESSORY_MANAGE,
		AccountPermission.RS_VENDORS_MANAGE,
	],
	[GROUPS.RESOURCES_2]: [
		AccountPermission.RS_SKILL_MANAGE,
		AccountPermission.RS_CREWTYPE_MANAGE,
		AccountPermission.RS_LOCATION_MANAGE,
		AccountPermission.RS_DOCUMENT_MANAGE,
		AccountPermission.RS_DIVISION_MANAGE,
		AccountPermission.RS_SALE_MANAGE,
		AccountPermission.RS_DELIVERABLE_MANAGE,
	],
	[GROUPS.EQUIPMENT]: [
		AccountPermission.MV_VIEW,
		AccountPermission.EQ_MANAGE,
		AccountPermission.TR_MANAGE,
		AccountPermission.TR_CREATE,
		AccountPermission.OR_MANAGE,
		AccountPermission.OR_CREATE,
		AccountPermission.PO_MANAGE,
		AccountPermission.PO_CREATE,
		AccountPermission.PO_VIEW_AND_MANAGE_ALL,
	],
	[GROUPS.JOBS]: [
		AccountPermission.JB_MANAGE,
		AccountPermission.JB_MANAGE_DELIVERABLE,
		AccountPermission.JB_MANAGE_BILLING_CODES,
		AccountPermission.JB_MANAGE_PROJECTS,
		AccountPermission.JB_MANAGE_WORK_SUMMARY,
		AccountPermission.JB_MANAGE_INVOICES,
	],
	[GROUPS.WO]: [
		AccountPermission.WO_MANAGE,
		AccountPermission.SB_VIEW,
		AccountPermission.PROD_DATA_VIEW,
		AccountPermission.PD_MANAGE,
		AccountPermission.WO_SETTINGS,
		AccountPermission.SB_SETTINGS,
		AccountPermission.WS_MANAGE,
	],
	[GROUPS.WR]: [
		AccountPermission.WR_MANAGE,
	],
	[GROUPS.FR]: [
		AccountPermission.FR_ACCOUNTING_MANAGE,
		AccountPermission.FR_MANAGEMENT_MANAGE,
		AccountPermission.FR_SUPERINTENDENT_UNASSIGNED_MANAGE,
		AccountPermission.FR_FILL,
		AccountPermission.FR_VIEW_ALL,
		AccountPermission.FR_ORDER,
		AccountPermission.FR_SI_SKIP_MANAGEMENT,
		AccountPermission.FR_EDIT_IN_FINALIZING,
		AccountPermission.FR_FINALIZE,
		AccountPermission.RB_MANAGE,
		AccountPermission.RT_MANAGE,
		AccountPermission.FR_REPORT_TYPE_VIEW,
	],
	[GROUPS.CONTACTS]: [
		AccountPermission.CT_MANAGE,
		AccountPermission.CT_VIEW,
	],
	[GROUPS.CR]: [
		AccountPermission.CR_ALL,
		AccountPermission.CR_EQUIPMENT_UTILIZATION,
		AccountPermission.CR_EMPLOYEE_UTILIZATION,
	],
	[GROUPS.COMM]: [
		AccountPermission.COMM_MANAGE,
		AccountPermission.COMM_HISTORY_VIEW,
	],
	[GROUPS.LABOR]: [
		AccountPermission.EM_MANAGE,
		AccountPermission.TEM_MANAGE,
	],
	[GROUPS.ACCOUNTING]: [
		AccountPermission.AC_ALL,
		AccountPermission.AC_JOB_PAYROLL,
		AccountPermission.AC_PER_DIEM,
		AccountPermission.AC_PREVAILING,
		AccountPermission.AC_WORK_SUMMARY,
		AccountPermission.AC_MANAGE_INVOICES,
	],
};
