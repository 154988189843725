import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import CDLStatus from '@acceligentllc/shared/enums/cdlStatus';
import WorkOrderStatusEnum from '@acceligentllc/shared/enums/workOrderStatus';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import AssignmentsModalSection from 'af-root/scenes/Company/ScheduleBoard/Shared/AssignmentsModalSection';
import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';
import ResourceSkillList from 'af-root/scenes/Company/ScheduleBoard/Shared/ResourceModals/SkillsList';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import ImageTag from 'af-components/Image';
import CustomModal from 'af-components/CustomModal';
import SegmentLabel from 'af-components/SegmentLabel';
import LockedValue from 'af-components/LockedValue';
import LabelWithColor from 'af-components/LabelWithColor';

import AssignableResourceType from '@acceligentllc/shared/enums/assignableResourceType';
import type ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardEmployeePerDiemAssignmentTypeVM } from 'ab-viewModels/scheduleBoardEmployeePerDiemAssignment.viewModel';

import type ScheduleBoardEmployeePerDiemAssignmentRM from 'ab-requestModels/scheduleBoardEmployeePerDiemAssignment.requestModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';
import { MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY } from 'ab-common/constants/scheduleBoard';

import type { RootState } from 'af-reducers';

import { isAllowed } from 'ab-utils/auth.util';
import { formatPhoneNumber } from 'ab-utils/phone.util';

import socket from 'af-utils/socket.util';
import type { ScheduleBoardOnDateViewModel } from 'ab-viewModels/scheduleBoardWorkOrdersOnDateView.viewModel';
import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';

import styles from './styles.module.scss';
import SettingsButton from './SettingsButton';

interface OwnProps {
	createEmployeeAssignment: (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEmployeeModalVM
	) => boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	removeEmployeeAssignment: (dueDate: string, workOrderId: number, workOrderCode: string, employeeId: number) => void;
	updateWorkOrderEmployeePerDiem: (workOrderId: number, employeeId: number, perDiem: boolean, dueDate: string) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const _getStateToProps = (
	id: Nullable<number>,
	date: Nullable<string>,
	workOrdersByDateDictionary: ScheduleBoardViewModel['workOrdersByDateDictionary']
) => {
	if (!id || !date || !workOrdersByDateDictionary) {
		return {
			assignedWorkOrders: [],
			assignableWorkOrders: [],
		};
	}

	const workOrdersDictionary = workOrdersByDateDictionary[date];
	const assignedWorkOrderCodes = workOrdersDictionary.employeeAssignments?.[id] ?? [];
	const cancelledAssignedWorkOrderCodes = workOrdersDictionary.canceledEmployeeAssignments?.[id] ?? [];
	const assignedWorkOrders = [...assignedWorkOrderCodes, ...cancelledAssignedWorkOrderCodes]
		.map((workOrderCode) => workOrdersDictionary?.workOrders?.[workOrderCode])
		.filter((_wo): _wo is ScheduleBoardOnDateViewModel['workOrders'][string] => (!!_wo));

	return {
		assignedWorkOrders,
		assignableWorkOrders: Object.keys(workOrdersDictionary?.workOrders ?? {})
			.reduce((_acc, _woCode) => {
				if (
					!assignedWorkOrderCodes.includes(_woCode)
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.CANCELED
					&& workOrdersDictionary?.workOrders?.[_woCode]?.status !== WorkOrderStatusEnum.LOCKED
					&& !workOrdersDictionary?.workOrders?.[_woCode]?.isPaused
				) {
					_acc.push(workOrdersDictionary!.workOrders[_woCode]);
				}
				return _acc;
			}, [] as ScheduleBoardWorkOrderViewModel[]),
	};
};

const EmployeeModal: React.FC<Props> = (props: Props) => {
	const {
		updateWorkOrderEmployeePerDiem,
		removeEmployeeAssignment,
		createEmployeeAssignment,
		hasPermissionsToEditScheduleBoard,
		workOrdersByDateDictionary,
		isAllowedToManagePerDiems,
	} = props;

	const {
		employee,
		employeeModalDate,
		showEmployeeModal,
		setEmployeeModalData,
		setEmployeeModalVisibility,
		setEmployeeWOHistoryModalVisibility,
	} = useScheduleBoardModals();

	const [perDiemAssignments, setPerDiemAssignments] = React.useState(employee?.perDiemAssignments ?? {});

	const {
		assignedWorkOrders,
		assignableWorkOrders,
	} = React.useMemo(() => {
		return _getStateToProps(employee?.id ?? null, employeeModalDate, workOrdersByDateDictionary);
	}, [employee, employeeModalDate, workOrdersByDateDictionary]);

	const updatePerDiem = React.useCallback((data: ScheduleBoardEmployeePerDiemAssignmentRM) => {
		if (data.employeeId === employee?.id) {
			const { workOrderId, perDiem, workOrderEmployeeId, employeeId } = data;

			let newPerDiemAssignment: ScheduleBoardEmployeePerDiemAssignmentTypeVM = {};
			if (perDiem) {
				newPerDiemAssignment[workOrderId] = { workOrderEmployeeId, employeeId, workOrderId };
			} else {
				newPerDiemAssignment = Object.fromEntries(
					Object.entries(perDiemAssignments).filter(([key]) => +key !== workOrderId)
				);
			}
			setPerDiemAssignments(newPerDiemAssignment);
		}
	}, [employee?.id, perDiemAssignments]);

	React.useEffect(() => {
		if (!employee) {
			return;
		}

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, updatePerDiem, true);

		return () => {
			socket.connection?.removeHandler(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, updatePerDiem);
		};
	}, [employee, updatePerDiem]);

	React.useEffect(() => {
		setPerDiemAssignments(employee?.perDiemAssignments ?? {});
	}, [employee]);

	const closeModal = React.useCallback(async () => {
		setEmployeeModalData(null, null);
		setEmployeeModalVisibility(false);
	}, [setEmployeeModalData, setEmployeeModalVisibility]);

	const createAssignment = React.useCallback(async (workOrderId: number) => {
		if (!hasPermissionsToEditScheduleBoard) {
			return false;
		}

		if (!employee || !employeeModalDate) {
			throw new Error('Employee not defined');
		}
		return await createEmployeeAssignment(
			AssignableResourceType.EMPLOYEE,
			employeeModalDate,
			workOrderId,
			employee.employeeStatus?.available ?? true,
			employee
		);
	}, [createEmployeeAssignment, employeeModalDate, employee, hasPermissionsToEditScheduleBoard]);

	const removeAssignment = React.useCallback((workOrderId: number, workOrderCode: string, itemId: number) => {
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!employeeModalDate) {
			throw new Error('Assignment not defined');
		}
		removeEmployeeAssignment(employeeModalDate, workOrderId, workOrderCode, itemId);
	}, [employeeModalDate, hasPermissionsToEditScheduleBoard, removeEmployeeAssignment]);

	const updateEmployeePerDiem = React.useCallback((workOrderId: number, employeeId: number, perDiem: boolean) => {
		if (!hasPermissionsToEditScheduleBoard) {
			return;
		}

		if (!employeeModalDate) {
			throw new Error('Assignment not defined');
		}
		updateWorkOrderEmployeePerDiem(workOrderId, employeeId, perDiem, employeeModalDate);
	}, [employeeModalDate, hasPermissionsToEditScheduleBoard, updateWorkOrderEmployeePerDiem]);

	const renderLoadingModalContent = React.useCallback(() => {
		return (<Loading onHide={closeModal} />);
	}, [closeModal]);

	const openWorkOrderHistoryModal = React.useCallback(() => {
		setEmployeeModalVisibility(false);
		setEmployeeWOHistoryModalVisibility(true);
	}, [setEmployeeModalVisibility, setEmployeeWOHistoryModalVisibility]);

	const renderDataModalContent = React.useCallback(() => {
		if (!employee) {
			throw new Error('Employee not defined');
		}

		const {
			cdlStatus,
			countryCode,
			email,
			employeeStatus,
			firstName,
			id,
			imageUrl,
			jobCode,
			laborType,
			lastName,
			office,
			phoneNumber,
			skills,
			wageClassification,
			assignableToWorkOrder,
		} = employee;
		const employeeStatusName = employeeStatus?.name ?? '-';

		const assignedInOffices = assignedWorkOrders.filter((wo) => !!wo.officeNickname);
		const supervisorOnWorkOrders = assignedWorkOrders.filter((wo) => wo.supervisorId === id);

		const scheduleBoardBaseClassName = 'schedule-board-modal';
		const headerClassName = `${scheduleBoardBaseClassName}__big-header`;
		const headerLeft = `${scheduleBoardBaseClassName}__big-header-left`;
		const headerRight = `${scheduleBoardBaseClassName}__big-header-right`;

		return (
			<>
				<CustomModal.Header className={`${styles[headerClassName]} `}>
					<div className={`${styles[headerLeft]} `}>
						<span>{firstName} {lastName}</span>
					</div>
					<div className={`${styles[headerRight]} `}>
						<LabelWithColor color={office?.color ?? null} text={office?.nickname ?? '-'} />
						<SettingsButton
							openWorkOrderHistoryModal={openWorkOrderHistoryModal}
						/>
					</div>
				</CustomModal.Header>
				<CustomModal.Body>
					<div className="schedule-board-modal__resource__row schedule-board-modal__resource__row--flex">
						<div className="schedule-board-modal__resource__avatar-wrapper">
							<ImageTag
								className="schedule-board-modal__resource__avatar"
								fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
								minSize={BlobStorageImageSizeContainer.SIZE_200X200}
								src={imageUrl}
								tryOriginal={true}
								tryRoot={true}
							/>
							{cdlStatus === CDLStatus.NO_CDL &&
								<div className="schedule-board-modal__resource__avatar-wrapper__no-cdl">
									<span>No CDL</span>
								</div>
							}
						</div>
						<div className="schedule-board-modal__resource__main-details">
							<div className="schedule-board-modal__resource__main-details__item">
								<LockedValue
									label="Labor Type"
									value={laborType}
								/>
							</div>
							<div className="schedule-board-modal__resource__main-details__item">
								<LockedValue
									label="Wage Classification"
									value={wageClassification}
								/>
							</div>
							<div className="schedule-board-modal__resource__main-details__item">
								<ResourceSkillList skills={skills} title="Skills" />
							</div>
						</div>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Contact Information" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Mobile Phone"
									value={formatPhoneNumber(phoneNumber, countryCode)}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Email"
									value={email}
								/>
							</Col>
							<Col md={12} />
						</Row>
					</div>
					<hr />
					<div className="schedule-board-modal__resource__row">
						<SegmentLabel label="Current Status" />
						<Row className="row--non-padded">
							<Col md={6}>
								<LockedValue
									label="Status"
									value={employeeStatusName}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job"
									value={jobCode}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Job Locations"
									value={assignedInOffices.length > 0
										? assignedInOffices.map((wo) => wo.officeNickname).join(', ')
										: '-'
									}
								/>
							</Col>
							<Col md={6}>
								<LockedValue
									label="Supervisor on Work Orders"
									value={
										supervisorOnWorkOrders.length > 0
											? supervisorOnWorkOrders.map((wo) => wo.code).join(', ')
											: '-'
									}
								/>
							</Col>
						</Row>
					</div>
					<hr />
					<AssignmentsModalSection
						assignableWorkOrders={assignableWorkOrders}
						assignedWorkOrders={assignedWorkOrders}
						createAssignment={createAssignment}
						isAllowedToManagePerDiems={isAllowedToManagePerDiems}
						isEditable={assignableToWorkOrder && hasPermissionsToEditScheduleBoard}
						itemId={id}
						maxItems={MAX_EMPLOYEE_ASSIGNMENTS_PER_DAY}
						perDiemAssignments={perDiemAssignments}
						removeAssignment={removeAssignment}
						showPerDiem={isAllowedToManagePerDiems}
						updateWorkOrderEmployeePerDiem={updateEmployeePerDiem}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Close"
						onClick={closeModal}
						style="secondary"
					/>
				</CustomModal.Footer>
			</>
		);
	}, [
		assignableWorkOrders,
		assignedWorkOrders,
		closeModal,
		createAssignment,
		employee,
		hasPermissionsToEditScheduleBoard,
		isAllowedToManagePerDiems,
		openWorkOrderHistoryModal,
		perDiemAssignments,
		removeAssignment,
		updateEmployeePerDiem]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showEmployeeModal}
			size="xl"
		>
			{employee ? renderDataModalContent() : renderLoadingModalContent()}
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToManagePerDiems: boolean = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		isAllowedToManagePerDiems,
		workOrdersByDateDictionary: state.scheduleBoard.workOrdersByDateDictionary,
	};
}

const connector = connect(mapStateToProps);

export default connector(EmployeeModal);
