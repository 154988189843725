import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type { RootState } from 'af-reducers';

import * as ContactActions from 'af-actions/contacts';

import CLIENT from 'af-constants/routes/client';

import type { TabProps, Column, ButtonData, TableRef, LegacyTableRef, TypedTable } from 'af-components/Table6';
import Table from 'af-components/Table6';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import EmailCell from 'af-components/Table6/Cells/EmailCell';
import PhoneCell from 'af-components/Table6/Cells/PhoneCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import AddToExistingModal from 'af-components/SharedForms/Contacts/AddToExistingModal';
import DateCell from 'af-components/Table6/Cells/DateCell';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { isAllowed } from 'ab-utils/auth.util';

import type { CompanyContactTableVM, CompanyUnsavedContactTableVM } from 'ab-viewModels/contact/table.viewModel';

const ContactTable = Table as TypedTable<CompanyContactTableVM>;
type ContactTableRef = LegacyTableRef<CompanyContactTableVM>;

const MAIL_CELL = {
	Header: 'Email Address',
	accessor: 'emailAddress',
	sortable: false,
	Cell: ({ original }) => <EmailCell emails={original.contactMethodEmails} />,
};

const PHONE_CELL = {
	Header: 'Mobile Phone',
	accessor: 'mobilePhone',
	sortable: false,
	Cell: ({ original }) => <PhoneCell phones={original.contactMethodPhones} />,
};

const UPDATED_BY_CELL_WIDTH = 190;

type OwnProps = CustomRouteComponentProps<void>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	showModal: boolean;
	unsavedContact: Nullable<CompanyContactTableVM>;
}

const DELETE_CONFIRMATION_BODY = 'This action cannot be undone.';

class ContactsTable extends React.PureComponent<Props, State> {

	static savedColumns: Column<CompanyContactTableVM>[] = [
		{
			Header: 'Full Name',
			accessor: 'fullName',
			Cell: ({ original }) => <strong>{original.fullName}</strong>,
		},
		{
			Header: 'Title',
			accessor: 'title',
			Cell: ({ original }) => original.title ?? <EmptyCell />,
		},
		{
			Header: 'Company',
			accessor: 'companyName', // accessor is used for table order, and search result gives us contactCompanyName
			Cell: ({ original }) => original.contactCompanyName ?? <EmptyCell />,
		},
		MAIL_CELL,
		PHONE_CELL,
		{
			Header: 'Updated',
			headerClassName: 'align-right',
			accessor: 'updatedAt',
			sortable: false,
			width: UPDATED_BY_CELL_WIDTH,
			Cell: ({ original }) => <LastUpdatedByCell updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
		},
	];

	static unsavedColumns: Column<CompanyUnsavedContactTableVM>[] = [
		{
			Header: 'Full Name',
			accessor: 'fullName',
			Cell: ({ original }) => <strong>{original.fullName}</strong>,
		},
		MAIL_CELL,
		PHONE_CELL,
		{
			Header: 'Created on',
			headerClassName: 'align-right',
			accessor: 'updatedAt',
			sortable: false,
			width: UPDATED_BY_CELL_WIDTH,
			Cell: ({ original }) => (
				<DateCell
					date={original.createdAt}
					details={original.createdOn}
					format={TimeFormat.DATE_ONLY}
					showPreposition={false}
				/>
			),
		},
	];

	state: State = {
		showModal: false,
		unsavedContact: null,
	};

	private _table: Nullable<TableRef> = null;

	constructor(props: Props) {
		super(props);
		this._table = React.createRef();
	}

	static deleteContactModalTitle = (original: CompanyContactTableVM) => `Are you sure you want to delete this Contact (${original.fullName})?`;
	static deleteContactModalBody = () => DELETE_CONFIRMATION_BODY;
	static deleteContactModalText = () => 'Delete Contact';

	deleteContact = async (original: CompanyContactTableVM) => {
		const { softDeleteContact } = this.props;
		await softDeleteContact(original.id);
	};

	editContact = async (original: CompanyContactTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.CONTACTS.EDIT(orgAlias, companyName, original.id.toString()));
	};

	previewContact = async (original: CompanyContactTableVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.CONTACTS.PREVIEW(orgAlias, companyName, original.id.toString()));
	};

	onRowClick = ({ original }: { original: CompanyContactTableVM; }) => {
		if (original?.id) {
			this.previewContact(original);
		}
	};

	openModal = (original: CompanyContactTableVM) => {
		this.setState(() => ({ showModal: true, unsavedContact: original }));
	};

	closeModal = () => {
		this.setState(() => ({ showModal: false, unsavedContact: null }));
	};

	reloadTable = () => {
		this._table?.current?.refreshTable();
		this.closeModal();
	};

	onCSVImportClick = async () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.CONTACTS.BULK_UPLOAD(orgAlias, companyName));
	};

	onCreateClick = async () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.CONTACTS.CREATE(orgAlias, companyName));
	};

	tabs = (): TabProps<CompanyContactTableVM>[] => {
		const {
			bulkSoftDeleteContact,
			findAllSavedForCompany,
			findAllUnsavedForCompany,
			hasEditPermission,
		} = this.props;

		const buttons: ButtonData[] = hasEditPermission
			? [
				{
					type: TableButtonType.LINK,
					hasPermission: true,
					label: 'CSV Import',
					icon: 'upload',
					onClick: this.onCSVImportClick,
				},
				{
					type: TableButtonType.PRIMARY,
					hasPermission: true,
					label: 'New Contact',
					onClick: this.onCreateClick,
				},
			]
			: [];

		const deleteRowAction = {
			label: 'Delete',
			action: this.deleteContact,
			hasModal: true,
			modalTitle: ContactsTable.deleteContactModalTitle,
			modalBody: ContactsTable.deleteContactModalBody,
			modalText: ContactsTable.deleteContactModalText,
			shouldRefresh: true,
		};

		const savedContactsRowActions = hasEditPermission
			? [
				{
					label: 'Edit',
					action: this.editContact,
					shouldRefresh: false,
				},
				deleteRowAction,
			]
			: [];

		const unSavedContactsRowActions = hasEditPermission
			? [
				{
					label: 'Add as New Contact',
					action: this.editContact,
					shouldRefresh: false,
				},
				{
					label: 'Add to Existing Contact',
					action: this.openModal,
					shouldRefresh: false,
				},
				deleteRowAction,
			]
			: [];

		return [
			{
				label: 'Contacts',
				columns: ContactsTable.savedColumns,
				selectable: hasEditPermission,
				hasSearchInput: true,
				searchLabel: 'Contacts',
				buttons,
				bulkDelete: bulkSoftDeleteContact,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				fetch: findAllSavedForCompany,
				onRowClick: this.onRowClick,
				rowActions: savedContactsRowActions,
			},
			{
				label: 'Unsaved Contacts',
				columns: ContactsTable.unsavedColumns,
				selectable: hasEditPermission,
				hasSearchInput: true,
				searchLabel: 'Contacts',
				bulkDelete: bulkSoftDeleteContact,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				fetch: findAllUnsavedForCompany,
				rowActions: unSavedContactsRowActions,
			},
		];
	};

	breadcrumbs = () => [{ label: 'Contacts' }];

	render() {
		const { showModal, unsavedContact } = this.state;

		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<ContactTable
					ref={this._table as unknown as ContactTableRef}
					tableName={TableNameEnum.CONTACT}
					tabs={this.tabs()}
				/>
				<AddToExistingModal
					closeModal={this.closeModal}
					onSubmit={this.reloadTable}
					showModal={showModal}
					unsavedContact={unsavedContact}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		hasEditPermission: isAllowed(PagePermissions.COMPANY.CONTACTS.MANAGE, companyData.permissions, companyData.isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		softDeleteContact: ContactActions.softDeleteContact,
		bulkSoftDeleteContact: ContactActions.bulkSoftDeleteContact,
		findAllSavedForCompany: ContactActions.findAllSavedForCompanyTable,
		findAllUnsavedForCompany: ContactActions.findAllUnsavedForCompanyTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(ContactsTable);
