import * as React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import { useLocation, useParams } from 'react-router-dom-v5-compat';

import type ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import type { RootState } from 'af-reducers';

import { useToggle } from 'af-utils/react.util';

import CLIENT from 'af-constants/routes/client';

import * as ToolRepairActions from 'af-actions/toolRepair';

import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';

import DetailsSection from './DetailsSection';
import InternalRepairSection from './InternalRepairSection';
import ExternalRepairSection from './ExternalRepairSection';
import TrackingHistorySection from './TrackingHistorySection';
import styles from './styles.module.scss';

type Props = ConnectedProps<typeof connector>;

const ToolRepairPreview = (props: Props) => {
	const { findById, companyName } = props;
	const [toolRepair, setToolRepair] = React.useState<Nullable<ToolRepairVM>>(null);
	const location = useLocation();
	const { id } = useParams();
	const history = useHistory();

	const loadStuff = React.useCallback(async () => {
		const _toolRepair = await findById(parseInt(id!, 10));
		setToolRepair(_toolRepair);
	}, [findById, id]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	const {
		value: toolDeletedModalOpen,
		setToTrue: openToolDeletedModal,
		setToFalse: closeToolDeletedModal,
	} = useToggle(false);

	const goToEditPage = React.useCallback(() => {
		if (!toolRepair?.tool.deletedAt) {
			history.push(CLIENT.COMPANY.TOOL_REPAIR.EDIT(`${toolRepair!.id}`, location.state.orgAlias, companyName));
		} else {
			openToolDeletedModal();
		}
	}, [companyName, history, location.state.orgAlias, openToolDeletedModal, toolRepair]);

	const goToToolRepairList = React.useCallback(() => {
		history.push(CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias]);

	return (
		<div>
			<Breadcrumbs
				items={[
					{ label: 'Tool Repairs', url: CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName) },
					{ label: 'Preview' },
				]}
			/>
			{
				toolRepair &&
				<div className={styles.container}>
					<Row className={styles.section}>
						<Col md={18}>
							<div className={styles.title}>{toolRepair.tool.serialNumber} <div className={styles['tool-type']}>{toolRepair.tool.toolType}</div></div>
						</Col>
						<Col md={6}>
							<div className={styles.buttons}>
								<Button
									label="Back to Tool Repair List"
									onClick={goToToolRepairList}
									style="secondary"
								/>
								<Button
									label="Edit"
									onClick={goToEditPage}
									style="secondary"
								/>
							</div>

						</Col>
					</Row>
					<DetailsSection
						toolRepair={toolRepair}
					/>
					<InternalRepairSection
						toolRepair={toolRepair}
					/>
					<ExternalRepairSection
						toolRepair={toolRepair}
					/>
					<TrackingHistorySection
						toolRepair={toolRepair}
					/>
				</div>
			}
			<ConfirmationModal
				body="The tool has been deleted. No further action on this Tool Repair can be taken."
				closeModal={closeToolDeletedModal}
				closeText="Close"
				hideConfirmButton={true}
				showModal={toolDeletedModalOpen}
				title="Tool Deleted"
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: ToolRepairActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	connector
);

export default enhance(ToolRepairPreview);
