import * as React from 'react';
import { compose } from 'redux';
import type { ColumnDef, AccessorFn, SortingState, Header, Row, HeaderGroup, Cell, PaginationState, TableState, RowSelectionState } from '@tanstack/react-table';
import { useReactTable, createColumnHelper, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';
import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import LoadingIndicator from 'af-components/LoadingIndicator';

import { RESIZE_DELAY, SEARCH_DELAY_LONG } from 'ab-common/constants/value';

import * as TableSettingsActions from 'af-actions/tableSettings';

import type { RootState } from 'af-reducers';

import type { ColumnSettingsViewModel } from 'ab-requestModels/tableSettings.requestModel';
import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import { debounce } from 'af-utils/actions.util';
import type { CellWidthsMap } from 'af-utils/react.util';
import { useDraggableColumns } from 'af-utils/react.util';
import { areColumnNamesEqual, updateTableSettingsColumns, getDefaultTableSettings } from 'af-utils/table.utils';
import * as SettingsUtils from 'af-utils/settings.util';

import SidePanel from './SidePanel';
import PaginationFooter from './PaginationFooter';
import ActionHeader from './ActionHeader';
import TableHeader from './TableHeader';
import TableSettingsModal from './Settings/TableSettingsModal';
import BaseCell from './BaseCell';
import BaseHeaderCell from './BaseHeaderCell';
import styles from './styles.module.scss';
import type { TableProps } from './types';

const CELL_MIN_WIDTH = 100;
const DISPLAY_CELL_WIDTH = 50;
const ACCESSOR_CELL_WIDTH = '1fr';
const TABLE_SETTINGS_WIDTH = '16px';

type Props<T> = TableProps<T> & ConnectedProps<typeof connector>;

const _findFixedCellIndex = <T,>(_c: Cell<T, unknown>) => (_fh: string) => _fh === _c.column.id;

const _findFixedHeaderIndex = <T,>(_c: Header<T, unknown>) => (_fh: string) => _fh === _c.column.id;

const _findCellForColumnId = <T,>(_columnId: string) =>
	(_row: Row<T>) => !!_row.getValue(_columnId) || !!_row.subRows.find((_subRow) => !!_subRow.getValue(_columnId));

export type TableRef<T> = {
	refreshTable: () => void;
	refreshTableHeader: () => void;
	getState: () => TableState;
	getTableData: () => Nullable<T[]>;
	resetPagination: () => void;
	getTableQuery: () => TableQuery;
};

const Table = React.forwardRef(function Table<T>(props: React.PropsWithChildren<Props<T>>, ref: React.RefObject<TableRef<T>>) {
	const {
		columns,
		fetch,
		additionalFilter,
		buttons,
		exportAsZip,
		hasSearchInput,
		hideActionHeader,
		renderTableHeader,
		searchLabel,
		selectable,
		onRowClick,
		hasSubRows,
		enableSubRowSelection,
		fetchTableHeaderData,
		accountId,
		tableName,
		findTableSettings,
		updateTableSettings,
		tableBodyClassName,
		defaultPageSize,
		searchTextKey,
		pageNumberKey,
		fitContentHeight,
		sidePanelVisible = false,
		renderSidePanelContext,
		renderSidePanelHeader,
		offsetHeight,
		emptyTableMessage,
		filtersClassName,
	} = props;

	const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
	const [columnVisibility, setColumnVisibility] = React.useState<Record<string, boolean>>({});
	const [columnIndicesRightAligned, setColumnIndicesRightAligned] = React.useState<number[]>([]);
	const [tableColumns, setTableColumns] = React.useState<ColumnDef<T>[]>([]);
	const [tableGridLayout, setTableGridLayout] = React.useState<Record<string, string>>({});
	const [tableData, setTableData] = React.useState<Nullable<TableContent<T>> | undefined>(null);
	const [tableHeaderData, setTableHeaderData] = React.useState<Nullable<Record<string, unknown>>>(null);
	const [pageCount, setPageCount] = React.useState(-1);

	const savePageNumber = React.useCallback((_pageNumber: number) => {
		if (accountId && pageNumberKey && _pageNumber >= 0) {
			SettingsUtils.setItem(pageNumberKey, `${_pageNumber}`);
		}
	}, [accountId, pageNumberKey]);

	const { defaultPageNumber, defaultSearchText } = React.useMemo(() => {
		const newSearchText = searchTextKey ? SettingsUtils.getItem(searchTextKey) : null;
		const newPageNumber = pageNumberKey ? SettingsUtils.getItem(pageNumberKey) : null;

		let adjustedPageNumber;
		const isPageCountSet = pageCount > -1;
		const isStoragedPageNumberInvalid = newPageNumber && (+newPageNumber + 1 > pageCount);
		if (isPageCountSet && isStoragedPageNumberInvalid) { // edge case when storaged page index is greater than max page count
			adjustedPageNumber = 0;
			savePageNumber(0);
		}
		return {
			defaultSearchText: newSearchText,
			defaultPageNumber: adjustedPageNumber !== undefined ? adjustedPageNumber : newPageNumber,
		};
	}, [pageCount, pageNumberKey, savePageNumber, searchTextKey]);

	const [filterText, setFilterText] = React.useState(defaultSearchText ?? '');
	const [sorting, setSorting] = React.useState<SortingState>();
	const [pagination, setPagination] = React.useState<PaginationState>();
	const [debouncedFilterText, setDebouncedFilterText] = React.useState<string>(defaultSearchText ?? '');
	const lastDebouncedFilterText = React.useRef(defaultSearchText ?? '');

	const [tableSettings, setTableSettings] = React.useState<Nullable<TableSettingsRequestModel>>(null);
	const [hasResolvedTableSettings, setHasResolvedTableSettings] = React.useState(false);
	const [showSettingsModal, setShowSettingsModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [currentlyPreviewedSidePanelRow, setCurrentlyPreviewedSidePanelRow] = React.useState<Nullable<Row<T>>>(null);

	const setNewDebouncedCellWidthsMap = React.useCallback(async (value: CellWidthsMap) => {
		if (!tableSettings || !accountId) {
			return;
		}
		const newColumnSettings: ColumnSettingsViewModel[] = tableSettings.columnSettings.map((_cs) => ({
			..._cs,
			width: value[_cs.name]?.width ?? _cs.width,
		}));
		const newTableSettings: TableSettingsRequestModel = { ...tableSettings, columnSettings: newColumnSettings };
		await updateTableSettings(newTableSettings);
		setTableSettings(newTableSettings);
	}, [tableSettings, updateTableSettings, accountId]);

	const debouncedSetColumnWidths = React.useMemo(() => {
		return debounce(setNewDebouncedCellWidthsMap, RESIZE_DELAY);
	}, [setNewDebouncedCellWidthsMap]);

	const { cellWidthsMap, columnRefs, onColumnDrag } = useDraggableColumns({
		minColumnWidth: CELL_MIN_WIDTH,
		onColumnDragSideEffect: debouncedSetColumnWidths,
	});

	const paginationUpdate = React.useCallback(async (settings: TableSettingsRequestModel) => {
		if (accountId) {
			await updateTableSettings(settings);
		}
	}, [accountId, updateTableSettings]);

	const table = useReactTable({
		state: {
			rowSelection,
			sorting,
			columnVisibility,
			pagination,
		},
		pageCount,
		columns: tableColumns,
		data: tableData?.rows ?? [],
		onRowSelectionChange: setRowSelection,
		enableRowSelection: selectable,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		getSubRows: hasSubRows ? props.getSubRows : undefined,
		manualSorting: true,
		manualPagination: true,
		enableSubRowSelection: !!enableSubRowSelection,
		enableSortingRemoval: true,
	});

	const fetchAndSetTableData = React.useCallback(async () => {
		if (!sorting || !pagination) {
			return;
		}

		// on debounced text change set pageIndex to 0, and then rerun this useEffect
		if (lastDebouncedFilterText.current !== debouncedFilterText) {
			lastDebouncedFilterText.current = debouncedFilterText;
			setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
			savePageNumber(0);
			return;
		}

		setLoading(true);
		const tableQuery = new TableQuery({
			page: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sortBy: sorting,
			filterByText: debouncedFilterText.trim(),
		});

		const newData = await fetch(tableQuery);

		setTableData(newData);
		if (newData === undefined) { // no attempt to fetch data made
			setPageCount(1);
		} else {
			setPageCount(newData ? Math.ceil(newData.totalCount / pagination.pageSize) : -1);
		}
		setLoading(false);
	}, [sorting, pagination, debouncedFilterText, fetch, savePageNumber]);

	const fetchAndSetTableHeaderData = React.useCallback(async () => {
		if (fetchTableHeaderData) {
			const newTableHeaderData = await fetchTableHeaderData?.();
			setTableHeaderData(newTableHeaderData ?? null);
		}
	}, [fetchTableHeaderData]);

	const fetchAndSetTableSettings = React.useCallback(async () => {
		const defaultTableSettings = getDefaultTableSettings(tableName, accountId, columns, defaultPageSize);

		if (accountId) {
			let newTableSettings = await findTableSettings(tableName);

			if (!newTableSettings) {
				newTableSettings = defaultTableSettings;
				await updateTableSettings(newTableSettings);
			} else if (!areColumnNamesEqual(defaultTableSettings, newTableSettings)) {
				// table component has been updated since last settings
				newTableSettings = updateTableSettingsColumns(newTableSettings, defaultTableSettings.columnSettings);
				await updateTableSettings(newTableSettings);
			}
			setTableSettings(newTableSettings ?? null);
			setHasResolvedTableSettings(true);
		} else {
			setTableSettings(defaultTableSettings);
			setHasResolvedTableSettings(true);
		}
	}, [tableName, accountId, columns, defaultPageSize, findTableSettings, updateTableSettings]);

	React.useImperativeHandle(ref, () => {
		return {
			refreshTable() {
				fetchAndSetTableData();
			},
			refreshTableHeader() {
				fetchAndSetTableHeaderData();
			},
			getTableData() {
				return tableData?.rows ?? null;
			},
			getState() {
				return table.getState();
			},
			resetPagination() {
				return table.setPageIndex(0);
			},
			getTableQuery() {
				return {
					page: pagination?.pageIndex ?? 0,
					pageSize: pagination?.pageSize ?? 10000,
					sortBy: sorting,
					filterByText: debouncedFilterText.trim(),
				};
			},
		};
	}, [debouncedFilterText, fetchAndSetTableData, fetchAndSetTableHeaderData, pagination?.pageIndex, pagination?.pageSize, sorting, table, tableData?.rows]);

	const setNewDebouncedText = React.useCallback((value: string) => {
		setDebouncedFilterText(value);
	}, []);

	const debouncedSetFilterText = React.useMemo(() => {
		return debounce(setNewDebouncedText, SEARCH_DELAY_LONG);
	}, [setNewDebouncedText]);

	// TODO: Consider using React.useDeferredValue once we migrate to React 18
	const onFilterTextChange = React.useCallback((newFilterText: string) => {
		setFilterText(newFilterText);
		debouncedSetFilterText(newFilterText);
		if (searchTextKey && accountId) {
			SettingsUtils.setItem(searchTextKey, newFilterText);
		}
	}, [accountId, debouncedSetFilterText, searchTextKey]);

	const setHeaderReference = React.useCallback((headerId: string) => (_ref: HTMLSpanElement) => columnRefs.current[headerId] = _ref, [columnRefs]);

	const handleRowClick = React.useCallback((_row: Row<T>) => () => {
		if (sidePanelVisible) {
			setCurrentlyPreviewedSidePanelRow(_row);
		} else {
			onRowClick?.(_row);
		}
	}, [onRowClick, sidePanelVisible]);

	const handleTableSettingsSave = React.useCallback(async (newTableSettings: TableSettingsRequestModel) => {
		if (accountId) {
			setTableSettings(newTableSettings);
			newTableSettings && await updateTableSettings(newTableSettings);
		}
	}, [accountId, updateTableSettings]);

	const closeTableSettingsModal = React.useCallback(() => setShowSettingsModal(false), []);

	const openTableSettingsModal = React.useCallback(() => setShowSettingsModal(true), []);

	React.useEffect(() => {
		fetchAndSetTableSettings();
	}, [fetchAndSetTableSettings]);

	React.useEffect(() => {
		const columnHelper = createColumnHelper<T>();

		if (!hasResolvedTableSettings) {
			return;
		}

		if (!tableSettings) {
			throw new Error('Table Settings have not been found.');
		}

		const displayColumnIndexes = columns.reduce<number[]>((_acc, _c, _index) => {
			if (_c.isDisplayColumn) {
				_acc.push(_index);
			}
			return _acc;
		}, []);

		const rightAlignedHeaderColumnsIndices = columns.reduce<number[]>((_acc, _c, _index) => {
			if (_c.rightAligned) {
				_acc.push(_index);
			}
			return _acc;
		}, []);
		setColumnIndicesRightAligned(rightAlignedHeaderColumnsIndices);

		// get columns in the right order
		const resolvedColumns = tableSettings.columnSettings.map((_tableSettingsColumn) => {
			const _c = columns.find((_col) => _col.id === _tableSettingsColumn.name);

			if (!_c) {
				throw new Error(`Column with name ${_tableSettingsColumn.name} not found.`);
			}

			return columnHelper.accessor(_c.accessor as unknown as AccessorFn<T>, {
				header: _c.header ?? '',
				id: _c.id,
				cell: _c.cell,
				size: _tableSettingsColumn.width ?? _c.size,
				enableSorting: !!_c.enableSorting,
				meta: {
					isFixed: _c.isFixed,
					isMultiSpanColumn: _c.isMultiSpanColumn,
					isOmmited: _c.isOmmited,
					startIndex: _c.startIndex,
					spanFor: _c.spanFor,
					subRowColumn: _c.subRowColumn,
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					isHidden: _c.isHidden || !_tableSettingsColumn.visible,
					autoHideEmptyColumn: _c.autoHideEmptyColumn,
				},
				sortingFn: _c.enableSorting ? (_c.sortingFn ?? 'auto') : undefined,
				sortUndefined: _c.enableSorting ? 1 : undefined,
				sortDescFirst: _c.enableSorting ? _c.sortDescFirst : undefined,
				invertSorting: _c.enableSorting ? _c.invertSorting : undefined,
				enableMultiSort: _c.enableSorting ? _c.enableMultiSort : undefined,
			});
		});

		// add display columns in the right order
		for (const _displayColIndex of displayColumnIndexes) {
			const _c = columns[_displayColIndex];
			const displayCol = columnHelper.display({
				cell: _c.cell,
				id: _c.id,
				header: _c.header ?? '',
				enableHiding: _c.enableHiding,
				size: _c.size,
				meta: {
					isFixed: _c.isFixed,
					isMultiSpanColumn: _c.isMultiSpanColumn,
					isOmmited: _c.isOmmited,
					startIndex: _c.isMultiSpanColumn ? _c.startIndex : undefined,
					spanFor: _c.isMultiSpanColumn ? _c.spanFor : undefined,
					subRowColumn: _c.subRowColumn,
					isHidden: _c.isHidden,
					autoHideEmptyColumn: _c.autoHideEmptyColumn,
				},
			});
			resolvedColumns.splice(_displayColIndex, 0, displayCol);
		}

		setTableColumns(resolvedColumns);
		setSorting(tableSettings.sort);
	}, [columns, hasResolvedTableSettings, tableSettings]);

	React.useEffect(() => {
		if (!tableSettings) {
			return;
		}
		if (!pagination) {
			setPagination({ pageIndex: defaultPageNumber ? +defaultPageNumber : 0, pageSize: tableSettings.pageSize });
		}
	}, [defaultPageNumber, pagination, tableSettings]);

	React.useEffect(() => {
		fetchAndSetTableData();
	}, [fetchAndSetTableData]);

	React.useEffect(() => {
		fetchAndSetTableHeaderData();
	}, [fetchAndSetTableHeaderData]);

	React.useEffect(() => {
		const newTableGridLayoutMap: Record<string, string> = {};
		const newVisibleColumns: Record<string, boolean> = {};

		for (const _column of table.getAllColumns()) {
			newVisibleColumns[_column.id] = _column.columnDef.meta?.isHidden ? false : true;
			if (!_column.columnDef.meta?.isHidden && _column.columnDef.meta?.autoHideEmptyColumn) {
				newVisibleColumns[_column.id] = !!table.getRowModel().rows.find(_findCellForColumnId(_column.id));
			}

			const isDisplayColumn = !_column.accessorFn;

			if (isDisplayColumn) {
				let cellWidth = '';
				if (!!_column.columnDef.size) {
					cellWidth = (typeof _column.columnDef.size === 'number') ? `${_column.columnDef.size}px` : _column.columnDef.size;
				} else {
					cellWidth = `${DISPLAY_CELL_WIDTH}px`;
				}
				newTableGridLayoutMap[_column.id] = _column.columnDef.meta?.isHidden ? '' : cellWidth;
			} else {
				let cellWidth = '';
				if (!!_column.columnDef.size) {
					cellWidth = (typeof _column.columnDef.size === 'number') ? `${_column.columnDef.size}px` : _column.columnDef.size;
				} else {
					cellWidth = `${ACCESSOR_CELL_WIDTH}`;
				}
				newTableGridLayoutMap[_column.id] = _column.columnDef.meta?.isHidden ? '' : cellWidth;
			}
		}

		setTableGridLayout(newTableGridLayoutMap);
		setColumnVisibility(newVisibleColumns);
	}, [table, tableColumns, tableData]);

	const gridTemplateColumns = React.useMemo(() => {
		const newTableGridLayoutMap = {};
		for (const _headerId of Object.keys(tableGridLayout)) {
			if (!columnVisibility[_headerId]) {
				continue;
			}
			newTableGridLayoutMap[_headerId] = cellWidthsMap[_headerId]?.width
				? `${cellWidthsMap[_headerId]?.width}px`
				: tableGridLayout[_headerId];
		}

		if (accountId) {
			newTableGridLayoutMap['table-settings'] = TABLE_SETTINGS_WIDTH;
		}

		return Object.values(newTableGridLayoutMap).join(' ');
	}, [cellWidthsMap, columnVisibility, tableGridLayout, accountId]);

	const fixedHeaderWidthsMap: Record<string, number> = React.useMemo(() => {
		const newFixedHeadersWidthsMap = {};
		for (const _column of tableColumns) {
			if (_column.meta?.isFixed) {
				newFixedHeadersWidthsMap[_column.id!] = cellWidthsMap[_column.id!]?.width ?? _column.size;
			}
		}
		return newFixedHeadersWidthsMap;
	}, [cellWidthsMap, tableColumns]);

	const resolveHeaderCellStyle = React.useCallback((_headerCell: Header<T, unknown>) => {
		const cellStyleProps: React.CSSProperties = {};
		const cellClassNames: string[] = [];

		cellClassNames.push(styles['container__table-container__table__head__row__header-cell']);

		if (columnIndicesRightAligned.includes(_headerCell.index)) {
			cellClassNames.push(styles['right-aligned-column-header']);
		}

		if (fixedHeaderWidthsMap[_headerCell.column.id]) {
			cellClassNames.push(styles.fixed);
			const _fixedHeaders = Object.keys(fixedHeaderWidthsMap);
			const isLastFixed = _fixedHeaders.at(-1) === _headerCell.column.id;
			const _cellIndex = _fixedHeaders.findIndex(_findFixedHeaderIndex(_headerCell));
			let leftOffSet: number | string = 0;
			if (_cellIndex === 0) {
				leftOffSet = 0;
			} else if (_cellIndex === 1) {
				leftOffSet = fixedHeaderWidthsMap[_fixedHeaders[0]];
			} else {
				leftOffSet = `calc(${_fixedHeaders.slice(0, _cellIndex).map((_fh) => `${fixedHeaderWidthsMap[_fh]}px`).join(' + ')})`;
			}

			isLastFixed && cellClassNames.push(styles['last-fixed']);
			cellStyleProps.left = leftOffSet;
		}

		// If there is no accesor function, it means we are dealing with display column
		if (!_headerCell.column.accessorFn) {
			cellClassNames.push(styles['display-column']);
		}

		return {
			cellStyleProps,
			cellClassName: cellClassNames.join(' '),
		};
	}, [columnIndicesRightAligned, fixedHeaderWidthsMap]);

	const resolveDataCellStyle = React.useCallback((_cell: Cell<T, unknown>, isSubRowCell: boolean) => {
		const cellStyleProps: React.CSSProperties = {};
		const cellClassNames: string[] = [];

		cellClassNames.push(
			isSubRowCell
				? styles['container__table-container__table__body__row__sub-row-data-cell']
				: styles['container__table-container__table__body__row__data-cell']
		);

		if (fixedHeaderWidthsMap[_cell.column.id]) {
			cellClassNames.push(styles.fixed);
			const _fixedHeaders = Object.keys(fixedHeaderWidthsMap);
			const isLastFixed = _fixedHeaders.at(-1) === _cell.column.id;
			const _cellIndex = _fixedHeaders.findIndex(_findFixedCellIndex(_cell));
			let leftOffSet: number | string = 0;
			if (_cellIndex === 0) {
				leftOffSet = 0;
			} else if (_cellIndex === 1) {
				leftOffSet = fixedHeaderWidthsMap[_fixedHeaders[0]];
			} else {
				leftOffSet = `calc(${_fixedHeaders.slice(0, _cellIndex).map((_fh) => `${fixedHeaderWidthsMap[_fh]}px`).join(' + ')})`;
			}
			isLastFixed && cellClassNames.push(styles['last-fixed']);
			cellStyleProps.left = leftOffSet;
		}

		// If there is no accesor function, it means we are dealing with display column
		if (!_cell.column.accessorFn) {
			cellClassNames.push(styles['display-column']);
		}
		if (!isSubRowCell && _cell.column.columnDef.meta?.isMultiSpanColumn) {
			cellStyleProps.gridColumn = `${_cell.column.columnDef.meta?.startIndex} / span ${_cell.column.columnDef.meta?.spanFor}`;
		}

		if (isSubRowCell && _cell.column.columnDef.meta?.subRowColumn?.isMultiSpanColumn) {
			cellStyleProps.gridColumn = `${_cell.column.columnDef.meta?.subRowColumn?.startIndex} / span ${_cell.column.columnDef.meta?.subRowColumn?.spanFor}`;
		}

		return {
			cellStyleProps,
			cellClassName: cellClassNames.join(' '),
		};
	}, [fixedHeaderWidthsMap]);

	const onSortChange = React.useCallback((_header: Header<T, unknown>) => async (e: React.MouseEvent<HTMLSpanElement>) => {
		if (!tableSettings || !sorting || !_header.column.columnDef.enableSorting) {
			return;
		}

		const nextSortingOrder = _header.column.getNextSortingOrder();
		let newSort: SortingState = [];
		if (!nextSortingOrder) {
			const headerIndex = sorting.findIndex((_h) => _h.id === _header.id);
			newSort = Array.from(sorting);
			newSort.splice(headerIndex, 1);
		} else {
			_header.column.toggleSorting(nextSortingOrder === 'desc' ? true : false, e.shiftKey);
			const headerSort = { desc: nextSortingOrder === 'desc' ? true : false, id: _header.column.id };

			if (!e.shiftKey) {
				newSort = [headerSort];
			} else {
				newSort = Array.from(sorting);
				const _headerSortIndex = newSort.findIndex((_s) => _s.id === headerSort.id);
				if (_headerSortIndex >= 0) {
					newSort.splice(_headerSortIndex, 1, headerSort);
				} else {
					newSort = [...newSort, headerSort];
				}
			}
		}

		const newTableSettings: TableSettingsRequestModel = {
			...tableSettings,
			sort: newSort,
		};
		setSorting(newSort);

		await handleTableSettingsSave(newTableSettings);
	}, [handleTableSettingsSave, tableSettings, sorting]);

	const headersMapper = React.useCallback((_header: Header<T, unknown>) => {
		const isDisplayColumn = !_header.column.accessorFn;

		const { cellStyleProps: headerCellStyleProps, cellClassName: headerCellClassName } = resolveHeaderCellStyle(_header);

		return (
			<BaseHeaderCell<T>
				header={_header}
				headerCellClassName={headerCellClassName}
				headerCellStyleProps={headerCellStyleProps}
				isDisplayColumn={isDisplayColumn}
				key={_header.id}
				onColumnDrag={onColumnDrag}
				onSortChange={onSortChange}
				setHeaderReference={setHeaderReference}
			/>
		);
	}, [resolveHeaderCellStyle, onColumnDrag, onSortChange, setHeaderReference]);

	const headerGroupsMapper = React.useCallback((_headerGroup: HeaderGroup<T>) => {
		const rowStyle = styles['container__table-container__table__head__row'];
		return (
			<div
				className={rowStyle}
				key={_headerGroup.id}
				style={{ gridTemplateColumns }}
			>
				{_headerGroup.headers.map(headersMapper)}
				{accountId &&
					<span className={styles['container__table-container__table__head__row__settings']}>
						<span className="icon-settings" onClick={openTableSettingsModal} />
					</span>
				}
			</div>
		);
	}, [gridTemplateColumns, headersMapper, accountId, openTableSettingsModal]);

	const cellsMapper = React.useCallback((_cell: Cell<T, unknown>) => {
		const { cellStyleProps: dataCellStyleProps, cellClassName: dataCellClassName } = resolveDataCellStyle(_cell, false);

		return (
			<BaseCell<T>
				cell={_cell}
				dataCellClassName={dataCellClassName}
				dataCellStyleProps={dataCellStyleProps}
				isSubRowCell={false}
				key={_cell.column.id}
			/>
		);
	}, [resolveDataCellStyle]);

	const subRowCellMapper = React.useCallback((_subRowCell: Cell<T, unknown>) => {
		if (_subRowCell.column.columnDef.meta?.subRowColumn?.isOmmited) {
			return null;
		}

		const { cellStyleProps: dataCellStyleProps, cellClassName: dataCellClassName } = resolveDataCellStyle(_subRowCell, true);
		return (
			<BaseCell<T>
				cell={_subRowCell}
				dataCellClassName={dataCellClassName}
				dataCellStyleProps={dataCellStyleProps}
				isSubRowCell={true}
				key={_subRowCell.column.id}
			/>
		);
	}, [resolveDataCellStyle]);

	const subRowsMapper = React.useCallback((_subRow: Row<T>) => {
		const rowStyles = [styles['container__table-container__table__body__row'], styles['container__table-container__table__body__row--sub-row']];
		const isLastSubRowCell = _subRow.getParentRow()?.subRows?.at(-1) === _subRow;

		if (!isLastSubRowCell) {
			rowStyles.push(styles['container__table-container__table__body__row--non-last']);
		}

		return (
			<div
				className={rowStyles.join(' ')}
				key={_subRow.id}
				style={{ gridTemplateColumns }}
			>
				{_subRow.getVisibleCells().map(subRowCellMapper)}
			</div>
		);
	}, [gridTemplateColumns, subRowCellMapper]);

	const rowsMapper = React.useCallback((_row: Row<T>) => {
		const rowStyles = [styles['container__table-container__table__body__row']];

		if (_row.subRows.length > 0) {
			rowStyles.push(styles['container__table-container__table__body__row--non-last']);
		}

		const rowGroupStyles = [styles['container__table-container__table__body__row-group']];

		if (_row.original === currentlyPreviewedSidePanelRow?.original) {
			rowGroupStyles.push(styles['container__table-container__table__body__row-group--active-in-side-panel']);
		}

		return (
			<div
				className={rowGroupStyles.join(' ')}
				key={_row.id}
				onClick={handleRowClick(_row)}
			>
				<div
					className={rowStyles.join(' ')}
					key={_row.id}
					style={{ gridTemplateColumns }}
				>
					{_row.getVisibleCells().map(cellsMapper)}
				</div>
				{_row.subRows.map(subRowsMapper)}
			</div>
		);
	}, [cellsMapper, currentlyPreviewedSidePanelRow?.original, gridTemplateColumns, handleRowClick, subRowsMapper]);

	const hasFetchedTableHeaderData = (!!renderTableHeader && !!fetchTableHeaderData && !!tableHeaderData) || !renderTableHeader;

	const rows = React.useMemo(() => {
		return table.getRowModel().rows;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table, tableData]); // table data required

	const containerStyle = React.useMemo<React.CSSProperties>(() => {
		if (sidePanelVisible) {
			return { gridTemplateColumns: '4fr 1fr' };
		}
		return {};
	}, [sidePanelVisible]);

	if (!hasFetchedTableHeaderData || tableData === null) {
		return null;
	}

	const showBulkActionHeader = (
		selectable
		&& props.renderBulkActionHeader
		&& table.getFilteredSelectedRowModel().rows.length > 0
	);

	const showSidePanel = (
		sidePanelVisible
		&& renderSidePanelContext
		&& renderSidePanelHeader
	);

	const originalBodyStyle = fitContentHeight ? 'container__table-container__table__body__fit-content' : 'container__table-container__table__body';
	const baseBodyStyle = `${styles[originalBodyStyle]} ${tableBodyClassName ?? ''}`;
	const bodyStyle = baseBodyStyle;
	const baseHeaderStyle = styles['container__table-container__table__head'];
	const noRowsMessage = emptyTableMessage ?? 'No rows found.';

	return (
		<>
			<div className={styles.container} style={containerStyle}>
				<div className={styles['container__table-container']}>
					{/* TODO: Consider using React.Suspense once we migrate to React 18 */}
					{!!renderTableHeader &&
						<TableHeader tableHeader={renderTableHeader} tableHeaderData={tableHeaderData} />
					}
					{!hideActionHeader &&
						<ActionHeader
							additionalFilter={additionalFilter}
							buttons={buttons ?? []}
							exportAsZip={exportAsZip}
							filtersClassName={filtersClassName}
							filterText={filterText}
							hasSearchInput={hasSearchInput ?? false}
							onFilterTextChange={onFilterTextChange}
							searchLabel={searchLabel ?? ''}
						/>
					}
					{showBulkActionHeader &&
						props.renderBulkActionHeader?.(table.getFilteredSelectedRowModel().rows)
					}
					<div className={styles['container__table-container__table']}>
						<div className={baseHeaderStyle}>
							{table.getHeaderGroups().map(headerGroupsMapper)}
						</div>
						<div className={bodyStyle}
							style={{
								'--extra-table-offset': `${offsetHeight ?? 0}px`,
							} as React.CSSProperties}
						>
							{
								!loading &&
								<>
									{rows.length > 0
										? rows.map(rowsMapper)
										: <div className={styles[`container__table-container__table__body__no-rows${fitContentHeight ? '__fit-content' : ''}`]}>{noRowsMessage}</div>
									}
								</>
							}
							{
								loading &&
								<div className={styles.loading}>
									<LoadingIndicator color="orange" />
								</div>
							}
						</div>
					</div>
					<PaginationFooter<T>
						pageCount={pageCount}
						pageSize={pagination?.pageSize}
						savePageNumber={savePageNumber}
						table={table}
						tableSettings={tableSettings}
						updateTableSettings={paginationUpdate}
					/>
				</div >
				{
					showSidePanel && (
						<SidePanel<T>
							currentlyPreviewedRow={currentlyPreviewedSidePanelRow}
							renderSidePanelContext={renderSidePanelContext}
							renderSidePanelHeader={renderSidePanelHeader}
						/>
					)
				}
			</div>
			<TableSettingsModal<T>
				accountId={accountId}
				closeTableSettings={closeTableSettingsModal}
				columns={columns}
				onSave={handleTableSettingsSave}
				show={showSettingsModal}
				tableName={tableName}
				tableSettings={tableSettings}
			/>
		</>
	);
});

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;

	return {
		accountId: companyData?.accountId ?? null,
	};
}

function mapDispatchToProps() {
	return {
		findTableSettings: TableSettingsActions.findTableSettings,
		updateTableSettings: TableSettingsActions.updateTableSettings,
	};
}

const connector = connect(
	mapStateToProps,
	mapDispatchToProps(),
	null,
	{ forwardRef: true }
);

const ConnectedTable = connector(
	Table
);

const enhance = compose(
	React.memo,
	connector
);

export default enhance(ConnectedTable) as <T>(
	props: TableProps<T> & { ref: React.RefObject<TableRef<T>>; }
) => ReturnType<typeof Table>;
