import * as React from 'react';
import { Col } from 'react-bootstrap';
import { FieldArray } from 'redux-form';

import type UpsertContactStatusEnum from '@acceligentllc/shared/enums/upsertContactStatus';

import type AddressRequestModel from 'ab-requestModels/address.requestModel';

import type { OwnProps as AddressesProps } from 'af-components/SharedForms/Address/Addresses';
import Addresses from 'af-components/SharedForms/Address/Addresses';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import * as MapsUtil from 'af-utils/maps.util';

import type { ContactAddressFM } from './formModel';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	addresses: AddressRequestModel[];
	resetTimer: () => void;
	change: (fieldName: string, value: UpsertContactStatusEnum) => void;
	selector: (fieldName: string) => ContactAddressFM;
}

type Marker = {
	lat: number;
	lng: number;
};

type Props = OwnProps;

const WorkOrderWorkLocation: React.FC<Props> = (props) => {

	const { addresses, disabled, change, selector } = props;
	const [markers, setMarkers] = React.useState<Marker[]>([]);

	React.useEffect(() => {
		setMarkers(addresses?.map(MapsUtil.getMarker).filter(MapsUtil.markerHasLatLng) ?? []);
	}, [addresses]);

	return (
		<Section label="Work Locations">
			<Col sm={24}>
				<FieldArray<AddressesProps>
					canBeEmpty={true}
					change={change}
					component={Addresses}
					disabled={disabled}
					disableMap={disabled}
					formName={WORK_ORDER_FORM}
					markers={markers}
					name="addresses"
					removeOnDelete={false}
					selector={selector}
				/>
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderWorkLocation);
