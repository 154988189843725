export const SUBMIT_DELAY = 1000;
export const TIMEOUT_DELAY = 5 * 60 * 1000; // 5 minutes in milliseconds
export const ONE_SECOND = 1000; // 1 second in milliseconds
export const HALF_MINUTE = ONE_SECOND * 30; // half minute in milliseconds

export const SCROLL_EVENT_LOCK_TIMEOUT = 10;

export const EQUIPMENT_COST_TREE_FILTER = 'equipment-cost-tree-filter';

export const DROPDOWN_MENU_CONTAINER_CLASSNAME = 'dropdown-menu--scroll-container';

export const DEFAULT_MAP_ZOOM_LEVEL_ON_CENTER = 13;

export const USE_WILDCARDS = process.env.USE_WILDCARDS === 'true';
export const USE_SSL = process.env.USE_SSL === 'true';

const __ENV__ = process.env.NODE_ENV?.toLowerCase();
export const __DEV__ = !process.env.NODE_ENV || !__ENV__?.includes('prod');

export const __ENVIRONMENT__ = process.env.ENVIRONMENT?.toLowerCase();
export const __STAGING__ = __ENVIRONMENT__ === 'staging';
export const __TESTING__ = __ENVIRONMENT__ === 'testing';

export const WO_PREVIEW_MOBILE_BREAKPOINT = 800;

export const NOTIFY_MODAL_COL_SPAN = {
	CREW: 10,
	CHECKBOX: 5,
	SEND_TIME: 7,
};

export const HISTORY_MODAL_COL_SPAN = {
	INDEX: 5,
	STATUS: 10,
	UPDATED_AT: 7,
	UPDATED_BY: 10,
};

export enum NOTIFY_EMPLOYEE_TYPE {
	EMAIL = 'EMAIL',
	SMS = 'SMS'
}

export const DISPLAY_VIEW_NUMBER_OF_ITEMS_IN_COLUMN = 10;
export const DISPLAY_VIEW_NUMBER_OF_RESOURCES_IN_WORK_ORDER_COLUMN = 12;

// We need consistent views at following screen widths: 768px, 1024px, 1280px, 1920px, 2560px, 3840px - we are placing breakpoints 50px lower
export const SCREEN_BREAKPOINT_XXS = 718;
export const SCREEN_BREAKPOINT_XS = 974;
export const SCREEN_BREAKPOINT_S = 1230;
export const SCREEN_BREAKPOINT_M = 1870;
export const SCREEN_BREAKPOINT_L = 2510;
export const SCREEN_BREAKPOINT_XL = 3790;

export const SCREEN_BREAKPOINT_REPORT_BLOCK_FORM = 1600;

export const MODAL_S = 384;

export const SB_STATISTIC_WIDTH = 180;

export const DAILY_TIP_MAX_LENGTH = 900; // TODO, NOTE: To help with COVID-19 notifications, it has been raised from 150. Should be reverted if we want to decrease the costs

export const DISPLAY_VIEW_BOARD_CONTEXT = 'BOARD';
export const DISPLAY_VIEW_TOOLBAR_CONTEXT = 'TOOLBAR';

export const APP_FONT_NAME = 'Roboto';

export const PREVIEW_LINK_COPIED_MESSAGE_TIME = 3000;

export const APP_SCROLLBARS_ID = 'app-scrollable';

export const TEXT_AREA_LINE_HIGHT = 16;

export const INPUT_FIELD_MAX_CHARACTERS = 300;

export const ASYNC_SELECT_DROPDOWN_WIDTH = 540;

export const CUSTOMER_WORK_ORDER_MAX_CHARACTERS = 25;

export const BILLING_CODE_DECIMAL_POINTS = 4;
