import * as React from 'react';
import { Field } from 'redux-form';

import type AccountPermission from '@acceligentllc/shared/enums/accountPermission';

import type AccountRM from 'ab-requestModels/account/upsert.requestModel';

import Checkbox from 'af-fields/Checkbox';

import { LABEL, IMPLICATIONS, permissionDependencies } from '../constants';

import PermissionDescription from '../PermissionDescription';

interface OwnProps {
	field: string;
	isDisabled: boolean;
	permission: AccountPermission;
	permissions: AccountRM['permissions'];
	change: (field: string, value: boolean) => void;
}

type Props = OwnProps;

const PermissionField: React.FC<Props> = (props) => {
	const { field, isDisabled, permission, permissions, change } = props;

	// Recursively checks permissions and checks if their dependencies have other dependencies
	const checkPermissionDependencies = React.useCallback((perm: AccountPermission, permissionMap: { [key: string]: true; }) => {
		// Used to avoid circular references
		if (permissionMap[perm]) {
			return;
		}

		if (IMPLICATIONS[perm]) {
			permissionMap[perm] = true;
			for (const consequentPermission of (IMPLICATIONS[perm] ?? [])) {
				if (IMPLICATIONS[consequentPermission]) {
					checkPermissionDependencies(consequentPermission, permissionMap);
				}
				change(`${field}.${consequentPermission}`, true);
			}
		}
	}, [change, field]);

	const handleChange = React.useCallback((isChecked: boolean) => {
		if (isChecked && IMPLICATIONS[permission]) {
			checkPermissionDependencies(permission, {});
		}
	}, [permission, checkPermissionDependencies]);

	const disabled = React.useMemo(() => {
		return permissionDependencies[permission]?.some((_permission) => permissions?.[_permission]) ?? false;
	}, [permission, permissions]);

	const tooltip = React.useMemo(() => {
		return PermissionDescription.constructIfExists(permission);
	}, [permission]);

	return (
		<Field
			component={Checkbox}
			id={`${field}.${permission}`}
			isDisabled={isDisabled || disabled}
			isSmall={true}
			label={LABEL[permission]}
			name={`${field}.${permission}`}
			onValueChange={handleChange}
			tooltipMessage={tooltip}
		/>
	);
};

export default PermissionField;
