import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';
import type { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';

import type { RootState } from 'af-reducers';

import * as WorkOrderActions from 'af-actions/workOrder';

import type { WorkOrderNoteForm } from 'ab-requestModels/workOrder.requestModel';

import { WORK_ORDER_NOTE } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import Textarea from 'af-fields/Textarea';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';

type FormProps = InjectedFormProps<WorkOrderNoteForm, FormOwnProps>;

type FormOwnProps = ConnectedProps<typeof connector>;

type Props = FormOwnProps & FormProps;

const _getInitialValues = (workOrdersByDateDictionary: ScheduleBoardViewModel['workOrdersByDateDictionary'], workOrderCode: Nullable<string>, date: Nullable<string>) => {
	const workOrder = date && workOrderCode
		? workOrdersByDateDictionary[date]?.workOrders?.[workOrderCode]
		: null;

	return {
		workOrderId: workOrder?.id,
		note: workOrder?.workOrderNotes ?? null,
	};
};

const WorkOrderNoteModal: React.FC<Props> = (props) => {
	const { submitting, handleSubmit, editWorkOrderNote, workOrdersByDateDictionary, initialize } = props;

	const { workOrderNoteModalCode: workOrderCode, workOrderNoteModalDate, setWorkOrderNoteModalData } = useScheduleBoardModals();

	const [showModal, setShowModal] = React.useState(!!workOrderCode);

	React.useEffect(() => {
		if (showModal) {
			const initialValues = _getInitialValues(workOrdersByDateDictionary, workOrderCode, workOrderNoteModalDate);
			initialize(initialValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal, workOrderCode, workOrderNoteModalDate]);

	React.useEffect(() => {
		setShowModal(!!workOrderCode);
	}, [workOrderCode]);

	const closeModal = React.useCallback(() => {
		setWorkOrderNoteModalData(null, null);
	}, [setWorkOrderNoteModalData]);

	const onSubmit = React.useCallback(async (form: WorkOrderNoteForm) => {
		await editWorkOrderNote(form.workOrderId, form);
		closeModal();
	}, [closeModal, editWorkOrderNote]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Edit Work Order Note"
			/>
			<CustomModal.Body>
				<Field
					component={Textarea}
					label="Note"
					maxCharacters={250}
					name="note"
					placeholder="Enter note"
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<SubmitButton
					label="Save Note"
					onClick={handleSubmit(onSubmit)}
					reduxFormSubmitting={submitting}
					submitKey={WORK_ORDER_NOTE}
					variant="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	return {
		workOrdersByDateDictionary: state.scheduleBoard.workOrdersByDateDictionary,
	};
}

function mapDispatchToProps() {
	return {
		editWorkOrderNote: WorkOrderActions.editWorkOrderNote,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());
const enhance = compose<React.ComponentClass>(
	connector,
	reduxForm<WorkOrderNoteForm, FormOwnProps>({ form: WORK_ORDER_NOTE })
);

export default enhance(WorkOrderNoteModal);
