import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import SubmitButton from 'af-components/SubmitButton';

interface OwnProps {
	submitting: boolean;
	invalid: boolean;
	onSubmit: () => void;
	onCancel: () => void;
}

type Props = OwnProps;

const Actions: React.FC<Props> = ({ onSubmit, onCancel, invalid, submitting }: Props) => {
	return (
		<div className="user-upsert__actions">
			<Button
				label="Cancel"
				onClick={onCancel}
				style="secondary"
			/>
			<SubmitButton
				disabled={invalid}
				label="Submit"
				onClick={onSubmit}
				reduxFormSubmitting={submitting}
			/>
		</div>
	);
};

export default React.memo(Actions);
