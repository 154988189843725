import * as Err from 'restify-errors';

import type { DirectoryIcon } from '@acceligentllc/shared/enums/directoryIcon';
import type FileType from '@acceligentllc/shared/enums/fileType';
import type Priority from '@acceligentllc/shared/enums/priority';
import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import type ResourceStatus from '@acceligentllc/shared/enums/resourceStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import type WorkRequestDisposalMethod from '@acceligentllc/shared/enums/workRequestDisposalMethod';
import type WorkRequestJobHazardAssessmentStatus from '@acceligentllc/shared/enums/workRequestJobHazardAssessmentStatus';
import type WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type DeliverableDataType from '@acceligentllc/shared/enums/deliverableDataType';
import { EmailTypes, EmailTypesArray, PhoneTypes, PhoneTypesArray } from '@acceligentllc/shared/enums/contactMethodType';
import type CountryCode from '@acceligentllc/shared/enums/countryCode';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type AccountBase from 'ab-domain/models/account/base';
import type AttachmentDirectoryBase from 'ab-domain/models/attachmentDirectory/base';
import type BillingCodeBase from 'ab-domain/models/billingCode/base';
import type DirectoryBase from 'ab-domain/models/directory/base';
import type JobStatusBase from 'ab-domain/models/jobStatus/base';
import type SaleBase from 'ab-domain/models/sale/base';
import type LocationBase from 'ab-domain/models/location/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type DivisionBase from 'ab-domain/models/division/base';
import type DeliverableDataBase from 'ab-domain/models/deliverableData/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type ContactAddressBase from 'ab-domain/models/contactAddress/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type AddressBase from 'ab-domain/models/address/base';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { filterMap } from '@acceligentllc/shared/utils/array';
import { parseDirectoryPath } from '@acceligentllc/shared/utils/blobStorage';
import * as UserUtils from '@acceligentllc/shared/utils/user';
import { getShortAddress } from '@acceligentllc/shared/utils/address';

import * as BlobStorageUtil from 'ab-utils/blobStorage.util';

import * as ContactUtils from 'ab-utils/contact.util';

export class W_Job_FindJobForm_VM {
	// Sales
	salesId: Nullable<string>;
	salesLead: Nullable<W_Job_FindJobForm_VM_Sales>;
	salesLeadId: Nullable<number>;
	estimateTotal: number;

	// Job Summary
	/** on form shown as Work Request Id */
	jobCode: string;
	title: Nullable<string>;
	isInternal: boolean;
	jobNotes: Nullable<string>;
	customerCompanyName: Nullable<string>;
	customerReferencePO: Nullable<string>;
	isEmergencyJob: boolean;
	priority: Priority;
	jobStatusId: Nullable<number>;
	jobStatus: Nullable<W_Job_FindJobForm_VM_WorkRequestStatus>;
	projectManagerId: Nullable<number>;
	projectManager: Nullable<W_Job_FindJobForm_VM_Employee>;
	officeId: Nullable<number>;
	office: Nullable<W_Job_FindJobForm_VM_Office>;
	divisionId: Nullable<number>;
	division: Nullable<W_Job_FindJobForm_VM_Division>;

	// Job Detail
	projectOwner: Nullable<string>;
	ownerReference: Nullable<string>;
	taxExempt: boolean;
	generalContractor: Nullable<string>;
	contractorReference: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jurisdiction: Nullable<string>;

	// Requirements
	requestMBEOrWBERequirementsNote: Nullable<string>;
	requestBondRequirementsNote: Nullable<string>;
	requestHSERequirementsNote: Nullable<string>;
	retainage: Nullable<string>;
	requestInsuranceRequirementsNote: Nullable<string>;
	requestLiquidatedDamagesNote: Nullable<string>;

	// Schedule Info
	targetStartDate: Nullable<string>;
	targetCompletionDate: Nullable<string>;
	targetDaysFromStart: Nullable<number>;
	startDate: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;

	// Contacts
	customerContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	siteContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	billingContact: Nullable<W_Job_FindJobForm_VM_Contact>;
	additionalContacts: Nullable<W_Job_FindJobForm_VM_Contact[]>;

	// Deliverables
	isDeliverable: boolean;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<W_Job_FindJobForm_VM_Account>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<W_Job_FindJobForm_VM_DeliverableData>;
	deliverableNotes: Nullable<string>;

	// Work Location
	workLocation: Nullable<W_Job_FindJobForm_VM_Address>;

	// Summary of Work
	requestJobNote: Nullable<string>;

	// Survey data
	surveyNeeded: boolean;
	surveyedById: Nullable<number>;
	surveyedBy: Nullable<W_Job_FindJobForm_VM_EmployeeOption>;
	surveyDate: Nullable<string>;
	waterAvailable: boolean;
	waterSupplyInformation: Nullable<string>;
	subcontractorNeeded: Nullable<string>;
	subcontractorInformation: Nullable<string>;
	pipeSizes: Nullable<string>;
	pipeTypes: Nullable<string>;
	manholeDepths: Nullable<string>;
	manholeFrameDiameters: Nullable<string>;
	informationsProvidedByCustomersBy: Nullable<string>;

	// Disposal
	ableToCleanAndWashEquipmentAtCustomerLocation: boolean;
	cleanAndWashLocation: Nullable<string>;
	isWasteGeneratedSolid: boolean;
	isWasteGeneratedSludge: boolean;
	isWasteGeneratedLiquid: boolean;
	isWasteHazardous: boolean;
	isWasteManifest: boolean;
	wasteDescription: Nullable<string>;
	expectedWasteQuantity: Nullable<number>;
	disposalMethod: Nullable<WorkRequestDisposalMethod>;
	disposalInstructions: Nullable<string>;
	disposalContact: Nullable<W_Job_FindJobForm_VM_Contact>;

	// Job Hazard Summary
	hazardSummary: Nullable<string>;
	permitsRequired: Nullable<string[]>;

	// Safety
	environmentalExposure: boolean;
	environmentalExposureDescription: Nullable<string>;
	respiratoryProtection: boolean;
	respiratoryProtectionDescription: Nullable<string>;
	overheadHazards: boolean;
	overheadHazardsDescription: Nullable<string>;
	heightWork: boolean;
	heightWorkDescription: Nullable<string>;
	mechanicalHazards: boolean;
	mechanicalHazardsDescription: Nullable<string>;
	electricalHazards: boolean;
	electricalHazardsDescription: Nullable<string>;
	hazardousMaterials: boolean;
	hazardousMaterialsDescription: Nullable<string>;
	dangerousAnimalsOrPlants: boolean;
	dangerousAnimalsOrPlantsDescription: Nullable<string>;

	// Environmental Protection
	waterOrWetlands: boolean;
	waterOrWetlandsDescription: Nullable<string>;
	stormDrain: boolean;
	stormDrainDescription: Nullable<string>;
	bypassPumping: boolean;
	bypassPumpingDescription: Nullable<string>;
	sensitiveEnvironment: boolean;
	sensitiveEnvironmentDescription: Nullable<string>;

	// Public Safety
	vehicleTraffic: boolean;
	vehicleTrafficDescription: Nullable<string>;
	pedestrianCrossing: boolean;
	pedestrianCrossingDescription: Nullable<string>;

	// Status
	jobHazardAssessmentStatus: Nullable<WorkRequestJobHazardAssessmentStatus>;

	/** Necessary when creating job through WO page */
	id: number;
	status: WorkRequestStatus;
	summaryOfWork: Nullable<string>;
	travelLocation: Nullable<W_Job_FindJobForm_VM_Address>;
	travelLocationShort: Nullable<string>;

	jc80gpm: boolean;
	sjc80gpm: boolean;
	jcRecycler80: boolean;
	jjc120gpm: boolean;
	jjc160gpm: boolean;
	jcHiRail: boolean;
	jetter: boolean;
	harbenJetter: boolean;
	hjJetter: boolean;
	tvTruck: boolean;
	tvLateralTk: boolean;
	tvGroutTk: boolean;
	tvLatGroutTk: boolean;
	tvSonarTk: boolean;
	tvPolaris: boolean;
	tvLCRTk: boolean;
	tvLateralReinstator: boolean;
	tvMTHTk: boolean;
	tvLaserTk: boolean;
	minicam: boolean;
	vacTk: boolean;
	hivacTk: boolean;
	hiRailvacTk: boolean;
	tanker: boolean;
	wb: boolean;
	hwBoiler: boolean;
	pressureWash: boolean;
	airComp: boolean;
	clamTk: boolean;
	bucketMach: boolean;
	mhRehabTk: boolean;
	microtrax: boolean;
	smallEasementMachine: boolean;
	bigEasementMachine: boolean;
	arrowBoard: boolean;
	supportTk: boolean;
	rollOffTk: boolean;
	pump: boolean;
	pigLaunch: boolean;
	stoveMach: boolean;
	powerRodder: boolean;
	reeferTruck: boolean;
	lateralReinstatorTrailer: boolean;
	steamCureTruck: boolean;
	airTesting: boolean;
	trackMachine: boolean;
	numberOfEmployees: number;
	additionalTruckEquipmentFields: W_Job_FindJobForm_VM_AdditionalTrucksEquipmentField[];

	// Billing
	billTo: Nullable<string>;
	billToAddress: Nullable<string>;
	billToCityStateZip: Nullable<string>;
	billToPhoneNumber: Nullable<string>;
	billToFaxNumber: Nullable<string>;
	billToContactName: Nullable<string>;
	billToEmail: Nullable<string>;

	allowCustomerSignature: boolean;

	targetedDaysFromStart: Nullable<number>;

	// Project and billing codes
	billingCodes: W_Job_FindJobForm_VM_BillingCode[];
	isProject: boolean;
	projectId: Nullable<number>;
	subjobs: W_Job_FindJobForm_VM_Subjob[];

	// Attachments
	directories: W_Job_FindJobForm_VM_WorkRequestDirectory[];
	workRequestAttachments: W_Job_FindJobForm_VM_Attachment[];
	uploadedAttachmentIds?: number[];

	public static async initialize(data: WorkRequestBase): Promise<W_Job_FindJobForm_VM> {
		if (!!data.projectId && !data.project) {
			throw new Err.InternalServerError('Include project in Work Request');
		}

		const attachmentDirectories = (data.workRequestDirectories ?? []).flatMap((_wrDir) => _wrDir.directory.attachmentDirectories ?? []);
		const targetStartDate = data.targetStartDate
			? TimeUtils.formatDate(data.targetStartDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		const targetCompletionDate = data.targetCompletionDate
			? TimeUtils.formatDate(data.targetCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;
		const startDate = data.startDate ? TimeUtils.formatDate(data.startDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null;
		const guaranteedCompletionDate = data.guaranteedCompletionDate
			? TimeUtils.formatDate(data.guaranteedCompletionDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
			: null;

		const additionalTruckEquipmentFields: W_Job_FindJobForm_VM_AdditionalTrucksEquipmentField[] = [];
		data.additionalTruckEquipmentField1Name &&
			additionalTruckEquipmentFields.push({ isChecked: data.additionalTruckEquipmentField1Checked, name: data.additionalTruckEquipmentField1Name });
		data.additionalTruckEquipmentField2Name &&
			additionalTruckEquipmentFields.push({ isChecked: data.additionalTruckEquipmentField2Checked, name: data.additionalTruckEquipmentField2Name });

		return {
			id: data.id,
			status: data.status,
			summaryOfWork: null,
			targetedDaysFromStart: null,
			jobNotes: null,
			salesId: data.salesId,
			salesLeadId: data.salesLeadId,
			salesLead: data.salesLead ? new W_Job_FindJobForm_VM_Sales(data.salesLead) : null,
			estimateTotal: data.estimateTotal ?? 0,
			// Job Summary
			jobCode: data.jobCode,
			title: data.title,
			isInternal: data.isInternal ?? false,
			customerCompanyName: data.customerCompanyName,
			customerReferencePO: data.customerReferencePO,
			isEmergencyJob: data.isEmergencyJob,
			priority: data.priority,
			jobStatusId: data.jobStatus?.id ?? null,
			jobStatus: data.jobStatus ? new W_Job_FindJobForm_VM_WorkRequestStatus(data.jobStatus) : null,
			projectManagerId: data.projectManagerId,
			projectManager: data.projectManager ? new W_Job_FindJobForm_VM_Employee(data.projectManager) : null,
			officeId: data.officeId,
			office: data.office ? new W_Job_FindJobForm_VM_Office(data.office) : null,
			divisionId: data.divisionId,
			division: data.division ? new W_Job_FindJobForm_VM_Division(data.division) : null,
			travelLocation: data.travelLocation ? new W_Job_FindJobForm_VM_Address(data.travelLocation) : null,
			// Job Detail
			projectOwner: data.projectOwner,
			ownerReference: data.ownerReference,
			taxExempt: data.taxExempt,
			generalContractor: data.generalContractor,
			contractorReference: data.contractorReference,
			isUnion: data.isUnion,
			isPrevailingWage: data.isPrevailingWage,
			jurisdiction: data.jurisdiction,
			deliverableAssigneeId: data.deliverableAssigneeId,
			deliverableAssignee: data.deliverableAssignee && new W_Job_FindJobForm_VM_Account(data.deliverableAssignee),
			deliverableSoftwareId: data.deliverableSoftwareId,
			deliverableSoftware: data.deliverableSoftware && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableSoftware),
			deliverableFileFormatId: data.deliverableFileFormatId,
			deliverableFileFormat: data.deliverableFileFormat && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableFileFormat),
			deliverableCodeStandardId: data.deliverableCodeStandardId,
			deliverableCodeStandard: data.deliverableCodeStandard && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableCodeStandard),
			deliverableDeliveryMethodId: data.deliverableDeliveryMethodId,
			deliverableDeliveryMethod: data.deliverableDeliveryMethod && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableDeliveryMethod),
			deliverableDeliveryTimelineId: data.deliverableDeliveryTimelineId,
			deliverableDeliveryTimeline: data.deliverableDeliveryTimeline && new W_Job_FindJobForm_VM_DeliverableData(data.deliverableDeliveryTimeline),
			deliverableNotes: data.deliverableNotes,
			travelLocationShort: data.travelLocation ? getShortAddress(data.travelLocation) : null,
			// Requirements
			requestMBEOrWBERequirementsNote: data.requestMBEOrWBERequirementsNote,
			requestBondRequirementsNote: data.requestBondRequirementsNote,
			requestHSERequirementsNote: data.requestHSERequirementsNote,
			retainage: data.retainage,
			requestInsuranceRequirementsNote: data.requestInsuranceRequirementsNote,
			requestLiquidatedDamagesNote: data.requestLiquidatedDamagesNote,
			// Schedule Info
			targetStartDate,
			targetCompletionDate,
			startDate,
			guaranteedCompletionDate,
			targetDaysFromStart: (targetStartDate && targetCompletionDate) ? TimeUtils.getDiff(targetCompletionDate, targetStartDate, 'days', TimeFormat.DATE_ONLY) : null,
			guaranteedDaysFromStart: (startDate && guaranteedCompletionDate) ? TimeUtils.getDiff(guaranteedCompletionDate, startDate, 'days', TimeFormat.DATE_ONLY) : null,
			// Contacts
			customerContact: data.customerContact ? new W_Job_FindJobForm_VM_Contact(data.customerContact) : null,
			siteContact: data.supervisorContact ? new W_Job_FindJobForm_VM_Contact(data.supervisorContact) : null,
			billingContact: data.billingContact ? new W_Job_FindJobForm_VM_Contact(data.billingContact) : null,
			billTo: data.billTo,
			billToAddress: data.billToAddress,
			billToCityStateZip: data.billToCityStateZip,
			billToPhoneNumber: data.billToPhoneNumber,
			billToFaxNumber: data.billToFaxNumber,
			billToContactName: data.billToContactName,
			billToEmail: data.billToEmail,
			additionalContacts: data.additionalContacts
				? W_Job_FindJobForm_VM_Contact.bulkConstructor(data.additionalContacts.map((_ac) => _ac.contactLookup))
				: [],
			// Deliverables
			isDeliverable: !!data.deliverableAssigneeId,
			allowCustomerSignature: data.allowCustomerSignature,
			// Work Location
			workLocation: data.travelLocation ? new W_Job_FindJobForm_VM_Address(data.travelLocation) : null,
			// Summary of Work
			requestJobNote: data.requestJobNote,
			// Survey data
			surveyNeeded: data.surveyNeeded,
			surveyedById: data.surveyedById,
			surveyedBy: data.surveyedBy ? new W_Job_FindJobForm_VM_EmployeeOption(data.surveyedBy) : null,
			surveyDate: data.surveyDate ? TimeUtils.formatDate(data.surveyDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null,
			waterAvailable: data.waterAvailable,
			waterSupplyInformation: data.waterSupplyInformation,
			subcontractorNeeded: data.subcontractorNeeded,
			subcontractorInformation: data.subcontractorInformation,
			pipeSizes: data.pipeSizes,
			pipeTypes: data.pipeTypes,
			manholeDepths: data.manholeDepths,
			manholeFrameDiameters: data.manholeFrameDiameters,
			informationsProvidedByCustomersBy: data.informationsProvidedByCustomersBy,
			// Trucks / Equipment
			jc80gpm: data.jc80gpm,
			sjc80gpm: data.sjc80gpm,
			jcRecycler80: data.jcRecycler80,
			jjc120gpm: data.jjc120gpm,
			jjc160gpm: data.jjc160gpm,
			jcHiRail: data.jcHiRail,
			jetter: data.jetter,
			harbenJetter: data.harbenJetter,
			hjJetter: data.hjJetter,
			tvTruck: data.tvTruck,
			tvLateralTk: data.tvLateralTk,
			tvGroutTk: data.tvGroutTk,
			tvLatGroutTk: data.tvLatGroutTk,
			tvSonarTk: data.tvSonarTk,
			tvPolaris: data.tvPolaris,
			tvLCRTk: data.tvLCRTk,
			tvLateralReinstator: data.tvLateralReinstator,
			tvMTHTk: data.tvMTHTk,
			tvLaserTk: data.tvLaserTk,
			minicam: data.minicam,
			vacTk: data.vacTk,
			hivacTk: data.hivacTk,
			hiRailvacTk: data.hiRailvacTk,
			tanker: data.tanker,
			wb: data.wb,
			hwBoiler: data.hwBoiler,
			pressureWash: data.pressureWash,
			airComp: data.airComp,
			clamTk: data.clamTk,
			bucketMach: data.bucketMach,
			mhRehabTk: data.mhRehabTk,
			microtrax: data.microtrax,
			smallEasementMachine: data.smallEasementMachine,
			bigEasementMachine: data.bigEasementMachine,
			arrowBoard: data.arrowBoard,
			supportTk: data.supportTk,
			rollOffTk: data.rollOffTk,
			pump: data.pump,
			pigLaunch: data.pigLaunch,
			stoveMach: data.stoveMach,
			powerRodder: data.powerRodder,
			reeferTruck: data.reeferTruck,
			lateralReinstatorTrailer: data.lateralReinstatorTrailer,
			steamCureTruck: data.steamCureTruck,
			airTesting: data.airTesting,
			trackMachine: data.trackMachine,
			numberOfEmployees: data.numberOfEmployees,
			additionalTruckEquipmentFields,
			// Disposal
			ableToCleanAndWashEquipmentAtCustomerLocation: data.ableToCleanAndWashEquipmentAtCustomerLocation,
			cleanAndWashLocation: data.cleanAndWashLocation,
			isWasteGeneratedSolid: data.isWasteGeneratedSolid,
			isWasteGeneratedSludge: data.isWasteGeneratedSludge,
			isWasteGeneratedLiquid: data.isWasteGeneratedLiquid,
			isWasteHazardous: data.isWasteHazardous,
			isWasteManifest: data.isWasteManifest,
			wasteDescription: data.wasteDescription,
			expectedWasteQuantity: data.expectedWasteQuantity,
			disposalMethod: data.disposalMethod,
			disposalInstructions: data.disposalInstructions,
			disposalContact: data.disposalContact ? new W_Job_FindJobForm_VM_Contact(data.disposalContact) : null,
			hazardSummary: data.hazardSummary,
			permitsRequired: data.permitsRequired,
			environmentalExposure: data.environmentalExposure,
			environmentalExposureDescription: data.environmentalExposureDescription,
			respiratoryProtection: data.respiratoryProtection,
			respiratoryProtectionDescription: data.respiratoryProtectionDescription,
			overheadHazards: data.overheadHazards,
			overheadHazardsDescription: data.overheadHazardsDescription,
			heightWork: data.heightWork,
			heightWorkDescription: data.heightWorkDescription,
			mechanicalHazards: data.mechanicalHazards,
			mechanicalHazardsDescription: data.mechanicalHazardsDescription,
			electricalHazards: data.electricalHazards,
			electricalHazardsDescription: data.electricalHazardsDescription,
			hazardousMaterials: data.hazardousMaterials,
			hazardousMaterialsDescription: data.hazardousMaterialsDescription,
			dangerousAnimalsOrPlants: data.dangerousAnimalsOrPlants,
			dangerousAnimalsOrPlantsDescription: data.dangerousAnimalsOrPlantsDescription,
			// Environmental Protection
			waterOrWetlands: data.waterOrWetlands,
			waterOrWetlandsDescription: data.waterOrWetlandsDescription,
			stormDrain: data.stormDrain,
			stormDrainDescription: data.stormDrainDescription,
			bypassPumping: data.bypassPumping,
			bypassPumpingDescription: data.bypassPumpingDescription,
			sensitiveEnvironment: data.sensitiveEnvironment,
			sensitiveEnvironmentDescription: data.sensitiveEnvironmentDescription,
			// Public Safety
			vehicleTraffic: data.vehicleTraffic,
			vehicleTrafficDescription: data.vehicleTrafficDescription,
			pedestrianCrossing: data.pedestrianCrossing,
			pedestrianCrossingDescription: data.pedestrianCrossingDescription,
			// Job Hazard Assessment Status
			jobHazardAssessmentStatus: data.jobHazardAssessmentStatus,
			billingCodes: !!data.billingCodes?.length
				? W_Job_FindJobForm_VM_BillingCode.bulkConstructor(data.billingCodes)
				: [],
			directories: W_Job_FindJobForm_VM_WorkRequestDirectory.bulkConstructor((data.workRequestDirectories ?? []).map((_wrDir) => _wrDir.directory)),
			workRequestAttachments: await W_Job_FindJobForm_VM_Attachment.bulkInitialize(attachmentDirectories),
			isProject: data.project
				? data.project.mainWorkRequestId === data.id
				: false,
			projectId: data.projectId,
			subjobs: filterMap(
				data.project?.workRequests ?? [],
				(_wr) => _wr.id !== data.id,
				(_wr) => new W_Job_FindJobForm_VM_Subjob(_wr)
			),
		};
	}
}

class W_Job_FindJobForm_VM_Subjob {
	id: number;
	jobCode: string;
	isInternal: boolean;
	title: Nullable<string>;
	customerCompany: Nullable<string>;
	customerFormatted: Nullable<string>;
	travelLocationShort: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	office: Nullable<string>;
	projectManager: Nullable<string>;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	isProject: boolean;

	constructor(wr: WorkRequestBase) {
		this.id = wr.id;
		this.jobCode = wr.jobCode;
		this.isInternal = !!wr.isInternal;
		this.title = wr.title;
		this.customerCompany = wr.customerCompanyName ?? wr.customerContact?.contact.companyName ?? null;
		this.customerFormatted = wr.customerContact?.contact.fullName ? `${wr.customerContact.contact.fullName}${this.customerCompany ? `, ${this.customerCompany}` : ''}` : null;
		this.travelLocationShort = wr.travelLocation ? getShortAddress(wr.travelLocation) : null;
		this.office = wr.office?.nickname ?? null;
		this.city = wr.travelLocation?.locality ?? null;
		this.state = wr.travelLocation?.aa1 ?? null;
		this.projectManager = wr.projectManager?.account?.user ? UserUtils.getUserName(wr.projectManager.account.user) : null;
		this.startDate = wr.startDate;
		this.isProject = wr.isProject();
	}
}

class W_Job_FindJobForm_VM_BillingCode {
	id: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;

	constructor(billingCode: BillingCodeBase) {
		this.id = billingCode.id;
		this.lineItemNumber = billingCode.lineItemNumber;
		this.customerNumber = billingCode.customerNumber;
		this.customerId = billingCode.customerId;
		this.ownerNumber = billingCode.ownerNumber;
		this.ownerId = billingCode.ownerId;
		this.unit = billingCode.unit;
		this.unitPrice = billingCode.unitPrice;
		this.bidQuantity = billingCode.bidQuantity;
		this.group = billingCode.group;
		this.description = billingCode.description;
	}

	private static _constructorMap = (billingCode: BillingCodeBase) => new W_Job_FindJobForm_VM_BillingCode(billingCode);

	static bulkConstructor = (billingCodes: BillingCodeBase[]) => billingCodes.map(W_Job_FindJobForm_VM_BillingCode._constructorMap);
}

class W_Job_FindJobForm_VM_UserInfo {
	accountId?: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;

	constructor(account: AccountBase) {
		this.accountId = account.id ?? undefined;
		this.userId = account.user.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Job_FindJobForm_VM_WorkRequestDirectory {
	id: number;
	name: string;
	parentId: Nullable<number>;
	icon: Nullable<DirectoryIcon>;
	lastModifiedAt: Nullable<Date>;
	lastModifiedBy: Nullable<W_Job_FindJobForm_VM_UserInfo>;
	attachmentIds: number[];

	constructor(directory: DirectoryBase) {
		this.id = directory.id;
		this.name = directory.name;
		this.parentId = directory.parentId;
		this.icon = directory.icon;
		this.attachmentIds = (directory.attachmentDirectories ?? []).map((_attDir) => _attDir.attachmentId);
		this.lastModifiedAt = directory.lastModifiedAt;
		this.lastModifiedBy = directory.lastModifiedBy ? new W_Job_FindJobForm_VM_UserInfo(directory.lastModifiedBy) : null;
	}

	private static _constructorMap = (directory: DirectoryBase) => new W_Job_FindJobForm_VM_WorkRequestDirectory(directory);

	static bulkConstructor = (directories: DirectoryBase[]) => directories.map(W_Job_FindJobForm_VM_WorkRequestDirectory._constructorMap);
}

class W_Job_FindJobForm_VM_Attachment {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	copyToWorkOrderFlag: boolean;
	lastModifiedAt: Date;
	uploadedBy: Nullable<W_Job_FindJobForm_VM_UserInfo>;

	public static async initialize(attachmentDirectory: AttachmentDirectoryBase): Promise<W_Job_FindJobForm_VM_Attachment> {
		const { attachment, workRequestAttachmentDirectory } = attachmentDirectory;
		const directories = parseDirectoryPath(attachment.storageContainer);

		const attUser = attachment.uploadedBy?.user;
		const uploadedObj = attachment.uploadedBy ? new W_Job_FindJobForm_VM_UserInfo(attachment.uploadedBy) : null;

		return {
			id: attachment.id,
			size: attachment.size,
			name: attachment.name,
			type: attachment.type,
			status: attachment.status,
			storageName: attachment.storageName,
			storageContainer: attachment.storageContainer,
			lastModifiedAt: attachment.createdAt,
			originalSrc: await BlobStorageUtil.generatePresignedGetUrl(directories, attachment.storageName),
			copyToWorkOrderFlag: workRequestAttachmentDirectory?.copyToWorkOrder ?? false,
			uploadedBy: attUser ? uploadedObj : null,
		};
	}

	public static async bulkInitialize(attachmentDirectories: AttachmentDirectoryBase[]) {
		return await Promise.all(attachmentDirectories.map(W_Job_FindJobForm_VM_Attachment.initialize));
	}
}

type W_Job_FindJobForm_VM_AdditionalTrucksEquipmentField = {
	name: string;
	isChecked: boolean;
};

class W_Job_FindJobForm_VM_WorkRequestStatus {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatusBase) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

class W_Job_FindJobForm_VM_Sales {
	id: number;
	fullName: string;

	constructor(sale: SaleBase) {
		this.id = sale.id;
		this.fullName = sale.fullName;
	}
}

class W_Job_FindJobForm_VM_Office {
	id: number;
	nickname: string;

	constructor(office: LocationBase) {
		this.id = office.id;
		this.nickname = office.nickname;
	}
}

class W_Job_FindJobForm_VM_EmployeeOption {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	uniqueId: string;
	accountTemplate: AccountPermissionTemplate;
	fullName: string;

	constructor(employee: EmployeeBase) {
		const user = employee?.account?.user;

		if (!user) {
			throw new Error('Missing user property');
		}

		this.id = employee.id;
		this.formattedCode = user.uniqueId;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.uniqueId = user.uniqueId;
		this.accountTemplate = employee?.account?.accountTemplate;
		this.fullName = UserUtils.getUserName(employee.account.user);
	}
}

class W_Job_FindJobForm_VM_Division {
	id: number;
	name: string;

	constructor(division: DivisionBase) {
		this.id = division.id;
		this.name = division.name;
	}
}

class W_Job_FindJobForm_VM_DeliverableData {
	id: number;
	name: string;
	type: DeliverableDataType;

	constructor(status: DeliverableDataBase) {
		this.id = status.id;
		this.name = status.name;
		this.type = status.type;
	}
}

class W_Job_FindJobForm_VM_Contact {
	id: number;
	workOrderContactId: number;
	role: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;

	addresses: W_Job_FindJobForm_VM_ContactAddress[];
	emails: W_Job_FindJobForm_VM_ContactMethod[];
	phones: W_Job_FindJobForm_VM_ContactMethod[];

	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];

	constructor(wrc: ContactLookupBase) {
		if (!wrc) {
			return;
		}

		const _contact = wrc?.contact;

		this.id = wrc.contactId;
		this.workOrderContactId = wrc.id;
		this.role = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.addresses = W_Job_FindJobForm_VM_ContactAddress.bulkConstructor(_contact?.addresses ?? []);
		this.emails = W_Job_FindJobForm_VM_ContactMethod.bulkConstructor(_contact?.contactMethods ?? [], EmailTypes);
		this.phones = W_Job_FindJobForm_VM_ContactMethod.bulkConstructor(_contact?.contactMethods ?? [], PhoneTypes);

		this.contactAddressIds = (wrc.contactLookupAddresses ?? []).map((_wrcAdr) => _wrcAdr.contactAddressId);
		this.contactEmailIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, EmailTypesArray);
		this.contactPhoneIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, PhoneTypesArray);
	}

	static bulkConstructor = (wrcs: ContactLookupBase[]) => wrcs.map((_wrc) => new W_Job_FindJobForm_VM_Contact(_wrc));
}

class W_Job_FindJobForm_VM_ContactAddress {
	id: number;
	contactId: number;
	addressId: number;
	address: W_Job_FindJobForm_VM_Address;
	shortAddress: Nullable<string>;

	constructor(contactAddress: ContactAddressBase) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new W_Job_FindJobForm_VM_Address(contactAddress.address);
	}

	static bulkConstructor(contactAddresses: ContactAddressBase[]): W_Job_FindJobForm_VM_ContactAddress[] {
		return contactAddresses.map((_address) => new W_Job_FindJobForm_VM_ContactAddress(_address));
	}
}

class W_Job_FindJobForm_VM_ContactMethod {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static bulkConstructor(contactMethods: ContactMethodBase[], type: typeof EmailTypes | typeof PhoneTypes): W_Job_FindJobForm_VM_ContactMethod[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new W_Job_FindJobForm_VM_ContactMethod(_method));
	}
}

class W_Job_FindJobForm_VM_Address {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;

	constructor(address: AddressBase) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.city = address.locality;
		this.zip = address.postalCode;
		this.state = address.aa1;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
		this.locality = address.locality;
	}
}

class W_Job_FindJobForm_VM_Account {
	id: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
	fullName: string;

	constructor(account: AccountBase) {
		this.id = account.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.uniqueId = account.user.uniqueId;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Job_FindJobForm_VM_Employee {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	formattedCode: string;

	constructor(employee: EmployeeBase) {
		this.id = employee.id;
		this.firstName = employee.account?.user?.firstName;
		this.lastName = employee.account?.user?.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.formattedCode = employee.account?.user?.uniqueId;
	}
}
