import * as React from 'react';

import { CalculatedReportDisplayStatus, CalculatedReportDisplayStatusLabels } from '@acceligentllc/shared/enums/reportDisplayStatus';

import styles from './styles.module.scss';

interface OwnProps {
	isCondensedView: boolean;
	isOfInterest: boolean;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
}

type Props = OwnProps;

const CSS_MODIFIERS = {
	[CalculatedReportDisplayStatus.TO_DO]: 'to-do',
	[CalculatedReportDisplayStatus.IN_PROGRESS]: 'in-progress',
	[CalculatedReportDisplayStatus.SUBMITTED]: 'submitted',
	[CalculatedReportDisplayStatus.PENDING_REVIEW_SUPERINTENDENT]: 'pending-superintendent',
	[CalculatedReportDisplayStatus.PENDING_REVIEW_MANAGEMENT]: 'pending-management',
	[CalculatedReportDisplayStatus.PENDING_REVIEW_ACCOUNTING]: 'pending-accounting',
	[CalculatedReportDisplayStatus.CHANGES_REQUESTED_FIELD_WORKER]: 'changes-requested',
	[CalculatedReportDisplayStatus.CHANGES_REQUESTED_SUPERINTENDENT]: 'changes-requested',
	[CalculatedReportDisplayStatus.CHANGES_REQUESTED_MANAGEMENT]: 'changes-requested',
	[CalculatedReportDisplayStatus.CHANGES_REQUESTED_ACCOUNTING]: 'changes-requested',
	[CalculatedReportDisplayStatus.FINALIZING]: 'finalizing',
	[CalculatedReportDisplayStatus.DONE]: 'done',
};

const DisplayReviewStatusLabel: React.FC<Props> = (props: Props) => {
	const {
		isCondensedView,
		isOfInterest,
		calculatedReportDisplayStatus,
	} = props;

	if (!calculatedReportDisplayStatus) {
		return <></>;
	}

	const activeStatus = isOfInterest ? '__active' : '__inactive';
	const baseClassName = isCondensedView ? 'label-condensed-view' : 'label';
	const statusModifier = calculatedReportDisplayStatus && CSS_MODIFIERS[calculatedReportDisplayStatus];

	const expandedClassName = styles[`${baseClassName}${activeStatus}--${statusModifier}`];

	const formLabelMultipleLines = (words: string[]): string[] => {
		if (words.length === 3) {
			return [words[0] + ' ' + words[1], words[2]];
		} else if (words.length === 4) {
			return [words[0] + ' ' + words[1], words[2] + ' ' + words[3]];
		}
		return words;
	};

	const wordsOfCalculatedReportDisplayStatus = calculatedReportDisplayStatus.toLowerCase().split('_');
	const labelsToRender: string[] = isCondensedView || wordsOfCalculatedReportDisplayStatus.length < 3
		? [CalculatedReportDisplayStatusLabels[calculatedReportDisplayStatus]]
		: formLabelMultipleLines(wordsOfCalculatedReportDisplayStatus);

	return (
		<div className={`${styles[baseClassName]} ${expandedClassName}`}>
			{labelsToRender.map((label) => <div key={`label_${label}`}>{label}</div>)}
		</div >
	);
};

export default React.memo(DisplayReviewStatusLabel);
