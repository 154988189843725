import * as React from 'react';
import { useOutletContext } from 'react-router-dom-v5-compat';

import TableNew from 'af-components/Table';

import type { JobPreviewOutletContext } from './types';

const RelatedWorkOrdersTab: React.FC = () => {
	const { tableName, columns, fetch, offsetHeight, buttons, additionalFilter, ref, onRowClick } = useOutletContext<JobPreviewOutletContext>();
	return (
		<TableNew
			additionalFilter={additionalFilter}
			buttons={buttons}
			columns={columns}
			fetch={fetch}
			hasSearchInput={true}
			offsetHeight={offsetHeight}
			onRowClick={onRowClick}
			ref={ref}
			searchLabel={'Work Orders'}
			selectable={false}
			tableName={tableName}
		/>
	);
};

export default React.memo(RelatedWorkOrdersTab);
