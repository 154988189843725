import * as React from 'react';
import type { CustomRouteComponentProps, CustomLocationState } from 'react-router-dom';
import type { DispatchActionsMapped } from 'react-redux';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators, compose } from 'redux';
import type { DropResult, DragStart } from 'react-beautiful-dnd';

import type ScheduleBoardRM from '@acceligentllc/shared/dtos/socket/request/connection/scheduleBoard';

import AssignableResourceType from '@acceligentllc/shared/enums/assignableResourceType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';
import Priority from '@acceligentllc/shared/enums/priority';

import { formatDate, isDateInCorrectFormat, normalizeDateToMoment, getDaysBetweenInclusive } from '@acceligentllc/shared/utils/time';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { WORK_ORDER_FORM, ORDER_COPY_FORM } from 'af-constants/reduxForms';

import SocketEvent from 'ab-enums/socketEvent.enum';
import ScheduleBoardViewEnum from 'ab-enums/scheduleBoardView.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import { DailyViewDragElementType } from 'ab-enums/scheduleBoardDragElementType.enum';
import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import { TemplateNotificationEnum } from 'ab-enums/notifyType.enum';

import EquipmentDownModal, { EquipmentDownForm } from 'af-root/scenes/Company/ScheduleBoard/Shared/EquipmentDownModal';

import * as SettingsKeys from 'af-constants/settingsKeys';

import Loading from './Loading';
import WeeklyViewContainer from './WeeklyViewContainer';
import Header from '../Shared/Header';
import EmployeeModal from '../Shared/ResourceModals/EmployeeModal';
import EquipmentModal from '../Shared/ResourceModals/EquipmentModal';
import TemporaryEmployeeModal from '../Shared/ResourceModals/TemporaryEmployeeModal';
import OrderInfoModals from '../Shared/OrderInfo/OrderInfoModals';
import LaborModal from '../Shared/LaborModal';
import EquipmentPlaceholderModal from '../Shared/EquipmentPlaceholderModal';
import EmployeeDownModal from '../Shared/EmployeeDownModal';
import ResourceDownConfirmationModal from '../Shared/ResourceDownConfirmationModal';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as WorkOrderActions from 'af-actions/workOrder';
import * as CompanyActions from 'af-actions/companies';
import * as EquipmentActions from 'af-actions/equipment';
import * as EmployeeActions from 'af-actions/employee';
import * as GeneralActions from 'af-actions/general';
import * as NotifyActions from 'af-actions/notify';

import type { RootState } from 'af-reducers';

import type * as User from 'ab-viewModels/user.viewModel';
import type { ScheduleBoardRejoinViewModel, ScheduleBoardWorkOrdersByDateDictionary } from 'ab-viewModels/scheduleBoard.viewModel';
import type SocketConnectionCountViewModelModel from 'ab-viewModels/socketConnectionCount.viewModel';
import type { EquipmentUnavailabilityDetailsMap, EquipmentUnavailabilityDetails } from 'ab-viewModels/dailyEquipmentStatus.viewModel';
import type { EmployeeUnavailabilityDetailsMap, EmployeeUnavailabilityDetails } from 'ab-viewModels/dailyEmployeeStatus.viewModel';
import type ScheduleBoardEmployeeModalVM from 'ab-viewModels/scheduleBoardEmployeeModal.viewModel';
import type ScheduleBoardEquipmentModalVM from 'ab-viewModels/scheduleBoardEquipmentModal.viewModel';

import type ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type { WorkOrderCancelForm, WorkOrderPauseForm, WorkOrderPublishForm } from 'ab-requestModels/workOrder.requestModel';
import type WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';
import type CreateLaborPlaceholderForm from 'ab-requestModels/scheduleBoardCreateLaborPlaceholder.requestModel';
import type CreateEquipmentPlaceholderForm from 'ab-requestModels/scheduleBoardCreateEquipmentPlaceholder.requestModel';
import type DownEquipmentRM from 'ab-requestModels/equipment/downEquipment.requestModel';
import type { EquipmentDownRequestModel } from 'ab-requestModels/equipmentDown.requestModel';
import type EmployeeDownRM from 'ab-requestModels/employee/employeeDown.requestModel';
import type CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import WorkOrderNoteEditModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/WorkOrderNoteModal';

import type { ScheduleBoardModalsProps } from 'af-root/context/scheduleBoardModalContext';

import ResourceAssignConfirmationModal from 'af-components/SharedForms/ResourceAssignModal';
import withScheduleBoardModals from 'af-components/ScheduleBoardModalContextWrapper';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';
import socket from 'af-utils/socket.util';

import { isAllowed } from 'ab-utils/auth.util';

import EmployeeWorkOrderHistoryModal from '../Shared/ResourceModals/EmployeeWorkOrderHistoryModal';
import EquipmentWorkOrderHistoryModal from '../Shared/ResourceModals/EquipmentWorkOrderHistoryModal';
import { withSettings } from 'af-utils/settings.util';

type MomentType = ReturnType<typeof normalizeDateToMoment>;

type OnSubmitCreateAssignmentMapType = {
	[T in keyof typeof AssignableResourceType]: (
		dueDate: string,
		workOrderId: number,
		resource: ScheduleBoardEmployeeModalVM | ScheduleBoardEquipmentModalVM
	) => () => void;
};

type OnCloseCreateAssignmentMapType = {
	[T in keyof typeof AssignableResourceType]: (resource: ScheduleBoardEmployeeModalVM | ScheduleBoardEquipmentModalVM, date: string) => () => void;
};

interface LocationStateProps extends CustomLocationState {
	lastOpenedOrderCode: string;
}
interface StateProps {
	zoomLevel: number;
	userData: User.UserData;
	companyData: User.CompanyData;
	startDate: string | null;
	endDate: string | null;
	dates: string[];
	isFilterApplied: boolean;
	copiedEmployeeId: Nullable<number>;
	copiedEquipmentId: Nullable<number>;
	copiedPlaceholderId: Nullable<number>;
	copiedTemporaryEmployeeId: Nullable<number>;
	copiedWorkOrderCode: Nullable<string>;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	loadResources: boolean;
	isBoardLoading: boolean;
	employeeUnavailabilityDetailsByDate: { [date: string]: EmployeeUnavailabilityDetailsMap; };
	equipmentUnavailabilityDetailsByDate: { [date: string]: EquipmentUnavailabilityDetailsMap; };
	workOrders: ScheduleBoardWorkOrdersByDateDictionary;
	initialStartDate: Nullable<string>;
	initialEndDate: Nullable<string>;
}

interface SettingsProps {
	_startDate: MomentType;
	_endDate: MomentType;
}

interface DispatchProps {
	scheduleBoardActions: typeof ScheduleBoardActions;
	generalActions: typeof GeneralActions;
	workOrderActions: typeof WorkOrderActions;
	companyActions: typeof CompanyActions;
	notifyActions: typeof NotifyActions;
	equipmentActions: typeof EquipmentActions;
	employeeActions: typeof EmployeeActions;
}

type OwnProps = CustomRouteComponentProps<void, Record<string, unknown>, LocationStateProps>;
type ConnectOwnProps = SettingsProps & OwnProps;
type Props = ConnectOwnProps & StateProps & DispatchActionsMapped<DispatchProps> & SettingsProps & ScheduleBoardModalsProps;

interface State {
	showEquipmentDownFormModal: boolean;
	showEmployeeDownFormModal: boolean;
	showResourceDownConfirmationModal: boolean;
	dragElement: Nullable<ScheduleBoardDragRequestModel>;
	downConfirmationType: Nullable<UnavailabilityReasonScope>;
	equipmentForUpdate: Nullable<EquipmentDownRequestModel>;
	employeeForUpdate: Nullable<EmployeeDownRM>;
	/** State used for Create Resource Assignment Modal */
	showResourceAssignConfirmationModal: boolean;
	resourceAssignModalType: Nullable<AssignableResourceType>;
	resourceAssignModalOnSubmit: Nullable<() => void>;
	resourceAssignModalOnClose: Nullable<() => void>;
}

class WeeklyView extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		dates: [],
	};

	state: State = {
		showEquipmentDownFormModal: false,
		showEmployeeDownFormModal: false,
		showResourceDownConfirmationModal: false,
		showResourceAssignConfirmationModal: false,
		dragElement: null,
		downConfirmationType: null,
		equipmentForUpdate: null,
		employeeForUpdate: null,
		resourceAssignModalType: null,
		resourceAssignModalOnSubmit: null,
		resourceAssignModalOnClose: null,
	};

	CREATE_ASSIGNMENT_ON_SUBMIT_MAP: Partial<OnSubmitCreateAssignmentMapType> = {
		[AssignableResourceType.EMPLOYEE]: (dueDate: string, workOrderId: number, resource: ScheduleBoardEmployeeModalVM) => {
			return async () => {
				await WeeklyView.createEmployeeAssignment(dueDate, workOrderId, resource.id);
				this.closeResourceAssignModal();
				this.props.scheduleBoardModals.setEmployeeModalData(resource, dueDate);
			};
		},
		[AssignableResourceType.EQUIPMENT]: (dueDate: string, workOrderId: number, resource: ScheduleBoardEquipmentModalVM) => {
			return async () => {
				await WeeklyView.createEquipmentAssignment(dueDate, workOrderId, resource.id);
				this.closeResourceAssignModal();
				this.props.scheduleBoardModals.setEquipmentModalData(resource, dueDate);
			};
		},
	};

	CREATE_ASSIGNMENT_ON_CLOSE_MAP: Partial<OnCloseCreateAssignmentMapType> = {
		[AssignableResourceType.EMPLOYEE]: (resource: ScheduleBoardEmployeeModalVM, date: string) => {
			return () => {
				this.closeResourceAssignModal();
				this.props.scheduleBoardModals.setEmployeeModalData(resource, date);
			};
		},
		[AssignableResourceType.EQUIPMENT]: (resource: ScheduleBoardEquipmentModalVM, date: string) => {
			return () => {
				this.closeResourceAssignModal();
				this.props.scheduleBoardModals.setEquipmentModalData(resource, date);
			};
		},
	};

	static forceUnlockOrder = (dueDate: string, workOrderId: string) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.FORCE_UNLOCK_WORK_ORDER, { workOrderId: +workOrderId, dueDate });
	};

	static createEmployeeAssignment = async (dueDate: string, workOrderId: number, employeeId: number, dragElement?: ScheduleBoardDragRequestModel) => {
		await socket.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EMPLOYEE_ASSIGNMENT,
			null,
			{ dueDate, workOrderId, employeeId, dragElement }
		);
	};

	static removeEmployeeAssignment = (dueDate: string, workOrderId: number, workOrderCode: string, employeeId: number) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_EMPLOYEE_ASSIGNMENT, { dueDate, workOrderId, workOrderCode, employeeId });
	};

	static createPlaceholderAssignment = (
		dueDate: string,
		workOrderId: number,
		workOrderPlaceholderId: number,
		dragElement?: ScheduleBoardDragRequestModel
	) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_PLACEHOLDER_ASSIGNMENT, { dueDate, workOrderId, workOrderPlaceholderId, dragElement });
	};

	static createEquipmentAssignment = async (dueDate: string, workOrderId: number, equipmentId: number, dragElement?: ScheduleBoardDragRequestModel) => {
		await socket.connection?.emitWithPromise(
			SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT,
			null,
			{ dueDate, workOrderId, equipmentId, dragElement }
		);
	};

	static removeEquipmentAssignment = (dueDate: string, workOrderId: number, workOrderCode: string, equipmentId: number) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT, { dueDate, workOrderId, workOrderCode, equipmentId });
	};

	static updateWorkOrderEmployeePerDiem = (workOrderId: number, employeeId: number, perDiem: boolean, dueDate: string) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM, { workOrderId, employeeId, perDiem, dueDate });
	};

	static resetTimer = () => {
		socket.connection?.resetTimeout();
	};

	static createLaborPlaceholder = (data: CreateLaborPlaceholderForm) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_LABOR_PLACEHOLDER, data);
	};

	static createEquipmentPlaceholder = (data: CreateEquipmentPlaceholderForm) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CREATE_EQUIPMENT_PLACEHOLDER, data);
	};

	static emitEquipmentDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const emptyDownData = {
			returnDate: null,
			currentDate: null,
			downNotes: null,
			priority: null,
			statusId: null,
			unavailabilityReason: null,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_END, { ...emptyDownData, ...dragElement });
	};

	static emitEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EMPLOYEE_DRAG_END, dragElement);
	};

	static emitTemporaryEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_END, dragElement);
	};

	async componentDidMount() {
		const { scheduleBoardActions, companyActions, startDate, endDate, loadResources, generalActions, initialStartDate, initialEndDate } = this.props;
		const loadedDates = scheduleBoardActions.getLoadedDates();

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WEEKLY_VIEW_BOARD_REJOIN, (data: ScheduleBoardRejoinViewModel) => {
			scheduleBoardActions.getScheduleBoardRejoin(data);
			scheduleBoardActions.clearFilters();
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RESOURCES, scheduleBoardActions.getScheduleBoardResources);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD_RESOURCES, ScheduleBoardUtil.loadResources);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD, this.reloadBoard);

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.SYNC_LABOR_STATISTICS, () => {
			scheduleBoardActions.setLaborStatistics(Object.keys(scheduleBoardActions.getLoadedDates()));
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WEEKLY_VIEW_CONNECTIONS_COUNT,
			(dueDate: string, data: SocketConnectionCountViewModelModel) => {
				const { dates } = this.props;

				if (dates.includes(dueDate)) {
					scheduleBoardActions.getSocketConnectionCount(data);
				}
			}
		);

		ScheduleBoardUtil.subscribeSharedSocketEvents(generalActions, scheduleBoardActions);

		// NOTE: In all cases that I tested, startDate and endDate are always null
		// loadedDates, however, has information that is relevant when coming from daily view
		ScheduleBoardUtil.activateWeeklyView(startDate, endDate, loadedDates);
		scheduleBoardActions.setScheduleBoardView(ScheduleBoardViewEnum.WEEKLY_VIEW);
		await companyActions.getCompany();

		if (loadResources) {
			ScheduleBoardUtil.loadResources();
		}
		ScheduleBoardUtil.joinWeeklyView(startDate, endDate, scheduleBoardActions.getLoadedDates());

		// TODO in future rework add this to other parts of weekly view
		// Use initial dates since they are available at mount
		scheduleBoardActions.addLatestPublishedRevisionsForDueDates(getDaysBetweenInclusive(initialStartDate, initialEndDate, TimeFormat.DATE_ONLY));
	}

	componentDidUpdate(prevProps: Props) {
		const { startDate, endDate, loadResources, scheduleBoardActions, isBoardLoading } = this.props;
		const loadedDates = scheduleBoardActions.getLoadedDates();

		if (
			startDate && endDate &&
			(prevProps?.startDate !== startDate || prevProps?.endDate !== endDate) &&
			socket.connection?.isConnected()
		) {
			ScheduleBoardUtil.leaveBoard();
			if (loadResources) {
				ScheduleBoardUtil.loadResources();
			}
			if (isBoardLoading) { // Load only when loading dates for the first time so we don't spam the server
				scheduleBoardActions.addLatestPublishedRevisionsForDueDates(getDaysBetweenInclusive(startDate, endDate, TimeFormat.DATE_ONLY));
			}
			ScheduleBoardUtil.joinWeeklyView(startDate, endDate, loadedDates);
		}
	}

	componentWillUnmount() {
		const { scheduleBoardActions } = this.props;
		scheduleBoardActions.setWeeklyViewHorizontalScrollingPercentage(0);
		if (socket.connection) {
			ScheduleBoardUtil.leaveBoard();
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WEEKLY_VIEW_CONNECTIONS_COUNT);
			socket.connection.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD);
		}
	}

	reloadBoard = () => {
		const { startDate, endDate } = this.props;
		if (!startDate || !endDate) {
			throw new Error('Start and end date required for weekly view');
		}
		ScheduleBoardUtil.joinWeeklyView(startDate, endDate, {});
	};

	activateWeeklyView = (startDate: string, endDate: string, loadedDates: { [T: string]: boolean; }) => {
		const fullBoardRequest: ScheduleBoardRM = {
			startDate,
			endDate,
			type: SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_WEEKLY_VIEW,
			loadedDates,
		};
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_WEEKLY_VIEW, fullBoardRequest);
	};

	addBlankWorkOrder = async (dueDate: string, index: number) => {
		const { scheduleBoardActions } = this.props;
		await scheduleBoardActions.addBlankWorkOrder(dueDate, index);
	};

	removeBlankWorkOrder = async (dueDate: string, index: number) => {
		const { scheduleBoardActions } = this.props;
		await scheduleBoardActions.removeBlankWorkOrder(dueDate, index);
	};

	deleteOrder = async (workOrderId: string) => {
		const { workOrderActions } = this.props;

		await workOrderActions.deleteWorkOrder(+workOrderId);
	};

	getWeeklyView = () => {
		const { scheduleBoardActions, startDate, endDate } = this.props;
		if (!startDate || !endDate) {
			throw new Error('Start and end date required for weekly view');
		}
		scheduleBoardActions.getWeeklyView(startDate, endDate, []);
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_WEEKLY_VIEW, { startDate, endDate });
	};

	cancelOrder = async (workOrderId: string, cancelForm: WorkOrderCancelForm) => {
		const { workOrderActions } = this.props;
		await workOrderActions.cancelWorkOrder(+workOrderId, cancelForm);
	};

	pauseOrder = async (workOrderId: string, form: WorkOrderPauseForm) => {
		const { workOrderActions } = this.props;
		await workOrderActions.pauseWorkOrder(+workOrderId, form);
	};

	resumeOrder = async (workOrderId: string) => {
		const { workOrderActions } = this.props;
		await workOrderActions.resumeWorkOrder(+workOrderId);
	};

	publishOrder = async (workOrderId: string, workOrderPublishForm: WorkOrderPublishForm) => {
		const { workOrderActions } = this.props;
		await workOrderActions.publishWorkOrder(+workOrderId, workOrderPublishForm?.delayReason);
	};

	generatePreview = async (workOrderId: string) => {
		const { location: { state: { orgAlias } }, companyData: { name: companyName }, workOrderActions } = this.props;
		await workOrderActions.generateWorkOrderConfirmation(workOrderId, orgAlias, companyName);
	};

	onDragStart = (dragData: DragStart) => {
		const { scheduleBoardActions } = this.props;

		WeeklyView.resetTimer();

		const { dragElementType, dragElement } = scheduleBoardActions.onDragStart(null, dragData);
		if (!dragElement) {
			return;
		}
		switch (dragElementType) {
			case DailyViewDragElementType.WORK_ORDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.WORK_ORDER_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
			case DailyViewDragElementType.EMPLOYEE:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EMPLOYEE_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
			case DailyViewDragElementType.EQUIPMENT:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.EQUIPMENT_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_START, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_START, dragElement);
				return;
			default:
				// this should never happen
				return;
		}
	};

	onDragEnd = (result: DropResult) => {
		const {
			scheduleBoardActions,
			copiedWorkOrderCode,
			workOrderActions,
			copiedEmployeeId,
			copiedEquipmentId,
			copiedPlaceholderId,
			copiedTemporaryEmployeeId,
		} = this.props;
		const isCopying = !!copiedEmployeeId || !!copiedEquipmentId || !!copiedPlaceholderId || !!copiedWorkOrderCode || !!copiedTemporaryEmployeeId;

		WeeklyView.resetTimer();

		const updateOnDropResource = {
			dragElementType: ScheduleBoardUtil.updateOnDropResourceElementTypeCondition,
			dragElement: ScheduleBoardUtil.updateOnDropResourceElementCondition,
		};

		const {
			dragElementType,
			dragElement,
			sourceDueDate,
			destinationDueDate,
			copiedWorkOrderId,
			destinationWorkOrderId,
			isRealCopy,
		} = scheduleBoardActions.onDragEnd(null, result, isCopying, copiedWorkOrderCode, true, updateOnDropResource);
		if (!dragElement) {
			return;
		}

		if (isRealCopy) {
			switch (dragElementType) {
				case DailyViewDragElementType.WORK_ORDER:
					if (!destinationDueDate || !copiedWorkOrderId || dragElement.destinationIndex === undefined) {
						throw new Error('Destination not provided');
					}

					// All weekdays allowed since we're directly copying it
					const copyForm: WorkOrderCopyRM = {
						monday: true,
						tuesday: true,
						wednesday: true,
						thursday: true,
						friday: true,
						saturday: true,
						sunday: true,
						startDate: formatDate(destinationDueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
						intervalSelection: false,
						orderId: copiedWorkOrderId,
						endDate: formatDate(destinationDueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
						copyDate: formatDate(sourceDueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
						index: dragElement.destinationIndex + 1,
						shouldPreserveIndex: false,
						shouldCopyAttachments: false,
					};
					// use then so that the drag end emit isn't blocked
					workOrderActions.copyWorkOrder(copiedWorkOrderId, copyForm);
					break;
				case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
					if (!destinationDueDate || !destinationWorkOrderId || !copiedEmployeeId) {
						throw new Error('Destination not provided');
					}
					WeeklyView.createEmployeeAssignment(destinationDueDate, destinationWorkOrderId, copiedEmployeeId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
					if (!destinationDueDate || !destinationWorkOrderId || !copiedEquipmentId) {
						throw new Error('Destination not provided');
					}
					WeeklyView.createEquipmentAssignment(destinationDueDate, destinationWorkOrderId, copiedEquipmentId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
					if (!destinationDueDate || !destinationWorkOrderId || !copiedPlaceholderId) {
						throw new Error('Destination not provided');
					}
					WeeklyView.createPlaceholderAssignment(destinationDueDate, destinationWorkOrderId, copiedPlaceholderId, dragElement);
					break;
				case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
					if (!destinationWorkOrderId || !copiedTemporaryEmployeeId) {
						throw new Error('Destination not provided');
					}
					this.createTemporaryEmployeeAssignment(destinationWorkOrderId, {
						index: dragElement.destinationIndex,
						temporaryEmployeeId: copiedTemporaryEmployeeId,
					});
					break;
				default:
				// this should never happen
			}
		}

		switch (dragElementType) {
			case DailyViewDragElementType.WORK_ORDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.WORK_ORDER_DRAG_END, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EMPLOYEE:
			case DailyViewDragElementType.EMPLOYEE:
				this.onEmployeeDragEnd(dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_EQUIPMENT:
			case DailyViewDragElementType.EQUIPMENT:
				this.onEquipmentDragEnd(dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_PLACEHOLDER:
				socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_END, dragElement);
				return;
			case DailyViewDragElementType.WORK_ORDER_TEMPORARY_EMPLOYEE:
				this.onTemporaryEmployeeDragEnd(dragElement);
				return;
			default:
				// this should never happen
				return;
		}
	};

	onEquipmentDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { equipmentUnavailabilityDetailsByDate } = this.props;
		const { destinationDroppableId, sourceDroppableId, itemId, dueDate } = dragElement;

		const shouldOpenConfirmationModal = !!destinationDroppableId
			? ScheduleBoardUtil.shouldOpenResourceDownConfirmationModal(sourceDroppableId, destinationDroppableId)
			: false;
		const shouldOpenFormModal = !!destinationDroppableId && ScheduleBoardUtil.shouldOpenResourceDownFormModal(destinationDroppableId);
		const shouldOpenResourceAvailableWarningModal = !!destinationDroppableId
			? ScheduleBoardUtil.shouldOpenResourceAvailableWarningModal(sourceDroppableId, destinationDroppableId)
			: false;

		if (shouldOpenConfirmationModal) {
			this.setState(() => ({ showResourceDownConfirmationModal: true, dragElement, downConfirmationType: UnavailabilityReasonScope.EQUIPMENT }));
		} else if (shouldOpenFormModal) {
			const details: EquipmentUnavailabilityDetails = equipmentUnavailabilityDetailsByDate?.[dueDate]?.[itemId];
			if (details) {
				const toolbarStatusCode = ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationDroppableId);
				if (!toolbarStatusCode) {
					throw new Error('Unavailable status must have an ID');
				}

				const equipmentForUpdate = {
					downNotes: details.notes ?? null,
					returnDate: (details.returnDate && formatDate(details.returnDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)) ?? null,
					currentDate: formatDate(new Date(), TimeFormat.DATE_ONLY),
					id: Number(itemId),
					priority: details.priority ?? Priority.MEDIUM,
					statusId: +toolbarStatusCode,
					unavailabilityReason: details.unavailabilityReason ?? null,
				};
				this.setState(() => ({ showEquipmentDownFormModal: true, dragElement, equipmentForUpdate }));
			} else {
				this.setState(() => ({ showEquipmentDownFormModal: true, dragElement }));
			}
		} else if (shouldOpenResourceAvailableWarningModal) {
			this.openResourceAssignModal(
				AssignableResourceType.EQUIPMENT,
				this.onConfirmResourceAssignConfirmationModal,
				this.onCloseResourceAssignConfirmationModal,
				dragElement
			);
		} else {
			WeeklyView.emitEquipmentDragEnd(dragElement);
		}
	};

	onEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { employeeUnavailabilityDetailsByDate } = this.props;
		const { destinationDroppableId, sourceDroppableId, itemId, dueDate } = dragElement;

		const shouldOpenConfirmationModal = !!destinationDroppableId
			? ScheduleBoardUtil.shouldOpenResourceDownConfirmationModal(sourceDroppableId, destinationDroppableId)
			: false;
		const shouldOpenFormModal = destinationDroppableId && ScheduleBoardUtil.shouldOpenResourceDownFormModal(destinationDroppableId);
		const shouldOpenResourceAvailableWarningModal = !!destinationDroppableId
			? ScheduleBoardUtil.shouldOpenResourceAvailableWarningModal(sourceDroppableId, destinationDroppableId)
			: false;

		if (shouldOpenConfirmationModal) {
			this.setState(() => ({ showResourceDownConfirmationModal: true, dragElement, downConfirmationType: UnavailabilityReasonScope.EMPLOYEE }));
		} else if (shouldOpenFormModal) {
			const details: Nullable<EmployeeUnavailabilityDetails> = employeeUnavailabilityDetailsByDate?.[dueDate]?.[itemId];
			if (details) {
				const _unavailableStatusId = destinationDroppableId && ScheduleBoardUtil.getToolbarCodeFromUniqueCode(destinationDroppableId);
				if (!_unavailableStatusId) {
					throw new Error('Cannot set employee as unavailable');
				}
				if (!isDateInCorrectFormat(dragElement.dueDate, TimeFormat.DATE_ONLY)) {
					throw new Error('Due date in incorrect format');
				}
				const employeeForUpdate: EmployeeDownRM = {
					id: Number(itemId),
					unavailableStatusId: +_unavailableStatusId,
					dueDate: dragElement.dueDate,
					unavailabilityReason: { label: details.unavailabilityReason },
					returnDate: details.returnDate && formatDate(details.returnDate, TimeFormat.DATE_ONLY),
					currentDate: formatDate(new Date(), TimeFormat.DATE_ONLY)!,
				};
				this.setState(() => ({ showEmployeeDownFormModal: true, dragElement, employeeForUpdate }));
			} else {
				this.setState(() => ({ showEmployeeDownFormModal: true, dragElement }));
			}
		} else if (shouldOpenResourceAvailableWarningModal) {
			this.openResourceAssignModal(
				AssignableResourceType.EMPLOYEE,
				this.onConfirmResourceAssignConfirmationModal,
				this.onCloseResourceAssignConfirmationModal,
				dragElement
			);
		} else {
			WeeklyView.emitEmployeeDragEnd(dragElement);
		}
	};

	onTemporaryEmployeeDragEnd = (dragElement: ScheduleBoardDragRequestModel) => {
		const { destinationDroppableId, sourceDroppableId, originalItemId } = dragElement;

		if (!destinationDroppableId) {
			return;
		}
		if (ScheduleBoardUtil.isOnBoard(destinationDroppableId) || ScheduleBoardUtil.isInToolbar(destinationDroppableId)) {
			WeeklyView.emitTemporaryEmployeeDragEnd(dragElement);
			return;
		}
		const { workOrders } = this.props;
		const workOrderCode = ScheduleBoardUtil.getUniqueCodeFromDroppableId(sourceDroppableId);

		const workOrderId: number = workOrderCode && workOrders?.[dragElement.dueDate][workOrderCode]?.id;
		if (!workOrderId) {
			return;
		}
		this.removeTemporaryEmployeeAssignment(workOrderId, +originalItemId);
		WeeklyView.emitTemporaryEmployeeDragEnd(dragElement);
	};

	closeResourceDownConfirmationAction = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			return;
		}

		scheduleBoardActions.onEquipmentDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		WeeklyView.emitEquipmentDragEnd(socketEvent);
		this.setState(() => ({ showResourceDownConfirmationModal: false, dragElement: null, downConfirmationType: null }));
	};

	confirmResourceDownAction = () => {
		const { downConfirmationType } = this.state;
		if (downConfirmationType === UnavailabilityReasonScope.EQUIPMENT) {
			this.setState(() => ({
				showResourceDownConfirmationModal: false,
				showEquipmentDownFormModal: true,
				downConfirmationType: null,
			}));
		} else {
			this.setState(() => ({
				showResourceDownConfirmationModal: false,
				showEmployeeDownFormModal: true,
				downConfirmationType: null,
			}));
		}
	};

	submitEquipmentDownFormModal = async (form: DownEquipmentRM) => {
		const { scheduleBoardActions, equipmentActions } = this.props;
		const { dragElement } = this.state;

		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		const isNotEdit = !form.unavailableStatusId;
		const newStatusId = dragElement.destinationDroppableId && ScheduleBoardUtil.getToolbarCodeFromUniqueCode(dragElement.destinationDroppableId);
		if (!newStatusId) {
			throw new Error('Unavailable status must have an ID');
		}
		const shouldRunOnDragEndConfirmation = form?.unavailableStatusId !== +newStatusId;

		if (isNotEdit) {
			form.unavailableStatusId = +newStatusId;
		}

		if (!isDateInCorrectFormat(dragElement.dueDate, TimeFormat.DATE_ONLY)) {
			throw new Error('Due date in incorrect format');
		}
		form.dueDate = dragElement.dueDate;
		form.currentDate = formatDate(new Date(), TimeFormat.DATE_ONLY);
		if (dragElement.sourceMetadata?.id) {
			form.workOrderId = dragElement.sourceMetadata.id;
		}

		await equipmentActions.downEquipment(+dragElement.originalItemId, form);
		// skip if source and destination are the same
		if (shouldRunOnDragEndConfirmation) {
			scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		}
		this.setState(() => ({ showEquipmentDownFormModal: false, dragElement: null, equipmentForUpdate: null }));
	};

	submitEmployeeDownFormModal = async (form: EmployeeDownRM) => {
		const { scheduleBoardActions, employeeActions } = this.props;
		const { dragElement } = this.state;

		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		const isNotEdit = !form.unavailableStatusId;
		const newStatusId = dragElement.destinationDroppableId && ScheduleBoardUtil.getToolbarCodeFromUniqueCode(dragElement.destinationDroppableId);
		if (!newStatusId) {
			throw new Error('Unavailable status must have an ID');
		}
		const shouldRunOnDragEndConfirmation = form?.unavailableStatusId !== +newStatusId;

		if (isNotEdit) {
			form.unavailableStatusId = +newStatusId;
		}

		if (!isDateInCorrectFormat(dragElement.dueDate, TimeFormat.DATE_ONLY)) {
			throw new Error('Due date in incorrect format');
		}
		form.dueDate = dragElement.dueDate;
		form.currentDate = formatDate(new Date(), TimeFormat.DATE_ONLY);
		if (dragElement.sourceMetadata?.id) {
			form.workOrderId = dragElement.sourceMetadata.id;
		}

		await employeeActions.downEmployee(+dragElement.originalItemId, form);
		// skip if source and destination are the same
		if (shouldRunOnDragEndConfirmation) {
			scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		}
		this.setState(() => ({ showEmployeeDownFormModal: false, dragElement: null, employeeForUpdate: null }));
	};

	closeEquipmentDownModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		scheduleBoardActions.onEquipmentDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		WeeklyView.emitEquipmentDragEnd(socketEvent);
		this.setState(() => ({ showEquipmentDownFormModal: false, dragElement: null, equipmentForUpdate: null }));
	};

	closeEmployeeDownModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		scheduleBoardActions.onEmployeeDragEndCancellation(dragElement);

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};
		WeeklyView.emitEmployeeDragEnd(socketEvent);
		this.setState(() => ({ showEmployeeDownFormModal: false, dragElement: null, employeeForUpdate: null }));
	};

	openResourceAssignModal = (
		type: AssignableResourceType,
		onSubmit: () => void,
		onClose: () => void,
		dragElement?: ScheduleBoardDragRequestModel
	) => {
		this.setState(() => ({
			showResourceAssignConfirmationModal: true,
			resourceAssignModalType: type,
			resourceAssignModalOnSubmit: onSubmit,
			resourceAssignModalOnClose: onClose,
			dragElement: dragElement ? dragElement : this.state.dragElement,
		}));
	};

	closeResourceAssignModal = () => {
		this.setState(() => ({
			showResourceAssignConfirmationModal: false,
			resourceAssignModalType: null,
			resourceAssignModalOnSubmit: null,
			resourceAssignModalOnClose: null,
			dragElement: null,
		}));
	};

	sendNotification = async (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => {
		const { notifyActions } = this.props;
		await notifyActions.sendTemplateNotificationMultipleWO({
			dueDate: formatDate(dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
			employeeEmailIds: notifyByEmail,
			employeeSmsIds: notifyBySms,
			notificationType: TemplateNotificationEnum.AVAILABLE_EMPLOYEE,
		});
	};

	scheduleAutoNotify = async (dueDate: string, notifyByEmail: number[], notifyBySms: number[]) => {
		const { notifyActions } = this.props;
		await notifyActions.scheduleForAutoNotify({
			dueDate,
			employeeEmailIds: notifyByEmail,
			employeeSmsIds: notifyBySms,
			notificationType: TemplateNotificationEnum.AVAILABLE_EMPLOYEE,
		});
	};

	// Methods used for Submit/Close in Create Resource Assignment Modal for SB stuff
	onConfirmResourceAssignConfirmationModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		if (ScheduleBoardUtil.isEmployee(dragElement.sourceDroppableId)) {
			WeeklyView.emitEmployeeDragEnd(dragElement);
		} else if (ScheduleBoardUtil.isEquipment(dragElement.sourceDroppableId)) {
			WeeklyView.emitEquipmentDragEnd(dragElement);
		}
		scheduleBoardActions.onResourceDragEndConfirmation(dragElement);
		this.closeResourceAssignModal();
	};

	onCloseResourceAssignConfirmationModal = () => {
		const { scheduleBoardActions } = this.props;
		const { dragElement } = this.state;
		if (!dragElement) {
			throw new Error('Dragged element not defined');
		}

		const socketEvent: ScheduleBoardDragRequestModel = {
			...dragElement,
			destinationIndex: dragElement.sourceIndex,
			destinationDroppableId: dragElement.sourceDroppableId,
		};

		if (dragElement.destinationDroppableId && ScheduleBoardUtil.isEmployee(dragElement.destinationDroppableId)) {
			WeeklyView.emitEmployeeDragEnd(socketEvent);
		} else if (dragElement.destinationDroppableId && ScheduleBoardUtil.isEquipment(dragElement.destinationDroppableId)) {
			WeeklyView.emitEquipmentDragEnd(socketEvent);
		}

		const destinationUniqueCode = dragElement.destinationDroppableId && ScheduleBoardUtil.getUniqueCodeFromDroppableId(dragElement.destinationDroppableId);
		const destinationDueDate = dragElement.destinationDroppableId && ScheduleBoardUtil.getDueDateFromDroppableId(dragElement.destinationDroppableId);

		if (destinationDueDate && destinationUniqueCode) {
			scheduleBoardActions.setCopiedResourcePlaceholder(destinationDueDate, destinationUniqueCode, undefined, true);
		}
		this.closeResourceAssignModal();
	};

	// Methods used for Submit/Close in Create Resource Assignment Modal for Resource Modals
	createAssignment = (
		type: AssignableResourceType,
		date: string,
		workOrderId: number,
		isAvailable: boolean,
		resource: ScheduleBoardEmployeeModalVM | ScheduleBoardEquipmentModalVM
	) => {
		const createAssignment = this.CREATE_ASSIGNMENT_ON_SUBMIT_MAP[type]?.(date, workOrderId, resource);
		if (!createAssignment) {
			throw new Error('Create assignment action not defined');
		}

		if (!isAvailable) {
			// close modal
			if (type === AssignableResourceType.EMPLOYEE) {
				this.props.scheduleBoardModals.setEmployeeModalData(null, null);
				this.props.scheduleBoardModals.setEmployeeModalVisibility(false);
			} else if (type === AssignableResourceType.EQUIPMENT) {
				this.props.scheduleBoardModals.setEquipmentModalData(null, null);
				this.props.scheduleBoardModals.setEquipmentModalVisibility(false);
			}

			const closeModal = this.CREATE_ASSIGNMENT_ON_CLOSE_MAP[type]?.(resource, date);

			if (!closeModal) {
				throw new Error('Close modal action not defined');
			}

			this.openResourceAssignModal(type, createAssignment, closeModal);
			return false;
		}
		createAssignment();
		return true;
	};

	createTemporaryEmployeeAssignment = (workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) => {
		const { workOrderActions } = this.props;
		workOrderActions.createTemporaryEmployeeAssignment(workOrderId, data);
	};

	removeTemporaryEmployeeAssignment = (workOrderId: number, workOrderResourceLookupId: number) => {
		const { workOrderActions } = this.props;
		workOrderActions.removeTemporaryEmployeeAssignment(workOrderId, workOrderResourceLookupId);
	};

	render() {
		const {
			dates,
			startDate,
			endDate,
			zoomLevel,
			companyData: { name: companyName },
			location: { state: { orgAlias }, search },
			isFilterApplied,
			isDragAndDropDisabled,
			hasPermissionsToEditScheduleBoard,
			hasPermissionsToSendNotifications,
			history,
			location,
			isBoardLoading,
		} = this.props;
		const {
			showEquipmentDownFormModal,
			showEmployeeDownFormModal,
			showResourceDownConfirmationModal,
			showResourceAssignConfirmationModal,
			dragElement,
			downConfirmationType,
			employeeForUpdate,
			equipmentForUpdate,
			resourceAssignModalType,
			resourceAssignModalOnClose,
			resourceAssignModalOnSubmit,
		} = this.state;

		const lastOpenedOrderCode = ScheduleBoardUtil.parseQueryString(search).order;

		return (
			<div className={`schedule-board zoom-${zoomLevel} ${isFilterApplied ? '--filtered-results' : ''}`}>
				<Header
					companyName={companyName}
					isBoardLoading={isBoardLoading}
					orgAlias={orgAlias}
				/>
				{
					isBoardLoading ?
						<Loading endDate={endDate} startDate={startDate} /> :
						<div className="schedule-board-container">
							<WeeklyViewContainer
								addBlankWorkOrder={this.addBlankWorkOrder}
								companyName={companyName}
								dates={dates}
								forceUnlockOrder={WeeklyView.forceUnlockOrder}
								hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
								hasPermissionsToSendNotifications={hasPermissionsToSendNotifications}
								history={history}
								isDragAndDropDisabled={isDragAndDropDisabled}
								lastOpenedOrderCode={lastOpenedOrderCode}
								onDragEnd={this.onDragEnd}
								onDragStart={this.onDragStart}
								orgAlias={orgAlias}
								removeBlankWorkOrder={this.removeBlankWorkOrder}
								scheduleAutoNotify={this.scheduleAutoNotify}
								sendNotification={this.sendNotification}
							/>
						</div>
				}
				<EmployeeModal
					createEmployeeAssignment={this.createAssignment}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					removeEmployeeAssignment={WeeklyView.removeEmployeeAssignment}
					updateWorkOrderEmployeePerDiem={WeeklyView.updateWorkOrderEmployeePerDiem}
				/>
				<EquipmentModal
					createEquipmentAssignment={this.createAssignment}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					removeEquipmentAssignment={WeeklyView.removeEquipmentAssignment}
				/>
				<TemporaryEmployeeModal
					createAssignment={this.createTemporaryEmployeeAssignment}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					removeAssignment={this.removeTemporaryEmployeeAssignment}
				/>
				<LaborModal
					onCreateAssignment={this.createTemporaryEmployeeAssignment}
					onCreatePlaceholder={WeeklyView.createLaborPlaceholder}
					resetTimer={WeeklyView.resetTimer}
				/>
				<EquipmentPlaceholderModal
					onSubmit={WeeklyView.createEquipmentPlaceholder}
					resetTimer={WeeklyView.resetTimer}
				/>
				<ResourceDownConfirmationModal
					dueDate={dragElement?.dueDate}
					onClose={this.closeResourceDownConfirmationAction}
					onSubmit={this.confirmResourceDownAction}
					showModal={showResourceDownConfirmationModal}
					type={downConfirmationType}
				/>
				<EquipmentDownModal
					initialFormValues={EquipmentDownForm.toForm(equipmentForUpdate)}
					onClose={this.closeEquipmentDownModal}
					onSubmit={this.submitEquipmentDownFormModal}
					showModal={showEquipmentDownFormModal}
					showStatusOption={!!equipmentForUpdate}
				/>
				<EmployeeDownModal
					initialFormValues={employeeForUpdate}
					onClose={this.closeEmployeeDownModal}
					onSubmit={this.submitEmployeeDownFormModal}
					showModal={showEmployeeDownFormModal}
					showStatusOption={!!employeeForUpdate}
				/>
				<OrderInfoModals
					cancelOrder={this.cancelOrder}
					companyName={companyName}
					deleteOrder={this.deleteOrder}
					generatePreview={this.generatePreview}
					hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
					history={history}
					location={location}
					orgAlias={orgAlias}
					pauseOrder={this.pauseOrder}
					publishOrder={this.publishOrder}
					resumeOrder={this.resumeOrder}
				/>
				<WorkOrderNoteEditModal />
				{resourceAssignModalOnClose &&
					<ResourceAssignConfirmationModal
						onClose={resourceAssignModalOnClose}
						onSubmit={resourceAssignModalOnSubmit}
						showModal={showResourceAssignConfirmationModal}
						type={resourceAssignModalType}
					/>
				}
				<EmployeeWorkOrderHistoryModal
					companyName={companyName}
				/>
				<EquipmentWorkOrderHistoryModal
					companyName={companyName}
				/>
			</div>
		);
	}
}

type ResourceUnavailabilityDetailsByDate = {
	equipmentUnavailabilityDetailsByDate: { [date: string]: EquipmentUnavailabilityDetailsMap; };
	employeeUnavailabilityDetailsByDate: { [date: string]: EmployeeUnavailabilityDetailsMap; };
};

const _hasWorkOrderUpdateSubmit = (formName: string) => formName === WORK_ORDER_FORM || formName === ORDER_COPY_FORM;

const _getResourceByDateReducer = (workOrdersByDateDictionary: ScheduleBoardWorkOrdersByDateDictionary) => {
	return (_acc: ResourceUnavailabilityDetailsByDate, _date: string) => {
		const equipmentMap = workOrdersByDateDictionary[_date]?.equipmentUnavailabilityDetails;
		const employeeMap = workOrdersByDateDictionary[_date]?.employeeUnavailabilityDetails;
		if (equipmentMap) {
			_acc.equipmentUnavailabilityDetailsByDate[_date] = equipmentMap;
		}
		if (employeeMap) {
			_acc.employeeUnavailabilityDetailsByDate[_date] = employeeMap;
		}
		return _acc;
	};
};

function mapStateToProps(state: RootState, ownProps: ConnectOwnProps): StateProps {
	const { userData, companyData } = state.user;
	const {
		workOrdersByDateDictionary,
		startDate,
		endDate,
		isFilterApplied,
		workOrdersSort,
		copiedEquipmentId,
		copiedEmployeeId,
		copiedTemporaryEmployeeId,
		copiedWorkOrderCode,
		zoomLevel,
		dates,
		copiedPlaceholderId,
	} = state.scheduleBoard;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const workOrdersUpdating = state.http.submitting.some(_hasWorkOrderUpdateSubmit);
	const loadResources = !state.scheduleBoard.resourcesLoaded;
	const datesLoaded = startDate && endDate && dates && dates.length > 0;
	const someDatesNotLoaded = datesLoaded ? dates.some((_date) => !workOrdersByDateDictionary[_date]?.workOrders) : true;
	const isBoardLoading = someDatesNotLoaded || !datesLoaded || workOrdersUpdating || loadResources;

	const isDragAndDropDisabled: boolean = workOrdersSort !== ScheduleBoardSortType.FREE_REORDERING;
	const hasPermissionsToEditScheduleBoard: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToSendNotifications: boolean = isAllowed(
		PagePermissions.COMPANY.COMMUNICATION.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	// FIXME: new object on every dispatch
	const _resourceByDateReducer = _getResourceByDateReducer(workOrdersByDateDictionary);
	const {
		equipmentUnavailabilityDetailsByDate,
		employeeUnavailabilityDetailsByDate,
	} = dates.reduce(_resourceByDateReducer,
		{ equipmentUnavailabilityDetailsByDate: {}, employeeUnavailabilityDetailsByDate: {} } as ResourceUnavailabilityDetailsByDate
	);

	// Data from weekly date picker that is available on mount
	const initialStartDate = ownProps._startDate && formatDate(ownProps._startDate, TimeFormat.DATE_ONLY);
	const initialEndDate = ownProps._endDate && formatDate(ownProps._endDate, TimeFormat.DATE_ONLY);

	return {
		userData,
		companyData,
		zoomLevel,
		copiedWorkOrderCode,
		copiedEmployeeId,
		copiedEquipmentId,
		copiedPlaceholderId,
		copiedTemporaryEmployeeId,
		equipmentUnavailabilityDetailsByDate,
		employeeUnavailabilityDetailsByDate,
		isFilterApplied,
		startDate: startDate ?? null,
		endDate: endDate ?? null,
		dates: dates || undefined,
		initialStartDate,
		initialEndDate,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		loadResources,
		isBoardLoading,
		/** Used to know to which work order id temp employee was dragged */
		workOrders: workOrdersByDateDictionary,
	};
}

type ActionType = GeneralActions.GeneralAction | CompanyActions.ActionType | ScheduleBoardActions.ScheduleBoardAction | WorkOrderActions.WorkOrderAction;
function mapDispatchToProps(dispatch: Dispatch<ActionType>): DispatchProps {
	return {
		generalActions: bindActionCreators(GeneralActions, dispatch),
		companyActions: bindActionCreators(CompanyActions, dispatch),
		scheduleBoardActions: bindActionCreators(ScheduleBoardActions, dispatch),
		workOrderActions: bindActionCreators(WorkOrderActions, dispatch),
		notifyActions: bindActionCreators(NotifyActions, dispatch),
		equipmentActions: bindActionCreators(EquipmentActions, dispatch),
		employeeActions: bindActionCreators(EmployeeActions, dispatch),
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withScheduleBoardModals,
	withSettings<SettingsProps>(() => ([
		{
			key: SettingsKeys.WEEKLY_VIEW_START_DUE_DATE(),
			mappedName: '_startDate',
			normalize: normalizeDateToMoment,
			defaultValue: undefined,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
		{
			key: SettingsKeys.WEEKLY_VIEW_END_DUE_DATE(),
			mappedName: '_endDate',
			normalize: normalizeDateToMoment,
			defaultValue: undefined,
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps)
);

export default enhance(WeeklyView);
