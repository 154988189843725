import type AccountPermission from '@acceligentllc/shared/enums/accountPermission';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';

import { getId } from '@acceligentllc/shared/utils/array';

import type AccountVM from 'ab-viewModels/account/member.viewModel';

import type CreateAccountRM from 'ab-requestModels/account/upsert.requestModel';
import type InviteAccountRM from 'ab-requestModels/account/invite.requestModel';

import { toRawPhoneNumber } from 'ab-utils/phone.util';

import { AccountPermissionTemplateOptions } from 'ab-enums/accountPermissionTemplates.enum';

import type * as EditMemberAPI from 'ab-api/web/account/editMember';

interface UserFM {
	uniqueId: string;
	firstName: string;
	lastName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	extension: Nullable<string>;
	imageUrl: Nullable<string>;
}

export interface ExistingUserFM {
	id: number;
	name: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	activeInLMS: boolean;
}

interface UserGroupFM {
	id: number;
	name: string;
}

interface LocationFM {
	id: number;
	nickname: string;
}

export default class AccountFM {
	id?: number;
	userId?: number;
	user?: UserFM;
	existingUserId?: number;
	existingUser?: ExistingUserFM;
	inviteOption?: typeof AccountFM.INVITE_OPTIONS[0] | null;
	deleteImage: boolean;
	locationId: Nullable<number>;
	location: Nullable<LocationFM>;
	accountTemplateId: AccountPermissionTemplate;
	accountTemplate: typeof AccountPermissionTemplateOptions[0] | undefined;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	permissions: Record<AccountPermission, boolean>;
	userGroups: UserGroupFM[];
	activateImmediately: boolean;
	activeInLMS: boolean;
	sendEmail: boolean;
	sendSms: boolean;
	isDeleted: Nullable<boolean>;

	static CREATE_NEW_USER_OPTION = { value: 0, label: 'Create new user' };
	static INVITE_USER_OPTION = { value: 1, label: 'Add to existing user' };

	static INVITE_OPTIONS = [
		AccountFM.CREATE_NEW_USER_OPTION,
		AccountFM.INVITE_USER_OPTION,
	];

	static getTemplateOption(template: AccountPermissionTemplate) {
		return AccountPermissionTemplateOptions.find((_option) => _option.id === template);
	}

	static fromViewModel(model: AccountVM): AccountFM {
		return {
			id: model.id,
			userId: model.userId,
			user: model.user,
			locationId: model.locationId,
			location: model.location,
			deleteImage: false,
			assignableToWorkOrder: model.assignableToWorkOrder,
			assignableAsSuperintendent: model.assignableAsSuperintendent,
			assignableAsProjectManager: model.assignableAsProjectManager,
			assignableAsQAQC: model.assignableAsQAQC,
			assignableAsAccounting: model.assignableAsAccounting,
			assignableAsManagement: model.assignableAsManagement,
			assignableAsTechnician: model.assignableAsTechnician,
			permissions: model.permissions,
			userGroups: model.userGroups,
			accountTemplateId: model.accountTemplate,
			accountTemplate: AccountFM.getTemplateOption(model.accountTemplate),
			activeInLMS: model.user.activeInLMS,
			activateImmediately: false,
			sendEmail: false,
			sendSms: false,
			inviteOption: null,
			isDeleted: model.isDeleted,
		};
	}

	static toCreateRequestModel(form: AccountFM): CreateAccountRM {
		if (!form.user) {
			throw new Error('User not defined');
		}

		return {
			id: form.id,
			user: {
				...form.user,
				phoneNumber: form.user?.phoneNumber ? toRawPhoneNumber(form.user.phoneNumber) : null,
				activeInLMS: form.activeInLMS,
			},
			imageUrl: form.user?.imageUrl ?? null,
			deleteImage: form.deleteImage,
			locationId: form.locationId,
			accountTemplate: form.accountTemplateId,
			assignableToWorkOrder: form.assignableToWorkOrder,
			assignableAsSuperintendent: form.assignableAsSuperintendent,
			assignableAsProjectManager: form.assignableAsProjectManager,
			assignableAsQAQC: form.assignableAsQAQC,
			assignableAsAccounting: form.assignableAsAccounting,
			assignableAsManagement: form.assignableAsManagement,
			assignableAsTechnician: form.assignableAsTechnician,
			permissions: form.permissions,
			userGroupIds: form.userGroups?.map(getId) ?? [],
			activateImmediately: form.activateImmediately,
			sendEmail: form.sendEmail,
			sendSms: form.sendSms,
			isDeleted: form.isDeleted,
		};
	}

	static toEditRM(form: AccountFM): EditMemberAPI.W_Account_EditMember_RM {
		if (!form.user || !form.id) {
			throw new Error('User not defined');
		}

		return {
			id: form.id,
			user: {
				id: form.id,
				...form.user,
				phoneNumber: form.user?.phoneNumber ? toRawPhoneNumber(form.user.phoneNumber) : null,
				activeInLMS: form.activeInLMS,
			},
			imageUrl: form.user?.imageUrl ?? null,
			deleteImage: form.deleteImage,
			locationId: form.locationId,
			accountTemplate: form.accountTemplateId,
			assignableToWorkOrder: form.assignableToWorkOrder,
			assignableAsSuperintendent: form.assignableAsSuperintendent,
			assignableAsProjectManager: form.assignableAsProjectManager,
			assignableAsQAQC: form.assignableAsQAQC,
			assignableAsAccounting: form.assignableAsAccounting,
			assignableAsManagement: form.assignableAsManagement,
			assignableAsTechnician: form.assignableAsTechnician,
			permissions: form.permissions,
			userGroupIds: form.userGroups?.map(getId) ?? [],
			activateImmediately: form.activateImmediately,
			sendEmail: form.sendEmail,
			sendSms: form.sendSms,
			isDeleted: form.isDeleted,
		};
	}

	static toInviteRequestModel(form: AccountFM): InviteAccountRM {
		return {
			existingUserId: form.existingUserId,
			locationId: form.locationId,
			accountTemplate: form.accountTemplateId,
			assignableToWorkOrder: form.assignableToWorkOrder,
			assignableAsSuperintendent: form.assignableAsSuperintendent,
			assignableAsProjectManager: form.assignableAsProjectManager,
			assignableAsQAQC: form.assignableAsQAQC,
			assignableAsAccounting: form.assignableAsAccounting,
			assignableAsManagement: form.assignableAsManagement,
			assignableAsTechnician: form.assignableAsTechnician,
			permissions: form.permissions,
			userGroupIds: form.userGroups?.map(getId) ?? [],
			activateImmediately: form.activateImmediately,
			activeInLMS: form.activeInLMS,
			sendEmail: form.sendEmail,
			sendSms: form.sendSms,
		};
	}
}
