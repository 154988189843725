import type ReportTypeBase from 'ab-domain/models/reportType/base';

export type W_ReportType_FindAllReportTypesForFilter_VM = W_ReportType_FindAllReportTypesForFilter_VM_Single[];

export class W_ReportType_FindAllReportTypesForFilter_VM_Single {
	id: number;
	name: string;

	constructor(reportType: ReportTypeBase) {
		this.id = reportType.id;
		this.name = reportType.name;
	}

	static bulkConstructor = (reportTypes) => reportTypes.map(W_ReportType_FindAllReportTypesForFilter_VM_Single._constructorMap);
	private static _constructorMap = (reportType) => new W_ReportType_FindAllReportTypesForFilter_VM_Single(reportType);

}

