import * as React from 'react';

import type { CompanyFeatureFlagsEntry } from 'ab-viewModels/companyFeatureFlags.viewModel';
import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	company: CompanyViewModel;
	general: (Nullable<CompanyFeatureFlagsEntry>)[];
}

export default class FeatureFlagPreview extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		general: [],
	};

	renderItem = (featureFlag: Nullable<CompanyFeatureFlagsEntry>, index: number) => {
		if (!featureFlag) {
			return null;
		}

		return (
			<PreviewItem
				checked={!!featureFlag.checked}
				hint={featureFlag.hint}
				id={`accqa__notification-settings__general__${featureFlag.key}`}
				indent={!!featureFlag.indent}
				key={`${featureFlag.label}${index}`}
				label={featureFlag.label}
				showCheckbox={!!featureFlag.showStatusIcon}
				value={featureFlag.value ?? undefined}
			/>
		);
	};

	render() {
		const { toggleEdit, general } = this.props;

		return (
			<SettingPreview
				buttonContext="notification-settings__general"
				containerId="accqa__notification-settings__general__container"
				toggleEdit={toggleEdit}
			>
				<div className="company-settings__info-segment">
					{
						general.map((_featureFlag: Nullable<CompanyFeatureFlagsEntry>, _index: number) => (
							this.renderItem(_featureFlag, _index))
						)
					}
				</div>
			</SettingPreview>

		);
	}
}
