import * as React from 'react';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { SCREEN_BREAKPOINT_M } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

type MomentType = ReturnType<typeof TimeUtils.normalizeDateToMoment>;

interface Props {
	startDate: MomentType;
	endDate: MomentType;
	value?: string;
	onClick?: () => void;
}

interface State {
	isTimeFormatShort: boolean;
}

export default class WeeklyDatePicker extends React.PureComponent<Props, State> {

	state: State = {
		isTimeFormatShort: false,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState(() => ({ isTimeFormatShort: window.innerWidth < SCREEN_BREAKPOINT_M }));
	};

	render() {
		const { onClick, startDate, endDate } = this.props;
		const { isTimeFormatShort } = this.state;

		const formattedStartDate = startDate && TimeUtils.formatDate(
			startDate,
			isTimeFormatShort ? TimeFormatEnum.SB_DATE_WEEK_SHORT : TimeFormatEnum.SB_DATE_WEEK_LONG
		).toUpperCase();
		const formattedEndDate = endDate && TimeUtils.formatDate(
			endDate,
			isTimeFormatShort ? TimeFormatEnum.SB_DATE_WEEK_SHORT : TimeFormatEnum.SB_DATE_WEEK_LONG
		).toUpperCase();

		return (
			<Tooltip message="Select Week from Calendar" placement="bottom">
				<span className="schedule-board-weekly-date-picker" onClick={onClick} role="button">
					<span className="schedule-board-date-format">
						{formattedStartDate}
						<span className="time-delimiter">to</span>
						{formattedEndDate}
					</span>

					<span className="btn btn--rectangle btn--icon" >
						<span className="icon-calendar" />
					</span>
				</span>
			</Tooltip>
		);
	}
}
