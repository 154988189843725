import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-constants/routes/client';
import { EQUIPMENT_COST_TREE_FILTER } from 'af-constants/values';

import Tooltip from 'af-components/Tooltip';

import Input from 'af-fields/Input';

interface OwnProps {
	showSmallButtons: boolean;
	showToolbarHeaders: boolean;
	incrementHighlightedNodeIndex: () => void;
	decrementHighlightedNodeIndex: () => void;
	isAllowedToCreate: boolean;
	dataFiltered?: string;
	collapseAll: () => void;
	expandAll: () => void;
	onClear: () => void;
	onFilter: (query: string) => void;
	isAnyExpanded: boolean;
	companyName: string;
	orgAlias: string;
}

type Props = OwnProps & CustomRouteComponentProps<void>;

class TreeHeader extends React.PureComponent<Props> {

	onClear = () => {
		const { onClear } = this.props;
		onClear();
	};

	onFilter = (query: string) => {
		const { onFilter } = this.props;
		onFilter(query);
	};

	collapseAll = () => this.props.collapseAll();

	expandAll = () => this.props.expandAll();

	renderTableHeaders = () => {
		const { showSmallButtons, isAnyExpanded } = this.props;

		return (
			<Row className="tree-table__header-titles">
				<Col md={1}>
					{isAnyExpanded
						? <span className="tree-table__expander icon-collapse" onClick={this.collapseAll} />
						: <span className="tree-table__expander icon-expand" onClick={this.expandAll} />
					}
				</Col>
				<Col md={7}>
					Name
				</Col>
				<Col md={5}>
					{showSmallButtons ? 'Skills' : 'Needed Skills'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Day' : 'Daily Cost'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Week' : 'Weekly Cost'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? '$/Month' : 'Monthly Cost'}
				</Col>
				<Col md={2}>
					Mob Charge
				</Col>
				<Col md={2}>
					{showSmallButtons ? 'Op hr Charge' : 'Op Hour Charge'}
				</Col>
				<Col md={2}>
					{showSmallButtons ? 'Fuel' : 'Fuel Cost'}
				</Col>
				<Col className="pull-right" md={4}>
					Updated
				</Col>
				<Col md={1} />
			</Row>
		);
	};

	goToCreate = () => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.CREATE(orgAlias, companyName));
	};

	goToCSVImport = () => {
		const { orgAlias, companyName, history } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.BULK_UPLOAD(orgAlias, companyName));
	};

	render() {
		const {
			incrementHighlightedNodeIndex,
			decrementHighlightedNodeIndex,
			dataFiltered,
			isAllowedToCreate,
			showToolbarHeaders,
		} = this.props;

		const idAndClassName = 'tree-table__header';

		return (
			<div
				className={idAndClassName}
				id={idAndClassName}
			>
				<div className="tree-table__header-container">
					<div className="tree-table__header-left">
						<div className="tree-table__match-filter">
							<Field
								component={Input}
								dataFiltered={dataFiltered}
								id={EQUIPMENT_COST_TREE_FILTER}
								inputGroupClassName="tree-table__input-filter"
								isStandalone={true}
								name="filter"
								onValueChange={this.onFilter}
								placeholder={showToolbarHeaders ? 'Search Equipment Costs' : 'Search'}
								type="text"
							/>
							{!!dataFiltered &&
								<div className="tree-table__match">
									<Tooltip message="Previous">
										<span className="icon-left" onClick={decrementHighlightedNodeIndex} />
									</Tooltip>
									<span>{dataFiltered}</span>
									<Tooltip message="Next">
										<span className="icon-right" onClick={incrementHighlightedNodeIndex} />
									</Tooltip>
								</div>
							}
							{
								!!dataFiltered ?
									<Tooltip message="Close">
										<span
											className="icon-close tree-table__filter-icon tree-table__filter-icon--clear"
											onClick={this.onClear}
										/>
									</Tooltip> :
									<Tooltip message="Search"><span className="icon-search tree-table__filter-icon" /></Tooltip>
							}
						</div>
					</div>
					<div className="tree-table__header-right">
						{
							isAllowedToCreate &&
							<>
								<Button
									icon="upload"
									label="CSV Import"
									onClick={this.goToCSVImport}
									style="link"
								/>
								<Button
									label="New Equipment Cost"
									onClick={this.goToCreate}
									style="primary"
								/>
							</>
						}
					</div>
				</div>
				{showToolbarHeaders && this.renderTableHeaders()}
			</div>
		);
	}
}

export default withRouter(TreeHeader);
