import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import { MAX_BILLABLE_WORK_INFORMATION_FIELDS } from 'ab-common/constants/value';

import Input from 'af-fields/Input';

import type { FormModel } from '../FormModel';
import styles from './styles.module.scss';

type Props = WrappedFieldArrayProps<FormModel['informationalFields'][0]>;

const AddBillableWorkModalInformationalField: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddInformationalFieldClick = React.useCallback(() => fields.push(''), [fields]);

	const onRemoveInformationalFieldClick = React.useCallback((index) => () => fields.remove(index), [fields]);

	const renderField = (field, index) => {
		return (
			<div className={styles['add-billable-work-modal__info-def-item']} key={field}>
				<Field
					component={Input}
					id="informationalField"
					label={`Informational Field ${index + 1}`}
					name={`${field}`}
					placeholder="Add informational field"
					type="text"
				/>
				<Button
					icon="delete"
					onClick={onRemoveInformationalFieldClick(index)}
					style="link-danger"
				/>
			</div>
		);
	};
	return (
		<>
			{fields.map(renderField)}
			{fields.length < MAX_BILLABLE_WORK_INFORMATION_FIELDS &&
				<Button
					icon="plus"
					label="Add Informational Field"
					onClick={onAddInformationalFieldClick}
					style="link"
				/>
			}
		</>
	);
};

export default AddBillableWorkModalInformationalField;
