import * as React from 'react';

import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';
import { QuantityUnitLabel, QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import type { CellWidthsMap } from 'af-utils/react.util';

import type { FieldVM } from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import type WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';
import Checkbox from 'af-components/Controls/Checkbox';

import WorkSummaryDetailsTableCell from './WorkSummaryDetailsTableCell';

import { resolveHighlightGroupKey } from '../helpers';
import { WORK_SUMMARY_COLUMN_DEFINITION_MAP, createIndexedColumn } from '../values';
import { resolveFieldValue } from '../Table/helpers';

interface Props {
	data: WorkSummaryDetailVM;
	maxNumberOfDefinitionFields: number;
	maxNumberOfInformationalFields: number;
	onBillingStatusToggle: (workSummaryDetail: WorkSummaryDetailVM) => Promise<void>;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string | number>;
	cellWidthsMap: CellWidthsMap;
	isReadOnly: boolean;
	hasPreviouslyBeenSplit: boolean;
	isGroupAndExcludeActionDisabled: boolean;
	isFirstOfItsGroupKey: boolean;
	isFirstOfItsGroupName: boolean;
	isLastOfItsGroupName: boolean;
	isOfCurrentlyHighlightedGroup: boolean;
	checkedWorkSummaryDetails: WorkSummaryDetailVM[];
	setNewWorkSummaryCheckedList: (wsds: WorkSummaryDetailVM[]) => void;
	onGroupTogetherClick: () => Promise<void>;
	onRestoreOriginalClick: () => Promise<void>;
	onExcludeFromCalculationGroupClick: () => Promise<void>;
	currentlyHighlightedGroupKey: Nullable<string>;
	jumpToQuantityFieldPathName: string;
	isAlternativeOption: boolean;
	isCustomSorted: boolean;
}

const _fillWithEmptyFields = (fields: FieldVM[], maxNumberOfFields: number) => {
	const noOfExtraEmptyFields = maxNumberOfFields - (fields.length);
	let modifiedFields: Nullable<FieldVM>[] = [];

	if (noOfExtraEmptyFields) {
		const extraFields = new Array(noOfExtraEmptyFields).fill(null, 0, maxNumberOfFields);
		modifiedFields =
			fields
				? fields.concat(extraFields)
				: extraFields;
	} else {
		modifiedFields = fields;
	}

	return modifiedFields;
};

const WorkSummaryDetailsTableRow: React.FC<Props> = (props) => {
	const {
		data,
		maxNumberOfDefinitionFields,
		maxNumberOfInformationalFields,
		onBillingStatusToggle,
		onRowHighlight,
		highlightedGroup,
		cellWidthsMap,
		isReadOnly,
		hasPreviouslyBeenSplit,
		isGroupAndExcludeActionDisabled,
		isFirstOfItsGroupKey,
		isFirstOfItsGroupName,
		isLastOfItsGroupName,
		isOfCurrentlyHighlightedGroup,
		setNewWorkSummaryCheckedList,
		checkedWorkSummaryDetails,
		onGroupTogetherClick,
		onExcludeFromCalculationGroupClick,
		currentlyHighlightedGroupKey,
		onRestoreOriginalClick,
		jumpToQuantityFieldPathName,
		isAlternativeOption,
		isCustomSorted,
	} = props;

	const {
		id,
		billableWorkId,
		date,
		workOrder,
		frSegment,
		frSegmentSecondary,
		definitionFields,
		workName,
		workType,
		quantity,
		unit,
		informationalFields,
		isBillable,
		workTypeFieldValueType,
		workTypeFieldType,
		workTypeFieldName,
		billingCodeCustomerId,
		billingCodeDescription,
		workSummaryGroup,
		subJobId,
	} = data;

	const [isBillingStatusToggleDisabled, setIsBillingStatusToggleDisabled] = React.useState(false);

	const modifiedDefinitionFields = React.useMemo(
		() => _fillWithEmptyFields(definitionFields, maxNumberOfDefinitionFields),
		[definitionFields, maxNumberOfDefinitionFields]
	);
	const modifiedInformationalFields = React.useMemo(
		() => _fillWithEmptyFields(informationalFields ?? [], maxNumberOfInformationalFields),
		[informationalFields, maxNumberOfInformationalFields]
	);

	const groupToHighlight = React.useMemo(
		() => workSummaryGroup ?? resolveHighlightGroupKey({ billableWorkId, workType, definitionFields, subJobId }),
		[workSummaryGroup, billableWorkId, workType, definitionFields, subJobId]
	);

	const actionOptions = React.useMemo(() => {
		return [
			{ label: 'Restore original', onClick: onRestoreOriginalClick, disabled: (!hasPreviouslyBeenSplit || isReadOnly) },
			{ label: 'Group together', onClick: onGroupTogetherClick, disabled: (isGroupAndExcludeActionDisabled || isReadOnly) },
			{ label: 'Mark as Non-billable', onClick: onExcludeFromCalculationGroupClick, disabled: (isGroupAndExcludeActionDisabled || isReadOnly) },
		];
	}, [onRestoreOriginalClick, hasPreviouslyBeenSplit, isReadOnly, onGroupTogetherClick, isGroupAndExcludeActionDisabled, onExcludeFromCalculationGroupClick]);

	const cellClassName = bemElement('field-report__work-summary__table__row__data', 'cell');
	const cellTextClassName = bemElement(cellClassName, 'text');
	const billingStatusClassName = bemElement(cellClassName, 'billing-action', { billed: isBillable, readonly: isReadOnly || isBillingStatusToggleDisabled });
	const checkboxClassName = bemElement(cellClassName, 'checkbox');

	const _findWorkSummaryDetail = React.useCallback((_wsd) => _wsd.id === id, [id]);
	const isChecked = !!checkedWorkSummaryDetails.find(_findWorkSummaryDetail);

	const onRowClick = React.useCallback(() => {
		const _rowGroupKey = resolveHighlightGroupKey({ billableWorkId, workType, definitionFields, subJobId });
		onRowHighlight(groupToHighlight, _rowGroupKey);
		if (currentlyHighlightedGroupKey !== _rowGroupKey) {
			setNewWorkSummaryCheckedList([]);
		}
	}, [billableWorkId, currentlyHighlightedGroupKey, definitionFields, groupToHighlight, onRowHighlight, setNewWorkSummaryCheckedList, subJobId, workType]);

	const handleCheckboxChange = React.useCallback(
		() => {
			if (isChecked) {
				const newCheckedList = Array.from(checkedWorkSummaryDetails);
				const currentCheckedIndex = newCheckedList.findIndex(_findWorkSummaryDetail);
				newCheckedList.splice(currentCheckedIndex, 1);
				setNewWorkSummaryCheckedList(newCheckedList);
			} else {
				setNewWorkSummaryCheckedList([...checkedWorkSummaryDetails, data]);
			}
		}
		, [isChecked, checkedWorkSummaryDetails, _findWorkSummaryDetail, setNewWorkSummaryCheckedList, data]);

	const handleBillingStatusToggle = React.useCallback(async () => {
		setIsBillingStatusToggleDisabled(true);
		await onBillingStatusToggle(data);
		setIsBillingStatusToggleDisabled(false);
	}, [onBillingStatusToggle, data]);

	const _renderInformationField = React.useCallback((_field: Nullable<FieldVM>, _index: number) => {
		const column = createIndexedColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.INFORMATIONAL_FIELD, _index);

		const formatted = _field ? `${_field.name}: ${resolveFieldValue(_field.value, _field.fieldType)}` : 'N/A';
		const key = `${_field?.id ?? `null-${_index}`}`;

		let displayValue: string | JSX.Element = formatted;
		let tooltipMessage = formatted;

		if (_field?.fieldType === ReportBlockFieldEnum.Type.IMAGE && _field.value) {
			const fileNameSplit = _field.value.split('/');
			const fileName = fileNameSplit.at(-1);

			displayValue = (
				<>
					{`${_field.name}: `}
					<a className="link" href={_field.value} rel="noreferrer" target="_blank">
						{fileName}
					</a>
				</>
			);
			tooltipMessage = fileName ?? 'N/A';
		}

		return (
			<WorkSummaryDetailsTableCell
				cellClassName={cellClassName}
				cellTextClassName={cellTextClassName}
				cellWidthsMap={cellWidthsMap}
				column={column}
				displayValue={displayValue}
				key={key}
				tooltipMessage={tooltipMessage}
			/>
		);
	}, [cellClassName, cellTextClassName, cellWidthsMap]);

	const _renderDefinitionField = React.useCallback((_field: Nullable<FieldVM>, _index: number) => {
		const column = createIndexedColumn(WORK_SUMMARY_COLUMN_DEFINITION_MAP.DEFINITION_FIELD, _index);

		const formatted = _field ? `${_field.name}: ${resolveFieldValue(_field.value, _field.fieldType)} ${_field.fieldUnit ?? ''}` : 'N/A';
		const key = `${_field?.id ?? `null-${_index}`}`;

		return (
			<WorkSummaryDetailsTableCell
				cellClassName={cellClassName}
				cellTextClassName={cellTextClassName}
				cellWidthsMap={cellWidthsMap}
				column={column}
				displayValue={formatted}
				key={key}
				tooltipMessage={formatted}
			/>
		);
	}, [cellClassName, cellTextClassName, cellWidthsMap]);

	const _renderMenuItem = React.useCallback((option) => {

		return (
			<span className={bemElement('field-report__work-summary__table__row__actions', 'menu-item')}>
				{option?.label}
			</span>
		);
	}, []);

	const isHighlighted = highlightedGroup === groupToHighlight;
	const showActionsButton = isOfCurrentlyHighlightedGroup && isFirstOfItsGroupKey && !isCustomSorted;
	const indicatePreviousSplit = isOfCurrentlyHighlightedGroup && hasPreviouslyBeenSplit;
	const showCheckbox = isOfCurrentlyHighlightedGroup && !isReadOnly && !isCustomSorted;

	const rowDataClassName = bemElement('field-report__work-summary__table__row', 'data', {
		highlighted: isHighlighted,
		split: indicatePreviousSplit,
		'last-seperated': isLastOfItsGroupName && !isCustomSorted,
		'first-seperated': isFirstOfItsGroupName && !isCustomSorted,
		'alternative': isAlternativeOption,
		'custom-sort': isCustomSorted,
	});

	const workTypeAdjustedName = workTypeFieldValueType === ReportBlockFieldEnum.ValueType.NUMBER
		? workTypeFieldName
		: `${workTypeFieldName}: ${resolveFieldValue(workType, workTypeFieldType)}`;

	return (
		<div className={bemBlock('field-report__work-summary__table__row', { 'custom-sort': isCustomSorted })}>
			<div className={bemElement('field-report__work-summary__table__row', 'actions', { highlighted: isHighlighted })}>
				{showActionsButton &&
					<Dropdown
						id="actions"
						isActionDropdown={true}
						labelKey="label"
						options={actionOptions}
						renderMenuItem={_renderMenuItem}
					/>
				}
			</div>
			<div
				className={rowDataClassName}
				onClick={onRowClick}
			>
				<span className={bemElement('field-report__work-summary__table__row__data', 'cell', { highlighted: isHighlighted })}>
					<span className={checkboxClassName}>
						{showCheckbox &&
							<Checkbox
								handleChange={handleCheckboxChange}
								isChecked={isChecked}
								isDisabled={isReadOnly}
							/>
						}
					</span>
					<span className={billingStatusClassName} onClick={!isBillingStatusToggleDisabled ? handleBillingStatusToggle : undefined}>
						<span className="icon-dollar" />
					</span>
					<a href={jumpToQuantityFieldPathName} rel="noreferrer" target="_blank">
						<span className="icon-external" />
					</a>
				</span>
				<WorkSummaryDetailsTableCell
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.DATE}
					displayValue={date}
					tooltipMessage={date}
				/>
				<WorkSummaryDetailsTableCell
					additionalStyling={{ long: true }}
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.WORK_ORDER}
					displayValue={workOrder}
					tooltipMessage={workOrder}
				/>
				<WorkSummaryDetailsTableCell
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.FR_SEGMENT}
					displayValue={frSegment}
					tooltipMessage={frSegment}
				/>
				<WorkSummaryDetailsTableCell
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.FR_SECONDARY_SEGMENT}
					displayValue={frSegmentSecondary ?? 'N/A'}
					tooltipMessage={frSegmentSecondary ?? 'N/A'}
				/>
				<WorkSummaryDetailsTableCell
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.WORK}
					displayValue={workName}
					tooltipMessage={workName}
				/>
				<WorkSummaryDetailsTableCell
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.TYPE}
					displayValue={workType ? workTypeAdjustedName : 'N/A'}
					tooltipMessage={workType ? workTypeAdjustedName : 'N/A'}
				/>
				{modifiedDefinitionFields?.map(_renderDefinitionField)}
				<WorkSummaryDetailsTableCell
					additionalStyling={{ 'align-right': true, short: true }}
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.QUANTITY}
					displayValue={(quantity ?? 0).toString()}
					tooltipMessage={(quantity ?? 0).toString()}
				/>
				<WorkSummaryDetailsTableCell
					additionalStyling={{ short: true }}
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.UNIT}
					displayValue={unit && QuantityUnitMap[unit] ? QuantityUnitMap[unit].replace('_', '/') : 'N/A'}
					tooltipMessage={unit && QuantityUnitLabel[unit] ? QuantityUnitLabel[unit] : 'N/A'}
				/>
				{modifiedInformationalFields?.map(_renderInformationField)}
				<WorkSummaryDetailsTableCell
					additionalStyling={{ short: true }}
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.BILLING_CODE_ID}
					displayValue={billingCodeCustomerId ?? 'N/A'}
					tooltipMessage={billingCodeCustomerId ?? 'N/A'}
				/>
				<WorkSummaryDetailsTableCell
					additionalStyling={{ short: true }}
					cellClassName={cellClassName}
					cellTextClassName={cellTextClassName}
					cellWidthsMap={cellWidthsMap}
					column={WORK_SUMMARY_COLUMN_DEFINITION_MAP.BILLING_CODE_DESCRIPTION}
					displayValue={billingCodeDescription ?? 'N/A'}
					tooltipMessage={billingCodeDescription ?? 'N/A'}
				/>
			</div>
		</div>
	);
};

export default WorkSummaryDetailsTableRow;
