import { EmailTypesArray, PhoneTypesArray } from '@acceligentllc/shared/enums/contactMethodType';
import type { PhoneTypes, EmailTypes } from '@acceligentllc/shared/enums/contactMethodType';
import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type FieldReportAccessRole from '@acceligentllc/shared/enums/fieldReportAccessRole';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import type WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';
import type { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type SubscriptionStatus from '@acceligentllc/shared/enums/subscriptionStatus';
import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type UserPermission from '@acceligentllc/shared/enums/userPermission';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { FAKE_ACCOUNT_DATA } from '@acceligentllc/shared/constants/value';

import * as UserUtils from '@acceligentllc/shared/utils/user';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';
import type UserBase from 'ab-domain/models/user/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type AccountBase from 'ab-domain/models/account/base';
import type AddressBase from 'ab-domain/models/address/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type ShiftBase from 'ab-domain/models/shift/base';

import type WorkOrderStatusRevision from 'ab-enums/workOrderStatusRevision';
import ValidationState from 'ab-enums/validationState.enum';

import { resolveFieldReportAccessRole } from 'ab-utils/account.util';
import * as UserUtil from 'ab-utils/user.util';
import * as CodeUtil from 'ab-utils/codes.util';
import * as WorkOrderUtil from 'ab-utils/workOrder.util';

import * as WorkOrderValidator from 'ab-validators/workOrder.validator';

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_GroupedContactMethods = {
	emails: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethod[];
	phones: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethod[];
};

class W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethod {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static groupedBulkConstructor(contactMethods: ContactMethodBase[]): W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_GroupedContactMethods {
		const result: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_GroupedContactMethods = {
			phones: [],
			emails: [],
		};

		for (const _cm of contactMethods) {
			if (PhoneTypesArray.indexOf(_cm.type) !== -1) {
				result.phones.push(new W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethod(_cm));
			} else if (EmailTypesArray.indexOf(_cm.type) !== -1) {
				result.emails.push(new W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethod(_cm));
			}
		}

		return result;
	}

}

interface W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_UpdatedBy {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

class W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_UpdatedByAccount implements W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_UpdatedBy {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = UserUtils.getUserName(accountUser);
		this.fullNameShort = UserUtils.getUserName(accountUser, true);
	}
}

interface W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Job {
	isInternal: boolean;
}
interface W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_WorkOrder {
	isFirst?: boolean;
	isLate?: boolean;
	delayReason?: string;
	supervisorId: Nullable<number>;
	dueDate: string;
	jobId: number;
	job: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Job;
	crewTypeId: Nullable<boolean>;
	customCrewType: Nullable<string>;
	shift: ShiftBase;
	addresses: AddressBase[];
}

export class W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM {
	id: number;
	code: string;
	index: number;
	workOrderId?: Nullable<number>;
	revision: string;
	statusBasedRevision: string;

	title: string;
	jobStatus: WorkRequestStatus;
	isJobInternal: boolean;
	jobNotes: Nullable<string>;

	publicLink: Nullable<string>;
	/** MM-DD-YYYY */
	dueDate: string;
	status: WorkOrderStatus;
	cancellationReason: Nullable<string>;
	notificationStatus: Nullable<NotificationStatusEnum>;
	isPaused: boolean;
	pauseReason: Nullable<string>;

	isFirst?: boolean;
	isLate?: boolean;
	delayReason?: string;

	notes: Nullable<string>;
	scopeOfWork: Nullable<string>;

	crewTypeName: Nullable<string>;
	crewTypeColor: Nullable<ColorPalette>;
	customCrewType: Nullable<string>;

	shiftName: string;
	timeToStart: Nullable<number>;
	timeToEnd: Nullable<number>;

	projectManager: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_User>;
	supervisor: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_User>;

	siteContact: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Contact>;
	workLocationAddress: Nullable<string>;

	officeNickname: Nullable<string>;
	officeColor: Nullable<ColorPalette>;
	divisionName: Nullable<string>;
	customerName: Nullable<string>;

	addresses: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Address[]>;
	workOrderResourceLookups: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_WorkOrderResourceLookup[];
	workOrderStatus: Nullable<WorkOrderStatusRevision>;

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;
	jobHours: Nullable<number>;
	shopHours: Nullable<number>;
	travelHours: Nullable<number>;
	workDescription: Nullable<string>;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;
	deliverableDeliveryMethod: Nullable<string>;
	deliverableDeliveryTimeline: Nullable<string>;
	deliverableAssignee: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_User>;
	deliverableNotes: Nullable<string>;

	updatedAt: Date;
	updatedBy: Nullable<W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_UpdatedBy>;

	validationState: ValidationState;
	isValid: boolean;

	locked: boolean;

	reviewStatus: WorkOrderReviewStatus;
	reviewLevel: WorkOrderReviewLevel;
	fieldReportAccessRole: Nullable<FieldReportAccessRole>;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	isFRStatusOfInterest: boolean;

	constructor(
		workOrder: WorkOrderBase,
		isFirst: boolean,
		notificationStatus: Nullable<NotificationStatusEnum>,
		calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>,
		isFRStatusOfInterest: boolean,
		account: AccountBase,
		userPermission: UserPermission,
		revenue: number | undefined,
		jobHours: number | undefined,
		shopHours: number | undefined,
		travelHours: number | undefined
	) {
		const workOrderRevision = WorkOrderUtil.initializeWorkOrderRevision(workOrder, null) as WorkOrderRevisionBase;

		this.revision = `${CodeUtil.revisionCode(workOrderRevision.revision)}`;
		this.workOrderStatus = workOrderRevision.workOrderStatus;

		this.title = workOrderRevision.jobTitle;
		this.jobStatus = workOrderRevision.jobStatus;
		this.isJobInternal = workOrderRevision.isJobInternal;
		this.jobNotes = workOrderRevision.jobNotes;

		this.publicLink = workOrderRevision.publicLink;

		this.dueDate = TimeUtils.formatDate(workOrderRevision.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		this.notes = workOrderRevision.notes;
		this.scopeOfWork = workOrderRevision.scopeOfWork;

		this.crewTypeName = workOrderRevision.crewTypeName;
		this.crewTypeColor = workOrderRevision.crewTypeColor;
		this.customCrewType = workOrderRevision.customCrewType;

		this.shiftName = workOrderRevision.shiftName;
		this.timeToStart = workOrderRevision.timeToStart;
		this.timeToEnd = workOrderRevision.timeToEnd;

		this.supervisor = workOrderRevision.supervisor;
		this.projectManager = workOrderRevision.projectManager;

		this.siteContact = workOrderRevision.siteContact;
		this.workLocationAddress = workOrderRevision.workLocationAddress;

		this.officeNickname = workOrderRevision.officeNickname;
		this.officeColor = workOrderRevision.officeColor;
		this.divisionName = workOrderRevision.divisionName;
		this.customerName = workOrderRevision.customerName;

		this.workOrderResourceLookups = workOrderRevision.workOrderResourceLookups;
		this.addresses = workOrderRevision.addresses;

		this.revenue = revenue?.toString() ?? workOrderRevision.revenue;

		this.jobHours = jobHours ?? workOrderRevision.jobHours;
		this.shopHours = shopHours ?? workOrderRevision.shopHours;
		this.travelHours = travelHours ?? workOrderRevision.travelHours;

		this.deliverableCodeStandard = workOrderRevision.deliverableCodeStandard;
		this.deliverableFileFormat = workOrderRevision.deliverableFileFormat;
		this.deliverableSoftware = workOrderRevision.deliverableSoftware;
		this.deliverableDeliveryMethod = workOrderRevision.deliverableDeliveryMethod;
		this.deliverableDeliveryTimeline = workOrderRevision.deliverableDeliveryTimeline;
		this.deliverableAssignee = workOrderRevision.deliverableAssignee;
		this.deliverableNotes = workOrderRevision.deliverableNotes;

		this.code = workOrder.workOrderCode;
		this.id = workOrder.id;
		this.workOrderId = null;
		this.statusBasedRevision = workOrder.statusBasedRevision;
		this.status = workOrder.status;
		this.isPaused = workOrder.isPaused;
		this.cancellationReason = workOrder.status === WorkOrderStatus.CANCELED
			? workOrder.cancellationReason ?? ''
			: null;
		this.pauseReason = workOrder.isPaused
			? workOrder.pauseReason ?? ''
			: null;

		this.isFirst = isFirst;

		this.updatedBy = workOrder.updatedBy ? new W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_UpdatedByAccount(workOrder.updatedBy) : null;
		this.updatedAt = workOrder.updatedAt;

		this.isValid = WorkOrderValidator.isValidWorkOrder({
			isFirst: this.isFirst,
			isLate: this.isLate,
			delayReason: this.delayReason,
			supervisorId: workOrder.supervisorId,
			dueDate: workOrder.dueDate,
			jobId: workOrder.workRequest.id,
			job: { isInternal: workOrder.workRequest.isInternal },
			crewTypeId: workOrder.crewTypeId,
			customCrewType: workOrder.customCrewType,
			shift: workOrder.shift,
			addresses: workOrder.addresses.map((_woAddress) => _woAddress.address),
		} as W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_WorkOrder).isValid;
		this.validationState = this.isValid ? ValidationState.COMPLETE : ValidationState.INVALID;

		this.notificationStatus = notificationStatus;
		this.reviewStatus = workOrder.reviewStatus;
		this.reviewLevel = workOrder.reviewLevel;

		const currentUserAccess = workOrder.fieldReportAccess?.find((_fra) => _fra.accountId === account.id);
		this.fieldReportAccessRole = resolveFieldReportAccessRole(account as AccountBase, currentUserAccess?.role ?? null, userPermission);

		this.calculatedReportDisplayStatus = calculatedReportDisplayStatus;
		this.isFRStatusOfInterest = isFRStatusOfInterest;
	}
}

class W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_User {
	/** **Note**: it should be null only for revisions created before https://acceligent.atlassian.net/browse/AP-4224 was merged. */
	id: Nullable<number>;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;

	constructor(user: Nullable<UserBase>) {
		if (!user) {
			return;
		}

		this.id = user?.id;
		this.fullName = UserUtil.getFullName(user);
		this.email = user?.email;
		this.phoneNumber = user?.phoneNumber;
		this.countryCode = user?.countryCode;
	}
}

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Address = {
	latitude: number;
	longitude: number;
	streetAddress: string;
	suite: Nullable<string>;
	locality: Nullable<string>;
	aa1: Nullable<string>;
	postalCode: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
};

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Contact = {
	id: number;
	name: string;
	companyName: string;
	companyRole: string;
	emails: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethodType[];
	phones: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethodType[];
	addresses: W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactAddress[];
};

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactMethodType = {
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode?: CountryCode;
};

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_ContactAddress = {
	street: string;
	suite: Nullable<string>;
	city: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	country: Nullable<string>;
};

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Notification = {
	fullName: string;
	email: Nullable<string>;
	officeNickname: string | undefined;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	subscriptionStatus: SubscriptionStatus | undefined;
	smsStatus: NotificationStatusEnum | undefined;
	smsSentAt: Date | undefined;
	emailStatus: NotificationStatusEnum | undefined;
	emailSentAt: Date | undefined;
	emailErrorMessage: string | undefined;
	smsErrorMessage: string | undefined;
	notificationType: string | undefined;
};

interface W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_EmployeeNotification
	extends W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Notification {
	employeeId: number;
}

interface W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_TemporaryEmployeeNotification
	extends W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_Notification {
	temporaryEmployeeId: number;
}

type W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_WorkOrderResourceLookup = {
	id: number;
	index: number;
	name: string;
	color: Nullable<ColorPalette>;
	skills?: ColorPalette[];
	notification?: Nullable<
		(
			W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_EmployeeNotification
			| W_WorkOrder_FindWorkOrderModalForScheduleBoard_VM_TemporaryEmployeeNotification
		)
	>;
	workOrderEmployeeId?: number;
	employeeId?: number;
	workOrderEquipmentId?: number;
	equipmentId?: number;
	workOrderTemporaryEmployeeId?: number;
	temporaryEmployeeId?: number;
	wageRateId?: number;
	equipmentCostId?: number;
	isAvailable: boolean;
};
