import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import MessageStatusHistoryTableVM from 'ab-viewModels/message/messageStatusHistoryTable.viewModel';

import TableButtonType from 'ab-enums/tableButtonType.enum';
import type TableNameEnum from 'ab-enums/tableName.enum';

import { downloadCSV } from 'af-utils/csv.utils';

import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import ElipsisCell from 'af-components/Table6/Cells/ElipsisCell';
import type { Column, TabProps } from 'af-components/Table6';
import Table from 'af-components/Table6';

interface OwnProps {
	companyName: string;
	findMessageStatusHistory: (messageId: number, tableRequestModel: TableQuery) => Promise<TableContent<MessageStatusHistoryTableVM>>;
	messageId: number;
	tableName: TableNameEnum;
}

type Props = OwnProps;

const MessageStatuses: React.FC<Props> = (props: Props) => {
	const { tableName, findMessageStatusHistory, messageId, companyName } = props;

	const columns: Column<MessageStatusHistoryTableVM>[] = React.useMemo(() => [
		{
			Header: 'Created At',
			accessor: 'createdAt',
			className: 'text-strong',
			Cell: ({ original }) => TimeUtils.formatDate(original.createdAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME),
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({ original }) => original.status,
		},
		{
			Header: 'Metadata',
			accessor: 'createdMetadata',
			className: 'selectable-text-cell',
			Cell: ({ original }) => {
				const data = original.createdMetadata ?? original.invalidatedMetadata;
				if (!data) {
					return <EmptyCell />;
				}
				return (
					<ElipsisCell
						message={data}
						showToolTip
					/>
				);
			},
		},
	], []);

	const fetchStatusHistory = React.useCallback(async (data: TableQuery) => {
		return await findMessageStatusHistory(messageId, data);
	}, [findMessageStatusHistory, messageId]);

	const onDownloadCSVClick = React.useCallback(async () => {
		const tableRequestModel = new TableQuery({
			page: 0,
			sortBy: [{ id: 'createdAt', desc: true }],
			filterByText: '',
			includeAll: true,
		});
		const result = await findMessageStatusHistory(messageId, tableRequestModel);
		const csvData = MessageStatusHistoryTableVM.toCSVData(result.rows);

		downloadCSV(csvData, `${companyName}_${messageId}_status_history.csv`);
	}, [findMessageStatusHistory, messageId, companyName]);

	const tabs = (): TabProps<MessageStatusHistoryTableVM>[] => {
		return [
			{
				label: 'Message Statuses',
				columns: columns,
				searchLabel: 'Message Statuses',
				fetch: fetchStatusHistory,
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: onDownloadCSVClick,
				}],
			},
		];
	};

	return (
		<Table
			hideTabs={true}
			tableName={tableName}
			tabs={tabs()}
		/>
	);
};

export default React.memo(MessageStatuses);
