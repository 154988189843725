import type DisplayViewToggle from '@acceligentllc/shared/enums/displayViewToggle';
import type WeekDay from '@acceligentllc/shared/enums/weekDay';
import type AutoNotifyOption from '@acceligentllc/shared/enums/autoNotifyOption';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type { SkillColorGrid } from 'ab-domain/models/company/base';
import type CompanyBase from 'ab-domain/models/company/base';
import type UserGroupBase from 'ab-domain/models/userGroup/base';
import type NotificationSettingBase from 'ab-domain/models/notificationSetting/base';
import type HolidayBase from 'ab-domain/models/holiday/base';
import type AddressBase from 'ab-domain/models/address/base';
import type WorkDayBase from 'ab-domain/models/workDay/base';

import type NotificationPriority from 'ab-enums/notificationPriority.enum';

import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type UserPermission from '@acceligentllc/shared/enums/userPermission';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type MemberInviteEnum from '@acceligentllc/shared/enums/memberInvite';

import * as BlobStorageUtil from '@acceligentllc/shared/utils/blobStorage';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type AccountBase from 'ab-domain/models/account/base';
import type PermissionBase from 'ab-domain/models/permission/base';
import type UserBase from 'ab-domain/models/user/base';
import type OrganizationBase from 'ab-domain/models/organization/base';
import { getStorageUrl } from '@acceligentllc/shared/utils/blobStorage';

import { PASSWORD_MAX_AGE } from 'ab-common/constants/value';
import type { LoginMethod } from 'ab-enums/auth.enum';

import * as BlobStorageUtil2 from 'ab-utils/blobStorage.util';

export class W_Company_CreateCompany_VM {
	user: W_Company_CreateCompany_VM_User;
	company: W_Company_CreateCompany_VM_Company;

	public static async initialize(
		user: UserBase,
		loginMethod: LoginMethod,
		token: string,
		company: CompanyBase
	): Promise<W_Company_CreateCompany_VM> {
		const _user = await W_Company_CreateCompany_VM_User.initialize(user, loginMethod, token);
		const _company = new W_Company_CreateCompany_VM_Company(company);

		return {
			user: _user,
			company: _company,
		};
	}
}

class W_Company_CreateCompany_VM_Company {
	id: number;
	name: string;
	imageUrl: string;
	address: W_Company_CreateCompany_VM_Address;
	workDays: W_Company_CreateCompany_VM_WorkDay[];
	holidays: W_Company_CreateCompany_VM_Holiday[];
	website: Nullable<string>;
	notification: W_Company_CreateCompany_VM_NotificationSettings;
	displayViewChangeTime: number;
	displayViewRotationTime: number;
	displayViewType: DisplayViewToggle;
	includeNonWorkingDaysOnDisplayView: boolean;
	fiscalYearStartMonth: number;
	skillColorGrid: SkillColorGrid;
	cdlInSkillsGrid: boolean;
	timezone: string;
	dayShiftStart: number;
	dayShiftEnd: number;
	nightShiftStart: number;
	nightShiftEnd: number;
	fieldReportAccessDayDuration: number;
	isEquipmentAutomaticReturnDate: boolean;
	isEmployeeAutomaticReturnDate: boolean;
	isFRManageAllowedToApproveTimeSheet: boolean;
	isFRManageAllowedToEditTimeSheet: boolean;
	isFRManageLevelAllowedInReviewProcess: boolean;
	allowAllNonBillableShortcut: boolean;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	customPDFHeaderUrl: Nullable<string>;
	complianceEhsUrl: Nullable<string>;
	/** YYYY-MM-DD */
	createdAt: string;
	purchaseOrderNumberPrefix: Nullable<string>;

	constructor(company: CompanyBase) {
		this.id = company.id;
		this.name = company.name;

		const result = company.imageUrl && BlobStorageUtil.parseStorageUrl(company.imageUrl);
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}

		this.address = new W_Company_CreateCompany_VM_Address(company.primaryAddress);
		this.workDays = company.workDays && W_Company_CreateCompany_VM_WorkDay.bulkConstructor(company.workDays);
		this.holidays = W_Company_CreateCompany_VM_Holiday.bulkConstructor(company.holidays);
		this.website = company.website;
		if (company.notification) {
			this.notification = new W_Company_CreateCompany_VM_NotificationSettings(company.notification);
		}
		this.displayViewChangeTime = company.displayViewChangeTime;
		this.displayViewRotationTime = company.displayViewRotationTime;
		this.displayViewType = company.displayViewType;
		this.includeNonWorkingDaysOnDisplayView = company.includeNonWorkingDaysOnDisplayView;
		this.fiscalYearStartMonth = company.fiscalYearStartMonth;
		this.skillColorGrid = company.skillColorGrid;
		this.cdlInSkillsGrid = company.cdlInSkillsGrid;
		this.timezone = company.notification?.timezone;
		this.dayShiftStart = company.dayShiftStart;
		this.dayShiftEnd = company.dayShiftEnd;
		this.nightShiftStart = company.nightShiftStart;
		this.nightShiftEnd = company.nightShiftEnd;
		this.fieldReportAccessDayDuration = company.fieldReportAccessDayDuration;
		this.isEquipmentAutomaticReturnDate = company.isEquipmentAutomaticReturnDate;
		this.isEmployeeAutomaticReturnDate = company.isEmployeeAutomaticReturnDate;
		this.isFRManageAllowedToApproveTimeSheet = company.isFRManageAllowedToApproveTimeSheet;
		this.isFRManageAllowedToEditTimeSheet = company.isFRManageAllowedToEditTimeSheet;
		this.isFRManageLevelAllowedInReviewProcess = company.isFRManageLevelAllowedInReviewProcess;
		this.allowAllNonBillableShortcut = company.allowAllNonBillableShortcut;
		this.areWorkRequestsEnabled = company.areWorkRequestsEnabled;
		this.arePortalFunctionalitiesEnabled = company.arePortalFunctionalitiesEnabled;
		this.isSendingInvoiceNotificationsEnabled = company.isSendingInvoiceNotificationsEnabled;
		this.createdAt = TimeUtils.formatDate(company.createdAt, TimeFormat.ISO_DATETIME);
		this.complianceEhsUrl = company.complianceEhsUrl;
		this.purchaseOrderNumberPrefix = company.purchaseOrderNumberPrefix;

		const customHeaderResult = company.customPDFHeaderUrl && BlobStorageUtil.parseStorageUrl(company.customPDFHeaderUrl);
		if (customHeaderResult) {
			this.customPDFHeaderUrl = BlobStorageUtil.getStorageUrl(customHeaderResult.directories, customHeaderResult.filename);
		}
	}
}

class W_Company_CreateCompany_VM_WorkDay {
	id: number;
	companyId: number;
	weekDay: WeekDay;

	constructor(workDay: WorkDayBase) {
		this.id = workDay.id;
		this.companyId = workDay?.company?.id;
		this.weekDay = workDay.weekDay;
	}

	private static _constructorMap = (entry: WorkDayBase) => new W_Company_CreateCompany_VM_WorkDay(entry);

	static bulkConstructor = (workDays: WorkDayBase[]) => workDays.map(W_Company_CreateCompany_VM_WorkDay._constructorMap);
}

class W_Company_CreateCompany_VM_Address {
	id: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: string;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;

	constructor(address: AddressBase) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.locality = address.locality;
		this.postalCode = address.postalCode;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
	}
}

class W_Company_CreateCompany_VM_Holiday {
	id: number;
	name: string;

	constructor(holiday: HolidayBase) {
		this.id = holiday.id;
		this.name = holiday.name;
	}

	static bulkConstructor = (holidays: HolidayBase[] = []): W_Company_CreateCompany_VM_Holiday[] => {
		return holidays.map((_holiday) => new W_Company_CreateCompany_VM_Holiday(_holiday));
	};
}

class W_Company_CreateCompany_VM_UserGroup {
	id: number;
	name: string;

	constructor(userGroup: UserGroupBase) {
		this.id = userGroup.id;
		this.name = userGroup.name;
	}
}

class W_Company_CreateCompany_VM_NotificationSettings {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	enableScheduleBoardEmail: boolean;
	enablePastWorkOrdersNotification: boolean;
	notificationPriority: NotificationPriority;
	notifyOnPublish: Nullable<AutoNotifyOption>;
	notifyOnCancel: Nullable<AutoNotifyOption>;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyScheduleBoardEmail: number;
	notifyUnassignedEmployees: boolean;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	notifyOnDay: number[];
	scheduleBoardEmailUserGroupId: Nullable<number>;
	scheduleBoardEmailUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	pastWorkOrdersNotificationUserGroupId: Nullable<number>;
	pastWorkOrdersNotificationUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroupId: Nullable<number>;
	itemLowQuantityNotificationUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroupId: Nullable<number>;
	dailyItemLowQuantityNotificationUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyYesterdaysBoardAt: number;
	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardUserGroupId: Nullable<number>;
	notifyYesterdaysBoardUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyFailedAt: number;
	enableNotifyFailed: boolean;
	notifyFailedUserGroupId: Nullable<number>;
	notifyFailedUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;
	notifyOnReportReject: boolean;
	notifyOnTimeSheetReject: boolean;
	notifyTemporaryLabor: boolean;
	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;
	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: number;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroupId: Nullable<number>;
	notifyInvoiceRemindersUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroupId: Nullable<number>;
	toolRepairNotificationUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroupId: Nullable<number>;
	notifyOnOrderCreationUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroupId: Nullable<number>;
	notifyForUnfinishedOrdersUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroupId: Nullable<number>;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;
	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<W_Company_CreateCompany_VM_UserGroup>;

	constructor(notification: NotificationSettingBase) {
		this.isEnabled = notification.isEnabled;
		this.isEnabledAutoNotificationsOnWorkDays = notification.isEnabledAutoNotificationsOnWorkDays;
		this.enableScheduleBoardEmail = notification.enableScheduleBoardEmail;
		this.enablePastWorkOrdersNotification = notification.enablePastWorkOrdersNotification;
		this.notificationPriority = notification.notificationPriority;
		this.notifyOnPublish = notification.notifyOnPublish;
		this.notifyOnCancel = notification.notifyOnCancel;
		this.notifyOnPause = notification.notifyOnPause;
		this.notifyOnResume = notification.notifyOnResume;
		this.notifyScheduleBoardEmail = notification.notifyScheduleBoardEmail;
		this.notifyUnassignedEmployees = notification.notifyUnassignedEmployees;
		this.notifyMultipleAssignments = notification.notifyMultipleAssignments;
		this.notifySupervisors = notification.notifySupervisors;
		this.notifyOnDay = [
			notification.notifyOnSunday,
			notification.notifyOnMonday,
			notification.notifyOnTuesday,
			notification.notifyOnWednesday,
			notification.notifyOnThursday,
			notification.notifyOnFriday,
			notification.notifyOnSaturday,
		];
		this.scheduleBoardEmailUserGroupId = notification.scheduleBoardEmailUserGroupId;
		this.scheduleBoardEmailUserGroup = notification.scheduleBoardEmailUserGroup
			&& new W_Company_CreateCompany_VM_UserGroup(notification.scheduleBoardEmailUserGroup);
		this.pastWorkOrdersNotificationUserGroupId = notification.pastWorkOrdersNotificationUserGroupId;
		this.pastWorkOrdersNotificationUserGroup = notification.pastWorkOrdersNotificationUserGroup
			&& new W_Company_CreateCompany_VM_UserGroup(notification.pastWorkOrdersNotificationUserGroup);
		this.enableNotifyYesterdaysBoard = notification.enableNotifyYesterdaysBoard;
		this.notifyYesterdaysBoardAt = notification.notifyYesterdaysBoardAt;
		this.notifyYesterdaysBoardUserGroupId = notification.notifyYesterdaysBoardUserGroupId;
		this.notifyYesterdaysBoardUserGroup = notification.notifyYesterdaysBoardUserGroup
			&& new W_Company_CreateCompany_VM_UserGroup(notification.notifyYesterdaysBoardUserGroup);
		this.enableNotifyFailed = notification.enableNotifyFailed;
		this.notifyFailedAt = notification.notifyFailedAt;
		this.notifyFailedUserGroupId = notification.notifyFailedUserGroupId;
		this.notifyFailedUserGroup = notification.notifyFailedUserGroup && new W_Company_CreateCompany_VM_UserGroup(notification.notifyFailedUserGroup);
		this.enableNotifyQAQC = notification.enableNotifyQAQC;
		this.enableNotifyDeliverableAssignee = notification.enableNotifyDeliverableAssignee;
		this.enableAutoNotifyDailyTips = notification.enableAutoNotifyDailyTips;
		this.notifyOnTimeSheetReject = notification.notifyOnTimeSheetReject;
		this.notifyOnReportReject = notification.notifyOnReportReject;
		this.notifyTemporaryLabor = notification.notifyTemporaryLabor;
		this.enableUnfinishedPurchaseOrderCreatedNotification = notification.enableUnfinishedPurchaseOrderCreatedNotification;
		this.enablePurchaseOrderWithUnreceivedItemsNotification = notification.enablePurchaseOrderWithUnreceivedItemsNotification;
		this.enableInvoiceRemindersNotification = notification.enableInvoiceRemindersNotification;
		this.notifyInvoiceRemindersAt = notification.notifyInvoiceRemindersAt;
		this.notifyInvoiceRemindersFrom = notification.notifyInvoiceRemindersFrom;
		this.notifyInvoiceRemindersUserGroupId = notification.notifyInvoiceRemindersUserGroupId;
		this.notifyInvoiceRemindersUserGroup = notification.notifyInvoiceRemindersUserGroup
			&& new W_Company_CreateCompany_VM_UserGroup(notification.notifyInvoiceRemindersUserGroup);
		this.notifyOnOrderCreation = notification.notifyOnOrderCreation;
		this.notifyOnOrderCreationUserGroupId = notification.notifyOnOrderCreationUserGroupId;
		this.notifyOnOrderCreationUserGroup = notification.notifyOnOrderCreationUserGroup
			&& new W_Company_CreateCompany_VM_UserGroup(notification.notifyOnOrderCreationUserGroup);
		this.notifyForUnfinishedOrders = notification.notifyForUnfinishedOrders;
		this.notifyForUnfinishedOrdersUserGroupId = notification.notifyForUnfinishedOrdersUserGroupId;
		this.notifyForUnfinishedOrdersUserGroup = notification.notifyForUnfinishedOrdersUserGroup &&
			new W_Company_CreateCompany_VM_UserGroup(notification.notifyForUnfinishedOrdersUserGroup);
		this.enableItemLowQuantityNotification = notification.enableItemLowQuantityNotification;
		this.itemLowQuantityNotificationUserGroupId = notification.itemLowQuantityNotificationUserGroupId;
		this.itemLowQuantityNotificationUserGroup = notification.itemLowQuantityNotificationUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.itemLowQuantityNotificationUserGroup)
			: null;
		this.enableDailyItemLowQuantityNotification = notification.enableDailyItemLowQuantityNotification;
		this.dailyItemLowQuantityNotificationUserGroupId = notification.dailyItemLowQuantityNotificationUserGroupId;
		this.dailyItemLowQuantityNotificationUserGroup = notification.dailyItemLowQuantityNotificationUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.dailyItemLowQuantityNotificationUserGroup)
			: null;
		this.enableToolRepairNotification = notification.enableToolRepairNotification;
		this.toolRepairNotificationUserGroupId = notification.toolRepairNotificationUserGroupId;
		this.toolRepairNotificationUserGroup = notification.toolRepairNotificationUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.toolRepairNotificationUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseDaily = notification.notifyForOrdersTotalExpenseDaily;
		this.notifyForOrdersTotalExpenseDailyUserGroup = notification.notifyForOrdersTotalExpenseDailyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForOrdersTotalExpenseDailyUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseWeekly = notification.notifyForOrdersTotalExpenseWeekly;
		this.notifyForOrdersTotalExpenseWeeklyUserGroup = notification.notifyForOrdersTotalExpenseWeeklyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForOrdersTotalExpenseWeeklyUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseMonthly = notification.notifyForOrdersTotalExpenseMonthly;
		this.notifyForOrdersTotalExpenseMonthlyUserGroup = notification.notifyForOrdersTotalExpenseMonthlyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForOrdersTotalExpenseMonthlyUserGroup)
			: null;
		this.notifyForDuplicateOrdersMonthly = notification.notifyForDuplicateOrdersMonthly;
		this.notifyForDuplicateOrdersMonthlyUserGroup = notification.notifyForDuplicateOrdersMonthlyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForDuplicateOrdersMonthlyUserGroup)
			: null;
		this.notifyForInexpensiveOrdersWeekly = notification.notifyForInexpensiveOrdersWeekly;
		this.notifyForInexpensiveOrdersWeeklyUserGroup = notification.notifyForInexpensiveOrdersWeeklyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForInexpensiveOrdersWeeklyUserGroup)
			: null;
		this.notifyForInexpensiveOrdersMonthly = notification.notifyForInexpensiveOrdersMonthly;
		this.notifyForInexpensiveOrdersMonthlyUserGroup = notification.notifyForInexpensiveOrdersMonthlyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForInexpensiveOrdersMonthlyUserGroup)
			: null;
		this.notifyForExpensiveOrdersWeekly = notification.notifyForExpensiveOrdersWeekly;
		this.notifyForExpensiveOrdersWeeklyUserGroup = notification.notifyForExpensiveOrdersWeeklyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForExpensiveOrdersWeeklyUserGroup)
			: null;
		this.notifyForExpensiveOrdersMonthly = notification.notifyForExpensiveOrdersMonthly;
		this.notifyForExpensiveOrdersMonthlyUserGroup = notification.notifyForExpensiveOrdersMonthlyUserGroup
			? new W_Company_CreateCompany_VM_UserGroup(notification.notifyForExpensiveOrdersMonthlyUserGroup)
			: null;
	}
}

class W_Company_CreateCompany_VM_OrganizationData {
	organizationId: number;
	name: string;
	alias: string;
	/** Can be empty if accounts not passed */
	companies: W_Company_CreateCompany_VM_CompanyData[];
	isPlatformAdmin: boolean;

	public static async initialize(organization: OrganizationBase, accounts: AccountBase[]): Promise<W_Company_CreateCompany_VM_OrganizationData> {
		const _companies = await Promise.all((accounts ?? [])
			.map(W_Company_CreateCompany_VM_CompanyData.initialize));

		return {
			organizationId: organization.id,
			name: organization.name,
			alias: organization.alias,
			isPlatformAdmin: organization.isPlatformAdmin,
			companies: _companies
				.sort((_company1: W_Company_CreateCompany_VM_CompanyData, _company2: W_Company_CreateCompany_VM_CompanyData) =>
					(_company1.name.localeCompare(_company2.name))
				),
		};
	}
}

class W_Company_CreateCompany_VM_UserData {
	/** -1 if platform admin */
	id: number;
	firstName: string;
	lastName: string;
	token: Nullable<string>;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	isFinalized: boolean;
	status: MemberInviteEnum;
	role: UserPermission;
	imageUrl: string;
	offlinePin: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	digitalSignatureUrl: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	showCreateDigitalSignature: boolean;
	isPasswordExpired: boolean;
	loginMethod: LoginMethod;
	passwordResetRequired: boolean;
	activeInLMS: boolean;

	public static async initialize(user: UserBase, loginMethod: LoginMethod, token?: string): Promise<W_Company_CreateCompany_VM_UserData> {
		const signature = user.digitalSignature;
		const digitalSignatureUrl = (user.isDigitalSignatureEnabled && signature)
			? getStorageUrl(signature.signatureAttachment.storageContainer, signature.signatureAttachment.storageName)
			: null;
		const digitalSignatureId = (user.isDigitalSignatureEnabled && signature)
			? signature.id
			: null;

		const result = user.imageUrl && BlobStorageUtil.parseStorageUrl(user.imageUrl);
		let imageUrl = '';
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			imageUrl = await BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}

		return {
			id: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			token: token ?? null,
			email: user.email,
			phoneNumber: user.phoneNumber,
			countryCode: user.countryCode,
			isFinalized: user.isFinalizedByEmail,
			status: user.status,
			role: user.role,
			offlinePin: user.offlinePin,
			isDigitalSignatureEnabled: user.isDigitalSignatureEnabled,
			showCreateDigitalSignature: user.showCreateDigitalSignature,
			isPasswordExpired: user.passwordHistory?.length ? TimeUtils.getDiff(new Date(), user.passwordHistory[0].createdAt, 'days') > PASSWORD_MAX_AGE : false,
			loginMethod: loginMethod,
			passwordResetRequired: user.passwordResetRequired,
			activeInLMS: user.activeInLMS,
			digitalSignatureId,
			digitalSignatureUrl,
			imageUrl,
		};
	}
}

class W_Company_CreateCompany_VM_CompanyData {
	id: number;
	name: string;
	permissions: string[];
	accountId: number;
	accountTemplate: AccountPermissionTemplate;
	logo: string;
	isCompanyAdmin: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	assignableAsAccounting: boolean;
	complianceEhsUrl: Nullable<string>;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	purchaseOrderNumberPrefix: Nullable<string>;
	customPDFHeaderUrl: Nullable<string>;
	website: Nullable<string>;
	location: Nullable<string>;

	public static async initialize(account: AccountBase): Promise<W_Company_CreateCompany_VM_CompanyData> {
		const parseResult = account.company.imageUrl ? BlobStorageUtil.parseStorageUrl(account.company.imageUrl) : null;
		const customPDFHeaderParseResult = account.company.customPDFHeaderUrl ? BlobStorageUtil.parseStorageUrl(account.company.customPDFHeaderUrl) : null;
		let customPDFHeaderUrl = '';
		if (customPDFHeaderParseResult) {
			customPDFHeaderUrl = await BlobStorageUtil2.generatePresignedGetUrl(customPDFHeaderParseResult.directories, customPDFHeaderParseResult.filename);
		}

		return {
			name: account.company.name,
			id: account.company.id,
			permissions: account.permissions.map((_permission: PermissionBase) => (_permission.permission.toString())),
			accountId: account.id,
			accountTemplate: account.accountTemplate,
			logo: parseResult ? BlobStorageUtil.getStorageUrl(parseResult.directories, parseResult.filename) : '',
			isCompanyAdmin: account.isCompanyAdmin,
			assignableToWorkOrder: account.assignableToWorkOrder,
			assignableAsSuperintendent: account.assignableAsSuperintendent,
			assignableAsProjectManager: account.assignableAsProjectManager,
			assignableAsQAQC: account.assignableAsQAQC,
			assignableAsManagement: account.assignableAsManagement,
			assignableAsTechnician: account.assignableAsTechnician,
			assignableAsAccounting: account.assignableAsAccounting,
			complianceEhsUrl: account.company.complianceEhsUrl,
			areWorkRequestsEnabled: account.company.areWorkRequestsEnabled,
			arePortalFunctionalitiesEnabled: account.company.arePortalFunctionalitiesEnabled,
			isSendingInvoiceNotificationsEnabled: account.company.isSendingInvoiceNotificationsEnabled,
			purchaseOrderNumberPrefix: account.company.purchaseOrderNumberPrefix,
			customPDFHeaderUrl,
			website: account.company.website,
			location: account.company.primaryAddress?.street,
		};
	}
}

/** NOTE: This view model is kept in localstorage on FE. Do not send entire User data from here */
class W_Company_CreateCompany_VM_User {
	organization: W_Company_CreateCompany_VM_OrganizationData;
	user: W_Company_CreateCompany_VM_UserData;

	public static async initialize(user: UserBase, loginMethod: LoginMethod, token: string): Promise<W_Company_CreateCompany_VM_User> {
		const _user = await W_Company_CreateCompany_VM_UserData.initialize(user, loginMethod, token);
		const _organization = await W_Company_CreateCompany_VM_OrganizationData.initialize(user.organization, user.accounts);

		return {
			user: _user,
			organization: _organization,
		};
	}
}
