import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import { CompoundUnitEnum, PlainUnitEnum, QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';
import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';

import * as CodeUtils from '@acceligentllc/shared/utils/codes';
import type AddressBase from 'ab-domain/models/address/base';

import type FieldReportBlockBase from 'ab-domain/models/fieldReportBlock/base';
import type FieldReportBlockFieldBase from 'ab-domain/models/fieldReportBlockField/base';
import type WorkSummaryDetailsBase from 'ab-domain/models/workSummaryDetails/base';
import type WorkSummaryDetailsDefinitionFieldBase from 'ab-domain/models/workSummaryDetailsDefinitionField/base';
import type WorkSummaryDetailsInformationFieldBase from 'ab-domain/models/workSummaryDetailsInformationField/base';

interface LeadingBlockTitlesMeta {
	reportTypeName: string;
	primary: Nullable<string>;
	secondary: Nullable<string>;
}

type LeadingBlockTitlesMetaMap = { [fieldReportTypeId: string]: LeadingBlockTitlesMeta; };

enum WORK_SUMMARY_DETAILS_TABLE_HEADERS {
	BILLABLE = 'Billable',
	DATE = 'Date',
	WORK_ORDER = 'Work Order',
	FR_SEGMENT = 'FR Segment',
	FR_SECONDARY_SEGMENT = 'FR Secondary Segment',
	WORK = 'Work',
	TYPE = 'Type',
	DEFINITION_FIELD = 'Definition',
	QUANITITY = 'Quanitity',
	UNIT = 'Unit',
	INFORMATIONAL_FIELD = 'Info',
	BILLING_CODE_ID = 'Billing code ID',
	BILLING_CODE_DESCRIPTION = 'Billing code description'
}

const LABEL_WORK_TYPE_FIELD_TYPES = {
	[ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE]: true,
	[ReportBlockFieldEnum.Type.CALCULATED]: true,
};

export function createLeadingBlockTitlesMetaMap(fieldReportBlocks: FieldReportBlockBase[]): LeadingBlockTitlesMetaMap {
	return fieldReportBlocks.reduce<LeadingBlockTitlesMetaMap>((_agg, _fieldReportBlock) => {
		const { fieldReportTypeId, segmentIndex, reportBlock } = _fieldReportBlock;
		if (!_agg[fieldReportTypeId]) {
			_agg[fieldReportTypeId] = { primary: null, secondary: null, reportTypeName: _fieldReportBlock.fieldReportType.name ?? '' };
		}
		if (segmentIndex === 0) {
			_agg[fieldReportTypeId].primary = reportBlock.name;
		} else {
			_agg[fieldReportTypeId].secondary = reportBlock.name;
		}
		return _agg;
	}, {});
}

function _resolveFieldValue(field: FieldReportBlockFieldBase, repeatableIndex: Nullable<number>) {
	const { value, fieldReportBlock: { reportBlock: { isRepeating } } } = field;

	if (!isRepeating) {
		return value;
	}

	if (repeatableIndex === null) {
		throw new Error('repeatableIndex not present for repeating block');
	}

	// Value can be boolean in case where one of definition fields or type field is a checkbox
	const jsonValue = value as Stringified<(string | boolean)[]>;
	const values = JSON.parse<(string | boolean)[]>(jsonValue);

	return values?.[repeatableIndex]?.toString() ?? null;
}

function _resolveInformationFieldValue(field: FieldReportBlockFieldBase, repeatableIndex: Nullable<number>) {
	const { value, fieldReportBlock: { reportBlock: { isRepeating } } } = field;

	if (!isRepeating) {
		return value;
	}

	const jsonValue = value as Stringified<string[]>;
	const values = JSON.parse<string[]>(jsonValue);

	if (repeatableIndex === null) {
		return values.join(', ');
	}

	return values[repeatableIndex] ?? null;
}

function _resolveDefaultQuantityValue(field: FieldReportBlockFieldBase, repeatableIndex: Nullable<number>, defaultQuantity: number) {
	const { value, fieldReportBlock: { reportBlock: { isRepeating } } } = field;

	if (!isRepeating) {
		return value ? defaultQuantity : null;
	}

	if (repeatableIndex === null) {
		throw new Error('repeatableIndex not present for repeating block');
	}

	const jsonValue = value as Stringified<string[]>;
	const values = JSON.parse<string[]>(jsonValue);

	return values[repeatableIndex] ? defaultQuantity : null;
}

function _resolveFieldValueForExport(value: Nullable<string>, type: ReportBlockFieldEnum.Type) {
	if (!value) {
		return 'N/A';
	}

	let resolvedValue = value;

	if (type === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = new Boolean(value) ? 'Yes' : 'No';
	}

	if (type === ReportBlockFieldEnum.Type.ADDRESS) {
		// since non repeatable address fields are saved as stringified objects
		const isNonRepeatingField = (typeof value === 'string');

		return isNonRepeatingField
			? JSON.parse(value).street
			: (value as unknown as AddressBase)?.street;
	}

	return resolvedValue;
}

function _resolveUnit(unit: Nullable<QuantityUnitType>) {
	if (!unit) {
		return null;
	}
	switch (unit) {
		case CompoundUnitEnum.FT_IN: return PlainUnitEnum.IN;
		case CompoundUnitEnum.CFT_CIN: return PlainUnitEnum.CIN;
		case CompoundUnitEnum.HH_MM: return PlainUnitEnum.MIN;
		case CompoundUnitEnum.LFT_LIN: return PlainUnitEnum.LIN;
		case CompoundUnitEnum.SFT_SIN: return PlainUnitEnum.SIN;
		case CompoundUnitEnum.VFT_VIN: return PlainUnitEnum.VIN;
	}
	return unit;
}

export interface FieldVM {
	id: number;
	value: Nullable<string>;
	name: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
	index: number;
}

class DefinitionFieldVM implements FieldVM {
	id: number;
	value: Nullable<string>;
	name: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
	index: number;

	constructor(field: WorkSummaryDetailsDefinitionFieldBase, wsd: WorkSummaryDetailsBase) {
		const { reportBlockField } = field.fieldReportBlockField;
		const resolvedValue = _resolveFieldValue(field.fieldReportBlockField, wsd.repeatableIndex);

		this.id = field.id;
		this.value = resolvedValue;
		this.name = reportBlockField.name;
		this.fieldType = reportBlockField.fieldType;
		this.fieldUnit = _resolveUnit(reportBlockField.unit ?? null);
		this.index = field.index;
	}
}

class InformationFieldVM implements FieldVM {
	id: number;
	value: Nullable<string>;
	name: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
	index: number;

	constructor(field: WorkSummaryDetailsInformationFieldBase, wsd: WorkSummaryDetailsBase) {
		const { reportBlockField } = field.fieldReportBlockField;
		const resolvedValue = _resolveInformationFieldValue(field.fieldReportBlockField, wsd.repeatableIndex);

		this.id = field.id;
		this.value = resolvedValue;
		this.name = reportBlockField.name;
		this.fieldType = reportBlockField.fieldType;
		this.fieldUnit = _resolveUnit(reportBlockField.unit ?? null);
		this.index = field.index;
	}
}

class WorkSummaryDetailVM {
	id: number;
	subJobId: number;
	billableWorkId: number;
	date: string;
	workOrder: string;
	frSegment: string;
	frSegmentSecondary: Nullable<string>;
	workName: string;
	workType: Nullable<string>;
	workTypeFieldName: string;
	workTypeFieldValueType: ReportBlockFieldEnum.ValueType;
	workTypeFieldType: ReportBlockFieldEnum.Type;
	quantity: Nullable<number>;
	unit: Nullable<string>;
	definitionFields: FieldVM[];
	informationalFields: FieldVM[];
	billingCodeId: Nullable<number>;
	isBillable: boolean;
	workSummaryGroup: Nullable<string>;
	quantityFieldId: Nullable<number>;
	fieldReportTypeId: number;
	billingCodeCustomerId: Nullable<string>;
	billingCodeDescription: Nullable<string>;

	constructor(wsd: WorkSummaryDetailsBase, leadingBlockTitlesMeta: LeadingBlockTitlesMeta) {
		const frBlock = wsd.workQuantityFieldReportBlockField?.fieldReportBlock ?? wsd.workTypeFieldReportBlockField.fieldReportBlock;
		const { instanceIndex, segmentIndex } = frBlock;
		const isPrimary = segmentIndex === 0;

		const fieldReport = wsd.getFieldReport();
		if (!fieldReport) {
			throw new Error('Field report not defined');
		}

		this.id = wsd.id;
		this.subJobId = wsd.subJobId;
		this.billableWorkId = wsd.billableWorkId;
		this.date = fieldReport.workOrder.dueDate;
		this.workOrder = CodeUtils.workOrderCode(fieldReport.workOrder, fieldReport.workOrder.workRequest);
		this.frSegment = `${leadingBlockTitlesMeta.reportTypeName} - ${leadingBlockTitlesMeta.primary} ${(instanceIndex ?? 0) + 1}`;
		this.frSegmentSecondary = !isPrimary ? `${leadingBlockTitlesMeta.secondary} ${(instanceIndex ?? 0) + 1}.${segmentIndex}` : null;
		this.workName = wsd.billableWork.workName;
		this.workType = !!LABEL_WORK_TYPE_FIELD_TYPES[wsd.workTypeFieldReportBlockField.reportBlockField.fieldType]
			? wsd.workTypeFieldReportBlockField.reportBlockField.name
			: _resolveFieldValue(wsd.workTypeFieldReportBlockField, wsd.repeatableIndex);
		this.workTypeFieldName = wsd.workTypeFieldReportBlockField.reportBlockField.name;
		this.workTypeFieldValueType = wsd.workTypeFieldReportBlockField.reportBlockField.valueType;
		this.workTypeFieldType = wsd.workTypeFieldReportBlockField.reportBlockField.fieldType;
		this.quantity = WorkSummaryDetailVM.parseQuantity(wsd);
		const _resolvedUnit = _resolveUnit(wsd.workQuantityFieldReportBlockField?.reportBlockField?.unit ?? wsd.billingCode?.unit ?? null);
		this.unit = _resolvedUnit ? QuantityUnitMap[_resolvedUnit] ?? null : null;

		this.definitionFields = WorkSummaryDetailVM._sortDefinitionFieldsByIndex(wsd.definitionFields).map((_field) => new DefinitionFieldVM(_field, wsd));

		this.informationalFields = wsd.informationFields.map((_field) => new InformationFieldVM(_field, wsd));
		this.billingCodeId = wsd.billingCodeId;
		this.isBillable = wsd.isBillable;
		this.workSummaryGroup = wsd.groupName;
		this.quantityFieldId = wsd.workQuantityFieldReportBlockField?.id ?? null;
		this.fieldReportTypeId = frBlock.fieldReportTypeId;

		this.billingCodeCustomerId = wsd.billingCode?.customerId ?? null;
		this.billingCodeDescription = wsd.billingCode?.description ?? null;
	}

	private static _sortDefinitionFieldsByIndex = (_definitionFields: WorkSummaryDetailsDefinitionFieldBase[]) => {
		return _definitionFields.toSorted((a, b) => a.index - b.index);
	};

	private static _sortWorkSummaryDetails = (_wsda: WorkSummaryDetailVM, _wsdb: WorkSummaryDetailVM) => {
		const _workSummaryDetailA = `${_wsda.frSegment}-${_wsda.frSegmentSecondary}`;
		const _workSummaryDetailB = `${_wsdb.frSegment}-${_wsdb.frSegmentSecondary}`;

		return _workSummaryDetailA.localeCompare(_workSummaryDetailB);
	};

	private static parseQuantity = (wsd: WorkSummaryDetailsBase): Nullable<number> => {
		const { repeatableIndex, workQuantityFieldReportBlockField, workTypeFieldReportBlockField, billableWork } = wsd;

		let numberValue: Nullable<number> = null;
		if (workQuantityFieldReportBlockField) {
			const rawValue = _resolveFieldValue(workQuantityFieldReportBlockField, repeatableIndex);
			if (!!rawValue && /^[-+]?[0-9]*\.?[0-9]+$/.test(rawValue)) {
				numberValue = Number(rawValue);
			}
		} else if (billableWork.defaultQuantity) {
			numberValue = Number(_resolveDefaultQuantityValue(workTypeFieldReportBlockField, repeatableIndex, billableWork.defaultQuantity));
		} else {
			throw new Error('Missing both work quantity field and default quantity');
		}

		return numberValue;
	};

	private static isValueEmpty = (value: Nullable<string>, fieldType: ReportBlockFieldEnum.Type) => (
		value === null
		|| value === ''
		|| (fieldType === ReportBlockFieldEnum.Type.BOOLEAN && value !== 'true')
	);

	private static isFieldValueEmpty = (field: FieldVM) => WorkSummaryDetailVM.isValueEmpty(field.value, field.fieldType);

	static bulkConstructor = (wsds: WorkSummaryDetailsBase[], leadingBlockTitlesMeta: { [fieldReportTypeId: string]: LeadingBlockTitlesMeta; }) => {
		// filtering in memory because some fields can be repeatable making their values JSON arrays - querrying that
		// in the DB layer would potentially be slow and awkward
		const workSummaryDetailVMs = wsds.reduce<WorkSummaryDetailVM[]>((_acc, _wsd) => {
			const block = _wsd.workQuantityFieldReportBlockField?.fieldReportBlock ?? _wsd.workTypeFieldReportBlockField.fieldReportBlock;
			const vm = new WorkSummaryDetailVM(_wsd, leadingBlockTitlesMeta[block.fieldReportTypeId]);
			if (
				vm.quantity === null
				|| WorkSummaryDetailVM.isValueEmpty(vm.workType, vm.workTypeFieldType)
				|| vm.definitionFields.some(WorkSummaryDetailVM.isFieldValueEmpty)
			) {
				return _acc;
			}
			_acc.push(vm);
			return _acc;
		}, []);
		return workSummaryDetailVMs.sort(WorkSummaryDetailVM._sortWorkSummaryDetails);
	};

	private static maxDefinitionFieldsReducer = (_max: number, _currentWSD: WorkSummaryDetailVM) => {
		return _currentWSD.definitionFields.length > _max ? _currentWSD.definitionFields.length : _max;
	};

	private static maxInformationalFieldsReducer = (_max: number, _currentWSD: WorkSummaryDetailVM) => {
		return _currentWSD.informationalFields.length > _max ? _currentWSD.informationalFields.length : _max;
	};

	private static resolveWorkSummaryDetailsTableHeaders = (workSummaryDetails: WorkSummaryDetailVM[]) => {
		const maxNumberOfDefinitionFields = workSummaryDetails.reduce(WorkSummaryDetailVM.maxDefinitionFieldsReducer, 0);
		const maxNumberOfInformationalFields = workSummaryDetails.reduce(WorkSummaryDetailVM.maxInformationalFieldsReducer, 0);

		const headers: string[] = [];
		const headerTemplateKeys = Object.keys(WORK_SUMMARY_DETAILS_TABLE_HEADERS);
		for (const headerTemplateKey of headerTemplateKeys) {
			if (WORK_SUMMARY_DETAILS_TABLE_HEADERS[headerTemplateKey] === WORK_SUMMARY_DETAILS_TABLE_HEADERS.DEFINITION_FIELD) {
				for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
					headers.push(`${WORK_SUMMARY_DETAILS_TABLE_HEADERS[headerTemplateKey]} ${i + 1}`);
				}
			}
			else if (WORK_SUMMARY_DETAILS_TABLE_HEADERS[headerTemplateKey] === WORK_SUMMARY_DETAILS_TABLE_HEADERS.INFORMATIONAL_FIELD) {
				for (let i = 0; i < maxNumberOfInformationalFields; i++) {
					headers.push(`${WORK_SUMMARY_DETAILS_TABLE_HEADERS[headerTemplateKey]} ${i + 1}`);
				}
			}
			else {
				headers.push(WORK_SUMMARY_DETAILS_TABLE_HEADERS[headerTemplateKey]);
			}
		}

		return headers;
	};

	static toCSVData = (workSummaryDetails: WorkSummaryDetailVM[]): string[][] => {
		const headers = WorkSummaryDetailVM.resolveWorkSummaryDetailsTableHeaders(workSummaryDetails);
		const maxNumberOfDefinitionFields = workSummaryDetails.reduce(WorkSummaryDetailVM.maxDefinitionFieldsReducer, 0);
		const maxNumberOfInformationalFields = workSummaryDetails.reduce(WorkSummaryDetailVM.maxInformationalFieldsReducer, 0);
		const rows: string[][] = workSummaryDetails.map((_wsd) => {
			const definitionFieldsValues: string[] = [];
			const informationalFieldsValues: string[] = [];
			for (let i = 0; i < maxNumberOfDefinitionFields; i++) {
				const defName = _wsd.definitionFields[i]?.name ? `${_wsd.definitionFields[i]?.name}: ` : '';
				const defValue = _wsd.definitionFields[i] ? _resolveFieldValueForExport(_wsd.definitionFields[i].value, _wsd.definitionFields[i].fieldType) : 'N/A';
				definitionFieldsValues.push(`${defName}${defValue}`);
			}

			for (let i = 0; i < maxNumberOfInformationalFields; i++) {
				const defName = _wsd.informationalFields[i]?.name ? `${_wsd.informationalFields[i]?.name}: ` : '';
				const defValue = _wsd.informationalFields[i] ? _resolveFieldValueForExport(_wsd.informationalFields[i].value, _wsd.informationalFields[i].fieldType) : 'N/A';
				informationalFieldsValues.push(`${defName}${defValue}`);
			}

			const workTypeAdjustedName = _wsd.workTypeFieldValueType === ReportBlockFieldEnum.ValueType.NUMBER
				? _wsd.workTypeFieldName
				: `${_wsd.workTypeFieldName}: ${_resolveFieldValueForExport(_wsd.workType, _wsd.workTypeFieldType)}`;

			return [
				_wsd.isBillable ? 'Yes' : 'No',
				_wsd.date,
				_wsd.workOrder,
				_wsd.frSegment ?? 'N/A',
				_wsd.frSegmentSecondary ?? 'N/A',
				_wsd.workName ?? 'N/A',
				_wsd.workType ? workTypeAdjustedName : 'N/A',
				...definitionFieldsValues,
				_wsd.quantity + '',
				_wsd.unit ?? 'N/A',
				...informationalFieldsValues,
				_wsd.billingCodeCustomerId ?? 'N/A',
				_wsd.billingCodeDescription ?? 'N/A',
			];
		});

		return [headers, ...rows];
	};

}

export default WorkSummaryDetailVM;
