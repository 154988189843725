import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	closeModal: () => void;
	showModal: boolean;
	updatedByFullName: Nullable<string>;
}

type Props = OwnProps;

export default class FieldReportTypeRemovedInfoModal extends React.PureComponent<Props> {
	render() {
		const { closeModal, showModal, updatedByFullName } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="warning"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Field Report Type Removed"
				/>
				<CustomModal.Body>
					{updatedByFullName ?? 'A user'} removed the report type you were working on.
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Understood"
						onClick={closeModal}
						style="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}
