/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import type { FinalState } from 'react-table-6';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { StaticContext } from 'react-router';
import { nanoid } from 'nanoid';

import ReportTypeStatusEnum from '@acceligentllc/shared/enums/reportTypeStatus';

import type { RootState } from 'af-reducers';

import * as ReportBlockActions from 'af-actions/reportBlock';
import * as ReportTypeActions from 'af-actions/reportType';

import type { ReportBlockFieldVM } from 'ab-viewModels/reportBlock/reportBlockTable.viewModel';
import type ReportBlockTableVM from 'ab-viewModels/reportBlock/reportBlockTable.viewModel';
import type { CustomReportTypeTableRowVM } from 'ab-viewModels/reportType/reportTypeTable.viewModel';
import type ReportTypeLocationVM from 'ab-viewModels/reportType/locationState.viewModel';

import type { ReportBlockFieldRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import type { TabProps, Column, ButtonData, RowInfo, ItemBlueprint } from 'af-components/Table6';
import Table from 'af-components/Table6';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import BooleanCell from 'af-components/Table6/Cells/BooleanCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';

import { isAllowed } from 'ab-utils/auth.util';
import { bemBlock } from 'ab-utils/bem.util';
import { isValidPositiveInteger } from 'ab-utils/validation.util';
import { initDefaultCompletionFieldRMValues } from 'ab-utils/reportBlockField.util';

import { isEmpty } from 'af-utils/object.util';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import ReportBlockModal from '../ReportBlock/Modals/ReportBlockModal';
import ReportTypeModal from '../Shared/ReportTypeModal';

import ReportBlockActionConfirmationBody from '../ReportBlock/Modals/ReportBlockActionConfirmationBody';
import { ReportBlockFormModel } from '../Shared/formModel';

import FieldTypeCell from './FieldTypeCell';

type ReportBlockFieldsGroups = {
	keyFields: ReportBlockFieldVM[];
	plainFields: ReportBlockFieldVM[];
};

interface LocationState {
	orgAlias: string;
	reportType: ReportTypeLocationVM;
}

type OwnProps = CustomRouteComponentProps<void, StaticContext, LocationState>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const NAME_COLUMN_WIDTH = 100;

const _getModalBody = (blockName: string) => `Delete of Report Block (${blockName}) will also remove it from the following Report Types, and will not be included in any new Field Reports:`;

const BREADCRUMBS = [{ label: 'Field Report Elements' }];

const REPORT_TYPE_COLUMNS: Column<CustomReportTypeTableRowVM>[] = [
	{
		accessor: 'name',
		width: NAME_COLUMN_WIDTH,
		Header: 'Type Name',
		Cell: ({ original }) => original.name,
	},
	{
		accessor: 'isRequired',
		Header: 'Required',
		sortable: false,
		Cell: ({ original }) => <BooleanCell value={!!original.isRequired} />,
	},
	{
		accessor: 'description',
		Header: 'Description',
		sortable: false,
		Cell: ({ original }) => 'description' in original ? original.description : <EmptyCell />,
	},
	{
		accessor: 'revision',
		Header: 'Revision',
		Cell: ({ original }) => 'revision' in original ? original.revision : <EmptyCell />,
	},
	{
		accessor: 'status',
		Header: 'Status',
		Cell: ({ original }) => {
			if (original.status === ReportTypeStatusEnum.ACTIVE) {
				return <span className="text-green">Active</span>;
			}
			return <span className="text-grey">Inactive</span>;
		},
	},
	{
		Header: 'Updated',
		accessor: 'updatedAt',
		Cell: ({ original }) => <LastUpdatedByCell isLeftAligned={true} updatedAt={original.updatedAt} updatedBy={original.updatedBy} />,
	},
];

const REPORT_BLOCK_COLUMNS: Column<ReportBlockTableVM>[] = [
	{
		accessor: 'name',
		width: NAME_COLUMN_WIDTH,
		Header: 'Block Name',
		Cell: ({ original }) => original.name,
	},
	{
		accessor: 'uniqueId',
		width: NAME_COLUMN_WIDTH,
		Header: 'Unique Id',
		Cell: ({ original }) => original.uniqueId ?? <EmptyCell />,
	},
	{
		Header: 'Block Type',
		accessor: 'isMain',
		Cell: ({ original }) => original.isMain ? 'Primary' : 'Secondary',
	},
	{
		Header: 'Key/Other',
		accessor: 'reportBlockFields',
		sortable: false,
		Cell: ({ original }) => {
			return _renderFieldTypeCell(original.reportBlockFields);
		},
	},
	{
		Header: 'Has Completion Status',
		accessor: 'completionReportBlockFieldId',
		Cell: ({ original }) => <BooleanCell value={!!original.completionReportBlockFieldId} />,
	},
];

const _groupReportBlockFields = (_acc: ReportBlockFieldsGroups, _item: ReportBlockFieldVM) => {
	if (_item.isKeyParameter) {
		_acc.keyFields.push(_item);
	} else {
		_acc.plainFields.push(_item);
	}
	return _acc;
};

const _getReportBlockRowClassName = (state: FinalState<ReportBlockTableVM>, rowInfo: RowInfo<ReportBlockTableVM>) => {
	const { data } = state;

	if (data.length === 1 && isEmpty(data[0] as unknown as Record<string, unknown>)) {
		return '';
	}
	return bemBlock('report-table__block-row', [rowInfo.original.isMain ? 'main' : 'not-main']);
};

const _renderFieldTypeCell = (fields: ReportBlockTableVM['reportBlockFields']) => {
	const { keyFields, plainFields } = (fields ?? []).reduce(_groupReportBlockFields, { keyFields: [], plainFields: [] });
	return <FieldTypeCell keyFields={keyFields} plainFields={plainFields} />;
};

const _deleteReportBlockModalTitle = (original: ReportBlockTableVM) => `Are you sure you want to delete this Report Block (${original.name})?`;
const _deleteReportBlockModalBody = (original: ReportBlockTableVM) => {
	return (
		<ReportBlockActionConfirmationBody
			message={_getModalBody(original.name)}
			reportTypes={original.reportTypes}
		/>
	);
};
const _deleteReportBlockModalText = () => 'Delete Report Block';

const _isReportTypeActive = (original: CustomReportTypeTableRowVM) => original.status === ReportTypeStatusEnum.ACTIVE;
const _isReportTypeInactive = (original: CustomReportTypeTableRowVM) => original.status === ReportTypeStatusEnum.INACTIVE;

const _activateReportTypeModalTitle = () => 'Activate Report Type';
const _activateReportTypeModalBody = (original: CustomReportTypeTableRowVM) => (
	<div>
		<div>Are you sure you want to activate this Report Type ({original.name})?</div>
		<div>Reactivating Report Type will return all associations with Work Orders and it will be automatically generated in new Field Reports.</div>
		<div>Change will not apply to already created Field Reports.</div>
	</div>
);
const _activateReportTypeModalText = () => 'Activate';

const _deactivateReportTypeModalTitle = () => 'Deactivate Report Type';
const _deactivateReportTypeModalBody = (original: CustomReportTypeTableRowVM) => (
	<div>
		<div>Are you sure you want to deactivate this Report Type ({original.name})?</div>
		<div>Deactivating Report Type will remove all associations with Work Orders and it won't be automatically generated in new Field Reports.</div>
		<div>Deactivated Report Type cannot be added to the Field Reports.</div>
		<div>Change will not apply to already created Field Reports.</div>
	</div>
);
const _deactivateReportTypeModalText = () => 'Deactivate';

const _deleteReportTypeModalTitle = () => 'Delete Report Type';

const _deleteReportTypeModalBody = (original: CustomReportTypeTableRowVM) => (
	<div>
		<div>Are you sure you want to delete this Report Type ({original.name})?</div>
		<div>Deleting Report Type will remove all associations with Work Orders and it won't be automatically generated in new Field Reports.</div>
		<div>Deleted Report Type cannot be added to the Field Reports.</div>
		<div>Change will not apply to already created Field Reports.</div>
	</div>
);

const _deleteReportTypeModalText = () => 'Delete';

const ReportBlockList: React.FC<Props> = (props: Props) => {
	const {
		companyName,
		createReportBlock,
		deleteReportBlock,
		deleteReportType,
		editCustomReportStatus,
		findReportBlockTable,
		findReportTypeTable,
		hasReportBlockManagePermission,
		history,
		location: { state: { orgAlias } },
		hasReportTypeManagePermission,
	} = props;

	const [showCreateReportBlockModal, setShowCreateReportBlockModal] = React.useState<boolean>(false);
	const [showCreateReportTypeModal, setShowCreateReportTypeModal] = React.useState<boolean>(false);

	const onDeleteReportBlock = React.useCallback(async (original: ReportBlockTableVM) => {
		await deleteReportBlock(original.id);
	}, [deleteReportBlock]);

	const onEditReportBlock = React.useCallback(async (original: ReportBlockTableVM) => {
		if (!original.id) {
			return;
		}
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.REPORT_BLOCK.EDIT(original.id.toString(), orgAlias, companyName));
	}, [history, orgAlias, companyName]);

	const onCreateReportBlock = React.useCallback(async (form: ReportBlockFormModel) => {
		form.virtualId = nanoid(UNIQUE_ID_SIZE);
		const fields: ReportBlockFieldRM[] = [];

		if (form.completionFieldVirtualId) {
			fields.push(initDefaultCompletionFieldRMValues(form.completionFieldVirtualId.toString()));
		}

		await createReportBlock(ReportBlockFormModel.toRequestModel(form), fields);
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.REPORT_BLOCK.CREATE(orgAlias, companyName));
	}, [companyName, orgAlias, history, createReportBlock]);

	const onReportBlockRowClick = React.useCallback(({ original }: RowInfo<ReportBlockTableVM>) => onEditReportBlock(original), [onEditReportBlock]);

	const onDeleteReportType = React.useCallback(async (original: CustomReportTypeTableRowVM) => {
		await deleteReportType(original.id);
	}, [deleteReportType]);

	const openReportTypeModal = React.useCallback(async () => setShowCreateReportTypeModal(true), []);
	const closeReportTypeModal = React.useCallback(() => setShowCreateReportTypeModal(false), []);

	const openCreateReportBlockModal = React.useCallback(async () => setShowCreateReportBlockModal(true), []);

	const closeCreateReportBlockModal = React.useCallback(() => setShowCreateReportBlockModal(false), []);

	const submitReportTypeModal = React.useCallback((name: string, description: Nullable<string>) => {
		history.push({
			pathname: CLIENT.COMPANY.SETTINGS.REPORT.REPORT_TYPE.CUSTOM.CREATE(orgAlias, companyName),
			state: {
				reportType: {
					name,
					description,
				},
				orgAlias,
			},
		});
	}, [companyName, history, orgAlias]);

	const editReportType = React.useCallback(async (original: CustomReportTypeTableRowVM) => {
		history.push(CLIENT.COMPANY.SETTINGS.REPORT.REPORT_TYPE.CUSTOM.EDIT(original.id?.toString(), orgAlias, companyName));
	}, [history, orgAlias, companyName]);

	const changeReportTypeStatus = React.useCallback(async (original: CustomReportTypeTableRowVM) => {
		const newStatus = original.status === ReportTypeStatusEnum.ACTIVE ? ReportTypeStatusEnum.INACTIVE : ReportTypeStatusEnum.ACTIVE;

		if (isValidPositiveInteger(original.id)) {
			await editCustomReportStatus(original.id as number, newStatus);
		}
	}, [editCustomReportStatus]);

	const onReportTypeRowClick = React.useCallback(({ original }: RowInfo<CustomReportTypeTableRowVM>) => editReportType(original), [editReportType]);

	const reportBlockTab = (): TabProps<ReportBlockTableVM> => {
		const reportBlockRowActions: ItemBlueprint<ReportBlockTableVM>[] = [
			{
				label: 'Edit',
				action: onEditReportBlock,
				shouldRefresh: false,
			},
			{
				label: 'Delete',
				action: onDeleteReportBlock,
				hasModal: true,
				modalTitle: _deleteReportBlockModalTitle,
				modalBody: _deleteReportBlockModalBody,
				modalText: _deleteReportBlockModalText,
				shouldRefresh: true,
			},
		];
		const reportBlockButtons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasReportBlockManagePermission,
				label: 'Create',
				onClick: openCreateReportBlockModal,
			},
		];

		return {
			label: 'Report Block',
			columns: REPORT_BLOCK_COLUMNS,
			selectable: false,
			hasSearchInput: true,
			buttons: reportBlockButtons,
			fetch: findReportBlockTable,
			onRowClick: onReportBlockRowClick,
			getRowClassName: _getReportBlockRowClassName,
			rowActions: hasReportBlockManagePermission ? reportBlockRowActions : undefined,
		};
	};

	const reportTypeTab = (): TabProps<CustomReportTypeTableRowVM> => {
		const reportTypeRowActions: ItemBlueprint<CustomReportTypeTableRowVM>[] = [
			{
				label: 'Edit',
				action: editReportType,
				shouldRefresh: false,
			},
			{
				label: 'Activate',
				action: changeReportTypeStatus,
				hide: _isReportTypeActive,
				shouldRefresh: true,
				hasModal: true,
				modalStyle: 'warning',
				modalTitle: _activateReportTypeModalTitle,
				modalBody: _activateReportTypeModalBody,
				modalText: _activateReportTypeModalText,
			},
			{
				label: 'Deactivate',
				action: changeReportTypeStatus,
				hide: _isReportTypeInactive,
				hasModal: true,
				modalStyle: 'warning',
				modalTitle: _deactivateReportTypeModalTitle,
				modalBody: _deactivateReportTypeModalBody,
				modalText: _deactivateReportTypeModalText,
				shouldRefresh: true,
			},
			{
				label: 'Delete',
				action: onDeleteReportType,
				hasModal: true,
				hide: (_row) => !!_row?.isRequired,
				modalTitle: _deleteReportTypeModalTitle,
				modalBody: _deleteReportTypeModalBody,
				modalText: _deleteReportTypeModalText,
				shouldRefresh: true,
			},
		];

		const reportTypeButtons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: hasReportTypeManagePermission,
				label: 'Create',
				onClick: openReportTypeModal,
			},
		];

		return {
			label: 'Report Type',
			columns: REPORT_TYPE_COLUMNS,
			selectable: false,
			hasSearchInput: true,
			buttons: reportTypeButtons,
			fetch: findReportTypeTable,
			onRowClick: onReportTypeRowClick,
			rowActions: hasReportTypeManagePermission ? reportTypeRowActions : undefined,
		};
	};

	const tabs = (): (TabProps<ReportBlockTableVM> | TabProps<CustomReportTypeTableRowVM>)[] => {
		const _result: (TabProps<ReportBlockTableVM> | TabProps<CustomReportTypeTableRowVM>)[] = [];

		if (hasReportTypeManagePermission) {
			_result.push(reportTypeTab());
		}
		if (hasReportBlockManagePermission) {
			_result.push(reportBlockTab());
		}
		return _result;
	};

	return (
		<div className="form-segment form-segment--maxi" >
			<Breadcrumbs items={BREADCRUMBS} />
			<Table
				tableName={TableNameEnum.REPORT_BLOCK}
				tabs={tabs()}
			/>
			<ReportBlockModal
				closeModal={closeCreateReportBlockModal}
				onSubmit={onCreateReportBlock}
				showModal={showCreateReportBlockModal}
				title="New Report Block"
			/>
			<ReportTypeModal
				closeModal={closeReportTypeModal}
				initialDescription={null}
				onSave={submitReportTypeModal}
				showModal={showCreateReportTypeModal}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasReportBlockManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_BLOCK,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasReportTypeManagePermission: boolean = isAllowed(
		PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_TYPE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasReportBlockManagePermission,
		hasReportTypeManagePermission,
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		createReportBlock: ReportBlockActions.setNewReportBlock,
		findReportBlockTable: ReportBlockActions.findTable,
		deleteReportBlock: ReportBlockActions.deleteReportBlock,
		findReportTypeTable: ReportTypeActions.findTable,
		deleteReportType: ReportTypeActions.deleteCustom,
		editCustomReportStatus: ReportTypeActions.editCustomStatus,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(ReportBlockList);
