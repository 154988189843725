import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

import { ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_STR } from '@acceligentllc/shared/enums/fileType';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type { Timezone } from '@acceligentllc/shared/enums/timezone';
import { timezones } from '@acceligentllc/shared/enums/timezone';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import type { CompanyRequestModel } from 'ab-requestModels/company.requestModel';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import Month from 'ab-enums/month.enum';

import MapField from 'af-components/Maps/LocationMap';
import ImageTag from 'af-components/Image';

import styles from './styles.module.scss';

const MonthOptions = [
	{ id: Month.JANUARY, name: 'January 1st' },
	{ id: Month.FEBRUARY, name: 'February 1st' },
	{ id: Month.MARCH, name: 'March 1st' },
	{ id: Month.APRIL, name: 'April 1st' },
	{ id: Month.MAY, name: 'May 1st' },
	{ id: Month.JUNE, name: 'June 1st' },
	{ id: Month.JULY, name: 'July 1st' },
	{ id: Month.AUGUST, name: 'August 1st' },
	{ id: Month.SEPTEMBER, name: 'September 1st' },
	{ id: Month.OCTOBER, name: 'October 1st' },
	{ id: Month.NOVEMBER, name: 'November 1st' },
	{ id: Month.DECEMBER, name: 'December 1st' },
];

interface Props extends InjectedFormProps<CompanyRequestModel> {
	formName: string;
	company?: CompanyViewModel;
	image: string | File | null;
	setImage: (img: string | File | null) => void;
	setShouldDeleteImage: (shouldDelete: boolean) => void;
}

const TIMEZONE_FILTER_BY: (keyof Timezone)[] = ['name'];

const CreateOrEditCompanyForm: React.FC<Props> = (props) => {

	const { error, company, formName, change, image, setImage, setShouldDeleteImage: setShouldDeleteImage } = props;

	const [imageUrl, setImageUrl] = React.useState<string | null>(null);
	const [uploadError, setUploadError] = React.useState<Nullable<string>>(null);
	const [hasReceivedInitialItem, setHasReceivedInitialItem] = React.useState<boolean>(false);

	const _inputElement = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (!hasReceivedInitialItem && company) {
			setHasReceivedInitialItem(true);
			setImage(company.imageUrl);
			setImageUrl(company.imageUrl);
		}
	}, [company, hasReceivedInitialItem, setImage]);

	const onInputClick = React.useCallback(() => {
		setUploadError(null);
		_inputElement.current?.click();
	}, []);

	const deleteImage = React.useCallback(() => {
		setImage(null);
		setImageUrl(null);
		setShouldDeleteImage(true);
		if (_inputElement.current) {
			_inputElement.current.value = '';
		}
	}, [setImage, setShouldDeleteImage]);

	const changeImage = React.useCallback((event) => {
		const [file] = event.target.files as File[];

		if (file) {
			if (ALLOWED_IMAGE_TYPES.includes(file.type)) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const _result = reader.result as string;
					setImage(file);
					setImageUrl(_result);
					setShouldDeleteImage(true);
				};
				reader.readAsDataURL(file);
			} else {
				setUploadError(`Invalid file format. Only ${ALLOWED_IMAGE_TYPES_STR} allowed.`);
			}
		}
	}, [setImage, setShouldDeleteImage]);

	return (
		<Form className="upsert-company">
			<Row className={styles.imageRow}>
				{
					image ?
						<ImageTag
							className="company-settings__image"
							minSize={BlobStorageImageSizeContainer.SIZE_200X200}
							src={imageUrl}
							tryOriginal={true}
							tryRoot={true}
						/> :
						<div className="company-settings__image company-settings__image--default ">
							{company?.name?.[0]?.toUpperCase() ?? ''}
						</div>
				}
				<Button
					icon="upload"
					onClick={onInputClick}
					style="secondary"
					tooltip="Upload"
					tooltipPosition="bottom"
				/>
				<Button
					disabled={!image}
					icon="delete"
					onClick={deleteImage}
					style="danger"
					tooltip="Delete"
					tooltipPosition="bottom"
				/>
				<input accept={ALLOWED_IMAGE_TYPES} className="display-none" onChange={changeImage} ref={_inputElement} type="file" />
			</Row>
			{uploadError &&
				<Row>
					<Col sm={24}>
						<span className="help-block">{uploadError}</span>
					</Col>
				</Row>
			}
			<Row>
				<Col sm={16}>
					<Field
						component={Input}
						label="Company name *"
						name="name"
						type="text"
					/>
				</Col>
				<Col sm={8} />
			</Row>
			<Row>
				<Col sm={16}>
					<Field
						component={Input}
						label="Website"
						name="website"
						type="text"
					/>
				</Col>
				<Col sm={8} />
			</Row>
			<Row>
				<Col sm={16}>
					<Field
						component={Dropdown}
						disabled={false}
						fixed={true}
						id="fiscalYearStartMonth"
						label="Fiscal Year Start:"
						labelKey="name"
						name="fiscalYearStartMonth"
						options={MonthOptions}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={8} />
			</Row>
			<Row>
				<Col sm={16}>
					<Field
						component={Dropdown}
						filterable={true}
						filterBy={TIMEZONE_FILTER_BY}
						id="timezone"
						label="Timezone *"
						labelKey="name"
						name="timezone"
						options={timezones}
						valueKey="name"
						withCaret={true}
					/>
				</Col>
				<Col sm={8} />
			</Row>
			<MapField
				aa1PropName="aa1"
				aa2PropName="aa2"
				aa3PropName="aa3"
				change={change}
				countryPropName="country"
				formName={formName}
				latitudePropName="latitude"
				localityPropName="locality"
				locationLabel="Street Address *"
				locationPropName="street"
				longitudePropName="longitude"
				marker={company ? { position: { lat: company.address.latitude, lng: company.address.longitude } } : null}
				postalCodePropName="postalCode"
				routePropName="route"
				streetNumberPropName="streetNumber"
				streetPropName="street"
			/>
			{error && <span className="help-block"><span className="icon-info" /> {error}</span>}
		</Form>
	);
};

export default CreateOrEditCompanyForm;
