import type { Dispatch, AnyAction } from 'redux';

import * as FormUtil from 'ab-utils/form.util';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { GetRootState } from 'af-reducers';

import * as CreateAPI from 'ab-api/web/item/create';
import * as FindItemNameListAPI from 'ab-api/web/item/findTakenNameList';
import * as FindItemDepartmentsAPI from 'ab-api/web/item/findItemDepartments';
import * as FindTableAPI from 'ab-api/web/item/findItemTable';
import * as FindForPurchaseOrderOptionsAPI from 'ab-api/web/item/findAllForPurchaseOrderOptions';
import * as UpdateAPI from 'ab-api/web/item/update';
import * as FindByIdAPI from 'ab-api/web/item/findById';
import * as DeleteAPI from 'ab-api/web/item/deleteItem';
import * as BulkDeleteAPI from 'ab-api/web/item/bulkDelete';

export function create(form: CreateAPI.W_Item_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			await http.post<void>(CreateAPI.URL(), fd, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, data: UpdateAPI.W_Item_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(data, 'imageUrl');
			await http.put<void>(UpdateAPI.URL(id), fd, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<FindByIdAPI.W_Item_FindById_VM>(FindByIdAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findTakenNameList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<FindItemNameListAPI.W_Item_FindTakenNameList_VM>(FindItemNameListAPI.URL());
			return result.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteItem(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete<void>(DeleteAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteItems(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: BulkDeleteAPI.W_Item_BulkDelete_RM = { itemIds: ids };
			await http.delete<void>(BulkDeleteAPI.URL(), body);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<FindTableAPI.W_Item_FindItemTable_VM>(FindTableAPI.URL(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findItemDepartments() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const result = await http.get<FindItemDepartmentsAPI.W_Item_FindItemDepartments_VM>(FindItemDepartmentsAPI.URL());
			return result.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.get<FindForPurchaseOrderOptionsAPI.W_Item_FindAllForPurchaseOrderOptions_VM>(
				FindForPurchaseOrderOptionsAPI.URL()
			);
			return response.items;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
