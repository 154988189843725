import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/reportTypeView${`?startDate=${string}&endDate=${string}&page=${number}&limit=${number}&filterBy=${string}&sortBy=${string}&ascending=${boolean}&filterByJobId=${number}&filterByReportTypeId=${number}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/reportTypeView`;

export const URL = (
	startDate: string,
	endDate: string,
	page: number,
	limit: number,
	filterText: string,
	sortBy: 'reportTypeName' | 'workOrderCode' | 'companyName' | 'startDate' | 'calculatedReportDisplayStatus' | 'reportTypeStatus' | '',
	sortAsc: boolean,
	filterByJobId: number,
	filterByReportTypeId: number
): RouteDefinition =>
	`${baseRoute}/reportTypeView?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&filterBy=${encodeURIComponent(filterText)}&sortBy=${sortBy}&ascending=${sortAsc}&filterByJobId=${filterByJobId}&filterByReportTypeId=${filterByReportTypeId}`;
