import { WILDCARD_REGEX, CLIENT_COMPANY_ROUTE_REGEX } from '@acceligentllc/shared/constants/regex';

import { __DEV__, USE_WILDCARDS } from 'af-constants/values';
import CLIENT from 'af-constants/routes/client';

import * as TextUtil from 'ab-utils/text.util';

export function getCurrentOrgAlias(): Nullable<string> {
	const { hostname, pathname } = window.location;

	if (USE_WILDCARDS) {
		return hostname.match(WILDCARD_REGEX)?.[1] ?? null;
	}

	const urlElements = pathname.split('/');
	return urlElements?.[1] && !urlElements[1].startsWith(':')
		? urlElements[1]
		: 'app';
}

/**
 * NOTE: will refresh the page if a new value is successfully set
 */
export function setCompanyNameInPath(orgAlias: string, companyName: string): boolean {
	const currentPathCompanyName = getCompanyNameInPath();
	const newPathCompanyName = TextUtil.removeSpaces(companyName);

	if (!currentPathCompanyName || currentPathCompanyName === newPathCompanyName) {
		return false;
	}

	window.location.pathname = CLIENT.HOME(orgAlias);
	return true;
}

export function getHostname(): Nullable<string> {
	const { hostname } = window.location;

	const appUrl = process.env.APP_URL;

	if (!appUrl) {
		console.info('App URL not configured, check APP_URL');
		return null;
	}

	const evaluatedHostname = USE_WILDCARDS && !__DEV__
		? hostname.match(WILDCARD_REGEX)?.[2]
		: appUrl;

	return evaluatedHostname ?? null;
}

export function getCompanyNameInPath(): Nullable<string> {
	const { pathname } = window.location;

	const companyPathMatch = pathname.match(CLIENT_COMPANY_ROUTE_REGEX);

	if (!companyPathMatch?.[0]) {
		return null;
	}
	const companyPathSegments = companyPathMatch[0].split('/');
	return companyPathSegments[companyPathSegments.length - 1];
}

export function getQueryParamsFromQueryString<T extends string>(queryString: string, params: T[]): Record<T, Nullable<string>> {
	const queryStringObj = new URLSearchParams(queryString);
	const paramsObj = {} as Record<T, Nullable<string>>;

	for (const _param of params) {
		paramsObj[_param] = queryStringObj.get(_param);
	}

	return paramsObj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createNewQueryParams(params: Record<string, any>): URLSearchParams {
	return new URLSearchParams(params);
}
