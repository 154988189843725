import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { _parseThousands } from 'ab-utils/number.util';

const getDecimalNumberRegex = (decimalPoints: number) => new RegExp(`^\\-?\\d*\\.?\\d{0,${decimalPoints}}$`);

const _removeLeadingZerosAndHandleMinus = (value: string): string => {
	if (!value) {
		return '';
	}
	if (value === '-') {
		return '-';
	}
	return parseInt(value, 10).toString();
};

export const formatWholeNumber = (value: string | number, returnZero: boolean = true): string => {
	value = typeof value === 'number' ? `${value}` : value;
	if (!value) {
		return returnZero ? '0' : '';
	}
	value = parseFloat(value) === 0 ? '0' : value.replace(/^0+/, '');
	value = value.replace(/\,/g, '');
	return _parseThousands(value);
};

export const formatDecimalNumberForDecimalPoints = (value: string | number, decimalPoints = 2): string => {
	value = typeof value === 'number' ? `${value}` : value;

	if (!value || value === 'Infinity' || value === '-') {
		return '0';
	}

	value = value.split(',').join('');
	if (value.includes('.')) {
		const decimalPointIndex = value.indexOf('.');
		const beforeDecimalPoint = value.substr(0, decimalPointIndex);
		const afterDecimalPoint = value.substr(decimalPointIndex + 1, decimalPoints);
		return `${formatWholeNumber(beforeDecimalPoint, false)}.${afterDecimalPoint}`;
	}
	return formatWholeNumber(value);
};

export const formatDecimalNumber = (value: string | number): string => formatDecimalNumberForDecimalPoints(value, 4);

export const formatPercentage = (value: string | number): string => {
	value = typeof value === 'number' ? `${value}` : value;
	return formatDecimalNumber(parseFloat(value));
};

export const normalizeDecimalNumberForDecimalPoints = (
	value: string | number,
	previousValue: string,
	decimalPoints = 2
): string => {
	if (!value) {
		return '0';
	}

	value = value.toString().split(',').join('');
	if (value === '-') {
		return '0';
	} else if (!value.match(getDecimalNumberRegex(decimalPoints))) {
		return previousValue;
	}

	if (value.includes('.')) {
		const decimalPointIndex = value.indexOf('.');
		const beforeDecimalPoint = value.substr(0, decimalPointIndex);
		const afterDecimalPoint = value.substr(decimalPointIndex + 1).split('.').join('');

		if (afterDecimalPoint.length <= decimalPoints) {
			return `${_removeLeadingZerosAndHandleMinus(beforeDecimalPoint)}.${afterDecimalPoint}`;
		}

		const before = `${beforeDecimalPoint}${afterDecimalPoint.substr(0, afterDecimalPoint.length - decimalPoints)}`;
		const after = afterDecimalPoint.substr(afterDecimalPoint.length - decimalPoints);

		return `${_removeLeadingZerosAndHandleMinus(before)}.${after}`;
	}

	return _removeLeadingZerosAndHandleMinus(value);
};

export const normalizeDecimalNumber = (
	value: string | number,
	previousValue: string
): string => normalizeDecimalNumberForDecimalPoints(value, previousValue, 2);

export const plainNumberNormalizer = (value: string | number): number => {
	if (!value) {
		return 0;
	}

	if (typeof value === 'number' && !isNaN(value)) {
		return value;
	}
	return parseFloat((value as string).split(',').join(''));
};

export const phoneNormalizer = (value: string, previousValue?: string): string => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');

	if (onlyNums.length > 10 && previousValue) {
		return previousValue;
	}
	if (onlyNums.length <= 3) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
	}
	return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

const normalizeCode = (code: string, prevCode: string, maxLength: number): string => {
	if (!code) {
		return prevCode;
	}
	if (code.length > maxLength) {
		return prevCode;
	}
	if (code.includes('.')) {
		return prevCode;
	}
	return code;
};

export const normalizeNDigitCode = (digits: number) => (code: string, prevCode: string) => normalizeCode(code, prevCode, digits);

export const normalizeDateWrapper = (format: TimeFormat = TimeFormat.TIME_ONLY_12H) => {
	return (value: Nullable<Date>) => {
		return value && TimeUtils.formatDate(value, format);
	};
};
