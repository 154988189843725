export const calculateManHourAverage = (
	revenue: Nullable<number>,
	jobHours: number,
	travelHours: number,
	shopHours: number
): string => {
	if (revenue === null) {
		return 'N/A';
	}

	const totalTime = jobHours + shopHours + travelHours;

	if (totalTime === 0) {
		return 'N/A';
	}

	return (revenue / totalTime).toFixed(2);
};
