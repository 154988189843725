import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { formatDate } from '@acceligentllc/shared/utils/time';

import type { ReportSignatureVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import { getDateTimeWithOffset } from '../../FieldReportsList/helpers';

interface OwnProps {
	signature: ReportSignatureVM;
	timeZone: string;
}

type Props = OwnProps;

const Signature: React.FC<Props> = (props: Props) => {
	const {
		signature: {
			url,
			name,
			signedAt,
		},
		timeZone,
	} = props;

	const time = React.useMemo(() => {
		return getDateTimeWithOffset(signedAt, timeZone, TimeFormat.FULL_DATE)
			?? formatDate(signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME);
	}, [signedAt, timeZone]);

	return (
		<div className="public-report__signatures__signature">
			<img className="public-report__signatures__signature-image" src={url} />
			<div className="public-report__signatures__signature-name">
				{name}, {time}
			</div>
		</div>
	);
};

export default React.memo(Signature);
