import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-constants/routes/client';

import type { RootState } from 'af-reducers';

interface StateProps {
	email: Nullable<string>;
}

type OwnProps = CustomRouteComponentProps;

type Props = StateProps & OwnProps;

class PasswordForgottenSuccess extends React.Component<Props> {

	goToLogin = () => {
		const {
			location: { state: { orgAlias } },
			history,
		} = this.props;

		history.push(CLIENT.AUTH.LOGIN(orgAlias));
	};

	render(): JSX.Element {
		const { email } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<div className="form-box form-box--standalone form-box--padded">
					<div className="form-box__icon-container">
						<span className="form-box__icon form-box__icon--success icon-check" />
					</div>
					<h2>Success!</h2>
					<h4><b>{email}</b></h4>
					<h4>If an account matches that email address, you should receive an email with instructions on how to reset your password shortly.</h4>
					<h4>Thank you</h4>
					<div className="form-box__footer">
						<Button
							label="Back to Login"
							onClick={this.goToLogin}
							style="primary"
							type="button"
						/>
					</div>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state: RootState): StateProps {
	return {
		email: state.user.forgottenEmail,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps, null)(PasswordForgottenSuccess);
