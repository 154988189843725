import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { FieldWorkClassificationCodeTableRowVM } from 'ab-viewModels/fieldWorkClassificationCode/table';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { RootState } from 'af-reducers';

import * as FieldWorkClassificationCodeActions from 'af-actions/fieldWorkClassificationCode';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import type TableComponent from 'af-components/Table6/Table';
import Breadcrumbs from 'af-components/Breadcrumbs';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	findAllForCompanyTable: typeof FieldWorkClassificationCodeActions.findAllForCompanyTable;
	deleteSingle: typeof FieldWorkClassificationCodeActions.deleteSingle;
	deleteMultiple: typeof FieldWorkClassificationCodeActions.deleteMultiple;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

class FieldWorkClassificationCodeList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Classification Code' }];

	static readonly DELETE_CONFIRMATION_BODY = (
		<>
			If deleted, the classification code will no longer be assignable.
			<br />
			This action cannot be undone.
		</>
	);

	static readonly COLUMNS: Column<FieldWorkClassificationCodeTableRowVM>[] = [
		{
			Header: 'Code',
			accessor: 'code',
			Cell: ({ original }) => original.code,
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ original }) => original.description,
		},
		{
			Header: 'Last Update',
			accessor: 'updatedAt',
			Cell: ({ original }) => (
				TimeUtils.formatDate(original.updatedAt, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME)
			),
		},
	];

	private _table: Nullable<TableComponent<FieldWorkClassificationCodeTableRowVM>> = null;

	static deleteModalTitle = (original: FieldWorkClassificationCodeTableRowVM) => `Are you sure you want to delete this Classification Code (${original.code})?`;
	static deleteModalBody = () => FieldWorkClassificationCodeList.DELETE_CONFIRMATION_BODY;
	static deleteModalText = () => 'Delete Classification Code';

	deleteFieldWorkClassificationCode = async (original: FieldWorkClassificationCodeTableRowVM) => {
		const { deleteSingle } = this.props;
		await deleteSingle(+original.id);
	};

	editFieldWorkClassificationCode = async (original: FieldWorkClassificationCodeTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.EDIT(original.id.toString(), orgAlias, companyName));
	};

	onMount = (table: TableComponent<FieldWorkClassificationCodeTableRowVM>) => {
		this._table = table;
	};

	onRowClick = ({ original }: { original: FieldWorkClassificationCodeTableRowVM; }) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	};

	refreshTable = async () => {
		if (this._table) {
			this._table.refreshTable();
		}
	};

	tabs = (): TabProps<FieldWorkClassificationCodeTableRowVM>[] => {
		const {
			findAllForCompanyTable,
			deleteMultiple,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Classification Code',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Classification Code',
				columns: FieldWorkClassificationCodeList.COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Classification Code',
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: deleteMultiple,
				bulkDeleteConfirmationBody: FieldWorkClassificationCodeList.DELETE_CONFIRMATION_BODY,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Edit',
						action: this.editFieldWorkClassificationCode,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: this.deleteFieldWorkClassificationCode,
						hasModal: true,
						modalTitle: FieldWorkClassificationCodeList.deleteModalTitle,
						modalBody: FieldWorkClassificationCodeList.deleteModalBody,
						modalText: FieldWorkClassificationCodeList.deleteModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={FieldWorkClassificationCodeList.BREADCRUMBS} />
				<Table
					onMount={this.onMount}
					tableName={TableNameEnum.FIELD_WORK_CLASSIFICATION_CODE}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		findAllForCompanyTable: FieldWorkClassificationCodeActions.findAllForCompanyTable,
		deleteSingle: FieldWorkClassificationCodeActions.deleteSingle,
		deleteMultiple: FieldWorkClassificationCodeActions.deleteMultiple,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(FieldWorkClassificationCodeList);
