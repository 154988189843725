import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom-v5-compat';

import HeaderCompanies from 'af-components/Header/HeaderCompanies';
import OrdersNavigation from 'af-components/Header/OrdersNavigation';
import ScheduleBoardNavigation from 'af-components/Header/ScheduleBoardNavigation';
import DisplayViewNavigation from 'af-components/Header/DisplayViewNavigation';
import MechanicViewNavigation from 'af-components/Header/MechanicView';
import FieldReportEditNavigation from 'af-components/Header/FieldReportEdit';
import UserGuideLegend from './UserGuideLegend';

const HeaderLoggedIn: React.FC = () => {

	const location = useLocation();

	const {
		isCompanyContext,
		isPlatformAdminContext,
	} = React.useMemo(() => {
		if (process.env.REVIEW_APP === 'true') {
			const [, , pathnameStart] = location.pathname.split('/');
			return {
				isCompanyContext: pathnameStart === 'company',
				isPlatformAdminContext: pathnameStart === 'platform',
			};
		}
		return {
			isCompanyContext: location.pathname.startsWith('/company'),
			isPlatformAdminContext: location.pathname.startsWith('/platform'),
		};
	}, [location.pathname]);

	// User guide base component has defined supported routes
	const renderUserGuideButton = React.useMemo(() => {
		return (
			<Routes>
				<Route element={<UserGuideLegend startGuideUri="/" visible={false} />} path="company/:companyName/scheduleBoard/dailyView/metrics" />
				<Route element={<UserGuideLegend startGuideUri="/" visible={false} />} path="company/:companyName/scheduleBoard/*" />
				<Route element={<UserGuideLegend startGuideUri="/jobs" visible={true} />} path="company/:companyName/jobs/*" />
				<Route element={<UserGuideLegend startGuideUri="/accounting" visible={true} />} path="company/:companyName/accounting/*" />
				<Route element={<UserGuideLegend startGuideUri="/orders" visible={true} />} path="company/:companyName/order/*" />
				<Route element={<UserGuideLegend startGuideUri="/purchase_orders" visible={true} />} path="company/:companyName/purchaseOrder/*" />
				<Route element={<UserGuideLegend startGuideUri="/tool_repairs" visible={true} />} path="company/:companyName/toolRepair/*" />
				<Route element={<UserGuideLegend startGuideUri="/resources" visible={true} />} path="company/:companyName/resources/*" />
				<Route element={<UserGuideLegend startGuideUri="/" visible={true} />} path="*" />
			</Routes>
		);
	}, []);

	return (
		<div className="main-header main-header--with-sidebar">
			<HeaderCompanies
				isCompanyContext={isCompanyContext}
				isPlatformAdminContext={isPlatformAdminContext}
			/>
			<Routes>
				<Route element={<OrdersNavigation />} path="company/:companyName/orders/table" />
				<Route element={<ScheduleBoardNavigation />} path="company/:companyName/scheduleBoard/*" />
				<Route element={<DisplayViewNavigation />} path="company/:companyName/displayView" />
				<Route element={<MechanicViewNavigation />} path="company/:companyName/mechanicView" />
				<Route element={<FieldReportEditNavigation />} path="company/:companyName/fieldReport/workOrder/:workOrderId/edit/:fieldReportId" />
			</Routes>
			{renderUserGuideButton}
		</div>
	);
};

export default HeaderLoggedIn;
