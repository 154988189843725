import * as React from 'react';
import { FieldArray } from 'redux-form';

import { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import { getId } from '@acceligentllc/shared/utils/array';

import type { WorkOrderEmployeeVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';

import type { OwnProps as WorkOrderEmployeesProps } from './WorkOrderEmployees';
import WorkOrderEmployees from './WorkOrderEmployees';

interface OwnProps {
	anyTouched: boolean;
	assignedToIdsError: string;
	change: (key: string, value: Nullable<number[]>) => void;
	selectedLevel: WorkOrderReviewLevel;
	selector: (key: string) => number[];
	users: WorkOrderEmployeeVM[];
}

type Props = OwnProps;

interface State {
	options: WorkOrderEmployeeVM[];
	selectedUsers: Nullable<WorkOrderEmployeeVM[]>;
}

class WorkOrderEmployeeSelect extends React.PureComponent<Props, State> {

	state: State = {
		options: this.props.users,
		selectedUsers: null,
	};

	static FILTER_BY: (keyof WorkOrderEmployeeVM)[] = ['fullName'];

	static _getSelectedUsers = (targetLevel: WorkOrderReviewLevel, users: Nullable<WorkOrderEmployeeVM[]>) => {
		return targetLevel < WorkOrderReviewLevel.LEVEL_2
			? users
			: [];
	};

	componentDidMount() {
		const { users, selectedLevel } = this.props;

		const selectedUsers = WorkOrderEmployeeSelect._getSelectedUsers(selectedLevel, users);
		this.setState(() => ({ options: users, selectedUsers }));
	}

	componentDidUpdate(prevProps: Props) {
		const { users, selectedLevel, change } = this.props;

		if (prevProps.users !== users || prevProps.selectedLevel !== selectedLevel) {
			const newSelectedUsers = WorkOrderEmployeeSelect._getSelectedUsers(selectedLevel, users);
			this.setState(() => ({ options: users, selectedUsers: newSelectedUsers }));
			change('assignedToIds', newSelectedUsers?.map(getId) ?? null);
		}
	}

	onChange = (selectedOption: Nullable<WorkOrderEmployeeVM>) => {
		const { change, selector } = this.props;
		const { selectedUsers, options } = this.state;

		if (!selectedOption) {
			return;
		}

		const selectedIds = selector('assignedToIds');
		const newUsers = [...(selectedUsers ?? []), selectedOption];
		const newOptions = options.filter((_option) => _option.id !== selectedOption.id);
		change('assignedToIds', [...(selectedIds ?? []), selectedOption.id]);

		this.setState(() => ({ selectedUsers: newUsers, options: newOptions }));
	};

	render() {
		const { change, selector, anyTouched, assignedToIdsError, selectedLevel } = this.props;
		const { options, selectedUsers } = this.state;

		return (
			<>
				{selectedLevel > WorkOrderReviewLevel.LEVEL_1 &&
					<Dropdown
						className="work-order-report-reject-modal__dropdown"
						filterable={true}
						filterBy={WorkOrderEmployeeSelect.FILTER_BY}
						fixed={true}
						label="Select User *"
						labelKey="fullName"
						onValueChange={this.onChange}
						options={options}
						placeholder="Select user to reject to"
						valueKey="id"
						withCaret={true}
					/>
				}
				<FieldArray<WorkOrderEmployeesProps>
					change={change}
					component={WorkOrderEmployees}
					employees={selectedUsers}
					error={anyTouched ? assignedToIdsError : undefined}
					name="assignedToIds"
					propName="assignedToIds"
					selector={selector}
				/>
			</>
		);
	}
}

export default WorkOrderEmployeeSelect;
