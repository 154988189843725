import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Row, Form, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import WorkOrderSettingsEnum, { WorkOrderSettingsHint } from 'ab-enums/workOrderSettings.enum';

import { FIELD_REPORT_SETTINGS } from 'af-constants/reduxForms';

import type { FieldReportSettingsRM } from 'ab-requestModels/company/fieldReportSettings.requestModel';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import { validate } from './validation';
import { fromVMtoRM } from './formModel';

interface OwnProps {
	isVisible: boolean;
	toggleEdit: () => void;
}

type FormProps = InjectedFormProps<FieldReportSettingsRM, FormOwnProps>;

interface StateProps {
	companyId: number | undefined;
	initialValues: FormProps['initialValues'] | null;
}

interface DispatchProps {
	editFieldReportSettings: typeof CompanyActions.editFieldReportSettings;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

class WorkOrderEdit extends React.PureComponent<Props> {

	editFieldReportSettings = async (form: FieldReportSettingsRM) => {
		const { toggleEdit, editFieldReportSettings } = this.props;

		await editFieldReportSettings(form);
		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { isVisible, handleSubmit, submitting, invalid } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Field Report Settings"
				/>
				<CustomModal.Body padding="none">
					<Form onSubmit={handleSubmit(this.editFieldReportSettings)}>
						<Row className="row--padded-top">
							<Col sm={6}>
								<Field
									component={Input}
									disabled={false}
									label={WorkOrderSettingsEnum.fieldReportAccessDayDuration}
									min={1}
									name="fieldReportAccessDayDuration"
									type="number"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="isFRManageAllowedToApproveTimeSheet"
									label={WorkOrderSettingsEnum.isFRManageAllowedToApproveTimeSheet}
									name="isFRManageAllowedToApproveTimeSheet"
									tooltipMessage={WorkOrderSettingsHint.isFRManageAllowedToApproveTimeSheet}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="isFRManageAllowedToEditTimeSheet"
									label={WorkOrderSettingsEnum.isFRManageAllowedToEditTimeSheet}
									name="isFRManageAllowedToEditTimeSheet"
									tooltipMessage={WorkOrderSettingsHint.isFRManageAllowedToEditTimeSheet}
								/>
							</Col>
						</Row>
						<Row className="row--padded-bottom">
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="isFRManageLevelAllowedInReviewProcess"
									label={WorkOrderSettingsEnum.isFRManageLevelAllowedInReviewProcess}
									name="isFRManageLevelAllowedInReviewProcess"
									tooltipMessage={WorkOrderSettingsHint.isFRManageLevelAllowedInReviewProcess}
								/>
							</Col>
							<Col sm={24}>
								<Field
									component={Checkbox}
									id="allowAllNonBillableShortcut"
									label={WorkOrderSettingsEnum.allowAllNonBillableShortcut}
									name="allowAllNonBillableShortcut"
									tooltipMessage={WorkOrderSettingsHint.allowAllNonBillableShortcut}
								/>
							</Col>
						</Row>
					</Form>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.onClose}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editFieldReportSettings)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		companyId: company?.id,
		initialValues: company && fromVMtoRM(company),
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		editFieldReportSettings: CompanyActions.editFieldReportSettings,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<FieldReportSettingsRM, FormOwnProps>({ form: FIELD_REPORT_SETTINGS, enableReinitialize: true, validate })
);

export default enhance(WorkOrderEdit);
