import { TableContent } from 'ab-common/dataStructures/tableContent';

import type ToolStatus from '@acceligentllc/shared/enums/toolStatus';
import type ToolState from '@acceligentllc/shared/enums/toolState';

import type ToolBase from 'ab-domain/models/tool/base';
import type ToolTrackingRecordBase from 'ab-domain/models/toolTrackingRecord/base';

export class W_SmallTool_FindSmallToolTable_VM extends TableContent<W_SmallTool_FindSmallToolTable_VM_Row>{
	constructor(tools: ToolBase[], pages: number, totalCount: number) {
		super(
			W_SmallTool_FindSmallToolTable_VM_Row.bulkConstructor(tools),
			pages,
			totalCount
		);
	}
}

class W_SmallTool_FindSmallToolTable_VM_Row {
	id: number;
	modelNumber: string;
	manufacturer: string;
	serialNumber: string;
	note: Nullable<string>;
	status: ToolStatus;
	location: W_SmallTool_FindSmallToolTable_VM_Location;
	toolType: W_SmallTool_FindSmallToolTable_VM_ToolType;
	currentLocation: string;
	state: ToolState;

	constructor(tool: ToolBase) {
		const latestRecord = tool.toolTrackingRecords.find(W_SmallTool_FindSmallToolTable_VM_Row.latestRecordCondition);
		this.id = tool.id;
		this.serialNumber = tool.serialNumber;
		this.manufacturer = tool.manufacturer;
		this.modelNumber = tool.modelNumber;
		this.location = new W_SmallTool_FindSmallToolTable_VM_Location(tool);
		this.note = tool.note;
		this.status = tool.status;
		this.state = tool.state;
		this.toolType = new W_SmallTool_FindSmallToolTable_VM_ToolType(tool);
		this.currentLocation = latestRecord?.getLocationName() ?? tool.location.nickname;
	}

	private static latestRecordCondition = (record: ToolTrackingRecordBase) => !record.validTo;

	private static _mapConstructor = (tool: ToolBase) => new W_SmallTool_FindSmallToolTable_VM_Row(tool);
	static bulkConstructor = (tools: ToolBase[]) => tools.map(W_SmallTool_FindSmallToolTable_VM_Row._mapConstructor);
}

class W_SmallTool_FindSmallToolTable_VM_Location {
	id: number;
	name: string;

	constructor(tool: ToolBase) {
		this.id = tool.locationId;
		this.name = tool.location.nickname;
	}
}

class W_SmallTool_FindSmallToolTable_VM_ToolType {
	id: number;
	name: string;

	constructor(tool: ToolBase) {
		this.id = tool.toolTypeId;
		this.name = tool.toolType.name;
	}
}
