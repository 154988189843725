import * as React from 'react';
import { Link } from 'react-router-dom';

import type UserPermission from '@acceligentllc/shared/enums/userPermission';

import { isAllowed } from 'ab-utils/auth.util';

export interface Props {
	label: string;
	url?: string;
	visibleFor: Metadata; // PagePermissionType | PagePermissionsTree
	companyPermissions?: string[];
	isCompanyAdmin?: boolean;
	userRole?: UserPermission;
	hash?: string;
	isLoading?: boolean;
	sublabel?: string;
	onClick?: () => void;
}

const FSNLinkWithStatus: React.FC<Props> = (props: Props) => {
	const { hash, url, onClick, label, visibleFor, isCompanyAdmin, userRole, companyPermissions, isLoading, sublabel } = props;

	const _isAllowed = React.useMemo(
		() => isAllowed(visibleFor, companyPermissions, isCompanyAdmin, userRole),
		[visibleFor, companyPermissions, isCompanyAdmin, userRole]
	);

	if (!_isAllowed) {
		return null;
	}

	if (isLoading) {
		return (
			<li className="link standalone">
				<a className="fsn-link-with-status">
					<div className="loading-row loading-box-nav-link" />
				</a>
			</li>
		);
	}

	return (
		<li className="link standalone">
			{hash
				? (
					<a className="fsn-link-with-status" href={`#${hash}`}>
						<div className="fsn-link-multirow">
							<span>{label}</span>
							{sublabel && <div className="fsn-link-sublabel">{sublabel}</div>}
						</div>
					</a>
				)
				: <Link className="fsn-link-with-status" onClick={onClick} to={url!}><>{label}</></Link>
			}
		</li>
	);
};

export default FSNLinkWithStatus;
