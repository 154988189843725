import type { GetObjectOutput } from '@aws-sdk/client-s3';
import { HeadObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import type { Readable } from 'stream';

import { getDirectoryPath } from '@acceligentllc/shared/utils/blobStorage';

import { s3Client } from '../infrastructure/configuration/blobStorage.config';

import { DEFAULT_SIGNED_URL_EXPIRE_TIME } from 'ab-common/constants/value';

type DirectoryType = string | string[];

export const bodyToBuffer = async (blobObject: GetObjectOutput): Promise<Buffer> => {
	const stream = blobObject.Body as Readable;

	return new Promise<Buffer>((resolve, reject) => {

		const _buf = Array<Uint8Array>();

		stream.on('data', (chunk) => _buf.push(chunk));
		stream.on('end', () => resolve(Buffer.concat(_buf)));
		stream.on('error', (err) => reject(`error converting stream - ${err}`));

	});
};

/**
 * Generates presigned url which allows you to access the private file in s3 bucket
 * @param {string | string[]} directory - single or list of directories
 * @param {string} filename - name of the file
 * @param {number} expires - time in seconds
 */
export const generatePresignedGetUrl = async (
	directory: DirectoryType,
	filename: string
): Promise<string> => {
	if (!s3Client) {
		throw new Error('S3 Bucket not configured, check AWS_S3_BUCKET_NAME');
	}

	const bucketName = process.env.AWS_S3_BUCKET_NAME;

	const command = new GetObjectCommand({
		Bucket: bucketName,
		Key: `${getDirectoryPath(directory)}/${filename}`,
	});
	return await getSignedUrl(s3Client, command, { expiresIn: DEFAULT_SIGNED_URL_EXPIRE_TIME });
};

export const fileExists = async (directory: DirectoryType, filename: string) => {
	const bucketName = process.env.AWS_S3_BUCKET_NAME;

	if (!bucketName || !s3Client) {
		console.info('S3 Bucket not configured, check AWS_S3_BUCKET_NAME');
		return null;
	}

	const command = new HeadObjectCommand({
		Bucket: bucketName,
		Key: `${getDirectoryPath(directory)}/${filename}`,
	});
	try {
		await s3Client.send(command);
	} catch {
		return false;
	}
	return true;
};
