import type { FormErrors, FormValueSelectorResult } from 'redux-form';
import { formValueSelector } from 'redux-form';

import { EMAIL_REGEX, ALL_PHONE_REGEXES } from '@acceligentllc/shared/constants/regex';

import { CONTACT_ACTION_MODAL_NEW_CONTACT } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers/index';

import { toRawPhoneNumber } from 'ab-utils/phone.util';

import type { SubmitActionCallbackData } from '../types';

export abstract class NewContactFormModel {
	isEmailUsed: boolean;
	email: string;
	isPhoneUsed: boolean;
	phoneNumber: string;

	static readonly FORM_NAME = CONTACT_ACTION_MODAL_NEW_CONTACT;

	static readonly selector: FormValueSelectorResult<RootState, NewContactFormModel> = formValueSelector(NewContactFormModel.FORM_NAME);

	static getSubmitActionData(state: RootState): SubmitActionCallbackData {
		const { isEmailUsed, email, isPhoneUsed, phoneNumber } = NewContactFormModel.selector(state, 'isEmailUsed', 'email', 'isPhoneUsed', 'phoneNumber');
		return {
			emails: isEmailUsed ? [email] : [],
			phones: isPhoneUsed ? [toRawPhoneNumber(phoneNumber)] : [],
		};
	}

	static validate(values: NewContactFormModel): FormErrors<NewContactFormModel> {
		if (!values.isEmailUsed && !values.isPhoneUsed) {
			return {
				isEmailUsed: 'Pick at least one contact method',
				isPhoneUsed: 'Pick at least one contact method',
			};
		}
		const errors: FormErrors<NewContactFormModel> = {};

		if (values.isEmailUsed && !EMAIL_REGEX.test(values.email)) {
			errors.email = 'Invalid email';
		}
		if (values.isPhoneUsed && !ALL_PHONE_REGEXES.some((_regExp) => _regExp.test(values.phoneNumber))) {
			errors.phoneNumber = 'Invalid phone number';
		}
		return errors;
	}
}
