/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as queryString from 'query-string';
import type { ResolveThunks, HandleThunkActionCreator } from 'react-redux';

import { filterMap } from '@acceligentllc/shared/utils/array';
import type ScheduleBoardRM from '@acceligentllc/shared/dtos/socket/request/connection/scheduleBoard';
import WorkOrderStatusEnum from '@acceligentllc/shared/enums/workOrderStatus';

import * as ArrayUtils from '@acceligentllc/shared/utils/array';

import type EmployeeNightShiftAssignmentVM from '@acceligentllc/shared/dtos/socket/view/nightShift/nightShiftAssignment';
import type TemporaryEmployeeNightShiftAssignmentVM from '@acceligentllc/shared/dtos/socket/view/nightShift/temporaryEmployeeNighShiftAssignment';
import type { ScheduleBoardRemoveAllWorkOrderEmployeesVM, ScheduleBoardRemoveAllWorkOrderEquipmentVM } from '@acceligentllc/shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardRemoveResourceAssignmentVM from '@acceligentllc/shared/dtos/socket/view/scheduleBoard/resources';
import type ScheduleBoardUpdateWorkOrderHasAttachmentsVM from '@acceligentllc/shared/dtos/socket/view/scheduleBoard/updateWorkOrderHasAttachments';

import type ScheduleBoardFilterVM from 'ab-viewModels/scheduleBoard/filter';
import { ScheduleBoardFilterType } from 'ab-viewModels/scheduleBoard/filter';

import ScheduleBoardFilterGroup from '@acceligentllc/shared/enums/scheduleBoardFilterGroup';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { AdditionalColors } from '@acceligentllc/shared/enums/color';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import type { ScheduleBoardWorkOrdersViewModel, BlankWorkOrder, ScheduleBoardColumnPlaceHolder } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardWorkOrdersByDateDictionary, ColumnNumbersForWorkOrder, WorkOrdersRowDistribution, ScheduleBoardRejoinViewModel, LocationViewModel } from 'ab-viewModels/scheduleBoard.viewModel';
import type ScheduleBoardViewModel from 'ab-viewModels/scheduleBoard.viewModel';
import type ScheduleBoardResourcesViewModel from 'ab-viewModels/scheduleBoardResources.viewModel';
import type { Single as ScheduleBoardResourceLookupViewModel, ScheduleBoardWorkOrderResourceLookupsViewModel, Single } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import type ScheduleBoardToolbarEmployeeUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEmployeeUpdate.viewModel';
import type ScheduleBoardToolbarEquipmentUpdate from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardToolbarEquipmentUpdate.viewModel';
import type ScheduleBoardEmployeeAssignment from 'ab-viewModels/scheduleBoardEmployeeAssignment.viewModel';
import type ScheduleBoardEquipmentAssignment from 'ab-viewModels/scheduleBoardEquipmentAssignment.viewModel';
import type ScheduleBoardUpdateCrewType from 'ab-viewModels/scheduleBoardUpdateCrewType.viewModel';
import type ScheduleBoardUnavailabilityReasonViewModel from 'ab-viewModels/scheduleBoardUnavailabilityReason.viewModel';
import type WorkOrderCodeAndDueDate from 'ab-viewModels/workOrderCodeAndDueDate.viewModel';
import type WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import type { ScheduleBoardEquipmentViewModel, EquipmentAssignments } from 'ab-viewModels/scheduleBoardEquipment.viewModel';
import ScheduleBoardEquipment from 'ab-viewModels/scheduleBoardEquipment.viewModel';
import type { ScheduleBoardEmployeesViewModel, EmployeeAssignments } from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import ScheduleBoardEmployee from 'ab-viewModels/scheduleBoardEmployee.viewModel';
import type ScheduleBoardTemporaryEmployee from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type { TemporaryEmployeeAssignmentsVM } from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import ScheduleBoardTemporaryEmployeeVM from 'ab-viewModels/scheduleBoardTemporaryEmployee.viewModel';
import type ScheduleBoardCopiedWorkOrderViewModel from 'ab-viewModels/scheduleBoardCopiedWorkOrder.viewModel';
import type { FullScheduleBoardProductionDataForDayVM, IndividualLaborStatisticsParsed, LaborStatisticsPerLocationParsed, ScheduleBoardLaborStatisticsForDay } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type ScheduleBoardLaborStatistics from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type ScheduleBoardAddResourceLookupAssignment from 'ab-viewModels/scheduleBoardAddResourceLookupAssignment.viewModel';
import type ScheduleBoardAddTemporaryEmployeeResourceLookupAssignmentVM from 'ab-viewModels/scheduleBoardAddTemporaryEmployeeResourceLookupAssignment.viewModel';
import type { NotificationStatusByEmployee, NotificationLookupViewModel, NotificationStatusViewModel, NotificationStatusByTemporaryEmployee } from 'ab-viewModels/notification.viewModel';
import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';
import type { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';
import type AddEquipmentDownDetails from 'ab-viewModels/equipment/addEquipmentDownDetails.viewModel';
import type { EmployeeUnavailabilityDetails, EmployeeUnavailabilityDetailsMap, EmployeeUnavailabilityDetailsVM } from 'ab-viewModels/dailyEmployeeStatus.viewModel';
import type { EmployeeInfo, LaborStatisticsPerLocationUnparsed, IndividualLaborStatisticsPerLocationUnparsed } from 'ab-viewModels/scheduleBoardLaborStatistics.viewModel';
import type { ScheduleBoardWorkOrdersTimeDataForDayVM } from 'ab-viewModels/scheduleBoardWorkOrdersTime.viewModel';
import { LatestPublishedWorkOrdersVM } from 'ab-viewModels/latestPublishedWorkOrder.viewModel';
import { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import type ScheduleBoardDeleteWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/deleteWorkOrder.viewModel';
import type ScheduleBoardReorderWorkOrdersViewModel from 'ab-socketModels/viewModels/scheduleBoard/reorderWorkOrders.viewModel';
import type ScheduleBoardUpdateWorkOrderNoteViewModel from 'ab-socketModels/viewModels/scheduleBoard/updateWorkOrderNote.viewModel';
import type ScheduleBoardPauseWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/pauseWorkOrder.viewModel';
import type ScheduleBoardResumeWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/resumeWorkOrder.viewModel';
import type ScheduleBoardLockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/lockWorkOrder.viewModel';
import type ScheduleBoardUnlockWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/unlockWorkOrder.viewModel';
import type ScheduleBoardRevertWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/revertWorkOrder.viewModel';
import type ScheduleBoardAddTemporaryEmployeeVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardAddTemporaryEmployee.viewModels';
import type UpdateJobWorkSummaryViewModel from 'ab-socketModels/viewModels/scheduleBoard/updateJobWorkSummary.viewModel';
import type UpdateWorkOrderTime from 'ab-socketModels/viewModels/scheduleBoard/updateWorkOrderTime.viewModel';

import type { BlankWorkOrderRequestModel } from 'ab-requestModels/workOrder.requestModel';
import type { ScheduleBoardDragDestinationData } from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type ScheduleBoardDragRequestModel from 'ab-requestModels/scheduleBoardDrag.requestModel';
import type ScheduleBoardEmployeePerDiemAssignmentRequestModel from 'ab-requestModels/scheduleBoardEmployeePerDiemAssignment.requestModel';
import type ScheduleBoardSetPerDiemForWorkOrdersRequestModel from 'ab-requestModels/scheduleBoardSetPerDiemsForWorkOrder.requestModel';
import type ScheduleBoardReturnDateRequestModel from 'ab-requestModels/scheduleBoardReturnDate.requestModel';

import type * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import type * as GeneralActions from 'af-actions/general';

import type { ScheduleBoardStoreState, SelectedWorkOrderModel } from 'af-models/scheduleBoard.models';
import type {
	CheckEmployeeInAvailableEmployeesNotificationModal,
	CheckAllInAvailableEmployeesNotificationModal,
	EmployeeNotificationStatusData,
	EmployeeNotifyModalState,
	OpenAvailableEmployeesNotificationModalData,
} from 'af-models/employeeNotifyModal.model';
import {
	getNotifyModalEmployeeFromEmployee,
} from 'af-models/employeeNotifyModal.model';

import SocketEvent from 'ab-enums/socketEvent.enum';
import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';
import ScheduleBoardSortType from 'ab-enums/scheduleBoardSortType.enum';
import { DailyViewDragElementType } from 'ab-enums/scheduleBoardDragElementType.enum';

import { RESOURCE_PLACEHOLDER, SCHEDULE_BOARD_TOOLBAR_AVAILABLE, SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE, TOOLBAR_GROUP_DEFAULT_ID } from 'ab-common/constants/scheduleBoard';
import { UNKNOWN_LOCATION_NICKNAME } from 'ab-common/constants/value';

import { NOTIFY_EMPLOYEE_TYPE } from 'af-constants/values';

import * as ScheduleBoardUtil from 'ab-utils/scheduleBoard.util';
import { isEmptyNumber } from 'ab-utils/validation.util';
import * as ArrayUtil from 'ab-utils/array.util';
import socket from 'af-utils/socket.util';
import { filterKeys } from 'ab-utils/object.util';
import { matches } from 'ab-utils/text.util';
import { EquipmentUnavailabilityDetailsMap } from 'ab-viewModels/dailyEquipmentStatus.viewModel';

type GeneralActionsParam = ResolveThunks<typeof GeneralActions>;
type ScheduleBoardActionsParam = ResolveThunks<typeof ScheduleBoardActions>;

// Private

// #region SB Utils Helpers

const _getAvailabilityFromDroppableId = (droppableId: string): boolean => {
	const code = ScheduleBoardUtil.getUniqueCodeFromDroppableId(droppableId);
	const availability = code && ScheduleBoardUtil.getToolbarAvailabilityFromUniqueCode(code);
	return !!availability && ScheduleBoardUtil.isAvailable(availability);
};

const _shouldDisplayInAvailableEmployeesModal = (account: ScheduleBoardEmployee['account']) => {
	return account.assignableToWorkOrder && !account.assignableAsSuperintendent && !account.assignableAsProjectManager;
};

// #endregion SB Utils Helpers

// #region SB Reducer Handlers Helpers

const _updateResourcesOnWorkOrderRemoveOrCancel = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	woCode: string,
	isCanceled: boolean = false
): ScheduleBoardStoreState => {
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	const { workOrders } = workOrdersDictionaryOnDate;
	const workOrder = workOrders[woCode];
	if (!workOrder) {
		console.error('Resources update called for non existing Work Order, operation skipped.');
		return scheduleBoard;
	}
	const workOrderProxyResourceLookups = workOrder.workOrderResourceLookups || [];

	const movedWorkOrders = {} as ScheduleBoardWorkOrdersViewModel;
	if (!isCanceled && workOrder.index !== undefined) {
		Object.keys(workOrders).forEach((_woCode) => {
			const _wo = workOrders[_woCode];
			if (_wo.index > workOrder.index) {
				movedWorkOrders[_woCode] = { ..._wo, index: _wo.index - 1 };
			}
		});
	}
	const newWorkOrdersDict = Object.keys(movedWorkOrders).length ? { ...workOrders, ...movedWorkOrders } : workOrders;

	const {
		assignedEmployeeIds,
		assignedEquipmentIds,
		assignedTemporaryEmployeeIds,
		workOrderEmployeeIds,
		workOrderTemporaryEmployeeIds,
	} = workOrderProxyResourceLookups.reduce((_assignments, _resourceLookupId) => {
		const resource = workOrdersDictionaryOnDate.workOrderResourceLookups[_resourceLookupId];
		if (resource.workOrderEmployeeId && resource.employeeId) {
			_assignments.assignedEmployeeIds.push(resource.employeeId);
			_assignments.workOrderEmployeeIds.push(resource.workOrderEmployeeId);
		} else if (resource.workOrderEquipmentId && resource.equipmentId) {
			_assignments.assignedEquipmentIds.push(resource.equipmentId);
		} else if (resource.workOrderTemporaryEmployeeId && resource.temporaryEmployeeId) {
			_assignments.assignedTemporaryEmployeeIds.push(resource.temporaryEmployeeId);
			_assignments.workOrderTemporaryEmployeeIds.push(resource.workOrderTemporaryEmployeeId);
		}
		return _assignments;
	}, {
		assignedEmployeeIds: [] as number[],
		assignedEquipmentIds: [] as number[],
		assignedTemporaryEmployeeIds: [] as number[],
		workOrderEmployeeIds: [] as number[],
		workOrderTemporaryEmployeeIds: [] as number[],
	});

	const isNotInTargetWorkOrder = (_assignment: string) => _assignment !== woCode;

	const employeeAssignments: EmployeeAssignments = Object.assign({}, workOrdersDictionaryOnDate.employeeAssignments);
	const canceledEmployeeAssignments: EmployeeAssignments = isCanceled
		? Object.assign({}, workOrdersDictionaryOnDate.canceledEmployeeAssignments)
		: workOrdersDictionaryOnDate.canceledEmployeeAssignments;

	assignedEmployeeIds.forEach((_employeeId: number) => {
		const updatedAssignments = employeeAssignments[_employeeId].filter(isNotInTargetWorkOrder);
		if (updatedAssignments.length !== employeeAssignments[_employeeId].length) {
			employeeAssignments[_employeeId] = updatedAssignments;
		}
		if (isCanceled) {
			canceledEmployeeAssignments[_employeeId] = [...(canceledEmployeeAssignments[_employeeId] || []), woCode];
		}
	});

	const temporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM = Object.assign({}, workOrdersDictionaryOnDate.temporaryEmployeeAssignments);
	const canceledTemporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM = isCanceled
		? Object.assign({}, workOrdersDictionaryOnDate.canceledTemporaryEmployeeAssignments)
		: workOrdersDictionaryOnDate.canceledTemporaryEmployeeAssignments;

	assignedTemporaryEmployeeIds.forEach((_temporaryEmployeeId: number) => {
		const updatedAssignments = temporaryEmployeeAssignments[_temporaryEmployeeId].filter(isNotInTargetWorkOrder);
		if (updatedAssignments.length !== temporaryEmployeeAssignments[_temporaryEmployeeId].length) {
			temporaryEmployeeAssignments[_temporaryEmployeeId] = updatedAssignments;
		}
		if (isCanceled) {
			canceledTemporaryEmployeeAssignments[_temporaryEmployeeId] = [...(canceledTemporaryEmployeeAssignments[_temporaryEmployeeId] || []), woCode];
		}
	});

	const equipmentAssignments: EquipmentAssignments = Object.assign({}, workOrdersDictionaryOnDate.equipmentAssignments);
	const canceledEquipmentAssignments: EquipmentAssignments = isCanceled
		? Object.assign({}, workOrdersDictionaryOnDate.canceledEquipmentAssignments)
		: workOrdersDictionaryOnDate.canceledEquipmentAssignments;

	assignedEquipmentIds.forEach((_equipmentId: number) => {
		const updatedAssignments = equipmentAssignments[_equipmentId].filter(isNotInTargetWorkOrder);
		if (updatedAssignments.length !== equipmentAssignments[_equipmentId].length) {
			equipmentAssignments[_equipmentId] = updatedAssignments;
		}
		if (isCanceled) {
			canceledEquipmentAssignments[_equipmentId] = [...(canceledEquipmentAssignments[_equipmentId] || []), woCode];
		}
	});

	const movedNotificationStatuses = workOrderEmployeeIds.reduce((_acc, _woeId) => {
		_acc[_woeId] = workOrdersDictionaryOnDate.assignedPublishedNotificationStatuses[_woeId];
		return _acc;
	}, {} as NotificationStatusByEmployee);

	const movedTempLaborNotificationStatuses = workOrderTemporaryEmployeeIds.reduce((_acc, _woeId) => {
		_acc[_woeId] = workOrdersDictionaryOnDate.assignedPublishedTempLaborNotificationStatuses[_woeId];
		return _acc;
	}, {} as NotificationStatusByTemporaryEmployee);

	const filteredWorkOrderResourceLookups = Object.entries(workOrdersDictionaryOnDate.workOrderResourceLookups || {})
		.reduce((_acc, [_resourceLookupId, _resourceLookup]) => {
			if (!workOrderProxyResourceLookups.includes(+_resourceLookupId)) {
				_acc[_resourceLookupId] = _resourceLookup;
			}
			return _acc;
		}, {});

	const canceledWorkOrderResourceLookups = isCanceled
		? Object.assign(
			{},
			workOrdersDictionaryOnDate.canceledWorkOrderResourceLookups,
			workOrderProxyResourceLookups.reduce((_acc: ScheduleBoardWorkOrderResourceLookupsViewModel, _resourceLookupId: number) => {
				_acc[_resourceLookupId] = workOrdersDictionaryOnDate.workOrderResourceLookups[_resourceLookupId];
				return _acc;
			}, {})
		)
		: workOrdersDictionaryOnDate.canceledWorkOrderResourceLookups;

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: newWorkOrdersDict,
				employeeAssignments,
				equipmentAssignments,
				temporaryEmployeeAssignments,
				workOrderResourceLookups: filteredWorkOrderResourceLookups,
				canceledWorkOrderResourceLookups,
				canceledEmployeeAssignments,
				canceledEquipmentAssignments,
				canceledTemporaryEmployeeAssignments,
				assignedCanceledNotificationStatuses: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].assignedCanceledNotificationStatuses,
					...movedNotificationStatuses,
				},
				assignedCanceledTempLaborNotificationStatuses: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].assignedCanceledTempLaborNotificationStatuses,
					...movedTempLaborNotificationStatuses,
				},
			},
		},
	};
};

const _updateResourcesOnWorkOrderPauseOrResume = (
	scheduleBoard: ScheduleBoardStoreState,
	/** YYYY-MM-DD */
	dueDate: string,
	woCode: string,
	statusBasedRevision: string,
	isPaused: boolean = false
): ScheduleBoardStoreState => {
	// Get the work orders for the due date.
	const displayDate = TimeUtils.formatDate(dueDate, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[displayDate];
	const { workOrders } = workOrdersDictionaryOnDate;

	// Find the work order to pause or resume.
	const workOrder = workOrders[woCode];

	if (!workOrder) {
		console.error('Resources update called for non existing Work Order, operation skipped.');
		return scheduleBoard;
	}

	// Create a new work orders dictionary, with the paused status updated for the specified work order.
	const newWorkOrdersDict = {
		...workOrders,
		[woCode]: {
			...workOrder,
			isPaused,
			statusBasedRevision,
		},
	};

	// Return the new schedule board state, with the updated work orders dictionary.
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[displayDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: newWorkOrdersDict,
			},
		},
	};
};

const _updateResourcesOnWorkOrderRevert = (
	scheduleBoard: ScheduleBoardStoreState,
	workOrder: ScheduleBoardWorkOrderViewModel
): ScheduleBoardStoreState => {
	// Get the work orders for the due date.
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[workOrder.dueDate];
	const { workOrders } = workOrdersDictionaryOnDate;

	if (!workOrder) {
		console.error('Resources update called for non existing Work Order, operation skipped.');
		return scheduleBoard;
	}

	// Create a new work orders dictionary with updates
	const newWorkOrdersDict = {
		...workOrders,
		[workOrder.code]: workOrder,
	};

	// Return the new schedule board state, with the updated work orders dictionary.
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[workOrder.dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: newWorkOrdersDict,
			},
		},
	};
};

const _updateResourcesOnWorkOrderLockOrUnlock = (
	scheduleBoard: ScheduleBoardStoreState,
	/** YYYY-MM-DD */
	dueDateInDbFormat: string,
	woCode: string,
	isLock: boolean = false
): ScheduleBoardStoreState => {
	// Get the work orders for the due date.
	const dueDate = TimeUtils.formatDate(dueDateInDbFormat, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	const { workOrders } = workOrdersDictionaryOnDate;

	// Find the work order to lock or unlock.
	const workOrder = workOrders[woCode];

	if (!workOrder) {
		console.error('Resources update called for non existing Work Order, operation skipped.');
		return scheduleBoard;
	}

	// Create a new work orders dictionary, with the status updated for the specified work order.
	const newWorkOrdersDict = {
		...workOrders,
		[woCode]: {
			...workOrder,
			status: isLock ? WorkOrderStatus.LOCKED : WorkOrderStatus.PUBLISHED,
		},
	};

	// Return the new schedule board state, with the updated work orders dictionary.
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: newWorkOrdersDict,
			},
		},
	};
};

type GetWorkOrdersDataWithoutResourceReturnType = {
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel;
	workOrders: ScheduleBoardWorkOrdersViewModel;
};
const _getWorkOrdersDataWithoutResource = (
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel,
	workOrders: ScheduleBoardWorkOrdersViewModel,
	isNotTargetResource: (resourceId: number | string) => boolean
): GetWorkOrdersDataWithoutResourceReturnType => {

	const updatedWorkOrderResourceLookups = Object.keys(workOrderResourceLookups).reduce(
		(_acc, _resourceId: string) => {
			if (isNotTargetResource(_resourceId)) {
				_acc[_resourceId] = workOrderResourceLookups[_resourceId];
			}
			return _acc;
		},
		{} as ScheduleBoardWorkOrderResourceLookupsViewModel
	);

	const updatedWorkOrderIds: number[] = [];
	const updatedWorkOrders: ScheduleBoardWorkOrdersViewModel = Object.entries(workOrders).reduce(
		(_acc, [_woCode, _wo]: [string, ScheduleBoardWorkOrderViewModel]) => {
			_acc[_woCode] = _wo;	// leave the same reference there is no change
			if (_wo.status === WorkOrderStatus.CANCELED) {
				return _acc;
			}
			const _workOrderResourceLookups = (_wo.workOrderResourceLookups || []);
			const filteredWorkOrderResourceLookups = _workOrderResourceLookups.filter(isNotTargetResource);

			if (_workOrderResourceLookups.length !== filteredWorkOrderResourceLookups.length) {
				updatedWorkOrderIds.push(_wo.id);
				_acc[_woCode] = {
					..._wo,
					workOrderResourceLookups: filteredWorkOrderResourceLookups,
					status: _wo.status === WorkOrderStatus.PUBLISHED ? WorkOrderStatus.OUTDATED : _wo.status,
				};
			}
			return _acc;
		}, {} as ScheduleBoardWorkOrdersViewModel
	);

	if (updatedWorkOrderIds.length === 0) {
		return {
			workOrderResourceLookups: updatedWorkOrderResourceLookups,	// just in case something was not stored correctly
			workOrders,
		};
	}

	return {
		workOrderResourceLookups: updatedWorkOrderResourceLookups,
		workOrders: updatedWorkOrders,
	};
};

interface GetWorkOrdersDataWithoutResourceOnDraftsReturnType extends GetWorkOrdersDataWithoutResourceReturnType {
	updatedWorkOrdersDict: Record<string, boolean>;
}

const _getWorkOrdersDataWithoutResourceOnDrafts = (
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel,
	workOrders: ScheduleBoardWorkOrdersViewModel,
	isNotTargetResource: (resourceId: number | string) => boolean
): GetWorkOrdersDataWithoutResourceOnDraftsReturnType => {

	const updatedWorkOrderResourceLookups = Object.keys(workOrderResourceLookups).reduce(
		(_acc, _resourceId: string) => {
			const workOrderCode = workOrderResourceLookups[_resourceId].workOrderCode;
			const notDraftWorkOrder = workOrders?.[workOrderCode]?.status !== WorkOrderStatus.DRAFT;
			const notTargetResource = isNotTargetResource(_resourceId);
			if (notTargetResource || (!notTargetResource && notDraftWorkOrder)) {
				_acc[_resourceId] = workOrderResourceLookups[_resourceId];
			}
			return _acc;
		},
		{} as ScheduleBoardWorkOrderResourceLookupsViewModel
	);

	const updatedWorkOrdersDict: Record<number, boolean> = {};
	const updatedWorkOrders: ScheduleBoardWorkOrdersViewModel = Object.entries(workOrders).reduce(
		(_acc, [_woCode, _wo]: [string, ScheduleBoardWorkOrderViewModel]) => {
			_acc[_woCode] = _wo;	// leave the same reference there is no change
			if (_wo.status === WorkOrderStatus.CANCELED) {
				return _acc;
			}

			const _workOrderResourceLookups = (_wo.workOrderResourceLookups || []);
			const filteredWorkOrderResourceLookups = _workOrderResourceLookups.filter(isNotTargetResource);

			if (_workOrderResourceLookups.length !== filteredWorkOrderResourceLookups.length && _wo.status === WorkOrderStatus.DRAFT) {
				updatedWorkOrdersDict[_wo.code] = true;
				_acc[_woCode] = {
					..._wo,
					workOrderResourceLookups: filteredWorkOrderResourceLookups,
				};
			}
			return _acc;
		}, {} as ScheduleBoardWorkOrdersViewModel
	);

	if (Object.keys(updatedWorkOrdersDict).length === 0) {
		return {
			workOrderResourceLookups: updatedWorkOrderResourceLookups,	// just in case something was not stored correctly
			workOrders,
			updatedWorkOrdersDict,
		};
	}

	return {
		workOrderResourceLookups: updatedWorkOrderResourceLookups,
		workOrders: updatedWorkOrders,
		updatedWorkOrdersDict,
	};
};

const _getBoardList = (scheduleBoard: ScheduleBoardStoreState, droppableId: string, dueDate: string, code: Nullable<string>): string[] | number[] => {
	const scheduleBoardOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (isWorkOrder(droppableId)) {
		return isLoadingPlaceholderDroppableId(droppableId)
			? []
			: scheduleBoardOnDate.workOrdersOrdering;
	}

	if (!code) {
		throw new Error('[SB LIST] Code not provided');
	}

	return scheduleBoardOnDate.workOrders[code].workOrderResourceLookups;
};

const _getToolbarList = (scheduleBoard: ScheduleBoardStoreState, droppableId: string, dueDate: string, code: string): number[] => {
	// toolbar context
	const _code = getToolbarCodeFromUniqueCode(code);
	const availability = getToolbarAvailabilityFromUniqueCode(code);

	if (isEmployee(droppableId)) {
		const employeeList: number[] = (
			!!availability && !!_code && scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.toolbarEmployees?.[availability]?.[_code]
		)
			? scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEmployees[availability][_code]
			: [];
		return employeeList.filter((_employeeId) => {

			const employee = scheduleBoard?.employees?.[_employeeId];

			return employee?.account?.assignableToWorkOrder && employee?.showOnScheduleBoard;
		});
	} else {
		const equipmentList: number[] = (
			!!availability && !!_code && scheduleBoard?.workOrdersByDateDictionary?.[dueDate]?.toolbarEquipment?.[availability]?.[_code]
		)
			? scheduleBoard.workOrdersByDateDictionary[dueDate]?.toolbarEquipment[availability][_code] ?? []
			: [];
		const _equipment = scheduleBoard?.equipment;
		return equipmentList.filter((_eqId) => _equipment?.[_eqId]?.showOnScheduleBoard);
	}
};

const _getList = (scheduleBoard: ScheduleBoardStoreState, droppableId: string): string[] | number[] => {
	const code = getUniqueCodeFromDroppableId(droppableId) ?? null;
	const dueDate = getDueDateFromDroppableId(droppableId) ?? null;

	if (!dueDate) {
		throw new Error('[SB LIST] Due date not provided');
	}

	if (isOnBoard(droppableId)) {
		return _getBoardList(scheduleBoard, droppableId, dueDate, code);
	} else {
		if (!code) {
			throw new Error('[SB LIST] Code not provided');
		}
		return _getToolbarList(scheduleBoard, droppableId, dueDate, code);
	}
};

const _move = (
	source: ReturnType<typeof _getList>,
	destination: ReturnType<typeof _getList>,
	droppableSource: { index: number; droppableId: string; },
	droppableDestination: { index: number; droppableId: string; }
) => {
	const sourceClone: ReturnType<typeof _getList> = Array.from(source as string[]);
	const destClone: ReturnType<typeof _getList> = Array.from(destination as string[]);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

const _updateBoardList = (
	scheduleBoard: ScheduleBoardStoreState,
	newList: string[] | number[],
	droppableId: string,
	dueDate: string,
	code: Nullable<string>
): ScheduleBoardStoreState => {
	if (isWorkOrder(droppableId)) {
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering = newList as string[];
	} else {
		if (!code) {
			throw new Error('[SB LIST] Code not provided');
		}

		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[code].workOrderResourceLookups = newList as number[];
	}
	return scheduleBoard;
};

const _updateToolbarList = (
	scheduleBoard: ScheduleBoardStoreState,
	newList: number[],
	droppableId: string,
	dueDate: string,
	code: string
): ScheduleBoardStoreState => {
	const _code = getToolbarCodeFromUniqueCode(code);
	const availability = getToolbarAvailabilityFromUniqueCode(code);

	if (!!availability && !!_code) {
		if (isEmployee(droppableId) && scheduleBoard.employees) {
			scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEmployees[availability][_code] = _sortToolbarEmployees(newList, scheduleBoard.employees);
		} else if (scheduleBoard.equipment) {
			scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEquipment[availability][_code] = _sortToolbarEquipment(newList, scheduleBoard.equipment);
		}
	}

	return scheduleBoard;
};

const _updateList = (scheduleBoard: ScheduleBoardStoreState, newList, droppableId: string, dueDate: string): ScheduleBoardStoreState => {
	const code = getUniqueCodeFromDroppableId(droppableId) ?? null;

	if (isOnBoard(droppableId)) {
		return _updateBoardList(scheduleBoard, newList, droppableId, dueDate, code);
	} else {
		if (!code) {
			throw new Error('[SB LIST] Code not provided');
		}

		return _updateToolbarList(scheduleBoard, newList, droppableId, dueDate, code);
	}
};

const _compareToolbarEmployees = (employeeA: ScheduleBoardEmployee, employeeB: ScheduleBoardEmployee) => {
	const compareOptions = { sensitivity: 'base' } as const;
	const employeeAOfficeIndex = employeeA?.office?.index ?? Infinity;
	const employeeBOfficeIndex = employeeB?.office?.index ?? Infinity;
	const employeeALastName = employeeA?.lastName ?? '';
	const employeeAFirstName = employeeA?.firstName ?? '';
	const employeeBLastName = employeeB?.lastName ?? '';
	const employeeBFirstName = employeeB?.firstName ?? '';

	const indexComparison = !isFinite(employeeAOfficeIndex) && !isFinite(employeeBOfficeIndex) ? 0 : employeeAOfficeIndex - employeeBOfficeIndex;
	const lastNameComparison = indexComparison === 0 ? employeeALastName.localeCompare(employeeBLastName, [], compareOptions) : indexComparison;
	return lastNameComparison === 0 ? employeeAFirstName.localeCompare(employeeBFirstName, [], compareOptions) : lastNameComparison;
};

const _hasAlreadyNotifiedEmployees = (employees: EmployeeNotificationStatusData) => {
	return Object.values(employees).some((_e) => (_e.shouldSendEmail && !!_e.lastEmailSentAt) || (_e.shouldSendSms && !!_e.lastSmsSentAt));
};

const _sortToolbarEmployees = (employees: number[], scheduleBoardEmployees: ScheduleBoardEmployeesViewModel) => {
	return employees.sort((employeeAId: number, employeeBId: number) => {
		const employeeA = scheduleBoardEmployees[employeeAId];
		const employeeB = scheduleBoardEmployees[employeeBId];
		return _compareToolbarEmployees(employeeA, employeeB);
	});
};

const _sortToolbarEquipment = (equipmentIds: number[], scheduleBoardEquipment: ScheduleBoardEquipmentViewModel) => {
	const _fallbackEquipment = { orderNumber: 0 };
	return [...equipmentIds].sort((equipmentAId: number, equipmentBId: number) => {
		const equipmentA = scheduleBoardEquipment[equipmentAId] ?? _fallbackEquipment;
		const equipmentB = scheduleBoardEquipment[equipmentBId] ?? _fallbackEquipment;

		return equipmentA.orderNumber! - equipmentB.orderNumber!;
	});
};

const _getSortedWorkOrderCodes = (
	workOrders: ScheduleBoardWorkOrdersViewModel,
	compare: (wo1: ScheduleBoardWorkOrderViewModel, wo2: ScheduleBoardWorkOrderViewModel) => number
) => {
	return Object.values(workOrders).sort(compare).map((wo: ScheduleBoardWorkOrderViewModel) => wo.code);
};

const _compareByOfficeAndJobNumberAsc = (wo1: ScheduleBoardWorkOrderViewModel, wo2: ScheduleBoardWorkOrderViewModel) => {
	const officeCompare = wo1.officeNickname.localeCompare(wo2.officeNickname);
	return officeCompare !== 0 ? officeCompare : wo1.codeStripped.localeCompare(wo2.codeStripped);
};

const _compareByOfficeAndJobNumberDesc = (wo1: ScheduleBoardWorkOrderViewModel, wo2: ScheduleBoardWorkOrderViewModel) => {
	return -_compareByOfficeAndJobNumberAsc(wo1, wo2);
};

const _getWorkOrdersOrderingForSort = (workOrders: ScheduleBoardWorkOrdersViewModel, workOrdersSort: ScheduleBoardSortType, dueDate: string) => {
	switch (workOrdersSort) {
		case ScheduleBoardSortType.FREE_REORDERING:
			const indexByWorkOrderCodeDict = ScheduleBoardUtil.getWorkOrderIndexDict(
				Object.values(workOrders),
				(wo: ScheduleBoardWorkOrderViewModel) => wo.code
			);
			return ScheduleBoardUtil.addBlankWorkOrders(
				indexByWorkOrderCodeDict,
				dueDate
			);
		case ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_ASC:
			return _getSortedWorkOrderCodes(
				workOrders,
				_compareByOfficeAndJobNumberAsc
			);
		case ScheduleBoardSortType.OFFICE_AND_JOB_NUMBER_DESC:
			return _getSortedWorkOrderCodes(
				workOrders,
				_compareByOfficeAndJobNumberDesc
			);
		default:
			return null;

	}
};

const _updateWorkOrderStatus = (workOrder: ScheduleBoardWorkOrderViewModel) => (
	workOrder.status === WorkOrderStatus.PUBLISHED
		? WorkOrderStatus.OUTDATED
		: workOrder.status
);

/**
 * Outdates Work Order if PUBLISHED
 *
 * @param dueDate `MM-DD-YYYY`
 */
const _updateWorkOrderStatusIfNeeded = (scheduleBoard: ScheduleBoardStoreState, workOrderCode: string, dueDate: string): ScheduleBoardStoreState => {
	const workOrders = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders;
	const workOrder = workOrders[workOrderCode];

	if (workOrder?.status === WorkOrderStatus.PUBLISHED) {
		return {
			...scheduleBoard,
			workOrdersByDateDictionary: {
				...scheduleBoard.workOrdersByDateDictionary,
				[dueDate]: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate],
					workOrders: {
						...workOrders,
						[workOrderCode]: {
							...workOrder,
							status: WorkOrderStatus.OUTDATED,
						},
					},
				},
			},
		};
	} else {
		return scheduleBoard;
	}
};

const _mapRevisionLookupToBaseSBModel = (revisionLookup: WorkOrderResourceLookupModel, workOrderCode: string): Single => {
	if (!workOrderCode) {
		throw new Error('Revision has no work order code');
	}
	return {
		id: revisionLookup.id,
		index: revisionLookup.index,
		employeeId: revisionLookup.employeeId,
		temporaryEmployeeId: revisionLookup.temporaryEmployeeId,
		workOrderEmployeeId: revisionLookup.workOrderEmployeeId,
		isDisabled: false,
		workOrderCode,
	};
};

interface RevisionLookupsById {
	[id: number]: Single;
}

const _revisionLookupsById = (revisions: LatestPublishedWorkOrdersVM): RevisionLookupsById => {

	const result: RevisionLookupsById = {};

	for (const workOrderId of Object.keys(revisions)) {
		for (const lookup of revisions[workOrderId].resourceLookups) {
			result[lookup.id] = _mapRevisionLookupToBaseSBModel(lookup, revisions[workOrderId].workOrderCode);
		}
	}

	return result;
};

const _workOrdersByCodeInRevisions = (revisions: LatestPublishedWorkOrdersVM): Record<string, true> => {
	return Object.keys(revisions).reduce<Record<string, true>>((_acc, _woId) => {
		const revision = revisions[_woId];
		_acc[revision.workOrderCode] = true;
		return _acc;
	}, {});
};

const _wasResourceAddedAfterPublishing = (
	woCode: string,
	workOrdersWithRevisions: Record<string, true>,
	id: number,
	lookupsById: RevisionLookupsById
): boolean => {
	return woCode in workOrdersWithRevisions && !(id in lookupsById);
};

const _workOrderResourceLookupsWithLastestRevisions = (
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel,
	revisions: Nullable<LatestPublishedWorkOrdersVM>
): ScheduleBoardWorkOrderResourceLookupsViewModel => {

	if (!revisions || !Object.keys(revisions).length) {
		return workOrderResourceLookups;
	}

	const revisionLookupsById = _revisionLookupsById(revisions);
	const workOrdersByCodeInRevisions = _workOrdersByCodeInRevisions(revisions);

	const result = Object.entries(workOrderResourceLookups).reduce((_acc, [_id, _lookup]) => {
		if (!_lookup.workOrderCode) {
			throw new Error('Work order resource has no work order code.');
		}
		if (_id in revisionLookupsById) {
			_acc[_id] = revisionLookupsById[_id];
			return _acc;
		}
		if (_wasResourceAddedAfterPublishing(_lookup.workOrderCode, workOrdersByCodeInRevisions, _lookup.id, revisionLookupsById)) {
			return _acc;
		}

		_acc[_lookup.id] = _lookup;
		return _acc;
	}, revisionLookupsById);

	return result;
};

const _updateLaborStatistics = (scheduleBoard: ScheduleBoardStoreState, dueDate: string): ScheduleBoardStoreState => {
	const { employees, workOrdersByDateDictionary, locations, latestPublishedRevisions } = scheduleBoard;

	if (!workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	const { workOrders, workOrderResourceLookups } = workOrdersByDateDictionary[dueDate];
	const resourceLookupsUpdatedByRevisions = _workOrderResourceLookupsWithLastestRevisions(workOrderResourceLookups, latestPublishedRevisions);

	const laborStatistics = calculateLaborStatistics(
		employees,
		workOrders,
		resourceLookupsUpdatedByRevisions,
		Object.values(locations ?? {})
	);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				laborStatistics,
			},
		},
	};
};

export const updateLaborStatisticsForLoadedDates = (
	scheduleBoard: ScheduleBoardStoreState
) => {

	let updatedState = scheduleBoard;

	if (updatedState.date) {
		updatedState = _updateLaborStatistics(updatedState, updatedState.date);
	}

	if (updatedState.startDate && updatedState.endDate) {
		TimeUtils.getDaysBetweenInclusive(updatedState.startDate, updatedState.endDate, TimeFormat.DATE_ONLY).forEach((_date) => {
			updatedState = _updateLaborStatistics(updatedState, _date);
		});
	}

	return updatedState;
};

const _updateWorkOrderResourceLookupList = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	workOrderCode: string,
	newWorkOrderResourceLookupList: number[]
): ScheduleBoardStoreState => {
	const workOrder = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[workOrderCode];

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
					[workOrderCode]: {
						...workOrder,
						workOrderResourceLookups: [...newWorkOrderResourceLookupList],
						status: _updateWorkOrderStatus(workOrder),
					} as ScheduleBoardWorkOrderViewModel,
				},
			},
		},
	};
};

const _areWorkOrderRowDistributionsEqual = (
	columnNumbersDict: ColumnNumbersForWorkOrder,
	workOrdersRowDistribution: WorkOrdersRowDistribution,
	originalColumnNumbersDict: ColumnNumbersForWorkOrder,
	originalWorkOrdersRowDistribution: WorkOrdersRowDistribution
): boolean => {
	const columnNumbersDictKeys = Object.keys(columnNumbersDict);
	const originalColumnNumbersDictKeys = Object.keys(originalColumnNumbersDict);

	if (columnNumbersDictKeys.length !== originalColumnNumbersDictKeys.length) {
		return false;
	}

	const workOrdersRowDistributionKeys = Object.keys(workOrdersRowDistribution);
	const originalWorkOrdersRowDistributionKeys = Object.keys(originalWorkOrdersRowDistribution);

	if (workOrdersRowDistributionKeys.length !== originalWorkOrdersRowDistributionKeys.length) {
		return false;
	}

	// this function will be called inside a loop, use for instead of lambda:
	for (const columnNumbersDictKey of columnNumbersDictKeys) {
		if (columnNumbersDict[columnNumbersDictKey] !== originalColumnNumbersDict[columnNumbersDictKey]) {
			return false;
		}
	}
	for (const workOrdersRowDistributionKey of workOrdersRowDistributionKeys) {
		if (workOrdersRowDistribution[workOrdersRowDistributionKey] !== originalWorkOrdersRowDistribution[workOrdersRowDistributionKey]) {
			return false;
		}
	}
	return true;
};

const _addEmployeeAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	employeeId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const employeeAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].employeeAssignments;
	// ensures uniqueness and removes assignments for WOs which don't exist anymore
	const currentEmployeeAssignmentMap = (employeeAssignments[employeeId] || []).reduce<Record<string, true>>((_acc, _code) => {
		if (scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[_code]) {
			_acc[_code] = true;
		}
		return _acc;
	}, {});

	currentEmployeeAssignmentMap[workOrderCode] = true;

	const currentEmployeeAssignments = Object.keys(currentEmployeeAssignmentMap);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				employeeAssignments: {
					...employeeAssignments,
					[employeeId]: currentEmployeeAssignments,
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _filterEmployeeAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	employeeId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const employeeAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].employeeAssignments;
	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				employeeAssignments: {
					...employeeAssignments,
					[employeeId]: (employeeAssignments[employeeId] || []).filter((woCode) => woCode !== workOrderCode),
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _addEquipmentAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	equipmentId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const equipmentAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentAssignments;
	// ensures uniqueness and removes assignments for WOs which don't exist anymore
	const currentEquipmentAssignmentMap = (equipmentAssignments[equipmentId] ?? []).reduce<Record<string, true>>((_acc, _code) => {
		if (scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[_code]) {
			_acc[_code] = true;
		}
		return _acc;
	}, {});

	currentEquipmentAssignmentMap[workOrderCode] = true;

	const currentEquipmentAssignments = Object.keys(currentEquipmentAssignmentMap);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				equipmentAssignments: {
					...equipmentAssignments,
					[equipmentId]: currentEquipmentAssignments,
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _filterEquipmentAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	equipmentId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const equipmentAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentAssignments;
	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				equipmentAssignments: {
					...equipmentAssignments,
					[equipmentId]: (equipmentAssignments[equipmentId] || []).filter((woCode) => woCode !== workOrderCode),
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

/**
 * @param dueDate `MM-DD-YYYY`
 */
const _filterTemporaryEmployeeAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	temporaryEmployeeId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const temporaryEmployeeAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].temporaryEmployeeAssignments;
	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				temporaryEmployeeAssignments: {
					...temporaryEmployeeAssignments,
					[temporaryEmployeeId]: (temporaryEmployeeAssignments[temporaryEmployeeId] || []).filter((woCode) => woCode !== workOrderCode),
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

/**
 * @param dueDate `MM-DD-YYYY`
 */
const _addTemporaryEmployeeAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	temporaryEmployeeId: number,
	workOrderCode: string,
	dueDate: string
): ScheduleBoardStoreState => {
	const temporaryEmployeeAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].temporaryEmployeeAssignments;
	// ensures uniqueness and removes assignments for WOs which don't exist anymore
	const currentTemporaryEmployeeAssignmentMap = (temporaryEmployeeAssignments[temporaryEmployeeId] ?? []).reduce<Record<string, true>>((_acc, _code) => {
		if (scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[_code]) {
			_acc[_code] = true;
		}
		return _acc;
	}, {});

	currentTemporaryEmployeeAssignmentMap[workOrderCode] = true;

	const currentTemporaryEmployeeAssignments = Object.keys(currentTemporaryEmployeeAssignmentMap);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				temporaryEmployeeAssignments: {
					...temporaryEmployeeAssignments,
					[temporaryEmployeeId]: currentTemporaryEmployeeAssignments,
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _getUpdateAssignmentReducer = (workOrder: ScheduleBoardWorkOrderViewModel) => {
	type UpdateAssignmentReducer = {
		updatedEmployeeAssignments: EmployeeAssignments;
		updatedEquipmentAssignments: EquipmentAssignments;
		updatedTemporaryEmployeeAssignments: TemporaryEmployeeAssignmentsVM;
	};
	return (_acc: UpdateAssignmentReducer, _resourceLookup: ScheduleBoardResourceLookupViewModel) => {
		if (_resourceLookup.workOrderPlaceholderId) {
			return _acc;
		}
		let targetObject: Nullable<string> = null;
		let targetIdPropName: Nullable<string> = null;
		if (_resourceLookup.workOrderEmployeeId) {
			targetObject = 'updatedEmployeeAssignments';
			targetIdPropName = 'employeeId';
		} else if (_resourceLookup.workOrderEquipmentId) {
			targetObject = 'updatedEquipmentAssignments';
			targetIdPropName = 'equipmentId';
		} else if (_resourceLookup.workOrderTemporaryEmployeeId) {
			targetObject = 'updatedTemporaryEmployeeAssignments';
			targetIdPropName = 'temporaryEmployeeId';
		} else {
			return _acc;
		}

		const targetId = _resourceLookup[targetIdPropName];
		if (!_acc[targetObject][targetId]) {
			_acc[targetObject][targetId] = [];
		}
		_acc[targetObject][targetId].push(workOrder.code);

		return _acc;
	};
};

const _addWorkOrderThenGetOrdering = (workOrders: ScheduleBoardWorkOrdersViewModel, workOrder: ScheduleBoardWorkOrderViewModel, date: string) => {
	const newWorkOrders = { ...workOrders, [workOrder.code]: workOrder };
	const shouldShiftOrders = Object.values(workOrders).some((_wo) => _wo.index === workOrder.index);
	const { maxIndex, workOrdersDict } = Object.values(newWorkOrders).reduce(
		(_acc, _wo) => {
			_acc.maxIndex = Math.max(_wo.index, _acc.maxIndex);
			if (shouldShiftOrders && _wo.index >= workOrder.index && _wo.code !== workOrder.code) {
				_acc.workOrdersDict[_wo.index + 1] = _wo.code;
				_wo.index = _wo.index + 1;
			} else {
				_acc.workOrdersDict[_wo.index] = _wo.code;
			}
			return _acc;
		},
		{ maxIndex: 0, workOrdersDict: {} } as { maxIndex: number; workOrdersDict: { [T: number]: string; }; }
	);

	const ordering: string[] = [];
	for (let _index = 1; _index <= (shouldShiftOrders ? maxIndex + 1 : maxIndex); _index += 1) {
		ordering[_index - 1] = workOrdersDict[_index] || generateBlankWorkOrderId(date, _index);
	}
	return ordering;
};

const _getBoardAfterCopy = (board: ScheduleBoardStoreState, data: ScheduleBoardCopiedWorkOrderViewModel) => {
	const { date, workOrder, workOrderResourceLookup: newWorkOrderResourceLookup } = data;

	const {
		employeeAssignments = {},
		equipmentAssignments = {},
		temporaryEmployeeAssignments = {},
		workOrders = {},
		workOrderResourceLookups = {},
	} = board.workOrdersByDateDictionary[date] ?? {};

	const {
		updatedEmployeeAssignments,
		updatedEquipmentAssignments,
		updatedTemporaryEmployeeAssignments,
	} = Object.values(newWorkOrderResourceLookup).reduce(
		_getUpdateAssignmentReducer(workOrder),
		{
			updatedEmployeeAssignments: employeeAssignments,
			updatedEquipmentAssignments: equipmentAssignments,
			updatedTemporaryEmployeeAssignments: temporaryEmployeeAssignments,
		}
	);

	const ordering = _addWorkOrderThenGetOrdering(workOrders, workOrder, date);

	return {
		...board,
		workOrdersByDateDictionary: {
			...board.workOrdersByDateDictionary,
			[date]: {
				...board.workOrdersByDateDictionary[date],
				workOrders: { ...workOrders, [workOrder.code]: workOrder },
				workOrdersOrdering: ordering,
				workOrderResourceLookups: { ...workOrderResourceLookups, ...newWorkOrderResourceLookup },
				employeeAssignments: updatedEmployeeAssignments,
				equipmentAssignments: updatedEquipmentAssignments,
				temporaryEmployeeAssignments: updatedTemporaryEmployeeAssignments,
			},
		},
	};
};

const _updateWorkOrderJob = (scheduleBoard: ScheduleBoardStoreState, workOrder: ScheduleBoardWorkOrderViewModel): ScheduleBoardStoreState => {
	const dueDate = workOrder.dueDate;
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	const workOrderListOnDate = Object.values(workOrdersDictionaryOnDate.workOrders);
	const oldWorkOrder = workOrderListOnDate.find((_wo) => _wo.id === workOrder.id);

	if (!oldWorkOrder) {
		throw Error('Cannot update work order that does not exist.');
	}

	const updatedWorkOrders = workOrderListOnDate.reduce((_acc, _wo) => {
		if (_wo.id === workOrder.id) {
			_acc[workOrder.code] = workOrder;
		} else {
			_acc[_wo.code] = { ..._wo };
		}
		return _acc;
	}, {});

	const updatedWorkOrderIndex = workOrdersDictionaryOnDate.workOrdersOrdering.indexOf(oldWorkOrder.code);
	const updatedWorkOrderOrdering = [...workOrdersDictionaryOnDate.workOrdersOrdering];
	updatedWorkOrderOrdering.splice(updatedWorkOrderIndex, 1, workOrder.code);

	const updatedColumnNumbersDict = Object.keys(workOrdersDictionaryOnDate.columnNumbersDict).reduce((_acc, _code) => {
		if (_code === oldWorkOrder.code) {
			_acc[workOrder.code] = workOrdersDictionaryOnDate.columnNumbersDict[_code];
		} else {
			_acc[_code] = workOrdersDictionaryOnDate.columnNumbersDict[_code];
		}
		return _acc;
	}, {});

	const updatedWorkOrdersRowDistribution = Object.keys(workOrdersDictionaryOnDate.workOrdersRowDistribution).reduce((_acc, _index) => {
		const _rowElements = workOrdersDictionaryOnDate.workOrdersRowDistribution[_index];
		const workOrderIndex = _rowElements.indexOf(oldWorkOrder.code);
		if (workOrderIndex !== -1) {
			_acc[_index] = [..._rowElements];
			_acc[_index].splice(workOrderIndex, 1, workOrder.code);
		} else {
			_acc[_index] = _rowElements;
		}
		return _acc;
	}, {});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: updatedWorkOrders,
				workOrdersOrdering: updatedWorkOrderOrdering,
				columnNumbersDict: updatedColumnNumbersDict,
				workOrdersRowDistribution: updatedWorkOrdersRowDistribution,
			},
		},
	};
};

const _addUniqueElement = (list: Nullable<number[]>, newElement: number): number[] => {
	const _list = list ?? [];
	if (_list.includes(newElement)) {
		return _list;
	}
	return [..._list, newElement];
};

const _resetEmployeeNotificationStatusAndPerDiem = (scheduleBoard: ScheduleBoardStoreState, resourceId: number, dueDate: string): ScheduleBoardStoreState => {
	const workOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrderResourceLookups: {
					...workOrderResourceLookups,
					[resourceId]: {
						...workOrderResourceLookups[resourceId],
						perDiem: false,
					},
				},
				assignedPublishedNotificationStatuses: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].assignedPublishedNotificationStatuses,
					[workOrderResourceLookups[resourceId].workOrderEmployeeId!]: null,
				},
			},
		},
	};
};

const _addWorkOrderEmployeeAssignmentAndUpdateResourceLookupOnDate = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardAddResourceLookupAssignment
): ScheduleBoardStoreState => {
	if (!payload.workOrderResourceLookup.workOrderCode) {
		throw new Error('Work order code not provided');
	}
	if (!payload.workOrderResourceLookup.employeeId) {
		throw new Error('Employee id not provided');
	}
	const updatedScheduleBoard = updateReducerOnAddResourceLookupWorkAssignment(scheduleBoard, payload);
	return _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, payload.workOrderResourceLookup.workOrderCode, payload.dueDate);
};

const _removeWorkOrderEmployeeAssignment = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardEmployeeAssignment): ScheduleBoardStoreState => {
	const { employeeId, workOrderCode, dueDate } = payload;
	const updatedScheduleBoard = _filterEmployeeAssignment(scheduleBoard, employeeId, workOrderCode, dueDate);
	return _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, workOrderCode, dueDate);
};

const _addWorkOrderEquipmentAssignment = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardEquipmentAssignment): ScheduleBoardStoreState => {
	const { equipmentId, workOrderCode, dueDate } = payload;
	const updatedScheduleBoard = _addEquipmentAssignment(scheduleBoard, equipmentId, workOrderCode, dueDate);
	return _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, workOrderCode, dueDate);
};

const _removeWorkOrderEquipmentAssignment = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardEquipmentAssignment): ScheduleBoardStoreState => {
	const { equipmentId, workOrderCode, dueDate } = payload;
	const updatedScheduleBoard = _filterEquipmentAssignment(scheduleBoard, equipmentId, workOrderCode, dueDate);
	return _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, workOrderCode, dueDate);
};

const _genericFilterCondition = (id: number, checked: boolean, attribute: string | number | undefined): boolean => {
	if (id === ScheduleBoardFilterType.ASSIGNED && checked) {
		return !!attribute;
	} else if (id === ScheduleBoardFilterType.UNASSIGNED && checked) {
		return !attribute;
	} else {
		return id === attribute && checked;
	}
};

const _sortHtmlElementsOnMechanicView = (firstElement: HTMLElement, secondElement: HTMLElement) => {
	const { top: firstElementTop, left: firstElementLeft } = firstElement.getBoundingClientRect();
	const { top: secondElementTop, left: secondElementLeft } = secondElement.getBoundingClientRect();

	// We assign Class weight to each element that tells us which nodes
	// should come at the front (nodes with lower weight go to the front of the list).
	const firstElementClassWeight = firstElement.classList.contains('unavailable-equipment-card') ? 1 : 0;
	const secondElementClassWeight = secondElement.classList.contains('unavailable-equipment-card') ? 1 : 0;

	if (firstElementClassWeight !== secondElementClassWeight) {
		return firstElementClassWeight - secondElementClassWeight;
	}

	if (firstElementTop !== secondElementTop) {
		return firstElementTop - secondElementTop;
	}

	if (firstElementLeft !== secondElementLeft) {
		return firstElementLeft - secondElementLeft;
	}

	return 0;
};

const _assignElementWeightFromId = (id: string): number => {
	if (id.includes('wo')) {
		return 2;
	}

	if (id.includes('equipment')) {
		return 1;
	}

	if (id.includes('employee')) {
		return 0;
	}

	return -1;
};

const _sortHtmlElementsOnScheduleBoard = (firstElement: HTMLElement, secondElement: HTMLElement) => {
	const { top: firstElementTop, left: firstElementLeft } = firstElement.getBoundingClientRect();
	const { top: secondElementTop, left: secondElementLeft } = secondElement.getBoundingClientRect();

	// We assign Id weight to each element that tells us which nodes
	// should come at the front (nodes with lower weight go to the front of the list).

	const firstElementIdWeight = _assignElementWeightFromId(firstElement.id);
	const secondElementIdWeight = _assignElementWeightFromId(secondElement.id);

	if (firstElementIdWeight !== secondElementIdWeight) {
		return firstElementIdWeight - secondElementIdWeight;
	}

	if (firstElementTop !== secondElementTop) {
		return firstElementTop - secondElementTop;
	}

	if (firstElementLeft !== secondElementLeft) {
		return firstElementLeft - secondElementLeft;
	}

	return 0;
};

// #endregion SB Reducer Handlers Helpers

// Public

// #region BE SB Utils Import

export const getContextFromDroppableId = ScheduleBoardUtil.getContextFromDroppableId;
export const getPropertyFromDroppableId = ScheduleBoardUtil.getPropertyFromDroppableId;
export const getDueDateFromDroppableId = ScheduleBoardUtil.getDueDateFromDroppableId;
export const getUniqueCodeFromDroppableId = ScheduleBoardUtil.getUniqueCodeFromDroppableId;
export const getToolbarCodeFromUniqueCode = ScheduleBoardUtil.getToolbarCodeFromUniqueCode;
export const getToolbarAvailabilityFromUniqueCode = ScheduleBoardUtil.getToolbarAvailabilityFromUniqueCode;
export const isWorkOrder = ScheduleBoardUtil.isWorkOrder;
export const isEmployee = ScheduleBoardUtil.isEmployee;
export const isResource = ScheduleBoardUtil.isResource;
export const isEquipment = ScheduleBoardUtil.isEquipment;
export const isDefaultLaborPlaceholder = ScheduleBoardUtil.isDefaultLaborPlaceholder;
export const isDefaultEquipmentPlaceholder = ScheduleBoardUtil.isDefaultEquipmentPlaceholder;
export const canDrop = ScheduleBoardUtil.canDrop;
export const isOnBoard = ScheduleBoardUtil.isOnBoard;
export const isInToolbar = ScheduleBoardUtil.isInToolbar;
export const isSourceEqualToDestination = ScheduleBoardUtil.isSourceEqualToDestination;
export const getAvailabilityLabel = ScheduleBoardUtil.getAvailabilityLabel;
export const isAvailable = ScheduleBoardUtil.isAvailable;
export const generateDroppableId = ScheduleBoardUtil.generateDroppableId;
export const getColumnIndexFromDroppableId = ScheduleBoardUtil.getColumnIndexFromDroppableId;
export const generateBlankWorkOrderId = ScheduleBoardUtil.generateBlankWorkOrderId;
export const generatePlaceholderDroppableId = ScheduleBoardUtil.generateLoadingPlaceholderDroppableId;
export const isBlankWorkOrderId = ScheduleBoardUtil.isBlankWorkOrderId;
export const isLoadingPlaceholderDroppableId = ScheduleBoardUtil.isLoadingPlaceholderDroppableId;
export const getWorkOrdersPerRowDistribution = ScheduleBoardUtil.getWorkOrdersPerRowDistribution;
export const getColumnNumberForWorkOrder = ScheduleBoardUtil.getColumnNumberForWorkOrder;
export const getGlobalParticipantNotificationStatus = ScheduleBoardUtil.getGlobalParticipantNotificationStatusFromViewModel;
export const getEmployeeNotificationStatus = ScheduleBoardUtil.getEmployeeNotificationStatus;

// #endregion BE SB Utils Import

// #region SB Utils

export const generateEmployeeSearchItemId = (employeeId: string) => `search-item-employee-${employeeId}`;
export const generateWorkOrderEmployeeSearchItemId = (woeId: string) => `search-item-wo-employee-${woeId}`;
export const generateEquipmentSearchItemId = (equipmentId: string) => `search-item-equipment-${equipmentId}`;
export const generateWorkOrderEquipmentSearchItemId = (woeId: string) => `search-item-wo-equipment-${woeId}`;
export const generateWorkOrderSearchItemId = (workOrderId: string) => `search-item-work-order-${workOrderId}`;
export const generateTemporaryEmployeeSearchItemId = (temporaryEmployeeId: string) => `search-item-temporary-employee-${temporaryEmployeeId}`;
export const generateWorkOrderTemporaryEmployeeSearchItemId = (woeId: string) => `search-item-wo-temporary-employee-${woeId}`;

/** FIXME: should be renamed or somewhere else */
export const parseQueryString = (query: string) => {
	return queryString.parse(query);
};

export const sortToolbarEquipmentIfNeeded = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardResourcesViewModel): ScheduleBoardStoreState => {
	const needToSort = Object.keys(payload.equipment).some((eqId) =>
		scheduleBoard.equipment?.[eqId]?.orderNumber !== payload.equipment[eqId].orderNumber
	);
	if (needToSort) {
		Object.keys(scheduleBoard.workOrdersByDateDictionary).forEach((date) => {
			const availableGroups = scheduleBoard.workOrdersByDateDictionary[date].toolbarEquipment.available;
			Object.keys(availableGroups).forEach((groupId) => {
				scheduleBoard.workOrdersByDateDictionary[date].toolbarEquipment.available[groupId] =
					_sortToolbarEquipment(availableGroups[groupId], payload.equipment);
			});
			const unavailableGroups = scheduleBoard.workOrdersByDateDictionary[date].toolbarEquipment.unavailable;
			Object.keys(unavailableGroups).forEach((groupId) => {
				scheduleBoard.workOrdersByDateDictionary[date].toolbarEquipment.unavailable[groupId] =
					_sortToolbarEquipment(unavailableGroups[groupId], payload.equipment);
			});
		});
	}
	return scheduleBoard;
};

export const updateOnDropResourceElementTypeCondition = (dragElementType: DailyViewDragElementType) => (
	dragElementType === DailyViewDragElementType.EQUIPMENT || dragElementType === DailyViewDragElementType.EMPLOYEE
);

export const updateOnDropResourceElementCondition = (dragElement: ScheduleBoardDragRequestModel) => {
	return !!dragElement.destinationDroppableId
		&& (
			shouldOpenResourceDownFormModal(dragElement.destinationDroppableId)
			|| shouldOpenResourceAvailableWarningModal(dragElement.sourceDroppableId, dragElement.destinationDroppableId)
		);
};

export const shouldOpenResourceDownConfirmationModal = (sourceDroppableId: string, destinationDroppableId: string) => {
	const isDestinationToolbar = ScheduleBoardUtil.isInToolbar(destinationDroppableId);
	const isDestinationAvailable = _getAvailabilityFromDroppableId(destinationDroppableId);
	const isSourceBoard = ScheduleBoardUtil.isOnBoard(sourceDroppableId);

	return isDestinationToolbar && isSourceBoard && !isDestinationAvailable;
};

export const shouldOpenResourceDownFormModal = (destinationDroppableId: string) => {
	const isDestinationToolbar = ScheduleBoardUtil.isInToolbar(destinationDroppableId);
	const isDestinationAvailable = _getAvailabilityFromDroppableId(destinationDroppableId);

	return isDestinationToolbar && !isDestinationAvailable;
};

export const shouldOpenResourceAvailableWarningModal = (sourceDroppableId: string, destinationDroppableId: string) => {
	if (ScheduleBoardUtil.isInToolbar(sourceDroppableId)) {
		const isSourceAvailable = _getAvailabilityFromDroppableId(sourceDroppableId);
		const isDestinationBoard = ScheduleBoardUtil.isOnBoard(destinationDroppableId);

		return !isSourceAvailable && isDestinationBoard;
	}
	return false;
};

export const findItemId = (scheduleBoard: ScheduleBoardStoreState, droppableId: string, index: number, draggableId?: string): string | number | undefined => {
	const list = _getList(scheduleBoard, droppableId);
	if (isWorkOrder(droppableId)) {
		return draggableId && getUniqueCodeFromDroppableId(draggableId);
	} else {
		return list[index];
	}
};

export const getStartIndexForRow = (workOrdersByRows: WorkOrdersRowDistribution, index: number): number => {
	return Object.keys(workOrdersByRows).reduce((_sum, _i) => (+_i < index) ? (_sum + workOrdersByRows[_i].length) : _sum, 0);
};

export const ignoreUndefinedLocation = (laborStatistics: Nullable<ScheduleBoardLaborStatistics>): Nullable<ScheduleBoardLaborStatistics> => {
	if (!laborStatistics) {
		return null;
	}
	const { laborStatisticsPerLocation: _laborStatisticsPerLocation, totalLaborStatistics: _totalLaborStatistics } = laborStatistics;
	if (!_totalLaborStatistics) {
		throw new Error('Labor statistics missing');
	}

	const unknownLocationData = _laborStatisticsPerLocation?.[UNKNOWN_LOCATION_NICKNAME] ?? {
		assignedLaborCount: 0,
		totalLaborCount: 0,
		crewsCount: 0,
		totalRevenue: 0,
	};

	const laborStatisticsPerLocation: LaborStatisticsPerLocationParsed = Object.entries(_laborStatisticsPerLocation ?? {}).reduce(
		(_acc: LaborStatisticsPerLocationParsed, [locationNickname, locationStatistics]) => {
			if (locationNickname !== UNKNOWN_LOCATION_NICKNAME) {
				_acc[locationNickname] = locationStatistics;
			}
			return _acc;
		},
		{} as LaborStatisticsPerLocationParsed
	);

	const totalLaborStatistics: IndividualLaborStatisticsParsed = {
		..._totalLaborStatistics,
		assignedLaborCount: _totalLaborStatistics.assignedLaborCount - unknownLocationData.assignedLaborCount,
		totalRevenue: _totalLaborStatistics.totalRevenue - unknownLocationData.totalRevenue,
		totalLaborCount: _totalLaborStatistics.totalLaborCount - unknownLocationData.totalLaborCount,
		crewsCount: _totalLaborStatistics.crewsCount - unknownLocationData.crewsCount,
	};

	return {
		laborStatisticsPerLocation,
		totalLaborStatistics,
	};
};

export const clearScheduleBoardRedux = (
	view: Nullable<ScheduleBoardView>,
	clearDailyView: HandleThunkActionCreator<typeof ScheduleBoardActions.clearDailyView>,
	clearWeeklyView: HandleThunkActionCreator<typeof ScheduleBoardActions.clearWeeklyView>
) => {
	switch (view) {
		case ScheduleBoardView.WEEKLY_VIEW:
			clearWeeklyView();
			break;
		case ScheduleBoardView.DAILY_VIEW:
		case ScheduleBoardView.MECHANIC_VIEW:
		default:
			clearDailyView();
	}
};

// #endregion SB Utils

// #region SB Reducer Handlers

export const setScheduleBoard = (state: ScheduleBoardStoreState, board: ScheduleBoardViewModel) => {
	const { dates, workOrdersByDateDictionary } = board;
	let updatedSB = {
		...state,
		dates: dates,
		workOrdersByDateDictionary: {
			...state.workOrdersByDateDictionary,
			...workOrdersByDateDictionary,
		},
	};
	if (updatedSB.workOrdersSort !== ScheduleBoardSortType.FREE_REORDERING) {
		updatedSB = changeWorkOrdersSort(updatedSB, updatedSB.workOrdersSort);
	}
	updatedSB = dates.reduce((_updatedSB, _date) => _updateLaborStatistics(_updatedSB, _date), updatedSB);
	return updateRowDistributionForDates(updatedSB, updatedSB.zoomLevel);
};

const _removeEmployeeUnavailability = (employeeId: number, employeeUnavailabilityDetails: EmployeeUnavailabilityDetailsMap) => {

	return Object.keys(employeeUnavailabilityDetails).reduce<EmployeeUnavailabilityDetailsMap>((_acc, _key) => {
		if (+_key !== employeeId) {
			_acc[_key] = employeeUnavailabilityDetails[_key];
		}

		return _acc;
	}, {});
};

const _updateEmployeeUnavailability = (
	employeeId: number,
	employeeAvailable: boolean,
	employeeUnavailabilityDetails: EmployeeUnavailabilityDetailsMap,
	downDetails?: EmployeeUnavailabilityDetails
) => {

	if (!employeeAvailable && downDetails) {
		return {
			...employeeUnavailabilityDetails,
			...{ [employeeId]: downDetails },
		};
	}

	if (employeeAvailable && employeeUnavailabilityDetails[employeeId]) {
		return _removeEmployeeUnavailability(employeeId, employeeUnavailabilityDetails);
	}

	return employeeUnavailabilityDetails;
};

const _updateUnavailabilityOnEmployeeAssignment = (
	employeeAvailable: boolean,
	employeeUnavailabilityDetails: EmployeeUnavailabilityDetailsMap,
	employeeId?: number,
	downDetails?: EmployeeUnavailabilityDetails
) => {

	if (!employeeId) {
		return employeeUnavailabilityDetails;
	}

	return _updateEmployeeUnavailability(
		employeeId,
		employeeAvailable,
		employeeUnavailabilityDetails,
		downDetails
	);
};

export const updateReducerOnAddResourceLookupWorkAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardAddResourceLookupAssignment
): ScheduleBoardStoreState => {
	const { dueDate, workOrderResourceLookup } = payload;
	const { workOrderCode, employeeId, equipmentId, temporaryEmployeeId, index, id } = workOrderResourceLookup;

	if (!workOrderCode) {
		throw new Error('WO code not defined');
	}

	const newWorkOrderResourceLookups = [...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[workOrderCode].workOrderResourceLookups];
	if (newWorkOrderResourceLookups[index] !== id) {
		const existingIndex = newWorkOrderResourceLookups.findIndex((resourceId) => resourceId === id);
		if (existingIndex !== -1) {
			newWorkOrderResourceLookups.splice(existingIndex, 1);
		}
		newWorkOrderResourceLookups.splice(index, 0, id);
	}
	let updatedScheduleBoard = _updateWorkOrderResourceLookupList(scheduleBoard, dueDate, workOrderCode, newWorkOrderResourceLookups);
	const updatedEmployeeUnavailabilityDetails = _updateUnavailabilityOnEmployeeAssignment(
		true,
		scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityDetails,
		employeeId
	);

	const workOrderResourceLookupsDict = updatedScheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;
	updatedScheduleBoard = {
		...updatedScheduleBoard,
		workOrdersByDateDictionary: {
			...updatedScheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...updatedScheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrderResourceLookups: {
					...workOrderResourceLookupsDict,
					[workOrderResourceLookup.id]: workOrderResourceLookup,
				},
				employeeUnavailabilityDetails: updatedEmployeeUnavailabilityDetails,
			},
		},
	};

	if (equipmentId) {
		return _addEquipmentAssignment(updatedScheduleBoard, equipmentId, workOrderCode, dueDate);
	} else if (employeeId) {
		return _addEmployeeAssignment(updatedScheduleBoard, employeeId, workOrderCode, dueDate);
	} else if (temporaryEmployeeId) {
		return _addTemporaryEmployeeAssignment(updatedScheduleBoard, temporaryEmployeeId, workOrderCode, dueDate);
	} else {
		return updatedScheduleBoard;
	}
};

export const updateReducerOnRemoveResourceLookupWorkAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardRemoveResourceAssignmentVM
): ScheduleBoardStoreState => {
	const {
		workOrderCode,
		workOrderEquipmentId,
		equipmentId,
		workOrderEmployeeId,
		employeeId,
		workOrderPlaceholderId,
		workOrderTemporaryEmployeeId,
		temporaryEmployeeId,
		dueDate,
	} = payload;

	let isResourceAlreadyRemoved = true;
	let updatedScheduleBoard = scheduleBoard;

	const workOrderResourceLookupsDict = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups || {};
	const workOrder = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[workOrderCode];
	const workOrderResourceLookups = workOrder?.workOrderResourceLookups ?? [];

	const filteredWorkOrderResourceLookups = workOrderResourceLookups.filter(
		(resourceId) =>
			!!(workOrderEquipmentId && workOrderEquipmentId !== workOrderResourceLookupsDict[resourceId].workOrderEquipmentId) ||
			!!(workOrderEmployeeId && workOrderEmployeeId !== workOrderResourceLookupsDict[resourceId].workOrderEmployeeId) ||
			!!(workOrderPlaceholderId && workOrderPlaceholderId !== workOrderResourceLookupsDict[resourceId].workOrderPlaceholderId) ||
			!!(workOrderTemporaryEmployeeId && workOrderTemporaryEmployeeId !== workOrderResourceLookupsDict[resourceId].workOrderTemporaryEmployeeId)
	);

	if (workOrderResourceLookups.length !== filteredWorkOrderResourceLookups.length) {
		// update resource lookups only if there is a change
		isResourceAlreadyRemoved = false;
		updatedScheduleBoard = _updateWorkOrderResourceLookupList(scheduleBoard, dueDate, workOrderCode, filteredWorkOrderResourceLookups);
	}

	updatedScheduleBoard = _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, workOrderCode, dueDate);

	let hasUpdatedWorkOrderResourceLookups = false;
	const updatedWorkOrderResourceLookups = Object.entries(workOrderResourceLookupsDict).reduce((_acc, [_resourceId, _resource]) => {
		if (
			(workOrderEquipmentId && _resource.workOrderEquipmentId === workOrderEquipmentId) ||
			(workOrderEmployeeId && _resource.workOrderEmployeeId === workOrderEmployeeId) ||
			(workOrderPlaceholderId && _resource.workOrderPlaceholderId === workOrderPlaceholderId) ||
			(workOrderTemporaryEmployeeId && _resource.workOrderTemporaryEmployeeId === workOrderTemporaryEmployeeId)
		) {
			hasUpdatedWorkOrderResourceLookups = true;
			return _acc;
		}
		Object.assign(_acc, { [_resourceId]: _resource });
		return _acc;
	}, {});

	if (hasUpdatedWorkOrderResourceLookups) {
		isResourceAlreadyRemoved = false;
		updatedScheduleBoard = {
			...updatedScheduleBoard,
			workOrdersByDateDictionary: {
				...updatedScheduleBoard.workOrdersByDateDictionary,
				[dueDate]: {
					...updatedScheduleBoard.workOrdersByDateDictionary[dueDate],
					workOrderResourceLookups: updatedWorkOrderResourceLookups,
				},
			},
		};
	}

	if (isResourceAlreadyRemoved) {
		// assume that assignments and statistics have already been updated and return an unaltered scheduleBoard
		return updatedScheduleBoard;
	}

	if (workOrderEquipmentId && equipmentId) {
		return _filterEquipmentAssignment(updatedScheduleBoard, equipmentId, workOrderCode, dueDate);
	} else if (workOrderEmployeeId && employeeId) {
		return _filterEmployeeAssignment(updatedScheduleBoard, employeeId, workOrderCode, dueDate);
	} else if (workOrderTemporaryEmployeeId && temporaryEmployeeId) {
		return _filterTemporaryEmployeeAssignment(updatedScheduleBoard, temporaryEmployeeId, workOrderCode, dueDate);
	} else {
		return updatedScheduleBoard;
	}
};

export const updateReducerOnAddTemporaryEmployeeResourceLookupWorkAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardAddTemporaryEmployeeResourceLookupAssignmentVM
): ScheduleBoardStoreState => {
	const { isNew, temporaryEmployee } = payload;

	let updatedScheduleBoard = scheduleBoard;
	if (isNew && temporaryEmployee) {
		updatedScheduleBoard = {
			...scheduleBoard,
			temporaryEmployees: {
				...scheduleBoard.temporaryEmployees,
				[temporaryEmployee.id]: temporaryEmployee,
			},
		};
	}
	return updateReducerOnAddResourceLookupWorkAssignment(updatedScheduleBoard, payload);
};

export const reorderWorkOrders = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardReorderWorkOrdersViewModel): ScheduleBoardStoreState => {
	const { indexByWorkOrderCodeDict, dueDate } = payload;

	const workOrdersOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	const workOrders = Object.keys(workOrdersOnDate.workOrders).reduce((_acc, workOrderCode: string) => {
		_acc[workOrderCode] = {
			...workOrdersOnDate.workOrders[workOrderCode],
			index: indexByWorkOrderCodeDict[workOrderCode],
		};
		return _acc;
	}, {});

	const workOrdersOrdering = ScheduleBoardUtil.addBlankWorkOrders(
		indexByWorkOrderCodeDict,
		dueDate
	);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersOnDate,
				workOrders,
				workOrdersOrdering,
			},
		},
	};
	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _updateOrAddWorkOrder = (scheduleBoard: ScheduleBoardStoreState, workOrder: ScheduleBoardWorkOrderViewModel): ScheduleBoardStoreState => {
	const dueDate = workOrder.dueDate;
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	const updatedWorkOrders = {
		...workOrdersDictionaryOnDate.workOrders,
		[workOrder.code]: workOrder,
	};

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders: updatedWorkOrders,
				workOrdersOrdering: [
					...workOrdersDictionaryOnDate.workOrdersOrdering,
					workOrder.code,
				],
			},
		},
	};
};

export const updateWorkOrder = (scheduleBoard: ScheduleBoardStoreState, workOrder: ScheduleBoardWorkOrderViewModel): ScheduleBoardStoreState => {
	// TODO: replace work order dictionary key to work order id
	// TODO: replace work order ordering with array of work order ids
	// TODO: replace work order column key with work order id
	// TODO: replace work order code from workOrdersRowDistribution with id
	const dueDate = workOrder.dueDate;
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	if (!workOrdersDictionaryOnDate) {
		return scheduleBoard;
	}

	const workOrderListOnDate = Object.values(workOrdersDictionaryOnDate.workOrders);
	const workOrderWithIdExists = !!workOrderListOnDate.find((_wo) => _wo.id === workOrder.id);

	const oldWorkOrder: ScheduleBoardWorkOrderViewModel | undefined = workOrdersDictionaryOnDate.workOrders[workOrder.code];
	const jobChanged = !oldWorkOrder && workOrderWithIdExists;
	if (jobChanged) {
		const updatedScheduleBoard = _updateWorkOrderJob(scheduleBoard, workOrder);
		return _updateLaborStatistics(updatedScheduleBoard, dueDate);
	}

	const shouldUpdateStatistics = workOrder.status === WorkOrderStatus.PUBLISHED || workOrder.status === WorkOrderStatus.LOCKED;
	const updatedScheduleBoard = _updateOrAddWorkOrder(scheduleBoard, workOrder);
	if (shouldUpdateStatistics) {
		return _updateLaborStatistics(updatedScheduleBoard, dueDate);
	}

	return updatedScheduleBoard;
};

export const addBlankWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: BlankWorkOrderRequestModel): ScheduleBoardStoreState => {
	const { dueDate, index } = payload;
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	const workOrdersOrdering = workOrdersDictionaryOnDate.workOrdersOrdering;
	workOrdersOrdering.splice(index, 0, generateBlankWorkOrderId(dueDate, index));

	const newWorkOrdersDict = Object.values(workOrdersDictionaryOnDate.workOrders || {}).reduce(
		(acc, wo) => ({
			...acc,
			[wo.code]: wo.index > index ? { ...wo, index: wo.index + 1 } : wo,
		}),
		{}
	);
	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrdersOrdering: [...workOrdersOrdering],
				workOrders: newWorkOrdersDict,
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const removeBlankWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: BlankWorkOrderRequestModel): ScheduleBoardStoreState => {
	const { dueDate, index } = payload;
	const workOrdersDictionaryOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (!workOrdersDictionaryOnDate) {
		// for some reason this can happen
		return scheduleBoard;
	}

	const newWorkOrdersDict = Object.values(workOrdersDictionaryOnDate.workOrders || {}).reduce(
		(acc, wo) => ({
			...acc,
			[wo.code]: wo.index > index ? { ...wo, index: wo.index - 1 } : wo,
		}),
		{}
	);
	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrdersOrdering: workOrdersDictionaryOnDate.workOrdersOrdering.filter((_, _index) => _index !== index),
				workOrders: newWorkOrdersDict,
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const updateRowDistributionForDates = (scheduleBoard: ScheduleBoardStoreState, zoomLevel: number) => {
	const { workOrdersByDateDictionary } = scheduleBoard;

	let isAnyChanged = false;

	const _workOrdersByDateDictionary = Object.keys(workOrdersByDateDictionary).reduce((_acc, _date) => {
		const { workOrdersOrdering, workOrders } = workOrdersByDateDictionary[_date];

		let _workOrders: ScheduleBoardColumnPlaceHolder[] = [];
		let _emptyValues: ScheduleBoardColumnPlaceHolder[] = [];
		for (const woCode of workOrdersOrdering) {
			let wo: ScheduleBoardColumnPlaceHolder | undefined = undefined;
			if (isBlankWorkOrderId(woCode)) {
				wo = { isBlank: true };
				_emptyValues.push(wo);
			} else {
				if (isLoadingPlaceholderDroppableId(woCode)) {
					wo = { isPlaceholder: true };
				} else {
					wo = workOrders[woCode];
				}
				_workOrders = [..._workOrders, ..._emptyValues, wo];
				_emptyValues = [];
			}
		}
		const { columnNumbersDict, workOrdersRowDistribution } = getWorkOrdersPerRowDistribution(_workOrders, zoomLevel, _date);

		if (!isAnyChanged) {
			// check for changes only if none have been found for now
			const {
				columnNumbersDict: originalColumnNumbersDict,
				workOrdersRowDistribution: originalWorkOrdersRowDistribution,
			} = workOrdersByDateDictionary[_date];
			isAnyChanged = !_areWorkOrderRowDistributionsEqual(
				columnNumbersDict,
				workOrdersRowDistribution,
				originalColumnNumbersDict,
				originalWorkOrdersRowDistribution
			);
		}

		return {
			..._acc,
			[_date]: {
				...workOrdersByDateDictionary[_date],
				columnNumbersDict,
				workOrdersRowDistribution,
			},
		};
	}, { ...workOrdersByDateDictionary });

	if (!isAnyChanged) {
		return scheduleBoard;
	}

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: _workOrdersByDateDictionary,
	};
};

export const setLaborStatistics = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardLaborStatisticsForDay): ScheduleBoardStoreState => {
	const newWorkOrdersDictionary = Object.entries(payload).reduce((_acc, [_dueDate, _statistics]) => {
		_acc[_dueDate] = {
			...scheduleBoard.workOrdersByDateDictionary[_dueDate],
			laborStatistics: _statistics,
		};
		return _acc;
	}, {});
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersDictionary,
	};
};

const _getWorkOrdersWithUpdatedRevenues = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: FullScheduleBoardProductionDataForDayVM
) => {
	return Object.entries(payload).reduce<ScheduleBoardWorkOrdersByDateDictionary>((_acc, [_dueDate, _productionData]) => {
		_acc[_dueDate] = {
			...scheduleBoard.workOrdersByDateDictionary[_dueDate],
			workOrders: Object.entries(scheduleBoard.workOrdersByDateDictionary[_dueDate].workOrders).reduce((_woAcc, [_code, _wo]) => {

				// Ensure all work orders are added
				_woAcc[_code] = {
					..._wo,
				};

				// Update work orders that are signaled to be updated
				if (_code in _productionData.workOrdersProductionData) {
					_woAcc[_code].revenue = _productionData.workOrdersProductionData[_code].revenue;
				}
				return _woAcc;
			}, {}),
		};
		return _acc;
	}, {});
};

const _getUpdatedStatisticsForNewRevenues = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: FullScheduleBoardProductionDataForDayVM,
	newWorkOrdersDictionary: ScheduleBoardWorkOrdersByDateDictionary
) => {
	return Object.entries(payload).reduce<ScheduleBoardWorkOrdersByDateDictionary>((_acc, [_dueDate]) => {
		_acc[_dueDate] = {
			...newWorkOrdersDictionary[_dueDate],
			laborStatistics: calculateLaborStatistics(
				scheduleBoard.employees,
				newWorkOrdersDictionary[_dueDate].workOrders,
				_workOrderResourceLookupsWithLastestRevisions(
					scheduleBoard.workOrdersByDateDictionary[_dueDate].workOrderResourceLookups,
					scheduleBoard.latestPublishedRevisions
				),
				scheduleBoard.locations ? Object.values(scheduleBoard.locations) : undefined
			),
		};
		return _acc;
	}, {});
};

const _addWorkOrdersOnUnupdatedDueDates = (
	scheduleBoard: ScheduleBoardStoreState,
	newWorkOrdersDictionaryWithUpdatedStatistics: ScheduleBoardWorkOrdersByDateDictionary
): ScheduleBoardWorkOrdersByDateDictionary => {

	const updatedWorkOrdersOnDueDates = { ...newWorkOrdersDictionaryWithUpdatedStatistics };

	for (const _dueDate of Object.keys(scheduleBoard.workOrdersByDateDictionary)) {
		if (_dueDate in updatedWorkOrdersOnDueDates) {
			continue;
		}
		updatedWorkOrdersOnDueDates[_dueDate] = scheduleBoard.workOrdersByDateDictionary[_dueDate];
	}

	return updatedWorkOrdersOnDueDates;
};

export const updateWorkOrdersAndStatisticsOnJobWorkSummaryUpdate = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: FullScheduleBoardProductionDataForDayVM
): ScheduleBoardStoreState => {

	const newWorkOrdersDictionary = _getWorkOrdersWithUpdatedRevenues(scheduleBoard, payload);
	const newWorkOrdersDictionaryWithUpdatedStatistics = _getUpdatedStatisticsForNewRevenues(scheduleBoard, payload, newWorkOrdersDictionary);
	const allWorkOrders = _addWorkOrdersOnUnupdatedDueDates(scheduleBoard, newWorkOrdersDictionaryWithUpdatedStatistics);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: allWorkOrders,
	};
};

export const updateTimeDataOnJobPayrollUpdate = (
	scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardWorkOrdersTimeDataForDayVM
): ScheduleBoardStoreState => {
	const newWorkOrdersDictionary = Object.entries(payload).reduce((_acc, [_dueDate, _productionData]) => {
		_acc[_dueDate] = {
			...scheduleBoard.workOrdersByDateDictionary[_dueDate],
			workOrders: Object.entries(scheduleBoard.workOrdersByDateDictionary[_dueDate].workOrders).reduce((_woAcc, [_code, _wo]) => {

				// Ensure all work orders are added
				_woAcc[_code] = {
					..._wo,
				};

				// Update work orders that are signaled to be updated
				if (_code in _productionData.workOrdersTimeData) {
					_woAcc[_code].jobHours = _productionData.workOrdersTimeData[_code].jobHours;
					_woAcc[_code].shopHours = _productionData.workOrdersTimeData[_code].shopHours;
					_woAcc[_code].travelHours = _productionData.workOrdersTimeData[_code].travelHours;
				}
				return _woAcc;
			}, {}),
		};
		return _acc;
	}, {});

	// Add all work orders that are on other due dates
	for (const _dueDate of Object.keys(scheduleBoard.workOrdersByDateDictionary)) {
		if (_dueDate in newWorkOrdersDictionary) {
			continue;
		}
		newWorkOrdersDictionary[_dueDate] = scheduleBoard.workOrdersByDateDictionary[_dueDate];
	}

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersDictionary,
	};
};

export const updateWorkOrderNote = (scheduleBoard: ScheduleBoardStoreState, data: ScheduleBoardUpdateWorkOrderNoteViewModel): ScheduleBoardStoreState => {
	const { dueDate, code, note } = data;

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
					[code]: {
						...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[code],
						workOrderNotes: note,
					},
				},
			},
		},
	};

	return _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, code, dueDate);
};

export const removeWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardDeleteWorkOrderViewModel): ScheduleBoardStoreState => {
	const updatedScheduleBoard = _updateResourcesOnWorkOrderRemoveOrCancel(scheduleBoard, payload.dueDate, payload.code, false);

	const workOrdersDictionaryOnDate = updatedScheduleBoard.workOrdersByDateDictionary[payload.dueDate];
	const workOrders = Object.entries(workOrdersDictionaryOnDate.workOrders)
		.reduce((acc, [code, wo]) => (code === payload.code ? acc : { ...acc, [code]: wo }), {});

	const updatedScheduleBoardV2 = {
		...updatedScheduleBoard,
		workOrdersByDateDictionary: {
			...updatedScheduleBoard.workOrdersByDateDictionary,
			[payload.dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders,
				workOrdersOrdering: workOrdersDictionaryOnDate.workOrdersOrdering.filter((code) => code !== payload.code),
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoardV2, updatedScheduleBoard.zoomLevel);
};

export const cancelWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardDeleteWorkOrderViewModel): ScheduleBoardStoreState => {
	const { dueDate, code, statusBasedRevision } = payload;
	const updatedScheduleBoard = _updateResourcesOnWorkOrderRemoveOrCancel(scheduleBoard, dueDate, code, true);

	const workOrdersDictionaryOnDate = updatedScheduleBoard.workOrdersByDateDictionary[dueDate];
	const workOrders = Object.entries(workOrdersDictionaryOnDate.workOrders)
		.reduce((acc, [_code, _wo]) => (
			_code === code
				? { ...acc, [_code]: { ..._wo, status: WorkOrderStatus.CANCELED, isPaused: false, pauseReason: null, statusBasedRevision } }
				: { ...acc, [_code]: _wo })
			, {});

	const updatedScheduleBoardV2 = {
		...updatedScheduleBoard,
		workOrdersByDateDictionary: {
			...updatedScheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...workOrdersDictionaryOnDate,
				workOrders,
			},
		},
	};
	return _updateLaborStatistics(updatedScheduleBoardV2, dueDate);
};

export const pauseWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardPauseWorkOrderViewModel): ScheduleBoardStoreState => {
	const { dueDate, code, statusBasedRevision } = payload;
	return _updateResourcesOnWorkOrderPauseOrResume(scheduleBoard, dueDate, code, statusBasedRevision ?? '', true);
};

export const resumeWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardResumeWorkOrderViewModel): ScheduleBoardStoreState => {
	const { dueDate, code, statusBasedRevision } = payload;
	return _updateResourcesOnWorkOrderPauseOrResume(scheduleBoard, dueDate, code, statusBasedRevision ?? '', false);
};

export const revertWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardRevertWorkOrderViewModel): ScheduleBoardStoreState => {
	const { workOrder } = payload;

	return _updateResourcesOnWorkOrderRevert(scheduleBoard, workOrder);
};

export const lockOnApproveWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardPauseWorkOrderViewModel): ScheduleBoardStoreState => {
	const { dueDate, code } = payload;
	return _updateResourcesOnWorkOrderLockOrUnlock(scheduleBoard, dueDate, code, true);
};

export const unlockOnApproveWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardResumeWorkOrderViewModel): ScheduleBoardStoreState => {
	const { dueDate, code } = payload;
	return _updateResourcesOnWorkOrderLockOrUnlock(scheduleBoard, dueDate, code, false);
};

export const copyMultipleWorkOrder = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardCopiedWorkOrderViewModel[]): ScheduleBoardStoreState => {

	const board = payload.reduce(_getBoardAfterCopy, { ...scheduleBoard });

	const updatedScheduleBoardV2 = updateRowDistributionForDates(board, board.zoomLevel);
	return updatedScheduleBoardV2;
};

export const reorderScheduleBoardWorkOrders = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardDragRequestModel): ScheduleBoardStoreState => {
	const { sourceIndex, destinationIndex, dueDate } = payload;

	if (!isEmptyNumber(destinationIndex) && sourceIndex !== undefined && destinationIndex !== undefined && sourceIndex !== destinationIndex) {
		let workOrdersOrdering: string[] = ArrayUtil.move(
			scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering,
			sourceIndex,
			destinationIndex
		);

		const workOrdersOrderingMap = workOrdersOrdering.reduce(
			(_acc, _code: string, _index: number) => {
				_acc[_code] = _index;
				return _acc;
			},
			{} as { [T in string]: number }
		);

		const { workOrdersDict, maxWOIndex } = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering.reduce(
			(_acc, _woCode) => {
				if (isBlankWorkOrderId(_woCode)) {
					return _acc;
				}
				const wo = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[_woCode];
				wo.index = workOrdersOrderingMap[_woCode] + 1; // +1 because workOrdersOrderingMap is 0 based
				_acc.workOrdersDict[_woCode] = { ...wo };
				if (_acc.maxWOIndex < _acc.workOrdersDict[_woCode].index) {
					_acc.maxWOIndex = _acc.workOrdersDict[_woCode].index;
				}
				return _acc;
			},
			{ maxWOIndex: 0, workOrdersDict: {} } as { workOrdersDict: ScheduleBoardWorkOrdersViewModel; maxWOIndex: number; }
		);

		if (workOrdersOrdering.length > maxWOIndex) {
			workOrdersOrdering = workOrdersOrdering.slice(0, maxWOIndex);
		}
		return updateRowDistributionForDates(
			{
				...scheduleBoard,
				workOrdersByDateDictionary: {
					...scheduleBoard.workOrdersByDateDictionary,
					[dueDate]: {
						...scheduleBoard.workOrdersByDateDictionary[dueDate],
						workOrders: workOrdersDict,
						workOrdersOrdering,
					},
				},
			},
			scheduleBoard.zoomLevel
		);
	} else {
		return updateRowDistributionForDates(scheduleBoard, scheduleBoard.zoomLevel);
	}
};

export const addToDroppableList = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardDragRequestModel): ScheduleBoardStoreState => {
	const { itemId, sourceDroppableId, destinationDroppableId, destinationIndex, dueDate } = payload;

	// Dropped employee to equipment and vice versa
	if (!destinationDroppableId || !canDrop(sourceDroppableId, destinationDroppableId)) {
		return scheduleBoard;
	}

	if (destinationIndex === undefined) {
		throw new Error('Destination index not defined');
	}

	let newList = ArrayUtil.insert(_getList(scheduleBoard, destinationDroppableId), destinationIndex, itemId);
	if (!isOnBoard(destinationDroppableId)) {
		if (isEmployee(destinationDroppableId) && scheduleBoard.employees) {
			newList = _sortToolbarEmployees(newList as number[], scheduleBoard.employees);
		} else if (scheduleBoard.equipment) {
			newList = _sortToolbarEquipment(newList as number[], scheduleBoard.equipment);
		}
	}
	const updatedScheduleBoard = _updateList(scheduleBoard, newList, destinationDroppableId, dueDate);

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

const _wasDroppedEmployeeUnavailable = (droppableId: string) => {
	const code = getUniqueCodeFromDroppableId(droppableId) ?? null;
	if (!code) {
		return false;
	}

	const availability = getToolbarAvailabilityFromUniqueCode(code);
	if (availability === SCHEDULE_BOARD_TOOLBAR_AVAILABLE) {
		return false;
	}

	return true;
};

const _attemptToRemoveUnavailabilityReasonForEmployee = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	employeeId: Nullable<number>,
	droppableId: string
) => {

	if (!employeeId) {
		return scheduleBoard;
	}

	if (!_wasDroppedEmployeeUnavailable(droppableId)) {
		return scheduleBoard;
	}

	const updatedEmployeeUnavailabilityDetails = _removeEmployeeUnavailability(
		employeeId,
		scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityDetails
	);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				employeeUnavailabilityDetails: updatedEmployeeUnavailabilityDetails,
			},
		},
	};
};

export const updateScheduleBoardDroppableList = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardDragRequestModel): ScheduleBoardStoreState => {
	const { itemId, sourceDroppableId, sourceIndex, destinationDroppableId, destinationIndex, dueDate } = payload;

	// Dropped outside the list
	if (!destinationDroppableId && isOnBoard(sourceDroppableId)) {
		if (sourceIndex === undefined) {
			throw new Error('Source index not defined');
		}

		const reorderedList = ArrayUtil.remove(_getList(scheduleBoard, sourceDroppableId), sourceIndex);
		const updatedScheduleBoard = _updateList(scheduleBoard, reorderedList, sourceDroppableId, dueDate);

		return updatedScheduleBoard;
	} else if (!destinationDroppableId) {
		return scheduleBoard;
	}

	// Dropped employee to equipment and vice versa
	if (!canDrop(sourceDroppableId, destinationDroppableId)) {
		return scheduleBoard;
	}

	const sourceContext = getContextFromDroppableId(sourceDroppableId);
	const destinationContext = getContextFromDroppableId(destinationDroppableId);

	// isWorkOrder part might not ever be executed
	if (isSourceEqualToDestination(sourceDroppableId, destinationDroppableId) || (isWorkOrder(sourceDroppableId) && isWorkOrder(destinationDroppableId))) {
		// dropped in same box so reorder only
		if (sourceIndex === undefined || destinationIndex === undefined) {
			throw new Error('Source or destination index not defined');
		}
		const reorderedList = ArrayUtil.move(_getList(scheduleBoard, sourceDroppableId), sourceIndex, destinationIndex);

		const updatedScheduleBoard = _updateList(scheduleBoard, reorderedList, sourceDroppableId, dueDate);
		return updatedScheduleBoard;
	} else if (sourceContext === destinationContext) {
		if (sourceIndex === undefined || destinationIndex === undefined) {
			throw new Error('Source or destination not found');
		}

		const moveResult = _move(
			_getList(scheduleBoard, sourceDroppableId),
			_getList(scheduleBoard, destinationDroppableId),
			{ index: sourceIndex, droppableId: sourceDroppableId },
			{ index: destinationIndex, droppableId: destinationDroppableId }
		);
		let updatedScheduleBoard = _updateList(scheduleBoard, moveResult[sourceDroppableId], sourceDroppableId, dueDate);
		updatedScheduleBoard = _updateList(updatedScheduleBoard, moveResult[destinationDroppableId], destinationDroppableId, dueDate);
		if (sourceContext === ScheduleBoardContext.BOARD) {
			const sourceCode = getUniqueCodeFromDroppableId(sourceDroppableId);
			const destinationCode = getUniqueCodeFromDroppableId(destinationDroppableId);

			if (sourceCode === undefined || destinationCode === undefined) {
				throw new Error('Source or destination not found');
			}

			const resource: ScheduleBoardResourceLookupViewModel | undefined =
				updatedScheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups[itemId];
			if (!resource) {
				throw new Error('Resource not found');
			}

			if (resource.workOrderEmployeeId && resource.employeeId) {
				const employeeId = resource.employeeId;
				updatedScheduleBoard = _resetEmployeeNotificationStatusAndPerDiem(updatedScheduleBoard, resource.id, dueDate);
				updatedScheduleBoard = _addWorkOrderEmployeeAssignmentAndUpdateResourceLookupOnDate(updatedScheduleBoard, {
					workOrderResourceLookup: {
						...resource,
						employeeId,
						workOrderCode: destinationCode,
					}, dueDate,
				});
				updatedScheduleBoard = _removeWorkOrderEmployeeAssignment(updatedScheduleBoard, { employeeId, workOrderCode: sourceCode, dueDate });
			} else if (resource.workOrderEquipmentId && resource.equipmentId) {
				const equipmentId = resource.equipmentId;
				updatedScheduleBoard = _addWorkOrderEquipmentAssignment(updatedScheduleBoard, { equipmentId, workOrderCode: destinationCode, dueDate });
				updatedScheduleBoard = _removeWorkOrderEquipmentAssignment(updatedScheduleBoard, { equipmentId, workOrderCode: sourceCode, dueDate });
			} else if (resource.workOrderPlaceholderId) {
				updatedScheduleBoard = _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, sourceCode, dueDate);
				updatedScheduleBoard = _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, destinationCode, dueDate);
			}
		} else {
			const destinationCode = getUniqueCodeFromDroppableId(destinationDroppableId);
			const destinationAvailability = destinationCode && ScheduleBoardUtil.getToolbarAvailabilityFromUniqueCode(destinationCode);
			if (destinationAvailability && isEquipment(destinationDroppableId)) {
				updatedScheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[itemId] = undefined;
			}
		}
		return updatedScheduleBoard;
	} else {
		// if moving from toolbar to board and vice versa, just delete item from list, backend will dispatch action for adding
		if (sourceIndex === undefined) {
			throw new Error('Source not found');
		}

		const filteredList = ArrayUtil.remove(_getList(scheduleBoard, sourceDroppableId), sourceIndex);
		let updatedScheduleBoard = _updateList(scheduleBoard, filteredList, sourceDroppableId, dueDate);

		if (sourceContext === ScheduleBoardContext.TOOLBAR) {
			// FIXME: this should probably go into one of the signals that are sent when moving employee from toolbar to board
			updatedScheduleBoard = _attemptToRemoveUnavailabilityReasonForEmployee(
				updatedScheduleBoard,
				dueDate,
				scheduleBoard.draggedEmployeeId,
				sourceDroppableId
			);
		}

		return _updateLaborStatistics(updatedScheduleBoard, dueDate);
	}
};

const _hasAssignmentsWhenAddingToToolbar = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	employeeId: number
) => {
	return scheduleBoard.workOrdersByDateDictionary[dueDate]?.employeeAssignments[employeeId]?.length > 0;
};

const _toolbarEmployeesOnDateBySections = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	employeeId: number,
	employeeStatusId: Nullable<number>,
	availableEmployeeStatus: Nullable<boolean>
) => {

	if (!employeeStatusId) {
		// TOOLBAR_GROUP_DEFAULT_ID might need to be used instead. If not, check why it's nullable
		throw new Error('Employee status ID shouldn\'t be null');
	}

	if (!scheduleBoard.employees) {
		throw new Error('Employee toolbar not defined');
	}

	const toolbarEmployees = scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEmployees;

	// An employee can only be on either toolbar or some work orders, not both
	if (_hasAssignmentsWhenAddingToToolbar(scheduleBoard, dueDate, employeeId)) {
		return toolbarEmployees;
	}

	const availability = getAvailabilityLabel(!!availableEmployeeStatus);
	const newToolbarEmployeeList = _addUniqueElement(
		employeeStatusId ? toolbarEmployees[availability][employeeStatusId] : null,
		employeeId
	);

	const dateToolbarEmployees = {
		...toolbarEmployees,
		[availability]: {
			...toolbarEmployees[availability],
			[employeeStatusId]: _sortToolbarEmployees(newToolbarEmployeeList, scheduleBoard.employees),
		},
	};
	return dateToolbarEmployees;
};

export const addToolbarEmployee = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEmployeeUpdate): ScheduleBoardStoreState => {
	const { employeeId, employeeStatusId, availableEmployeeStatus, dueDate, downDetails } = payload;

	if (!dueDate) {
		throw new Error('Due date not defined');
	}

	const dateToolbarEmployees = _toolbarEmployeesOnDateBySections(scheduleBoard, dueDate, employeeId, employeeStatusId, availableEmployeeStatus);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				toolbarEmployees: dateToolbarEmployees,
				employeeUnavailabilityDetails: _updateEmployeeUnavailability(
					employeeId,
					availableEmployeeStatus ?? false,
					scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityDetails,
					downDetails
				),
			},
		},
	};
};

export const addToolbarEmployeeFromDate = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEmployeeUpdate) => {
	const { employeeId, employeeStatusId, availableEmployeeStatus, dueDate, downDetails } = payload;
	if (!dueDate) {
		throw new Error('Due date not defined');
	}

	if (!employeeStatusId) {
		throw new Error('Employee status ID shouldn\'t be null');
	}

	const newWorkOrdersByDateDictionary = { ...scheduleBoard.workOrdersByDateDictionary };

	Object.keys(newWorkOrdersByDateDictionary)
		.filter((date) => new Date(date) >= new Date(dueDate))  // Filter dates that are the same as or after dueDate
		.forEach((date) => {
			const dateToolbarEmployees = _toolbarEmployeesOnDateBySections(scheduleBoard, date, employeeId, employeeStatusId, availableEmployeeStatus);

			newWorkOrdersByDateDictionary[date] = {
				...newWorkOrdersByDateDictionary[date],
				toolbarEmployees: dateToolbarEmployees,
				employeeUnavailabilityDetails: {
					...newWorkOrdersByDateDictionary[date].employeeUnavailabilityDetails,
					...(downDetails ? { [employeeId]: downDetails } : {}),
				},
			};
		});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const removeToolbarEmployee = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEmployeeUpdate): ScheduleBoardStoreState => {
	const { employeeStatusId = TOOLBAR_GROUP_DEFAULT_ID, employeeId, availableEmployeeStatus, dueDate, downDetails } = payload;
	if (!dueDate) {
		throw new Error('Due date not defined');
	}

	if (employeeStatusId === null) {
		// TOOLBAR_GROUP_DEFAULT_ID is used instead
		throw new Error('Employee status ID shouldn\'t be null');
	}

	const availability = getAvailabilityLabel(!!availableEmployeeStatus);
	const toolbarEmployees = scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEmployees;
	const employeeUnavailabilityDetails = scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityDetails;

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				toolbarEmployees: {
					...toolbarEmployees,
					[availability]: {
						...toolbarEmployees[availability],
						[employeeStatusId]: (toolbarEmployees[availability][employeeStatusId] || []).filter((_empId) => Number(_empId) !== Number(employeeId)),
					},
				},
				employeeUnavailabilityDetails: {
					...employeeUnavailabilityDetails,
					...(downDetails ? { [employeeId]: downDetails } : {}),
				},
			},
		},
	};
};

export const removeToolbarEmployeeFromDate = (scheduleBoard, payload) => {
	const {
		employeeStatusId = TOOLBAR_GROUP_DEFAULT_ID,
		employeeId,
		availableEmployeeStatus,
		dueDate,
		downDetails,
	} = payload;

	if (!dueDate) {
		throw new Error('Due date not defined');
	}

	if (employeeStatusId === null) {
		throw new Error('Employee status ID shouldn\'t be null');
	}

	const newWorkOrdersByDateDictionary = { ...scheduleBoard.workOrdersByDateDictionary };
	const availability = getAvailabilityLabel(!!availableEmployeeStatus);

	Object.keys(newWorkOrdersByDateDictionary)
		.filter((date) => new Date(date) >= new Date(dueDate))
		.forEach((date) => {
			const toolbarEmployees = newWorkOrdersByDateDictionary[date].toolbarEmployees;
			const employeeUnavailabilityDetails = newWorkOrdersByDateDictionary[date].employeeUnavailabilityDetails;

			newWorkOrdersByDateDictionary[date] = {
				...newWorkOrdersByDateDictionary[date],
				toolbarEmployees: {
					...toolbarEmployees,
					[availability]: {
						...toolbarEmployees[availability],
						[employeeStatusId]: (toolbarEmployees[availability][employeeStatusId] || []).filter((_empId) => Number(_empId) !== Number(employeeId)),
					},
				},
				employeeUnavailabilityDetails: {
					...employeeUnavailabilityDetails,
					...(downDetails ? { [employeeId]: downDetails } : {}),
				},
			};
		});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const removeMultipleToolbarEmployee = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardToolbarEmployeeUpdate[]
): ScheduleBoardStoreState => {
	return payload.reduce(removeToolbarEmployee, { ...scheduleBoard });
};

export const addToolbarEmployeeForAllDays = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEmployeeUpdate): ScheduleBoardStoreState => {
	const { employeeId, availableEmployeeStatus, employeeStatusId, dueDate } = payload;
	const availability = getAvailabilityLabel(!!availableEmployeeStatus);

	const newWorkOrdersByDateDictionary = Object.entries(scheduleBoard.workOrdersByDateDictionary).reduce((_acc, [_date, _board]) => {
		const isDefaultStatus = !TimeUtils.isGTE(_date, dueDate);
		const availabilityForDay = isDefaultStatus ? 'available' : availability;
		const statusForDay = isDefaultStatus ? TOOLBAR_GROUP_DEFAULT_ID : employeeStatusId;
		if (statusForDay === null) {
			throw new Error('Status for day shouldn\'t be null');
		}
		if (!scheduleBoard.employees) {
			throw new Error('Employee toolbar not defined');
		}
		const newToolbarEmployeeList = _addUniqueElement(_board?.toolbarEmployees?.[availabilityForDay]?.[statusForDay], employeeId);
		_acc[_date] = {
			..._acc[_date],
			toolbarEmployees: {
				..._acc[_date].toolbarEmployees,
				[availabilityForDay]: {
					..._acc[_date].toolbarEmployees[availabilityForDay],
					[statusForDay]: _sortToolbarEmployees(newToolbarEmployeeList, scheduleBoard.employees),
				},
			},
		};
		return _acc;
	}, { ...scheduleBoard.workOrdersByDateDictionary });

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const removeToolbarEmployeeForAllDays = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardToolbarEmployeeUpdate
): ScheduleBoardStoreState => {
	const { employeeId, dueDate } = payload;

	const newWorkOrdersByDateDictionary = Object.entries(scheduleBoard.workOrdersByDateDictionary).reduce((_acc, [_date, _board]) => {
		if (!dueDate || dueDate === _date) {
			let didChange = false;
			const _result = {
				..._board,
				toolbarEmployees: {
					available: {
						...Object.entries(_board.toolbarEmployees.available).reduce((_available, [_key, _elements]) => {
							const hasElement = _elements.includes(employeeId);
							didChange = didChange || hasElement;
							_available[_key] = hasElement ? _elements.filter((_empId) => _empId !== employeeId) : _elements;
							return _available;
						}, {}),
					},
					unavailable: {
						...Object.entries(_board.toolbarEmployees.unavailable).reduce((_unavailable, [_key, _elements]) => {
							const hasElement = _elements.includes(employeeId);
							didChange = didChange || hasElement;
							_unavailable[_key] = hasElement ? _elements.filter((_empId) => _empId !== employeeId) : _elements;
							return _unavailable;
						}, {}),
					},
				},
			};
			_acc[_date] = didChange ? _result : _board;
		}
		return _acc;
	}, { ...scheduleBoard.workOrdersByDateDictionary });

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

const _getEquipmentUnavailabilityStatusId = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	availability: string,
	equipmentId: number
) => {

	if (availability !== SCHEDULE_BOARD_TOOLBAR_UNAVAILABLE) {
		return undefined;
	}

	return scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[equipmentId]?.equipmentStatusId;

};

export const addToolbarEquipment = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEquipmentUpdate): ScheduleBoardStoreState => {
	const {
		officeNickname = UNKNOWN_LOCATION_NICKNAME,
		equipmentStatusId,
		equipmentStatus,
		officeColor,
		equipmentId: _equipmentId,
		availableEquipmentStatus,
		dueDate,
		downDetails,
		clearAssignmentsList,
	} = payload;

	if (!dueDate) {
		throw new Error('Due date missing');
	}
	if (!scheduleBoard.equipment) {
		throw new Error('Equipment toolbar not defined');
	}
	if (!scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		// for some reason this can happen
		return scheduleBoard;
	}

	const equipmentId: number = Number(_equipmentId);
	const availability = getAvailabilityLabel(availableEquipmentStatus);
	const equipment = scheduleBoard.equipment[equipmentId];
	const toolbarEquipment = scheduleBoard.workOrdersByDateDictionary[dueDate].toolbarEquipment;
	const unavailableEquipmentStatusId = _getEquipmentUnavailabilityStatusId(scheduleBoard, dueDate, availability, equipmentId);
	const key = unavailableEquipmentStatusId ?? equipmentStatusId ?? TOOLBAR_GROUP_DEFAULT_ID;
	const newEquipmentList = _addUniqueElement(toolbarEquipment[availability][key], equipmentId);
	const equipmentAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentAssignments;
	const _newUnavailablityReason = Object.keys(scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails).reduce((_acc, _key) => {
		if (+_key !== equipmentId) {
			_acc[_key] = { ...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[_key] };
			return _acc;
		}

		if (availableEquipmentStatus) {
			return _acc;
		}

		_acc[_key] = downDetails ?? { ...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[_key] };
		return _acc;
	}, {});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				toolbarEquipment: {
					...toolbarEquipment,
					[availability]: {
						...toolbarEquipment[availability],
						[key]: _sortToolbarEquipment(newEquipmentList, scheduleBoard.equipment),
					},
				},
				equipmentAssignments: {
					...equipmentAssignments,
					[equipmentId]: clearAssignmentsList ? [] : equipmentAssignments[equipmentId],
				},
				equipmentUnavailabilityDetails: _newUnavailablityReason,
			},
		},
		equipment: {
			...scheduleBoard.equipment,
			[equipmentId]: {
				...equipment,
				equipmentStatus: { ...equipment.equipmentStatus, name: equipmentStatus },
				office: equipment.office ? { ...equipment.office, color: officeColor, nickname: officeNickname } : null,
			} as ScheduleBoardEquipment,
		},
	};
};

export const addEquipmentDownDetails = (scheduleBoard: ScheduleBoardStoreState, payload: AddEquipmentDownDetails): ScheduleBoardStoreState => {
	const { date, downDetailsMap } = payload;

	scheduleBoard.workOrdersByDateDictionary[date].equipmentUnavailabilityDetails = {
		...scheduleBoard.workOrdersByDateDictionary[date].equipmentUnavailabilityDetails,
		...downDetailsMap,
	};

	return scheduleBoard;
};

export const addEmployeeDownDetails = (scheduleBoard: ScheduleBoardStoreState, payload: EmployeeUnavailabilityDetailsVM): ScheduleBoardStoreState => {
	const { employeeUnavailabilityDetailsMap, date } = payload;
	const formattedDate = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY);

	const details = Object.keys(employeeUnavailabilityDetailsMap).reduce((_acc, _employeeId) => {
		if (employeeUnavailabilityDetailsMap[_employeeId].unavailabilityReasonId) {
			_acc.reason[_employeeId] = {
				id: employeeUnavailabilityDetailsMap[_employeeId].unavailabilityReasonId,
				name: employeeUnavailabilityDetailsMap[_employeeId].unavailabilityReason,
			};
		}
		if (employeeUnavailabilityDetailsMap[_employeeId].returnDate) {
			_acc.returnDate[_employeeId] = employeeUnavailabilityDetailsMap[_employeeId].returnDate;
		}
		return _acc;
	}, { reason: {}, returnDate: {} });

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[formattedDate]: {
				...scheduleBoard.workOrdersByDateDictionary[formattedDate],
				employeeUnavailabilityDetails: {
					...scheduleBoard.workOrdersByDateDictionary[formattedDate].employeeUnavailabilityDetails,
					...employeeUnavailabilityDetailsMap,
				},
				employeeUnavailabilityReason: {
					...scheduleBoard.workOrdersByDateDictionary[formattedDate].employeeUnavailabilityReason,
					...details.reason,
				},
				employeeReturnDate: {
					...scheduleBoard.workOrdersByDateDictionary[formattedDate].employeeReturnDate,
					...details.returnDate,
				},
			},
		},
	};
};

export const removeToolbarEquipment = (scheduleBoard: ScheduleBoardStoreState, payload: ScheduleBoardToolbarEquipmentUpdate): ScheduleBoardStoreState => {
	const { equipmentStatusId, equipmentId, availableEquipmentStatus, dueDate, downDetails } = payload;

	if (!dueDate) {
		throw new Error('Due date missing');
	}

	const availability = getAvailabilityLabel(availableEquipmentStatus);
	const key = equipmentStatusId ?? TOOLBAR_GROUP_DEFAULT_ID;
	const toolbarEquipment = scheduleBoard.workOrdersByDateDictionary[dueDate]?.toolbarEquipment;
	const _newUnavailablityReason = Object.keys(scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails).reduce((_acc, _key) => {
		if (+_key !== equipmentId) {
			_acc[_key] = scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[_key];
			return _acc;
		}

		if (availableEquipmentStatus) {
			return _acc;
		}

		if (downDetails) {
			_acc[_key] = downDetails;
		}

		return _acc;
	}, {});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				toolbarEquipment: {
					...toolbarEquipment,
					[availability]: {
						...toolbarEquipment[availability],
						[key]: (toolbarEquipment[availability][key] || []).filter((_eqId) => _eqId !== Number(equipmentId)),
					},
				},
				equipmentUnavailabilityDetails: _newUnavailablityReason,
			},
		},
	};
};

export const removeMultipleToolbarEquipment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardToolbarEquipmentUpdate[]
): ScheduleBoardStoreState => {
	return payload.reduce(removeToolbarEquipment, { ...scheduleBoard });
};

export const addToolbarEquipmentForAllDays = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardToolbarEquipmentUpdate
): ScheduleBoardStoreState => {
	const { officeId = TOOLBAR_GROUP_DEFAULT_ID, equipmentStatusId, equipmentId, availableEquipmentStatus, dueDate } = payload;
	const availability = getAvailabilityLabel(availableEquipmentStatus);
	const key = availableEquipmentStatus ? officeId : equipmentStatusId;

	const newWorkOrdersByDateDictionary = Object.entries(scheduleBoard.workOrdersByDateDictionary).reduce((_acc, [_date, _board]) => {
		const isDefaultStatus = !TimeUtils.isGTE(_date, dueDate);
		const availabilityForDay = isDefaultStatus ? 'available' : availability;
		const locationForDay = availableEquipmentStatus ? officeId : TOOLBAR_GROUP_DEFAULT_ID;
		const keyForDay = isDefaultStatus ? locationForDay : key;
		if (!keyForDay) {
			throw new Error('Status key not found');
		}
		if (!scheduleBoard.equipment) {
			throw new Error('Equipment toolbar not defined');
		}

		const newEquipmentList = _addUniqueElement(_board?.toolbarEquipment?.[availabilityForDay]?.[keyForDay], Number(equipmentId));
		_acc[_date] = {
			..._acc[_date],
			toolbarEquipment: {
				..._acc[_date].toolbarEquipment,
				[availabilityForDay]: {
					..._acc[_date].toolbarEquipment[availabilityForDay],
					[keyForDay]: _sortToolbarEquipment(newEquipmentList, scheduleBoard.equipment),
				},
			},
		};
		return _acc;
	}, { ...scheduleBoard.workOrdersByDateDictionary });

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const removeToolbarEquipmentForAllDays = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardToolbarEquipmentUpdate
): ScheduleBoardStoreState => {
	const { equipmentId, dueDate } = payload;

	const newWorkOrdersByDateDictionary = Object.entries(scheduleBoard.workOrdersByDateDictionary).reduce((_acc, [_date, _board]) => {
		let didChange = false;
		if (!dueDate || dueDate === _date) {
			const _result = {
				..._board,
				toolbarEquipment: {
					available: {
						...Object.entries(_board.toolbarEquipment.available).reduce((_available, [_key, _elements]) => {
							const hasElement = _elements.includes(equipmentId);
							didChange = didChange || hasElement;
							_available[_key] = hasElement ? _elements.filter((_empId) => _empId !== equipmentId) : _elements;
							return _available;
						}, {}),
					},
					unavailable: {
						...Object.entries(_board.toolbarEquipment.unavailable).reduce((_unavailable, [_key, _elements]) => {
							const hasElement = _elements.includes(Number(equipmentId));
							didChange = didChange || hasElement;
							_unavailable[_key] = hasElement ? _elements.filter((_empId) => _empId !== equipmentId) : _elements;
							_unavailable[_key] = _elements.filter((_equId) => _equId !== Number(equipmentId));
							return _unavailable;
						}, {}),
					},
				},
			};
			_acc[_date] = didChange ? _result : _board;
		}
		return _acc;
	}, { ...scheduleBoard.workOrdersByDateDictionary });

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const removeAllWorkOrderEmployees = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM
): ScheduleBoardStoreState => {
	const { employeeId, dueDate } = payload;

	const oldWorkOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;
	const isNotTargetEmployeeResource = (_resourceId: number | string) => !!_resourceId && oldWorkOrderResourceLookups[_resourceId].employeeId !== employeeId;

	const { workOrderResourceLookups, workOrders } = _getWorkOrdersDataWithoutResource(
		oldWorkOrderResourceLookups,
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
		isNotTargetEmployeeResource
	);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders,
				workOrderResourceLookups,
				employeeAssignments: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].employeeAssignments,
					[employeeId]: [],
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const removeWorkOrderEmployeeOnDrafts = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardRemoveAllWorkOrderEmployeesVM
): ScheduleBoardStoreState => {
	const { employeeId, dueDate } = payload;

	if (!scheduleBoard.workOrdersByDateDictionary?.[dueDate]) {
		throw new Error(`Work orders on '${dueDate}' not found`);
	}

	const oldWorkOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;

	const isNotTargetEmployeeResource = (_resourceId: number | string) =>
		!!_resourceId && oldWorkOrderResourceLookups[_resourceId].employeeId !== employeeId;

	const { workOrderResourceLookups, workOrders, updatedWorkOrdersDict } = _getWorkOrdersDataWithoutResourceOnDrafts(
		oldWorkOrderResourceLookups,
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
		isNotTargetEmployeeResource
	);

	const employeeAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].employeeAssignments;
	const filteredEmployeeAssignments = employeeAssignments[employeeId].filter((workOrderCode) => !updatedWorkOrdersDict[workOrderCode]);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders,
				workOrderResourceLookups,
				employeeAssignments: {
					...employeeAssignments,
					[employeeId]: filteredEmployeeAssignments,
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const removeAllWorkOrderEquipment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM
): ScheduleBoardStoreState => {
	const { equipmentId, dueDate } = payload;
	const oldWorkOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;
	const isNotTargetEquipmentResource = (_resourceId: number | string) =>
		!!_resourceId && oldWorkOrderResourceLookups[_resourceId].equipmentId !== equipmentId;

	const { workOrderResourceLookups, workOrders } = _getWorkOrdersDataWithoutResource(
		oldWorkOrderResourceLookups,
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
		isNotTargetEquipmentResource
	);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders,
				workOrderResourceLookups,
				equipmentAssignments: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentAssignments,
					[equipmentId]: [],
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const removeWorkOrderEquipmentOnDrafts = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardRemoveAllWorkOrderEquipmentVM
): ScheduleBoardStoreState => {
	const { equipmentId, dueDate } = payload;

	const oldWorkOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;

	const isNotTargetEquipmentResource = (_resourceId: number | string) =>
		!!_resourceId && oldWorkOrderResourceLookups[_resourceId].equipmentId !== equipmentId;

	const { workOrderResourceLookups, workOrders, updatedWorkOrdersDict } = _getWorkOrdersDataWithoutResourceOnDrafts(
		oldWorkOrderResourceLookups,
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders,
		isNotTargetEquipmentResource
	);

	const equipmentAssignments = scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentAssignments;
	const filteredEquipmentAssignments = equipmentAssignments[equipmentId].filter((workOrderCode) => !updatedWorkOrdersDict[workOrderCode]);

	const updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders,
				workOrderResourceLookups,
				equipmentAssignments: {
					...equipmentAssignments,
					[equipmentId]: filteredEquipmentAssignments,
				},
			},
		},
	};

	return updateRowDistributionForDates(updatedScheduleBoard, updatedScheduleBoard.zoomLevel);
};

export const addTemporaryEmployee = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardTemporaryEmployeeVM
): ScheduleBoardStoreState => {
	const updatedScheduleBoard = {
		...scheduleBoard,
		temporaryEmployees: {
			...scheduleBoard.temporaryEmployees,
			[payload.id]: payload,
		},
	};

	return updatedScheduleBoard;
};

export const addWorkOrderResourceLookupToDictionary = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: ScheduleBoardAddResourceLookupAssignment
): ScheduleBoardStoreState => {
	const { workOrderResourceLookup, dueDate, changeStatus } = payload;
	const { id, employeeId, equipmentId, temporaryEmployeeId, workOrderCode } = workOrderResourceLookup;

	if (!scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		// for some reason this can happen
		return scheduleBoard;
	}

	let updatedScheduleBoard = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrderResourceLookups: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups,
					[id]: workOrderResourceLookup,
				},
			},
		},
	};

	if (!workOrderCode) {
		throw new Error('Work Order code not provided');
	}

	if (workOrderResourceLookup.workOrderEmployeeId && employeeId) {
		updatedScheduleBoard = _addEmployeeAssignment(updatedScheduleBoard, employeeId, workOrderCode, dueDate);
	} else if (workOrderResourceLookup.workOrderEquipmentId && equipmentId) {
		updatedScheduleBoard = _addEquipmentAssignment(updatedScheduleBoard, equipmentId, workOrderCode, dueDate);
	} else if (workOrderResourceLookup.workOrderTemporaryEmployeeId && temporaryEmployeeId) {
		updatedScheduleBoard = _addTemporaryEmployeeAssignment(updatedScheduleBoard, temporaryEmployeeId, workOrderCode, dueDate);
	}
	if (changeStatus) {
		updatedScheduleBoard = _updateWorkOrderStatusIfNeeded(updatedScheduleBoard, workOrderCode, dueDate);
	}

	return updatedScheduleBoard;
};

export const clearUnavailabilityReason = (
	scheduleBoard: ScheduleBoardStoreState,
	itemId: number,
	scope: UnavailabilityReasonScope,
	dueDate: string
): ScheduleBoardStoreState => {
	if (!scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	if (scope === UnavailabilityReasonScope.EMPLOYEE) {
		return {
			...scheduleBoard,
			workOrdersByDateDictionary: {
				...scheduleBoard.workOrdersByDateDictionary,
				[dueDate]: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate],
					employeeUnavailabilityReason: {
						...scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityReason,
						[itemId]: undefined,
					},
				},
			},
		};
	}
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				equipmentUnavailabilityReason: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityReason,
					[itemId]: undefined,
				},
				equipmentUnavailabilityDetails: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails,
					[itemId]: {
						...scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails[itemId],
						unavailabilityReason: null,
						unavailabilityReasonId: null,
					},
				},
			},
		},
	};

};

export const assignUnavailabilityReason = (
	scheduleBoard: ScheduleBoardStoreState,
	data: ScheduleBoardUnavailabilityReasonViewModel
): ScheduleBoardStoreState => {
	const { itemId, unavailabilityReason, scope, dueDate } = data;

	if (!scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	if (!unavailabilityReason) {
		throw new Error('Unavailability reason not provided');
	}

	const boardOnDate = scheduleBoard.workOrdersByDateDictionary[dueDate];
	const newUnavailabilityReason = {
		name: unavailabilityReason.name,
		id: unavailabilityReason.id,
	};

	if (scope === UnavailabilityReasonScope.EMPLOYEE) {
		return {
			...scheduleBoard,
			workOrdersByDateDictionary: {
				...scheduleBoard.workOrdersByDateDictionary,
				[dueDate]: {
					...boardOnDate,
					employeeUnavailabilityReason: {
						...boardOnDate.employeeUnavailabilityReason,
						[itemId]: newUnavailabilityReason,
					},
				},
			},
		};
	}
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...boardOnDate,
				equipmentUnavailabilityReason: {
					...boardOnDate.equipmentUnavailabilityReason,
					[itemId]: newUnavailabilityReason,
				},
				equipmentUnavailabilityDetails: {
					...boardOnDate.equipmentUnavailabilityDetails,
					[itemId]: {
						...boardOnDate.equipmentUnavailabilityDetails[itemId],
						unavailabilityReason: unavailabilityReason.name,
						unavailabilityReasonId: unavailabilityReason.id,
					},
				},
			},
		},
	};
};

const _removeEquipmentUnavailability = (equipmentId: number, equipmentUnavailabilityDetails: EquipmentUnavailabilityDetailsMap) => {

	return Object.keys(equipmentUnavailabilityDetails).reduce<EquipmentUnavailabilityDetailsMap>((_acc, _key) => {
		if (+_key !== equipmentId) {
			_acc[_key] = equipmentUnavailabilityDetails[_key];
		}

		return _acc;
	}, {});
};

export const changeReturnDate = (
	scheduleBoard: ScheduleBoardStoreState,
	data: ScheduleBoardReturnDateRequestModel
): ScheduleBoardStoreState => {
	const { itemId, date: returnDate, scope, dueDate, employeeUnavailabilityDetails, equipmentUnavailabilityDetails } = data;

	if (!scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	const key = scope === UnavailabilityReasonScope.EMPLOYEE ? 'employeeReturnDate' : 'equipmentReturnDate';

	let newWorkOrdersByDateDictionary = { ...scheduleBoard.workOrdersByDateDictionary };

	const boardOnDate = { ...scheduleBoard.workOrdersByDateDictionary[dueDate] };
	let newBoardOnDate = {
		...boardOnDate,
		[key]: {
			...boardOnDate[key],
			[itemId]: returnDate,
		},
	};

	if (returnDate) {
		if (scope === UnavailabilityReasonScope.EMPLOYEE) {
			if (returnDate === dueDate || TimeUtils.isBefore(returnDate, dueDate, TimeFormat.DATE_ONLY)) {
				newBoardOnDate = {
					...newBoardOnDate,
					employeeUnavailabilityDetails:
						_removeEmployeeUnavailability(itemId, scheduleBoard.workOrdersByDateDictionary[dueDate].employeeUnavailabilityDetails),
				};
			} else if (TimeUtils.isBefore(dueDate, returnDate, TimeFormat.DATE_ONLY) && employeeUnavailabilityDetails) {
				const _employeeUnavailabilityDetails = newBoardOnDate.employeeUnavailabilityDetails;
				newBoardOnDate = {
					...newBoardOnDate,
					employeeUnavailabilityDetails:
					{
						..._employeeUnavailabilityDetails,
						[itemId]: employeeUnavailabilityDetails,
					},
				};
			}
		}
		if (scope === UnavailabilityReasonScope.EQUIPMENT) {
			if (returnDate === dueDate || TimeUtils.isBefore(returnDate, dueDate, TimeFormat.DATE_ONLY)) {
				newBoardOnDate = {
					...newBoardOnDate,
					equipmentUnavailabilityDetails:
						_removeEquipmentUnavailability(itemId, scheduleBoard.workOrdersByDateDictionary[dueDate].equipmentUnavailabilityDetails),
				};
			} else if (TimeUtils.isBefore(dueDate, returnDate, TimeFormat.DATE_ONLY) && equipmentUnavailabilityDetails) {
				const _equipmentUnavailabilityDetails = newBoardOnDate.equipmentUnavailabilityDetails;
				newBoardOnDate = {
					...newBoardOnDate,
					equipmentUnavailabilityDetails:
					{
						..._equipmentUnavailabilityDetails,
						[itemId]: equipmentUnavailabilityDetails,
					},
				};
			}
		}
	}

	newWorkOrdersByDateDictionary = {
		...newWorkOrdersByDateDictionary,
		[dueDate]: newBoardOnDate,
	};

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newWorkOrdersByDateDictionary,
	};
};

export const updateEmployeePerDiem = (
	scheduleBoard: ScheduleBoardStoreState,
	data: ScheduleBoardEmployeePerDiemAssignmentRequestModel
): ScheduleBoardStoreState => {
	const { perDiem, workOrderResourceLookupId, dueDate } = data;

	if (!workOrderResourceLookupId) {
		throw new Error('Work Order Resource Lookup not provided');
	}

	const newState = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrderResourceLookups: {
					...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups,
					[workOrderResourceLookupId]: {
						...scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups[workOrderResourceLookupId],
						perDiem,
					},
				},
			},
		},
	};

	return newState;
};

export const setPerDiemForWorkOrders = (
	scheduleBoard: ScheduleBoardStoreState,
	data: ScheduleBoardSetPerDiemForWorkOrdersRequestModel
): ScheduleBoardStoreState => {
	const { dueDate, workOrderIds } = data;

	const workOrders = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders;
	const workOrderResourceLookups = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrderResourceLookups;

	const workOrderResourceLookupIds = Object.values(workOrders).reduce((_resourceLookupIds, _workOrder) => {
		if (workOrderIds.includes(_workOrder.id)) {
			// only employee assignment ids
			_workOrder.workOrderResourceLookups.reduce<number[]>((_workOrderResourceLookupIds, _resourceLookupId) => {
				const isWorkOrderEmployeeResourceLookup = !!workOrderResourceLookups[_resourceLookupId].workOrderEmployeeId;
				if (isWorkOrderEmployeeResourceLookup) {
					_workOrderResourceLookupIds.push(_resourceLookupId);
				}
				return _workOrderResourceLookupIds;
			}, _resourceLookupIds);
		}
		return _resourceLookupIds;
	}, []);

	workOrderResourceLookupIds.forEach((_resourceLookupId) => {
		workOrderResourceLookups[_resourceLookupId] = {
			...workOrderResourceLookups[_resourceLookupId],
			perDiem: true,
		};
	});

	const newState = {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrderResourceLookups: {
					...workOrderResourceLookups,
				},
			},
		},
	};

	return newState;
};

export const toggleLockOnWorkOrder = (scheduleBoard: ScheduleBoardStoreState, data: WorkOrderIdAndDueDate, locked: boolean): ScheduleBoardStoreState => {
	const { dueDate, workOrderId } = data;
	const allForDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (!allForDate) {
		return scheduleBoard;
	}

	const workOrders = allForDate.workOrders;
	// Let me know if this is sub optimal, have no clue how to approach this without significant state overhauls
	const orderToLock = Object.values(workOrders).find((_wo) => _wo.id === workOrderId);

	if (!orderToLock) {
		return scheduleBoard;
	}

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders: {
					...workOrders,
					[orderToLock.code]: ({ ...orderToLock, locked } as ScheduleBoardWorkOrderViewModel),
				},
			},
		},
	};
};

export const toggleDisableWorkOrder = (scheduleBoard: ScheduleBoardStoreState, data: WorkOrderCodeAndDueDate, isDisabled: boolean): ScheduleBoardStoreState => {
	const { dueDate, workOrderCode } = data;
	const allForDate = scheduleBoard.workOrdersByDateDictionary[dueDate];

	if (!allForDate) {
		return scheduleBoard;
	}

	const workOrders = allForDate.workOrders;

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders: { ...workOrders, [workOrderCode]: { ...workOrders[workOrderCode], isDisabled } as ScheduleBoardWorkOrderViewModel },
			},
		},
	};
};

export const applyFilters = (scheduleBoard: ScheduleBoardStoreState, filters: ScheduleBoardFilterVM): ScheduleBoardStoreState => {
	const workOrdersByDateDictionary = Object.keys(scheduleBoard.workOrdersByDateDictionary).reduce(
		(_workOrdersByDateDictionary, dueDate) => {
			_workOrdersByDateDictionary[dueDate] = {
				...scheduleBoard.workOrdersByDateDictionary[dueDate],
				workOrders: Object.keys(scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders).reduce((_workOrders, code) => {
					const order = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[code];

					const isFilteredByCrewType = filters[ScheduleBoardFilterGroup.CREW_TYPE]?.some(
						(crewType) => {
							if (crewType.id === ScheduleBoardFilterType.ASSIGNED && crewType.checked) {
								return !!order.crewTypeId;
							} else if (crewType.id === ScheduleBoardFilterType.UNASSIGNED && crewType.checked) {
								return !!order.customCrewType && !order.crewTypeId; // internal
							} else {
								return crewType.id === order.crewTypeId && crewType.checked;
							}
						}
					);
					_workOrders[code] = {
						...order,
						isFiltered: isFilteredByCrewType,
					};
					return _workOrders;
				}, {}),
			};
			return _workOrdersByDateDictionary;
		},
		{} as ScheduleBoardWorkOrdersByDateDictionary
	);

	if (!scheduleBoard.employees || !scheduleBoard.equipment) {
		throw new Error('Toolbar not defined');
	}

	const employees = Object.keys(scheduleBoard.employees).reduce((_employees, employeeId) => {
		const employee = scheduleBoard.employees![+employeeId];
		const employeeOfficeId = employee?.office?.id;
		const isFilteredByOffice = filters[ScheduleBoardFilterGroup.EMPLOYEE_OFFICE]?.some(
			(office) => _genericFilterCondition(office.id, office.checked, employeeOfficeId)
		);
		_employees[employeeId] = {
			...employee,
			isFilteredOnToolbar: isFilteredByOffice,
			isFilteredOnBoard: isFilteredByOffice,
		};
		return _employees;
	}, {});

	const equipment = Object.keys(scheduleBoard.equipment).reduce((_equipment, equipmentId) => {
		const _equipmentItem = scheduleBoard.equipment![+equipmentId];
		const equipmentOfficeId = _equipmentItem?.office?.id;
		const equipmentCategoryId = _equipmentItem.ecId;
		const isFilteredByOffice = filters[ScheduleBoardFilterGroup.EQUIPMENT_OFFICE]?.some(
			(office) => _genericFilterCondition(office.id, office.checked, equipmentOfficeId)
		);
		const isFilteredByEquipmentCategory = filters[ScheduleBoardFilterGroup.EQUIPMENT_COST]?.some(
			(ecc) => _genericFilterCondition(ecc.id, ecc.checked, equipmentCategoryId)
		);
		_equipment[equipmentId] = {
			..._equipmentItem,
			isFilteredOnToolbar: isFilteredByOffice && isFilteredByEquipmentCategory,
			isFilteredOnBoard: isFilteredByOffice && isFilteredByEquipmentCategory,
		};
		return _equipment;
	}, {});

	return {
		...scheduleBoard,
		workOrdersByDateDictionary,
		employees,
		equipment,
		isFilterApplied: true,
	};
};

export const setCopiedWorkOrderPlaceholder = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: Nullable<string>,
	code: Nullable<string>,
	index: number | undefined
): ScheduleBoardStoreState => {
	if (!dueDate || !scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	let workOrdersOrdering: string[] = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering;
	index = index !== undefined ? index : workOrdersOrdering.length;

	if (!code) {
		workOrdersOrdering = workOrdersOrdering.filter((_code: string) => !isLoadingPlaceholderDroppableId(_code));
		scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering = [...workOrdersOrdering];
	} else {
		workOrdersOrdering.splice(index, 0, code);
	}
	scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering = [...workOrdersOrdering];

	return { ...scheduleBoard };
};

/**
 * This function sets placeholder in work order resources.
 * Placeholder is shown as grey item and it shows the location of the created Employee, Equipment or Placeholder.
 */
export const setCopiedResourcePlaceholder = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	code: string | undefined,
	index?: number,
	clearPlaceholder?: boolean
): ScheduleBoardStoreState => {
	if (!code || !scheduleBoard.workOrdersByDateDictionary[dueDate]) {
		return scheduleBoard;
	}

	let workOrderResourceLookups: number[] = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[code].workOrderResourceLookups;
	index = index !== undefined ? index : workOrderResourceLookups.length;

	if (clearPlaceholder) {
		workOrderResourceLookups = workOrderResourceLookups.filter((_resourceId: number) => _resourceId !== RESOURCE_PLACEHOLDER);
	} else {
		workOrderResourceLookups.splice(index, 0, RESOURCE_PLACEHOLDER);
	}

	scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[code].workOrderResourceLookups = [...workOrderResourceLookups];

	return {
		...scheduleBoard,
	};
};

export const updateScheduleBoardSearchResults = (scheduleBoard: ScheduleBoardStoreState, query: string): ScheduleBoardStoreState => {
	let searchResultItems: string[] = [];
	const { scheduleBoardView, weeklyViewDateWithToolbar, date } = scheduleBoard;

	const isDailyView = scheduleBoardView === ScheduleBoardView.DAILY_VIEW && !!date;
	const isWeeklyViewToolbarOpened = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW && weeklyViewDateWithToolbar;
	const isMechanicView = scheduleBoardView === ScheduleBoardView.MECHANIC_VIEW;
	const highlightInToolbar = isDailyView || isMechanicView || isWeeklyViewToolbarOpened;

	const employees = Object.entries(scheduleBoard.employees ?? {}).reduce((_acc, [empId, emp]) => {
		const shownInToolbar: boolean = !emp.isDeleted && emp.account?.assignableToWorkOrder && emp.showOnScheduleBoard;
		const isMatched: boolean = ScheduleBoardEmployee.matches(emp, query);

		if (isMatched && highlightInToolbar && shownInToolbar && !isMechanicView) {
			searchResultItems.push(generateEmployeeSearchItemId(empId));
		}

		_acc[empId] = {
			...emp,
			isMatched,
		} as ScheduleBoardEmployee;

		return _acc;
	}, {} as { [employeeId: string]: ScheduleBoardEmployee; });

	const equipment = Object.entries(scheduleBoard.equipment ?? {}).reduce((_acc, [eqId, eq]) => {
		const shownInToolbar: boolean = !eq.isDeleted && eq.showOnScheduleBoard;
		const isMatched: boolean = ScheduleBoardEquipment.matches(eq, query);

		if (isMatched && highlightInToolbar && shownInToolbar) {
			searchResultItems.push(generateEquipmentSearchItemId(eqId));
		}

		_acc[eqId] = {
			...eq,
			isMatched,
		} as ScheduleBoardEquipment;

		return _acc;
	}, {} as { [equipmentId: string]: ScheduleBoardEquipment; });

	const temporaryEmployees = Object.entries(scheduleBoard.temporaryEmployees ?? {}).reduce((_acc, [empId, emp]) => {
		const isMatched: boolean = ScheduleBoardTemporaryEmployeeVM.matches(emp, query);

		if (isMatched && !isMechanicView) {
			searchResultItems.push(generateTemporaryEmployeeSearchItemId(empId));
		}

		_acc[empId] = {
			...emp,
			isMatched,
		} as ScheduleBoardTemporaryEmployeeVM;

		return _acc;
	}, {} as { [employeeId: string]: ScheduleBoardTemporaryEmployeeVM; });

	const result = {
		...scheduleBoard,
		employees,
		equipment,
		temporaryEmployees,
		workOrdersByDateDictionary: Object.entries(scheduleBoard.workOrdersByDateDictionary || {}).reduce(
			(acc, [dueDate, woDictionary]) => {
				if (isDailyView && dueDate !== date) {
					return {
						...acc,
						[dueDate]: woDictionary,
					};
				}
				return {
					...acc,
					[dueDate]: {
						...woDictionary,
						workOrdersOrdering: woDictionary.workOrdersOrdering || [],
						workOrders: Object.entries(woDictionary.workOrders || {}).reduce((acc1, [woCode, wo]) => {
							const _workOrderResourceLookups = filterMap(
								wo.workOrderResourceLookups,
								(_resourceId) => !!woDictionary.workOrderResourceLookups[_resourceId],
								(_resourceId) => woDictionary.workOrderResourceLookups[_resourceId]
							);

							const extraDesc = _workOrderResourceLookups.map((_worl) => {
								if (_worl.equipmentId) {
									const _equipment = equipment[_worl.equipmentId];
									return _equipment ? _equipment._desc : '';
								} else if (_worl.employeeId) {
									const employee = employees[_worl.employeeId];
									return employee ? employee._desc : '';
								} else if (_worl.temporaryEmployeeId) {
									const temporaryEmployee = temporaryEmployees[_worl.temporaryEmployeeId];
									return temporaryEmployee ? temporaryEmployee._desc : '';
								} else {
									return '';
								}
							}).join(' ');

							const isMatched: boolean = matches(
								{
									...wo,
									newWorkOrderResourceLookups: _workOrderResourceLookups,
									_desc: `${wo._desc} ${extraDesc}`,
								},
								query,
								scheduleBoard.isShowNotesActive ? wo.workOrderNotes ?? undefined : undefined
							);
							if (isMatched && !isMechanicView) {
								searchResultItems.push(generateWorkOrderSearchItemId(wo.id.toString()));
							} else if (isMatched) {
								const resourceLookup = woDictionary?.workOrderResourceLookups;
								for (const _worl of wo.workOrderResourceLookups) {
									if (resourceLookup?.[_worl]?.equipmentId) {
										const searchId = generateEquipmentSearchItemId(resourceLookup![_worl]!.equipmentId!.toString());
										if (!searchResultItems.some((_searchItemId) => _searchItemId === searchId)) {
											searchResultItems.push(searchId);
										}
									}
								}
							}

							wo.workOrderResourceLookups?.forEach((_resourceId) => {
								const resource = wo.status !== WorkOrderStatus.CANCELED
									? woDictionary.workOrderResourceLookups[_resourceId]
									: woDictionary.canceledWorkOrderResourceLookups[_resourceId];

								if (resource?.equipmentId && equipment?.[resource.equipmentId]?.isMatched && isMechanicView) {
									const equipmentSearchId = generateEquipmentSearchItemId(`${resource.equipmentId}`);
									searchResultItems = searchResultItems.filter((key) => key !== equipmentSearchId);
									searchResultItems.push(equipmentSearchId);
								}
							});
							return {
								...acc1,
								[woCode]: {
									...wo,
									isMatched,
								} as ScheduleBoardWorkOrderViewModel,
							};
						}, {}),
					},
				};
			}, {}),
	};

	const sortNodes = isMechanicView
		? _sortHtmlElementsOnMechanicView
		: _sortHtmlElementsOnScheduleBoard;

	searchResultItems = searchResultItems.reduce((_acc, _id) => {
		const element = document.getElementById(_id);
		if (element) {
			_acc.push(element);
		}
		return _acc;
	}, [] as HTMLElement[])
		.sort(sortNodes)
		.map((_node) => _node.id);

	return { ...result, query, activeSearchItemIndex: 0, searchResultItems };
};

export const toggleWeeklyViewSelectMultiple = (scheduleBoard: ScheduleBoardStoreState, dueDate: string): ScheduleBoardStoreState => {
	const toggledDueDates = { ...scheduleBoard.weeklyViewSelectMultiple };

	if (toggledDueDates[dueDate]) {
		delete toggledDueDates[dueDate];
	} else {
		toggledDueDates[dueDate] = {};
	}
	return {
		...scheduleBoard,
		weeklyViewSelectMultiple: toggledDueDates,
	};
};

export const toggleDayOnWeeklyView = (scheduleBoard: ScheduleBoardStoreState, dueDate: string, selectValue: boolean): ScheduleBoardStoreState => {
	const workOrdersOnDay = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders;
	const toggledWorkOrders = Object.values(workOrdersOnDay || {}).reduce((_acc, _wo) => {
		_acc[_wo.id] = selectValue ? { id: _wo.id, code: _wo.code } : null;
		return _acc;
	}, {});
	return {
		...scheduleBoard,
		weeklyViewSelectMultiple: {
			...scheduleBoard.weeklyViewSelectMultiple,
			[dueDate]: toggledWorkOrders,
		},
	};
};

export const toggleWorkOrderOnWeeklyView = (
	scheduleBoard: ScheduleBoardStoreState,
	dueDate: string,
	workOrder: SelectedWorkOrderModel
): ScheduleBoardStoreState => {
	return {
		...scheduleBoard,
		weeklyViewSelectMultiple: {
			...scheduleBoard.weeklyViewSelectMultiple,
			[dueDate]: {
				...scheduleBoard.weeklyViewSelectMultiple[dueDate],
				[workOrder.id]: !!scheduleBoard.weeklyViewSelectMultiple[dueDate][workOrder.id] ? null : workOrder,
			},
		},
	};
};

export const updateHasAttachmentsOnWorkOrder = (
	scheduleBoard: ScheduleBoardStoreState,
	data: ScheduleBoardUpdateWorkOrderHasAttachmentsVM
): ScheduleBoardStoreState => {
	const workOrder = scheduleBoard.workOrdersByDateDictionary[data.dueDate].workOrders[data.workOrderCode];
	if (!workOrder) {
		return scheduleBoard;
	}
	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[data.dueDate]: {
				...scheduleBoard.workOrdersByDateDictionary[data.dueDate],
				workOrders: {
					...scheduleBoard.workOrdersByDateDictionary[data.dueDate].workOrders,
					[data.workOrderCode]: {
						...workOrder,
						hasAttachments: data.hasAttachments,
						// cannot guarantee that WO should be outdated, outdate WO with another event
					},
				},
			},
		},
	};
};

export const syncNotificationStatus = (scheduleBoard: ScheduleBoardStoreState, payload: NotificationLookupViewModel): ScheduleBoardStoreState => {
	const {
		assignedCanceledNotificationStatus,
		assignedPublishedNotificationStatus,
		availableEmployeeNotificationStatus,
		assignedPublishedTempLaborNotificationStatus,
		assignedCanceledTempLaborNotificationStatus,
	} = payload;

	const reduceOutdatedNotifications = (_acc: NotificationStatusByEmployee, [_employeeId, _status]: [string, NotificationStatusViewModel]) => {
		// TODO: remove this after adding FE logic for outdated notifications

		_acc[_employeeId] = _status;
		if (_status.emailStatus === NotificationStatusEnum.OUTDATED) {
			// converted employeeId to number for typings
			_acc[+_employeeId]!.emailStatus = undefined;
			_acc[+_employeeId]!.emailSentAt = undefined;
		}
		if (_status.smsStatus === NotificationStatusEnum.OUTDATED) {
			// converted employeeId to number for typings
			_acc[+_employeeId]!.smsStatus = undefined;
			_acc[+_employeeId]!.smsSentAt = undefined;
		}
		_acc[+_employeeId]!.isPreviousRevision = _status.isPreviousRevision;
		return _acc;
	};

	const reduceOutdatedTempLaborNotifications = (
		_acc: NotificationStatusByTemporaryEmployee,
		[_temporaryEmployeeId, _status]: [string, NotificationStatusViewModel]
	) => {
		// TODO: remove this after adding FE logic for outdated notifications

		_acc[_temporaryEmployeeId] = _status;
		if (_status.emailStatus === NotificationStatusEnum.OUTDATED) {
			// converted employeeId to number for typings
			_acc[+_temporaryEmployeeId]!.emailStatus = undefined;
			_acc[+_temporaryEmployeeId]!.emailSentAt = undefined;
		}
		if (_status.smsStatus === NotificationStatusEnum.OUTDATED) {
			// converted employeeId to number for typings
			_acc[+_temporaryEmployeeId]!.smsStatus = undefined;
			_acc[+_temporaryEmployeeId]!.smsSentAt = undefined;
		}
		_acc[+_temporaryEmployeeId]!.isPreviousRevision = _status.isPreviousRevision;
		return _acc;
	};

	let statusByDate = Object.keys(assignedCanceledNotificationStatus).reduce((_acc, _date) => {
		const _statusMap = assignedCanceledNotificationStatus[_date];
		_acc[_date] = {
			assignedCanceledNotificationStatuses: Object.entries(_statusMap).reduce(reduceOutdatedNotifications, {}),
		};
		return _acc;
	}, {});

	statusByDate = Object.keys(assignedPublishedNotificationStatus).reduce((_acc, _date) => {
		const _statusMap = assignedPublishedNotificationStatus[_date];
		_acc[_date] = {
			..._acc[_date],
			assignedPublishedNotificationStatuses: Object.entries(_statusMap).reduce(reduceOutdatedNotifications, {}),
		};
		return _acc;
	}, statusByDate);

	statusByDate = Object.keys(availableEmployeeNotificationStatus).reduce((_acc, _date) => {
		const _statusMap = availableEmployeeNotificationStatus[_date];
		_acc[_date] = {
			..._acc[_date],
			availableNotificationStatuses: Object.entries(_statusMap).reduce(reduceOutdatedNotifications, {}),
		};
		return _acc;
	}, statusByDate);

	statusByDate = Object.keys(assignedCanceledTempLaborNotificationStatus).reduce((_acc, _date) => {
		const _statusMap = assignedCanceledTempLaborNotificationStatus[_date];
		_acc[_date] = {
			..._acc[_date],
			assignedCanceledTempLaborNotificationStatuses: Object.entries(_statusMap).reduce(reduceOutdatedTempLaborNotifications, {}),
		};
		return _acc;
	}, statusByDate);

	statusByDate = Object.keys(assignedPublishedTempLaborNotificationStatus).reduce((_acc, _date) => {
		const _statusMap = assignedPublishedTempLaborNotificationStatus[_date];
		_acc[_date] = {
			..._acc[_date],
			assignedPublishedTempLaborNotificationStatuses: Object.entries(_statusMap).reduce(reduceOutdatedTempLaborNotifications, {}),
		};
		return _acc;
	}, statusByDate);

	let isStateUpdated = false;
	const newScheduleBoardByDateDictionary = Object.entries(scheduleBoard.workOrdersByDateDictionary).reduce((_state, [_date, _board]) => {
		if (statusByDate[_date]) {
			isStateUpdated = true;
			_state[_date] = {
				..._board,
				assignedCanceledNotificationStatuses: {
					..._board.assignedCanceledNotificationStatuses,
					...statusByDate[_date].assignedCanceledNotificationStatuses,
				},
				assignedPublishedNotificationStatuses: {
					..._board.assignedPublishedNotificationStatuses,
					...statusByDate[_date].assignedPublishedNotificationStatuses,
				},
				availableNotificationStatuses: { ..._board.availableNotificationStatuses, ...statusByDate[_date].availableNotificationStatuses },
				assignedCanceledTempLaborNotificationStatuses: {
					..._board.assignedCanceledTempLaborNotificationStatuses,
					...statusByDate[_date].assignedCanceledTempLaborNotificationStatuses,
				},
				assignedPublishedTempLaborNotificationStatuses: {
					..._board.assignedPublishedTempLaborNotificationStatuses,
					...statusByDate[_date].assignedPublishedTempLaborNotificationStatuses,
				},
			};
		}
		return _state;
	}, { ...scheduleBoard.workOrdersByDateDictionary });

	if (!isStateUpdated) {
		return scheduleBoard;
	}

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: newScheduleBoardByDateDictionary,
	};
};

export const setDraggingLaborPlaceholder = (scheduleBoard: ScheduleBoardStoreState, dragging: boolean, data: Nullable<ScheduleBoardDragDestinationData>) => {
	const updatedState = { ...scheduleBoard, draggingLaborPlaceholder: dragging };
	if (!dragging && data) {
		const { destinationDroppableId, destinationIndex, dueDate } = data;
		const workOrderCode = ScheduleBoardUtil.getUniqueCodeFromDroppableId(destinationDroppableId);
		if (!workOrderCode || !dueDate) {
			throw new Error('Work Order not found');
		}
		const workOrder = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[workOrderCode];

		updatedState.laborModal = { isOpen: true, workOrderCode, workOrderId: workOrder.id, index: destinationIndex, dueDate: workOrder.dueDate };
	}
	return updatedState;
};

export const setDraggingEquipmentPlaceholder = (
	scheduleBoard: ScheduleBoardStoreState,
	dragging: boolean,
	data: Nullable<ScheduleBoardDragDestinationData>
) => {
	const updatedState = { ...scheduleBoard, draggingEquipmentPlaceholder: dragging };
	if (!dragging && data) {
		const { destinationDroppableId, destinationIndex, dueDate } = data;
		const workOrderCode = ScheduleBoardUtil.getUniqueCodeFromDroppableId(destinationDroppableId);
		if (!workOrderCode || !dueDate) {
			throw new Error('Work Order not found');
		}
		const workOrder = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders[workOrderCode];

		updatedState.equipmentPlaceholderModal = { isOpen: true, workOrderCode, workOrderId: workOrder.id, index: destinationIndex };
	}
	return updatedState;
};

export const openAvailableEmployeesNotificationModal = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: OpenAvailableEmployeesNotificationModalData
): ScheduleBoardStoreState => {
	const { groupId, dueDate } = payload;

	const updatedScheduleBoard = {
		...scheduleBoard,
		availableEmployeesNotificationModal: {
			...scheduleBoard.availableEmployeesNotificationModal,
			groupId,
			dueDate,
			isOpen: true,
		},
	};

	return refreshAvailableEmployeesNotificationModal(updatedScheduleBoard);
};

export const refreshAvailableEmployeesNotificationModal = (scheduleBoard: ScheduleBoardStoreState): ScheduleBoardStoreState => {
	const { workOrdersByDateDictionary, employees, availableEmployeesNotificationModal, toolbarEmployeeGroupTitles } = scheduleBoard;
	if (!employees) {
		throw new Error('Employee toolbar not defined');
	}

	const { groupId, dueDate } = availableEmployeesNotificationModal;
	if (!dueDate) {
		throw new Error('Due date not defined');
	}

	const { toolbarEmployees, availableNotificationStatuses } = workOrdersByDateDictionary[dueDate];

	const filteredEmployeeIds = groupId !== null
		? toolbarEmployees.available[groupId].filter((_empId) => _shouldDisplayInAvailableEmployeesModal(employees[_empId].account))
		: [];
	const groupTitle = toolbarEmployeeGroupTitles?.available?.find((_group) => _group.id === groupId);
	const sortedEmployeeIds = _sortToolbarEmployees(filteredEmployeeIds, employees);

	let hasAlreadyNotifiedEmployees = false;
	const _employees = sortedEmployeeIds.reduce((_acc: EmployeeNotificationStatusData, _empId: number) => {
		const _e = employees[_empId];
		if (
			(!!_e.email && !!availableNotificationStatuses?.[_empId]?.emailSentAt)
			|| (!!_e.phoneNumber && !!availableNotificationStatuses?.[_empId]?.smsSentAt)
		) {
			hasAlreadyNotifiedEmployees = true;
		}
		_acc[_e.id] = {
			...getNotifyModalEmployeeFromEmployee(_e.id, _e, availableNotificationStatuses?.[_empId]),
			shouldSendEmail: !!_e.email && availableNotificationStatuses?.[_empId]?.emailStatus !== NotificationStatusEnum.OPENED,
			shouldSendSms: !!_e.phoneNumber && availableNotificationStatuses?.[_empId]?.smsStatus !== NotificationStatusEnum.OPENED,
		};
		return _acc;
	}, {} as EmployeeNotificationStatusData);

	return {
		...scheduleBoard,
		availableEmployeesNotificationModal: {
			...scheduleBoard.availableEmployeesNotificationModal,
			employees: _employees,
			employeeIds: sortedEmployeeIds,
			hasAlreadyNotifiedEmployees,
			groupTitle: groupTitle?.title ?? null,
		},
	};
};

export const checkEmployeeAvailableEmployeesNotificationModal = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: CheckEmployeeInAvailableEmployeesNotificationModal
): ScheduleBoardStoreState => {
	const { availableEmployeesNotificationModal } = scheduleBoard;
	const { employees } = availableEmployeesNotificationModal;
	const { employeeId, type } = payload;

	const _employees = {
		...employees,
		[employeeId]: {
			...employees[employeeId],
			shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? !employees[employeeId].shouldSendEmail : employees[employeeId].shouldSendEmail,
			shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? !employees[employeeId].shouldSendSms : employees[employeeId].shouldSendSms,
		},
	};
	const hasAlreadyNotifiedEmployees = _hasAlreadyNotifiedEmployees(_employees);

	return {
		...scheduleBoard,
		availableEmployeesNotificationModal: {
			...availableEmployeesNotificationModal,
			employees: _employees,
			hasAlreadyNotifiedEmployees,
		},
	};
};

export const checkAllAvailableEmployeesNotificationModal = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: CheckAllInAvailableEmployeesNotificationModal
): ScheduleBoardStoreState => {
	const { type, value } = payload;
	const { availableEmployeesNotificationModal } = scheduleBoard;

	const prevEmployees = Object.values(availableEmployeesNotificationModal.employees);
	const _employees = prevEmployees.reduce((_acc: EmployeeNotificationStatusData, _e: EmployeeNotifyModalState) => {
		_acc[_e.id] = {
			..._e,
			shouldSendEmail: type === NOTIFY_EMPLOYEE_TYPE.EMAIL ? !!(_e.email && value) : _e.shouldSendEmail,
			shouldSendSms: type === NOTIFY_EMPLOYEE_TYPE.SMS ? !!(_e.phoneNumber && value) : _e.shouldSendSms,
		};
		return _acc;
	}, {});

	const hasAlreadyNotifiedEmployees = _hasAlreadyNotifiedEmployees(_employees);

	return {
		...scheduleBoard,
		availableEmployeesNotificationModal: {
			...availableEmployeesNotificationModal,
			employees: _employees,
			hasAlreadyNotifiedEmployees,
		},
	};
};

export const resetAllAvailableEmployeesNotificationModal = (scheduleBoard: ScheduleBoardStoreState, dueDate: string): ScheduleBoardStoreState => {
	const { availableEmployeesNotificationModal, workOrdersByDateDictionary } = scheduleBoard;
	const availableNotificationStatuses = workOrdersByDateDictionary?.[dueDate]?.availableNotificationStatuses;

	const prevEmployees = Object.values(availableEmployeesNotificationModal.employees);
	const _employees: EmployeeNotificationStatusData = prevEmployees.reduce((_acc: EmployeeNotificationStatusData, _e: EmployeeNotifyModalState) => {
		_acc[_e.id] = {
			..._e,
			shouldSendEmail: !!_e.email && availableNotificationStatuses?.[_e.id]?.emailStatus !== NotificationStatusEnum.OPENED,
			shouldSendSms: !!_e.phoneNumber && availableNotificationStatuses?.[_e.id]?.smsStatus !== NotificationStatusEnum.OPENED,
		};
		return _acc;
	}, {});

	return {
		...scheduleBoard,
		availableEmployeesNotificationModal: {
			...availableEmployeesNotificationModal,
			employees: _employees,
			hasAlreadyNotifiedEmployees: false,
		},
	};
};

export const changeWorkOrdersSort = (scheduleBoard: ScheduleBoardStoreState, workOrdersSort: ScheduleBoardSortType): ScheduleBoardStoreState => {
	const dates = Object.keys(scheduleBoard.workOrdersByDateDictionary);
	dates.forEach((dueDate: string) => {
		const workOrders = scheduleBoard.workOrdersByDateDictionary[dueDate].workOrders;
		const workOrdersOrdering = _getWorkOrdersOrderingForSort(workOrders, workOrdersSort, dueDate);
		if (!!workOrdersOrdering) {
			const _workOrders: (ScheduleBoardWorkOrderViewModel | BlankWorkOrder)[] = workOrdersOrdering.map(
				(_woCode: string) => ScheduleBoardUtil.isBlankWorkOrderId(_woCode) ? { isBlank: true } as BlankWorkOrder : workOrders[_woCode]
			);
			const {
				columnNumbersDict,
				workOrdersRowDistribution,
			} = ScheduleBoardUtil.getWorkOrdersPerRowDistribution(_workOrders, scheduleBoard.zoomLevel, dueDate);

			scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersOrdering = workOrdersOrdering;
			scheduleBoard.workOrdersByDateDictionary[dueDate].columnNumbersDict = columnNumbersDict;
			scheduleBoard.workOrdersByDateDictionary[dueDate].workOrdersRowDistribution = workOrdersRowDistribution;
		}
	});
	return {
		...scheduleBoard,
		workOrdersSort,
	};
};

export const updateDailyTip = (scheduleBoard: ScheduleBoardStoreState, payload: DailyTipViewModel): ScheduleBoardStoreState => {
	const { date } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				dailyTip: payload,
			},
		},
	};
};

export const updateDailyPerDiemTip = (scheduleBoard: ScheduleBoardStoreState, payload: DailyPerDiemTipViewModel): ScheduleBoardStoreState => {
	const { date } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				dailyPerDiemTip: payload,
			},
		},
	};
};

export const addEmployeeNightShiftAssignment = (scheduleBoard: ScheduleBoardStoreState, payload: EmployeeNightShiftAssignmentVM): ScheduleBoardStoreState => {
	const { date, employeeId } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				employeeNightShiftAssignments: {
					...scheduleBoard.workOrdersByDateDictionary[dateOnly].employeeNightShiftAssignments,
					[`${employeeId}`]: true,
				},
			},
		},
	};
};

export const removeEmployeeNightShiftAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: EmployeeNightShiftAssignmentVM
): ScheduleBoardStoreState => {
	const { date, employeeId } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	const nextNightShiftAssignments = { ...scheduleBoard.workOrdersByDateDictionary[dateOnly]?.employeeNightShiftAssignments };
	delete nextNightShiftAssignments[employeeId];

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				employeeNightShiftAssignments: nextNightShiftAssignments,
			},
		},
	};
};

export const addTemporaryEmployeeNightShiftAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: TemporaryEmployeeNightShiftAssignmentVM
): ScheduleBoardStoreState => {
	const { date, temporaryEmployeeId } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				temporaryEmployeeNightShiftAssignments: {
					...scheduleBoard.workOrdersByDateDictionary[dateOnly].temporaryEmployeeNightShiftAssignments,
					[`${temporaryEmployeeId}`]: true,
				},
			},
		},
	};
};

export const removeTemporaryEmployeeNightShiftAssignment = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: TemporaryEmployeeNightShiftAssignmentVM
): ScheduleBoardStoreState => {
	const { date, temporaryEmployeeId } = payload;

	const dateOnly = TimeUtils.formatDate(date, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY);

	const nextNightShiftAssignments = { ...scheduleBoard.workOrdersByDateDictionary[dateOnly]?.temporaryEmployeeNightShiftAssignments };
	delete nextNightShiftAssignments[temporaryEmployeeId];

	return {
		...scheduleBoard,
		workOrdersByDateDictionary: {
			...scheduleBoard.workOrdersByDateDictionary,
			[dateOnly]: {
				...scheduleBoard.workOrdersByDateDictionary[dateOnly],
				temporaryEmployeeNightShiftAssignments: nextNightShiftAssignments,
			},
		},
	};
};

export const addLatestPublishedRevisions = (
	scheduleBoard: ScheduleBoardStoreState,
	payload: LatestPublishedWorkOrdersVM
): ScheduleBoardStoreState => {

	return {
		...scheduleBoard,
		latestPublishedRevisions: {
			...scheduleBoard.latestPublishedRevisions,
			...payload,
		},
	};
};

export const clearPublishedRevisions = (
	scheduleBoard: ScheduleBoardStoreState,
	workOrderIds: number[]
): ScheduleBoardStoreState => {

	return {
		...scheduleBoard,
		latestPublishedRevisions: {
			...filterKeys(scheduleBoard.latestPublishedRevisions ?? {}, workOrderIds.map(String)),
		},
	};
};

const _getInitLocationObject = (index: number | undefined = Infinity, hidden: boolean = false): IndividualLaborStatisticsPerLocationUnparsed => ({
	color: null,
	assignedLaborCount: {},
	totalLaborCount: 0,
	crews: {},
	totalRevenue: 0,
	index,
	hidden,
});

const _filterEmployeesToIncludeInStatistics = (employees: Nullable<ScheduleBoardEmployeesViewModel>) => {
	if (!employees) {
		return null;
	}

	const filteredEmployeeEntries = Object.entries(employees).filter(([, _employee]) => {
		// TODO remove this info log once we figure out how such an employee could be loaded into the SB state
		if (_employee.showOnScheduleBoard === undefined) {
			console.info('Employee must have information about schedule board visibility.', _employee);
		}
		return !!_employee.showOnScheduleBoard;
	});

	return Object.fromEntries(filteredEmployeeEntries);
};

const _resourceLookupsByWOCode = (workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel) => {
	return Object.keys(workOrderResourceLookups).reduce((_acc, _id) => {
		const resource: Single = workOrderResourceLookups[_id];
		if (!resource.workOrderCode) {
			throw new Error('Resource has no work order code');
		}
		if (!_acc[resource.workOrderCode]) {
			_acc[resource.workOrderCode] = [];
		}
		_acc[resource.workOrderCode].push(resource);
		return _acc;
	}, {} as { [code: string]: Single[]; });
};

/**
 * Calculates labor statistics for schedule board.
 *
 * @param employees Ensure only employees that can be shown on schedule board are sent
 * @param workOrders
 * @param workOrderResourceLookups
 * @param locationsForCompany
 * @returns
 */
export const calculateLaborStatistics = (
	employees: Nullable<ScheduleBoardEmployeesViewModel>,
	workOrders: ScheduleBoardWorkOrdersViewModel,
	workOrderResourceLookups: ScheduleBoardWorkOrderResourceLookupsViewModel = {},
	locationsForCompany: LocationViewModel[] = []
): ScheduleBoardLaborStatistics => {

	const _locationsForCompany = locationsForCompany.filter((_loc: LocationViewModel) => !_loc.isDeleted);
	const _employees = _filterEmployeesToIncludeInStatistics(employees);

	// Work orders might not have the latest office nicknames loaded so we need to reference them by id
	const _locationsLookup = locationsForCompany.reduce(ArrayUtils.getToLookupByKey(ArrayUtils.getId), {});

	// task AP-2345 describes revenue section of labor statistics
	const initialLaborStatistics: LaborStatisticsPerLocationUnparsed = _locationsForCompany.reduce(
		(_acc, _location) => Object.assign(_acc, { [_location?.nickname]: _getInitLocationObject(_location?.index, !_location?.showInStatistics) }),
		{ [UNKNOWN_LOCATION_NICKNAME]: _getInitLocationObject() }
	);

	// get all nonCanceled work orders and are not in draft
	const allVisibleWorkOrders = Object.values(workOrders ?? {}).filter(
		(_wo) => _wo.status !== WorkOrderStatusEnum.CANCELED && _wo.status !== WorkOrderStatusEnum.DRAFT && !_wo.isInternal
	);

	// helper function for getting employee objects from workOrderEmployeeId
	const getEmployee = (_resourceLookupId: number) => {
		const employeeId = workOrderResourceLookups?.[_resourceLookupId]?.employeeId;
		return (employeeId && _employees)
			? _employees[employeeId]
			: undefined;
	};

	const resourceLookupsByWOCode = _resourceLookupsByWOCode(workOrderResourceLookups);
	const assignedLaborStatistics: LaborStatisticsPerLocationUnparsed = allVisibleWorkOrders
		.reduce((_acc: EmployeeInfo[], _wo: ScheduleBoardWorkOrderViewModel) => {
			// get needed information for each assigned employee in order to
			// group and count them for labor statistics
			const employeesWithoutSIandPM = filterMap(
				resourceLookupsByWOCode[_wo.code] ?? [],
				(_resource) => {
					const employee = getEmployee(_resource.id);
					const isProjectManager = employee?.account?.assignableAsProjectManager;
					const isSuperintendent = employee?.account?.assignableAsSuperintendent;
					return !!employee && !isProjectManager && !isSuperintendent && !employee?.isDeleted;
				},
				(_resource) => _resource.id);
			const laborCount = employeesWithoutSIandPM.length;
			const workOrderRevenue = _wo.revenue ? +_wo.revenue || 0 : 0;
			const revenuePerPerson = laborCount ? (workOrderRevenue / laborCount) : 0;

			const employeeInfoList = employeesWithoutSIandPM.map((_resourceLookupId) => {
				const employee = getEmployee(_resourceLookupId);
				return ({
					officeNickname: employee?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME,
					officeColor: employee?.office?.color,
					employeeId: employee?.id,
					revenue: revenuePerPerson,
				} as EmployeeInfo);
			});
			_acc.push(...employeeInfoList);
			return _acc;
		}, [])
		// count assigned labor for each office
		.reduce((_acc: LaborStatisticsPerLocationUnparsed, _empInfo: EmployeeInfo) => {
			const { officeColor, officeNickname, employeeId, revenue } = _empInfo;
			if (!_acc[officeNickname]) {
				_acc[officeNickname] = _getInitLocationObject();
			}
			// make stats
			_acc[officeNickname].color = officeColor;
			_acc[officeNickname].totalRevenue += revenue;
			Object.assign(_acc[officeNickname].assignedLaborCount, { [employeeId]: true });
			return _acc;
		}, initialLaborStatistics);

	// count crews and revenue for each office
	const crewsLaborStatistics = allVisibleWorkOrders.reduce((_acc: LaborStatisticsPerLocationUnparsed, _wo: ScheduleBoardWorkOrderViewModel) => {
		const woOfficeNickname = (_wo?.officeId && _locationsLookup[_wo.officeId] !== undefined)
			? _locationsLookup[_wo.officeId].nickname
			: UNKNOWN_LOCATION_NICKNAME;

		if (!_acc[woOfficeNickname]) {
			_acc[woOfficeNickname] = _getInitLocationObject();
		}
		Object.assign(_acc[woOfficeNickname], { color: _wo?.officeColor });
		Object.assign(_acc[woOfficeNickname].crews, { [_wo.code]: true });
		const woWithoutFieldWorkers = !_wo.workOrderResourceLookups?.some((_resourceLookupId) => {
			const employee = getEmployee(_resourceLookupId);
			const isProjectManager = employee?.account?.assignableAsProjectManager;
			const isSuperintendent = employee?.account?.assignableAsSuperintendent;
			return !!employee && !isProjectManager && !isSuperintendent && !employee?.isDeleted;
		});
		if (woWithoutFieldWorkers && !!_wo.revenue) {
			_acc[woOfficeNickname].totalRevenue += +_wo.revenue;
		}
		return _acc;
	}, assignedLaborStatistics);

	// count total number of employees in each office that has assigned labor
	const laborStatisticsUnparsed: LaborStatisticsPerLocationUnparsed = Object.values(_employees ?? {})
		.reduce((_acc: LaborStatisticsPerLocationUnparsed, _emp: ScheduleBoardEmployee) => {
			if (!!_emp.account && !_emp.account.assignableAsSuperintendent && !_emp.account.assignableAsProjectManager && !_emp.isDeleted) {
				const officeNickname = _emp?.office?.nickname ?? UNKNOWN_LOCATION_NICKNAME;
				if (!_acc[officeNickname]) {
					_acc[officeNickname] = _getInitLocationObject(_emp?.office?.index);
				}
				Object.assign(
					_acc[officeNickname],
					{
						color: _emp?.office?.color,
						totalLaborCount: (_acc?.[officeNickname]?.totalLaborCount ?? 0) + 1,
					}
				);
			}
			return _acc;
		}, crewsLaborStatistics);

	// sort offices by index
	const sortedNicknames = Object.keys(laborStatisticsUnparsed)
		.sort((_nickname1, _nickname2) => (laborStatisticsUnparsed[_nickname1]?.index ?? Infinity) - (laborStatisticsUnparsed[_nickname2]?.index ?? Infinity));

	// parse office statistics objects
	const sortedAndParsedLaborStatistics: LaborStatisticsPerLocationParsed = sortedNicknames.reduce(
		(_acc: LaborStatisticsPerLocationParsed, _officeNickname: string) => {

			if (laborStatisticsUnparsed[_officeNickname] === undefined) {
				return _acc;
			}

			Object.assign(_acc, {
				[_officeNickname]: {
					color: laborStatisticsUnparsed[_officeNickname].color,
					assignedLaborCount: Object.keys(laborStatisticsUnparsed[_officeNickname].assignedLaborCount || {}).length,
					totalLaborCount: laborStatisticsUnparsed[_officeNickname].totalLaborCount,
					crewsCount: Object.keys(laborStatisticsUnparsed[_officeNickname].crews || {}).length,
					totalRevenue: laborStatisticsUnparsed[_officeNickname].totalRevenue,
					hidden: laborStatisticsUnparsed[_officeNickname].hidden,
				},
			});
			return _acc;
		},
		{} as LaborStatisticsPerLocationParsed
	);

	// calculate total counts
	const totalLaborStatistics: IndividualLaborStatisticsParsed = Object.values(sortedAndParsedLaborStatistics).reduce(
		(_acc: IndividualLaborStatisticsParsed, _counts: IndividualLaborStatisticsParsed) => {
			_acc.crewsCount += _counts.crewsCount;
			_acc.assignedLaborCount += _counts.assignedLaborCount;
			_acc.totalLaborCount += _counts.totalLaborCount;
			_acc.totalRevenue += _counts.totalRevenue;
			return _acc;
		},
		{ color: AdditionalColors.GREY, crewsCount: 0, assignedLaborCount: 0, totalLaborCount: 0, totalRevenue: 0 } as IndividualLaborStatisticsParsed
	);

	return {
		laborStatisticsPerLocation: sortedAndParsedLaborStatistics,
		totalLaborStatistics,
	};
};

// #endregion SB Reducer Handlers

// #region SB Socket methods

export const subscribeDailySocketEvents = (generalActions: GeneralActionsParam, scheduleBoardActions: ScheduleBoardActionsParam) => {

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DAILY_VIEW_BOARD_REJOIN, (data: ScheduleBoardRejoinViewModel) => {
		scheduleBoardActions.getScheduleBoardRejoin(data);
		scheduleBoardActions.clearFilters();
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RESOURCES, (data: ScheduleBoardResourcesViewModel) => {
		scheduleBoardActions.getScheduleBoardResources(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD_RESOURCES, () => {
		loadResources();
	});

	subscribeSharedSocketEvents(generalActions, scheduleBoardActions);
};

export const subscribeSharedSocketEvents = (generalActions: GeneralActionsParam, scheduleBoardActions: ScheduleBoardActionsParam) => {

	socket.connection?.subscribe(SocketEvent.PREDEFINED.CONNECT_ERROR, () => {
		console.info('predefined error: show refresh modal');
		generalActions.showRefreshModal();
	});

	socket.connection?.subscribeToManager(SocketEvent.PREDEFINED.ERROR, () => {
		console.info('predefined error: show refresh modal');
		generalActions.showRefreshModal();
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.GENERAL.SYNC_ERROR, () => {
		console.info('sync error: show refresh modal');
		generalActions.showRefreshModal();
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CONNECTED, (data: ScheduleBoardRM) => {
		// current connections
		const hasDatesToLoad = !!data?.datesToLoad?.length;
		switch (data.type) {
			case SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_WEEKLY_VIEW:
			case SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_WEEKLY_VIEW: // Might never get called directly, and does essentaily the same thing as join daily view
				if (hasDatesToLoad) {
					scheduleBoardActions.getWeeklyView(data.startDate, data.endDate, data.datesToLoad);
					socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_WEEKLY_VIEW, data);
				} else {
					socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_WEEKLY_VIEW_REJOIN, data);
				}
				break;
			case SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_DAILY_VIEW:
			case SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_DAILY_VIEW: // Might never get called directly, and does essentaily the same thing as join daily view
				if (hasDatesToLoad) {
					scheduleBoardActions.getDailyView(data.date, data.datesToLoad);
				} else {
					socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.GET_DAILY_VIEW_REJOIN, data);
				}
				break;
			default:
				console.info(`Connection request ${data.type}`);
		}
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.EMPLOYEE_DRAG_START, (employeeId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.disableWorkOrderItem(data);
		}
		scheduleBoardActions.disableEmployeeItem(employeeId);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.EMPLOYEE_DRAG_END, (employeeId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.enableWorkOrderItem(data);
		}
		scheduleBoardActions.enableEmployeeItem(employeeId);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_START,
		(temporaryEmployeeId: number, data?: WorkOrderCodeAndDueDate) => {
			//  if data is received we lock need to lock WO
			if (data) {
				scheduleBoardActions.disableWorkOrderItem(data);
			}
			scheduleBoardActions.disableTemporaryEmployeeItem(temporaryEmployeeId);
		});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.TEMPORARY_EMPLOYEE_DRAG_END,
		(temporaryEmployeeId: number, data?: WorkOrderCodeAndDueDate) => {
			//  if data is received we lock need to lock WO
			if (data) {
				scheduleBoardActions.enableWorkOrderItem(data);
			}
			scheduleBoardActions.enableTemporaryEmployeeItem(temporaryEmployeeId);
		});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_SCHEDULE_BOARD_DROPPABLE_LIST, (dragElement: ScheduleBoardDragRequestModel) => {
		scheduleBoardActions.updateScheduleBoardDroppableList(dragElement);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.EQUIPMENT_DRAG_START, (equipmentId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.disableWorkOrderItem(data);
		}
		scheduleBoardActions.disableEquipmentItem(equipmentId);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.EQUIPMENT_DRAG_END, (equipmentId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.enableWorkOrderItem(data);
		}
		scheduleBoardActions.enableEquipmentItem(equipmentId);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_START, (placeholderId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.disableWorkOrderItem(data);
		}
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.PLACEHOLDER_DRAG_END, (placeholderId: number, data?: WorkOrderCodeAndDueDate) => {
		//  if data is received we lock need to lock WO
		if (data) {
			scheduleBoardActions.enableWorkOrderItem(data);
		}
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WORK_ORDER_DRAG_START, (data: WorkOrderCodeAndDueDate) => {
		scheduleBoardActions.disableWorkOrderItem(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WORK_ORDER_DRAG_END, (data: WorkOrderCodeAndDueDate) => {
		scheduleBoardActions.enableWorkOrderItem(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REORDER_WORK_ORDERS, (data: ScheduleBoardReorderWorkOrdersViewModel) => {
		scheduleBoardActions.updateScheduleBoardWorkOrderIndices(data);
	});

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_RESOURCE_PLACEHOLDER, (data: ScheduleBoardAddResourceLookupAssignment) => {
		scheduleBoardActions.addPlaceholderAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EMPLOYEE_ASSIGNMENT, (data: ScheduleBoardAddResourceLookupAssignment) => {
		if (!data.workOrderResourceLookup?.workOrderCode) {
			throw new Error('Cannot subscribe without WO code');
		}
		scheduleBoardActions.setCopiedResourcePlaceholder(data.dueDate, data.workOrderResourceLookup.workOrderCode, undefined, true);
		scheduleBoardActions.addEmployeeAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EMPLOYEE_ASSIGNMENT, (data: ScheduleBoardRemoveResourceAssignmentVM) => {
		scheduleBoardActions.removeEmployeeAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_EQUIPMENT_ASSIGNMENT, (data: ScheduleBoardAddResourceLookupAssignment) => {
		scheduleBoardActions.setCopiedResourcePlaceholder(data.dueDate, data.workOrderResourceLookup?.workOrderCode, undefined, true);
		scheduleBoardActions.addEquipmentAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_EQUIPMENT_ASSIGNMENT, (data: ScheduleBoardRemoveResourceAssignmentVM) => {
		scheduleBoardActions.removeEquipmentAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_PLACEHOLDER_ASSIGNMENT, (data: ScheduleBoardAddResourceLookupAssignment) => {
		scheduleBoardActions.setCopiedResourcePlaceholder(data.dueDate, data.workOrderResourceLookup?.workOrderCode, undefined, true);
		scheduleBoardActions.addPlaceholderAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_PLACEHOLDER_ASSIGNMENT, (data: ScheduleBoardRemoveResourceAssignmentVM) => {
		scheduleBoardActions.removePlaceholderAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CREATE_TEMPORARY_EMPLOYEE_ASSIGNMENT, (data: ScheduleBoardAddResourceLookupAssignment) => {
		scheduleBoardActions.setCopiedResourcePlaceholder(data.dueDate, data.workOrderResourceLookup?.workOrderCode, undefined, true);
		scheduleBoardActions.addTemporaryEmployeeAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TEMPORARY_EMPLOYEE_ASSIGNMENT, (data: ScheduleBoardRemoveResourceAssignmentVM) => {
		scheduleBoardActions.removeTemporaryEmployeeAssignment(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TO_DROPPABLE_LIST, (data: ScheduleBoardDragRequestModel) => {
		scheduleBoardActions.addToScheduleBoardDroppableList(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EMPLOYEE, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.removeToolbarEmployee(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EMPLOYEE_FROM_DATE, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.removeToolbarEmployeeFromDate(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_MULTIPLE_TOOLBAR_EMPLOYEE, (data: ScheduleBoardToolbarEmployeeUpdate[]) => {
		scheduleBoardActions.removeMultipleToolbarEmployee(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EQUIPMENT, (data: ScheduleBoardToolbarEquipmentUpdate) => {
		scheduleBoardActions.removeToolbarEquipment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_MULTIPLE_TOOLBAR_EQUIPMENT, (data: ScheduleBoardToolbarEquipmentUpdate[]) => {
		scheduleBoardActions.removeMultipleToolbarEquipment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.removeToolbarEmployeeForAllDays(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS, (data: ScheduleBoardToolbarEquipmentUpdate) => {
		scheduleBoardActions.removeToolbarEquipmentForAllDays(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EMPLOYEE, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.addToolbarEmployee(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EMPLOYEE_FROM_DATE, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.addToolbarEmployeeFromDate(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EQUIPMENT, (data: ScheduleBoardToolbarEquipmentUpdate) => {
		scheduleBoardActions.addToolbarEquipment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_EQUIPMENT_DOWN_DETAILS, (data: AddEquipmentDownDetails) => {
		scheduleBoardActions.addToolbarEquipmentDownDetails(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_EMPLOYEE_DOWN_DETAILS, (data: EmployeeUnavailabilityDetailsVM) => {
		scheduleBoardActions.addToolbarEmployeeDownDetails(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EMPLOYEE_FOR_ALL_DAYS, (data: ScheduleBoardToolbarEmployeeUpdate) => {
		scheduleBoardActions.addToolbarEmployeeForAllDays(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TOOLBAR_EQUIPMENT_FOR_ALL_DAYS, (data: ScheduleBoardToolbarEquipmentUpdate) => {
		scheduleBoardActions.addToolbarEquipmentForAllDays(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_WORK_ORDER_RESOURCE_LOOKUP_TO_DICT, (data: ScheduleBoardAddResourceLookupAssignment) => {
		scheduleBoardActions.setCopiedResourcePlaceholder(data.dueDate, data.workOrderResourceLookup?.workOrderCode, undefined, true);
		scheduleBoardActions.addWorkOrderResourceLookupToDict(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TEMPORARY_EMPLOYEE, (data: ScheduleBoardAddTemporaryEmployeeVM) => {
		scheduleBoardActions.addTemporaryEmployee(data);
	});

	socket.connection?.subscribe(
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EMPLOYEE_ASSIGNMENT_FROM_DICT,
		(data: ScheduleBoardRemoveAllWorkOrderEmployeesVM) => {
			scheduleBoardActions.removeAllWorkOrderEmployees(data);
		}
	);

	socket.connection?.subscribe(
		SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EMPLOYEE_ASSIGNMENT_ON_DRAFTS,
		(data: ScheduleBoardRemoveAllWorkOrderEmployeesVM) => {
			scheduleBoardActions.removeAllWorkOrderEmployeeOnDrafts(data);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EQUIPMENT_ASSIGNMENT_FROM_DICT,
		(data: ScheduleBoardRemoveAllWorkOrderEquipmentVM) => {
			scheduleBoardActions.removeAllWorkOrderEquipment(data);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_ALL_EQUIPMENT_ASSIGNMENT_ON_DRAFTS,
		(data: ScheduleBoardRemoveAllWorkOrderEquipmentVM) => {
			scheduleBoardActions.removeAllWorkOrderEquipmentOnDrafts(data);
		}
	);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE, (data: ScheduleBoardEmployee) => {
		scheduleBoardActions.updateEmployee(data);
	}, true);

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_TEMPORARY_EMPLOYEE, (data: ScheduleBoardTemporaryEmployee) => {
		scheduleBoardActions.updateTemporaryEmployee(data);
	}, true);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EQUIPMENT, (data: ScheduleBoardEquipment) => {
		scheduleBoardActions.updateEquipment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_WORK_ORDER, (data: WorkOrderIdAndDueDate) => {
		scheduleBoardActions.lockWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_WORK_ORDER, (data: WorkOrderIdAndDueDate) => {
		scheduleBoardActions.unlockWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER, (data: ScheduleBoardWorkOrderViewModel) => {
		scheduleBoardActions.updateScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_BLANK_WORK_ORDER, (data) => {
		scheduleBoardActions.addBlankWorkOrderInRedux(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_BLANK_WORK_ORDER, (data) => {
		scheduleBoardActions.removeBlankWorkOrderInRedux(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.DELETE_WORK_ORDER, (data: ScheduleBoardDeleteWorkOrderViewModel) => {
		scheduleBoardActions.removeScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CANCEL_WORK_ORDER, (data: ScheduleBoardDeleteWorkOrderViewModel) => {
		scheduleBoardActions.cancelScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.PAUSE_WORK_ORDER, (data: ScheduleBoardPauseWorkOrderViewModel) => {
		scheduleBoardActions.pauseScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RESUME_WORK_ORDER, (data: ScheduleBoardResumeWorkOrderViewModel) => {
		scheduleBoardActions.resumeScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REVERT_WORK_ORDER, (data: ScheduleBoardRevertWorkOrderViewModel) => {
		scheduleBoardActions.revertScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.LOCK_STATUS_WORK_ORDER, (data: ScheduleBoardLockWorkOrderViewModel) => {
		scheduleBoardActions.lockScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UNLOCK_STATUS_WORK_ORDER, (data: ScheduleBoardUnlockWorkOrderViewModel) => {
		scheduleBoardActions.unlockScheduleBoardWorkOrder(data);
	});

	// Registering multiple handlers, because it is also used in work order modal
	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.PUBLISH_WORK_ORDER, (data: ScheduleBoardWorkOrderViewModel) => {
		scheduleBoardActions.clearPublishedRevisions([data.id]); // First clear revision, then proceed to updates
		scheduleBoardActions.updateScheduleBoardWorkOrder(data);
		const _woData = {
			workOrders: [data.id],
			dueDates: [TimeUtils.formatDate(data.dueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.DATE_ONLY)],
		};
		scheduleBoardActions.updateWorkOrdersOnJobWorkSummaryUpdate(_woData);
		scheduleBoardActions.updateTimeDataOnJobPayrollUpdate(_woData);
	}, true);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.COPY_MULTIPLE_WORK_ORDER, (data: ScheduleBoardCopiedWorkOrderViewModel[]) => {
		data.forEach(({ date }: ScheduleBoardCopiedWorkOrderViewModel) => {
			scheduleBoardActions.setCopiedWorkOrderPlaceholder(date, null);
		});
		scheduleBoardActions.copyMultipleScheduleBoardWorkOrder(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_UNAVAILABILITY_REASON, (data: ScheduleBoardUnavailabilityReasonViewModel) => {
		scheduleBoardActions.assignUnavailabilityReason(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CLEAR_UNAVAILABILITY_REASON,
		({ itemId, scope, dueDate }: ScheduleBoardUnavailabilityReasonViewModel) => {
			scheduleBoardActions.clearUnavailabilityReason(itemId, scope, dueDate);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.CHANGE_RETURN_DATE, (data: ScheduleBoardReturnDateRequestModel) => {
		scheduleBoardActions.changeReturnDate(data);
	});

	socket.connection?.subscribe(
		SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_EMPLOYEE_PER_DIEM,
		async (data: ScheduleBoardEmployeePerDiemAssignmentRequestModel) => {
			scheduleBoardActions.updateEmployeePerDiem(data);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.SET_PER_DIEM_FOR_WORK_ORDERS,
		async (data: ScheduleBoardSetPerDiemForWorkOrdersRequestModel) => {
			scheduleBoardActions.setPerDiemForWorkOrders(data);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.SYNC_NOTIFICATION_STATUS, (data: NotificationLookupViewModel) => {
		scheduleBoardActions.syncWorkOrderEmployeeNotificationStatus(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER_HAS_ATTACHMENTS,
		(data: ScheduleBoardUpdateWorkOrderHasAttachmentsVM) => {
			scheduleBoardActions.updateWorkOrderHasAttachments(data);
		}
	);

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_WORK_ORDER_NOTE, (data: ScheduleBoardUpdateWorkOrderNoteViewModel) => {
		scheduleBoardActions.updateWorkOrderNote(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_NIGHT_SHIFT_ASSIGNMENT, (data: EmployeeNightShiftAssignmentVM) => {
		scheduleBoardActions.addEmployeeNightShiftAssignment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_NIGHT_SHIFT_ASSIGNMENT, (data: EmployeeNightShiftAssignmentVM) => {
		scheduleBoardActions.removeEmployeeNightShiftAssignment(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.ADD_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		(data: TemporaryEmployeeNightShiftAssignmentVM) => {
			scheduleBoardActions.addTemporaryEmployeeNightShiftAssignment(data);
		});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.REMOVE_TEMPORARY_EMPLOYEE_NIGHT_SHIFT_ASSIGNMENT,
		(data: TemporaryEmployeeNightShiftAssignmentVM) => {
			scheduleBoardActions.removeTemporaryEmployeeNightShiftAssignment(data);
		});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_CREW_TYPE, (data: ScheduleBoardUpdateCrewType) => {
		scheduleBoardActions.updateCrewType(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_DAILY_PER_DIEM_TIP, (data: DailyPerDiemTipViewModel) => {
		scheduleBoardActions.updateDailyPerDiemTip(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_DAILY_TIP, (data: DailyTipViewModel) => {
		scheduleBoardActions.updateDailyTip(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_REVENUE_ON_JOB_WORK_SUMMARY_UPDATE, (data: UpdateJobWorkSummaryViewModel) => {
		scheduleBoardActions.updateWorkOrdersOnJobWorkSummaryUpdate(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.UPDATE_TIME_ON_JOB_PAYROLL_UPDATE, (data: UpdateWorkOrderTime) => {
		scheduleBoardActions.updateTimeDataOnJobPayrollUpdate(data);
	});

	socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.WORK_ORDER_OUTDATED, (data: number) => {
		scheduleBoardActions.addLatestPublishedRevisionForWorkOrders([data]);
	});
};

// moved methods from the socket component. we should refactor entire file

// Shared socket action implementations:

/** Emits `FE.SCHEDULE_BOARD.LOAD_BOARD_RESOURCES` */
export const loadResources = () => {
	// not using `scheduleBoardActions.reloadScheduleBoardResources();` because that might trigger anther call to `LOAD_BOARD_RESOURCES`
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.LOAD_BOARD_RESOURCES);
};

type PropsForLoadResources = { shouldLoadResources: boolean; };
/** Shared `componentDidUpdate` logic for components that are subscribed to the `resourcesLoaded` state */
export const shouldLoadResourcesOnUpdate = (prevProps: PropsForLoadResources, currentProps: PropsForLoadResources) => {
	return prevProps.shouldLoadResources === false && currentProps.shouldLoadResources === true && socket.connection?.isConnected();
};

export const joinDailyView = (date: string | undefined, loadedDates: { [T: string]: boolean; }, isWorkOrderForm: boolean = false) => {
	const fullBoardRequest: ScheduleBoardRM = { date, isWorkOrderForm, type: SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_DAILY_VIEW, loadedDates };
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_DAILY_VIEW, fullBoardRequest);
};

export const joinWeeklyView = (startDate: Nullable<string>, endDate: Nullable<string>, loadedDates: { [T: string]: boolean; }) => {
	const fullBoardRequest: ScheduleBoardRM = {
		startDate: startDate ?? undefined,
		endDate: endDate ?? undefined,
		type: SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_WEEKLY_VIEW,
		loadedDates,
	};
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.JOIN_WEEKLY_VIEW, fullBoardRequest);
};

export const activateDailyView = (date: string, loadedDates: { [T: string]: boolean; }, isWorkOrderForm: boolean = false) => {
	const fullBoardRequest: ScheduleBoardRM = { date, isWorkOrderForm, type: SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_DAILY_VIEW, loadedDates };
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_DAILY_VIEW, fullBoardRequest);
};

export const activateWeeklyView = (startDate: Nullable<string>, endDate: Nullable<string>, loadedDates: { [T: string]: boolean; }) => {
	const fullBoardRequest: ScheduleBoardRM = {
		startDate: startDate ?? undefined,
		endDate: endDate ?? undefined,
		type: SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_WEEKLY_VIEW,
		loadedDates,
	};
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.ACTIVATE_WEEKLY_VIEW, fullBoardRequest);
};

export const leaveBoard = () => {
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.LEAVE_BOARD);
};

export const unsubscribeFromScheduleBoardEvents = () => {
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.UNSUBSCRIBE_FROM_SCHEDULE_BOARD_EVENTS);
};

export const lockWorkOrder = (workOrderId: number, dueDate: string) => {
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.ADD_WORK_ORDER_LOCK, { dueDate, workOrderId });
};

export const unlockWorkOrder = (workOrderId: number, dueDate: string) => {
	const event = { dueDate: TimeUtils.formatDate(dueDate, TimeFormatEnum.DATE_ONLY, TimeFormatEnum.DB_DATE_ONLY), workOrderId };
	socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.REMOVE_WORK_ORDER_LOCK, event);
};
// #endregion SB Socket methods
