import * as React from 'react';
import type { TypedRowInfo } from 'react-table-6';
import { Col } from 'react-bootstrap';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { RootState } from 'af-reducers';

import type TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import AgencyAssociatedWorkersTableVM from 'ab-viewModels/agency/associatedWorkersTable.viewModel';

import type { Column, TabProps, ItemBlueprint } from 'af-components/Table6';
import Table from 'af-components/Table6';

import { downloadCSV } from 'af-utils/csv.utils';

import * as TemporaryEmployeeActions from 'af-actions/temporaryEmployee';

interface OwnProps {
	csvName: string;
	fetchAssociatedWorkers: (tableRequestModel: TableQuery) => Promise<TableResult<AgencyAssociatedWorkersTableVM>>;
	onRowClick: (rowInfo: TypedRowInfo<AgencyAssociatedWorkersTableVM>) => void;
	tableName: TableNameEnum;
	editAccount: (original: AgencyAssociatedWorkersTableVM) => void;
	previewAccount: (original: AgencyAssociatedWorkersTableVM) => void;
	isDeletedAgency: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const deleteAccountModalTitle = (original: AgencyAssociatedWorkersTableVM) => `Are you sure you want to delete this User for company (${original.firstName} ${original.lastName})?`;
const deleteAccountModalBody = () => <>This action cannot be undone.</>;
const deleteAccountModalText = () => 'Delete User';

const AssociatedWorkers: React.FC<Props> = (props: Props) => {

	const { csvName, deleteAccount, fetchAssociatedWorkers, onRowClick, editAccount, previewAccount, tableName, isDeletedAgency } = props;

	const deleteAccountAction = React.useCallback(async (original: AgencyAssociatedWorkersTableVM) => {
		await deleteAccount(original.id);
	}, [deleteAccount]);

	const onDownloadCSVClick = async () => {

		let fileName = csvName;

		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await fetchAssociatedWorkers(tableRequestModel);
		const csvData = AgencyAssociatedWorkersTableVM.toCSVData(result.rows);

		if (!fileName.endsWith('.csv')) {
			fileName = `${fileName}.csv`;
		}

		downloadCSV(csvData, fileName);
	};

	const columns: Column<AgencyAssociatedWorkersTableVM>[] = React.useMemo(() => [
		{
			Header: 'Name',
			accessor: 'name',
			Cell: ({ original }) => (
				<Col>
					<b>
						{`${original.lastName},${original.firstName}`}
					</b>
					<div className="cell-additional-info">{original.uniqueId}</div>
				</Col>
			),
		},
		{
			Header: 'Total WO',
			accessor: 'totalWO',
			Cell: ({ original }) => original.totalWorkOrders,
		},
	], []);

	const rowActions = React.useMemo(() => {
		const actions: ItemBlueprint<AgencyAssociatedWorkersTableVM>[] = [];
		if (!isDeletedAgency) {
			actions.push({
				label: 'Edit',
				action: editAccount,
				shouldRefresh: false,
			});
		}
		actions.push({
			label: 'Preview',
			action: previewAccount,
			shouldRefresh: false,
		},
			{
				label: 'Deactivate for Company',
				action: deleteAccountAction,
				hasModal: true,
				modalTitle: deleteAccountModalTitle,
				modalBody: deleteAccountModalBody,
				modalText: deleteAccountModalText,
				shouldRefresh: true,
			});
		return actions;
	}, [deleteAccountAction, editAccount, isDeletedAgency, previewAccount]);

	const tabs: TabProps<AgencyAssociatedWorkersTableVM>[] =
		[
			{
				label: 'Work Orders',
				columns: columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Work Orders',
				fetch: fetchAssociatedWorkers,
				onRowClick,
				rowActions,
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: onDownloadCSVClick,
				}],
			},
		];

	return (
		<Table
			hideTabs={true}
			tableName={tableName}
			tabs={tabs}
		/>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps() {
	return {
		deleteAccount: TemporaryEmployeeActions.remove,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	React.memo
);

export default enhance(AssociatedWorkers);
