import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';

import type ItemDepartmentBase from 'ab-domain/models/itemDepartment/base';

export class W_Item_FindItemDepartments_VM {
	items: W_Item_FindItemDepartments_VM_Single[];

	constructor(departments: ItemDepartmentBase[]) {
		this.items = W_Item_FindItemDepartments_VM_Single.bulkConstructor(departments);
	}
}

class W_Item_FindItemDepartments_VM_Single {
	id: number;
	itemId: number;
	itemName: string;
	currentStock: number;
	partNumber: Nullable<string>;
	packageType: Nullable<VendorPackageType>;
	price: number;
	locationNickname: string;
	departmentId: number;
	departmentName: string;
	imageUrl: Nullable<string>;
	modelNumber: string;

	constructor(itemDepartment: ItemDepartmentBase) {
		this.id = itemDepartment.id;
		this.itemId = itemDepartment.itemId;
		this.itemName = itemDepartment.item.name;
		this.currentStock = +itemDepartment.quantity;
		this.partNumber = itemDepartment.item.itemVendor?.partNumber ?? null;
		this.packageType = itemDepartment.item.itemVendor?.packageType ?? null;
		this.price = itemDepartment.item.itemVendor?.price ? +itemDepartment.item.itemVendor?.price : 0;
		this.locationNickname = itemDepartment.department.location.nickname;
		this.departmentId = itemDepartment.departmentId;
		this.departmentName = itemDepartment.department.name;
		this.imageUrl = itemDepartment.item.imageUrl;
		this.modelNumber = itemDepartment.item.modelNumber;
	}

	private static _mapConstructor(itemDepartment: ItemDepartmentBase) {
		return new W_Item_FindItemDepartments_VM_Single(itemDepartment);
	}

	static bulkConstructor(departments: ItemDepartmentBase[]) {
		return departments.map(W_Item_FindItemDepartments_VM_Single._mapConstructor);
	}
}
