import type { Dispatch, AnyAction } from 'redux';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import * as CreateAPI from 'ab-api/web/itemCategory/create';
import * as FindAllAPI from 'ab-api/web/itemCategory/findAll';
import * as UpdateAPI from 'ab-api/web/itemCategory/update';
import * as RemoveAPI from 'ab-api/web/itemCategory/remove';

import type { GetRootState } from 'af-reducers';

export function create(form: CreateAPI.W_ItemCategory_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(CreateAPI.URL(), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(id: number, form: UpdateAPI.W_ItemCategory_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put<void>(UpdateAPI.URL(id), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function remove(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete<void>(RemoveAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.get<FindAllAPI.W_ItemCategory_FindAll_VM>(FindAllAPI.URL());
			return response.categories;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
