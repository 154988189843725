import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';

import type { ReportBlockFieldRM } from 'ab-requestModels/reportBlock/reportBlock.requestModel';

export const initDefaultCompletionFieldRMValues = (virtualId: string): ReportBlockFieldRM => {
	return {
		virtualId,
		name: 'Completed',
		index: null,
		valueType: ReportBlockFieldEnum.ValueType.BOOLEAN,
		dimension: ReportBlockFieldEnum.Dimension.VERY_SMALL,
		defaultValue: null,
		isKeyParameter: false,
		unit: null,
		isVisibleToCustomer: true,
		isRequired: false,
		isUnique: false,
		hasTooltip: false,
		fieldType: ReportBlockFieldEnum.Type.COMPLETION,
		calculatedFieldOptions: null,
		operationType: null,
		options: null,
		tooltipText: null,
		isDescriptiveTextBold: false,
		icon: null,
	};
};
