import { ICON_CLASS_NAME_REGEX } from '@acceligentllc/shared/constants/regex';

const ICON_ELEMENT_QUERY_SELECTOR = '[class^=icon-], [class*=" icon-"]';
const HTML_ENTITY_CLASS_NAME = 'icon-html-entity';

export function iconElementsToHTMLEntity(parent: HTMLElement | Document) {
	const iconElements = parent.querySelectorAll(ICON_ELEMENT_QUERY_SELECTOR);
	iconElements.forEach((_element: HTMLElement) => {
		if (_element.className.includes(HTML_ENTITY_CLASS_NAME)) {
			return;
		}
		const _content = getComputedStyle(_element, '::before').content;
		_element.className = _element.className.replace(ICON_CLASS_NAME_REGEX, HTML_ENTITY_CLASS_NAME);
		_element.innerHTML = `&#${_content.replace(/"/g, '').charCodeAt(0)};`;
	});
}
