import { isValidEmail } from '@acceligentllc/shared/utils/email';
import { isValidFormattedPhoneNumber } from '@acceligentllc/shared/utils/phone';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type UpsertTemporaryEmployeeRM from 'ab-requestModels/temporaryEmployee.requestModel';

export function validate(values: UpsertTemporaryEmployeeRM) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.firstName)) {
		errors.firstName = 'First name is required';
	}

	if (!isValidTextInput(values.lastName)) {
		errors.lastName = 'Last name is required';
	}

	if (values.email && !isValidEmail(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (values.phoneNumber && !isValidFormattedPhoneNumber(values.phoneNumber)) {
		errors.phoneNumber = 'Invalid mobile phone';
	}

	if (!values.agencyId) {
		errors.agencyId = 'Agency is required';
	}

	return errors;
}
