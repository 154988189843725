import * as React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import * as ReportBlockFieldEnums from '@acceligentllc/shared/enums/reportBlockField';

import { bemElement } from 'ab-utils/bem.util';

import { DEFAULT_FIELD_LIST_ID } from '../../values';

interface OwnProps {
	items: string[];
}

type Props = OwnProps;

const DroppableFieldList: React.FC<Props> = (props) => {
	const { items } = props;

	const renderDraggable = React.useCallback((item: ReportBlockFieldEnums.Type, index: number) => {
		const className = bemElement('report-block-form', 'draggable-label', ['uppercase']);

		return (
			<Draggable
				draggableId={item}
				index={index} // important
				key={item}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="report-block-form__draggable-field"
						ref={provided?.innerRef}
					>
						<span className={className}>{ReportBlockFieldEnums.PrimaryTypeNamed[item]}</span>
					</div>
				)}
			</Draggable>
		);
	}, []);

	return (
		<Droppable direction="vertical" droppableId={DEFAULT_FIELD_LIST_ID} isDropDisabled={true}>
			{(droppableProvided) => (
				<div ref={droppableProvided.innerRef}>
					{items.map(renderDraggable)}
					{droppableProvided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

export default React.memo(DroppableFieldList);
