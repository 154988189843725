import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { MessageDestinationTypeLabel } from '@acceligentllc/shared/enums/messageDestinationType';
import { MessageTypeLabels } from '@acceligentllc/shared/enums/messageType';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { RootState } from 'af-reducers';

import * as MessageActions from 'af-actions/message';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type TableComponent from 'af-components/Table6/Table';
import type { TabProps, Column, CellInfo, LegacyTableRef, TypedTable } from 'af-components/Table6';
import Table from 'af-components/Table6';
import DateFilter from 'af-components/DateFilter';
import DateCell from 'af-components/Table6/Cells/DateCell';
import ElipsisCell from 'af-components/Table6/Cells/ElipsisCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';

import MessageTableVM from 'ab-viewModels/message/messageTable.viewModel';

import * as SettingsUtils from 'af-utils/settings.util';
import { downloadCSV } from 'af-utils/csv.utils';
import { getTableRequestModel } from 'af-utils/table.utils';

import * as SettingsKeys from 'af-constants/settingsKeys';
import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

const CommunicationTable = Table as TypedTable<MessageTableVM>;
type CommunicationTableRef = LegacyTableRef<MessageTableVM>;

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = CustomRouteComponentProps & SettingProps & ConnectedProps<typeof connector>;

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.COMMUNICATION_HISTORY_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.COMMUNICATION_HISTORY_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.COMMUNICATION_HISTORY_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const CommunicationHistoryTable: React.FC<Props> = (props: Props) => {
	const { findMessagesForTable, companyName, history, location: { state: { orgAlias } } } = props;

	const tableRef = React.useRef<TableComponent<MessageTableVM>>(null);

	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);

	const columns: Column<MessageTableVM>[] = [
		{
			Header: 'Sent at',
			accessor: 'sentAt',
			Cell: ({ original }: CellInfo<MessageTableVM>) => (
				<DateCell
					date={original.sentAt}
					dateSourceFormat={TimeFormatEnum.ISO_DATETIME}
					format={TimeFormatEnum.FULL_DATE}
					isLeftAligned
				/>
			),
		},
		{
			Header: 'Sender',
			accessor: 'senderFullName',
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return (
					<ElipsisCell
						message={
							original.isAutomatic
								? 'AUTOMATIC'
								: original.senderFullName ?? 'N/A'
						}
						showToolTip
					/>
				);
			},
		},
		{
			Header: 'Type',
			accessor: 'destinationType',
			Cell: ({ original }: CellInfo<MessageTableVM>) => MessageDestinationTypeLabel[original.destinationType],
		},
		{
			Header: 'Destination',
			accessor: 'destination',
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return (
					<ElipsisCell
						message={original.destination ?? 'N/A'}
						showToolTip
					/>
				);
			},
		},
		{
			Header: 'Message Type',
			accessor: 'messageType',
			width: 200,
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return (
					<ElipsisCell
						message={MessageTypeLabels[original.messageType]}
						showToolTip
					/>
				);
			},
		},
		{
			Header: 'Message Status',
			sortable: false,
			accessor: 'currentStatus',
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return (
					<div>
						<div>{original.currentStatus.status}</div>
						<DateCell
							date={original.currentStatus.createdAt}
							dateSourceFormat={TimeFormatEnum.ISO_DATETIME}
							format={TimeFormatEnum.FULL_DATE}
						/>
					</div>
				);
			},
		},
		{
			Header: 'User Group',
			accessor: 'userGroupName',
			Cell: ({ original }: CellInfo<MessageTableVM>) => original.userGroupName ?? <EmptyCell />,
		},
		{
			Header: 'Content',
			accessor: 'content',
			className: 'selectable-text-cell',
			width: 150,
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return (
					<ElipsisCell
						message={original.content}
						showToolTip
					/>
				);
			},
		},
		{
			Header: 'Recipient',
			accessor: 'recipient',
			Cell: ({ original }: CellInfo<MessageTableVM>) => {
				return original.recipient ?? <EmptyCell />;
			},
		},
	];

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
		refresh();
	}, [startDate, endDate, period]);

	const refresh = () => {
		tableRef?.current?.refreshTable();
	};

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const findAllTable = React.useCallback(async (tableRequestModel: TableQuery) => {
		return await findMessagesForTable(tableRequestModel, startDate, endDate);
	}, [findMessagesForTable, startDate, endDate]);

	const onRowClick = ({ original }: CellInfo<MessageTableVM>) => {
		if (original?.id && companyName) {
			history.push(CLIENT.COMPANY.COMMUNICATION.MESSAGE_PREVIEW(original.id.toString(), orgAlias, companyName));
		} else {
			throw new Error('Missing id or company name');
		}
	};

	const onDownloadCSVClick = async () => {
		if (tableRef?.current?.state) {
			const tableRequestModel = getTableRequestModel(tableRef.current.state);

			const result = await findMessagesForTable(tableRequestModel, startDate, endDate);
			downloadCSV(MessageTableVM.toCSVData(result.rows), `${companyName}_messages.csv`);
		} else {
			throw new Error('Table state not initialized');
		}
	};

	const renderFilter = () => {
		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	};

	const tabs = (): TabProps<MessageTableVM>[] => {
		const tabProps: TabProps<MessageTableVM> = {
			label: 'Communication history',
			buttons: [
				{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: onDownloadCSVClick,
				},
			],
			columns: columns,
			onRowClick: onRowClick,
			hasSearchInput: true,
			searchLabel: 'Communication history',
			additionalFilter: renderFilter,
			fetch: findAllTable,
		};

		return [tabProps];
	};

	return (
		<>
			<CommunicationTable
				ref={tableRef as unknown as CommunicationTableRef}
				tableName={TableNameEnum.COMMUNICATION_HISTORY}
				tabs={tabs()}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findMessagesForTable: MessageActions.findMessagesForTable,
	};
}

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const enhance = compose<React.ComponentClass>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.COMMUNICATION_HISTORY_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.COMMUNICATION_HISTORY_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.COMMUNICATION_HISTORY_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	withRouter,
	connector
);

export default enhance(CommunicationHistoryTable);
