import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import Radio from 'af-components/Controls/Radio';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	canSubmitToManagement: boolean;
	canSubmitToAccounting: boolean;
	closeModal: () => void;
	currentLevel: WorkOrderReviewLevel;
	onConfirm: (reviewLevel: WorkOrderReviewLevel) => void;
	showModal: boolean;
	title: string;
}

type Props = OwnProps;

const SUPERINTENDENT_LABEL = 'Submit to superintendent for review';
const MANAGEMENT_LABEL = 'Approve and submit to management';
const ACCOUNTING_LABEL = 'Approve and submit to accounting';

interface SubmitToType {
	label: string;
	isChecked: () => boolean;
	shouldDisplay: () => boolean;
	onCheck: () => void;
}

const SubmitToModalBody: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, title, canSubmitToAccounting, canSubmitToManagement, onConfirm, currentLevel } = props;

	const [submitToLevel, setSubmitToLevel] = React.useState<WorkOrderReviewLevel>(currentLevel < 3 ? currentLevel + 1 : currentLevel);

	const checkSubmitToSI = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_1), [setSubmitToLevel]);

	const checkSubmitManagementLevel = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_2), [setSubmitToLevel]);

	const checkSubmitAccountingLevel = React.useCallback(() => setSubmitToLevel(WorkOrderReviewLevel.LEVEL_3), [setSubmitToLevel]);

	const handleOnConfirm = React.useCallback(() => {
		onConfirm(submitToLevel);
	}, [onConfirm, submitToLevel]);

	const submitToLevelObjects: SubmitToType[] = React.useMemo(() => [
		{
			label: SUPERINTENDENT_LABEL,
			isChecked: () => submitToLevel === WorkOrderReviewLevel.LEVEL_1,
			shouldDisplay: () => currentLevel < WorkOrderReviewLevel.LEVEL_1,
			onCheck: checkSubmitToSI,
		},
		{
			label: MANAGEMENT_LABEL,
			isChecked: () => submitToLevel === WorkOrderReviewLevel.LEVEL_2,
			shouldDisplay: () => currentLevel <= WorkOrderReviewLevel.LEVEL_2 && canSubmitToManagement,
			onCheck: checkSubmitManagementLevel,
		},
		{
			label: ACCOUNTING_LABEL,
			isChecked: () => submitToLevel === WorkOrderReviewLevel.LEVEL_3,
			shouldDisplay: () => currentLevel <= WorkOrderReviewLevel.LEVEL_3 && canSubmitToAccounting,
			onCheck: checkSubmitAccountingLevel,
		},
	], [
		canSubmitToAccounting,
		canSubmitToManagement,
		checkSubmitAccountingLevel,
		checkSubmitManagementLevel,
		checkSubmitToSI,
		currentLevel,
		submitToLevel,
	]);

	const renderSubmitCheckbox = (levelObject: SubmitToType) => {
		if (!levelObject.shouldDisplay()) {
			return null;
		}

		return (
			<div className="report-submit-modal__radio">
				<Radio
					checked={levelObject.isChecked()}
					inline={true}
					label={levelObject.label}
					onCheck={levelObject.onCheck}
				/>
			</div>
		);
	};

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={title}
			/>
			<CustomModal.Body>
				<div className="report-submit-modal__title">Submit report as:</div>
				{submitToLevelObjects.map(renderSubmitCheckbox)}
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<Button
					disabled={!submitToLevel}
					label="Continue"
					onClick={handleOnConfirm}
					style="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(SubmitToModalBody);
