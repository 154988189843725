import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import StatusIcon from 'af-components/StatusIcon';

import type { WorkOrderForm } from 'ab-requestModels/workOrder.requestModel';

import type { Props as FSNLinkWithStatusProps } from './FSN/FSNLinkWithStatus';
import FSN from './FSN';

interface OwnProps extends CustomRouteComponentProps {
	submitDisabled: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class OrdersFSN extends React.Component<Props> {

	static getSearchText(_order: WorkOrderForm) {
		const searchText = [_order.crewTypeId, _order.customCrewType];
		return searchText.join(' ');
	}

	static renderStatusIcon(status: WorkOrderStatus, firstRevision: boolean, notificationsEnabled: boolean, notificationStatus?: NotificationStatusEnum) {

		return (
			<StatusIcon
				firstRevision={firstRevision}
				isSmall={true}
				notificationsEnabled={notificationsEnabled}
				notificationStatus={notificationStatus}
				status={status}
			/>
		);
	}

	links = (): FSNLinkWithStatusProps[] => {
		const { orders, isFetching } = this.props;

		if (!orders?.length && isFetching) {
			return Array(7).fill(1).map((item, index) => ({
				isLoading: true,
				hash: `${index}`,
				label: `${index}`,
				sublabel: `${index}`,
				searchText: '',
				visibleFor: PagePermissions.COMPANY.WORK_ORDERS,
			} as FSNLinkWithStatusProps));
		}

		return (orders ?? []).map((_order: WorkOrderForm) => {
			return {
				hash: _order.code,
				label: _order.job.title,
				sublabel: _order.code,
				searchText: OrdersFSN.getSearchText(_order),
				visibleFor: PagePermissions.COMPANY.WORK_ORDERS,
			} as FSNLinkWithStatusProps;
		});
	};

	render() {
		const { companyData, userRole, orders } = this.props;
		const ordersLength = orders?.length ?? 0;

		return (
			<FSN
				hasStates={false}
				infoLabel={`Work Orders (${ordersLength})`}
				isCompanyAdmin={!!companyData?.isCompanyAdmin}
				links={this.links()}
				permissions={companyData?.permissions ?? []}
				scrollLabel="none"
				searchable={true}
				userRole={userRole}
				withStatus={true}
			/>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData }, company: { company } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyData,
		orders: state.workOrder.workOrders.list,
		isFetching: state.http.isFetching,
		notificationsEnabled: company?.notification?.isEnabled ?? false,
		userRole: userData.role,
	};
}

const connector = connect(mapStateToProps);

export default connector(OrdersFSN);
