import type WorkRequestBidStatus from '@acceligentllc/shared/enums/workRequestBidStatus';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import baseRoute from '../baseRoute';

export * from './viewModel';
export * from './queryParams';

type RouteDefinition = `${typeof baseRoute}/table${`?query=${string}${`&bidStatus=${string}` | ''}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/table`;

export const URL = (tableRequestModel: TableQuery, bidStatus: WorkRequestBidStatus): RouteDefinition =>
	`${baseRoute}/table?query=${encodeURIComponent((JSON.stringify(tableRequestModel)))}${bidStatus ? `&bidStatus=${bidStatus}` : ''}`;
