import type ItemCategoryBase from 'ab-domain/models/itemCategory/base';

export class W_ItemCategory_FindAll_VM {
	categories: W_ItemCategory_FindAll_VM_Single[];

	constructor(categories: ItemCategoryBase[]) {
		this.categories = W_ItemCategory_FindAll_VM_Single.bulkConstructor(categories);
	}
}

class W_ItemCategory_FindAll_VM_Single {
	id: number;
	name: string;

	constructor(category: ItemCategoryBase) {
		this.id = category.id;
		this.name = category.name;
	}

	private static _mapConstructor(category: ItemCategoryBase) {
		return new W_ItemCategory_FindAll_VM_Single(category);
	}

	static bulkConstructor(categories: ItemCategoryBase[]) {
		return categories.map(W_ItemCategory_FindAll_VM_Single._mapConstructor);
	}
}
