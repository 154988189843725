import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { StaticContext } from 'react-router';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { Row } from 'react-bootstrap';

import AccountPermission from '@acceligentllc/shared/enums/accountPermission';
import AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';

import { AccountPermissionFlags, AccountPermissionTemplateOptions, AccountPermissionTemplates } from 'ab-enums/accountPermissionTemplates.enum';

import RadioGroup from 'af-fields/RadioGroup';

import Breadcrumbs from 'af-components/Breadcrumbs';

import { validate } from './validations';

import * as AccountActions from 'af-actions/accounts';
import * as UserActions from 'af-actions/users';
import * as LocationActions from 'af-actions/location';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import { MEMBER_INVITE } from 'af-constants/reduxForms';

import type { ExistingUserFM } from '../Form/formModel';
import FormModel from '../Form/formModel';

import MemberForm from '../Form';
import ExistingMember from '../Form/ExistingMember';
import MemberInfo from '../Form/UserInfo';

interface CustomRouteState { orgAlias: string; from: string; }

type OwnProps = CustomRouteComponentProps<void, StaticContext, CustomRouteState>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormOwnProps & InjectedFormProps<FormModel>;

interface ImageType {
	image: Nullable<File | string>;
	imageUrl: Nullable<string>;
	deleteImage: boolean;
}

const InviteCompanyMembers: React.FC<Props> = (props) => {
	const {
		change,
		initialize,
		isInviteExisting,
		isCompanyAdmin,
		permissions,
		location: { state: { orgAlias, from } },
		companyName,
		submitting,
		invalid,
		invalidEmail,
		invalidPhoneNumber,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		accountTemplate,
		handleSubmit,
		fetchMissingUsers,
		findAllLocations,
		history,
		invite,
		create,
	} = props;
	const [changedAccountTemplate, setChangedAccountTemplate] = React.useState(false);
	const [{ image, imageUrl, deleteImage }, setImage] = React.useState<ImageType>(
		{ image: null, imageUrl: null, deleteImage: false }
	);

	React.useEffect(() => {
		initialize({ activeInLMS: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeState = React.useCallback(() => {
		setChangedAccountTemplate(true);
		if (from === CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST()) {
			const fielWorkerTemplate = AccountPermissionTemplateOptions.find((_option) => _option.id === AccountPermissionTemplate.FIELD_WORKER);
			change('accountTemplateId', fielWorkerTemplate!.id);
			change('accountTemplate', fielWorkerTemplate);

			AccountPermissionFlags[fielWorkerTemplate!.id].forEach((_flag) => {
				change(_flag, true);
			});

			AccountPermissionTemplates[fielWorkerTemplate!.id].forEach((_permission) => {
				change(`permissions.${AccountPermission[_permission]}`, true);
			});

			change('assignableAsAccounting', false);
			change('assignableAsProjectManager', false);
			change('assignableAsSuperintendent', false);
			change('assignableAsQAQC', false);
			change('assignableAsManagement', false);
			change('assignableAsTechnician', false);
		}
	}, [change, from]);

	React.useEffect(() => {
		if (accountTemplate === undefined && !changedAccountTemplate) {
			changeState();
		}
	}, [accountTemplate, changeState, changedAccountTemplate]);

	const goBack = React.useCallback(() => {
		history.goBack();
	}, [history]);

	const submit = React.useCallback(async (data: FormModel) => {
		if (isInviteExisting) {
			await invite(FormModel.toInviteRequestModel(data));
		} else {
			const form = FormModel.toCreateRequestModel(data);
			form.imageUrl = deleteImage ? null : image as string;
			form.deleteImage = deleteImage;
			await create(form);
		}

		goBack();
	}, [create, deleteImage, goBack, image, invite, isInviteExisting]);

	const removeImage = React.useCallback(() => {
		setImage({ image: null, imageUrl: null, deleteImage: true });
	}, []);

	const changeFormType = React.useCallback((field: string, value: NonNullable<FormModel['inviteOption']>['value']) => {
		initialize({ activeInLMS: true });
		change(field, value);
	}, [change, initialize]);

	const existingUserChanged = React.useCallback((selectedUser: ExistingUserFM) => {
		change('activeInLMS', selectedUser.activeInLMS);
	}, [change]);

	const onImageUploadSave = React.useCallback((img: File, imgUrl: string) => {
		setImage({ image: img, imageUrl: imgUrl, deleteImage: true });
	}, []);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={
					[
						{ label: 'Users', url: CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE(orgAlias, companyName) },
						{ label: 'Add User' },
					]
				}
			/>
			<div className="form-box">
				<Row className="row--padded">
					<RadioGroup
						changeField={changeFormType}
						field="inviteOption.value"
						initialValue={FormModel.CREATE_NEW_USER_OPTION.value}
						inline={true}
						items={FormModel.INVITE_OPTIONS}
					/>
				</Row>
			</div>
			<MemberForm
				accountTemplate={accountTemplate}
				activeInLMS={true}
				assignableAsAccounting={assignableAsAccounting}
				assignableAsManagement={assignableAsManagement}
				assignableAsSuperintendent={assignableAsSuperintendent}
				assignableAsTechnician={assignableAsTechnician}
				assignableToWorkOrder={assignableToWorkOrder}
				change={change}
				invalid={invalid}
				invalidEmail={invalidEmail}
				invalidPhoneNumber={invalidPhoneNumber}
				isCompanyAdmin={isCompanyAdmin}
				onCancel={goBack}
				onSubmit={handleSubmit(submit)}
				permissions={permissions}
				showActivateImmediately={true}
				submitting={submitting}
			>
				{isInviteExisting ? (
					<ExistingMember
						loadLocations={findAllLocations}
						loadUsers={fetchMissingUsers}
						userChanged={existingUserChanged}
					/>
				) : (
					<MemberInfo
						deleteImage={removeImage}
						image={image}
						imageUrl={imageUrl}
						isEmailInvalid={invalidEmail}
						isPhoneNumberInvalid={invalidPhoneNumber}
						loadLocations={findAllLocations}
						onImageUploadSave={onImageUploadSave}
					/>
				)}
			</MemberForm>
		</div>
	);
};

const selector = formValueSelector(MEMBER_INVITE);
const errorSelector = getFormSyncErrors(MEMBER_INVITE);

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	const {
		inviteOption,
		permissions,
		user,
		existingUser,
		accountTemplateId,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
	} = selector(
		state,
		'inviteOption',
		'permissions',
		'user',
		'existingUser',
		'accountTemplateId',
		'assignableToWorkOrder',
		'assignableAsSuperintendent',
		'assignableAsAccounting',
		'assignableAsManagement',
		'assignableAsTechnician'
	) as FormModel;

	const phoneNumber = user?.phoneNumber ?? existingUser?.phoneNumber;
	const email = user?.email ?? existingUser?.email;
	const { user: userErrors } = errorSelector(state) as FormModel;
	return {
		companyName: companyData.name,
		isInviteExisting: inviteOption?.value === FormModel.INVITE_USER_OPTION.value,
		invalidEmail: !email || !!userErrors?.email,
		invalidPhoneNumber: !phoneNumber || !!userErrors?.phoneNumber,
		isCompanyAdmin: false,
		permissions,
		accountTemplate: accountTemplateId,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
	};
}

function mapDispatchToProps() {
	return {
		invite: AccountActions.addExisting,
		create: AccountActions.create,
		fetchMissingUsers: UserActions.fetchMissingUsers,
		findAllLocations: LocationActions.findList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<FormModel, FormOwnProps>({ form: MEMBER_INVITE, validate })
);

export default enhance(InviteCompanyMembers);
