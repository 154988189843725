import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import * as OrganizationActions from 'af-actions/organizations';
import * as AuthenticationActions from 'af-actions/authentication';

import type { OrganizationTableRowVM } from 'ab-viewModels/organization/tableRow.viewModel';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';

import CLIENT from 'af-constants/routes/client';
import { USE_WILDCARDS } from 'af-constants/values';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { getHostname } from 'af-utils/window.util';
import { createPlatformEventListenerForOrganization } from 'af-utils/platform.util';

type OwnProps = CustomRouteComponentProps;

type Props = ConnectedProps<typeof connector> & OwnProps;

class AllOrganizations extends React.Component<Props> {

	columns: Column<OrganizationTableRowVM>[] = [
		{
			Header: 'Name',
			accessor: 'name',
			Cell: ({ original }) => original.name,
		},
		{
			Header: 'Alias',
			accessor: 'alias',
			Cell: ({ original }) => original.alias,
		},
	];

	notifyAllEmployees = (original: OrganizationTableRowVM) => {
		const { history, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.PLATFORM.NOTIFY_EMPLOYEES(orgAlias, original.alias));
	};

	onRowClick = ({ original }: { original: OrganizationTableRowVM; }) => {
		const orgAlias = original.alias;
		if (!orgAlias) {
			return;
		}
		if (USE_WILDCARDS) {
			const url = `//${orgAlias}.${getHostname()}${CLIENT.AUTH.PLATFORM_ADMIN_LOGIN(orgAlias)}`;
			const orgWindow = window.open(url, '_blank');
			createPlatformEventListenerForOrganization(orgAlias, orgWindow);
		} else {
			const url = CLIENT.AUTH.LOGIN(orgAlias);
			window.open(url, '_blank');
		}
	};

	redirectToUsersTable = (original: OrganizationTableRowVM) => {
		const { history, location: { state: { orgAlias } } } = this.props;
		history.push(CLIENT.PLATFORM.USERS.LIST(orgAlias, original.alias));
	};

	tabs = (): TabProps<OrganizationTableRowVM>[] => {
		const {
			findAllOrganizations,
			location: { state: { orgAlias } },
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Client Organization',
				onClick: async () => history.push(CLIENT.PLATFORM.CREATE(orgAlias)),
			},
		];

		return [
			{
				label: 'Organizations',
				columns: this.columns,
				fetch: findAllOrganizations,
				hasSearchInput: true,
				searchLabel: 'Organizations',
				buttons,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Notify All Employees',
						action: this.notifyAllEmployees,
						shouldRefresh: false,
					},
					{
						label: 'Users Table',
						action: this.redirectToUsersTable,
						shouldRefresh: false,
					},
				],
			},
		];
	};

	breadcrumbs = () => [{ label: 'Organizations' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.PLATFORM_ORGANIZATIONS}
					tabs={this.tabs()}
				/>
			</div>
		);
	}

}

function mapDispatchToProps() {
	return {
		findAllOrganizations: OrganizationActions.findAllOrganizations,
		platformAdminOrganizationLogin: AuthenticationActions.platformAdminOrganizationLogin,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector<React.ComponentClass<OwnProps>>(AllOrganizations);
