import * as React from 'react';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import * as TimeSheetUtils from '@acceligentllc/shared/utils/timeSheet';

import type { TimeSheetInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type WorkOrderReviewRecapVM from 'ab-viewModels/workOrder/workOrderReviewRecap.viewModel';

import type ApproveWorkOrderRM from 'ab-requestModels/workOrder/approveWorkOrder.requestModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SignatureModal from '../../../Shared/SignatureModal';

import SuccessModalBody from '../Shared/SuccessModalBody';

import DigitalSignatureSaveModal from '../DigitalSignatureSaveModal';

import ApproveModal from './ApproveModal';

import SignatureForm from '../../../Shared/SignatureModal/FormModel';

interface TimeSheetsByStatus {
	signedTimeSheets: TimeSheetInfoVM[];
	unsignedTimeSheets: TimeSheetInfoVM[];
}

interface OwnProps {
	closeModal: () => void;
	fieldReports: FieldReportListItemVM[];
	isWorkOrderReadOnly: (reviewLevel: WorkOrderReviewLevel) => boolean;
	onSubmit: (form: ApproveWorkOrderRM) => Promise<void>;
	reviewLevel: WorkOrderReviewLevel;
	showModal: boolean;
	targetReviewLevel: Nullable<WorkOrderReviewLevel>;
	workOrderId: number;
	workOrderReviewRecap: Nullable<WorkOrderReviewRecapVM>;
	revertOutdated: boolean;
	isCompanyAdmin: boolean;
	isDigitalSignatureEnabled: boolean;
	showCreateDigitalSignature: boolean;
	timezone: Nullable<string>;
}

type Props = OwnProps;

const MODAL_TITLE = 'Approve Report and Time Sheets';

const APPROVE_TITLE = 'Field Reports Approved';

const APPROVE_DESCRIPTION = 'Report with all its time sheets has been approved and sent for additional review';
const LAST_APPROVE_DESCRIPTION = 'Report has been successfully approved';

const SIGNATURE_MODAL_DESCRIPTION = `
	By signing, You acknowledge that You have reviewed the information provided in this Report, and that you approve its content. 
	Report will be automatically sent to the next level for approval.
`;

const _timeSheetReducer = (acc: TimeSheetsByStatus, timeSheet: TimeSheetInfoVM): typeof acc => {
	const { signatureStatus, isSignedBySI } = timeSheet;
	if (TimeSheetUtils.isTimeSheetSigned(signatureStatus, isSignedBySI)) {
		acc.signedTimeSheets.push(timeSheet);
	} else {
		acc.unsignedTimeSheets.push(timeSheet);
	}
	return acc;
};

const _timeSheetStateSetter = (timeSheets: TimeSheetInfoVM[]) => {
	return timeSheets.reduce(
		_timeSheetReducer,
		{ signedTimeSheets: [], unsignedTimeSheets: [] } as TimeSheetsByStatus
	);
};

const _getDescription = (reviewLevel: WorkOrderReviewLevel) => {
	if (reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
		return LAST_APPROVE_DESCRIPTION;
	}
	return APPROVE_DESCRIPTION;
};

const ApproveModalHandler: React.FC<Props> = (props: Props) => {
	const {
		closeModal,
		fieldReports,
		isWorkOrderReadOnly,
		onSubmit,
		reviewLevel,
		showModal,
		targetReviewLevel,
		workOrderReviewRecap,
		revertOutdated,
		isCompanyAdmin,
		isDigitalSignatureEnabled,
		showCreateDigitalSignature,
		timezone,
	} = props;

	const [showApproveModal, setShowApproveModal] = React.useState<boolean>(showModal);
	const [showSignatureModal, setShowSignatureModal] = React.useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = React.useState<boolean>(false);
	const [showCreateDigitalSignatureModal, setShowDigitalSignatureModal] = React.useState<boolean>(false);
	const [signedTimeSheets, setSignedTimeSheets] = React.useState<TimeSheetInfoVM[]>([]);
	const [unsignedTimeSheets, setUnsignedTimeSheets] = React.useState<TimeSheetInfoVM[]>([]);
	const [signatureImage, setSignatureImage] = React.useState<Nullable<string>>(null);

	React.useEffect(() => {
		if (workOrderReviewRecap) {
			const { signedTimeSheets: _signedTimeSheets, unsignedTimeSheets: _unsignedTimeSheets } = _timeSheetStateSetter(workOrderReviewRecap.timeSheets);
			setSignedTimeSheets(_signedTimeSheets);
			setUnsignedTimeSheets(_unsignedTimeSheets);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setShowApproveModal(showModal);
		setShowSignatureModal(false);
		setShowSuccessModal(false);
		setShowDigitalSignatureModal(false);
	}, [showModal]);

	React.useEffect(() => {
		if (workOrderReviewRecap?.timeSheets) {
			const { signedTimeSheets: _signedTimeSheets, unsignedTimeSheets: _unsignedTimeSheets } = _timeSheetStateSetter(workOrderReviewRecap.timeSheets);
			setSignedTimeSheets(_signedTimeSheets);
			setUnsignedTimeSheets(_unsignedTimeSheets);
		}
	}, [workOrderReviewRecap?.timeSheets]);

	const approve = React.useCallback(async (form: SignatureForm) => {
		if (!targetReviewLevel) {
			throw new Error('Missing target review level');
		}
		await onSubmit(SignatureForm.toApproveRM(form, targetReviewLevel, revertOutdated));

		setShowSignatureModal(false);
		setShowSuccessModal(true);
		setSignatureImage(form.signatureImage);
	}, [onSubmit, targetReviewLevel, revertOutdated]);

	const openShowSignatureModal = React.useCallback(() => {
		setShowSignatureModal(true);
		setShowApproveModal(false);
	}, []);

	const onSuccessModalConfirm = React.useCallback(() => {
		setShowSuccessModal(false);
		if (!isDigitalSignatureEnabled && !isCompanyAdmin && showCreateDigitalSignature) {
			setShowDigitalSignatureModal(true);
		} else {
			closeModal();
		}
	}, [closeModal, isDigitalSignatureEnabled, showCreateDigitalSignature, isCompanyAdmin]);

	const closeDigitalSignatureModal = React.useCallback(() => {
		closeModal();
		setShowDigitalSignatureModal(false);
	}, [closeModal]);

	const renderSuccessModalBody = React.useCallback(() => {
		const newReviewLevel = targetReviewLevel ?? reviewLevel + 1;

		return (
			<SuccessModalBody
				description={_getDescription(newReviewLevel)}
				isReadOnly={isWorkOrderReadOnly(newReviewLevel)}
				reviewStatus={WorkOrderReviewStatus.APPROVED}
				title={APPROVE_TITLE}
			/>
		);
	}, [isWorkOrderReadOnly, reviewLevel, targetReviewLevel]);

	return (
		<>
			{showApproveModal &&
				<ApproveModal
					closeModal={closeModal}
					fieldReports={fieldReports}
					onConfirm={openShowSignatureModal}
					reviewLevel={reviewLevel}
					showModal={showApproveModal}
					signedTimeSheets={signedTimeSheets}
					timeSheetEntriesByAccountIdMap={workOrderReviewRecap?.timeSheetEntriesByAccountId ?? null}
					timezone={timezone}
					unsignedTimeSheets={unsignedTimeSheets}
				/>}
			{showSignatureModal &&
				<SignatureModal
					closeModal={closeModal}
					description={SIGNATURE_MODAL_DESCRIPTION}
					onSubmit={approve}
					showDigitalSignature={isDigitalSignatureEnabled}
					showModal={showSignatureModal}
					showNameInput={false}
					title={MODAL_TITLE}

				/>}
			{showSuccessModal &&
				<ConfirmationModal
					body={renderSuccessModalBody()}
					closeModal={onSuccessModalConfirm}
					confirmAction={onSuccessModalConfirm}
					confirmText="Done"
					modalStyle="info"
					showCancel={false}
					showModal={showSuccessModal}
					size="md"
					title={MODAL_TITLE}
				/>}
			{showCreateDigitalSignatureModal &&
				<DigitalSignatureSaveModal
					closeModal={closeDigitalSignatureModal}
					showModal={showCreateDigitalSignatureModal}
					signatureImage={signatureImage}
				/>}
		</>
	);
};

export default React.memo(ApproveModalHandler);
