import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import * as AuthenticationActions from 'af-actions/authentication';

import CLIENT from 'af-constants/routes/client';

import socket from 'af-utils/socket.util';
import { Button } from '@acceligentllc/storybook';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

class Logout extends React.PureComponent<Props> {

	async componentDidMount() {
		const { logout } = this.props;
		await logout();
		if (socket.connection) {
			socket.connection.disconnect();
		}
	}

	goToLogin = () => {
		const { history, location: { state: { orgAlias } } } = this.props;

		history.push(CLIENT.AUTH.LOGIN(orgAlias));
	};

	render(): JSX.Element {
		const { location: { state: { orgAlias } } } = this.props;
		return (
			<div className="form-segment form-segment--mini">
				<h3>LOGGED OUT</h3>
				<div className="form-box form-box--standalone">
					<Row className="row--padded-top">
						<p>You have been successfully logged out.</p>
						<p>
							If you would like to continue using the platform, please <Link to={CLIENT.AUTH.LOGIN(orgAlias)}>log in</Link>
						</p>
					</Row>
					<Row className="row--submit">
						<Button
							label="Log in"
							onClick={this.goToLogin}
							style="primary"
							type="button"
						/>
					</Row>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps() {
	return {
		logout: AuthenticationActions.logout,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(Logout);
