import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { WorkOrderReviewStatusLabelForPdf } from '@acceligentllc/shared/enums/reportDisplayStatus';

import type { ReportConfirmationVM, PCCompanyVM, PCJobVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import LabelWithColor from 'af-components/LabelWithColor';
import LockedValue from 'af-components/LockedValue';

import { DeviceType, useDeviceType } from './helpers';
import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	report: ReportConfirmationVM;
	company: PCCompanyVM;
	job: PCJobVM;
}

type Props = OwnProps;

const ReportHeader: React.FC<Props> = (props: Props) => {
	const deviceType = useDeviceType();

	const {
		report: {
			workOrder: {
				code,
				dueDate,
				status,
				shift,
				timeToStart,
				timeToEnd,
				crewTypeColor,
				crewTypeName,
				supervisorName,
				timeZone,
			},
		},
		job: {
			title,
			office,
			customer,
			jobAddress,
		},
	} = props;

	const isMobileOrTablet = (deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET);

	const smallerSegmentClassName = bemElement('public-report', 'segment__item', [isMobileOrTablet ? '3' : '1']);
	const largerSegmentClassName = bemElement('public-report', 'segment__item', [isMobileOrTablet ? '3' : '2']);

	return (
		<div className="public-report__report-info">
			<div className="public-report__report-info__title-row">
				<div className="public-report__report-info__title-row__title">{code}</div>
				<div className="public-report__report-info__title-row__status">{WorkOrderReviewStatusLabelForPdf[status]}</div>
				<div className="public-report__report-info__title-row__date">{TimeUtils.formatDate(dueDate, TimeFormat.DAY_SHORT_WITH_DATE, TimeFormat.DB_DATE_ONLY)}</div>
			</div>
			<div className="public-report__segment__row">
				<div className={smallerSegmentClassName}>
					<LockedValue
						label="Job No."
						value={title}
					/>
				</div>
				<div className={smallerSegmentClassName}>
					<LockedValue
						label="Customer"
						value={customer}
					/>
				</div>
				<div className={largerSegmentClassName}>
					<LockedValue
						label="Location"
						value={jobAddress ?? 'N/A'}
					/>
				</div>
				<div className={largerSegmentClassName}>
					<LockedValue
						label="Time Zone"
						value={TimeUtils.abbreviateTimeZone(timeZone, 'z (Z)')}
					/>
				</div>
			</div>
			<div className="public-report__segment__row">
				<div className={largerSegmentClassName}>
					<LockedValue
						label="Shift"
						value={`${shift}, ${timeToStart} - ${timeToEnd}`}
					/>
				</div>
				<div className={smallerSegmentClassName}>
					<LockedValue
						label="Report To"
						value={supervisorName}
					/>
				</div>
				<div className={smallerSegmentClassName}>
					<LockedValue
						label="Office"
						value={office}
					/>
				</div>
				<div className={largerSegmentClassName}>
					<LockedValue
						label="Crew"
						value={<LabelWithColor color={crewTypeColor} text={crewTypeName} />}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(ReportHeader);
