import * as React from 'react';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import { resolveBlockDisplayIndex } from 'ab-utils/fieldReport.util';

import FieldReportBlockField from './FieldReportBlockField';
import styles from './styles.module.scss';

type FieldReportBlockFieldVM = FieldReportTypeSidePanelVM['fieldReportBlocks'][0]['fieldReportBlockFields'][0];

type Props = {
	fieldReportBlock: FieldReportTypeSidePanelVM['fieldReportBlocks']['0'];
	instanceCount: number;
	segmentCount: number;
};

const renderCompletionField = (isCompleted: boolean) => {
	const completedStyles = [styles['side-panel__field'], styles['side-panel__field--completed'], styles['side-panel__field--span-two']];
	return (
		<div className={completedStyles.join(' ')}>
			<span>Completed:</span>
			<b>{isCompleted ? 'Yes' : 'No'}</b>
		</div>
	);
};

const FieldReportBlockSidePanel: React.FC<Props> = ({ fieldReportBlock, instanceCount, segmentCount }) => {
	const [showBlockFields, setShowBlockFields] = React.useState(true);

	const blockStyle = React.useMemo(() => {
		const resolvedStyles = [styles['side-panel__block']];
		const isPrimary = !fieldReportBlock.segmentIndex; // null means its upper or lower total, 0 means primary block
		if (isPrimary) {
			resolvedStyles.push(styles['side-panel__block--primary']);
		} else {
			fieldReportBlock.reportBlock.isMain
				? resolvedStyles.push(styles['side-panel__block--secondary-main'])
				: resolvedStyles.push(styles['side-panel__block--secondary']);
		}
		return resolvedStyles.join(' ');
	}, [fieldReportBlock.reportBlock.isMain, fieldReportBlock.segmentIndex]);

	const headerStyle = React.useMemo(() => {
		const resolvedStyles = [styles['side-panel__block__header']];
		if (fieldReportBlock.reportBlock.isMain) {
			resolvedStyles.push(styles['side-panel__block__header--main']);

			if (!fieldReportBlock.segmentIndex) {
				resolvedStyles.push(styles['side-panel__block__header--primary']);
			}
		}
		return resolvedStyles.join(' ');
	}, [fieldReportBlock.reportBlock.isMain, fieldReportBlock.segmentIndex]);

	const blockDisplayIndex = React.useMemo(() =>
		(fieldReportBlock.instanceIndex !== null
			&& fieldReportBlock.segmentIndex !== null
			&& fieldReportBlock.index !== null)
			? resolveBlockDisplayIndex(
				fieldReportBlock.instanceIndex ?? 0,
				fieldReportBlock.segmentIndex ?? 0,
				fieldReportBlock.index ?? 0,
				instanceCount,
				segmentCount
			)
			: '',
		[fieldReportBlock.index, fieldReportBlock.instanceIndex, fieldReportBlock.segmentIndex, instanceCount, segmentCount]);

	const reportBlockFieldsMapper = React.useCallback((_fieldReportBlockField: FieldReportBlockFieldVM) => {
		return <FieldReportBlockField fieldReportBlockField={_fieldReportBlockField} isRepeatable={false} key={_fieldReportBlockField.id} />;
	}, []);

	const fieldReportRepeatableBlockFieldsMapper = React.useCallback((repeatableIndex: number) =>
		(fieldReportBlockField: FieldReportBlockFieldVM) => {
			return (
				<FieldReportBlockField fieldReportBlockField={fieldReportBlockField} isRepeatable={true} repeatableIndex={repeatableIndex} />
			);
		}, []);

	const onBlockHeaderClick = React.useCallback(() => {
		setShowBlockFields(!showBlockFields);
	}, [showBlockFields]);

	const title = React.useMemo(() => `${fieldReportBlock.reportBlock.name} ${blockDisplayIndex ?? ''}`, [blockDisplayIndex, fieldReportBlock.reportBlock.name]);
	const iconName = showBlockFields ? 'icon-up' : 'icon-down';

	if (fieldReportBlock.reportBlock.isRepeating) {
		const repeatableBlocks: JSX.Element[] = [];
		for (let index = 0; index < fieldReportBlock.repeatingCount; index++) {
			repeatableBlocks.push(
				<div className={styles['side-panel__block__body']} key={`${fieldReportBlock.id}#${index}`} >
					{fieldReportBlock.fieldReportBlockFields.map(fieldReportRepeatableBlockFieldsMapper(index))}
				</div>
			);
		}

		if (fieldReportBlock.reportBlock.hasCompletionStatus) {
			repeatableBlocks.push((
				<div className={styles['side-panel__block__body']} key={`${fieldReportBlock.id}#completed`} >
					{renderCompletionField(fieldReportBlock.completed)}
				</div>
			));
		}

		return (
			<div className={blockStyle}>
				<div className={headerStyle} onClick={onBlockHeaderClick}>
					{title}
					{<span className={iconName} />}
				</div>
				{showBlockFields && (
					<div className={styles['side-panel__block']}>
						{repeatableBlocks}
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={blockStyle}>
			<div className={headerStyle} onClick={onBlockHeaderClick}>
				{title}
				{<span className={iconName} />}
			</div>
			{showBlockFields && (
				<div className={styles['side-panel__block__body']}>
					{fieldReportBlock.fieldReportBlockFields.map(reportBlockFieldsMapper)}
					{fieldReportBlock.reportBlock.hasCompletionStatus && renderCompletionField(fieldReportBlock.completed)}
				</div>
			)}
		</div>
	);
};

export default React.memo(FieldReportBlockSidePanel);
