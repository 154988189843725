import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import CLIENT from 'af-constants/routes/client';

const Error404: React.FC = () => {

	const location = useLocation();
	const navigate = useNavigate();
	const orgAlias = location.state.orgAlias;

	const goToLogin = React.useCallback(() => {
		navigate(CLIENT.HOME(orgAlias));
	}, [navigate, orgAlias]);

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon icon-not_found" />
				</div>
				<h2>Ooops! Looks like the page is gone...</h2>
				<h4 className="light">Sorry, but the page you are looking for has not been found!</h4>
				<div className="form-box__footer">
					<Button
						label="Back to Home"
						onClick={goToLogin}
						style="primary"
						type="button"
					/>
				</div>
			</div>
		</div>
	);

};

export default Error404;
