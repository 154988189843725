import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as WorkRequestActions from 'af-actions/workRequests/index';

import CustomModal from 'af-components/CustomModal';
import Checkbox from 'af-components/Fields/Checkbox';
import SubmitButton from 'af-components/SubmitButton';

import { CONVERT_TO_JOB } from 'af-constants/reduxForms';

import Input from 'af-fields/Input';

import type { ConvertToJobForm } from '../Form/helpers';
import { validateConvertToJobForm } from '../Form/helpers';

import styles from './styles.module.scss';

interface OwnProps {
	closeModal: () => void;
	showModal: boolean;
	currentJobCode: string;
	workRequestId: number;
	onConvert: (convertedToJobId: string, convertedToJobCode?: string) => void;
}

type Props = OwnProps & InjectedFormProps<ConvertToJobForm> & ConnectedProps<typeof connector>;

const WorkRequestConvertToJobModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		showModal,
		currentJobCode,
		valid,
		handleSubmit,
		initialize,
		submitting,
		convertToJob,
		workRequestId,
		onConvert,
	} = props;

	React.useEffect(() => {
		initialize({ jobCode: currentJobCode, keepAttachments: false });
	}, [currentJobCode, initialize]);

	const handleConvert = React.useCallback(async (form: ConvertToJobForm) => {
		const response = await convertToJob(workRequestId, form);
		onConvert(response.convertedToJobId.toString(), response.convertedToJobCode);
	}, [convertToJob, onConvert, workRequestId]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={`Convert ${currentJobCode} into a Job?`}
			/>
			<CustomModal.Body>
				<div className={styles['copy-convert-modal']}>
					<div className={styles['copy-convert-modal__info']}>
						Original Work Request will still be accessible as a read-only page.
					</div>
					<div className={styles['copy-convert-modal__job-id']}>
						<Field
							component={Input}
							defaultValue={currentJobCode}
							label="Job ID *"
							name="jobCode"
							placeholder="Enter Job ID"
						/>
					</div>
					<Field
						component={Checkbox}
						id="keepAttachments"
						label="Copy Attachments to new Job"
						name="keepAttachments"
					/>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<SubmitButton
					disabled={!valid}
					label="Convert"
					onClick={handleSubmit(handleConvert)}
					reduxFormSubmitting={submitting}
					variant="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		convertToJob: WorkRequestActions.convertToJob,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	reduxForm({
		form: CONVERT_TO_JOB,
		validate: validateConvertToJobForm,
	}),
	React.memo
);

export default enhance(WorkRequestConvertToJobModal);
