import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

interface OwnProps {
	startTime: Nullable<string>;
	endTime: Nullable<string>;
	disableStartEndValidation: boolean;
	hasOverlaps: boolean;
	showSignatureModal: () => void;
}

const SignButton = (props: OwnProps) => {
	const { disableStartEndValidation, startTime, endTime, hasOverlaps, showSignatureModal } = props;

	if (!disableStartEndValidation && (!startTime || !endTime)) {
		let message: string | undefined = undefined;
		if (!startTime && !endTime) {
			message = 'Time Sheet is empty';
		} else if (!endTime) {
			message = 'End time is empty';
		}

		return (
			<Button
				disabled={true}
				label="Sign My Time Sheet"
				onClick={showSignatureModal}
				style="secondary"
				tooltip={message}
			/>
		);
	}

	return (
		<Button
			disabled={hasOverlaps}
			label="Sign My Time Sheet"
			onClick={showSignatureModal}
			style="secondary"
		/>
	);
};

export default SignButton;
