import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { filterMap } from '@acceligentllc/shared/utils/array';
import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import type { TimeSheetEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import { bemBlock } from 'ab-utils/bem.util';

import { useToggle } from 'af-utils/react.util';

import { TimeSheetEntryFM } from './formModel';
import TimeSheetEntry from './TimeSheetEntry';
import { calculateTotalTimes } from './helpers';

interface OwnProps {
	dueDate: string;
	canEdit: boolean;
	inEditMode: boolean;
	overlap: Record<string, OverlapMeta>;
	startEditing: () => void;
	endEditing: () => void;
	update: (form: TimeSheetEntryRM[]) => void;
	change: (fieldName: string, value: string) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<TimeSheetEntryFM>;

const filterEntries = (entry: TimeSheetEntryFM) => !!entry.id || (!!entry.startDate && !!entry.startTime && !!entry.endTime && !!entry.workType);

const TimeSheetArray: React.FC<Props> = (props) => {
	const {
		fields,
		dueDate,
		canEdit,
		inEditMode,
		startEditing,
		endEditing,
		update,
		change,
		overlap,
	} = props;
	const {
		value: expanded,
		toggle: toggleExpand,
	} = useToggle(false);

	const sheetClassName = bemBlock('condensed-table__employee-list-sheet', { expanded });
	const entriesClassName = bemBlock('condensed-table__employee-list-sheet__entries', { readonly: !inEditMode || !fields.length });

	const updateSheet = React.useCallback((entries: TimeSheetEntryFM[]) => {
		update(filterMap(entries, filterEntries, TimeSheetEntryFM.toRequestModel));
	}, [update]);

	const onValueChange = React.useCallback((index: number, entry: TimeSheetEntryFM) => {
		const entries = fields.getAll();
		entries.splice(index, 1, entry);
		updateSheet(entries);
	}, [fields, updateSheet]);

	const add = React.useCallback(() => {
		fields.push({ startTime: null, endTime: null } as TimeSheetEntryFM);
		startEditing();
	}, [fields, startEditing]);

	const remove = React.useCallback((index: number) => {
		fields.remove(index);
		const entries = fields.getAll();
		const removed = entries.splice(index, 1);
		if (filterEntries(removed[0])) {
			updateSheet(entries);
		}

		if (!entries.length) {
			endEditing();
		}
	}, [fields, updateSheet, endEditing]);

	const toggleEdit = React.useCallback(() => {
		if (inEditMode) {
			endEditing();
		} else {
			startEditing();
		}
	}, [inEditMode, startEditing, endEditing]);

	const renderEntry = (_entryName: string, _index: number) => {
		const entry = fields.get(_index);

		return (
			<TimeSheetEntry
				canEdit={canEdit}
				change={change}
				dueDate={dueDate}
				entry={entry}
				field={_entryName}
				index={_index}
				inEditMode={inEditMode}
				key={entry.id ?? _index}
				onToggleEdit={toggleEdit}
				onValueChange={onValueChange}
				overlap={overlap[entry.id ?? `created_${_index}`]}
				remove={remove}
			/>
		);
	};

	const renderAddEntry = () => {
		return (
			<div className="condensed-table__add-row">
				<div className="condensed-table__add-row-button" onClick={add}>
					<span className="icon-plus" />
					<span>Add time entry</span>
				</div>
			</div>
		);
	};

	const totals = React.useMemo(() => {
		const entries = fields.getAll() ?? [];
		const summedTimes = entries.reduce(calculateTotalTimes, {
			totalJobDuration: 0,
			totalShopDuration: 0,
			totalTravelDuration: 0,
			totalBreakDuration: 0,
		});

		const totalJobDuration = TimeUtils.roundTimeDurationToInterval(summedTimes.totalJobDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalShopDuration = TimeUtils.roundTimeDurationToInterval(summedTimes.totalShopDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalTravelDuration = TimeUtils.roundTimeDurationToInterval(summedTimes.totalTravelDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const totalBreakDuration = TimeUtils.roundTimeDurationToInterval(summedTimes.totalBreakDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		const total = totalJobDuration + totalShopDuration + totalTravelDuration;

		return {
			total,
			totalJobDuration,
			totalShopDuration,
			totalTravelDuration,
			totalBreakDuration,
		};
	}, [fields]);

	return (
		<div className={sheetClassName}>
			<div className={`icon-${expanded ? 'collapse' : 'expand'}`} onClick={toggleExpand} />
			<div className="bold condensed-table__employee-list-sheet__total">{TimeUtils.minutesToHoursAndMinutes(totals.total)}</div>
			<div className="condensed-table__employee-list-sheet__job">{TimeUtils.minutesToHoursAndMinutes(totals.totalJobDuration)}</div>
			<div className="condensed-table__employee-list-sheet__shop">{TimeUtils.minutesToHoursAndMinutes(totals.totalShopDuration)}</div>
			<div className="condensed-table__employee-list-sheet__travel">{TimeUtils.minutesToHoursAndMinutes(totals.totalTravelDuration)}</div>
			<div className="condensed-table__employee-list-sheet__break">{TimeUtils.minutesToHoursAndMinutes(totals.totalBreakDuration)}</div>
			{expanded && (
				<div className={entriesClassName}>
					{fields.map(renderEntry)}
					<div />
					{canEdit && (inEditMode || !fields.length) && renderAddEntry()}
				</div>
			)}
		</div>
	);
};

export default TimeSheetArray;
