import PurchaseOrderStatus from '@acceligentllc/shared/enums/purchaseOrderStatus';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { getUserName } from '@acceligentllc/shared/utils/user';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import PurchaseOrderItemCompletion from '@acceligentllc/shared/enums/purchaseOrderItemCompletion';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import type PurchaseOrdersTableBase from 'ab-domain/views/purchaseOrdersTable/base';

export class W_PurchaseOrder_FindPurchaseOrderTable_VM extends TableContent<W_PurchaseOrder_FindPurchaseOrderTable_VM_Row>{
	constructor(purchaseOrders: PurchaseOrdersTableBase[], pages: number, totalCount: number) {
		super(
			W_PurchaseOrder_FindPurchaseOrderTable_VM_Row.bulkConstructor(purchaseOrders),
			pages,
			totalCount
		);
	}
}

class W_PurchaseOrder_FindPurchaseOrderTable_VM_Row {
	id: number;
	status: PurchaseOrderStatus;
	orderNumber: string;
	/** MM-DD-YYYY */
	dateCreated: string;
	/** MM-DD-YYYY */
	dateSubmitted: Nullable<string>;
	vendorName: string;
	equipmentCode: Nullable<string>;
	jobCode: Nullable<string>;
	itemCategory: Nullable<string>;
	locationName: Nullable<string>;
	totalCost: number;
	requestedBy: string;
	submittedBy: Nullable<string>;
	itemsReceived: PurchaseOrderItemCompletion;
	itemsInvoiced: PurchaseOrderItemCompletion;
	purchaseOrderItems: W_PurchaseOrder_FindPurchaseOrderTable_VM_Item[];

	constructor(po: PurchaseOrdersTableBase) {
		this.id = po.id;
		this.status = po.status;
		this.orderNumber = po.orderNumber;
		this.dateCreated = TimeUtils.formatDate(po.createdAt, TimeFormat.DATE_ONLY);
		this.dateSubmitted = po.dateSubmitted ? TimeUtils.formatDate(po.dateSubmitted, TimeFormat.DATE_ONLY) : null;
		this.vendorName = po.vendor.name;
		this.equipmentCode = po.equipment ? po.equipment.code : null;
		this.jobCode = po.workRequest ? po.workRequest.jobCode : null;
		this.itemCategory = po.itemCategory?.name ?? null;
		this.locationName = po.location?.nickname ?? null;
		this.totalCost = po.totalCost;
		this.requestedBy = getUserName(po.requestedBy?.user);
		this.submittedBy = po.status !== PurchaseOrderStatus.UNFINISHED ? getUserName(po.submittedBy?.user) : null;
		this.itemsReceived = po.purchaseOrderItems.every((poi) => poi.itemsReceived === PurchaseOrderItemCompletion.ALL)
			? PurchaseOrderItemCompletion.ALL
			: po.purchaseOrderItems.every((poi) => poi.itemsReceived === PurchaseOrderItemCompletion.NONE)
				? PurchaseOrderItemCompletion.NONE
				: PurchaseOrderItemCompletion.PARTIALLY;
		this.itemsInvoiced = po.purchaseOrderItems.every((poi) => poi.itemsInvoiced === PurchaseOrderItemCompletion.ALL)
			? PurchaseOrderItemCompletion.ALL
			: po.purchaseOrderItems.every((poi) => poi.itemsInvoiced === PurchaseOrderItemCompletion.NONE)
				? PurchaseOrderItemCompletion.NONE
				: PurchaseOrderItemCompletion.PARTIALLY;
		this.purchaseOrderItems = W_PurchaseOrder_FindPurchaseOrderTable_VM_Item.bulkConstructor(po.purchaseOrderItems);
	}

	private static _constructorMap = (po: PurchaseOrdersTableBase) => new W_PurchaseOrder_FindPurchaseOrderTable_VM_Row(po);

	static bulkConstructor = (pos: PurchaseOrdersTableBase[]) => pos.map(this._constructorMap);
}

class W_PurchaseOrder_FindPurchaseOrderTable_VM_Item {
	id: number;
	quantity: number;
	name: string;

	constructor(poi: PurchaseOrdersTableBase['purchaseOrderItems'][number]) {
		this.id = poi.id;
		this.quantity = poi.quantity;
		this.name = poi.name;
	}

	private static _constructorMap = (poi: PurchaseOrdersTableBase['purchaseOrderItems'][number]) => new W_PurchaseOrder_FindPurchaseOrderTable_VM_Item(poi);

	static bulkConstructor = (pois: PurchaseOrdersTableBase['purchaseOrderItems']) => pois.map(this._constructorMap);
}
