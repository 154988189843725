import * as React from 'react';

import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import * as ReportBlockField from '@acceligentllc/shared/enums/reportBlockField';
import { ExtendedColorPalette } from '@acceligentllc/shared/enums/color';
import RepeatableBlockType from '@acceligentllc/shared/enums/repeatableBlockType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';

import type { SignatureFieldVM } from '@acceligentllc/shared/utils/fieldReport';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import LockedValue from 'af-components/LockedValue';

import { displayValue } from './helpers';
import { DeviceType, useDeviceType } from '../helpers';

import SignatureStatusLabel from '../../../Shared/SignatureStatusLabel';

interface OwnProps {
	name: string;
	value?: string;
	isImage: boolean;
	unit: Nullable<QuantityUnitType>;
	dimension: ReportBlockField.Dimension;
	repeatableBlockType?: Nullable<RepeatableBlockType>;
	renderLabel?: boolean;
	renderValue?: boolean;
	inverse?: boolean;
	fieldType: ReportBlockField.Type;
	descriptiveTextColor: ExtendedColorPalette;
	isDescriptiveTextBold: boolean;
}

type Props = OwnProps;

const Field: React.FC<Props> = (props: Props) => {
	const deviceType = useDeviceType();
	const {
		name,
		value,
		isImage,
		unit,
		dimension,
		fieldType,
		descriptiveTextColor,
		isDescriptiveTextBold,
		repeatableBlockType,
		renderLabel = true,
		renderValue = true,
		inverse = false,
	} = props;

	const tableType = repeatableBlockType === RepeatableBlockType.INLINE_TABLE
		? 'inline'
		: 'appendix';

	let itemClass = !!repeatableBlockType && repeatableBlockType === RepeatableBlockType.DEFAULT
		? 'public-report__segment__item'
		: `public-report__segment__${tableType}-table__item`;

	if (deviceType === DeviceType.MOBILE) {
		if (dimension === ReportBlockField.Dimension.VERY_SMALL
			|| dimension === ReportBlockField.Dimension.SMALL) {
			itemClass = `${itemClass} ${itemClass}--2`;
		} else {
			itemClass = itemClass = `${itemClass} ${itemClass}--3`;
		}
	} else if (!!repeatableBlockType && repeatableBlockType !== RepeatableBlockType.DEFAULT) {
		switch (dimension) {
			case ReportBlockField.Dimension.VERY_SMALL:
				itemClass = `${itemClass} ${itemClass}--1`;
				break;
			case ReportBlockField.Dimension.SMALL:
				itemClass = `${itemClass} ${itemClass}--2`;
				break;
			case ReportBlockField.Dimension.MEDIUM:
				itemClass = `${itemClass} ${itemClass}--3`;
				break;
			case ReportBlockField.Dimension.LARGE:
				itemClass = `${itemClass} ${itemClass}--4`;
				break;
			case ReportBlockField.Dimension.LARGEST:
				itemClass = `${itemClass} ${itemClass}--5`;
				break;
		}
	} else {
		switch (dimension) {
			case ReportBlockField.Dimension.VERY_SMALL:
			case ReportBlockField.Dimension.SMALL:
				itemClass = `${itemClass} ${itemClass}--1`;
				break;
			case ReportBlockField.Dimension.MEDIUM:
				itemClass = `${itemClass} ${itemClass}--2`;
				break;
			case ReportBlockField.Dimension.LARGE:
				itemClass = `${itemClass} ${itemClass}--3`;
				break;
			case ReportBlockField.Dimension.LARGEST:
				itemClass = `${itemClass} ${itemClass}--6`;
				break;
		}
	}

	const getSignatureValue = React.useCallback(() => {
		const signature = !!value && typeof value === 'string' ? JSON.parse<SignatureFieldVM>(value) : value;

		if (!signature || typeof signature === 'string') {
			// signature can be empty string in some initialization cases
			return <SignatureStatusLabel status={TimeSheetSignatureStatus.UNSIGNED} />;
		}

		return (
			signature.imageUrl
				? <div className="public-report__segment__row">
					<div>
						<SignatureStatusLabel status={TimeSheetSignatureStatus.SIGNED} />
						<br />
						by {signature.name}
						<br />
						{TimeUtils.formatDate(signature.signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
					</div>
					<div>
						<img className="public-report__segment__item__image" src={signature.imageUrl} />
					</div>
				</div>
				: <div>
					<SignatureStatusLabel status={TimeSheetSignatureStatus.UNSIGNED} />
				</div>
		);
	}, [value]);

	const label = React.useMemo(() => {
		switch (fieldType) {
			case ReportBlockField.Type.IMMUTABLE_TEXT:
			case ReportBlockField.Type.LINE_BREAK:
				return '';
			default: return name;
		}
	}, [fieldType, name]);

	const fieldValue = React.useMemo(() => {
		if (fieldType === ReportBlockField.Type.LINE_BREAK) {
			return <></>;
		}

		if (isImage && !!value) {
			return <img className="public-report__segment__item__image" src={value}></img>;
		}

		if (fieldType === ReportBlockField.Type.SIGNATURE) {
			return getSignatureValue();
		}

		return displayValue(value ?? null, unit);
	}, [fieldType, getSignatureValue, isImage, unit, value]);

	const valueClassName = React.useMemo(() => {
		const modifiers = {
			'redText': fieldType === ReportBlockField.Type.IMMUTABLE_TEXT && descriptiveTextColor === ExtendedColorPalette.RED,
			'normalText': (fieldType === ReportBlockField.Type.IMMUTABLE_TEXT && !isDescriptiveTextBold),
		};

		return bemElement('locked-input', inverse ? 'header' : 'content', modifiers);
	}, [descriptiveTextColor, fieldType, inverse, isDescriptiveTextBold]);

	const labelClassName = React.useMemo(() => {
		const modifiers = {
			'boldText': fieldType === ReportBlockField.Type.SIGNATURE,
		};

		return bemElement('locked-input', 'label', modifiers);
	}, [fieldType]);

	return (
		<div className={itemClass}>
			<LockedValue
				inverse={inverse}
				label={label}
				labelClassName={labelClassName}
				renderLabel={renderLabel}
				renderValue={renderValue}
				value={fieldValue}
				valueClassName={valueClassName}
			/>
		</div>
	);
};

export default React.memo(Field);
