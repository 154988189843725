import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import { CompoundUnitEnum, PlainUnitEnum, QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';
import type * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';

import type JobWorkSummaryBase from 'ab-domain/models/jobWorkSummary/base';

import { MAX_BILLABLE_WORK_DEFINITION_FIELDS } from 'ab-common/constants/value';

export class W_FieldReport_FindWorkSummary_VM {
	workSummaryRows: W_FieldReport_FindWorkSummary_VM_Row[];

	constructor(workSummaries: JobWorkSummaryBase[]) {
		this.workSummaryRows = W_FieldReport_FindWorkSummary_VM_Row.bulkConstructor(workSummaries);
	}
}

class W_FieldReport_FindWorkSummary_VM_Row {
	subJobId: number;
	billableWorkId: number;
	billingCodeId: Nullable<number>;
	billingCodeUnit: Nullable<QuantityUnitType>;
	billingGroup: Nullable<string>;
	work: string;
	isBillable: boolean;
	quantity: number;
	total: Nullable<number>;
	unit: Nullable<string>;
	type: string;
	typeFieldName: string;
	typeFieldType: ReportBlockFieldEnum.Type;
	definitionFields: W_FieldReport_FindWorkSummary_VM_DefinitionField[];
	customerId: Nullable<string>;
	unitPrice: Nullable<number>;
	description: Nullable<string>;
	group: Nullable<string>;
	workSummaryGroup: Nullable<string>;
	jobCode: Nullable<string>;

	constructor(jws: JobWorkSummaryBase) {
		this.subJobId = jws.workRequestId;
		if (!jws.billableWorkId) {
			throw new Error('Only use JWS with dataType ORIGINAL for this VM');
		}
		this.billableWorkId = jws.billableWorkId;
		this.billingCodeId = jws.billingCodeId;
		this.billingCodeUnit = jws.billingCode?.unit ?? null;
		this.billingGroup = jws.group;
		this.work = jws.work;
		this.isBillable = jws.isBillable;
		this.quantity = jws.quantity ?? 0;
		this.unit = jws.unit ? QuantityUnitMap[_resolveUnit(jws.unit)] ?? null : null;
		this.total = jws.revenue;
		this.type = jws.typeValue ?? '';
		this.typeFieldName = jws.type;
		if (!jws.typeFieldType) {
			throw new Error('Only use JWS with dataType ORIGINAL for this VM');
		}
		this.typeFieldType = jws.typeFieldType;
		this.definitionFields = [];
		for (let i = 1; i <= MAX_BILLABLE_WORK_DEFINITION_FIELDS; i++) {
			const fieldName = jws[`definitionField${i}Name`];
			if (fieldName) {
				this.definitionFields.push(new W_FieldReport_FindWorkSummary_VM_DefinitionField(
					i - 1,
					jws[`definitionField${i}Value`],
					fieldName,
					jws[`definitionField${i}Type`],
					jws[`definitionField${i}Unit`] ? _resolveUnit(jws[`definitionField${i}Unit`] as QuantityUnitType) : null
				));
			}
		}

		this.customerId = jws.customerId;
		this.unitPrice = jws.unitPrice;
		this.description = jws.description;
		this.group = jws.group;
		this.workSummaryGroup = jws.workSummaryGroup;
		this.jobCode = jws.workRequest?.jobCode ?? null;
	}

	private static _constructorMap = (_workSummary: JobWorkSummaryBase) => new W_FieldReport_FindWorkSummary_VM_Row(_workSummary);

	static bulkConstructor = (workSummaries: JobWorkSummaryBase[]) => workSummaries.map(W_FieldReport_FindWorkSummary_VM_Row._constructorMap);
}

class W_FieldReport_FindWorkSummary_VM_DefinitionField {
	value: string;
	fieldName: string;
	fieldType: ReportBlockFieldEnum.Type;
	fieldUnit: Nullable<QuantityUnitType>;
	index: number;

	constructor(
		index: number,
		value: string,
		fieldName: string,
		fieldType: ReportBlockFieldEnum.Type,
		fieldUnit: Nullable<QuantityUnitType>
	) {
		this.index = index;
		this.value = value;
		this.fieldName = fieldName;
		this.fieldType = fieldType;
		this.fieldUnit = fieldUnit;
	}
}

function _resolveUnit(unit: QuantityUnitType) {
	switch (unit) {
		case CompoundUnitEnum.FT_IN: return PlainUnitEnum.IN;
		case CompoundUnitEnum.CFT_CIN: return PlainUnitEnum.CIN;
		case CompoundUnitEnum.HH_MM: return PlainUnitEnum.MIN;
		case CompoundUnitEnum.LFT_LIN: return PlainUnitEnum.LIN;
		case CompoundUnitEnum.SFT_SIN: return PlainUnitEnum.SIN;
		case CompoundUnitEnum.VFT_VIN: return PlainUnitEnum.VIN;
	}
	return unit;
}
