import * as React from 'react';
import type { ReactGoogleChartProps, Formatter } from 'react-google-charts';
import { Chart } from 'react-google-charts';

import { ColorHex, AdditionalColors } from '@acceligentllc/shared/enums/color';

import type { UtilizationInfoCardChartViewModel } from 'ab-common/dataStructures/chart';

import { roundToLeadingDigitsPrecision } from 'ab-utils/number.util';

import { APP_FONT_NAME } from 'af-constants/values';
import type { LineChartDataVectorViewModel } from 'ab-common/dataStructures/chart';

// Style constants:

const GRIDLINES_COLOR = ColorHex.GREY_BACKGROUND;
const PRIMARY_LINE_COLOR = ColorHex.ORANGE;
const SECONDARY_LINE_COLOR = ColorHex.BLUE;
const H_AXIS_BASELINE_COLOR = ColorHex[AdditionalColors.BLACK];	// represents the fiscal year delimiter (i.e. equals fiscal year start month position)

const AXIS_TEXT_STYLE = {
	fontName: APP_FONT_NAME,
	fontSize: 12,
	color: ColorHex.GREY_FONT_LIGHT,
};

const CHART_POSITION: NonNullable<ReactGoogleChartProps['options']>['chartArea'] = {
	top: 30,
	right: 10,
	bottom: 20,
	left: 60,
};

const LEGEND_STYLE: NonNullable<ReactGoogleChartProps['options']>['legend'] = {
	position: 'top',
	alignment: 'center',
	textStyle: {
		fontName: APP_FONT_NAME,
		fontSize: 10,
	},
};

const BASE_OPTIONS: Partial<ReactGoogleChartProps['options']> = {
	titlePosition: 'none',
	colors: [PRIMARY_LINE_COLOR, SECONDARY_LINE_COLOR],
	chartArea: CHART_POSITION,
	legend: LEGEND_STYLE,
};

// Helpers:

const _VAxisRound = (value: number) => {
	const numberOfLeadingDigits = Math.trunc(Math.log10(Math.abs(value) || 1)) % 3 + 1;
	return roundToLeadingDigitsPrecision(value, Math.min(numberOfLeadingDigits, 2));
};

// Component:

interface OwnProps {
	title: string;
	footerLabel: string;
	footerValue: string;
	hAxisLabel: string;
	vAxisFormatter?: (value: number) => number | string;
	hAxisFormatter?: Formatter;
	chartData: UtilizationInfoCardChartViewModel | undefined | null;
}

type Props = OwnProps;

export default class InfoCardChart extends React.PureComponent<Props> {

	getHAxisBaselineOptions = () => {
		const { chartData } = this.props;

		if (!chartData) {
			throw new Error('Chart data not loaded');
		}

		const { hAxisBaselineKeyIndex } = chartData;

		if (hAxisBaselineKeyIndex === undefined) {
			return { baselineColor: 'transparent' };
		}
		return {
			baselineColor: H_AXIS_BASELINE_COLOR,
			baseline: hAxisBaselineKeyIndex,
		};
	};

	getVAxisTicks = (): NonNullable<NonNullable<ReactGoogleChartProps['options']>['vAxis']>['ticks'] => {
		const { vAxisFormatter, chartData } = this.props;
		if (!chartData) {
			throw new Error('Chart data not loaded');
		}

		const { currentHalfYearData, previousHalfYearData } = chartData;
		if (!currentHalfYearData || !previousHalfYearData) {
			throw new Error('Chart data not loaded');
		}

		const currentValues = currentHalfYearData.values as LineChartDataVectorViewModel<number>['values'];
		const previousValues = previousHalfYearData.values as LineChartDataVectorViewModel<number>['values'];

		let max = Math.max(...currentValues, ...previousValues) || 0;
		let min = Math.min(...currentValues, ...previousValues) || 0;
		let high: number = max;
		let low: number = min;

		if (high === 0 && low === 0) {
			high = 0.01;
			low = -0.01;
		}
		const offset = Math.abs(high - low) * 0.01;	// add 1% offset so the line doesn't get cut off

		max = _VAxisRound(max);
		min = _VAxisRound(min);
		high = _VAxisRound(high + offset);
		low = _VAxisRound(low - offset);

		const midValue = (high - low) / 2 + low;
		const actualMid = (max - min) / 2 + min;

		return [
			{ v: high, f: vAxisFormatter ? vAxisFormatter(max) : max },
			{ v: midValue, f: vAxisFormatter ? vAxisFormatter(actualMid) : actualMid },
			{ v: low, f: vAxisFormatter ? vAxisFormatter(min) : min },
		];
	};

	render() {
		const { title, footerLabel, footerValue, hAxisLabel, hAxisFormatter, chartData } = this.props;

		if (!chartData) {
			return (
				<div className="utilization-info-card__chart-container">
					<div className="utilization-info-card__chart-title">{title}</div>
					<div className="utilization-info-card__chart--loading">
						<img src="/images/dashboard/dashboard_pending_stats.svg" />
						<div className="utilization-info-card__chart--loading__info">
							<span>Loading...</span>
						</div>
					</div>
				</div>
			);
		}

		const { hAxisKeys, currentHalfYearData, previousHalfYearData } = chartData;

		const hAxisTicks = hAxisKeys.map((_key, _index) => ({ v: _index, f: _key }));

		if (!currentHalfYearData || !previousHalfYearData) {
			throw new Error('Data not loaded');
		}

		const data: ReactGoogleChartProps['data'] = [
			[hAxisLabel, { label: currentHalfYearData.label, color: PRIMARY_LINE_COLOR }, { label: previousHalfYearData.label, color: SECONDARY_LINE_COLOR }],
			...hAxisTicks.map((_hAxisTick, _i) => {
				if (_i === hAxisTicks.length - 1) {
					// if last value is `null` set it to `0` to avoid the Google Chart API Error "All series on a given axis must be of the same data type"
					return [_hAxisTick, currentHalfYearData.values[_i] ?? 0, previousHalfYearData.values[_i] ?? 0];
				}
				return [_hAxisTick, currentHalfYearData.values[_i], previousHalfYearData.values[_i]];
			}),
		];

		const options: ReactGoogleChartProps['options'] = {
			...BASE_OPTIONS,
			vAxis: {
				baselineColor: GRIDLINES_COLOR,
				gridlines: { color: 'transparent' },
				textStyle: AXIS_TEXT_STYLE,
				ticks: this.getVAxisTicks(),
			},
			hAxis: {
				...this.getHAxisBaselineOptions(),
				gridlines: { color: GRIDLINES_COLOR },
				textStyle: AXIS_TEXT_STYLE,
				ticks: hAxisTicks,
			},
		};

		const formatters: ReactGoogleChartProps['formatters'] = hAxisFormatter
			? [
				{ ...(hAxisFormatter), column: 1 },
				{ ...(hAxisFormatter), column: 2 },
			] as ReactGoogleChartProps['formatters']
			: undefined;

		return (
			<div className="utilization-info-card__chart-container">
				<div className="utilization-info-card__chart-title">{title}</div>
				<div className="utilization-info-card__chart">
					<Chart
						chartType="LineChart"
						data={data}
						formatters={formatters}
						height="100%"
						options={options}
						width="100%"
					/>
				</div>
				<div className="utilization-info-card__chart-footer">
					<div className="utilization-info-card__chart-footer__entry">
						<span className="utilization-info-card__chart-footer__entry__title">{footerLabel}</span>
						<span className="utilization-info-card__chart-footer__entry__value">{footerValue}</span>
					</div>
				</div>
			</div>
		);
	}
}
