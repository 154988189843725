import type WorkRequestBase from 'ab-domain/models/workRequest/base';

export type W_Job_FindAllJobsForJobFilter_VM = W_Job_FindAllJobsForJobFilter_VM_Single[];

export class W_Job_FindAllJobsForJobFilter_VM_Single {
	id: number;
	jobCode: string;
	title: Nullable<string>;
	customerCompany: Nullable<string>;

	constructor(workRequest: WorkRequestBase) {
		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.title = workRequest.title;
		this.customerCompany = workRequest.customerCompanyName ?? workRequest.customerContact?.contact.companyName ?? null;
	}

	static bulkConstructor = (workOrders) => workOrders.map(W_Job_FindAllJobsForJobFilter_VM_Single._constructorMap);
	private static _constructorMap = (workOrder) => new W_Job_FindAllJobsForJobFilter_VM_Single(workOrder);

}

