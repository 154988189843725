import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import SegmentLabel from 'af-components/SegmentLabel';

import type { ScheduleBoardEmployeePerDiemAssignmentTypeVM } from 'ab-viewModels/scheduleBoardEmployeePerDiemAssignment.viewModel';
import type ScheduleBoardWorkOrderVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import NonAssignableResourceAssignment from './NonAssignableResourceAssignment';
import AssignableResourceAssignment from './AssignableResourceAssignment';
import styles from './styles.module.scss';

interface OwnProps {
	itemId: number;
	maxItems: number;
	assignableWorkOrders: ScheduleBoardWorkOrderVM[];
	assignedWorkOrders: ScheduleBoardWorkOrderVM[];
	perDiemAssignments?: ScheduleBoardEmployeePerDiemAssignmentTypeVM;
	isAllowedToManagePerDiems: boolean;
	isEditable: boolean;
	showPerDiem: boolean;
	/** [workOrderId]: workOrderResourceLookupId */
	resourceLookupIds: { [workOrderId: number]: number; };
	createAssignment: (workOrderId: number) => Promise<boolean>;
	removeAssignment: (workOrderId: number, workOrderCode: string, itemId: number, resourceLookupId?: number) => void;
	updateWorkOrderEmployeePerDiem?: (workOrderId: number, employeeId: number, perDiem: boolean) => void;
}

interface State {
	assignmentForms: number[];
}

type Props = OwnProps;

export default class AssignmentsModalSection extends React.PureComponent<Props, State> {
	static defaultProps: Partial<Props> = {
		assignableWorkOrders: [],
		assignedWorkOrders: [],
		perDiemAssignments: {},
		showPerDiem: true,
	};

	state: State = {
		assignmentForms: [],
	};

	addAssignmentForm = () => {
		const { assignmentForms } = this.state;
		this.setState({ assignmentForms: assignmentForms.concat(assignmentForms.length) });
	};

	onCreateAssignment = async (index: number, workOrder: ScheduleBoardWorkOrderVM) => {
		const { id } = workOrder;
		const { createAssignment } = this.props;
		const isDone = await createAssignment(id);
		if (isDone) {
			this.removeAssignmentForm(index);
		}
	};

	removeAssignmentItem = (workOrderId: number, workOrderCode: string, resourceLookupId?: number) => {
		const { removeAssignment, itemId } = this.props;
		removeAssignment(workOrderId, workOrderCode, itemId, resourceLookupId);
	};

	removeAssignmentForm = (index: number) => {
		this.setState((state: State) => ({ assignmentForms: state.assignmentForms.filter((formNumber) => formNumber !== index) }));
	};

	onPerDiemCheck = (workOrderId: number, event: React.ChangeEvent<HTMLInputElement>) => {
		const { updateWorkOrderEmployeePerDiem, itemId } = this.props;
		const perDiem = event.target.checked;
		if (updateWorkOrderEmployeePerDiem) {
			updateWorkOrderEmployeePerDiem(workOrderId, itemId, perDiem);
		}
	};

	renderAssignment = (assignmentNumber: number, index: number) => {
		const { assignableWorkOrders } = this.props;
		return (
			<AssignableResourceAssignment
				assignmentNumber={assignmentNumber}
				createAssignment={this.onCreateAssignment}
				key={index}
				removeAssignment={this.removeAssignmentForm}
				workOrders={assignableWorkOrders}
			/>
		);
	};

	renderNonEditableAssignment = (workOrder: ScheduleBoardWorkOrderVM, index: number) => {
		const { showPerDiem, perDiemAssignments, isEditable, resourceLookupIds } = this.props;

		if (!perDiemAssignments) {
			return null;
		}

		return (
			<NonAssignableResourceAssignment
				index={index}
				isEditable={isEditable}
				key={index}
				perDiemAssignments={perDiemAssignments}
				perDiemCheck={this.onPerDiemCheck}
				removeAssignment={this.removeAssignmentItem}
				resourceLookupId={resourceLookupIds?.[workOrder.id]}
				showPerDiem={showPerDiem}
				workOrder={workOrder}
			/>
		);
	};

	render() {
		const { assignedWorkOrders, maxItems, isEditable, assignableWorkOrders } = this.props;
		const { assignmentForms } = this.state;

		const allowAssignmentAddition = (!assignedWorkOrders || assignedWorkOrders.length < maxItems) && isEditable && !!assignableWorkOrders.length;

		const containerClassName = bemElement(
			'schedule-board-modal__resource',
			'secondary-row',
			{
				'padded-bottom': !allowAssignmentAddition,
				'final': true,
			}
		);

		return (
			<>
				<div className={containerClassName}>
					<SegmentLabel label="Today's assignments" />
					<div className={styles.assignmentsList}>
						{assignedWorkOrders.map(this.renderNonEditableAssignment)}
						{assignmentForms.map(this.renderAssignment)}
						{allowAssignmentAddition &&
							<Button
								icon="plus"
								label="Add Assignment"
								onClick={this.addAssignmentForm}
								style="link"
							/>
						}
					</div>
				</div>
			</>
		);
	}
}
