import * as React from 'react';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type { TimeSheetInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';
import type WorkOrderReviewRecapVM from 'ab-viewModels/workOrder/workOrderReviewRecap.viewModel';

import type SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import SignatureModal from '../../../Shared/SignatureModal';

import SuccessModalBody from '../Shared/SuccessModalBody';

import SubmitForReviewModal from './SubmitForReviewModal';
import DigitalSignatureSaveModal from '../DigitalSignatureSaveModal';

import SignatureForm from '../../../Shared/SignatureModal/FormModel';

interface OwnProps {
	closeModal: () => void;
	currentReviewLevel: WorkOrderReviewLevel;
	currentUserAccountId: number;
	fieldReports: FieldReportListItemVM[];
	isManagerAdminSI: boolean;
	isWorkOrderReadOnly: (reviewLevel: WorkOrderReviewLevel) => boolean;
	onSubmit: (form: SubmitWorkOrderForReviewRM) => Promise<void>;
	showModal: boolean;
	submitToLevel: WorkOrderReviewLevel;
	workOrderId: number;
	workOrderReviewRecap: Nullable<WorkOrderReviewRecapVM>;
	revertOutdated: boolean;
	isCompanyAdmin: boolean;
	isDigitalSignatureEnabled: boolean;
	showCreateDigitalSignature: boolean;
	timezone: Nullable<string>;
}

type Props = OwnProps;

interface State {
	currentUserTimeSheet: Nullable<TimeSheetInfoVM>;
	showSubmitForReviewModal: boolean;
	showSignatureModal: boolean;
	showSuccessModal: boolean;
	showCreateDigitalSignatureModal: boolean;
	signatureImage: Nullable<string>;
}

class SubmitForReviewModalHandler extends React.PureComponent<Props, State> {

	state: State = {
		currentUserTimeSheet: SubmitForReviewModalHandler.getCurrentUserTimeSheet(
			this.props.currentUserAccountId,
			this.props.workOrderReviewRecap?.timeSheets ?? null
		),
		showSubmitForReviewModal: this.props.showModal,
		showSignatureModal: false,
		showSuccessModal: false,
		showCreateDigitalSignatureModal: false,
		signatureImage: null,
	};

	static USER_TEXT = 'You are about to sign your time sheet and submit following content for review:';

	static MODAL_TITLE = 'Submit for Review';

	static SUBMIT_TITLE = 'Field Reports Submitted for Review';
	static APPROVE_TITLE = 'Field Reports Approved';

	static REVIEW_DESCRIPTION = 'Report has been successfully submitted for review';
	static APPROVE_DESCRIPTION = 'Report with all its time sheets has been approved and sent for additional review';

	static getCurrentUserTimeSheet = (accountId: number, timeSheets: Nullable<TimeSheetInfoVM[]>) => {
		return timeSheets?.find((_timeSheet) => _timeSheet.accountId === accountId) ?? null;
	};

	static getTitle = (reviewLevel: WorkOrderReviewLevel) => {
		return reviewLevel === WorkOrderReviewLevel.LEVEL_1
			? SubmitForReviewModalHandler.SUBMIT_TITLE
			: SubmitForReviewModalHandler.APPROVE_TITLE;
	};

	static getDescription = (reviewLevel?: WorkOrderReviewLevel) => {
		return reviewLevel === WorkOrderReviewLevel.LEVEL_1
			? SubmitForReviewModalHandler.REVIEW_DESCRIPTION
			: SubmitForReviewModalHandler.APPROVE_DESCRIPTION;
	};

	componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;

		if ((!prevProps.showModal && showModal) || (prevProps.showModal && !showModal)) {
			this.setState(() => this.stateSetter());
		}
	}

	stateSetter = () => {
		const { showModal, workOrderReviewRecap, currentUserAccountId } = this.props;
		return {
			currentUserTimeSheet: SubmitForReviewModalHandler.getCurrentUserTimeSheet(currentUserAccountId, workOrderReviewRecap?.timeSheets ?? null),
			showSubmitForReviewModal: showModal,
			showSignatureModal: false,
			showSuccessModal: false,
			showCreateDigitalSignatureModal: false,
		};
	};

	submitForReview = async (form: SignatureForm) => {
		const { onSubmit, submitToLevel, revertOutdated } = this.props;

		await onSubmit(SignatureForm.toSubmitForReviewRM(form, submitToLevel, revertOutdated));

		// Show success modal
		this.setState(() => ({
			showSubmitForReviewModal: false,
			showSignatureModal: false,
			showSuccessModal: true,
			signatureImage: form.signatureImage,
		}));
	};

	showSignatureModal = () => this.setState(() => ({ showSubmitForReviewModal: false, showSignatureModal: true }));
	showSubmitForReviewModal = () => this.setState(() => ({ showSubmitForReviewModal: true }));

	onSuccessModalConfirm = () => {
		const { closeModal, isDigitalSignatureEnabled, submitToLevel, showCreateDigitalSignature, isCompanyAdmin } = this.props;

		if (!isDigitalSignatureEnabled && !isCompanyAdmin && showCreateDigitalSignature && (submitToLevel > WorkOrderReviewLevel.LEVEL_1)) {
			this.setState(() => ({ showSuccessModal: false, showCreateDigitalSignatureModal: true }));
		} else {
			closeModal();
			this.setState(() => ({ showSuccessModal: false }));
		}
	};

	closeDigitalSignatureModal = () => {
		const { closeModal } = this.props;

		closeModal();
		this.setState(() => ({ showCreateDigitalSignatureModal: false }));
	};

	renderSuccessModalBody = () => {
		const { isWorkOrderReadOnly, submitToLevel } = this.props;

		const title = SubmitForReviewModalHandler.getTitle(submitToLevel);
		const description = SubmitForReviewModalHandler.getDescription(submitToLevel);

		return (
			<SuccessModalBody
				description={description}
				isReadOnly={isWorkOrderReadOnly(submitToLevel)}
				reviewStatus={WorkOrderReviewStatus.IN_REVIEW}
				title={title}
			/>
		);
	};

	render = () => {
		const { fieldReports, workOrderReviewRecap, closeModal, submitToLevel, timezone } = this.props;
		const {
			currentUserTimeSheet,
			showSignatureModal,
			showSubmitForReviewModal,
			showSuccessModal,
			showCreateDigitalSignatureModal,
			signatureImage,
		} = this.state;

		const showDigitalSignature = (submitToLevel > WorkOrderReviewLevel.LEVEL_1);

		return (
			<>
				{showSubmitForReviewModal &&
					<SubmitForReviewModal
						closeModal={closeModal}
						currentUserTimeSheet={currentUserTimeSheet}
						fieldReports={fieldReports}
						onConfirm={this.showSignatureModal}
						showModal={showSubmitForReviewModal}
						timeSheetEntriesByAccountIdMap={workOrderReviewRecap?.timeSheetEntriesByAccountId ?? null}
						timeSheets={workOrderReviewRecap?.timeSheets ?? []}
						timezone={timezone}
					/>}
				{showSignatureModal &&
					<SignatureModal
						closeModal={closeModal}
						description="Submitting a Field Report for review requires a signature."
						onSubmit={this.submitForReview}
						showDigitalSignature={showDigitalSignature}
						showModal={showSignatureModal}
						showNameInput={false}
						title={SubmitForReviewModalHandler.MODAL_TITLE}
					/>}
				{showSuccessModal &&
					<ConfirmationModal
						body={this.renderSuccessModalBody()}
						closeModal={this.onSuccessModalConfirm}
						confirmAction={this.onSuccessModalConfirm}
						confirmText="Done"
						modalStyle="info"
						showCancel={false}
						showModal={showSuccessModal}
						size="md"
						title={SubmitForReviewModalHandler.MODAL_TITLE}
					/>}
				{showCreateDigitalSignatureModal &&
					<DigitalSignatureSaveModal
						closeModal={this.closeDigitalSignatureModal}
						showModal={showCreateDigitalSignatureModal}
						signatureImage={signatureImage}
					/>}
			</>
		);
	};
}

export default SubmitForReviewModalHandler;
