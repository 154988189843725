import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import styles from './styles.module.scss';

interface OwnProps {
	isOpaque: boolean;
	jobTime: number;
	shopTime: number;
	travelTime: number;
	breakTime: number;
	hasOverlap: boolean;
}

type Props = OwnProps;

const TotalTimeSheetTimes: React.FC<Props> = (props) => {
	const { isOpaque, hasOverlap, jobTime: totalJobTime, shopTime: totalShopTime, travelTime: totalTravelTime, breakTime: totalBreakTime } = props;

	const roundedTotalJobTime = TimeUtils.roundTimeDurationToInterval(totalJobTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
	const roundedTotalShopTime = TimeUtils.roundTimeDurationToInterval(totalShopTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
	const roundedTotalTravelTime = TimeUtils.roundTimeDurationToInterval(totalTravelTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
	const roundedTotalBreakTime = TimeUtils.roundTimeDurationToInterval(totalBreakTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

	const roundedTotalTime = roundedTotalJobTime + roundedTotalShopTime + roundedTotalTravelTime + roundedTotalBreakTime;

	return (
		hasOverlap
			?
			<div className={`${styles['time-sheet-bulk-edit-modal__timeline__total-times-overlap']} 
							${isOpaque ? `${styles['time-sheet-bulk-edit-modal__timeline__total-times-overlap--opaque']}` : ''} `}
			>
				One or more corrections overlap with activities on the timeline.
			</div>
			:
			<div className={`${styles['time-sheet-bulk-edit-modal__timeline__total-times']} 
			${isOpaque ? `${styles['time-sheet-bulk-edit-modal__timeline__total-times--opaque']}` : ''} `}
			>
				<div className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row']}>
					Total Time on Work Order
					<span>
						{TimeUtils.minutesToHoursAndMinutes(roundedTotalTime)}
					</span>
				</div>
				<div className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row']}>
					<span className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row__work-type']}>
						<span className="icon-equipment" />
						{TimeUtils.minutesToHoursAndMinutes(roundedTotalJobTime)}
					</span>
					<span className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row__work-type']}>
						<span className="icon-home" />
						{TimeUtils.minutesToHoursAndMinutes(roundedTotalShopTime)}
					</span>
					<span className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row__work-type']}>
						<span className="icon-travel" />
						{TimeUtils.minutesToHoursAndMinutes(roundedTotalTravelTime)}
					</span>
					<span className={styles['time-sheet-bulk-edit-modal__timeline__total-times__row__work-type']}>
						<span className="icon-break" />
						{TimeUtils.minutesToHoursAndMinutes(roundedTotalBreakTime)}
					</span>
				</div>
			</div >
	);
};

export default React.memo(TotalTimeSheetTimes);
