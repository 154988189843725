import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';

import type EmployeeDownRM from 'ab-requestModels/employee/employeeDown.requestModel';

import type { UnavailableEmployeeStatusVM } from 'ab-viewModels/employeeStatus.viewModel';

import type { RootState } from 'af-reducers';

import DateInput from 'af-fields/DateInput';
import Dropdown from 'af-fields/Dropdown';
import AsyncSelect from 'af-fields/AsyncSelect';

import { EMPLOYEE_DOWN_FORM } from 'af-constants/reduxForms';
import { ASYNC_SELECT_DROPDOWN_WIDTH } from 'af-constants/values';

import CustomModal from 'af-components/CustomModal';
import StatusLabel from 'af-components/StatusLabel';
import SubmitButton from 'af-components/SubmitButton';

import * as EmployeeStatusActions from 'af-actions/employeeStatus';
import * as UnavailabilityActions from 'af-actions/unavailabilityReason';

import { validate, warn } from './validation';

type FormProps = InjectedFormProps<EmployeeDownRM, FormOwnProps>;

interface OwnProps {
	initialFormValues: Nullable<FormProps['initialValues']>;
	onClose: () => void;
	onSubmit: (form: EmployeeDownRM) => Promise<void>;
	showModal: boolean;
	showStatusOption?: boolean;
}

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

interface State {
	unavailableStatuses: UnavailableEmployeeStatusVM[];
}

class EmployeeDownModal extends React.PureComponent<Props, State> {

	static AUTOMATIC_RETURN_DATE_TITLE = 'Automatic Return Date';
	static EXPECTED_RETURN_DATE_TITLE = 'Expected Return Date';
	static AUTOMATIC_RETURN_DATE_TOOLTIP_MESSAGE = 'Labor will become available on this date';
	static EXPECTED_RETURN_DATE_TOOLTIP_MESSAGE = 'Labor may become available on this date';

	static MENU_STYLE: React.CSSProperties = {
		width: ASYNC_SELECT_DROPDOWN_WIDTH,
	};

	state: State = {
		unavailableStatuses: [],
	};

	static formatOptionLabel = (option) => option.label;

	static getReturnDateLabel = (isAutomaticReturnDate: boolean) => (
		isAutomaticReturnDate
			? EmployeeDownModal.AUTOMATIC_RETURN_DATE_TITLE
			: EmployeeDownModal.EXPECTED_RETURN_DATE_TITLE
	);

	static getReturnDateTooltip = (isAutomaticReturnDate: boolean) => (
		isAutomaticReturnDate
			? EmployeeDownModal.AUTOMATIC_RETURN_DATE_TOOLTIP_MESSAGE
			: EmployeeDownModal.EXPECTED_RETURN_DATE_TOOLTIP_MESSAGE
	);

	async componentDidUpdate(prevProps: Props) {
		const { destroy, initialize, initialFormValues } = this.props;

		// modal closed
		if (prevProps.showModal && !this.props.showModal) {
			destroy();
		}

		// modal opened
		if (!prevProps.showModal && this.props.showModal) {
			initialize(initialFormValues ?? {} as EmployeeDownRM);
			await this.loadUnavailableStatuses();
		}
	}

	loadUnavailableStatuses = async () => {
		const { findAllUnavailableEmployeeStatuses } = this.props;

		const statuses = await findAllUnavailableEmployeeStatuses();

		this.setState(() => ({ unavailableStatuses: statuses }));
	};

	onSubmit = async (form: EmployeeDownRM) => {
		const { onSubmit } = this.props;
		await onSubmit(form);
	};

	onSearchReason = async (query: string) => {
		const { findUnavailabilityReasons } = this.props;
		const options = await findUnavailabilityReasons(query, UnavailabilityReasonScope.EMPLOYEE);
		return options.map((_option) => ({ label: _option.name, value: _option.id }));
	};

	onClearReason = () => {
		const { change } = this.props;
		change('unavailabilityReason', { label: '', value: null });
	};

	onNewReason = (label: string) => {
		const { change } = this.props;

		if (!label) {
			return;
		}

		change('unavailabilityReason', { label, value: null });
	};

	static renderStatusMenuItem = (selectedItem: UnavailableEmployeeStatusVM) => {
		return selectedItem?.name
			? <StatusLabel isAvailable={false} label={selectedItem.name} />
			: null;
	};

	render() {
		const {
			submitting,
			showModal,
			handleSubmit,
			onClose,
			invalid,
			isAutomaticReturnDate,
			showStatusOption,
		} = this.props;
		const { unavailableStatuses } = this.state;
		return (
			<CustomModal
				closeModal={onClose}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={onClose}
					title="Set Labor to Unavailable Status"
				>
				</CustomModal.Header>
				<CustomModal.Body padding="none">
					{showStatusOption
						? (
							<Row className="row--padded-top">
								<Col md={12}>
									<Field
										component={Dropdown}
										fixed={true}
										id="status"
										label="Status *"
										name="unavailableStatusId"
										options={unavailableStatuses}
										renderMenuItem={EmployeeDownModal.renderStatusMenuItem}
										valueKey="id"
										withCaret={true}
									/>
								</Col>
							</Row>
						)
						: <></>
					}
					<Row className={showStatusOption ? '' : 'row--padded-top'}>
						<Col md={12}>
							<Field
								component={DateInput}
								dateFormat={TimeFormatEnum.DATE_ONLY}
								fixed={true}
								id="returnDate"
								isClearable={true}
								label={EmployeeDownModal.getReturnDateLabel(isAutomaticReturnDate)}
								name="returnDate"
								originalDateFormat={TimeFormatEnum.DATE_ONLY}
								tooltipMessage={EmployeeDownModal.getReturnDateTooltip(isAutomaticReturnDate)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={24}>
							<Field
								allowNew={true}
								component={AsyncSelect}
								fixed={true}
								formatOptionLabel={EmployeeDownModal.formatOptionLabel}
								label="Unavailability Reason"
								menuStyle={EmployeeDownModal.MENU_STYLE}
								name="unavailabilityReason"
								onClear={this.onClearReason}
								onCreateNew={this.onNewReason}
								onSearch={this.onSearchReason}
								placeholder="None"
							/>
						</Col>
					</Row>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={onClose}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Set as Unavailable"
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={EMPLOYEE_DOWN_FORM}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { company } = state.company;

	return {
		date: state.scheduleBoard.date,
		isAutomaticReturnDate: company?.isEmployeeAutomaticReturnDate ?? false,
	};
}

function mapDispatchToProps() {
	return {
		findAllUnavailableEmployeeStatuses: EmployeeStatusActions.findAllUnavailable,
		findUnavailabilityReasons: UnavailabilityActions.findUnavailabilityReasons,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<EmployeeDownRM, FormOwnProps>({
		form: EMPLOYEE_DOWN_FORM,
		validate,
		warn,
	})
);

export default enhance(EmployeeDownModal) as React.ComponentClass<OwnProps>;
