import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';

import type { BlockValueType } from '@acceligentllc/shared/utils/fieldReport';

import * as FieldReportActions from 'af-actions/fieldReport';

import LoadingOverlay from 'af-components/LoadingOverlay';

import type { RootState } from 'af-reducers';

import Segment from './Segment';
import type { RequestQueue } from '../../helpers';

interface OwnProps {
	fieldReportId: number;
	instanceId: string;
	fieldReportTypeId: number;
	showInstanceCount: boolean;
	highlightedBlockId: Nullable<string>;
	isPreview: boolean;
	change: (fieldName: string, value: BlockValueType) => void;
	lock: (blockId: string) => void;
	unlock: () => void;
	forceUnlock: (blockId: string) => void;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (_ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
	areFRsReadOnly: boolean;
	isAbleToReject: boolean;
	requestQueue: RequestQueue;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	addingSegment: boolean;
	showFullInstance: boolean;
}

class Instance extends React.PureComponent<Props, State> {
	state: State = {
		addingSegment: false,
		showFullInstance: true,
	};

	addSegment = () => this.setState(
		() => ({ addingSegment: true }),
		async () => {
			const { addSegment, fieldReportId, fieldReportTypeId, instanceIndex } = this.props;

			await addSegment(fieldReportId, fieldReportTypeId, instanceIndex);
			this.setState(() => ({ addingSegment: false }));
		}
	);

	toggleSegment = () => this.setState((state) => ({ showFullInstance: !state.showFullInstance }));

	renderSegment = (segmentId: string, key: number) => {
		const {
			instanceIndex,
			instance,
			showInstanceCount,
			fieldReportTypeId,
			change,
			lock,
			highlightedBlockId,
			unlock,
			forceUnlock,
			isPreview,
			fieldReportId,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			requestQueue,
		} = this.props;
		const { showFullInstance } = this.state;

		if (!showFullInstance && key > 0) {
			return null;
		}

		return (
			<Segment
				change={change}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				forceUnlock={forceUnlock}
				highlightedBlockId={highlightedBlockId}
				index={key}
				instanceIndex={instanceIndex}
				isPreview={isPreview}
				key={key}
				lock={lock} // don't count primary segment
				removeFocusedField={removeFocusedField}
				requestQueue={requestQueue}
				secondaryCount={instance.segments.length - 1}
				segmentId={segmentId}
				setFieldToFocus={setFieldToFocus}
				showInstanceCount={showInstanceCount} // don't count primary segment
				showSegmentCount={instance.segments.length > 2}
				toggleSegment={this.toggleSegment}
				unlock={unlock}
			/>
		);
	};

	renderAddSegment = () => {
		const { firstBlockName } = this.props;
		return (
			<div className="field-report-type-new__add-segment">
				<Button
					icon="plus"
					label={`Add ${firstBlockName ?? 'Secondary'}`}
					onClick={this.addSegment}
					style="secondary"
				/>
			</div>
		);
	};

	renderApprovedWorkOrderMessage = () => {
		return (
			<div className="field-report-type__status-information">
				In order to edit Report Types, please reject Field Report, which will bring it back to edit mode.
			</div>
		);
	};

	render() {
		const { instance, isPreview, areFRsReadOnly, isAbleToReject } = this.props;
		const { addingSegment } = this.state;
		if (!instance) {
			return null;
		}

		const { segments } = instance;

		// if the segment is a secondary segment
		const shouldRenderAddSegment = segments.length > 1 && !isPreview;

		return (
			<>
				<div className="field-report-type__instance">
					{areFRsReadOnly && isAbleToReject && this.renderApprovedWorkOrderMessage()}
					{segments.map(this.renderSegment)}
					{shouldRenderAddSegment && this.renderAddSegment()}
				</div>
				<LoadingOverlay label="Adding Segment" show={addingSegment} />
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { fieldReport: { fieldReport } } = state;
	const { instanceId, fieldReportTypeId } = ownProps;
	if (!fieldReport) {
		throw new Error('fieldReport is required in this context');
	}
	const fieldReportType = fieldReport.typeMap[fieldReportTypeId];
	if (!fieldReportType) {
		throw new Error('fieldReportType not found');
	}
	const instance = fieldReport.instanceMap[instanceId];
	const segment = fieldReport.segmentMap[instance.segments[1]];
	const fieldBlock = fieldReport.fieldReportBlockMap[segment?.blocks[0]];
	const block = fieldReport.blockMap[fieldBlock?.reportBlockId];

	return {
		instance,
		instanceIndex: fieldReportType.instances.indexOf(instanceId),
		firstBlockName: block?.name,
	};
}

function mapDispatchToProps() {
	return {
		addSegment: FieldReportActions.addSegment,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(Instance);
