import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { CUSTOMER_SERVICE_NAME, CUSTOMER_SERVICE_NAME_SHORT } from '@acceligentllc/shared/constants/value';

import Tooltip from 'af-components/Tooltip';

import styles from './styles.module.scss';

type Props = {
	updatedBy: Nullable<string>;
	updatedAt: Date;
};

const UpdatedByCell: React.FC<Props> = ({ updatedAt, updatedBy }) => {
	const updatedAtDate = React.useMemo(() => TimeUtils.formatDate(updatedAt, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME), [updatedAt]);
	const updatedAtTime = React.useMemo(() => TimeUtils.formatDate(updatedAt, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME), [updatedAt]);

	const updatedByName = updatedBy ?? CUSTOMER_SERVICE_NAME_SHORT;

	return (
		<span className={styles['updated-by-cell']}>
			{updatedAtDate}
			<div className={styles['updated-by-cell__time']}>
				{updatedAtTime}
			</div>
			<div className={styles['updated-by-cell__name']}>
				{`by ${updatedByName}`}
				{!updatedBy && (
					<Tooltip
						message={CUSTOMER_SERVICE_NAME}
						placement="bottom"
					>
						<span className="icon-help_fill" />
					</Tooltip>
				)}
			</div>
		</span>
	);
};

export default React.memo(UpdatedByCell);
