const DisabledFeatures = {
	Compliance: process.env.FTD_COMPLIANCE === 'true',
	Dashboard: process.env.FTD_DASHBOARD === 'true',
	FieldReportSendBulkPublicReport: process.env.FTD_FIELD_REPORT_SEND_BULK_PUBLIC_REPORT === 'true',
	WorkRequest: process.env.FTD_WORK_REQUEST === 'true',
	Inventory: process.env.FTD_INVENTORY === 'true',
	Orders: process.env.FTD_INVENTORY === 'true' || process.env.FTD_ORDER === 'true',
	PurchaseOrder: process.env.FTD_INVENTORY === 'true' || process.env.FTD_PURCHASE_ORDER === 'true',
	Project: process.env.FTD_PROJECT === 'true',
	Invoices: process.env.FTD_INVOICES === 'true',
	RedundantJobPayrollReportFE: process.env.FTD_JPR_FE === 'true',
	RedundantJobPayrollReportBE: process.env.FTD_JPR_BE === 'true',
	UserGuide: process.env.FTD_USER_GUIDE === 'true',
	FieldReportTypeTable: process.env.FTD_REPORT_TYPE_VIEW === 'true',
} as const;

export default DisabledFeatures;
