import * as React from 'react';

import type { ColorPalette } from '@acceligentllc/shared/enums/color';

import ColorSquare from 'af-components/ColorSquare';

interface OwnProps {
	label?: string;
	containerClassName?: string;
	contentClassName?: string;
	transparent?: boolean;
	size?: 'small' | 'medium' | 'large';
	color?: ColorPalette;
}

type Props = React.PropsWithChildren<OwnProps>;

class Tag extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		containerClassName: '',
		contentClassName: '',
		size: 'medium',
	};

	render() {
		const {
			label,
			transparent,
			containerClassName,
			contentClassName,
			size,
			children,
			color,
		} = this.props;

		let className = `tag tag--${size}`;
		className = transparent ? `${className} tag--transparent` : className;
		className = containerClassName ? `${className} ${containerClassName}` : className;

		let contentClass = 'tag__content';
		contentClass = contentClassName ? `${contentClass} ${contentClassName}` : contentClass;

		return (
			<div className={className}>
				<div className={contentClass}>
					{
						color &&
						<ColorSquare color={color} />
					}
					{children ?? label}
				</div>
			</div>
		);
	}

}

export default Tag;
