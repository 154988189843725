import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import { PASSWORD_LENGTH_MIN, PASSWORD_CATEGORIES, MIN_PASSWORD_CATEGORY_REQUIREMENTS } from 'ab-common/constants/value';

export default function validate(values: UserRequestModel.ResetPassword) {
	const errors: UserRequestModel.ResetPassword = {} as UserRequestModel.ResetPassword;

	let pwComplexity = 0;
	for (const category of Object.values(PASSWORD_CATEGORIES)) {
		if (category.test(values.password)) pwComplexity++;
	}

	if (!isValidTextInput(values.password)) {
		errors.password = 'Password required';
	} else if (values.password.length < PASSWORD_LENGTH_MIN) {
		errors.password = `Password requires at least ${PASSWORD_LENGTH_MIN} characters`;
	} else if (pwComplexity < MIN_PASSWORD_CATEGORY_REQUIREMENTS) {
		errors.password = 'Minimum password requirements not satisfied';
	}

	if (!values.repeatedPassword) {
		errors.repeatedPassword = 'Repeated password is required';
	} else if (values.password !== values.repeatedPassword) {
		errors.repeatedPassword = 'Password and repeated password do not match';
	}

	return errors;
}
