/* eslint-disable react-hooks/rules-of-hooks */
import * as ValueConstants from '@acceligentllc/shared/constants/value';
import { URL_PROTOCOL_REGEX } from '@acceligentllc/shared/constants/regex';

import { removeSpaces } from 'ab-utils/text.util';

export const EMAIL_NOREPLY = 'noreply@acceligent.com';

export const PASSWORD_LENGTH_MIN = ValueConstants.PASSWORD_LENGTH_MIN;
export const PASSWORD_MAX_AGE = 365; // max age in days before password expires
export const USED_PASSWORD_HISTORY = 5; // prevent using last X passwords when changing password
// new password needs to satisfy X requirements from the list
export const PASSWORD_CATEGORIES = { 'uppercase letters': /\p{Lu}/u, 'lowercase letters': /\p{Ll}/u, 'numbers': /\p{N}/u, 'symbols': /[\p{S}\p{P}]/u } as const;
export const MIN_PASSWORD_CATEGORY_REQUIREMENTS = 3;
export const LOGIN_LOCKOUT_TIME = 60; // time in minutes that the user is locked-out from platform after X succesive failed login attempts
export const LOGIN_MAX_ATTEMPTS = 5; // max failed login attempts before lock-out

export const WORK_REQUEST_CODE_COUNT_LENGTH = ValueConstants.WORK_REQUEST_CODE_COUNT_LENGTH;
export const WORK_ORDER_CODE_COUNT_LENGTH = ValueConstants.WORK_ORDER_CODE_COUNT_LENGTH;

export const DEFAULT_WORK_ORDER_REVISION = 0;

export const SENDGRID_DEFAULT_ERROR = -1; // SENDGRID CUSTOM ERROR CODE
export const TWILIO_INVALID_NUMBER_ERROR = 21211;
export const TWILIO_MESSAGE_TOO_LONG_ERROR = 'The concatenated message body exceeds the 1600 character limit.';
export const TWILIO_MESSAGE_TIMEOUT = 'ETIMEDOUT';

export const HOLD_END_DELAY = 700;
export const PRESS_AND_HOLD_DELAY = 400;

// max numeric values:
export const MAX_EQUIPMENT_COST_MOB_CHARGE = 99999999.99;
export const MAX_EQUIPMENT_COST_DAILY_COST = 999999.99;
export const MAX_EQUIPMENT_COST_WEEKLY_COST = 9999999.99;
export const MAX_EQUIPMENT_COST_MONTHLY_COST = 9999999.99;
export const MAX_EQUIPMENT_COST_OPERATING_CHARGE = 999999.99;
export const MAX_EQUIPMENT_COST_FUEL_COST = 9999.99;
export const MAX_WAGE_RATE_HOURLY_RATE = 9999.99;
export const MAX_WORK_ORDER_REVENUE = 99999999.99;
export const MAX_WORK_ORDER_MAN_HOUR_AVERAGE = 9999.99;
export const MAX_JOB_STATUS_NAME_LENGTH = 10;
export const MAX_JOB_STATUS_DESCRIPTION_LENGTH = 200;
export const MAX_BILLING_CODE_UNIT_PRICE_VALUE = 9999999999;

export const FAKE_ACCOUNT_DATA = ValueConstants.FAKE_ACCOUNT_DATA;

export const CUSTOMER_SERVICE_NAME = ValueConstants.CUSTOMER_SERVICE_NAME;
export const CUSTOMER_SERVICE_NAME_SHORT = ValueConstants.CUSTOMER_SERVICE_NAME_SHORT;

// single character in sms needed
const SEVEN_BIT_SINGLE_CHARACTERS = [
	'@', 'Δ', ' ', '0', '¡', 'P', '¿', 'p', '£', '_', '!', '1', 'A', 'Q', 'a', 'q', '$', 'Φ', '"',
	'2', 'B', 'R', 'b', 'r', '¥', 'Γ', '#', '3', 'C', 'S', 'c', 's', 'è', 'Λ', '¤',
	'4', 'D', 'T', 'd', 't', 'é', 'Ω', '%', '5', 'E', 'U', 'e', 'u', 'ù', 'Π', '&',
	'6', 'F', 'V', 'f', 'v', 'ì', 'Ψ', '\'', '7', 'G', 'W', 'g', 'w', 'ò', 'Σ', '(',
	'8', 'H', 'X', 'h', 'x', 'Ç', 'Θ', ')', '9', 'I', 'Y', 'i', 'y', '\n', 'Ξ',
	'*', ':', 'J', 'Z', 'j', 'z', 'Ø', '0x1B', '+', ';', 'K', 'Ä', 'k', 'ä', 'ø', 'Æ',
	',', '<', 'L', 'Ö', 'l', 'ö', '\t', 'æ', '-', '=', 'M', 'Ñ', 'm', 'ñ', 'Å',
	'ß', '.', '>', 'N', 'Ü', 'n', 'ü', 'å', 'É', '/', '?', 'O', '§', 'o', 'à',
];

// need additional ESC character in sms
export const SEVEN_BIT_DOUBLE_CHARACTERS = ['|', '^', '€', '{', '}', '[', ']', '~'];

export const SEVEN_BIT_ALL_CHARACTERS = [...SEVEN_BIT_SINGLE_CHARACTERS, ...SEVEN_BIT_DOUBLE_CHARACTERS];

export const DEFAULT_TOKEN_EXPIRATION_TIME = 30 * 24 * 60 * 60; // month in seconds

export const ACTIVATION_CODE_EXPIRATION = 1; // in hours

// FIXME: move to infrastructure or application layer
export const REDIS_KEYS = {
	SB_ACTIVE_CONNECTIONS: 'socket-activeSBConnections',
	FR_ACTIVE_CONNECTIONS: 'socket-activeFRConnections',
	FR_LIST_ACTIVE_CONNECTIONS: 'socket-activeFRListConnections',
	WORK_ORDER_LOCKS: 'socket-workOrderLocks',
	FIELD_REPORT_BLOCK_LOCKS: 'socket-fieldReportBlockLocks',
};

export const POSTGRES_YARN_PACKAGE_DEPENDENCY_META = {
	ADDRESS_TIMEZONE: 'geo-tz',
} as const;
export const POSTGRES_YARN_PACKAGE_DEPENDENCY_META_NAMES = Object.values(POSTGRES_YARN_PACKAGE_DEPENDENCY_META) ?? [];

export const DEFAULT_TRANSACTION_MAX_TRIES = 4;
export const DEFAULT_TRANSACTION_RETRY_AFTER = 500; // in ms
export const DEFAULT_TRANSACTION_RETRY_AFTER_MULTIPLIER = 1.5; // multiplies the previous value on each retry

export const DEFAULT_SIGNED_URL_EXPIRE_TIME = 10000; // in seconds

export const WORK_ORDER_PUBLIC_LINK_LENGTH = 42;

export const EXTERNAL_ALIASES = ['app', 'www', ''];
export const FORBIDDEN_ALIASES = [...EXTERNAL_ALIASES, 'admin', 'acceligent'];

export const UNKNOWN_LOCATION_NICKNAME = 'UNKNOWN';

export const DEFAULT_LABOR_PLACEHOLDER = 'DEFAULT_LABOR_PLACEHOLDER';
export const DEFAULT_EQUIPMENT_PLACEHOLDER = 'DEFAULT_EQUIPMENT_PLACEHOLDER';

export const DEFAULT_USER_UNIQUE_ID = 'EE0000';
export const TEMP_EMPLOYEE_PASSWORD = 'TMP_PASSWORD';

export const DEFAULT_WAGE_RATE_TYPE = 'DEFAULT';
export const DEFAULT_WAGE_RATE_CLASSIFICATION = 'DEFAULT';
export const EQUIPMENT_FRONT_IMAGE_FIELDNAME = 'frontImageUrl';
export const EQUIPMENT_BACK_IMAGE_FIELDNAME = 'backImageUrl';

export const SEARCH_DELAY = 350;
export const SEARCH_DELAY_LONG = 700;
export const FETCH_DELAY = 350;
export const RESIZE_DELAY = 250;
export const DATE_CHANGE_DELAY = 250;

export const DEFAULT_TABLE_PAGE_SIZE = 10;

/** Supports revision up to 702 */
export const REVISION_ALPHABET = ValueConstants.REVISION_ALPHABET;

export const FIRST_REVISION_BACKGROUND_COLOR_CLASS = 'bg-yellow';
export const OTHER_REVISION_BACKGROUND_COLOR_CLASS = 'bg-red';
export const CURRENT_WORK_ORDER_REVISION_ITEM = '(Unpublished changes)';
export const LATEST_PUBLISHED_WORK_ORDER_REVISION_ITEM = '(Latest Published Revision)';

export const MAX_EQUIPMENT_SPECIFICATION_LENGTH = 8;
export const MAX_EMPLOYEE_NOTIFICATION_MESSAGE_LENGTH = 900; // TODO, NOTE: To help with COVID-19 notifications, it has been raised from 140. Should be reverted if we want to decrease the costs

export const DEFAULT_EMPLOYEE_IMAGE = '/images/elements/ic_employee_default.svg';
export const DEFAULT_EQUIPMENT_IMAGE = '/images/elements/ic_equipment_default.svg';
export const DEFAULT_IMAGE_PLACEHOLDER = '/images/elements/ic_image_placeholder_default.svg';

export const WORK_ORDER_PUBLIC_LINK_CODE_LENGTH = 42;
export const FIELD_REPORT_PUBLIC_LINK_CODE_LENGTH = 42;

export const MAX_NOTIFICATION_AGE_IN_DAYS = 30;

export const SOCKET_TIMEOUT = 30000; // 30 seconds before timing out

export const ACCELIGENT_LOGO = 'https://s3.amazonaws.com/acceligent-public/acceligent_logo.jpeg';

export const HTTP_ACCEPTED_MESSAGE = 'Accepted';

export const NEW_ACTIVATION_CODE_LENGTH = 6;
export const OLD_ACTIVATION_CODE_LENGTH = 8;

const isDevelopment = () => (!process.env.NODE_ENV || process.env.NODE_ENV.toLowerCase().indexOf('dev') === 0);

const getProtocol = () => process.env.USE_SSL === 'true' ? 'https' : 'http';
export const setProtocolIfNeeded = (url: string) => {
	const protocol = getProtocol();
	return !url.startsWith(protocol) ? `${protocol}://${url}` : url;
};
const useWildcards = () => process.env.USE_WILDCARDS === 'true';
export const getHostnameWithAlias = (orgAlias: string) => `${getProtocol()}://${useWildcards() ? `${orgAlias}.${process.env.APP_URL}` : `${process.env.APP_URL}/${orgAlias}`}`;
export const getWebhookUrlWithProtocol = (urlCode: string) => `${getProtocol()}://${useWildcards() && !isDevelopment() ? 'app.' : ''}${process.env.APP_URL}/${urlCode}`; // isDevelopment added for testing locally
export const setProtocolIfMissing = (url: string, protocol: string = 'https') => URL_PROTOCOL_REGEX.test(url) ? url : `${protocol}://${url}`;
export const getShortUrl = (urlCode: string) => {
	if (process.env.REVIEW_APP === 'true') {
		return `${process.env.HEROKU_APP_NAME}.herokuapp.com/${urlCode}`;
	}
	return `${process.env.SHORT_URL}/${urlCode}`;
};

const getApiUrlWithProtocol = (route: string) => `${getProtocol()}://${useWildcards() ? 'app.' : ''}${process.env.APP_URL}/api/1.0/${route}`;

/** factory methods for urls sent in sms, email etc. */
export const RedirectUrlMap = {
	// API
	apiDownloadPublicReportsPdf: (publicLink: string) => getApiUrlWithProtocol(`workOrder/reports/customer/${publicLink}/download`),
	// Client: Auth
	organizationRoot: (orgAlias: string) => `${getHostnameWithAlias(orgAlias)}/`,
	login: (orgAlias: string) => `${getHostnameWithAlias(orgAlias)}/login`,
	phoneLogin: (orgAlias: string) => `${getHostnameWithAlias(orgAlias)}/login?isPhoneForm=true`,
	resetPassword: (orgAlias: string, email: string, activationCode: string) => `${getHostnameWithAlias(orgAlias)}/resetPassword?email=${encodeURIComponent(email)}&activationCode=${encodeURIComponent(activationCode)}`,
	// Client: Other
	workOrderFeedbackPersonal: (orgAlias: string, personalPublicLink: string) => `${getHostnameWithAlias(orgAlias)}/feedback/workOrder/personal/${personalPublicLink}`,
	workRequestFeedback: (orgAlias: string, publicLink: string) => `${getHostnameWithAlias(orgAlias)}/feedback/request/${encodeURIComponent(publicLink)}`,
	publicReport: (orgAlias: string, companyName: string, publicLink: string) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/publicReport/${publicLink}`,
	fieldReport: (orgAlias: string, companyName: string, workOrderId: string) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/fieldReport/workOrder/${workOrderId}`,
	availableEmployeeSmsNotificationConfirmation: (orgAlias: string, personalPublicLink: string) => `${getHostnameWithAlias(orgAlias)}/availableEmployeeSmsNotification/confirmation/${personalPublicLink}`,
	purchaseOrder: (orgAlias: string, companyName: string, purchaseOrderId: number) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/purchaseOrder/${purchaseOrderId}/preview`,
	order: (orgAlias: string, companyName: string, orderId: number) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/order/${orderId}/edit`,
	item: (orgAlias: string, companyName: string, itemId: number) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/resources/item/${itemId}`,
	toolRepair: (orgAlias: string, companyName: string, itemId: number) => `${getHostnameWithAlias(orgAlias)}/company/${removeSpaces(companyName)}/toolRepair/${itemId}/edit`,
} as const;

/**
 * Used at start of URL paths to indicate that we have prepared mobile screen for that URL
 */
export const PREPARED_MOBILE_SCREEN_PATH_PREFIX = '/r/';

export const MAX_BILLABLE_WORK_DEFINITION_FIELDS = 4;
export const MAX_BILLABLE_WORK_INFORMATION_FIELDS = 4;

export const MAX_WR_TRUCK_EQUIPMENT_ADDITIONAL_FIELDS = 2;

/** Size of random generated unique ids */
export const UNIQUE_ID_SIZE = 7;

/** ID for all statuses in dropdown option  */
export const ALL_STATUSES_OPTION_ID = -2;

/** Time when automatic time sheet entries are ended */
export const LONG_AUTOMATIC_TIME_SHEET_ENTRIES_TIME = 36 * 60 * 60 * 1000; // 36h in miliseconds

