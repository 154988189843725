import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type MemberInviteEnum from '@acceligentllc/shared/enums/memberInvite';

import { getUserName } from '@acceligentllc/shared/utils/user';

import type AccountBase from 'ab-domain/models/account/base';

export class W_Account_FindAllForCSV_VM {
	rows: W_Account_FindAllForCSV_VM_Row[];

	constructor(accounts: AccountBase[]) {
		this.rows = W_Account_FindAllForCSV_VM_Row.bulkConstructor(accounts);
	}
}

class W_Account_FindAllForCSV_VM_Row {
	uniqueId: string;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	status: MemberInviteEnum;

	constructor(account: AccountBase) {
		this.uniqueId = account.user.uniqueId;
		this.fullName = getUserName(account.user);
		this.email = account.user.email;
		this.phoneNumber = account.user.phoneNumber;
		this.countryCode = account.user.countryCode;
		this.status = account.user.status;
	}

	static bulkConstructor(accounts: AccountBase[]) {
		return accounts.map(W_Account_FindAllForCSV_VM_Row._mapConstructor);
	}

	private static _mapConstructor(account: AccountBase) {
		return new W_Account_FindAllForCSV_VM_Row(account);
	}
}
