import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import { getShortAddress } from '@acceligentllc/shared/utils/address';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import * as TextUtils from 'ab-utils/text.util';
import * as CodeUtils from 'ab-utils/codes.util';

class JobReportListVM implements SearchableModel {

	static matches = TextUtils.matches;

	workRequestId: number;
	code: string;
	jobCode: string;
	title: Nullable<string>;
	areAllReportsInAccounting: boolean;
	customer: Nullable<string>;
	address: Nullable<string>;
	_desc: string;

	constructor(workRequest: WorkRequestBase) {
		this.workRequestId = workRequest.id;
		this.code = CodeUtils.workRequestCode(workRequest.year, workRequest.code);
		this.jobCode = workRequest.jobCode ?? this.code;
		this.title = workRequest.title;
		this.customer = workRequest.customerCompanyName;
		this.areAllReportsInAccounting = workRequest.workOrders.every(JobReportListVM._isInAccountingApprovedOrHigher);
		this.address = getShortAddress(workRequest.travelLocation);
		this._desc = [this.jobCode, this.title, this.customer, this.address].filter(Boolean).join(' ');
	}

	static bulkConstructor = (jobs: WorkRequestBase[]) => jobs.map(JobReportListVM._constructorMap);

	private static _constructorMap = (job: WorkRequestBase) => new JobReportListVM(job);

	private static _isInAccountingApprovedOrHigher = (workOrder: WorkOrderBase) => (
		(workOrder.reviewLevel === WorkOrderReviewLevel.LEVEL_3
			&& workOrder.reviewStatus === WorkOrderReviewStatus.APPROVED)
		|| workOrder.reviewStatus === WorkOrderReviewStatus.FINAL
	);
}

export default JobReportListVM;
