import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import type AssignableResourceType from '@acceligentllc/shared/enums/assignableResourceType';

import type { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import type EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import type TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';

import * as ReduxUtils from 'ab-utils/reduxForms.util';

import type WorkOrderFM from '../../formModel';

import ResourceLookupItem from './ResourceLookupItem';
import RemoveResourceLookupButton from './RemoveResourceLookupButton';

interface Props {
	disabled?: boolean;
	draggableId: string;
	dueDate: string;
	employees: EmployeeOptionVM[];
	equipment: EquipmentViewModel[];
	temporaryEmployees: TemporaryEmployeeOptionVM[];
	index: number;
	isAllowedToManagePerDiems: boolean;
	isCancelled: boolean;
	loadEmployees: (isLazyLoaded: boolean) => void;
	loadEquipment: (isLazyLoaded: boolean) => void;
	loadTemporaryEmployees: (isLazyLoaded: boolean) => void;
	removeWorkOrderResourceLookup: (index: number) => void;
	resetTimer: () => void;
	resourceLookup: WorkOrderFM['workOrderResourceLookups'][0];
	resourceLookupPropName: string;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
	workOrderId: number;
}

const DraggableResourceLookup: React.FC<Props> = (props: Props) => {
	const {
		disabled,
		draggableId,
		dueDate,
		employees,
		equipment,
		temporaryEmployees,
		index,
		isAllowedToManagePerDiems,
		loadEmployees,
		loadEquipment,
		loadTemporaryEmployees,
		removeWorkOrderResourceLookup,
		resetTimer,
		resourceLookup,
		resourceLookupPropName,
		isCancelled,
		showResourceAssignConfirmationModal,
	} = props;

	return (
		<Draggable
			draggableId={draggableId}
			index={index}
			isDragDisabled={disabled}
		>
			{(draggableProvided) => (
				<div
					ref={draggableProvided.innerRef}
					{...draggableProvided.draggableProps}
					{...draggableProvided.dragHandleProps}
					className="resource-lookup__draggable"
					id={`accqa__wo-edit__resource__${index}`}
				>
					<Row className="row--non-padded">
						<Col className="resource-lookup__draggable--details" sm={12}>
							{!disabled && <span className="icon-drag_indicator" />}
							<ResourceLookupItem
								disabled={!!disabled}
								dueDate={dueDate}
								employees={employees}
								equipment={equipment}
								index={index}
								loadEmployees={loadEmployees}
								loadEquipment={loadEquipment}
								loadTemporaryEmployees={loadTemporaryEmployees}
								resetTimer={resetTimer}
								resourceLookup={resourceLookup}
								resourceLookupPropName={resourceLookupPropName}
								showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
								temporaryEmployees={temporaryEmployees}
							/>
							<RemoveResourceLookupButton
								disabled={!!disabled}
								index={index}
								removeWorkOrderResourceLookup={removeWorkOrderResourceLookup}
							/>
						</Col>
						{resourceLookup.employeeId && isAllowedToManagePerDiems &&
							<Col sm={3} xs={6}>
								<Field
									component={Checkbox}
									extraClass="resource-lookup__per-diem"
									isDisabled={disabled && !isCancelled}
									isStandalone={true}
									label="Per Diem"
									name={`${resourceLookupPropName}.perDiem`}
								/>
							</Col>
						}
						{resourceLookup.employeeId && resourceLookup.perDiem && isAllowedToManagePerDiems &&
							<Col className="resource-lookup__per-diem-amount" sm={5} xs={18}>
								<Field
									className="resource-lookup__per-diem-amount-input"
									component={Input}
									disabled={disabled && !isCancelled}
									format={ReduxUtils.formatDecimalNumber}
									isDollarValue={true}
									isStandalone={true}
									name={`${resourceLookupPropName}.perDiemAmount`}
									normalize={ReduxUtils.normalizeDecimalNumber}
									onValueChange={resetTimer}
									placeholder="0"
									type="text"
								/>
								<div className="resource-lookup__per-diem-amount-label">Amount</div>
							</Col>
						}
					</Row>
				</div>
			)}
		</Draggable>
	);
};

export default DraggableResourceLookup;
