import * as React from 'react';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import DeliverableDataTypeEnum from '@acceligentllc/shared/enums/deliverableDataType';

import * as DeliverableDataActions from 'af-actions/deliverableData';

import type { DeliverableDataListViewModel } from 'ab-viewModels/deliverableData.viewModel';

import type { DeliverableDataRequestModel } from 'ab-requestModels/deliverableData.requestModel';

import DeliverableDataTypeList from './DeliverableDataTypeList';
import Loading from './Loading';

interface DispatchProps {
	deleteStatus: typeof DeliverableDataActions.deleteData;
	create: typeof DeliverableDataActions.create;
	findAll: typeof DeliverableDataActions.findAllForCompany;
	update: typeof DeliverableDataActions.update;
}

type Props = ResolveThunks<DispatchProps>;

interface State {
	data: Nullable<DeliverableDataListViewModel>;
	isLoaded: boolean;
}

class DeliverableData extends React.Component<Props, State> {
	state: State = {
		data: null,
		isLoaded: false,
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		const { findAll } = this.props;
		this.setState(() => ({ isLoaded: false }), async () => {
			const data = await findAll();
			this.setState(() => ({ data, isLoaded: true }));
		});
	};

	create = async (form: DeliverableDataRequestModel) => {
		const { create } = this.props;
		await create(form);
		this.loadData();
	};

	delete = async (form: DeliverableDataRequestModel) => {
		const { deleteStatus } = this.props;

		if (!form.id) {
			throw new Error('No deliverable data provided');
		}
		await deleteStatus(form.id);
		this.loadData();
	};

	update = async (form: DeliverableDataRequestModel) => {
		const { update } = this.props;
		await update(form);
		this.loadData();
	};

	render() {
		const { isLoaded, data } = this.state;
		if (!isLoaded || !data) {
			return <Loading />;
		}
		return (
			<div className="form-segment">
				<Row className="row--padded deliverable-data__container">
					<Col sm={4}>
						<DeliverableDataTypeList
							label="Software"
							list={data[DeliverableDataTypeEnum.DELIVERABLE_SOFTWARE]}
							onCreate={this.create}
							onDelete={this.delete}
							onEdit={this.update}
							type={DeliverableDataTypeEnum.DELIVERABLE_SOFTWARE}
						/>
					</Col>
					<Col sm={4}>
						<DeliverableDataTypeList
							label="Code Standard"
							list={data[DeliverableDataTypeEnum.DELIVERABLE_CODE_STANDARD]}
							onCreate={this.create}
							onDelete={this.delete}
							onEdit={this.update}
							type={DeliverableDataTypeEnum.DELIVERABLE_CODE_STANDARD}
						/>
					</Col>
					<Col sm={4}>
						<DeliverableDataTypeList
							label="File Format"
							list={data[DeliverableDataTypeEnum.DELIVERABLE_FILE_FORMAT]}
							onCreate={this.create}
							onDelete={this.delete}
							onEdit={this.update}
							type={DeliverableDataTypeEnum.DELIVERABLE_FILE_FORMAT}
						/>
					</Col>
					<Col sm={4}>
						<DeliverableDataTypeList
							label="Delivery Method"
							list={data[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_METHOD]}
							onCreate={this.create}
							onDelete={this.delete}
							onEdit={this.update}
							type={DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_METHOD}
						/>
					</Col>
					<Col sm={4}>
						<DeliverableDataTypeList
							label="Delivery Timeline"
							list={data[DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_TIMELINE]}
							onCreate={this.create}
							onDelete={this.delete}
							onEdit={this.update}
							type={DeliverableDataTypeEnum.DELIVERABLE_DELIVERY_TIMELINE}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		deleteStatus: DeliverableDataActions.deleteData,
		create: DeliverableDataActions.create,
		findAll: DeliverableDataActions.findAllForCompany,
		update: DeliverableDataActions.update,
	};
}

export default connect<null, DispatchProps>(null, mapDispatchToProps())(DeliverableData);
