/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';

import { FieldReportTypeStatusEnumIcon } from '@acceligentllc/shared/enums/fieldReportTypeStatus';

import type { RootState } from 'af-reducers';

import * as ReportTypeActions from 'af-actions/reportType';

import CustomModal from 'af-components/CustomModal';

import Dropdown from 'af-fields/Dropdown';

import { ADD_REPORT_TYPE } from 'af-constants/reduxForms';

import type { ReportTypeItemVM } from 'ab-viewModels/reportType/reportTypeList.viewModel';
import type { FieldReportTypeVM } from 'ab-viewModels/fieldReport/fieldReport.viewModel';

import type { UpsertFieldReportTypeRM } from 'ab-requestModels/fieldReport/fieldReport.requestModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { filterMap } from 'ab-utils/array.util';
import { isAllowed } from 'ab-utils/auth.util';

const DUMMY_FIELD_REPORT_TYPE_VM_ID = -1;

interface ReportTypeListItem {
	id?: number;
	name: string;
	typeId: number;
	index: number;
	isRequired: boolean;
}

interface ReportTypeForm {
	reportTypeId: number;
	reportType: ReportTypeItemVM;
}

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	onSave: (fieldReportTypes: UpsertFieldReportTypeRM[]) => void;
	fieldReportTypes: Record<string, FieldReportTypeVM>;
	fieldReportOrder: number[];
	lockedFieldReportTypes: number[];
}

type Props = OwnProps & InjectedFormProps<ReportTypeForm> & ConnectedProps<typeof connector>;

interface State {
	allReportTypeOptions: ReportTypeItemVM[];
	/** NOTE: if an item does not have `typeId`, that's the one where the dropdown is */
	reportTypeList: ReportTypeListItem[];
	showDropdown: boolean;
	startOffset: number;
	endOffset: number;
}

class ReportTypesModal extends React.PureComponent<Props, State> {

	state: State = {
		allReportTypeOptions: [], // set in `fetchOptions`
		showDropdown: false,
		...ReportTypesModal.initializeList(this.props.fieldReportOrder ?? [], this.props.fieldReportTypes),
	};

	/**
	 * Used to compute Dropdown options when needed (on demand).
	 * Memoizes the result for the given parameters.
	 */
	static getAvailableReportTypeOptions = (allReportTypeOptions: ReportTypeItemVM[], reportTypeList: ReportTypeListItem[]) => {
		if (!allReportTypeOptions?.length || !reportTypeList?.length) {
			return [];
		}
		const reportTypeLookup = reportTypeList.reduce(ReportTypesModal.toReportTypeIdLookup, {});
		return allReportTypeOptions.filter((_item) => !reportTypeLookup[_item.id]);
	};

	static toReportTypeIdLookup = (_lookup: { [reportTypeId: number]: true; }, _item: ReportTypeListItem) => {
		_lookup[_item.typeId] = true;
		return _lookup;
	};

	static initializeList = (reportTypeOrder: number[], reportTypes: Record<string, FieldReportTypeVM>) => {
		const list = reportTypeOrder.map((_id, _index) => ReportTypesModal.mapFieldReportType(reportTypes[_id], _index));
		return {
			reportTypeList: list,
			startOffset: 0,
			endOffset: 0,
		};
	};

	static mapFieldReportType = (fieldReportType: FieldReportTypeVM, index: number): ReportTypeListItem => {
		return {
			id: fieldReportType?.id,
			name: fieldReportType?.name,
			typeId: fieldReportType?.typeId,
			index,
			isRequired: fieldReportType?.isRequired,
		};
	};

	static updateIndices = (_item: ReportTypeListItem, _index: number): ReportTypeListItem => ({ ..._item, index: _index });

	componentDidUpdate(prevProps: Props) {
		const { destroy, initialize, showModal, fieldReportTypes, fieldReportOrder } = this.props;

		if (prevProps.showModal && !showModal) {
			// modal closed
			this.setState(() => ({ showDropdown: false, reportTypeList: [] }));
			destroy();
		} else if (!prevProps.showModal && showModal) {
			// modal opened
			initialize({ reportTypeId: undefined });
			this.fetchOptions();
			this.setState(() => ReportTypesModal.initializeList(fieldReportOrder ?? [], fieldReportTypes));
		}

		if (prevProps.fieldReportTypes !== fieldReportTypes && showModal === prevProps.showModal) {
			this.setState(() => ReportTypesModal.initializeList(fieldReportOrder ?? [], fieldReportTypes));
		}
	}

	save = async () => {
		const { onSave } = this.props;
		const { reportTypeList } = this.state;

		const updatedFieldReportTypes: UpsertFieldReportTypeRM[] = filterMap(
			reportTypeList,
			(_item) => !!_item.typeId,
			(_item) => ({
				id: _item.id === DUMMY_FIELD_REPORT_TYPE_VM_ID ? undefined : _item.id,
				typeId: _item.typeId,
				index: _item.index,
			})
		);

		onSave(updatedFieldReportTypes);
		this.close();
	};

	close = () => {
		const { closeModal } = this.props;
		this.setState(() => ({ showDropdown: false }), closeModal);
	};

	onDragEnd = ({ source, destination }: DropResult) => {
		const { startOffset, endOffset, reportTypeList } = this.state;

		if (!destination) {
			return;
		}

		let destinationIndex = destination?.index;
		if (destinationIndex < startOffset) {
			destinationIndex = startOffset;
		} else if (destinationIndex > reportTypeList.length - endOffset - 1) {
			destinationIndex = reportTypeList.length - endOffset - 1;
		}

		this.reorder(source.index, destinationIndex);
	};

	onAddReportTypeClick = () => {
		this.setState(() => ({ showDropdown: true }));
		this.setState(({ reportTypeList, endOffset }) => {
			const result = [...reportTypeList];
			result.splice(reportTypeList.length - endOffset, 0, { index: result.length } as ReportTypeListItem);
			return { showDropdown: true, reportTypeList: result.map(ReportTypesModal.updateIndices) };
		});
	};

	add = async (reportType: ReportTypeItemVM) => {
		const { initialize } = this.props;

		this.setState(({ reportTypeList }) => {
			const result = [...reportTypeList];
			const index = result.findIndex((_item) => !_item.typeId);
			const newItem: ReportTypeListItem = {
				id: DUMMY_FIELD_REPORT_TYPE_VM_ID,
				name: reportType.name,
				typeId: reportType.id,
				index,
				isRequired: false,
			};
			result.splice(index, 1, newItem);
			return {
				showDropdown: false,
				reportTypeList: result,
			};
		});

		initialize({ reportTypeId: undefined });
	};

	remove = (index: number) => {
		this.setState(({ reportTypeList, showDropdown }) => {
			const result = [...reportTypeList];
			const [removedItem] = result.splice(index, 1);

			const isRemovedDropdown = !removedItem.typeId;
			return {
				reportTypeList: result.map(ReportTypesModal.updateIndices),
				showDropdown: isRemovedDropdown ? false : showDropdown,
			};
		});
	};

	reorder = (fromIndex: number, toIndex: number) => {
		this.setState(({ reportTypeList }) => {
			const result = [...reportTypeList];

			const [movedType] = result.splice(fromIndex, 1);
			result.splice(toIndex, 0, movedType);
			return { reportTypeList: result.map(ReportTypesModal.updateIndices) };
		});
	};

	fetchOptions = async () => {
		const { fetchOptionsList: fetchList } = this.props;
		const options = await fetchList();
		this.setState(() => ({ allReportTypeOptions: options }));
	};

	static renderSelectedReportType = (type: ReportTypeItemVM) => {
		return <span>{type.name}</span>;
	};

	static renderOptionReportTypeItem = (type: ReportTypeItemVM) => {
		return (
			<div>
				<div className="text-hidden">{type.name}</div>
				<small>{type.description}</small>
			</div>
		);
	};

	static filterReportType = (type: ReportTypeItemVM, searchText: string) => {
		const text = searchText?.toLowerCase() ?? '';
		return type.name.toLowerCase().includes(text);
	};

	static isItemRemovable = (_item: ReportTypeListItem): boolean => !_item.isRequired;

	renderDraggable = (item: ReportTypeListItem, index: number) => {
		const { lockedFieldReportTypes, hasReorderReportTypesPermission } = this.props;
		const { allReportTypeOptions, reportTypeList } = this.state;

		const isLocked = !!item.id && lockedFieldReportTypes.includes(+item.id);
		const isRemoveEnabled = !isLocked && ReportTypesModal.isItemRemovable(item);
		const isItemDropdown = !item.typeId;
		const isDragDisabled = !hasReorderReportTypesPermission;

		return (
			<Draggable
				draggableId={isItemDropdown ? 'draggable-dropdown' : item.typeId.toString()}
				index={item.index}
				isDragDisabled={isDragDisabled}
				key={index}
			>
				{(provided) => (
					<div
						{...provided?.draggableProps}
						{...provided?.dragHandleProps}
						className="report-type-modal__draggable-item"
						ref={provided?.innerRef}
						style={provided.draggableProps.style}
					>
						{!isDragDisabled && <span className="icon-drag_indicator report-type-modal__draggable-indicator" />}
						{isItemDropdown
							? (
								<Field
									component={Dropdown}
									dropdownClassName="report-type-modal__report-type-dropdown"
									filterable={true}
									filterBy={ReportTypesModal.filterReportType}
									fixed={true}
									id="reportType"
									name="reportTypeId"
									onValueChange={this.add}
									options={ReportTypesModal.getAvailableReportTypeOptions(allReportTypeOptions, reportTypeList)}
									propName="reportType"
									renderMenuItem={ReportTypesModal.renderOptionReportTypeItem}
									renderSelected={ReportTypesModal.renderSelectedReportType}
									valueKey="id"
									withCaret={true}
								/>
							)
							: (
								<div className="report-type-modal__draggable-label">
									<span className={FieldReportTypeStatusEnumIcon.NOT_FILLED} />
									<span>{item.name}</span>
								</div>
							)
						}
						{isRemoveEnabled &&
							<Button
								icon="delete"
								onClick={this.remove.bind(this, item.index)}
								style="link-danger"
								tooltip="Delete"
							/>
						}
					</div>
				)}
			</Draggable>
		);
	};

	renderDroppable = () => {
		const { showDropdown, reportTypeList } = this.state;
		return (
			<Droppable direction="vertical" droppableId="report-type-id">
				{(droppableProvided) => (
					<div ref={droppableProvided.innerRef}>
						{reportTypeList.map(this.renderDraggable)}
						{!showDropdown && (
							<div className="report-type-modal__action-link">
								<Button
									icon="plus"
									label="Add Report Type"
									onClick={this.onAddReportTypeClick}
									style="link"
								/>
							</div>
						)}
					</div>
				)}
			</Droppable>
		);
	};

	render() {
		const { showModal } = this.props;

		return (
			<CustomModal
				className="report-type-modal"
				closeModal={this.close}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={this.close}
					title="Report Types"
				/>
				<CustomModal.Body>
					<DragDropContext onDragEnd={this.onDragEnd}>
						{this.renderDroppable()}
					</DragDropContext>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.close}
						style="secondary"
					/>
					<Button
						label="Save"
						onClick={this.save}
						style="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasReorderReportTypesPermission = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.REORDER_RTS,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		hasReorderReportTypesPermission,
	};
}

function mapDispatchToProps() {
	return {
		fetchOptionsList: ReportTypeActions.findCustomShallowActiveList,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());
const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<ReportTypeForm>({ form: ADD_REPORT_TYPE, enableReinitialize: true })
);

export default enhance(ReportTypesModal);
