import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { TimeSheetWorkTypeDisplayInfo } from '@acceligentllc/shared/enums/timeSheetEntryWorkType';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

interface Props {
	showNotAssignedWarning: boolean;
	overlappedEntriesByWO: Record<string, TimelineEntitesForAccount[]>;
	showOverlaps: boolean;
}

const overlappedEntriesByWOMapper = (overlappedEntriesByWO: Record<string, TimelineEntitesForAccount[]>) => {
	return (woCode: string) => {
		return (
			<React.Fragment key={woCode}>
				{woCode}
				{overlappedEntriesByWO[woCode].map(overlappedEntriesMapper)}
			</React.Fragment>
		);
	};
};

const overlappedEntriesMapper = (oe: (TimelineEntitesForAccount)) => {
	if (oe.type === TimelineEntityType.ENTRY) {
		return (
			<Row className={bemElement('time-sheet-list__row__info__user-name', 'tooltip__entry')}>
				<Col sm={4}>
					{TimeSheetWorkTypeDisplayInfo[oe.entry.workType].label}
				</Col>
				<Col sm={3}>
					<span className={`${bemElement('time-sheet-list__row__info__user-name', 'tooltip__entry__icon')} icon-${TimeSheetWorkTypeDisplayInfo[oe.entry.workType].icon}`} />
				</Col>
				<Col sm={8}>
					{TimeUtils.formatDate(oe.entry.startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)}
				</Col>
				<Col sm={1}>-</Col>
				<Col sm={8}>
					{oe.entry.endTime ? TimeUtils.formatDate(oe.entry.endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : null}
				</Col>
			</Row>
		);
	}
};

const TimeSheetInfoWarningMessage: React.FC<Props> = (props: Props) => {

	const { showNotAssignedWarning, overlappedEntriesByWO, showOverlaps } = props;

	const workOrderCodes = Object.keys(overlappedEntriesByWO);
	const overlapMessage = showOverlaps ? 'Entries overlap on following Work Orders:' : 'Entries overlap with other Work Orders';

	return (
		<div className={bemElement('time-sheet-list__row__info__user-name', 'tooltip')}>
			{showNotAssignedWarning &&
				<div className={bemElement('time-sheet-list__row__info__user-name', 'tooltip__title-user')}>
					<span className="icon-warning" />
					User is no longer assigned to Work Order
				</div>
			}
			{workOrderCodes.length > 0 &&
				<div className={bemElement('time-sheet-list__row__info__user-name', 'tooltip__title-entry')}>
					<span className="icon-warning" />
					{overlapMessage}
				</div>
			}
			{showOverlaps && workOrderCodes.map(overlappedEntriesByWOMapper(overlappedEntriesByWO))}
		</div>
	);
};

export default TimeSheetInfoWarningMessage;
