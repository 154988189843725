import { capitalize } from 'ab-utils/text.util';

import { FAKE_ACCOUNT_DATA } from '@acceligentllc/shared/constants/value';
import { getUserName } from '@acceligentllc/shared/utils/user';

import type Priority from '@acceligentllc/shared/enums/priority';
import { stateAbbreviation } from '@acceligentllc/shared/enums/state';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';

import { formatDate, toUtcDate } from '@acceligentllc/shared/utils/time';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type AccountBase from 'ab-domain/models/account/base';
import type UserBase from 'ab-domain/models/user/base';

import JobStatusVM from './jobStatus/option.viewModel';
import { AddressVM } from './workRequest/workRequestDetailsUpsert.viewModel';

class UpdatedByAccountViewModel implements UpdatedByViewModel {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}
}

interface UpdatedByViewModel {
	accountId?: number;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}

const CSV_HEADER_KEYS = [
	'Finished',
	'Status',
	'Job number',
	'Customer Company',
	'Job Title',
	'City',
	'State',
	'Calculated Start Date',
	'Guaranteed Completion Date',
	'Expected Completion Date',
	'Est Total Price',
	'Office',
	'Division',
	'Project Manager',
	'Priority',
	'Updated',
];

export default class JobTableCSVViewModel {
	id: number;
	jobCode: string;
	customerCompanyName: Nullable<string>;
	projectManager: Nullable<string>;
	status: WorkRequestStatus;
	jobStatus: Nullable<JobStatusVM>;
	updatedAt: Date;
	updatedBy: UpdatedByViewModel;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	calculatedStartDate: Nullable<Date>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	priority: Priority;
	estimateTotal: Nullable<number>;
	title: Nullable<string>;
	publicLink: Nullable<string>;
	estTotalPrice?: number;
	office: Nullable<string>;
	division: Nullable<string>;
	travelLocation: Nullable<AddressVM>;

	constructor(workRequest: WorkRequestBase) {
		const _projectManager: Nullable<UserBase> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.status = workRequest.status;
		this.jobStatus = workRequest.jobStatus ? new JobStatusVM(workRequest.jobStatus) : null;
		this.startDate = workRequest.startDate;
		this.calculatedStartDate = workRequest.calculatedStartDate ? toUtcDate(workRequest.calculatedStartDate, TimeFormat.DB_DATE_ONLY) : null;
		this.title = workRequest.title;
		this.updatedBy = new UpdatedByAccountViewModel(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt;
		this.publicLink = workRequest.publicLink;
		this.office = workRequest?.office?.nickname ?? null;
		this.division = workRequest.division?.name ?? null;

		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.targetCompletionDate = workRequest?.targetCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;
		this.priority = workRequest?.priority;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;

		this.customerCompanyName = workRequest.customerCompanyName;
		this.travelLocation = workRequest.travelLocation ? new AddressVM(workRequest.travelLocation) : null;
	}

	private static _constructorMap = (_job) => new JobTableCSVViewModel(_job);

	static bulkConstructor = (jobs: WorkRequestBase[]) => jobs.map(JobTableCSVViewModel._constructorMap);

	static toCSVData(viewModels: JobTableCSVViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_KEYS];

		const rows: string[][] = viewModels.map((_entry: JobTableCSVViewModel) => {
			const _row: string[] = [
				_entry?.status === WorkRequestStatus.FINISHED ? 'Yes' : 'No',
				_entry?.jobStatus?.name ?? 'None',
				_entry?.jobCode,
				_entry?.customerCompanyName ?? '-',
				_entry?.title ?? '',
				_entry?.travelLocation?.city ?? '-',
				(_entry?.travelLocation?.state && stateAbbreviation[_entry.travelLocation.state]) ?? _entry?.travelLocation?.state ?? '-',
				_entry?.calculatedStartDate ? formatDate(_entry.calculatedStartDate) : '-',
				_entry?.guaranteedCompletionDate ? formatDate(_entry.guaranteedCompletionDate) : '-',
				_entry?.targetCompletionDate ? formatDate(_entry.targetCompletionDate) : '-',
				_entry?.estimateTotal?.toString() ?? '-',
				_entry?.office ?? '-',
				_entry?.division ?? '-',
				_entry?.projectManager ?? '-',
				capitalize(_entry?.priority) ?? '-',
				`${formatDate(_entry?.updatedAt, TimeFormat.FULL_DATE)} by ${_entry?.updatedBy?.fullName}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}
