import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import type { Option } from 'react-select/src/filters';
import { useRouteMatch } from 'react-router';

import ToolStatus, { ToolStatusLabel } from '@acceligentllc/shared/enums/toolStatus';
import ToolState, { ToolStateLabel } from '@acceligentllc/shared/enums/toolState';

import type LocationOptionVM from 'ab-viewModels/location/option.viewModel';
import type ListVM from 'ab-viewModels/tool/list.viewModel';

import * as ToolTypeAction from 'af-actions/toolType';
import * as LocationAction from 'af-actions/location';
import * as ToolAction from 'af-actions/tool';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';
import AsyncSelect from 'af-fields/AsyncSelect';

import SubmitButton from 'af-components/SubmitButton';
import BackButton from 'af-components/BackButton';
import Label from 'af-components/LockedValue/Label';
import StatusLabel from 'af-components/StatusLabel';

import type FormModel from './formModel';

import styles from './styles.module.scss';

interface PathParams {
	id?: string;
}

type FormProps = InjectedFormProps<FormModel>;

type OptionType = {
	label: string;
	value: number;
};

interface OwnProps {
	onSubmit: (form: FormModel) => Promise<void>;
	formActionWrapper: {
		selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
	};
	status: ToolStatus;
	state: ToolState;
}

type Props = OwnProps & ConnectedProps<typeof connector> & FormProps;

const getToolTypeValue = (option: { label: string; value: number; }) => option.value;
const getToolTypeLabel = (option: { label: string; value: number; }) => option.label;
const formatToolTypeLabel = (option: { label: string; value: number; }) => <span>{option.label}</span>;

const getLocationOptionValue = (option: LocationOptionVM) => `${option.id}`;
const getLocationOptionLabel = (option: LocationOptionVM) => option.nickname;
const formatLocationOptionLabel = (option: LocationOptionVM) => <div>{option.nickname}</div>;
const getTextForLocation = (data: LocationOptionVM) => `${data.nickname}`;

export const filterLocations = (option: Option, text: string) => {
	const loweredText = text ? text.toLowerCase() : '';
	const searchableText = getTextForLocation(option.data);
	return searchableText.toLowerCase().includes(loweredText);
};

const LocationSelect = Select as unknown as new () => Select<LocationOptionVM>;

const ToolForm = (props: Props) => {
	const {
		handleSubmit,
		submitting,
		invalid,
		onSubmit,
		findToolType,
		findAllLocations,
		createToolType,
		change,
		findTakenSerialNumbers,
		status = ToolStatus.AVAILABLE,
		state = ToolState.ACTIVE,
	} = props;

	const match = useRouteMatch<PathParams>();

	const id = match.params.id;

	const [loading, setLoading] = React.useState(false);
	const [toolTypes, setToolTypes] = React.useState<{ label: string; value: number; }[]>([]);
	const [locations, setLocations] = React.useState<LocationOptionVM[]>([]);
	const [takenSerialNumbers, setTakenSerialNumbers] = React.useState<ListVM[]>([]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				const [
					_toolTypes,
					_locations,
					_takenSerialNumbers,
				] = await Promise.all([
					findToolType(),
					findAllLocations(),
					findTakenSerialNumbers(),
				]);

				setToolTypes(_toolTypes.map((_toolType) => ({ label: _toolType.name, value: _toolType.id })));
				setLocations(_locations);
				setTakenSerialNumbers(_takenSerialNumbers);
				setLoading(false);
			};
			loadStuff();
		}
	});

	const submit = async (form: FormModel) => {
		await onSubmit(form);
	};

	const onToolTypeCreate = React.useCallback(async (name: string) => {
		const newToolType = await createToolType({ name });
		setToolTypes([...toolTypes, { label: newToolType.name, value: newToolType.id }]);
		change('toolType', { label: newToolType.name, value: newToolType.id });
	}, [change, createToolType, toolTypes]);

	const onToolTypeClear = React.useCallback(() => {
		change('toolType', null);
	}, [change]);

	const getNewToolType = React.useCallback((value: string, label: string) => {
		return { id: +value, name: label };
	}, []);

	const isNewToolTypeValid = React.useCallback((
		inputValue: string,
		options: OptionType[]
	) => {
		const trimmedValue = inputValue.trim();

		if (!trimmedValue) {
			return false;
		}
		return !options.some((_item) => _item.label.toLowerCase().includes(inputValue.toLowerCase()));
	}, []);

	const filterToolType = React.useCallback(async (key: string) => {
		if (!key) {
			return toolTypes;
		}
		return toolTypes.filter((_item) => {
			const name = _item.label.toLowerCase();
			return name.includes(key.toLowerCase());
		});
	}, [toolTypes]);

	const onLocationChange = React.useCallback((location: LocationOptionVM) => {
		if (!location) {
			change('location', null);
		}
	}, [change]);

	const checkUniqueSerialNumber = React.useCallback((value: string) => {
		if (takenSerialNumbers.some((_tool) => _tool.serialNumber === value && (!id || +id !== _tool.id))) {
			return 'Serial Number already taken';
		}
	}, [takenSerialNumbers, id]);

	return (
		<form onSubmit={handleSubmit(submit)}>
			<div className="form-box">
				<Row className="row--flex">
					<Col md={12}>
						<Row className="row--padded">
							<Col md={12}>
								<Field
									component={Input}
									label="Serial Number *"
									name="serialNumber"
									placeholder="Enter Serial number"
									type="text"
									validate={checkUniqueSerialNumber}
								/>
							</Col>
							<Col md={12}>
								<Field
									allowNew={true}
									component={AsyncSelect}
									formatOptionLabel={formatToolTypeLabel}
									getNewOptionData={getNewToolType}
									getOptionLabel={getToolTypeLabel}
									getOptionValue={getToolTypeValue}
									isValidNewOption={isNewToolTypeValid}
									label="Tool Type *"
									name="toolType"
									onClear={onToolTypeClear}
									onCreateNew={onToolTypeCreate}
									onSearch={filterToolType}
									options={toolTypes}
									placeholder=""
								/>
							</Col>

							<Col md={12}>
								<Label className={styles.label} label={'Status'} />
								<div className={styles['status-value']}>
									<StatusLabel
										grey={state === ToolState.INACTIVE}
										isAvailable={state === ToolState.ACTIVE}
										label={ToolStateLabel[state]}
									/>
								</div>
							</Col>
							<Col md={12}>
								<Label className={styles.label} label={'Status'} />
								<div className={styles['status-value']}>
									<StatusLabel
										isAvailable={status === ToolStatus.AVAILABLE}
										label={ToolStatusLabel[status]}
									/>
								</div>
							</Col>
							<Col md={12}>
								<Field
									component={LocationSelect}
									filterOption={filterLocations}
									formatOptionLabel={formatLocationOptionLabel}
									getOptionLabel={getLocationOptionLabel}
									getOptionValue={getLocationOptionValue}
									label="Home Location *"
									name="location"
									onValueChange={onLocationChange}
									options={locations}
									placeholder="Enter Home Location"
								/>
							</Col>
							<Col md={12}>
								<Field
									component={Input}
									label="Manufacturer *"
									name="manufacturer"
									placeholder="Enter Manufacturer Name"
									type="text"
								/>
							</Col>
							<Col md={12}>
								<Field
									component={Input}
									label="Model Number *"
									name="modelNumber"
									placeholder="Enter Model Number"
									type="text"
								/>
							</Col>
							<Col md={12}>
							</Col>
						</Row>
					</Col>
					<Col md={12}>
						<Row className="row--padded">
							<Col md={24}>
								<Field
									component={Textarea}
									controlCursor={true}
									label="Note"
									name="note"
									rows={6}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="row--submit">
					<BackButton />
					<SubmitButton
						disabled={invalid}
						reduxFormSubmitting={submitting}
						variant="primary"
					/>
				</Row>
			</div>
		</form>
	);
};

function mapDispatchToProps() {
	return {
		findAllLocations: LocationAction.findList,
		findToolType: ToolTypeAction.findList,
		createToolType: ToolTypeAction.create,
		findTakenSerialNumbers: ToolAction.findTakenSerialList,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(ToolForm);
