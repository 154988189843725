import CDLStatus from '@acceligentllc/shared/enums/cdlStatus';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import type WorkOrderDirectoryBase from 'ab-domain/models/workOrderDirectory/base';

import { getShortAddress } from '@acceligentllc/shared/utils/address';
import { getUserName } from '@acceligentllc/shared/utils/user';
import { parseDirectoryPath } from '@acceligentllc/shared/utils/blobStorage';
import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeOptionUtils from '@acceligentllc/shared/utils/timeOption';

import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type ContactBase from 'ab-domain/models/contact/base';
import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';

import * as BlobStorageUtils from 'ab-utils/blobStorage.util';
import * as CodeUtils from 'ab-utils/codes.util';
import * as ContactUtils from 'ab-utils/contact.util';
import * as LocationUtils from 'ab-utils/location.util';
import * as PhoneUtils from 'ab-utils/phone.util';

import { CUSTOMER_SERVICE_NAME } from 'ab-common/constants/value';

export class W_WorkOrder_FindPublicConfirmation_VM {
	code: Nullable<string>;
	dueDate: string;
	revision: string;
	statusBasedRevision: string;
	crewNumber: number;
	workStart: string;
	workEnd: string;
	shift: string;

	jobCustomerCompany: Nullable<string>;
	jobTitle: Nullable<string>;
	jobTravelLocationShort: Nullable<string>;
	jobOffice: Nullable<string>;
	jobDivision: Nullable<string>;
	jobNote: Nullable<string>;

	crewTypeName: Nullable<string>;
	crewTypeColor: Nullable<ColorPalette>;

	workOrderNotes: Nullable<string>;
	scopeOfWork: Nullable<string>;

	projectManagerName: Nullable<string>;
	projectManagerPhoneNumber: Nullable<string>;
	supervisorName: Nullable<string>;
	supervisorPhoneNumber: Nullable<string>;
	siteContactName: Nullable<string>;
	siteContactStreet: Nullable<string>;
	siteContactRole: Nullable<string>;
	siteContactCompanyName: Nullable<string>;
	siteContactEmails: Nullable<string>[];
	siteContactPhoneNumbers: Nullable<string>[];

	workLocations: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation[];

	workOrderResources: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[];

	workOrderAttachmentsFromDirectories: Nullable<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[]>;

	updatedAt: string;
	updatedBy: string;

	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;

	public static async initialize(data: WorkOrderBase | WorkOrderRevisionBase, isRevision: boolean): Promise<W_WorkOrder_FindPublicConfirmation_VM> {
		const {
			code,
			shift,
			jobCustomerCompany,
			jobTitle,
			jobTravelLocationShort,
			jobOffice,
			jobDivision,
			jobNote,
			crewTypeName,
			crewTypeColor,
			projectManagerName,
			projectManagerPhoneNumber,
			supervisorName,
			supervisorPhoneNumber,
			siteContactName,
			siteContactRole,
			siteContactStreet,
			siteContactCompanyName,
			siteContactEmails,
			siteContactPhoneNumbers,
			workLocations,
			workOrderResources,
			updatedAt,
			updatedBy,
			deliverableSoftware,
			deliverableCodeStandard,
			deliverableFileFormat,
			workOrderAttachmentsFromDirectories,
			statusBasedRevision,
		} = isRevision
				? await W_WorkOrder_FindPublicConfirmation_VM_SharedData.initializeFromWorkOrderRevision(data as WorkOrderRevisionBase)
				: await W_WorkOrder_FindPublicConfirmation_VM_SharedData.initializeFromWorkOrder(data as WorkOrderBase);

		return {
			code,
			statusBasedRevision,
			dueDate: TimeUtils.formatDate(data.dueDate, TimeFormatEnum.DAY_OF_WEEK_WITH_DATE_AND_YEAR),
			revision: CodeUtils.revisionCode(data.revision),
			crewNumber: data.code,
			workStart: TimeOptionUtils.format(data.timeToStart),
			workEnd: TimeOptionUtils.format(data.timeToEnd),
			shift,
			workOrderNotes: data.notes,
			scopeOfWork: data.scopeOfWork,
			jobCustomerCompany,
			jobTitle,
			jobTravelLocationShort,
			jobOffice,
			jobDivision,
			jobNote,
			deliverableSoftware,
			deliverableCodeStandard,
			deliverableFileFormat,
			crewTypeName,
			crewTypeColor,
			projectManagerName,
			projectManagerPhoneNumber,
			supervisorName,
			supervisorPhoneNumber,
			siteContactName,
			siteContactRole,
			siteContactStreet,
			siteContactCompanyName,
			siteContactEmails,
			siteContactPhoneNumbers,
			workLocations,
			workOrderResources,
			updatedAt,
			updatedBy,
			workOrderAttachmentsFromDirectories,
		};
	}
}

class W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment {
	name: string;
	size: number;
	url: string;

	public static async initialize(data: WorkOrderDirectoryBase[]): Promise<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[]> {
		return await Promise.all(data.flatMap((_workOrderDirectory) => {
			return (_workOrderDirectory.directory.attachmentDirectories ?? []).map(async (_attDirectory) => {
				const url = await BlobStorageUtils.generatePresignedGetUrl(
					parseDirectoryPath(_attDirectory.attachment.storageContainer),
					_attDirectory.attachment.storageName
				);
				return {
					name: _attDirectory.attachment.name,
					size: _attDirectory.attachment.size,
					url,
				};
			});
		}));
	}
}

class W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource {
	name: string;
	description: Nullable<string>;
	color: Nullable<ColorPalette>;
	type: ResourceType;
	noCDL: boolean;
	isAvailable: boolean;

	static mapFromWorkOrderResources(data: WorkOrderBase): W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[] {
		return data.workOrderResourceLookups.reduce<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[]>((_acc, _resourceLookup) => {
			const _employee = _resourceLookup.workOrderEmployee?.employee;
			const _equipment = _resourceLookup.workOrderEquipment?.equipment;
			const _temporaryEmployee = _resourceLookup.workOrderTemporaryEmployee?.temporaryEmployee;

			if (_employee) {
				_acc.push({
					name: getUserName(_employee.account?.user),
					description: _employee.wageRate?.type,
					color: _employee.account?.location?.color ?? null,
					type: 'LABOR' as ResourceType,
					noCDL: _employee.cdlStatus === CDLStatus.NO_CDL,
					isAvailable: true,
				});
			} else if (_equipment) {
				_acc.push({
					name: _equipment.code,
					description: _equipment.specification,
					color: _equipment.equipmentCost.category.categoryColor ?? null,
					type: 'EQUIPMENT' as ResourceType,
					noCDL: false,
					isAvailable: _equipment?.dailyEquipmentStatus?.[0]?.equipmentStatus?.available ?? true,
				});
			} else if (_temporaryEmployee) {
				_acc.push({
					name: getUserName(_temporaryEmployee.account?.user),
					description: _temporaryEmployee.agency?.name,
					color: _temporaryEmployee.agency?.color,
					type: 'TEMPORARY LABOR' as ResourceType,
					noCDL: false,
					isAvailable: true,
				});
			}
			return _acc;
		}, []);
	}

	static mapFromWorkOrderRevisionResources(data: WorkOrderRevisionBase): W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[] {
		return data.workOrderResourceLookups.reduce(
			(
				_acc: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[],
				_resourceLookup
			) => {
				if (_resourceLookup.employeeId) {
					_acc.push({
						name: _resourceLookup.name,
						description: '',
						color: _resourceLookup.color,
						type: 'LABOR' as ResourceType,
						isAvailable: _resourceLookup.isAvailable ?? true,
					} as W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource);
				} else if (_resourceLookup.equipmentId) {
					_acc.push({
						name: _resourceLookup.name,
						description: '',
						color: _resourceLookup.color,
						type: 'EQUIPMENT' as ResourceType,
						isAvailable: _resourceLookup.isAvailable ?? true,
					} as W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource);
				} else if (_resourceLookup.temporaryEmployeeId) {
					_acc.push({
						name: _resourceLookup.name,
						description: '',
						color: _resourceLookup.color,
						type: 'TEMPORARY LABOR' as ResourceType,
						noCDL: false,
						isAvailable: _resourceLookup.isAvailable ?? true,
					} as W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource);
				}
				return _acc;
			}, []);
	}
}

class W_WorkOrder_FindPublicConfirmation_VM_SharedData {
	code: Nullable<string>;
	shift: string;
	jobCustomerCompany: Nullable<string>;
	jobTitle: Nullable<string>;
	jobTravelLocationShort: string;
	jobOffice: Nullable<string>;
	jobDivision: Nullable<string>;
	jobNote: Nullable<string>;
	crewTypeName: Nullable<string>;
	crewTypeColor: Nullable<ColorPalette>;
	projectManagerName: Nullable<string>;
	projectManagerPhoneNumber: Nullable<string>;
	supervisorName: Nullable<string>;
	supervisorPhoneNumber: Nullable<string>;
	siteContactName: Nullable<string>;
	siteContactRole: Nullable<string>;
	siteContactStreet: Nullable<string>;
	siteContactCompanyName: Nullable<string>;
	siteContactEmails: Nullable<string>[];
	siteContactPhoneNumbers: Nullable<string>[];
	workLocations: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation[];
	workOrderResources: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource[];
	updatedAt: string;
	updatedBy: string;
	deliverableSoftware: Nullable<string>;
	deliverableCodeStandard: Nullable<string>;
	deliverableFileFormat: Nullable<string>;
	workOrderAttachmentsFromDirectory?: W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[];
	workOrderAttachmentsFromDirectories: Nullable<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[]>;
	statusBasedRevision: string;

	public static async initializeFromWorkOrder(data: WorkOrderBase): Promise<W_WorkOrder_FindPublicConfirmation_VM_SharedData> {
		const code = data.workRequest.jobCode;
		const shift = data.shift?.name;

		const jobCustomerCompany = data.workRequest.customerCompanyName;
		const jobTitle = data.workRequest.title;
		const jobTravelLocationShort = getShortAddress(data.workRequest.travelLocation);
		const jobOffice = data.workRequest.office?.nickname;
		const jobDivision = data.workRequest.division?.name;
		const jobNote = data.workRequest.requestJobNote;
		const deliverableSoftware = data.workRequest.deliverableSoftware?.name;
		const deliverableCodeStandard = data.workRequest.deliverableCodeStandard?.name;
		const deliverableFileFormat = data.workRequest.deliverableFileFormat?.name;

		const crewTypeName = data.crewType ? data.crewType.name : data.customCrewType;
		const crewTypeColor = data.crewType?.color;

		const projectManagerName = data.projectManager && getUserName(data.projectManager.account.user);
		const projectManagerPhoneNumber = data.projectManager && PhoneUtils.formatPhoneNumber(
			data.projectManager.account.user.phoneNumber,
			data.projectManager.account.user.countryCode
		);
		const supervisorName = data.supervisor && getUserName(data.supervisor.account.user);
		const supervisorPhoneNumber = data.supervisor && PhoneUtils.formatPhoneNumber(
			data.supervisor.account.user.phoneNumber,
			data.supervisor.account.user.countryCode
		);

		const {
			siteContactName,
			siteContactRole,
			siteContactStreet,
			siteContactCompanyName,
			siteContactEmails,
			siteContactPhoneNumbers,
		} = W_WorkOrder_FindPublicConfirmation_VM_SharedData.mapFromWorkOrderSiteContact(data.siteContact);

		const workLocations = W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation.mapFromWorkOrderWorkLocation(data);
		const workOrderResources = W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource.mapFromWorkOrderResources(data);

		let workOrderAttachmentsFromDirectories: Nullable<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[]> = null;
		if (data.workOrderDirectories) {
			workOrderAttachmentsFromDirectories =
				await W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment.initialize(data.workOrderDirectories);
		}

		const updatedBy = data.updatedBy ? getUserName(data.updatedBy.user) : CUSTOMER_SERVICE_NAME;

		return {
			code,
			shift,
			jobCustomerCompany,
			jobTitle,
			jobTravelLocationShort,
			jobOffice: jobOffice ?? null,
			jobDivision: jobDivision ?? null,
			jobNote,
			crewTypeName,
			crewTypeColor: crewTypeColor ?? null,
			projectManagerName,
			projectManagerPhoneNumber,
			supervisorName,
			supervisorPhoneNumber,
			siteContactName: siteContactName ?? null,
			siteContactRole: siteContactRole ?? null,
			siteContactStreet: siteContactStreet ?? null,
			siteContactCompanyName: siteContactCompanyName ?? null,
			siteContactEmails: siteContactEmails ?? [],
			siteContactPhoneNumbers: siteContactPhoneNumbers ?? [],
			workLocations: workLocations ?? [],
			workOrderResources,
			workOrderAttachmentsFromDirectories,
			updatedAt: TimeUtils.formatDate(data.updatedAt, TimeFormatEnum.FULL_DATE),
			updatedBy,
			deliverableSoftware: deliverableSoftware ?? null,
			deliverableCodeStandard: deliverableCodeStandard ?? null,
			deliverableFileFormat: deliverableFileFormat ?? null,
			statusBasedRevision: data.statusBasedRevision,
		};
	}

	public static async initializeFromWorkOrderRevision(data: WorkOrderRevisionBase): Promise<W_WorkOrder_FindPublicConfirmation_VM_SharedData> {
		const code = data.jobCode;
		const shift = data.shiftName;

		const jobCustomerCompany = data.customerName;
		const jobTitle = data.jobTitle;
		const jobTravelLocationShort = getShortAddress(data.workOrder.workRequest.travelLocation);
		const jobOffice = data.officeNickname;
		const jobDivision = data.divisionName;
		const jobNote = data.jobNotes;

		const deliverableSoftware = data.deliverableSoftware;
		const deliverableCodeStandard = data.deliverableCodeStandard;
		const deliverableFileFormat = data.deliverableFileFormat;

		const crewTypeName = data.crewTypeName;
		const crewTypeColor = data.crewTypeColor;

		const projectManagerName = data.projectManager?.fullName;
		const projectManagerPhoneNumber = data.projectManager && PhoneUtils.formatPhoneNumber(data.projectManager.phoneNumber, data.projectManager.countryCode);
		const supervisorName = data.supervisor?.fullName;
		const supervisorPhoneNumber = data.supervisor && PhoneUtils.formatPhoneNumber(data.supervisor.phoneNumber, data.supervisor.countryCode);

		const contactFromRevision = data.siteContact
			? W_WorkOrder_FindPublicConfirmation_VM_SharedData.mapFromWorkOrderRevisionSiteContact(data.siteContact)
			: null;

		const workLocations = W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation.mapFromWorkOrderRevisionWorkLocation(data);

		const workOrderResources = W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationResource.mapFromWorkOrderRevisionResources(data);

		const updatedBy = data.createdBy ? getUserName(data.createdBy.user) : CUSTOMER_SERVICE_NAME;

		let workOrderAttachmentsFromDirectories: Nullable<W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationAttachment[]> = null;
		if (!!data.attachments?.length) {
			workOrderAttachmentsFromDirectories =
				await Promise.all(data.attachments.map(async (_att) => ({
					name: _att.name,
					size: _att.size,
					url: await BlobStorageUtils.generatePresignedGetUrl(
						parseDirectoryPath(_att.storageContainer),
						_att.storageName
					),
				})));
		}

		return {
			code,
			shift,
			jobCustomerCompany,
			jobTitle,
			jobTravelLocationShort,
			jobOffice,
			jobDivision,
			jobNote,
			crewTypeName,
			crewTypeColor,
			projectManagerName: projectManagerName ?? null,
			projectManagerPhoneNumber,
			supervisorName: supervisorName ?? null,
			supervisorPhoneNumber,
			siteContactName: contactFromRevision?.siteContactName ?? null,
			siteContactRole: contactFromRevision?.siteContactRole ?? null,
			siteContactStreet: contactFromRevision?.siteContactStreet ?? null,
			siteContactCompanyName: contactFromRevision?.siteContactCompanyName ?? null,
			siteContactEmails: contactFromRevision?.siteContactEmails ?? [],
			siteContactPhoneNumbers: contactFromRevision?.siteContactPhoneNumbers ?? [],
			workLocations: workLocations ?? [],
			workOrderResources,
			workOrderAttachmentsFromDirectories,
			updatedAt: TimeUtils.formatDate(data.createdAt, TimeFormatEnum.FULL_DATE),
			updatedBy,
			deliverableSoftware,
			deliverableCodeStandard,
			deliverableFileFormat,
			statusBasedRevision: data.statusBasedRevision && data.statusBasedRevision > 1 ? `${data.statusBasedRevision - 1}` : '',
		};
	}

	static mapFromWorkOrderSiteContact = (siteContact: Nullable<ContactLookupBase>) => {
		if (!siteContact) {
			return {};
		}

		const _contact = siteContact.contact ?? {} as ContactBase;
		const siteContactName = _contact.fullName;
		const siteContactCompanyName = _contact.companyName;
		const siteContactRole = _contact.title;

		let siteContactStreet = '';
		if (siteContact.contactLookupAddresses?.length) {
			const contactAddressId = siteContact.contactLookupAddresses[0].contactAddressId;
			const selectedAddress = _contact.addresses?.find(({ id }) => id === contactAddressId);
			siteContactStreet = selectedAddress?.address?.street ?? '';
		}
		const { emails, phoneNumbers } = ContactUtils.getActiveContactMethods<string, string>(
			siteContact,
			(cm: ContactMethodBase) => PhoneUtils.formatPhoneNumber(cm.value, cm.countryCode)!,
			(cm: ContactMethodBase) => cm.value
		);

		return {
			siteContactName,
			siteContactRole,
			siteContactCompanyName,
			siteContactStreet,
			siteContactEmails: emails,
			siteContactPhoneNumbers: phoneNumbers,
		};
	};

	static mapFromWorkOrderRevisionSiteContact = (siteContact: NonNullable<WorkOrderRevisionBase['siteContact']>) => {
		const siteContactName = siteContact.name;
		const siteContactRole = siteContact.companyRole;
		const siteContactCompanyName = siteContact.companyName;
		const siteContactStreet = siteContact.addresses?.[0]?.street;
		const siteContactEmails = (siteContact.emails ?? []).map((_contactMethod) => _contactMethod.value);
		const siteContactPhoneNumbers = (siteContact.phones ?? []).map((_contactMethod) =>
			PhoneUtils.formatPhoneNumber(_contactMethod.value, _contactMethod.countryCode ?? null)
		);

		return {
			siteContactName,
			siteContactRole,
			siteContactCompanyName,
			siteContactStreet,
			siteContactEmails,
			siteContactPhoneNumbers,
		};
	};
}

class W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation {
	street: string;
	mapLink: Nullable<string>;

	static mapFromWorkOrderWorkLocation = (data: WorkOrderBase): W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation[] => {
		return data.addresses.map(({ address }) => {
			const mapLink = LocationUtils.generateGoogleMapLink(address.latitude, address.longitude);
			const street = mapLink ? address.street : `${address.street}, ${address.locality}, ${address.aa1}, ${address.country}`;
			return {
				street,
				mapLink,
			};
		});
	};

	static mapFromWorkOrderRevisionWorkLocation = (
		data: WorkOrderRevisionBase
	): W_WorkOrder_FindPublicConfirmation_VM_WorkOrderConfirmationWorkLocation[] | undefined => {
		return data.addresses?.map((_address) => {
			const mapLink = LocationUtils.generateGoogleMapLink(_address.latitude, _address.longitude);
			const street = mapLink ? _address.streetAddress : `${_address.streetAddress}, ${_address.locality}, ${_address.aa1}, ${_address.country}`;
			return {
				street,
				mapLink,
			};
		});
	};

}

type ResourceType = 'LABOR' | 'EQUIPMENT' | 'TEMPORARY LABOR';

