import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';

import type CreateRM from 'ab-requestModels/item/upsert.requestModel';

import type { W_Item_FindById_VM } from 'ab-api/web/item/findById';

class ItemCategoryFM {
	itemCategoryId: number;
	name: string;
}

export interface DepartmentFM {
	id: number;
	name: string;
	includeDepartment: boolean;
	currentStock: number;
	minimumStock: number;
	maximumStock: number;
}

class LocationFM {
	[id: number]: DepartmentFM[];
}

class VendorFM {
	label: string;
	value: number;
}

interface CrewTypeFM {
	id: number;
	name: string;
	color: ColorPalette;
}

class FormModel {
	id: number;
	name: string;
	manufacturer: string;
	modelNumber: string;
	itemCategories: ItemCategoryFM[];
	description: Nullable<string>;
	stockRegularly: boolean;
	vendor: Nullable<VendorFM>;
	vendorPartNumber: Nullable<string>;
	vendorUrl: Nullable<string>;
	packageType: Nullable<string>;
	packageQuantity: Nullable<string>;
	vendorPrice: Nullable<number>;
	note: Nullable<string>;
	location: LocationFM;
	imageUrl: Nullable<string>;
	crewTypeId: Nullable<number>;
	crewType: Nullable<CrewTypeFM>;

	private static toDepartmentRN(list: CreateRM['departments'], department: DepartmentFM): CreateRM['departments'] {
		if (department.includeDepartment) {
			list.push({
				departmentId: department.id,
				quantity: department.currentStock,
				maximumQuantity: department.maximumStock,
				minimumQuantity: department.minimumStock,
			});
		}
		return list;
	}

	private static locationToDepartmentRM(location: LocationFM): CreateRM['departments'] {
		return Object.keys(location).reduce((_list, _location) => {
			_list.push(...location[_location].reduce(FormModel.toDepartmentRN, [] as CreateRM['departments']));
			return _list;
		}, [] as CreateRM['departments']);
	}

	static toRequestModel(form: FormModel): CreateRM {
		return {
			name: form.name,
			manufacturer: form.manufacturer,
			modelNumber: form.modelNumber,
			itemCategories: form.itemCategories,
			imageUrl: form.imageUrl,
			deleteImage: !form.imageUrl,
			description: form.description,
			stockRegularly: form.stockRegularly,
			vendor: form.vendor ? {
				vendorId: form.vendor.value,
				partNumber: form.vendorPartNumber!,
				price: form.vendorPrice!,
				url: form.vendorUrl,
				packageType: form.packageType as VendorPackageType,
				packageSize: form.packageQuantity,
				note: form.note,
			} : null,
			departments: !!form.location ? FormModel.locationToDepartmentRM(form.location) : [],
			crewTypeId: form.crewTypeId,
		};
	}

	static fromVM(model: W_Item_FindById_VM): FormModel {
		return {
			id: model.id,
			name: model.name,
			manufacturer: model.manufacturer,
			modelNumber: model.modelNumber,
			itemCategories: model.itemCategories,
			description: model.description,
			stockRegularly: model.stockRegularly,
			vendor: !!model.vendor ? { label: model.vendor.name, value: model.vendor.vendorId } : null,
			vendorPartNumber: model.vendor?.partNumber ?? null,
			vendorUrl: model.vendor?.url ?? null,
			packageType: model.vendor?.packageType ?? null,
			packageQuantity: model.vendor?.packageQuantity ?? null,
			vendorPrice: model.vendor?.price ?? null,
			note: model.vendor?.note ?? null,
			location: model.departments.reduce((_acc, _department) => {
				if (!_acc[_department.locationId]) {
					_acc[_department.locationId] = [];
				}
				_acc[_department.locationId].push({
					id: _department.departmentId,
					name: _department.name,
					includeDepartment: true,
					currentStock: _department.currentStock,
					minimumStock: _department.minimumStock,
					maximumStock: _department.maximumStock,
				});
				return _acc;
			}, {} as FormModel['location']),
			imageUrl: model.imageUrl,
			crewType: model.crewType,
			crewTypeId: model.crewType?.id ?? null,
		};
	}
}

export default FormModel;
