import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { TimeSheetWorkTypeDisplayInfo } from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimeSheetEntryType from '@acceligentllc/shared/enums/timeSheetEntryType';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import type { TimeSheetEntryWithType } from '../formModel';
import { calculateRealStartTime } from '../../../helpers';
import Stopwatch from '../../../../Shared/Stopwatch';

interface OwnProps {
	isOpaque: boolean;
	editEntry: () => void;
	deleteEntry: () => void;
	getEntry: () => TimeSheetEntryWithType;
	overlap: OverlapMeta;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	readonly: boolean;
	timeZoneInUse: Nullable<string>;
	isAnotherTimeSheetInEditMode: boolean;
	isThisTimeSheetInEditMode: boolean;
}

type Props = OwnProps;

const TimeSheetEntryPreview: React.FC<Props> = (props: Props) => {

	const {
		isOpaque,
		editEntry,
		deleteEntry,
		getEntry,
		overlap,
		employeeApprovalStatus,
		readonly,
		timeZoneInUse,
		isAnotherTimeSheetInEditMode,
		isThisTimeSheetInEditMode,
	} = props;

	const entryWithType = getEntry();
	const { workType, type, startTime, endTime, startDate, isInActiveShift, isNew } = entryWithType.entry;

	const { icon: workTypeIcon, label: workTypeLabel } = TimeSheetWorkTypeDisplayInfo[workType];

	const timeSheetEntryClassName = 'time-sheet-edit-modal__time-sheet-entry';

	const hasOverlap = overlap?.startTime || overlap?.endTime || overlap?.occupied;
	const isUnsignedManualEntry = (
		type === TimeSheetEntryType.MANUAL
		&& employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.UNSIGNED
	);

	const workTypeIconClassName = `${bemElement(timeSheetEntryClassName, 'work-type-icon', { overlap: hasOverlap, 'unsigned-manual': isUnsignedManualEntry })} icon-${workTypeIcon}`;

	const endTimeFormatted = !endTime ? 'N/A' : TimeUtils.formatDate(endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME);
	const durationFormatted = !endTime ? 'N/A' : TimeUtils.minutesToHoursAndMinutes(TimeUtils.getDiff(endTime, startTime, 'minutes', TimeFormat.ISO_DATETIME));
	const startTimeFormatted = TimeUtils.formatDate(startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME);

	const canBeEdited = !readonly && endTime && !isAnotherTimeSheetInEditMode && !isThisTimeSheetInEditMode;

	const onDeleteEntry = React.useCallback(() => {
		deleteEntry();
	}, [deleteEntry]);

	const onEditEntry = React.useCallback(() => {
		editEntry();
	}, [editEntry]);

	return (
		<Row className={bemBlock(timeSheetEntryClassName, [`${isNew ? 'newly-added' : ''}`, `${isOpaque ? 'opaque' : ''}`])}>
			<div className={bemElement(timeSheetEntryClassName, 'connection', { overlap: overlap?.endTime })} />
			<Col sm={1}>
				<span className={workTypeIconClassName} />
			</Col>
			<Col sm={4}>
				<span className={bemElement(timeSheetEntryClassName, 'work-type-label')}>{workTypeLabel}</span>
			</Col>
			<Col sm={4}>
				<span className={bemElement(timeSheetEntryClassName, 'start-date')}>{startDate}</span>
			</Col>
			<Col sm={5}>
				<span className={bemElement(timeSheetEntryClassName, 'time', [`${overlap?.startTime ? 'overlap' : ''}`, `${isUnsignedManualEntry ? 'unsigned-manual' : ''}`])}>
					{startTimeFormatted}
				</span>
			</Col>
			{isInActiveShift && !endTime
				? <Col sm={11}>
					<Stopwatch startTime={calculateRealStartTime(startTime, timeZoneInUse)} />
				</Col>
				: <>
					<Col className={bemElement(timeSheetEntryClassName, 'time-separator')} sm={1}>
						-
					</Col>
					<Col sm={5}>
						<span className={bemElement(timeSheetEntryClassName, 'time', [`${overlap?.endTime ? 'overlap' : ''}`, `${isUnsignedManualEntry ? 'unsigned-manual' : ''}`])}>
							{endTimeFormatted}
						</span>
					</Col>
					<Col sm={5}>
						<span className={bemElement(timeSheetEntryClassName, 'duration')}>{durationFormatted}</span>
					</Col>
					<Col sm={1}>
						{(canBeEdited) &&
							<Button
								icon="edit"
								onClick={onEditEntry}
								style="link"
								tooltip="Edit"
							/>
						}
					</Col>
					<Col sm={1}>
						{(canBeEdited) &&
							<Button
								icon="delete"
								onClick={onDeleteEntry}
								style="link-danger"
								tooltip="Delete"
							/>
						}
					</Col>
				</>
			}
		</Row>
	);
};

export default React.memo(TimeSheetEntryPreview);
