import * as React from 'react';

import { resolveWorkOrderStatusIconAndLabel } from '@acceligentllc/shared/utils/workOrderFieldReport';

import WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';

interface OwnProps {
	title: string;
	description: string | JSX.Element;
	reviewStatus: WorkOrderReviewStatus;
	isReadOnly: boolean;
}

type Props = OwnProps;

const SuccessModalBody: React.FC<Props> = (props: Props) => {
	const { title, description, reviewStatus, isReadOnly } = props;

	const color = reviewStatus === WorkOrderReviewStatus.REJECTED
		? 'text-red'
		: 'text-orange';

	const { iconName } = resolveWorkOrderStatusIconAndLabel(reviewStatus, isReadOnly);
	const iconClassName = `submit-success-modal__check-icon icon-${iconName} ${color}`;

	return (
		<div className="submit-success-modal__body">
			<div className="submit-success-modal__content-title">{title}</div>
			<span className={iconClassName} />
			<div className="submit-success-modal__user-message">{description}</div>
		</div>
	);
};

export default React.memo(SuccessModalBody);
