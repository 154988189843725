import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import AccountPermission from '@acceligentllc/shared/enums/accountPermission';

import { AccountPermissionTemplateOptions, AccountPermissionTemplates, AccountPermissionFlags } from 'ab-enums/accountPermissionTemplates.enum';

import Dropdown from 'af-fields/Dropdown';

import type FormModel from '../formModel';

import Permissions from '../../Shared/Permission';
import { IMPLICATIONS, FLAG_IMPLICATIONS } from '../../Shared/Permission/constants';

interface Props {
	change: (field: string, value: boolean | (typeof AccountPermissionTemplateOptions)[0]) => void;
	permissions: FormModel['permissions'];
	isCompanyAdmin: boolean;
	accountTemplate: AccountPermissionTemplate;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
}

interface State {
	isCompanyAdmin: boolean;
	modified: boolean;
}

class MemberPermissions extends React.PureComponent<Props, State> {
	state: State = {
		isCompanyAdmin: this.props.isCompanyAdmin,
		modified: false,
	};

	static renderMenuOption = (option: (typeof AccountPermissionTemplateOptions)[0]) => <div>{option.label}</div>;

	componentDidUpdate(prevProps: Props) {
		const { permissions, isCompanyAdmin, change, accountTemplate } = this.props;
		const { modified } = this.state;

		if (isCompanyAdmin) {
			this.setState(() => ({ modified: false }));
		}
		if (permissions !== prevProps.permissions || accountTemplate !== prevProps.accountTemplate) {
			const templatePermissions = AccountPermissionTemplates[accountTemplate]?.reduce((_acc, _permission) => {
				_acc[_permission] = true;
				for (const _implication of (IMPLICATIONS[_permission] ?? [])) {
					_acc[_implication] = true;
				}
				return _acc;
			}, {} as Record<AccountPermission, true>) ?? {} as Record<AccountPermission, true>;

			const newModified = Object.keys(permissions ?? {}).some((_permission) => permissions[_permission] !== (templatePermissions[_permission] ?? false));

			const selected = AccountPermissionTemplateOptions.find((_option) => _option.id === accountTemplate);
			if (!!selected && newModified !== modified) {
				change('accountTemplate', { ...selected });
				this.setState(() => ({ modified: newModified }));
			}
		}
	}

	toggleAll = (value: boolean, skipAdminCheck: boolean = false) => {
		const {
			change,
			assignableToWorkOrder,
			assignableAsSuperintendent,
			assignableAsAccounting,
			assignableAsManagement,
			assignableAsTechnician,
		} = this.props;
		const { isCompanyAdmin } = this.state;

		let lockedPermissions: { [permission in AccountPermission]?: boolean } = {};

		if (!skipAdminCheck) {
			if (assignableToWorkOrder) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableToWorkOrder };
			}
			if (assignableAsSuperintendent) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsSuperintendent };
			}
			if (assignableAsAccounting) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsAccounting };
			}
			if (assignableAsManagement) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsManagement };
			}
			if (assignableAsTechnician) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsTechnician };
			}
		}

		if (skipAdminCheck || (!skipAdminCheck && !isCompanyAdmin)) {
			Object.keys(AccountPermission).forEach((_permission: AccountPermission) => {
				if (!lockedPermissions[_permission]) {
					change(`permissions.${AccountPermission[_permission]}`, value);
				}
			});
		}
	};

	checkPermissions = (perm: AccountPermission, permissionMap: { [key: string]: true; }) => {
		// Used to avoid circular references
		if (permissionMap[perm]) {
			return;
		}

		const { change } = this.props;

		if (IMPLICATIONS[perm]) {
			permissionMap[perm] = true;
			for (const consequentPermission of (IMPLICATIONS[perm] ?? [])) {
				if (IMPLICATIONS[consequentPermission]) {
					this.checkPermissions(consequentPermission, permissionMap);
				}
				change(`permissions.${consequentPermission}`, true);
			}
		}
	};

	selectPermissionsForTemplate = (template: AccountPermissionTemplate) => {
		const { change } = this.props;

		AccountPermissionTemplates[template].forEach((_permission) => {
			change(`permissions.${AccountPermission[_permission]}`, true);

			this.checkPermissions(_permission, {});
		});
	};

	onTemplateChange = (option: typeof AccountPermissionTemplateOptions[0]) => {
		const { change } = this.props;

		const isCompanyAdmin = option.id === AccountPermissionTemplate.ADMIN;

		this.toggleAll(isCompanyAdmin, true);
		this.selectPermissionsForTemplate(option.id);

		change('assignableAsAccounting', false);
		change('assignableToWorkOrder', false);
		change('assignableAsProjectManager', false);
		change('assignableAsSuperintendent', false);
		change('assignableAsQAQC', false);
		change('assignableAsManagement', false);
		change('assignableAsTechnician', false);

		AccountPermissionFlags[option.id].forEach((_flag) => {
			change(_flag, true);
		});

		this.setState(() => ({ isCompanyAdmin }));
	};

	renderSelected = (option: (typeof AccountPermissionTemplateOptions)[0]) => {
		const { modified } = this.state;
		return (
			<div>{option.label}{modified ? ' - modified' : ''}</div>
		);
	};

	render() {
		const {
			change,
			permissions,
			isCompanyAdmin,
			assignableToWorkOrder,
			assignableAsSuperintendent,
			assignableAsAccounting,
			assignableAsManagement,
			assignableAsTechnician,
		} = this.props;
		return (
			<div>
				<Row >
					<Col sm={6}>
						<Field
							component={Dropdown}
							id="template-dropdown"
							label="Permissions template *"
							name="accountTemplateId"
							onValueChange={this.onTemplateChange}
							options={AccountPermissionTemplateOptions}
							placeholder="Select Permission Template"
							propName="accountTemplate"
							renderMenuItem={MemberPermissions.renderMenuOption}
							renderSelected={this.renderSelected}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={18} />
				</Row>
				<Permissions
					assignableAsAccounting={assignableAsAccounting}
					assignableAsManagement={assignableAsManagement}
					assignableAsSuperintendent={assignableAsSuperintendent}
					assignableAsTechnician={assignableAsTechnician}
					assignableToWorkOrder={assignableToWorkOrder}
					change={change}
					isCompanyAdmin={isCompanyAdmin}
					permissions={permissions}
					toggleAll={this.toggleAll}
				/>
			</div>
		);
	}
}

export default MemberPermissions;
