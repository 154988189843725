import ResourceStatusEnum from '@acceligentllc/shared/enums/resourceStatus';

import type EmployeeStatusBase from 'ab-domain/models/employeeStatus/base';
import type EquipmentStatusBase from 'ab-domain/models/equipmentStatus/base';
import type DailyEmployeeStatusTableBase from 'ab-domain/views/dailyEmployeeStatusTable/base';

const CSV_HEADER_KEYS = [
	'Status',
	'Status Valid',
	'Unavailability Reason',
];

type ResourceStatus = EmployeeStatusBase | EquipmentStatusBase;

class ResourceStatusVM {
	id: number;
	name: Nullable<string>;
	index: Nullable<number>;
	available: boolean;
	isDeleted: boolean;

	constructor(status: ResourceStatus) {
		this.id = status.id;
		this.name = status.name;
		this.index = status.index;
		this.available = status.available;
		this.isDeleted = status.status === ResourceStatusEnum.DELETED;
	}
}

export class EmployeeStatusHistoryTableViewModel {
	status: Nullable<ResourceStatusVM>;
	statusValidFrom: string;
	statusValidTo: Nullable<string>;
	unavailabilityReason: Nullable<string>;

	constructor(dailyEmployeeStatus: DailyEmployeeStatusTableBase) {
		this.status = dailyEmployeeStatus?.employeeStatusId ?
			new ResourceStatusVM({
				id: dailyEmployeeStatus.employeeStatusId,
				name: dailyEmployeeStatus.employeeStatusName,
				index: dailyEmployeeStatus.employeeStatusIndex,
				available: dailyEmployeeStatus.employeeStatusAvailable,
				status: dailyEmployeeStatus.employeeStatusStatus,
			} as ResourceStatus)
			: null;

		this.statusValidFrom = dailyEmployeeStatus.statusValidFrom;
		this.statusValidTo = dailyEmployeeStatus.statusValidTo;

		this.unavailabilityReason = dailyEmployeeStatus?.unavailabilityReason ?? null;
	}

	static bulkConstructor(dailyStatuses: DailyEmployeeStatusTableBase[]): EmployeeStatusHistoryTableViewModel[] {
		return dailyStatuses.map(EmployeeStatusHistoryTableViewModel._mapConstructor);
	}

	private static _mapConstructor(dailyStatus: DailyEmployeeStatusTableBase) {
		return new EmployeeStatusHistoryTableViewModel(dailyStatus);
	}

	static toCSVData(viewModels: EmployeeStatusHistoryTableViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_KEYS];

		const rows: string[][] = viewModels.map((_entry: EmployeeStatusHistoryTableViewModel) => {
			const _row: string[] = [
				_entry?.status?.name ?? 'Available',
				_entry?.statusValidTo ? `from ${_entry?.statusValidFrom} to ${_entry?.statusValidTo}` : `from ${_entry?.statusValidFrom}`,
				_entry?.status?.available ? 'N/A' : _entry?.unavailabilityReason ?? 'None',
			];
			return _row;
		});

		return [header, ...rows];
	}
}
