import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import { getUserName } from '@acceligentllc/shared/utils/user';
import { formatPhoneNumber } from '@acceligentllc/shared/utils/phone';

import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type SubscriptionStatus from '@acceligentllc/shared/enums/subscriptionStatus';

import * as TextUtil from 'ab-utils/text.util';

import type AccountBase from 'ab-domain/models/account/base';

import type TemporaryEmployeeBase from 'ab-domain/models/temporaryEmployee/base';

import type { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

type ScheduleBoardEmployeeDisplayDataVM = { firstName: string; lastName: string; };

interface OfficeVM {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;
}

interface UserVM {
	id: number;
	fullName: string;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
}

class AccountVM {
	id: number;
	fullName: string;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	user: UserVM;

	constructor(account: AccountBase) {
		this.id = account.id;
		this.fullName = getUserName(account.user);
		this.assignableToWorkOrder = account.assignableToWorkOrder;
		this.assignableAsSuperintendent = account.assignableAsSuperintendent;
		this.assignableAsProjectManager = account.assignableAsProjectManager;
		this.assignableAsQAQC = account.assignableAsQAQC;
		this.user = {
			id: account.user.id,
			fullName: getUserName(account.user),
			email: account.user.email,
			phoneNumber: account.user.phoneNumber ? formatPhoneNumber(account.user.phoneNumber) : null,
		} as UserVM;
	}
}

class ScheduleBoardTemporaryEmployeeVM implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<ScheduleBoardTemporaryEmployeeVM> = TextUtil.matches;

	id: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
	office: Nullable<OfficeVM>;
	isDisabled: boolean; // do not need to initialize them on the server side
	isFilteredOnBoard: boolean; // do not need to initialize them on the server side
	isMatched?: boolean;
	isDeleted: boolean;
	agencyColor: ColorPalette;
	/** subscription status, name stripped in order to save lots of bytes on sch.board */
	subscriptionStatus: SubscriptionStatus;
	account: AccountVM;

	duplicateDisplayNameExists?: boolean;

	_desc: string;

	constructor(employee: TemporaryEmployeeBase) {
		const _employeeUser = employee?.account?.user;

		this.id = employee.id;
		this.firstName = _employeeUser?.firstName;
		this.lastName = _employeeUser?.lastName;
		this.uniqueId = employee.uniqueId;
		this.isDeleted = employee.account.isDeleted;
		this.agencyColor = employee.agency.color;
		// variations of fullName
		const searchableLiterals = [`${this.firstName} ${this.lastName}`];
		this._desc = searchableLiterals.join(' ');
		this.isMatched = false;
		this.subscriptionStatus = _employeeUser.subscriptionStatus;
		this.account = new AccountVM(employee.account);
		this.office = null;
	}

	static getDisplayName(employee: ScheduleBoardTemporaryEmployeeVM): string {
		const { firstName, lastName, duplicateDisplayNameExists } = employee;
		return `${firstName?.[0]}${firstName && duplicateDisplayNameExists ? firstName[1] : ''} ${lastName}`;
	}

	static getDisplayData(employee: ScheduleBoardTemporaryEmployeeVM): ScheduleBoardEmployeeDisplayDataVM {
		const { firstName: _firstName, lastName, duplicateDisplayNameExists } = employee;
		return {
			firstName: _firstName && (duplicateDisplayNameExists ? _firstName.slice(0, 2) : _firstName[0]),
			lastName,
		};
	}
}

export type ScheduleBoardTemporaryEmployeesVM = { [temporaryEmployeeId: number]: ScheduleBoardTemporaryEmployeeVM; };

export type TemporaryEmployeeAssignmentsVM = { [temporaryEmployeeId: number]: string[]; }; // [temporaryEmployeeId]: workOrderCode[]

export default ScheduleBoardTemporaryEmployeeVM;
