import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import type { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import CustomModal from 'af-components/CustomModal';

import FieldReports from '../Shared/ReportsSummary/FieldReports';

import TimeSheets from './TimeSheets';

interface OwnProps {
	currentUserTimeSheet: Nullable<TimeSheetInfoVM>;
	fieldReports: FieldReportListItemVM[];
	closeModal: () => void;
	onConfirm: () => void;
	showModal: boolean;
	timeSheetEntriesByAccountIdMap: Nullable<{ [accountId: number]: TimeSheetEntryInfoVM[]; }>;
	timeSheets: TimeSheetInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const DESCRIPTION = 'You are about to submit following Report contents for review:';
const MODAL_TITLE = 'Submit for Review';

const SubmitForReviewModal: React.FC<Props> = (props) => {
	const { closeModal, onConfirm, timeSheetEntriesByAccountIdMap, timeSheets, fieldReports, currentUserTimeSheet, showModal, timezone } = props;

	return (
		<CustomModal
			className="report-type-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={MODAL_TITLE}
			/>
			<CustomModal.Body>
				<div className="report-submit-modal__text">{DESCRIPTION}</div>
				<TimeSheets
					currentUserTimeSheet={currentUserTimeSheet}
					timeSheetEntriesByAccountIdMap={timeSheetEntriesByAccountIdMap}
					timeSheets={timeSheets}
					timezone={timezone}
				/>
				<FieldReports
					fieldReports={fieldReports}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<Button
					label="Continue"
					onClick={onConfirm}
					style="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(SubmitForReviewModal);
