import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import baseRoute from '../baseRoute';

export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/table/deactivated/csv${`?query=${string}` | ''}`;

export const ROUTE: RouteDefinition = `${baseRoute}/table/deactivated/csv`;

export const URL = (tableQuery: TableQuery): RouteDefinition => `${baseRoute}/table/deactivated/csv?query=${encodeURIComponent((JSON.stringify(tableQuery)))}`;
