import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Checkbox from 'af-components/Controls/Checkbox';

import * as CompanyActions from 'af-actions/companies';

import PreviewItem from '../../Shared/PreviewItem';

type OwnProps = {
	showModal: boolean;
	closeModal: () => void;
	areWorkRequestsEnabled: boolean;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const EnableWorkRequestsCompanyEdit: React.FC<Props> = ({ closeModal, showModal, areWorkRequestsEnabled, editEnableWorkRequests }) => {
	const [enableWorkRequests, setEnableWorkRequests] = React.useState(areWorkRequestsEnabled);

	const renderLabel = React.useCallback(() => {
		return (
			<PreviewItem
				checked={!!areWorkRequestsEnabled}
				hint={CompanyFeatureFlagsTips.workRequests}
				indent={false}
				label={CompanyFeatureFlagsEnum.areWorkRequestsEnabled}
				showCheckbox={false}
			/>
		);
	}, [areWorkRequestsEnabled]);

	const handleChange = React.useCallback((event) => {
		setEnableWorkRequests(event.target.checked);
	}, []);

	const handleSubmit = React.useCallback(async () => {
		await editEnableWorkRequests({ areWorkRequestsEnabled: enableWorkRequests });
		closeModal();
	}, [editEnableWorkRequests, enableWorkRequests, closeModal]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Edit Work Request Settings"
			/>
			<CustomModal.Body>
				<Checkbox
					handleChange={handleChange}
					isChecked={enableWorkRequests}
					label={renderLabel()}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<SubmitButton
					label="Save"
					onClick={handleSubmit}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		editEnableWorkRequests: CompanyActions.editEnableWorkRequests,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(EnableWorkRequestsCompanyEdit);
