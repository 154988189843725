import * as React from 'react';

import type { ExtendedColorValue } from '@acceligentllc/shared/enums/color';

import ScheduleBoardEmployeeViewModel from 'ab-viewModels/scheduleBoardEmployee.viewModel';

import * as ColorUtils from 'ab-utils/color.util';

import EmployeeAdditionalInfo from './EmployeeAdditionalInfo';
import PerDiemIndicator from './PerDiemIndicator';

interface Props {
	employee: ScheduleBoardEmployeeViewModel;
	officeColor: ExtendedColorValue;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	isPerDiem?: boolean;
	isAllowedToManagePerDiems?: boolean;
}

const DraggableItemBody: React.FC<Props> = (props: Props) => {
	const { employee, officeColor, isPerDiem, isAllowedToManagePerDiems } = props;
	const { firstName, lastName } = ScheduleBoardEmployeeViewModel.getDisplayData(employee);

	return (
		<>
			<span className={`sb-resource-item__full-name ${ColorUtils.getColorTextClass(officeColor, true)}`}>
				{firstName} <span className="sb-resource-item__lastName">{lastName}</span>
			</span>
			<EmployeeAdditionalInfo employee={employee} />
			{isAllowedToManagePerDiems && <PerDiemIndicator isPerDiem={!!isPerDiem} />}
		</>
	);
};

export default DraggableItemBody;
