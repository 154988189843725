import * as Err from 'restify-errors';
import { nanoid } from 'nanoid';

import { DEFAULT_WORK_REQUEST_CODE_REGEX } from '@acceligentllc/shared/constants/regex';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type WorkRequestBase from 'ab-domain/models/workRequest/base';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { range } from '@acceligentllc/shared/utils/array';

import type DeliverableSubmissionBase from 'ab-domain/models/deliverableSubmission/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';

import { WORK_REQUEST_CODE_COUNT_LENGTH, WORK_ORDER_CODE_COUNT_LENGTH, REVISION_ALPHABET } from 'ab-common/constants/value';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

// Private

interface JobCode {
	jobCode: string;
}

type WorkOrderForCode = Pick<WorkOrderBase, 'code' | 'dueDate'>;
type WorkRequestForCode = Partial<WorkRequestBase> & { year: number; code: number; };

interface BasicWorkRequestRequestModel {
	code: number;
	year: number;
}

// Public

export interface WorkOrderCode {
	workRequestCode?: BasicWorkRequestRequestModel;
	code: number;
	jobCode?: JobCode;
	dueDate: Date;
}

export const padStart = (value: number, size: number): string => {
	let stringifiedValue = (value ?? 0).toString();
	const paddingSize = size - stringifiedValue.length;
	for (let i = 0; i < paddingSize; i++) {
		stringifiedValue = '0' + stringifiedValue;
	}
	return stringifiedValue;
};

export const parseCode = (wrCode: string): BasicWorkRequestRequestModel => {
	const segments = wrCode.split('-');
	if (segments.length < 2) {
		throw new Err.NotFoundError();
	}

	const year: number = Number(segments[0]) + 2000;
	const code: number = Number(segments[1]);

	if (isNaN(year) || isNaN(code)) {
		throw new Err.NotFoundError();
	}

	return {
		year,
		code,
	};
};

export const parseCodePartial = (wrCode: string): BasicWorkRequestRequestModel => {
	const segments = wrCode.split('-');
	let year: number = NaN, code: number = NaN;

	if (segments.length < 2) {
		if (wrCode.length === 2) {
			year = Number(wrCode) + 2000;
		} else if (wrCode.length > 2 && wrCode.length <= 4) {
			code = Number(wrCode);
		}
	} else {
		year = Number(segments[0]) + 2000;
		if (segments[1].length > 3) {
			code = Number(segments[1]);
		}
	}
	return {
		year,
		code,
	};
};

export const parseWorkOrderCode = (woCode: string, date: string, iteration: string): WorkOrderCode => {
	const _dueDate = TimeUtils.toUtcDate(date);
	if (!_dueDate) {
		throw new Error('Due date incorrect');
	}

	const response: WorkOrderCode = {
		code: parseInt(iteration, 10),
		dueDate: _dueDate,
	};

	if (DEFAULT_WORK_REQUEST_CODE_REGEX.test(woCode)) {
		response.workRequestCode = parseCode(woCode);
	} else {
		response.jobCode = {
			jobCode: woCode,
		};
	}
	return response;
};

// Used for URLs. Also acts as default Job ID unless otherwise specified
/** @deprecated Prefer using model method */
export const workRequestCode = (year: number, code: number): string => {
	return `${year % 100}-${padStart(code, WORK_REQUEST_CODE_COUNT_LENGTH)}`;
};

/** @deprecated Prefer using model method or SQL function */
export const workOrderCodeStripped = (workOrder: WorkOrderForCode, workRequest: WorkRequestForCode): string => {
	const jobCode = workRequest.jobCode ?? workRequestCode(workRequest.year, workRequest.code);
	return `${jobCode}.${padStart(workOrder.code, WORK_ORDER_CODE_COUNT_LENGTH)}`;
};

/** @deprecated Prefer using model method or SQL function */
export const workOrderCodeStrippedPlain = (workOrderCode: number, code: number, year: number, jobCode: string): string => {
	const _jobCode = jobCode ?? workRequestCode(year, code);
	return `${_jobCode}.${padStart(workOrderCode, WORK_ORDER_CODE_COUNT_LENGTH)}`;
};

/** @deprecated Prefer using model method or SQL function */
export const workOrderCode = (workOrder: WorkOrderForCode, workRequest: WorkRequestForCode): string => {
	return `${workOrderCodeStripped(workOrder, workRequest)}.${TimeUtils.formatDate(new Date(workOrder.dueDate), TimeFormat.SHORT)}`;
};

/** @deprecated Prefer using model method */
export const deliverableSubmissionCode = (submission: DeliverableSubmissionBase, workOrder: WorkOrderBase, workRequest: WorkRequestForCode): string => {
	return `${workOrderCodeStripped(workOrder, workRequest)}.${submission.code}`;
};

/** @deprecated Prefer using model method */
export const workOrderCodeFromRevision = (workOrderRevision: WorkOrderRevisionBase): string => {
	const jobCode = workOrderRevision.jobCode ?? workRequestCode(workOrderRevision.workRequestYear, workOrderRevision.workRequestCode);
	const workOrderCodeLabel = `${jobCode}.${padStart(workOrderRevision.code, WORK_ORDER_CODE_COUNT_LENGTH)}`;
	return `${workOrderCodeLabel}.${TimeUtils.formatDate(workOrderRevision.dueDate, TimeFormat.SHORT)}`;
};

export const generateId = (length: number): string => {
	return range(0, length / 7 + 1).reduce((_str: string) => _str += `${nanoid(UNIQUE_ID_SIZE)}`, '').slice(0, length);
};

// 1,2,3 -> A,B,C
export const revisionCode = (revision: number, initialCode: string = '') => {
	if (isNaN(revision) || !isFinite(revision)) {
		return '';
	}
	revision--;
	const index = ((revision % REVISION_ALPHABET.length) + 1) % REVISION_ALPHABET.length;
	const letter = REVISION_ALPHABET[index];
	return revision < 0 ? initialCode : revisionCode(Math.floor(revision / REVISION_ALPHABET.length), letter + initialCode);
};

/** @deprecated Prefer using model method or SQL function */
export const fieldReportCodePlain = (woCode: string, fieldReportIndex: number): string => {
	return `${woCode}.FR${padStart(fieldReportIndex + 1, 2)}`;
};

export const generateActivationCode = (numberOfDigits: number) => {
	const multiplier = Math.pow(10, numberOfDigits);
	return padStart(Math.floor(Math.random() * multiplier), numberOfDigits);
};
