import { toDate } from '@acceligentllc/shared/utils/time';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type DownEquipmentRM from 'ab-requestModels/equipment/downEquipment.requestModel';

export function validate(values: DownEquipmentRM, props) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.note)) {
		errors.note = 'Failure details are required';
	}

	if (!values.priority) {
		errors.priority = 'Priority is required';
	}

	if (values.returnDate) {
		const returnDateDate = toDate(values.returnDate, TimeFormat.DATE_ONLY);
		const dateDate = toDate(props.date, TimeFormat.DATE_ONLY);

		if (returnDateDate && dateDate && returnDateDate <= dateDate) {
			errors.returnDate = 'Return Date has to be after date of work';
		}
	}

	return errors;
}

export function warn(values: DownEquipmentRM, props) {
	const errors: ValidationErrors = {};

	if (!values.returnDate) {
		errors.returnDate = 'Leaving Return Date empty will make equipment permanently unavailable and will remove any future statuses';
	} else {
		if (props.isAutomaticReturnDate && props.initialFormValues?.returnDate !== values.returnDate) {
			errors.returnDate = 'Equipment will become available on this date';
		}
	}

	return errors;
}

