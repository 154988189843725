import * as React from 'react';

import type { OccupiedEntity } from '@acceligentllc/shared/utils/timeSheetEntry';

import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import type { OccupiedTimeSheetEntityForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import BaseSection from './BaseSection';

import type { PercentagesOfDateChanges, PercentagesForChangeToDueDate } from '../types';
import styles from './styles.module.scss';

const MINIMAL_TIMELINE_PERCENTAGE = 1;

interface Props {
	entity: OccupiedEntity<OccupiedTimeSheetEntityForAccount>;
	percentageOfTimeline: number;
	roundedLeft: boolean;
	roundedRight: boolean;
	overlap: boolean;
	isManagerOrAdmin: boolean;
	workOrderCode: string;
	dueDate: string;
	sectionPercentagesForDueDate?: PercentagesForChangeToDueDate;
	dateChangesOnSectionInPercentages?: PercentagesOfDateChanges[];
	nextEntryIsGap: boolean;
}

const OccupiedSection: React.FC<Props> = (props: Props) => {
	const {
		entity,
		percentageOfTimeline,
		roundedLeft,
		roundedRight,
		overlap,
		isManagerOrAdmin,
		workOrderCode,
		dueDate,
		sectionPercentagesForDueDate,
		dateChangesOnSectionInPercentages,
		nextEntryIsGap,
	} = props;

	if (entity.type !== TimelineEntityType.OCCUPIED) {
		throw new Error('Cannot show timeline section as occupied.');
	}

	const { entry } = entity;
	const sectionContainerStyle = getSectionContatinerStyle();
	const sectionBarClassName = getBarSectionStyle();
	const iconStyle = getIconStyle();
	const timelinePercentage = Math.max(percentageOfTimeline, MINIMAL_TIMELINE_PERCENTAGE);

	return (
		<BaseSection
			dateChangesOnSectionInPercentages={dateChangesOnSectionInPercentages}
			dueDate={dueDate}
			endTime={entry.endTime}
			icon={null}
			iconStyle={iconStyle}
			isManagerOrAdmin={isManagerOrAdmin}
			nextEntryIsGap={nextEntryIsGap}
			sectionBarClassName={sectionBarClassName}
			sectionContainerStyle={sectionContainerStyle}
			sectionPercentagesForDueDate={sectionPercentagesForDueDate}
			startTime={entry.startTime}
			timelinePercentage={timelinePercentage}
			workOrderCode={workOrderCode}
			workType={null}
		/>
	);

	function getBarSectionStyle(): string {
		const sectionBarClassNameArray = ['section'];

		sectionBarClassNameArray.push('occupied');

		if (roundedLeft) {
			sectionBarClassNameArray.push('rounded-left');
		}
		if (roundedRight) {
			sectionBarClassNameArray.push('rounded-right');
		}
		if (overlap) {
			sectionBarClassNameArray.push('overlap');
		}

		return sectionBarClassNameArray.map((style) => styles[style]).join(' ');
	}

	function getIconStyle() {

		const iconClassNameArray = ['section__icon'];
		if (overlap) {
			iconClassNameArray.push('overlap');
		}

		return iconClassNameArray.map((style) => styles[style]).join(' ');
	}

	function getSectionContatinerStyle() {
		return styles['section-container'];
	}

};

export default OccupiedSection;
