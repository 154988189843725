import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import TableNameEnum from 'ab-enums/tableName.enum';

import type { JobPayrollChildTableBetaRowVM } from 'ab-viewModels/accounting/jobPayrollTableBeta.viewModel';

import CLIENT from 'af-constants/routes/client';

import type { Column, RowInfo, TableFinalState, SettingsItemBlueprint } from 'af-components/Table6';
import type { TabProps } from 'af-components/Table6/IndentTable';
import IndentTable from 'af-components/Table6/IndentTable';
import BooleanCell from 'af-components/Table6/Cells/BooleanCell';
import DateCell from 'af-components/Table6/Cells/DateCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';

interface OwnProps {
	companyName: string;
	orgAlias: string;
	data: TableContent<JobPayrollChildTableBetaRowVM>;
}

type Props = OwnProps;

const HEADER_CLASS_MODIFIERS = ['grey', 'bold'];
const EXPANDER_ROW_CLASS_MODIFIERS = ['grey', 'expanded'];
const NON_EXPANDER_ROW_CLASS_MODIFIERS = ['grey'];

function getHeaderClassModifiers() {
	return HEADER_CLASS_MODIFIERS;
}
function getRowClassModifiers({ expanded }: TableFinalState<JobPayrollChildTableBetaRowVM>, row: RowInfo<JobPayrollChildTableBetaRowVM>) {
	return !!expanded[row?.index] ? EXPANDER_ROW_CLASS_MODIFIERS : NON_EXPANDER_ROW_CLASS_MODIFIERS;
}

const COLUMNS: Column<JobPayrollChildTableBetaRowVM>[] = [
	{
		Header: 'Date',
		accessor: 'localDateValue',
		sortable: false,
		Cell: ({ original }) => (
			<DateCell
				date={original.localDateValue}
				dateSourceFormat={TimeFormat.DB_DATE_ONLY}
				format={TimeFormat.DATE_ONLY}
				isLeftAligned
			/>
		),
		width: 150,
		className: 'selectable-text-cell bold',
	},
	{
		Header: 'Work Order',
		accessor: 'workOrderShift',
		sortable: false,
		width: 110,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.workOrderShift,
	},
	{
		Header: 'Work Order Weekly Code',
		accessor: 'workOrderWeeklyCode',
		sortable: false,
		width: 110,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.workOrderWeeklyCode,
	},
	{
		Header: 'Employee ID',
		accessor: 'userUniqueId',
		sortable: false,
		width: 150,
		className: 'selectable-text-cell bold',
		Cell: ({ original }) => original.userUniqueId,
	},
	{
		Header: 'Employee Name',
		accessor: 'userFullName',
		sortable: false,
		width: 200,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.userFullName,
	},
	{
		Header: 'State',
		accessor: 'accountLocationAddressState',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.accountLocationAddressState ?? <EmptyCell />,
	},
	{
		Header: 'Classification Code',
		accessor: 'fieldWorkClassificationCode',
		sortable: false,
		width: 180,
		className: 'selectable-text-cell bold',
		Cell: ({ original }) => {
			if (!original.fieldWorkClassificationCode) {
				return <EmptyCell message="[MISSING]" />;
			}
			if (!original.hasClassificationCode) {
				return <EmptyCell message={original.fieldWorkClassificationCode} />;
			}
			return original.fieldWorkClassificationCode;
		},
	},
	{
		Header: 'Equipment',
		accessor: 'equipmentIdCodes',
		sortable: false,
		width: 180,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.equipmentIdCodes ?? <EmptyCell />,
	},
	{
		Header: 'Time Card Note',
		accessor: 'timeSheetNote',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.timeSheetNote,
	},
	{
		Header: 'Job',
		accessor: 'jobHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.jobHours,
	},
	{
		Header: 'Shop',
		accessor: 'shopHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.shopHours,
	},
	{
		Header: 'Travel',
		accessor: 'travelHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.travelHours,
	},
	{
		Header: 'Break',
		accessor: 'breakHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.breakHours,
	},
	{
		Header: 'Total Job',
		accessor: 'totalJobHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalJobHours,
	},
	{
		Header: 'Total Shop',
		accessor: 'totalShopHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalShopHours,
	},
	{
		Header: 'Total Travel',
		accessor: 'totalTravelHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalTravelHours,
	},
	{
		Header: 'Total Break',
		accessor: 'totalBreakHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalBreakHours,
	},
	{
		Header: 'Total Hours',
		accessor: 'totalHours',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalHours,
	},
	{
		Header: 'Total Hours/WO',
		accessor: 'totalHoursPerWO',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.totalHoursPerWO,
	},
	{
		Header: 'Crew',
		accessor: 'crewName',
		sortable: false,
		className: 'selectable-text-cell',
		Cell: ({ original }) => original.crewName,
	},
	{
		Header: 'Is Signed',
		accessor: 'isTimeSheetSigned',
		sortable: false,
		width: 120,
		Cell: ({ original }) => <BooleanCell value={original.isTimeSheetSigned} />,
	},
	{
		Header: 'Is Approved',
		accessor: 'isTimeSheetApproved',
		sortable: false,
		width: 120,
		Cell: ({ original }) => <BooleanCell value={original.isTimeSheetApproved} />,
	},
	{
		Header: 'Is Canceled',
		accessor: 'isWorkOrderCanceled',
		sortable: false,
		width: 120,
		Cell: ({ original }) => <BooleanCell value={original.isWorkOrderCanceled} />,
	},
];

const FieldReportChildTableBeta: React.FC<Props> = (props) => {
	const { data, companyName, orgAlias } = props;

	const openReport = React.useCallback((original: JobPayrollChildTableBetaRowVM) => {
		const { workOrderId } = original;

		window.open(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName), '_blank')?.focus();
	}, [companyName, orgAlias]);

	const rowActions = React.useMemo<SettingsItemBlueprint<JobPayrollChildTableBetaRowVM>[]>(() => [
		{
			label: 'Open Report',
			action: openReport,
			shouldRefresh: false,
		},
	], [openReport]);

	const tab = React.useMemo<TabProps<JobPayrollChildTableBetaRowVM>>(() => ({
		columns: COLUMNS,
		fetch: () => Promise.resolve(data),
		getHeaderClassModifiers: getHeaderClassModifiers,
		getRowClassModifiers: getRowClassModifiers,
		rowActions,
	}), [data, rowActions]);

	return (
		<IndentTable
			indent={1}
			isLoaded={true}
			tab={tab}
			table={data}
			tableName={TableNameEnum.JOB_PAYROLL_REPORT_CHILD_TABLE}
		/>
	);
};

export default React.memo(FieldReportChildTableBeta);
