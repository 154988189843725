import * as React from 'react';
import type { FormErrors, WrappedFieldArrayProps } from 'redux-form';
import { FieldArray } from 'redux-form';

import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';
import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import type FieldWorkClassificationCodeListItemVM from 'ab-viewModels/fieldWorkClassificationCode/listItem';
import type { EquipmentListItemVM } from 'ab-viewModels/timeSplitEquipment/timeSplitEquipment';

import * as TimeSheetUtils from '@acceligentllc/shared/utils/timeSheet';
import { findOverlapsByVirtualId, getTimelineEntities } from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type OccupiedSlotsForWorkOrderVM from 'ab-viewModels/timeSheet/occupiedSlotsForWorkOrder.viewModel';
import type { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import type { OwnProps as TimeSplitEntriesListProps } from './TimeSplitEntries/TimeSplitEntriesList';
import TimeSplitEntriesList from './TimeSplitEntries/TimeSplitEntriesList';
import type { OwnProps as TimeSheetEntriesListProps } from './TimeSheetEntries/TimeSheetEntriesList';
import TimeSheetEntriesList from './TimeSheetEntries/TimeSheetEntriesList';
import styles from './styles.module.scss';
import type { TimeSheetAddedEntryWithType, TimeSheetEntryFormModel, TimeSheetEntryWithType, TimeSheetGapEntryWithType, TimeSheetOccupiedEntryWithType } from './formModel';
import TimeSheetEditFormModel, { TimeAllocationEntryFormModel, TimeSheetEntryFormType, TimeSplitEntryFormModel } from './formModel';
import TotalTimeSheetTimes from './TotalTimeSheetTimes';
import RejectionBanner from './RejectionBanner';
import EquipmentTimeError from './TimeSplitEntries/EquipmentTimeError';
import EquipmentTimeWarning from './TimeSplitEntries/EquipmentTimeWarning';
import type { OwnProps as TimeAllocationEntriesListProps } from './TimeAllocations/TimeAllocationEntriesList';
import TimeAllocationEntriesList from './TimeAllocations/TimeAllocationEntriesList';
import type { EntryValidationErrors } from '.';

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};
export interface OwnProps {
	canEditClassificationCodes: boolean;
	areFRsReadOnly: boolean;
	canSeeTimeAllocations: boolean;
	/** YYYY-MM-DD */
	dueDate: string;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	subJobs: SubjobVM[];
	isWorkOrderPaused: boolean;
	workRequestId: number;
	workOrderReviewStatus: WorkOrderReviewStatus;
	/** Keeps track of which time zone we're using in the form */
	timeZoneInUse: Nullable<string>;
	timeSheetsSyncErrors: FormErrors<EntryValidationErrors, string>;
	isAllowedToEditEquipment: boolean;
	getOccupiedSlots: (accountId: number, entries: TimeSheetEntryFormModel[]) => Promise<Nullable<OccupiedSlotsForWorkOrderVM>>;
	isAllowedToEditTimeSheet: (accountId: number) => boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<TimeSheetEditFormModel>;

export enum TimeSheetEntryAction {
	REMOVE = 'REMOVE',
	SAVE = 'SAVE',
	EDITING = 'EDITING',
	RESET = 'RESET'
}

export enum TimeSplitEntryAction {
	REMOVE = 'REMOVE',
	ADD = 'ADD',
	FIELD_CHANGED = 'FIELD_CHANGED'
}

export enum TimeAllocationEntryAction {
	REMOVE = 'REMOVE',
	ADD = 'ADD',
	FIELD_CHANGED = 'FIELD_CHANGED'
}

export interface TimeSheetEntryActionModel {
	action: TimeSheetEntryAction;
	entryIndex: number;
	newEntry?: TimeSheetEntryWithType | TimeSheetAddedEntryWithType;
}

const calculateTotalEquipmentTime = (timeSplitEntries: TimeSplitEntryFormModel[]) => {
	let totalEquipmentTime = 0;
	if (timeSplitEntries?.length > 0) {
		totalEquipmentTime = timeSplitEntries
			.reduce((totalEqTime, entry) => entry.duration + totalEqTime, 0);
	}
	return totalEquipmentTime;
};

const TimeSheetEditModalBody: React.FC<Props> = (props) => {
	const {
		fields,
		change,
		classificationCodes,
		canEditClassificationCodes,
		sections,
		isAllowedToEditTimeSheet,
		isAllowedToEditEquipment,
		workOrderReviewStatus,
		isWorkOrderPaused,
		getOccupiedSlots,
		dueDate,
		areFRsReadOnly,
		timeZoneInUse,
		subJobs,
		workRequestId,
		canSeeTimeAllocations,
		timeSheetsSyncErrors,
	} = props;

	fields.getAll()?.sort((a, b) => a.userFullName.localeCompare(b.userFullName)) ?? fields;

	const classificationCodeIdsWithValues = React.useMemo(() => {
		const _classificationCodeIdsWithValues: Record<number, string> = {};
		classificationCodes.forEach((classificationCode) => {
			_classificationCodeIdsWithValues[classificationCode.id] = classificationCode.code;
		});
		return _classificationCodeIdsWithValues;
	}, [classificationCodes]);

	const equipmentIdsWithValues = React.useMemo(() => {
		const _equipmentIdsWithValues: Record<number, string> = {};
		sections.forEach((section) => {
			section?.options.forEach((option) => {
				_equipmentIdsWithValues[option.id] = option.code;
			});
		});
		return _equipmentIdsWithValues;
	}, [sections]);

	const calculateGapsOverlapsAndSortEntries = React.useCallback(async (
		timeSheetEntries: (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType | TimeSheetAddedEntryWithType)[],
		accountId: number
	) => {
		const formattedEntries = TimeSheetEditFormModel.filterOutGapAndOccupiedEntries(timeSheetEntries).map((_entryWithType) => _entryWithType.entry);

		const occupiedSlots = await getOccupiedSlots(accountId, formattedEntries);
		const overlaps = findOverlapsByVirtualId(formattedEntries, occupiedSlots?.slots);

		const hasOverlap = Object.keys(overlaps).some((_key) => (overlaps[_key].startTime || overlaps[_key].endTime || overlaps[_key].occupied));
		const timelineEntities = getTimelineEntities(formattedEntries, occupiedSlots?.slots);

		const newEntries = TimeSheetEditFormModel.mapTimelineEntitiesToTimeSheetEntriesList(timelineEntities);

		return {
			hasOverlap,
			newEntries,
			overlaps,
		};
	}, [getOccupiedSlots]);

	const changeAndSortEntries = React.useCallback(async (timeSheetField: string, timeSheetIndex: number, actionModel: TimeSheetEntryActionModel) => {
		const timeSheet = fields.get(timeSheetIndex);
		if (!timeSheet) {
			return;
		}
		const timeSheetEntries = [...timeSheet.timeSheetEntries];

		const { action, entryIndex, newEntry: newEntryWithType } = actionModel;

		const previousIndexOfEditingEntry = timeSheet.editingEntryIndex;
		const virtualIdOfEditingEntry = newEntryWithType?.entry.virtualId;

		if (action === TimeSheetEntryAction.REMOVE) {
			timeSheetEntries.splice(entryIndex, 1);
		} else if (action === TimeSheetEntryAction.SAVE) {
			const entryWithType = timeSheetEntries[entryIndex];
			if (entryWithType.type !== TimeSheetEntryFormType.ADDED && entryWithType.type !== TimeSheetEntryFormType.ENTRY) {
				throw new Error('Trying to save entry that is not being edited');
			}
			const newEntry = TimeSheetEditFormModel.addedEntryToListEntry(entryWithType.entry);
			timeSheetEntries.splice(entryIndex, 1, {
				entry: newEntry,
				type: TimeSheetEntryFormType.ENTRY,
			});
		} else if (action === TimeSheetEntryAction.EDITING) {
			if (newEntryWithType) {
				timeSheetEntries.splice(entryIndex, 1, newEntryWithType);
			}
		} else if (action === TimeSheetEntryAction.RESET) {
			if (newEntryWithType) {
				timeSheetEntries.splice(entryIndex, 1, newEntryWithType);
			}
		}

		const { hasOverlap, newEntries, overlaps } = await calculateGapsOverlapsAndSortEntries(timeSheetEntries, timeSheet.accountId);

		if (virtualIdOfEditingEntry && action === TimeSheetEntryAction.EDITING) {
			const indexOfEditingEntry = newEntries.findIndex((entryWithType) => entryWithType.entry.virtualId === virtualIdOfEditingEntry);
			// if there was change in placement of entries due to sorting and gaps/overlaps calculation
			if (indexOfEditingEntry !== -1 && previousIndexOfEditingEntry !== indexOfEditingEntry) {
				change(timeSheetField + '.editingEntryIndex', indexOfEditingEntry);
			}
		}

		change(timeSheetField + '.hasOverlap', hasOverlap);
		change(timeSheetField + '.overlaps', overlaps);
		change(timeSheetField + '.timeSheetEntries', newEntries);

		// set/reset/remove time allocations for that work type
		if (newEntryWithType?.entry.workType ||
			(action === TimeSheetEntryAction.REMOVE && timeSheet.timeSheetEntries[entryIndex].type === TimeSheetEntryFormType.ENTRY)
		) {
			const workType = newEntryWithType?.entry.workType ?? (timeSheet.timeSheetEntries[entryIndex].entry as TimeSheetEntryFormModel).workType;
			const totalDurationOfChangedEntry = TimeSheetEditFormModel.getTotalTimeForWorkType(newEntries, workType);
			const roundedDuration = TimeUtils.roundTimeDurationToInterval(totalDurationOfChangedEntry, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
			const newAllocationEntries = TimeAllocationEntryFormModel.resetAllocationEntriesForWorkType(
				[...timeSheet.timeAllocationEntries],
				workType,
				workRequestId,
				roundedDuration
			);
			const previousWorkTypeOfEditingEntry = timeSheet.editingEntryInitialEntry?.type === TimeSheetEntryFormType.ENTRY ?
				timeSheet.editingEntryInitialEntry?.entry.workType : null;
			let renewedAllocationEntries = [] as TimeAllocationEntryFormModel[];
			if (previousWorkTypeOfEditingEntry && previousWorkTypeOfEditingEntry !== workType) {
				const previousWorkTypeDuration = TimeSheetEditFormModel.getTotalTimeForWorkType(newEntries, previousWorkTypeOfEditingEntry);
				const roundedPreviousWorkTypeDuration = TimeUtils.roundTimeDurationToInterval(
					previousWorkTypeDuration, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL
				);
				renewedAllocationEntries = TimeAllocationEntryFormModel.resetAllocationEntriesForWorkType(
					newAllocationEntries,
					previousWorkTypeOfEditingEntry,
					workRequestId,
					roundedPreviousWorkTypeDuration
				);
			}
			if (renewedAllocationEntries.length) {
				change(timeSheetField + '.timeAllocationEntries', renewedAllocationEntries);
			} else {
				change(timeSheetField + '.timeAllocationEntries', newAllocationEntries);
			}
			change(timeSheetField + '.timeAllocationEntriesHaveChanged', true);
		}
	}, [calculateGapsOverlapsAndSortEntries, change, fields, workRequestId]);

	const changeTimeSplitEntries = React.useCallback(async (
		timeSheetField: string, timeSheetIndex: number, action: TimeSplitEntryAction, entryIndex: Nullable<number>
	) => {
		const timeSheet = fields.get(timeSheetIndex);
		if (!timeSheet) {
			return;
		}
		const timeSplitEntries = [...timeSheet.timeSplitEntries];

		if (action === TimeSplitEntryAction.REMOVE && entryIndex !== null) {
			timeSplitEntries.splice(entryIndex, 1);
		} else if (action === TimeSplitEntryAction.ADD) {
			timeSplitEntries.push(new TimeSplitEntryFormModel(workRequestId));
		} else if (action === TimeSplitEntryAction.FIELD_CHANGED) {
			return;
		}

		change(timeSheetField + '.timeSplitEntries', timeSplitEntries);
	}, [change, fields, workRequestId]);

	const changeTimeAllocationEntries = React.useCallback(async (
		timeSheetField: string, timeSheetIndex: number, action: TimeAllocationEntryAction, entryIndex: Nullable<number>
	) => {
		const timeSheet = fields.get(timeSheetIndex);
		if (!timeSheet) {
			return;
		}
		const timeAllocationEntries = [...timeSheet.timeAllocationEntries];

		if (action === TimeAllocationEntryAction.REMOVE && entryIndex !== null) {
			timeAllocationEntries.splice(entryIndex, 1);
		} else if (action === TimeAllocationEntryAction.ADD) {
			timeAllocationEntries.push(new TimeAllocationEntryFormModel(workRequestId));
		} else if (action === TimeAllocationEntryAction.FIELD_CHANGED) {
			return;
		}

		change(timeSheetField + '.timeAllocationEntries', timeAllocationEntries);
	}, [change, fields, workRequestId]);

	const changeOnlyOverlapsForValidation = React.useCallback(async (timeSheetField: string, timeSheetIndex: number) => {
		const timeSheet = fields.get(timeSheetIndex);
		if (!timeSheet) {
			return;
		}
		const { hasOverlap, overlaps } = await calculateGapsOverlapsAndSortEntries(timeSheet.timeSheetEntries, timeSheet.accountId);

		change(timeSheetField + '.hasOverlap', hasOverlap);
		change(timeSheetField + '.overlaps', overlaps);
	}, [calculateGapsOverlapsAndSortEntries, change, fields]);

	const renderTimeSheetEntries = React.useCallback((field: string, index: number) => {
		const timeSheet = fields.get(index);
		const timeSheetEntries = TimeSheetEditFormModel.mapEntriesWithTypesToEntries(
			TimeSheetEditFormModel.filterOutGapAndOccupiedEntries(timeSheet.timeSheetEntries)
		);
		const totalJobTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.JOB);
		const totalShopTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.SHOP);
		const totalTravelTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.TRAVEL);
		const totalBreakTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.BREAK);

		const approvalStatus = timeSheet.approvalStatus;
		const employeeApprovalStatus = timeSheet.employeeApprovalStatus;

		const allowedToEditTimeSheet = isAllowedToEditTimeSheet(timeSheet.accountId);

		const isTimeSheetReadonly = !!approvalStatus?.approvalStatus && TimeSheetUtils.shouldTsBeReadOnly(
			workOrderReviewStatus,
			approvalStatus?.approvalStatus,
			allowedToEditTimeSheet
		);

		const isRejected = (
			approvalStatus.approvalStatus === TimeSheetApprovalStatus.REJECTED
			&& employeeApprovalStatus.signatureStatus !== TimeSheetSignatureStatus.SIGNED
		);

		const indexOfEntryInEdit = timeSheet.editingEntryIndex;

		const changeEntries = (actionModel: TimeSheetEntryActionModel, shouldNotUpdateEntriesHaveChanged: boolean = false) => {
			changeAndSortEntries(field, index, actionModel);
			if (!shouldNotUpdateEntriesHaveChanged) {
				change(field + '.timeSheetEntriesHaveChanged', true);
			}
		};

		const calculateOverlapsForValidation = () => {
			changeOnlyOverlapsForValidation(field, index);
		};

		const setTimeSheetInEditIndexForAllTimeSheets = (timeSheetIndex: Nullable<number>) => {
			fields.forEach((_field) => {
				change(_field + '.timeSheetInEditIndex', timeSheetIndex);
			});
		};

		const setEditingEntryIndexAndValue = (
			_index: Nullable<number>,
			initialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>
		) => {
			if (timeSheet.editingEntryIndex !== null
				&& _index === null && initialEntry === null
				|| timeSheet.editingEntryIndex === null && timeSheet.editingEntryInitialEntry === null
				&& _index !== null) {
				change(field + '.editingEntryIndex', _index);
				change(field + '.editingEntryInitialEntry', initialEntry);
				if (_index !== null && timeSheet.timeSheetInEditIndex === null
					|| _index === null && timeSheet.timeSheetInEditIndex !== null) {
					setTimeSheetInEditIndexForAllTimeSheets(_index === null ? null : index);
				}
			}
		};

		const isOtherTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex !== index;
		const isThisTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex === index;

		const errors = timeSheetsSyncErrors ?
			timeSheetsSyncErrors[index]?.timeSheetEntries as FormErrors<TimeSheetEntryWithType | TimeSheetAddedEntryWithType, string>[]
			: [];

		return (
			<>
				<div
					className={`${styles['time-sheet-bulk-edit-modal__bulk-modal-row__user-name']} 
							${isOtherTimeSheetInEditMode ? `${styles['time-sheet-bulk-edit-modal__bulk-modal-row__user-name--opaque']}` : ''} `}
					key={'username' + index}
				>
					{timeSheet.userFullName}
				</div>
				<div className={styles['time-sheet-bulk-edit-modal__timeline']}>
					<TotalTimeSheetTimes
						breakTime={totalBreakTime}
						hasOverlap={timeSheet.hasOverlap}
						isOpaque={isOtherTimeSheetInEditMode}
						jobTime={totalJobTime}
						shopTime={totalShopTime}
						travelTime={totalTravelTime}
					/>
					{isRejected &&
						<RejectionBanner
							isOpaque={isOtherTimeSheetInEditMode}
							rejectedBy={approvalStatus.userName}
							rejectionReason={approvalStatus.note}
						/>
					}
					<FieldArray<TimeSheetEntriesListProps>
						areTimeSheetsReadOnly={isTimeSheetReadonly || isWorkOrderPaused}
						calculateOverlapsForValidation={calculateOverlapsForValidation.bind(this)}
						change={change}
						changeEntries={changeEntries.bind(this)}
						component={TimeSheetEntriesList}
						dueDate={dueDate}
						editingEntryIndex={indexOfEntryInEdit}
						editingEntryInitialEntry={timeSheet.editingEntryInitialEntry}
						employeeApprovalStatus={employeeApprovalStatus}
						entriesSyncErrors={errors}
						isAllowedToEdit={allowedToEditTimeSheet}
						isAnotherTimeSheetInEditMode={isOtherTimeSheetInEditMode}
						isThisTimeSheetInEditMode={isThisTimeSheetInEditMode}
						key={'timeSheetEntries' + index}
						name={field + '.timeSheetEntries'}
						overlaps={timeSheet.overlaps}
						setEditingEntryIndexAndValue={setEditingEntryIndexAndValue.bind(this)}
						timeZoneInUse={timeZoneInUse}
					/>
				</div>
			</>
		);
	}, [
		change,
		changeAndSortEntries,
		changeOnlyOverlapsForValidation,
		dueDate,
		fields,
		isAllowedToEditTimeSheet,
		isWorkOrderPaused,
		timeZoneInUse,
		workOrderReviewStatus,
		timeSheetsSyncErrors,
	]);

	const renderTimeSplits = React.useCallback((field: string, index: number) => {
		const timeSheet = fields.get(index);
		const timeSheetEntries = TimeSheetEditFormModel.mapEntriesWithTypesToEntries(
			TimeSheetEditFormModel.filterOutGapAndOccupiedEntries(timeSheet.timeSheetEntries)
		);
		const totalJobTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.JOB);
		const roundedTotalJobTime = TimeUtils.roundTimeDurationToInterval(totalJobTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalEquipmentTime = calculateTotalEquipmentTime(timeSheet.timeSplitEntries);

		const showEquipmentTimeWarning = roundedTotalJobTime > totalEquipmentTime;
		const showEquipmentTimeError = roundedTotalJobTime < totalEquipmentTime;

		const isOtherTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex !== index;
		const isThisTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex === index;

		const readonly = !isAllowedToEditEquipment || areFRsReadOnly || isOtherTimeSheetInEditMode || isThisTimeSheetInEditMode;
		const isAllowedToAddEquipment = isAllowedToEditEquipment && !areFRsReadOnly && !isOtherTimeSheetInEditMode && !isThisTimeSheetInEditMode;

		const changeEntries = (action: TimeSplitEntryAction, entryIndex: Nullable<number>) => {
			changeTimeSplitEntries(field, index, action, entryIndex);
			change(field + '.timeSplitEntriesHaveChanged', true);
		};

		const classModifiers = isOtherTimeSheetInEditMode || isThisTimeSheetInEditMode ? ['opaque', 'spacing-top'] : ['spacing-top'];

		return (

			<div className={bemElement('time-sheet-list', 'equipment-modal', classModifiers)}>
				{showEquipmentTimeError && <EquipmentTimeError />}
				<div className={bemElement('time-sheet-list__equipment-modal', 'total-times', { error: showEquipmentTimeError })}>
					<div>Total in Equipment: {TimeUtils.minutesToHoursAndMinutes(totalEquipmentTime)}</div>
					<div>Total Job Time: {TimeUtils.minutesToHoursAndMinutes(roundedTotalJobTime)}</div>
				</div>
				{showEquipmentTimeWarning && <EquipmentTimeWarning />}
				<FieldArray<TimeSplitEntriesListProps>
					canEditClassificationCodes={canEditClassificationCodes}
					canSeeTimeAllocations={canSeeTimeAllocations}
					change={change}
					changeEntries={changeEntries.bind(this)}
					classificationCodeIdsWithValues={classificationCodeIdsWithValues}
					classificationCodes={classificationCodes}
					component={TimeSplitEntriesList}
					equipmentIdsWithValues={equipmentIdsWithValues}
					index={index}
					isAllowedToAddEquipment={isAllowedToAddEquipment}
					key={'timeSplitEntries' + index}
					name={field + '.timeSplitEntries'}
					readonly={readonly}
					sections={sections}
					subJobs={subJobs}
				/>
			</div>
		);
	}, [
		areFRsReadOnly,
		canSeeTimeAllocations,
		canEditClassificationCodes,
		isAllowedToEditEquipment,
		change,
		changeTimeSplitEntries,
		classificationCodeIdsWithValues,
		classificationCodes,
		equipmentIdsWithValues,
		fields,
		sections,
		subJobs,
	]);

	const renderTimeAllocations = React.useCallback((field: string, index: number) => {
		const timeSheet = fields.get(index);
		const timeSheetEntries = TimeSheetEditFormModel.mapEntriesWithTypesToEntries(
			TimeSheetEditFormModel.filterOutGapAndOccupiedEntries(timeSheet.timeSheetEntries)
		);
		const allowedToEditTimeSheet = isAllowedToEditTimeSheet(timeSheet.accountId);

		const isOtherTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex !== index;
		const isThisTimeSheetInEditMode = timeSheet.timeSheetInEditIndex !== null && timeSheet.timeSheetInEditIndex === index;

		const readonly = !allowedToEditTimeSheet || areFRsReadOnly || isOtherTimeSheetInEditMode || isThisTimeSheetInEditMode;
		const isAllowedToAddTimeAllocations = allowedToEditTimeSheet && !areFRsReadOnly && !isOtherTimeSheetInEditMode && !isThisTimeSheetInEditMode;

		const totalJobTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.JOB);
		const roundedTotalJobTime = TimeUtils.roundTimeDurationToInterval(totalJobTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalBreakTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.BREAK);
		const roundedTotalBreakTime = TimeUtils.roundTimeDurationToInterval(totalBreakTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalShopTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.SHOP);
		const roundedTotalShopTime = TimeUtils.roundTimeDurationToInterval(totalShopTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const totalTravelTime = TimeSheetEditFormModel.totalWorkTypeTime(timeSheetEntries, TimeSheetEntryWorkType.TRAVEL);
		const roundedTotalTravelTime = TimeUtils.roundTimeDurationToInterval(totalTravelTime, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);

		const {
			jobAllocationsTime,
			breakAllocationsTime,
			shopAllocationsTime,
			travelAllocationsTime,
		} = TimeAllocationEntryFormModel.mapEntriesToTimesPerWorkType(timeSheet.timeAllocationEntries);

		const showAllocationsTimeWarning = roundedTotalJobTime !== jobAllocationsTime || roundedTotalBreakTime !== breakAllocationsTime
			|| roundedTotalShopTime !== shopAllocationsTime || roundedTotalTravelTime !== travelAllocationsTime;

		const changeEntries = (action: TimeAllocationEntryAction, entryIndex: Nullable<number>) => {
			changeTimeAllocationEntries(field, index, action, entryIndex);
			change(field + '.timeAllocationEntriesHaveChanged', true);
		};

		return (
			<div className={`${styles['time-allocations']} 
			${isOtherTimeSheetInEditMode || isThisTimeSheetInEditMode ? `${styles['time-allocations--opaque']}` : ''} `}
			>
				<div className={styles['time-allocations__title']}>
					Time Allocations
				</div>
				{showAllocationsTimeWarning &&
					<div className={`${styles['time-allocations__allocation-time-warning']}`}>
						<span className="icon-info" />Time sum per work type does not match allocations sum per work type.
					</div>
				}
				<FieldArray<TimeAllocationEntriesListProps>
					change={change}
					changeEntries={changeEntries.bind(this)}
					component={TimeAllocationEntriesList}
					index={index}
					isAllowedToAddTimeAllocations={isAllowedToAddTimeAllocations}
					key={'timeAllocationEntries' + index}
					name={field + '.timeAllocationEntries'}
					readonly={readonly}
					subJobs={subJobs}
				/>
			</div>
		);
	}, [areFRsReadOnly, change, changeTimeAllocationEntries, fields, isAllowedToEditTimeSheet, subJobs]);

	return (
		<>
			{fields.map((field, index) => {
				return (
					<div className={styles['time-sheet-bulk-edit-modal__bulk-modal-row']} key={'modalRow' + index}>
						{renderTimeSheetEntries(field, index)}
						{canSeeTimeAllocations && renderTimeAllocations(field, index)}
						{renderTimeSplits(field, index)}
					</div>
				);
			})}
		</>
	);

};

export default TimeSheetEditModalBody;
