import * as React from 'react';
import DatePicker from 'react-datepicker';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import enUs from 'date-fns/locale/en-US';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import { toMomentUtc, normalizeDateToMoment, parseMoment, datePickerFormat } from '@acceligentllc/shared/utils/time';

import * as SettingsKeys from 'af-constants/settingsKeys';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import * as WorkOrderActions from 'af-actions/workOrder';

import { withSettings, setWorkOrderSelectedDueDate } from 'af-utils/settings.util';
import { isAllowed } from 'ab-utils/auth.util';

import type { RootState } from 'af-reducers';

import type * as User from 'ab-viewModels/user.viewModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import NavigationItem from 'af-components/Header/ScheduleBoardNavigation/NavigationItem';

type MomentType = ReturnType<typeof normalizeDateToMoment>;

interface OwnProps {
	defaultDateFormat?: string;
	defaultLocale?: Locale;
}

interface ConnectOwnProps extends OwnProps {
	selectedDate: MomentType;
}

interface DispatchProps {
	findWorkOrdersByDueDate: typeof WorkOrderActions.findWorkOrdersByDueDate;
}

interface StateProps {
	organizationData: User.OrganizationData;
	companyData: User.CompanyData;
	userData: User.UserData;
}

type Props = ConnectOwnProps & StateProps & ResolveThunks<DispatchProps>;

const OrdersNavigation: React.FC<Props> = (props) => {

	const { selectedDate: _selectedDate, defaultDateFormat = TimeFormatEnum.DATE_ONLY, defaultLocale = enUs, findWorkOrdersByDueDate } = props;

	const [selectedDate, setSelectedDate] = React.useState(props.selectedDate!);
	const [isAllowedToEnterScheduleBoard, setIsAllowedToEnterScheduleBoard] = React.useState(false);

	React.useEffect(() => {
		const _isAllowedToEnterScheduleBoard = isAllowed(
			PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD,
			props.companyData.permissions,
			props.companyData.isCompanyAdmin,
			props.userData.role
		);

		if (_isAllowedToEnterScheduleBoard) {
			setIsAllowedToEnterScheduleBoard(true);
		}
	}, [props.companyData.isCompanyAdmin, props.companyData.permissions, props.userData.role]);

	const _datepicker = React.useRef<Nullable<DatePicker>>(null);

	const fetchOrders = React.useCallback(async (actionSelectedDate: NonNullable<MomentType>) => {
		await findWorkOrdersByDueDate(actionSelectedDate.format(TimeFormatEnum.DATE_ONLY), '', actionSelectedDate);
	}, [findWorkOrdersByDueDate]);

	const onDateSelect = React.useCallback(async (date: Date) => {
		const momentDate = parseMoment(date);
		if (!momentDate) {
			throw new Error('Date incorrectly provided');
		}

		setWorkOrderSelectedDueDate(momentDate);
		setSelectedDate(momentDate);
		await fetchOrders(momentDate);
	}, [fetchOrders]);

	React.useEffect(() => {
		if (!_selectedDate) {
			throw new Error('No date selected');
		}

		onDateSelect(_selectedDate.toDate());
		fetchOrders(_selectedDate);
	}, [_selectedDate, fetchOrders, onDateSelect]);

	const prevDate = React.useCallback(() => onDateSelect(selectedDate.subtract(1, 'days').toDate()), [onDateSelect, selectedDate]);

	const nextDate = React.useCallback(() => onDateSelect(selectedDate.add(1, 'days').toDate()), [onDateSelect, selectedDate]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const focusDatePicker = React.useCallback(() => (_datepicker.current as any).input.focus(), []);

	const setDatepickerRef = React.useCallback((datepicker: DatePicker) => _datepicker.current = datepicker, []);

	return (
		<div className="orders-navigation">
			<div className="navigation-title">
				Work Orders
			</div>
			<div className="orders-navigation-right">
				<DatePicker
					className="form-control open-right"
					dateFormat={datePickerFormat(defaultDateFormat)}
					dropdownMode="select"
					locale={defaultLocale}
					onChange={onDateSelect}
					onSelect={onDateSelect}
					ref={setDatepickerRef}
					selected={selectedDate?.toDate?.()}
					showMonthDropdown={true}
					showYearDropdown={true}
					todayButton="Today"
				/>
				<MultipleOptionsButton isLeftFlat={true}>
					<RectangleButton
						action={focusDatePicker}
						isSquare={true}
						label={<span className="icon-calendar" />}
						tooltipMessage="Select Day from Calendar"
						tooltipPlacement="bottom"
					/>
					<RectangleButton
						action={prevDate}
						isSquare={true}
						label={<span className="icon-left" />}
						tooltipMessage="Previous Day"
						tooltipPlacement="bottom"
					/>
					<RectangleButton
						action={nextDate}
						isSquare={true}
						label={<span className="icon-right" />}
						tooltipMessage="Next Day"
						tooltipPlacement="bottom"
					/>
				</MultipleOptionsButton>
				{
					isAllowedToEnterScheduleBoard && <NavigationItem />
				}
			</div>
		</div>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		findWorkOrdersByDueDate: WorkOrderActions.findWorkOrdersByDueDate,
	};
}

function mapStateToProps(state: RootState): StateProps {
	const { userData, companyData, organizationData } = state.user;
	if (!userData || !companyData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		userData,
		organizationData,
		companyData,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	withSettings<Props>(() => ([
		{
			key: SettingsKeys.WORK_ORDER_SELECTED_DUE_DATE(),
			mappedName: 'selectedDate',
			normalize: normalizeDateToMoment,
			defaultValue: toMomentUtc(new Date()),
			source: BrowserStorageEnum.SESSION_STORAGE,
		},
	])),
	connect<StateProps, DispatchProps, ConnectOwnProps>(mapStateToProps, mapDispatchToProps())
);

export default enhance(OrdersNavigation);
