import * as moment from 'moment';
import { ExpectationFailedError } from 'restify-errors';

import AutoNotifyOption from '@acceligentllc/shared/enums/autoNotifyOption';
import FileType from '@acceligentllc/shared/enums/fileType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import BlobStorageContainer from '@acceligentllc/shared/enums/blobStorageContainer';
import NotificationTypeEnum from '@acceligentllc/shared/enums/notificationType';
import { RevisionInformationGroupsEnum } from '@acceligentllc/shared/enums/revisionInformationGroups';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import { weekDays } from '@acceligentllc/shared/enums/weekDay';

import { getShortAddress } from '@acceligentllc/shared/utils/address';
import * as TimeUtils from '@acceligentllc/shared/utils/time';
import { getUserName } from '@acceligentllc/shared/utils/user';

import { WORK_ORDER_PUBLIC_LINK_CODE_LENGTH } from 'ab-common/constants/value';

import type HolidayBase from 'ab-domain/models/holiday/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type ContactAddressBase from 'ab-domain/models/contactAddress/base';
import type AttachmentBase from 'ab-domain/models/attachment/base';
import type NotificationSettingBase from 'ab-domain/models/notificationSetting/base';
import type WorkOrderAddressBase from 'ab-domain/models/workOrderAddress/base';
import type WorkOrderPlaceholderBase from 'ab-domain/models/workOrderPlaceholder/base';
import type WorkRequestBase from 'ab-domain/models/workRequest/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type TemporaryEmployeeBase from 'ab-domain/models/temporaryEmployee/base';
import type EquipmentBase from 'ab-domain/models/equipment/base';
import type CrewType from 'ab-domain/models/crewType/base';
import type ShiftBase from 'ab-domain/models/shift/base';
import type AddressBase from 'ab-domain/models/address/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type LocationBase from 'ab-domain/models/location/base';
import type WorkOrderEmployeeBase from 'ab-domain/models/workOrderEmployee/base';
import type WorkOrderTemporaryEmployeeBase from 'ab-domain/models/workOrderTemporaryEmployee/base';
import type WorkOrderEquipmentBase from 'ab-domain/models/workOrderEquipment/base';
import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';
import type WorkDayBase from 'ab-domain/models/workDay/base';

import { stateAbbreviation } from 'ab-enums/states.enum';
import WorkOrderStatusRevision from 'ab-enums/workOrderStatusRevision';

import type {
	WorkOrderResourceLookupModel,
	AddressModel,
	ContactAddressModel,
	AttachmentModel,
	ContactMethodModel,
	WorkOrderRevisionContactModel,
} from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import { UserModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import * as ContactUtils from 'ab-utils/contact.util';
import { getStreetAddress } from 'ab-utils/formatting.util';
import * as CodeUtils from 'ab-utils/codes.util';
import { getJobDescriptionForLogs } from 'ab-utils/job.util';
import { arraysHaveSameElements } from 'ab-utils/array.util';

const _isCompanyWorking = (
	workDays: WorkDayBase[],
	/** format 'YYYY-MM-DD' */
	holidaysMap: { [date: string]: true; },
	notification: NotificationSettingBase,
	date: moment.Moment
): boolean => {
	let day = date.day() - 1;
	if (day < 0) {
		day = 6;
	}
	return notification.isEnabled && !holidaysMap[TimeUtils.toDatabaseDateOnly(date)!] && workDays.some((_cwo) => _cwo.weekDay === weekDays[day]);
};

export const sortCompare = (wo1: WorkOrderBase, wo2: WorkOrderBase): number => {
	if (wo1.dueDate === wo2.dueDate) {
		return wo1.index! - wo2.index!;
	}
	return wo1.dueDate < wo2.dueDate ? -1 : 1;
};

/**
 * @param date YYYY-MM-DD
 */
export const shouldSendNotificationForWorkOrder = (
	workDays: WorkDayBase[],
	holidays: HolidayBase[],
	notification: NotificationSettingBase,
	dueDate: string,
	/** YYYY-MM-DD */
	date: string
): boolean => {
	const currentDate = TimeUtils.parseDateOnlyToMomentUtc(date, TimeFormat.DB_DATE_ONLY);

	if (!currentDate) {
		throw new ExpectationFailedError();
	}

	const isDueDateSameOrAfterToday = !!TimeUtils.toMomentUtc(dueDate, TimeFormat.DB_DATE_ONLY)?.isSameOrAfter(currentDate?.startOf('day'));

	// Condition for immediately sending notification
	const shouldNotifyImmediately = notification.isEnabled
		&& notification.notifyOnCancel === AutoNotifyOption.IMMEDIATE
		&& isDueDateSameOrAfterToday;

	// Condition for special case
	// Ff notifications were sent already before on last working day and today is not a working day, send notification immediately

	const companyHolidayNames = holidays.map((_holiday) => _holiday.name);
	const tomorrowDate = currentDate.clone().add(1, 'day').toDate();
	const oneWeekFromTomorrow = currentDate.clone().add(7, 'day').toDate();
	const holidayDates = TimeUtils.getHolidayDatesForCompany(companyHolidayNames, tomorrowDate, oneWeekFromTomorrow)
		.reduce((_acc, _date: Date) => {
			_acc[TimeUtils.toDatabaseDateOnly(_date)!] = true;
			return _acc;
		}, {} as { [date: string]: true; });

	const shouldNotifyCauseOfNonWorkingDay = notification.isEnabledAutoNotificationsOnWorkDays
		&& !!notification.notifyOnCancel
		&& isDueDateSameOrAfterToday
		&& !_isCompanyWorking(workDays, holidayDates, notification, currentDate);

	return shouldNotifyImmediately || shouldNotifyCauseOfNonWorkingDay;
};

export const shouldSendTempLaborNotificationForWorkOrder = (
	notification: NotificationSettingBase,
	workOrder: WorkOrderBase
): boolean => {
	return notification.isEnabled
		&& notification.notifyTemporaryLabor
		&& !workOrder.excludeTempLaborFromNotify;
};

export const generatePublicLink = () => CodeUtils.generateId(WORK_ORDER_PUBLIC_LINK_CODE_LENGTH);

export const getWorkOrderRevisionAttachmentName = (workOrder: WorkOrderBase, storageName: string) => `${workOrder.id}-${workOrder.revision}-${storageName}`;

export const initializeWorkOrderRevision = (
	workOrder: WorkOrderBase,
	accountId: Nullable<number>,
	workOrderAttachments?: AttachmentBase[]
): Partial<WorkOrderRevisionBase> => {
	const _workRequest = workOrder.workRequest;

	let customerName: Nullable<string> = null;
	if (_workRequest?.customerCompanyName) {
		customerName = _workRequest.customerCompanyName;
	} else if (_workRequest?.customerContact?.contact?.companyName) {
		customerName = _workRequest.customerContact.contact.companyName;
	}

	const totalRevisions = workOrder.workOrderRevisions.filter((rev) => rev.revision === workOrder.revision).length;
	const travelLocation = _workRequest?.travelLocation;

	const _company = workOrder?.company;
	const attachments = (workOrderAttachments ?? []).map((_att: AttachmentBase) => ({
		uploadedBy: getUserName(_att?.uploadedBy?.user),
		uploadedOn: _att.createdAt,
		size: _att.size,
		name: _att.name,
		type: _att.type,
		storageName: getWorkOrderRevisionAttachmentName(workOrder, _att.storageName),
		storageContainer: BlobStorageContainer.WO_REVISION_ATTACHMENTS,
	}));

	const workOrderResourceLookups = (workOrder?.workOrderResourceLookups ?? []).map((_worl) => {
		const workOrderResourceLookup: WorkOrderResourceLookupModel = {
			id: _worl?.id,
			index: _worl?.index,
			name: '',
			color: null,
			notification: null,
			skills: [],
			workOrderEmployeeId: _worl?.workOrderEmployeeId ?? undefined,
			workOrderEquipmentId: _worl?.workOrderEquipmentId ?? undefined,
			workOrderTemporaryEmployeeId: _worl?.workOrderTemporaryEmployeeId ?? undefined,
			wageRateId: _worl?.workOrderPlaceholder?.wageRateId ?? undefined,
			equipmentCostId: _worl?.workOrderPlaceholder?.equipmentCostId ?? undefined,
			isAvailable: true,
		};

		if (workOrderResourceLookup.workOrderEmployeeId) {

			if (!_worl?.workOrderEmployee) {
				throw new Error('[WO REVISION] Employee not initialized');
			}

			const workOrderEmployee = _worl.workOrderEmployee;
			const employee = workOrderEmployee.employee;
			const userName = getUserName(employee.account?.user, true);
			const wageRateType = employee?.wageRate?.type;
			const account = employee?.account;
			const user = account?.user;

			workOrderResourceLookup.employeeId = employee?.id;
			workOrderResourceLookup.name = `${userName} ${wageRateType}`;
			workOrderResourceLookup.color = account?.location?.color ?? null;
			workOrderResourceLookup.skills = (employee?.skills ?? []).map((_skill) => _skill.color);

			const smsNotificationStatus = (workOrderEmployee?.templateNotificationLookup ?? [])
				.find((_lookup) => _lookup.notificationStatus.type === NotificationTypeEnum.SMS);
			const emailNotificationStatus = (workOrderEmployee?.templateNotificationLookup ?? [])
				.find((_lookup) => _lookup.notificationStatus.type === NotificationTypeEnum.EMAIL);

			workOrderResourceLookup.notification = {
				fullName: getUserName(employee?.account?.user),
				email: user?.email ?? null,
				officeNickname: account?.location?.nickname,
				phoneNumber: user?.phoneNumber ?? null,
				countryCode: employee?.account?.user?.countryCode ?? null,
				employeeId: workOrderEmployee?.employeeId,
				emailInvalidatedAt: user?.emailInvalidatedAt ?? null,
				phoneInvalidatedAt: user?.phoneInvalidatedAt ?? null,
				subscriptionStatus: user?.subscriptionStatus ?? undefined,
				smsSentAt: smsNotificationStatus?.notificationStatus?.sentAt ?? undefined,
				emailSentAt: emailNotificationStatus?.notificationStatus?.sentAt ?? undefined,
				smsStatus: smsNotificationStatus?.notificationStatus?.status,
				emailStatus: emailNotificationStatus?.notificationStatus?.status,
				emailErrorMessage: emailNotificationStatus?.notificationStatus?.errorMessage ?? undefined,
				smsErrorMessage: smsNotificationStatus?.notificationStatus?.errorMessage ?? undefined,
				notificationType: workOrderEmployee?.templateNotificationLookup?.sort(
					(a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
				)[0]?.type ?? undefined,
			};
		} else if (workOrderResourceLookup.workOrderEquipmentId) {
			// FIXME: categoryColor is undefined when accessing it directly through workOrderResourceLookup. That's why find function is used here.
			const _workOrderEquipment = (workOrder?.workOrderEquipment ?? []).find((_woe) => _woe.id === workOrderResourceLookup.workOrderEquipmentId);
			const _equipment = _workOrderEquipment?.equipment;
			workOrderResourceLookup.equipmentId = _equipment?.id;
			workOrderResourceLookup.name = `${_equipment?.code ?? 'N/A'} ${_equipment?.specification ?? ''}`;
			workOrderResourceLookup.color = _equipment?.equipmentCost?.category?.categoryColor ?? null;
			workOrderResourceLookup.isAvailable = _worl?.workOrderEquipment?.equipment?.dailyEquipmentStatus?.[0]?.equipmentStatus?.available ?? true;
		} else if (workOrderResourceLookup.wageRateId) {
			const _wageRate = _worl?.workOrderPlaceholder?.wageRate;
			workOrderResourceLookup.name = `${_wageRate?.type} (${_wageRate?.wageClassification})`;
			workOrderResourceLookup.skills = (_worl?.workOrderPlaceholder?.skills ?? []).map((_skill) => _skill.color);
		} else if (workOrderResourceLookup.workOrderTemporaryEmployeeId) {

			if (!_worl?.workOrderTemporaryEmployee) {
				throw new Error('[WO REVISION] Temporary Employee not initialized');
			}

			const workOrderTemporaryEmployee = _worl.workOrderTemporaryEmployee;
			const temporaryEmployee = workOrderTemporaryEmployee.temporaryEmployee;
			const account = temporaryEmployee?.account;
			const user = account?.user;
			const userName = getUserName(user, true);
			const agency = temporaryEmployee?.agency;

			workOrderResourceLookup.temporaryEmployeeId = temporaryEmployee?.id;
			workOrderResourceLookup.name = userName;
			workOrderResourceLookup.color = agency?.color ?? null;
			workOrderResourceLookup.agency = agency.name ?? null;

			const smsNotificationStatus = (workOrderTemporaryEmployee?.templateNotificationLookup ?? [])
				.find((_lookup) => _lookup.notificationStatus.type === NotificationTypeEnum.SMS);
			const emailNotificationStatus = (workOrderTemporaryEmployee?.templateNotificationLookup ?? [])
				.find((_lookup) => _lookup.notificationStatus.type === NotificationTypeEnum.EMAIL);

			workOrderResourceLookup.notification = {
				fullName: getUserName(user),
				email: user?.email ?? null,
				officeNickname: account?.location?.nickname,
				phoneNumber: user?.phoneNumber ?? null,
				countryCode: temporaryEmployee?.account?.user?.countryCode ?? null,
				temporaryEmployeeId: workOrderTemporaryEmployee?.temporaryEmployeeId,
				emailInvalidatedAt: user?.emailInvalidatedAt ?? null,
				phoneInvalidatedAt: user?.phoneInvalidatedAt ?? null,
				subscriptionStatus: user?.subscriptionStatus ?? undefined,
				smsSentAt: smsNotificationStatus?.notificationStatus?.sentAt ?? undefined,
				emailSentAt: emailNotificationStatus?.notificationStatus?.sentAt ?? undefined,
				smsStatus: smsNotificationStatus?.notificationStatus?.status,
				emailStatus: emailNotificationStatus?.notificationStatus?.status,
				emailErrorMessage: emailNotificationStatus?.notificationStatus?.errorMessage ?? undefined,
				smsErrorMessage: smsNotificationStatus?.notificationStatus?.errorMessage ?? undefined,
				notificationType: workOrderTemporaryEmployee?.templateNotificationLookup?.sort(
					(a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
				)[0]?.type ?? undefined,
			};
		} else {
			const _equipmentCost = _worl?.workOrderPlaceholder?.equipmentCost;
			workOrderResourceLookup.name = `${_equipmentCost?.subcategory} (${_equipmentCost?.category?.name})`;
			workOrderResourceLookup.color = _equipmentCost?.category?.categoryColor ?? null;
			workOrderResourceLookup.skills = (_worl?.workOrderPlaceholder?.skills ?? []).map((_skill) => _skill.color);
		}
		return workOrderResourceLookup;
	});

	const addresses = (workOrder?.addresses ?? []).map((_address: WorkOrderAddressBase): AddressModel => ({
		latitude: _address.address.latitude,
		longitude: _address.address.longitude,
		streetAddress: getStreetAddress(_address.address),
		suite: _address.address.suite,
		locality: _address.address.locality,
		aa1: _address.address.aa1,
		postalCode: _address.address.postalCode,
		postalOfficeBoxCode: _address.address.postalOfficeBoxCode,
		country: _address.address.country,
	}));

	// copy site contact
	const _siteContact = workOrder?.siteContact;
	const contact = _siteContact?.contact;

	const contactAddressIds = (_siteContact?.contactLookupAddresses ?? []).map((_wrcAdr) => _wrcAdr.contactAddressId);
	const { emails, phoneNumbers } = ContactUtils.getActiveContactMethods<ContactMethodModel, ContactMethodModel>(
		_siteContact,
		(cm: ContactMethodBase) => ({ value: cm.value, type: cm.type, countryCode: cm.countryCode ?? undefined }),
		(cm: ContactMethodBase) => ({ value: cm.value, type: cm.type })
	);

	const _addresses = (contact?.addresses ?? []).reduce((_acc: ContactAddressModel[], _address: ContactAddressBase) => {
		if (contactAddressIds.includes(_address.id)) {
			_acc.push({
				street: getStreetAddress(_address.address),
				suite: _address.address.suite,
				city: _address.address.locality,
				stateAbbreviation: _address.address.aa1 ? stateAbbreviation[_address.address.aa1] : null,
				zip: _address.address.postalCode,
				country: _address.address.country,
			});
		}
		return _acc;
	}, []);

	const siteContact = contact ? {
		id: contact.id,
		name: contact.fullName,
		companyName: contact.companyName ?? '',
		companyRole: contact.title ?? '',
		emails,
		phones: phoneNumbers,
		addresses: _addresses,
		siteContactId: _siteContact.id,
	} : null;

	const deliverableAssignee = _workRequest?.deliverableAssignee?.user;

	return {
		workOrderId: workOrder.id,
		workRequestId: _workRequest.id,
		jobTitle: _workRequest?.title ?? 'N/A', // FIXME: Incorrect, either both needs to be nullish or none of it should
		jobCode: _workRequest?.jobCode,
		jobStatus: _workRequest?.status,
		workRequestYear: _workRequest?.year,
		workRequestCode: _workRequest?.code,
		isJobInternal: workOrder.isInternal,
		officeNickname: _workRequest?.office?.nickname ?? null,
		officeColor: _workRequest?.office?.color ?? null,
		divisionName: _workRequest?.division?.name ?? null,
		customerName,
		workLocationAddress: travelLocation ? getShortAddress(travelLocation) : null,
		companyAddress: getShortAddress(_company?.primaryAddress),
		companyName: _company?.name,
		supervisor: new UserModel(workOrder.supervisor?.account?.user ?? null),
		projectManager: new UserModel(workOrder.projectManager?.account?.user ?? null),
		attachments,
		notes: workOrder.notes,
		jobNotes: _workRequest?.requestJobNote,
		scopeOfWork: workOrder.scopeOfWork,
		shiftName: workOrder.shift?.name,
		crewTypeName: workOrder.crewType?.name ?? null,
		crewTypeColor: workOrder.crewType?.color ?? null,
		customCrewType: workOrder.customCrewType,
		code: workOrder.code,
		dueDate: workOrder.dueDate,
		timeToStart: workOrder.timeToStart,
		timeToEnd: workOrder.timeToEnd,
		revision: workOrder.revision,
		statusBasedRevision: totalRevisions + 1,
		workOrderStatus: workOrder.isPaused ? WorkOrderStatusRevision.PAUSED : WorkOrderStatusRevision.ACTIVE,
		pauseReason: workOrder?.pauseReason ?? null,
		publicLink: workOrder.publicLink,
		createdById: accountId,
		workOrderResourceLookups,
		addresses,
		siteContact,
		// work order production data
		revenue: workOrder?.revenue,
		manHourAverage: workOrder?.manHourAverage,
		jobHours: workOrder?.jobHours,
		shopHours: workOrder?.shopHours,
		travelHours: workOrder?.travelHours,
		workDescription: workOrder?.workDescription,
		// Deliverable data
		deliverableAssignee: deliverableAssignee ? new UserModel(deliverableAssignee) : null,
		deliverableCodeStandard: _workRequest?.deliverableCodeStandard?.name ?? null,
		deliverableFileFormat: _workRequest?.deliverableFileFormat?.name ?? null,
		deliverableSoftware: _workRequest?.deliverableSoftware?.name ?? null,
		deliverableDeliveryMethod: _workRequest?.deliverableDeliveryMethod?.name ?? null,
		deliverableDeliveryTimeline: _workRequest?.deliverableDeliveryTimeline?.name ?? null,

	};
};

export const getPdfAttachments = (attachments: (AttachmentBase | AttachmentModel)[]) => {
	return attachments.filter((_attachment) => _attachment.type === FileType.PDF);
};

export const isWorkOrderFromBeforeCurrentWeek = (dueDate: string) => {
	const _dueDate = TimeUtils.parseMoment(dueDate, TimeFormat.DB_DATE_ONLY); // TODO: don't expose moment
	return !!_dueDate?.isBefore(moment().startOf('week'));
};

export const getWorkOrderPlaceholderText = (workOrderPlaceholder: WorkOrderPlaceholderBase) => {
	if (workOrderPlaceholder.wageRateId) {
		return `${workOrderPlaceholder?.wageRate?.type} (${workOrderPlaceholder?.wageRate?.wageClassification})`;
	} else {
		return `${workOrderPlaceholder?.equipmentCost?.subcategory} (${workOrderPlaceholder?.equipmentCost?.category?.name})`;
	}
};

export interface Difference<T, K> {
	workOrder: T;
	workOrderRevision: K;
}

export interface JobDifferences {
	workRequestId: Difference<number, number>;
	customerCompanyName: Difference<string, string>;
	location: Difference<string, string>;
	office: Difference<Nullable<LocationBase>, Nullable<LocationBase>>;
	title: Difference<string, string>;
	jobCode: Difference<string, string>;
}

export interface CrewAndDateDifferences {
	crewTypeId?: Difference<number, number>;
	crewTypeName?: Difference<string, string>;
	crewTypeColor?: Difference<Nullable<ColorPalette>, Nullable<ColorPalette>>;
	supervisor?: Difference<Nullable<EmployeeBase>, EmployeeBase>;
	dueDate?: Difference<string, string>;
}

export interface GeneralDifferences {
	projectManager?: Difference<Nullable<EmployeeBase>, Nullable<EmployeeBase>>;
	shiftId?: Difference<number, number>;
	shiftName?: Difference<string, string>;
	timeToStart?: Difference<number, number>;
	timeToEnd?: Difference<number, number>;
}

export interface LocationsDifferences {
	addresses?: Difference<WorkOrderAddressBase[], AddressModel[]>;
	siteContact?: Difference<Nullable<ContactLookupBase>, Nullable<WorkOrderRevisionContactModel>>;
}

export interface ResourcesDifferences {
	employees: Difference<WorkOrderEmployeeBase[], EmployeeBase[]>;
	temporaryEmployees: Difference<WorkOrderTemporaryEmployeeBase[], TemporaryEmployeeBase[]>;
	equipment: Difference<WorkOrderEquipmentBase[], EquipmentBase[]>;
}

export interface Notes_1_Differences {
	notes?: Difference<Nullable<string>, Nullable<string>>;
	jobNotes?: Difference<Nullable<string>, Nullable<string>>;
	scopeOfWork?: Difference<Nullable<string>, Nullable<string>>;
}

export interface Notes_2_Differences {
	jobHours?: Difference<Nullable<number>, Nullable<number>>;
	revenue?: Difference<Nullable<string>, Nullable<string>>;
	shopHours?: Difference<Nullable<number>, Nullable<number>>;
	travelHours?: Difference<Nullable<number>, Nullable<number>>;
	workDescription?: Difference<Nullable<string>, Nullable<string>>;
	manHourAverage?: Difference<Nullable<string>, Nullable<string>>;
}

export type Differences = {
	[RevisionInformationGroupsEnum.JOB]?: JobDifferences;
	[RevisionInformationGroupsEnum.CREW_AND_DATE]?: CrewAndDateDifferences;
	[RevisionInformationGroupsEnum.GENERAL]?: GeneralDifferences;
	[RevisionInformationGroupsEnum.LOCATIONS]?: LocationsDifferences;
	[RevisionInformationGroupsEnum.RESOURCES]?: ResourcesDifferences;
	[RevisionInformationGroupsEnum.NOTES_1]?: Notes_1_Differences;
	[RevisionInformationGroupsEnum.NOTES_2]?: Notes_2_Differences;
};

type DifferenceKeys = keyof (
	JobDifferences & CrewAndDateDifferences & GeneralDifferences & LocationsDifferences & ResourcesDifferences & Notes_1_Differences & Notes_2_Differences
);

export const compareWorkOrderToRevision = async (
	workOrder: WorkOrderBase,
	workOrderRevision: WorkOrderRevisionBase,
	revisionJob: WorkRequestBase,
	workOrderRevisionEmployees: EmployeeBase[],
	workOrderRevisionTemporaryEmployees: TemporaryEmployeeBase[],
	workOrderRevisionEquipment: EquipmentBase[],
	revisionCrewType: Nullable<CrewType>,
	revisionShift: ShiftBase,
	revisionSupervisor: EmployeeBase,
	revisionProjectManager: Nullable<EmployeeBase>
): Promise<Differences> => {
	const differences: Differences = {};

	const currentJob = workOrder.workRequest;

	const _addDifference = <T, K, G extends RevisionInformationGroupsEnum>(
		group: G,
		key: DifferenceKeys,
		workOrderValue: Nullable<T>,
		workOrderRevisionValue: Nullable<K>
	) => {
		if (!differences[group]) {
			differences[group] = {} as Differences[G];
		}

		differences[group]![key] = {
			workOrder: workOrderValue,
			workOrderRevision: workOrderRevisionValue,
		};
	};

	const _compare = <T, K, G extends RevisionInformationGroupsEnum>(
		prop1: Nullable<T>,
		prop2: Nullable<K>,
		name: DifferenceKeys,
		group: G,
		secondaryProp?: string
	): void => {
		const value1 = secondaryProp ? prop1?.[secondaryProp] : prop1;
		const value2 = secondaryProp ? prop2?.[secondaryProp] : prop2;

		if (value1 !== value2) {
			if (!differences[group]) {
				differences[group] = {} as Differences[G];
			}

			differences[group]![name] = {
				workOrder: prop1,
				workOrderRevision: prop2,
			};
		}
	};

	function _addressesAreEqual(woaAddress: AddressBase, addressModel: AddressModel): boolean {
		return (
			woaAddress.latitude === addressModel.latitude &&
			woaAddress.longitude === addressModel.longitude
		);
	}

	function _addressArraysAreEqual(workOrderAddresses: WorkOrderAddressBase[], addressModels: AddressModel[]): boolean {
		if (workOrderAddresses.length !== addressModels.length) {
			return false;
		}

		const sortedWOA = [...workOrderAddresses].sort((a, b) =>
			(a.address.latitude - b.address.latitude) || (a.address.longitude - b.address.longitude)
		);

		const sortedAM = [...addressModels].sort((a, b) =>
			(a.latitude - b.latitude) || (a.longitude - b.longitude)
		);

		return sortedWOA.every((woa, index) =>
			_addressesAreEqual(woa.address, sortedAM[index])
		);
	}

	// If jobs are different, put important info in appropriate diff group
	if (currentJob.id !== revisionJob.id) {
		_addDifference(RevisionInformationGroupsEnum.JOB, 'workRequestId', currentJob.id, revisionJob.id);
		_addDifference(RevisionInformationGroupsEnum.JOB, 'title', currentJob.title, revisionJob.title);
		_addDifference(RevisionInformationGroupsEnum.JOB, 'jobCode', currentJob.jobCode, revisionJob.jobCode);
		_addDifference(RevisionInformationGroupsEnum.JOB, 'office', currentJob.office, revisionJob.office);
		_addDifference(RevisionInformationGroupsEnum.JOB, 'customerCompanyName', currentJob.customerCompanyName, revisionJob.customerCompanyName);
		_addDifference(RevisionInformationGroupsEnum.JOB, 'location', getShortAddress(currentJob.travelLocation), getShortAddress(revisionJob.travelLocation));
	}

	// compare crew information and date
	_compare(workOrder.crewType?.id, revisionCrewType?.id, 'crewTypeId', RevisionInformationGroupsEnum.CREW_AND_DATE);
	_compare(workOrder.crewType?.name, workOrderRevision.crewTypeName, 'crewTypeName', RevisionInformationGroupsEnum.CREW_AND_DATE);
	_compare(workOrder.crewType?.color, workOrderRevision.crewTypeColor, 'crewTypeColor', RevisionInformationGroupsEnum.CREW_AND_DATE);
	_compare(workOrder.supervisor, revisionSupervisor, 'supervisor', RevisionInformationGroupsEnum.CREW_AND_DATE, 'id');
	_compare(workOrder.dueDate, workOrderRevision.dueDate, 'dueDate', RevisionInformationGroupsEnum.CREW_AND_DATE);

	// compare general information
	_compare(workOrder.projectManager, revisionProjectManager, 'projectManager', RevisionInformationGroupsEnum.GENERAL);
	_compare(workOrder.shift?.id, revisionShift.id, 'shiftId', RevisionInformationGroupsEnum.GENERAL);
	_compare(workOrder.shift?.name, workOrderRevision.shiftName, 'shiftName', RevisionInformationGroupsEnum.GENERAL);
	if (workOrder.timeToStart !== workOrderRevision.timeToStart || workOrder.timeToEnd !== workOrderRevision.timeToEnd) {
		_addDifference(RevisionInformationGroupsEnum.GENERAL, 'timeToStart', workOrder.timeToStart, workOrderRevision.timeToStart);
		_addDifference(RevisionInformationGroupsEnum.GENERAL, 'timeToEnd', workOrder.timeToEnd, workOrderRevision.timeToEnd);
	}

	// compare notes
	_compare(workOrder.notes, workOrderRevision.notes, 'notes', RevisionInformationGroupsEnum.NOTES_1);
	_compare(currentJob.requestJobNote, revisionJob.requestJobNote, 'jobNotes', RevisionInformationGroupsEnum.NOTES_1);
	_compare(workOrder.scopeOfWork, workOrderRevision.scopeOfWork, 'scopeOfWork', RevisionInformationGroupsEnum.NOTES_1);

	_compare(workOrder.revenue, workOrderRevision.revenue, 'revenue', RevisionInformationGroupsEnum.NOTES_2);
	_compare(workOrder.manHourAverage, workOrderRevision.manHourAverage, 'manHourAverage', RevisionInformationGroupsEnum.NOTES_2);
	_compare(workOrder.jobHours, workOrderRevision.jobHours, 'jobHours', RevisionInformationGroupsEnum.NOTES_2);
	_compare(workOrder.shopHours, workOrderRevision.shopHours, 'shopHours', RevisionInformationGroupsEnum.NOTES_2);
	_compare(workOrder.travelHours, workOrderRevision.travelHours, 'travelHours', RevisionInformationGroupsEnum.NOTES_2);
	_compare(workOrder.workDescription, workOrderRevision.workDescription, 'workDescription', RevisionInformationGroupsEnum.NOTES_2);

	// compare resources
	const employees = workOrder.workOrderEmployees;
	const temporaryEmployees = workOrder.workOrderTemporaryEmployees;
	const equipment = workOrder.workOrderEquipment;

	const revisionEmployees = workOrderRevision.workOrderResourceLookups?.map(
		(rl) => workOrderRevisionEmployees.find((e) => e.id === rl.employeeId)).filter((e) => e !== undefined) as EmployeeBase[];

	const revisionTemporaryEmployees = workOrderRevision.workOrderResourceLookups?.map(
		(rl) => workOrderRevisionTemporaryEmployees.find((e) => e.id === rl.temporaryEmployeeId)).filter((e) => e !== undefined) as TemporaryEmployeeBase[];

	const revisionEquipment = workOrderRevision.workOrderResourceLookups?.map(
		(rl) => workOrderRevisionEquipment.find((e) => e.id === rl.equipmentId)).filter((e) => e !== undefined) as EquipmentBase[];

	const employeesAreEqual = arraysHaveSameElements(
		employees.map((e) => e.employeeId),
		revisionEmployees.map((e) => e.id)
	);
	const temporaryEmployeesAreEqual = arraysHaveSameElements(
		temporaryEmployees.map((e) => e.temporaryEmployeeId),
		revisionTemporaryEmployees.map((e) => e.id)
	);
	const equipmentIsEqual = arraysHaveSameElements(
		equipment.map((e) => e.equipmentId),
		revisionEquipment.map((e) => e.id)
	);

	// if any of resources is different, return all
	if (!employeesAreEqual || !temporaryEmployeesAreEqual || !equipmentIsEqual) {
		_addDifference(RevisionInformationGroupsEnum.RESOURCES, 'employees', employees, revisionEmployees);
		_addDifference(RevisionInformationGroupsEnum.RESOURCES, 'temporaryEmployees', temporaryEmployees, revisionTemporaryEmployees);
		_addDifference(RevisionInformationGroupsEnum.RESOURCES, 'equipment', equipment, revisionEquipment);
	}

	if (!_addressArraysAreEqual(workOrder.addresses, workOrderRevision.addresses ?? [])) {
		_addDifference(RevisionInformationGroupsEnum.LOCATIONS, 'addresses', workOrder.addresses, workOrderRevision.addresses ?? []);
	}

	if (
		workOrder.siteContact?.contact?.fullName !== workOrderRevision.siteContact?.name
		|| workOrder.siteContact?.contact?.companyName !== workOrderRevision.siteContact?.companyName
	) {
		_addDifference(RevisionInformationGroupsEnum.LOCATIONS, 'siteContact', workOrder.siteContact, workOrderRevision.siteContact);
	}

	{ return differences; }
};

export const getWorkOrderDescriptionForLogs = (workOrder: WorkOrderBase): string => {
	if (!workOrder) {
		return '';
	}

	const jobDescription = getJobDescriptionForLogs(workOrder.workRequest);
	const workOrderCode = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);

	const description = [
		jobDescription,
		workOrderCode,
	].filter(Boolean).join(', ');

	return description;
};
