import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';

import Loading from 'af-root/scenes/Company/ScheduleBoard/Shared/LoadingModal';

import Textarea from 'af-fields/Textarea';

import type { DailyMessageRequestModel } from 'ab-requestModels/dailyMessage.requestModel';

import { DAILY_TIP_MAX_LENGTH } from 'af-constants/values';

import CustomModal from 'af-components/CustomModal';

interface OwnProps {
	modalVisible: boolean;
	isLoading: boolean;
	previousMessages: { message: string; }[];
	label: string;
	onSubmit: (values: DailyMessageRequestModel) => void;
	closeModal: () => void;
}

type Props = OwnProps & InjectedFormProps<DailyMessageRequestModel, OwnProps>;

interface ListElementProps {
	message: string;
	onSelect: (message: string) => void;
}

class DailyMessageModal extends React.PureComponent<Props> {
	selectMessage = (message: string) => {
		const { change } = this.props;
		change('message', message);
	};

	onClick = (props: ListElementProps) => {
		const { message, onSelect } = props;
		onSelect(message);
	};

	renderListElement = (props: ListElementProps) => {
		const { message } = props;

		return (
			<li>
				<b>{message}</b>
				&nbsp;
				<Button
					label="Use this"
					onClick={this.onClick.bind(this, props)}
					size="small"
					style="link"
				/>
			</li>
		);
	};

	render() {
		const { onSubmit, closeModal, modalVisible, handleSubmit, invalid, submitting, label, isLoading, previousMessages } = this.props;

		if (isLoading) {
			return (
				<CustomModal
					className="loading-modal modal-md"
					closeModal={closeModal}
					showModal={modalVisible}
				>
					<Loading onHide={closeModal} />
				</CustomModal>
			);
		}

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={modalVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={`Add ${label}`}
				/>
				<CustomModal.Body>
					<div className="daily-tip-modal">
						<div className="content-form">
							<Field
								component={Textarea}
								label={label}
								maxCharacters={DAILY_TIP_MAX_LENGTH}
								name="message"
								placeholder="Enter Message"
								rows={2}
								showMaxCharactersLabel={true}
							/>
						</div>
						{!!previousMessages.length &&
							<div className="content-list">
								Last {previousMessages.length} Message{previousMessages.length === 1 ? '' : 's'} Used
								<ul>
									{previousMessages.map((_msg, _index) =>
										React.createElement(this.renderListElement, { message: _msg.message, onSelect: this.selectMessage, key: _index })
									)}
								</ul>
							</div>
						}
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Close"
						onClick={closeModal}
						style="secondary"
					/>
					<Button
						disabled={invalid}
						label="Save Message"
						onClick={handleSubmit(onSubmit)}
						style="primary"
						submitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default (formName: string) => reduxForm<DailyMessageRequestModel, OwnProps>({ form: formName, destroyOnUnmount: true })(DailyMessageModal);
