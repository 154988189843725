import * as React from 'react';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import { tryGetStorageUrlForSize } from '@acceligentllc/shared/utils/blobStorage';

import ImageTag from 'af-components/Image';
import CustomModal from 'af-components/CustomModal';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CameraLogo = require('@acceligentllc/assets/assets/ic_camera.svg');

interface OwnProps {
	show: boolean;
	imageUrl: Nullable<string>;
	fieldName: string;
	disabled: boolean;
	isEditable: boolean;
	onHide: () => void;
	onDelete: () => void;
}

interface State {
	imageSrc: Nullable<string>;
}

type Props = OwnProps;

class EditImageModal extends React.PureComponent<Props, State> {

	state: State = {
		imageSrc: this.props.imageUrl ? EditImageModal.getStorageUrl(this.props.imageUrl) : null,
	};

	static getStorageUrl = (imageUrl: string) => {
		return !!imageUrl ? tryGetStorageUrlForSize(imageUrl, BlobStorageImageSizeContainer.SIZE_1260X900, true) : null;
	};

	componentDidUpdate(prevProps: Props) {
		const { imageUrl } = this.props;
		const { imageUrl: prevImageUrl } = prevProps;

		if (imageUrl && (this.state.imageSrc === undefined || imageUrl !== prevImageUrl)) {
			const imageSrc = EditImageModal.getStorageUrl(imageUrl);
			this.setState(() => ({ imageSrc }));
		}
	}

	onDeleteImage = () => {
		const { onDelete, onHide } = this.props;
		onDelete();
		onHide();
	};

	render() {
		const { show, fieldName, disabled, isEditable, onHide } = this.props;
		const { imageSrc } = this.state;

		if (!imageSrc) {
			return null;
		}

		return (
			<CustomModal
				closeModal={onHide}
				modalStyle="info"
				showModal={show}
				size="md"
			>
				<CustomModal.Header
					className="image-field-modal__header"
					closeModal={onHide}
				/>
				<CustomModal.Body>
					<div className="modal-edit-container">
						<ImageTag
							className="image-field-modal__image"
							fallbackSrc={CameraLogo}
							src={imageSrc}
							tryOriginal={true}
						/>
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<div className="image-field-modal__footer">
						<div className="image-field-modal__field-name">{fieldName}</div>
						{(!disabled && isEditable) && <span className="icon-delete image-field-modal__delete" onClick={this.onDeleteImage}></span>}
					</div>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default EditImageModal;
