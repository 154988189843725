import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import type { CellContext } from '@tanstack/react-table';

import CDLStatus, { CDLStatusLabel } from '@acceligentllc/shared/enums/cdlStatus';
import { TimePeriodSpanLabel } from '@acceligentllc/shared/enums/timePeriodSpan';
import { ColorPalette } from '@acceligentllc/shared/enums/color';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type { RootState } from 'af-reducers';

import * as SkillActions from 'af-actions/skill';
import * as KPIActions from 'af-actions/kpi';
import * as CompanyActions from 'af-actions/companies';

import CLIENT from 'af-constants/routes/client';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { SkillViewModel, SkillOptionViewModel } from 'ab-viewModels/skill.viewModel';
import { NO_SKILLS_OPTION } from 'ab-viewModels/skill.viewModel';
import { LaborUtilizationViewModel, LaborUtilizationDataViewModel } from 'ab-viewModels/laborUtilization.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import PlainMultiselectDropdown from 'af-fields/PlainMultiselectDropdown';

import SkillsCell from 'af-components/Table6/Cells/SkillsCell';
import SkillsColorGrid from 'af-components/SkillsColorGrid';
import Dropdown from 'af-components/Controls/Dropdown';
import Breadcrumbs from 'af-components/Breadcrumbs';
import TableNew from 'af-components/Table';
import type { TableRef } from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import BarMeter from 'af-components/BarMeter';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';

import { downloadCSV } from 'af-utils/csv.utils';

import * as ColorUtil from 'ab-utils/color.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';

import InfoCard from './InfoCard';

import styles from './styles.module.scss';

interface CdlOption {
	id: CDLStatus;
	name: CDLStatusLabel;
}

const cdlOptions: CdlOption[] = Object.keys(CDLStatus).map((_cdlStatus: CDLStatus) => ({ id: _cdlStatus, name: CDLStatusLabel[_cdlStatus] }));

type Props = ConnectedProps<typeof connector>;

const getLaborUtilizationTable = (
	data: Nullable<LaborUtilizationDataViewModel>,
	selectedSkillIds: Nullable<number[]>,
	cdlOption: Nullable<CDLStatus>,
	tableRequestModel: TableQuery
) => {
	const sanitizedRequestModel = new TableQuery(tableRequestModel);

	return data ? LaborUtilizationDataViewModel.getTable(
		sanitizedRequestModel,
		selectedSkillIds,
		cdlOption,
		data
	) : {} as TableContent<LaborUtilizationViewModel>;
};

const BREADCRUMBS = [{ label: 'Labor Utilization' }];

const LaborUtilizationTable: React.FC<Props> = (props) => {
	const {
		getCompany,
		findAllForDropdown,
		fetchLaborUtilizationData,
		companyName,
	} = props;

	const _tableRef = React.useRef<TableRef<LaborUtilizationViewModel>>(null);

	const history = useHistory();
	const { state: { orgAlias } } = useLocation<{ orgAlias: string; }>();

	const [utilizationData, setUtilizationData] = React.useState<Nullable<LaborUtilizationDataViewModel>>(null);
	const [skills, setSkills] = React.useState<SkillOptionViewModel[]>([]);
	const [selectedSkills, setSelectedSkills] = React.useState<SkillOptionViewModel[]>([]);
	const [selectedCdlOption, setSelectedCdlOption] = React.useState<Nullable<CdlOption>>(null);

	const renderWorkingCell = React.useCallback(({ cell }: CellContext<LaborUtilizationViewModel, string>) => {
		const { daysAssigned, daysAvailable, daysUnavailable, totalDays } = cell.row.original;

		const workingPercentage = Math.round(daysAssigned / (totalDays || 1) * 100);
		let color: ColorPalette = ColorPalette.GREEN;
		if (workingPercentage < 25) {
			color = ColorPalette.RED;
		} else if (workingPercentage < 67) {
			color = ColorPalette.ORANGE;
		}

		const entries = [
			{ label: 'Scheduled', value: daysAssigned, color: color },
			{ label: 'Not Scheduled', value: daysAvailable, isHidden: true },
			{ label: 'Not Available', value: daysUnavailable, isHidden: true },
		];
		return (
			<div className={styles.working}>
				<span>{workingPercentage}% ({daysAssigned} days)</span>
				<BarMeter entries={entries} total={totalDays} unitLabel="days" withTooltip={true} />
			</div>
		);
	}, []);

	const renderMonetaryCell = React.useCallback(({ cell }: CellContext<LaborUtilizationViewModel, number>) => {
		const value = cell.getValue();
		return (
			<span className={value && value < 0 ? 'text-red' : ''}>
				{value
					? moneyNormalizer(value)
					: <EmptyCell />
				}
			</span>
		);
	}, []);

	const goToEmployeePreview = React.useCallback(async (original: LaborUtilizationViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW(original.id.toString(), orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const goToEmployeeStatusHistory = React.useCallback(async (original: LaborUtilizationViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.EMPLOYEE.STATUS_HISTORY(original.id.toString(), orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<LaborUtilizationViewModel, unknown>) => {
		const options = [
			{ onClick: () => goToEmployeePreview(_cell.row.original), label: 'Preview', shouldRefresh: false },
			{ onClick: () => goToEmployeeStatusHistory(_cell.row.original), label: 'Status History', shouldRefresh: false },
		];

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [goToEmployeePreview, goToEmployeeStatusHistory]);

	const columns: TableProps<LaborUtilizationViewModel>['columns'] = React.useMemo(() => [
		{
			id: 'fullName',
			header: 'Name',
			accessor: 'fullName',
			enableSorting: true,
			cell: ({ cell }) => <strong>{cell.row.original.fullName}</strong>,
		},
		{
			id: 'timePeriodId',
			header: (utilizationData?.timePeriod && TimePeriodSpanLabel[utilizationData.timePeriod]) ?? TimePeriodSpanLabel.YEAR,
			accessor: 'timePeriodId',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.timePeriodId || <EmptyCell />,
		},
		{
			id: 'cdlStatus',
			header: 'CDL',
			accessor: 'cdlStatus',
			enableSorting: false,
			cell: ({ cell }) => CDLStatusLabel[cell.row.original.cdlStatus],
		},
		{
			id: 'skills',
			header: 'Skills',
			accessor: 'skills',
			enableSorting: false,
			cell: ({ cell }) => <SkillsCell skills={cell.row.original.skills} />,
		},
		{
			id: 'daysAssigned',
			header: 'Working',
			accessor: 'daysAssigned',
			enableSorting: true,
			cell: renderWorkingCell,
		},
		{
			id: 'dailyRevenue',
			header: 'Daily Revenue',
			accessor: 'dailyRevenue',
			enableSorting: false,
			cell: renderMonetaryCell,
		},
		{
			id: 'totalRevenue',
			header: 'Total Revenue',
			accessor: 'totalRevenue',
			enableSorting: false,
			cell: renderMonetaryCell,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderMonetaryCell, renderWorkingCell, resolveActionsButton, utilizationData]);

	const initialize = React.useCallback(async () => {
		await getCompany();
		const [
			_skills,
			_utilizationData,
		] = await Promise.all([
			findAllForDropdown(),
			fetchLaborUtilizationData(),
		]);
		setSkills([NO_SKILLS_OPTION, ..._skills]);
		setUtilizationData(_utilizationData);
	}, [fetchLaborUtilizationData, findAllForDropdown, getCompany]);

	React.useEffect(() => {
		initialize();
	}, [initialize]);

	const fetchTable = React.useCallback(async (tableRequestModel: TableQuery) => {
		return getLaborUtilizationTable(
			utilizationData,
			selectedSkills.map((_skillOption) => _skillOption.id),
			selectedCdlOption?.id ?? null,
			tableRequestModel
		) ?? {} as TableContent<LaborUtilizationViewModel>;
	}, [selectedCdlOption?.id, selectedSkills, utilizationData]);

	const onRowClick = React.useCallback(({ original }: { original: LaborUtilizationViewModel; }) => {
		if (original?.id) {
			goToEmployeePreview(original);
		}
	}, [goToEmployeePreview]);

	const onValueChange = React.useCallback(async (_selectedSkills: SkillOptionViewModel[]): Promise<void> => {
		setSelectedSkills(_selectedSkills);
	}, []);

	const renderLaborSkillOptionItem = React.useCallback((option: SkillViewModel) => {
		const { color, name } = option;

		return (
			<span className={styles['utilization-view__option-item']}>
				<div className={`${styles['utilization-view__option-color-preview']} ${ColorUtil.getColorBackgroundClass(color)}`} />
				<span>{name}</span>
			</span>
		);
	}, []);

	const renderCdlSelectedItem = React.useCallback((optionItem: CdlOption) => {
		if (optionItem === null) {
			return <span className={styles['text-grey']}>CDL</span>;
		}
		return <>{optionItem.name}</>;
	}, []);

	const renderCdlMenuItem = React.useCallback((optionItem: CdlOption) => {
		if (optionItem === null) {
			return <></>;
		}
		return <>{optionItem.name}</>;
	}, []);

	const onCdlItemChange = React.useCallback(async (optionItem: CdlOption) => setSelectedCdlOption(optionItem), []);

	const renderSelectedLaborPlaceholderSkillOptionItem = React.useCallback((_skills: SkillViewModel[]) => {
		return (
			<div className={styles['utilization-view__selected-labor-skills']}>
				<SkillsColorGrid skills={_skills} />
				<span>Skills</span>
			</div>
		);
	}, []);

	const additionalFilter = React.useCallback(() => {
		return (
			<>
				<Dropdown<CdlOption>
					hasBlankOption={true}
					id="cdl-filter"
					isWhite={true}
					labelKey="name"
					onValueChange={onCdlItemChange}
					options={cdlOptions}
					renderMenuItem={renderCdlMenuItem}
					renderSelected={renderCdlSelectedItem}
					valueKey="id"
					withCaret={true}
				/>
				<PlainMultiselectDropdown
					containerClassName="utilization-view__labor-skills-dropdown"
					id="multiselectDropdownLaborUtilizationView"
					isWhite={true}
					labelKey="name"
					onValueChange={onValueChange}
					options={skills}
					renderMenuItem={renderLaborSkillOptionItem}
					renderSelected={renderSelectedLaborPlaceholderSkillOptionItem}
					selected={selectedSkills}
					valueKey="id"
					withCaret={true}
				/>
			</>
		);
	}, [
		onCdlItemChange,
		onValueChange,
		renderCdlMenuItem,
		renderCdlSelectedItem,
		renderLaborSkillOptionItem,
		renderSelectedLaborPlaceholderSkillOptionItem,
		selectedSkills,
		skills,
	]);

	const onDownloadCSVClick = React.useCallback(async () => {
		downloadCSV(LaborUtilizationViewModel.toCSVData(utilizationData?.rows ?? []), `${companyName}_labor-utilization.csv`);
	}, [companyName, utilizationData?.rows]);

	const buttons = React.useMemo(() => {
		return [
			{
				label: 'Export as CSV',
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
			},
		];
	}, [onDownloadCSVClick]);

	return (
		<div className={styles['utilization-view']}>
			<Breadcrumbs items={BREADCRUMBS} />
			<InfoCard
				daysAssigned={utilizationData?.daysAssigned ?? 0}
				daysAvailable={utilizationData?.daysAvailable ?? 0}
				daysUnavailable={utilizationData?.daysUnavailable ?? 0}
				targetProfit={utilizationData?.targetProfit ?? 0}
				totalDays={utilizationData?.totalDays ?? 0}
				totalRevenue={utilizationData?.totalRevenue ?? 0}
			/>
			<TableNew
				additionalFilter={additionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchTable}
				hasSearchInput={true}
				offsetHeight={255}
				onRowClick={onRowClick}
				ref={_tableRef}
				searchLabel={'Employee'}
				selectable={false}
				tableName={TableNameEnum.LABOR_UTILIZATION}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getCompany: CompanyActions.getCompany,
		findAllForDropdown: SkillActions.findAllForDropdown,
		fetchLaborUtilizationData: KPIActions.fetchLaborUtilizationData,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(LaborUtilizationTable);
