import * as React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import * as papaParse from 'papaparse';

import type UnitEnum from '@acceligentllc/shared/enums/quantityUnit';
import { QuantityUnitLabel, QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';

import UploadStatus from 'ab-enums/uploadStatus.enum';

import type { CSVData } from 'ab-viewModels/csv.viewModel';

import ImportBillingCodeRM from 'ab-requestModels/billingCode/importBillingCode.requestModel';

import CustomModal from 'af-components/CustomModal';
import CodeField from 'af-components/CodeField';
import CSVUploadMessage from 'af-components/Dropzone/CSVUploadMessage';
import Dropzone from 'af-components/Dropzone';
import SegmentLabel from 'af-components/SegmentLabel';

import * as CSVUtils from 'af-utils/csv.utils';

import parse from 'af-components/SharedForms/Job/BillingCodes/BulkImport/parse';

import { BILLING_CODE_CSV_SAMPLE } from 'af-constants/csvSamples';

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	addImportedBillingCodes: (billingCodes: Array<ImportBillingCodeRM>) => void;
}

type Props = OwnProps;

const manual = [
	'Fields that should be located in the CSV are listed below.',
	'The ones marked with an asterisk are required and should not be left out.',
];

const fields = (
	<CodeField isBlue={true}>
		<>
			<b>lineItemNumber</b>           | * | Line Item Number <br />
			<b>customerNumber</b>           |   | Customer Number <br />
			<b>customerId</b>               | * | Customer ID <br />
			<b>ownerNumber</b>              |   | Owner Number <br />
			<b>ownerId</b>                  |   | Owner ID <br />
			<b>bidQuantity</b>              |   | Bid Quantity <br />
			<b>unit</b>                     | * | Unit (Supported units are listed below)<br />
			<b>unitPrice</b>                | * | Unit Price (Supported format: 100000.00)<br />
			<b>group</b>                    |   | Group <br />
			<b>description</b>              | * | Description <br />
		</>
	</CodeField>
);

const notes = 'You can find a sample of what the CSV should look like and adjust yours accordingly';

const mandatoryFields = ['lineItemNumber', 'customerId', 'unit', 'unitPrice', 'description'];

const validateHeader = (inputFields: string[]) => {
	let headerErrors = {};

	for (const _header of mandatoryFields) {
		if (!inputFields.includes(_header)) {
			headerErrors = {
				...headerErrors,
				[`${_header}`]: `Header is missing field ${_header}.`,
			};
		}
	}
	return headerErrors;
};

const BulkImport: React.FC<Props> = (props) => {
	const {
		showModal,
		closeModal,
		addImportedBillingCodes,
	} = props;

	const [
		showInstructions,
		setShowInstructions,
	] = React.useState<boolean>(false);
	const [
		filesError,
		setFilesError,
	] = React.useState<Nullable<JSX.Element>>(null);
	const [
		importedCount,
		setImportedCount,
	] = React.useState<number>(0);
	const [
		updatedCount,
		setUpdatedCount,
	] = React.useState<number>(0);
	const [
		uploadingFileName,
		setUploadingFileName,
	] = React.useState<string>('');
	const [
		uploadStatus,
		setUploadStatus,
	] = React.useState<UploadStatus>(UploadStatus.INITIAL);
	const [
		currentUploadedBillingCodes,
		setCurrentUploadedBillingCodes,
	] = React.useState<Nullable<ImportBillingCodeRM[]>>(null);

	const initialize = React.useCallback(async () => {
		setShowInstructions(false);
		setUploadStatus(UploadStatus.INITIAL);
		setUploadingFileName('');
		setImportedCount(0);
		setUpdatedCount(0);
		setFilesError(null);
		setCurrentUploadedBillingCodes(null);
	}, []);

	const handleCloseModal = React.useCallback(async () => {
		initialize();
		closeModal();
	}, [initialize, closeModal]);

	const handleSave = React.useCallback(() => {
		if (currentUploadedBillingCodes) {
			addImportedBillingCodes(currentUploadedBillingCodes);
		}
		handleCloseModal();
	}, [addImportedBillingCodes, currentUploadedBillingCodes, handleCloseModal]);

	const uploadBillingCodes = async (form: ImportBillingCodeRM[]) => {
		setCurrentUploadedBillingCodes(form);
	};

	const upload = React.useCallback(async (acceptedFiles) => {
		const fileReader = new FileReader();
		const [csvFile] = acceptedFiles;

		setUploadingFileName(csvFile.name);
		setUploadStatus(UploadStatus.UPLOADING);

		const fileReaderOnloadWrapper = async () => {
			return new Promise<CSVData>((resolve, reject) => {
				fileReader.onload = (e) => {
					const target = e.target as FileReader;
					const _result = target.result as string;
					const { errors, data, meta } = papaParse.parse(_result, { header: true, skipEmptyLines: true });

					try {
						if (mandatoryFields) {
							const headerErrors = validateHeader(meta.fields ?? []);
							if (Object.keys(headerErrors).length) {
								return reject(headerErrors);
							}
						}
						// UndetectableDelimiter appears when we try to upload a single-column csv (like members' emails)
						if (errors.length && (errors.length > 1 || errors[0].code !== 'UndetectableDelimiter')) {
							return reject({ contacts: 'CSV is invalid' });
						}
						return resolve(data as CSVData);

					} catch (error) {
						if (errors.length === 1 && errors[0].code === 'UndetectableDelimiter') {
							return;
						}
						throw error;
					}
				};

				fileReader.onerror = () => {
					try {
						return reject({ contacts: 'Something went wrong, try refreshing page' });
					} catch (error) {
						throw error;
					}
				};
			});
		};

		try {
			fileReader.readAsText(csvFile);
			const data = await fileReaderOnloadWrapper();
			const sanitizedData = CSVUtils.sanitize(data);
			const parsedData = parse(sanitizedData);

			const validationResults = ImportBillingCodeRM.bulkValidateRequest(parsedData);

			const isValid = validationResults.every((vr) => vr.isValid === true);

			const validationErrors = validationResults.map((vr) => vr.errors);
			if (!isValid) {
				// Manual error handling
				setUploadStatus(UploadStatus.FAILURE);
				setFilesError(CSVUtils.formatErrorTooltip(validationErrors));
				setImportedCount(0);
				setUpdatedCount(0);
				return;
			}
			for (const _rowData of parsedData) {
				if (_rowData.unit) {
					const _rowUnit = _rowData.unit.toUpperCase().replace('/', '_');
					if (QuantityUnitMap[_rowUnit]) {
						_rowData.unit = QuantityUnitMap[_rowUnit];
					}
				}
			}
			setUploadStatus(UploadStatus.SUCCESS);
			setFilesError(null);
			setImportedCount(parsedData.length);
			setUpdatedCount(parsedData.length);
			uploadBillingCodes(parsedData);
			return;

		} catch (err) {
			const { errors } = err;
			setUploadStatus(UploadStatus.FAILURE);
			setFilesError(CSVUtils.formatErrorTooltip(errors || err));
			setImportedCount(0);
			setUpdatedCount(0);
			return;
		}
	}, []);

	const toggleInstructions = React.useCallback(() => {
		setShowInstructions((prev) => !prev);
	}, []);

	const renderManual = () => (
		<div className="bulk-upsert__manual">
			How to use:
			<br />
			{
				manual.map((_step: string, _index: number) => (
					<div key={`manualStep#${_index}`}>
						<span className="bulk-upsert__manual-step">{_index + 1}.</span>
						{_step}
					</div>
				))
			}
		</div>
	);

	const renderFields = () => (
		<div className="bulk-upsert__fields">
			{fields}
		</div>
	);

	const renderNotes = () => (
		<div className="bulk-upsert__note">
			<span className="bulk-upsert__note-label">NOTE:</span>
			{notes}
		</div>
	);

	const renderUnitTypes = () => (
		<div className="bulk-upsert__note">
			<b>SUPPORTED UNITS: <br /></b>
			{Object.keys(QuantityUnitLabel)
				.map((_unit: UnitEnum) => (
					<span key={_unit}>
						{QuantityUnitLabel[_unit]} <br />
					</span>
				))
			}
		</div >
	);

	const renderDownloadCSV = () => {
		return (
			<a
				className="btn--upload"
				download="sample.csv"
				href={CSVUtils.generateCSVHref(BILLING_CODE_CSV_SAMPLE)}
			>
				Download the sample CSV file
			</a>
		);
	};

	return (
		<CustomModal
			closeModal={handleCloseModal}
			modalStyle="info"
			showModal={showModal}
			size="xl"
		>
			<CustomModal.Header
				closeModal={handleCloseModal}
				title="Import CSV"
			/>
			<CustomModal.Body>
				<div className="form-box">
					<Row>
						<Col sm={24}>
							<SegmentLabel label="INSTRUCTIONS" />
						</Col>
					</Row>
					<Row className={showInstructions ? '' : 'row--padded-bottom'}>
						<Col className="bulk-upsert__instructions-header" sm={24}>
							<div className="bulk-upsert__instruction-text">
								<span>
									You can use the CSV import tool below to upload large amounts of data into your Acceligent web application. The data must
									be formatted correctly in order for the upload to work. {renderDownloadCSV()} and read more about it in the instructions.
								</span>
							</div>
							<a
								className={`btn btn-toggle ${showInstructions ? 'active' : ''}`}
								onClick={toggleInstructions}
								type="button"
							>
								<span className={showInstructions ? 'icon-up' : 'icon-down'} />
								Instructions
							</a>
						</Col>
					</Row>
					{
						showInstructions &&
						<>
							<hr />
							<Row className="row--padded-bottom">
								<Col sm={24}>
									{renderManual()}
									{renderFields()}
									{renderNotes()}
									{renderUnitTypes()}
								</Col>
							</Row>
						</>
					}
				</div>
				<Form>
					<div>
						<Row>
							<Col sm={24}>
								<SegmentLabel label="Upload CSV" />
								<Dropzone onDrop={upload}>
									<CSVUploadMessage
										filesError={filesError}
										importedCount={importedCount}
										updatedCount={updatedCount}
										uploadingFileName={uploadingFileName}
										uploadStatus={uploadStatus}
									/>
								</Dropzone>
							</Col>
						</Row>
					</div>
				</Form>
			</CustomModal.Body>
			<CustomModal.Footer>
				<>
					<Button
						label="Cancel"
						onClick={handleCloseModal}
						style="secondary"
					/>
					<Button
						disabled={uploadStatus !== UploadStatus.SUCCESS}
						label="Save"
						onClick={handleSave}
						style="secondary"
					/>
				</>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(BulkImport);
