import * as React from 'react';

import type { ModalRouteData } from 'af-utils/modalNavigation.util';
import SearchPage from './SearchPage';
import GuideMainPage from './GuideMainPage';
import UserGuide from './UserGuide';
import type { PageIcons } from './types';
import ModalHome from './ModalHome';

// TODO: Can be removed around the end of 2025, needed because headers were not set appropriately on routes config, so until then we need to bust the cache
// After removing the server configuration of headers should do its thing and revalidate files on each request
// Remove all src and URLs where this was used
export const CACHE_BUST = '?cache=bust';

/**
 * To add a new guide in the code, add title in the files array, the route in the routes and the icon in icons map
 * and the name in the main pages if it should show on the home screen.
 *
 * Add a link to whatever page you need. Instead of spaces use underscore.
 *
 * Make sure that the H1 titles in the page are the same as the file names.
 *
 * If the guide is a main page for part of the platform, eg. Jobs, update the HeaderLoggedIn.tsx to automatically open the right guide instead of the home page.
 */

// Used in home page, pass the spaced string that is displayed at the top of main pages as key, e.g. Tool Repairs, as value pass the link
interface MainPageLinks {
	[page: string]: string;
}

export const mainPages: MainPageLinks = {
	'Jobs': '/jobs',
	'Work Orders': '/work_orders',
	'Orders': '/orders',
	'Purchase Orders': '/purchase_orders',
	'Accounting': '/accounting',
	'Resources': '/resources',
	'Equipment': '/equipment_main',
	'Tool Repairs': '/tool_repairs',
};

export const routes: ModalRouteData[] = [
	// User guide generic routes
	{
		path: '/',
		element: <ModalHome />,
	},
	{
		path: '/search',
		element: <SearchPage />,
	},

	// #region Job routes
	{
		path: '/jobs',
		element: <GuideMainPage icon="icon-jobs" mainPageName="Jobs" mdFile="Jobs" />,
	},
	{
		path: '/managing_a_specific_job',
		element: <UserGuide icon="icon-jobs" mdFile="Managing_A_Specific_Job" />,
	},
	{
		path: '/attachments_on_job_page',
		element: <UserGuide icon="icon-jobs" mdFile="Attachments_on_Job_Page" />,
	},
	{
		path: '/job_hazard_assessment',
		element: <UserGuide icon="icon-jobs" mdFile="Job_Hazard_Assessment" />,
	},
	{
		path: '/related_work_orders',
		element: <UserGuide icon="icon-jobs" mdFile="Related_Work_Orders" />,
	},
	{
		path: '/job_work_summary',
		element: <UserGuide icon="icon-jobs" mdFile="Job_Work_Summary" />,
	},
	{
		path: '/invoice_on_job_page',
		element: <UserGuide icon="icon-jobs" mdFile="Invoice_on_Job_Page" />,
	},
	{
		path: '/billing_codes',
		element: <UserGuide icon="icon-jobs" mdFile="Billing_Codes" />,
	},
	// #endregion

	// #region Accounting routes
	{
		path: '/accounting',
		element: <GuideMainPage icon="icon-accounting" mainPageName="Accounting" mdFile="Accounting" />,
	},
	{
		path: '/exception_report_for_wr',
		element: <UserGuide icon="icon-accounting" mdFile="Exception_Report_for_WR" />,
	},
	{
		path: '/invoice_on_accounting_page',
		element: <UserGuide icon="icon-accounting" mdFile="Invoice_on_Accounting_Page" />,
	},
	{
		path: '/job_payroll_report',
		element: <UserGuide icon="icon-accounting" mdFile="Job_Payroll_Report" />,
	},
	{
		path: '/prevailing_wage_report',
		element: <UserGuide icon="icon-accounting" mdFile="Prevailing_Wage_Report" />,
	},
	// #endregion

	// #region Orders routes
	{
		path: '/orders',
		element: <GuideMainPage icon="icon-cost_code" mainPageName="Orders" mdFile="Orders" />,
	},
	{
		path: '/creating_a_new_order',
		element: <UserGuide icon="icon-cost_code" mdFile="Creating_a_New_Order" />,
	},
	{
		path: '/creating_order_for_stock',
		element: <UserGuide icon="icon-cost_code" mdFile="Creating_Order_For_Stock" />,
	},
	{
		path: '/order_management',
		element: <UserGuide icon="icon-cost_code" mdFile="Order_Management" />,
	},
	{
		path: '/order_notifications',
		element: <UserGuide icon="icon-cost_code" mdFile="Order_Notifications" />,
	},
	// #endregion

	// #region Purchase order routes
	{
		path: '/purchase_orders',
		element: <GuideMainPage icon="icon-cart" mainPageName="Purchase Orders" mdFile="Purchase_Orders" />,
	},
	{
		path: '/creating_new_purchase_order',
		element: <UserGuide icon="icon-cart" mdFile="Creating_New_Purchase_Order" />,
	},
	{
		path: '/purchase_order_management',
		element: <UserGuide icon="icon-cart" mdFile="Purchase_Order_Management" />,
	},
	{
		path: '/purchase_order_notifications',
		element: <UserGuide icon="icon-cart" mdFile="Purchase_Order_Notifications" />,
	},
	// #endregion

	// #region Tool repair routes
	{
		path: '/tool_repairs',
		element: <GuideMainPage icon="icon-wrench-cog" mainPageName="Tool Repairs" mdFile="Tool_Repairs" />,
	},
	{
		path: '/creating_new_repair_request',
		element: <UserGuide icon="icon-wrench-cog" mdFile="Creating_New_Repair_Request" />,
	},
	{
		path: '/managing_tool_repairs',
		element: <UserGuide icon="icon-wrench-cog" mdFile="Managing_Tool_Repairs" />,
	},
	{
		path: '/repair_request_notifications',
		element: <UserGuide icon="icon-wrench-cog" mdFile="Repair_Request_Notifications" />,
	},
	// #endregion

	// #region Resources routes
	{
		path: '/resources',
		element: <GuideMainPage icon="icon-list" mainPageName="Resources" mdFile="Resources" />,
	},
	{
		path: '/accessory_category',
		element: <UserGuide icon="icon-list" mdFile="Accessory_Category" />,
	},
	{
		path: '/location',
		element: <UserGuide icon="icon-list" mdFile="Location" />,
	},
	// #endregion

	// #region Work orders routes
	{
		path: '/work_orders',
		element: <GuideMainPage icon="icon-work_order" mainPageName="Work Orders" mdFile="Work_Orders" />,
	},
	{
		path: '/creating_a_new_work_order',
		element: <UserGuide icon="icon-work_order" mdFile="Creating_a_New_Work_Order" />,
	},
	{
		path: '/managing_work_orders',
		element: <UserGuide icon="icon-work_order" mdFile="Managing_Work_Orders" />,
	},
	{
		path: '/work_order_production_data_and_statistics',
		element: <UserGuide icon="icon-work_order" mdFile="Work_Order_Production_Data_and_Statistics" />,
	},
	// #endregion

	// #region Equipment routes
	{
		path: '/equipment_main',
		element: <GuideMainPage icon="icon-equipment" mainPageName="Equipment" mdFile="Equipment_main" />,
	},
	{
		path: '/accessories',
		element: <UserGuide icon="icon-equipment" mdFile="Accessories" />,
	},
	{
		path: '/equipment',
		element: <UserGuide icon="icon-equipment" mdFile="Equipment" />,
	},
	{
		path: '/small_tools',
		element: <UserGuide icon="icon-equipment" mdFile="Small_Tools" />,
	},
	// #endregion
];

export const mdFiles = [
	// #region Job files
	'Jobs',
	'Managing_A_Specific_Job',
	'Attachments_on_Job_Page',
	'Related_Work_Orders',
	'Invoice_on_Job_Page',
	'Job_Hazard_Assessment',
	'Job_Work_Summary',
	'Billing_Codes',
	// #endregion

	// #region Accounting files
	'Accounting',
	'Exception_Report_for_WR',
	'Invoice_on_Accounting_Page',
	'Job_Payroll_Report',
	'Prevailing_Wage_Report',
	// #endregion

	// #region Orders files
	'Orders',
	'Creating_a_New_Order',
	'Creating_Order_For_Stock',
	'Order_Management',
	'Order_Notifications',
	// #endregion

	// #region Purchase Orders files
	'Purchase_Orders',
	'Creating_New_Purchase_Order',
	'Purchase_Order_Management',
	'Purchase_Order_Notifications',
	// #endregion

	// #region Tool repair files
	'Tool_Repairs',
	'Creating_New_Repair_Request',
	'Managing_Tool_Repairs',
	'Repair_Request_Notifications',
	// #endregion

	// #region Resources files
	'Resources',
	'Equipment',
	'Accessory_Category',
	'Location',
	// #endregion

	// #region Work orders
	'Work_Orders',
	'Creating_a_New_Work_Order',
	'Managing_Work_Orders',
	'Work_Order_Production_Data_and_Statistics',
	// #endregion

	// #region Equipment files
	'Equipment_main',
	'Accessories',
	'Equipment',
	'Small_Tools',
	// #endregion
] as const;

export const icons: PageIcons = {
	// #region Job icons
	'Jobs': 'icon-jobs',
	'Managing_A_Specific_Job': 'icon-jobs',
	'Attachments_on_Job_Page': 'icon-jobs',
	'Related_Work_Orders': 'icon-jobs',
	'Invoice_on_Job_Page': 'icon-jobs',
	'Job_Hazard_Assessment': 'icon-jobs',
	'Job_Work_Summary': 'icon-jobs',
	'Billing_Codes': 'icon-jobs',
	// #endregion

	// #region Accounting icons
	'Accounting': 'icon-accounting',
	'Exception_Report_for_WR': 'icon-accounting',
	'Invoice_on_Accounting_Page': 'icon-accounting',
	'Job_Payroll_Report': 'icon-accounting',
	'Prevailing_Wage_Report': 'icon-accounting',
	// #endregion

	// #region Order icons
	'Orders': 'icon-cost_code',
	'Creating_a_New_Order': 'icon-cost_code',
	'Creating_Order_For_Stock': 'icon-cost_code',
	'Order_Management': 'icon-cost_code',
	'Order_Notifications': 'icon-cost_code',
	// #endregion

	// #region Purchase Order icons
	'Purchase_Orders': 'icon-cart',
	'Creating_New_Purchase_Order': 'icon-cart',
	'Purchase_Order_Management': 'icon-cart',
	'Purchase_Order_Notifications': 'icon-cart',
	// #endregion

	// #region Tool Repair icons
	'Tool_Repairs': 'icon-wrench-cog',
	'Creating_New_Repair_Request': 'icon-wrench-cog',
	'Managing_Tool_Repairs': 'icon-wrench-cog',
	'Repair_Request_Notifications': 'icon-wrench-cog',
	// #endregion

	// #region Resources icons
	'Resources': 'icon-list',
	'Accessory_Category': 'icon-list',
	'Location': 'icon-list',
	// #endregion

	// #region Work orders icons
	'Work_Orders': 'icon-work_order',
	'Creating_a_New_Work_Order': 'icon-work_order',
	'Managing_Work_Orders': 'icon-work_order',
	'Work_Order_Production_Data_and_Statistics': 'icon-work_order',
	// #endregion

	// #region Equipment icons
	'Equipment_main': 'icon-equipment',
	'Accessories': 'icon-equipment',
	'Equipment': 'icon-equipment',
	'Small_Tools': 'icon-equipment',
	// #endregion

};
