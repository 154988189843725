import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext, Row } from '@tanstack/react-table';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import { RepairStatusLabel } from '@acceligentllc/shared/enums/repairStatus';

import Breadcrumbs from 'af-components/Breadcrumbs';
import type { TableRef } from 'af-components/Table';
import Table from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import DateCell from 'af-components/Table/Cells/DateCell';
import UpdatedByCell from 'af-components/Table/Cells/UpdatedByCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

import * as toolRepairActions from 'af-actions/toolRepair';

import type TableVM from 'ab-viewModels/toolRepair/table.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import * as SettingsKeys from 'af-constants/settingsKeys';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

type Props = ConnectedProps<typeof connector>;

const PAGE_NUMBER_KEY = SettingsKeys.TOOL_REPAIR_PAGE_NUMBER();

const RepairTable: React.FC<Props> = (props) => {
	const { findForTable, companyName } = props;

	const tableRef = React.useRef<TableRef<TableVM>>(null);
	const navigate = useNavigate();
	const location = useLocation();

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<TableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await findForTable(
			new TableQuery({ page, pageSize, sortBy, filterByText })
		);
		return result;
	}, [findForTable]);

	const goToCreate = React.useCallback(async () => {
		navigate(CLIENT.COMPANY.TOOL_REPAIR.CREATE(location.state.orgAlias, companyName));
	}, [companyName, location.state.orgAlias, navigate]);

	const goToEdit = React.useCallback((row: Row<TableVM>) => {
		navigate(CLIENT.COMPANY.TOOL_REPAIR.EDIT(`${row.original.id}`, location.state.orgAlias, companyName));
	}, [companyName, location.state.orgAlias, navigate]);

	const goToPreview = React.useCallback((row: Row<TableVM>) => {
		navigate(CLIENT.COMPANY.TOOL_REPAIR.PREVIEW(`${row.original.id}`, location.state.orgAlias, companyName));
	}, [companyName, location.state.orgAlias, navigate]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<TableVM, unknown>) => {
		if (_cell.row.original.toolDeleted) {
			return null;
		}

		const options = [
			{ onClick: () => goToEdit(_cell.row), label: 'Edit' },
		];

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [goToEdit]);

	const columns: TableProps<TableVM>['columns'] = React.useMemo(() => [
		{
			id: 'status',
			accessor: 'status',
			header: 'Status',
			cell: (cell: CellContext<TableVM, TableVM['status']>) => <span>{RepairStatusLabel[cell.getValue()]}</span>,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'toolType',
			accessor: 'toolType',
			header: 'Tool Type',
			cell: (cell: CellContext<TableVM, TableVM['toolType']>) => cell.getValue(),
			enableHiding: true,
		},
		{
			id: 'tool',
			accessor: 'tool',
			header: 'Serial Number',
			cell: (cell: CellContext<TableVM, TableVM['tool']>) => cell.getValue(),
			enableHiding: true,
		},
		{
			id: 'location',
			accessor: 'location',
			header: 'Location',
			cell: (cell: CellContext<TableVM, TableVM['location']>) => cell.getValue(),
			enableHiding: true,
		},
		{
			id: 'reportedIssue',
			accessor: 'reportedIssue',
			header: 'Reported Issue',
			cell: (cell: CellContext<TableVM, TableVM['reportedIssue']>) => cell.getValue(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'internalComment',
			accessor: 'internalComment',
			header: 'Internal Comment',
			cell: (cell: CellContext<TableVM, TableVM['internalComment']>) => cell.getValue(),
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'price',
			accessor: 'price',
			header: 'Cost',
			cell: (cell: CellContext<TableVM, TableVM['price']>) =>
				<DollarCell isUnitPrice={true} maxFractionDigits={2} minFractionDigits={2} value={+cell.getValue()} />,
			enableHiding: true,
		},
		{
			id: 'createdAt',
			accessor: 'createdAt',
			header: 'Date Submitted',
			cell: (cell: CellContext<TableVM, TableVM['createdAt']>) => <DateCell date={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'completedAt',
			accessor: 'completedAt',
			header: 'Date Completed',
			cell: (cell: CellContext<TableVM, TableVM['completedAt']>) => {
				const value = cell.getValue();
				return value ? <DateCell date={value} /> : 'N/A';
			},
			enableSorting: true,
			enableHiding: true,
			sortDescFirst: true,
		},
		{
			id: 'updatedAt',
			accessor: 'createdAt',
			header: 'Updated',
			cell: (cell: CellContext<TableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.updatedAt} updatedBy={cell.row.original.updatedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [resolveActionsButton]);

	const buttons = React.useMemo(() => [
		{ label: 'New Repair Request', type: TableButtonType.PRIMARY, hasPermission: true, onClick: goToCreate },
	], [goToCreate]);

	return (
		<>
			<Breadcrumbs items={[{ label: 'Tool Repairs' }]} />
			<Table
				buttons={buttons}
				columns={columns}
				defaultPageSize={100}
				fetch={fetchRows}
				hasSearchInput={true}
				onRowClick={goToPreview}
				pageNumberKey={PAGE_NUMBER_KEY}
				ref={tableRef}
				searchTextKey={SettingsKeys.TOOL_REPAIR_SEARCH_TEXT()}
				tableName={TableNameEnum.TOOL_REPAIR}
			/>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForTable: toolRepairActions.findAllForTable,
	};
}

export default connector(RepairTable);
