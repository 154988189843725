import * as React from 'react';
import type { WrappedFieldArrayProps, FieldArrayFieldsProps } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import type { HolidayOption } from 'ab-viewModels/holidaySettings.viewModel';

import Select from 'af-components/Controls/Select';

export interface OwnProps {
	options: HolidayOption[];
	onHolidayRemove: (holiday: HolidayOption) => void;
	onValueChange: (holiday: HolidayOption) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<HolidayOption>;

export default class Holidays extends React.PureComponent<Props> {

	onChange = (holiday: HolidayOption) => {
		const { fields, onValueChange } = this.props;
		if (holiday) {
			fields.push(holiday);
			onValueChange(holiday);
		}
	};

	removeHoliday = (fields: FieldArrayFieldsProps<HolidayOption>, index: number) => {
		const { onHolidayRemove } = this.props;
		fields.remove(index);
		onHolidayRemove(fields.get(index));
	};

	getOptionName = (option: HolidayOption) => option.name;

	render() {
		const { fields, options } = this.props;

		return (
			<div>
				<Select
					clearOnSelect={true}
					fixed={true}
					getOptionLabel={this.getOptionName}
					getOptionValue={this.getOptionName}
					isClearable={false}
					onValueChange={this.onChange}
					options={options}
					placeholder="Select holiday"
				/>
				<div className="update-workdays__item-list">
					{
						fields.map((_h, _index) => (
							<div className="update-workdays__item" key={_index}>
								<span>{fields.get(_index).name}</span>
								<Button
									icon="delete"
									onClick={this.removeHoliday.bind(this, fields, _index)}
									style="link-danger"
									tooltip="Delete"
								/>
							</div>
						))
					}
				</div>
			</div>
		);
	}
}
