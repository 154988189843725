import * as React from 'react';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import { bemElement } from 'ab-utils/bem.util';

import Label from 'af-components/LockedValue/Label';
import ImageTag from 'af-components/Image';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CameraLogo = require('@acceligentllc/assets/assets/ic_camera.svg');

interface OwnProps {
	value: Nullable<string>;
	fieldName: string;
	highlightedRed: boolean;
	label: string | JSX.Element;
	tooltipMessage?: Nullable<string>;
}

type Props = OwnProps;

const ImageField: React.FC<Props> = (props: Props) => {
	const { value, fieldName, highlightedRed, label, tooltipMessage } = props;

	return (
		<div className="report-block__image-field">
			<div className="report-block__image-field__title">
				<Label
					label={label}
					tooltipMessage={tooltipMessage}
					tooltipPlacement="top"
					withMargin={true}
				/>
			</div>
			{
				value
					? (
						<ImageTag
							className="image-field__image"
							fallbackSrc={CameraLogo}
							minSize={BlobStorageImageSizeContainer.SIZE_50X50}
							src={value}
							tryOriginal={true}
						/>
					)
					: (
						<div className={bemElement('report-block__image-field', 'upload', { 'highlighted-red': highlightedRed })}>
							<span className="icon-camera" />
							{fieldName}
						</div>
					)
			}
		</div>
	);
};

export default React.memo(ImageField);
