import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import styles from './styles.module.scss';

import type { PercentagesForChangeToDueDate } from './types';

interface Props {
	/** YYYY-MM-DD */
	date: string;
	sectionPercentagesForDueDate: PercentagesForChangeToDueDate;
	isTimelineOnDueDate: boolean;
	showDate: boolean;
	isDateToShowOnDueDate: boolean;
}

const AdjustableBackground: React.FC<Props> = (props: Props) => {

	const {
		date,
		sectionPercentagesForDueDate,
		isTimelineOnDueDate,
		showDate,
		isDateToShowOnDueDate,
	} = props;

	const [isOverflowing, setIsOverflowing] = React.useState(false);

	const dateRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (dateRef.current) {
			setIsOverflowing(dateRef.current.scrollWidth > dateRef.current.clientWidth);
		}
	}, [date]);

	const dateToShow = formatDateToShowOnDateChange(date);
	const backgroundStyle = getBackgroundStyle();
	const backgroundStyleVariables = getSectionBackgroundStyleOnDateChangeVariables();
	const textStyle = getTextStyle();

	return (
		<>
			<div
				className={backgroundStyle}
				style={backgroundStyleVariables}
			>
				<div className={textStyle} ref={dateRef}>
					{dateToShow}
				</div>
			</div>
		</>
	);

	function calculatePercentageOnDate() {
		return 100
			- (sectionPercentagesForDueDate?.percentageOfSectionBeforeDueDate ?? 0)
			- (sectionPercentagesForDueDate?.percentageOfSectionAfterDueDate ?? 0);
	}

	function calculateLeftOffset() {
		return sectionPercentagesForDueDate?.percentageOfSectionBeforeDueDate ?? 0;
	}

	function getSectionBackgroundStyleOnDateChangeVariables() {
		const adjustedLeftOffset = calculateLeftOffset();
		return {
			'--percentageOfSectionOnDueDate': `${calculatePercentageOnDate()}%`,
			'--left-offset': `${adjustedLeftOffset}%`,
		} as React.CSSProperties;
	}

	function formatDateToShowOnDateChange(_date: string) {
		if (!showDate) {
			return '';
		}
		return TimeUtils.formatDate(_date, TimeFormat.SB_DATE_SHORT, TimeFormat.DB_DATE_ONLY);
	}

	function getBackgroundStyle() {

		const backgroundStyles = ['timeline__bar-container__background-on-due-date'];

		if (isTimelineOnDueDate) {
			backgroundStyles.push('timeline__bar-container__background-on-due-date--paint');
		}

		return backgroundStyles.map((style) => styles[style]).join(' ');
	}

	function getTextStyle() {
		const textStyles = ['timeline__bar-container__background-on-due-date__text'];

		if (!isDateToShowOnDueDate) {
			textStyles.push('timeline__bar-container__background-on-due-date__text--orange');
		}

		if (isOverflowing || !showDate) {
			textStyles.push('timeline__bar-container__background-on-due-date__text--hidden');
		}

		return textStyles.map((style) => styles[style]).join(' ');
	}
};

export default AdjustableBackground;
