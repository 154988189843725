import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import type { RootState } from 'af-reducers';

import Tooltip from 'af-components/Tooltip';

import type { WorkOrderResourceLookupModel } from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import { bemElement } from 'ab-utils/bem.util';
import * as ColorUtils from 'ab-utils/color.util';

interface OwnProps {
	/** 'MM-DD-YYYY' */
	dueDate: string;
	removed: boolean;
	resourceLookup: WorkOrderResourceLookupModel;
	workOrderStatus: WorkOrderStatus;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const TemporaryEmployeeItem: React.FC<Props> = (props: Props) => {
	const { removed, employeeDetails } = props;

	if (!employeeDetails) {
		return null;
	}

	const getEmployeeLabel = () => {
		const { firstName, lastName, uniqueId, agencyColor, duplicateDisplayNameExists } = employeeDetails;
		const color = ColorUtils.getColorTextClass(agencyColor, true);

		return (
			<div className="work-order-info-modal__resource-item__temporary-employee">
				<span className={`icon-temp_labor work-order-info-modal__resource-item__icon ${color}`} />
				<span className={`work-order-info-modal__resource-item--details ${color}`}>
					{`${firstName[0]} ${lastName}`}
				</span>
				{duplicateDisplayNameExists &&
					<span className="work-order-info-modal__resource-item--details">
						{uniqueId}
					</span>
				}
			</div>
		);
	};

	return removed ?
		(
			<Tooltip message="Removed" placement="right">
				<div className={bemElement('work-order-info-modal', 'resource-item', ['employee', 'removed'])}>
					<div>{getEmployeeLabel()}</div>
				</div>
			</Tooltip>
		) : (
			<div className={bemElement('work-order-info-modal', 'resource-item', ['employee'])}>
				{getEmployeeLabel()}
			</div>
		);
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const { resourceLookup: { id: resourceLookupId }, dueDate, workOrderStatus } = ownProps;
	const { workOrdersByDateDictionary, temporaryEmployees } = state.scheduleBoard;

	let temporaryEmployeeId = workOrdersByDateDictionary?.[dueDate]?.workOrderResourceLookups?.[resourceLookupId]?.temporaryEmployeeId;
	if (workOrderStatus === WorkOrderStatus.CANCELED) {
		temporaryEmployeeId = workOrdersByDateDictionary?.[dueDate]?.canceledWorkOrderResourceLookups?.[resourceLookupId]?.temporaryEmployeeId;
	}

	return {
		employeeDetails: temporaryEmployeeId ? temporaryEmployees?.[temporaryEmployeeId] : undefined,
	};
};

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(TemporaryEmployeeItem);
