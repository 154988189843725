import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import SubscriptionStatus from '@acceligentllc/shared/enums/subscriptionStatus';

import type EmployeeBase from 'ab-domain/models/employee/base';
import type EmployeeSkillBase from 'ab-domain/models/employeeSkill/base';

import * as UserUtils from 'ab-utils/user.util';

const CSV_REQUIRED_KEYS = [
	'ID',
	'fullName',
	'userType',
	'laborType',
	'wageClassification',
	'homeOffice',
	'SMSSubscribedStatus',
	'phoneNumber',
	'email',
];

const COMPANY_CSV_REQUIRED_KEYS = [
	...CSV_REQUIRED_KEYS,
	'showOnScheduleBoard',
	'skill.0.name',
	'skill.0.color',
];

const EMPTY_SKILL = {
	id: null,
	name: '',
	color: null,
};

export class W_Employee_FindDeactivatedList_VM {
	list: W_Employee_FindDeactivatedList_VM_Row[];

	constructor(employees: EmployeeBase[]) {
		this.list = employees.map((_employee: EmployeeBase) => new W_Employee_FindDeactivatedList_VM_Row(_employee));
	}

	static toCSVData(viewModel: W_Employee_FindDeactivatedList_VM): string[][] {
		let maxNumberOfSkills = 1;	// if skills are able to be shown on export, the first group of columns should always be visible
		const header: string[] = [...COMPANY_CSV_REQUIRED_KEYS];

		viewModel.list.forEach((_item: W_Employee_FindDeactivatedList_VM_Row) => {
			if (_item.skills.length > maxNumberOfSkills) {
				for (let _index = maxNumberOfSkills; _index < _item.skills.length; _index++) {
					header.push(`skill.${_index}.name`, `skill.${_index}.color`);
				}
				maxNumberOfSkills = _item.skills.length;
			}
		});

		const rows: string[][] = viewModel.list.map((_item: W_Employee_FindDeactivatedList_VM_Row) => {
			const _row: string[] = [
				_item.uniqueId,
				_item.fullName,
				_item.accountTemplate,
				_item.wageRateType,
				_item.wageRateClassification,
				_item.locationNickname,
				_item.subscriptionStatus,
				_item.phoneNumber,
				_item.email,
			];
			_row.push(_item.showOnScheduleBoard ? 'Yes' : 'No');
			for (let _index = 0; _index < maxNumberOfSkills; _index++) {
				const _skill = _item.skills[_index] || EMPTY_SKILL;
				_row.push(_skill.name, _skill.color);
			}
			return _row;
		});

		return [header, ...rows];
	}
}

class W_Employee_FindDeactivatedList_VM_Row {
	employeeId: number;
	accountId: number;
	userId: number;
	wageRateId: number;
	locationId: Nullable<number>;

	uniqueId: string;
	fullName: string;
	accountTemplate: AccountPermissionTemplate;
	wageRateType: string;
	wageRateClassification: string;
	locationNickname: string;
	showOnScheduleBoard: boolean;
	subscriptionStatus: SubscriptionStatus;
	phoneNumber: string;
	email: string;

	skills: W_Employee_FindDeactivatedList_VM_Skill[];
	companyName: string;

	constructor(employee: EmployeeBase) {
		const accountProxy = employee.account;
		const userProxy = accountProxy.user;

		this.employeeId = employee.id;
		this.accountId = employee.accountId;
		this.userId = accountProxy.userId;
		this.wageRateId = employee.wageRateId;
		this.locationId = accountProxy.locationId;

		this.uniqueId = userProxy.uniqueId ?? '';
		this.fullName = UserUtils.getFormattedFullName(userProxy.firstName, userProxy.lastName);
		this.accountTemplate = accountProxy.accountTemplate;
		this.wageRateType = employee.wageRate.type ?? '';
		this.wageRateClassification = employee.wageRate.wageClassification ?? '';
		this.locationNickname = accountProxy.location?.nickname ?? '';
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.subscriptionStatus = userProxy.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED;
		this.phoneNumber = userProxy.phoneNumber ?? '';
		this.email = userProxy.email ?? '';

		this.skills = W_Employee_FindDeactivatedList_VM_Skill.bulkConstructor(employee.employeeSkills ?? []);
		this.companyName = employee.company.name ?? '';
	}
}

class W_Employee_FindDeactivatedList_VM_Skill {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(es: EmployeeSkillBase) {
		this.id = es.skill.id;
		this.name = es.skill.name;
		this.color = es.skill.color;
	}

	private static _constructorMap(skill: EmployeeSkillBase) {
		return new W_Employee_FindDeactivatedList_VM_Skill(skill);
	}

	static bulkConstructor(skills: EmployeeSkillBase[]) {
		return skills.map(W_Employee_FindDeactivatedList_VM_Skill._constructorMap);
	}
}
