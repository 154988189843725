import * as React from 'react';

import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import DateFilter from 'af-components/DateFilter';
import FilterInput from 'af-components/Table6/ActionHeader/FilterInput';
import TableTypeEnum from 'af-components/Table6/tableType.enum';

interface Props {
	period: TimePeriodRecurrence;
	startDate: Date;
	endDate: Date;
	filterText: string;
	setDateFilter: (period: TimePeriodRecurrence, startDate: Date, endDate: Date) => void;
	onFilterTextChange: (value: string) => void;
}

const Filter: React.FC<Props> = (props) => {
	const { filterText, period, startDate, endDate, setDateFilter, onFilterTextChange } = props;
	const filterByDate = React.useCallback((newStartDate: Date, newEndDate: Date) => {
		setDateFilter(period, newStartDate, newEndDate);
	}, [setDateFilter, period]);

	const changePeriod = React.useCallback((newPeriod: TimePeriodRecurrence, selected: Date) => {
		let newStartDate: Date;
		let newEndDate: Date;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('failed to parse selectedMoment');
		}

		switch (newPeriod) {
			case TimePeriodRecurrence.MONTHLY:
				newStartDate = selectedMoment.clone().startOf('month').toDate();
				newEndDate = selectedMoment.clone().endOf('month').toDate();
				break;
			case TimePeriodRecurrence.WEEKLY:
				newStartDate = selectedMoment.clone().startOf('week').toDate();
				newEndDate = selectedMoment.clone().endOf('week').toDate();
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				newStartDate = selected;
				newEndDate = selected;
				break;
		}
		setDateFilter(newPeriod, newStartDate, newEndDate);
	}, [setDateFilter]);

	return (
		<div className="condensed-table__filter">
			<FilterInput
				filterText={filterText}
				onFilterTextChange={onFilterTextChange}
				searchLabel=""
				tableType={TableTypeEnum.DEFAULT}
			/>
			<DateFilter
				changePeriod={changePeriod}
				endDate={endDate}
				onChange={filterByDate}
				period={period}
				startDate={startDate}
			/>
		</div>
	);
};

export default Filter;
