import { DEFAULT_INTERNAL_CREW_TYPE_NAME, UNDEFINED_CREW_TYPE_NAME } from 'ab-common/constants/crewType';
import type { ColorPalette, ExtendedColorValue } from '@acceligentllc/shared/enums/color';
import { DefaultColor } from '@acceligentllc/shared/enums/color';

export default class BasicCrewType {
	name: string;
	/**
	 * Has only `ColorPalette` values in a regular scenario (i.e. when saved in the DB),
	 * but can have `AdditionalColors` values when used on a linked model, such as `WorkOrder`
	 */
	color: Nullable<ExtendedColorValue>;

	constructor(name: Nullable<string>, color: Nullable<ColorPalette>) {
		this.name = name ?? BasicCrewType.UNDEFINED.name;
		this.color = color ?? BasicCrewType.UNDEFINED.color;
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	static UNDEFINED: BasicCrewType = {
		name: UNDEFINED_CREW_TYPE_NAME,
		color: null,
	};

	/**
	 * Used for crew types of internal jobs
	 * @param crewTypeName usually `WorkOrder.customCrewType`
	 */
	static internal(crewTypeName: Nullable<string>): BasicCrewType {
		return {
			name: crewTypeName ?? DEFAULT_INTERNAL_CREW_TYPE_NAME,
			color: DefaultColor.INTERNAL_JOB,
		};
	}
}
