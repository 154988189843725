import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import type { RootState } from 'af-reducers';
import { connect } from 'react-redux';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { isAllowed } from 'ab-utils/auth.util';

import * as CompanyActions from 'af-actions/companies';

import Breadcrumbs from 'af-components/Breadcrumbs';
import TabNavigation from 'af-components/TabNavigation';

import CondensedTable from './CondensedTable';
import FullTable from './FullTable';
import ReportTypeViewTable from './ReportTypeViewTable';

type Props = ConnectedProps<typeof connector>;

const BREADCRUMBS = [{ label: 'Field Reports' }];
const FULL_VIEW_TAB = { ID: 1, LABEL: 'FULL VIEW' };
const CONDENSED_VIEW_TAB = { ID: 2, LABEL: 'CONDENSED VIEW' };
const REPORT_TYPE_VIEW_TAB = { ID: 3, LABEL: 'REPORT TYPE VIEW' };

const resolveTabs = (showCondensedTable: boolean, showFieldReportTypeTableTab: boolean) => {
	const tabs = [
		{ id: FULL_VIEW_TAB.ID, label: FULL_VIEW_TAB.LABEL },
	];

	if (showCondensedTable) {
		tabs.push({ id: CONDENSED_VIEW_TAB.ID, label: CONDENSED_VIEW_TAB.LABEL });
	}

	if (showFieldReportTypeTableTab && !DisabledFeatures.FieldReportTypeTable) {
		tabs.push({ id: REPORT_TYPE_VIEW_TAB.ID, label: REPORT_TYPE_VIEW_TAB.LABEL });
	}

	return tabs;
};

const WorkOrdersTable: React.FC<Props> = (props) => {

	const { getCompany, showCondensedTab, showFieldReportTypeTableTab } = props;

	const [activeTab, setActiveTab] = React.useState(FULL_VIEW_TAB.ID);

	const tabs = React.useMemo(() => resolveTabs(showCondensedTab, showFieldReportTypeTableTab), [showCondensedTab, showFieldReportTypeTableTab]);
	React.useEffect(() => {
		getCompany();
	}, [getCompany]);

	const renderTab = () => {
		switch (activeTab) {
			case CONDENSED_VIEW_TAB.ID:
				return <CondensedTable />;
			case REPORT_TYPE_VIEW_TAB.ID:
				return <ReportTypeViewTable />;
			case FULL_VIEW_TAB.ID:
			default:
				return <FullTable />;
		}
	};

	return <div className="form-segment form-segment--maxi field-report-orders-table">
		<Breadcrumbs items={BREADCRUMBS} />
		{tabs.length > 1 && (
			<TabNavigation
				active={activeTab}
				onClick={setActiveTab}
				tabs={tabs}
			/>
		)}
		{renderTab()}
	</div>;
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const showCondensedTab = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE, permissions, isCompanyAdmin, role);
	const showFieldReportTypeTableTab = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FR_REPORT_TYPE_VIEW, permissions, isCompanyAdmin, role);

	return {
		showCondensedTab,
		showFieldReportTypeTableTab,
	};
}

function mapDispatchToProps() {
	return {
		getCompany: CompanyActions.getCompany,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(WorkOrdersTable);

