import type AddressBase from 'ab-domain/models/address/base';
import type ContactAddressBase from 'ab-domain/models/contactAddress/base';
import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type JobStatusBase from 'ab-domain/models/jobStatus/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type LocationBase from 'ab-domain/models/location/base';
import type AccountBase from 'ab-domain/models/account/base';
import type DeliverableDataBase from 'ab-domain/models/deliverableData/base';
import type DivisionBase from 'ab-domain/models/division/base';
import type SaleBase from 'ab-domain/models/sale/base';
import type EmployeeBase from 'ab-domain/models/employee/base';

import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type WorkRequestDisposalMethod from '@acceligentllc/shared/enums/workRequestDisposalMethod';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import { EmailTypes, EmailTypesArray, PhoneTypes, PhoneTypesArray } from '@acceligentllc/shared/enums/contactMethodType';
import type Priority from '@acceligentllc/shared/enums/priority';
import type DeliverableDataTypeEnum from '@acceligentllc/shared/enums/deliverableDataType';

import * as UserUtils from '@acceligentllc/shared/utils/user';

import * as ContactUtils from 'ab-utils/contact.util';

export const getAttributeName = (attribute: keyof WorkRequestDetailsUpsertVM) => attribute;

export class WorkRequestStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatusBase) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}
}

export class EmployeeOptionVM {
	id: number;
	formattedCode: string;
	firstName: string;
	lastName: string;
	uniqueId: string;
	accountTemplate: AccountPermissionTemplate;
	fullName: string;

	constructor(employee: EmployeeBase) {
		const user = employee?.account?.user;

		if (!user) {
			throw new Error('Missing user property');
		}

		this.id = employee.id;
		this.formattedCode = user.uniqueId;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.uniqueId = user.uniqueId;
		this.accountTemplate = employee?.account?.accountTemplate;
		this.fullName = UserUtils.getUserName(employee.account.user);
	}

	static bulkConstructor(employees: EmployeeBase[]): EmployeeOptionVM[] {
		return employees.map((_employee) => new EmployeeOptionVM(_employee));
	}
}

export class AddressVM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;

	constructor(address: AddressBase) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.city = address.locality;
		this.zip = address.postalCode;
		this.state = address.aa1;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
		this.locality = address.locality;
	}
}

class ContactAddressVM {
	id: number;
	contactId: number;
	addressId: number;
	address: AddressVM;
	shortAddress: Nullable<string>;

	constructor(contactAddress: ContactAddressBase) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new AddressVM(contactAddress.address);
	}

	static toList(contactAddresses: ContactAddressBase[]): ContactAddressVM[] {
		return contactAddresses.map((_address) => new ContactAddressVM(_address));
	}
}

class ContactMethodVM {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static toList(contactMethods: ContactMethodBase[], type: typeof EmailTypes | typeof PhoneTypes): ContactMethodVM[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new ContactMethodVM(_method));
	}
}

export class DeliverableDataVM {
	id: number;
	name: string;
	type: DeliverableDataTypeEnum;

	constructor(status: DeliverableDataBase) {
		this.id = status.id;
		this.name = status.name;
		this.type = status.type;
	}
}

export class ContactVM {
	id: number;
	workOrderContactId: number;
	role: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;

	addresses: ContactAddressVM[];
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];

	contactAddressIds: number[];
	contactEmailIds: number[];
	contactPhoneIds: number[];

	constructor(wrc: ContactLookupBase) {
		if (!wrc) {
			return;
		}

		const _contact = wrc?.contact;

		this.id = wrc.contactId;
		this.workOrderContactId = wrc.id;
		this.role = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.addresses = ContactAddressVM.toList(_contact?.addresses ?? []);
		this.emails = ContactMethodVM.toList(_contact?.contactMethods ?? [], EmailTypes);
		this.phones = ContactMethodVM.toList(_contact?.contactMethods ?? [], PhoneTypes);

		this.contactAddressIds = wrc.contactLookupAddresses?.map((_wrcAdr) => _wrcAdr.contactAddressId) ?? [];
		this.contactEmailIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, EmailTypesArray);
		this.contactPhoneIds = ContactUtils.filterContactMethod(wrc.contactLookupMethods, PhoneTypesArray);
	}

	static bulkConstructor = (wrcs: ContactLookupBase[]) => wrcs.map((_wrc) => new ContactVM(_wrc));
}

export class OfficeVM {
	id: number;
	nickname: string;

	constructor(office: LocationBase) {
		this.id = office.id;
		this.nickname = office.nickname;
	}
}

export class DivisionVM {
	id: number;
	name: string;

	constructor(division: DivisionBase) {
		this.id = division.id;
		this.name = division.name;
	}
}

export class AccountVM {
	id: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
	fullName: string;

	constructor(account: AccountBase) {
		this.id = account.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.uniqueId = account.user.uniqueId;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

export class SalesVM {
	id: number;
	fullName: string;

	constructor(sale: SaleBase) {
		this.id = sale.id;
		this.fullName = sale.fullName;
	}
}

export class EmployeeVM {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	formattedCode: string;

	constructor(employee: EmployeeBase) {
		this.id = employee.id;
		this.firstName = employee.account?.user?.firstName;
		this.lastName = employee.account?.user?.lastName;
		this.fullName = UserUtils.getUserName(employee.account.user);
		this.formattedCode = employee.account?.user?.uniqueId;
	}
}

interface WorkRequestDetailsUpsertVM {
	// Sales
	salesId: Nullable<string>;
	salesLead: Nullable<SalesVM>;
	salesLeadId: Nullable<number>;
	estimateTotal: number;

	// Job Summary
	/** on form shown as Work Request Id */
	jobCode: string;
	title: Nullable<string>;
	isInternal: boolean;
	customerCompanyName: Nullable<string>;
	customerReferencePO: Nullable<string>;
	isEmergencyJob: boolean;
	priority: Priority;
	jobStatusId: Nullable<number>;
	jobStatus: Nullable<WorkRequestStatusVM>;
	projectManagerId: Nullable<number>;
	projectManager: Nullable<EmployeeVM>;
	officeId: Nullable<number>;
	office: Nullable<OfficeVM>;
	divisionId: Nullable<number>;
	division: Nullable<DivisionVM>;

	// Job Detail
	projectOwner: Nullable<string>;
	ownerReference: Nullable<string>;
	taxExempt: boolean;
	generalContractor: Nullable<string>;
	contractorReference: Nullable<string>;
	isUnion: boolean;
	isPrevailingWage: boolean;
	jurisdiction: Nullable<string>;

	// Requirements
	requestMBEOrWBERequirementsNote: Nullable<string>;
	requestBondRequirementsNote: Nullable<string>;
	requestHSERequirementsNote: Nullable<string>;
	retainage: Nullable<string>;
	requestInsuranceRequirementsNote: Nullable<string>;
	requestLiquidatedDamagesNote: Nullable<string>;

	// Schedule Info
	targetStartDate: Nullable<string>;
	targetCompletionDate: Nullable<string>;
	targetDaysFromStart: Nullable<number>;
	startDate: Nullable<string>;
	guaranteedCompletionDate: Nullable<string>;
	guaranteedDaysFromStart: Nullable<number>;

	// Contacts
	customerContact: Nullable<ContactVM>;
	siteContact: Nullable<ContactVM>;
	billingContact: Nullable<ContactVM>;
	additionalContacts: Nullable<ContactVM[]>;

	// Deliverables
	isDeliverable: boolean;
	deliverableAssigneeId: Nullable<number>;
	deliverableAssignee: Nullable<AccountVM>;
	deliverableSoftwareId: Nullable<number>;
	deliverableSoftware: Nullable<DeliverableDataVM>;
	deliverableFileFormatId: Nullable<number>;
	deliverableFileFormat: Nullable<DeliverableDataVM>;
	deliverableCodeStandardId: Nullable<number>;
	deliverableCodeStandard: Nullable<DeliverableDataVM>;
	deliverableDeliveryMethodId: Nullable<number>;
	deliverableDeliveryMethod: Nullable<DeliverableDataVM>;
	deliverableDeliveryTimelineId: Nullable<number>;
	deliverableDeliveryTimeline: Nullable<DeliverableDataVM>;
	deliverableNotes: Nullable<string>;

	// Work Location
	workLocation: Nullable<AddressVM>;

	// Summary of Work
	requestJobNote: Nullable<string>;

	// Survey data
	surveyNeeded: boolean;
	surveyedById: Nullable<number>;
	surveyedBy: Nullable<EmployeeOptionVM>;
	surveyDate: Nullable<string>;
	waterAvailable: boolean;
	waterSupplyInformation: Nullable<string>;
	subcontractorNeeded: Nullable<string>;
	subcontractorInformation: Nullable<string>;
	pipeSizes: Nullable<string>;
	pipeTypes: Nullable<string>;
	manholeDepths: Nullable<string>;
	manholeFrameDiameters: Nullable<string>;
	informationsProvidedByCustomersBy: Nullable<string>;

	// Disposal
	ableToCleanAndWashEquipmentAtCustomerLocation: boolean;
	cleanAndWashLocation: Nullable<string>;
	isWasteGeneratedSolid: boolean;
	isWasteGeneratedSludge: boolean;
	isWasteGeneratedLiquid: boolean;
	isWasteHazardous: boolean;
	isWasteManifest: boolean;
	wasteDescription: Nullable<string>;
	expectedWasteQuantity: Nullable<number>;
	disposalMethod: Nullable<WorkRequestDisposalMethod>;
	disposalInstructions: Nullable<string>;
	disposalContact: Nullable<ContactVM>;
}

export default WorkRequestDetailsUpsertVM;
