import * as Err from 'restify-errors';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import InvoiceBillingLookupType from '@acceligentllc/shared/enums/invoiceBillingLookupType';
import InvoiceStatus from '@acceligentllc/shared/enums/invoiceStatus';
import type FileType from '@acceligentllc/shared/enums/fileType';
import type ResourceStatus from '@acceligentllc/shared/enums/resourceStatus';
import { type PhoneTypes, EmailTypes, EmailTypesArray } from '@acceligentllc/shared/enums/contactMethodType';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as UserUtils from '@acceligentllc/shared/utils/user';
import * as BlobStorageUtil from '@acceligentllc/shared/utils/blobStorage';
import { filterContactMethod } from '@acceligentllc/shared/utils/contact';

import type ContactLookupBase from 'ab-domain/models/contactLookup/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';
import type InvoiceBase from 'ab-domain/models/invoice/base';
import type InstallmentBase from 'ab-domain/models/installment/base';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import type AttachmentBase from 'ab-domain/models/attachment/base';
import type AccountBase from 'ab-domain/models/account/base';

import * as BlobStorageUtilLocal from 'ab-utils/blobStorage.util'; // TODO: move everything to shared

export class W_Accounting_FindInvoicesTable_VM extends TableContent<W_Accounting_FindInvoicesTable_VM_Row>{
	public static async initialize(invoices: InvoiceBase[], pages: number, totalCount: number) {
		const invoicesVM = await W_Accounting_FindInvoicesTable_VM_Row.bulkConstructor(invoices);

		return new TableContent(invoicesVM, pages, totalCount);
	}
}

class W_Accounting_FindInvoicesTable_VM_Row {
	id: number;
	workRequestId: number;
	jobCode: string;
	invoiceCode: string;
	status: InvoiceStatusDisplay;
	totalAmount: number;
	paidAmount: number;
	outstandingDebt: number;
	retainedAmount: number;
	percentagePaid: number;
	dateCreated: string;
	invoicingDate: Nullable<string>;
	dueDate: string;
	note: Nullable<string>;
	billingContacts: W_Accounting_FindInvoicesTable_VM_InvoiceBillingContact[];
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	installments: W_Accounting_FindInvoicesTable_VM_Installment[];
	lastInstallmentDate: Nullable<Date>;
	attachments: W_Accounting_FindInvoicesTable_VM_Attachment[];
	uploadedAttachmentIds: number[];

	public static async initialize(invoice: InvoiceBase): Promise<W_Accounting_FindInvoicesTable_VM_Row> {
		const _totalAmount = parseFloat(invoice.totalAmount);
		const _paidAmount = W_Accounting_FindInvoicesTable_VM_Row._resolvePaidAmount(invoice.installments);

		let jobCode = '';
		if (invoice.workRequest?.jobCode) {
			jobCode = invoice.workRequest?.jobCode;
		} else {
			throw new Err.NotFoundError('Job code and jobCustomName not found');
		}

		let attachments: W_Accounting_FindInvoicesTable_VM_Attachment[] = [];
		if (invoice.invoiceAttachmentLookups) {
			attachments =
				await W_Accounting_FindInvoicesTable_VM_Attachment.bulkInitialize(invoice.invoiceAttachmentLookups.map((_lookup) => _lookup.attachment));
		}

		return {
			id: invoice.id,
			jobCode,
			invoiceCode: invoice.invoiceCode,
			workRequestId: invoice.workRequestId,
			status: W_Accounting_FindInvoicesTable_VM_Row._resolveStatusForDisplay(invoice.status, _totalAmount, _paidAmount),
			totalAmount: _totalAmount,
			paidAmount: _paidAmount,
			outstandingDebt: W_Accounting_FindInvoicesTable_VM_Row._resolveOutstandingDebt(_totalAmount, _paidAmount),
			retainedAmount: invoice.retainedAmount ? parseFloat(invoice.retainedAmount) : 0,
			percentagePaid: +(Math.min(_paidAmount / _totalAmount * 100, 100)).toFixed(2),
			dateCreated: invoice.dateCreated,
			invoicingDate: invoice.invoicingDate,
			dueDate: invoice.dueDate,
			note: invoice.note,
			sendReminderOnInvoice: invoice.sendReminderOnInvoice,
			excludeFromAutomaticReminders: invoice.excludeFromAutomaticReminders,
			billingContacts: (invoice.invoiceBillingLookups ?? []).map((_lookup) => {
				switch (_lookup.type) {
					case InvoiceBillingLookupType.EMAIL_ONLY: {
						return {
							id: _lookup.id,
							type: InvoiceBillingLookupType.EMAIL_ONLY,
							email: _lookup.email!,
						};
					}
					case InvoiceBillingLookupType.CONTACT: {
						return {
							id: _lookup.id,
							type: InvoiceBillingLookupType.CONTACT,
							contact: new W_Accounting_FindInvoicesTable_VM_Contact(_lookup.contactLookup!),
						};
					}
				}
			}),
			installments: W_Accounting_FindInvoicesTable_VM_Installment.bulkConstructor(invoice.installments),
			lastInstallmentDate: W_Accounting_FindInvoicesTable_VM_Row._resolveLastInstallmentDate(invoice.installments),
			attachments,
			uploadedAttachmentIds: (invoice.invoiceAttachmentLookups ?? []).map((_lookup) => _lookup.attachmentId),
		};
	}

	public static async bulkConstructor(invoices: InvoiceBase[]) {
		return await Promise.all(invoices.map(W_Accounting_FindInvoicesTable_VM_Row.initialize));
	}

	private static _resolvePaidAmount(installments: InstallmentBase[]) {
		if (!installments) {
			return 0;
		}
		return installments.reduce((_sum, _inst) => {
			return _sum + parseFloat(_inst.amount);
		}, 0);
	}

	private static _resolveOutstandingDebt(totalAmount: number, paidAmount: number) {
		if (paidAmount >= totalAmount) {
			return 0;
		}
		return totalAmount - paidAmount;
	}

	private static _resolveStatusForDisplay(invoiceStatus: InvoiceStatus, totalAmount: number, paidByInstallments: number) {
		if (paidByInstallments === 0) {
			if (invoiceStatus === InvoiceStatus.DRAFT) {
				return InvoiceStatusDisplay.DRAFT;
			}
			return InvoiceStatusDisplay.INVOICED;
		}

		if (paidByInstallments < totalAmount) {
			return InvoiceStatusDisplay.PARTIALLY_PAID;
		} else if (paidByInstallments === totalAmount) {
			return InvoiceStatusDisplay.PAID;
		} else {
			return InvoiceStatusDisplay.OVERPAID;
		}
	}

	private static _resolveLastInstallmentDate(installments: InstallmentBase[]) {
		if (!installments) {
			return null;
		}
		const installmentDates = installments.map((_i) => TimeUtils.toUtcDate(_i.datePaid, TimeFormat.DB_DATE_ONLY));
		return new Date(Math.max.apply(null, installmentDates));
	}
}

class W_Accounting_FindInvoicesTable_VM_Installment {
	id: number;
	invoiceId: number;
	amount: string;
	/** YYYY-MM-DD */
	datePaid: string;
	note: Nullable<string>;
	createdAt: Date;

	constructor(installment: InstallmentBase) {
		this.id = installment.id;
		this.invoiceId = installment.invoiceId;
		this.amount = installment.amount;
		this.datePaid = installment.datePaid;
		this.note = installment.note;
		this.createdAt = installment.createdAt;
	}

	static bulkConstructor = (installments: InstallmentBase[]) => installments.map((_in) => new W_Accounting_FindInvoicesTable_VM_Installment(_in));
}

class W_Accounting_FindInvoicesTable_VM_UserInfo {
	accountId?: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;

	constructor(account: AccountBase) {
		this.accountId = account.id ?? undefined;
		this.userId = account.user.id;
		this.firstName = account.user.firstName;
		this.lastName = account.user.lastName;
		this.fullName = UserUtils.getUserName(account.user);
	}
}

class W_Accounting_FindInvoicesTable_VM_Attachment {
	id: number;
	size: number;
	name: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<W_Accounting_FindInvoicesTable_VM_UserInfo>;

	public static async initialize(attachment: AttachmentBase): Promise<W_Accounting_FindInvoicesTable_VM_Attachment> {
		const directories = BlobStorageUtil.parseDirectoryPath(attachment.storageContainer);

		const originalSrc = await BlobStorageUtilLocal.generatePresignedGetUrl(directories, attachment.storageName);

		const attUser = attachment.uploadedBy?.user;
		const uploadedObj = attachment.uploadedBy ? new W_Accounting_FindInvoicesTable_VM_UserInfo(attachment.uploadedBy) : null;

		return {
			id: attachment.id,
			size: attachment.size,
			name: attachment.name,
			type: attachment.type,
			status: attachment.status,
			storageName: attachment.storageName,
			storageContainer: attachment.storageContainer,
			lastModifiedAt: attachment.createdAt,
			uploadedBy: attUser ? uploadedObj : null,
			originalSrc,
		};

	}

	public static async bulkInitialize(attachments: AttachmentBase[]): Promise<W_Accounting_FindInvoicesTable_VM_Attachment[]> {
		return await Promise.all((attachments ?? []).map(W_Accounting_FindInvoicesTable_VM_Attachment.initialize));
	}
}

class W_Accounting_FindInvoicesTable_VM_ContactMethod {
	id: number;
	contactId: number;
	type: PhoneTypes | EmailTypes;
	value: string;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
	}

	static toList(contactMethods: ContactMethodBase[], type: typeof EmailTypes | typeof PhoneTypes): W_Accounting_FindInvoicesTable_VM_ContactMethod[] {
		return contactMethods
			.filter((_method) => _method && Object.keys(type).includes(_method.type))
			.map((_method) => new W_Accounting_FindInvoicesTable_VM_ContactMethod(_method));
	}
}

class W_Accounting_FindInvoicesTable_VM_Contact {
	id: number;
	contactId: number;
	title: Nullable<string>;
	fullName: string;
	companyName: Nullable<string>;
	emails: W_Accounting_FindInvoicesTable_VM_ContactMethod[];
	contactEmailIds: number[];

	constructor(_contactLookup: ContactLookupBase) {
		const _contact = _contactLookup?.contact;

		this.id = _contactLookup.id;
		this.contactId = _contact.id;
		this.title = _contact?.title;
		this.fullName = _contact?.fullName;
		this.companyName = _contact?.companyName;

		this.emails = W_Accounting_FindInvoicesTable_VM_ContactMethod.toList(_contact?.contactMethods ?? [], EmailTypes);

		this.contactEmailIds = filterContactMethod(_contactLookup.contactLookupMethods, EmailTypesArray);
	}

	static bulkConstructor = (_contactLookups: ContactLookupBase[]) =>
		_contactLookups.map((__contactLookup) => new W_Accounting_FindInvoicesTable_VM_Contact(__contactLookup));
}

type W_Accounting_FindInvoicesTable_VM_BillingEmail = {
	type: InvoiceBillingLookupType.EMAIL_ONLY;
	email: string;
};

type W_Accounting_FindInvoicesTable_VM_BillingContact = {
	contact: W_Accounting_FindInvoicesTable_VM_Contact;
	type: InvoiceBillingLookupType.CONTACT;
};

type W_Accounting_FindInvoicesTable_VM_InvoiceBillingContact = { id: number; }
	& (W_Accounting_FindInvoicesTable_VM_BillingEmail | W_Accounting_FindInvoicesTable_VM_BillingContact);
