import * as React from 'react';

import { icons, mainPages } from '../routes';
import HomeLink from './HomeLink';

const ModalHome: React.FC = () => {
	return (
		<>
			<div className="modal-home">
				{Object.entries(mainPages).map(([page, link]) => {
					return (
						<HomeLink icon={icons[page.replaceAll(' ', '_')]} key={page} link={link} page={page} />
					);
				}
				)}
			</div>
			<div className="modal-construction">
				<img src="/images/userGuide/construction.svg" />
				<div className="construction-title">User Guides are currently under construction</div>
				<div className="construction-text">
					<p>Our User Guides are currently being updated.</p>
					<p>Some pages may be temporarily unavailable. We appreciate your patience and will be adding new content soon.</p>
					<p>If you need immediate assistance, please contact our support team.</p>
				</div>
			</div>
		</>
	);
};

export default React.memo(ModalHome);
