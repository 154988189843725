import * as React from 'react';
import type { FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import type { CellContext } from '@tanstack/react-table';
import { Button } from '@acceligentllc/storybook';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import Input from 'af-fields/Input';
import type { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTableField from 'af-fields/SimpleTable';

import DropdownComponent from 'af-components/Controls/Dropdown';
import type { FooterButton, SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import ImageTag from 'af-components/Image';

import type ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';

import { dollarFormatter } from 'af-utils/format.util';
import { useToggle } from 'af-utils/react.util';

import type FormModel from './formModel';
import styles from './styles.module.scss';

export interface OwnProps {
	availableItems: ItemDepartmentVM[];
	initialized: boolean;
	disabled: boolean;
	errors: FormErrorsWithArray<FormModel, string>;
	setHasUnsavedItems: (value: boolean) => void;
}

type ItemFormModel = FormModel['items'][0];
type Props = OwnProps & WrappedFieldArrayProps<ItemFormModel>;

const renderItemOption = (item: ItemDepartmentVM) => (
	<>
		<ImageTag
			fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
			minSize={BlobStorageImageSizeContainer.SIZE_50X50}
			src={item.imageUrl}
			tryOriginal={true}
			tryRoot={true}
		/>
		<div className={styles['item-option']}>
			<span>{item.itemName}</span>
			<span className={styles['item-option__details']}>
				{item.modelNumber} | {item.locationNickname} | {item.departmentName} | {item.partNumber ? `${item.partNumber} |` : ''} On stock: {item.currentStock}
			</span>
		</div>
	</>

);

const Order: React.FC<Props> = (props) => {
	const { fields, availableItems, initialized, disabled, errors, setHasUnsavedItems } = props;

	const {
		value: showSelectItemDropdown,
		setToFalse: hideItemDropdown,
		setToTrue: showItemDropdown,
	} = useToggle(false);

	const [itemOptions, setItemOptions] = React.useState<ItemDepartmentVM[]>([]);
	const [itemOptionsInitialized, setItemOptionsInitialized] = React.useState(false);

	React.useEffect(() => {
		const currentItems = fields.getAll();
		if (currentItems !== undefined && !itemOptionsInitialized && availableItems.length) {
			setItemOptions(availableItems.filter((i) => !currentItems?.find((c) => c.id === i.id)));
			setItemOptionsInitialized(true);
		}
	}, [availableItems, fields, itemOptionsInitialized]);

	const selectItem = React.useCallback((item: ItemDepartmentVM) => {
		fields.push({
			id: new Date().getTime(), // We need some number, that will be unique, strictly only for form
			itemId: item.itemId,
			itemDepartmentId: item.id,
			itemName: item.itemName,
			quantity: 1,
			price: item.price,
			departmentId: item.departmentId,
			isInEditMode: false,
			vendorNumber: item.partNumber,
			itemType: item.packageType,
			departmentName: item.departmentName,
			locationName: item.locationNickname,
		});
		setItemOptions(itemOptions.filter((i) => i.id !== item.id));
		hideItemDropdown();
	}, [fields, hideItemDropdown, itemOptions]);

	const renderSelectItemDropdown = React.useCallback(() => {
		return (
			<div className={styles['item-table-footer__dropdown']}>
				<div>
					<DropdownComponent<ItemDepartmentVM>
						containerClassName={styles['item-dropdown']}
						filterable={true}
						filterBy={['itemName', 'locationNickname', 'departmentName', 'partNumber', 'modelNumber']}
						fixed={true}
						fullWidth={true}
						id="item-dropdown"
						onValueChange={selectItem}
						options={itemOptions}
						placeholder="Select Item"
						renderMenuItem={renderItemOption}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<Button
					icon="close"
					onClick={hideItemDropdown}
					style="link-danger"
				/>
			</div >
		);
	}, [selectItem, itemOptions, hideItemDropdown]);

	const footerButtons = React.useMemo(() => {
		if (disabled) {
			return undefined;
		}
		const resolvedFooterButtons = [{
			iconName: 'plus',
			label: 'Add Item',
			onClick: showItemDropdown,
		}];

		return resolvedFooterButtons as FooterButton[];
	}, [disabled, showItemDropdown]);

	const handleDelete = React.useCallback((index: number) => {
		const formItem = fields.get(index);
		const item = availableItems.find((i) => i.id === formItem.itemDepartmentId);
		if (item) {
			setItemOptions([...itemOptions, item]);
		}
	}, [availableItems, fields, itemOptions]);

	const columns: SimpleTableProps<ItemFormModel & SimpleTableRow>['columns'] = React.useMemo(() => [{
		id: 'quantity',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						min={1}
						name={`${_cell.row.original.name}.quantity`}
						step={1}
						type="number"
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Quantity',
		accessor: 'quantity',
		size: 80,
		enableSorting: false,
	},
	{
		id: 'vendorNumber',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, string>) => _cell.getValue() ?? 'N/A',
		header: 'Vendor Number',
		size: 200,
		accessor: 'vendorNumber',
		enableSorting: true,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, string>) => _cell.getValue() ?? 'N/A',
		header: 'Item Name',
		size: 300,
		accessor: 'itemName',
		enableSorting: true,
	},
	{
		id: 'itemType',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, string>) => _cell.getValue() ?? 'N/A',
		header: 'Item Type',
		size: 100,
		accessor: 'itemType',
		enableSorting: true,
	},
	{
		id: 'department',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, string>) => {
			return <div>{_cell.row.original.locationName} ({_cell.row.original.departmentName})</div>;
		},
		header: 'Department',
		size: 250,
		accessor: 'department',
		enableSorting: false,
	},
	{
		id: 'price',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Price Each',
		accessor: 'price',
		size: 80,
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			return dollarFormatter.format(_cell.row.original.price * _cell.row.original.quantity);
		},
		header: 'Extended Price',
		size: 80,
		accessor: 'extendedPrice',
	}], []);

	return (
		<SimpleTableField
			allowEdit={!disabled}
			columns={columns}
			errors={errors.items}
			fields={fields}
			footerButtonsLeft={footerButtons}
			footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : undefined}
			initialized={initialized}
			label="Items used for repair"
			notifyAreThereItemsInEditMode={setHasUnsavedItems}
			onDelete={handleDelete}
		/>
	);
};

export default React.memo(Order);
