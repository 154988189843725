import * as React from 'react';
import type { FormErrors, WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import type { TimeSheetAddedEntryWithType, TimeSheetEntryFormModel, TimeSheetEntryWithType, TimeSheetGapEntryWithType, TimeSheetOccupiedEntryWithType } from '../formModel';
import TimeSheetEditFormModel, { TimeSheetAddedEntryFormModel, TimeSheetEntryFormType } from '../formModel';
import TimeSheetEntry from './TimeSheetEntry';
import styles from '../styles.module.scss';
import type { TimeSheetEntryActionModel } from '../TimeSheetEditModalBody';
import { TimeSheetEntryAction } from '../TimeSheetEditModalBody';

export interface OwnProps {
	isAllowedToEdit: boolean;
	areTimeSheetsReadOnly: boolean;
	/** YYYY-MM-DD */
	dueDate: string;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	overlaps: Record<string, OverlapMeta>;
	entriesSyncErrors: FormErrors<TimeSheetEntryWithType | TimeSheetAddedEntryWithType, string>[];
	editingEntryIndex: Nullable<number>;
	editingEntryInitialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>;
	isAnotherTimeSheetInEditMode: boolean;
	isThisTimeSheetInEditMode: boolean;
	/** Keeps track of which time zone we're using in the form */
	timeZoneInUse: Nullable<string>;
	setEditingEntryIndexAndValue: (index: Nullable<number>, initialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	changeEntries: (actionModel: TimeSheetEntryActionModel, shouldNotUpdateEntriesHaveChanged?: boolean) => void;
	calculateOverlapsForValidation: () => void;
}

type Props = OwnProps & WrappedFieldArrayProps<
	TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType | TimeSheetAddedEntryWithType
>;

const TimeSheetEntriesList: React.FC<Props> = (props) => {

	const {
		isAllowedToEdit,
		areTimeSheetsReadOnly,
		fields,
		employeeApprovalStatus,
		overlaps,
		editingEntryIndex,
		editingEntryInitialEntry,
		isAnotherTimeSheetInEditMode,
		isThisTimeSheetInEditMode,
		dueDate,
		timeZoneInUse,
		entriesSyncErrors,
		change,
		setEditingEntryIndexAndValue,
		changeEntries,
		calculateOverlapsForValidation,
	} = props;

	const isAllowedToAddTimeEntry = isAllowedToEdit && !areTimeSheetsReadOnly && !(isThisTimeSheetInEditMode || isAnotherTimeSheetInEditMode);

	const resetEntry = React.useCallback((index: number, value) => {
		changeEntries({
			action: TimeSheetEntryAction.RESET,
			entryIndex: index,
			newEntry: value,
		});
		setEditingEntryIndexAndValue(null, null);
	}, [changeEntries, setEditingEntryIndexAndValue]);

	const removeEntry = React.useCallback((index: number) => {
		const entryWithType = fields.get(index);
		if (entryWithType.type === TimeSheetEntryFormType.ADDED) {
			changeEntries({
				action: TimeSheetEntryAction.REMOVE,
				entryIndex: index,
			}, true);
		} else {
			changeEntries({
				action: TimeSheetEntryAction.REMOVE,
				entryIndex: index,
			});
		}
		setEditingEntryIndexAndValue(null, null);
	}, [changeEntries, fields, setEditingEntryIndexAndValue]);

	const saveEntry = React.useCallback((index: number) => {
		const entryWithType = fields.get(index);
		if (entryWithType.type !== TimeSheetEntryFormType.ADDED && entryWithType.type !== TimeSheetEntryFormType.ENTRY) {
			throw new Error('Trying to save entry that is not being edited');
		}
		const newEntry = TimeSheetEditFormModel.addedEntryToListEntry(entryWithType.entry);
		changeEntries({
			action: TimeSheetEntryAction.SAVE,
			entryIndex: index,
			newEntry: {
				entry: newEntry,
				type: TimeSheetEntryFormType.ENTRY,
			},
		});
		setEditingEntryIndexAndValue(null, null);
	}, [changeEntries, fields, setEditingEntryIndexAndValue]);

	const addEntry = React.useCallback(() => {
		fields.push({ entry: new TimeSheetAddedEntryFormModel(true), type: TimeSheetEntryFormType.ADDED });
		setEditingEntryIndexAndValue(fields.length, null);
	}, [fields, setEditingEntryIndexAndValue]);

	const addGapEntry = React.useCallback((index: number) => {
		const gapEntryWithType = fields.get(index);
		if (gapEntryWithType.type !== TimeSheetEntryFormType.GAP) {
			throw new Error('Trying to add a gap entry from entry that ');
		}
		fields.splice(index, 1,
			{
				entry: new TimeSheetAddedEntryFormModel(
					false, gapEntryWithType.entry.startTime, gapEntryWithType.entry.endTime!, gapEntryWithType.entry.startDate
				),
				type: TimeSheetEntryFormType.ADDED,
			});
		setEditingEntryIndexAndValue(index, gapEntryWithType);
	}, [fields, setEditingEntryIndexAndValue]);

	const onEditingEntry = React.useCallback((
		index: number,
		newEntryWithType: TimeSheetEntryWithType
	) => {
		if (newEntryWithType.type !== TimeSheetEntryFormType.ENTRY) {
			throw new Error('Trying to edit entry that cant be edited');
		}
		changeEntries({
			action: TimeSheetEntryAction.EDITING,
			entryIndex: index,
			newEntry: newEntryWithType,
		});
	}, [changeEntries]);

	const renderTimeSheetEntry = React.useCallback((field: string, index: number) => {
		const entryWithType = fields.get(index);
		const overlap = overlaps[entryWithType.entry.virtualId];
		const entryErrors = entriesSyncErrors ?
			entriesSyncErrors[index]?.entry as FormErrors<TimeSheetEntryFormModel | TimeSheetAddedEntryFormModel, string>
			: {};

		return (
			<Field
				addGapEntry={addGapEntry}
				calculateOverlapsForValidation={calculateOverlapsForValidation}
				change={change}
				component={TimeSheetEntry}
				dueDate={dueDate}
				editingEntryIndex={editingEntryIndex}
				editingEntryInitialEntry={editingEntryInitialEntry}
				employeeApprovalStatus={employeeApprovalStatus}
				entrySyncErrors={entryErrors}
				getEntry={fields.get}
				index={index}
				isAllowedToAddTimeEntry={isAllowedToAddTimeEntry}
				isAnotherTimeSheetInEditMode={isAnotherTimeSheetInEditMode}
				isThisTimeSheetInEditMode={isThisTimeSheetInEditMode}
				key={index}
				name={field}
				onEditingEntry={onEditingEntry}
				overlap={overlap}
				removeEntry={removeEntry}
				resetEntry={resetEntry}
				saveEntry={saveEntry}
				setEditingEntryIndexAndValue={setEditingEntryIndexAndValue}
				timeZoneInUse={timeZoneInUse}
			/>
		);
	}, [
		addGapEntry,
		dueDate,
		editingEntryIndex,
		employeeApprovalStatus,
		fields,
		isAllowedToAddTimeEntry,
		overlaps,
		removeEntry,
		resetEntry,
		saveEntry,
		setEditingEntryIndexAndValue,
		change,
		isThisTimeSheetInEditMode,
		isAnotherTimeSheetInEditMode,
		calculateOverlapsForValidation,
		onEditingEntry,
		editingEntryInitialEntry,
		timeZoneInUse,
		entriesSyncErrors,
	]);

	return (
		<>

			<div className={styles['time-sheet-bulk-edit-modal__timeline__entries']}>
				{fields.map(renderTimeSheetEntry)}
			</div>

			{isAllowedToAddTimeEntry && !isAnotherTimeSheetInEditMode && (
				<div className={`${styles['time-sheet-bulk-edit-modal__add-button']} ${styles['time-sheet-bulk-edit-modal__add-button--spacing-top']}`}>
					<Button
						icon="plus"
						label="Add Timeline Entry"
						onClick={addEntry}
						style="link"
					/>
				</div>
			)}
		</>
	);
};

export default React.memo(TimeSheetEntriesList);
