import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import SubscriptionStatus from '@acceligentllc/shared/enums/subscriptionStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as UserUtils from '@acceligentllc/shared/utils/user';

import { LATEST_PUBLISHED_WORK_ORDER_REVISION_ITEM, CURRENT_WORK_ORDER_REVISION_ITEM } from 'ab-common/constants/value';

import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type WorkOrderRevisionBase from 'ab-domain/models/workOrderRevision/base';

import * as CodeUtils from 'ab-utils/codes.util';

type W_WorkOrder_FindAllRevisionsForWorkOrder_VM_EmployeesAndTemporaryEmployees = {
	employees: W_WorkOrder_FindAllRevisionsForWorkOrder_VM_Employee[];
	temporaryEmployees: W_WorkOrder_FindAllRevisionsForWorkOrder_VM_TemporaryEmployee[];
};

export type W_WorkOrder_FindAllRevisionsForWorkOrder_VM = (
	W_WorkOrder_FindAllRevisionsForWorkOrder_VM_CurrentModalItem
	| W_WorkOrder_FindAllRevisionsForWorkOrder_VM_RevisionModalItem
	| W_WorkOrder_FindAllRevisionsForWorkOrder_VM_UnavailableWorkOrderRevision
);

export class W_WorkOrder_FindAllRevisionsForWorkOrder_VM_ModalItem {
	id: Nullable<number>;
	code: Nullable<string>;
	dueDate: Nullable<string>;
	revision: Nullable<string>;
	updatedAt: Nullable<Date>;
	status: Nullable<WorkOrderStatus>;
	isCurrentWorkOrder: Nullable<boolean>; // last state of work order (not latest revision, but latest work order)
	additionalText: Nullable<string>;
	updatedByFullName: Nullable<string>;
	disabled?: boolean;

	constructor(revision: WorkOrderBase | WorkOrderRevisionBase) {
		this.id = revision.id;
		this.dueDate = TimeUtils.formatDate(revision.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
		this.code = revision.workOrderCode;
	}

	static bulkConstructor(
		revisions: Array<(WorkOrderBase & { isRevision: false; }) | (WorkOrderRevisionBase & { isRevision: true; })>
	): Array<W_WorkOrder_FindAllRevisionsForWorkOrder_VM_RevisionModalItem | W_WorkOrder_FindAllRevisionsForWorkOrder_VM_CurrentModalItem> {
		const isCurrentPublished = revisions.some((revision) =>
			!revision.isRevision
			&& revision.status === WorkOrderStatus.PUBLISHED
		);
		// revisions array should have WorkOrder as last item. All previous ones should be WorkOrderRevision
		const result = revisions.map((revision, index, array) => {
			if (!revision.isRevision) {
				return new W_WorkOrder_FindAllRevisionsForWorkOrder_VM_CurrentModalItem(
					revision,
					revisions.filter((_rev) => _rev.revision === revision.revision && _rev.isRevision).length
				);
			}
			const additionalText = !isCurrentPublished && index === array.length - 2 ? LATEST_PUBLISHED_WORK_ORDER_REVISION_ITEM : '';
			return new W_WorkOrder_FindAllRevisionsForWorkOrder_VM_RevisionModalItem(revision, additionalText);
		});

		if (revisions.length > 0 && revisions[0].revision > 1) {
			result.unshift(new W_WorkOrder_FindAllRevisionsForWorkOrder_VM_UnavailableWorkOrderRevision());
		}

		return result;
	}
}

class W_WorkOrder_FindAllRevisionsForWorkOrder_VM_CurrentModalItem extends W_WorkOrder_FindAllRevisionsForWorkOrder_VM_ModalItem {
	constructor(workOrder: WorkOrderBase, totalRevisions?: number) {
		super(workOrder);
		this.revision = totalRevisions ? `${CodeUtils.revisionCode(workOrder.revision)}.${totalRevisions}` : CodeUtils.revisionCode(workOrder.revision);
		this.updatedAt = workOrder.updatedAt;
		this.status = workOrder.status;
		this.isCurrentWorkOrder = true;
		this.additionalText = CURRENT_WORK_ORDER_REVISION_ITEM;
		this.updatedByFullName = UserUtils.getUserName(
			workOrder.updatedBy ? workOrder.updatedBy?.user : null
		);
	}
}

class W_WorkOrder_FindAllRevisionsForWorkOrder_VM_RevisionModalItem extends W_WorkOrder_FindAllRevisionsForWorkOrder_VM_ModalItem {
	employees: W_WorkOrder_FindAllRevisionsForWorkOrder_VM_Employee[];
	temporaryEmployees: W_WorkOrder_FindAllRevisionsForWorkOrder_VM_TemporaryEmployee[];

	constructor(workOrderRevision: WorkOrderRevisionBase, additionalText?: string) {
		super(workOrderRevision);
		const revisionStatus = workOrderRevision.statusBasedRevision && workOrderRevision.statusBasedRevision > 1 ? `.${workOrderRevision.statusBasedRevision - 1}` : '';
		this.revision = `${CodeUtils.revisionCode(workOrderRevision.revision)}${revisionStatus}`;
		this.updatedAt = workOrderRevision.createdAt;
		this.status = WorkOrderStatus.PUBLISHED;
		this.isCurrentWorkOrder = false;
		this.additionalText = additionalText ?? null;

		const {
			employees,
			temporaryEmployees,
		} = workOrderRevision.workOrderResourceLookups.reduce(
			(_acc, _currentResource) => {
				if (_currentResource.employeeId && _currentResource.notification) {
					_acc.employees.push({
						employeeId: _currentResource.employeeId,
						fullName: _currentResource.notification.fullName,
						emailSentAt: _currentResource.notification.emailSentAt ?? null,
						smsSentAt: _currentResource.notification.smsSentAt ?? null,
						email: _currentResource.notification.email,
						emailStatus: _currentResource.notification.emailStatus ?? null,
						emailErrorMessage: _currentResource.notification.emailErrorMessage ?? null,
						smsErrorMessage: _currentResource.notification.smsErrorMessage ?? null,
						phoneNumber: _currentResource.notification.phoneNumber,
						smsStatus: _currentResource.notification.smsStatus ?? null,
						emailInvalidatedAt: _currentResource.notification.emailInvalidatedAt,
						phoneInvalidatedAt: _currentResource.notification.phoneInvalidatedAt,
						officeColor: _currentResource.color,
						officeNickname: _currentResource.notification.officeNickname ?? null,
						subscriptionStatus: _currentResource.notification.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
					});
				} else if (_currentResource.temporaryEmployeeId && _currentResource.notification) {
					_acc.temporaryEmployees.push({
						temporaryEmployeeId: _currentResource.temporaryEmployeeId,
						fullName: _currentResource.notification.fullName,
						emailSentAt: _currentResource.notification.emailSentAt ?? null,
						smsSentAt: _currentResource.notification.smsSentAt ?? null,
						email: _currentResource.notification.email,
						emailStatus: _currentResource.notification.emailStatus ?? null,
						emailErrorMessage: _currentResource.notification.emailErrorMessage ?? null,
						smsErrorMessage: _currentResource.notification.smsErrorMessage ?? null,
						phoneNumber: _currentResource.notification.phoneNumber,
						smsStatus: _currentResource.notification.smsStatus ?? null,
						emailInvalidatedAt: _currentResource.notification.emailInvalidatedAt,
						phoneInvalidatedAt: _currentResource.notification.phoneInvalidatedAt,
						subscriptionStatus: _currentResource.notification.subscriptionStatus ?? SubscriptionStatus.SUBSCRIBED,
					});
				}
				return _acc;
			},
			{ employees: [], temporaryEmployees: [] } as W_WorkOrder_FindAllRevisionsForWorkOrder_VM_EmployeesAndTemporaryEmployees
		);

		this.employees = employees;
		this.temporaryEmployees = temporaryEmployees;
	}
}

// this class is used as disabled dropdown item for backwards incompatible Work Orders
class W_WorkOrder_FindAllRevisionsForWorkOrder_VM_UnavailableWorkOrderRevision {
	id: Nullable<number>;
	code: Nullable<string>;
	dueDate: Nullable<string>;
	revision: Nullable<string>;
	updatedAt: Nullable<Date>;
	status: Nullable<WorkOrderStatus>;
	isCurrentWorkOrder: Nullable<boolean>;
	additionalText: string;
	disabled: boolean;
	updatedByFullName: Nullable<string>;

	constructor() {
		this.id = null;
		this.revision = null;
		this.dueDate = null;
		this.code = null;
		this.updatedAt = null;
		this.status = null;
		this.isCurrentWorkOrder = null;
		this.additionalText = 'No previous revisions available.';
		this.disabled = true;
		this.updatedByFullName = null;
	}
}

interface W_WorkOrder_FindAllRevisionsForWorkOrder_VM_Employee {
	employeeId: number;
	fullName: string;
	emailSentAt: Nullable<Date>;
	smsSentAt: Nullable<Date>;
	email: Nullable<string>;
	emailStatus: Nullable<NotificationStatusEnum>;
	phoneNumber: Nullable<string>;
	smsStatus: Nullable<NotificationStatusEnum>;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	officeColor: Nullable<ColorPalette>;
	officeNickname: Nullable<string>;
	subscriptionStatus: SubscriptionStatus;
	emailErrorMessage: Nullable<string>;
	smsErrorMessage: Nullable<string>;
}

interface W_WorkOrder_FindAllRevisionsForWorkOrder_VM_TemporaryEmployee {
	temporaryEmployeeId: number;
	fullName: string;
	emailSentAt: Nullable<Date>;
	smsSentAt: Nullable<Date>;
	email: Nullable<string>;
	emailStatus: Nullable<NotificationStatusEnum>;
	phoneNumber: Nullable<string>;
	smsStatus: Nullable<NotificationStatusEnum>;
	emailInvalidatedAt: Nullable<Date>;
	phoneInvalidatedAt: Nullable<Date>;
	subscriptionStatus: SubscriptionStatus;
	emailErrorMessage: Nullable<string>;
	smsErrorMessage: Nullable<string>;
}
