import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';
import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';

import WorkRequestBidStatus from '@acceligentllc/shared/enums/workRequestBidStatus';

import TabNavigation from 'af-components/TabNavigation';
import Dropdown from 'af-components/Controls/Dropdown';
import Breadcrumbs from 'af-components/Breadcrumbs';

import * as WorkRequestActions from 'af-actions/workRequests';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

import Tooltip from 'af-components/Tooltip';

import type WorkRequestPreviewVM from 'ab-viewModels/workRequest/workRequestPreview.viewModel';

import WorkRequestConvertToJobModal from '../Shared/WorkRequestConvertToJobModal';
import WorkRequestReasonForLostModal from '../Shared/WorkRequestReasonForLostModal';
import WorkRequestCopyModal from '../Shared/WorkRequestCopyModal';

import styles from './styles.module.scss';
import { WorkRequestPreviewTabs } from './types';

type Props = ConnectedProps<typeof connector> & InjectedFormProps<WorkRequestPreviewVM>;

interface Tab {
	id: number;
	label: string;
}

const TABS: Tab[] = [
	{ id: 0, label: 'Work Request' },
	{ id: 1, label: 'Job Hazard Assessment' },
	{ id: 2, label: 'Attachments' },
];

const BID_STATUS_OPTIONS = [
	{ id: WorkRequestBidStatus.IN_PROGRESS, label: 'In Progress' },
	{ id: WorkRequestBidStatus.LOST, label: 'Lost' },
	{ id: WorkRequestBidStatus.WON, label: 'Won' },
];

const resolveBreadcrumbs = (_wr: Nullable<WorkRequestPreviewVM>, orgAlias: string, companyName: string) => {

	const items: { label: string; url?: string; }[] =
		[
			{
				label: 'Work Request',
				url: CLIENT.COMPANY.WORK_REQUESTS.TABLE(orgAlias, companyName),
			},
			{
				label: _wr?.details.jobCode ?? 'Loading...',
			},
			{
				label: 'Preview',
			},
		];
	return items;
};

const WorkRequestPreview: React.FC<Props> = (props) => {
	const [currentWorkRequest, setCurrentWorkRequest] = React.useState<Nullable<WorkRequestPreviewVM>>(null);
	const [isUpdatingBidStatus, setIsUpdatingBidStatus] = React.useState(false);
	const [currentBidStatus, setCurrentBidStatus] = React.useState<Nullable<Exclude<WorkRequestBidStatus, 'JOB'>>>(null);
	const [currentLostForReason, setCurrentLostForReason] = React.useState<Nullable<string>>(null);
	const [currentConvertedToJobId, setCurrentConvertedToJobId] = React.useState<Nullable<number>>(null);
	const [currentConvertedToJobCode, setCurrentConvertedToJobCode] = React.useState<Nullable<string>>(null);
	const [showReasonForLostModal, setShowReasonForLostModal] = React.useState(false);
	const [showConvertToJobModal, setShowConvertToJobModal] = React.useState(false);
	const [showCopyModal, setShowCopyModal] = React.useState(false);

	const {
		companyName,
		findById,
		editBidStatus,
	} = props;

	const { state: { orgAlias } } = useLocation();
	const { workRequestId, '*': tabId } = useParams();
	const navigate = useNavigate();
	const activeTabId = React.useMemo(() => {
		switch (tabId) {
			case WorkRequestPreviewTabs.DETAILS:
				return TABS[0].id;
			case WorkRequestPreviewTabs.JOB_HAZARD_ASSESSMENT:
				return TABS[1].id;
			case WorkRequestPreviewTabs.ATTACHMENTS:
				return TABS[2].id;
			default:
				return TABS[0].id;
		}
	}, [tabId]);

	const fetchAndSetData = React.useCallback(async () => {
		if (workRequestId) {
			const wr = await findById(+workRequestId);
			if (wr.bidStatus === WorkRequestBidStatus.JOB) {
				throw new Error('Work Request has been converted to job.');
			}
			setCurrentWorkRequest(wr);
			setCurrentBidStatus(wr.bidStatus);
			setCurrentLostForReason(wr.lostForReason ?? null);
			setCurrentConvertedToJobId(wr.convertedToJobId ?? null);
			setCurrentConvertedToJobCode(wr.convertedToJobCode);
		}
	}, [findById, workRequestId]);

	React.useEffect(() => {
		fetchAndSetData();
	}, [fetchAndSetData]);

	const resolveOutletContext = React.useCallback((workRequest: WorkRequestPreviewVM, wid: string) => ({
		isJobPreview: false,
		orgAlias,
		workRequest: workRequest.details,
		jobHazardAssessment: workRequest.jobHazardAssessment,
		hideAttachedToWO: true,
		hideCopyToWO: true,
		jobId: wid,
		isReadOnly: currentBidStatus === WorkRequestBidStatus.LOCKED_IN,
	}), [currentBidStatus, orgAlias]);

	const onTabSelect = React.useCallback((newTabId: number) => {
		let tabName = '';
		switch (newTabId) {
			case TABS[0].id:
				tabName = WorkRequestPreviewTabs.DETAILS;
				break;
			case TABS[1].id:
				tabName = WorkRequestPreviewTabs.JOB_HAZARD_ASSESSMENT;
				break;
			case TABS[2].id:
				tabName = WorkRequestPreviewTabs.ATTACHMENTS;
				break;
			default:
				tabName = WorkRequestPreviewTabs.DETAILS;
				break;
		}
		navigate(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW_TAB(orgAlias, companyName, workRequestId, tabName));
	}, [companyName, navigate, orgAlias, workRequestId]);

	const currentWorkRequestBidStatusOption = React.useMemo(
		() => BID_STATUS_OPTIONS.find((_option) => _option.id === currentBidStatus),
		[currentBidStatus]);

	const onBidStatusChange = React.useCallback(async (_nextBidStatus: typeof BID_STATUS_OPTIONS[0]) => {
		if (!workRequestId) {
			return;
		}

		if (!currentBidStatus || currentBidStatus === WorkRequestBidStatus.LOCKED_IN) {
			return;
		}

		if (_nextBidStatus.id === currentBidStatus) {
			return;
		}

		switch (_nextBidStatus.id) {
			case (WorkRequestBidStatus.IN_PROGRESS):
			case (WorkRequestBidStatus.WON): {
				setIsUpdatingBidStatus(true);

				await editBidStatus(+workRequestId, {
					newBidStatus: _nextBidStatus.id,
					previousBidStatus: currentBidStatus,
				});
				setCurrentBidStatus(_nextBidStatus.id);
				setIsUpdatingBidStatus(false);
				break;
			}
			case (WorkRequestBidStatus.LOST): {
				setShowReasonForLostModal(true);
				break;
			}

		}
	}, [currentBidStatus, editBidStatus, workRequestId]);

	const resolveCurrentBidStatusClassName = React.useCallback((_bidStatus: WorkRequestBidStatus) => {
		switch (_bidStatus) {
			case (WorkRequestBidStatus.IN_PROGRESS):
				return styles['work-request-preview__submit-section__in-progress-status'];
			case (WorkRequestBidStatus.LOST):
				return styles['work-request-preview__submit-section__lost-status'];
			case (WorkRequestBidStatus.WON):
				return styles['work-request-preview__submit-section__won-status'];
			case (WorkRequestBidStatus.LOCKED_IN):
				return styles['work-request-preview__submit-section__locked-in-status'];
			default:
				return '';
		}
	}, []);

	const onCloseReasonForLostModal = React.useCallback(() => {
		setShowReasonForLostModal(false);
	}, []);

	const onCloseConvertToJobModal = React.useCallback(() => {
		setShowConvertToJobModal(false);
	}, []);

	const onConvertToJobClick = React.useCallback(() => {
		setShowConvertToJobModal(true);
	}, []);

	const openCopyModal = React.useCallback(() => setShowCopyModal(true), []);

	const closeCopyModal = React.useCallback(() => setShowCopyModal(false), []);

	const onConvert = React.useCallback((convertedToJobId: string, convertedToJobCode?: string) => {
		setCurrentConvertedToJobId(+convertedToJobId);
		setCurrentBidStatus(WorkRequestBidStatus.LOCKED_IN);
		setCurrentConvertedToJobCode(convertedToJobCode ?? null);
		onCloseConvertToJobModal();
	}, [onCloseConvertToJobModal]);

	const onEditClick = React.useCallback(() => {
		tabId
			? navigate(CLIENT.COMPANY.WORK_REQUESTS.EDIT_TAB(orgAlias, companyName, workRequestId, tabId))
			: navigate(CLIENT.COMPANY.WORK_REQUESTS.EDIT(orgAlias, companyName, workRequestId));
	}, [companyName, navigate, orgAlias, tabId, workRequestId]);

	const handleMarkAsLostSave = React.useCallback(async (lostForReason: string) => {
		if (!workRequestId) {
			return;
		}

		setIsUpdatingBidStatus(true);
		await editBidStatus(+workRequestId, {
			newBidStatus: WorkRequestBidStatus.LOST,
			previousBidStatus: currentBidStatus as WorkRequestBidStatus.IN_PROGRESS | WorkRequestBidStatus.WON,
			lostForReason,
		});
		setIsUpdatingBidStatus(false);
		setCurrentBidStatus(WorkRequestBidStatus.LOST);
		setCurrentLostForReason(lostForReason);
	}, [currentBidStatus, editBidStatus, workRequestId]);

	const redirectToCopiedWorkRequestPage = React.useCallback((copiedWorkRequestId: number) => {
		navigate(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${copiedWorkRequestId}`));
	}, [companyName, navigate, orgAlias]);

	if (!currentWorkRequest || !workRequestId || !currentBidStatus) {
		return <Breadcrumbs items={resolveBreadcrumbs(currentWorkRequest, orgAlias, companyName)} />;
	}

	const isBidStatusChangeDisabled = (currentWorkRequest.bidStatus === WorkRequestBidStatus.LOCKED_IN) || isUpdatingBidStatus;
	const showEditButton = (currentBidStatus !== WorkRequestBidStatus.LOCKED_IN);
	const showMarkAsWonAndConvertToJobButton = (currentBidStatus === WorkRequestBidStatus.IN_PROGRESS || currentBidStatus === WorkRequestBidStatus.LOST);

	return (
		<>
			<Breadcrumbs items={resolveBreadcrumbs(currentWorkRequest, orgAlias, companyName)} />
			<div className={styles['work-request-preview']}>
				<div className={styles['work-request-preview__submit-section']}>
					<div className={styles['work-request-preview__submit-section__status']}>
						<b>Status:</b>
						{currentBidStatus === WorkRequestBidStatus.LOCKED_IN && !!currentConvertedToJobId
							? (
								<>
									<span className={resolveCurrentBidStatusClassName(WorkRequestBidStatus.LOCKED_IN)}>
										Converted to Job
									</span>
									<span className={styles['work-request-preview__submit-section__locked-in-status__associated-job']}>
										<a href={CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, `${currentConvertedToJobId}`)} rel="noreferrer" target="_blank">
											<span className="icon-external" />
											{`View Associated Job (${currentConvertedToJobCode})`}
										</a>
									</span>
								</>
							)
							: (
								<Dropdown
									className={resolveCurrentBidStatusClassName(currentBidStatus)}
									containerClassName={styles['work-request-preview__submit-section__status__dropdown']}
									defaultValue={currentWorkRequestBidStatusOption}
									disabled={isBidStatusChangeDisabled}
									isControlled={true}
									labelKey="label"
									onValueChange={onBidStatusChange}
									options={BID_STATUS_OPTIONS}
									withBorder={false}
									withCaret={true}
								/>
							)}
						{currentBidStatus === WorkRequestBidStatus.LOST && currentLostForReason && (
							<Tooltip message={currentLostForReason}>
								<div className={styles['work-request-preview__submit-section__lost-status__reason']}>
									Reason: {currentLostForReason}
								</div>
							</Tooltip>
						)}
					</div>
					<div className={styles['work-request-preview__submit-section__actions']}>
						{showEditButton && (
							<Button
								label="Edit"
								onClick={onEditClick}
								style="secondary"
							/>
						)}
						<Button
							label="Copy"
							onClick={openCopyModal}
							style="secondary"
						/>
						{showMarkAsWonAndConvertToJobButton && (
							<Button
								label="Mark as Won and Convert to Job"
								onClick={onConvertToJobClick}
								style="secondary"
							/>
						)}
						{currentBidStatus === WorkRequestBidStatus.WON && (
							<Button
								label="Convert to Job"
								onClick={onConvertToJobClick}
								style="secondary"
							/>
						)}
					</div>
				</div>
				<TabNavigation
					active={activeTabId}
					navigationClassName={styles['work-request-preview__tabs-navigation']}
					onClick={onTabSelect}
					tabs={TABS}
				/>
				<Outlet context={resolveOutletContext(currentWorkRequest, workRequestId)} />
			</div>
			<WorkRequestReasonForLostModal
				closeModal={onCloseReasonForLostModal}
				handleMarkAsLostSave={handleMarkAsLostSave}
				showModal={showReasonForLostModal}
			/>
			<WorkRequestConvertToJobModal
				closeModal={onCloseConvertToJobModal}
				currentJobCode={currentWorkRequest.details.jobCode}
				onConvert={onConvert}
				showModal={showConvertToJobModal}
				workRequestId={+workRequestId}
			/>
			<WorkRequestCopyModal
				closeModal={closeCopyModal}
				currentWorkRequestCode={currentWorkRequest.details.jobCode}
				currentWorkRequestId={currentWorkRequest.details.id}
				redirectToCopiedWorkRequestPage={redirectToCopiedWorkRequestPage}
				showModal={showCopyModal}
			/>
		</>
	);
};

function mapDispatchToProps() {
	return {
		findById: WorkRequestActions.findByIdForPreview,
		editBidStatus: WorkRequestActions.editBidStatus,
	};
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType>(
	connector,
	React.memo
);

export default enhance(WorkRequestPreview);
