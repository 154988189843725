import * as React from 'react';

import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';

import type * as TimeSheetEntryUtils from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';

import styles from './styles.module.scss';

const REFRESH_INTERVAL = 500;

interface OwnProps {
	entry: TimeCardEntryVM;
	overlaps: Nullable<Record<string, TimeSheetEntryUtils.OverlapMeta>>;
	hasPredecessor: boolean;
	hasSuccessor: boolean;
	timeOffset: number;
	timeZoneInUse: Nullable<string>;
}

type Props = OwnProps;

const WORK_TYPE_LABELS = {
	[TimeSheetEntryWorkType.BREAK]: 'Break',
	[TimeSheetEntryWorkType.SHOP]: 'Shop',
	[TimeSheetEntryWorkType.TRAVEL]: 'Travel',
	[TimeSheetEntryWorkType.JOB]: 'Job',
};

const WORK_TYPE_ICONS: Record<TimeSheetEntryWorkType, string> = {
	[TimeSheetEntryWorkType.BREAK]: 'icon-break',
	[TimeSheetEntryWorkType.SHOP]: 'icon-home',
	[TimeSheetEntryWorkType.TRAVEL]: 'icon-travel',
	[TimeSheetEntryWorkType.JOB]: 'icon-equipment',
};

const TimeSheetEntryDetails: React.FC<Props> = (props: Props) => {
	const { entry, overlaps, hasPredecessor, hasSuccessor, timeOffset, timeZoneInUse } = props;
	const ref = React.useRef<Nullable<NodeJS.Timeout>>(null);
	const [currentTime, setCurrentTime] = React.useState(new Date());

	const overlapTimeToHighlight: string[] = [];

	const overlap = overlaps?.[entry.id];

	if (overlap) {
		if (!!overlap.startTime) {
			overlapTimeToHighlight.push('startTime');
		}
		if (!!overlap.endTime) {
			overlapTimeToHighlight.push('endTime');
		}
	}
	const connectorBottomStyle = overlapTimeToHighlight.includes('startTime') ? styles.connectorBottomWarn : styles.connectorBottom;
	const connectorTopStyle = overlapTimeToHighlight.includes('endTime') ? styles.connectorTopWarn : styles.connectorTop;
	const overlapHighlightStart = overlapTimeToHighlight.includes('startTime') ? styles.overlapingTime : styles.startEndTime;
	const overlapHighlightEnd = overlapTimeToHighlight.includes('endTime') ? styles.overlapingTime : styles.startEndTime;

	const workTypeDuration = React.useMemo(() => {
		return (!!entry.endTime && !!entry.startTime) ?
			TimeUtils.minutesToHoursAndMinutes(TimeUtils.getDiff(entry.endTime, entry.startTime, 'minutes', TimeFormat.ISO_DATETIME)) : '--:--';
	}, [entry.endTime, entry.startTime]);

	const startTimeForTimer = React.useMemo(() => {
		// in case time zone of work order is different than time zone on client
		if (!entry.endTime && timeZoneInUse) {
			const startTimeWithoutOffset = TimeUtils.offsetTime(entry.startTime, -timeOffset, 'minutes', TimeFormat.ISO_DATETIME);
			const startTime = startTimeWithoutOffset ? new Date(startTimeWithoutOffset) : new Date(entry.startTime);
			return startTime;
		}

		return new Date(entry.startTime);
	}, [entry.endTime, entry.startTime, timeOffset, timeZoneInUse]);

	React.useEffect(() => {
		ref.current = setInterval(() => setCurrentTime(new Date()), REFRESH_INTERVAL);

		return () => {
			if (!ref.current) {
				return;
			}
			clearInterval(ref.current);
		};
	}, []);

	let workTypeIconStyle = '';
	if ((!entry.workOrderCode && entry.endTime) || overlapTimeToHighlight.length) {
		workTypeIconStyle = styles.redIcon;
	} else if (entry.timeSheetSignatureStatus === TimeSheetSignatureStatus.OUTDATED) {
		workTypeIconStyle = styles.orangeIcon;
	} else if (entry.timeSheetApprovalStatus === TimeSheetApprovalStatus.APPROVED) {
		workTypeIconStyle = styles.inactiveIcon;
	}

	let positioningIconStyle = '';
	if (entry.timeSheetApprovalStatus !== TimeSheetApprovalStatus.APPROVED) {
		positioningIconStyle = styles.greenIcon;
	} else if (entry.timeSheetApprovalStatus === TimeSheetApprovalStatus.APPROVED) {
		positioningIconStyle = styles.inactiveIcon;
	}

	const startTime = React.useMemo(() => {
		return TimeUtils.formatDate(entry.startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME);
	}, [entry.startTime]);

	const endTime = React.useMemo(() => {
		return entry.endTime ? TimeUtils.formatDate(entry.endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME) : '';
	}, [entry.endTime]);

	return (
		<>
			{hasSuccessor && <div className={connectorTopStyle} />}
			<div className={styles.timeSheetEntry}>
				<div className={styles.workTypeIcon}>
					{WORK_TYPE_ICONS[entry.workType] &&
						<div className={workTypeIconStyle}>
							<span className={WORK_TYPE_ICONS[entry.workType]} />
						</div>
					}
					{!!entry.isInActiveShift && (
						<span className="icon-time_card" />
					)}
					{entry.timeSheetApprovalStatus === TimeSheetApprovalStatus.REJECTED && (
						<div className={positioningIconStyle}>
							<span className="icon-x_circle" />
						</div>
					)}
					{entry.timeSheetApprovalStatus === TimeSheetApprovalStatus.APPROVED && (
						<div className={positioningIconStyle}>
							<span className="icon-check_circle" />
						</div>
					)}
					{entry.timeSheetSignatureStatus === TimeSheetSignatureStatus.SIGNED && (
						<div className={positioningIconStyle}>
							<span className="icon-check_circle" />
						</div>
					)}
				</div>
				<div className={styles.workTypeInfo}>
					<div className={styles.firstRow}>
						<div className={styles.workType}>
							{WORK_TYPE_LABELS[entry.workType] || entry.workType}
						</div>
						<div className={styles.workOrderCodeSeparator} />
						{entry.workOrderCode &&
							<div className={styles.workOrderCode}>
								{entry.workOrderCode}
							</div>
						}
						{!entry.workOrderCode && entry.endTime &&
							<div className={styles.unassigned}>
								Unassigned
							</div>
						}
					</div>
					<div className={styles.secondRow}>
						<div className={overlapHighlightStart}>
							{startTime}
						</div>
						<div className={styles.startEndTime}>
							-
						</div>
						<div className={overlapHighlightEnd}>
							{endTime}
						</div>
					</div>
				</div>
				{/* INFO SHOWN FOR FINNISHED ENTRIES */}
				{!!entry.endTime &&
					<>
						<div className={styles.workTypeDuration}>
							{workTypeDuration}
						</div><div className={styles.workTypeEditIcon}>
							<span className="icon-edit" />
						</div>
					</>
				}
				{/* TIMER FOR ONGOING ENTRY */}
				{!entry.endTime && !!currentTime && !!startTimeForTimer &&
					<div className={styles.activeTimer}>
						{TimeUtils.secondsToHMS(TimeUtils.getDiff(currentTime, startTimeForTimer, 'second'))}
					</div>
				}
			</div>
			{hasPredecessor && <div className={connectorBottomStyle} />}
		</>
	);
};

export default React.memo(TimeSheetEntryDetails);
