import { TableContent } from 'ab-common/dataStructures/tableContent';

import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';

import type ItemBase from 'ab-domain/models/item/base';

export class W_Item_FindItemTable_VM extends TableContent<W_Item_FindItemTable_VM_Row>{
	constructor(items: ItemBase[], pages: number, totalCount: number) {
		super(
			W_Item_FindItemTable_VM_Row.bulkConstructor(items),
			pages,
			totalCount
		);
	}
}

class W_Item_FindItemTable_VM_Row {
	id: number;
	imageUrl: Nullable<string>;
	modelNumber: string;
	name: string;
	packageType: Nullable<VendorPackageType>;
	price: Nullable<string>;
	itemCategory: string;
	currentStock: Nullable<number>;
	crewType: Nullable<string>;
	crewColor: Nullable<string>;

	constructor(item: ItemBase) {
		this.id = item.id;
		this.imageUrl = item.imageUrl;
		this.modelNumber = item.modelNumber;
		this.name = item.name;
		this.packageType = item.itemVendor?.packageType ?? null;
		this.price = item.itemVendor?.price ?? null;
		this.itemCategory = item.itemItemCategoryLookup.map((_iicl) => _iicl.itemCategory.name).join(', ');
		this.currentStock = item.itemDepartments.reduce(W_Item_FindItemTable_VM_Row._calculateCurrentStock, 0);
		this.crewType = item.crewType?.name ?? null;
		this.crewColor = item.crewType?.color ?? null;
	}

	private static _calculateCurrentStock = (sum: number, department: ItemBase['itemDepartments'][0]) => sum + (+department.quantity ?? 0);

	private static _mapConstructor = (item: ItemBase) => new W_Item_FindItemTable_VM_Row(item);
	static bulkConstructor = (items: ItemBase[]) => items.map(W_Item_FindItemTable_VM_Row._mapConstructor);
}
