import type { FormErrors, FormValueSelectorResult } from 'redux-form';
import { formValueSelector } from 'redux-form';

import type ContactVM from 'ab-viewModels/contact/contact';

import { filterMap } from '@acceligentllc/shared/utils/array';

import { CONTACT_ACTION_MODAL_SAVED_CONTACT } from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers/index';

import { toRawPhoneNumber } from 'ab-utils/phone.util';

import type { SubmitActionCallbackData } from '../types';

type ContactMethodVM = (ContactVM['emails'] | ContactVM['phones'])[number];

export abstract class SavedContactMethodFormModel {
	isUsed: boolean;
	value: string;

	static isUsed = (contactMethod: SavedContactMethodFormModel) => contactMethod.isUsed;

	static getValue = (contactMethod: SavedContactMethodFormModel) => contactMethod.value;

	static formatNumber = (contactMethod: SavedContactMethodFormModel) => toRawPhoneNumber(contactMethod.value);

	static fromContactMethodVM(contactMethod: ContactMethodVM): SavedContactMethodFormModel {
		return { value: contactMethod.value, isUsed: false };
	}
}

export abstract class SavedContactFormModel {
	emails: SavedContactMethodFormModel[];
	phones: SavedContactMethodFormModel[];

	static readonly FORM_NAME = CONTACT_ACTION_MODAL_SAVED_CONTACT;

	static readonly selector: FormValueSelectorResult<RootState, SavedContactFormModel> = formValueSelector(SavedContactFormModel.FORM_NAME);

	static getSubmitActionData(state: RootState): SubmitActionCallbackData {
		const { emails, phones } = SavedContactFormModel.selector(state, 'emails', 'phones');
		return {
			emails: emails ? filterMap(emails, SavedContactMethodFormModel.isUsed, SavedContactMethodFormModel.getValue) : [],
			phones: phones ? filterMap(phones, SavedContactMethodFormModel.isUsed, SavedContactMethodFormModel.formatNumber) : [],
		};
	}

	static fromContactVM(contact: Nullable<ContactVM>) {
		return {
			emails: contact?.emails?.map(SavedContactMethodFormModel.fromContactMethodVM) ?? [],
			phones: contact?.phones?.map(SavedContactMethodFormModel.fromContactMethodVM) ?? [],
		};
	}

	static validate(values: SavedContactFormModel): FormErrors<SavedContactFormModel> {
		if (values.emails?.some(SavedContactMethodFormModel.isUsed) || values.phones?.some(SavedContactMethodFormModel.isUsed)) {
			return {};
		}
		return {
			emails: 'Pick at least one contact method',
			phones: 'Pick at least one contact method',
		};
	}
}
