import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import Textarea from 'af-fields/Textarea';

import type JobUpsertFM from '../../../formModel';
import { formSectionClassColumn6, formSectionClassRow } from '../../../helpers';

type Props = WrappedFieldArrayProps<JobUpsertFM['permitsRequired']>;

const Permits: React.FC<Props> = (props) => {
	const { fields } = props;

	const onAddClick = React.useCallback(() => {
		fields.push(null);
	}, [fields]);

	const onRemoveClick = React.useCallback((index: number) => () => fields.remove(index), [fields]);

	const renderPermit = (_permit: string, _index: number) => {
		return (
			<div className={formSectionClassRow} key={_permit}>
				<div className={formSectionClassColumn6}>
					<Field
						component={Textarea}
						maxCharacters={300}
						name={_permit}
						placeholder="Enter Text"
						rows={1}
						showMaxCharactersLabel={true}
						type="text"
					/>
				</div>
				<Button
					icon="delete"
					onClick={onRemoveClick(_index)}
					style="link-danger"
				/>
			</div>
		);
	};

	return <>
		{fields.map(renderPermit)}
		<Button
			icon="plus"
			label="Add Permit"
			onClick={onAddClick}
			style="link"
		/>
	</>;
};

export default Permits;
