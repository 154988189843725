import * as React from 'react';
import { compose } from 'redux';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';

import Checkbox from 'af-components/Controls/Checkbox';

import styles from './styles.module.scss';

const WORK_TYPE_LABELS = {
	[TimeSheetEntryWorkType.BREAK]: 'Break',
	[TimeSheetEntryWorkType.SHOP]: 'Shop',
	[TimeSheetEntryWorkType.TRAVEL]: 'Travel',
	[TimeSheetEntryWorkType.JOB]: 'Job',
};

const workTypeIcons: Record<TimeSheetEntryWorkType, string> = {
	[TimeSheetEntryWorkType.BREAK]: 'icon-break',
	[TimeSheetEntryWorkType.SHOP]: 'icon-home',
	[TimeSheetEntryWorkType.TRAVEL]: 'icon-travel',
	[TimeSheetEntryWorkType.JOB]: 'icon-equipment',
};

interface OwnProps {
	entry: TimeCardEntryVM;
	onEntrySelect: (entryId: number) => void;
	entrySelected: boolean;
}

type Props = OwnProps;

const UnassignedEntry: React.FC<Props> = (props: Props) => {
	const { entry, onEntrySelect, entrySelected } = props;

	const handleEntrySelect = React.useCallback(() => {
		onEntrySelect(entry.id);
	}, [entry.id, onEntrySelect]);

	const startTime = React.useMemo(() => {
		return TimeUtils.formatDate(entry.startTime, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME);
	}, [entry.startTime]);

	const endTime = React.useMemo(() => {
		return entry.endTime ? TimeUtils.formatDate(entry.endTime, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME) : '';
	}, [entry.endTime]);

	return (
		<div className={styles.timeSheetEntry}>
			<div className={styles.workTypeIcon}>
				{workTypeIcons[entry.workType] && <span className={workTypeIcons[entry.workType]} />}
			</div>
			<div className={styles.workTypeInfo}>
				<div className={styles.firstRow}>
					<div className={styles.workType}>
						{WORK_TYPE_LABELS[entry.workType]}
					</div>
					<div className={styles.workOrderCodeSeparator} />
					<div className={styles.unassigned}>
						Unassigned
					</div>
				</div>
				<div className={styles.secondRow}>
					<div className={styles.startEndTime}>
						{startTime}
					</div>
					<div className={styles.startEndTime}>
						-
					</div>
					<div className={styles.startEndTime}>
						{endTime}
					</div>
				</div>
			</div>

			<div className={styles.checkbox}>
				<Checkbox
					handleChange={handleEntrySelect}
					inline={true}
					isChecked={entrySelected}
				/>
			</div>
		</div>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo
);
export default enhance(UnassignedEntry);
