import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';

import type { PCTimeSheetVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import Label from 'af-components/LockedValue/Label';
import LockedValue from 'af-components/LockedValue';

import SignatureStatusLabel from '../../Shared/SignatureStatusLabel';
import EquipmentItem from './EquipmentItem';

interface OwnProps {
	timeSheet: PCTimeSheetVM;
}

type Props = OwnProps;

const TimeSheet: React.FC<Props> = (props: Props) => {
	const {
		timeSheet,
		timeSheet: {
			startTime,
			endTime,
			totalJobDuration,
			totalTravelDuration,
			totalShopDuration,
			totalBreakDuration,
			totalDuration,
			timeSplitEntries,
			signatureStatus,
			approvalStatus,
			approvedBy,
			approvedAt,
			rejectReason,
			rejectedBy,
			rejectedAt,
			signatureUrl,
			approvalSignatureUrl,
			signedAt,
			note,
		},
	} = props;

	return (
		<div className="public-report__time-sheet">
			<div className="public-report__time-sheet__user-and-signature-container">
				<div className="public-report__time-sheet__user-and-signature">
					<div className="public-report__time-sheet__user-and-signature__user">
						{timeSheet.fieldWorkerName}
					</div>
					<div className="public-report__time-sheet__user-and-signature__signature-container">
						<div className="public-report__time-sheet__user-and-signature__signature-statuses">
							<div>
								<SignatureStatusLabel status={signatureStatus} />
							</div>
							{signatureStatus === TimeSheetSignatureStatus.SIGNED &&
								<div>
									at {signedAt}
								</div>
							}
							{approvalStatus === TimeSheetApprovalStatus.APPROVED &&
								<>
									{signatureStatus !== TimeSheetSignatureStatus.SIGNED &&
										<div className="public-report__time-sheet__user-and-signature__signature-statuses__si">
											Signed by {approvedBy}
										</div>
									}
									<div>
										<SignatureStatusLabel status={approvalStatus} />
									</div>
									<div>
										by {approvedBy}
										<br />
										at {approvedAt}
									</div>
								</>
							}
							<br />
							{approvalStatus === TimeSheetApprovalStatus.REJECTED &&
								<>
									<div>
										<SignatureStatusLabel status={approvalStatus} />
									</div>
									<div>
										by {rejectedBy}
										<br />
										at {rejectedAt}
									</div>
									<div className="public-report__time-sheet__user-and-signature__signature-statuses__si">
										{rejectReason}
									</div>
								</>
							}
						</div>
						{(!!signatureUrl || !!approvalSignatureUrl) &&
							<img
								className="public-report__time-sheet__user-and-signature__signature-image"
								src={signatureUrl ?? approvalSignatureUrl ?? undefined}
							/>
						}
					</div>
				</div>
				{note &&
					<div className="public-report__time-sheet__user-and-signature__note">
						<Label inverse={true} label="Note: " />
						<Label label={note} />
					</div>
				}
			</div>
			<div className="public-report__time-sheet__time-and-equipment">
				<div className="public-report__time-sheet__time-and-equipment__totals">
					<div className="public-report__time-sheet__time-and-equipment__totals__start-finish">
						<div><LockedValue label="Start" value={startTime ?? 'N/A'} /></div>
						<div><LockedValue label="-" value="-" /></div>
						<div><LockedValue label="Finish" value={endTime ?? 'N/A'} /></div>
					</div>
					<div className="public-report__time-sheet__time-and-equipment__totals__total">{TimeUtils.minutesToHoursAndMinutes(totalDuration)}</div>
				</div>
				<div className={`public-report__time-sheet__time-and-equipment__totals-breakdown ${!timeSplitEntries?.length ? 'public-report__time-sheet__time-and-equipment__totals-breakdown--empty' : ''}`}>
					<div className="public-report__time-sheet__time-and-equipment__totals-breakdown__item">Job: <b>{TimeUtils.minutesToHoursAndMinutes(totalJobDuration)}</b></div>
					<div className="public-report__time-sheet__time-and-equipment__totals-breakdown__item">Shop: <b>{TimeUtils.minutesToHoursAndMinutes(totalShopDuration)}</b></div>
					<div className="public-report__time-sheet__time-and-equipment__totals-breakdown__item">Travel: <b>{TimeUtils.minutesToHoursAndMinutes(totalTravelDuration)}</b></div>
					<div className="public-report__time-sheet__time-and-equipment__totals-breakdown__item">Break: <b>{TimeUtils.minutesToHoursAndMinutes(totalBreakDuration)}</b></div>
				</div>
				{!!timeSplitEntries?.length &&
					<div className="public-report__time-sheet__time-and-equipment__equipment">
						<div className="public-report__time-sheet__time-and-equipment__equipment__item">
							<div className="public-report__time-sheet__time-and-equipment__equipment__item__time">Time</div>
							<div className="public-report__time-sheet__time-and-equipment__equipment__item__equipment">Equipment</div>
							<div className="public-report__time-sheet__time-and-equipment__equipment__item__classification-code">Classification Code</div>
						</div>
						{timeSplitEntries?.map((_tse) => (
							<EquipmentItem
								classificationCode={_tse.classificationCode}
								equipmentCategoryColor={_tse.equipmentCategoryColor}
								equipmentCode={_tse.equipmentCode}
								equipmentSpecification={_tse.equipmentSpecification}
								key={`${_tse.equipmentCategoryColor}#${_tse.classificationCode}#${_tse.time}`}
								time={_tse.time}
							/>
						))}
					</div>
				}
			</div>
		</div>
	);
};

export default React.memo(TimeSheet);
