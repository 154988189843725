import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import LockedValue from './LockedValue';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	jobCode: Nullable<string>;
	startDate: Nullable<string>;
	endDate: Nullable<string>;
	deliveryTimeline: Nullable<string>;
	deliveryMethod: Nullable<string>;
	workOrderCode?: string;
	dueDate?: string;
	submissionCode?: string;
}

type Props = OwnProps;

class Details extends React.PureComponent<Props> {
	render() {
		const {
			jobCode,
			workOrderCode,
			deliveryTimeline,
			startDate,
			endDate,
			dueDate,
			deliveryMethod,
			submissionCode,
		} = this.props;

		return (
			<div className={bemElement('deliverable-form', 'details')}>
				{submissionCode && (
					<Row className="row--padded">
						<Col sm={8}><LockedValue defaultValue="N/A" label="Submission" value={submissionCode} /></Col>
					</Row>
				)}
				<Row className="row--padded">
					<Col sm={8}><LockedValue defaultValue="N/A" label="Delivery Timeline" value={deliveryTimeline} /></Col>
					<Col sm={8}><LockedValue defaultValue="N/A" label="Delivery method" value={deliveryMethod} /></Col>
				</Row>
				{workOrderCode && (
					<Row className="row--padded">
						<Col sm={8}><LockedValue defaultValue="N/A" label="Work Order Code" value={workOrderCode} /></Col>
						<Col sm={8}><LockedValue defaultValue="N/A" label="Date of work" value={dueDate} /></Col>
					</Row>
				)}
				<Row className="row--padded">
					<Col sm={8}><LockedValue defaultValue="N/A" label="Job Code" value={jobCode} /></Col>
					<Col sm={8}><LockedValue defaultValue="N/A" label="Start Date" value={startDate} /></Col>
					<Col sm={8}><LockedValue defaultValue="N/A" label="End Date" value={endDate} /></Col>
				</Row>
			</div>
		);
	}
}

export default Details;
