import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { DispatchActionsMapped } from 'react-redux';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import DisplayViewToggle from '@acceligentllc/shared/enums/displayViewToggle';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';
import * as CompanyActions from 'af-actions/companies';
import * as GeneralActions from 'af-actions/general';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { isAllowed } from 'ab-utils/auth.util';

import TimeInterval from 'af-utils/interval.util';
import socket from 'af-utils/socket.util';
import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

import { DISPLAY_VIEW_BOARD_CONTEXT, DISPLAY_VIEW_TOOLBAR_CONTEXT } from 'af-constants/values';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import SocketEvent from 'ab-enums/socketEvent.enum';

import Loading from 'af-root/scenes/Company/ScheduleBoard/DailyView/Loading';

import Board from './Board';
import Toolbar from './Toolbar';
import TimerIndicator from './TimerIndicator';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	company: Nullable<CompanyViewModel>;
	isLoading: boolean;
	isAllowedToViewProdData: boolean;
	shouldLoadResources: boolean;
}

interface DispatchProps {
	scheduleBoardActions: typeof ScheduleBoardActions;
	generalActions: typeof GeneralActions;
	companyActions: typeof CompanyActions;
}

type Props = OwnProps & StateProps & DispatchActionsMapped<DispatchProps>;

interface State {
	dueDate: string;
	context: string;
	contextTimer: number | undefined;
}

class DisplayView extends React.PureComponent<Props, State> {
	state: State = {
		dueDate: DisplayView.getDueDate(this.props.company, this.props.location.search),
		context: DisplayView.getContext(this.props.company),
		contextTimer: this.props.company?.displayViewRotationTime,
	};

	rotationTimer: TimeInterval;

	constructor(props: Props) {
		super(props);

		this.rotationTimer = new TimeInterval(this.toggleContext);
	}

	static isOffset = (search: string): boolean => {
		const offset = search?.split('?offset=')?.[1];
		if (offset === 'true') {
			return true;
		}
		return false;
	};

	static getTimeDiff = (company: CompanyViewModel): number => {
		const { displayViewChangeTime } = company;
		const currentDate = new Date();

		if (!displayViewChangeTime) {
			return -1;
		}

		const currentTime = currentDate.getHours() * 60 * 60 + currentDate.getMinutes() * 60 + currentDate.getSeconds();
		const changeTime = displayViewChangeTime * 60;

		return currentTime - changeTime;
	};

	static getDueDate = (company: Nullable<CompanyViewModel>, search: string): string => {
		const { includeNonWorkingDaysOnDisplayView, workDays = [] } = company ?? {};

		const isOffset = DisplayView.isOffset(search);
		const timeDiff = company ? DisplayView.getTimeDiff(company) : 0;

		const displayViewDate = isOffset ? TimeUtils.getTomorrowsDate() : TimeUtils.getTodaysDate();

		if (timeDiff < 0) {
			// show base date
			if (isOffset && !includeNonWorkingDaysOnDisplayView) {
				// show next day
				return TimeUtils.getNextWorkingDayDate(workDays);
			}
			return displayViewDate;
		}
		if (includeNonWorkingDaysOnDisplayView) {
			// show next day
			return TimeUtils.getNextDayDate(displayViewDate);
		}

		if (isOffset) {
			// show second working day
			const nextWorkingDay = TimeUtils.getNextWorkingDayDate(workDays);
			return TimeUtils.getNextWorkingDayDateForDate(nextWorkingDay, workDays);
		}
		// show first working day
		return TimeUtils.getNextWorkingDayDateForDate(displayViewDate, workDays);
	};

	static getContext = (company: Nullable<CompanyViewModel>): string => {
		if (company?.displayViewType === DisplayViewToggle.TOOLBAR) {
			return DISPLAY_VIEW_TOOLBAR_CONTEXT;
		}
		return DISPLAY_VIEW_BOARD_CONTEXT;
	};

	async componentDidMount() {
		const { scheduleBoardActions, generalActions, companyActions, shouldLoadResources } = this.props;
		const { dueDate } = this.state;

		await companyActions.getCompany();

		socket.connection?.subscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD, () => {
			ScheduleBoardUtil.activateDailyView(this.state.dueDate, {});
		});

		socket.connection?.subscribe(SocketEvent.V2.BE.COMPANY.UPDATE_SETTINGS, async () => {
			await companyActions.getCompany();
		});

		ScheduleBoardUtil.subscribeDailySocketEvents(generalActions, scheduleBoardActions);

		if (shouldLoadResources) {
			ScheduleBoardUtil.loadResources();
		}
		this.joinDailyView(dueDate);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { company, location: { search } } = this.props;
		const { dueDate } = this.state;

		if (location.search !== prevProps.location.search) {
			this.setState(() => ({ dueDate: DisplayView.getDueDate(company, search) }));
		}

		if (ScheduleBoardUtil.shouldLoadResourcesOnUpdate(prevProps, this.props)) {
			ScheduleBoardUtil.loadResources();
		}

		if (company && prevProps.company?.displayViewType !== company?.displayViewType) {
			this.setState(() => ({ context: DisplayView.getContext(company) }));
		}

		if (prevState.dueDate !== dueDate) {
			this.joinDailyView(dueDate);
		}
	}

	joinDailyView = (date: string) => {
		const { scheduleBoardActions } = this.props;
		const loadedDates = scheduleBoardActions.getLoadedDates();

		scheduleBoardActions.updateDisplayViewDate(date);
		ScheduleBoardUtil.joinDailyView(date, loadedDates);
	};

	componentWillUnmount() {
		this.rotationTimer?.cancel();
		socket.connection?.unsubscribe(SocketEvent.V2.BE.COMPANY.UPDATE_SETTINGS);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.SCHEDULE_BOARD.RELOAD_BOARD);
	}

	toggleContext = () => {
		const { company, location } = this.props;
		if (!company) {
			throw new Error('Company not loaded');
		}

		const { displayViewType } = company;

		// update due date if either switch time or midnight passed
		const dueDate = DisplayView.getDueDate(company, location.search);
		if (displayViewType !== DisplayViewToggle.TOGGLE) {
			this.setState(() => ({ dueDate }));
			return;
		}
		this.setState((state: State) => {
			if ((state.contextTimer ?? 0) > 0) {
				return {
					dueDate,
					contextTimer: state.contextTimer! - 1,
					context: state.context,
				};
			}
			return {
				dueDate,
				contextTimer: company.displayViewRotationTime,
				context: state.context === DISPLAY_VIEW_BOARD_CONTEXT ? DISPLAY_VIEW_TOOLBAR_CONTEXT : DISPLAY_VIEW_BOARD_CONTEXT,
			};
		});
	};

	render() {
		const { isLoading, isAllowedToViewProdData, company } = this.props;
		const { dueDate, context, contextTimer } = this.state;

		if (isLoading || !company) {
			return (
				<div className="schedule-board zoom-0">
					<div className="schedule-board-container">
						<Loading zoomLevel={0} />
					</div>
				</div>
			);
		}

		const { notification, displayViewType, displayViewRotationTime } = company;
		const showIndicator = displayViewType === DisplayViewToggle.TOGGLE;

		return (
			<div className="display-view">
				{context === DISPLAY_VIEW_BOARD_CONTEXT
					? <Board dueDate={dueDate} notificationsEnabled={notification?.isEnabled} showOfficeInfo={isAllowedToViewProdData} />
					: <Toolbar dueDate={dueDate} showOfficeInfo={isAllowedToViewProdData} />
				}
				{showIndicator && <TimerIndicator secondsLeft={contextTimer ?? 0} secondsTotal={displayViewRotationTime} />}
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const dueDate = state.scheduleBoard.displayViewDate;

	const isLoading = !state.scheduleBoard.workOrdersByDateDictionary[dueDate]?.workOrders;
	const isAllowedToViewProdData = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		company,
		isLoading,
		isAllowedToViewProdData,
		shouldLoadResources: !state.scheduleBoard.resourcesLoaded,
	};
}

type ActionType = GeneralActions.GeneralAction | CompanyActions.ActionType | ScheduleBoardActions.ScheduleBoardAction;
function mapDispatchToProps(dispatch: Dispatch<ActionType>): DispatchProps {
	return {
		companyActions: bindActionCreators(CompanyActions, dispatch),
		generalActions: bindActionCreators(GeneralActions, dispatch),
		scheduleBoardActions: bindActionCreators(ScheduleBoardActions, dispatch),
	};
}

export default connect<StateProps, void, OwnProps>(mapStateToProps, mapDispatchToProps)(DisplayView);
