import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import { Droppable } from 'react-beautiful-dnd';

import { bemElement } from 'ab-utils/bem.util';

import { DroppableZones } from 'ab-enums/reportTypeBuilder.enum';

import Tooltip from 'af-components/Tooltip';

import { generateCalculatedBlockDroppableId } from 'af-utils/reportTypeBuilder.util';

import FieldInfoDraggable from '../../../Shared/Draggable/DraggableField';

import type { ReportBlockFormModel, ReportBlockFieldFormModel } from '../../../Shared/formModel';

const DEFAULT_TOTAL_BLOCK_NAME = 'Report Total';

interface SharedOwnProps {
	block: Nullable<ReportBlockFormModel>;
	isPrimary: boolean;
	editBlock: (reportTypeBlockId: string) => void;
	openFieldModal: (isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>, fieldId: Nullable<string>) => void;
	removeField: (blockId: string, fieldId: string) => void;
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
	reportTypeBlockId: Nullable<string>;
	upsertField: (form: ReportBlockFieldFormModel, isPrimary: boolean, isTotalBlock: boolean, reportTypeBlockId: Nullable<string>) => void;
}

interface TotalBlockProps {
	isTotalBlock: true;
}

interface DraggableBlockProps {
	removeBlock: (id: string) => void;
	draggingElementSource: Nullable<string>;
	index: number;
	isTotalBlock: false;
}

type Props = SharedOwnProps & (TotalBlockProps | DraggableBlockProps);

const CalculatedBlock: React.FC<Props> = (props: Props) => {
	const { isTotalBlock, reportFieldsByIdMap, block, isPrimary, editBlock, openFieldModal, removeField, upsertField, reportTypeBlockId } = props;

	const onRemoveBlock = React.useCallback(() => {
		const { removeBlock } = props as DraggableBlockProps;
		if (!reportTypeBlockId) {
			throw Error('Missing reportType block id');
		}
		removeBlock(reportTypeBlockId);
	}, [props, reportTypeBlockId]);

	const onEditBlock = React.useCallback(() => {
		if (!reportTypeBlockId) {
			throw Error('Missing reportType block id');
		}
		editBlock(reportTypeBlockId);
	}, [editBlock, reportTypeBlockId]);

	const onAddField = React.useCallback(() => {
		openFieldModal(isPrimary, isTotalBlock, reportTypeBlockId, null);
	}, [openFieldModal, reportTypeBlockId, isPrimary, isTotalBlock]);

	const onEditField = React.useCallback((id: string) => {
		openFieldModal(isPrimary, isTotalBlock, reportTypeBlockId, id);
	}, [openFieldModal, reportTypeBlockId, isPrimary, isTotalBlock]);

	const onRemoveField = React.useCallback((fieldId: string) => {
		if (!reportTypeBlockId) {
			throw Error('Missing reportType block id');
		}
		removeField(reportTypeBlockId, fieldId);
	}, [reportTypeBlockId, removeField]);

	const toggleFieldVisibility = React.useCallback((id: string) => {
		if (!reportTypeBlockId) {
			throw Error('Missing reportType block id');
		}
		const field = reportFieldsByIdMap[id];
		const newField = { ...field, isVisibleToCustomer: !field.isVisibleToCustomer };
		upsertField(newField, isPrimary, isTotalBlock, reportTypeBlockId);
	}, [upsertField, reportTypeBlockId, isPrimary, isTotalBlock, reportFieldsByIdMap]);

	const renderDraggable = (fieldId: string, index: number) => {
		const field = reportFieldsByIdMap[fieldId];

		return (
			<FieldInfoDraggable
				fieldId={field.virtualId}
				fieldIsVisibleToCustomer={field.isVisibleToCustomer}
				fieldName={field.name}
				fieldType={field.fieldType}
				hasTooltip={field.hasTooltip}
				index={index}
				key={field.virtualId ?? `${field.name}@${index}`}
				onFieldClick={onEditField}
				onFieldVisibleToCustomerIconClick={toggleFieldVisibility}
				operation={field.operationType}
				options={field.options}
				removeField={onRemoveField}
				tooltipText={field.tooltipText}
				unit={field.unit}
			/>
		);
	};

	const renderList = (isDraggingOver: boolean) => {
		const listLength = block?.reportBlockFieldIds?.length ?? 0;

		// 32px for a field + 16px for margin
		// 2px to mitigate the border appearing and disappearing
		const listHeight = ((listLength + 1) * 48) + (isDraggingOver ? 2 : 0);

		const containerClassName = bemElement(
			'report-block-form',
			'droppable-container',
			{ empty: !listLength, hover: isDraggingOver && !!listLength }
		);

		return (
			<div
				className={containerClassName}
				style={{ height: listHeight }}
			>
				{!!listLength && block?.reportBlockFieldIds?.map(renderDraggable)}
			</div>
		);
	};

	const renderDroppable = (droppableId: string, isDisabled: boolean) => {
		const shouldDisplayList = !!block?.reportBlockFieldIds?.length;

		return (
			<>
				<div className="report-block-form__title">
					Fields
				</div>
				{shouldDisplayList &&
					<Droppable
						direction="vertical"
						droppableId={droppableId}
						isDropDisabled={isDisabled}
					>
						{(droppableProvided, snapshot) => (
							<div ref={droppableProvided.innerRef}>
								{renderList(snapshot.isDraggingOver)}
								{droppableProvided.placeholder}
							</div>
						)}
					</Droppable>
				}
				<div className="report-block-form__add-button-container">
					<Button
						icon="plus"
						label="Add Calculated Field"
						onClick={onAddField}
						style="link"
					/>
				</div>
			</>
		);
	};

	const renderTotalBlock = () => {
		const droppableId = isPrimary
			? DroppableZones.UPPER_TOTAL_BLOCK_LIST_ID
			: DroppableZones.LOWER_TOTAL_BLOCK_LIST_ID;

		const headerClassName = bemElement('report-block-form', 'fields-header', { regular: true, primary: true });

		const displayName = block?.name ?? DEFAULT_TOTAL_BLOCK_NAME;

		return (
			<div className="form-box">
				<div className={headerClassName}>
					<div>
						<div className="report-block-form__header-title">{displayName}</div>
					</div>
					{block?.name &&
						<Button
							context={droppableId}
							icon="edit"
							onClick={onEditBlock}
							style="link"
						/>
					}
				</div>
				<div className="report-block-form">
					{renderDroppable(droppableId, false)}
				</div>
			</div>
		);
	};

	const renderDraggableBlock = () => {
		const { draggingElementSource, index } = props as DraggableBlockProps;

		const labelOuterClassName = bemElement('report-block-form', 'draggable-label', { 'non-padded': true, 'calculated-block': true, expandable: true });
		const labelInnerClassName = bemElement('report-block-form', 'draggable-label', { stretched: true });

		const droppableId = generateCalculatedBlockDroppableId(isPrimary, index);
		const isDisabled = draggingElementSource !== droppableId;
		return (
			<>
				<span className="icon-drag_indicator report-block-form__draggable-indicator" />
				<div className={labelOuterClassName}>
					<div className={labelInnerClassName}>
						<div>{block?.name}</div>
						<span className="icon-edit" onClick={onEditBlock} />
					</div>
					<div className="report-block-form--calculated-draggable-block">
						{renderDroppable(droppableId, isDisabled)}
					</div>
				</div>
				<Tooltip message="Delete">
					<span className="icon-delete report-block-form__remove-icon" onClick={onRemoveBlock} />
				</Tooltip>
			</>
		);
	};

	return isTotalBlock
		? renderTotalBlock()
		: renderDraggableBlock();
};

export default React.memo(CalculatedBlock);
