import * as React from 'react';

import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';

import LockedValue from 'af-components/LockedValue';

import type { TimeSplitEntryVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimeSheetTrackedEntryVM } from 'ab-viewModels/timeSheet/trackedEntries.viewModel';

import WorkTypeLabel from './WorkTypeLabel';

import WorkTypeValue from './WorkTypeValue';

import styles from './styles.module.scss';

interface OwnProps {
	timeSplitEntries: TimeSplitEntryVM[];
	breakDuration: number;
	jobDuration: number;
	shopDuration: number;
	travelDuration: number;
	showEquipmentTimeError: boolean;
	trackedEntry: Nullable<TimeSheetTrackedEntryVM>;
}

const TimeSheetDurations: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		timeSplitEntries,
		breakDuration: totalBreakDuration,
		jobDuration: totalJobDuration,
		shopDuration: totalShopDuration,
		travelDuration: totalTravelDuration,
		showEquipmentTimeError,
		trackedEntry,
	} = props;

	const hasTimeSplitEntries = !!timeSplitEntries && timeSplitEntries.length > 0 && !showEquipmentTimeError;

	const jobRowTimeSplitsStyle = hasTimeSplitEntries ? ` ${styles['time__total--greyed']}` : '';
	const jobRowEquipmentTimeErrorStyle = showEquipmentTimeError ? ` ${styles['time__total--error']}` : '';
	const jobTimeStyle = `${styles.time__total}${jobRowTimeSplitsStyle}${jobRowEquipmentTimeErrorStyle}`;

	const isActiveEntryJobType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.JOB);
	const isActiveEntryShopType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.SHOP);
	const isActiveEntryTravelType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.TRAVEL);
	const isActiveEntryBreakType = !!trackedEntry && (trackedEntry?.workType === TimeSheetEntryWorkType.BREAK);

	return (
		<div className={styles['time__total-split-time-container']}>
			<div className={jobTimeStyle}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="equipment"
							isActiveEntry={isActiveEntryJobType}
							label="Job"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryJobType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalJobDuration}
						/>
					}
				/>
			</div>
			<div className={styles.time__total}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="home"
							isActiveEntry={isActiveEntryShopType}
							label="Shop"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryShopType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalShopDuration}
						/>
					}
				/>
			</div>
			<div className={styles.time__total}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="travel"
							isActiveEntry={isActiveEntryTravelType}
							label="Travel"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryTravelType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalTravelDuration}
						/>
					}
				/>
			</div>
			<div className={styles.time__total}>
				<LockedValue
					label={
						<WorkTypeLabel
							icon="break"
							isActiveEntry={isActiveEntryBreakType}
							label="Break"
						/>
					}
					value={
						<WorkTypeValue
							isActiveEntry={isActiveEntryBreakType}
							startTime={trackedEntry?.startTime ?? null}
							totalWorkTypeDuration={totalBreakDuration}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default TimeSheetDurations;
