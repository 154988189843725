import * as React from 'react';

import type { SignatureFieldVM } from '@acceligentllc/shared/utils/fieldReport';

import SignatureStatus from './SignatureStatus';

interface OwnProps {
	value: Nullable<SignatureFieldVM>;
	name: string;
}

type Props = OwnProps;

const Preview: React.FC<Props> = (props) => {
	const { value, name } = props;

	return (
		<SignatureStatus
			name={name}
			value={value}
		/>
	);
};

export default React.memo(Preview);
