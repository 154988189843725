import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import Stopwatch from '../../../../Shared/Stopwatch';

interface Props {
	isActiveEntry: boolean;
	startTime: Nullable<string>;
	totalWorkTypeDuration: number;
}

const WorkTypeValue: React.FC<Props> = (props: Props) => {

	const { isActiveEntry, startTime, totalWorkTypeDuration } = props;

	if (isActiveEntry && startTime) {
		return <Stopwatch addedMinutes={totalWorkTypeDuration} startTime={startTime} />;
	}

	return (
		<span>{TimeUtils.minutesToHoursAndMinutes(totalWorkTypeDuration)}</span>
	);
};

export default WorkTypeValue;
