import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type SubscriptionStatus from '@acceligentllc/shared/enums/subscriptionStatus';

import * as BlobStorageUtil from '@acceligentllc/shared/utils/blobStorage';
import { getUserName } from '@acceligentllc/shared/utils/user';

import { FAKE_ACCOUNT_DATA } from '@acceligentllc/shared/constants/value';

import type SkillBase from 'ab-domain/models/skill/base';
import type WageRateBase from 'ab-domain/models/wageRate/base';
import type LocationBase from 'ab-domain/models/location/base';
import type AccountBase from 'ab-domain/models/account/base';
import type UserBase from 'ab-domain/models/user/base';
import type EmployeeBase from 'ab-domain/models/employee/base';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import * as UserUtils from 'ab-utils/user.util';

export class W_Employee_FindDeactivated_VM extends TableContent<W_Employee_FindDeactivated_VM_Row>{
	constructor(employees: EmployeeBase[], pages: number, totalCount: number) {
		super(
			W_Employee_FindDeactivated_VM_Row.bulkConstructor(employees),
			pages,
			totalCount
		);
	}
}

class W_Employee_FindDeactivated_VM_Row {
	id: number;
	code: string;
	fullName: string;
	wageRateId: number;
	wageRate: W_Employee_FindDeactivated_VM_WageRateVM;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	accountTemplate: AccountPermissionTemplate;
	email: Nullable<string>;
	updatedById: number;
	updatedBy: Nullable<W_Employee_FindDeactivated_VM_UpdatedByAccountVM>;
	updatedAt: Date;
	company: string;
	skills: W_Employee_FindDeactivated_VM_SkillVM[];
	location: Nullable<W_Employee_FindDeactivated_VM_LocationVM>;
	subscriptionStatus: SubscriptionStatus;
	showOnScheduleBoard: boolean;
	imageUrl?: string;

	constructor(employee: EmployeeBase) {
		const user = employee.account.user;

		this.id = employee.id;
		this.code = user.uniqueId;
		this.fullName = UserUtils.getFormattedFullName(user.firstName, user.lastName);
		this.wageRateId = employee.wageRateId;
		this.wageRate = new W_Employee_FindDeactivated_VM_WageRateVM(employee.wageRate);
		this.phoneNumber = user.phoneNumber;
		this.countryCode = user.countryCode;
		this.accountTemplate = employee.account.accountTemplate;
		this.email = user.email;
		this.updatedAt = employee.updatedAt;
		this.company = employee.company.name;
		this.updatedBy = employee.updatedBy ? new W_Employee_FindDeactivated_VM_UpdatedByAccountVM(employee.updatedBy) : null;
		this.skills = W_Employee_FindDeactivated_VM_SkillVM.bulkConstructor(employee.skills ?? []);
		this.location = employee.account.location ? new W_Employee_FindDeactivated_VM_LocationVM(employee.account.location) : null;
		this.showOnScheduleBoard = employee.showOnScheduleBoard;
		this.subscriptionStatus = user.subscriptionStatus;
		this.imageUrl = user.imageUrl ? BlobStorageUtil.tryGetStorageUrlForSize(user.imageUrl, BlobStorageImageSizeContainer.SIZE_50X50, true) : undefined;
	}

	static bulkConstructor(employees: EmployeeBase[] = []): W_Employee_FindDeactivated_VM_Row[] {
		return employees.map(W_Employee_FindDeactivated_VM_Row._constructorMap);
	}

	private static _constructorMap(employee: EmployeeBase) {
		return new W_Employee_FindDeactivated_VM_Row(employee);
	}
}

class W_Employee_FindDeactivated_VM_UpdatedByAccountVM {
	accountId: number;
	userId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	fullNameShort: string;

	constructor(account: Nullable<AccountBase>) {
		const accountObject = account ?? { id: FAKE_ACCOUNT_DATA.ACCOUNT_ID } as unknown as AccountBase;
		const accountUser = accountObject.user || { id: accountObject.userId || FAKE_ACCOUNT_DATA.USER_ID } as UserBase;

		this.accountId = accountObject.id;
		this.userId = accountObject.userId;
		this.firstName = accountUser.firstName;
		this.lastName = accountUser.lastName;
		this.fullName = getUserName(accountUser);
		this.fullNameShort = getUserName(accountUser, true);
	}
}

class W_Employee_FindDeactivated_VM_WageRateVM {
	id: number;
	type: string;
	wageClassification: string;

	constructor(wageRate: WageRateBase) {
		this.id = wageRate.id;
		this.type = wageRate.type;
		this.wageClassification = wageRate.wageClassification;
	}

	static bulkConstructor(wageRates: WageRateBase[]) {
		return wageRates.map(W_Employee_FindDeactivated_VM_WageRateVM._constructorMap);
	}

	private static _constructorMap(wageRate: WageRateBase) {
		return new W_Employee_FindDeactivated_VM_WageRateVM(wageRate);
	}
}

class W_Employee_FindDeactivated_VM_SkillVM {
	id: number;
	name: string;
	color: ColorPalette;

	constructor(skill: SkillBase) {
		this.id = skill.id;
		this.name = skill.name;
		this.color = skill.color;
	}

	static bulkConstructor(skills: SkillBase[] = []): W_Employee_FindDeactivated_VM_SkillVM[] {
		return skills.map(W_Employee_FindDeactivated_VM_SkillVM._constructorMap);
	}

	private static _constructorMap(_skill: SkillBase) {
		return new W_Employee_FindDeactivated_VM_SkillVM(_skill);
	}
}

class W_Employee_FindDeactivated_VM_LocationVM {
	id: number;
	nickname: string;

	constructor(location: LocationBase) {
		this.id = location.id;
		this.nickname = location.nickname;
	}
}
