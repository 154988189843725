import * as React from 'react';

import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';

import type { AccountPermissionTemplateOptions } from 'ab-enums/accountPermissionTemplates.enum';

import Actions from './Actions';
import Invitation from './Invitation';
import Section from './Section';
import UserGroup from './UserGroup';
import MemberPermissions from './MemberPermissions';
import type FormModel from './formModel';
import LMSActivation from './LMSActivation';

interface OwnProps {
	invalidEmail: boolean;
	invalidPhoneNumber: boolean;
	isCompanyAdmin: boolean;
	permissions: FormModel['permissions'];
	submitting: boolean;
	invalid: boolean;
	showActivateImmediately: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsAccounting: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	accountTemplate: AccountPermissionTemplate;
	activeInLMS: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	change: (filedName: string, value: boolean | typeof AccountPermissionTemplateOptions[0]) => void;
}

type Props = React.PropsWithChildren<OwnProps>;

const MemberForm: React.FC<Props> = (props) => {
	const {
		children,
		invalidEmail,
		invalidPhoneNumber,
		isCompanyAdmin,
		permissions,
		submitting,
		invalid,
		showActivateImmediately,
		assignableToWorkOrder,
		assignableAsSuperintendent,
		assignableAsAccounting,
		assignableAsManagement,
		assignableAsTechnician,
		accountTemplate,
		activeInLMS,
		onCancel,
		onSubmit,
		change,
	} = props;

	return (
		<div>
			<Section label="User Information">
				{children}
			</Section>
			<Section label="Activation Options">
				{showActivateImmediately &&
					<Invitation
						invalidEmail={invalidEmail}
						invalidPhoneNumber={invalidPhoneNumber}
					/>
				}
				<LMSActivation isActivated={activeInLMS} />
			</Section>
			<Section label="User Groups">
				<UserGroup />
			</Section>
			<Section label="Permissions">
				<MemberPermissions
					accountTemplate={accountTemplate}
					assignableAsAccounting={assignableAsAccounting}
					assignableAsManagement={assignableAsManagement}
					assignableAsSuperintendent={assignableAsSuperintendent}
					assignableAsTechnician={assignableAsTechnician}
					assignableToWorkOrder={assignableToWorkOrder}
					change={change}
					isCompanyAdmin={isCompanyAdmin}
					permissions={permissions}
				/>
				<Actions
					invalid={invalid}
					onCancel={onCancel}
					onSubmit={onSubmit}
					submitting={submitting}
				/>
			</Section>
		</div>
	);
};

export default MemberForm;
