import * as React from 'react';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import { getRevisionBackgroundColorClass } from 'ab-utils/scheduleBoard.util';

interface OwnProps {
	status: WorkOrderStatus;
	className?: string;
	firstRevision?: boolean;
	notificationStatus?: Nullable<NotificationStatusEnum>;
	notificationsEnabled?: boolean;
	onClick?: () => void;
	isSmall?: boolean;
	isPaused: boolean;
}

type Props = OwnProps;

class StatusIcon extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		className: '',
		firstRevision: true,
		notificationsEnabled: true,
		isSmall: false,
	};

	onClick = () => {
		const { onClick } = this.props;
		if (onClick) {
			onClick();
		}
	};

	render() {
		const {
			status,
			className,
			firstRevision,
			notificationStatus,
			notificationsEnabled,
			isSmall,
			onClick,
			isPaused,
		} = this.props;

		let baseClassName = 'status-icon';
		baseClassName = isSmall ? `${baseClassName} status-icon--small` : baseClassName;
		baseClassName = onClick ? `${baseClassName} status-icon--clickable` : baseClassName;

		if (isPaused) {
			return (
				<div className={`${baseClassName} bg-darkGrey ${className} accqa__wo-status-draft`} onClick={this.onClick}>
					<span className="icon-pause text-white" />
				</div>
			);
		}

		switch (status) {
			case WorkOrderStatus.DRAFT:
				return (
					<div className={`${baseClassName} bg-grey ${className} accqa__wo-status-draft`} onClick={this.onClick}>
						<span className="icon-draft text-grey" />
					</div>
				);
			case WorkOrderStatus.PUBLISHED:
				if (!notificationsEnabled) {
					return (
						<div className={`${baseClassName} bg-green ${className} accqa__wo-status-notified`} onClick={this.onClick}>
							<span className="icon-check text-white" />
						</div>
					);
				}

				if (!notificationStatus || notificationStatus === NotificationStatusEnum.DELIVERED) {
					return (
						<div
							className={`${baseClassName} ${className} ${getRevisionBackgroundColorClass(!!firstRevision)} ${firstRevision ? 'accqa__wo-status-published' : 'accqa__wo-status-republished'}`}
							onClick={this.onClick}
						>
							<span className="icon-notifications text-white" />
						</div>
					);

				}

				if (notificationStatus && notificationStatus === NotificationStatusEnum.ERROR) {
					return (
						<div className={`${baseClassName} bg-red ${className} accqa__wo-status-error`} onClick={this.onClick}>
							<span className="icon-warning text-white" />
						</div>
					);
				}

				return (
					<div className={`${baseClassName} bg-green ${className} accqa__wo-status-notified`} onClick={this.onClick}>
						<span className="icon-check text-white bold" />
					</div>
				);
			case WorkOrderStatus.OUTDATED:
				return (
					<div className={`${baseClassName} bg-red ${className} accqa__wo-status-outdated`} onClick={this.onClick}>
						<span className="icon-clock text-white" />
					</div>
				);
			case WorkOrderStatus.CANCELED:
				return (
					<div className={`${baseClassName} bg-white ${className} accqa__wo-status-canceled`} onClick={this.onClick}>
						<span className="icon-close text-red bold" />
					</div>
				);
			case WorkOrderStatus.LOCKED:
				return (
					<div className={`${baseClassName} bg-blue ${className} accqa__wo-status-locked`} onClick={this.onClick}>
						<span className="icon-lock text-white" />
					</div>
				);
			default:
				return null;
		}
	}
}

export default StatusIcon;
