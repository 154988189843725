import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import Textarea from 'af-fields/Textarea';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import LoadingIndicator from 'af-components/LoadingIndicator';

import * as TimeSheetActions from 'af-actions/timeSheet';

import * as FORMS from 'af-constants/reduxForms';

interface TimeSheetNoteForm {
	note: string;
}

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	accountId: number;
	workOrderId: number;
}

type Props = OwnProps & InjectedFormProps<TimeSheetNoteForm> & ConnectedProps<typeof connector>;

interface State {
	loading: boolean;
	submitting: boolean;
}

class TimeSheetNoteModal extends React.PureComponent<Props, State> {
	state: State = {
		loading: true,
		submitting: false,
	};

	async componentDidUpdate(prevProps: Props) {
		const { showModal } = this.props;
		if (!prevProps.showModal && showModal) {
			await this.fetchData();
		}
	}

	fetchData = async () => {
		const { workOrderId, accountId, initialize, findWorkOrderTimeSheetNoteForAccount } = this.props;
		this.setState(() => ({ loading: true }), async () => {
			const { note } = await findWorkOrderTimeSheetNoteForAccount(workOrderId, accountId);
			initialize({ note: note ?? '' });
			this.setState({ loading: false });
		});
	};

	onSave = async (form: TimeSheetNoteForm) => {
		const { workOrderId, accountId, closeModal, updateWorkOrderTimeSheetNoteForAccount } = this.props;
		const { note } = form;

		this.setState(() => ({ submitting: true }), async () => {
			await updateWorkOrderTimeSheetNoteForAccount(workOrderId, accountId, { note });
			this.setState({ submitting: false });
			closeModal();
		});
	};

	render() {
		const { showModal, closeModal, handleSubmit, dirty } = this.props;
		const { loading, submitting } = this.state;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Edit Time Card Note"
				/>
				<CustomModal.Body>
					{loading ?
						<div className="time-sheet-list__note-modal-loading">
							<LoadingIndicator color="orange" />
						</div>
						:
						<Field
							component={Textarea}
							label="Time Card Note"
							maxCharacters={300}
							name="note"
							placeholder="Write a note"
							rows={6}
							showMaxCharactersLabel={true}
						/>
					}
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={closeModal}
						style="secondary"
					/>
					<SubmitButton
						disabled={!dirty}
						label="Save"
						onClick={handleSubmit(this.onSave)}
						reduxFormSubmitting={submitting}
						variant="primary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

const mapDispatchToProps = () => {
	return {
		findWorkOrderTimeSheetNoteForAccount: TimeSheetActions.findWorkOrderTimeSheetNoteForAccount,
		updateWorkOrderTimeSheetNoteForAccount: TimeSheetActions.updateWorkOrderTimeSheetNoteForAccount,
	};
};

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<TimeSheetNoteForm>({ form: FORMS.TIME_SHEET_NOTE })
);

export default enhance(TimeSheetNoteModal);
