import FormFieldArrayStatus from '@acceligentllc/shared/enums/formFieldArrayStatus';

import type AccessRM from 'ab-requestModels/fieldReport/fieldReportAccess.requestModel';

const isValidAccess = (value: AccessRM['accessList'][0]) => {
	if (value.status !== FormFieldArrayStatus.ADDED) {
		return true;
	}
	return !!value.employee?.accountId;
};

export const validate = (values: AccessRM) => {
	const errors: ValidationErrors = { accessList: [] };

	values.accessList?.forEach((_value, _index) => {
		if (!isValidAccess(_value)) {
			errors.accessList[_index] = { employee: 'Supervisor is required.' };
		}
	});

	return errors.accessList.length === 0 ? {} : errors;
};
