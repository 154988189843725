import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';

import * as CompanyActions from 'af-actions/companies';

import type { CompanyWorkDaysForm } from 'ab-requestModels/company.requestModel';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { COMPANY_WORK_DAYS_EDIT } from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import EditCompanyWorkDaysForm from './WorkDaysForm';

type FormProps = InjectedFormProps<CompanyWorkDaysForm, FormOwnProps>;

interface CompanySettingsRouteProps {
	orgAlias: string;
}
interface OwnProps extends CustomRouteComponentProps<CompanySettingsRouteProps> {
	isVisible: boolean;
	company: CompanyViewModel;
	toggleEdit: () => void;
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editCompanyWorkDays: typeof CompanyActions.editCompanyWorkDays;
}

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

class CompanyEdit extends React.PureComponent<Props> {

	editCompanyWorkDays = async (form: CompanyWorkDaysForm) => {
		const { toggleEdit, company, editCompanyWorkDays } = this.props;

		form.id = company.id;
		await editCompanyWorkDays(form);

		toggleEdit();
	};

	onClose = () => {
		const { toggleEdit, reset } = this.props;
		reset();
		toggleEdit();
	};

	render() {
		const { handleSubmit, invalid, submitting, isVisible } = this.props;

		return (
			<CustomModal
				closeModal={this.onClose}
				modalStyle="info"
				showModal={isVisible}
				size="lg"
			>
				<CustomModal.Header
					closeModal={this.onClose}
					title="Edit Work Days"
				/>
				<CustomModal.Body padding="none">
					<EditCompanyWorkDaysForm
						{...this.props}
						formName={COMPANY_WORK_DAYS_EDIT}
						onSubmit={this.editCompanyWorkDays}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.onClose}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Save"
						onClick={handleSubmit(this.editCompanyWorkDays)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		editCompanyWorkDays: CompanyActions.editCompanyWorkDays,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<CompanyWorkDaysForm, FormOwnProps>({ form: COMPANY_WORK_DAYS_EDIT, enableReinitialize: true })
);

export default enhance(CompanyEdit);
