import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import styles from './styles.module.scss';

export const formSectionClass = styles['work-request-form__tab-content__form-section'];
export const formSectionTitleClass = styles['work-request-form__tab-content__form-section__title'];
export const formSectionClassRow = styles['work-request-form__tab-content__form-section__row'];
export const formSectionClassColumn1 = styles['work-request-form__tab-content__form-section__row__column-1'];
export const formSectionClassColumn2 = styles['work-request-form__tab-content__form-section__row__column-2'];
export const formSectionClassColumn4 = styles['work-request-form__tab-content__form-section__row__column-4'];
export const formSectionClassColumn6 = styles['work-request-form__tab-content__form-section__row__column-6'];
export const formSectionClassColumn8 = styles['work-request-form__tab-content__form-section__row__column-8'];
export const formSectionDropdownItem = styles['work-request-form__contact-dropdown-item'];
export const formSectionDropdownItemMain = styles['work-request-form__contact-dropdown-item__main'];
export const formSectionDropdownItemSecondary = styles['work-request-form__contact-dropdown-item__secondary'];

export interface ConvertToJobForm {
	jobCode: string;
	keepAttachments: boolean;
}

export const validateConvertToJobForm = (form: ConvertToJobForm): ValidationErrors => {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(form.jobCode)) {
		errors.jobCode = 'Job ID is Required.';
	}

	return errors;
};

export interface CopyWorkRequestForm {
	workRequestCode: string;
	keepAttachments: boolean;
}

export const validateCopyWorkRequestForm = (form: CopyWorkRequestForm): ValidationErrors => {
	const errors: ValidationErrors = {};

	if (!form.workRequestCode) {
		errors.workRequestCode = 'Work Request ID is Required.';
	}

	return errors;
};
