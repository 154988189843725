import * as React from 'react';
import type { History } from 'history';

import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';

import SegmentLabel from 'af-components/SegmentLabel';

import CLIENT from 'af-constants/routes/client';

import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';
import DisplayReviewStatusLabel from 'af-root/scenes/Company/FieldReports/Shared/DisplayReviewStatusLabel';

interface OwnProps {
	companyName: string;
	history: History;
	orgAlias: string;
	reviewStatus: WorkOrderReviewStatus;
	workOrderId: number;
	workOrderName: string;
	calculatedReportDisplayStatus: Nullable<CalculatedReportDisplayStatus>;
	isFRStatusOfInterest: boolean;
}

type Props = OwnProps;

class ReportsSection extends React.PureComponent<Props> {

	onClick = () => {
		const { history, workOrderId, orgAlias, companyName } = this.props;
		history.push(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName));
	};

	render() {
		const { workOrderName, calculatedReportDisplayStatus, isFRStatusOfInterest } = this.props;

		return (
			<div>
				<SegmentLabel label="Reports" />
				<div className="work-order-info-modal__status-label">
					<DisplayReviewStatusLabel
						calculatedReportDisplayStatus={calculatedReportDisplayStatus}
						isCondensedView={false}
						isOfInterest={isFRStatusOfInterest}
					/>
				</div>
				<div className="work-order-info-modal__report-link" onClick={this.onClick}>
					{`Field Reports for ${workOrderName}`}
				</div>
			</div>
		);
	}
}

export default ReportsSection;
