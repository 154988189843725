import { Button } from '@acceligentllc/storybook';
import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomLocationState } from 'react-router-dom';
import type { v6Props } from 'react-router-dom-v5-compat';
import { Outlet } from 'react-router-dom-v5-compat';
import { compose } from 'redux';
import { useLocation, useHistory } from 'react-router';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';
import WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';

import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import type JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import withRouterV6 from 'af-components/V6Wrapper';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import ResourceCell from 'af-components/Table6/Cells/ResourceCell';
import LabelWithColor from 'af-components/LabelWithColor';
import DateFilter from 'af-components/DateFilter';
import TabNavigation from 'af-components/TabNavigation';
import ProjectSubJobIndicator from 'af-components/ProjectSubJobIndicator';
import type { TableRef } from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';

import { downloadCSV } from 'af-utils/csv.utils';
import { setItemWithFormatter, setItem } from 'af-utils/settings.util';
import * as SettingsUtils from 'af-utils/settings.util';

import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import TableNameEnum from 'ab-enums/tableName.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';
import { isAllowed } from 'ab-utils/auth.util';

import Loading from './Loading';

import styles from './styles.module.scss';

import CopyModal from '../Shared/CopyModal';
import { JobPreviewTabRoutes, type JobPreviewOutletContext } from './types';
import { JobEditTabRoutes } from '../Form/Edit/types';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = v6Props<CustomLocationState> & SettingProps & ConnectedProps<typeof connector>;

function setWOStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

function setWOEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.LOCAL_STORAGE);
}

function setWOPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

export const PreviewTabIndexes: Record<keyof typeof JobPreviewTabRoutes, number> = {
	DETAILS: 0,
	JOB_HAZARD_ASSESSMENT: 1,
	BILLING_CODES: 2,
	ATTACHMENTS: 3,
	RELATED_WORK_ORDERS: 4,
	WORK_SUMMARY: 5,
	INVOICES: 6,
};

const PREVIEW_TABS = [
	{ id: PreviewTabIndexes.DETAILS, label: 'Details' },
	{ id: PreviewTabIndexes.JOB_HAZARD_ASSESSMENT, label: 'Job Hazard Assessment' },
	{ id: PreviewTabIndexes.BILLING_CODES, label: 'Billing Codes' },
	{ id: PreviewTabIndexes.ATTACHMENTS, label: 'Attachments' },
	{ id: PreviewTabIndexes.RELATED_WORK_ORDERS, label: 'Related Work Orders' },
	{ id: PreviewTabIndexes.WORK_SUMMARY, label: 'Work Summary' },
	{ id: PreviewTabIndexes.INVOICES, label: 'Invoices' },
];
const Preview: React.FC<Props> = (props) => {
	const {
		startDate: initialStartDate,
		endDate: initialEndDate,
		period: initialPeriod,
		findJobById,
		params: { jobId, '*': tabId },
		companyName,
		findRelatedWorkOrders,
		navigate,
		isSendingInvoiceNotificationsEnabled,
		hasPermissionToManageBillingCodes,
		hasPermissionsToAccessWorkSummary,
		hasPermissionsToManageInvoices,
	} = props;

	const [job, setJob] = React.useState<Nullable<JobPreviewViewModel>>(null);
	const [startDate, setStartDate] = React.useState<Date>(initialStartDate);
	const [endDate, setEndDate] = React.useState<Date>(initialEndDate);
	const [period, setPeriod] = React.useState<TimePeriodRecurrence>(initialPeriod);
	const [showCopyModal, setShowCopyModal] = React.useState<boolean>(false);

	const _tableRef = React.useRef<TableRef<WorkOrderTableViewModel>>(null);

	const history = useHistory();
	const { state: { orgAlias, activeTabEnum } } = useLocation<{ orgAlias: string; activeTabEnum: number; }>();

	const openWoReport = React.useCallback((workOrderId: number) => {
		window.open(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName));
	}, [companyName, orgAlias]);

	const columns: TableProps<WorkOrderTableViewModel>['columns'] = React.useMemo(() => [
		{
			header: 'Work Order',
			accessor: 'workOrderCode',
			id: 'code',
			size: 190,
			enableSorting: true,
			cell: ({ cell }) => (
				<span className={styles['text-strong']}>{cell.row.original.code}{cell.row.original.revision ? `, REV ${cell.row.original.revision}` : ''}</span>
			),
		},
		{
			header: 'Job Title',
			accessor: 'title',
			id: 'title',
			size: 100,
			enableSorting: true,
			cell: ({ cell }) => (
				<span>{cell.row.original.jobTitle}</span>
			),
		},
		{
			header: 'Status',
			accessor: 'status',
			id: 'status',
			size: 80,
			enableSorting: true,
			cell: ({ cell }) => {
				switch (cell.row.original.status) {
					case WorkOrderStatus.CANCELED:
						return <span className={`${styles['text-red']} ${styles['text-strong']}`}>{cell.row.original.status}</span>;
					default:
						return <span className={styles['text-black']}>{cell.row.original.status}</span>;
				}
			},
		},
		{
			id: 'dueDate',
			header: 'Date of Work',
			accessor: 'dueDate',
			size: 140,
			enableSorting: true,
			cell: ({ cell }) => <span>{TimeUtils.getShortDayName(cell.row.original.dueDate)}, {cell.row.original.dueDate}</span>,
		},
		{
			id: 'crewNumber',
			header: 'Crew',
			accessor: 'crewNumber',
			size: 55,
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.crewNumber,
		},
		{
			id: 'crewType',
			header: 'Crew Type',
			accessor: 'crewType',
			size: 100,
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.crewType
				? <LabelWithColor color={cell.row.original.crewColor} text={cell.row.original.crewType} /> : <EmptyCell />,
		},
		{
			id: 'supervisor',
			header: 'Superintendent',
			accessor: 'supervisor.account.user.firstName',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.supervisor?.fullName
				? <div className={styles['single-line-text']}>{cell.row.original.supervisor.fullName}</div>
				: <EmptyCell />,
		},
		{
			id: 'projectManager',
			header: 'Project Manager',
			accessor: 'projectManager.account.user.firstName',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.projectManager?.fullName
				? <div className={styles['single-line-text']}>{cell.row.original.projectManager.fullName}</div>
				: <EmptyCell />,
		},
		{
			id: 'production',
			header: 'Production',
			accessor: 'production',
			size: 105,
			enableSorting: false,
			cell: () => <EmptyCell />,
		},
		{
			id: 'revenue',
			header: 'Revenue',
			accessor: 'revenue',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.revenue ? `$ ${formatDecimalNumber(cell.row.original.revenue)}` : <EmptyCell />,
		},
		{
			id: 'manHourAverage',
			header: 'Man-hours',
			accessor: 'manHourAverage',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.manHourAverage ? `$ ${formatDecimalNumber(cell.row.original.manHourAverage)}` : <EmptyCell />,
		},
		{
			id: 'equipmentAndLabor',
			header: 'Equip./Labor/Temp. Labor',
			accessor: 'equipmentAndLabor',
			className: 'text-center',
			enableSorting: false,
			cell: ({ cell }) => {
				return (
					<ResourceCell
						original={cell.row.original}
					/>
				);
			},
		},
		{
			id: 'notes',
			header: 'Notes',
			accessor: 'notes',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.notes ? <div className={styles['single-line-text']}>{cell.row.original.notes}</div> : <EmptyCell />,
		},
		{
			id: 'woLink',
			accessor: 'woLink',
			header: 'Field Report',
			enableSorting: false,
			size: 120,
			cell: ({ cell }) => {
				switch (cell.row.original.status) {
					case WorkOrderStatus.DRAFT:
						return <EmptyCell />;
					default:
						return (
							<div className={styles['work-order-info-modal__report-link']} onClick={openWoReport.bind(null, cell.row.original.id)}>
								<div className={styles['work-order-info-modal__icon-text']}>View</div> <span className={`icon-external ${styles['link-cell__icon']}`} />
							</div >
						);
				}
			},
		},
	], [openWoReport]);

	const initializeData = React.useCallback(async () => {
		const _job = await findJobById(+jobId);
		setJob(_job);
	}, [findJobById, jobId]);

	React.useEffect(() => {
		initializeData();
	}, [initializeData]);

	const openCopyJobModal = React.useCallback(() => {
		setShowCopyModal(true);
	}, []);

	const closeCopyJobModal = React.useCallback(() => {
		setShowCopyModal(false);
	}, []);

	const resolveActiveTabId = React.useCallback(() => {
		if (activeTabEnum) {
			return activeTabEnum ?? PreviewTabIndexes.DETAILS;
		}

		switch (tabId) {
			case JobPreviewTabRoutes.DETAILS:
				return PREVIEW_TABS[0].id;
			case JobPreviewTabRoutes.JOB_HAZARD_ASSESSMENT:
				return PREVIEW_TABS[1].id;
			case JobPreviewTabRoutes.BILLING_CODES:
				return PREVIEW_TABS[2].id;
			case JobPreviewTabRoutes.ATTACHMENTS:
				return PREVIEW_TABS[3].id;
			case JobPreviewTabRoutes.RELATED_WORK_ORDERS:
				return PREVIEW_TABS[4].id;
			case JobPreviewTabRoutes.WORK_SUMMARY:
				return PREVIEW_TABS[5].id;
			case JobPreviewTabRoutes.INVOICES:
				return PREVIEW_TABS[6].id;
			default:
				return PREVIEW_TABS[0].id;
		}
	}, [activeTabEnum, tabId]);

	const onTabClick = React.useCallback((id: number) => {
		let tabName = '';
		switch (id) {
			case PreviewTabIndexes.DETAILS: {
				tabName = JobPreviewTabRoutes.DETAILS;
				break;
			}
			case PreviewTabIndexes.JOB_HAZARD_ASSESSMENT: {
				tabName = JobPreviewTabRoutes.JOB_HAZARD_ASSESSMENT;
				break;
			}
			case PreviewTabIndexes.BILLING_CODES: {
				tabName = JobPreviewTabRoutes.BILLING_CODES;
				break;
			}
			case PreviewTabIndexes.ATTACHMENTS: {
				tabName = JobPreviewTabRoutes.ATTACHMENTS;
				break;
			}
			case PreviewTabIndexes.RELATED_WORK_ORDERS: {
				tabName = JobPreviewTabRoutes.RELATED_WORK_ORDERS;
				break;
			}
			case PreviewTabIndexes.WORK_SUMMARY: {
				tabName = JobPreviewTabRoutes.WORK_SUMMARY;
				break;
			}
			case PreviewTabIndexes.INVOICES: {
				tabName = JobPreviewTabRoutes.INVOICES;
				break;
			}
			default: {
				tabName = JobPreviewTabRoutes.DETAILS;
				break;
			}
		}
		navigate(CLIENT.COMPANY.JOBS.PREVIEW_TAB(orgAlias, companyName, jobId, tabName));
	}, [companyName, jobId, navigate, orgAlias]);

	const onDownloadCSVClick = React.useCallback(async () => {
		if (!job) {
			throw new Error('Missing job');
		}

		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await findRelatedWorkOrders(job.details.id, startDate, endDate, tableRequestModel);
		const csvData = WorkOrderTableViewModel.toCSVData(result.rows);

		downloadCSV(csvData, `${companyName}_${job.details.jobCode}_work_orders.csv`);
	}, [companyName, endDate, findRelatedWorkOrders, job, startDate]);

	const refresh = React.useCallback(() => {
		if (_tableRef.current) {
			_tableRef.current.refreshTable();
		}
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, selected: Date) => {
		let _startDate: Date, _endDate: Date;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('Failed to parse selectedMoment');
		}

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				_endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				_endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				_startDate = selected;
				_endDate = selected;
				break;
		}

		setPeriod(_period);
		setStartDate(_startDate);
		setEndDate(_endDate);
		setWOStartDate(_startDate);
		setWOEndDate(_endDate);
		setWOPeriod(_period);
		refresh();
	}, [refresh]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
		setWOStartDate(_startDate);
		setWOEndDate(_endDate);
		refresh();
	}, [refresh]);

	const renderFilter = React.useCallback(() => {
		return (
			<div className={styles['table-filter']}>
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	}, [changePeriod, endDate, filterByDate, period, startDate]);

	const redirectToEditTabAction = React.useCallback((activeTabId: number) => {
		switch (activeTabId) {
			case PreviewTabIndexes.DETAILS: {
				return JobEditTabRoutes.DETAILS;
			}
			case PreviewTabIndexes.JOB_HAZARD_ASSESSMENT: {
				return JobEditTabRoutes.JOB_HAZARD_ASSESSMENT;
			}
			case PreviewTabIndexes.BILLING_CODES: {
				return JobEditTabRoutes.BILLING_CODES;
			}
			case PreviewTabIndexes.ATTACHMENTS: {
				return JobEditTabRoutes.ATTACHMENTS;
			}
			default: {
				return JobEditTabRoutes.DETAILS;
			}
		}
	}, []);

	const adjustTabsForPermissions = React.useCallback(() => {
		const tabsAdjustedForPermissions: typeof PREVIEW_TABS = [];
		for (const _tab of PREVIEW_TABS) {
			if (!hasPermissionToManageBillingCodes && _tab.id === PreviewTabIndexes.BILLING_CODES
				|| !hasPermissionsToAccessWorkSummary && _tab.id === PreviewTabIndexes.WORK_SUMMARY
				|| (process.env.FTD_INVOICES === 'true' || !hasPermissionsToManageInvoices) && _tab.id === PreviewTabIndexes.INVOICES
			) {
				continue;
			}

			tabsAdjustedForPermissions.push(_tab);
		}

		return tabsAdjustedForPermissions;
	}, [hasPermissionToManageBillingCodes, hasPermissionsToAccessWorkSummary, hasPermissionsToManageInvoices]);

	const buttons = React.useMemo(() => {
		return [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
				label: 'Export as CSV',
			},
		];
	}, [onDownloadCSVClick]);

	const fetchRelatedWorkOrders = React.useCallback((tableQuery: TableQuery) => {
		return findRelatedWorkOrders(jobId, startDate, endDate, tableQuery);
	}, [endDate, findRelatedWorkOrders, jobId, startDate]);

	const resolveOutletContext = React.useCallback((_job: JobPreviewViewModel): JobPreviewOutletContext => {
		return {
			allowCustomerSignature: _job.allowCustomerSignature,
			billingCodes: _job.billingCodes,
			companyName,
			hasPermissionToManageBillingCodes,
			isJobPreview: true,
			orgAlias,
			workRequest: _job.details,
			billingContact: _job.details.billingContact,
			isSendingInvoiceNotificationsEnabled,
			jobCode: _job.details.jobCode,
			jobHazardAssessment: _job.jobHazardAssessment,
			jobId: +jobId,
			hasPermissionsToAccessWorkSummary,
			buttons,
			fetch: fetchRelatedWorkOrders,
			searchLabel: 'Work Orders',
			selectable: false,
			columns,
			hasSearchInput: true,
			tableName: TableNameEnum.JOB_WORK_ORDERS,
			additionalFilter: renderFilter,
			ref: _tableRef,
			offsetHeight: 140,
		};
	}, [
		buttons,
		columns,
		companyName,
		fetchRelatedWorkOrders,
		hasPermissionToManageBillingCodes,
		hasPermissionsToAccessWorkSummary,
		isSendingInvoiceNotificationsEnabled,
		jobId,
		orgAlias,
		renderFilter,
	]);

	const showEditButton = React.useMemo(() => job?.status !== WorkRequestStatus.FINISHED, [job]);
	const activeTabId = React.useMemo(() => resolveActiveTabId(), [resolveActiveTabId]);
	const redirectToEditTab = React.useMemo(() => redirectToEditTabAction(activeTabId), [activeTabId, redirectToEditTabAction]);
	const editPageUrl = React.useMemo(() => redirectToEditTab
		? CLIENT.COMPANY.JOBS.EDIT_TAB(orgAlias, companyName, jobId, redirectToEditTab)
		: CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, jobId), [companyName, jobId, orgAlias, redirectToEditTab]);
	const tabsAdjustedForPermissions = React.useMemo(() => adjustTabsForPermissions(), [adjustTabsForPermissions]);

	const goToEdit = React.useCallback(() => {
		history.push(editPageUrl);
	}, [editPageUrl, history]);

	const areActionsAndNavigationSticky = React.useMemo(() => (activeTabId === PREVIEW_TABS[0].id || activeTabId === PREVIEW_TABS[1].id), [activeTabId]);
	const navigationClassName = React.useMemo(() => areActionsAndNavigationSticky ? styles['job-preview__tabs-navigation'] : undefined, [areActionsAndNavigationSticky]);
	const actionClasses = React.useMemo(() => {
		const _actionClasses = [styles['job-preview__submit-section']];
		if (areActionsAndNavigationSticky) {
			_actionClasses.push(styles['job-preview__submit-section-sticky']);
		}
		return _actionClasses;
	}, [areActionsAndNavigationSticky]);

	const breadcrumbItems = [
		{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
		{ label: job?.details.jobCode ?? '' },
		{ label: 'Preview' },
	];

	if (!job) {
		return <Loading />;
	}

	return (
		<>
			<Breadcrumbs
				items={
					breadcrumbItems
				}
			/>
			<div className={actionClasses.join(' ')}>
				<div className={styles['title-container']}>
					{!!job && (
						<>
							<div className={styles.title}>{job.details.jobCode}</div>
							<ProjectSubJobIndicator
								isProject={job.details.isProject}
								isSubJob={job.details.isProjectSubjob}
							/>
							{(!job.details.isProject && !!job.details.projectMainJobCode) && (
								<div className={styles['main-job-title']}>Associated Project: {job.details.projectMainJobCode}</div>
							)}
						</>
					)}
				</div>
				<div className={styles.actions}>
					<div>
						{showEditButton && (
							<Button
								label="Edit"
								onClick={goToEdit}
								style="secondary"
							/>
						)}
					</div>
					<Button
						label="Copy"
						onClick={openCopyJobModal}
						style="secondary"
					/>
				</div>
			</div>
			<TabNavigation
				active={activeTabId}
				navigationClassName={navigationClassName}
				onClick={onTabClick}
				tabs={tabsAdjustedForPermissions}
			/>
			{<Outlet context={resolveOutletContext(job)} />}
			<CopyModal
				close={closeCopyJobModal}
				jobToCopyCode={job.details.jobCode}
				jobToCopyId={job.details.id}
				showModal={showCopyModal}
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasViewWorkOrderPermission = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToManageInvoices = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_INVOICES,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToAccessWorkSummary = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_WORK_SUMMARY,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
		hasViewWorkOrderPermission,
		hasPermissionToManageBillingCodes,
		hasPermissionsToManageInvoices,
		hasPermissionsToAccessWorkSummary,
	};
}

function mapDispatchToProps() {
	return {
		findJobById: JobActions.findByIdForPreview,
		findAllBillingCodes: WorkRequestActions.findAllBillingCodesTable,
		findRelatedWorkOrders: JobActions.findRelatedWorkOrders,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	withRouterV6,
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(Preview);
