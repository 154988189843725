import * as React from 'react';
import type { WrappedFieldProps, FormErrors } from 'redux-form';
import { Field } from 'redux-form';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';

import TimeSheetEntryNewOrEdit from './TimeSheetEntryNewOrEdit';
import TimeSheetEntryPreview from './TimeSheetEntryPreview';
import type { TimeSheetAddedEntryFormModel, TimeSheetAddedEntryWithType, TimeSheetEntryFormModel, TimeSheetEntryWithType, TimeSheetGapEntryWithType, TimeSheetOccupiedEntryWithType } from '../formModel';
import { TimeSheetEntryFormType } from '../formModel';
import Gap from '../Gap';
import OccupiedSlot from '../OccupiedSlot';

export interface OwnProps {
	timeSheetId: number;
	isAllowedToEdit: boolean;
	canEditAccess: boolean;
	areTimeSheetsReadOnly: boolean;
	areFRsReadOnly: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	/** YYYY-MM-DD */
	dueDate: string;
	timeZoneInUse: Nullable<string>;
	isAllowedToAddTimeEntry: boolean;
	index: number;
	name: string;
	saveEntry: (index: number) => void;
	removeEntry: (index: number) => void;
	resetEntry: (index: number, value: TimeSheetEntryWithType | TimeSheetGapEntryWithType) => void;
	addGapEntry: (index: number) => void;
	getEntry: (index: number) => TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType | TimeSheetAddedEntryWithType;
	setEditingEntryIndexAndValue: (index: Nullable<number>, initialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>) => void;
	onEditingEntry: (index: number, newEntry: TimeSheetEntryWithType) => void;
	editingEntryIndex: Nullable<number>;
	editingEntryInitialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	overlap: OverlapMeta;
	entrySyncErrors: FormErrors<TimeSheetEntryFormModel | TimeSheetAddedEntryFormModel, string>;
	isAnotherTimeSheetInEditMode: boolean;
	isThisTimeSheetInEditMode: boolean;
}

type Props = OwnProps & WrappedFieldProps;

const TimeSheetEntry: React.FC<Props> = (props) => {

	const {
		saveEntry,
		removeEntry,
		getEntry,
		addGapEntry,
		resetEntry,
		setEditingEntryIndexAndValue,
		index,
		timeZoneInUse,
		employeeApprovalStatus,
		isAllowedToAddTimeEntry,
		overlap,
		timeSheetId,
		change,
		dueDate,
		editingEntryIndex,
		input,
		isAnotherTimeSheetInEditMode,
		isThisTimeSheetInEditMode,
		editingEntryInitialEntry,
		onEditingEntry,
		entrySyncErrors,
	} = props;

	const openEditMode = React.useCallback(() => {
		const initialEntryWithType = getEntry(index);
		if (initialEntryWithType.type !== TimeSheetEntryFormType.ENTRY && initialEntryWithType.type !== TimeSheetEntryFormType.GAP) {
			throw new Error('Only existing or gap entries can be edited.');
		}
		setEditingEntryIndexAndValue(index, { ...initialEntryWithType });
	}, [getEntry, index, setEditingEntryIndexAndValue]);

	const onCancelEdit = React.useCallback(() => {
		if (editingEntryInitialEntry !== null) {
			resetEntry(index, editingEntryInitialEntry);
		} else {
			removeEntry(index);
		}
	}, [editingEntryInitialEntry, index, removeEntry, resetEntry]);

	const onDelete = React.useCallback(() => {
		removeEntry(index);
	}, [index, removeEntry]);

	const onSave = React.useCallback(() => {
		saveEntry(index);
	}, [saveEntry, index]);

	const onGetPreviewEntry = React.useCallback(() => {
		const entryWithType = getEntry(index);
		if (entryWithType.type !== TimeSheetEntryFormType.ENTRY) {
			throw new Error('Wrong type of entry for previewing.');
		}
		return entryWithType;
	}, [index, getEntry]);

	const onGetNewOrEditEntry = React.useCallback(() => {
		const entryWithType = getEntry(index);
		if (entryWithType.type !== TimeSheetEntryFormType.ENTRY && entryWithType.type !== TimeSheetEntryFormType.ADDED) {
			throw new Error('Wrong type of entry for editing');
		}
		return entryWithType;
	}, [index, getEntry]);

	const onGapClick = React.useCallback(() => {
		openEditMode();
		addGapEntry(index);
	}, [addGapEntry, index, openEditMode]);

	const renderEntry = () => {
		const entryWithType = getEntry(index);

		if (entryWithType.type === TimeSheetEntryFormType.GAP) {
			const key = `gap#${index}`;

			const isOpaque = editingEntryIndex !== null && editingEntryIndex !== index || isThisTimeSheetInEditMode || isAnotherTimeSheetInEditMode;

			return (
				<Gap
					isOpaque={isOpaque}
					key={key}
					onClick={isAllowedToAddTimeEntry ? onGapClick : undefined}
				/>
			);
		} else if (entryWithType.type === TimeSheetEntryFormType.ENTRY) {
			if (editingEntryIndex !== null && editingEntryIndex === index) {
				return renderNewOrEdit();
			}

			if (!entryWithType.entry.belongsToOtherSheet) {
				return renderPreview();
			}

		} else if (entryWithType.type === TimeSheetEntryFormType.OCCUPIED) {
			const isOpaque = editingEntryIndex !== null && editingEntryIndex !== index;
			return (
				<OccupiedSlot
					endTime={entryWithType.entry.endTime!}
					isOpaque={isOpaque}
					key={`occupied#${entryWithType.entry.startTime}#${entryWithType.entry.endTime}`}
					startTime={entryWithType.entry.startTime}
				/>
			);
		} else if (entryWithType.type === TimeSheetEntryFormType.ADDED) {
			return renderNewOrEdit();
		}
		return null;
	};

	const renderNewOrEdit = () => {
		return (
			<Field
				change={change}
				component={TimeSheetEntryNewOrEdit}
				dueDate={dueDate}
				editingEntryInitialEntry={editingEntryInitialEntry}
				getEntry={onGetNewOrEditEntry}
				index={index}
				name={`${input.name}.entry`}
				onCancel={onCancelEdit}
				onDelete={onDelete}
				onEditingEntry={onEditingEntry}
				onSave={onSave}
				overlap={overlap}
				syncErrors={entrySyncErrors}
				timeSheetId={timeSheetId}
				timeZoneInUse={timeZoneInUse}
			/>
		);
	};

	const renderPreview = () => {
		const isOpaque = editingEntryIndex !== null && editingEntryIndex !== index || isAnotherTimeSheetInEditMode;

		return (
			<TimeSheetEntryPreview
				deleteEntry={onDelete}
				editEntry={openEditMode}
				employeeApprovalStatus={employeeApprovalStatus}
				getEntry={onGetPreviewEntry}
				isAnotherTimeSheetInEditMode={isAnotherTimeSheetInEditMode}
				isOpaque={isOpaque}
				isThisTimeSheetInEditMode={isThisTimeSheetInEditMode}
				overlap={overlap}
				readonly={!isAllowedToAddTimeEntry || isOpaque}
				timeZoneInUse={timeZoneInUse}
			/>
		);
	};

	return renderEntry();
};

export default React.memo(TimeSheetEntry);

