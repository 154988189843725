import * as React from 'react';

import WorkRequestJobHazardAssessmentStatus from '@acceligentllc/shared/enums/workRequestJobHazardAssessmentStatus';

import { previewSectionClass, previewSectionClassRow, previewSectionTitleClass } from '../../helpers';

type Props = {
	status: Nullable<WorkRequestJobHazardAssessmentStatus>;
};

const JobHazardAssessmentStatusSection: React.FC<Props> = ({ status }) => {
	const statusValue = React.useMemo(() => {
		if (!status) {
			return 'Not Started';
		}

		switch (status) {
			case (WorkRequestJobHazardAssessmentStatus.COMPLETE): {
				return 'Complete';
			}

			case (WorkRequestJobHazardAssessmentStatus.IN_PROGRESS): {
				return 'In Progress';
			}

			case (WorkRequestJobHazardAssessmentStatus.NOT_STARTED): {
				return 'Not Started';
			}

		}
	}, [status]);
	return (
		<div className={previewSectionClass}>
			<div className={previewSectionTitleClass}>
				Status
			</div>
			<div className={previewSectionClassRow}>
				<b>{statusValue}</b>
			</div>
		</div>
	);
};

export default React.memo(JobHazardAssessmentStatusSection);
