import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeOption from '@acceligentllc/shared/utils/timeOption';

import type { RootState } from 'af-reducers';

import * as NotifyActions from 'af-actions/notify';

import type ScheduleBoardTemplateNotificationRequestModel from 'ab-requestModels/scheduleBoardTemplateNotification.requestModel';

import { SEND_SB_TEMPLATE_FORM, SCHEDULE_SB_TEMPLATE_EMAIL } from 'af-constants/reduxForms';

import NotificationPriority from 'ab-enums/notificationPriority.enum';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import UserGroupsSelection from 'af-components/UserGroupsSelection';

interface OwnProps {
	/** MM-DD-YYYY */
	date: string;
	showModal: boolean;
	closeModal: () => void;
}

interface StateProps {
	scheduleBoardTemplateNotifyAt: number | undefined;
}

interface DispatchProps {
	sendScheduleBoardTemplate: typeof NotifyActions.sendSBTemplateNotification;
	scheduleScheduleBoardTemplate: typeof NotifyActions.scheduleSBForAutoNotify;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & InjectedFormProps<ScheduleBoardTemplateNotificationRequestModel, FormOwnProps>;

class ScheduleBoardTemplateNotifyModal extends React.Component<Props> {
	componentDidMount() {
		const { initialize } = this.props;
		initialize({ notificationPriority: NotificationPriority.EMAIL_ONLY });
	}

	onSend = async (form: ScheduleBoardTemplateNotificationRequestModel) => {
		const { reset, date, sendScheduleBoardTemplate, closeModal } = this.props;

		form.date = TimeUtils.formatDate(date, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY);

		await sendScheduleBoardTemplate(form);
		reset();
		closeModal();
	};

	onSchedule = async (form: ScheduleBoardTemplateNotificationRequestModel) => {
		const { reset, date, scheduleScheduleBoardTemplate, closeModal } = this.props;

		form.date = date;

		await scheduleScheduleBoardTemplate(form);
		reset();
		closeModal();
	};

	renderModalContent = () => {
		const { invalid, date, handleSubmit, change, array, scheduleBoardTemplateNotifyAt, closeModal } = this.props;

		const dateBefore = TimeUtils.parseMoment(date, TimeFormat.DATE_ONLY)?.subtract(1, 'day')?.format(TimeFormat.DATE_ONLY_SHORT_YEAR);

		return (
			<>
				<CustomModal.Header
					closeModal={closeModal}
					title="Send Schedule Board Template"
				/>
				<CustomModal.Body>
					<UserGroupsSelection
						array={array}
						change={change}
						formName={SEND_SB_TEMPLATE_FORM}
						isModal={true}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={closeModal}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Send immediately"
						onClick={handleSubmit(this.onSend)}
						submitKey={SEND_SB_TEMPLATE_FORM}
					/>
					{dateBefore && scheduleBoardTemplateNotifyAt !== undefined
						? (
							<SubmitButton
								label={`Notify at ${dateBefore}, ${TimeOption.format(scheduleBoardTemplateNotifyAt)}`}
								onClick={handleSubmit(this.onSchedule)}
								submitKey={SCHEDULE_SB_TEMPLATE_EMAIL}
							/>
						)
						: null
					}
				</CustomModal.Footer>
			</>
		);
	};

	render() {
		const { showModal, date, closeModal } = this.props;

		if (!showModal || !date) {
			return null;
		}

		return (
			<CustomModal
				className="schedule-board-template-modal"
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="xl"
			>
				{this.renderModalContent()}
			</CustomModal>
		);
	}
}
function mapStateToProps(state: RootState): StateProps {
	const { company } = state.company;

	return {
		scheduleBoardTemplateNotifyAt: company?.notification?.notifyScheduleBoardEmail,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		sendScheduleBoardTemplate: NotifyActions.sendSBTemplateNotification,
		scheduleScheduleBoardTemplate: NotifyActions.scheduleSBForAutoNotify,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<ScheduleBoardTemplateNotificationRequestModel, FormOwnProps>({ form: SEND_SB_TEMPLATE_FORM })
);

export default enhance(ScheduleBoardTemplateNotifyModal);
