/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field, destroy } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import { REPORT_TYPE_BLOCK } from 'af-constants/reduxForms';

import Dropdown from 'af-fields/Dropdown';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';

import type { ReportBlockFormModel } from '../../Shared/formModel';

export interface AddReportTypeBlockForm {
	reportBlockId: number;
	reportBlock: ReportBlockFormModel;
}

interface OwnProps {
	close: () => void;
	loadOptions: () => ReportBlockFormModel[];
	onSubmit: (form: AddReportTypeBlockForm) => void;
	show: boolean;
	valueKey: string;
}

type Props = OwnProps & InjectedFormProps<AddReportTypeBlockForm, OwnProps>;

const _filterOptions = (option: ReportBlockFormModel, searchText: string) => {
	return option.name.toLowerCase().includes(searchText.toLowerCase());
};

const renderOption = (option: ReportBlockFormModel) => {
	return (
		<div className="report-block-form__block_option">
			<div>{option.name}</div>
			<div className="report-block-form__block_option__uniqueId">({option.uniqueId})</div>
		</div>
	);
};

const ReportTypeBlockModal: React.FC<Props> = (props: Props) => {
	const { handleSubmit, onSubmit, close, show, invalid, submitting, loadOptions, initialValues, initialize, valueKey } = props;

	const [options, setOptions] = React.useState<ReportBlockFormModel[]>([]);

	React.useEffect(() => {
		if (show) {
			initialize(initialValues);
		} else {
			destroy();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	const onLoadOptions = React.useCallback(() => {
		setOptions(loadOptions());
	}, [loadOptions]);

	const handleAdd = React.useCallback((form: AddReportTypeBlockForm) => {
		onSubmit(form);
		close();
	}, [onSubmit, close]);

	return (
		<CustomModal
			className="report-block-modal"
			closeModal={close}
			modalStyle="info"
			showModal={show}
			size="sm"
		>
			<CustomModal.Header
				closeModal={close}
				title="Add Report Block"
			/>
			<CustomModal.Body>
				<Field
					component={Dropdown}
					filterable={true}
					filterBy={_filterOptions}
					fixed={true}
					id="reportBlock"
					label="Report Block"
					name="reportBlockId"
					onLazyLoad={onLoadOptions}
					options={options}
					propName="reportBlock"
					renderMenuItem={renderOption}
					renderSelected={renderOption}
					valueKey={valueKey}
					withCaret={true}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={close}
					style="secondary"
				/>
				<SubmitButton
					disabled={invalid}
					label="Add"
					onClick={handleSubmit(handleAdd)}
					reduxFormSubmitting={submitting}
					submitKey={REPORT_TYPE_BLOCK}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<AddReportTypeBlockForm, OwnProps>({
		form: REPORT_TYPE_BLOCK,
		enableReinitialize: true,
	})
);

export default enhance(ReportTypeBlockModal);
