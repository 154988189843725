import * as React from 'react';

import type { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeSheetUtils from '@acceligentllc/shared/utils/timeSheet';

import TimeSheetEntry from './TimeSheetEntry';

interface OwnProps {
	timeSheet: TimeSheetInfoVM;
	timeSheetEntries: TimeSheetEntryInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const TimeSheet: React.FC<Props> = (props) => {
	const {
		timeSheet: {
			totalJobDuration,
			totalShopDuration,
			totalTravelDuration,
			totalBreakDuration,
			signatureStatus,
			isSignedBySI,
		},
		timeSheetEntries,
		timezone,
	} = props;

	const totalTime = totalJobDuration + totalShopDuration + totalTravelDuration;
	const isTimeSheetSigned = TimeSheetUtils.isTimeSheetSigned(
		signatureStatus,
		isSignedBySI
	);

	const renderEntry = (entry: TimeSheetEntryInfoVM, index: number) => {
		return (
			<TimeSheetEntry
				entry={entry}
				isSigned={isTimeSheetSigned}
				key={index}
				timezone={timezone}
			/>
		);
	};

	return (
		<div>
			<div className="modal-time-sheet-employee__header">
				<div className="modal-time-sheet-employee__header__title">
					<div>Total Time on Work Order</div>
					<div>{TimeUtils.minutesToHoursAndMinutes(totalTime)}</div>
				</div>
				<div className="modal-time-sheet-employee__header__totals">
					<div className="modal-time-sheet-employee__header__total">
						<span className="modal-time-sheet-employee__header__work-icon icon-equipment" />
						<div>{TimeUtils.minutesToHoursAndMinutes(totalJobDuration)}</div>
					</div>
					<div className="modal-time-sheet-employee__header__total">
						<span className="modal-time-sheet-employee__header__work-icon icon-home" />
						<div>{TimeUtils.minutesToHoursAndMinutes(totalShopDuration)}</div>
					</div>
					<div className="modal-time-sheet-employee__header__total">
						<span className="modal-time-sheet-employee__header__work-icon icon-travel" />
						<div>{TimeUtils.minutesToHoursAndMinutes(totalTravelDuration)}</div>
					</div>
					<div className="modal-time-sheet-employee__header__total">
						<span className="modal-time-sheet-employee__header__work-icon icon-break" />
						<div>{TimeUtils.minutesToHoursAndMinutes(totalBreakDuration)}</div>
					</div>
				</div>
			</div>
			<div className="modal-time-sheet-employee__header__entry-list">
				{timeSheetEntries.map(renderEntry)}
			</div>
		</div>
	);
};

export default React.memo(TimeSheet);
