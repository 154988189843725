import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import styles from './styles.module.scss';

interface OwnProps {
	note: Nullable<string>;
	openNoteModal: () => void;
	showNoteActionButton: boolean;
}

const TimeSheetNote: React.FC<OwnProps> = (props: OwnProps) => {
	const { note, openNoteModal, showNoteActionButton } = props;

	const readonlyNoteStyle = showNoteActionButton ? '' : ` ${styles['time-sheet-list__row__note--readonly']}`;
	const noteClassName = `${styles['time-sheet-list__row__note']}${readonlyNoteStyle}`;

	return (
		<div className={noteClassName}>
			{note ?
				<>
					<div className={styles['time-sheet-list__row__note__edit__header']}>
						<span className={styles['time-sheet-list__row__note__edit__header__title']}>Time Card Note</span>
						{showNoteActionButton &&
							<Button
								icon="edit"
								label="Edit Note"
								onClick={openNoteModal}
								style="link"
							/>
						}
					</div>
					<div className={styles['time-sheet-list__row__note__edit__text']}>
						{note}
					</div>
				</> :
				<div className={styles['time-sheet-list__row__note__add']}>
					{showNoteActionButton &&
						<Button
							icon="plus"
							label="Add Time Card Note"
							onClick={openNoteModal}
							style="link"
						/>
					}
				</div>
			}
		</div>
	);
};

export default React.memo(TimeSheetNote);

