import { ColorPalette } from '@acceligentllc/shared/enums/color';

export const CONTACTS_CSV_SAMPLE = [
	['fullName', 'companyName', 'contactRole', 'addresses.0.latitude', 'addresses.0.longitude', 'addresses.0.street', 'addresses.0.aa1', 'addresses.0.aa2', 'addresses.0.aa3', 'addresses.0.country', 'addresses.0.locality', 'addresses.0.postalCode',
		'addresses.0.route', 'addresses.0.streetNumber', 'addresses.0.suite', 'addresses.1.longitude', 'addresses.1.latitude', 'addresses.1.street', 'addresses.1.aa1', 'addresses.1.country', 'addresses.1.locality', 'addresses.1.postalCode',
		'phones.0.countryCode', 'phones.0.value', 'phones.0.type', 'phones.0.extension', 'phones.1.countryCode', 'phones.1.value', 'phones.1.type', 'phones.1.extension', 'emails.0.value'],
	['Jessica Phillips', '', 'Storage Manager', '36.5978891330702', '-97.03125', '4 Mile Rd, Ponca City, OK 74604, USA', 'Oklahoma', 'Osage County', '', 'United States', 'Ponca City', '74604',
		'4 Mile Road', '', '', '42,65012181368023', '-85,2978515625', '701 W Apple St, Hastings, MI 49058, USA', 'Michigan', 'United States', 'Hastings', '49058',
		'US', '(431) 153-9948', 'PHONE_MOBILE', '', '', '', '', '', 'jessica.phillips@company.com'],
	['John Doe', 'Acceligent', 'Contractor', '45.54753435', '-100.435343125', '148 15th St W, Mobridge, SD 57601, USA', 'South Dakota', '', '', 'United States', 'Mobridge', '57601', '', '', '',
		'', '', '', '', '', '', '', 'US', '(431) 153-9948', 'PHONE_MOBILE', '', 'US', '(431) 443-9948', 'PHONE_OFFICE', '', 'john.doe@company.com'],
];

// NOTE: depends on EQUIPMENT_COST_CSV_SAMPLE
export const EQUIPMENT_CSV_SAMPLE = [
	['id', 'specification', 'primaryContact', 'secondaryContact', 'equipmentStatus', 'licenses', 'skills.0.name', 'skills.0.color', 'skills.1.name', 'skills.1.color', 'skills.2.name', 'skills.2.color', 'equipmentCost.type', 'equipmentCost.group', 'equipmentCost.category', 'equipmentCost.categoryColor', 'equipmentCost.subcategory', 'equipmentCost.mobCharge', 'equipmentCost.dailyCost', 'equipmentCost.weeklyCost', 'equipmentCost.monthlyCost', 'equipmentCost.operatingCharge', 'equipmentCost.fuelCost', 'equipmentCost.licenses', 'equipmentCost.skills.0.name', 'equipmentCost.skills.0.color', 'equipmentCost.skills.1.name', 'equipmentCost.skills.1.color', 'equipmentCost.skills.2.name', 'equipmentCost.skills.2.color'],
	['Carrier', 'HC', 'John Doe', 'Jane Doe', 'Shop', 'licence 01', 'Skill 01', ColorPalette.BLUE, 'Skill 02', ColorPalette.CERISE, 'Skill 03', ColorPalette.GREEN, 'EQUIPMENT', 'Heavy Equipment', 'Heavy trucks', ColorPalette.RED, 'Truck 1 ton', '10', '3', '12', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, '', '', '', ''],
	['HT2001', 'HT', 'Djuro Djuric', 'Ivan Ivic', '', '', '', '', '', '', '', '', 'EQUIPMENT', 'Heavy Equipment', 'Machines', ColorPalette.RED, 'JCB 3CX', '102', '31', '122', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, 'Skill 4', ColorPalette.MAROON, '', ''],
];

export const EQUIPMENT_COST_CSV_SAMPLE = [	// TODO: add new fields
	['type', 'group', 'category', 'categoryColor', 'subcategory', 'mobCharge', 'dailyCost', 'weeklyCost', 'monthlyCost', 'operatingCharge', 'fuelCost', 'licenses', 'skills.0.name', 'skills.0.color', 'skills.1.name', 'skills.1.color', 'skills.2.name', 'skills.2.color'],
	['EQUIPMENT', 'Heavy Equipment', 'Heavy trucks', ColorPalette.RED, 'Truck 1 ton', '10', '3', '12', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, '', '', '', ''],
	['ACCESSORY', 'Wall Support Elements', 'Insulation Element', ColorPalette.RED, 'Thermostar', '10', '3', '12', '54', '12', '2', '', '', '', '', '', '', ''],
	['EQUIPMENT', 'Heavy Equipment', 'Heavy trucks', ColorPalette.RED, 'Truck 2 ton', '102', '31', '122', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, 'Skill 4', ColorPalette.MAROON, '', ''],
	['EQUIPMENT', 'Heavy Equipment', 'Heavy machines', ColorPalette.RED, 'Crane', '102', '31', '122', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, 'Skill 4', ColorPalette.MAROON, '', ''],
	['EQUIPMENT', 'Heavy Equipment', 'Heavy machines', ColorPalette.RED, 'JCB 3CX', '102', '31', '122', '54', '12', '2', 'Drivers License', 'Skill 1', ColorPalette.BLUE, 'Skill 4', ColorPalette.MAROON, '', ''],
	['ACCESSORY', 'Wall Support Elements', 'Beam', ColorPalette.RED, 'Steel beam', '10', '3', '12', '54', '152', '2', '', '', '', '', '', '', ''],
	['ACCESSORY', 'Masonry', 'Anconoptima 14', ColorPalette.VIOLET, '30/20 channel', '10', '3', '12', '54', '4', '2', 'Masonry license', 'Skill 1', ColorPalette.BLUE, 'Skill 2', ColorPalette.GREEN, 'Skill 3', ColorPalette.CERISE],
	['ACCESSORY', 'Masonry', 'Anconoptima 14', ColorPalette.VIOLET, 'FAZ 12/30 A4', '10', '3', '12', '54', '23', '2', 'Masonry license', 'Skill 1', ColorPalette.BLUE, 'Skill 2', ColorPalette.GREEN, 'Skill 3', ColorPalette.CERISE],
	['ACCESSORY', 'Masonry', 'Anconoptima 14', ColorPalette.VIOLET, 'M12 Steelgrip', '30', '3', '12', '6', '3', '2', 'Masonry license', 'Skill 2', ColorPalette.GREEN, 'Skill 3', ColorPalette.CERISE, '', ''],
	['ACCESSORY', 'Masonry', 'Anconoptima 14', ColorPalette.VIOLET, 'M12 set screw', '1', '3', '12', '54', '12', '42', 'Masonry license', 'Skill 2', ColorPalette.GREEN, 'Skill 3', ColorPalette.CERISE, '', ''],
	['SMALL_TOOL', 'Wrenches', 'Open-end wrench', ColorPalette.BLUE, 'Wrench 4', '10', '3', '12', '54', '12', '25', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Open-end wrench', ColorPalette.BLUE, 'Wrench 2', '12', '1', '12', '54', '12', '23', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Open-end wrench', ColorPalette.BLUE, 'Wrench 1', '10', '2', '12', '54', '12', '22', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Box-end wrench', ColorPalette.RED, 'Wrench 5', '10', '3', '12', '54', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Box-end wrench', ColorPalette.RED, 'Wrench 3', '10', '3', '2', '54', '6', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Ratcheting box wrench', ColorPalette.CERISE, 'Wrench 3', '10', '3', '5', '54', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Wrenches', 'Ratcheting box wrench', ColorPalette.CERISE, 'Wrench 2', '1', '3', '4', '3', '2', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Pliers', 'Slip joint pliers', ColorPalette.GREEN, 'Plier 2', '10', '3', '12', '54', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Pliers', 'Slip joint pliers', ColorPalette.GREEN, 'Plier 1', '10', '3', '2', '54', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Clamps', 'Clamps', ColorPalette.PURPLE, 'Pipe Clamp', '10', '3', '12', '52', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Clamps', 'Clamps', ColorPalette.PURPLE, 'Bar Clamp', '10', '3', '12', '54', '12', '2', '', '', '', '', '', '', ''],
	['SMALL_TOOL', 'Clamps', 'Clamps', ColorPalette.PURPLE, 'C-clamp', '10', '3', '12', '54', '12', '2', '', '', '', '', '', '', ''],
];

export const MEMBERS_CSV_SAMPLE = [
	['uniqueId', 'email', 'countryCode', 'phoneNumber', 'firstName', 'lastName'],
	['EE0001', 'john.doe@sample.com', 'US', '(431) 153-9948', 'John', 'Doe'],
	['EE0002', 'mate.matic@sample.com', '', '', 'Mate', 'Matic'],
	['EE0003', '', 'US', '(431) 153-9999', 'John', 'Smith'],
];

export const WAGE_RATE_CSV_SAMPLE = [
	['type', 'wageClassification', 'hourlyRate'],
	['Mechanic', 'Mechanic 1', '62'],
	['Laborer', 'Laborer 4', '54'],
	['Operating Engineers', 'Op. Engineer 2', '72'],
	['Supervisor', 'Op. Engineer 2', '65'],
];

export const BILLING_CODE_CSV_SAMPLE = [
	['lineItemNumber', 'customerNumber', 'customerId', 'ownerNumber', 'ownerId', 'bidQuantity', 'unit', 'unitPrice', 'group', 'description'],
	['2', '4', 'BLG432', '35567688', '22222222', '10', 'LF', '10', 'MTH', 'description'],
	['3', '7', 'BLG436', '35567998', '22233322', '50', 'IN', '1', 'LCR', 'description'],
	['4', '', 'BLG433', '', '', '', 'PCT', '12', '', 'description'],
];
