import * as React from 'react';
import { Button } from 'react-bootstrap';
import { compose } from 'redux';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type * as TimeSheetEntryUtils from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { __ENVIRONMENT__, __STAGING__, __TESTING__ } from 'af-constants/values';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';
import type TimeCardRejectedTimeSheetVM from 'ab-viewModels/timeSheet/timeCardRejectedTimeSheet.viewModel';

import DayInTimeCard from './DayInTimeCard';
import styles from './styles.module.scss';

interface RejectedByDate {
	rejected: TimeCardRejectedTimeSheetVM[];
}

interface EntriesByDate {
	date: string;
	entries: TimeCardEntryVM[];
}
interface OwnProps {
	allAccountEntries: TimeCardEntryVM[];
	hasUnassignedEntries: number;
	hasUnassignedEntriesForMessage: number;
	rejectedTSForAccount: TimeCardRejectedTimeSheetVM[];
	overlaps: Nullable<Record<string, TimeSheetEntryUtils.OverlapMeta>>;
	timeOffset: number;
	timeZoneInUse: Nullable<string>;
}

type Props = OwnProps;

const TimeCardTroubleshootModal: React.FC<Props> = (props: Props) => {
	const {
		hasUnassignedEntriesForMessage,
		allAccountEntries,
		rejectedTSForAccount,
		overlaps,
		timeOffset,
		timeZoneInUse,
	} = props;

	const today = React.useMemo(() => {
		const now = new Date();
		return TimeUtils.formatDate(now, TimeFormat.DATE_ONLY);
	}, []);

	const entriesGroupedByDate: EntriesByDate[] = React.useMemo(() => {
		return Object.values(
			allAccountEntries.reduce((acc, _tle) => {

				const startTimeFormatted = TimeUtils.formatDate(_tle.startTime, TimeFormat.ISO_DATETIME, TimeFormat.ISO_DATETIME);
				const tseStartDate = TimeUtils.formatDate(startTimeFormatted, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

				if (!acc[tseStartDate]) {
					acc[tseStartDate] = { date: tseStartDate, entries: [] };
				}

				acc[tseStartDate].entries.push(_tle);

				return acc;
			}, {})
		);
	}, [allAccountEntries]);

	const rejectedTSForDay = React.useMemo(() => {
		return rejectedTSForAccount.reduce<Record<string, RejectedByDate>>((acc, _rejected) => {
			const dueDate = TimeUtils.formatDate(_rejected.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

			if (!acc[dueDate]) {
				acc[dueDate] = { rejected: [] };
			}

			acc[dueDate].rejected.push(_rejected);

			return acc;
		}, {});
	}, [rejectedTSForAccount]);

	const hasTodayEntry = React.useMemo(() => {
		return entriesGroupedByDate.some((entry) => entry.date === today);
	}, [entriesGroupedByDate, today]);

	if (!hasTodayEntry) {
		entriesGroupedByDate.unshift({ date: today, entries: [] });
	}

	const renderLogos = React.useCallback(() => {
		if (__TESTING__ || __STAGING__) {
			return (
				<img src={`/images/logo/ic_logo_white_${__ENVIRONMENT__}.svg`} />
			);
		}
		return (
			<img src="/images/logo/ic_logo_white.svg" />
		);
	}, []);

	const isInActiveShift: boolean = React.useMemo(() => { return allAccountEntries.some((_tse) => _tse.isInActiveShift); }, [allAccountEntries]);

	return (
		<div className={styles.timeCard}>
			{/* TIME CARD HEADER */}
			<div className={styles.timeCardHeader}>
				<div className={styles.headerIcon}>
					{renderLogos()}
				</div>
				<div className={styles.timeCardName}>
					Time Card
				</div>
				<div className={styles.headerSettingsIcon}>
					<span className="icon-actions icon" />
				</div>
			</div>

			{/* TIME CARD BODY */}
			{!!rejectedTSForAccount.length && <div className={styles.timeCardEntriesAlert}>
				{(rejectedTSForAccount.length > 1) ?
					'Multiple Time Sheets have been rejected.'
					:
					<div>
						Time Sheet for <b>{rejectedTSForAccount[0].workOrderCode}</b> of <b>{rejectedTSForAccount[0].dueDate}</b> has been rejected by {rejectedTSForAccount[0].rejectedByFullName ?? 'Acceligent Customer Service'}.
					</div>
				}
			</div>}

			{overlaps && Object.keys(overlaps).length !== 0 && <div className={styles.timeCardEntriesAlert}>
				One or more corrections overlap with activities on the timeline.
			</div>}
			{/* If there is overlap on time card we don't show error message for unassigned entries */}
			{!!overlaps && !!hasUnassignedEntriesForMessage && <div className={styles.timeCardEntriesAlert}>
				There are Time Entires which are not assigned to a Work Order.
			</div>}
			{isInActiveShift && <div className={styles.timeCardEntriesWarning}>
				You have an active shift. <b>Go to active shift. {'>'} </b>
			</div>}

			{/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
			{entriesGroupedByDate && entriesGroupedByDate.map((_dateWithEntries: EntriesByDate) => {
				return (
					<React.Fragment key={_dateWithEntries.date}>
						<DayInTimeCard
							dateWithEntries={_dateWithEntries}
							hasTodayEntry={hasTodayEntry}
							overlaps={overlaps}
							rejectedTS={rejectedTSForDay[_dateWithEntries.date]?.rejected ?? []}
							timeOffset={timeOffset}
							timeZoneInUse={timeZoneInUse}
							today={today}
						/>
					</React.Fragment>
				);
			})}

			{/* EMPTY SPACE */}
			<div className={styles.emptySpace}></div>

			{/* TIME CARD BUTTONS */}
			<div className={styles.footerButtons}>
				{!isInActiveShift && <div >
					<Button
						className={styles.timeCardButton}
						disabled
						size="lg"
						variant="info"
					>
						Start New Shift
					</Button>
				</div>}
				<div >
					<Button
						className={styles.timeCardButton}
						disabled
						size="lg"
						variant="info"
					>
						Sign Time Card
					</Button>
				</div>
			</div>
		</div>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo
);
export default enhance(TimeCardTroubleshootModal);
