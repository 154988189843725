import type { EmailTypes, PhoneTypes } from '@acceligentllc/shared/enums/contactMethodType';
import { EmailTypesArray, PhoneTypesArray } from '@acceligentllc/shared/enums/contactMethodType';
import CountryCode from '@acceligentllc/shared/enums/countryCode';
import { stateAbbreviation } from '@acceligentllc/shared/enums/state';
import UpsertContactStatusEnum from '@acceligentllc/shared/enums/upsertContactStatus';

import type ContactRM from 'ab-requestModels/contact/upsert';

import type AddressBase from 'ab-domain/models/address/base';
import type ContactBase from 'ab-domain/models/contact/base';
import type ContactAddressBase from 'ab-domain/models/contactAddress/base';
import type ContactMethodBase from 'ab-domain/models/contactMethod/base';

import { getShortAddress, getStreetAddress } from '@acceligentllc/shared/utils/address';
import { formatPhoneNumber } from '@acceligentllc/shared/utils/phone';

type GroupedContactMethods = {
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];
};

class ContactMethodVM {
	id: number;
	contactId: number;
	type: EmailTypes | PhoneTypes;
	value: string;
	countryCode: Nullable<CountryCode>;
	extension: Nullable<string>;

	constructor(contactMethod: ContactMethodBase) {
		this.id = contactMethod.id;
		this.contactId = contactMethod.contactId;
		this.type = contactMethod.type;
		this.value = contactMethod.value;
		this.countryCode = contactMethod.countryCode;
		this.extension = contactMethod.extension;
	}

	static bulkConstructor(contactMethods: ContactMethodBase[]): ContactMethodVM[] {
		return contactMethods.map(ContactMethodVM._mapConstructor);
	}

	static groupedBulkConstructor(contactMethods: ContactMethodBase[]): GroupedContactMethods {
		const result: GroupedContactMethods = {
			phones: [],
			emails: [],
		};

		for (const _cm of contactMethods) {
			if (PhoneTypesArray.indexOf(_cm.type) !== -1) {
				result.phones.push(new ContactMethodVM(_cm));
			} else if (EmailTypesArray.indexOf(_cm.type) !== -1) {
				result.emails.push(new ContactMethodVM(_cm));
			}
		}

		return result;
	}

	static bulkToEmailRequestModel(contactMethods: ContactMethodVM[]): ContactRM['emails'] {
		return contactMethods.map(ContactMethodVM.toEmailRequestModel);
	}

	static bulkToPhoneRequestModel(contactMethods: ContactMethodVM[]): ContactRM['phones'] {
		return contactMethods.map(ContactMethodVM.toPhoneRequestModel);
	}

	private static _mapConstructor(contactMethod: ContactMethodBase): ContactMethodVM {
		return new ContactMethodVM(contactMethod);
	}

	private static toEmailRequestModel(method: ContactMethodVM): ContactRM['emails'][0] {
		return {
			id: method.id,
			value: method.value,
			type: method.type as EmailTypes,
			status: UpsertContactStatusEnum.OLD,
		};
	}

	private static toPhoneRequestModel(method: ContactMethodVM): ContactRM['phones'][0] {
		return {
			id: method.id,
			value: formatPhoneNumber(method.value)!,
			type: method.type as PhoneTypes,
			countryCode: method.countryCode ?? CountryCode.US,
			extension: method.extension,
			status: UpsertContactStatusEnum.OLD,
		};
	}
}

class AddressVM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	stateAbbreviation: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	shortAddress: string;
	latitude: number;
	longitude: number;

	constructor(address: AddressBase) {
		this.id = address.id;
		this.street = getStreetAddress(address);
		this.streetNumber = address.streetNumber;
		this.route = address.route;
		this.suite = address.suite;
		this.city = address.locality;
		this.state = address.aa1;
		this.stateAbbreviation = address.aa1 && stateAbbreviation[address.aa1];
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.zip = address.postalCode;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.country = address.country;
		this.shortAddress = getShortAddress(address);
		this.longitude = address.longitude;
		this.latitude = address.latitude;
	}

	static toRequestModel(address: AddressVM): ContactRM['addresses'][0]['address'] {
		return {
			id: address.id,
			aa1: address.state,
			aa2: address.aa2,
			aa3: address.aa3,
			country: address.country,
			latitude: address.latitude,
			longitude: address.longitude,
			locality: address.city,
			postalCode: address.zip,
			route: address.route,
			street: address.street,
			streetNumber: address.streetNumber,
			suite: address.suite,
			postalOfficeBoxCode: address.postalOfficeBoxCode,
		};
	}
}

class ContactAddressVM {
	id: number;
	contactId: number;
	addressId: number;
	address: AddressVM;

	constructor(contactAddress: ContactAddressBase) {
		this.id = contactAddress.id;
		this.contactId = contactAddress.contactId;
		this.addressId = contactAddress.addressId;
		this.address = contactAddress.address && new AddressVM(contactAddress.address);
	}

	static bulkConstructor(contactAddresses: ContactAddressBase[]): ContactAddressVM[] {
		return contactAddresses.map(ContactAddressVM._mapConstructor);
	}

	static bulkToRequestModel(contactAddresses: ContactAddressVM[]): ContactRM['addresses'] {
		return contactAddresses.map(ContactAddressVM.toRequestModel);
	}

	private static _mapConstructor(address: ContactAddressBase): ContactAddressVM {
		return new ContactAddressVM(address);
	}

	private static toRequestModel(contactAddress: ContactAddressVM): ContactRM['addresses'][0] {
		return {
			id: contactAddress.id,
			address: AddressVM.toRequestModel(contactAddress.address),
			status: UpsertContactStatusEnum.OLD,
		};
	}
}

class ContactVM {
	id: number;
	fullName: string;
	companyName: Nullable<string>;
	title: Nullable<string>;
	addresses: ContactAddressVM[];
	emails: ContactMethodVM[];
	phones: ContactMethodVM[];
	isUnsaved: boolean;

	constructor(contact: ContactBase) {
		this.id = contact.id;
		this.companyName = contact.companyName;
		this.fullName = contact.fullName;
		this.title = contact.title;
		this.addresses = ContactAddressVM.bulkConstructor(contact.addresses ?? []);

		const groupedMethods = ContactMethodVM.groupedBulkConstructor(contact.contactMethods ?? []);
		this.emails = groupedMethods.emails;
		this.phones = groupedMethods.phones;

		this.isUnsaved = contact.isUnsaved;
	}

	static toRequestModel(contact: ContactVM): ContactRM {
		return {
			id: contact.id,
			fullName: contact.fullName,
			companyName: contact.companyName,
			title: contact.title,
			phones: ContactMethodVM.bulkToPhoneRequestModel(contact.phones),
			emails: ContactMethodVM.bulkToEmailRequestModel(contact.emails),
			addresses: ContactAddressVM.bulkToRequestModel(contact.addresses),
		};
	}

	static bulkConstructor(contacts: ContactBase[]): ContactVM[] {
		return contacts.map(ContactVM._mapConstructor);
	}

	static _mapConstructor(contact: ContactBase): ContactVM {
		return new ContactVM(contact);
	}
}

export default ContactVM;
