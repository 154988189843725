import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import type { BillableWorkRM } from 'ab-requestModels/reportType/reportType.requestModel';

import type { BillableWorkVM } from 'ab-viewModels/reportType/reportType.viewModel';

import type { ReportBlockFormModel, ReportTypeBlockFormModel } from '../../Shared/formModel';
import ReportTypeModal from '../../Shared/ReportTypeModal';

import BillableWorkModal from '../Modals/BillableWork';
import type { BillableWorkDropdownOption } from '../Modals/BillableWork/types';

import BillableWorkListItem from './BillableWorkListItem';

interface OwnProps {
	isPreviewInFocus: boolean;
	isSaveDisabled: boolean;
	onPreviewClick: () => void;
	onSaveClick: () => void;
	reportTypeName: string;
	showPreviewToggle: boolean;
	/** if not passed, rename is disabled */
	edit?: (name: string, description: Nullable<string>) => void;
	description: Nullable<string>;
}

interface OwnPropsWithBillableWork {
	billableWorks: BillableWorkRM[];
	billableWorkFieldsByIdMap: { [id: string]: BillableWorkDropdownOption; };
	definitionFieldFieldIds: string[];
	hasBillableWork: true;
	informationFieldFieldIds: string[];
	reportTypeBlocksByIdMap: Record<string, ReportTypeBlockFormModel>;
	reportBlocksByIdMap: { [id: string]: ReportBlockFormModel; };
	workTypeFieldIds: string[];
	workQuantityFieldIds: string[];
	addBillableWork: (billableWork: BillableWorkRM) => void;
	removeBillableWork: (index: number) => void;
	editBillableWork: (index: number, billableWork: BillableWorkRM) => void;
	setCurrentlyHighlightedReportFields: (currentlyHighlightedBillableWork: BillableWorkRM) => void;
}

type OwnPropsWithoutBillableWorks = (
	Partial<Record<keyof OwnPropsWithBillableWork, undefined>>
	& { hasBillableWork?: false; }
);

export type FormHeaderPropsWithBillableWork = OwnPropsWithBillableWork;
export type FormHeaderPropsWithoutBillableWork = OwnPropsWithoutBillableWorks;

type Props = OwnProps & (OwnPropsWithBillableWork | OwnPropsWithoutBillableWorks);

const ReportTypeFormHeader: React.FC<Props> = (props: Props) => {
	const {
		billableWorks,
		description,
		edit,
		hasBillableWork,
		isPreviewInFocus,
		isSaveDisabled,
		onPreviewClick,
		onSaveClick,
		reportTypeName,
		showPreviewToggle,
		addBillableWork,
		removeBillableWork,
		editBillableWork,
		setCurrentlyHighlightedReportFields,
	} = props;

	const [billableWorkForEditIndex, setBillableWorkForEditIndex] = React.useState<Nullable<number>>(null);
	const [currentlyHighlightedBillableWorkIndex, setCurrentlyHighlightedBillableWorkIndex] = React.useState<Nullable<number>>(null);
	const [reservedBillableWorkNames, setReservedBillableWorkName] = React.useState<Nullable<Record<string, true>>>(null);
	const [showBillableWorkModal, setShowBillableWorkModal] = React.useState<boolean>(!props.reportTypeName?.length);
	const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
	const [showBillableWorkList, setShowBillableWorkList] = React.useState<boolean>(true);

	const openModal = React.useCallback(() => setShowEditModal(true), []);
	const closeModal = React.useCallback(() => setShowEditModal(false), []);

	const getBillableWorkReservedNames = React.useCallback((index: Nullable<number>) => {
		if (!billableWorks) {
			return {};
		}

		return billableWorks.reduce<Record<string, true>>((_acc, _bw, _index) => {
			if (index === null || index !== _index) {
				_acc[_bw.workName.toLowerCase()] = true;
			}
			return _acc;
		}, {});
	}, [billableWorks]);

	const openBillableWorkModal = React.useCallback(() => {
		setReservedBillableWorkName(getBillableWorkReservedNames(null));
		setShowBillableWorkModal(true);
	}, [getBillableWorkReservedNames]);

	const closeBillableWorkModal = React.useCallback(() => {
		setShowBillableWorkModal(false);
		setBillableWorkForEditIndex(null);
		setReservedBillableWorkName(null);
	}, []);

	const openBillableWorkModalForEdit = React.useCallback((index: number) => {
		setBillableWorkForEditIndex(index);
		setReservedBillableWorkName(getBillableWorkReservedNames(index));
		setShowBillableWorkModal(true);
	}, [getBillableWorkReservedNames]);

	const openShowBillableWorkList = React.useCallback(() => setShowBillableWorkList(true), []);
	const hideBillableWorkList = React.useCallback(() => setShowBillableWorkList(false), []);

	const setHighlightedBillableWorkIndex = React.useCallback((index: number) => setCurrentlyHighlightedBillableWorkIndex(index), []);

	const onNameChange = React.useCallback((name: string, _description: Nullable<string>) => {
		edit?.(name, _description);
	}, [edit]);

	const handleAdd = React.useCallback((billableWork: BillableWorkRM) => {
		addBillableWork?.(billableWork);
	}, [addBillableWork]);

	const handleEdit = React.useCallback((billableWork: BillableWorkRM) => {
		if (billableWorkForEditIndex !== null) {
			editBillableWork?.(billableWorkForEditIndex, billableWork);
		}
	}, [editBillableWork, billableWorkForEditIndex]);

	const handleRemove = React.useCallback((index: number) => {
		removeBillableWork?.(index);
	}, [removeBillableWork]);

	const renderBillableWork = (billableWork: BillableWorkVM, index: number) => {
		return (
			<BillableWorkListItem
				billableWork={billableWork}
				currentlyHighlightedBillableWorkIndex={currentlyHighlightedBillableWorkIndex}
				definitionFields={billableWorks?.[index]?.definitionFields ?? []}
				index={index}
				key={billableWork.workName}
				onDelete={handleRemove}
				onEdit={openBillableWorkModalForEdit}
				setCurrentlyHighlightedBillableWorkIndex={setHighlightedBillableWorkIndex}
				setCurrentlyHighlightedFields={setCurrentlyHighlightedReportFields}
			/>
		);
	};

	const renderBillableWorkModal = () => {
		if (hasBillableWork) {
			const {
				billableWorkFieldsByIdMap,
				definitionFieldFieldIds,
				informationFieldFieldIds,
				reportBlocksByIdMap,
				reportTypeBlocksByIdMap,
				workQuantityFieldIds,
				workTypeFieldIds,
			} = props;
			const billableWorkForEdit = billableWorkForEditIndex !== null ? (billableWorks?.[billableWorkForEditIndex] ?? null) : null;

			return (
				<BillableWorkModal
					billableWorkFieldsByIdMap={billableWorkFieldsByIdMap}
					closeModal={closeBillableWorkModal}
					definitionFieldFieldIds={definitionFieldFieldIds}
					informationFieldFieldIds={informationFieldFieldIds}
					initialData={billableWorkForEdit}
					onCreate={handleAdd}
					onEdit={handleEdit}
					reportBlocksByIdMap={reportBlocksByIdMap}
					reportTypeBlocksByIdMap={reportTypeBlocksByIdMap}
					reservedBillableWorkNames={reservedBillableWorkNames}
					showModal={showBillableWorkModal}
					workQuantityFieldIds={workQuantityFieldIds}
					workTypeFieldIds={workTypeFieldIds}
				/>
			);
		}
		return null;
	};

	const isNameChangeDisabled = !edit;
	let previewBtnClassName = 'btn btn-toggle';
	previewBtnClassName = isPreviewInFocus ? `${previewBtnClassName} active` : previewBtnClassName;

	return (
		<div className="form-box">
			<div className="report-block-form__fields-header">
				<div>
					<div className="report-block-form__header-title">{reportTypeName}</div>
					{description && <div className="report-block-form__fields-header--subtitle">{description}</div>}
				</div>
				<div className="report-block-form__header-buttons">
					{showPreviewToggle &&
						<a className={previewBtnClassName} onClick={onPreviewClick} role="button">
							<span className="icon-visibility_on" />
							Preview
						</a>
					}
					{!isNameChangeDisabled &&
						<Button
							label="Edit"
							onClick={openModal}
							style="secondary"
						/>
					}
					<Button
						disabled={isSaveDisabled}
						label="Save"
						onClick={onSaveClick}
						style="primary"
					/>
				</div>
			</div>
			{hasBillableWork && (
				<div className="report-block-form__billable-work">
					<div className="report-block-form__billable-work__title-container">
						<div className="report-block-form__billable-work__title-container__title">
							BILLABLE WORK
						</div>
						{showBillableWorkList
							? <span className="icon-down" onClick={hideBillableWorkList} />
							: <span className="icon-up" onClick={openShowBillableWorkList} />
						}
					</div>
					<div className="report-block-form__billable-work__list">
						{showBillableWorkList && billableWorks?.map(renderBillableWork)}
					</div>
					{showBillableWorkList &&
						<div className="report-block-form__billable-work__actions">
							<a className="report-block-form__billable-work__actions__add" onClick={openBillableWorkModal} role="button">
								<span className="icon icon-plus" />
								<span>Add Billable Work</span>
							</a>
						</div>
					}
				</div>
			)}
			<ReportTypeModal
				closeModal={closeModal}
				initialDescription={description}
				initialName={reportTypeName}
				onSave={onNameChange}
				showModal={showEditModal}
			/>
			{renderBillableWorkModal()}
		</div>
	);
};

export default React.memo(ReportTypeFormHeader);
