import * as React from 'react';

interface Props {
	elementName: string;
	elementValue: string | number;
}

const TooltipElement: React.FC<Props> = (props) => {

	const {
		elementName,
		elementValue,
	} = props;

	return (
		<div className="schedule-board-production-data-tooltip-data">
			<span className="schedule-board-production-data-tooltip-first-column">{elementName}</span>
			<span>{elementValue}</span>
		</div>
	);
};

export default TooltipElement;
