import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import { MAX_JOB_STATUS_DESCRIPTION_LENGTH, MAX_JOB_STATUS_NAME_LENGTH } from 'ab-common/constants/value';

import type JobStatusRequestModel from 'ab-requestModels/jobStatus.requestModel';

export function validate(values: JobStatusRequestModel) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'A name is required';
	} else if (values.name.length > MAX_JOB_STATUS_NAME_LENGTH) {
		errors.name = `Name cannot have more than ${MAX_JOB_STATUS_NAME_LENGTH} characters`;
	}

	if (!!values.description && values.description.length > MAX_JOB_STATUS_DESCRIPTION_LENGTH) {
		errors.description = `Description cannot have more than ${MAX_JOB_STATUS_DESCRIPTION_LENGTH} characters`;
	}

	if (!values.color) {
		errors.color = 'Color is required';
	}

	return errors;
}
