import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-constants/routes/client';

type Props = CustomRouteComponentProps;

const PasswordResetSuccess: React.FC<Props> = (props) => {
	const { location: { state: { orgAlias } }, history } = props;

	const goToLogin = React.useCallback(() => {
		history.push(CLIENT.AUTH.LOGIN(orgAlias));
	}, [history, orgAlias]);

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon form-box__icon--success icon-check" />
				</div>
				<h2>Your password has been successfuly reset</h2>
				<h4 className="light">In order to start using the platform, please <Link to={CLIENT.AUTH.LOGIN(orgAlias)}>log in</Link>.</h4>
				<div className="form-box__footer">
					<Button
						fullWidth={true}
						label="Log in"
						onClick={goToLogin}
						style="primary"
						type="button"
					/>
				</div>
			</div>
		</div>
	);
};

export default withRouter(PasswordResetSuccess);
