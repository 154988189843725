import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-constants/routes/client';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

interface OwnProps {
	workOrderId: number;
	orgAlias: string;
	companyName: string;
	text?: string;
	disabled?: boolean;
	onHide: () => void;
	scheduleBoardView: ScheduleBoardView;
}

type Props = OwnProps & CustomRouteComponentProps;

const EditButton = ({ scheduleBoardView, workOrderId, orgAlias, companyName, text = 'Edit', disabled, onHide, history }: Props) => {

	let originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);

	if (scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW) {
		originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName);
	}

	const goToEdit = React.useCallback(() => {
		onHide();
		history.push(
			CLIENT.COMPANY.WORK_ORDERS.ORDER(`${workOrderId}`, orgAlias, companyName),
			{ originUrl, originLabel: 'Schedule Board', orgAlias }
		);
	}, [companyName, history, onHide, orgAlias, originUrl, workOrderId]);

	return (
		<Button
			disabled={disabled}
			label={text || 'Edit'}
			onClick={goToEdit}
			style="secondary"
		/>
	);

};

export default withRouter(EditButton);
