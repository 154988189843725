import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import Checkbox from 'af-components/Controls/Checkbox';
import LabelWithColor from 'af-components/LabelWithColor';

import type ScheduleBoardWorkOrderVM from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';
import type { ScheduleBoardEmployeePerDiemAssignmentTypeVM } from 'ab-viewModels/scheduleBoardEmployeePerDiemAssignment.viewModel';

interface OwnProps {
	index: number;
	isEditable: boolean;
	perDiemAssignments: ScheduleBoardEmployeePerDiemAssignmentTypeVM;
	perDiemCheck: (workOrderId: number, event: React.ChangeEvent<HTMLInputElement>) => void;
	removeAssignment: (workOrderId: number, workOrderCode: string, resourceLookupId?: number) => void;
	showPerDiem: boolean;
	workOrder: ScheduleBoardWorkOrderVM;
	resourceLookupId?: number;
}

type Props = OwnProps;

const NON_DELETABLE_WO_STATUSES = {
	[WorkOrderStatus.LOCKED]: true,
	[WorkOrderStatus.CANCELED]: true,
};

class NonAssignableResourceAssignment extends React.PureComponent<Props> {

	onRemoveAssignment = () => {
		const { removeAssignment, workOrder, resourceLookupId } = this.props;
		removeAssignment(workOrder.id, workOrder.code, resourceLookupId);
	};

	onPerDiemCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { perDiemCheck, workOrder } = this.props;
		perDiemCheck(workOrder.id, event);
	};

	render() {
		const { workOrder, perDiemAssignments, index, isEditable, showPerDiem } = this.props;

		const hasPerDiem = !!perDiemAssignments[workOrder.id];

		const canDelete = isEditable && !workOrder.isPaused && !NON_DELETABLE_WO_STATUSES[workOrder.status];

		return (
			<div
				className="schedule-board-modal__resource__assignment"
				key={`${workOrder.code}-${index}`}
			>
				<div className="schedule-board-modal__resource__assignment__name">
					<div className="schedule-board-modal__resource__assignment__name__work-order">{workOrder.code}</div>
					<LabelWithColor color={workOrder.crewTypeColor} text={workOrder.crewTypeName} />
				</div>
				<div className="schedule-board-modal__resource__assignment__controls">
					{showPerDiem && perDiemAssignments &&
						<Checkbox
							handleChange={this.onPerDiemCheck}
							inline={false}
							isChecked={hasPerDiem}
							isDisabled={!isEditable}
							label="Per Diem"
						/>
					}
					{
						canDelete &&
						<Button
							icon="delete"
							onClick={this.onRemoveAssignment}
							style="link-danger"
							tooltip="Delete"
						/>
					}
				</div>
			</div>
		);
	}
}

export default NonAssignableResourceAssignment;
