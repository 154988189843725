import * as React from 'react';

import type { EntryEntity } from '@acceligentllc/shared/utils/timeSheetEntry';

import type TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import { TimeSheetWorkTypeDisplayInfo } from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimeSheetEntryType from '@acceligentllc/shared/enums/timeSheetEntryType';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import type { TimelineEntityDefinition } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import styles from './styles.module.scss';
import BaseSection from './BaseSection';
import type { PercentagesOfDateChanges, PercentagesForChangeToDueDate } from '../types';

const MINIMAL_TIMELINE_PERCENTAGE = 1;

interface Props {
	entity: EntryEntity<TimelineEntityDefinition>;
	percentageOfTimeline: number;
	roundedLeft: boolean;
	roundedRight: boolean;
	overlap: boolean;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
	workType: TimeSheetEntryWorkType;
	dueDate: string;
	sectionPercentagesForDueDate?: PercentagesForChangeToDueDate;
	dateChangesOnSectionInPercentages?: PercentagesOfDateChanges[];
	nextEntryIsGap: boolean;
}

const EntrySection: React.FC<Props> = (props: Props) => {

	const {
		entity,
		percentageOfTimeline,
		roundedLeft,
		roundedRight,
		overlap,
		employeeApprovalStatus,
		superintendentApprovalStatus,
		isManagerOrAdmin,
		workType,
		dueDate,
		sectionPercentagesForDueDate,
		dateChangesOnSectionInPercentages,
		nextEntryIsGap,
	} = props;

	if (entity.type !== TimelineEntityType.ENTRY) {
		throw new Error('Cannot show timeline section as an entry.');
	}

	const { entry } = entity;
	const sectionContainerStyle = getSectionContatinerStyle();
	const sectionBarClassName = getBarSectionStyle();
	const iconStyle = getIconStyle();
	const timelinePercentage = Math.max(percentageOfTimeline, MINIMAL_TIMELINE_PERCENTAGE);
	const icon = getIcon();

	return (
		<BaseSection
			dateChangesOnSectionInPercentages={dateChangesOnSectionInPercentages}
			dueDate={dueDate}
			endTime={entry.endTime}
			icon={icon}
			iconStyle={iconStyle}
			isManagerOrAdmin={isManagerOrAdmin}
			nextEntryIsGap={nextEntryIsGap}
			sectionBarClassName={sectionBarClassName}
			sectionContainerStyle={sectionContainerStyle}
			sectionPercentagesForDueDate={sectionPercentagesForDueDate}
			startTime={entry.startTime}
			timelinePercentage={timelinePercentage}
			workOrderCode={null}
			workType={overlap ? null : workType}
		/>
	);

	function getBarSectionStyle() {
		const isOutdated = employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.OUTDATED;
		const isApprovedBySI = superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.APPROVED;
		const isUnsignedManualEntry =
			entity.entry.type === TimeSheetEntryType.MANUAL && employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.UNSIGNED;

		const sectionBarClassNameArray = ['section', 'entry'];
		if (roundedLeft) {
			sectionBarClassNameArray.push('rounded-left');
		}
		if (roundedRight) {
			sectionBarClassNameArray.push('rounded-right');
		}
		if (isUnsignedManualEntry || isOutdated) {
			sectionBarClassNameArray.push('unsigned-manual');
		}
		if (isApprovedBySI) {
			sectionBarClassNameArray.push('si-signed');
		}
		if (overlap) {
			sectionBarClassNameArray.push('overlap');
		}

		return sectionBarClassNameArray.map((style) => styles[style]).join(' ');
	}

	function getIconStyle() {

		const isUnsignedManualEntry = entity.entry.type === TimeSheetEntryType.MANUAL
			&& employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.UNSIGNED;
		const isOutdated = employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.OUTDATED;
		const isApprovedBySI = superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.APPROVED;

		const iconClassNameArray = ['section__icon'];
		if (isUnsignedManualEntry || isOutdated) {
			iconClassNameArray.push('unsigned-manual');
		}
		if (isApprovedBySI) {
			iconClassNameArray.push('si-signed');
		}
		if (overlap) {
			iconClassNameArray.push('overlap');
		}

		return iconClassNameArray.map((style) => styles[style]).join(' ');
	}

	function getSectionContatinerStyle() {
		return styles['section-container'];
	}

	function getIcon() {
		if (overlap) {
			return 'warning';
		}
		return TimeSheetWorkTypeDisplayInfo[entity.entry.workType].icon;
	}
};

export default EntrySection;
