import * as React from 'react';

import { ColorPalette } from '@acceligentllc/shared/enums/color';

import type { EquipmentUtilizationViewModelShared } from 'ab-viewModels/equipmentUtilization.viewModel';
import type { LaborUtilizationViewModel } from 'ab-viewModels/laborUtilization.viewModel';

import type { CellInfo } from 'af-components/Table6';
import BarMeter from 'af-components/BarMeter';

type Props = Omit<CellInfo<EquipmentUtilizationViewModelShared | LaborUtilizationViewModel>, 'value'>;

const WorkingCell = ({ original }: Props) => {
	const { daysAssigned, daysAvailable, daysUnavailable, totalDays } = original;

	const workingPercentage = Math.round(daysAssigned / (totalDays || 1) * 100);
	let color: ColorPalette = ColorPalette.GREEN;
	if (workingPercentage < 25) {
		color = ColorPalette.RED;
	} else if (workingPercentage < 67) {
		color = ColorPalette.ORANGE;
	}

	const entries = [
		{ label: 'Scheduled', value: daysAssigned, color: color },
		{ label: 'Not Scheduled', value: daysAvailable, isHidden: true },
		{ label: 'Not Available', value: daysUnavailable, isHidden: true },
	];
	return (
		<div className="working">
			<span>{workingPercentage}% ({daysAssigned} days)</span>
			<BarMeter entries={entries} total={totalDays} unitLabel="days" withTooltip={true} />
		</div>
	);
};

export default WorkingCell;
