import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { RootState } from 'af-reducers';

import WorkOrderCancelModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/CancelModal';
import WorkOrderPauseModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/PauseModal';
import WorkOrderResumeModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/ResumeModal';
import WorkOrderDelayModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/DelayModal';
import WorkOrderCopyModal from 'af-root/scenes/Company/WorkOrders/Table/OrderCopyForm';
import NotifyParticipantsModal from 'af-root/scenes/Company/WorkOrders/Table/NotifyParticipantsModal';
import NotificationPreviewModal from 'af-root/scenes/Company/ScheduleBoard/Shared/OrderInfo/NotificationPreviewModal';

import ConfirmationModal from 'af-components/ConfirmationModal';

import * as WorkOrderActions from 'af-actions/workOrder';
import * as DailyTipActions from 'af-actions/dailyTip';
import * as NotifyActions from 'af-actions/notify';

import { TemplateNotificationEnum } from 'ab-enums/notifyType.enum';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';
import type { DailyTipViewModel } from 'ab-viewModels/dailyTip.viewModel';
import type { ScheduleBoardWorkOrderResourceLookupsViewModel } from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardResourceLookup.viewModel';
import type { WorkOrderRevisionEmployee, WorkOrderRevisionTemporaryEmployee } from 'ab-viewModels/workOrderRevisionItem.viewModel';

import type { WorkOrderCancelForm, WorkOrderDelayForm, WorkOrderPauseForm } from 'ab-requestModels/workOrder.requestModel';
import type WorkOrderCopyRM from 'ab-requestModels/workOrder/copyWorkOrders.requestModel';

interface OwnProps {
	cancelOrder: (workOrderId: string, cancelForm: WorkOrderCancelForm) => Promise<void>;
	pauseOrder: (workOrderId: string, pauseForm: WorkOrderPauseForm) => Promise<void>;
	resumeOrder: (workOrderId: string) => Promise<void>;
	closeOrderInfoModal: () => void;
	deleteOrder: (workOrderId: string) => void;
	notificationTemplate: string | undefined;
	publishOrder: (workOrderId: number, form?: WorkOrderDelayForm) => Promise<void>;
	workOrder: Nullable<WorkOrderModalViewModel>;
	/** Modal visibility setters */
	setCancelModalVisibility: (isVisible: boolean) => void;
	setPauseModalVisibility: (isVisible: boolean) => void;
	setResumeModalVisibility: (isVisible: boolean) => void;
	setCopyModalVisibility: (isVisible: boolean) => void;
	setDelayModalVisibility: (isVisible: boolean) => void;
	setDeleteModalVisibility: (isVisible: boolean) => void;
	setNotificationPreviewModalVisibility: (isVisible: boolean) => void;
	setNotifyParticipantsModalVisibility: (isVisible: boolean) => void;
	setWorkOrderModalVisibility: (isVisible: boolean) => void;
	setCancelWarningModalVisibility: (isVisible: boolean) => void;
	/** Modal visibility flags */
	showCancelModal: boolean;
	showPauseModal: boolean;
	showResumeModal: boolean;
	showCopyModal: boolean;
	showDelayModal: boolean;
	showDeleteModal: boolean;
	showNotificationPreviewModal: boolean;
	showNotifyParticipantsModal: boolean;
	showCancelWarningModal: boolean;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const DELETE_WARNING_BODY = (
	<>
		Are you sure you want to delete this Work Order?
		<br />
		This action cannot be undone.
	</>
);

const CANCEL_WARNING_BODY = (
	<>
		Work Order with Finalized WO Report can no longer be canceled
	</>
);

const resourceToResourceIdReducer = (resourceLookup: Nullable<ScheduleBoardWorkOrderResourceLookupsViewModel>) => {
	return Object.values(resourceLookup ?? {})?.reduce((_acc, _resource) => {
		if (_resource.workOrderEmployeeId && _resource.employeeId) {
			_acc.workOrderEmployees[_resource.workOrderEmployeeId] = _resource.employeeId;
		} else if (_resource.workOrderTemporaryEmployeeId && _resource.temporaryEmployeeId) {
			_acc.workOrderTemporaryEmployees[_resource.workOrderTemporaryEmployeeId] = _resource.temporaryEmployeeId;
		}
		return _acc;
	}, {
		workOrderEmployees: {},
		workOrderTemporaryEmployees: {},
	} as {
		workOrderEmployees: { [employeeId: number]: number; };
		workOrderTemporaryEmployees: { [temporaryEmployeeId: number]: number; };
	});
};

const determineNotificationType = (status: WorkOrderStatus, isPaused: boolean, latestNotificationType: string | undefined) => {
	if (status === WorkOrderStatus.PUBLISHED) {
		if (isPaused) {
			return TemplateNotificationEnum.PAUSED_WORK_ORDER;
		} else {
			if (latestNotificationType && latestNotificationType === TemplateNotificationEnum.PAUSED_WORK_ORDER) {
				return TemplateNotificationEnum.RESUMED_WORK_ORDER;
			}
		}
		return TemplateNotificationEnum.PUBLISHED_WORK_ORDER;
	}
	return TemplateNotificationEnum.CANCELED_WORK_ORDER;
};

const OrderActionModals: React.FC<Props> = (props) => {
	const {
		isLoading,
		showDeleteModal,
		showDelayModal,
		showCancelModal,
		showNotifyParticipantsModal,
		showNotificationPreviewModal,
		showCopyModal,
		showCancelWarningModal,
		participantsEmailAndSmsStatus,
		temporaryParticipantsEmailAndSmsStatus,
		autoNotifyAt,
		autoNotifyOnPublish,
		loadRevisions,
		notifyTemporaryLabor,
		closeOrderInfoModal,
		setDeleteModalVisibility,
		setCancelModalVisibility,
		setPauseModalVisibility,
		setResumeModalVisibility,
		setWorkOrderModalVisibility,
		setDelayModalVisibility,
		setCopyModalVisibility,
		setNotifyParticipantsModalVisibility,
		setNotificationPreviewModalVisibility,
		setCancelWarningModalVisibility,
		scheduleWorkOrderParticipants,
		notifyWorkOrderParticipants,
		employees,
		temporaryEmployees,
		publishOrder,
		copyWorkOrder,
		cancelOrder,
		pauseOrder,
		deleteOrder,
		resumeOrder,
		createCustomDailyTip,
		resourceLookup,
		dailyTip = {} as DailyTipViewModel,
		initialCopyModalData,
		workOrder,
		notificationTemplate,
		showPauseModal,
		showResumeModal,
	} = props;

	const [workOrderEmployees, setWorkOrderEmployees] = React.useState<{ [workOrderEmployeeId: number]: number; }>({});
	const [workOrderTemporaryEmployees, setWorkOrderTemporaryEmployees] = React.useState<{ [workOrderTemporaryEmployeeId: number]: number; }>({});
	const [revisionEmployees, setRevisionEmployees] = React.useState<{ [revisionId: number]: WorkOrderRevisionEmployee[]; }>({});
	const [revisionTemporaryEmployees, setRevisionTemporaryEmployees] = React.useState<{ [revisionId: number]: WorkOrderRevisionTemporaryEmployee[]; }>({});

	const loadRevisionEmployees = React.useCallback(async () => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		const revisions = await loadRevisions(workOrder.id);
		const newRevisionEmployees = {} as typeof revisionEmployees;
		const newRevisionTemporaryEmployees = {} as typeof revisionTemporaryEmployees;

		revisions.forEach((revision) => {
			// using instanceof causes runtime error "Can't resolve 'ab-models/workOrder.model' in node/src/dataTransferObjects/viewModels'
			if ('employees' in revision && revision.employees.length > 0 && revision.id) {
				newRevisionEmployees[revision.id] = revision.employees;
			}
			if ('temporaryEmployees' in revision && revision.temporaryEmployees.length > 0 && revision.id) {
				newRevisionTemporaryEmployees[revision.id] = revision.temporaryEmployees;
			}
		});
		setRevisionEmployees(newRevisionEmployees);
		setRevisionTemporaryEmployees(newRevisionTemporaryEmployees);
	}, [loadRevisions, workOrder]);

	React.useEffect(() => {
		loadRevisionEmployees();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const {
			workOrderEmployees: _workOrderEmployees,
			workOrderTemporaryEmployees: _workOrderTemporaryEmployees,
		} = resourceToResourceIdReducer(resourceLookup);
		setWorkOrderEmployees(_workOrderEmployees);
		setWorkOrderTemporaryEmployees(_workOrderTemporaryEmployees);
	}, [resourceLookup]);

	React.useEffect(() => {
		loadRevisionEmployees();
	}, [workOrder?.id, workOrder?.revision, loadRevisionEmployees]);

	const closeDeleteModal = React.useCallback(() => {
		closeOrderInfoModal();
		setDeleteModalVisibility(false);
	}, [closeOrderInfoModal, setDeleteModalVisibility]);

	const closeCancelModal = React.useCallback(() => {
		closeOrderInfoModal();
		setCancelModalVisibility(false);
	}, [closeOrderInfoModal, setCancelModalVisibility]);

	const closePauseModal = React.useCallback(() => {
		closeOrderInfoModal();
		setPauseModalVisibility(false);
	}, [closeOrderInfoModal, setPauseModalVisibility]);

	const closeResumeModal = React.useCallback(() => {
		closeOrderInfoModal();
		setResumeModalVisibility(false);
	}, [closeOrderInfoModal, setResumeModalVisibility]);

	const closeDelayModal = React.useCallback(() => {
		setWorkOrderModalVisibility(true);
		setDelayModalVisibility(false);
	}, [setWorkOrderModalVisibility, setDelayModalVisibility]);

	const closeCopyModal = React.useCallback(() => {
		closeOrderInfoModal();
		setCopyModalVisibility(false);
	}, [closeOrderInfoModal, setCopyModalVisibility]);

	const closeNotifyParticipantsModal = React.useCallback(() => {
		setWorkOrderModalVisibility(true);
		setNotifyParticipantsModalVisibility(false);
	}, [setWorkOrderModalVisibility, setNotifyParticipantsModalVisibility]);

	const closeNotificationPreviewModal = React.useCallback(() => {
		setWorkOrderModalVisibility(true);
		setNotificationPreviewModalVisibility(false);
	}, [setWorkOrderModalVisibility, setNotificationPreviewModalVisibility]);

	const closeCancelWarningModal = React.useCallback(() => {
		setCancelWarningModalVisibility(false);
		setWorkOrderModalVisibility(true);
	}, [setCancelWarningModalVisibility, setWorkOrderModalVisibility]);

	const onCancelOrder = React.useCallback(async (cancelForm: WorkOrderCancelForm) => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		await cancelOrder(`${workOrder.id}`, cancelForm);

		if (showCancelModal) {
			closeCancelModal();
		}
	}, [cancelOrder, closeCancelModal, showCancelModal, workOrder]);

	const onPauseOrder = React.useCallback(async (form: WorkOrderPauseForm) => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		await pauseOrder(`${workOrder.id}`, form);

		if (showPauseModal) {
			closePauseModal();
		}
	}, [workOrder, pauseOrder, showPauseModal, closePauseModal]);

	const onResumeOrder = React.useCallback(async () => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		await resumeOrder(`${workOrder.id}`);

		if (showResumeModal) {
			closeResumeModal();
		}
	}, [workOrder, resumeOrder, showResumeModal, closeResumeModal]);

	const onDeleteOrder = React.useCallback(() => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		deleteOrder(`${workOrder.id}`);
		closeDeleteModal();
	}, [workOrder, deleteOrder, closeDeleteModal]);

	const onCreateCustomDailyTip = React.useCallback(async (message: string) => {
		if (!workOrder) {
			throw new Error('Work Order not defined');
		}
		const { dueDate } = workOrder;

		const customTip = await createCustomDailyTip(message, dueDate, true);
		return customTip.id;
	}, [workOrder, createCustomDailyTip]);

	const notifyParticipants = React.useCallback(async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		workOrderId: number,
		message?: string,
		isEdited?: boolean
	) => {
		if (!workOrder) {
			throw new Error('Work Order not defined');
		}
		const { status, isPaused, dueDate: _dueDate } = workOrder;

		let customTipId = 0;
		if (message) {
			customTipId = isEdited ? await onCreateCustomDailyTip(message) : dailyTip!.id;
		}

		const latestNotificationType = workOrder.workOrderResourceLookups[0]?.notification?.notificationType;

		await notifyWorkOrderParticipants({
			dueDate: TimeUtils.formatDate(_dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY),
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			dailyTipId: !!customTipId ? customTipId : null,
			notificationType: determineNotificationType(status, isPaused, latestNotificationType),
			workOrderId,
		});
	}, [workOrder, notifyWorkOrderParticipants, dailyTip, onCreateCustomDailyTip]);

	const onCopyOrder = React.useCallback(async (copyForm: WorkOrderCopyRM) => {
		await copyWorkOrder(copyForm);
		closeCopyModal();
	}, [copyWorkOrder, closeCopyModal]);

	const onPublish = React.useCallback((publishForm: WorkOrderDelayForm) => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		return publishOrder(workOrder?.id, publishForm);
	}, [workOrder, publishOrder]);

	const scheduleAutoNotify = React.useCallback(async (
		workOrderEmployeeIdsForSms: number[],
		workOrderEmployeeIdsForEmail: number[],
		workOrderTemporaryEmployeeIdsForSms: number[],
		workOrderTemporaryEmployeeIdsForEmail: number[],
		message?: string,
		/** Map of employee ids -> work order ids removed from WO */
		removedEmployeeIdsMap?: { [employeeId: number]: number; },
		/** Map of employee ids -> work order ids removed from WO */
		removedTemporaryEmployeeIdsMap?: { [temporaryEmployeeId: number]: number; }
	) => {
		if (!workOrder) {
			throw new Error('Work order not found');
		}
		const { status, isPaused, dueDate, workOrderResourceLookups } = workOrder;
		const latestNotificationType = workOrderResourceLookups[0]?.notification?.notificationType;

		const customTipId = message ? await onCreateCustomDailyTip(message) : undefined;
		await scheduleWorkOrderParticipants({
			dueDate,
			employeeEmailIds: workOrderEmployeeIdsForEmail,
			employeeSmsIds: workOrderEmployeeIdsForSms,
			tempEmployeeEmailIds: workOrderTemporaryEmployeeIdsForEmail,
			tempEmployeeSmsIds: workOrderTemporaryEmployeeIdsForSms,
			dailyTipId: customTipId,
			notificationType: determineNotificationType(status, isPaused, latestNotificationType),
			removedEmployeeIdsMap,
			removedTemporaryEmployeeIdsMap,
		});
	}, [onCreateCustomDailyTip, scheduleWorkOrderParticipants, workOrder]);

	const workOrderStatus = workOrder?.status;
	const isPublished: boolean = workOrderStatus === WorkOrderStatus.PUBLISHED;
	const isCanceled: boolean = workOrderStatus === WorkOrderStatus.CANCELED;
	const isOutdated: boolean = workOrderStatus === WorkOrderStatus.OUTDATED;
	const isPaused: boolean = workOrder?.isPaused ?? false;

	return workOrder ? (
		<>
			<WorkOrderCancelModal
				closeCancelModal={closeCancelModal}
				onSubmit={onCancelOrder}
				showCancelModal={showCancelModal}
				workOrderCode={workOrder?.code}
			/>
			<WorkOrderPauseModal
				closePauseModal={closePauseModal}
				onSubmit={onPauseOrder}
				showPauseModal={showPauseModal}
				workOrderCode={workOrder?.code}
			/>
			<WorkOrderResumeModal
				closeResumeModal={closeResumeModal}
				resumeWorkOrder={onResumeOrder}
				showResumeModal={showResumeModal}
				workOrderCode={workOrder?.code}
			/>
			<WorkOrderDelayModal
				closeDelayModal={closeDelayModal}
				onSubmit={onPublish}
				showDelayModal={showDelayModal}
				workOrderCode={workOrder?.code}
			/>
			{showNotifyParticipantsModal && participantsEmailAndSmsStatus && (!!employees || !!temporaryEmployees) && notificationTemplate &&
				<NotifyParticipantsModal
					autoNotifyAt={autoNotifyAt}
					autoNotifyOnPublish={autoNotifyOnPublish}
					closeModal={closeNotifyParticipantsModal}
					dailyTip={dailyTip?.message ?? ''}
					isLoading={isLoading}
					notificationTemplate={notificationTemplate}
					notifyParticipants={notifyParticipants}
					notifyTemporaryLabor={notifyTemporaryLabor}
					order={workOrder}
					participantsEmailAndSmsStatus={participantsEmailAndSmsStatus}
					revisionEmployees={revisionEmployees}
					revisionTemporaryEmployees={revisionTemporaryEmployees}
					scheduleAutoNotify={scheduleAutoNotify}
					scheduleBoardEmployees={employees}
					scheduleBoardTemporaryEmployees={temporaryEmployees}
					showModal={showNotifyParticipantsModal}
					temporaryParticipantsEmailAndSmsStatus={temporaryParticipantsEmailAndSmsStatus}
					workOrderEmployees={workOrderEmployees}
					workOrderTemporaryEmployees={workOrderTemporaryEmployees}
				/>
			}
			{(isPublished || isCanceled || isOutdated || isPaused) && !!notificationTemplate &&
				<NotificationPreviewModal
					closeModal={closeNotificationPreviewModal}
					isLoading={isLoading}
					notificationTemplate={notificationTemplate}
					showModal={showNotificationPreviewModal}
				/>
			}
			<WorkOrderCopyModal
				closeCopyModal={closeCopyModal}
				initialFormData={initialCopyModalData}
				onSubmit={onCopyOrder}
				showCopyModal={showCopyModal}
			/>
			<ConfirmationModal
				body={DELETE_WARNING_BODY}
				closeModal={closeDeleteModal}
				confirmAction={onDeleteOrder}
				confirmText="Delete Work Order"
				modalStyle="danger"
				showModal={showDeleteModal}
				size="md"
				title={`Delete Work Order (${workOrder?.code ?? ''})?`}
			/>
			<ConfirmationModal
				body={CANCEL_WARNING_BODY}
				closeModal={closeCancelWarningModal}
				confirmAction={closeCancelWarningModal}
				confirmText="OK"
				modalStyle="warning"
				showCancel={false}
				showModal={showCancelWarningModal}
				size="sm"
				title="Can't Cancel Work Order"
			/>
		</>
	) : null;
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { workOrder } = ownProps;
	const { workOrdersByDateDictionary, employees, temporaryEmployees } = state.scheduleBoard;
	const { company } = state.company;

	const dueDate = workOrder?.dueDate;
	const stateOnDate = dueDate ? workOrdersByDateDictionary?.[dueDate] : undefined;

	const selectedDayOfWeek = dueDate && TimeUtils.parseMoment(dueDate, TimeFormat.DATE_ONLY)?.day();
	const dayBefore = selectedDayOfWeek ? selectedDayOfWeek - 1 : 6;

	const isWorkOrderCanceled = workOrder?.status === WorkOrderStatus.CANCELED;
	const employeeStatuses = isWorkOrderCanceled
		? stateOnDate?.assignedCanceledNotificationStatuses
		: stateOnDate?.assignedPublishedNotificationStatuses;
	const temporaryEmployeeStatuses = isWorkOrderCanceled
		? stateOnDate?.assignedCanceledTempLaborNotificationStatuses
		: stateOnDate?.assignedPublishedTempLaborNotificationStatuses;

	const resourceLookup = isWorkOrderCanceled
		? stateOnDate?.canceledWorkOrderResourceLookups
		: stateOnDate?.workOrderResourceLookups;

	return {
		participantsEmailAndSmsStatus: employeeStatuses ?? null,
		temporaryParticipantsEmailAndSmsStatus: temporaryEmployeeStatuses ?? null,
		isLoading: state.http.isFetching,
		autoNotifyOnPublish: company?.notification?.notifyOnPublish ?? null,
		notifyTemporaryLabor: company?.notification.notifyTemporaryLabor ?? false,
		autoNotifyAt: dueDate
			? company?.notification?.notifyOnDay?.[dayBefore] ?? null
			: null,
		employees,
		temporaryEmployees,
		initialCopyModalData: state.workOrder.copyWorkOrderModalData,
		dailyTip: stateOnDate?.dailyTip,
		resourceLookup: resourceLookup ?? null,
	};
}

function mapDispatchToProps() {
	return {
		copyWorkOrder: WorkOrderActions.copyWorkOrder,
		createCustomDailyTip: DailyTipActions.create,
		notifyWorkOrderParticipants: NotifyActions.sendTemplateNotificationSingleWO,
		scheduleWorkOrderParticipants: NotifyActions.scheduleForAutoNotify,
		loadRevisions: WorkOrderActions.lazyLoadRevisions,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(OrderActionModals);
