import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import { EquipmentCostCategoryLabel } from '@acceligentllc/shared/enums/equipmentCostCategory';

import type { EquipmentCostCategoryItem } from 'ab-viewModels/editableTree.viewModel';

export interface EquipmentCostCategoryForm {
	name: string;
	color: ColorPalette; // It will be pulled from field as a single value in array
}

export class EquipmentCostCategoryFM {

	type: Nullable<string>; // When creating level 3, type is inherited through parentId
	parentId: Nullable<number>; // Only available when creating level 3
	color: ColorPalette; // Only available when creating level 3
	name: string;

	static fromTree(parent: EquipmentCostCategoryItem, form: EquipmentCostCategoryForm): EquipmentCostCategoryFM {
		// Root Category is mocked from type so it needs to be specially handled
		const isFromRootCategory = parent.nodeId < 0;

		const typeName = isFromRootCategory ? parent.name : null;
		const type = Object.keys(EquipmentCostCategoryLabel).find((_key) => EquipmentCostCategoryLabel[_key] === typeName);

		return {
			type: type ?? null,
			parentId: !isFromRootCategory ? parent.nodeId : null,
			name: form.name,
			color: form.color,
		};
	}
}
