import type AccountPermission from '@acceligentllc/shared/enums/accountPermission';
import UserPermission from '@acceligentllc/shared/enums/userPermission';

import type PermissionType from 'ab-domain/models/permission/base';

import type PermissionBase from 'ab-domain/models/permission/base';

import type { PagePermissionType, PagePermissionsTree } from 'ab-enums/pagePermissions.enum';

// FIXME: this file should be reworked so that parts of it can be moved into the domain layer

// Private Functions

const _hasAnyPermission = (
	allowedCalculatedType: PagePermissionType,
	availableCompanyPermissions: string[] = [],
	isCompanyAdmin: boolean = false,
	availableRole: UserPermission = UserPermission.MEMBER
): boolean => {

	// Action, page etc. requires at least one permission, any permission
	if (
		allowedCalculatedType.isOrganizationMemberAllowed &&
		allowedCalculatedType.isCompanyMemberAllowed &&
		!allowedCalculatedType.permissions?.length
	) {
		return true;
	}

	const isOwnerOrAdmin: boolean = _isOwnerOrAdmin(availableRole);

	if (isOwnerOrAdmin) {
		return true;
	}

	if (!_hasOrganizationPermissions(allowedCalculatedType.isOrganizationMemberAllowed, isOwnerOrAdmin)) {
		return false;
	}

	if (isCompanyAdmin) {
		return true;
	} else if (!allowedCalculatedType.isCompanyMemberAllowed) {
		return false;
	}

	return availableCompanyPermissions.some((_availableP: string) => (
		allowedCalculatedType.permissions?.includes(_availableP as AccountPermission))
	);
};

const _hasOrganizationPermissions = (isAllowedForMember: boolean, isOwnerOrAdmin: boolean): boolean => {
	if (isAllowedForMember) {
		return true;
	}
	return isOwnerOrAdmin;
};

const _isOwnerOrAdmin = (role: UserPermission): boolean => {
	return role === UserPermission.OWNER || role === UserPermission.ADMIN;
};

const PERMISSION_TYPE_KEYS = ['permissions', 'isOrganizationMemberAllowed', 'isCompanyMemberAllowed'] as const;
const _isPermissionType = (pagePermissions: PagePermissionsTree | PagePermissionType): pagePermissions is PagePermissionType => {
	return PERMISSION_TYPE_KEYS.some((_key) => _key in pagePermissions);
};

// Public Functions

/** @deprecated use `Permission.asString` on BE */
export const getStringPermission = (permission: PermissionBase): string => permission.permission;

export const hasAnyStringPermission = (
	allowedCalculatedType: PagePermissionType,
	availableCompanyPermissions: string[] | undefined = [],
	isCompanyAdmin: boolean | undefined = false,
	availableRole: UserPermission | undefined = UserPermission.MEMBER
): boolean => {
	return _hasAnyPermission(allowedCalculatedType, availableCompanyPermissions, isCompanyAdmin, availableRole);
};

export const hasAnyPermission = (
	allowedCalculatedType: PagePermissionType,
	availableCompanyPermissions: PermissionType[] = [],
	isCompanyAdmin: boolean = false,
	availableRole: UserPermission = UserPermission.MEMBER
): boolean => {
	const availableStringPermissions: string[] = availableCompanyPermissions.map(getStringPermission);
	return _hasAnyPermission(allowedCalculatedType, availableStringPermissions, isCompanyAdmin, availableRole);
};

export const gatherPermissions = (pagePermissions: PagePermissionsTree | PagePermissionType): PagePermissionType => {
	if (_isPermissionType(pagePermissions)) {
		return pagePermissions;
	}
	const permission = Object.keys(pagePermissions).reduce(
		(accumulator: PagePermissionType, key: string) => {
			const leaf: PagePermissionType = gatherPermissions(pagePermissions[key]);
			if (!leaf.permissions?.length) {
				// if one leaf is empty, the grouped permission type will also be empty
				accumulator.permissions = null;
			} else if (accumulator.permissions) {
				accumulator.permissions.push(...leaf.permissions);
			}
			accumulator.isOrganizationMemberAllowed = accumulator.isOrganizationMemberAllowed || leaf.isOrganizationMemberAllowed;
			accumulator.isCompanyMemberAllowed = accumulator.isCompanyMemberAllowed || leaf.isCompanyMemberAllowed;
			return accumulator;
		},
		{
			isOrganizationMemberAllowed: false,
			isCompanyMemberAllowed: false,
			permissions: [],
		}
	);

	if (!permission.permissions) {
		permission.permissions = [];
	}
	return permission;
};

export const isAllowed = (
	visibleFor: PagePermissionType | PagePermissionsTree,
	companyPermissions: string[] | undefined,
	isCompanyAdmin: boolean | undefined,
	userRole: UserPermission | undefined
) => {
	const allowedCalculatedType: PagePermissionType = gatherPermissions(visibleFor);
	return hasAnyStringPermission(allowedCalculatedType, companyPermissions, isCompanyAdmin, userRole);
};
