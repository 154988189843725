import * as React from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import type { WrappedFieldProps } from 'redux-form';

import { bemElement } from 'ab-utils/bem.util';

import MimeType from 'ab-enums/mimeType.enum';
import { Button } from '@acceligentllc/storybook';

interface OwnProps {
	containerClassName?: string;
	onChange?: () => void;
	onClear?: () => void;
	mimeType?: MimeType;
	isReadonly?: boolean;
}

type Props = OwnProps & WrappedFieldProps;

const Signature: React.FC<Props> = (props) => {
	const { containerClassName, onChange, onClear, input, isReadonly = false, mimeType = MimeType.PNG } = props;

	const [isTextContainerVisible, setIsTextContainerVisible] = React.useState<boolean>(true);

	const canvasRef = React.useRef<ReactSignatureCanvas>(null);

	React.useEffect(() => {
		if (input.value) {
			canvasRef.current?.fromDataURL(input.value);
			setIsTextContainerVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (isReadonly) {
			canvasRef.current?.off();
			setIsTextContainerVisible(false);
			canvasRef.current?.clear();
			canvasRef.current?.fromDataURL(input.value);
		} else {
			canvasRef.current?.on();
			canvasRef.current?.clear();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReadonly]); // Only change if isReadonly flag changes, avoid unnecessary rendering

	const onBegin = React.useCallback(() => {
		setIsTextContainerVisible(false);
	}, []);

	const onEnd = React.useCallback(() => {
		const signatureImage = canvasRef.current?.toDataURL(mimeType);
		input.onChange(signatureImage);
		onChange?.();
	}, [input, onChange, mimeType]);

	const onClearClick = React.useCallback(() => {
		canvasRef.current?.clear();
		input.onChange(null);
		onChange?.();
		onClear?.();
	}, [input, onChange, onClear]);

	const containerClass = `${bemElement('signature', 'pad-container')} ${containerClassName ?? ''}`;
	const showClear = !isTextContainerVisible && !isReadonly;

	return (
		<div className={containerClass}>
			{showClear &&
				<div className="signature__clear-button">
					<Button
						icon="close"
						label="Clear"
						onClick={onClearClick}
						style="link"
					/>
				</div>
			}
			<ReactSignatureCanvas
				canvasProps={{
					className: 'signature__pad',
				}}
				onBegin={onBegin}
				onEnd={onEnd}
				ref={canvasRef}
			/>
			<div className={bemElement('signature', 'text-container', [!isTextContainerVisible ? 'invisible' : ''])}>
				<span className="content">Draw Signature Here</span>
			</div>
		</div>
	);
};

export default React.memo(Signature);
