import * as React from 'react';
import { useHistory } from 'react-router';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext, Row } from '@tanstack/react-table';
import { useOutletContext } from 'react-router-dom-v5-compat';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import InvoiceBillingLookupType from '@acceligentllc/shared/enums/invoiceBillingLookupType';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import type { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type UserGroupAndPmVM from 'ab-viewModels/workRequest/userGroupAndPmVM.viewModel';

import * as SettingsKeys from 'af-constants/settingsKeys';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

import * as SettingsUtils from 'af-utils/settings.util';

import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import DateFilter from 'af-components/DateFilter';
import TextCell from 'af-components/Table/Cells/TextCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import type { TableProps } from 'af-components/Table/types';

import * as jobActions from 'af-actions/jobs';
import * as AttachmentActions from 'af-actions/attachment';
import ConfirmationModal from 'af-components/ConfirmationModal';
import Dropdown from 'af-components/Controls/Dropdown';
import PercentageCell from 'af-components/Table/Cells/PercentageCell';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import { bemElement } from 'ab-utils/bem.util';
import { getId } from 'ab-utils/array.util';

import CLIENT from 'af-routes/client';

import type InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';

import styles from './styles.module.scss';
import TableHeader from './TableHeader';
import { InvoiceStatusFilterItem } from './InvoiceStatusFilterItem';
import { InvoiceFM } from './CreateOrEdit/formModel';
import type { JobPreviewOutletContext } from '../types';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type TableHeaderData = {
	totalInvoiced: number;
	totalPaid: number;
	outstandingDebt: number;
};

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

type Props = ConnectedProps<typeof connector> & SettingProps;

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.INVOICES_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.INVOICES_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.INVOICES_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const Invoices: React.FC<Props> = (props) => {
	const {
		getInvoicesTable,
		getInvoicesTotals,
		updateInvoice,
		deleteInvoice,
		sendManualReminderForInvoice,
		getUserGroupAndPM,
		downloadAttachmentsAsZip,
	} = props;

	const { jobId, isSendingInvoiceNotificationsEnabled, billingContact, jobCode, orgAlias, companyName } = useOutletContext<JobPreviewOutletContext>();
	const tableRef = React.useRef<TableRef<InvoiceVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);
	const [currentlyActiveRow, setCurrentlyActiveRow] = React.useState<Nullable<InvoiceVM>>(null);
	const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
	const [showInvoiceModal, setShowInvoiceModal] = React.useState<boolean>(false);
	const [showSendReminderModal, setShowSendReminderModal] = React.useState<boolean>(false);
	const [filterByStatus, setFilterByStatus] = React.useState<Nullable<InvoiceStatusDisplay>>(null);
	const [userGroupAndPM, setUserGroupAndPM] = React.useState<Nullable<UserGroupAndPmVM>>(null);

	const DELETE_INVOICE_MODAL_BODY = (
		<>
			You are about to delete this invoice.
			<br />
			This action can not be reversed.
			<br />
			Are you sure you want to proceed?
		</>
	);

	const INVOICE_INVOICE_MODAL_BODY = React.useMemo(() => (
		<>
			You are about to change status of this draft to <i>Invoiced</i>.
			<br />
			Some sections of the form will no longer be editable.
			<br />
			<br />
			Continue with invoicing?
		</>
	), []);

	const getSendReminderModalBody = React.useCallback(() => {
		if (currentlyActiveRow?.billingContacts?.length) {
			return (<>
				Payment reminder will be sent to following billing contact(s):
				<br />
				{
					currentlyActiveRow.billingContacts.map((_billingContact, index) => {
						let contactValue = '';
						let contactEmails: Nullable<string> = null;
						if (_billingContact.type === InvoiceBillingLookupType.EMAIL_ONLY) {
							contactValue = _billingContact.email;
						} else if (_billingContact.type === InvoiceBillingLookupType.CONTACT) {
							contactValue = _billingContact.contact.fullName;
							const selectedEmails = new Set(_billingContact.contact.contactEmailIds);
							const selectedEmailsValues = _billingContact.contact.emails.reduce((_emails, _email) => {
								if (selectedEmails.has(_email.id)) {
									_emails.push(_email.value);
								}
								return _emails;
							}, [] as string[]);
							contactEmails = ` (${selectedEmailsValues.join(', ')})`;
						}

						return (
							<React.Fragment key={index}>
								<b>
									{contactValue}
								</b>
								{contactEmails && (
									<span>
										{contactEmails}
									</span>)
								}
								{index !== currentlyActiveRow.billingContacts.length - 1 ? ', ' : '.'}
							</React.Fragment>
						);
					})
				}
				{
					userGroupAndPM?.userGroupName &&
					<>
						< br />
						< br />
						user group: <b>{userGroupAndPM?.userGroupName}</b>
					</>
				}
				{
					userGroupAndPM?.pmFirstName &&
					<>
						< br />
						< br />
						and project manager: <b>{userGroupAndPM?.pmFirstName} {userGroupAndPM?.pmLastName} ({userGroupAndPM?.pmEmail})</b>
					</>
				}
				< br />
				<br />
				Send reminder?
			</>);
		}
	}, [currentlyActiveRow, userGroupAndPM]);

	const getInvoiceModalBody = React.useCallback(() => {
		if (currentlyActiveRow?.billingContacts?.length && currentlyActiveRow.sendReminderOnInvoice) {
			return (<>
				You are about to change status of this draft to <i>Invoiced</i>.
				<br />
				You will not be able to make any changes to the data after it has been invoiced.
				<br />
				<br />
				Invoice information will be sent to following billing contacts:
				<br />
				{
					currentlyActiveRow.billingContacts.map((_billingContact, index) => {
						let contactValue = '';
						let contactEmails: Nullable<string> = null;
						if (_billingContact.type === InvoiceBillingLookupType.EMAIL_ONLY) {
							contactValue = _billingContact.email;
						} else if (_billingContact.type === InvoiceBillingLookupType.CONTACT) {
							contactValue = _billingContact.contact.fullName;
							const selectedEmails = new Set(_billingContact.contact.contactEmailIds);
							const selectedEmailsValues = _billingContact.contact.emails.reduce((_emails, _email) => {
								if (selectedEmails.has(_email.id)) {
									_emails.push(_email.value);
								}
								return _emails;
							}, [] as string[]);
							contactEmails = ` (${selectedEmailsValues.join(', ')})`;
						}

						return (
							<React.Fragment key={index}>
								<b>
									{contactValue}
								</b>
								{contactEmails && (
									<span>
										{contactEmails}
									</span>)
								}
								{index !== currentlyActiveRow.billingContacts.length - 1 ? ', ' : '.'}
							</React.Fragment>
						);
					})
				}
				{
					userGroupAndPM?.userGroupName &&
					<>
						< br />
						< br />
						user group: <b>{userGroupAndPM?.userGroupName}</b>
					</>
				}
				{
					userGroupAndPM?.pmFirstName &&
					<>
						< br />
						< br />
						and project manager: <b>{userGroupAndPM?.pmFirstName} {userGroupAndPM?.pmLastName} ({userGroupAndPM?.pmEmail})</b>
					</>
				}
				< br />
				< br />
				To invoice without sending an email, uncheck this option in the
				< i > Reminders</i > section of this invoice.
				< br />
				<br />
				Continue with invoicing ?
			</>);
		}
		return INVOICE_INVOICE_MODAL_BODY;
	}, [INVOICE_INVOICE_MODAL_BODY, currentlyActiveRow, userGroupAndPM]);

	const closeDeleteModal = React.useCallback(() => setShowDeleteModal(false), []);
	const closeSendReminderModal = React.useCallback(() => setShowSendReminderModal(false), []);
	const closeInvoiceModal = React.useCallback(() => setShowInvoiceModal(false), []);

	const history = useHistory();
	const notificationSnackbar = useNotificationSnackbar();

	// in order to be used only once the component is mounted, deps are left empty
	React.useEffect(() => {
		async function fetchData() {
			const usrGroupPM = await getUserGroupAndPM(jobId);
			setUserGroupAndPM(usrGroupPM);
		}
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<InvoiceVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		const result = await getInvoicesTable(
			jobId,
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			startDate,
			endDate
		);

		if (filterByStatus) {
			const newRows: InvoiceVM[] = [];
			for (const row of result.rows) {
				if (row.status === filterByStatus) {
					newRows.push(row);
				}
			}
			result.rows = newRows;
		}

		return result;
	}, [getInvoicesTable, jobId, startDate, endDate, filterByStatus]);

	const fetchInvoicesTotals = React.useCallback(async () => {
		const totals = await getInvoicesTotals(jobId);
		return totals;
	}, [getInvoicesTotals, jobId]);

	const renderTableHeader = React.useCallback((tableHeaderData: TableHeaderData) => {
		return (
			<TableHeader
				outstandingDebt={tableHeaderData.outstandingDebt}
				totalInvoiced={tableHeaderData.totalInvoiced}
				totalPaid={tableHeaderData.totalPaid}
			/>
		);
	}, []);

	const goToNewInvoice = React.useCallback(async () => {
		history.push({
			pathname: CLIENT.COMPANY.JOBS.INVOICE_CREATE(orgAlias, companyName, jobId.toString()),
			state: {
				jobCode,
				billingEmailsToPrefill: billingContact?.selectedContactMethodEmails ?? [],
				userGroupAndPM: userGroupAndPM,
			},
		});
	}, [billingContact, companyName, history, jobCode, jobId, orgAlias, userGroupAndPM]);

	const goToEditInvoice = React.useCallback(async (invoiceId: number) => {
		history.push({
			pathname: CLIENT.COMPANY.JOBS.INVOICE_EDIT(orgAlias, companyName, jobId.toString(), invoiceId.toString()),
			state: {
				jobCode,
				userGroupAndPM: userGroupAndPM,
			},
		});
	}, [companyName, history, jobCode, jobId, orgAlias, userGroupAndPM]);

	const goToPreviewInvoice = React.useCallback(async (invoice: InvoiceVM) => {
		if (invoice.id) {
			history.push({
				pathname: CLIENT.COMPANY.JOBS.INVOICE_PREVIEW(orgAlias, companyName, jobId.toString(), invoice.id.toString()),
				state: {
					jobCode,
					userGroupAndPM: userGroupAndPM,
				},
			});
		}
	}, [companyName, history, jobCode, jobId, orgAlias, userGroupAndPM]);

	const onRowClick = React.useCallback((_row: Row<InvoiceVM>) => {
		setCurrentlyActiveRow(_row.original);
		if (_row.original.status === InvoiceStatusDisplay.DRAFT && _row.original.id) {
			goToEditInvoice(_row.original.id);
		} else {
			goToPreviewInvoice(_row.original);
		}
	}, [goToEditInvoice, goToPreviewInvoice]);

	const invoiceInvoiceRow = React.useCallback(() => async () => {
		if (!currentlyActiveRow) {
			throw new Error('Currently active row not set.');
		}
		if (currentlyActiveRow.status === InvoiceStatusDisplay.DRAFT) {
			await updateInvoice(
				jobId,
				currentlyActiveRow.id,
				InvoiceFM.fromVMtoRM({
					...currentlyActiveRow,
					status: InvoiceStatusDisplay.INVOICED,
					invoicingDate: TimeUtils.formatDate(new Date(), TimeFormatEnum.DB_DATE_ONLY),
				}));
		}
		tableRef.current?.refreshTable();
		tableRef.current?.refreshTableHeader();
		setCurrentlyActiveRow(null);
	}, [currentlyActiveRow, jobId, updateInvoice]);

	const deleteInvoiceRow = React.useCallback(() => async () => {
		if (!currentlyActiveRow) {
			throw new Error('Currently active row not set.');
		}
		await deleteInvoice(jobId, currentlyActiveRow.id);
		tableRef.current?.refreshTable();
		tableRef.current?.refreshTableHeader();
		setCurrentlyActiveRow(null);
	}, [currentlyActiveRow, deleteInvoice, jobId]);

	const onInvoiceRow = React.useCallback((_invoice: InvoiceVM) => async () => {
		setCurrentlyActiveRow(_invoice);
		setShowInvoiceModal(true);
	}, []);

	const onDeleteRow = React.useCallback((_invoice: InvoiceVM) => async () => {
		setCurrentlyActiveRow(_invoice);
		setShowDeleteModal(true);
	}, []);

	const onEditRow = React.useCallback((row: Row<InvoiceVM>) => () => {
		setCurrentlyActiveRow(row.original);
		onRowClick(row);
	}, [onRowClick]);

	const onSendManualReminder = React.useCallback((invoice: InvoiceVM) => () => {
		setCurrentlyActiveRow(invoice);
		setShowSendReminderModal(true);
	}, []);

	const sendManualReminder = React.useCallback(() => async () => {
		const notification: Nullable<NotificationData> = notificationSnackbar.loading('Sending invoice reminder to billing contacts.', new Date());
		try {
			if (currentlyActiveRow) {
				await sendManualReminderForInvoice(currentlyActiveRow.id);
				if (notification) {
					notificationSnackbar.removeNotificationSnackbar(notification);
					const notificationText = `Reminders for invoice ${currentlyActiveRow.invoiceCode} were sent to the billing contacts.`;
					notificationSnackbar.success(notificationText, new Date());
				}
			}
		} catch (error) {
			if (error.errors.billingContacts) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = error.errors.billingContacts;
				notificationSnackbar.error(notificationText, new Date());
			}
		}

	}, [currentlyActiveRow, notificationSnackbar, sendManualReminderForInvoice]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<InvoiceVM, unknown>) => {
		const actions = [
			{ onClick: onEditRow(_cell.row), label: 'Edit' },
			{ onClick: onDeleteRow(_cell.row.original), label: 'Delete' },
		];
		if (_cell.row.original.status === InvoiceStatusDisplay.DRAFT) {
			actions.push({ onClick: onInvoiceRow(_cell.row.original), label: 'Invoice' });
		}
		if (_cell.row.original.status !== InvoiceStatusDisplay.DRAFT
			&& _cell.row.original.status !== InvoiceStatusDisplay.PAID
			&& _cell.row.original.status !== InvoiceStatusDisplay.OVERPAID
			&& isSendingInvoiceNotificationsEnabled
			&& _cell.row.original.billingContacts.length
		) {
			actions.push({ onClick: onSendManualReminder(_cell.row.original), label: 'Send Reminder' });
		}
		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={actions}
				valueKey="label"
			/>
		);
	}, [onDeleteRow, onEditRow, onInvoiceRow, onSendManualReminder, isSendingInvoiceNotificationsEnabled]);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const filterByInvoiceStatus = React.useCallback((value) => {
		tableRef.current?.resetPagination();
		if (Object.values<string>(InvoiceStatusDisplay).includes(value.name)) {
			setFilterByStatus(value.name);
		} else {
			setFilterByStatus(null);
		}
	}, []);

	const renderInvoiceStatus = React.useCallback((invoiceStatus: InvoiceStatusDisplay) => {
		const cellStyle: string[] = [];
		cellStyle.push(styles['invoice__status-cell']);

		switch (invoiceStatus) {
			case InvoiceStatusDisplay.DRAFT: {
				cellStyle.push(styles['invoice__status-cell--draft']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-draft" />
						<b>Draft</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.INVOICED: {
				cellStyle.push(styles['invoice__status-cell--invoiced']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-invoice_status_invoiced" />
						<b>Invoiced</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PARTIALLY_PAID: {
				cellStyle.push(styles['invoice__status-cell--partially-paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Partially paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PAID: {
				cellStyle.push(styles['invoice__status-cell--paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.OVERPAID: {
				cellStyle.push(styles['invoice__status-cell--overpaid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Overpaid</b>
					</span>
				);
			}
			default: {
				cellStyle.push(styles['invoice__empty-cell']);
				return (
					<span className={cellStyle.join(' ')}>
						N/A
					</span>
				);
			}
		}
	}, []);

	const renderStatusMenuItem = React.useCallback((optionItem: InvoiceStatusFilterItem) => {
		return !!optionItem && Object.values<string>(InvoiceStatusDisplay).includes(optionItem.name)
			? renderInvoiceStatus(optionItem.name as InvoiceStatusDisplay)
			: <b>All statuses</b>;
	}, [renderInvoiceStatus]);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<>

				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<InvoiceStatusFilterItem>
						className={styles['invoices-table__filter-button']}
						defaultValue={InvoiceStatusFilterItem.DEFAULT_ALL}
						id="invoices-statuses-filter"
						labelKey="name"
						onValueChange={filterByInvoiceStatus}
						options={[InvoiceStatusFilterItem.DEFAULT_ALL, ...InvoiceStatusFilterItem.STATUSES]}
						renderMenuItem={renderStatusMenuItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					areButtonsSmall={true}
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</>

		);
	}, [changePeriod, endDate, filterByDate, filterByInvoiceStatus, period, renderStatusMenuItem, startDate]);

	const renderTextCell = React.useCallback(() => (cell) => {

		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderDateCell = React.useCallback(() => (cell) => {

		const dbDate = cell.getValue() as string;

		const date = TimeUtils.formatDate(dbDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		return <TextCell value={date} />;
	}, []);

	const renderDollarAmountCell = React.useCallback(() => (cell) => {
		if (cell.getValue() === undefined || cell.getValue() === null) {
			return null;
		}

		return (
			<DollarCell isUnitPrice={true} value={cell.getValue()} />
		);
	}, []);

	const renderPercentageCell = React.useCallback(() => (cell) => {
		if (cell.getValue() === undefined || cell.getValue() === null) {
			return null;
		}

		return (
			<PercentageCell value={cell.getValue()} />
		);
	}, []);

	const renderInvoiceStatusCell = React.useCallback(() => (_cell: CellContext<InvoiceVM, unknown>) => {
		return renderInvoiceStatus(_cell.cell.row.original.status);
	}, [renderInvoiceStatus]);

	const renderAttachmentsCell = React.useCallback(() => (cell: CellContext<InvoiceVM, InvoiceVM['attachments']>) => {
		if (!cell.getValue() || cell.getValue().length === 0) {
			return null;
		}

		const invoiceCode = cell.row.original.invoiceCode;

		const onDownloadAttachmentsClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			const attachmentIds = cell.getValue().map(getId);
			const startMsg = notificationSnackbar.loading(`Started Downloading attachments for ${invoiceCode}`);
			await downloadAttachmentsAsZip(attachmentIds, `${jobCode}_${invoiceCode}.zip`);
			notificationSnackbar.removeNotificationSnackbar(startMsg);
			notificationSnackbar.success('Download Complete');
		};

		return (
			<span className={styles['invoice__download-cell']} onClick={onDownloadAttachmentsClick.bind(this)}>
				<span className="icon-attachment" />
				<span className={styles['invoice__download-cell__text']}>
					Download
				</span>
			</span>
		);

	}, [downloadAttachmentsAsZip, jobCode, notificationSnackbar]);

	const buttons = React.useMemo(() => [
		{ label: 'New Invoice', type: TableButtonType.PRIMARY, hasPermission: true, onClick: goToNewInvoice },
	], [goToNewInvoice]);

	const columns: TableProps<InvoiceVM>['columns'] = React.useMemo(() => [
		{
			id: 'invoiceCode',
			accessor: 'invoiceCode',
			header: 'Invoice ID',
			cell: renderTextCell(),
			enableSorting: true,
			size: 150,
		},
		{
			id: 'invoicingDate',
			accessor: 'invoicingDate',
			header: 'Date Of Invoicing',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'dateCreated',
			accessor: 'dateCreated',
			header: 'Date Created',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'dueDate',
			accessor: 'dueDate',
			header: 'Due Date',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'status',
			accessor: 'status',
			header: 'Invoice Status',
			cell: renderInvoiceStatusCell(),
		},
		{
			id: 'paidAmount',
			accessor: 'paidAmount',
			header: 'Paid Amount ($)',
			cell: renderDollarAmountCell(),
			rightAligned: true,
			enableSorting: false,
		},
		{
			id: 'totalAmount',
			accessor: 'totalAmount',
			header: 'Total Owed ($)',
			cell: renderDollarAmountCell(),
			rightAligned: true,
			enableSorting: true,
		},
		{
			id: 'percentagePaid',
			accessor: 'percentagePaid',
			header: 'Percentage Paid (%)',
			cell: renderPercentageCell(),
			rightAligned: true,
		},
		{
			id: 'lastInstallmentDate',
			accessor: 'lastInstallmentDate',
			header: 'Date Of Last Installment',
			cell: renderDateCell(),
		},
		{
			id: 'attachments',
			accessor: 'attachments',
			header: 'Attachments',
			cell: renderAttachmentsCell(),
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderTextCell, renderDateCell, renderInvoiceStatusCell, renderDollarAmountCell, renderPercentageCell, renderAttachmentsCell, resolveActionsButton]);

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
	}, [startDate, endDate, period]);

	return (
		<>
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				fetchTableHeaderData={fetchInvoicesTotals}
				hasSearchInput={true}
				offsetHeight={35}
				onRowClick={onRowClick}
				ref={tableRef}
				renderTableHeader={renderTableHeader}
				searchLabel={'by Invoice ID'}
				selectable={true}
				tableBodyClassName={styles['work-summary-table']}
				tableName={TableNameEnum.INVOICE}
			/>
			<ConfirmationModal
				body={DELETE_INVOICE_MODAL_BODY}
				closeModal={closeDeleteModal}
				closeText="Cancel"
				confirmAction={deleteInvoiceRow()}
				confirmText="Continue"
				modalStyle="danger"
				showModal={showDeleteModal}
				title="Delete invoice"
			/>
			<ConfirmationModal
				body={getSendReminderModalBody()}
				closeModal={closeSendReminderModal}
				closeText="Cancel"
				confirmAction={sendManualReminder()}
				confirmText="Send"
				modalStyle="info"
				showModal={showSendReminderModal}
				size="md"
				title="Send reminder?"
			/>
			<ConfirmationModal
				body={getInvoiceModalBody()}
				closeModal={closeInvoiceModal}
				closeText="Cancel"
				confirmAction={invoiceInvoiceRow()}
				confirmText="Continue"
				modalStyle="info"
				showModal={showInvoiceModal}
				size="md"
				title="Invoice draft?"
			/>
		</>
	);
};

function mapDispatchToProps() {
	return {
		getInvoicesTable: jobActions.getInvoicesTable,
		getInvoicesTotals: jobActions.getInvoicesTotals,
		updateInvoice: jobActions.updateInvoice,
		deleteInvoice: jobActions.deleteInvoice,
		getUserGroupAndPM: jobActions.getUserGroupAndPM,
		sendManualReminderForInvoice: jobActions.sendManualReminderForInvoice,
		downloadAttachmentsAsZip: AttachmentActions.downloadAttachmentsAsZip,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.INVOICES_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.INVOICES_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.INVOICES_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(Invoices);
