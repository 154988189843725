import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import type { History } from 'history';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import { getWorkOrderSelectedDueDate } from 'af-utils/settings.util';

import ActionsMenuItem from 'af-components/Menu/MenuItem';
import CascadeActionsMenuItem from 'af-components/Menu/CascadeMenuItem';

import CLIENT from 'af-constants/routes/client';
import { SCREEN_BREAKPOINT_M } from 'af-constants/values';

interface OwnProps {
	companyName: string;
	orgAlias: string;
	history: History;
	isAllowedToEdit: boolean;
	isAllowedToViewProdData: boolean;
	isMultiSelectActive: boolean;
	isPublishDisabled: boolean;
	isPublishing: boolean;
	isCopyDisabled: boolean;
	isNotifyDisabled: boolean;
	isExporting: boolean;
	isNotifyShown: boolean;
	isBoardLoading: boolean;
	scheduleBoardView: Nullable<ScheduleBoardView>;
	isAddPerDiemDisabled: boolean;
	openAddPerDiemModal: () => void;
	publishWorkOrders: () => void;
	copyWorkOrders: () => void;
	notifyWorkOrders: () => void;
	openDailyTipModal: () => void;
	openDailyPerDiemTipModal: () => void;
	openSBTemplateNotificationModal: () => void;
	exportPdf: (withCalculations: boolean, onlyCurrentDay: boolean) => Promise<void>;
	exportXlsx: (withCalculations: boolean, onlyCurrentDay: boolean) => Promise<void>;
	exportTemplate: () => Promise<void>;
	captureBoardScreenshot: () => void;
}

interface State {
	areCreationButtonsVisible: boolean;
	originUrl: string;
}

type Props = OwnProps;

class ActionsMenu extends React.PureComponent<Props, State> {

	state: State = {
		areCreationButtonsVisible: window.innerWidth < SCREEN_BREAKPOINT_M,
		originUrl: '',
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		const { scheduleBoardView, orgAlias, companyName } = props;

		let originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW(orgAlias, companyName);
		if (scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW) {
			originUrl = CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW(orgAlias, companyName);
		}
		return originUrl !== state.originUrl ? { originUrl } : null;
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	toggleDropdown = () => {
		document.dispatchEvent(new MouseEvent('click'));
	};

	updateWindowDimensions = () => {
		this.setState(() => ({ areCreationButtonsVisible: window.innerWidth < SCREEN_BREAKPOINT_M }));
	};

	createNewWorkOrder = () => {
		const { history, orgAlias, companyName } = this.props;
		const { originUrl } = this.state;

		const dueDate = getWorkOrderSelectedDueDate();
		const defaultDueDate = dueDate && TimeUtils.formatDate(dueDate, TimeFormatEnum.JS_TIME);

		history.push(CLIENT.COMPANY.WORK_ORDERS.ORDER(null, orgAlias, companyName), {
			defaultDueDate,
			originUrl,
			originLabel: 'Schedule Board',
		});
	};

	createNewJob = () => {
		const { history, orgAlias, companyName } = this.props;
		const { originUrl } = this.state;

		history.push({
			pathname: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
			state: { originUrl },
		});
	};

	bulkPublish = () => {
		const { isPublishDisabled, publishWorkOrders } = this.props;
		if (!isPublishDisabled) {
			publishWorkOrders();
			this.toggleDropdown();
		}
	};

	bulkCopy = () => {
		const { isCopyDisabled, copyWorkOrders } = this.props;
		if (!isCopyDisabled) {
			copyWorkOrders();
			this.toggleDropdown();
		}
	};

	bulkNotify = () => {
		const { isNotifyDisabled, notifyWorkOrders } = this.props;
		if (!isNotifyDisabled) {
			notifyWorkOrders();
		}
	};

	openAddPerDiemModal = () => {
		const { isAddPerDiemDisabled, openAddPerDiemModal } = this.props;
		if (!isAddPerDiemDisabled) {
			openAddPerDiemModal();
		}
	};

	exportDayAsPdf = () => {
		const { exportPdf } = this.props;
		exportPdf(false, true);
		this.toggleDropdown();
	};

	exportWeekAsPdf = () => {
		const { exportPdf } = this.props;
		exportPdf(false, false);
		this.toggleDropdown();
	};

	exportDayAsPdfWithCalculations = () => {
		const { exportPdf } = this.props;
		exportPdf(true, true);
		this.toggleDropdown();
	};

	exportWeekAsPdfWithCalculations = () => {
		const { exportPdf } = this.props;
		exportPdf(true, false);
		this.toggleDropdown();
	};

	exportDayAsXlsx = () => {
		const { exportXlsx } = this.props;
		exportXlsx(false, true);
		this.toggleDropdown();
	};

	exportWeekAsXlsx = () => {
		const { exportXlsx } = this.props;
		exportXlsx(false, false);
		this.toggleDropdown();
	};

	exportDayAsXlsxWithCalculations = () => {
		const { exportXlsx } = this.props;
		exportXlsx(true, true);
		this.toggleDropdown();
	};

	exportWeekAsXlsxWithCalculations = () => {
		const { exportXlsx } = this.props;
		exportXlsx(true, false);
		this.toggleDropdown();
	};

	exportDayAsTemplate = () => {
		const { exportTemplate } = this.props;
		exportTemplate();
		this.toggleDropdown();
	};

	dailyTip = () => {
		const { openDailyTipModal } = this.props;
		openDailyTipModal();
		this.toggleDropdown();
	};

	dailyPerDiemTip = () => {
		const { openDailyPerDiemTipModal } = this.props;
		openDailyPerDiemTipModal();
		this.toggleDropdown();
	};

	screenshotBoard = () => {
		const { captureBoardScreenshot } = this.props;
		captureBoardScreenshot();
		this.toggleDropdown();
	};

	openSBTemplateNotificationModal = () => {
		const { openSBTemplateNotificationModal } = this.props;
		openSBTemplateNotificationModal();
		this.toggleDropdown();
	};

	render() {
		const {
			isAllowedToEdit,
			isAllowedToViewProdData,
			isMultiSelectActive,
			isPublishing,
			isPublishDisabled,
			isCopyDisabled,
			isNotifyDisabled,
			isAddPerDiemDisabled,
			isExporting,
			isNotifyShown,
			isBoardLoading,
			scheduleBoardView,
		} = this.props;
		const { areCreationButtonsVisible } = this.state;

		const isDailyView = scheduleBoardView === ScheduleBoardView.DAILY_VIEW;
		const workOrdersSubmenuLabel: string = isMultiSelectActive ? 'Selected' : 'All';

		return (
			<Dropdown className="schedule-board-actions-menu" id="schedule-board-actions-menu__button">
				<Dropdown.Toggle variant="info">
					<span className="icon-actions" />
					Menu
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu--fit-content" id="accqa__schedule-board-actions-menu__dropdown">
					{isAllowedToEdit && areCreationButtonsVisible &&
						<>
							<ActionsMenuItem
								action={this.createNewWorkOrder}
								label="Add New Work Order"
							/>
							<ActionsMenuItem
								action={this.createNewJob}
								label="Add New Job"
							/>
						</>
					}
					{isAllowedToEdit && isDailyView &&
						<CascadeActionsMenuItem
							items={[
								{ isDisabled: isPublishing || isPublishDisabled, action: this.bulkPublish, label: 'Publish WOs' },
								{ isDisabled: isCopyDisabled, action: this.bulkCopy, label: 'Copy WOs' },
								{ isDisabled: isNotifyDisabled, action: this.bulkNotify, label: 'Notify Labor on WOs' },
								{ isDisabled: isAddPerDiemDisabled, action: this.openAddPerDiemModal, label: 'Add Per Diem to WOs' },
							]}
							label={`${workOrdersSubmenuLabel} Work Orders`}
							position="left"
						/>
					}
					<div className="dropdown-menu__divider" />
					{isDailyView &&
						<CascadeActionsMenuItem
							items={[
								{ isDisabled: isExporting, action: this.exportDayAsXlsx, label: 'Export day as XSLS' },
								{ isDisabled: isExporting, action: this.exportDayAsPdf, label: 'Export day as PDF' },
								...(isAllowedToViewProdData
									? [
										{ divider: true },
										{ isDisabled: isExporting, action: this.exportDayAsXlsxWithCalculations, label: 'Export day as XSLS (calculations)' },
										{ isDisabled: isExporting, action: this.exportDayAsPdfWithCalculations, label: 'Export day as PDF (calculations)' },
									]
									: []
								),
								{ divider: true },
								{ isDisabled: isExporting, action: this.exportDayAsTemplate, label: 'Export day as PDF (17x11)' },
							]}
							label="Export Day"
							position="left"
						/>
					}
					<CascadeActionsMenuItem
						items={[
							{ isDisabled: isExporting, action: this.exportWeekAsXlsx, label: 'Export week as XSLS' },
							{ isDisabled: isExporting, action: this.exportWeekAsPdf, label: 'Export week as PDF' },
							...(isAllowedToViewProdData
								? [
									{ divider: true },
									{ isDisabled: isExporting, action: this.exportWeekAsXlsxWithCalculations, label: 'Export week as XSLS (calculations)' },
									{ isDisabled: isExporting, action: this.exportWeekAsPdfWithCalculations, label: 'Export week as PDF (calculations)' },
								]
								: []
							),
						]}
						label="Export Week"
						position="left"
					/>
					{isDailyView &&
						<>
							<div className="dropdown-menu__divider" />
							{isAllowedToEdit && isNotifyShown &&
								<ActionsMenuItem
									action={this.dailyTip}
									label="Edit Daily Tip"
								/>
							}
							{isAllowedToEdit && isNotifyShown &&
								<ActionsMenuItem
									action={this.dailyPerDiemTip}
									label="Edit Per Diem Message"
								/>
							}
							<ActionsMenuItem
								action={this.screenshotBoard}
								isDisabled={isBoardLoading}
								label="Capture Screenshot"
							/>
							{isNotifyShown &&
								<ActionsMenuItem
									action={this.openSBTemplateNotificationModal}
									id="accqa__schedule-board__send-template"
									isDisabled={isBoardLoading}
									label="Send Schedule Board template"
								/>
							}
						</>
					}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

export default ActionsMenu;
