import * as React from 'react';
import { Field } from 'redux-form';

import WorkRequestDisposalMethod from '@acceligentllc/shared/enums/workRequestDisposalMethod';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import { getAttributeName } from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';
import RadioGroup from 'af-fields/RadioGroup';

import styles from '../../styles.module.scss';
import { formSectionClass, formSectionClassColumn1, formSectionClassColumn2, formSectionClassColumn4, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

const DISPOSAL_METHOD_OPTIONS = Object.keys(WorkRequestDisposalMethod).map((_dm) => {
	switch (_dm) {
		case (WorkRequestDisposalMethod.NONE): {
			return { value: _dm, label: 'None' };
		}

		case (WorkRequestDisposalMethod.OFF_SITE_DISPOSAL): {
			return { value: _dm, label: 'Off-site Disposal' };
		}

		case (WorkRequestDisposalMethod.ON_SITE_DISPOSAL): {
			return { value: _dm, label: 'On-site Disposal' };
		}

		case (WorkRequestDisposalMethod.THIRD_PARTY_TRANSPORT): {
			return { value: _dm, label: 'Third Party Transport' };
		}

		default: {
			return { value: _dm, label: 'None' };
		}
	}
});

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
	formName: string;
	isDisabled: boolean;
};

const DisposalSection: React.FC<Props> = (props) => {
	const { change, formName, isDisabled } = props;

	const onDisposalMethodChange = React.useCallback((field: string, value) => {
		change(field, value);
	}, [change]);

	const ableToCleanAndWashEquipmentAtCustomerLocationStyle = React.useMemo(() => [formSectionClassColumn4, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);
	const wasteGeneratedStyle = React.useMemo(() => [formSectionClassColumn1, styles['job-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Disposal
			</div>
			<div className={formSectionClassRow}>
				<div className={ableToCleanAndWashEquipmentAtCustomerLocationStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="ableToCleanAndWashEquipmentAtCustomerLocation"
						label="Able To Clean and Wash Equipment at Customer Location"
						name={getAttributeName('ableToCleanAndWashEquipmentAtCustomerLocation')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="cleanAndWashLocation"
						label="Clean and Wash Location"
						name={getAttributeName('cleanAndWashLocation')}
						placeholder="Enter Clean and Wash Location"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn4}>
					Waste Generated:
				</div>
				<div className={formSectionClassColumn4}>
					Hazardous / Manifest
				</div>
			</div>
			<div className={[formSectionClassRow, styles['job-form__waste-types']].join(' ')}>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isWasteGeneratedSolid"
						label="Solid"
						name={getAttributeName('isWasteGeneratedSolid')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isWasteGeneratedSludge"
						label="Sludge"
						name={getAttributeName('isWasteGeneratedSludge')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isWasteGeneratedLiquid"
						label="Liquid"
						name={getAttributeName('isWasteGeneratedLiquid')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isWasteHazardous"
						label="Hazardous"
						name={getAttributeName('isWasteHazardous')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						disabled={isDisabled}
						id="isWasteManifest"
						label="Manifest"
						name={getAttributeName('isWasteManifest')}
					/>
				</div>
			</div>
			<div className={[formSectionClassRow, styles['job-form__waste-types']].join(' ')}>
				Disposal Method
				<div className={formSectionClassColumn8}>
					<RadioGroup
						changeField={onDisposalMethodChange}
						disabled={isDisabled}
						extraClass={styles['job-form__radio-group']}
						field={getAttributeName('disposalMethod')}
						inline={true}
						items={DISPOSAL_METHOD_OPTIONS}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="wasteDescription"
						label="Waste Description"
						name={getAttributeName('wasteDescription')}
						placeholder="Enter Waste Description"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="expectedWasteQuantity"
						label="Expected Waste Quantity"
						name={getAttributeName('expectedWasteQuantity')}
						placeholder="Enter Expected Waste Quantity"
						type="number"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						disabled={isDisabled}
						id="disposalInstructions"
						label="Disposal Instructions"
						name={getAttributeName('disposalInstructions')}
						placeholder="Enter Disposal Instructions"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Disposal Contact
					<JobContact
						className={styles['job-form__contact']}
						contactLabel="Enter Disposal Contact"
						disabled={isDisabled}
						fieldName={getAttributeName('disposalContact')}
						formName={formName}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(DisposalSection);
