import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import * as SaleActions from 'af-actions/sale';

import type { SaleTableRowVM } from 'ab-viewModels/sale/tableRow.viewModel';

import CLIENT from 'af-constants/routes/client';

import type { TabProps, Column, ButtonData } from 'af-components/Table6';
import Table from 'af-components/Table6';
import AddressCell from 'af-components/Table6/Cells/AddressCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { formatPhoneNumber } from 'ab-utils/phone.util';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	deleteSaleFromTable: typeof SaleActions.deleteSaleFromTable;
	findAllForCompanyTable: typeof SaleActions.findAllForCompanyTable;
	bulkDeleteSaleFromTable: typeof SaleActions.bulkDeleteSaleFromTable;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

const DELETE_CONFIRMATION_BODY = (
	<>
		If deleted, the sales will no longer be assignable.
		<br />
		This action cannot be undone.
	</>
);

class SaleList extends React.PureComponent<Props> {

	static readonly BREADCRUMBS = [{ label: 'Sales' }];

	static readonly COLUMNS: Column<SaleTableRowVM>[] = [
		{
			Header: 'Full name',
			accessor: 'fullName',
			Cell: ({ original }) => original.fullName,
		},
		{
			Header: 'Title',
			accessor: 'title',
			Cell: ({ original }) => original.title,
		},
		{
			Header: 'Address',
			sortable: false,
			Cell: ({ original }) => original.address ? <AddressCell address={original.address} /> : <EmptyCell />,
		},
		{
			Header: 'Mobile Phone',
			accessor: 'phone',
			Cell: ({ original }) => formatPhoneNumber(original.phone, original.phoneCountryCode),
		},
		{
			Header: 'Fax',
			accessor: 'fax',
			Cell: ({ original }) => formatPhoneNumber(original.fax, original.faxCountryCode),
		},
		{
			Header: 'Email',
			accessor: 'email',
			sortable: false,
			Cell: ({ original }) => original.email,
		},
	];

	static deleteSalesModalTitle = (original: SaleTableRowVM) => `Are you sure you want to delete this Sales (${original.fullName})?`;
	static deleteSalesModalBody = () => DELETE_CONFIRMATION_BODY;
	static deleteSalesModalText = () => 'Delete Sales';

	deleteSales = async (original: SaleTableRowVM) => {
		const { deleteSaleFromTable } = this.props;
		await deleteSaleFromTable(original.id);
	};

	editSales = async (original: SaleTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.SALE.EDIT(original.id.toString(), orgAlias, companyName));
	};

	previewSales = async (original: SaleTableRowVM) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.RESOURCES.SALE.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	onRowClick = ({ original }: { original: SaleTableRowVM; }) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.RESOURCES.SALE.PREVIEW(original.id.toString(), orgAlias, companyName));
		}
	};

	tabs = (): TabProps<SaleTableRowVM>[] => {
		const {
			findAllForCompanyTable,
			bulkDeleteSaleFromTable,
			location: { state: { orgAlias } },
			companyName,
			history,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Sales',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.SALE.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Sales',
				columns: SaleList.COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Sales',
				buttons,
				fetch: findAllForCompanyTable,
				bulkDelete: bulkDeleteSaleFromTable,
				bulkDeleteConfirmationBody: DELETE_CONFIRMATION_BODY,
				onRowClick: this.onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: this.previewSales,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: this.editSales,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: this.deleteSales,
						hasModal: true,
						modalTitle: SaleList.deleteSalesModalTitle,
						modalBody: SaleList.deleteSalesModalBody,
						modalText: SaleList.deleteSalesModalText,
						shouldRefresh: true,
					},
				],
			},

		];
	};

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={SaleList.BREADCRUMBS} />
				<Table
					tableName={TableNameEnum.SALES}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		deleteSaleFromTable: SaleActions.deleteSaleFromTable,
		findAllForCompanyTable: SaleActions.findAllForCompanyTable,
		bulkDeleteSaleFromTable: SaleActions.bulkDeleteSaleFromTable,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(SaleList);
