import type AccountShared from 'ab-domain/models/account/base';

import FieldReportAccessRoleEnum from '@acceligentllc/shared/enums/fieldReportAccessRole';
import type UserPermission from '@acceligentllc/shared/enums/userPermission';

import PagePermissions from 'ab-enums/pagePermissions.enum';
import { isAllowed } from 'ab-utils/auth.util';

export const resolveFieldReportAccessRole = (
	account: AccountShared,
	role: Nullable<FieldReportAccessRoleEnum>,
	userPermission: UserPermission) => {

	if (account.id === null || account.assignableAsAccounting) {
		return FieldReportAccessRoleEnum.ACCOUNTING;
	}
	else if (account.assignableAsManagement) {
		return FieldReportAccessRoleEnum.MANAGEMENT;
	}
	else if (account.assignableAsSuperintendent) {
		return FieldReportAccessRoleEnum.SUPERINTENDENT;
	}

	const hasSIUnassignedPermission = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.SI_UNASSIGNED_MANAGE,
		account.permissions.map((permission) => permission.permission),
		account.isCompanyAdmin,
		userPermission
	);

	if (hasSIUnassignedPermission) {
		return FieldReportAccessRoleEnum.SUPERINTENDENT;
	}

	return role ?? null;

};
