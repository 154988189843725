import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';

import type ContactRM from 'ab-requestModels/contact/upsert';
import { EmailTypes, EmailTypeNames } from '@acceligentllc/shared/enums/contactMethodType';
import UpsertContactStatusEnum from '@acceligentllc/shared/enums/upsertContactStatus';

import Input from 'af-fields/Input';
import Dropdown from 'af-fields/Dropdown';

import SegmentLabel from 'af-components/SegmentLabel';

import styles from './styles.module.scss';

interface EmailTypeOption {
	id: EmailTypes;
	label: string;
}

export interface OwnProps {
	customError?: string;
	onRemoveContactMethod: (email: ContactRM['emails'][0]) => void;
	change: (fieldName: string, value: UpsertContactStatusEnum) => void;
	areEmailsRequired?: boolean;
}

type Props = OwnProps & WrappedFieldArrayProps<ContactRM['emails'][0]>;

class EmailContactMethods extends React.Component<Props> {

	static _emailTypeOptions: EmailTypeOption[] = Object.keys(EmailTypes)
		.map((_key: EmailTypes) => ({ id: _key, label: EmailTypeNames[_key] }));

	addContactMethod = () => {
		const { fields } = this.props;

		fields.push({
			type: EmailTypes.EMAIL_DEFAULT,
			status: UpsertContactStatusEnum.ADDED,
		} as ContactRM['emails'][0]);
	};

	updateContactMethod = (index: number, fieldName: string) => {
		const { change, fields } = this.props;

		const field = fields.get(index);
		if (field.status === UpsertContactStatusEnum.OLD) {
			change(`${fieldName}.status`, UpsertContactStatusEnum.EDITED);
		}
	};

	removeContactMethod = (index: number) => {
		const { onRemoveContactMethod, fields } = this.props;
		const removedMethod = fields.get(index);
		fields.remove(index);
		if (removedMethod.status !== UpsertContactStatusEnum.ADDED) {
			onRemoveContactMethod({ ...removedMethod, status: UpsertContactStatusEnum.REMOVED });
		}
	};

	renderMethodTypes = () => {
		const { fields } = this.props;

		return fields.map((_field, _index: number) => (
			<Row key={_index}>
				<Col sm={6}>
					<Field
						component={Input}
						name={`${_field}.value`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						placeholder="Email"
						type="text"
					/>
				</Col>
				<Col sm={6}>
					<Field
						component={Dropdown}
						id={_field}
						labelKey="label"
						name={`${_field}.type`}
						onValueChange={this.updateContactMethod.bind(this, _index, _field)}
						options={EmailContactMethods._emailTypeOptions}
						placeholder="Type"
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col className={styles.deleteWrapper} sm={1}>
					<Button
						icon="delete"
						onClick={this.removeContactMethod.bind(this, _index)}
						style="link-danger"
						tooltip="Delete"
					/>
				</Col>
			</Row>
		));
	};

	render() {
		const { meta: { error, submitFailed }, customError, areEmailsRequired, fields } = this.props;

		const emailLabel = `Email ${areEmailsRequired ? '*' : ''}`;
		return (
			<div className="contact-method contact-method--non-padded">
				<div className="contact-method__header">
					<SegmentLabel label="Email Addresses" />
				</div>
				{!!fields.length &&
					<div className="contact-method__header">
						{emailLabel}
					</div>
				}
				{this.renderMethodTypes()}
				<Row>
					<Col sm={12}>
						<Button
							icon="plus"
							label="Add Email"
							onClick={this.addContactMethod}
							style="link"
						/>
						{submitFailed && error && <span>{error}</span>}
						{customError && <span className="help-block"><span className="icon-info"></span>{customError}</span>}
					</Col>
				</Row>
			</div>
		);
	}
}

export default EmailContactMethods;
