import * as React from 'react';
import { Field } from 'redux-form';

import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';

import Text from 'af-fields/Text';

interface OwnProps {
	formName: string;
	name: string;
	tooltipMessage?: React.ComponentProps<typeof Text>['tooltipMessage'];
	unit?: Nullable<QuantityUnitType>;
}

type Props = OwnProps;

const TextPreview: React.FC<Props> = (props) => {
	const {
		formName,
		name,
		tooltipMessage,
		unit,
	} = props;
	return (
		<Field
			component={Text}
			defaultValue="None"
			id={formName}
			label={name}
			name={formName}
			placeholder={name}
			tooltipMessage={tooltipMessage}
			valueSuffix={unit}
		/>
	);
};

export default TextPreview;
