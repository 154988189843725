import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { WrappedFieldProps } from 'redux-form';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import ImageTag from 'af-components/Image';
import Tooltip from 'af-components/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CameraLogo = require('@acceligentllc/assets/assets/ic_camera.svg');

interface OwnProps {
	onImageUpload: () => void;
	selected: string;
}

type Props = OwnProps & WrappedFieldProps;

const MESSAGE = 'Please upload image. Immutable image field cannot be saved without image.';

const ImageUploadField: React.FC<Props> = (props) => {
	const { onImageUpload, meta: { error, touched }, input: { name, value } } = props;

	const [imageUrl, setImageUrl] = React.useState(value);

	React.useEffect(() => {
		if (imageUrl === undefined || value !== imageUrl) {
			setImageUrl(value);
		}
	}, [value, imageUrl]);

	return (
		<div className="report-block-form-field-modal__image-upload-container">
			{imageUrl &&
				<ImageTag
					className="image-field__image"
					fallbackSrc={CameraLogo}
					minSize={BlobStorageImageSizeContainer.SIZE_200X200}
					src={imageUrl}
					tryOriginal={true}
				/>
			}
			{!imageUrl && <div>{MESSAGE}</div>}
			<div className="modal-upload-action">
				<Tooltip message="Upload">
					<Button
						icon="upload"
						onClick={onImageUpload}
						style="secondary"
					/>
				</Tooltip>
			</div>
			{(touched && error) &&
				<span className="help-block"><span className="icon-info" /> {typeof error === 'object' ? error[name] : error}</span>
			}
		</div>
	);
};

export default React.memo(ImageUploadField);
