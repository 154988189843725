import { ExtendedColorValue, DefaultColor } from '@acceligentllc/shared/enums/color';
import ScheduleBoardFilterGroup from '@acceligentllc/shared/enums/scheduleBoardFilterGroup';

import type LocationBase from 'ab-domain/models/location/base';
import type EquipmentCostCategoryBase from 'ab-domain/models/equipmentCostCategory/base';
import type CrewTypeBase from 'ab-domain/models/crewType/base';

const FILTER_DEFINED = 'Defined';
const FILTER_UNKNOWN_LOCATION = 'Unknown location';
const FILTER_CUSTOM_CREW_TYPE = 'INTERNAL';

export enum ScheduleBoardFilterType {
	ASSIGNED = -1,
	UNASSIGNED = -2
}

export interface FilterVM {
	id: number;
	text: string;
	color: ExtendedColorValue | null;
	filterGroup: ScheduleBoardFilterGroup;
	checked: boolean;
}

export default class ScheduleBoardFilterVM {
	[ScheduleBoardFilterGroup.CREW_TYPE]!: FilterVM[];
	[ScheduleBoardFilterGroup.EQUIPMENT_OFFICE]!: FilterVM[];
	[ScheduleBoardFilterGroup.EMPLOYEE_OFFICE]!: FilterVM[];
	[ScheduleBoardFilterGroup.EQUIPMENT_COST]!: FilterVM[];

	constructor(
		offices: LocationBase[] = [],
		equipmentCostCategories: EquipmentCostCategoryBase[] = [],
		crewTypes: CrewTypeBase[] = []
	) {
		// Find and map all offices
		const _equipmentOffices: FilterVM[] = offices.map((_) => {
			return ({
				id: _.id,
				text: _.nickname,
				color: _.color,
				filterGroup: ScheduleBoardFilterGroup.EQUIPMENT_OFFICE,
				checked: true,
			});
		});
		_equipmentOffices.unshift({
			id: ScheduleBoardFilterType.ASSIGNED,
			text: FILTER_DEFINED,
			color: null,
			filterGroup: ScheduleBoardFilterGroup.EQUIPMENT_OFFICE,
			checked: true,
		});
		_equipmentOffices.push({
			id: ScheduleBoardFilterType.UNASSIGNED,
			text: FILTER_UNKNOWN_LOCATION,
			color: ExtendedColorValue.GREY,
			filterGroup: ScheduleBoardFilterGroup.EQUIPMENT_OFFICE,
			checked: true,
		});

		// Find and map all equipment cost categories
		const _equipmentCostCategories: FilterVM[] = equipmentCostCategories.map((_) => {
			return ({
				id: _.id,
				text: _.name,
				color: _.categoryColor,
				filterGroup: ScheduleBoardFilterGroup.EQUIPMENT_COST,
				checked: true,
			});
		});
		_equipmentCostCategories.unshift({
			id: ScheduleBoardFilterType.ASSIGNED,
			text: FILTER_DEFINED,
			color: null,
			filterGroup: ScheduleBoardFilterGroup.EQUIPMENT_COST,
			checked: true,
		});

		// Find and map all Crew Types
		const _crewTypes: FilterVM[] = crewTypes.map((_) => {
			return ({
				id: _.id,
				text: _.name,
				color: _.color,
				filterGroup: ScheduleBoardFilterGroup.CREW_TYPE,
				checked: true,
			});
		});
		_crewTypes.unshift({
			id: ScheduleBoardFilterType.ASSIGNED,
			text: FILTER_DEFINED,
			color: null,
			filterGroup: ScheduleBoardFilterGroup.CREW_TYPE,
			checked: true,
		});
		_crewTypes.push({
			id: ScheduleBoardFilterType.UNASSIGNED,
			text: FILTER_CUSTOM_CREW_TYPE,
			color: DefaultColor.INTERNAL_JOB,
			filterGroup: ScheduleBoardFilterGroup.CREW_TYPE,
			checked: true,
		});

		this[ScheduleBoardFilterGroup.CREW_TYPE] = _crewTypes;
		this[ScheduleBoardFilterGroup.EQUIPMENT_OFFICE] = _equipmentOffices;
		this[ScheduleBoardFilterGroup.EMPLOYEE_OFFICE] = _equipmentOffices.map((_fvm: FilterVM) => (
			{
				..._fvm,
				filterGroup: ScheduleBoardFilterGroup.EMPLOYEE_OFFICE,
			}
		));
		this[ScheduleBoardFilterGroup.EQUIPMENT_COST] = _equipmentCostCategories;

	}
}
