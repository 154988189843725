import * as React from 'react';
import type { CellContext } from '@tanstack/react-table';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import ToolStatus, { ToolStatusLabel } from '@acceligentllc/shared/enums/toolStatus';
import ToolState, { ToolStateLabel } from '@acceligentllc/shared/enums/toolState';

import { formatDate } from '@acceligentllc/shared/utils/time';

import type { ToolTrackingRecordViewModel } from 'ab-viewModels/equipment/equipmentPreview.viewModel';

import LinkCell from 'af-components/Table/Cells/LinkCell';
import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import SimpleTableControl from 'af-components/Controls/SimpleTable';
import Label from 'af-components/Label';

import { getFullClientUrl } from 'af-utils/http.util';

import CLIENT from 'af-routes/client';

import styles from './styles.module.scss';

interface OwnProps {
	toolTrackingRecords: ToolTrackingRecordViewModel[];
	orgAlias: string;
	companyName: string;
}

type Props = OwnProps;

const SmallToolTable: React.FC<Props> = ({ toolTrackingRecords, orgAlias, companyName }) => {

	const rows = React.useMemo(() => {
		return toolTrackingRecords.slice().sort((a, b) => a.modelNumber.localeCompare(b.modelNumber));
	}, [toolTrackingRecords]);

	const renderSerialNumberCell = React.useCallback((_cell: CellContext<ToolTrackingRecordViewModel, string>) => {
		const id = _cell.row.original.toolId;
		const path = getFullClientUrl(orgAlias, CLIENT.COMPANY.EQUIPMENT.TOOL.PREVIEW(`${id}`, orgAlias, companyName));
		return <><LinkCell label="" path={path} />{_cell.row.original.serialNumber}</>;
	}, [companyName, orgAlias]);

	const renderStatusCell = React.useCallback((cell: CellContext<ToolTrackingRecordViewModel, string>) => {
		const status = cell.row.original.status;

		const text = ToolStatusLabel[status];

		switch (status) {
			case ToolStatus.IN_REPAIR:
			case ToolStatus.OUT_FOR_REPAIR:
			case ToolStatus.IN_SERVICE:
				return <Label className={'pending'} text={text} />;
			default:
				return <Label className={'completed'} text={text} />;
		}
	}, []);

	const renderStateCell = React.useCallback((cell: CellContext<ToolTrackingRecordViewModel, string>) => {
		const state = cell.row.original.state;

		const text = ToolStateLabel[state];

		switch (state) {
			case ToolState.INACTIVE:
				return <Label className={'inactive'} text={text} />;
			default:
				return <Label className={'completed'} text={text} />;
		}
	}, []);

	const columns: SimpleTableProps<ToolTrackingRecordViewModel>['columns'] = React.useMemo(() => [{
		id: 'modelNumber',
		cell: (_cell: CellContext<ToolTrackingRecordViewModel, string>) => {
			return _cell.getValue();
		},
		header: 'Model Number',
		accessor: 'modelNumber',
		enableSorting: true,
		size: 130,
	},
	{
		id: 'serialNumber',
		cell: renderSerialNumberCell,
		header: 'Serial Number',
		accessor: 'serialNumber',
		enableSorting: true,
		size: 130,
	},
	{
		id: 'type',
		cell: (_cell: CellContext<ToolTrackingRecordViewModel, number>) => {
			return _cell.getValue();
		},
		header: 'Type',
		accessor: 'type',
		enableSorting: true,
		size: 200,
	},
	{
		id: 'state',
		cell: renderStateCell,
		header: 'Status',
		accessor: 'state',
		enableSorting: true,
		size: 200,
	},
	{
		id: 'status',
		cell: renderStatusCell,
		header: 'Repair Status',
		accessor: 'status',
		enableSorting: true,
		size: 200,
	},
	{
		id: 'validFrom',
		cell: (_cell: CellContext<ToolTrackingRecordViewModel, number>) => {
			return formatDate(_cell.getValue(), TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);
		},
		header: 'Assigned to Equipment on',
		accessor: 'validFrom',
		enableSorting: false,
	}], [renderSerialNumberCell, renderStateCell, renderStatusCell]);

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>Small Tools Assigned to Equipment</div>
			<SimpleTableControl columns={columns} rows={rows} />
		</div>
	);
};

export default React.memo(SmallToolTable);
