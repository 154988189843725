import * as React from 'react';

import type { PercentagesOfDateChanges } from './types';
import Bar from './Bar';

interface Props {
	dateChangesOnSectionInPercentages: PercentagesOfDateChanges[];
	timelineWidth: number;
}

const TEXT_WIDTH = 84;
const TIMELINE_PADDINGS = 32;

const BarsOnDateChanges: React.FC<Props> = (props: Props) => {

	const {
		dateChangesOnSectionInPercentages,
		timelineWidth,
	} = props;

	const showDateChangeBars = !!dateChangesOnSectionInPercentages.length;

	const getTextTranslatePercentageVariables = React.useCallback((percentage: number, isBeforeDueDate: boolean) => {
		let adjustedPercentage = percentage;
		if (isBeforeDueDate) {
			adjustedPercentage = adjustPercentageOffsetBeforeDueDate(percentage, timelineWidth);
		}
		if (!isBeforeDueDate) {
			adjustedPercentage = adjustPercentageOffsetAfterDueDate(percentage, timelineWidth);
		}
		return {
			'--translateRightPercentage': `${adjustedPercentage}%`,
			'--translateLeftPercentage': `${adjustedPercentage}%`,
			'--textWidth': `${TEXT_WIDTH}px`,
		} as React.CSSProperties;
	}, [timelineWidth]);

	const willOverlapWithDateText = React.useCallback((
		percentage: number,
		isBeforeDueDate: boolean,
		_timelineWidth: number,
		previousDatePercentage: PercentagesOfDateChanges,
		followingDatePercentage: PercentagesOfDateChanges
	) => {
		if (!_timelineWidth) {
			return false;
		}
		const percentageOfText = TEXT_WIDTH / _timelineWidth * 100;
		if (isBeforeDueDate) {
			return willOverLapWithFollowingText(percentage, percentageOfText, followingDatePercentage);
		}
		return willOverLapWithPreviousText(percentageOfText, previousDatePercentage);
	}, []);

	const renderBar = React.useCallback((dateChangePercentageData: PercentagesOfDateChanges, index: number) => {
		const _willOverlapWithDateText = willOverlapWithDateText(
			dateChangePercentageData.leftOffsetInPercentage,
			dateChangePercentageData.isBeforeDueDate,
			timelineWidth,
			dateChangesOnSectionInPercentages[index - 1],
			dateChangesOnSectionInPercentages[index + 1]

		);
		const translatePercentageVariables = getTextTranslatePercentageVariables(
			dateChangePercentageData.leftOffsetInPercentage,
			dateChangePercentageData.isBeforeDueDate
		);
		return (
			<Bar
				dateChangePercentageData={dateChangePercentageData}
				key={dateChangePercentageData.dateToShow.toISOString()}
				translatePercentageVariables={translatePercentageVariables}
				willOverlapWihtDateText={_willOverlapWithDateText}
			/>
		);
	}, [dateChangesOnSectionInPercentages, getTextTranslatePercentageVariables, timelineWidth, willOverlapWithDateText]);

	return (
		<>
			{showDateChangeBars && dateChangesOnSectionInPercentages.map(renderBar)}
		</>
	);

	function adjustPercentageOffsetAfterDueDate(percentage: number, _timelineWidth: number) {
		if (_timelineWidth === 0) {
			return 100;
		}

		const percentageOfText = TEXT_WIDTH / _timelineWidth * 100;
		const percentageOfPadding = TIMELINE_PADDINGS / (_timelineWidth + 2 * TIMELINE_PADDINGS) * 100;

		const endOfText = percentage + percentageOfText;
		if (endOfText - percentageOfPadding <= 100) {
			return 100;
		}

		return 100 * ((100 - percentage) / percentageOfText) + // Adjust for amount outside of timeline, scaled to text width
			100 * ((100 * (TIMELINE_PADDINGS / (_timelineWidth + 2 * TIMELINE_PADDINGS))) / percentageOfText); // Adjust for the outside padding, scaled to text width
	}

	function adjustPercentageOffsetBeforeDueDate(percentage: number, _timelineWidth: number) {
		if (_timelineWidth === 0) {
			return -100;
		}

		const percentageOfText = TEXT_WIDTH / _timelineWidth * 100;
		const percentageOfPadding = TIMELINE_PADDINGS / (_timelineWidth + 2 * TIMELINE_PADDINGS) * 100;

		const endOfText = percentage - percentageOfText;
		if (endOfText + percentageOfPadding > 0) {
			return -100;
		}

		return -100 * (percentage / percentageOfText)// Adjust for amount outside of timeline, scaled to text width
			- 100 * ((100 * (TIMELINE_PADDINGS / (_timelineWidth + 2 * TIMELINE_PADDINGS))) / percentageOfText); // Adjust for the outside padding, scaled to text width
	}

	function willOverLapWithFollowingText(percentage: number, percentageOfText: number, followingDatePercentage: PercentagesOfDateChanges) {
		if (followingDatePercentage === undefined) {
			return false;
		}
		return percentage + percentageOfText >= followingDatePercentage.leftOffsetInPercentage;
	}

	function willOverLapWithPreviousText(percentageOfText: number, previousDatePercentage: PercentagesOfDateChanges) {
		if (previousDatePercentage === undefined) {
			return false;
		}
		return previousDatePercentage.leftOffsetInPercentage + percentageOfText >= 100 - percentageOfText;
	}
};

export default BarsOnDateChanges;
