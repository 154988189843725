import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import * as LogActions from 'af-actions/log';

import type { LogViewModel } from 'ab-viewModels/log.viewModel';

import type { RootState } from 'af-reducers';

import LogPreviewFields from 'af-components/SharedPreviews/Settings/Logs';
import Breadcrumbs from 'af-components/Breadcrumbs';

import Loading from './Loading';

import CLIENT from 'af-constants/routes/client';
import { compose } from 'redux';
import { Button } from '@acceligentllc/storybook';

interface PathParams {
	logId: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	log: Nullable<LogViewModel>;
}

class LogPreview extends React.PureComponent<Props, State> {
	state: State = {
		log: null,
	};

	async componentDidMount() {
		const { fetch, match: { params: { logId } } } = this.props;
		const log = await fetch(logId);

		this.setState(() => ({ log: log ?? null }));
	}

	goBack = () => {
		const {
			history,
			companyName,
			location: { state: { orgAlias } },
		} = this.props;

		history.push(CLIENT.COMPANY.SETTINGS.LOGS.TABLE(orgAlias, companyName));
	};

	render() {
		const {
			companyName,
			location: { state: { orgAlias } },
		} = this.props;
		const { log } = this.state;

		if (!log) {
			return <Loading />;
		}

		return (
			<div className="form-segment">
				<Breadcrumbs
					items={
						[
							{ label: 'Logs', url: CLIENT.COMPANY.SETTINGS.LOGS.TABLE(orgAlias, companyName) },
							{ label: 'Details' },
						]
					}
				/>
				<div className="form-box">
					<LogPreviewFields log={log} />
					<Row className="row--submit">
						<Button
							label="Back"
							onClick={this.goBack}
							style="secondary"
							type="button"
						/>
					</Row>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		fetch: LogActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps())
);
export default enhance(LogPreview);
