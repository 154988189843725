import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button } from '@acceligentllc/storybook';

import CLIENT from 'af-routes/client';

type Props = CustomRouteComponentProps<void>;

const ForgotOrganizationSuccess: React.FC<Props> = React.memo((props) => {
	const { history } = props;

	const goBack = React.useCallback(() => {
		history.push(CLIENT.APP.ORGANIZATIONS);
	}, [history]);

	return (
		<div className="form-segment form-segment--mini">
			<div className="form-box form-box--standalone form-box--padded">
				<div className="form-box__icon-container">
					<span className="form-box__icon form-box__icon--success icon-check" />
				</div>
				<h2>Instructions Sent</h2>
				<h4>We have sent an email to your address!</h4>
				<h4>Please follow the instructions You have received.</h4>
				<div className="form-box__footer">
					<Button
						label="Back"
						onClick={goBack}
						style="secondary"
						type="button"
					/>
				</div>
			</div>
		</div>
	);
});

export default withRouter(ForgotOrganizationSuccess);
