import type DisplayViewToggle from '@acceligentllc/shared/enums/displayViewToggle';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import type NotificationPriority from '@acceligentllc/shared/enums/notificationPriority';
import type AutoNotifyOption from '@acceligentllc/shared/enums/autoNotifyOption';
import type WeekDay from '@acceligentllc/shared/enums/weekDay';

import type { SkillColorGrid } from 'ab-domain/models/company/base';
import type CompanyBase from 'ab-domain/models/company/base';
import type AddressBase from 'ab-domain/models/address/base';
import type HolidayBase from 'ab-domain/models/holiday/base';
import type UserGroupBase from 'ab-domain/models/userGroup/base';
import type NotificationSettingBase from 'ab-domain/models/notificationSetting/base';
import type WorkDayBase from 'ab-domain/models/workDay/base';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeOptionUtils from '@acceligentllc/shared/utils/timeOption';
import * as BlobStorageUtils from '@acceligentllc/shared/utils/blobStorage';

class WorkDayViewModel {
	id: number;
	companyId: number;
	weekDay: WeekDay;

	constructor(workDay: WorkDayBase) {
		this.id = workDay.id;
		this.companyId = workDay?.company?.id;
		this.weekDay = workDay.weekDay;
	}

	private static _constructorMap = (entry: WorkDayBase) => new WorkDayViewModel(entry);

	static bulkConstructor = (workDays: WorkDayBase[]) => workDays.map(WorkDayViewModel._constructorMap);
}

class UserGroupVM {
	id: number;
	name: string;

	constructor(userGroup: UserGroupBase) {
		this.id = userGroup.id;
		this.name = userGroup.name;
	}
}

class AddressVM {
	id: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: string;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;

	constructor(address: AddressBase) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.locality = address.locality;
		this.postalCode = address.postalCode;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
	}
}

class HolidayVM {
	id: number;
	name: string;

	constructor(holiday: HolidayBase) {
		this.id = holiday.id;
		this.name = holiday.name;
	}

	static toViewModels = (holidays: HolidayBase[] = []): HolidayVM[] => {
		return holidays.map((_holiday) => new HolidayVM(_holiday));
	};
}

class NotificationSettingsViewModel {
	isEnabled: boolean;
	enableScheduleBoardEmail: boolean;
	enablePastWorkOrdersNotification: boolean;
	notificationPriority: NotificationPriority;
	notifyOnPublish: Nullable<AutoNotifyOption>;
	notifyOnCancel: Nullable<AutoNotifyOption>;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyScheduleBoardEmail: number;
	notifyUnassignedEmployees: boolean;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	notifyOnDay: number[];
	scheduleBoardEmailUserGroupId: Nullable<number>;
	scheduleBoardEmailUserGroup: Nullable<UserGroupVM>;
	pastWorkOrdersNotificationUserGroupId: Nullable<number>;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupVM>;
	notifyYesterdaysBoardAt: number;
	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardUserGroupId: Nullable<number>;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupVM>;
	notifyFailedAt: number;
	enableNotifyFailed: boolean;
	notifyFailedUserGroupId: Nullable<number>;
	notifyFailedUserGroup: Nullable<UserGroupVM>;
	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;

	constructor(notification: NotificationSettingBase) {
		this.isEnabled = notification.isEnabled;
		this.enableScheduleBoardEmail = notification.enableScheduleBoardEmail;
		this.enablePastWorkOrdersNotification = notification.enablePastWorkOrdersNotification;
		this.notificationPriority = notification.notificationPriority;
		this.notifyOnPublish = notification.notifyOnPublish;
		this.notifyOnCancel = notification.notifyOnCancel;
		this.notifyOnPause = notification.notifyOnPause;
		this.notifyOnResume = notification.notifyOnResume;
		this.notifyScheduleBoardEmail = notification.notifyScheduleBoardEmail;
		this.notifyUnassignedEmployees = notification.notifyUnassignedEmployees;
		this.notifyMultipleAssignments = notification.notifyMultipleAssignments;
		this.notifySupervisors = notification.notifySupervisors;
		this.notifyOnDay = [
			notification.notifyOnSunday,
			notification.notifyOnMonday,
			notification.notifyOnTuesday,
			notification.notifyOnWednesday,
			notification.notifyOnThursday,
			notification.notifyOnFriday,
			notification.notifyOnSaturday,
		];
		this.scheduleBoardEmailUserGroupId = notification.scheduleBoardEmailUserGroupId;
		this.scheduleBoardEmailUserGroup = notification.scheduleBoardEmailUserGroup && new UserGroupVM(notification.scheduleBoardEmailUserGroup);
		this.pastWorkOrdersNotificationUserGroupId = notification.pastWorkOrdersNotificationUserGroupId;
		this.pastWorkOrdersNotificationUserGroup = notification.pastWorkOrdersNotificationUserGroup &&
			new UserGroupVM(notification.pastWorkOrdersNotificationUserGroup);
		this.enableNotifyYesterdaysBoard = notification.enableNotifyYesterdaysBoard;
		this.notifyYesterdaysBoardAt = notification.notifyYesterdaysBoardAt;
		this.notifyYesterdaysBoardUserGroupId = notification.notifyYesterdaysBoardUserGroupId;
		this.notifyYesterdaysBoardUserGroup = notification.notifyYesterdaysBoardUserGroup &&
			new UserGroupVM(notification.notifyYesterdaysBoardUserGroup);
		this.enableNotifyFailed = notification.enableNotifyFailed;
		this.notifyFailedAt = notification.notifyFailedAt;
		this.notifyFailedUserGroupId = notification.notifyFailedUserGroupId;
		this.notifyFailedUserGroup = notification.notifyFailedUserGroup && new UserGroupVM(notification.notifyFailedUserGroup);
		this.enableNotifyQAQC = notification.enableNotifyQAQC;
		this.enableNotifyDeliverableAssignee = notification.enableNotifyDeliverableAssignee;
		this.enableAutoNotifyDailyTips = notification.enableAutoNotifyDailyTips;
	}

	static getNotifyAtForDate(notificationSettings: NotificationSettingsViewModel, date: Date): number {
		// moment day() returns 0-6 where 0 is always Sunday
		const dateMoment = TimeUtils.parseMoment(date);
		if (!dateMoment) {
			throw new Error('Date not provided');
		}

		return notificationSettings.notifyOnDay[dateMoment.day()];
	}
}

class CompanyViewModel {
	id: number;
	name: string;
	imageUrl: string;
	address: AddressVM;
	workDays: WorkDayViewModel[];
	holidays: HolidayVM[];
	website: Nullable<string>;
	notification: NotificationSettingsViewModel;
	displayViewChangeTime: number;
	displayViewRotationTime: number;
	displayViewType: DisplayViewToggle;
	includeNonWorkingDaysOnDisplayView: boolean;
	fiscalYearStartMonth: number;
	skillColorGrid: SkillColorGrid;
	cdlInSkillsGrid: boolean;
	timezone: string;
	dayShiftStart: number;
	dayShiftEnd: number;
	nightShiftStart: number;
	nightShiftEnd: number;
	fieldReportAccessDayDuration: number;
	isEquipmentAutomaticReturnDate: boolean;
	isEmployeeAutomaticReturnDate: boolean;
	isFRManageAllowedToApproveTimeSheet: boolean;
	isFRManageAllowedToEditTimeSheet: boolean;
	isFRManageLevelAllowedInReviewProcess: boolean;
	customPDFHeaderUrl: Nullable<string>;
	/** YYYY-MM-DD */
	createdAt: string;

	constructor(company: CompanyBase) {
		this.id = company.id;
		this.name = company.name;

		const result = company.imageUrl && BlobStorageUtils.parseStorageUrl(company.imageUrl);
		if (result) {
			result.directories = BlobStorageUtils.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtils.getStorageUrl(result.directories, result.filename);
		}

		this.address = new AddressVM(company.primaryAddress);
		this.workDays = company.workDays && WorkDayViewModel.bulkConstructor(company.workDays);
		this.holidays = HolidayVM.toViewModels(company.holidays);
		this.website = company.website;
		if (company.notification) {
			this.notification = new NotificationSettingsViewModel(company.notification);
		}
		this.displayViewChangeTime = company.displayViewChangeTime;
		this.displayViewRotationTime = company.displayViewRotationTime;
		this.displayViewType = company.displayViewType;
		this.includeNonWorkingDaysOnDisplayView = company.includeNonWorkingDaysOnDisplayView;
		this.fiscalYearStartMonth = company.fiscalYearStartMonth;
		this.skillColorGrid = company.skillColorGrid;
		this.cdlInSkillsGrid = company.cdlInSkillsGrid;
		this.timezone = company.notification?.timezone;
		this.dayShiftStart = company.dayShiftStart;
		this.dayShiftEnd = company.dayShiftEnd;
		this.nightShiftStart = company.nightShiftStart;
		this.nightShiftEnd = company.nightShiftEnd;
		this.fieldReportAccessDayDuration = company.fieldReportAccessDayDuration;
		this.isEquipmentAutomaticReturnDate = company.isEquipmentAutomaticReturnDate;
		this.isEmployeeAutomaticReturnDate = company.isEmployeeAutomaticReturnDate;
		this.isFRManageAllowedToApproveTimeSheet = company.isFRManageAllowedToApproveTimeSheet;
		this.isFRManageAllowedToEditTimeSheet = company.isFRManageAllowedToEditTimeSheet;
		this.isFRManageLevelAllowedInReviewProcess = company.isFRManageLevelAllowedInReviewProcess;
		this.createdAt = TimeUtils.formatDate(company.createdAt, TimeFormat.ISO_DATETIME);

		const customHeaderResult = company.customPDFHeaderUrl && BlobStorageUtils.parseStorageUrl(company.customPDFHeaderUrl);
		if (customHeaderResult) {
			this.customPDFHeaderUrl = BlobStorageUtils.getStorageUrl(customHeaderResult.directories, customHeaderResult.filename);
		}
	}
}

export interface ScheduleBoardSettingsFormToModel {
	displayViewChangeTime: number;
	displayViewRotationTime: number;
	skillColorGrid: SkillColorGrid;
	cdlInSkillsGrid: boolean;
	displayViewType: DisplayViewToggle;
	includeNonWorkingDaysOnDisplayView: boolean;
}

export class ScheduleBoardSettingsForm {
	displayViewChangeTime: string;
	displayViewRotationTime: number;
	skillColorGrid: SkillColorGrid;
	cdlInSkillsGrid: boolean;
	displayViewType: DisplayViewToggle;
	includeNonWorkingDaysOnDisplayView: boolean;

	constructor(company: CompanyViewModel) {
		this.displayViewChangeTime = TimeOptionUtils.toString(company.displayViewChangeTime);
		this.displayViewRotationTime = company.displayViewRotationTime;
		this.skillColorGrid = company.skillColorGrid;
		this.cdlInSkillsGrid = company.cdlInSkillsGrid;
		this.displayViewType = company.displayViewType;
		this.includeNonWorkingDaysOnDisplayView = company.includeNonWorkingDaysOnDisplayView;
	}

	static toModel(form: ScheduleBoardSettingsForm): ScheduleBoardSettingsFormToModel {
		const displayViewChangeTime = TimeOptionUtils.toNumber(form.displayViewChangeTime);
		if (!displayViewChangeTime) {
			throw new Error('Change time not defined');
		}

		return {
			displayViewChangeTime,
			displayViewRotationTime: form.displayViewRotationTime,
			skillColorGrid: form.skillColorGrid,
			cdlInSkillsGrid: form.cdlInSkillsGrid,
			includeNonWorkingDaysOnDisplayView: form.includeNonWorkingDaysOnDisplayView,
			displayViewType: form.displayViewType,
		};
	}
}
