import type { CustomFormErrors, FormErrors } from 'redux-form';
import { nanoid } from 'nanoid';

import type { TimeAllocationEntryRM, TimeSheetsBulkUpdateRM, TimeSplitEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSplitEntryVM from 'ab-viewModels/timeSplitEntry/timeSplitEntry';
import type TimeAllocationEntryVM from 'ab-viewModels/timeAllocationEntry/timeAllocationEntry';
import type { TimeSheetEntryRM } from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetUpdateRM from 'ab-requestModels/timeSheet/timeSheetUpdate';
import type TimeSheetEntryVM from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import TimeSheetEntryType from '@acceligentllc/shared/enums/timeSheetEntryType';
import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import { offsetTimeSheetEntry, revertOffsetTimeSheetEntry } from '../../helpers';
import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';
import type { OverlapMeta, TimelineEntity } from '@acceligentllc/shared/utils/timeSheetEntry';

export class TimeSheetEntryFormModel {
	id?: number;
	type: TimeSheetEntryType;
	workType: TimeSheetEntryWorkType;
	/** MM-DD-YYYY */
	startDate: string;
	/** format: ISO_DATETIME */
	startTime: string;
	/** format: ISO_DATETIME */
	endTime: Nullable<string>;
	equipmentId: Nullable<number>;
	createdAt: Nullable<Date>;
	isInActiveShift: boolean;
	workOrderCode: Nullable<string>;
	belongsToOtherSheet: boolean;
	virtualId: string;
	isNew: boolean;

	static readonly DEFAULT_WORK_TYPE = null;
	static readonly WORK_TYPE_OPTIONS = Object.values(TimeSheetEntryWorkType);

	static toRequestModel = (form: TimeSheetEntryFormModel, timeZoneInUse: Nullable<string>): TimeSheetEntryRM => {
		const { startTime, endTime } = revertOffsetTimeSheetEntry(form.startTime, form.endTime, timeZoneInUse);

		return {
			id: form.id,
			isInActiveShift: form.isInActiveShift,
			type: form.type,
			workType: form.workType,
			startTime,
			endTime,
			equipmentId: form.equipmentId,
		};
	};

	static parseFromVM = (entry: TimeSheetEntryVM, timeZone: Nullable<string>): TimeSheetEntryFormModel => {
		const { startDate, startTime, endTime } = offsetTimeSheetEntry(entry.startTime, entry.endTime, timeZone);

		return {
			id: entry.id,
			type: entry.type,
			workType: entry.workType,
			startTime,
			startDate,
			endTime,
			equipmentId: entry.equipmentId,
			createdAt: TimeUtils.toDate(entry.createdAt, TimeFormat.ISO_DATETIME),
			isInActiveShift: entry.isInActiveShift,
			workOrderCode: entry.workOrderCode,
			belongsToOtherSheet: entry.belongsToOtherSheet,
			virtualId: nanoid(UNIQUE_ID_SIZE),
			isNew: false,
		};
	};

	static dateIsInThePast = (date: string) => {
		return TimeUtils.getDiff(new Date(), TimeUtils.parseDate(date, TimeFormat.ISO_DATETIME), 'milliseconds') > 0;
	};

	static validate = (entry: TimeSheetEntryFormModel): FormErrors<TimeSheetEntryFormModel> => {
		const errors: FormErrors<TimeSheetEntryFormModel> = {};
		if (!entry.startTime) {
			errors.startTime = 'Start time is required';
		} else {
			if (!TimeUtils.isDateInCorrectFormat(entry.startTime, TimeFormat.ISO_DATETIME)) {
				errors.startTime = 'Start time is not in ISO_DATETIME format';
			}
			if (!TimeSheetEntryFormModel.dateIsInThePast(entry.startTime)) {
				errors.startTime = 'Start time must be in the past';
			}
		}
		if (entry.endTime && !TimeUtils.isDateInCorrectFormat(entry.endTime, TimeFormat.ISO_DATETIME)) {
			errors.endTime = 'End time is not in ISO_DATETIME format';
		}
		if (entry.endTime && !TimeSheetEntryFormModel.dateIsInThePast(entry.endTime)) {
			errors.endTime = 'End time must be in the past';
		}
		if (!entry.startDate) {
			errors.startDate = 'Start date is required';
		} else {
			if (!TimeUtils.isDateInCorrectFormat(entry.startDate, TimeFormat.DATE_ONLY)) {
				errors.startDate = 'Start date is not in MM-DD-YYYY format';
			}
		}
		return errors;
	};
}

class TimeSheetGapEntryFormModel {
	id?: number;
	/** MM-DD-YYYY */
	startDate: string;
	/** format: ISO_DATETIME */
	startTime: string;
	/** format: ISO_DATETIME */
	endTime: Nullable<string>;
	virtualId: string;

	static parseFromVM = (entry: {
		startTime: string;
		endTime: string;
	}, timeZone: Nullable<string>): TimeSheetGapEntryFormModel => {
		const { startDate, startTime, endTime } = offsetTimeSheetEntry(entry.startTime, entry.endTime, timeZone);

		return {
			startTime,
			startDate,
			endTime,
			virtualId: nanoid(UNIQUE_ID_SIZE),
		};
	};

}

class TimeSheetOccupiedEntryFormModel {
	id?: number;
	/** MM-DD-YYYY */
	startDate: string;
	/** format: ISO_DATETIME */
	startTime: string;
	/** format: ISO_DATETIME */
	endTime: Nullable<string>;
	virtualId: string;

	static parseFromVM = (entry: {
		startTime: string;
		endTime: string;
	}, timeZone: Nullable<string>): TimeSheetOccupiedEntryFormModel => {
		const { startDate, startTime, endTime } = offsetTimeSheetEntry(entry.startTime, entry.endTime, timeZone);

		return {
			startTime,
			startDate,
			endTime,
			virtualId: nanoid(UNIQUE_ID_SIZE),
		};
	};

}

export class TimeSheetAddedEntryFormModel {
	virtualId: string;
	workType: Nullable<TimeSheetEntryWorkType>;
	/** MM-DD-YYYY */
	startDate: Nullable<string>;
	/** format: ISO_DATETIME */
	startTime: Nullable<string>;
	/** format: ISO_DATETIME */
	endTime: Nullable<string>;
	isNew: boolean;
	equipmentId: Nullable<number>;

	constructor(
		isNew: boolean,
		startTime?: string,
		endTime?: string,
		startDate?: string
	) {
		this.virtualId = nanoid(UNIQUE_ID_SIZE);
		this.workType = null;
		this.startDate = startDate ?? null;
		this.startTime = startTime ?? null;
		this.endTime = endTime ?? null;
		this.isNew = isNew;
		this.equipmentId = null;
	}

	static dateIsInThePast = (date: string) => {
		return TimeUtils.getDiff(new Date(), TimeUtils.parseDate(date, TimeFormat.ISO_DATETIME), 'milliseconds') > 0;
	};

	static validate = (entry: TimeSheetAddedEntryFormModel): FormErrors<TimeSheetAddedEntryFormModel> => {

		const errors: FormErrors<TimeSheetAddedEntryFormModel> = {};
		if (entry.startTime) {
			if (!TimeUtils.isDateInCorrectFormat(entry.startTime, TimeFormat.ISO_DATETIME)) {
				errors.startTime = 'Start time is not in ISO_DATETIME format';
			}
			if (!TimeSheetAddedEntryFormModel.dateIsInThePast(entry.startTime)) {
				errors.startTime = 'Start time must be in the past';
			}
		}
		if (entry.endTime) {
			if (!TimeUtils.isDateInCorrectFormat(entry.endTime, TimeFormat.ISO_DATETIME)) {
				errors.endTime = 'End time is not in ISO_DATETIME format';
			}
			if (!TimeSheetAddedEntryFormModel.dateIsInThePast(entry.endTime)) {
				errors.endTime = 'End time must be in the past';
			}
		}
		if (entry.startDate && !TimeUtils.isDateInCorrectFormat(entry.startDate, TimeFormat.DATE_ONLY)) {
			errors.startDate = 'Start date is not in MM-DD-YYYY format';
		}
		return errors;
	};
}

export class TimeSplitEntryFormModel {
	virtualId?: string;
	fieldWorkClassificationCodeId: Nullable<number>;
	equipmentId: Nullable<number>;
	duration: number;
	allocatedWorkRequestId: number;

	static readonly POSITIVE_INTEGER_REGEX = /^\d+$/;
	/** Min duration in minutes */
	static readonly MIN_DURATION = 0;
	/** Max duration in minutes */
	static readonly MAX_DURATION = 1440;

	constructor(workRequestId: number) {
		this.virtualId = nanoid(UNIQUE_ID_SIZE);
		this.fieldWorkClassificationCodeId = null;
		this.equipmentId = null;
		this.duration = TimeSplitEntryFormModel.MIN_DURATION;
		this.allocatedWorkRequestId = workRequestId;
	}

	static parseFromVM = (entry: TimeSplitEntryVM): TimeSplitEntryFormModel => {
		return {
			virtualId: `${entry.id}`,
			fieldWorkClassificationCodeId: entry.fieldWorkClassificationCodeId,
			equipmentId: entry.equipmentId,
			duration: entry.time,
			allocatedWorkRequestId: entry.allocatedWorkRequestId,
		};
	};

	static validate = (entry: TimeSplitEntryFormModel): FormErrors<TimeSplitEntryFormModel> => {
		const errors: FormErrors<TimeSplitEntryFormModel> = {};

		if (!Number.isInteger(entry.duration)) {
			errors.duration = 'Add duration';
		}

		if (!!entry.duration && entry.duration % DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL !== 0) {
			errors.duration = `Duration is not a ${DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL} minute interval`;
		}

		if (!!entry.duration && entry.duration < TimeSplitEntryFormModel.MIN_DURATION || entry.duration > TimeSplitEntryFormModel.MAX_DURATION) {
			errors.duration = `Duration must be between ${TimeSplitEntryFormModel.MIN_DURATION} and ${TimeSplitEntryFormModel.MAX_DURATION}`;
		}

		return errors;
	};

	static toRequestModel = (entry: TimeSplitEntryFormModel): TimeSplitEntryRM => {
		return {
			equipmentId: entry.equipmentId!,
			fieldWorkClassificationCodeId: entry.fieldWorkClassificationCodeId!,
			time: entry.duration,
			allocatedWorkRequestId: entry.allocatedWorkRequestId,
		};
	};
}

export class TimeAllocationEntryFormModel {
	id?: number;
	workType: TimeSheetEntryWorkType;
	time: number;
	allocatedWorkRequestId: number;
	virtualId: string;

	static readonly DEFAULT_WORK_TYPE = TimeSheetEntryWorkType.JOB;
	static readonly DEFAULT_DURATION = 15;
	static readonly WORK_TYPE_OPTIONS = Object.values(TimeSheetEntryWorkType);

	constructor(workRequestId: number, workType?: TimeSheetEntryWorkType, duration?: number) {
		this.virtualId = nanoid(UNIQUE_ID_SIZE);
		this.workType = workType ?? TimeAllocationEntryFormModel.DEFAULT_WORK_TYPE;
		this.time = duration ?? TimeAllocationEntryFormModel.DEFAULT_DURATION;
		this.allocatedWorkRequestId = workRequestId;
	}

	// this serves purpose for allocation entries with time over 24h
	// in database we save it as is, but on frontend our dropdowns show time only up to 24h
	// so here we make sure we split those entries into multiple entries so that each is max 24h long
	static splitEntryInMultipleByDay = (entry: TimeAllocationEntryVM | TimeAllocationEntryFormModel) => {
		const DAY_IN_MINUTES = 24 * 60;

		let totalEntryTime = entry.time;

		const entries: TimeAllocationEntryFormModel[] = [];

		while (totalEntryTime > DAY_IN_MINUTES) {
			entries.push({
				id: entry.id,
				workType: entry.workType,
				time: DAY_IN_MINUTES,
				allocatedWorkRequestId: entry.allocatedWorkRequestId,
				virtualId: nanoid(UNIQUE_ID_SIZE),
			});
			totalEntryTime = totalEntryTime - DAY_IN_MINUTES;
		}

		entries.push({
			id: entry.id,
			workType: entry.workType,
			time: totalEntryTime,
			allocatedWorkRequestId: entry.allocatedWorkRequestId,
			virtualId: nanoid(UNIQUE_ID_SIZE),
		});

		return entries;
	};

	static toRequestModel = (form: TimeAllocationEntryFormModel): TimeAllocationEntryRM => {

		return {
			workType: form.workType,
			time: form.time,
			allocatedWorkRequestId: form.allocatedWorkRequestId,
		};
	};

	static parseFromVM = (entry: TimeAllocationEntryVM): TimeAllocationEntryFormModel[] => {
		return TimeAllocationEntryFormModel.splitEntryInMultipleByDay(entry);
	};

	// we aggregate frontend entries based on work type and allocatedWorkRequestId
	// because in database we save it as one allocation entry (when on frontend the entry is split if it is longer than 24h)
	static sumAllEntriesWithSameWorkTypeAndWorkRequest = (
		timeAllocationEntries: TimeSheetEditFormModel['timeAllocationEntries']
	): TimeAllocationEntryRM[] => {

		const mapWithAggregatedDurations = timeAllocationEntries.reduce((_acc, _entry) => {
			const key = `${_entry.workType}${_entry.allocatedWorkRequestId}`;
			if (!_acc[key]) {
				_acc[key] = {
					allocatedWorkRequestId: _entry.allocatedWorkRequestId,
					workType: _entry.workType,
					time: 0,
				};
			}
			_acc[key].time += _entry.time;
			return _acc;
		}, {} as Record<string, TimeAllocationEntryRM>);

		return Object.values(mapWithAggregatedDurations);

	};

	static mapEntriesToTimesPerWorkType = (
		timeAllocationEntries: TimeSheetEditFormModel['timeAllocationEntries']
	) => {
		return timeAllocationEntries.reduce((_acc, _entry) => {
			if (_entry.workType === TimeSheetEntryWorkType.JOB) {
				_acc.jobAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.BREAK) {
				_acc.breakAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.SHOP) {
				_acc.shopAllocationsTime += _entry.time;
			} else if (_entry.workType === TimeSheetEntryWorkType.TRAVEL) {
				_acc.travelAllocationsTime += _entry.time;
			}
			return _acc;
		}, {
			jobAllocationsTime: 0,
			breakAllocationsTime: 0,
			shopAllocationsTime: 0,
			travelAllocationsTime: 0,
		});
	};

	static resetAllocationEntriesForWorkType = (
		timeAllocationEntries: TimeSheetEditFormModel['timeAllocationEntries'],
		workType: TimeSheetEntryWorkType,
		workRequestId: number,
		timeSheetEntriesDurationForWorkType: number
	) => {
		const updatedEntries = timeAllocationEntries.filter((entry) => entry.workType !== workType);
		if (timeSheetEntriesDurationForWorkType > 0) {
			const splitedEntries = TimeAllocationEntryFormModel.splitEntryInMultipleByDay(
				new TimeAllocationEntryFormModel(workRequestId, workType, timeSheetEntriesDurationForWorkType)
			);
			updatedEntries.push(...splitedEntries);
		}
		return updatedEntries;
	};

	static validate = (entry: TimeAllocationEntryFormModel): FormErrors<TimeAllocationEntryFormModel> => {
		const errors: FormErrors<TimeAllocationEntryFormModel> = {};
		if (!entry.workType) {
			errors.workType = 'Work type is required';
		}
		if (!Number.isInteger(entry.time)) {
			errors.time = 'Add duration';
		}

		if (!!entry.time && entry.time % DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL !== 0) {
			errors.time = `Duration is not a ${DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL} minute interval`;
		}

		if (!!entry.time && entry.time < TimeSplitEntryFormModel.MIN_DURATION || entry.time > TimeSplitEntryFormModel.MAX_DURATION) {
			errors.time = `Duration must be between ${TimeSplitEntryFormModel.MIN_DURATION} and ${TimeSplitEntryFormModel.MAX_DURATION}`;
		}

		return errors;
	};
}

export enum TimeSheetEntryFormType {
	OCCUPIED = 'OCCUPIED',
	ENTRY = 'ENTRY',
	GAP = 'GAP',
	ADDED = 'ADDED'
}

export interface TimeSheetEntryWithType {
	entry: TimeSheetEntryFormModel;
	type: TimeSheetEntryFormType.ENTRY;
}
export interface TimeSheetGapEntryWithType {
	entry: TimeSheetGapEntryFormModel;
	type: TimeSheetEntryFormType.GAP;
}
export interface TimeSheetOccupiedEntryWithType {
	entry: TimeSheetOccupiedEntryFormModel;
	type: TimeSheetEntryFormType.OCCUPIED;
}
export interface TimeSheetAddedEntryWithType {
	entry: TimeSheetAddedEntryFormModel;
	type: TimeSheetEntryFormType.ADDED;
}

interface TimeBeforeAndAfter {
	roundedWorkTimeBefore: number;
	roundedWorkTimeAfter: Nullable<number>;
}
export interface TimesPerWorkType {
	job: TimeBeforeAndAfter;
	break: TimeBeforeAndAfter;
	travel: TimeBeforeAndAfter;
	shop: TimeBeforeAndAfter;
}

class TimeSheetEditFormModel {
	accountId: number;
	userFullName: string;
	approvalStatus: TimeSheetVM['superintendentApprovalStatus'];
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	timeSheetEntries: (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType | TimeSheetAddedEntryWithType)[];
	timeSheetEntriesHaveChanged: boolean;
	timeSplitEntries: TimeSplitEntryFormModel[];
	timeSplitEntriesHaveChanged: boolean;
	editingEntryIndex: Nullable<number>;
	editingEntryInitialEntry: Nullable<TimeSheetEntryWithType | TimeSheetGapEntryWithType>;
	hasOverlap: boolean;
	overlaps: Record<string, OverlapMeta>;
	timeSheetInEditIndex: Nullable<number>;
	timeAllocationEntries: TimeAllocationEntryFormModel[];
	timeAllocationEntriesHaveChanged: boolean;
	workTimesBeforeAndAfter: TimesPerWorkType;

	static fieldName = (field: keyof TimeSheetEditFormModel) => field;

	static timeSheetEntryFieldName = (index: number, field: keyof TimeSheetEntryFormModel) =>
		`${TimeSheetEditFormModel.fieldName('timeSheetEntries')}[${index}].${field}`;

	static createAddedEntry = () => new TimeSheetAddedEntryFormModel(true);

	static addedEntryToListEntry = (entry: TimeSheetAddedEntryFormModel): TimeSheetEntryFormModel => {
		const { virtualId, workType, startDate, startTime, endTime, equipmentId } = entry;

		if (!workType || !startDate || !startTime || !endTime) {
			throw new Error('Cannot create list entry - fill all required info');
		}
		return {
			virtualId,
			type: TimeSheetEntryType.MANUAL,
			workType,
			startDate,
			startTime,
			endTime,
			equipmentId,
			createdAt: null,
			isInActiveShift: false,
			workOrderCode: null,
			belongsToOtherSheet: false,
			isNew: false,
		};
	};

	static toRequestModel = (form: Nullable<TimeSheetEditFormModel>, timeZoneInUse: Nullable<string>): TimeSheetUpdateRM => {
		const rm: TimeSheetUpdateRM = {};

		if (!form) {
			// Form never got initialized, nothing to update
			return rm;
		}

		if (form.timeSheetEntriesHaveChanged) {
			rm.timeSheetEntries = form.timeSheetEntries.reduce<TimeSheetEntryRM[]>((_acc, _entryWithType) => {
				if (_entryWithType.type === TimeSheetEntryFormType.ENTRY && !_entryWithType.entry.belongsToOtherSheet) {
					_acc.push(TimeSheetEntryFormModel.toRequestModel(_entryWithType.entry, timeZoneInUse));
				}
				return _acc;
			}, []);
		}

		if (form.timeSplitEntriesHaveChanged) {
			rm.timeSplitEntries = form.timeSplitEntries.map((_entry) => TimeSplitEntryFormModel.toRequestModel(_entry));
		}

		if (form.timeAllocationEntriesHaveChanged) {
			rm.timeAllocationEntries = TimeAllocationEntryFormModel.sumAllEntriesWithSameWorkTypeAndWorkRequest(form.timeAllocationEntries);
		}

		return rm;
	};

	static filterOutGapAndOccupiedEntries = (
		timeSheetEntries: TimeSheetEditFormModel['timeSheetEntries']
	) => {
		return timeSheetEntries.filter((_entryWithType) => _entryWithType.type === TimeSheetEntryFormType.ENTRY) as TimeSheetEntryWithType[];
	};

	static mapEntriesWithTypesToEntries = (
		timeSheetEntries: TimeSheetEditFormModel['timeSheetEntries']
	) => {
		return timeSheetEntries.reduce((_acc, _entryWithType) => {
			if (_entryWithType.type === TimeSheetEntryFormType.ENTRY) {
				_acc.push(_entryWithType.entry);
			}
			return _acc;
		}, [] as TimeSheetEntryFormModel[]);
	};

	static getTotalTimeForWorkType = (
		entries: (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType)[],
		workType: TimeSheetEntryWorkType
	) => {
		const totalTimeForWorkType = entries.reduce((acc, entry) => {
			if (entry.type === TimeSheetEntryFormType.ENTRY && entry.entry.workType === workType && entry.entry.endTime) {
				acc += TimeUtils.getDiff(entry.entry.endTime, entry.entry.startTime, 'minutes', TimeFormat.ISO_DATETIME);
			}
			return acc;
		}, 0);
		return totalTimeForWorkType;
	};

	static validate = (form: TimeSheetEditFormModel): FormErrors<TimeSheetEditFormModel> => {
		const errors: CustomFormErrors<TimeSheetEditFormModel> = {};

		errors.timeSheetEntries = form?.timeSheetEntries?.reduce((_acc, _entryWithType) => {
			if (_entryWithType.type === TimeSheetEntryFormType.ENTRY) {
				const validatedEntry = TimeSheetEntryFormModel.validate(_entryWithType.entry);
				if (validatedEntry.endTime || validatedEntry.startTime || validatedEntry.startDate) {
					_acc.push({ entry: validatedEntry } as FormErrors<TimeSheetEntryWithType>);
				} else {
					_acc.push({});
				}
			}
			else if (_entryWithType.type === TimeSheetEntryFormType.ADDED) {
				const validatedEntry = TimeSheetAddedEntryFormModel.validate(_entryWithType.entry);
				if (validatedEntry.endTime || validatedEntry.startTime || validatedEntry.startDate) {
					_acc.push({ entry: validatedEntry } as FormErrors<TimeSheetAddedEntryWithType>);
				} else {
					_acc.push({});
				}
			} else {
				_acc.push({});
			}
			return _acc;
		}, [] as FormErrors<TimeSheetEntryWithType | TimeSheetAddedEntryWithType>[]);
		const allEmpty = errors.timeSheetEntries?.every((element) => {
			return element && typeof element === 'object' && !Object.keys(element).length;
		});
		if (allEmpty) {
			errors.timeSheetEntries = [];
		}

		errors.timeSplitEntries = form?.timeSplitEntries?.reduce((_acc, _entry) => {
			const validatedEntry = TimeSplitEntryFormModel.validate(_entry);
			if (validatedEntry._error) {
				_acc.push(validatedEntry);
			}
			return _acc;
		}, [] as FormErrors<TimeSplitEntryFormModel>[]);

		return errors as FormErrors<TimeSheetEditFormModel>;
	};

	private static _getTotalWorkTypeReducer = (workType: TimeSheetEntryWorkType) => {
		return (_total: number, _entry: TimeSheetEntryFormModel) => {
			if (_entry.workType !== workType || !_entry.startTime || !_entry.endTime) {
				return _total;
			}
			return _total + TimeUtils.compare(_entry.endTime, _entry.startTime, 'minute', TimeFormat.ISO_DATETIME);
		};
	};

	static totalWorkTypeTime = (timeSheetEntries: TimeSheetEntryFormModel[], workType: TimeSheetEntryWorkType) => {
		return timeSheetEntries.reduce(TimeSheetEditFormModel._getTotalWorkTypeReducer(workType), 0);
	};

	private static _totalTimeSheetTimeReducer = (_total: number, _entry: TimeSheetEntryFormModel) => {
		if (!_entry.startTime || !_entry.endTime || _entry.belongsToOtherSheet) {
			return _total;
		}
		return _total + TimeUtils.compare(_entry.endTime, _entry.startTime, 'minute', TimeFormat.ISO_DATETIME);
	};

	static totalTimeSheetTime = (timeSheetEntries: TimeSheetEntryFormModel[]) => {
		return timeSheetEntries.reduce(TimeSheetEditFormModel._totalTimeSheetTimeReducer, 0);
	};

	static checkAllEndTimes = (form: TimeSheetEditFormModel) => {
		return form.timeSheetEntries.every((_entryWithType) => !!_entryWithType.entry.endTime);
	};

	static hasUnsavedChanges = (form: TimeSheetEditFormModel) => {
		if (!form) {
			return false;
		}
		return form.timeSheetEntriesHaveChanged || form.timeSplitEntriesHaveChanged || form.timeAllocationEntriesHaveChanged;
	};

	static mapTimelineEntitiesToTimeSheetEntriesList = (
		timelineEntities: TimelineEntity<TimeSheetEntryFormModel, {
			startTime: string;
			endTime: string;
		}>[]
	): (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType)[] => {
		const timeSheetEntries: (TimeSheetEntryWithType | TimeSheetGapEntryWithType | TimeSheetOccupiedEntryWithType)[] = [];
		timelineEntities.forEach((_e) => {
			if (_e.type === TimelineEntityType.ENTRY) {
				timeSheetEntries.push({ entry: _e.entry, type: TimeSheetEntryFormType.ENTRY });
			} else if (_e.type === TimelineEntityType.OCCUPIED) {
				timeSheetEntries.push({ entry: TimeSheetOccupiedEntryFormModel.parseFromVM(_e.entry, null), type: TimeSheetEntryFormType.OCCUPIED });
			} else if (_e.type === TimelineEntityType.GAP) {
				timeSheetEntries.push({ entry: TimeSheetGapEntryFormModel.parseFromVM(_e.entry, null), type: TimeSheetEntryFormType.GAP });
			}
		});
		return timeSheetEntries;
	};
}

export class TimeSheetBulkEditFormModel {
	timeSheets: TimeSheetEditFormModel[];

	static fieldName = (field: keyof TimeSheetBulkEditFormModel) => field;

	static timeSheetEditFormFieldName = (index: number, field: keyof TimeSheetEditFormModel) =>
		`${TimeSheetBulkEditFormModel.fieldName('timeSheets')}[${index}].${field}`;

	static timeSheetEditTimeEntryFieldName = (index: string, field: string) =>
		`${TimeSheetBulkEditFormModel.fieldName('timeSheets')}[${index}].${field}`;

	static toRequestModel = (
		form: Nullable<TimeSheetBulkEditFormModel>,
		timeSheets: Nullable<TimeSheetVM[]>,
		timeZoneInUse: Nullable<string>
	): TimeSheetsBulkUpdateRM => {
		const rm: TimeSheetsBulkUpdateRM = {};

		if (!form || !timeSheets) {
			// Form never got initialized, nothing to update
			return rm;
		}

		form.timeSheets.forEach((ts) => {
			const requestModel = TimeSheetEditFormModel.toRequestModel(ts, timeZoneInUse);
			rm[ts.accountId] = requestModel;
		});

		return rm;
	};

	static validate = (form: TimeSheetBulkEditFormModel): FormErrors<TimeSheetBulkEditFormModel> => {
		const errors: CustomFormErrors<TimeSheetBulkEditFormModel> = {};

		if (form?.timeSheets) {
			errors.timeSheets = [] as FormErrors<TimeSheetEditFormModel>[];
			const timeSheetsErrors: FormErrors<TimeSheetEditFormModel>[] = [];
			form.timeSheets?.forEach((ts) => {
				timeSheetsErrors.push(TimeSheetEditFormModel.validate(ts));
			});
			errors.timeSheets = timeSheetsErrors;
		}
		return errors as FormErrors<Record<number, TimeSheetEditFormModel>>;
	};

	static hasUnsavedChanges = (form: TimeSheetBulkEditFormModel) => {
		if (!form?.timeSheets) {
			return false;
		}
		return form.timeSheets.some((ts) =>
			ts.timeSheetEntriesHaveChanged || ts.timeSplitEntriesHaveChanged || ts.timeAllocationEntriesHaveChanged
		);

	};

	static hasNoAddedTimeSheetEntriesInProgress = (form: TimeSheetBulkEditFormModel) => {
		if (!form?.timeSheets) {
			return false;
		}

		let hasNoAdded = true;
		form.timeSheets.forEach((ts) => {
			if (ts.editingEntryIndex !== null) {
				hasNoAdded = false;
				return;
			}
		});
		return hasNoAdded;
	};

}

export default TimeSheetEditFormModel;
