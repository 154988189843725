import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import { compose } from 'redux';

import CustomModal from 'af-components/CustomModal';
import DateInput from 'af-components/Controls/DateInput';
import PeriodPicker from 'af-components/Controls/PeriodPicker';
import SubmitButton from 'af-components/SubmitButton';
import Radio from 'af-components/Controls/Radio';

import { useToggle } from 'af-utils/react.util';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

interface OwnProps {
	isOpen: boolean;
	onSubmit: (startDate: Date, endDate: Date) => void;
	closeModal: () => void;
	companyName: string;
	companyCreationDate: Nullable<Date>;
	lastLogDate: Nullable<Date>;
}

type Props = OwnProps;

const MultipleLogsDatePickerModule: React.FC<Props> = (props) => {
	const { isOpen, onSubmit, closeModal, companyName, companyCreationDate, lastLogDate } = props;

	const [
		interval,
		setInterval,
	] = React.useState<{ startDate: Date; endDate: Date; }>(
		{
			startDate: lastLogDate ?? new Date(),
			endDate: lastLogDate ?? new Date(),
		});

	const { value: isCustomDateRange, setToTrue: setToCustomDateRange, setToFalse: setToDayRange } = useToggle(true);

	const handlePeriodChange = React.useCallback((startDate: Date, endDate: Date) => setInterval({ startDate, endDate }), []);
	const handleDateChange = React.useCallback((newDate: Date) => setInterval({ startDate: newDate, endDate: newDate }), []);

	const handleSubmit = React.useCallback(() => {
		if (!isCustomDateRange) {
			onSubmit(interval.endDate, interval.endDate);
		} else {
			onSubmit(interval.startDate, interval.endDate);
		}
	}, [interval.endDate, interval.startDate, isCustomDateRange, onSubmit]);

	React.useEffect(() => {
		const date = lastLogDate ? TimeUtils.normalizeUTCDate(lastLogDate) : new Date();

		setInterval({
			startDate: date,
			endDate: date,
		});
	}, [lastLogDate]);

	const dateInputLastLogDate = React.useMemo(() => lastLogDate ? TimeUtils.normalizeUTCDate(lastLogDate) : undefined, [lastLogDate]);
	const dateInputCompanyCreationDate = React.useMemo(() =>
		companyCreationDate ? TimeUtils.normalizeUTCDate(companyCreationDate) : undefined
		, [companyCreationDate]);

	const calendarSettings = React.useMemo(() => ({
		maxDate: dateInputLastLogDate,
		minDate: dateInputCompanyCreationDate,
	}), [dateInputCompanyCreationDate, dateInputLastLogDate]);

	return (
		<CustomModal
			className="field-report-orders-table__send-multiple-reports-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={isOpen}
			size="md"
		>
			<CustomModal.Header closeModal={closeModal} title={`Download logs for ${companyName}`} />
			<CustomModal.Body>
				<div className="field-report-orders-table__send-multiple-reports-modal__description-container">
					Select time range for which you want to download logs. Logs will be exported as a CSV file.
				</div>
				<div className="field-report-orders-table__send-multiple-reports-modal__date-picker">
					<Radio
						checked={!isCustomDateRange}
						className="field-report-orders-table__send-multiple-reports-modal__picker-radio"
						inline={true}
						label="Day"
						onCheck={setToDayRange}
					/>
					{!isCustomDateRange &&
						<DateInput
							calendarSettings={calendarSettings}
							fixed={true}
							onValueChange={handleDateChange}
							selectedValue={interval.endDate}
						/>
					}
				</div>
				<div>
					<Radio
						checked={isCustomDateRange}
						className="field-report-orders-table__send-multiple-reports-modal__picker-radio"
						inline={true}
						label="Custom Date Range"
						onCheck={setToCustomDateRange}
					/>
					{isCustomDateRange &&
						<PeriodPicker
							end={interval.endDate}
							isPopperFixed
							isPopperInline
							maxDate={dateInputLastLogDate}
							minDate={dateInputCompanyCreationDate}
							onChange={handlePeriodChange}
							start={interval.startDate}
						/>
					}
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<SubmitButton label="Export" onClick={handleSubmit} />
			</CustomModal.Footer>
		</CustomModal>
	);
};

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo
);

export default enhance(MultipleLogsDatePickerModule);
