import FileType from '@acceligentllc/shared/enums/fileType';

enum MimeType {
	CSV = 'text/csv',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	JPEG = 'image/jpeg',
	JPG = 'image/jpeg',
	PDF = 'application/pdf',
	PNG = 'image/png',
	SVG = 'image/svg+xml',
	XLS = 'application/vnd.ms-excel',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	ZIP = 'application/zip',
}

export function fromFileType(fileType: FileType): Nullable<MimeType> {
	switch (fileType) {
		case FileType.PNG: return MimeType.PNG;
		case FileType.JPG: return MimeType.JPG;
		case FileType.JPEG: return MimeType.JPEG;
		case FileType.SVG: return MimeType.SVG;
		case FileType.PDF: return MimeType.PDF;
		case FileType.ZIP: return MimeType.ZIP;
		case FileType.XLSX: return MimeType.XLSX;
		case FileType.XLS: return MimeType.XLS;
		case FileType.DOCX: return MimeType.DOCX;
		case FileType.DOC: return MimeType.DOC;
		case FileType.CSV: return MimeType.CSV;
		default: return null;
	}
}

export default MimeType;
