import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as UserUtils from '@acceligentllc/shared/utils/user';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import type WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import type WorkOrderBase from 'ab-domain/models/workOrder/base';
import type EmployeeBase from 'ab-domain/models/employee/base';
import type WorkOrderEmployeeBase from 'ab-domain/models/workOrderEmployee/base';
import type WorkOrderEquipmentBase from 'ab-domain/models/workOrderEquipment/base';
import type WorkOrderTemporaryEmployeeBase from 'ab-domain/models/workOrderTemporaryEmployee/base';

import * as CodeUtils from 'ab-utils/codes.util';

import { TableContent } from 'ab-common/dataStructures/tableContent';

export class W_Job_FindRelatedWorkOrdersTable_VM extends TableContent<W_Job_FindRelatedWorkOrdersTable_VM_Row>{
	constructor(workOrders: WorkOrderBase[], pages: number, totalCount: number) {
		super(
			W_Job_FindRelatedWorkOrdersTable_VM_Row.bulkConstructor(workOrders),
			pages,
			totalCount
		);
	}
}

class W_Job_FindRelatedWorkOrdersTable_VM_Row {
	id: number;
	code: string;
	/** MM-DD-YYYY */
	dueDate: string;
	crewNumber: number;

	revision: string;

	projectManager: Nullable<W_Job_FindRelatedWorkOrdersTable_VM_Superintendent>;
	supervisor: W_Job_FindRelatedWorkOrdersTable_VM_Superintendent;

	workOrderEmployees: W_Job_FindRelatedWorkOrdersTable_VM_WOEmployee[];
	workOrderEquipment: W_Job_FindRelatedWorkOrdersTable_VM_WOEquipment[];
	workOrderTemporaryEmployees: W_Job_FindRelatedWorkOrdersTable_VM_WOTempEmployee[];

	revenue: Nullable<string>;
	manHourAverage: Nullable<string>;

	notes: Nullable<string>;
	jobTitle: string;
	status: WorkOrderStatus;
	crewType: Nullable<string>;
	woLink: Nullable<string>;
	crewColor: Nullable<string>;

	constructor(workOrder: WorkOrderBase) {
		this.id = workOrder.id;
		this.code = CodeUtils.workOrderCode(workOrder, workOrder.workRequest);
		this.crewNumber = workOrder.code;

		this.dueDate = TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		this.revision = CodeUtils.revisionCode(workOrder.revision);

		this.projectManager = workOrder.projectManager && new W_Job_FindRelatedWorkOrdersTable_VM_Superintendent(workOrder.projectManager);

		if (!workOrder.supervisor) {
			throw new Error('WO supervisor not defined');
		}
		this.supervisor = workOrder.supervisor && new W_Job_FindRelatedWorkOrdersTable_VM_Superintendent(workOrder.supervisor);

		this.workOrderEmployees = workOrder.workOrderEmployees && W_Job_FindRelatedWorkOrdersTable_VM_WOEmployee.bulkConstructor(workOrder.workOrderEmployees);
		this.workOrderEquipment = workOrder.workOrderEquipment && W_Job_FindRelatedWorkOrdersTable_VM_WOEquipment.bulkConstructor(workOrder.workOrderEquipment);
		this.workOrderTemporaryEmployees = workOrder.workOrderTemporaryEmployees
			&& W_Job_FindRelatedWorkOrdersTable_VM_WOTempEmployee.bulkConstructor(workOrder.workOrderTemporaryEmployees);

		this.revenue = workOrder.revenue;
		this.manHourAverage = workOrder.manHourAverage;

		this.notes = workOrder.notes;

		this.jobTitle = workOrder.workRequest.title;
		this.status = workOrder.status;
		this.crewType = workOrder.crewType?.name ?? null;
		this.crewColor = workOrder.crewType?.color ?? null;
	}

	static bulkConstructor(workOrders: WorkOrderBase[] = []): W_Job_FindRelatedWorkOrdersTable_VM_Row[] {
		return workOrders.map(W_Job_FindRelatedWorkOrdersTable_VM_Row._constructorMap);
	}

	private static _constructorMap = (_workOrder: WorkOrderBase) => new W_Job_FindRelatedWorkOrdersTable_VM_Row(_workOrder);
}

class W_Job_FindRelatedWorkOrdersTable_VM_WOTempEmployee {
	fullName: string;
	uniqueId: string;

	constructor(workOrderTemporaryEmployee: WorkOrderTemporaryEmployeeBase) {
		const user = workOrderTemporaryEmployee.temporaryEmployee.account.user;

		this.fullName = UserUtils.getUserName(user);
		this.uniqueId = user.uniqueId;
	}

	static bulkConstructor = (temporaryEmployees: WorkOrderTemporaryEmployeeBase[]) =>
		temporaryEmployees.map(W_Job_FindRelatedWorkOrdersTable_VM_WOTempEmployee._constructorMap);
	private static _constructorMap = (temporaryEmployees: WorkOrderTemporaryEmployeeBase) =>
		new W_Job_FindRelatedWorkOrdersTable_VM_WOTempEmployee(temporaryEmployees);
}

class W_Job_FindRelatedWorkOrdersTable_VM_WOEquipment {
	code: string;
	specification: Nullable<string>;

	constructor(workOrderEquipment: WorkOrderEquipmentBase) {
		this.code = workOrderEquipment.equipment.code;
		this.specification = workOrderEquipment.equipment.specification;
	}

	static bulkConstructor = (employees: WorkOrderEquipmentBase[]) => employees.map(W_Job_FindRelatedWorkOrdersTable_VM_WOEquipment._constructorMap);
	private static _constructorMap = (employee: WorkOrderEquipmentBase) => new W_Job_FindRelatedWorkOrdersTable_VM_WOEquipment(employee);
}

class W_Job_FindRelatedWorkOrdersTable_VM_WOEmployee {
	fullName: string;
	uniqueId: string;

	constructor(workOrderEmployee: WorkOrderEmployeeBase) {
		const user = workOrderEmployee.employee.account.user;

		this.fullName = UserUtils.getUserName(user);
		this.uniqueId = user.uniqueId;
	}

	static bulkConstructor = (employees: WorkOrderEmployeeBase[]) => employees.map(W_Job_FindRelatedWorkOrdersTable_VM_WOEmployee._constructorMap);
	private static _constructorMap = (employee: WorkOrderEmployeeBase) => new W_Job_FindRelatedWorkOrdersTable_VM_WOEmployee(employee);
}

class W_Job_FindRelatedWorkOrdersTable_VM_Superintendent {
	fullName: string;

	constructor(employee: EmployeeBase) {
		this.fullName = UserUtils.getUserName(employee.account.user);
	}
}

