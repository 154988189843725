import * as React from 'react';
import type { FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import { Field } from 'redux-form';
import type { CellContext } from '@tanstack/react-table';
import { nanoid } from 'nanoid';

import PurchaseOrderItemCompletion from '@acceligentllc/shared/enums/purchaseOrderItemCompletion';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import { VendorPackageTypeLabel } from '@acceligentllc/shared/enums/vendorPackageType';

import { DEFAULT_EQUIPMENT_IMAGE, UNIQUE_ID_SIZE } from 'ab-common/constants/value';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import type { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTableField from 'af-fields/SimpleTable';
import SimpleTableControl from 'af-components/Controls/SimpleTable';
import ImageTag from 'af-components/Image';
import DropdownComponent from 'af-components/Controls/Dropdown';
import type { FooterButton, SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import TextHighlight from 'af-components/TextHighlight';

import type ItemOptionVM from 'ab-viewModels/item/itemOption.viewModel';

import { dollarFormatter } from 'af-utils/format.util';
import * as ReduxUtils from 'ab-utils/reduxForms.util';

import type { PurchaseOrderFormModel, PurchaseOrderItemFormModel } from './formModel';
import styles from './styles.module.scss';
import { statusDropdownOptions } from './helpers';

export interface OwnProps {
	availableItems: ItemOptionVM[];
	vendorId: Nullable<number>;
	isReadOnly: boolean;
	setUnsavedChanges: () => void;
	errors: FormErrorsWithArray<PurchaseOrderFormModel, string>;
	initialized: boolean;
	change: (field: string, value: string | number) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<PurchaseOrderItemFormModel>;

const renderItemOption = (option: ItemOptionVM, searchText: string) => (
	<>
		<ImageTag
			fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
			minSize={BlobStorageImageSizeContainer.SIZE_50X50}
			src={option.imageUrl}
			tryOriginal={true}
			tryRoot={true}
		/>
		<div className={styles['form__menu-option']} key={option.id}>
			<div className={styles['form__menu-option__text']}>
				<span className={styles['form__menu-option__text__name']}>
					<TextHighlight searchText={searchText} text={option.name} />
					{option.packageType && <TextHighlight searchText={searchText} text={`(${VendorPackageTypeLabel[option.packageType]})`} />}
					<TextHighlight searchText={searchText} text={option.modelNumber} />
				</span>
			</div>
			<div>
				<small className={styles['form__menu-option__sub-text']}>
					{option.vendorName && <TextHighlight searchText={searchText} text={option.vendorName} />}
					&nbsp;&#124;&nbsp;
					{option.vendorPartNumber && <TextHighlight searchText={searchText} text={option.vendorPartNumber} />}
				</small>
			</div>
		</div >
	</>
);

const PurchaseOrderItems: React.FC<Props> = (props) => {
	const { fields, availableItems, vendorId, isReadOnly, setUnsavedChanges, errors, initialized, change } = props;

	const [showSelectItemDropdown, setShowSelectItemDropdown] = React.useState(false);

	const addItem = React.useCallback(() => {
		fields.push({
			id: nanoid(UNIQUE_ID_SIZE),
			itemId: null,
			itemName: '',
			itemNumber: '',
			itemsInvoiced: PurchaseOrderItemCompletion.NONE,
			itemsReceived: PurchaseOrderItemCompletion.NONE,
			priceEach: 0,
			quantity: 1,
			extendedPrice: 0,
			receivedQuantity: 0,
			invoicedQuantity: 0,
			comment: null,
		});
		setUnsavedChanges();
	}, [fields, setUnsavedChanges]);

	const selectItem = React.useCallback((item: ItemOptionVM) => {
		fields.push({
			itemId: item.id,
			itemName: item.name,
			itemNumber: item.modelNumber,
			itemsInvoiced: PurchaseOrderItemCompletion.NONE,
			itemsReceived: PurchaseOrderItemCompletion.NONE,
			priceEach: item.vendorId === vendorId ? item.price ?? 0 : 0,
			quantity: 1,
			extendedPrice: item.vendorId === vendorId ? item.price ?? 0 : 0,
			receivedQuantity: 0,
			invoicedQuantity: 0,
			comment: null,
			id: `${item.id}`, // this should be falsy when sending to BE, but if we put falsy here it breaks certain table functionalities, we make it falsy later
		});
		setUnsavedChanges();
		setShowSelectItemDropdown(false);
	}, [fields, setUnsavedChanges, vendorId]);

	const onCloseSelectItemDropdownClick = React.useCallback(() => setShowSelectItemDropdown(false), []);

	const renderSelectItemDropdown = React.useCallback(() => {
		return (
			<div className={styles['select-intentory-system']}>
				<div className={styles['select-intentory-system__dropdown']}>
					<DropdownComponent<ItemOptionVM>
						filterable={true}
						filterBy={['name', 'partNumber', 'modelNumber', 'packageType', 'vendorName', 'vendorPartNumber']}
						fixed={true}
						id="item-dropdown"
						onValueChange={selectItem}
						options={availableItems}
						placeholder="Select From Inventory System"
						renderMenuItem={renderItemOption}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<span className="icon-close" onClick={onCloseSelectItemDropdownClick} />
			</div>
		);
	}, [selectItem, availableItems, onCloseSelectItemDropdownClick]);

	const getReceivedStatus = React.useCallback((fieldName: string) => {
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.itemsReceived ?? 0;
	}, [fields]);

	const getInvoicedStatus = React.useCallback((fieldName: string) => {
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.itemsInvoiced ?? 0;
	}, [fields]);

	const recalculateExtendedPrice = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
		const fieldPath = event.target.name.split('.');
		const fieldName = fieldPath[1]; // either 'priceEach' or 'quantity'
		const fieldIndex = +(fieldPath[0].match(/\[(\d+)\]/)?.[1] as string);
		const field = fields.get(fieldIndex);

		if (fieldName === 'priceEach') {
			change(`${fieldPath[0]}.extendedPrice`, newValue * field.quantity);
		} else {
			change(`${fieldPath[0]}.extendedPrice`, newValue * field.priceEach);
			// if we are changing quantity, we should also change received and invoiced quantity if ALL are received/invoiced
			if (getReceivedStatus(`${fieldPath[0]}`) === PurchaseOrderItemCompletion.ALL) {
				change(`${fieldPath[0]}.receivedQuantity`, newValue);
			}
			if (getInvoicedStatus(`${fieldPath[0]}`) === PurchaseOrderItemCompletion.ALL) {
				change(`${fieldPath[0]}.invoicedQuantity`, newValue);
			}
		}

		setUnsavedChanges();
	}, [change, fields, getInvoicedStatus, getReceivedStatus, setUnsavedChanges]);

	const getExtendedPrice = React.useCallback((fieldName: string) => {
		// Extract index from fields name with regex -- example: items[12].extendedPrice --> 12
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.extendedPrice ?? 0;
	}, [fields]);

	const getInvoicedQuantity = React.useCallback((fieldName: string) => {
		// Extract index from fields name with regex
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.invoicedQuantity ?? 0;
	}, [fields]);

	const getReceivedQuantity = React.useCallback((fieldName: string) => {
		// Extract index from fields name with regex
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.receivedQuantity ?? 0;
	}, [fields]);

	const getQuantity = React.useCallback((fieldName: string) => {
		// Extract index from fields name with regex
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.quantity ?? 0;
	}, [fields]);

	const footerButtonsLeft = React.useMemo(() => {
		const resolvedFooterButtons = [{
			iconName: 'plus',
			label: 'Add Item Manually',
			onClick: addItem,
		}];
		if (!showSelectItemDropdown) {
			resolvedFooterButtons.push({ iconName: 'plus', label: 'Add Item From Inventory System', onClick: () => setShowSelectItemDropdown(true) });
		}
		return resolvedFooterButtons as FooterButton[];
	}, [addItem, showSelectItemDropdown]);

	const markAllAsReceived = React.useCallback(() => {
		fields.forEach((field) => {
			// Extract index from fields name with regex
			const fieldIndex = +(field.match(/\[(\d+)\]/)?.[1] as string);
			const quantity = fields.get(fieldIndex).quantity;
			change(`${field}.itemsReceived`, PurchaseOrderItemCompletion.ALL);
			change(`${field}.receivedQuantity`, quantity);
		});
		setUnsavedChanges();
	}, [change, fields, setUnsavedChanges]);

	const markAllAsInvoiced = React.useCallback(() => {
		fields.forEach((field) => {
			// Extract index from fields name with regex
			const fieldIndex = +(field.match(/\[(\d+)\]/)?.[1] as string);
			const quantity = fields.get(fieldIndex).quantity;
			change(`${field}.itemsInvoiced`, PurchaseOrderItemCompletion.ALL);
			change(`${field}.invoicedQuantity`, quantity);
		});
		setUnsavedChanges();
	}, [change, fields, setUnsavedChanges]);

	const footerButtonsRight = React.useMemo(() => {
		return [
			{
				label: 'Mark All as Received',
				onClick: markAllAsReceived,
			}, {
				label: 'Mark All as Invoiced',
				onClick: markAllAsInvoiced,
			},
		] as FooterButton[];
	}, [markAllAsInvoiced, markAllAsReceived]);

	const onTotalInvoicedChange = React.useCallback((status: PurchaseOrderItemCompletion, fieldName: string) => {
		const quantity = getQuantity(fieldName);
		if (status === PurchaseOrderItemCompletion.ALL) {
			change(`${fieldName}.invoicedQuantity`, quantity);
		} else if (status === PurchaseOrderItemCompletion.NONE) {
			change(`${fieldName}.invoicedQuantity`, 0);
		}
	}, [change, getQuantity]);

	const onTotalInvoicedHandler = React.useCallback((fieldName: string) => {
		return (status: typeof statusDropdownOptions[0]) => onTotalInvoicedChange(status.label, fieldName);
	}, [onTotalInvoicedChange]);

	const onTotalReceivedChange = React.useCallback((status: PurchaseOrderItemCompletion, fieldName: string) => {
		const quantity = getQuantity(fieldName);
		if (status === PurchaseOrderItemCompletion.ALL) {
			change(`${fieldName}.receivedQuantity`, quantity);
		} else if (status === PurchaseOrderItemCompletion.NONE) {
			change(`${fieldName}.receivedQuantity`, 0);
		}
	}, [change, getQuantity]);

	const onTotalReceivedHandler = React.useCallback((fieldName: string) => {
		return (status: typeof statusDropdownOptions[0]) => onTotalReceivedChange(status.label, fieldName);
	}, [onTotalReceivedChange]);

	const editModeColumns: SimpleTableProps<PurchaseOrderItemFormModel & SimpleTableRow>['columns'] = React.useMemo(() => [
		{
			id: 'quantity',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							min={0}
							name={`${_cell.row.original.name}.quantity`}
							normalize={ReduxUtils.normalizeDecimalNumber}
							onChange={recalculateExtendedPrice}
							type="number"
						/>
					);
				}
				const value = +_cell.getValue() ?? 0;
				return parseFloat(value.toFixed(2)).toString();
			},
			header: 'Requested Quantity *',
			accessor: 'quantity',
			size: 80,
			enableSorting: false,
		},
		{
			id: 'itemNumber',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, string>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							name={`${_cell.row.original.name}.itemNumber`}
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
			header: 'Item No.',
			accessor: 'itemNumber',
			enableSorting: true,
		},
		{
			id: 'itemName',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, string>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							name={`${_cell.row.original.name}.itemName`}
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
			header: 'Item Name *',
			size: 250,
			accessor: 'itemName',
			enableSorting: true,
		},
		{
			id: 'receivedQuantity',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				const receivedStatus = getReceivedStatus(_cell.row.original.name);
				if (_cell.row.original.isInEditMode && receivedStatus === PurchaseOrderItemCompletion.PARTIALLY) {
					return (
						<Field
							component={Input}
							min={0}
							name={`${_cell.row.original.name}.receivedQuantity`}
							normalize={ReduxUtils.normalizeDecimalNumber}
							type="number"
						/>
					);
				}
				const value = +getReceivedQuantity(_cell.row.original.name);
				return value ? parseFloat(value.toFixed(2)).toString() : '0';
			},
			header: 'Received Quantity',
			accessor: 'receivedQuantity',
			enableSorting: false,
		},
		{
			id: 'priceEach',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							min={0}
							name={`${_cell.row.original.name}.priceEach`}
							onChange={recalculateExtendedPrice}
							step={0.01}
							type="number"
						/>
					);
				}
				return dollarFormatter.format(_cell.getValue() ?? 0);
			},
			header: 'Price Each *',
			accessor: 'priceEach',
			enableSorting: false,
		},
		{
			id: 'extendedPrice',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				return <div className={styles.right}>{dollarFormatter.format(getExtendedPrice(_cell.row.original.name))}</div>;
			},
			header: 'Extended Price',
			accessor: 'extendedPrice',
		},
		{
			id: 'itemReceived',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							className={styles['dropdown-white-background']}
							component={Dropdown}
							containerId={`itemReceived_${_cell.row.id}`}
							disabled={isReadOnly}
							fixed={true}
							labelKey="label"
							name={`${_cell.row.original.name}.itemsReceived`}
							onChange={setUnsavedChanges}
							onValueChange={onTotalReceivedHandler(_cell.row.original.name)}
							options={statusDropdownOptions}
							valueKey="label"
							withCaret={true}
						/>
					);
				}
				return getReceivedStatus(_cell.row.original.name) ?? null;
			},
			header: 'Item Received',
			accessor: 'itemsReceived',
			enableSorting: true,
		},
		{
			id: 'itemInvoiced',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							className={styles['dropdown-white-background']}
							component={Dropdown}
							containerId={`itemInvoiced_${_cell.row.id}`}
							disabled={isReadOnly}
							fixed={true}
							labelKey="label"
							name={`${_cell.row.original.name}.itemsInvoiced`}
							onChange={setUnsavedChanges}
							onValueChange={onTotalInvoicedHandler(_cell.row.original.name)}
							options={statusDropdownOptions}
							valueKey="label"
							withCaret={true}
						/>
					);
				}
				return getInvoicedStatus(_cell.row.original.name) ?? null;
			},
			header: 'Item Invoiced',
			accessor: 'itemsInvoiced',
			enableSorting: true,
		},
		{
			id: 'invoicedQuantity',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				const invoicedStatus = getInvoicedStatus(_cell.row.original.name);
				if (_cell.row.original.isInEditMode && invoicedStatus === PurchaseOrderItemCompletion.PARTIALLY) {
					return (
						<Field
							component={Input}
							min={0}
							name={`${_cell.row.original.name}.invoicedQuantity`}
							normalize={ReduxUtils.normalizeDecimalNumber}
							type="number"
						/>
					);
				}
				const value = +getInvoicedQuantity(_cell.row.original.name);
				return value ? parseFloat(value.toFixed(2)).toString() : '0';
			},
			header: 'Total Invoiced',
			accessor: 'invoicedQuantity',
			enableSorting: false,
		},
		{
			id: 'comment',
			cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
				if (_cell.row.original.isInEditMode) {
					return (
						<Field
							component={Input}
							maxCharacters={300}
							name={`${_cell.row.original.name}.comment`}
							showCharNum={true}
							type="text"
						/>
					);
				}
				return _cell.getValue() ?? 'N/A';
			},
			header: 'Comments',
			accessor: 'comment',
			enableSorting: false,
			size: 250,
			// We don't want to add recalculating actions because it will cause problems with field inputs
			// User will have to mouse-click the field again for every character input
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}], [isReadOnly, setUnsavedChanges]);

	const readonlyModeColumns: SimpleTableProps<PurchaseOrderItemFormModel>['columns'] = React.useMemo(() => [{
		id: 'itemNumber',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, string>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item No.',
		accessor: 'itemNumber',
		enableSorting: true,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, string>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item Name *',
		size: 300,
		accessor: 'itemName',
		enableSorting: true,
	},
	{
		id: 'quantity',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			const value = _cell.getValue();
			return value ? parseFloat(value.toFixed(2)).toString() : '0';
		},
		header: 'Requested Quantity *',
		accessor: 'quantity',
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return dollarFormatter.format(_cell.row.original.priceEach * _cell.row.original.quantity);
		},
		header: 'Extended Price',
		accessor: 'extendedPrice',
	},
	{
		id: 'priceEach',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Price Each *',
		accessor: 'priceEach',
		enableSorting: false,
	},
	{
		id: 'itemReceived',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? null;
		},
		header: 'Item Received',
		accessor: 'itemsReceived',
		enableSorting: true,
	},
	{
		id: 'receivedQuantity',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			const value = _cell.getValue();
			return value ? parseFloat(value.toFixed(2)).toString() : '0';
		},
		header: 'Received Quantity',
		accessor: 'receivedQuantity',
		enableSorting: false,
	},
	{
		id: 'itemInvoiced',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? null;
		},
		header: 'Item Invoiced',
		accessor: 'itemsInvoiced',
		enableSorting: true,
	},
	{
		id: 'invoicedQuantity',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			const value = _cell.getValue();
			return value ? parseFloat(value.toFixed(2)).toString() : '0';
		},
		header: 'Total Invoiced',
		accessor: 'invoicedQuantity',
		enableSorting: false,
	},
	{
		id: 'comment',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Comments',
		accessor: 'comment',
		enableSorting: false,
	}], []);

	if (isReadOnly) {
		return <SimpleTableControl columns={readonlyModeColumns} label="Items" rows={fields.getAll()} />;
	}

	return (
		<SimpleTableField
			allowEdit={true}
			columns={editModeColumns}
			emptyTableMessage='No items added. Press "+ Add" to add an item.'
			errors={errors.purchaseOrderItems}
			fields={fields}
			footerButtonsLeft={footerButtonsLeft}
			footerButtonsRight={footerButtonsRight}
			footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : undefined}
			initialized={initialized}
		/>
	);
};

export default React.memo(PurchaseOrderItems);
