import ReportBlockType from '@acceligentllc/shared/enums/reportBlockType';

export enum DroppableZones {
	BLOCK_LIST_ID = 'block-list-id',
	CUSTOM_BLOCK_LIST_ID = 'custom-block-list-id',
	MAIN_BLOCK_LIST_ID = 'main-block-list-id',
	PRIMARY_LIST_ID = 'primary-list-id',
	PRIMARY_MAIN_BLOCK_ID = 'primary-main-block-id',
	SECONDARY_LIST_ID = 'secondary-list-id',
	SECONDARY_MAIN_BLOCK_ID = 'secondary-main-block-id',
	UPPER_TOTAL_BLOCK_LIST_ID = 'upper_total_block_list_id',
	LOWER_TOTAL_BLOCK_LIST_ID = 'lower_total_block_list_id',
	CALCULATED_BLOCK_FIELD_LIST_ID = 'calculated-block-field-list'
}

export const MainBlockZones = {
	[DroppableZones.PRIMARY_MAIN_BLOCK_ID]: true,
	[DroppableZones.SECONDARY_MAIN_BLOCK_ID]: true,
} as const;

export namespace CustomBlocks {

	export enum CustomBlock {
		CALCULATED = 'CALCULATED'
	}

	export const CustomBlockLabel: { [T in keyof typeof CustomBlock]: string; } = {
		[CustomBlock.CALCULATED]: 'Calculated',
	};

	export const CustomBlockDroppableId: { [T in keyof typeof CustomBlock]: string; } = {
		[CustomBlock.CALCULATED]: 'calculated-block-id',
	} as const;

	export const DroppableIdCustomBlock = {
		['calculated-block-id']: CustomBlock.CALCULATED,
	} as const;

	export const CustomBlockReportBlockType: { [T in keyof typeof CustomBlock]: ReportBlockType; } = {
		[CustomBlock.CALCULATED]: ReportBlockType.CALCULATED,
	} as const;
}
