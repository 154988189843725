import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import { TimeSheetEntryWorkTypeLabel, TimeSheetWorkTypeDisplayInfo } from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimeSheetEntryType from '@acceligentllc/shared/enums/timeSheetEntryType';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import type { TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

interface OwnProps {
	entry: TimeSheetEntryInfoVM;
	isSigned: boolean;
	timezone: Nullable<string>;
}

type Props = OwnProps;

const TimeSheetEntry: React.FC<Props> = (props) => {
	const { entry: { startTime, endTime, workType, type }, isSigned, timezone } = props;

	const duration = (startTime && endTime)
		? TimeUtils.minutesToHoursAndMinutes(TimeUtils.compare(endTime, startTime, 'minutes'))
		: '-';

	const { icon: workTypeIcon } = TimeSheetWorkTypeDisplayInfo[workType];

	const isManual = type === TimeSheetEntryType.MANUAL || !isSigned;

	const iconClassName = bemElement('modal-time-sheet-employee__header', 'entry-icon', {
		manual: isManual,
	});
	const dateClassName = bemElement('modal-time-sheet-employee__header', 'entry-dates', {
		manual: isManual,
	});

	const { timezoneStartTime, timezoneEndTime } = React.useMemo(() => {

		const browserOffset = (new Date()).getTimezoneOffset();
		if (!timezone) {
			return {
				timezoneStartTime: TimeUtils.offsetTime(startTime, -browserOffset, 'minutes', TimeFormat.ISO_DATETIME),
				timezoneEndTime: endTime ? TimeUtils.offsetTime(endTime, -browserOffset, 'minutes', TimeFormat.ISO_DATETIME) : endTime,
			};
		}

		const timeZoneOffset = TimeUtils.getOffset(timezone);

		return {
			timezoneStartTime: TimeUtils.offsetTime(startTime, timeZoneOffset, 'minutes', TimeFormat.ISO_DATETIME),
			timezoneEndTime: endTime ? TimeUtils.offsetTime(endTime, timeZoneOffset, 'minutes', TimeFormat.ISO_DATETIME) : endTime,
		};
	}, [startTime, endTime, timezone]);

	return (
		<div className="modal-time-sheet-employee__header__entry">
			<div className="modal-time-sheet-employee__header__entry-content">
				<span className={`${iconClassName} icon-${workTypeIcon}`}></span>
				<div>{TimeSheetEntryWorkTypeLabel[workType]}</div>
			</div>
			<div className="modal-time-sheet-employee__header__entry-content">
				<div className={dateClassName}>
					<div>{TimeUtils.formatDate(timezoneStartTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)}</div>
					{!!endTime && (
						<>
							<div>-</div>
							<div>{TimeUtils.formatDate(timezoneEndTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME)}</div>
						</>
					)}
				</div>
				<div className="modal-time-sheet-employee__header__entry-duration">{duration}</div>
			</div>
		</div>
	);
};

export default React.memo(TimeSheetEntry);
