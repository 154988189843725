import * as React from 'react';
import { Button } from '@acceligentllc/storybook';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

import type TableSettingsRequestModel from 'ab-requestModels/tableSettings.requestModel';

import { ColumnSettingItem } from 'ab-viewModels/columnSettings.viewModel';
import type TableSettingsVM from 'ab-viewModels/tableSettings.viewModel';

import * as ArrayUtil from 'ab-utils/array.util';

import type { Column } from 'af-components/Table/types';
import CustomModal from 'af-components/CustomModal';
import ConfirmationModal from 'af-components/ConfirmationModal';

import { getDefaultTableSettings } from 'af-utils/table.utils';

import ColumnList from './ColumnList';

interface OwnProps<T> {
	tableName: string;
	accountId: Nullable<number>;
	columns: Column<T>[];
	tableSettings: Nullable<TableSettingsVM>;
	show: boolean;
	onSave: (tableSettings: Nullable<TableSettingsRequestModel>) => Promise<void>;
	closeTableSettings: () => void;
}

type Props<T> = OwnProps<T>;

interface State {
	tableSettings: Nullable<TableSettingsVM>;
	showResetToDefaultModal: boolean;
	hideMainModal: boolean;
	reloading: boolean;
}
class TableSettingsModal<T> extends React.PureComponent<Props<T>, State> {
	state: State = {
		hideMainModal: false,
		tableSettings: this.props.tableSettings,
		showResetToDefaultModal: false,
		reloading: false,
	};

	openResetToDefaultModal = () => this.setState(() => ({ hideMainModal: true, showResetToDefaultModal: true }));

	closeResetToDefaultModal = () => this.setState(() => ({ hideMainModal: false, showResetToDefaultModal: false }));

	toggleColumnVisibility = (item: ColumnSettingItem) => {
		this.setState(
			(state: State) => {
				if (!state.tableSettings) {
					return state;
				}

				const columnSettings = state.tableSettings?.columnSettings?.map((_col) => ({ ..._col })) ?? [];
				const index = columnSettings.findIndex(
					(col) => col.name === item.name
				);
				columnSettings[index].visible = !columnSettings[index].visible;

				return { tableSettings: { ...state.tableSettings, columnSettings } };
			}
		);
	};

	onDragEnd = async ({ source, destination }: DropResult) => {
		if (!destination) {
			return;
		}

		const sourceIndex = source.index;
		const destinationIndex = destination.index;
		this.setState(
			(state: State) => {
				if (!state.tableSettings) {
					return state;
				}

				const _columnSettings = ArrayUtil.move(
					state.tableSettings.columnSettings,
					sourceIndex,
					destinationIndex
				);
				return { tableSettings: { ...state.tableSettings, columnSettings: _columnSettings } };
			}
		);
	};

	renderColumnList = () => {
		const { columns } = this.props;
		const { tableSettings } = this.state;

		const filteredColumns = tableSettings?.columnSettings?.reduce<ColumnSettingItem[]>((_acc, _col) => {
			const item = columns.find(({ id }) => id === _col.name);
			if (item) {
				const fixed = !!item.isFixed;
				_acc.push(new ColumnSettingItem(
					_col.name,
					_col.visible,
					_col.width ?? undefined,
					fixed,
					item.tableSettingsName ?? item.header as React.ReactNode
				));
			}
			return _acc;
		}, []) ?? [];

		return <ColumnList columns={filteredColumns} toggleColumnVisibility={this.toggleColumnVisibility} />;
	};

	resetToDefault = async () => {
		const { onSave, tableName, accountId, columns } = this.props;

		const defaultTableSettings = getDefaultTableSettings(tableName, accountId, columns);

		await onSave(defaultTableSettings);
		this.setState(() => ({ reloading: true }));
		window.location.reload();
	};

	onSave = () => {
		const { onSave, closeTableSettings } = this.props;
		const { tableSettings } = this.state;

		onSave(tableSettings);
		closeTableSettings();
	};

	render() {
		const { show, closeTableSettings } = this.props;
		const { showResetToDefaultModal, hideMainModal, reloading } = this.state;

		return (
			<>
				<CustomModal
					closeModal={closeTableSettings}
					id="table-settings-modal"
					modalStyle="info"
					showModal={show && !hideMainModal}
					size="sm"
				>
					<CustomModal.Header
						closeModal={closeTableSettings}
						title="Table Settings"
					/>
					<CustomModal.Body>
						<DragDropContext onDragEnd={this.onDragEnd} >
							{this.renderColumnList()}
						</DragDropContext>
					</CustomModal.Body>
					<CustomModal.Footer>
						<Button
							label="Cancel"
							onClick={closeTableSettings}
							style="secondary"
						/>
						<Button
							label="Reset to default"
							onClick={this.openResetToDefaultModal}
							style="danger"
						/>
						<Button
							label="Save"
							onClick={this.onSave}
							style="primary"
						/>
					</CustomModal.Footer>
				</CustomModal>
				<ConfirmationModal
					body={
						<>
							Following table settings will be reset to default:
							<ul>
								<li>Sort</li>
								<li>Page size</li>
								<li>Column order</li>
								<li>Column visibility</li>
								<li>Column width</li>
							</ul>
							This action will refresh the page and cannot be undone.
						</>
					}
					closeModal={this.closeResetToDefaultModal}
					confirmAction={this.resetToDefault}
					confirmText="Reset to default"
					disabled={reloading}
					hideOnConfirm={false}
					modalStyle="danger"
					showModal={showResetToDefaultModal}
					title="Reset to default"
				/>
			</>
		);
	}
}

export default TableSettingsModal;
