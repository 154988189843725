import type { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import type JobHazardAssessmentPreviewVM from 'ab-viewModels/workRequest/jobHazardAssessmentPreview.viewModel';
import type { PreviewBillingContactVM } from 'ab-viewModels/workRequest/workRequestPreviewDetails.viewModel';

import type { JobOutletContext } from 'af-components/SharedPreviews/WorkRequest/Details/types';
import type { TableProps } from 'af-components/Table/types';
import type { TableRef } from 'af-components/Table';

export type JobPreviewOutletContext<T = WorkOrderTableViewModel> = JobOutletContext & TableProps<T> & {
	jobId: number;
	jobHazardAssessment: JobHazardAssessmentPreviewVM;
	jobCode: string;
	billingContact: Nullable<PreviewBillingContactVM>;
	isSendingInvoiceNotificationsEnabled: boolean;
	ref: React.RefObject<TableRef<T>>;
};

export enum JobPreviewTabRoutes {
	DETAILS = 'details',
	BILLING_CODES = 'billingCodes',
	JOB_HAZARD_ASSESSMENT = 'jobHazardAssessment',
	ATTACHMENTS = 'attachments',
	RELATED_WORK_ORDERS = 'relatedWorkOrders',
	WORK_SUMMARY = 'workSummary',
	INVOICES = 'invoices',
}
