import * as React from 'react';

import type { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type FieldReportAccessRole from '@acceligentllc/shared/enums/fieldReportAccessRole';
import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';

import * as ReportDisplayStatusUtils from '@acceligentllc/shared/utils/workOrderReport';
import { getId } from '@acceligentllc/shared/utils/array';

import Tooltip from 'af-components/Tooltip';

import type { TimeSheetVM, EmployeeVM } from 'ab-viewModels/workOrder/workOrderForReportsTable.viewModel';

import WorkOrderStatusSummaryTooltip from './WorkOrderStatusSummaryTooltip';
import DisplayReviewStatusLabel from './DisplayReviewStatusLabel';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';

interface OwnProps {
	accountId: Nullable<number>;
	assignedTo: Nullable<EmployeeVM[]>;
	fieldReportAccessRole: Nullable<FieldReportAccessRole>;
	isCondensedView: boolean;
	lastUpdatedAt: Nullable<string>;
	reviewLevel: WorkOrderReviewLevel;
	reviewStatus: WorkOrderReviewStatus;
	calculatedReportDisplayStatus: CalculatedReportDisplayStatus;
	timeSheets: TimeSheetVM[];
}

type Props = OwnProps;

const isTimeSheetRejected = (timeSheet: TimeSheetVM) => timeSheet.approvalStatus === TimeSheetApprovalStatus.REJECTED;

const DisplayReviewStatusLabelWithTooltip: React.FC<Props> = (props: Props) => {
	const {
		assignedTo,
		accountId,
		fieldReportAccessRole,
		isCondensedView,
		reviewLevel,
		reviewStatus,
		calculatedReportDisplayStatus,
		timeSheets,
		lastUpdatedAt,
	} = props;

	const hasCurrentUserRejectedTimeSheet = React.useMemo(
		() => timeSheets.some((_ts) => isTimeSheetRejected(_ts) && _ts.accountId === accountId), [timeSheets, accountId]
	);

	const isOfInterest = React.useMemo(
		() => {
			if (!!fieldReportAccessRole && calculatedReportDisplayStatus) {
				const isStatusOfInterest = ReportDisplayStatusUtils.isCalculatedReportDisplayStatusOfInterestForRole(
					fieldReportAccessRole,
					calculatedReportDisplayStatus,
					accountId,
					assignedTo?.map(getId) ?? null,
					hasCurrentUserRejectedTimeSheet
				);
				return isStatusOfInterest ?? false;
			}
			return false;
		},
		[fieldReportAccessRole, calculatedReportDisplayStatus, assignedTo, accountId, hasCurrentUserRejectedTimeSheet]
	);

	const renderTooltipMessage = () => {
		return (
			<WorkOrderStatusSummaryTooltip
				assignedTo={assignedTo}
				lastUpdatedAt={lastUpdatedAt}
				reviewLevel={reviewLevel}
				reviewStatus={reviewStatus}
				timeSheets={timeSheets}
			/>
		);
	};

	return (
		<Tooltip className="bar-meter__tooltip" message={renderTooltipMessage()} >
			<DisplayReviewStatusLabel
				calculatedReportDisplayStatus={calculatedReportDisplayStatus}
				isCondensedView={isCondensedView}
				isOfInterest={isOfInterest}
			/>
		</Tooltip>
	);
};

export default React.memo(DisplayReviewStatusLabelWithTooltip);
