import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import { QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';
import type WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { formatDate } from '@acceligentllc/shared/utils/time';
import { getPlainFullName } from '@acceligentllc/shared/utils/user';
import { workOrderCode } from '@acceligentllc/shared/utils/codes';

import { CUSTOMER_SERVICE_NAME } from '@acceligentllc/shared/constants/value';

import { TableContent } from 'ab-common/dataStructures/tableContent';

import type BillingCodeBase from 'ab-domain/models/billingCode/base';
import type JobWorkSummaryBase from 'ab-domain/models/jobWorkSummary/base';

export class W_Accounting_FindWorkSummaryTable_VM extends TableContent<W_Accounting_FindWorkSummaryTable_VM_Row>{
	constructor(jobWorkSummaries: JobWorkSummaryBase[], pages: number, totalCount: number) {
		super(
			W_Accounting_FindWorkSummaryTable_VM_Row.bulkConstructor(jobWorkSummaries),
			pages,
			totalCount
		);
	}
}

class W_Accounting_FindWorkSummaryTable_VM_Row {
	workRequest: string;
	workOrder: Nullable<string>;
	workOrderId: Nullable<number>;
	/** YYYY-MM-DD */
	startDate: string;
	quantity: number;
	description: Nullable<string>;
	billingCode: Nullable<string>;
	unit: Nullable<string>;
	selectedBillingCode: Nullable<W_Accounting_FindWorkSummaryTable_VM_BillingCode>;
	unitPrice: Nullable<number>;
	revenue: number;
	work: Nullable<string>;
	type: Nullable<string>;
	typeValue: Nullable<string>;
	definition1: Nullable<string>;
	definition2: Nullable<string>;
	definition3: Nullable<string>;
	definition4: Nullable<string>;
	reviewStatus: Nullable<WorkSummaryStatus>;
	lastUpdatedAt: Nullable<string>;
	lastUpdatedBy: Nullable<string>;

	private constructor(row: JobWorkSummaryBase) {
		this.workRequest = row.workRequest!.jobCode as string;
		this.workOrder = row.workOrder ? workOrderCode(row.workOrder, row.workOrder.workRequest) : null;
		this.workOrderId = row.workOrderId;
		this.startDate = row.startDate;
		this.quantity = row.quantity ?? 0;
		this.description = row.description;
		this.billingCode = row.customerId;
		this.selectedBillingCode = row.billingCode ?? null;
		this.unit = row.unit && QuantityUnitMap[row.unit] ? QuantityUnitMap[row.unit].replace('_', '/') : null;
		this.unitPrice = row.unitPrice;
		this.revenue = row.revenue ?? 0;
		this.work = row.work;
		this.type = row.type !== row.typeValue && !!row.typeValue ? `${row.type}: ${row.typeValue}` : row.type;
		this.definition1 = row.definitionField1Name ? `${row.definitionField1Name}: ${row.definitionField1Value ?? 'N/A'}` : null;
		this.definition2 = row.definitionField2Name ? `${row.definitionField2Name}: ${row.definitionField2Value ?? 'N/A'}` : null;
		this.definition3 = row.definitionField3Name ? `${row.definitionField3Name}: ${row.definitionField3Value ?? 'N/A'}` : null;
		this.definition4 = row.definitionField4Name ? `${row.definitionField4Name}: ${row.definitionField4Value ?? 'N/A'}` : null;
		this.reviewStatus = row.fieldReport?.workSummaryStatus ?? null;
		this.lastUpdatedAt = row.fieldReport?.workSummaryLastUpdatedAt
			? formatDate(row.fieldReport.workSummaryLastUpdatedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)
			: null;
		if (this.lastUpdatedAt) {
			this.lastUpdatedBy = row.fieldReport?.workSummaryLastUpdatedBy
				? getPlainFullName(row.fieldReport.workSummaryLastUpdatedBy.user.firstName, row.fieldReport.workSummaryLastUpdatedBy.user.lastName)
				: CUSTOMER_SERVICE_NAME;
		} else {
			this.lastUpdatedBy = null;
		}
	}

	static bulkConstructor(rows: JobWorkSummaryBase[]) {
		return rows.map((_row) => new W_Accounting_FindWorkSummaryTable_VM_Row(_row));
	}

}

class W_Accounting_FindWorkSummaryTable_VM_BillingCode {
	id: number;
	unit: QuantityUnitType;

	constructor(billingCode: BillingCodeBase) {
		this.id = billingCode.id;
		this.unit = billingCode.unit;
	}
}

