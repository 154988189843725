import * as React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { Field } from 'redux-form';

import { ExtendedColorPalette } from '@acceligentllc/shared/enums/color';

import Textarea from 'af-fields/Textarea';
import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';

import LabelWithColor from 'af-components/LabelWithColor';

type Props = WrappedFieldProps;

const INPUT_FIELD_MAX_CHARACTERS = 500;

interface ColorOption {
	name: ExtendedColorPalette;
}

const AVAILABLE_COLORS = [{ name: ExtendedColorPalette.RED }, { name: ExtendedColorPalette.BLACK }];

const DEFAULT_COLOR = AVAILABLE_COLORS[0];

const DescriptiveTextBlock: React.FC<Props> = (props) => {

	const { meta, input } = props;

	const renderColorOption = React.useCallback((option: ColorOption) => {
		return <LabelWithColor color={option.name} text={<strong>{option.name.toLowerCase()}</strong>} />;
	}, []);

	return (
		<div>
			<Field
				component={Textarea}
				dynamicRows={true}
				input={input}
				label="Text"
				maxCharacters={INPUT_FIELD_MAX_CHARACTERS}
				meta={meta}
				name={input.name}
				placeholder="Descriptive Text"
				rows={1}
			/>
			<Field
				component={Checkbox}
				isStandalone={true}
				label="Bold text"
				name="isDescriptiveTextBold"
			/>
			<Field
				component={Dropdown}
				defaultValue={DEFAULT_COLOR}
				label="Text Color"
				name="descriptiveTextColor"
				options={AVAILABLE_COLORS}
				renderMenuItem={renderColorOption}
				valueKey="name"
			/>
		</div>
	);
};

export default React.memo(DescriptiveTextBlock);
