import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';

import type { TimeSheetInfoVM, TimeSheetEntryInfoVM } from 'ab-viewModels/workOrder/submitForReviewRecap';

import type { FieldReportListItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import CustomModal from 'af-components/CustomModal';

import TimeSheets from './TimeSheets';

import FieldReports from '../Shared/ReportsSummary/FieldReports';

interface OwnProps {
	fieldReports: FieldReportListItemVM[];
	closeModal: () => void;
	onConfirm: () => void;
	reviewLevel: WorkOrderReviewLevel;
	showModal: boolean;
	signedTimeSheets: TimeSheetInfoVM[];
	timeSheetEntriesByAccountIdMap: Nullable<{ [accountId: number]: TimeSheetEntryInfoVM[]; }>;
	unsignedTimeSheets: TimeSheetInfoVM[];
	timezone: Nullable<string>;
}

type Props = OwnProps;

const MODAL_TITLE = 'Approve Report and Time Sheets';

const REPORTS_SUMMARY_DESCRIPTION_ALL_SIGNED = 'You are about to approve all time sheets and submit following Report content for review.';
const REPORTS_SUMMARY_DESCRIPTION_ALL_SIGNED_LAST_APPROVAL = 'You are about to approve entire report content and all time sheets.';
const REPORTS_SUMMARY_DESCRIPTION_UNSIGNED = 'You are about to approve all time sheets and submit following Report content for review. Your signature will be used to sign all unsigned time sheets.';
const REPORTS_SUMMARY_DESCRIPTION_UNSIGNED_LAST_APPROVAL = 'You are about to approve entire report content and all time sheets. Your signature will be used to sign all unsigned time sheets.';

const getDescription = (reviewLevel: WorkOrderReviewLevel, hasUnsignedTimeSheets: boolean) => {
	if (reviewLevel === WorkOrderReviewLevel.LEVEL_3) {
		if (hasUnsignedTimeSheets) {
			return REPORTS_SUMMARY_DESCRIPTION_UNSIGNED_LAST_APPROVAL;
		}
		return REPORTS_SUMMARY_DESCRIPTION_ALL_SIGNED_LAST_APPROVAL;
	} else {
		if (hasUnsignedTimeSheets) {
			return REPORTS_SUMMARY_DESCRIPTION_UNSIGNED;
		}
		return REPORTS_SUMMARY_DESCRIPTION_ALL_SIGNED;
	}
};

const ApproveModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		onConfirm,
		timeSheetEntriesByAccountIdMap,
		fieldReports,
		reviewLevel,
		signedTimeSheets,
		unsignedTimeSheets,
		showModal,
		timezone,
	} = props;

	return (
		<CustomModal
			className="report-type-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="md"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={MODAL_TITLE}
			/>
			<CustomModal.Body>
				<div className="report-submit-modal__text">{getDescription(reviewLevel, !!unsignedTimeSheets?.length)}</div>
				<TimeSheets
					signedTimeSheets={signedTimeSheets}
					timeSheetEntriesByAccountIdMap={timeSheetEntriesByAccountIdMap}
					timezone={timezone}
					unsignedTimeSheets={unsignedTimeSheets}
				/>
				<FieldReports
					fieldReports={fieldReports}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<Button
					label="Continue"
					onClick={onConfirm}
					style="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(ApproveModal);
