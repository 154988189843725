import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CellContext, Row } from '@tanstack/react-table';
import { useNavigate, useOutletContext } from 'react-router-dom-v5-compat';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';
import WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';
import * as ReportBlockFieldEnum from '@acceligentllc/shared/enums/reportBlockField';
import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';
import { QuantityUnitMap } from '@acceligentllc/shared/enums/quantityUnit';

import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';
import type JobWorkSummaryWorkOrderVM from 'ab-viewModels/workRequest/jobWorkSummaryWorkOrder.viewModel';
import type { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';
import type JobWithProjectInfoVM from 'ab-viewModels/workRequest/jobWithProjectInfo.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { convertUnits } from 'ab-utils/unitConversion.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import * as SettingsUtils from 'af-utils/settings.util';
import { getFullClientUrl } from 'af-utils/http.util';
import { downloadCSV } from 'af-utils/csv.utils';

import CLIENT from 'af-routes/client';

import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import DateFilter from 'af-components/DateFilter';
import LinkCell from 'af-components/Table/Cells/LinkCell';
import TextCell from 'af-components/Table/Cells/TextCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import type { TableProps } from 'af-components/Table/types';
import Dropdown from 'af-components/Controls/Dropdown';
import Tooltip from 'af-components/Tooltip';

import * as BillingCodeActions from 'af-actions/billingCode/index';
import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import { resolveFieldValue } from 'af-root/scenes/Company/FieldReports/FieldReportsList/FieldReport/WorkSummary/Table/helpers';

import styles from './styles.module.scss';
import AddBillableWorkModal from './AddBillableWorkModal';
import TableHeader from './TableHeader';
import type { JobPreviewOutletContext } from '../types';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type TableHeaderData = {
	totalRevenue: number;
};

export interface SubjobBillingCodes {
	workRequestId: number;
	billingCodes: BillingCodeVM[];
}

export interface SubjobWorkOrders {
	workRequestId: number;
	workOrders: JobWorkSummaryWorkOrderVM[];
}

type Props = ConnectedProps<typeof connector> & SettingProps;

const _resolveType = (jws: JobWorkSummaryVM) => {
	return (jws.typeFieldType === ReportBlockFieldEnum.Type.NUMERIC_ATTRIBUTE || jws.typeFieldType === ReportBlockFieldEnum.Type.CALCULATED)
		? jws.type
		: `${jws.type}: ${resolveFieldValue(jws.typeValue, jws.typeFieldType)}`;
};

const _resolveDefFieldFullValue = (value: string, name: Nullable<string>, type: Nullable<ReportBlockFieldEnum.Type>) => {
	if (!name) {
		return value;
	}

	let resolvedValue = value;
	if (type === ReportBlockFieldEnum.Type.BOOLEAN) {
		resolvedValue = value === 'true' ? 'Yes' : 'No';
	}

	return `${name}: ${resolvedValue}`;
};

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.JOB_WORK_SUMMARY_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.JOB_WORK_SUMMARY_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.JOB_WORK_SUMMARY_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const defaultSubjobOptions = [
	{ jobCode: 'All sub-jobs' },
];

const renderSubjob = (option: SubjobVM) => <span>{option.jobCode}</span>;

const WorkSummary: React.FC<Props> = (props) => {
	const {
		findBillingCodesForJobId,
		getWorkSummaryTable,
		getWorkSummaryTableCSV,
		getWorkOrdersForJobWorkSummary,
		getWorkSummaryTotalRevenue,
		restoreOriginalJobWorkSummary,
		findAllSubjobs,
		findJobWithProjectInfo,
	} = props;

	const navigate = useNavigate();

	const { jobId, companyName, orgAlias, jobCode, hasPermissionsToAccessWorkSummary } = useOutletContext<JobPreviewOutletContext>();

	const tableRef = React.useRef<TableRef<JobWorkSummaryVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);
	const [showAddBillableWorkModal, setShowAddBillableWorkModal] = React.useState(false);
	const [billingCodes, setBillingCodes] = React.useState<Nullable<BillingCodeVM[]>>(null);
	const [workOrders, setWorkOrders] = React.useState<Nullable<JobWorkSummaryWorkOrderVM[]>>(null);
	const [currentlyEditedRow, setCurrentlyEditedRow] = React.useState<Nullable<JobWorkSummaryVM>>(null);
	const [subjobs, setSubjobs] = React.useState<SubjobVM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Partial<SubjobVM>>(defaultSubjobOptions[0]);
	const [subjobBillingCodes, setSubjobBillingCodes] = React.useState<SubjobBillingCodes[]>([]);
	const [subjobWorkOrders, setSubjobWorkOrders] = React.useState<SubjobWorkOrders[]>([]);
	const [jobWithProjectInfo, setJobWithProjectInfo] = React.useState<Nullable<JobWithProjectInfoVM>>(null);

	const selectedSubJobIdToFetch = React.useMemo(() => {
		return selectedSubjob?.id ?? null;
	}, [selectedSubjob]);

	const isProject = React.useMemo(() => {
		if (!jobWithProjectInfo) {
			return false;
		}
		return jobWithProjectInfo?.mainJobId === jobWithProjectInfo?.id;
	}, [jobWithProjectInfo]);

	const openAddBillableWorkModal = React.useCallback(async () => {
		setShowAddBillableWorkModal(true);
	}, []);

	const closeAddBillableWorkModal = React.useCallback(() => {
		setShowAddBillableWorkModal(false);
		setCurrentlyEditedRow(null);
	}, []);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<undefined | TableContent<JobWorkSummaryVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		if (!hasPermissionsToAccessWorkSummary) {
			navigate(CLIENT.ERROR.ERR403());
			return;
		}

		const result = await getWorkSummaryTable(
			jobId,
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			startDate,
			endDate,
			selectedSubJobIdToFetch
		);
		return result;
	}, [hasPermissionsToAccessWorkSummary, getWorkSummaryTable, jobId, startDate, endDate, selectedSubJobIdToFetch, navigate]);

	const fetchRevenueTotal = React.useCallback(async () => {
		const totalRevenue = await getWorkSummaryTotalRevenue(jobId, selectedSubJobIdToFetch);
		return { totalRevenue };
	}, [getWorkSummaryTotalRevenue, jobId, selectedSubJobIdToFetch]);

	const renderTableHeader = React.useCallback((tableHeaderData: TableHeaderData) => {
		return (
			<TableHeader
				totalRevenue={tableHeaderData.totalRevenue}
			/>
		);
	}, []);

	const onRowEdit = React.useCallback((_jws: JobWorkSummaryVM) => () => {
		setCurrentlyEditedRow(_jws);
		openAddBillableWorkModal();
	}, [openAddBillableWorkModal]);

	const onRestoreOriginalClick = React.useCallback((_jws: JobWorkSummaryVM, _jobId) => {
		return async () => {
			await restoreOriginalJobWorkSummary(_jobId, _jws.id);
			tableRef.current?.refreshTable();
		};
	}, [restoreOriginalJobWorkSummary]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={[
					{ onClick: onRowEdit(_cell.row.original), label: 'Edit' },
					{ onClick: onRestoreOriginalClick(_cell.row.original, jobId), label: 'Restore Original Data', disabled: !_cell.row.original.originalData }]
				}
				valueKey="label"
			/>
		);
	}, [jobId, onRestoreOriginalClick, onRowEdit]);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const onSubjobFilterChange = React.useCallback((option: Partial<SubjobVM>) => {
		tableRef.current?.resetPagination();
		setSelectedSubjob(option);
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<>
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
				{
					isProject &&
					process.env.FTD_PROJECT !== 'true' &&
					<>
						<Dropdown<Partial<SubjobVM>>
							defaultValue={defaultSubjobOptions[0]}
							filterable={false}
							forcePlaceholder={false}
							isWhite={true}
							onValueChange={onSubjobFilterChange}
							options={[...defaultSubjobOptions, ...subjobs]}
							renderMenuItem={renderSubjob}
							valueKey="jobCode"
							withBorder={true}
							withCaret={true}
						/>
					</>
				}

			</>

		);
	}, [changePeriod, endDate, filterByDate, isProject, onSubjobFilterChange, period, startDate, subjobs]);

	const renderFirstSubRowCell = React.useCallback(() => {
		return (
			<TextCell className={styles['job-work-summary__sub-row-cell']} value="Original Data" />
		);
	}, []);

	const renderTextCell = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		if (isSubRowCell) {
			return (
				<TextCell className={styles['job-work-summary__sub-row-cell']} value={cell.getValue()} />
			);
		}

		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderDateCell = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		const dbDate = cell.getValue() as string;

		const date = TimeUtils.formatDate(dbDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		if (isSubRowCell) {
			return (
				<TextCell className={styles['job-work-summary__sub-row-cell']} value={date} />
			);
		}

		return <TextCell value={date} />;
	}, []);

	const renderUnitPriceCell = React.useCallback((isSubRow: boolean) => (cell) => {
		const className = isSubRow ? styles['job-work-summary__sub-row-cell'] : undefined;
		const _value = cell.getValue();

		if (!_value) {
			return null;
		}

		const _billingUnit: QuantityUnitType = QuantityUnitMap[cell.row.original.billingCode?.unit?.replace('/', '_')];
		const _unit: QuantityUnitType = QuantityUnitMap[cell.row.original.unit?.replace('/', '_')];

		const convertedUnitPrice = _unit !== _billingUnit ? convertUnits(_value, _unit, _billingUnit) : null;

		const tooltipMessage = convertedUnitPrice
			? <>
				<div>Quantity tracked in {_unit?.replace('_', '/')}</div>
				<div>Billing code in {_billingUnit?.replace('_', '/')}</div>
			</>
			: null;

		const _renderValue = convertedUnitPrice
			? `$${parseFloat(convertedUnitPrice.toFixed(8))} per ${_unit ? _unit?.replace('_', '/') : 'N/A'} ($${parseFloat(_value.toFixed(4))} per ${_billingUnit?.replace('_', '/')})`
			: `$${parseFloat(_value.toFixed(4))} per ${_unit ? _unit?.replace('_', '/') : 'N/A'}`;

		const textCell = <TextCell className={className} value={_renderValue} />;

		return (
			tooltipMessage ? <Tooltip message={tooltipMessage}>{textCell}</Tooltip> : textCell
		);
	}, []);

	const renderLinkCell = React.useCallback((isSubRowCell: boolean) =>
		(_cell: CellContext<JobWorkSummaryVM, unknown>) => {
			const className: string[] = [];
			isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

			if (!_cell.cell.row.original.workOrderCode) {
				className.push(styles['job-work-summary__empty-cell']);
				return (
					<span className={className.join(' ')}>
						N/A
					</span>
				);
			}

			const path = _cell.row.original.workOrderId
				? getFullClientUrl(orgAlias, CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${_cell.row.original.workOrderId}`, orgAlias, companyName))
				: '';

			return <LinkCell label={_cell.row.original.workOrderCode!} labelClassName={className.join(' ')} path={path} />;
		}, [companyName, orgAlias]);

	const renderDefinitionOrInfoField = React.useCallback((isSubRowCell: boolean) => (cell) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		const value = cell.getValue();
		let displayValue = cell.renderValue();

		if (typeof value === 'string') {
			// Check if value is address object displayed as a string, in which case take only street value
			try {
				const startIndex = value.indexOf('{');
				const endIndex = value.lastIndexOf('}');
				const fieldName = value.substring(0, startIndex).trim();
				const jsonStr = JSON.parse(value.substring(startIndex, endIndex + 1).trim());
				if (jsonStr.street) {
					displayValue = `${fieldName} ${jsonStr.street}`;
				}
			} catch {
				// Do nothing
			}
		}
		return (
			<TextCell className={className.join('')} value={displayValue} />
		);
	}, []);

	const renderBillingCode = React.useCallback((isSubRowCell: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);

		if (!_cell.getValue<string>()) {
			className.push(styles['job-work-summary__no-billing-code']);
			return (
				<span className={className.join(' ')}>
					No Billing Code
				</span>
			);
		}

		return <TextCell className={className.join(' ')} value={_cell.getValue<string>()} />;
	}, []);

	const renderRevenueCell = React.useCallback((isSubRowCell: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const className: string[] = [];
		isSubRowCell && className.push(styles['job-work-summary__sub-row-cell']);
		if (_cell.getValue() === null) {
			className.push(styles['job-work-summary__empty-cell']);
			className.push(styles['job-work-summary__empty-cell--right-aligned']);
			return (
				<span className={className.join(' ')}>
					N/A
				</span>
			);
		}

		return <DollarCell className={className.join(' ')} value={_cell.getValue<JobWorkSummaryVM['revenue']>() ?? 0} />;
	}, []);

	const renderWorkSummaryStatusCell = React.useCallback((isSubRow: boolean) => (_cell: CellContext<JobWorkSummaryVM, unknown>) => {
		const cellStyle: string[] = [];
		cellStyle.push(styles['job-work-summary__review-status-cell']);
		isSubRow && cellStyle.push(styles['job-work-summary__review-status-cell--sub-row-cell']);

		let body = <span className={cellStyle.join(' ')}>
			<span className="icon-dot" />
			<b>Draft</b>
		</span>;

		switch (_cell.cell.row.original.reviewStatus) {
			case WorkSummaryStatus.DRAFT: {
				cellStyle.push(styles['job-work-summary__review-status-cell--draft']);
				body = <span className={cellStyle.join(' ')}>
					<span className="icon-dot" />
					<b>Draft</b>
				</span>;
				break;
			}
			case WorkSummaryStatus.IN_PROGRESS: {
				cellStyle.push(styles['job-work-summary__review-status-cell--in-progress']);
				body = <span className={cellStyle.join(' ')}>
					<span className="icon-dot" />
					<b>In Progress</b>
				</span>;
				break;
			}
			case WorkSummaryStatus.OUTDATED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--outdated']);
				body = <span className={cellStyle.join(' ')}>
					<span className="icon-dot_outline" />
					<b>Outdated</b>
				</span>;
				break;
			}
			case WorkSummaryStatus.REVIEWED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--reviewed']);
				body = <span className={cellStyle.join(' ')}>
					<span className="icon-dot" />
					<b>Reviewed</b>
				</span>;
				break;
			}
			case WorkSummaryStatus.COMPLETED: {
				cellStyle.push(styles['job-work-summary__review-status-cell--completed']);
				body = <span className={cellStyle.join(' ')}>
					<span className="icon-dot" />
					<b>Completed</b>
				</span>;
				break;
			}
			default: {
				cellStyle.push(styles['job-work-summary__empty-cell']);
				body = <span className={cellStyle.join(' ')}>
					N/A
				</span>;
			}
		}

		const reviewedBy = _cell.cell.row.original.reviewedBy;
		const reviewedAt = _cell.cell.row.original.reviewedAt;

		const message = reviewedBy ? `${reviewedAt} By ${reviewedBy}` : null;

		return message ? (
			<Tooltip message={message} placement="top">
				{body}
			</Tooltip>
		) : (
			body
		);
	}, []);

	const getSubRows = React.useCallback((_originalRow) => {
		if (!!_originalRow.originalData) {
			return [_originalRow.originalData];
		} else {
			return [];
		}
	}, []);

	const onRowClick = React.useCallback((_row: Row<JobWorkSummaryVM>) => {
		setCurrentlyEditedRow(_row.original);
		openAddBillableWorkModal();
	}, [openAddBillableWorkModal]);

	const fetchAndInitializeData = React.useCallback(async () => {
		const [
			fetchedBillingCodes,
			fetchedWorkOrders,
			fetchedJob,
		] = await Promise.all([
			findBillingCodesForJobId(jobId),
			getWorkOrdersForJobWorkSummary(jobId),
			findJobWithProjectInfo(jobId),
		]);

		setBillingCodes(fetchedBillingCodes);
		setWorkOrders(fetchedWorkOrders);
		setJobWithProjectInfo(fetchedJob);
	}, [findBillingCodesForJobId, findJobWithProjectInfo, getWorkOrdersForJobWorkSummary, jobId]);

	const refreshTable = React.useCallback(() => {
		tableRef.current?.refreshTable();
	}, []);

	const refreshTableHeader = React.useCallback(() => {
		tableRef.current?.refreshTableHeader();
	}, []);

	const onExportWorkSummaryClick = React.useCallback(async () => {
		const tableQuery = tableRef.current?.getTableQuery();
		const result = await getWorkSummaryTableCSV(jobId, startDate, endDate, selectedSubJobIdToFetch, tableQuery?.filterByText ?? '');
		const csvData = JobWorkSummaryVM.toCSVData(result);
		downloadCSV(csvData, `${jobCode}_work_summary.csv`);
	}, [getWorkSummaryTableCSV, jobId, startDate, endDate, selectedSubJobIdToFetch, jobCode]);

	const buttons = React.useMemo(() => [
		{ label: 'Add Billable Work', icon: 'plus', type: TableButtonType.LINK, hasPermission: true, onClick: openAddBillableWorkModal },
		{ label: 'Export Work Summary', icon: 'download', type: TableButtonType.LINK, hasPermission: true, onClick: onExportWorkSummaryClick },
	], [onExportWorkSummaryClick, openAddBillableWorkModal]);

	const fetchSubJobs = React.useCallback(async () => {
		const _subjobs = await findAllSubjobs(jobId);
		setSubjobs(_subjobs);
		const billingCodeMap: SubjobBillingCodes[] = [];
		const workOrderMap: SubjobWorkOrders[] = [];
		for (const _subjob of _subjobs) {
			const _billingCodes = await findBillingCodesForJobId(_subjob.id);
			const _workOrders = await getWorkOrdersForJobWorkSummary(_subjob.id);
			billingCodeMap.push({ workRequestId: _subjob.id, billingCodes: _billingCodes });
			workOrderMap.push({ workRequestId: _subjob.id, workOrders: _workOrders });
		}
		setSubjobBillingCodes(billingCodeMap);
		setSubjobWorkOrders(workOrderMap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findAllSubjobs, findBillingCodesForJobId, jobId]);

	React.useEffect(() => {
		fetchSubJobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isJobColumnHidden = React.useMemo(() => { return !isProject || process.env.FTD_PROJECT === 'true'; }, [isProject]);

	const columns: TableProps<JobWorkSummaryVM>['columns'] = React.useMemo(() => [
		{
			id: 'actions',
			isDisplayColumn: true,
			isFixed: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
			subRowColumn: {
				cell: renderFirstSubRowCell,
			},
			size: 100,
		},
		{
			id: 'customerId',
			accessor: 'customerId',
			header: 'Billing Code',
			cell: renderBillingCode(false),
			isFixed: true,
			subRowColumn: {
				cell: renderBillingCode(true),
			},
			enableSorting: true,
			size: 150,
		},
		{
			id: 'workRequest',
			accessor: 'workRequest',
			header: 'Job Name',
			cell: renderTextCell(false),
			isFixed: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
			enableSorting: true,
			size: isJobColumnHidden ? 0 : 150,
			enableHiding: true,
			isHidden: isJobColumnHidden,
		},
		{
			id: 'revenue',
			accessor: 'revenue',
			header: 'Revenue',
			cell: renderRevenueCell(false),
			isFixed: true,
			subRowColumn: {
				cell: renderRevenueCell(true),
			},
			enableSorting: true,
			size: 100,
		},
		{
			id: 'workOrder',
			accessor: 'workOrderCode',
			header: 'Work Order',
			cell: renderLinkCell(false),
			enableSorting: true,
			size: 250,
			subRowColumn: {
				cell: renderLinkCell(true),
			},
		},
		{
			id: 'startDate',
			accessor: 'startDate',
			header: 'Start Date',
			cell: renderDateCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDateCell(true),
			},
		},
		{
			id: 'reviewStatus',
			accessor: 'reviewStatus',
			header: 'Review Status',
			cell: renderWorkSummaryStatusCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderWorkSummaryStatusCell(true),
			},
		},
		{
			id: 'invoiceId',
			accessor: 'invoiceId',
			header: 'Invoice ID',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'work',
			accessor: 'work',
			header: 'Work',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'type',
			accessor: _resolveType,
			header: 'Type',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'defField1',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.definitionField1Value
				? _resolveDefFieldFullValue(_originalRow.definitionField1Value, _originalRow.definitionField1Name, _originalRow.definitionField1Type)
				: null,
			header: 'Definition field 1',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField2',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.definitionField2Value
				? _resolveDefFieldFullValue(_originalRow.definitionField2Value, _originalRow.definitionField2Name, _originalRow.definitionField2Type)
				: null,
			header: 'Definition field 2',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField3',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.definitionField3Value
				? _resolveDefFieldFullValue(_originalRow.definitionField3Value, _originalRow.definitionField3Name, _originalRow.definitionField3Type)
				: null,
			header: 'Definition field 3',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'defField4',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.definitionField4Value
				? _resolveDefFieldFullValue(_originalRow.definitionField4Value, _originalRow.definitionField4Name, _originalRow.definitionField4Type)
				: null,
			header: 'Definition field 4',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField1',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField1Value,
			header: 'Informational field 1',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField2',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField2Value,
			header: 'Informational field 2',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField3',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField3Value,
			header: 'Informational field 3',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'infoField4',
			accessor: (_originalRow: JobWorkSummaryVM) => _originalRow.informationField4Value,
			header: 'Informational field 4',
			cell: renderDefinitionOrInfoField(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderDefinitionOrInfoField(true),
			},
			enableHiding: true,
			autoHideEmptyColumn: true,
		},
		{
			id: 'quantity',
			accessor: 'quantity',
			header: 'Quantity',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'unit',
			accessor: 'unit',
			header: 'Unit',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'group',
			accessor: 'group',
			header: 'Group',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
		{
			id: 'unitPrice',
			accessor: 'unitPrice',
			header: 'Unit Price',
			cell: renderUnitPriceCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderUnitPriceCell(true),
			},
		},
		{
			id: 'description',
			accessor: 'description',
			header: 'Description',
			cell: renderTextCell(false),
			enableSorting: true,
			subRowColumn: {
				cell: renderTextCell(true),
			},
		},
	], [resolveActionsButton,
		renderFirstSubRowCell,
		renderBillingCode,
		renderRevenueCell,
		renderLinkCell,
		renderTextCell,
		renderWorkSummaryStatusCell,
		renderDefinitionOrInfoField,
		renderUnitPriceCell,
		renderDateCell,
		isJobColumnHidden,
	]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
	}, [startDate, endDate, period]);

	if (!billingCodes || !jobWithProjectInfo || !workOrders) {
		return null;
	}

	if (!hasPermissionsToAccessWorkSummary) {
		navigate(CLIENT.ERROR.ERR403());
		return;
	}

	return (
		<>
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				fetchTableHeaderData={fetchRevenueTotal}
				getSubRows={getSubRows}
				hasSearchInput={true}
				hasSubRows={true}
				onRowClick={onRowClick}
				ref={tableRef}
				renderTableHeader={renderTableHeader}
				selectable={true}
				tableBodyClassName={styles['work-summary-table']}
				tableName={TableNameEnum.JOB_WORK_SUMMARY}
			/>
			<AddBillableWorkModal
				billingCodes={billingCodes}
				closeModal={closeAddBillableWorkModal}
				currentlyEditedRow={currentlyEditedRow}
				isProject={isProject}
				jobId={jobId}
				refreshTable={refreshTable}
				refreshTableHeader={refreshTableHeader}
				showModal={showAddBillableWorkModal}
				subjobBillingCodes={subjobBillingCodes}
				subjobs={subjobs}
				subjobWorkOrders={subjobWorkOrders}
				workOrders={workOrders}
			/>
		</>
	);
};

function mapDispatchToProps() {
	return {
		findBillingCodesForJobId: BillingCodeActions.findForJobId,
		getWorkSummaryTable: JobActions.getWorkSummaryTable,
		getWorkSummaryTableCSV: JobActions.getWorkSummaryTableCSV,
		getWorkOrdersForJobWorkSummary: JobActions.getWorkOrdersForJobWorkSummary,
		getWorkSummaryTotalRevenue: JobActions.getWorkSummaryTotalRevenue,
		restoreOriginalJobWorkSummary: JobActions.restoreOriginalJobWorkSummary,
		findAllSubjobs: WorkRequestActions.findAllSubjobs,
		findJobWithProjectInfo: WorkRequestActions.findWithProjectInfo,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.JOB_WORK_SUMMARY_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(WorkSummary);
