import * as React from 'react';
import { Row } from 'react-bootstrap';

import type { CompanyFeatureFlagsEntry } from 'ab-viewModels/companyFeatureFlags.viewModel';

import PreviewDay from '../../Shared/PreviewDay';
import SettingPreview from '../../Shared/SettingPreview';

interface OwnProps {
	toggleEdit: () => void;
	defaultSendTimes: (Nullable<CompanyFeatureFlagsEntry>)[];
}

type Props = OwnProps;

export default class NotificationTimesPreview extends React.PureComponent<Props> {

	render() {
		const { toggleEdit, defaultSendTimes } = this.props;

		return (
			<SettingPreview
				buttonContext="notification-settings__default-times"
				containerId="accqa__notification-settings__default-times__container"
				toggleEdit={toggleEdit}
			>
				<div className="company-settings__info-segment">
					<Row className="company-settings__feature-days">
						{
							defaultSendTimes.map((_featureFlag: Nullable<CompanyFeatureFlagsEntry>) => (
								<PreviewDay
									key={`${_featureFlag?.label}#${_featureFlag?.value}`}
									label={_featureFlag?.label ?? ''}
									value={_featureFlag?.value ?? null}
								/>
							))
						}
					</Row>
				</div>
			</SettingPreview>
		);
	}
}
