import * as React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import enUs from 'date-fns/locale/en-US';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { RootState } from 'af-reducers';

import SocketEvent from 'ab-enums/socketEvent.enum';

import CustomDatePickerInput from './CustomDatePickerInput';

import ConfirmationModal from 'af-components/ConfirmationModal';

import socket from 'af-utils/socket.util';

interface OwnProps {
	itemId: number;
	/** MM-DD-YYYY */
	dueDate: string;
	scope: UnavailabilityReasonScope;
	hasPermissionsToEditScheduleBoard: boolean;
}

interface StateProps {
	/** YYYY-MM-DD */
	returnDate: Nullable<string>;
}

type Props = OwnProps & StateProps;

interface State {
	selectedDate: Nullable<Date>;
	showClearReturnDateConfirmationModal: boolean;
}

const popperProps = {
	positionFixed: true,
};

class ReturnDatePicker extends React.PureComponent<Props, State> {

	static CONFIRMATION_MODAL_TITLE = 'Remove return date';
	static EQUIPMENT_CONFIRMATION_MODAL_BODY = 'This action will make equipment permanently unavailable and will remove any future statuses. Are you sure want to continue?';
	static EMPLOYEE_CONFIRMATION_MODAL_BODY = 'This action will make labor permanently unavailable and will remove any future statuses. Are you sure want to continue?';

	state: State = {
		selectedDate: this.props.returnDate ? TimeUtils.parseDate(this.props.returnDate, TimeFormat.DB_DATE_ONLY) : null,
		showClearReturnDateConfirmationModal: false,
	};

	static getDerivedStateFromProps(props: Props, oldState: State) {
		const oldDate = oldState.selectedDate ? TimeUtils.parseMoment(oldState.selectedDate) : null;
		const newDate = props.returnDate ? TimeUtils.parseMoment(props.returnDate, TimeFormat.DB_DATE_ONLY) : null;

		if (!oldDate && !newDate || oldDate && newDate?.isSame?.(oldDate, 'date')) {
			return null;
		}

		const selectedDate = props.returnDate ? TimeUtils.parseDate(props.returnDate, TimeFormat.DB_DATE_ONLY) : null;
		return { selectedDate };
	}

	onDateSelect = async (date: Nullable<Date>) => {
		this.setState(() => ({ selectedDate: date }));
		this.onDateChanged(date ? TimeUtils.formatDate(date, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME) : null);
	};

	onDateChanged = async (date: Nullable<string>) => {
		const { dueDate, itemId, scope } = this.props;
		const currentDate = TimeUtils.toDatabaseDateOnly(new Date())!;
		socket.connection?.emit(SocketEvent.V2.FE.SCHEDULE_BOARD.CHANGE_RETURN_DATE, { itemId, dueDate, date, currentDate, scope });
	};

	onClear = () => this.onDateSelect(null);

	openClearReturnDateConfirmationModal = () => this.setState(() => ({ showClearReturnDateConfirmationModal: true }));
	closeClearReturnDateConfirmationModal = () => this.setState(() => ({ showClearReturnDateConfirmationModal: false }));

	render() {
		const { dueDate, hasPermissionsToEditScheduleBoard, scope } = this.props;
		const { selectedDate, showClearReturnDateConfirmationModal } = this.state;

		const body = scope === UnavailabilityReasonScope.EQUIPMENT
			? ReturnDatePicker.EQUIPMENT_CONFIRMATION_MODAL_BODY
			: ReturnDatePicker.EMPLOYEE_CONFIRMATION_MODAL_BODY;

		const dueDateDate = TimeUtils.parseDate(dueDate, TimeFormat.DATE_ONLY);
		const nextDayDateString = TimeUtils.getNextDayDate(dueDateDate, TimeFormat.DATE_ONLY);
		const minDateDate = TimeUtils.parseDate(nextDayDateString, TimeFormat.DATE_ONLY);

		return (
			<>
				<DatePicker
					className="form-control"
					customInput={<CustomDatePickerInput onClear={this.openClearReturnDateConfirmationModal} />}
					dateFormat={TimeUtils.datePickerFormat(TimeFormat.SHORT_DATE)}
					disabled={!hasPermissionsToEditScheduleBoard}
					dropdownMode="select"
					locale={enUs}
					minDate={minDateDate}
					onChange={this.onDateSelect}
					popperPlacement="top-end"
					popperProps={popperProps}
					selected={selectedDate}
					showMonthDropdown={true}
					showYearDropdown={true}
					todayButton="Today"
				/>
				<ConfirmationModal
					body={body}
					closeModal={this.closeClearReturnDateConfirmationModal}
					confirmAction={this.onClear}
					showModal={showClearReturnDateConfirmationModal}
					size={'md'}
					title={ReturnDatePicker.CONFIRMATION_MODAL_TITLE}
				/>
			</>
		);
	}
}

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const { workOrdersByDateDictionary } = state.scheduleBoard;
	const { itemId, dueDate, scope } = ownProps;

	const attribute = scope === UnavailabilityReasonScope.EMPLOYEE ? 'employeeReturnDate' : 'equipmentReturnDate';
	const _returnDate = workOrdersByDateDictionary?.[dueDate]?.[attribute]?.[itemId];
	// FIXME: return date is sometimes in DB_DATE_ONLY, and sometimes in DATE_ONLY. Should not happen. Investigate
	const returnDate = _returnDate ? TimeUtils.formatDate(_returnDate, TimeFormat.DB_DATE_ONLY) : null;

	return {
		returnDate,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(ReturnDatePicker);
