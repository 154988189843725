import * as React from 'react';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import { Dimension, Type } from '@acceligentllc/shared/enums/reportBlockField';
import type { SignatureFieldVM } from '@acceligentllc/shared/utils/fieldReport';
import { formatDate } from '@acceligentllc/shared/utils/time';
import { ExtendedColorPalette } from '@acceligentllc/shared/enums/color';
import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import SignatureStatusLabel from '../../../Shared/SignatureStatusLabel';
import styles from './styles.module.scss';

type FieldReportBlockFieldVM = FieldReportTypeSidePanelVM['fieldReportBlocks'][0]['fieldReportBlockFields'][0];

type RepeatableProps = {
	isRepeatable: true;
	repeatableIndex: number;
} | {
	isRepeatable: false;
	repeatableIndex?: never;
};

type Props = {
	fieldReportBlockField: FieldReportBlockFieldVM;
} & RepeatableProps;

const _renderImmutableText = (fieldReportBlockField: FieldReportBlockFieldVM) => {
	const blackText = fieldReportBlockField.reportBlockField.descriptiveTextColor === ExtendedColorPalette.BLACK;
	const classNames = [styles['side-panel__descriptive-text'], styles['side-panel__field--span-two'], styles['side-panel__field']];
	if (blackText) {
		classNames.push(styles['side-panel__descriptive-text--black-text']);
	}

	if (fieldReportBlockField.reportBlockField.isDescriptiveTextBold) {
		classNames.push(styles['side-panel__descriptive-text--bold-text']);
	}

	return (
		<div className={classNames.join(' ')}>
			{fieldReportBlockField.reportBlockField.defaultValue}
		</div>
	);
};

const renderImageField = (_value: Nullable<string>) => {
	return (
		<>
			{_value ? <img src={_value} /> : 'None'}
		</>
	);
};

const renderPlainField = (_value: Nullable<string>, unit: Nullable<QuantityUnitType>) => (
	<div className={styles['side-panel__field__value']}>
		{_value !== null && _value !== '' ? `${_value} ${unit ?? ''}` : 'None'}
	</div>
);

const resolveClassName = (dimension: Dimension) => {
	const fieldClassName = [styles['side-panel__field']];
	if (
		dimension === Dimension.SMALL
		|| dimension === Dimension.VERY_SMALL
		|| dimension === Dimension.MEDIUM
		|| dimension === Dimension.ADJUSTABLE
	) {
		fieldClassName.push(styles['side-panel__field--span-one']);
	} else {
		fieldClassName.push(styles['side-panel__field--span-two']);
	}

	return fieldClassName.join(' ');
};

const renderSignatureField = (_value: Nullable<string>) => {

	if (!_value) {
		return (
			<div className={styles['side-panel__field']}>
				None
			</div>
		);
	}
	const signature = JSON.parse(_value) as SignatureFieldVM;

	if (signature.id < 1) {
		return (
			<div className={styles['side-panel__field']}>
				None
			</div>
		);
	}
	return (
		<div className={styles['side-panel__signature-field']}>
			<span>
				<SignatureStatusLabel status={TimeSheetSignatureStatus.SIGNED} />
				by {signature.name}
				<br />
				{formatDate(signature.signedAt, TimeFormat.FULL_DATE, TimeFormat.ISO_DATETIME)}
			</span>
			{signature.imageUrl ? <img src={signature.imageUrl} /> : 'None'}
		</div>
	);
};

const _renderLineBreak = () => <div className={styles['side-panel__field--span-two']} />;

const _renderCalculatedField = (_value: Nullable<string>, unit: Nullable<QuantityUnitType>) => {
	return (
		<div className={styles['side-panel__field__value']}>
			{_value !== null && _value !== '' ? `${_value} ${unit ?? ''}` : 'None'}
		</div>
	);
};

const _resolveFieldValue = (fieldType: Type, fieldValue: Nullable<string>, unit: Nullable<QuantityUnitType>) => {
	if (fieldType === Type.IMAGE || fieldType === Type.IMMUTABLE_IMAGE) {
		return renderImageField(fieldValue);
	}

	if (fieldType === Type.CALCULATED) {
		return _renderCalculatedField(fieldValue, unit);
	}

	if (fieldType === Type.SIGNATURE) {
		return renderSignatureField(fieldValue);
	}

	if (fieldType === Type.LINE_BREAK) {
		return _renderLineBreak();
	}

	return renderPlainField(fieldValue, unit);
};

const FieldReportBlockFieldSidePanel: React.FC<Props> = (props) => {
	const { fieldReportBlockField, isRepeatable } = props;
	const fieldClassName = resolveClassName(fieldReportBlockField.reportBlockField.dimension);

	const fieldValue = React.useMemo(() => {
		let newValue = fieldReportBlockField.value;
		if (isRepeatable) {
			newValue = (fieldReportBlockField.value as Nullable<string>[])?.[props.repeatableIndex] ?? null;
		}
		return newValue;
	}, [fieldReportBlockField.value, isRepeatable, props.repeatableIndex]);

	if (fieldReportBlockField.isCompletion) {
		// we are rendering it in footer of block
		return null;
	}

	if (fieldReportBlockField.fieldType === Type.IMMUTABLE_TEXT) {
		return _renderImmutableText(fieldReportBlockField);
	}

	const value = _resolveFieldValue(fieldReportBlockField.fieldType, fieldValue as Nullable<string>, fieldReportBlockField.reportBlockField.unit);
	const hideHeader = (fieldReportBlockField.fieldType === Type.LINE_BREAK);

	return (
		<div
			className={fieldClassName}
			key={fieldReportBlockField.id}
		>
			{!hideHeader && (
				<div className={styles['side-panel__field__name']}>
					{fieldReportBlockField.reportBlockField.name}
				</div>
			)}
			{value}
		</div>
	);
};

export default React.memo(FieldReportBlockFieldSidePanel);
