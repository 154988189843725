import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button } from '@acceligentllc/storybook';

type Props = CustomRouteComponentProps<void>;

class BackButton extends React.Component<Props> {

	goBack = () => this.props.history.goBack();

	render() {
		return (
			<Button
				label="Back"
				onClick={this.goBack}
				style="secondary"
				type="button"
			/>
		);
	}
}

export default withRouter(BackButton);
