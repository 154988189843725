import * as React from 'react';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import type WorkRequestDetailsUpsertVM from 'ab-viewModels/workRequest/workRequestDetailsUpsert.viewModel';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import styles from '../../../styles.module.scss';
import { formSectionClassColumn8, formSectionClassRow } from '../../../helpers';

export type OwnProps = {
	formName: string;
};

type Props = WrappedFieldArrayProps<WorkRequestDetailsUpsertVM['additionalContacts']> & OwnProps;

const AdditionalContacts: React.FC<Props> = ({ fields, formName }) => {
	const onAddClick = React.useCallback(() => {
		fields.push(null);
	}, [fields]);

	const onRemoveClick = React.useCallback((index: number) => () => fields.remove(index), [fields]);

	const renderAdditionalContact = React.useCallback((_name: string, _index: number) => {
		const additionalContactName = `Additional Contact ${_index + 1}`;
		return (
			<React.Fragment key={_name}>
				<div className={formSectionClassRow}>
					{additionalContactName}
				</div>
				<div className={formSectionClassRow} key={_name}>
					<div className={[formSectionClassColumn8, styles['job-form__additional-contact-container'], styles['job-form__delete-button']].join(' ')}>
						<JobContact
							className={[styles['job-form__additional-contact']].join(' ')}
							contactLabel="Enter Additional Contact"
							fieldName={_name}
							formName={formName}
						/>
						<Button
							icon="delete"
							onClick={onRemoveClick(_index)}
							style="link-danger"
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}, [formName, onRemoveClick]);

	return (
		<>
			{fields.map(renderAdditionalContact)}
			<Button
				icon="plus"
				label="Add Contact"
				onClick={onAddClick}
				style="link"
			/>
		</>
	);
};

export default AdditionalContacts;
