import * as React from 'react';
import type { InjectedFormProps, FormAction } from 'redux-form';
import { reduxForm } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';

import * as CompanyActions from 'af-actions/companies';

import type { CompanyRequestModel } from 'ab-requestModels/company.requestModel';
import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import { COMPANY_EDIT } from 'af-constants/reduxForms';

import EditCompanyForm from 'af-components/SharedForms/Company/BasicInfoForm';
import { validate } from 'af-components/SharedForms/Company/BasicInfoForm/validations';
import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

type FormProps = InjectedFormProps<CompanyRequestModel, FormOwnProps>;

interface CompanySettingsRouteProps {
	orgAlias: string;
}

interface OwnProps extends CustomRouteComponentProps<CompanySettingsRouteProps> {
	company: CompanyViewModel;
	isVisible: boolean;
	toggleEdit: () => void;
	changeField: (form: string, field: string, value: string | number) => FormAction;
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	editCompany: typeof CompanyActions.editCompany;
}

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

const CompanyEdit: React.FC<Props> = (props) => {
	const {
		toggleEdit,
		initialValues: { name },
		editCompany,
		reset,
		handleSubmit,
		isVisible,
		invalid,
		submitting,
	} = props;

	const [image, setImage] = React.useState<string | File | null>(null);
	const [shouldDeleteImage, setShouldDeleteImage] = React.useState<boolean>(false);

	const submit = React.useCallback(async (form: CompanyRequestModel) => {
		form.image = image;
		form.deleteImage = shouldDeleteImage;

		form.nameChanged = name !== form.name;
		await editCompany(form);
		toggleEdit();
	}, [editCompany, image, name, shouldDeleteImage, toggleEdit]);

	const onClose = React.useCallback(() => {
		reset();
		toggleEdit();
	}, [reset, toggleEdit]);

	return (
		<CustomModal
			closeModal={onClose}
			modalStyle="info"
			showModal={isVisible}
			size="lg"
		>
			<CustomModal.Header
				closeModal={onClose}
				title="Edit Company Settings"
			/>
			<CustomModal.Body padding="none">
				<EditCompanyForm
					{...props}
					formName={COMPANY_EDIT}
					image={image}
					setImage={setImage}
					setShouldDeleteImage={setShouldDeleteImage}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={onClose}
					style="secondary"
				/>
				<SubmitButton
					disabled={invalid}
					label="Save"
					onClick={handleSubmit(submit)}
					reduxFormSubmitting={submitting}
					submitKey={COMPANY_EDIT}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps(): DispatchProps {
	return {
		editCompany: CompanyActions.editCompany,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<CompanyRequestModel, FormOwnProps>({ form: COMPANY_EDIT, validate, enableReinitialize: true })
);

export default enhance(CompanyEdit);
