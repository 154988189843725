import * as React from 'react';
import { Field } from 'redux-form';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { normalizeDateWrapper } from 'ab-utils/reduxForms.util';

import TimePicker from 'af-fields/TimePicker';

import fieldHoc from '../Shared/fieldHoc';

interface OwnProps {
	disabled: boolean;
	formName: string;
	name: string;
	tooltipMessage: Nullable<React.ComponentProps<typeof TimePicker>['tooltipMessage']>;
	onFocus?: () => void;
	onValueChange: () => void;
}

type Props = OwnProps;

const TimeField: React.FC<Props> = (props) => {
	const {
		disabled,
		formName,
		name,
		tooltipMessage,
		onValueChange,
		onFocus,
	} = props;
	return (
		<Field
			component={TimePicker}
			disabled={disabled}
			inputTimeFormat={TimeFormat.TIME_ONLY_12H}
			label={name}
			name={formName}
			normalize={normalizeDateWrapper()}
			onFocus={onFocus}
			onValueChange={onValueChange}
			tooltipMessage={tooltipMessage}
		/>
	);
};

export default fieldHoc(React.memo(TimeField));
