import * as React from 'react';

import CDLStatus from '@acceligentllc/shared/enums/cdlStatus';

import type { DisplayViewEmployeeViewModel } from 'ab-viewModels/scheduleBoardDisplayView.viewModel';

import SkillsColorGrid from 'af-components/SkillsColorGrid';
import Icon from 'af-components/Icon';

interface OwnProps {
	employee: DisplayViewEmployeeViewModel;
}

type Props = OwnProps;

const EmployeeAdditionalInfo: React.FC<Props> = (props: Props) => {
	const { employee } = props;

	if (employee.account.assignableAsSuperintendent && employee.account.assignableAsProjectManager) {
		return <Icon name="helmet_si_pm" pathNumber={5} />;
	} else if (employee.account.assignableAsSuperintendent) {
		return <Icon name="helmet_s" pathNumber={5} />;
	} else if (employee.account.assignableAsProjectManager) {
		return <Icon name="helmet_pm" pathNumber={5} />;
	} else if (employee.skills?.length) {
		return <SkillsColorGrid enableCdlIndicator={employee.cdlStatus === CDLStatus.CDL_A} skills={employee.skills} />;
	} else {
		return null;
	}
};

export default React.memo(EmployeeAdditionalInfo);
