import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { bemElement } from 'ab-utils/bem.util';

interface OwnProps {
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: string;
	isOpaque: boolean;
}

type Props = OwnProps;

const OccupiedSlot: React.FC<Props> = (props: Props) => {
	const { startTime, endTime, isOpaque } = props;

	const start = TimeUtils.formatDate(startTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME);
	const startDate = TimeUtils.formatDate(startTime, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);
	const end = TimeUtils.formatDate(endTime, TimeFormat.TIME_ONLY_12H, TimeFormat.ISO_DATETIME);
	const endDate = TimeUtils.formatDate(endTime, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);

	const endDateDisplay = startDate === endDate ? '' : `${endDate} / `;

	const occupiedLabel = `${startDate} / ${start} - ${endDateDisplay}${end}`;

	const className = bemElement('time-sheet-edit-modal', 'time-sheet-occupied', { opaque: isOpaque });

	return (
		<div className={className}>
			Occupied: {occupiedLabel}
		</div>
	);
};

export default React.memo(OccupiedSlot);
