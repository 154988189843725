import * as React from 'react';
import type { Row } from '@tanstack/react-table';

import styles from '../styles.module.scss';

interface Props<T> {
	currentlyPreviewedRow: Nullable<Row<T>>;
	renderSidePanelContext: (selectedRow: Row<T>) => JSX.Element;
	renderSidePanelHeader: (selectedRow: Row<T>) => JSX.Element;
}

function SidePanel<T>({ currentlyPreviewedRow, renderSidePanelContext, renderSidePanelHeader }: React.PropsWithChildren<Props<T>>) {

	const defaultHeader = React.useMemo(() => {
		return (
			<span className={styles['container__side-panel__header__default']}>
				Preview Content
			</span>
		);
	}, []);

	const defaultContext = React.useMemo(() => (
		<div className={styles['container__side-panel__body__hint']}>
			Click a row to display detailed information.
		</div>
	), []);

	return (
		<div className={styles['container__side-panel']}>
			<div className={styles['container__side-panel__header']}>
				{currentlyPreviewedRow
					? renderSidePanelHeader(currentlyPreviewedRow)
					: defaultHeader
				}
			</div>
			<div className={styles['container__side-panel__body']}>
				{currentlyPreviewedRow
					? renderSidePanelContext(currentlyPreviewedRow)
					: defaultContext
				}
			</div>
		</div>
	);
}

export default React.memo(SidePanel) as typeof SidePanel;
