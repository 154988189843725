import type { W_ReportType_FindAllReportTypesForFilter_VM, W_ReportType_FindAllReportTypesForFilter_VM_Single } from 'ab-api/web/reportType/findAllForFilter';

export class ReportTypeFilterItem {
	id: number;
	name: string;
	/** 1-based index */
	index: number;

	constructor(reportType: W_ReportType_FindAllReportTypesForFilter_VM_Single, index: number) {
		this.id = reportType.id;
		this.name = reportType.name;
		this.index = index;
	}

	private static _constructorMap = (
		reportType: W_ReportType_FindAllReportTypesForFilter_VM_Single, index: number
	) => new ReportTypeFilterItem(reportType, index);

	static bulkConstructor = (
		reportTypes: W_ReportType_FindAllReportTypesForFilter_VM
	) => reportTypes.map((reportType, index) => {
		return ReportTypeFilterItem._constructorMap(reportType, index + 1);
	});

}
