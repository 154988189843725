import * as React from 'react';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router';
import type { Row } from '@tanstack/react-table';

import { getShortDayName } from '@acceligentllc/shared/utils/time';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';

import type TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';

import { downloadCSV } from 'af-utils/csv.utils';
import * as SettingsUtils from 'af-utils/settings.util';
import { setItemWithFormatter, setItem } from 'af-utils/settings.util';

import * as SettingsKeys from 'af-constants/settingsKeys';

import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import ResourceCell from 'af-components/Table6/Cells/ResourceCell';
import DateFilter from 'af-components/DateFilter';
import LabelWithColor from 'af-components/LabelWithColor';

import CLIENT from 'af-routes/client';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

interface OwnProps {
	csvName: string;
	fetchWorkOrders: (tableRequestModel: TableQuery, startDate: Date, endDate: Date) => Promise<TableResult<WorkOrderTableViewModel>>;
	tableName: TableNameEnum;
	companyName: string;
	offsetHeight?: number;
}

type Props = OwnProps & SettingProps;

function setWOStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

const RelatedWorkOrders: React.FC<Props> = (props) => {
	const {
		fetchWorkOrders: _fetchWorkOrders,
		tableName,
		csvName,
		companyName,
		startDate: initialStartDate,
		endDate: initialEndDate,
		period: initialPeriod,
		offsetHeight,
	} = props;

	const [startDate, setStartDate] = React.useState<Date>(initialStartDate);
	const [endDate, setEndDate] = React.useState<Date>(initialEndDate);
	const [period, setPeriod] = React.useState<TimePeriodRecurrence>(initialPeriod);

	const _tableRef = React.useRef<TableRef<WorkOrderTableViewModel>>(null);

	const history = useHistory();
	const { state: { orgAlias } } = useLocation<{ orgAlias: string; }>();

	const openWoReport = React.useCallback((row: Row<WorkOrderTableViewModel>) => {
		history.push(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(`${row.original.id}`, orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const columns: TableProps<WorkOrderTableViewModel>['columns'] = React.useMemo(() => [
		{
			header: 'Work Order',
			accessor: 'workOrderCode',
			id: 'code',
			size: 190,
			enableSorting: true,
			cell: ({ cell }) => (
				<span className="text-strong"> {cell.row.original.code}{cell.row.original.revision ? `, REV ${cell.row.original.revision}` : ''}</span>
			),
		},
		{
			header: 'Job Title',
			accessor: 'title',
			id: 'title',
			size: 100,
			enableSorting: true,
			cell: ({ cell }) => (
				<span>{cell.row.original.jobTitle}</span>
			),
		},
		{
			header: 'Status',
			accessor: 'status',
			id: 'status',
			size: 80,
			enableSorting: true,
			cell: ({ cell }) => {
				switch (cell.row.original.status) {
					case WorkOrderStatus.CANCELED:
						return <span className="text-red text-strong">{cell.row.original.status}</span>;
					default:
						return <span className="text-black">{cell.row.original.status}</span>;
				}
			},
		},
		{
			header: 'Date of Work',
			accessor: 'dueDate',
			size: 140,
			id: 'dueDate',
			enableSorting: true,
			cell: ({ cell }) => <span>{getShortDayName(cell.row.original.dueDate)}, {cell.row.original.dueDate}</span>,
		},
		{
			header: 'Crew',
			accessor: 'crewNumber',
			size: 55,
			id: 'crewNumber',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.crewNumber,
		},
		{
			header: 'Crew Type',
			accessor: 'crewType',
			size: 100,
			id: 'crewType',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.crewType
				? <LabelWithColor color={cell.row.original.crewColor} text={cell.row.original.crewType} /> : <EmptyCell />,
		},
		{
			header: 'Superintendent',
			accessor: 'supervisor.account.user.firstName',
			size: 100,
			id: 'supervisor',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.supervisor?.fullName ?? <EmptyCell />,
		},
		{
			header: 'Project Manager',
			accessor: 'projectManager.account.user.firstName',
			size: 100,
			id: 'projectManager',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.projectManager?.fullName ?? <EmptyCell />,
		},
		{
			header: 'Revenue',
			accessor: 'revenue',
			size: 80,
			id: 'revenue',
			cell: ({ cell }) => cell.row.original.revenue ? `$ ${formatDecimalNumber(cell.row.original.revenue)}` : <EmptyCell />,
		},
		{
			header: 'Man-hours',
			accessor: 'manHourAverage',
			size: 70,
			id: 'manHourAverage',
			enableSorting: true,
			cell: ({ cell }) => cell.row.original.manHourAverage ? `$ ${formatDecimalNumber(cell.row.original.manHourAverage)}` : <EmptyCell />,
		},
		{
			accessor: 'equipmentAndLabor',
			header: 'Equip./Labor/Temp. Labor',
			enableSorting: false,
			size: 100,
			id: 'equipmentAndLabor',
			cell: ({ cell }) => {
				return (
					<ResourceCell
						original={cell.row.original}
					/>
				);
			},
		},
		{
			accessor: 'woLink',
			header: 'Field Report',
			enableSorting: false,
			size: 120,
			id: 'woLink',
			cell: ({ cell }) => {
				switch (cell.row.original.status) {
					case WorkOrderStatus.DRAFT:
						return <EmptyCell />;
					default:
						return (
							<div className="work-order-info-modal__report-link" onClick={openWoReport.bind(null, cell.row)}>
								<div className="work-order-info-modal__icon-text">View</div> < span className="icon-external link-cell__icon" />
							</div >
						);
				}
			},
		},
	], [openWoReport]);

	const fetchWorkOrders = React.useCallback(async (tableRequestModel: TableQuery) => {
		return await _fetchWorkOrders(tableRequestModel, startDate, endDate);
	}, [_fetchWorkOrders, endDate, startDate]);

	const refresh = React.useCallback(() => {
		if (_tableRef.current) {
			_tableRef.current.refreshTable();
		}
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, selected: Date) => {
		let _startDate: Date | undefined, _endDate: Date | undefined;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('Failed to parse selectedMoment');
		}

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				_endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				_endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				_startDate = selected;
				_endDate = selected;
				break;
		}

		setPeriod(_period);
		setStartDate(_startDate);
		setEndDate(_endDate);
		setWOStartDate(_startDate);
		setWOEndDate(_endDate);
		setWOPeriod(_period);
		refresh();
	}, [refresh]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {

		setStartDate(_startDate);
		setEndDate(_endDate);

		setWOStartDate(_startDate);
		setWOEndDate(_endDate);
		refresh();
	}, [refresh]);

	const renderFilter = React.useCallback(() => {

		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter changePeriod={changePeriod} endDate={endDate} onChange={filterByDate} period={period} startDate={startDate} />
			</div>
		);
	}, [changePeriod, endDate, filterByDate, period, startDate]);

	const onDownloadCSVClick = React.useCallback(async () => {
		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await _fetchWorkOrders(tableRequestModel, startDate, endDate);
		const csvData = WorkOrderTableViewModel.toCSVData(result.rows);

		const _csvName = !csvName.endsWith('.csv') ? `${csvName}.csv` : csvName;

		downloadCSV(csvData, _csvName);
	}, [_fetchWorkOrders, csvName, endDate, startDate]);

	const tableButtons = React.useMemo(() => {
		return [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: onDownloadCSVClick,
				label: 'Export as CSV',
			},
		];
	}, [onDownloadCSVClick]);

	return (
		<TableNew
			additionalFilter={renderFilter}
			buttons={tableButtons}
			columns={columns}
			fetch={fetchWorkOrders}
			hasSearchInput={true}
			offsetHeight={offsetHeight}
			onRowClick={openWoReport}
			ref={_tableRef}
			searchLabel={'Work Orders'}
			selectable={false}
			tableName={tableName}
		/>
	);
};

const enhance = compose<React.ComponentClass<OwnProps>>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	]))
);

export default enhance(RelatedWorkOrders);
