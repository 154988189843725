import baseRoute from '../baseRoute';

export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/${number | ':fieldReportId'}/equipmentList`;

export const ROUTE: RouteDefinition = `${baseRoute}/:fieldReportId/equipmentList`;

export const URL = (fieldReportId: number): RouteDefinition =>
	`${baseRoute}/${fieldReportId}/equipmentList`;
