import NotificationPriority from '@acceligentllc/shared/enums/notificationPriority';
import AutoNotifyOption from '@acceligentllc/shared/enums/autoNotifyOption';

import * as TimeOptionUtils from '@acceligentllc/shared/utils/timeOption';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import type { TemporaryLaborNotificationsRM } from 'ab-requestModels/notificationSettings.requestModel';

interface UserGroupFM {
	id: number;
	name: string;
}

export class GeneralNotifications {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	notificationPriority: NotificationPriority;

	static fromCompanyViewModel(company: CompanyViewModel): GeneralNotifications {
		const { notification } = company;
		return {
			isEnabled: notification.isEnabled,
			isEnabledAutoNotificationsOnWorkDays: notification.isEnabledAutoNotificationsOnWorkDays,
			notificationPriority: notification.notificationPriority,
		};
	}
}

export interface DefaultNotificationTimesForm {
	notifyOnMonday: string;
	notifyOnTuesday: string;
	notifyOnWednesday: string;
	notifyOnThursday: string;
	notifyOnFriday: string;
	notifyOnSaturday: string;
	notifyOnSunday: string;
}

export class DefaultNotificationTimes {
	notifyOnMonday: Nullable<number>;
	notifyOnTuesday: Nullable<number>;
	notifyOnWednesday: Nullable<number>;
	notifyOnThursday: Nullable<number>;
	notifyOnFriday: Nullable<number>;
	notifyOnSaturday: Nullable<number>;
	notifyOnSunday: Nullable<number>;

	static fromCompanyViewModel(company: CompanyViewModel): DefaultNotificationTimesForm {
		const { notification } = company;

		return {
			notifyOnMonday: TimeOptionUtils.toString(notification.notifyOnDay[1]),
			notifyOnTuesday: TimeOptionUtils.toString(notification.notifyOnDay[2]),
			notifyOnWednesday: TimeOptionUtils.toString(notification.notifyOnDay[3]),
			notifyOnThursday: TimeOptionUtils.toString(notification.notifyOnDay[4]),
			notifyOnFriday: TimeOptionUtils.toString(notification.notifyOnDay[5]),
			notifyOnSaturday: TimeOptionUtils.toString(notification.notifyOnDay[6]),
			notifyOnSunday: TimeOptionUtils.toString(notification.notifyOnDay[0]),
		};
	}

	static fromForm(form: DefaultNotificationTimesForm): DefaultNotificationTimes {
		return {
			notifyOnMonday: TimeOptionUtils.toNumber(form.notifyOnMonday),
			notifyOnTuesday: TimeOptionUtils.toNumber(form.notifyOnTuesday),
			notifyOnWednesday: TimeOptionUtils.toNumber(form.notifyOnWednesday),
			notifyOnThursday: TimeOptionUtils.toNumber(form.notifyOnThursday),
			notifyOnFriday: TimeOptionUtils.toNumber(form.notifyOnFriday),
			notifyOnSaturday: TimeOptionUtils.toNumber(form.notifyOnSaturday),
			notifyOnSunday: TimeOptionUtils.toNumber(form.notifyOnSunday),
		};
	}

	static toFrom(model: DefaultNotificationTimes): { notifyOnDay: number[] | undefined; } {
		if (
			model.notifyOnSunday === null
			|| model.notifyOnMonday === null
			|| model.notifyOnTuesday === null
			|| model.notifyOnWednesday === null
			|| model.notifyOnThursday === null
			|| model.notifyOnFriday === null
			|| model.notifyOnSaturday === null
		) {
			return {
				notifyOnDay: undefined,
			};
		}

		return {
			notifyOnDay: [
				model.notifyOnSunday,
				model.notifyOnMonday,
				model.notifyOnTuesday,
				model.notifyOnWednesday,
				model.notifyOnThursday,
				model.notifyOnFriday,
				model.notifyOnSaturday,
			],
		};
	}
}

export interface SpecialEmailsForm {
	enableScheduleBoardEmail: boolean;
	notifyScheduleBoardEmail: string;
	scheduleBoardEmailUserGroup: Nullable<UserGroupFM>;

	enablePastWorkOrdersNotification: boolean;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupFM>;

	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardAt: string;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupFM>;

	enableNotifyFailed: boolean;
	notifyFailedAt: string;
	notifyFailedUserGroup: Nullable<UserGroupFM>;

	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroup: Nullable<UserGroupFM>;

	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroup: Nullable<UserGroupFM>;

	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;

	isSendingInvoiceNotificationsEnabledForCompany: boolean;
	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: string;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroup: Nullable<UserGroupFM>;

	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroup: Nullable<UserGroupFM>;

	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroup: Nullable<UserGroupFM>;

	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<UserGroupFM>;
}

export class SpecialEmails {
	enableScheduleBoardEmail: boolean;
	notifyScheduleBoardEmail: Nullable<number>;
	scheduleBoardEmailUserGroup: Nullable<UserGroupFM>;

	enablePastWorkOrdersNotification: boolean;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupFM>;

	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardAt: Nullable<number>;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupFM>;

	enableNotifyFailed: boolean;
	notifyFailedAt: Nullable<number>;
	notifyFailedUserGroup: Nullable<UserGroupFM>;

	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;

	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: Nullable<number>;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroup: Nullable<UserGroupFM>;

	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroup: Nullable<UserGroupFM>;

	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroup: Nullable<UserGroupFM>;
	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroup: Nullable<UserGroupFM>;

	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroup: Nullable<UserGroupFM>;

	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<UserGroupFM>;

	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<UserGroupFM>;

	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<UserGroupFM>;

	static fromCompanyViewModel(company: CompanyViewModel): SpecialEmailsForm {
		const { notification } = company;
		return {
			enableScheduleBoardEmail: notification.enableScheduleBoardEmail,
			notifyScheduleBoardEmail: TimeOptionUtils.toString(notification.notifyScheduleBoardEmail),
			scheduleBoardEmailUserGroup: notification.scheduleBoardEmailUserGroup,
			enablePastWorkOrdersNotification: notification.enablePastWorkOrdersNotification,
			pastWorkOrdersNotificationUserGroup: notification.pastWorkOrdersNotificationUserGroup,
			enableNotifyYesterdaysBoard: notification.enableNotifyYesterdaysBoard,
			notifyYesterdaysBoardAt: TimeOptionUtils.toString(notification.notifyYesterdaysBoardAt),
			notifyYesterdaysBoardUserGroup: notification.notifyYesterdaysBoardUserGroup,
			enableNotifyFailed: notification.enableNotifyFailed,
			notifyFailedAt: TimeOptionUtils.toString(notification.notifyFailedAt),
			notifyFailedUserGroup: notification.notifyFailedUserGroup,
			enableUnfinishedPurchaseOrderCreatedNotification: notification.enableUnfinishedPurchaseOrderCreatedNotification,
			enablePurchaseOrderWithUnreceivedItemsNotification: notification.enablePurchaseOrderWithUnreceivedItemsNotification,
			isSendingInvoiceNotificationsEnabledForCompany: company.isSendingInvoiceNotificationsEnabled,
			enableInvoiceRemindersNotification: notification.enableInvoiceRemindersNotification,
			notifyInvoiceRemindersAt: TimeOptionUtils.toString(notification.notifyInvoiceRemindersAt),
			notifyInvoiceRemindersFrom: notification.notifyInvoiceRemindersFrom,
			notifyInvoiceRemindersUserGroup: notification.notifyInvoiceRemindersUserGroup,
			notifyOnOrderCreation: notification.notifyOnOrderCreation,
			notifyOnOrderCreationUserGroup: notification.notifyOnOrderCreationUserGroup,
			notifyForUnfinishedOrders: notification.notifyForUnfinishedOrders,
			notifyForUnfinishedOrdersUserGroup: notification.notifyForUnfinishedOrdersUserGroup,
			enableItemLowQuantityNotification: notification.enableItemLowQuantityNotification,
			itemLowQuantityNotificationUserGroup: notification.itemLowQuantityNotificationUserGroup,
			enableDailyItemLowQuantityNotification: notification.enableDailyItemLowQuantityNotification,
			dailyItemLowQuantityNotificationUserGroup: notification.dailyItemLowQuantityNotificationUserGroup,
			enableToolRepairNotification: notification.enableToolRepairNotification,
			toolRepairNotificationUserGroup: notification.toolRepairNotificationUserGroup,
			notifyForOrdersTotalExpenseDaily: notification.notifyForOrdersTotalExpenseDaily,
			notifyForOrdersTotalExpenseDailyUserGroup: notification.notifyForOrdersTotalExpenseDailyUserGroup,
			notifyForOrdersTotalExpenseWeekly: notification.notifyForOrdersTotalExpenseWeekly,
			notifyForOrdersTotalExpenseWeeklyUserGroup: notification.notifyForOrdersTotalExpenseWeeklyUserGroup,
			notifyForOrdersTotalExpenseMonthly: notification.notifyForOrdersTotalExpenseMonthly,
			notifyForOrdersTotalExpenseMonthlyUserGroup: notification.notifyForOrdersTotalExpenseMonthlyUserGroup,
			notifyForDuplicateOrdersMonthly: notification.notifyForDuplicateOrdersMonthly,
			notifyForDuplicateOrdersMonthlyUserGroup: notification.notifyForDuplicateOrdersMonthlyUserGroup,
			notifyForInexpensiveOrdersWeekly: notification.notifyForInexpensiveOrdersWeekly,
			notifyForInexpensiveOrdersWeeklyUserGroup: notification.notifyForInexpensiveOrdersWeeklyUserGroup,
			notifyForInexpensiveOrdersMonthly: notification.notifyForInexpensiveOrdersMonthly,
			notifyForInexpensiveOrdersMonthlyUserGroup: notification.notifyForInexpensiveOrdersMonthlyUserGroup,
			notifyForExpensiveOrdersWeekly: notification.notifyForExpensiveOrdersWeekly,
			notifyForExpensiveOrdersWeeklyUserGroup: notification.notifyForExpensiveOrdersWeeklyUserGroup,
			notifyForExpensiveOrdersMonthly: notification.notifyForExpensiveOrdersMonthly,
			notifyForExpensiveOrdersMonthlyUserGroup: notification.notifyForExpensiveOrdersMonthlyUserGroup,
		};
	}

	static fromForm(form: SpecialEmailsForm): SpecialEmails {
		return {
			enableScheduleBoardEmail: form.enableScheduleBoardEmail,
			notifyScheduleBoardEmail: TimeOptionUtils.toNumber(form.notifyScheduleBoardEmail),
			scheduleBoardEmailUserGroup: form.scheduleBoardEmailUserGroup,
			enablePastWorkOrdersNotification: form.enablePastWorkOrdersNotification,
			pastWorkOrdersNotificationUserGroup: form.pastWorkOrdersNotificationUserGroup,
			enableNotifyYesterdaysBoard: form.enableNotifyYesterdaysBoard,
			notifyYesterdaysBoardAt: TimeOptionUtils.toNumber(form.notifyYesterdaysBoardAt),
			notifyYesterdaysBoardUserGroup: form.notifyYesterdaysBoardUserGroup,
			enableNotifyFailed: form.enableNotifyFailed,
			notifyFailedAt: TimeOptionUtils.toNumber(form.notifyFailedAt),
			notifyFailedUserGroup: form.notifyFailedUserGroup,
			enableUnfinishedPurchaseOrderCreatedNotification: form.enableUnfinishedPurchaseOrderCreatedNotification,
			enablePurchaseOrderWithUnreceivedItemsNotification: form.enablePurchaseOrderWithUnreceivedItemsNotification,
			enableInvoiceRemindersNotification: form.enableInvoiceRemindersNotification,
			notifyInvoiceRemindersAt: TimeOptionUtils.toNumber(form.notifyInvoiceRemindersAt),
			notifyInvoiceRemindersFrom: form.notifyInvoiceRemindersFrom,
			notifyInvoiceRemindersUserGroup: form.notifyInvoiceRemindersUserGroup,
			notifyOnOrderCreation: form.notifyOnOrderCreation,
			notifyOnOrderCreationUserGroup: form.notifyOnOrderCreationUserGroup,
			notifyForUnfinishedOrders: form.notifyForUnfinishedOrders,
			notifyForUnfinishedOrdersUserGroup: form.notifyForUnfinishedOrdersUserGroup,
			enableItemLowQuantityNotification: form.enableItemLowQuantityNotification,
			itemLowQuantityNotificationUserGroup: form.itemLowQuantityNotificationUserGroup,
			enableDailyItemLowQuantityNotification: form.enableDailyItemLowQuantityNotification,
			dailyItemLowQuantityNotificationUserGroup: form.dailyItemLowQuantityNotificationUserGroup,
			enableToolRepairNotification: form.enableToolRepairNotification,
			toolRepairNotificationUserGroup: form.toolRepairNotificationUserGroup,
			notifyForOrdersTotalExpenseDaily: form.notifyForOrdersTotalExpenseDaily,
			notifyForOrdersTotalExpenseDailyUserGroup: form.notifyForOrdersTotalExpenseDailyUserGroup,
			notifyForOrdersTotalExpenseWeekly: form.notifyForOrdersTotalExpenseWeekly,
			notifyForOrdersTotalExpenseWeeklyUserGroup: form.notifyForOrdersTotalExpenseWeeklyUserGroup,
			notifyForOrdersTotalExpenseMonthly: form.notifyForOrdersTotalExpenseMonthly,
			notifyForOrdersTotalExpenseMonthlyUserGroup: form.notifyForOrdersTotalExpenseMonthlyUserGroup,
			notifyForDuplicateOrdersMonthly: form.notifyForDuplicateOrdersMonthly,
			notifyForDuplicateOrdersMonthlyUserGroup: form.notifyForDuplicateOrdersMonthlyUserGroup,
			notifyForInexpensiveOrdersWeekly: form.notifyForInexpensiveOrdersWeekly,
			notifyForInexpensiveOrdersWeeklyUserGroup: form.notifyForInexpensiveOrdersWeeklyUserGroup,
			notifyForInexpensiveOrdersMonthly: form.notifyForInexpensiveOrdersMonthly,
			notifyForInexpensiveOrdersMonthlyUserGroup: form.notifyForInexpensiveOrdersMonthlyUserGroup,
			notifyForExpensiveOrdersWeekly: form.notifyForExpensiveOrdersWeekly,
			notifyForExpensiveOrdersWeeklyUserGroup: form.notifyForExpensiveOrdersWeeklyUserGroup,
			notifyForExpensiveOrdersMonthly: form.notifyForExpensiveOrdersMonthly,
			notifyForExpensiveOrdersMonthlyUserGroup: form.notifyForExpensiveOrdersMonthlyUserGroup,
		};
	}
}

export class AutomaticNotifications {
	notifyOnPublish: Nullable<AutoNotifyOption>;
	notifyOnCancel: Nullable<AutoNotifyOption>;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyUnassignedEmployees: boolean;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	notifyOnTimeSheetReject: boolean;
	notifyOnReportReject: boolean;

	// Only on FE to evaluate checkbox
	enableAutoNotifyOnPublish?: boolean;
	enableAutoNotifyOnCancel?: boolean;

	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;

	static fromCompanyViewModel(company: CompanyViewModel): AutomaticNotifications {
		const { notification } = company;
		return {
			notifyOnPublish: notification.notifyOnPublish ?? AutoNotifyOption.DEFAULT_TIME,
			notifyOnCancel: notification.notifyOnCancel ?? AutoNotifyOption.DEFAULT_TIME,
			notifyOnPause: notification.notifyOnPause,
			notifyOnResume: notification.notifyOnResume,
			notifyUnassignedEmployees: notification.notifyUnassignedEmployees,
			notifyMultipleAssignments: notification.notifyMultipleAssignments,
			notifySupervisors: notification.notifySupervisors,
			enableAutoNotifyOnPublish: !!notification.notifyOnPublish,
			enableAutoNotifyOnCancel: !!notification.notifyOnCancel,
			enableNotifyQAQC: notification.enableNotifyQAQC,
			enableNotifyDeliverableAssignee: notification.enableNotifyDeliverableAssignee,
			enableAutoNotifyDailyTips: notification.enableAutoNotifyDailyTips,
			notifyOnReportReject: notification.notifyOnReportReject,
			notifyOnTimeSheetReject: notification.notifyOnTimeSheetReject,
		};
	}

	static fromForm(form: AutomaticNotifications): AutomaticNotifications {
		return {
			notifyOnPublish: form.enableAutoNotifyOnPublish ? form.notifyOnPublish : null,
			notifyOnCancel: form.enableAutoNotifyOnCancel ? form.notifyOnCancel : null,
			notifyOnPause: form.notifyOnPause,
			notifyOnResume: form.notifyOnResume,
			notifyUnassignedEmployees: form.notifyUnassignedEmployees,
			notifyMultipleAssignments: form.notifyMultipleAssignments,
			notifySupervisors: form.notifySupervisors,
			enableNotifyQAQC: form.enableNotifyQAQC,
			enableNotifyDeliverableAssignee: form.enableNotifyDeliverableAssignee,
			enableAutoNotifyDailyTips: form.enableAutoNotifyDailyTips,
			notifyOnReportReject: form.notifyOnReportReject,
			notifyOnTimeSheetReject: form.notifyOnTimeSheetReject,
		};
	}
}

interface UserGroupVM {
	id: number;
	name: string;
}

export class CompanyFeatureFlagsFM {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	notifyOnPublish: AutoNotifyOption;
	notificationPriority: NotificationPriority;
	notifyOnCancel: AutoNotifyOption;
	notifyUnassignedEmployees: boolean;
	enableAutoNotifyOnPublish: boolean;
	enableAutoNotifyOnCancel: boolean;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyScheduleBoardEmail: string;
	enableScheduleBoardEmail: boolean;
	enablePastWorkOrdersNotification: boolean;
	notifyOnMonday: string;
	notifyOnTuesday: string;
	notifyOnWednesday: string;
	notifyOnThursday: string;
	notifyOnFriday: string;
	notifyOnSaturday: string;
	notifyOnSunday: string;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	scheduleBoardEmailUserGroup: Nullable<UserGroupVM>;
	pastWorkOrdersNotificationUserGroup: Nullable<UserGroupVM>;
	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroup: Nullable<UserGroupVM>;
	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroup: Nullable<UserGroupFM>;
	notifyYesterdaysBoardAt: string;
	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardUserGroup: Nullable<UserGroupVM>;
	notifyFailedAt: string;
	enableNotifyFailed: boolean;
	notifyFailedUserGroup: Nullable<UserGroupVM>;
	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;
	notifyOnReportReject: boolean;
	notifyOnTimeSheetReject: boolean;
	notifyTemporaryLabor: boolean;
	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: string;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroup: Nullable<UserGroupVM>;
	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroup: Nullable<UserGroupVM>;
	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroup: Nullable<UserGroupVM>;
	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<UserGroupVM>;
	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<UserGroupVM>;
	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<UserGroupVM>;

	constructor(company: CompanyViewModel) {
		const { notification } = company;
		this.isEnabled = notification.isEnabled || false;
		this.isEnabledAutoNotificationsOnWorkDays = notification.isEnabledAutoNotificationsOnWorkDays;
		this.notifyUnassignedEmployees = notification.notifyUnassignedEmployees;
		this.notifyScheduleBoardEmail = TimeOptionUtils.toString(notification.notifyScheduleBoardEmail);
		this.notifyOnMonday = TimeOptionUtils.toString(notification.notifyOnDay[1]);
		this.notifyOnTuesday = TimeOptionUtils.toString(notification.notifyOnDay[2]);
		this.notifyOnWednesday = TimeOptionUtils.toString(notification.notifyOnDay[3]);
		this.notifyOnThursday = TimeOptionUtils.toString(notification.notifyOnDay[4]);
		this.notifyOnFriday = TimeOptionUtils.toString(notification.notifyOnDay[5]);
		this.notifyOnSaturday = TimeOptionUtils.toString(notification.notifyOnDay[6]);
		this.notifyOnSunday = TimeOptionUtils.toString(notification.notifyOnDay[0]);
		this.enableScheduleBoardEmail = this.isEnabled && !!notification.enableScheduleBoardEmail;
		this.enablePastWorkOrdersNotification = this.isEnabled && !!notification.enablePastWorkOrdersNotification;
		this.enableAutoNotifyOnPublish = this.isEnabled && !!notification.notifyOnPublish;
		this.enableAutoNotifyOnCancel = this.isEnabled && !!notification.notifyOnCancel;
		this.notificationPriority = notification.notificationPriority || NotificationPriority.SMS_FIRST;
		this.notifyOnPublish = notification.notifyOnPublish ?? AutoNotifyOption.DEFAULT_TIME;
		this.notifyOnCancel = notification.notifyOnCancel ?? AutoNotifyOption.DEFAULT_TIME;
		this.notifyOnPause = notification.notifyOnPause;
		this.notifyOnResume = notification.notifyOnResume;
		this.notifyMultipleAssignments = notification.notifyMultipleAssignments;
		this.notifySupervisors = notification.notifySupervisors;
		this.scheduleBoardEmailUserGroup = notification.scheduleBoardEmailUserGroup;
		this.pastWorkOrdersNotificationUserGroup = notification.pastWorkOrdersNotificationUserGroup;
		this.notifyYesterdaysBoardAt = TimeOptionUtils.toString(notification.notifyYesterdaysBoardAt);
		this.enableNotifyYesterdaysBoard = notification.enableNotifyYesterdaysBoard;
		this.notifyYesterdaysBoardUserGroup = notification.notifyYesterdaysBoardUserGroup;
		this.notifyFailedAt = TimeOptionUtils.toString(notification.notifyFailedAt);
		this.enableNotifyFailed = notification.enableNotifyFailed;
		this.notifyFailedUserGroup = notification.notifyFailedUserGroup;
		this.enableNotifyQAQC = notification.enableNotifyQAQC;
		this.enableNotifyDeliverableAssignee = notification.enableNotifyDeliverableAssignee;
		this.enableAutoNotifyDailyTips = notification.enableAutoNotifyDailyTips;
		this.notifyOnReportReject = notification.notifyOnReportReject;
		this.notifyOnTimeSheetReject = notification.notifyOnTimeSheetReject;
		this.notifyTemporaryLabor = notification.notifyTemporaryLabor;
		this.enableUnfinishedPurchaseOrderCreatedNotification = notification.enableUnfinishedPurchaseOrderCreatedNotification;
		this.enablePurchaseOrderWithUnreceivedItemsNotification = notification.enablePurchaseOrderWithUnreceivedItemsNotification;
		this.isSendingInvoiceNotificationsEnabled = company.isSendingInvoiceNotificationsEnabled;
		this.enableInvoiceRemindersNotification = notification.enableInvoiceRemindersNotification;
		this.notifyInvoiceRemindersAt = TimeOptionUtils.toString(notification.notifyInvoiceRemindersAt);
		this.notifyInvoiceRemindersFrom = notification.notifyInvoiceRemindersFrom;
		this.notifyInvoiceRemindersUserGroup = notification.notifyInvoiceRemindersUserGroup;
		this.notifyOnOrderCreation = notification.notifyOnOrderCreation;
		this.notifyOnOrderCreationUserGroup = notification.notifyOnOrderCreationUserGroup;
		this.notifyForUnfinishedOrders = notification.notifyForUnfinishedOrders;
		this.notifyForUnfinishedOrdersUserGroup = notification.notifyForUnfinishedOrdersUserGroup;

		this.enableItemLowQuantityNotification = this.isEnabled && !!notification.enableItemLowQuantityNotification;
		this.itemLowQuantityNotificationUserGroup = notification.itemLowQuantityNotificationUserGroup;

		this.enableDailyItemLowQuantityNotification = this.isEnabled && !!notification.enableDailyItemLowQuantityNotification;
		this.dailyItemLowQuantityNotificationUserGroup = notification.dailyItemLowQuantityNotificationUserGroup;
		this.enableToolRepairNotification = this.isEnabled && !!notification.enableToolRepairNotification;
		this.toolRepairNotificationUserGroup = notification.toolRepairNotificationUserGroup;
		this.notifyForOrdersTotalExpenseDaily = notification.notifyForOrdersTotalExpenseDaily;
		this.notifyForOrdersTotalExpenseDailyUserGroup = notification.notifyForOrdersTotalExpenseDailyUserGroup;
		this.notifyForOrdersTotalExpenseWeekly = notification.notifyForOrdersTotalExpenseWeekly;
		this.notifyForOrdersTotalExpenseWeeklyUserGroup = notification.notifyForOrdersTotalExpenseWeeklyUserGroup;
		this.notifyForOrdersTotalExpenseMonthly = notification.notifyForOrdersTotalExpenseMonthly;
		this.notifyForOrdersTotalExpenseMonthlyUserGroup = notification.notifyForOrdersTotalExpenseMonthlyUserGroup;
		this.notifyForDuplicateOrdersMonthly = notification.notifyForDuplicateOrdersMonthly;
		this.notifyForDuplicateOrdersMonthlyUserGroup = notification.notifyForDuplicateOrdersMonthlyUserGroup;
		this.notifyForInexpensiveOrdersWeekly = notification.notifyForInexpensiveOrdersWeekly;
		this.notifyForInexpensiveOrdersWeeklyUserGroup = notification.notifyForInexpensiveOrdersWeeklyUserGroup;
		this.notifyForInexpensiveOrdersMonthly = notification.notifyForInexpensiveOrdersMonthly;
		this.notifyForInexpensiveOrdersMonthlyUserGroup = notification.notifyForInexpensiveOrdersMonthlyUserGroup;
		this.notifyForExpensiveOrdersWeekly = notification.notifyForExpensiveOrdersWeekly;
		this.notifyForExpensiveOrdersWeeklyUserGroup = notification.notifyForExpensiveOrdersWeeklyUserGroup;
		this.notifyForExpensiveOrdersMonthly = notification.notifyForExpensiveOrdersMonthly;
		this.notifyForExpensiveOrdersMonthlyUserGroup = notification.notifyForExpensiveOrdersMonthlyUserGroup;
	}

	static fromCompanyViewModel(company: CompanyViewModel): CompanyFeatureFlagsFM {
		return new CompanyFeatureFlagsFM(company);
	}
}

export class TemporaryLaborNotificationsForm {
	notifyTemporaryLabor: boolean;

	static fromCompanyViewModel(company: CompanyViewModel): TemporaryLaborNotificationsForm {
		const { notification } = company;
		return {
			notifyTemporaryLabor: notification.notifyTemporaryLabor,
		};
	}

	static toRequestModel(form: TemporaryLaborNotificationsForm): TemporaryLaborNotificationsRM {
		return {
			notifyTemporaryLabor: form.notifyTemporaryLabor,
		};
	}
}
