import type RepairStatus from '@acceligentllc/shared/enums/repairStatus';
import type Carrier from '@acceligentllc/shared/enums/orderCarrier';
import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';

import type UpsertRM from 'ab-dtos/requestModels/toolRepair/upsert.requestModel';

import type UpdateVM from 'ab-viewModels/toolRepair/preview.viewModel';

interface LocationFM {
	id: number;
	nickname: string;
}

interface ToolFM {
	id: number;
	serialNumber: string;
	toolType: string;
}

interface WorkRequestFM {
	id: number;
	jobCode: string;
}

interface EquipmentFM {
	id: number;
	code: string;
	specification: Nullable<string>;
	equipmentCostCategoryColor: Nullable<ColorPalette>;
}

interface EmployeeAccountFM {
	id: number;
	accountId: number;
	firstName: string;
	lastName: string;
	uniqueId: string;
}

interface AccountFM {
	id: number;
	fullName: string;
}

interface RepairLocationFM {
	id: number;
	name: string;
}

class OrderItemFM {
	id: number;
	itemId: number;
	itemDepartmentId: Nullable<number>;
	itemName: string;
	departmentId: Nullable<number>;
	quantity: number;
	price: number;
	isInEditMode: boolean;
	vendorNumber: Nullable<string>;
	itemType: Nullable<VendorPackageType>;
	departmentName: Nullable<string>;
	locationName: Nullable<string>;

	constructor(item: UpdateVM['items'][0]) {
		this.id = item.id;
		this.itemId = item.itemId;
		this.itemDepartmentId = item.itemDepartmentId;
		this.itemName = item.itemName;
		this.departmentId = item.departmentId;
		this.quantity = item.quantity;
		this.price = item.price;
		this.isInEditMode = false;
		this.vendorNumber = item.vendorNumber;
		this.itemType = item.itemType;
		this.departmentName = item.departmentName;
		this.locationName = item.locationName;
	}

	private static _mapConstructor(item: UpdateVM['items'][0]) {
		return new OrderItemFM(item);
	}

	static bulkConstructor(items: UpdateVM['items']) {
		return items.map(OrderItemFM._mapConstructor);
	}
}

class FormModel {
	tool: ToolFM;
	status: RepairStatus; // not input
	price: string;
	priceManualOverride: boolean;
	workRequest: Nullable<WorkRequestFM>;
	equipment: Nullable<EquipmentFM>;
	superintendent: Nullable<EmployeeAccountFM>;
	operator: Nullable<EmployeeAccountFM>;
	location: LocationFM;
	reportedIssue: string;
	internalComment: Nullable<string>;
	externalComment: Nullable<string>;
	createdAt: Date; // not input
	receivedAt: Nullable<Date>; // not input
	startedAt: Nullable<Date>; // not input
	completedAt: Nullable<Date>; // not input
	shippedAt: Nullable<Date>; // not input
	shippedBy: Nullable<EmployeeAccountFM>;
	isExternal: boolean;
	returnedAt: Nullable<Date>; // not input
	repairLocation: Nullable<RepairLocationFM>;
	carrier: Nullable<Carrier>;
	trackingNumber: Nullable<string>;
	repairedBy: Nullable<AccountFM>;
	items: OrderItemFM[];

	constructor(vm: UpdateVM) {
		this.tool = vm.tool;
		this.status = vm.status;
		this.price = vm.price;
		this.priceManualOverride = vm.priceManualOverride;
		this.workRequest = vm.workRequest;
		this.equipment = vm.equipment;
		this.superintendent = vm.superintendent;
		this.operator = vm.operator;
		this.location = vm.location;
		this.reportedIssue = vm.reportedIssue;
		this.internalComment = vm.internalComment;
		this.externalComment = vm.externalComment;
		this.createdAt = vm.createdAt;
		this.receivedAt = vm.receivedAt;
		this.startedAt = vm.startedAt;
		this.completedAt = vm.completedAt;
		this.shippedAt = vm.shippedAt;
		this.shippedBy = vm.shippedBy;
		this.isExternal = vm.isExternal;
		this.returnedAt = vm.returnedAt;
		this.repairLocation = vm.repairLocation;
		this.carrier = vm.carrier;
		this.trackingNumber = vm.trackingNumber;
		this.repairedBy = vm.repairedBy;
		this.items = OrderItemFM.bulkConstructor(vm.items);
	}

	static toRequestModel(form: FormModel): UpsertRM {
		if (!form.superintendent) {
			throw new Error('Missing form.superintendent, check validation.');
		}
		if (!form.operator) {
			throw new Error('Missing form.operator, check validation.');
		}

		return {
			toolId: form.tool.id,
			status: form.status,
			price: +form.price,
			priceOverride: form.priceManualOverride,
			workRequestId: form.workRequest?.id ?? null,
			equipmentId: form.equipment?.id ?? null,
			superintendentId: form.superintendent.accountId,
			operatorId: form.operator.accountId,
			locationId: form.location.id,
			reportedIssue: form.reportedIssue,
			internalComment: form.internalComment,
			externalComment: form.externalComment,
			shippedById: form.shippedBy?.accountId ?? null,
			repairLocationId: form.repairLocation?.id ?? null,
			carrier: form.carrier,
			trackingNumber: form.trackingNumber,
			repairedById: form.repairedBy?.id ?? null,
			orderItems: form.items.map((item) => (
				{
					itemId: item.itemId,
					itemDepartmentId: item.itemDepartmentId,
					departmentId: item.departmentId,
					quantity: item.quantity,
				}
			)),
		};
	}
}

export default FormModel;
