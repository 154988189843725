import * as React from 'react';
import { compose } from 'redux';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import type * as TimeSheetEntryUtils from '@acceligentllc/shared/utils/timeSheetEntry';
import { getTimelineEntities } from '@acceligentllc/shared/utils/timeSheetEntry';

import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';
import type TimeCardRejectedTimeSheetVM from 'ab-viewModels/timeSheet/timeCardRejectedTimeSheet.viewModel';

import TimeSheetEntryDetails from './TimeSheetEntryDetails';
import styles from './styles.module.scss';

interface OccupiedSlotModel {
	startTime: string;
	endTime: string;
	workOrderCode?: string;
}

type Gap = {
	type: TimelineEntityType.GAP;
	entry: {
		startTime: string;
		endTime: string;
	};
};

type Entry = {
	type: TimelineEntityType.ENTRY;
	entry: TimeCardEntryVM;
};

interface WorkersDay {
	secondsByWorkType: Record<TimeSheetEntryWorkType, number>;
	secondsTotal: number;
}

type EntriesByDate = {
	date: string;
	entries: TimeCardEntryVM[];
};

interface OwnProps {
	dateWithEntries: EntriesByDate;
	hasTodayEntry: boolean;
	overlaps: Nullable<Record<string, TimeSheetEntryUtils.OverlapMeta>>;
	rejectedTS: TimeCardRejectedTimeSheetVM[];
	today: string;
	timeOffset: number;
	timeZoneInUse: Nullable<string>;
}

type Props = OwnProps;

// Need for styling - gray line between 2 entries that "connects" them
const areEntitiesConnected = (
	e1?: TimeSheetEntryUtils.TimelineEntity<TimeCardEntryVM, OccupiedSlotModel>,
	e2?: TimeSheetEntryUtils.TimelineEntity<TimeCardEntryVM, OccupiedSlotModel>
) => {
	if (!e1 || !e2 || e1.type === TimelineEntityType.GAP || e2.type === TimelineEntityType.GAP) {
		return false;
	}
	return !!e1.entry.endTime && e1.entry.endTime >= e2.entry.startTime;
};

const DayInTimeCard: React.FC<Props> = (props: Props) => {
	const {
		dateWithEntries,
		hasTodayEntry,
		overlaps,
		rejectedTS,
		today,
		timeOffset,
		timeZoneInUse,
	} = props;

	const timelineEntities = React.useMemo(() => getTimelineEntities(dateWithEntries.entries, undefined, true), [dateWithEntries.entries]);

	const hasUnassignedEntry = React.useMemo(() => {
		return dateWithEntries.entries.some((entry) => entry.workOrderCode === null);
	}, [dateWithEntries.entries]);

	let infoForRejectMessage: TimeCardRejectedTimeSheetVM[] = [];

	if (rejectedTS.length) {
		infoForRejectMessage = rejectedTS;
	}

	const isToday = React.useMemo(() => dateWithEntries.date === today, [dateWithEntries.date, today]);

	const dateToDisplay = React.useMemo(() => {
		return isToday ?
			`TODAY, ${dateWithEntries.date}` :
			TimeUtils.formatDate(dateWithEntries.date, TimeFormat.DAY_WITH_DATE, TimeFormat.DATE_ONLY);
	}, [dateWithEntries.date, isToday]);

	const workersDay: WorkersDay = React.useMemo(() => {
		const calculated: WorkersDay = {
			secondsTotal: 0,
			secondsByWorkType: {
				[TimeSheetEntryWorkType.BREAK]: 0,
				[TimeSheetEntryWorkType.JOB]: 0,
				[TimeSheetEntryWorkType.SHOP]: 0,
				[TimeSheetEntryWorkType.TRAVEL]: 0,
			},
		};
		const sumByWorkOrderIdMap: Record<number, Record<TimeSheetEntryWorkType, number>> = {};

		// For time calculation we have to exclude currently active shift
		const dateWithEntriesWithoutActive = dateWithEntries.entries.filter((_entry) => _entry.endTime);

		// Calculate times by work type
		for (const _entry of Object.values(dateWithEntriesWithoutActive)) {
			const seconds = TimeUtils.getDiff(_entry.endTime, _entry.startTime, 'seconds', TimeFormat.ISO_DATETIME);

			// If there is no workOrderId, all entries belong to same workOrder
			const workOrderId = _entry.workOrderId ?? 0;
			if (!sumByWorkOrderIdMap[workOrderId]) {
				sumByWorkOrderIdMap[workOrderId] = {
					[TimeSheetEntryWorkType.BREAK]: 0,
					[TimeSheetEntryWorkType.JOB]: 0,
					[TimeSheetEntryWorkType.SHOP]: 0,
					[TimeSheetEntryWorkType.TRAVEL]: 0,
				};
			}
			sumByWorkOrderIdMap[workOrderId][_entry.workType] += seconds;
		}

		for (const _sums of Object.values(sumByWorkOrderIdMap)) {
			for (const [_workType, _seconds] of Object.entries(_sums)) {
				const roundedValue = TimeUtils.roundTimeDurationToInterval(_seconds / 60, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL) * 60;
				calculated.secondsByWorkType[_workType as TimeSheetEntryWorkType] += roundedValue;
				calculated.secondsTotal += _workType === TimeSheetEntryWorkType.BREAK ? 0 : roundedValue;
			}
		}
		return calculated;
	}, [dateWithEntries.entries]);

	const jobTimeTotal = React.useMemo(() => {
		return TimeUtils.minutesToHoursAndMinutes(Math.trunc(workersDay.secondsByWorkType[TimeSheetEntryWorkType.JOB] / 60));
	}, [workersDay.secondsByWorkType]);
	const shopTimeTotal = React.useMemo(() => {
		return TimeUtils.minutesToHoursAndMinutes(Math.trunc(workersDay.secondsByWorkType[TimeSheetEntryWorkType.SHOP] / 60));
	}, [workersDay.secondsByWorkType]);
	const travelTimeTotal = React.useMemo(() => {
		return TimeUtils.minutesToHoursAndMinutes(Math.trunc(workersDay.secondsByWorkType[TimeSheetEntryWorkType.TRAVEL] / 60));
	}, [workersDay.secondsByWorkType]);
	const breakTimeTotal = React.useMemo(() => {
		return TimeUtils.minutesToHoursAndMinutes(Math.trunc(workersDay.secondsByWorkType[TimeSheetEntryWorkType.BREAK] / 60));
	}, [workersDay.secondsByWorkType]);

	const totalWorkingTimeToDisplay = React.useMemo(() => {
		return TimeUtils.minutesToHoursAndMinutes(Math.trunc(workersDay.secondsTotal / 60));
	}, [workersDay.secondsTotal]);

	return (
		<div className={styles.timeCardDay}>
			<div className={styles.timeCardDate}>
				<div>
					{dateToDisplay.toUpperCase()}
				</div>
				{!!hasUnassignedEntry && <div className={styles.linkText}>
					Assign All
				</div>}
			</div>

			<div className={styles.totalTimeGeneral}>
				<div className={styles.firstRow}>
					<div>
						Total
					</div>
					<div className={styles.time}>
						{totalWorkingTimeToDisplay}
					</div>
				</div>
				{
					!!workersDay.secondsTotal && <div className={styles.secondRow}>
						<div className={styles.iconAndTime}>
							<span className="icon-equipment" />
							{jobTimeTotal}
						</div>
						<div className={styles.iconAndTime}>
							<span className="icon-home" />
							{shopTimeTotal}
						</div>
						<div className={styles.iconAndTime}>
							<span className="icon-travel" />
							{travelTimeTotal}
						</div>
						<div className={styles.iconAndTime}>
							<span className="icon-break" />
							{breakTimeTotal}
						</div>
					</div>
				}
			</div>

			{!!infoForRejectMessage.length && infoForRejectMessage.map((_message) => {
				return (
					<React.Fragment key={_message.workOrderCode}>
						<div className={styles.timeCardEntriesAlert}>
							Time Sheet for <b>{_message.workOrderCode}</b> of <b>{TimeUtils.formatDate(
								_message.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY
							)}</b>
							{' '}has been rejected by <b>{_message.rejectedByFullName ?? 'ACS'}</b>. Reason: <b>{_message.rejectReason}</b>
						</div>
					</React.Fragment>
				);
			})}

			{/* IF THERE IS NO ENTRY FOR TODAY'S DATE, SHOW EMPTY TSE INFO */}
			{!hasTodayEntry && isToday &&
				<div className={styles.emptyEntryRow}>
					There is no logged time for today.
				</div>
			}

			{
				timelineEntities.map((_tse: Entry | Gap, index: number) => {
					const hasPredecessor = areEntitiesConnected(timelineEntities[index + 1], _tse);
					const hasSuccessor = areEntitiesConnected(_tse, timelineEntities[index - 1]);

					return (
						<React.Fragment key={`${_tse.type}-${_tse.entry.startTime}-${_tse.entry.endTime}`}>
							{/* Gaps have to be shown in time card as a warning*/}
							{_tse.type === TimelineEntityType.GAP &&
								<div className={styles.gapRow}>
									<span className="icon-info" />
									A gap in your timeline
								</div>
							}

							{/* Entry */}
							{_tse.type === TimelineEntityType.ENTRY &&
								<TimeSheetEntryDetails
									entry={_tse.entry}
									hasPredecessor={hasPredecessor}
									hasSuccessor={hasSuccessor}
									overlaps={overlaps}
									timeOffset={timeOffset}
									timeZoneInUse={timeZoneInUse}
								/>
							}
						</React.Fragment>
					);
				})
			}
		</div>
	);
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo
);
export default enhance(DayInTimeCard);
