import * as React from 'react';

import type { SignatureFieldVM } from '@acceligentllc/shared/utils/fieldReport';
import { formatDate } from '@acceligentllc/shared/utils/time';

import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';

import Image from 'af-components/Image';

import SignatureStatusLabel from '../../../../../Shared/SignatureStatusLabel';

interface OwnProps {
	value: Nullable<SignatureFieldVM>;
	name: string;
}

type Props = React.PropsWithChildren<OwnProps>;

const SignatureStatus: React.FC<Props> = (props) => {
	const { value, name, children } = props;

	return (
		<>
			{!!value
				? <>
					{name}
					<div className="report-block__signature-field">
						<div>
							<SignatureStatusLabel className="report-block__signature-field__signature-label" status={TimeSheetSignatureStatus.SIGNED} />
							<div className="report-block__signature-field__signature-name">
								by {value.name} {formatDate(value.signedAt, TimeFormatEnum.FULL_DATE)}
							</div>
						</div>
						<Image className="report-block__signature-field__signature-image" src={value.imageUrl} />
						{children}
					</div>
				</>
				: <div>N/A</div>
			}
		</>
	);
};

export default React.memo(SignatureStatus);
