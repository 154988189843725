import type { Dispatch, AnyAction } from 'redux';
import { saveAs } from 'file-saver';
import type { Moment } from 'moment';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import type WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';
import WorkOrderPositionOption from '@acceligentllc/shared/enums/workOrderPosition';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeOptionUtils from '@acceligentllc/shared/utils/timeOption';

import CLIENT from 'af-constants/routes/client';
import API from 'af-constants/routes/api';
import { WORK_ORDER_FORM, DOWNLOAD_ORDER_CONFIRMATION, ORDER_COPY_FORM, ORDER_LIST_FORM, ORDER_CANCEL_FORM, SIGNATURE_FIELD_REPORT } from 'af-constants/reduxForms';

import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';
import { awaitableTimeout, pollAPI } from 'af-utils/timeout.util';

import type { Differences } from 'ab-utils/workOrder.util';

import * as workOrderActionCreators from 'af-actions/workOrder/workOrder.actionCreators';

import type { GetRootState } from 'af-reducers';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type FinalizeReviewRM from 'ab-requestModels/workOrder/finalizeReview.requestModel';
import type { WorkOrderNoteForm, WorkOrderCancelForm, WorkOrderPauseForm, WorkOrderForm } from 'ab-requestModels/workOrder.requestModel';
import type CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';
import type CreateCustomerSignatureForPublicReportsRM from 'ab-requestModels/workOrder/createCustomerSignatureForPublicReports.requestModel';
import type WorkOrderFindOrCreateCompleteReportLookupBulkGroupCodeRM from 'ab-requestModels/workOrder/findOrCreateCompleteReportLookupBulkGroupCode.requestModel';
import type WorkOrderSendCustomerSignatureRequestForReportsRM from 'ab-requestModels/workOrder/sendCustomerSignatureRequestForReports.requestModel';
import type WorkOrderSendReportsForCustomerRM from 'ab-requestModels/workOrder/sendReportsForCustomer.requestModel';
import type SubmitWorkOrderForReviewRM from 'ab-requestModels/workOrder/submitForReview.requestModel';

import type CanCompleteWorkSummariesVM from 'ab-viewModels/workOrder/canCompleteWorkSummaries.viewModel';
import type WorkOrderReviewRecapVM from 'ab-viewModels/workOrder/workOrderReviewRecap.viewModel';
import type WorkOrderIdAndDueDate from 'ab-viewModels/workOrderIdAndDueDate.viewModel';
import type { WorkOrderViewModel } from 'ab-viewModels/workOrder.viewModel';
import type { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import type WorkOrderUpsertVM from 'ab-viewModels/workOrder/workOrderUpsert.viewModel';
import type WorkOrderConfirmationViewModel from 'ab-viewModels/workOrderConfirmation.viewModel';
import type { WorkDaysVM } from 'ab-viewModels/workOrder/copyWorkOrders.viewModel';
import WorkOrderCopyVM from 'ab-viewModels/workOrder/copyWorkOrders.viewModel';
import type WorkOrderFieldReportCardVM from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';
import type WorkOrderPublicLinkVM from 'ab-viewModels/workOrderEmployeePublicLinkViewModel';
import type PublicReportConfirmationVM from 'ab-viewModels/report/publicConfirmation.viewModel';
import type ReportsCSVDataVM from 'ab-viewModels/report/csvData.viewModel';
import type WorkOrderDirectoryVM from 'ab-viewModels/directory/workOrderDirectory.viewModel';
import type DownloadFieldReportPDF from 'ab-viewModels/workOrder/downloadFieldReportPDF.viewModel';
import type AssociatedSubjobVM from 'ab-viewModels/workRequest/associatedSubjob.viewModel';

import type { NotificationSnackbarContextType } from 'af-root/context/notificationSnackbarContext';
import type { TaskRecoveryContextType } from 'af-root/context/taskRecoveryContext';

import * as CopyAPI from 'ab-api/web/workOrder/copy';
import * as CreateAPI from 'ab-api/web/workOrder/create';
import * as DeleteAPI from 'ab-api/web/workOrder/deleteWorkOrder';
import * as PublicConfirmationAPI from 'ab-api/web/workOrder/findPublicConfirmation';
import * as PublicConfirmationDownloadAPI from 'ab-api/web/workOrder/downloadPublicConfirmation';
import * as PrivateConfirmationDownloadAPI from 'ab-api/web/workOrder/downloadPrivateConfirmation';
import * as PublishAPI from 'ab-api/web/workOrder/publish';
import * as PublishBulkAPI from 'ab-api/web/workOrder/publishBulk';
import * as CreateDirectoryAPI from 'ab-api/web/workOrder/createDirectory';
import * as UpdateAPI from 'ab-api/web/workOrder/update';
import * as DeleteDirectoryAPI from 'ab-api/web/workOrder/deleteWorkOrderDirectory';
import * as FindAllRevisionsForWorkOrderAPI from 'ab-api/web/workOrder/findAllRevisionsForWorkOrder';
import * as EditWorkSummaryStatusAPI from 'ab-api/web/fieldReport/editWorkSummaryStatus';
import * as GetWorkSummaryStatusAPI from 'ab-api/web/fieldReport/getWorkSummaryStatus';

import * as ApproveReviewAPI from 'ab-api/web/workOrderReport/approveReview';
import * as RejectReviewAPI from 'ab-api/web/workOrderReport/rejectReview';
import * as DownloadPublicReportAPI from 'ab-api/web/workOrderReport/downloadPublicReport';
import * as ClearPDFGenerationProcessAPI from 'ab-api/web/workOrderReport/clearPDFGenerationProcess';

function _workOrderViewModelToForm(workOrder: WorkOrderViewModel): Nullable<WorkOrderForm> {
	if (!workOrder) {
		return null;
	}

	return {
		...workOrder,
		timeToStart: TimeOptionUtils.toString(workOrder.timeToStart),
		timeToEnd: TimeOptionUtils.toString(workOrder.timeToEnd),
		index: workOrder.index,
		position: WorkOrderPositionOption.DEFAULT,
	};
}

export function findWorkOrderById(workOrderId: number) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const order = await http.get<WorkOrderUpsertVM>(API.V1.WORK_ORDER.FIND_BY_ID(workOrderId));
			dispatch(workOrderActionCreators.FIND_WORK_ORDER(order));
			return order;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllWorkOrdersWithNotificationStatusesByDueDate(dueDate: string) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			// clear redux state before fetching new orders
			dispatch(workOrderActionCreators.FIND_ALL_WORK_ORDERS(null));
			const result = await http.get<WorkOrderViewModel[]>(API.V1.WORK_ORDER.LIST(dueDate, ''));
			const data = result.map(_workOrderViewModelToForm);
			dispatch(workOrderActionCreators.FIND_ALL_WORK_ORDERS(data));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrdersByDueDate(dueDate: string, query: string, selectedDate?: Moment) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			// clear redux state before fetching new orders
			dispatch(workOrderActionCreators.FIND_ALL_WORK_ORDERS(null, selectedDate, query));
			const result = await http.get<WorkOrderViewModel[]>(API.V1.WORK_ORDER.LIST(dueDate, query));
			const data = result.map(_workOrderViewModelToForm);
			dispatch(workOrderActionCreators.FIND_ALL_WORK_ORDERS(data, selectedDate, query));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkOrdersByTemporaryEmployeeId(temporaryEmployeeId: number, tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const tableData = new TableQuery(tableRequestModel);
			return await http.get<TableResult<WorkOrderTableViewModel>>(API.V1.WORK_ORDER.LIST_BY_TEMPORARY_EMPLOYEE_ID(temporaryEmployeeId, tableData));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 * @param dueDate `MM-DD-YYYY`
 */
export function findBlankWorkOrdersByDueDate(dueDate: string) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<number[]>(API.V1.WORK_ORDER.LIST_BLANK(dueDate));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editWorkOrderNote(workOrderId: number, noteForm: WorkOrderNoteForm) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.WORK_ORDER.NOTE(workOrderId), noteForm, { submitting: WORK_ORDER_FORM });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function publishWorkOrder(workOrderId: number, delayReason?: string) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: PublishAPI.W_WorkOrder_Publish_RM = { delayReason };
			await http.post<void>(PublishAPI.URL(workOrderId), body, { submitting: WORK_ORDER_FORM });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function publishWorkOrders(workOrderIds: number[], { delayReason }: { delayReason?: string; } = {}) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: PublishBulkAPI.W_WorkOrder_PublishBulk_RM = { delayReason, workOrderIds };
			await http.post<void>(PublishBulkAPI.URL(), body, { submitting: WORK_ORDER_FORM });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function cancelWorkOrder(workOrderId: number, cancelForm: WorkOrderCancelForm) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.WORK_ORDER.CANCEL(workOrderId), cancelForm, { submitting: [ORDER_CANCEL_FORM, WORK_ORDER_FORM, ORDER_LIST_FORM] });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function pauseWorkOrder(workOrderId: number, pauseForm: WorkOrderPauseForm) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.WORK_ORDER.PAUSE(workOrderId), pauseForm, { submitting: [ORDER_CANCEL_FORM, WORK_ORDER_FORM, ORDER_LIST_FORM] });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resumeWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.WORK_ORDER.RESUME(workOrderId), { submitting: [ORDER_CANCEL_FORM, WORK_ORDER_FORM, ORDER_LIST_FORM] });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete<void>(DeleteAPI.URL(+workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function saveWorkOrder() {
	return (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>) => {
		dispatch(workOrderActionCreators.SAVE_WORK_ORDER());
	};
}

export function create(form: CreateAPI.W_WorkOrder_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(CreateAPI.URL(), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(workOrderId: number, form: UpdateAPI.W_WorkOrder_Update_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put<void>(UpdateAPI.URL(workOrderId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function lockWorkOrder(data: WorkOrderIdAndDueDate) {
	return (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>) => {
		dispatch(workOrderActionCreators.LOCK_WORK_ORDER(data));
	};
}

export function unlockWorkOrder(data: WorkOrderIdAndDueDate) {
	return (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>) => {
		dispatch(workOrderActionCreators.UNLOCK_WORK_ORDER(data));
	};
}

// Copy actions:

export function copyWorkOrder(form: CopyAPI.W_WorkOrder_Copy_RM) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<CopyAPI.W_WorkOrder_Copy_VM>(CopyAPI.URL(), form, { submitting: ORDER_COPY_FORM });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reinitializeCopyForm(
	startDate: string,
	copyDate: string,
	workingWeekDays: WorkDaysVM = {},
	validOrders: string[],
	finishedOrders: string[],
	cancelledOrders: string[],
	selectedOrdersIds?: number[],
	code?: string) {
	return (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>) => {
		const modalData = new WorkOrderCopyVM(startDate, copyDate, workingWeekDays, validOrders, finishedOrders, cancelledOrders, selectedOrdersIds, code);
		dispatch(workOrderActionCreators.SET_COPY_WORK_ORDER_MODAL_DATA(modalData));
	};
}

// Confirmation Pdf and Notifications:

export function findForConfirmationByPublicLink(publicLink: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<PublicConfirmationAPI.W_WorkOrder_FindPublicConfirmation_VM>(PublicConfirmationAPI.URL(publicLink));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForConfirmationById(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkOrderConfirmationViewModel>(API.V1.WORK_ORDER.CONFIRMATION(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function generateWorkOrderConfirmation(workOrderId: number | string, orgAlias: string, companyName: string, originUrl?: string) {
	return async (dispatch: Dispatch<workOrderActionCreators.WorkOrderAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			redirectTo(CLIENT.COMPANY.WORK_ORDERS.CONFIRMATION(workOrderId as string, orgAlias, companyName), { originUrl });
			// redirect before other actions for the loading component to render

			// await http.put(API.V1.WORK_ORDER.CONFIRMATION_FILE(workOrderId), form, { submitting: WORK_ORDER_FORM });
			// This code is no longer needed because the download route generates the pdf
			// if it doesn't exist, however leave it here in case of workflow changes.

			// dispatch(workOrderActionCreators.FIND_WORK_ORDER(form));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadWorkOrderConfirmationPdf(workOrderId: number | string, filename: string, snackbar: NotificationSnackbarContextType) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const progressMessage = snackbar.loading('Fetching file state.', new Date());

		const action = async () => {
			const data = await http.get<PrivateConfirmationDownloadAPI.W_WorkOrder_DownloadPrivateConfirmation_VM>(
				PrivateConfirmationDownloadAPI.URL(+workOrderId),
				{ submitting: DOWNLOAD_ORDER_CONFIRMATION }
			);
			if (!data.url) {
				// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
				await awaitableTimeout(action, 2000);
			} else {
				snackbar.removeNotificationSnackbar(progressMessage);
				const downloadStartMessage = snackbar.loading(`Downloading ${filename}`);
				const fetchedFile = await fetch(data.url);
				const blob = await fetchedFile?.blob();
				saveAs(blob, `${filename}.pdf`);
				snackbar.success(`Done downloading ${filename}`);
				snackbar.removeNotificationSnackbar(downloadStartMessage);
				return;
			}
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadWorkOrderConfirmationPdfPublic(publicLink: string, filename: string, snackbar: NotificationSnackbarContextType) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const progressMessage = snackbar.loading('Fetching file state.', new Date());
		const action = async () => {

			const data = await http.get<PublicConfirmationDownloadAPI.W_WorkOrder_DownloadPublicConfirmation_VM>(PublicConfirmationDownloadAPI.URL(publicLink));
			if (!data.url) {
				// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
				await awaitableTimeout(action, 2000);
			} else {
				snackbar.removeNotificationSnackbar(progressMessage);
				const downloadStartMessage = snackbar.loading(`Downloading ${filename}`);
				const fetchedFile = await fetch(data.url);
				const blob = await fetchedFile?.blob();
				saveAs(blob, `${filename}.pdf`);
				snackbar.success(`Done downloading ${filename}`);
				snackbar.removeNotificationSnackbar(downloadStartMessage);
				return;
			}
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkOrderConfirmationData(workOrderId: number | string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const response = await http.get<any>(API.V1.WORK_ORDER.CONFIRMATION_FILE(+workOrderId));
			dispatch(workOrderActionCreators.GET_WORK_ORDER_CONFIRMATION_DATA(response.data || []));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function clearWorkOrderParticipantsEmailStatus() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			dispatch(workOrderActionCreators.CLEAR_WORK_ORDER_PARTICIPANTS_EMAIL_STATUS());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function redirectParticipantToWorkOrderConfirmationPublic(personalPublicLink: string, orgAlias: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.get<WorkOrderPublicLinkVM>(API.V1.WORK_ORDER_EMPLOYEE.PUBLIC.WORK_ORDER_LINK(personalPublicLink));
			if (response) {	// just in case get didn't throw 404
				await http.put(API.V1.WORK_ORDER_EMPLOYEE.PUBLIC.HAS_OPENED_PERSONAL_LINK(personalPublicLink));

				const dueDate = response.dueDate
					? TimeUtils.formatDate(response.dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
					: null;

				if (response.publicLink) {
					// if there is public link user still exists on work order
					redirectTo(CLIENT.FEEDBACK.WORK_ORDER(response.publicLink, orgAlias));
				} else if (response.workOrderCode && dueDate) {
					redirectTo(CLIENT.FEEDBACK.WORK_ORDER_PERSONAL_UNASSIGNED(orgAlias, response.workOrderCode, dueDate));
				}
			} else {
				redirectTo(CLIENT.ERROR.ERR404(orgAlias));
			}
		};

		const errorOverride: ErrorOverride = {
			err404: (error) => {
				if (error.response.data) {
					const { code, dueDate: _dueDate } = error.response.data;
					const dueDate = _dueDate
						? TimeUtils.formatDate(_dueDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY)
						: undefined;
					redirectTo(CLIENT.FEEDBACK.WORK_ORDER_PERSONAL_UNASSIGNED(orgAlias, code, dueDate));
				} else {
					redirectTo(CLIENT.ERROR.ERR404(orgAlias));
				}
			},
		};
		return await errorHandler(action, dispatch, redirectTo, errorOverride);
	};
}

// Resets and clears:

export function resetWorkOrdersTable() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			dispatch(workOrderActionCreators.CLEAR_WORK_ORDER_LIST());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resetWorkOrder() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			dispatch(workOrderActionCreators.RESET_WORK_ORDER());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

/** FIXME: this is getting called every time WO modal is opened. Call it only after it is requested */
export function getNotificationTemplate(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<string>(API.V1.WORK_ORDER.GET_NOTIFICATION_TEMPLATE(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function lazyLoadRevisions(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<FindAllRevisionsForWorkOrderAPI.W_WorkOrder_FindAllRevisionsForWorkOrder_VM[]>(
				FindAllRevisionsForWorkOrderAPI.URL(workOrderId)
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

// Field Report Actions
// TODO: create Report actions and move actions below there

export function findWorkOrderFieldReportCardById(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkOrderFieldReportCardVM>(API.V1.WORK_ORDER.FIND_CARD_FOR_FIELD_REPORT_BY_ID(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findReportsCSVData(workOrderIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ReportsCSVDataVM>(API.V1.WORK_ORDER.REPORTS_CSV_DATA(workOrderIds));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findOrCreateCompleteGroupCode(workOrderIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data: WorkOrderFindOrCreateCompleteReportLookupBulkGroupCodeRM = { workOrderIds };
			return await http.post<string>(API.V1.WORK_ORDER.FIND_OR_CREATE_COMPLETE_GROUP_CODE, data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findOrCreatePublicGroupCode(workOrderIds: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data: WorkOrderFindOrCreateCompleteReportLookupBulkGroupCodeRM = { workOrderIds };
			return await http.post<string>(API.V1.WORK_ORDER.FIND_OR_CREATE_PUBLIC_GROUP_CODE, data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

const _showResourceGenerationFailedSnackbars = (snackbar: NotificationSnackbarContextType, errors: string[]) => {
	errors.forEach((error) => {
		snackbar.error(`[${error}] export failed to generate.`);
	});
};

const _showResourceGenerationRetryingSnackbars = (snackbar: NotificationSnackbarContextType, errors: string[]) => {
	errors.forEach((error) => {
		const errorSnackbar = snackbar.error(`There was an error generating [${error}]. Retrying…`);
		setTimeout(() => {
			snackbar.removeNotificationSnackbar(errorSnackbar);
		}, 2000);
	});
};

const _showFinalGenerationFailedSnackbars = (snackbar: NotificationSnackbarContextType, errors: string[]) => {
	errors.forEach(() => {
		snackbar.error('Final PDF failed to generate.');
	});
};

const _showFinalGenerationRetryingSnackbars = (snackbar: NotificationSnackbarContextType, errors: string[]) => {
	errors.forEach(() => {
		const errorSnackbar = snackbar.error('There was an error generating complete PDF. Retrying…');
		setTimeout(() => {
			snackbar.removeNotificationSnackbar(errorSnackbar);
		}, 2000);
	});
};

const _handleGeneratePDFRecoveryWithSnackbar = (
	data: DownloadFieldReportPDF,
	groupName: string,
	snackbar: NotificationSnackbarContextType,
	taskRecovery: TaskRecoveryContextType,
	allowedAttempts: number
): boolean => {

	// Check WO errors
	if (!data.resourceErrors?.length) {
		return false;
	}
	taskRecovery.addErrors(groupName, data.resourceErrors);
	const tasksThatCancelTheAction = taskRecovery.getTasksAboveThreshold(groupName, allowedAttempts);

	if (tasksThatCancelTheAction.length) {
		_showResourceGenerationFailedSnackbars(snackbar, tasksThatCancelTheAction.map((error) => error.task));
		return true;
	}
	_showResourceGenerationRetryingSnackbars(snackbar, data.resourceErrors ?? []);

	// Check finalizing actions errors
	if (!data.error?.length) {
		return false;
	}
	taskRecovery.addErrors(groupName, data.error);
	const finalizingTasksThatCancelTheAction = taskRecovery.getTasksAboveThreshold(groupName, allowedAttempts);

	if (finalizingTasksThatCancelTheAction.length) {
		_showFinalGenerationFailedSnackbars(snackbar, finalizingTasksThatCancelTheAction.map((error) => error.task));
		return true;
	}

	_showFinalGenerationRetryingSnackbars(snackbar, data.error ?? []);

	return false;
};

export function downloadCompleteReportPDF(
	groupCode: string,
	filename: string,
	snackbar: NotificationSnackbarContextType,
	taskRecovery: TaskRecoveryContextType,
	allowedAttempts: number
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const progressMessage = snackbar.loading('Fetching file state.', new Date());
		const action = async () => {
			const data = await http.get<DownloadFieldReportPDF>(API.V1.WORK_ORDER.DOWNLOAD_COMPLETE_REPORT(groupCode));
			if (!data.url) {
				// Message received that we're still generating the report. We will ping in 2 seconds to check again
				// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
				const newMessage = !!data.merging ? 'Finalizing' : `Generating reports. ${data.done ?? 0} / ${data.total} done.`;
				snackbar.updateContent(progressMessage, newMessage);
				const shouldStopTask = _handleGeneratePDFRecoveryWithSnackbar(data, groupCode, snackbar, taskRecovery, allowedAttempts);
				if (shouldStopTask) {
					snackbar.removeNotificationSnackbar(progressMessage);
					taskRecovery.clearGroupErrors(groupCode);
					await http.delete<void>(ClearPDFGenerationProcessAPI.URL(groupCode));
					return;
				}
				await awaitableTimeout(action, 2000);
			} else {
				snackbar.removeNotificationSnackbar(progressMessage);
				const downloadStartMessage = snackbar.loading(`Downloading ${filename}`);
				const fetchedFile = await fetch(data.url);
				const blob = await fetchedFile?.blob();
				saveAs(blob, `${filename}.pdf`);
				snackbar.success(`Done downloading ${filename}`);
				snackbar.removeNotificationSnackbar(downloadStartMessage);
				taskRecovery.clearGroupErrors(groupCode);
				return;
			}
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadPublicReportPDF(
	groupCode: string,
	filename: string,
	snackbar: NotificationSnackbarContextType,
	taskRecovery: TaskRecoveryContextType,
	allowedAttempts: number
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const progressMessage = snackbar.loading('Fetching file state.', new Date());
		const action = async () => {
			const data = await http.get<DownloadPublicReportAPI.W_WorkOrderReport_DownloadPublicReport_VM>(DownloadPublicReportAPI.URL(groupCode));
			if (!data.url) {
				// Message received that we're still generating the report. We will ping in 2 seconds to check again
				// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
				const newMessage = !!data.merging ? 'Finalizing' : `Generating reports. ${data.done ?? 0} / ${data.total} done.`;
				snackbar.updateContent(progressMessage, newMessage);
				const shouldStopTask = _handleGeneratePDFRecoveryWithSnackbar(data, groupCode, snackbar, taskRecovery, allowedAttempts);
				if (shouldStopTask) {
					snackbar.removeNotificationSnackbar(progressMessage);
					taskRecovery.clearGroupErrors(groupCode);
					await http.delete<void>(ClearPDFGenerationProcessAPI.URL(groupCode));
					return;
				}
				await awaitableTimeout(action, 2000);
			} else {
				snackbar.removeNotificationSnackbar(progressMessage);
				const downloadStartMessage = snackbar.loading(`Downloading ${filename}`);
				const fetchedFile = await fetch(data.url);
				const blob = await fetchedFile?.blob();
				saveAs(blob, `${filename}.pdf`);
				snackbar.success(`Done downloading ${filename}`);
				snackbar.removeNotificationSnackbar(downloadStartMessage);
				taskRecovery.clearGroupErrors(groupCode);
				return;
			}
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function sendCustomerSignatureRequestForReports(data: WorkOrderSendCustomerSignatureRequestForReportsRM, submitting?: string | string[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<string>(API.V1.WORK_ORDER.SEND_CUSTOMER_SIGNATURE_REQUEST, { ...data }, { submitting });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function sendReportsForCustomer(data: WorkOrderSendReportsForCustomerRM, submitting?: string | string[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<string>(API.V1.WORK_ORDER.SEND_REPORTS_FOR_CUSTOMER, { ...data }, { submitting });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findPublicReports(orgAlias: string, companyName: string, publicLink: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<PublicReportConfirmationVM>(API.V1.WORK_ORDER.PUBLIC.REPORT(orgAlias, companyName, publicLink));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

const _shouldStopGeneratingPDF = (
	data: DownloadFieldReportPDF,
	groupName: string,
	taskRecovery: TaskRecoveryContextType,
	allowedAttempts: number
): boolean => {

	if (!data.resourceErrors?.length) {
		return false;
	}

	taskRecovery.addErrors(groupName, data.resourceErrors);
	const tasksThatCancelTheAction = taskRecovery.getTasksAboveThreshold(groupName, allowedAttempts);

	if (tasksThatCancelTheAction.length) {
		taskRecovery.clearGroupErrors(groupName);
		return true;
	}

	return false;
};

export function getWebViewUrlWhenPdfIsReady(groupCode: string, taskRecovery: TaskRecoveryContextType, allowedAttempts: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			// Message received that we're still generating the report. We will ping in 2 seconds to check again
			// We're jumping through hoops to make sure the caller of this action doesn't have to worry about retrying mechanism
			return await pollAPI(
				async () => await http.get<DownloadPublicReportAPI.W_WorkOrderReport_DownloadPublicReport_VM>(DownloadPublicReportAPI.URL(groupCode)),
				(data: DownloadPublicReportAPI.W_WorkOrderReport_DownloadPublicReport_VM) =>
					!!data.url || _shouldStopGeneratingPDF(data, groupCode, taskRecovery, allowedAttempts),
				2000,
				async () => await http.delete<void>(ClearPDFGenerationProcessAPI.URL(groupCode))
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findReviewRecap(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<WorkOrderReviewRecapVM>(API.V1.WORK_ORDER.FIELD_REPORT.FIND_REVIEW_RECAP(workOrderId));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function signPublicReportsAsCustomer(orgAlias: string, companyName: string, publicLink: string, data: CreateCustomerSignatureForPublicReportsRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post(API.V1.WORK_ORDER.PUBLIC.REPORT_CUSTOMER_SIGNATURE(orgAlias, companyName, publicLink), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function submitForReview(workOrderId: number, form: SubmitWorkOrderForReviewRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const options = {
				submitting: SIGNATURE_FIELD_REPORT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'image/png',
				},
			};
			await http.post<void>(API.V1.WORK_ORDER.FIELD_REPORT.SUBMIT_FOR_REVIEW(workOrderId), form, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function approveReview(workOrderId: number, form: ApproveReviewAPI.W_WorkOrderReport_ApproveReview_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const options = {
				submitting: SIGNATURE_FIELD_REPORT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'image/png',
				},
			};
			await http.post<void>(ApproveReviewAPI.URL(workOrderId), form, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function rejectReview(workOrderId: number, form: RejectReviewAPI.W_WorkOrderReport_RejectReview_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(RejectReviewAPI.URL(workOrderId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function finalizeReview(workOrderId: number, form: FinalizeReviewRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.WORK_ORDER.FIELD_REPORT.FINALIZE(workOrderId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadCustomerWorkOrderReportPdf(workOrderId: number, workOrderCode: string = '') {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = await http.get(API.V1.WORK_ORDER.DOWNLOAD_CUSTOMER_REPORT(+workOrderId), { responseType: 'blob' });
			saveAs(data, `${workOrderCode}_report.pdf`);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createTemporaryEmployeeAssignment(workOrderId: number, data: CreateTemporaryEmployeeAssignmentRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.WORK_ORDER.CREATE_TEMPORARY_EMPLOYEE_ASSIGNMENT(workOrderId), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeTemporaryEmployeeAssignment(workOrderId: number, workOrderResourceLookupId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.WORK_ORDER.DELETE_TEMPORARY_EMPLOYEE_ASSIGNMENT(workOrderId, workOrderResourceLookupId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkSummaryStatus(fieldReportId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<GetWorkSummaryStatusAPI.W_FieldReport_GetWorkSummaryStatus_VM>(GetWorkSummaryStatusAPI.URL(fieldReportId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editWorkSummaryStatus(fieldReportId: number, workSummaryStatus: WorkSummaryStatus) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const body: EditWorkSummaryStatusAPI.W_FieldReport_EditWorkSummaryStatus_RM = { workSummaryStatus };
			await http.put<void>(EditWorkSummaryStatusAPI.URL(fieldReportId), body);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function checkIfCanCompleteWorkSummaries(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const { canComplete } = await http.get<CanCompleteWorkSummariesVM>(API.V1.WORK_ORDER.CAN_COMPLETE_WORK_SUMMARIES(workOrderId));
			return canComplete;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function fetchOutdatedWorkOrderDifferences(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<Differences>(API.V1.WORK_ORDER.OUTDATED_CHANGES(workOrderId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkOrderDirectoriesAttachments(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const directoriesWithAttachments = await http.get<WorkOrderDirectoryVM[]>(
				API.V1.WORK_ORDER.ATTACHMENTS.DIRECTORIES_ATTACHMENTS(workOrderId)
			);
			return directoriesWithAttachments;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createDirectory(workOrderId: number, parentDirectoryId: number, newDirName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const body: CreateDirectoryAPI.W_WorkOrder_CreateDirectory_RM = { name: newDirName };
			await http.post<void>(CreateDirectoryAPI.URL(workOrderId, parentDirectoryId), body);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteDirectory(workOrderId: number, directoryId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete<void>(DeleteDirectoryAPI.URL(workOrderId, directoryId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function reviewVirtualReports(workOrderId: number, workRequestId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<void>(API.V1.WORK_ORDER.REVIEW_VIRTUAL_REPORTS(workOrderId, workRequestId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAssociatedSubjobs(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<AssociatedSubjobVM[]>(API.V1.WORK_ORDER.FIND_ASSOCIATED_SUBJOBS(workOrderId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
