import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';

interface Props {
	code: string | undefined;
	closeModal: () => void;
	showModal: boolean;
	goBackToWorkOrders: (checkIfFormDirty?: boolean) => void;
	reload: () => void;
	isFromScheduleBoard: boolean;
}

class SessionExpiredModal extends React.PureComponent<Props> {

	goBackToWorkOrders = () => {
		this.props.goBackToWorkOrders(false);
	};

	render() {
		const { showModal, code, closeModal, reload, isFromScheduleBoard } = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="warning"
				showModal={showModal}
				size="sm"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Your session has expired"
				/>
				<CustomModal.Body>
					<p>Your session has expired due to inactivity.</p>
					<p>Please click "Refresh" to keep working on Work Order <b>{code}</b></p>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label={`Back to ${isFromScheduleBoard ? 'Schedule Board' : 'Work Orders'}`}
						onClick={this.goBackToWorkOrders}
						style="secondary"
					/>
					<Button
						label="Refresh"
						onClick={reload}
						style="secondary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}

}

export default SessionExpiredModal;
