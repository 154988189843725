import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import type UserPermission from '@acceligentllc/shared/enums/userPermission';

import { filterMap } from '@acceligentllc/shared/utils/array';

import type { Props as FSNLinkNSProps } from './FSNLink';
import FSNLink from './FSNLink';
import type { Props as FSNLinkWithStatusNSProps } from './FSNLinkWithStatus';
import FSNLinkWithStatus from './FSNLinkWithStatus';
import FSBottomComponent from './FSBottomComponent';

interface SideNavTab {
	name: string;
	link: string;
	isActive: boolean;
}

interface OwnProps {
	links: Nullable<FSNLinkNSProps[]>;
	permissions: string[];
	isCompanyAdmin: boolean;
	userRole: UserPermission;
	bottomComponentRoute?: string;
	renderBottomComponent?: Nullable<(props: CustomRouteComponentProps) => React.ReactNode>;
	scrollLabel?: string;
	errors?: Metadata;
	changeLabel?: (hash: string) => void;
	actionRoute?: string;
	actionLabel?: string;
	hasStates?: boolean;
	searchable?: boolean;
	infoLabel?: string;
	withStatus?: boolean;
	tabs?: SideNavTab[];
}

type Props = OwnProps;

interface State {
	searchText: string;
	isMinimized: boolean;
}

class FloatingSideNavFSN extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		hasStates: false,
		searchable: false,
		withStatus: false,
	};

	state: State = {
		searchText: '',
		isMinimized: true,
	};

	toggleFSN = () => this.setState((state: State) => ({ isMinimized: !state.isMinimized }));

	onLinkClick = () => this.setState(() => ({ isMinimized: true }));

	onSearchChange = (e) => {
		e.persist();
		this.setState(() => ({ searchText: e.target.value }));
	};

	clearSearchText = () => {
		this.setState(() => ({ searchText: '' }));
	};

	renderBasicLink = (link: FSNLinkNSProps) => {
		const { userRole, changeLabel, errors, hasStates, scrollLabel, permissions, isCompanyAdmin } = this.props;

		return (
			<FSNLink
				changeLabel={changeLabel}
				companyPermissions={permissions}
				counter={link.counter}
				errorKey={link.errorKey}
				errors={errors}
				hash={link.hash}
				hasStates={hasStates}
				icon={link.icon}
				id={link.id}
				isActive={!!link.isActive || link.hash === scrollLabel}
				isCompanyAdmin={isCompanyAdmin}
				isForceHidden={link.isForceHidden}
				isLoading={link.isLoading}
				key={link.label + link.hash}
				label={link.label}
				onClick={this.onLinkClick}
				url={link.url}
				userRole={userRole}
				visibleFor={link.visibleFor}
			/>
		);
	};

	renderBasicLinks = (links: FSNLinkNSProps[]) => {
		const { searchText } = this.state;

		if (!searchText) {
			return links?.map(this.renderBasicLink);
		}
		const searchTextLowercased = searchText.toLowerCase();

		return filterMap(
			links,
			(link: FSNLinkNSProps) => link.label.toLowerCase().includes(searchTextLowercased),
			this.renderBasicLink
		);
	};

	renderLinkWithStatus = (link: FSNLinkWithStatusNSProps, index: number) => {
		const { userRole, permissions, isCompanyAdmin } = this.props;

		return (
			<FSNLinkWithStatus
				companyPermissions={permissions}
				hash={link.hash}
				isCompanyAdmin={isCompanyAdmin}
				isLoading={link.isLoading}
				key={link.label + link.hash + link.sublabel}
				label={`${index + 1}. ${link.label}`}
				onClick={this.onLinkClick}
				sublabel={link.sublabel}
				url={link.url}
				userRole={userRole}
				visibleFor={link.visibleFor}
			/>
		);
	};

	renderLinksWithStatus = (links: FSNLinkWithStatusNSProps[]) => {
		const { searchText } = this.state;

		if (!searchText) {
			return links.map(this.renderLinkWithStatus);
		}
		const searchTextLowercased = searchText.toLowerCase();

		// NOTE: Cannot use `filterMap` because mapper requires `filter` result indexing
		return links
			.filter((link: FSNLinkWithStatusNSProps) => link.label.toLowerCase().includes(searchTextLowercased))
			.map(this.renderLinkWithStatus)
			;
	};

	renderLinks = (links: FSNLinkNSProps[]) => {
		const { withStatus } = this.props;

		return withStatus ? this.renderLinksWithStatus(links) : this.renderBasicLinks(links);
	};

	renderSearchInput = () => {
		const { searchText } = this.state;

		return (
			<li className="link floating-sidenav-search">
				<input
					onChange={this.onSearchChange}
					placeholder="Search..."
					type="text"
					value={searchText}
				/>
				{searchText &&
					<a className="clear-search" onClick={this.clearSearchText} role="button">
						<img src="/images/inputs/ic_close.svg" />
					</a>
				}
			</li>
		);
	};

	static renderAction = (actionRoute: string | undefined, actionLabel: string | undefined) => {
		if (!actionRoute || !actionLabel) {
			return null;
		}
		return (
			<li className="link action">
				<Link to={actionRoute}>
					<img className="floating-sidenav-icon" src="/images/sidebar/ic_reduce_default.svg" />
					{actionLabel}
				</Link>
			</li>
		);
	};

	renderLabel = () => {
		const { infoLabel } = this.props;
		const { isMinimized } = this.state;

		if (!infoLabel) {
			return null;
		}
		return (
			<li className="link info" onClick={this.toggleFSN} >
				<span className="info-text">
					{infoLabel}
				</span>
				<span className={`collapse-icon ${isMinimized ? 'icon-right' : 'icon-left'}`} />
			</li>
		);
	};

	static renderSidebarTab(tab: SideNavTab, index: number) {
		return (
			<Link className={tab.isActive ? 'active' : ''} key={tab.link + index} to={tab.link}>
				{tab.name}
			</Link>
		);
	}

	static renderSidebarTabs = (tabs: Nullable<SideNavTab[]>) => {
		if (!tabs?.length) {
			return null;
		}

		return (
			<li className="link action tabbed">
				{tabs.map(FloatingSideNavFSN.renderSidebarTab)}
			</li>
		);
	};

	render() {
		const {
			bottomComponentRoute,
			renderBottomComponent,
			actionLabel,
			actionRoute,
			searchable,
			links,
			tabs,
		} = this.props;
		const { isMinimized } = this.state;

		return (
			<div className="floating-sidenav-placeholder">
				<nav className={`floating-sidenav ${isMinimized ? 'minimized' : ''}`}>
					<ul className="floating-sidenav-menu">
						{this.renderLabel()}
						{FloatingSideNavFSN.renderAction(actionRoute, actionLabel)}
						{FloatingSideNavFSN.renderSidebarTabs(tabs ?? null)}
						{searchable && this.renderSearchInput()}
						{links && this.renderLinks(links)}
					</ul>
					{bottomComponentRoute && renderBottomComponent &&
						<div className="floating-sidenav-bottom">
							<FSBottomComponent forRoute={bottomComponentRoute} renderComponent={renderBottomComponent} />
						</div>
					}
				</nav>
			</div>
		);
	}

}

export default FloatingSideNavFSN;
