import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type { RootState } from 'af-reducers';

import DeliverableFormDetails from 'af-components/DeliverableFormDetails';
import DeliverableAssignmentForm from 'af-components/SharedForms/Deliverables/Assignment';
import DeliverableAssignmentFM from 'af-components/SharedForms/Deliverables/Assignment/formModel';
import CanceledRibbon from 'af-components/CanceledRibbon';
import Breadcrumbs from 'af-components/Breadcrumbs';

import * as DeliverableActions from 'af-actions/deliverable';

import type { DeliverableSubmissionViewModel, DeliverableViewModel, DeliverableAssignmentViewModel } from 'ab-viewModels/deliverable.viewModel';

import CLIENT from 'af-constants/routes/client';

import * as SettingsUtils from 'af-utils/settings.util';

interface PathParams {
	deliverableId: string;
	submissionId: string;
	id: string;
}

interface StateProps {
	companyName: string;
}

interface DispatchProps {
	editAssignment: typeof DeliverableActions.updateDeliverableAssignment;
	findAssignment: typeof DeliverableActions.findAssignmentById;
	findDeliverable: typeof DeliverableActions.findById;
	findSubmission: typeof DeliverableActions.findSubmissionById;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

interface State {
	deliverable: DeliverableViewModel;
	assignment: DeliverableAssignmentViewModel;
	submission: DeliverableSubmissionViewModel;
}

class DeliverableAssignment extends React.Component<Props, State> {
	state: State = {
		deliverable: {} as DeliverableViewModel,
		assignment: {} as DeliverableAssignmentViewModel,
		submission: {} as DeliverableSubmissionViewModel,
	};

	async componentDidMount() {
		const { match: { params: { deliverableId, id, submissionId } }, findDeliverable, findAssignment, findSubmission } = this.props;
		const [deliverable, submission, assignment] = await Promise.all([findDeliverable(+deliverableId), findSubmission(+submissionId), findAssignment(+id)]);
		SettingsUtils.setExpandedDeliverableIds(deliverableId, submissionId, id);
		this.setState(() => ({ deliverable, assignment, submission }));
	}

	onSubmit = async (form: DeliverableAssignmentFM) => {
		const { editAssignment, location: { state: { orgAlias } }, companyName } = this.props;
		await editAssignment(DeliverableAssignmentFM.fromFMtoRM(form), orgAlias, companyName);
	};

	onBack = () => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName));
	};

	render() {
		const {
			location: { state: { orgAlias } },
			companyName,
		} = this.props;
		const { deliverable, assignment, submission } = this.state;

		return (
			<div className="form-segment deliverable-form">
				<Breadcrumbs
					items={[
						{ label: 'Deliverables', url: CLIENT.COMPANY.DELIVERABLE.DASHBOARD(orgAlias, companyName) },
						{ label: 'Edit Deliverable Assignment' },
					]}
				/>
				<DeliverableFormDetails
					deliveryMethod={deliverable.deliveryMethod}
					deliveryTimeline={deliverable.deliveryTimeline}
					dueDate={submission.dueDate}
					endDate={deliverable.endDate}
					jobCode={deliverable.jobCode}
					startDate={deliverable.startDate}
					submissionCode={submission.submissionCode}
					workOrderCode={submission.workOrderCode}
				/>
				{assignment.isCanceled &&
					<CanceledRibbon
						cancellationReason={assignment.cancellationReason ?? ''}
						updatedAt={assignment.canceledAt}
						updatedBy={assignment.canceledBy}
					/>
				}
				<DeliverableAssignmentForm
					initialValues={DeliverableAssignmentFM.fromVMtoFM(assignment)}
					isDisabled={assignment.isCanceled}
					onBack={this.onBack}
					onSubmit={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: RootState): StateProps => {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
};

function mapDispatchToProps(): DispatchProps {
	return {
		editAssignment: DeliverableActions.updateDeliverableAssignment,
		findSubmission: DeliverableActions.findSubmissionById,
		findAssignment: DeliverableActions.findAssignmentById,
		findDeliverable: DeliverableActions.findById,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(DeliverableAssignment);
