import type { W_Job_FindAllJobsForJobFilter_VM, W_Job_FindAllJobsForJobFilter_VM_Single } from 'ab-api/web/job/findAllJobsForJobFilter';

export class JobFilterItem {
	static DEFAULT_ALL: JobFilterItem = Object.freeze({
		id: -1,
		index: 0,
		jobCode: 'All Jobs',
		title: null,
		customerCompany: null,
	});

	id: number;
	/** 1-based index */
	index: number;

	jobCode: string;
	title: Nullable<string>;
	customerCompany: Nullable<string>;

	constructor(job: W_Job_FindAllJobsForJobFilter_VM_Single, index: number) {
		this.id = job.id;
		this.index = index;
		this.jobCode = job.jobCode;
		this.title = job.title;
		this.customerCompany = job.customerCompany;
	}

	private static _constructorMap = (job: W_Job_FindAllJobsForJobFilter_VM_Single, index: number) => new JobFilterItem(job, index);

	static bulkConstructor = (jobs: W_Job_FindAllJobsForJobFilter_VM) => jobs.map((job, index) => { return JobFilterItem._constructorMap(job, index + 1); });

}
