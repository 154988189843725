import * as React from 'react';

interface OwnProps {
	applyOverlap?: boolean;
}

type Props = React.PropsWithChildren<OwnProps>;

import styles from './styles.module.scss';

const EmployeeDraggableBadgesContainer = (props: Props) => {
	const { children, applyOverlap } = props;

	const containerStyle = getContainerStyle();

	return (
		<div className={containerStyle}>
			{children}
		</div>
	);

	/**
	 * Assumes three children when applying overlap.
	 */
	function getContainerStyle() {
		const overlapBadgesStyle = applyOverlap ? ` ${styles['badges-container__overlap-badges']}` : '';
		return `${styles['badges-container']}${overlapBadgesStyle}`;
	}
};

export default EmployeeDraggableBadgesContainer;
