import { URL_REGEX } from '@acceligentllc/shared/constants/regex';

import type DeliverableSubmissionFM from './formModel';

export const validate = (form: DeliverableSubmissionFM) => {
	const errors: ValidationErrors = {};

	if (!form.statusId) {
		errors.statusId = 'Status is required';
	} else if (form.status?.isJobStatus) {
		errors.statusId = 'Invalid Status selected.';
	}

	if (!form.reviewType) {
		errors.reviewType = 'Review Type is required.';
	}

	if (form.trackingLink) {
		if (!URL_REGEX.test(form.trackingLink)) {
			errors.trackingLink = 'URL is invalid.';
		}
	}

	return errors;
};
