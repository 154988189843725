import * as React from 'react';

interface OwnProps {
	companyStatus: Record<string, boolean>;
}

type Props = OwnProps;

const CompanyStatusCell: React.FC<Props> = (props: Props) => {
	const { companyStatus } = props;

	const renderCompanyStatus = React.useCallback((_companyName: string, _isDeleted: boolean) => {
		const status = _isDeleted
			? <span className="text-grey">Deleted</span>
			: <span className="text-green">Active</span>;
		return (
			<div>
				{status} - {_companyName}
			</div>
		);
	}, []);

	return <div className={'company-status-cell'}>
		{Object.keys(companyStatus).map((companyName: string) => renderCompanyStatus(companyName, companyStatus[companyName]))}
	</div>;
};

export default CompanyStatusCell;
