import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import * as ArrayUtils from '@acceligentllc/shared/utils/array';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { BlankPositionOption } from '@acceligentllc/shared/enums/workOrderPosition';
import WorkOrderPositionOption, { PositionOptionList } from '@acceligentllc/shared/enums/workOrderPosition';

import Dropdown from 'af-fields/Dropdown';

import Section from '../Shared/Section';

interface OwnProps {
	disabled: boolean;
	dueDate: string;
	orderPosition: WorkOrderPositionOption;
	initialIndex: Nullable<number>;
	resetTimer: () => void;
	loadBlankPositions: (dueDate: string) => Promise<number[]>;
	change: (fieldName: string, value: Nullable<number>) => void;
}

type Props = OwnProps;

const WorkOrderScheduleBoard: React.FC<Props> = (props) => {
	const INDEX_OPTIONS = ArrayUtils.range(1, 61).map((_index) => ({ id: _index + 1, label: _index + 1 }));

	const { disabled, resetTimer, orderPosition, dueDate, loadBlankPositions, change, initialIndex } = props;

	const [blankOrders, setBlankOrders] = React.useState([] as BlankPositionOption[]);

	const lazyLoadBlankWorkOrders = React.useCallback(async (isLazyLoaded: boolean) => {
		if (!isLazyLoaded) {
			const blankIndexList = await loadBlankPositions(TimeUtils.formatDate(dueDate));
			const blankPositions = blankIndexList.map((_bwo) => ({ id: _bwo, label: _bwo }));
			setBlankOrders(blankPositions);
		}
	}, [loadBlankPositions, dueDate]);

	const onPlaceWorkOrderChange = React.useCallback(() => {
		change('index', initialIndex);
		resetTimer();
	}, [change, initialIndex, resetTimer]);

	return (
		<Section label="Schedule Board Sort Index">
			<Col sm={6}>
				<Field
					component={Dropdown}
					disabled={disabled}
					id="position"
					label="Select Work Order Sort Index"
					labelKey="label"
					name="position"
					onValueChange={onPlaceWorkOrderChange}
					options={PositionOptionList}
					valueKey="id"
					withCaret={true}
				/>
			</Col>
			<Col sm={6}>
				{orderPosition === WorkOrderPositionOption.BLANK &&
					<Field
						component={Dropdown}
						disabled={disabled}
						id="index"
						label="Select Blank Position"
						labelKey="label"
						name="index"
						onLazyLoad={lazyLoadBlankWorkOrders}
						onValueChange={resetTimer}
						options={blankOrders}
						valueKey="id"
						withCaret={true}
					/>
				}
				{orderPosition === WorkOrderPositionOption.SPECIFIC &&
					<Field
						component={Dropdown}
						disabled={disabled}
						id="index"
						label="Select Specific Position"
						labelKey="label"
						name="index"
						onValueChange={resetTimer}
						options={INDEX_OPTIONS}
						valueKey="id"
						withCaret={true}
					/>
				}
			</Col>
		</Section>
	);
};

export default React.memo(WorkOrderScheduleBoard);
