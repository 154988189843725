import * as React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import AccountPermission from '@acceligentllc/shared/enums/accountPermission';
import AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';

import { formatPhoneNumber } from '@acceligentllc/shared/utils/phone';

import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';

import type { AccountRM } from 'ab-requestModels/account/upload.requestModel';

import { AccountPermissionFlags, AccountPermissionTemplateOptions, AccountPermissionTemplates } from 'ab-enums/accountPermissionTemplates.enum';

import Permissions from '../Shared/Permission';
import { FLAG_IMPLICATIONS, IMPLICATIONS } from '../Shared/Permission/constants';

export interface OwnProps {
	disabled: boolean;
	field: string;
	index: number;
	account: AccountRM;
	change: (field: string, value: boolean | typeof AccountPermissionTemplateOptions[0]) => void;
}

type Props = OwnProps;

interface State {
	isExpanded: boolean;
	modified: boolean;
	isCompanyAdmin: boolean;
	activateImmediatelyChecked: boolean;
	activeInLMSChecked: boolean;
}

class MemberPermissions extends React.PureComponent<Props, State> {

	state: State = {
		isExpanded: false,
		modified: false,
		isCompanyAdmin: false,
		activateImmediatelyChecked: false,
		activeInLMSChecked: false,
	};

	toggleExpanded = () => {
		this.setState((state: State) => ({ isExpanded: !state.isExpanded }));
	};

	static renderMenuOption = (option: (typeof AccountPermissionTemplateOptions)[0]) => <div>{option.label}</div>;

	componentDidUpdate(prevProps: Props) {
		const { account, change, field } = this.props;
		const { permissions, accountTemplateId } = account;
		const { modified } = this.state;

		if (accountTemplateId === AccountPermissionTemplate.ADMIN) {
			this.setState(() => ({ modified: false }));
		} else if (permissions !== prevProps.account.permissions || accountTemplateId !== prevProps.account.accountTemplateId) {
			const templatePermissions = AccountPermissionTemplates[accountTemplateId]?.reduce((_acc, _permission) => {
				_acc[_permission] = true;
				for (const _implication of (IMPLICATIONS[_permission] ?? [])) {
					_acc[_implication] = true;
				}
				return _acc;
			}, {}) as Record<AccountPermission, true> ?? {} as Record<AccountPermission, true>;

			const newModified = Object.keys(permissions ?? {}).some((_permission) => permissions[_permission] !== (templatePermissions[_permission] ?? false));

			const selected = AccountPermissionTemplateOptions.find((_option) => _option.id === accountTemplateId);
			if (!!selected && newModified !== modified) {
				change(`${field}.accountTemplate`, { ...selected });
				this.setState(() => ({ modified: newModified }));
			}
		}
	}

	toggleAll = (value: boolean, skipAdminCheck: boolean = false) => {
		const {
			change,
			field,
			account: {
				assignableToWorkOrder,
				assignableAsSuperintendent,
				assignableAsAccounting,
				assignableAsManagement,
				assignableAsTechnician,
			},
		} = this.props;
		const { isCompanyAdmin } = this.state;

		let lockedPermissions: { [permission in AccountPermission]?: boolean } = {};

		if (!skipAdminCheck) {
			if (assignableToWorkOrder) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableToWorkOrder };
			}
			if (assignableAsSuperintendent) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsSuperintendent };
			}
			if (assignableAsAccounting) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsAccounting };
			}
			if (assignableAsManagement) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsManagement };
			}
			if (assignableAsTechnician) {
				lockedPermissions = { ...lockedPermissions, ...FLAG_IMPLICATIONS.assignableAsTechnician };
			}
		}

		if (skipAdminCheck || (!skipAdminCheck && !isCompanyAdmin)) {
			Object.keys(AccountPermission).forEach((_permission: AccountPermission) => {
				if (!lockedPermissions[_permission]) {
					change(`${field}.permissions.${AccountPermission[_permission]}`, value);
				}
			});
		}
	};

	checkPermissionDependencies = (perm: AccountPermission, permissionMap: { [key: string]: true; }) => {
		// Used to avoid circular references
		if (permissionMap[perm]) {
			return;
		}

		const { change, field } = this.props;

		if (IMPLICATIONS[perm]) {
			permissionMap[perm] = true;
			for (const consequentPermission of (IMPLICATIONS[perm] ?? [])) {
				if (IMPLICATIONS[consequentPermission]) {
					this.checkPermissionDependencies(consequentPermission, permissionMap);
				}
				change(`${field}.permissions.${consequentPermission}`, true);
			}
		}
	};

	selectPermissionsForTemplate = (template: AccountPermissionTemplate) => {
		const { change, field } = this.props;

		AccountPermissionTemplates[template].forEach((_permission) => {
			change(`${field}.permissions.${AccountPermission[_permission]}`, true);

			this.checkPermissionDependencies(_permission, {});
		});
	};

	onTemplateChange = (option: typeof AccountPermissionTemplateOptions[0]) => {
		const { change, field } = this.props;

		const isCompanyAdmin = option.id === AccountPermissionTemplate.ADMIN;

		this.toggleAll(isCompanyAdmin, true);
		this.selectPermissionsForTemplate(option.id);

		change(`${field}.assignableAsAccounting`, false);
		change(`${field}.assignableToWorkOrder`, false);
		change(`${field}.assignableAsProjectManager`, false);
		change(`${field}.assignableAsSuperintendent`, false);
		change(`${field}.assignableAsQAQC`, false);
		change(`${field}.assignableAsManagement`, false);
		change(`${field}.assignableAsTechnician`, false);

		AccountPermissionFlags[option.id].forEach((_flag) => {
			change(`${field}.${_flag}`, true);
		});

		this.setState(() => ({ isCompanyAdmin }));
	};

	onActivateImmediatelyChange = () => {
		this.setState((state: State) => ({ activateImmediatelyChecked: !state.activateImmediatelyChecked }));
	};

	onActiveInLMSChange = () => {
		this.setState((state: State) => ({ activeInLMSChecked: !state.activeInLMSChecked }));
	};

	renderSelected = (option: (typeof AccountPermissionTemplateOptions)[0]) => {
		const { modified } = this.state;
		return (
			<div>{option.label}{modified ? ' - modified' : ''}</div>
		);
	};

	changePermissions = (permission: string, value: boolean) => {
		const { change, field } = this.props;
		change(`${field}.${permission}`, value);
	};

	render() {
		const {
			field,
			index,
			account: {
				firstName,
				lastName,
				email,
				phoneNumber,
				assignableAsAccounting,
				assignableAsManagement,
				assignableAsTechnician,
				assignableAsSuperintendent,
				assignableToWorkOrder,
				permissions,
			},
		} = this.props;
		const { isExpanded, isCompanyAdmin } = this.state;

		return (
			<div className="member-permissions" key={index}>
				<Row className="member-permissions__header">
					<Col className="member-permissions__email" sm={6}>
						<div className="member-permissions__title">
							<div className="member-permissions__name">
								{firstName} {lastName}
							</div>
							<div className="member-permissions__contact">
								{email}
							</div>
							<div className="member-permissions__contact">
								{formatPhoneNumber(phoneNumber)}
							</div>
						</div>
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id={`${field}.activateImmediately`}
							isCondensed={true}
							isStandalone={true}
							label="Activate immediately"
							name={`${field}.activateImmediately`}
							onChange={this.onActivateImmediatelyChange}
							tooltipMessage="Activating the user will allow them to log into the platform."
						/>
						{
							this.state.activateImmediatelyChecked &&
							<>
								{email &&
									<Field
										component={Checkbox}
										id={`${field}.shouldSendEmailInvite`}
										isCondensed={true}
										isStandalone={true}
										label="Send email invite"
										name={`${field}.shouldSendEmailInvite`}
									/>
								}
								{phoneNumber &&
									<Field
										component={Checkbox}
										id={`${field}.shouldSendPhoneInvite`}
										isCondensed={true}
										isStandalone={true}
										label="Send phone invite"
										name={`${field}.shouldSendPhoneInvite`}
									/>
								}
							</>
						}
					</Col>
					<Col sm={6}>
						<Field
							component={Checkbox}
							id={`${field}.activeInLMS`}
							inline={true}
							isDisabled={false}
							isStandalone={true}
							label="Active in LMS"
							name={`${field}.activeInLMS`}
							onChange={this.onActiveInLMSChange}
							tooltipMessage="Selecting the checkbox will allow the user to log into the learning management system."
							type="text"
						/>
					</Col>
					<Col sm={6}>
						<Field
							component={Dropdown}
							id="template-dropdown"
							label="Permissions template *"
							name={`${field}.accountTemplateId`}
							onValueChange={this.onTemplateChange}
							options={AccountPermissionTemplateOptions}
							placeholder="Select Permission Template"
							propName={`${field}.accountTemplate`}
							renderMenuItem={MemberPermissions.renderMenuOption}
							renderSelected={this.renderSelected}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col className="member-permissions__toggle" sm={6}>
						<a
							className={`btn btn-toggle ${isExpanded ? 'active' : ''}`}
							onClick={this.toggleExpanded}
							type="button"
						>
							<span className={isExpanded ? 'icon-up' : 'icon-down'} />
							Permissions
						</a>
					</Col>
				</Row>
				{isExpanded &&
					<Permissions
						assignableAsAccounting={assignableAsAccounting}
						assignableAsManagement={assignableAsManagement}
						assignableAsSuperintendent={assignableAsSuperintendent}
						assignableAsTechnician={assignableAsTechnician}
						assignableToWorkOrder={assignableToWorkOrder}
						change={this.changePermissions}
						fieldBase={field}
						isCompanyAdmin={isCompanyAdmin}
						permissions={permissions}
						toggleAll={this.toggleAll}
					/>
				}
			</div>
		);
	}

}

export default MemberPermissions;
