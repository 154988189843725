import * as React from 'react';

import styles from './styles.module.scss';
import FilterInput from './FilterInput';
import Buttons from './Buttons';
import type { ButtonData } from '../types';

type Props = {
	hasSearchInput: boolean;
	onFilterTextChange: (filterText: string) => void;
	searchLabel: string;
	filterText: string;
	additionalFilter?: () => JSX.Element;
	buttons: ButtonData[];
	exportAsZip?: boolean;
	filtersClassName?: string;
};

const ActionHeader: React.FC<Props> = (props) => {
	const { hasSearchInput, onFilterTextChange, searchLabel, filterText, additionalFilter, buttons, exportAsZip, filtersClassName } = props;

	const filtersStyle = React.useMemo(() => {

		const appliedStyles = [styles['action-header__filters']];

		if (filtersClassName) {
			appliedStyles.push(filtersClassName);
		}

		return appliedStyles;

	}, [filtersClassName]);

	return (
		<div className={styles['action-header']}>
			<div className={filtersStyle.join(' ')}>
				{hasSearchInput &&
					<FilterInput
						filterText={filterText}
						onFilterTextChange={onFilterTextChange}
						searchLabel={searchLabel}
					/>
				}
				{additionalFilter?.()}
			</div>
			<Buttons buttons={buttons} exportAsZip={exportAsZip} />
		</div>
	);
};

export default React.memo(ActionHeader);
