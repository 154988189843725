import * as React from 'react';

import type TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';

import Tooltip from 'af-components/Tooltip';

import TimelineTooltip from '../TimelineTooltip';

import styles from './styles.module.scss';

import type { PercentagesOfDateChanges, PercentagesForChangeToDueDate } from '../types';

interface Props {
	timelinePercentage: number;
	isManagerOrAdmin: boolean;
	iconStyle: string;
	workType: Nullable<TimeSheetEntryWorkType>;
	workOrderCode: Nullable<string>;
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	sectionBarClassName: string;
	icon: Nullable<string>;
	sectionContainerStyle: string;
	dueDate: string;
	sectionPercentagesForDueDate?: PercentagesForChangeToDueDate;
	dateChangesOnSectionInPercentages?: PercentagesOfDateChanges[];
	nextEntryIsGap: boolean;
}

const BaseSection: React.FC<Props> = (props: Props) => {

	const {
		timelinePercentage,
		isManagerOrAdmin,
		iconStyle,
		workType,
		workOrderCode,
		startTime,
		endTime,
		sectionBarClassName,
		icon,
		sectionContainerStyle,
		nextEntryIsGap,
	} = props;

	const sectionWidthVariables = { '--widthPercentage': `${timelinePercentage}%` } as React.CSSProperties;
	const containerStyles = getBarContainerStyles();

	return (
		<div
			className={sectionContainerStyle}
			style={sectionWidthVariables}
		>
			<Tooltip
				message={
					<TimelineTooltip
						endTime={endTime}
						isManagerOrAdmin={isManagerOrAdmin}
						startTime={startTime}
						workOrderCode={workOrderCode}
						workType={workType}
					/>
				}
			>
				<div className={containerStyles}>
					<div className={styles['section-container__icon-container']}>
						<div className={iconStyle}>
							{icon && <span className={`icon-${icon}`} />}
						</div>
					</div>
					<span className={sectionBarClassName} />
				</div>
			</Tooltip>
		</div>
	);

	function getBarContainerStyles() {
		const containerStylesArray = ['section-container__bar-container'];
		if (nextEntryIsGap) {
			containerStylesArray.push('section-container__bar-container--gap');
		}
		return containerStylesArray.map((style) => styles[style]).join(' ');
	}
};

export default BaseSection;
