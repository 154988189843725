import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import Tooltip from 'af-components/Tooltip';

import styles from './styles.module.scss';

interface NotificationType {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

interface NotificationSnackbarType {
	notification: NotificationType;
	closeNotification: (notification: NotificationType) => void;
}

const NotificationSnackbar = (props: NotificationSnackbarType) => {
	const { notification, closeNotification } = props;

	const backgroundColorClassName = notification.type;

	const onClickHandler = React.useCallback(() => {
		closeNotification(notification);
	}, [notification, closeNotification]);

	return (
		<div className={`${styles.notification} ${styles[backgroundColorClassName]}`}>
			<div className={`${styles.icon}`}>
				{notification.type === NotificationSnackbarTypes.LOADING && <span className={`${styles.notificationIcon} ${styles.iconLoading} icon-loading`} />}
				{notification.type === NotificationSnackbarTypes.INFO && <span className={`${styles.notificationIcon} icon-info`} />}
				{notification.type === NotificationSnackbarTypes.SUCCESS && <span className={`${styles.notificationIcon} icon-check_circle`} />}
				{notification.type === NotificationSnackbarTypes.WARNING && <span className={`${styles.notificationIcon} icon-warning `} />}
				{notification.type === NotificationSnackbarTypes.ERROR && <span className={`${styles.notificationIcon} icon-warning`} />}
			</div>
			<div className={`${styles.notificationDetails}`}>
				<Tooltip
					message={notification.content}
					placement="bottom"
				>
					<div className={`${styles.notificationText}`}>
						{notification.content}
					</div>
				</Tooltip>
				<div className={`${styles.notificationDate}`}>
					{TimeUtils.formatDate(notification.date ?? new Date(), TimeFormat.FULL_DATE_LONG, TimeFormat.DB_DATE_ONLY)}
				</div>
			</div>
			<div className={`${styles.icon}`} onClick={onClickHandler}>
				<div className={`icon-close ${styles.iconClose}`}></div>
			</div>
		</div >
	);
};

export default NotificationSnackbar;
