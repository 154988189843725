import * as React from 'react';

import TimeSheetSignatureStatus, { TimeSheetSignatureStatusLabel } from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import TimeSheetApprovalStatus, { TimeSheetApprovalStatusLabel } from '@acceligentllc/shared/enums/timeSheetApprovalStatus';

import styles from './styles.module.scss';

type Status = TimeSheetSignatureStatus | TimeSheetApprovalStatus | 'FINALIZED';

interface OwnProps {
	className?: string;
	status: Status;
}

type Props = OwnProps;

const CSS_CLASSNAME_EXTENSIONS = {
	[TimeSheetSignatureStatus.OUTDATED]: 'outdated',
	[TimeSheetSignatureStatus.SIGNED]: 'signed',
	'FINALIZED': 'signed',
	[TimeSheetSignatureStatus.UNSIGNED]: 'unsigned',
	[TimeSheetApprovalStatus.PENDING]: 'unsigned',
	[TimeSheetApprovalStatus.APPROVED]: 'approved',
	[TimeSheetApprovalStatus.REJECTED]: 'rejected',
};

const SignatureStatusLabel = ({ status, className }: Props) => {
	const baseClassName = 'pill';
	const extendedClassName = `${baseClassName}--${CSS_CLASSNAME_EXTENSIONS[status]}`;

	let label: string = 'Finalized';
	if (TimeSheetSignatureStatus[status]) {
		label = TimeSheetSignatureStatusLabel[status];
	} else if (TimeSheetApprovalStatus[status]) {
		label = TimeSheetApprovalStatusLabel[status];
	}

	return (
		<span className={`${styles[baseClassName]} ${styles[extendedClassName]} ${className ?? ''}`}>
			{label}
		</span>
	);
};

export default React.memo(SignatureStatusLabel);
