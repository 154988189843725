import { WHITESPACE_REGEX } from '@acceligentllc/shared/constants/regex';

import { queryString } from '@acceligentllc/shared/utils/text';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

export const tokenizeString = (text: string = ''): string[] => {
	text = text || '';
	text = text.trim().replace(/\s+/g, ' ');
	return !!text ? text.split(' ') : [];
};

export const removeSpaces = (text: string) => text ? text.replace(/\s+/g, '') : '';

export const compareByCompanyName = (company1: string, company2: string): boolean =>
	removeSpaces(company1).toLowerCase() === removeSpaces(company2).toLowerCase();

export const toLowerCase = (str: string) => {
	return str?.toLowerCase() ?? '';
};

export const isLowerCaseEqual = (str1: Nullable<string>, str2: Nullable<string>) => {
	return (str1 ?? '').toLowerCase() === (str2 ?? '').toLowerCase();
};

export const escapeRegExp = (s: string = '') => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const wrapWithParenthesis = (text: string) => `(${text})`;

export const padStartWith = (text: string, padSize: number, padChar: string = ' '): string => {
	const pad = Array(padSize).join(padChar);
	return `${pad}${text}`;
};

export const capitalize = (text: string) => {
	if (text?.charAt(0)) {
		return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	}
	return text;
};

export function matches<T extends SearchableModel>(item: T, query: string, additionalText?: Nullable<string>, caseSensitive: boolean = false): boolean {
	const searchQuery = query?.trim();
	if (!searchQuery || searchQuery.length < 2) {
		return false;
	}
	const searchString = caseSensitive ? searchQuery : searchQuery.toLowerCase();

	const searchInputs = (searchString || '').replace(WHITESPACE_REGEX, ' ').split(' ');
	const searchableFields = item._desc?.split(' ') ?? [];

	if (additionalText) {
		searchableFields.push(additionalText);
	}

	return queryString(searchInputs, searchableFields, caseSensitive);
}
