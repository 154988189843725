import * as React from 'react';

import { CompoundUnitEnum } from '@acceligentllc/shared/enums/quantityUnit';

import Preview from '../Shared/TextPreview';
import CompoundPreview from '../Shared/CompoundTextPreview';

type Props = React.ComponentProps<typeof Preview> | React.ComponentProps<typeof CompoundPreview>;

const CalculatedField: React.FC<Props> = (props) => {
	const { unit } = props;

	if (unit && CompoundUnitEnum[unit]) {
		return (
			<CompoundPreview {...(props as React.ComponentProps<typeof CompoundPreview>)} />
		);
	}
	return (
		<div className="report-block__calculated-field">
			<Preview {...(props as React.ComponentProps<typeof Preview>)} />
		</div>
	);
};

export default React.memo(CalculatedField);
