import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeSheetEntryUtils from '@acceligentllc/shared/utils/timeSheetEntry';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimeSheetTrackedEntryVM } from 'ab-viewModels/timeSheet/trackedEntries.viewModel';
import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';
import type TimeSheetHasOverlappingEntryVM from 'ab-viewModels/timeSheet/timeSheetHasOverlappingEntry.viewModel';
import type TimeCardEntryVM from 'ab-viewModels/timeSheet/timeCardEntry.viewModel';
import type TimeCardRejectedTimeSheetVM from 'ab-viewModels/timeSheet/timeCardRejectedTimeSheet.viewModel';

import ConfirmationModal from 'af-components/ConfirmationModal';

import RejectModal from '../../../Shared/RejectTimeSheetModal';
import type SignatureForm from '../../../Shared/SignatureModal/FormModel';
import SignTimeSheetModal from '../../Modals/SignTimeSheetModal';
import TimelineSubmitSuccessModal from '../../Modals/TimelineSubmitSuccessModal';
import TimeSheetNoteModal from '../../Modals/TimeSheetNoteModal';
import TimeSheetTimeSplits from './TimeSheetTimeSplits/TimeSheetTimeSplits';
import TimeSheetTime from './TimeSheetTime';
import TimeSheetNote from './TimeSheetNote';
import TimeSheetDurations from './TimeSheetDurations/TimeSheetDurations';
import TimeSheetInfo from './TimeSheetInfo/TimeSheetInfo';
import TimeSheetApprovalActions from './TimeSheetApprovalActions/TimeSheetApprovalActions';
import Timeline from './Timeline/index';
import TimelineHistoryModal from '../../Modals/TimelineHistoryModal';
import TimeCardTroubleshootModal from '../../Modals/TimeCardTroubleshootModal';
import AssignUnassignedEntriesModal from '../../Modals/AssignUnassignedEntriesModal';
import TimeSheetSignature from './TimeSheetInfo/TimeSheetSignature';
import TimeSheetRowActions from './TimeSheetRowActions';

import styles from './styles.module.scss';

interface OwnProps {
	/** False if the user isn't on the latest published version of WO */
	active: boolean;
	allAccountEntries: TimeCardEntryVM[];
	fetchAccountEntries: () => void;
	code: string;
	hasEntryChanges: boolean;
	hasTimeSheetOverlaps: (timeSheetId: number) => Promise<TimeSheetHasOverlappingEntryVM>;
	isManagementAllowedToApprove: boolean;
	isManagerOrAdmin: boolean;
	isRejectable: boolean;
	isSuperintendent: boolean;
	isTemporaryEmployee: boolean;
	onEdit: (timeSheet: TimeSheetVM) => void;
	onTimeSheetSubmit: (form: SignatureForm) => Promise<void>;
	onTimeSheetReject: () => Promise<void>;
	isTimeSheetReadOnly: boolean;
	timeSheet: TimeSheetVM;
	workOrderId: number;
	isOwner: boolean;
	areReportsReadOnly: boolean;
	trackedEntry: Nullable<TimeSheetTrackedEntryVM>;
	timeSheetEntities: TimelineEntitesForAccount[];
	timeSheetOverlaps: Nullable<Record<string, OverlapMeta>>;
	timeZoneInUse: Nullable<string>;
	isDuplicateName: boolean;
	workOrderStatus: WorkOrderStatus;
	rejectedTimeSheetsForTimeCards: TimeCardRejectedTimeSheetVM[];
}

type Props = OwnProps;

interface State {
	employee: { name: string; status: Nullable<TimeSheetSignatureStatus>; accountId: number; }[];
	hasOverlaps: boolean;
	showEquipmentActionButton: boolean;
	showEquipmentModal: boolean;
	showEquipmentTimeError: boolean;
	showNoChangesOnRejectModal: boolean;
	showNoteModal: boolean;
	showTimeCardTroubleshootModal: boolean;
	showAssignUnassignedModal: boolean;
	showRejectButton: boolean;
	showRejectModal: boolean;
	showSignButton: boolean;
	showSignatureModal: boolean;
	showTimelineHistoryModal: boolean;
	showTimelineSubmitSuccessModal: boolean;
}

class TimeSheetRow extends React.PureComponent<Props, State> {

	static defaultProps: Partial<Props> = {
		hasEntryChanges: false,
		timeSheetEntities: [],
	};

	state: State = {
		employee: [TimeSheetRow.getEmployee(this.props.timeSheet)],
		hasOverlaps: false,
		showRejectModal: false,
		showSignatureModal: false,
		showTimelineSubmitSuccessModal: false,
		showRejectButton: false,
		showSignButton: false,
		showEquipmentModal: false,
		showNoteModal: false,
		showTimeCardTroubleshootModal: false,
		showAssignUnassignedModal: false,
		showEquipmentTimeError: false,
		showEquipmentActionButton: false,
		showNoChangesOnRejectModal: false,
		showTimelineHistoryModal: false,
	};

	static getDerivedStateFromProps(props: Props) {
		const {
			isManagementAllowedToApprove,
			isManagerOrAdmin,
			timeSheet: {
				employeeApprovalStatus,
				superintendentApprovalStatus,
				timeSplitEntries,
				totalJobDuration,
			},
			isOwner,
			isRejectable,
			isSuperintendent,
			areReportsReadOnly,
			isTemporaryEmployee,
		} = props;

		const canManagerApprove = isManagementAllowedToApprove ? isManagerOrAdmin : false;
		const isSigned = employeeApprovalStatus.signatureStatus === TimeSheetSignatureStatus.SIGNED;

		const showSignButton = isOwner
			&& !isSigned
			&& !isTemporaryEmployee
			&& superintendentApprovalStatus.approvalStatus !== TimeSheetApprovalStatus.APPROVED;
		const showRejectButton = isRejectable
			&& !isOwner
			&& (isSuperintendent || canManagerApprove)
			&& !areReportsReadOnly;

		const showEquipmentActionButton = !areReportsReadOnly;

		let totalEquipmentTime = 0;
		if (timeSplitEntries?.length > 0) {
			totalEquipmentTime = timeSplitEntries.map((ts) => ts.time).reduce((currEqTime, aggregateTime) => currEqTime + aggregateTime);
		}
		const showEquipmentTimeError = totalEquipmentTime > totalJobDuration;

		return {
			showSignButton,
			showRejectButton,
			showEquipmentTimeError,
			showEquipmentActionButton,
		};
	}

	static getEmployee = (sheet: TimeSheetVM) => {
		return {
			name: sheet.userFullName,
			accountId: sheet.accountId,
			status: sheet.employeeApprovalStatus.signatureStatus,
		};
	};

	static wasSignedAfterRejection = (timeSheet: TimeSheetVM) => {
		return timeSheet.wasRejected
			&& timeSheet.employeeApprovalStatus?.signatureStatus === TimeSheetSignatureStatus.SIGNED
			&& timeSheet.superintendentApprovalStatus?.approvalStatus !== TimeSheetApprovalStatus.APPROVED;
	};

	static wasNotModified = (timeSheet: TimeSheetVM) => {
		return timeSheet.superintendentApprovalStatus?.approvalStatus === TimeSheetApprovalStatus.REJECTED && !timeSheet.wasModifiedAfterRejection;
	};

	static NO_CHANGES_ON_REJECTED_TIME_SHEET = (workOrderCode: string, rejectedBy: Nullable<string>) => (
		<>
			Your Time Sheet for <strong>{workOrderCode}</strong> has been rejected{!!rejectedBy && <> by <strong>{rejectedBy}</strong></>}.
			<br />
			<br />
			If you proceed  you will sign unchanged Time Sheet. You can leave a note to Superintendent when signing.
			<br />
			<br />
			Do you want to proceed to signature?
		</>
	);

	static offsetTimelineEntry(entry: TimeCardEntryVM, offset: number): TimeCardEntryVM {
		return {
			...entry,
			startTime: TimeUtils.offsetTime(entry.startTime, offset, 'minutes', TimeFormat.ISO_DATETIME) ?? entry.startTime,
			endTime: entry.endTime ? TimeUtils.offsetTime(entry.endTime, offset, 'minutes', TimeFormat.ISO_DATETIME) : null,
		};
	}

	componentDidMount() {
		this.findOverlaps();
	}

	componentDidUpdate(prevProps: Props) {
		const { timeSheet, allAccountEntries, fetchAccountEntries } = this.props;
		if (prevProps.timeSheet !== timeSheet) {
			this.findOverlaps();
		}

		if (prevProps.allAccountEntries !== allAccountEntries) {
			fetchAccountEntries;
		}
	}

	findOverlaps = async () => {
		const { timeSheet, hasTimeSheetOverlaps } = this.props;

		const { hasOverlaps } = await hasTimeSheetOverlaps(timeSheet.id);
		this.setState(() => ({ hasOverlaps }));
	};

	showRejectModal = () => this.setState(({ showRejectModal: true }));
	hideRejectModal = () => this.setState(({ showRejectModal: false }));

	showSignatureModal = () => this.setState(() => ({ showSignatureModal: true }));
	hideSignatureModal = () => this.setState(() => ({ showSignatureModal: false }));

	showTimelineSubmitSuccessModal = () => this.setState(() => ({ showTimelineSubmitSuccessModal: true }));
	hideTimelineSubmitSuccessModal = () => this.setState(() => ({ showTimelineSubmitSuccessModal: false }));

	showEquipmentModal = () => this.setState(() => ({ showEquipmentModal: true }));
	hideEquipmentModal = () => this.setState(() => ({ showEquipmentModal: false }));

	showNoteModal = () => this.setState(() => ({ showNoteModal: true }));
	hideNoteModal = () => this.setState(() => ({ showNoteModal: false }));

	showTimeCardTroubleshootModal = () => this.setState(() => ({ showTimeCardTroubleshootModal: true }));
	hideTimeCardTroubleshootModal = () => this.setState(() => ({ showTimeCardTroubleshootModal: false }));

	showAssignUnassignedModal = () => this.setState(() => ({ showAssignUnassignedModal: true, showTimeCardTroubleshootModal: false }));
	hideAssignUnassignedModal = () => this.setState(() => ({ showAssignUnassignedModal: false }));

	showNoChangesOnRejectModal = () => this.setState(() => ({ showNoChangesOnRejectModal: true }));
	hideNoChangesOnRejectModal = () => this.setState(() => ({ showNoChangesOnRejectModal: false }));

	showTimelineHistoryModal = () => this.setState(() => ({ showTimelineHistoryModal: true }));
	hideTimelineHistoryModal = () => this.setState(() => ({ showTimelineHistoryModal: false }));

	handleSignTimeSheet = () => {
		const { timeSheet } = this.props;

		if (TimeSheetRow.wasNotModified(timeSheet)) {
			this.showNoChangesOnRejectModal();
		} else {
			this.showSignatureModal();
		}
	};

	handleEdit = () => {
		const { onEdit, timeSheet } = this.props;

		onEdit(timeSheet);
	};

	onTimeSheetSubmit = async (form: SignatureForm) => {
		const { onTimeSheetSubmit } = this.props;

		await onTimeSheetSubmit(form);
		this.hideSignatureModal();
		this.showTimelineSubmitSuccessModal();
	};

	render() {
		const {
			timeSheet: {
				id,
				accountId,
				userFullName,
				dueDate,
				employeeApprovalStatus,
				superintendentApprovalStatus,
				totalJobDuration,
				timeSplitEntries,
				endTime,
				note,
				startTime,
				totalBreakDuration,
				totalTravelDuration,
				totalShopDuration,
				temporaryEmployeeUniqueId: userUniqueId,
			},
			areReportsReadOnly,
			isTimeSheetReadOnly,
			code,
			workOrderId,
			onTimeSheetReject,
			isOwner,
			isManagerOrAdmin,
			isSuperintendent,
			isTemporaryEmployee,
			active,
			trackedEntry,
			timeSheetEntities,
			timeSheetOverlaps,
			timeZoneInUse,
			hasEntryChanges,
			isDuplicateName,
			workOrderStatus,
			allAccountEntries,
			rejectedTimeSheetsForTimeCards,
		} = this.props;

		const {
			showRejectModal,
			showSignatureModal,
			showTimelineSubmitSuccessModal,
			showNoteModal,
			showTimeCardTroubleshootModal,
			showAssignUnassignedModal,
			hasOverlaps,
			showRejectButton,
			showSignButton,
			showEquipmentTimeError,
			showEquipmentActionButton,
			showNoChangesOnRejectModal,
			showTimelineHistoryModal,
		} = this.state;

		const isApproved = superintendentApprovalStatus.approvalStatus === TimeSheetApprovalStatus.APPROVED;
		const isReadOnly = areReportsReadOnly && isTimeSheetReadOnly;
		const isModified = TimeSheetRow.wasSignedAfterRejection(this.props.timeSheet);

		const timeSectionStyle = `${styles['time-sheet-list__row__info__total-time-section']} ${styles['time-sheet-list__row__info__info-section']}`;
		const modifiedStyle = isModified ? ` ${styles['time-sheet-list__row__info--modified']}` : '';
		const modalContainerStyle = `${styles['time-sheet-list__row__info']}${modifiedStyle} row`;

		const totalTime = totalJobDuration + totalShopDuration + totalTravelDuration;

		const showOverlaps = isSuperintendent || isManagerOrAdmin;

		const browserOffset = (new Date()).getTimezoneOffset();
		const timeZoneOffset = timeZoneInUse ? TimeUtils.getOffset(timeZoneInUse) : 0;

		const timeOffset = timeZoneOffset + browserOffset;
		const entriesWithOffset = allAccountEntries?.map((_entry) => TimeSheetRow.offsetTimelineEntry(_entry, timeOffset)) ?? [];

		// If time entries are recorded/created in another time zone, we want to check work time to decide if unassigned entries are available for WO
		const entriesBasedOnTimeZone = timeZoneInUse ? entriesWithOffset : allAccountEntries;

		const numberOfTotalUnassignedEntries = allAccountEntries?.filter((_entry) => _entry.workOrderId === null && _entry.endTime).length ?? 0;

		const availableUnassignedEntries: TimeCardEntryVM[] = entriesBasedOnTimeZone?.filter(
			(_tse) => {
				const timeSheetStartTime = TimeUtils.formatDate(_tse.startTime, TimeFormat.DB_DATE_ONLY, TimeFormat.DB_DATE_ONLY);
				const isEntryForToday = dueDate === timeSheetStartTime;
				const isEntryForTomorrow = TimeUtils.formatDate(TimeUtils.addDays(dueDate, 1, TimeFormat.DB_DATE_ONLY),
					TimeFormat.DB_DATE_ONLY) === timeSheetStartTime;

				return _tse.workOrderId === null
					&& _tse.endTime
					&& (isEntryForToday || isEntryForTomorrow);
			}
		);
		const accountOverlaps = allAccountEntries ? TimeSheetEntryUtils.findOverlaps(allAccountEntries) ?? {} : null;

		const rejectedTSForAccount = rejectedTimeSheetsForTimeCards.filter((_ts) => accountId === _ts.accountId);

		return (
			<div className={styles['time-sheet-list__row']}>
				<div className={styles['time-sheet-list__row__name-and-action-section']}>
					<TimeSheetInfo
						active={active}
						isApproved={isApproved}
						isDuplicateName={isDuplicateName}
						isOwner={isOwner}
						isTemporaryEmployee={isTemporaryEmployee}
						overlaps={timeSheetOverlaps}
						showOverlaps={showOverlaps}
						timeSheetEntities={timeSheetEntities}
						userFullName={userFullName}
						userUniqueId={userUniqueId}
					/>
					<TimeSheetRowActions
						handleEdit={this.handleEdit}
						isManagerOrAdmin={isManagerOrAdmin}
						isOwner={isOwner}
						isReadOnly={isReadOnly}
						isSuperIntendent={isSuperintendent}
						openViewHistoryModal={this.showTimelineHistoryModal}
					/>

				</div>
				<div className={styles['time-sheet-list__row__info']}>
					<div className={styles['time-sheet-list__row__info__info-section']}>
						<TimeSheetSignature
							approvalStatus={superintendentApprovalStatus}
							isOwner={isOwner}
							isTemporaryEmployee={isTemporaryEmployee}
							signatureStatus={employeeApprovalStatus}
						/>
						<TimeSheetApprovalActions
							disableStartEndValidation={workOrderStatus === WorkOrderStatus.CANCELED}
							endTime={endTime}
							hasOverlaps={hasOverlaps}
							onRejectButtonClick={this.showRejectModal}
							onSignButtonClick={this.handleSignTimeSheet}
							showRejectButton={showRejectButton}
							showSignButton={showSignButton}
							startTime={startTime}
						/>
					</div>
					<div className={timeSectionStyle}>
						{(isManagerOrAdmin || isSuperintendent) && <div className={styles['time-sheet-list__row__info__time-card-link']}>
							{numberOfTotalUnassignedEntries ?
								<Button
									icon="search"
									label="Unassigned time entries"
									onClick={this.showTimeCardTroubleshootModal}
									style="link-danger"
								/>
								:
								<Button
									icon="search"
									label="Open Time Card Troubleshooting View"
									onClick={this.showTimeCardTroubleshootModal}
									style="link"
								/>
							}
						</div>}

						<TimeSheetTime
							endTime={endTime}
							startTime={startTime}
							totalTime={totalTime}
						/>
						<TimeSheetNote
							note={note}
							openNoteModal={this.showNoteModal}
							showNoteActionButton={!isTimeSheetReadOnly}
						/>
					</div>
					<div className={styles['time-sheet-list__row__info__info-section']}>
						<TimeSheetDurations
							breakDuration={totalBreakDuration}
							jobDuration={totalJobDuration}
							shopDuration={totalShopDuration}
							showEquipmentTimeError={showEquipmentTimeError}
							timeSplitEntries={timeSplitEntries}
							trackedEntry={trackedEntry}
							travelDuration={totalTravelDuration}
						/>
						<TimeSheetTimeSplits
							isManagerOrAdmin={isManagerOrAdmin}
							isSuperintendent={isSuperintendent}
							showEquipmentActionButton={showEquipmentActionButton}
							showEquipmentModal={this.showEquipmentModal}
							showEquipmentTimeError={showEquipmentTimeError}
							timeSplitEntries={timeSplitEntries}
							totalJobDuration={totalJobDuration}
						/>
					</div>
				</div>
				<Timeline
					dueDate={this.props.timeSheet.dueDate}
					employeeApprovalStatus={employeeApprovalStatus}
					entities={timeSheetEntities}
					isManagerOrAdmin={isManagerOrAdmin}
					isSuperIntendent={isSuperintendent}
					overlaps={timeSheetOverlaps}
					showTimeline={hasEntryChanges}
					superintendentApprovalStatus={superintendentApprovalStatus}
				/>
				<div className={modalContainerStyle}>
					<RejectModal
						accountId={accountId}
						close={this.hideRejectModal}
						code={code}
						dueDate={dueDate}
						employeeFullName={userFullName}
						onSubmit={onTimeSheetReject}
						showModal={showRejectModal}
						workOrderId={workOrderId}
					/>
					<SignTimeSheetModal
						closeModal={this.hideSignatureModal}
						showModal={showSignatureModal}
						submit={this.onTimeSheetSubmit}
					/>
					<TimelineSubmitSuccessModal
						closeModal={this.hideTimelineSubmitSuccessModal}
						showModal={showTimelineSubmitSuccessModal}
					/>
					<TimeSheetNoteModal
						accountId={accountId}
						closeModal={this.hideNoteModal}
						showModal={showNoteModal}
						workOrderId={workOrderId}
					/>
					{showTimeCardTroubleshootModal &&
						<TimeCardTroubleshootModal
							accountId={accountId}
							allAccountEntries={entriesBasedOnTimeZone}
							closeModal={this.hideTimeCardTroubleshootModal}
							hasAvailableUnassignedEntries={availableUnassignedEntries ? availableUnassignedEntries.length : 0}
							hasUnassignedEntriesForMessage={numberOfTotalUnassignedEntries}
							isApproved={isApproved}
							openAssignUnassignedModal={this.showAssignUnassignedModal}
							overlaps={accountOverlaps}
							rejectedTSForAccount={rejectedTSForAccount}
							showEquipmentTimeError={showEquipmentTimeError}
							showModal={showTimeCardTroubleshootModal}
							timeOffset={timeOffset}
							timeZoneInUse={timeZoneInUse}
							userFullName={userFullName}
							workOrderId={workOrderId}
						/>
					}
					{showAssignUnassignedModal &&
						<AssignUnassignedEntriesModal
							accountId={accountId}
							closeModal={this.hideAssignUnassignedModal}
							hasEntryChanges={hasEntryChanges}
							timeSheetId={id}
							unassignedEntries={availableUnassignedEntries}
							userFullName={userFullName}
							workOrderId={workOrderId}
						/>
					}
					<TimelineHistoryModal
						accountId={accountId}
						closeModal={this.hideTimelineHistoryModal}
						showModal={showTimelineHistoryModal}
						timeZoneInUse={timeZoneInUse}
						workOrderId={workOrderId}
					/>
					<ConfirmationModal
						body={TimeSheetRow.NO_CHANGES_ON_REJECTED_TIME_SHEET(code, superintendentApprovalStatus.userName)}
						closeModal={this.hideNoChangesOnRejectModal}
						closeText="Cancel"
						confirmAction={this.showSignatureModal}
						confirmText="Continue"
						modalStyle="warning"
						showModal={showNoChangesOnRejectModal}
						size="sm"
						title="No changes made to Rejected Time Sheet"
					/>
				</div>
			</div >
		);
	}
}

export default TimeSheetRow;
