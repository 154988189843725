import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { AdditionalColors } from '@acceligentllc/shared/enums/color';
import UnavailabilityReasonScope from '@acceligentllc/shared/enums/unavailabilityReasonScope';

import type { RootState } from 'af-reducers';

import * as ColorUtils from 'ab-utils/color.util';

import { generateEquipmentSearchItemId } from 'af-utils/scheduleBoard.util';

import EquipmentUnavailabilityReason from 'af-root/scenes/Company/ScheduleBoard/Shared/UnavailabilityReason';
import ReturnDateDatePicker from 'af-root/scenes/Company/ScheduleBoard/Shared/ReturnDateDatepicker';

interface OwnProps {
	draggableProps: Metadata;
	dragHandleProps: Metadata;
	innerRef: string;
	equipmentId: number;
	dueDate: string;
	isToolbar: boolean;
	isDragging: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	onClick: () => void;
	workOrderCode?: string;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const DraggableWithReason: React.FC<Props> = (props: Props) => {
	const {
		isDragging,
		draggableProps,
		dragHandleProps,
		innerRef,
		onClick,
		dueDate,
		equipment,
		hasPermissionsToEditScheduleBoard,
		draggableId,
		isActive,
		isDisabled,
		isToolbar,
		workOrderCode,
	} = props;

	const [draggableItemClassName, setDraggableItemClassName] = React.useState('');

	React.useEffect(() => {
		if (!equipment) {
			return;
		}
		const { color, isFilteredOnToolbar, isFilteredOnBoard, isMatched } = equipment;

		let newDraggableItemClassName = 'sb-resource-item sb-resource-item--equipment';
		newDraggableItemClassName += isDisabled ? ' sb-resource-item--disabled' : '';
		newDraggableItemClassName += isMatched && !workOrderCode ? ' sb-resource-item--highlighted' : '';
		newDraggableItemClassName += (isToolbar && isFilteredOnToolbar) || (!isToolbar && isFilteredOnBoard) ? ' filtered' : '';
		newDraggableItemClassName += ` ${ColorUtils.getColorBackgroundClass(color ?? AdditionalColors.GREY)}`;
		newDraggableItemClassName += isActive ? ' sb-resource-item--active' : '';
		newDraggableItemClassName = newDraggableItemClassName.trim();

		setDraggableItemClassName(newDraggableItemClassName);
	}, [draggableItemClassName, equipment, isActive, isDisabled, isToolbar, workOrderCode]);

	const { name, spec: specification, id: equipmentId } = equipment;

	return (
		<div
			ref={innerRef}
			{...draggableProps}
			{...dragHandleProps}
			className="sb-resource-item--with-reason"
		>
			<div className="sb-resource-item--with-datepicker">
				<div
					className={draggableItemClassName}
					id={draggableId}
				>
					<a className="sb-resource-item__content" onClick={onClick} role="button">
						<span className="sb-resource-item__full-name sb-resource-item__full-name--transparent-bg">{name}</span>
						{specification && <span className="sb-resource-item__specification">{specification}</span>}
					</a>
				</div>
				<div className={isDragging ? 'sb-resource-item--opaque' : ''}>
					<ReturnDateDatePicker
						dueDate={dueDate}
						hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
						itemId={equipmentId}
						scope={UnavailabilityReasonScope.EQUIPMENT}
					/>
				</div>
			</div>
			<EquipmentUnavailabilityReason
				dueDate={dueDate}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				isDragging={isDragging}
				itemId={equipmentId}
				scope={UnavailabilityReasonScope.EQUIPMENT}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { equipment: equipmentDict, draggedEquipmentId, searchResultItems, activeSearchItemIndex } = state.scheduleBoard;
	const { equipmentId, isToolbar } = ownProps;
	if (!equipmentDict) {
		throw new Error('Equipment dictionary not loaded');
	}

	const draggableId = generateEquipmentSearchItemId(equipmentId.toString());
	const equipment = equipmentDict[equipmentId];
	const isActive = searchResultItems[activeSearchItemIndex] === draggableId;

	const isDraggedFromToolbarInOtherTab = isToolbar && equipment?.isDisabled && !draggedEquipmentId;

	return {
		draggableId,
		equipment,
		isDisabled: draggedEquipmentId === equipmentId || isDraggedFromToolbarInOtherTab,
		isActive,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(DraggableWithReason);
