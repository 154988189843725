import * as React from 'react';

import FieldReportTypeStatusEnum from '@acceligentllc/shared/enums/fieldReportTypeStatus';
import WorkSummaryStatus from '@acceligentllc/shared/enums/workSummaryStatus';

import type { FieldReportTypeVM } from 'ab-viewModels/fieldReport/fieldReport.viewModel';
import type FieldReportVM from 'ab-viewModels/fieldReport/fieldReport.viewModel';
import type WorkSummaryStatusVM from 'ab-viewModels/workOrder/workSummaryStatus.viewModel';

import type { UpsertFieldReportTypeRM } from 'ab-requestModels/fieldReport/fieldReport.requestModel';

import { bemBlock, bemElement } from 'ab-utils/bem.util';

import TabNavigation from 'af-components/TabNavigation';

import ReportTypeModal from './Modals/ReportTypeModal';

interface OwnProps {
	activeTabId: Nullable<number>;
	editReportTypes: (fieldReportTypes: UpsertFieldReportTypeRM[]) => void;
	fieldReportOrder: number[];
	fieldReportTypes: FieldReportVM['typeMap'];
	isPreview: boolean;
	lockedFieldReportTypes: number[];
	onTabChange: (typeId: number) => void;
	isWorkSummaryAccessible: boolean;
	workSummaryStatus: WorkSummaryStatusVM;
}

type Props = OwnProps;

export const WORK_SUMMARY_TAB_ID = 0;

interface Tabs {
	id: number;
	label: string;
	status: FieldReportTypeStatusEnum;
	resolveCustomClassName?: (active: boolean) => string;
	generateTabKey?: () => string;
	workSummaryStatus?: WorkSummaryStatus;
}
interface State {
	showReportTypeModal: boolean;
	fieldReportTypes: FieldReportVM['typeMap'];
	tabs: Tabs[];
}

class FieldReportTabs extends React.Component<Props, State> {
	state: State = {
		showReportTypeModal: false,
		fieldReportTypes: this.props.fieldReportTypes,
		tabs: FieldReportTabs.createTabs(
			this.props.isWorkSummaryAccessible, this.props.workSummaryStatus.status, this.props.fieldReportOrder, this.props.fieldReportTypes
		),
	};

	static mapFieldReportTypesToTabs = (id: number, type: FieldReportTypeVM): Tabs => {
		let status = FieldReportTypeStatusEnum.NOT_FILLED;
		if (type.hasRequiredField) {
			if (type.numberOfFilledRequiredFields === 0) {
				status = FieldReportTypeStatusEnum.NOT_FILLED;
			} else if (type.numberOfFilledRequiredFields === type.numberOfRequiredFields) {
				status = FieldReportTypeStatusEnum.COMPLETED;
			} else {
				status = FieldReportTypeStatusEnum.IN_PROGRESS;
			}
		}
		return {
			id,
			label: type?.name,
			status,
			generateTabKey: () => `${id}-${status}`,
		};
	};

	static createTabs = (
		isWorkSummaryAccessible: boolean,
		workSummaryStatus: WorkSummaryStatus,
		fieldReportOrder: number[],
		fieldReportTypes: FieldReportVM['typeMap']
	) => {
		const workSummaryTab = FieldReportTabs.resolveWorkSummaryTab(workSummaryStatus);

		// FIXME: on the following FR, fieldReportOrder had a null in it. Check it out:
		// https://carylon.acceligent.com/company/NWMCC-BOS/fieldReport/workOrder/428842
		// After that, filter(Boolean) should be removed

		return (isWorkSummaryAccessible ? [workSummaryTab] : []).concat(
			fieldReportOrder.filter(Boolean).map((_id) => FieldReportTabs.mapFieldReportTypesToTabs(+_id, fieldReportTypes[_id]))
		);
	};

	componentDidUpdate(prevProps: Props) {
		const { fieldReportTypes, fieldReportOrder, isWorkSummaryAccessible, workSummaryStatus } = this.props;
		if (prevProps.fieldReportTypes !== fieldReportTypes || prevProps.workSummaryStatus !== workSummaryStatus) {
			this.setState(() => {
				const tabs = FieldReportTabs.createTabs(isWorkSummaryAccessible, workSummaryStatus.status, fieldReportOrder, fieldReportTypes);
				return { tabs, fieldReportTypes };
			});
		}
	}

	static resolveWorkSummaryTab = (workSummaryStatus: WorkSummaryStatus): Tabs => {
		return {
			id: WORK_SUMMARY_TAB_ID,
			label: 'Work Summary',
			status: FieldReportTypeStatusEnum.NOT_FILLED,
			resolveCustomClassName: (active: boolean) => {
				return bemElement('tabs-navigation', 'work-summary-tab', {
					active,
				});
			},
			workSummaryStatus,
		};
	};

	_resolveWorkSummaryTabIcon = (workSummaryStatus: Nullable<WorkSummaryStatus>) => {
		const className = bemElement('field-report__tab-navigation', 'work-summary-icon');

		switch (workSummaryStatus) {
			case WorkSummaryStatus.DRAFT: {
				return (
					<span className={bemBlock(className, { draft: true })}>
						<span className="icon-dot" />
					</span>
				);
			}
			case WorkSummaryStatus.IN_PROGRESS: {
				return (
					<span className={bemBlock(className, { 'in-progress': true })}>
						<span className="icon-dot" />
					</span>
				);
			}
			case WorkSummaryStatus.OUTDATED: {
				return (
					<span className={bemBlock(className, { outdated: true })}>
						<span className="icon-dot_outline" />
					</span>
				);
			}
			case WorkSummaryStatus.REVIEWED: {
				return (
					<span className={bemBlock(className, { reviewed: true })}>
						<span className="icon-dot" />
					</span>
				);
			}
			case WorkSummaryStatus.COMPLETED: {
				return (
					<span className={bemBlock(className, { completed: true })}>
						<span className="icon-dot" />
					</span>
				);
			}
			default: {
				return (
					<span className={bemBlock(className, { draft: true })}>
						<span className="icon-dot" />
					</span>
				);
			}
		}
	};

	_resolveRequiredFieldsTabIcon = (status: Nullable<FieldReportTypeStatusEnum>) => {
		if (!status || status === FieldReportTypeStatusEnum.NOT_FILLED) {
			return;
		}
		const className = bemElement(
			'field-report__tab-navigation',
			'required-field-completion-icon',
			{
				'in-progress': status === FieldReportTypeStatusEnum.IN_PROGRESS,
				completed: status === FieldReportTypeStatusEnum.COMPLETED,
			}
		);
		return (
			<span className={className}>
				<span className="icon-dot" />
			</span>
		);
	};

	renderTab = (id: number) => {
		const { tabs } = this.state;
		const tab = tabs.find((_tab) => _tab.id === id);

		if (!tab) {
			throw new Error(`tab ${id} not found`);
		}

		return (
			<div className="field-report__tab-navigation">
				{/*
					TODO: enable when validation starts working
					<Tooltip message={FieldReportTypeStatusEnumLabel[tab.status]}>
						<span className={FieldReportTypeStatusEnumIcon[tab.status]} />
					</Tooltip>
				*/}
				{tab.workSummaryStatus !== undefined ? this._resolveWorkSummaryTabIcon(tab.workSummaryStatus ?? null) : null}
				<span>{tab?.label}</span>
				{tab.workSummaryStatus === undefined ? this._resolveRequiredFieldsTabIcon(tab.status) : null}
			</div>
		);
	};

	onTabClick = (id: number) => {
		const { onTabChange } = this.props;
		onTabChange(id);
	};

	openReportTypesModal = () => this.setState(() => ({ showReportTypeModal: true }));

	closeReportTypeModal = () => this.setState(() => ({ showReportTypeModal: false }));

	render() {
		const { editReportTypes, activeTabId, lockedFieldReportTypes, isPreview, fieldReportOrder } = this.props;
		const { showReportTypeModal, tabs, fieldReportTypes } = this.state;

		return (
			<>
				<TabNavigation
					active={activeTabId}
					onClick={this.onTabClick}
					onEditTabs={!isPreview ? this.openReportTypesModal : undefined}
					renderLabel={this.renderTab}
					tabs={tabs}
				/>
				<ReportTypeModal
					closeModal={this.closeReportTypeModal}
					fieldReportOrder={fieldReportOrder}
					fieldReportTypes={fieldReportTypes}
					lockedFieldReportTypes={lockedFieldReportTypes}
					onSave={editReportTypes}
					showModal={showReportTypeModal}
				/>
			</>
		);
	}
}

export default FieldReportTabs;
