import baseRoute from '../baseRoute';

export * from './viewModel';

type RouteDefinition = `${typeof baseRoute}/${number | ':fieldReportId'}/workSummary`;

export const ROUTE: RouteDefinition = `${baseRoute}/:fieldReportId/workSummary`;

export const URL = (fieldReportId: number): RouteDefinition =>
	`${baseRoute}/${fieldReportId}/workSummary`;
