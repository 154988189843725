import { isValidEmail } from '@acceligentllc/shared/utils/email';
import { isValidFormattedPhoneNumber } from '@acceligentllc/shared/utils/phone';
import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import CountryCode from '@acceligentllc/shared/enums/countryCode';

import FormModel from '../Form/formModel';

function validateNewUser(values: FormModel) {
	const errors: ValidationErrors = {};
	const { user = {} as FormModel['user'] } = values;

	if (!isValidTextInput(user!.uniqueId)) {
		errors.uniqueId = 'Unique ID is required';
	}

	if (!!user!.email && !isValidEmail(user!.email)) {
		errors.email = 'Invalid email address';
	}

	if (!!user!.phoneNumber && !isValidFormattedPhoneNumber(user!.phoneNumber, CountryCode.US)) {
		errors.phoneNumber = 'Invalid mobile phone';
	}

	if (!isValidTextInput(user!.firstName)) {
		errors.firstName = 'First name is required';
	}

	if (!isValidTextInput(user!.lastName)) {
		errors.lastName = 'Last name is required';
	}

	return Object.keys(errors).length ? { user: errors } : errors;
}

function validateExistingUser(values: FormModel) {
	const errors: ValidationErrors = {};

	if (!values.existingUserId) {
		errors.existingUserId = 'User is required';
	}

	return errors;
}

export function validate(values: FormModel) {
	const isInviteExisting = values.inviteOption?.value === FormModel.INVITE_USER_OPTION.value;
	const errors: ValidationErrors = isInviteExisting ? validateExistingUser(values) : validateNewUser(values);

	if (!values.accountTemplateId) {
		errors.accountTemplateId = 'Account template is Required';
	}

	return errors;
}
