import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type FormModel from './formModel';

export function validate(values: FormModel) {
	const errors: ValidationErrors = {};

	if (!values.serialNumber || !isValidTextInput(values.serialNumber)) {
		errors.serialNumber = 'Serial Number is required';
	}

	if (!values.manufacturer || !isValidTextInput(values.manufacturer)) {
		errors.manufacturer = 'Manufacturer is required';
	}

	if (!values.modelNumber || !isValidTextInput(values.modelNumber)) {
		errors.modelNumber = 'Model number is required';
	}

	if (!values.location) {
		errors.location = 'Location is required';
	}

	if (!values.toolType) {
		errors.toolType = 'Tool Type number is required';
	}

	if (!values.status) {
		errors.status = 'Status number is required';
	}

	return errors;
}
