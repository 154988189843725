import * as React from 'react';

import { AdditionalColors, ColorHex } from '@acceligentllc/shared/enums/color';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import WorkRequestStatus from '@acceligentllc/shared/enums/workRequestStatus';
import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import ScheduleBoardView from 'ab-enums/scheduleBoardView.enum';

import * as ColorUtils from 'ab-utils/color.util';
import { moneyNormalizer, formatDecimalNumber } from 'ab-utils/formatting.util';
import { isFirstRevision } from 'ab-utils/scheduleBoard.util';

import StatusIcon from 'af-components/StatusIcon';
import Tooltip from 'af-components/Tooltip';

import { SCREEN_BREAKPOINT_S, SCREEN_BREAKPOINT_M, SCREEN_BREAKPOINT_L, SCREEN_BREAKPOINT_XL } from 'af-constants/values';

import AttachmentsBadge from '../AttachmentsBadge';
import CopyProgressBar from '../CopyProgressBar';
import NightShiftBadge from '../NightShiftBadge';
import PressAndHoldAnchor from '../PressAndHoldAnchor';
import TooltipElement from './CardInfoTooltipElement';
import { calculateManHourAverage } from 'ab-utils/productionData.util';

interface OwnProps {
	/**
	 * Only partial in Edit Production Data mode
	 * I'll leave it only labeled as partial here to make sure it's handled, but we should make it more accurate after eliminating produciton data editing
	 */
	workOrder: Partial<ScheduleBoardWorkOrderViewModel>;
	supervisorName: string;
	projectManagerName: string;
	scheduleBoardView: Nullable<ScheduleBoardView>;
	isCalculationsView: boolean;
	notificationStatus: Nullable<NotificationStatusEnum>;
	zoomLevel: number;
	isAllowedToEditWorkOrders: boolean;
	isAllowedToViewProdData: boolean;
	notificationsEnabled?: boolean;
	isDragging?: boolean;
	openOrderInfoModal: () => void;
	setCopiedWorkOrderCode: (code: Nullable<string>) => void;
}

type Props = OwnProps;

const _renderCanceledLabel = () => {
	return (
		<div className="schedule-board-card-canceled">
			<span>canceled</span>
		</div>
	);
};

const Info: React.FC<Props> = (props) => {
	const {
		isDragging = false,
		zoomLevel,
		setCopiedWorkOrderCode,
		workOrder: { code },
		openOrderInfoModal,
		scheduleBoardView,
		workOrder,
		projectManagerName,
		supervisorName,
		isCalculationsView,
		notificationStatus,
		notificationsEnabled,
		isAllowedToViewProdData,
	} = props;

	const [isShortCardInfo, setIsShortCardInfo] = React.useState(false);

	const updateWindowDimensions = React.useCallback(() => {
		const isShort = (window.innerWidth < SCREEN_BREAKPOINT_S)
			|| (window.innerWidth < SCREEN_BREAKPOINT_M)
			|| (window.innerWidth < SCREEN_BREAKPOINT_L && zoomLevel < 2)
			|| (window.innerWidth < SCREEN_BREAKPOINT_XL && zoomLevel < 1);

		if (isShort === isShortCardInfo) {
			return;
		}

		setIsShortCardInfo(isShort);
	}, [isShortCardInfo, zoomLevel]);

	// On mount
	React.useEffect(() => {
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		// Needed for when zoom level changes
		updateWindowDimensions();
	}, [props, updateWindowDimensions]);

	const onHoldEnd = React.useCallback(() => {
		if (!code) {
			throw new Error('Code is required');
		}
		setCopiedWorkOrderCode(code);
	}, [code, setCopiedWorkOrderCode]);

	const onRelease = React.useCallback(() => {
		if (!isDragging) {
			setCopiedWorkOrderCode(null);
		}
	}, [isDragging, setCopiedWorkOrderCode]);

	const renderAddress = React.useCallback(() => {
		const { title, customer, address } = workOrder;

		let tooltipTitle = '';
		tooltipTitle += customer ? `${customer}\n` : '';
		tooltipTitle += title ? `${title}\n` : '';
		tooltipTitle += address ?? '';

		return (
			<div className="schedule-board-card-address">
				<div className="schedule-board-card-address-content" title={tooltipTitle}>
					{customer && <><span>{customer}</span><br /></>}
					{title && <><span>{title}</span><br /></>}
					<span>{address}</span>
				</div>
			</div>
		);
	}, [workOrder]);

	const renderTooltip = React.useMemo(() => {

		const automaticCalculationAfter = new Date(process.env.AUTO_PROD_DATA_CUTOFF_DATE ?? '');
		const _dueDate: Date = new Date(TimeUtils.formatDate(workOrder.dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY));

		const areDatesValid = !(isNaN(automaticCalculationAfter.getTime()) || isNaN(_dueDate.getTime()));

		return (
			<div className="schedule-board-production-data-tooltip-container">
				<div className="schedule-board-production-data-tooltip-heading">
					<div>PRODUCTION DATA</div>
					{areDatesValid && <div className="schedule-board-production-data-tooltip-calculation-explainer">
						{automaticCalculationAfter < _dueDate ? '(calculated automatically)' : '(calculated manually)'}
					</div>}
				</div>
				<div>
					<TooltipElement elementName="Revenue" elementValue={moneyNormalizer(workOrder.revenue ?? null) ?? '-'} />
					<TooltipElement elementName="Man Hour Average"
						elementValue={automaticCalculationAfter < _dueDate ?
							getManHourAverage(
								workOrder.revenue,
								workOrder.jobHours,
								workOrder.shopHours,
								workOrder.travelHours
							)
							: workOrder.manHourAverage ?? '-'}
					/>
					<TooltipElement elementName="Job Hours" elementValue={workOrder.jobHours ?? '-'} />
					<TooltipElement elementName="Shop Hours" elementValue={workOrder.shopHours ?? '-'} />
					<TooltipElement elementName="Travel Hours" elementValue={workOrder.travelHours ?? '-'} />
				</div>
			</div>
		);
	}, [workOrder.dueDate, workOrder.jobHours, workOrder.manHourAverage, workOrder.revenue, workOrder.shopHours, workOrder.travelHours]);

	const renderInfo = React.useCallback((isCopying: boolean, copyFinished: boolean) => {
		const {
			dueDate,
			revenue,
			jobHours,
			shopHours,
			travelHours,
			crewTypeColor,
			customCrewType,
			isInternal,
			isNightShift,
			codeStripped,
			status,
			revision,
			statusBasedRevision,
			workStart,
			workEnd,
			timeToEnd,
			timeToStart,
			shift,
			isPaused,
			projectJobCode,
			isUnion,
			isPrevailingWage,
			jobStatusColor,
			jobStatusName,
		} = workOrder;

		const totalHours = (jobHours ?? 0) + (shopHours ?? 0) + (travelHours ?? 0);
		const isDraft: boolean = status === WorkOrderStatus.DRAFT;
		const isWeeklyView: boolean = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW;
		const isCanceled: boolean = status === WorkOrderStatus.CANCELED;

		const nightShiftWorkStartDateFormatted = TimeUtils.formatDate(dueDate, TimeFormat.SHORT_DATE, TimeFormat.DATE_ONLY);
		const daysToAdd = timeToStart && timeToEnd && timeToStart > timeToEnd ? 1 : 0;
		const nightShiftWorkEndDate = dueDate && TimeUtils.addDays(TimeUtils.parseDate(dueDate, TimeFormat.DATE_ONLY), daysToAdd);
		const nightShiftWorkEndDateFormatted = TimeUtils.formatDate(nightShiftWorkEndDate, TimeFormat.SHORT_DATE);

		let headerBackgroundClassName = '';
		if (!isInternal) {
			headerBackgroundClassName = !crewTypeColor ?
				ColorUtils.getColorBackgroundClass(AdditionalColors.GREY) :
				ColorUtils.getColorBackgroundClass(crewTypeColor);
		}

		const displayProjectCode = !!projectJobCode && process.env.FTD_PROJECT !== 'true';

		return (
			<>
				<div>
					<div className={`schedule-board-card-crew ${headerBackgroundClassName}`}>
						{workOrder.hasAttachments && <AttachmentsBadge />}
						{
							!!jobStatusColor &&
							<div className="schedule-board-card-job-status" >
								<Tooltip
									message={`${jobStatusName}`}
									placement="top-start"
								>
									<div className="schedule-board-card-job-tooltip" style={{ backgroundColor: ColorHex[jobStatusColor] ?? undefined }} />
								</Tooltip>
							</div>
						}
						<div className="schedule-board-card-status">
							<div className="schedule-board-card-status-icon">
								<StatusIcon
									firstRevision={revision ? isFirstRevision(revision) : undefined}
									isPaused={isPaused}
									notificationsEnabled={notificationsEnabled}
									notificationStatus={notificationStatus}
									status={status}
								/>
							</div>
						</div>
						<div className="schedule-board-card-code">
							{isInternal
								? <Tooltip message={customCrewType ?? ''}><span>{customCrewType}</span></Tooltip>
								: <Tooltip message={`${codeStripped}${revision && !isDraft ? ` REV ${revision}${statusBasedRevision ? `.${statusBasedRevision}` : ''}` : ''}`}>
									<span>
										{codeStripped}{revision && !isDraft ? ` REV ${revision}${statusBasedRevision ? `.${statusBasedRevision}` : ''}` : ''}
									</span>
								</Tooltip>
							}
						</div>
						{
							displayProjectCode &&
							<div className="schedule-board-card-project-code">
								{projectJobCode}
							</div>
						}
					</div>
					{
						// If we put ?? it won't how PW if isUnion is false
						// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
						(isUnion || isPrevailingWage) &&
						<div
							className="schedule-board-card-flags"
						>
							{
								isUnion &&
								<div className="schedule-board-card-flag">U</div>
							}
							{
								isPrevailingWage &&
								<div className="schedule-board-card-flag">PW</div>
							}
						</div>
					}
					{
						!isCalculationsView && isAllowedToViewProdData && !isInternal &&
						<div
							className="schedule-board-card-metrics"
							title={`${!revenue ? '-' : moneyNormalizer(revenue)} / ${!totalHours ? '-' : `${formatDecimalNumber(totalHours, 0, 2)} HR`}`}
						>
							<Tooltip message={renderTooltip}>
								{!revenue ? '-' : moneyNormalizer(revenue)} / {!totalHours ? '-' : `${formatDecimalNumber(totalHours, 0, 2)} HR`}
							</Tooltip>
						</div>
					}
					{
						workOrder.status === WorkOrderStatus.LOCKED &&
						<div
							className="schedule-board-card-locked"
						>
							APPROVED (LOCKED)
						</div>
					}
					{
						workOrder.isPaused &&
						<div
							className="schedule-board-card-on-hold"
						>
							ON HOLD
						</div>
					}
					{isCanceled ? _renderCanceledLabel() : renderAddress()}
					<div
						className="schedule-board-card-supervisor"
						title={`${projectManagerName} / ${supervisorName}`}
					>
						{projectManagerName} / {supervisorName}
					</div>
					<div className="schedule-board-card-shop-work">
						{isNightShift && <div className="night-shift-icon-container"><NightShiftBadge /></div>}
						<div className={`shift-item ${isShortCardInfo ? 'centered' : ''}`}>
							{!isShortCardInfo && <label>{shift ?? 'N/A'}</label>}
							{
								!isNightShift
									? <span className="schedule-board-card-shift" title={`${workStart} - ${workEnd}`}>{workStart} - {workEnd}</span>
									: (
										<div className="schedule-board-card-night-shift">
											<span className="schedule-board-card-shift" title={`${nightShiftWorkStartDateFormatted} ${workStart}`}>{nightShiftWorkStartDateFormatted} {workStart}</span>
											<span className="schedule-board-card-shift" title={`${nightShiftWorkEndDateFormatted} ${workEnd}`}>{nightShiftWorkEndDateFormatted} {workEnd}</span>
										</div>
									)
							}
						</div>
					</div>
				</div>
				{isWeeklyView &&
					<CopyProgressBar
						copyFinished={copyFinished}
						isCopying={isCopying}
					/>
				}
			</>
		);
	}, [
		isAllowedToViewProdData,
		isCalculationsView,
		isShortCardInfo,
		notificationStatus,
		notificationsEnabled,
		projectManagerName,
		renderAddress,
		scheduleBoardView,
		supervisorName,
		workOrder,
		renderTooltip,
	]);

	const isWeeklyView = scheduleBoardView === ScheduleBoardView.WEEKLY_VIEW;

	return (
		<PressAndHoldAnchor
			onClick={openOrderInfoModal}	// NOTE: will not open on calculations
			onHoldEnd={onHoldEnd}
			onRelease={onRelease}
			regularAnchor={!isWeeklyView || workOrder.jobStatus === WorkRequestStatus.FINISHED}
		>
			{renderInfo}
		</PressAndHoldAnchor>
	);

	function getManHourAverage(
		revenue: Nullable<string> | undefined,
		jobHours: Nullable<number> | undefined,
		shopHours: Nullable<number> | undefined,
		travelHours: Nullable<number> | undefined
	) {

		const revenueForCalculation = revenue !== undefined && revenue !== null ?
			Number(revenue)
			: null;

		const mha = calculateManHourAverage(
			revenueForCalculation,
			jobHours ?? 0,
			shopHours ?? 0,
			travelHours ?? 0
		);

		if (mha === 'N/A') {
			return mha;
		}

		return moneyNormalizer(mha);
	}
};

export default React.memo(Info);
