import * as React from 'react';

import WorkOrderReviewStatus, { WorkOrderReviewLevel } from '@acceligentllc/shared/enums/workOrderReviewStatus';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import { formatDate } from '@acceligentllc/shared/utils/time';

import type { WorkOrderReviewHistoryItemVM } from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import { bemElement } from 'ab-utils/bem.util';

import LockedValue from 'af-components/LockedValue';

interface OwnProps {
	reviewStatus: WorkOrderReviewStatus;
	reviewLevel: WorkOrderReviewLevel;
	currentReviewHistoryItem: Nullable<WorkOrderReviewHistoryItemVM>;
}

type Props = OwnProps;

const getReviewLabel = (reviewStatus: WorkOrderReviewStatus, reviewLevel: WorkOrderReviewLevel) => {
	switch (reviewStatus) {
		case WorkOrderReviewStatus.REJECTED:
			return <div className={bemElement('field-report-work-order-info-card__header', 'reviewed', ['rejected'])}>REJECTED BY</div>;
		case WorkOrderReviewStatus.FINAL:
			return <div className={bemElement('field-report-work-order-info-card__header', 'reviewed', ['finalized'])}>FINALIZED BY</div>;
		default:
			if (reviewLevel === WorkOrderReviewLevel.LEVEL_0) {
				return <div className={bemElement('field-report-work-order-info-card__header', 'reviewed', ['submitted'])}>SUBMITTED BY</div>;
			}
			return <div className={bemElement('field-report-work-order-info-card__header', 'reviewed', ['approved'])}>APPROVED BY</div>;
	}
};

const WorkOrderReviewedByLabel: React.FC<Props> = (props: Props) => {
	const { reviewStatus, reviewLevel, currentReviewHistoryItem } = props;

	if (reviewStatus === WorkOrderReviewStatus.DRAFT || !currentReviewHistoryItem) {
		return null;
	}

	const label = getReviewLabel(reviewStatus, reviewLevel);
	const { time, userName } = currentReviewHistoryItem;

	return (
		<LockedValue
			label={label}
			value={
				<LockedValue
					inverse={true}
					label={userName}
					value={
						<div className="field-report-work-order-info-card__header__reviewed__time">
							{formatDate(time, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)}
						</div>
					}
				/>
			}
		/>
	);
};

export default React.memo(WorkOrderReviewedByLabel);
