import * as React from 'react';

import { filterMap } from '@acceligentllc/shared/utils/array';

import { bemElement } from 'ab-utils/bem.util';

import type { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';

import ReportBlockField from './Field';
import type { ReportBlockFieldFormModel } from '../formModel';
import type { HighlightedBillableWorkBlockFields } from '../../ReportType/Shared/values';

interface OwnProps {
	currentlyHighlightedFields?: HighlightedBillableWorkBlockFields;
	filterVisibleToCustomer: boolean;
	formField: string;
	formValues?: Record<string, string>[];
	highlightVisibleToCustomer?: boolean;
	index?: number;
	onCalculatedFieldChange: (id: string) => void;
	onFieldValueChange?: (id: string, value: string, index: number | undefined) => void;
	listOfEquipment?: Nullable<EquipmentListViewModel>;
	reportBlockFieldIds: Nullable<string[]>;
	reportFieldsByIdMap: { [id: string]: ReportBlockFieldFormModel; };
}

type Props = OwnProps;

const Block: React.FC<Props> = (props: Props) => {
	const {
		reportBlockFieldIds,
		formField,
		filterVisibleToCustomer,
		highlightVisibleToCustomer,
		onFieldValueChange,
		onCalculatedFieldChange,
		listOfEquipment,
		formValues,
		index,
		reportFieldsByIdMap,
		currentlyHighlightedFields,
	} = props;

	const filterVisibleField = React.useCallback((fieldId: string) => {
		const field = reportFieldsByIdMap[fieldId];
		if (!field) {
			throw new Error('Missing field');
		}
		return field.isVisibleToCustomer;
	}, [reportFieldsByIdMap]);

	const renderField = React.useCallback((fieldId: string) => {
		const field = reportFieldsByIdMap[fieldId];
		if (!field) {
			throw new Error('Missing field');
		}
		const id = `report-block-field-${field.virtualId}`;
		const isCurrentlyHighlighted = currentlyHighlightedFields?.[field.virtualId] ?? false;

		return (
			<ReportBlockField
				field={field}
				formField={formField}
				formValues={formValues}
				highlightVisibleToCustomer={highlightVisibleToCustomer}
				id={id}
				isHighlighted={isCurrentlyHighlighted}
				key={field.virtualId}
				listOfEquipment={listOfEquipment}
				onCalculatedFieldChange={onCalculatedFieldChange}
				onFieldValueChange={onFieldValueChange}
				repeatIndex={index}
			/>
		);
	}, [reportFieldsByIdMap,
		currentlyHighlightedFields,
		formField,
		formValues,
		highlightVisibleToCustomer,
		listOfEquipment,
		onCalculatedFieldChange,
		onFieldValueChange,
		index]
	);

	const element = 'array-elements';
	const modifiers = {
		'settings-preview': true,
	};

	const className = bemElement('report-block', element, modifiers);

	return (
		<div className={className}>
			{filterVisibleToCustomer
				? filterMap(
					reportBlockFieldIds ?? [],
					filterVisibleField,
					renderField
				)
				: (reportBlockFieldIds ?? []).map(renderField)
			}
		</div>
	);
};

export default React.memo(Block);
