import type CountryCode from '@acceligentllc/shared/enums/countryCode';
import type UserPermission from '@acceligentllc/shared/enums/userPermission';
import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import type AccountPermissionTemplate from '@acceligentllc/shared/enums/accountPermissionTemplate';
import type MemberInviteEnum from '@acceligentllc/shared/enums/memberInvite';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import type SystemNotificationType from '@acceligentllc/shared/enums/systemNotificationType';
import type { TimeOptionValues } from '@acceligentllc/shared/enums/timeOption';

import * as BlobStorageUtil from '@acceligentllc/shared/utils/blobStorage';
import * as TimeUtils from '@acceligentllc/shared/utils/time';
import * as TimeOptionUtils from '@acceligentllc/shared/utils/timeOption';

import type AccountBase from 'ab-domain/models/account/base';
import type PermissionBase from 'ab-domain/models/permission/base';
import type UserBase from 'ab-domain/models/user/base';
import type OrganizationBase from 'ab-domain/models/organization/base';
import type SystemNotificationBase from 'ab-domain/models/systemNotification/base';
import { getStorageUrl } from '@acceligentllc/shared/utils/blobStorage';

import { PASSWORD_MAX_AGE } from 'ab-common/constants/value';
import type { LoginMethod } from 'ab-enums/auth.enum';

import * as BlobStorageUtil2 from 'ab-utils/blobStorage.util';

class SystemNotificationRequestModel {
	id: number;
	type: SystemNotificationType;
	content: string;
	startsAt: Date;
	startsAtDate?: string; // only on unparsed version
	startsAtTime?: TimeOptionValues; // only on unparsed version
	endsAt: Date;
	endsAtDate?: string; // only on unparsed version
	endsAtTime?: TimeOptionValues; // only on unparsed version

	constructor(unparsedForm: SystemNotificationRequestModel) {
		if (!unparsedForm.startsAtDate || !unparsedForm.startsAtTime || !unparsedForm.endsAtDate || !unparsedForm.endsAtTime) {
			throw new Error('Times and dates not provided');
		}

		const startsAtTime = TimeOptionUtils.toNumber(unparsedForm.startsAtTime);
		const endsAtTime = TimeOptionUtils.toNumber(unparsedForm.endsAtTime);
		if (!startsAtTime || !endsAtTime) {
			throw new Error('Times in incorrect format');
		}

		this.type = unparsedForm.type;
		this.content = unparsedForm.content;

		const startsAtMoment = TimeUtils.parseMoment(unparsedForm.startsAtDate, TimeFormatEnum.DATE_ONLY);
		const endsAtMoment = TimeUtils.parseMoment(unparsedForm.endsAtDate, TimeFormatEnum.DATE_ONLY);
		if (!startsAtMoment || !endsAtMoment) {
			throw new Error('Times in incorrect format');
		}

		this.startsAt = startsAtMoment.add(startsAtTime, 'minutes').toDate();
		this.endsAt = endsAtMoment.add(endsAtTime, 'minutes').toDate();
	}

	static fromModelToRequestModel(systemNotification: SystemNotificationBase): SystemNotificationRequestModel {
		return {
			id: systemNotification.id,
			type: systemNotification.type,
			content: systemNotification.content,
			startsAt: systemNotification.startsAt,
			endsAt: systemNotification.endsAt,
		};
	}

	static fromModelListToRequestModelList(systemNotifications: SystemNotificationBase[]): SystemNotificationRequestModel[] {
		return systemNotifications.map((_sn) => this.fromModelToRequestModel(_sn));
	}

}

export interface CompanyPermission {
	companyName: string;
	companyId: number;
	permissions: string[];
	accountId: number;
}

/** Saved in localStorage to keep track of multiple active organization logins */
export class SignInData {
	orgAlias: string;
	userId: number;
	token: string;
	email: Nullable<string>;
	isPlatformAdmin: boolean;
	isFinalized: boolean;
	loginMethod: LoginMethod;
	/** Tracks active company - only avaliable on FE */
	companyId?: number;
	/** name from URL */
	companyName?: string;

	constructor(
		orgAlias: string,
		userId: number,
		token: string,
		isPlatformAdmin: boolean,
		isFinalized: boolean,
		email: Nullable<string>,
		loginMethod: LoginMethod,
		companyId?: number,
		companyName?: string
	) {
		this.orgAlias = orgAlias;
		this.userId = userId;
		this.token = token;
		this.isPlatformAdmin = isPlatformAdmin;
		this.isFinalized = isFinalized;
		this.email = email;
		this.companyId = companyId;
		this.companyName = companyName;
		this.loginMethod = loginMethod;
	}
}

export class OrganizationData {
	organizationId: number;
	name: string;
	alias: string;
	/** Can be empty if accounts not passed */
	companies: CompanyData[];
	isPlatformAdmin: boolean;

	public static async initialize(organization: OrganizationBase, accounts: AccountBase[]) {
		const _companies = await Promise.all((accounts ?? [])
			.map(CompanyData.initialize));

		return {
			organizationId: organization.id,
			name: organization.name,
			alias: organization.alias,
			isPlatformAdmin: organization.isPlatformAdmin,
			companies: _companies
				.sort((_company1: CompanyData, _company2: CompanyData) => (_company1.name.localeCompare(_company2.name))),
		};
	}
}

export class UserData {
	/** -1 if platform admin */
	id: number;
	firstName: string;
	lastName: string;
	token: Nullable<string>;
	email: Nullable<string>;
	phoneNumber: Nullable<string>;
	countryCode: Nullable<CountryCode>;
	isFinalized: boolean;
	status: MemberInviteEnum;
	role: UserPermission;
	imageUrl: string;
	offlinePin: Nullable<string>;
	isDigitalSignatureEnabled: boolean;
	digitalSignatureUrl: Nullable<string>;
	digitalSignatureId: Nullable<number>;
	showCreateDigitalSignature: boolean;
	isPasswordExpired: boolean;
	loginMethod: LoginMethod;
	passwordResetRequired: boolean;
	activeInLMS: boolean;

	constructor(user: UserBase, loginMethod: LoginMethod, token?: string) {
		this.id = user.id;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.token = token ?? null;
		this.email = user.email;
		this.phoneNumber = user.phoneNumber;
		this.countryCode = user.countryCode;
		this.isFinalized = user.isFinalizedByEmail;
		this.status = user.status;
		this.role = user.role;
		this.offlinePin = user.offlinePin;
		this.isDigitalSignatureEnabled = user.isDigitalSignatureEnabled;
		this.showCreateDigitalSignature = user.showCreateDigitalSignature;
		this.isPasswordExpired = user.passwordHistory?.length ? TimeUtils.getDiff(new Date(), user.passwordHistory[0].createdAt, 'days') > PASSWORD_MAX_AGE : false;
		this.loginMethod = loginMethod;
		this.passwordResetRequired = user.passwordResetRequired;

		const signature = user.digitalSignature;
		this.digitalSignatureUrl = (user.isDigitalSignatureEnabled && signature)
			? getStorageUrl(signature.signatureAttachment.storageContainer, signature.signatureAttachment.storageName)
			: null;
		this.digitalSignatureId = (user.isDigitalSignatureEnabled && signature)
			? signature.id
			: null;

		const result = user.imageUrl && BlobStorageUtil.parseStorageUrl(user.imageUrl);
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}
		this.activeInLMS = user.activeInLMS;
	}
}

export class CompanyData {
	id: number;
	name: string;
	permissions: string[];
	accountId: number;
	accountTemplate: AccountPermissionTemplate;
	logo: string;
	isCompanyAdmin: boolean;
	assignableToWorkOrder: boolean;
	assignableAsSuperintendent: boolean;
	assignableAsProjectManager: boolean;
	assignableAsQAQC: boolean;
	assignableAsManagement: boolean;
	assignableAsTechnician: boolean;
	assignableAsAccounting: boolean;
	complianceEhsUrl: Nullable<string>;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	purchaseOrderNumberPrefix: Nullable<string>;
	customPDFHeaderUrl: Nullable<string>;
	website: Nullable<string>;
	location: Nullable<string>;

	public static async initialize(account: AccountBase) {
		const parseResult = account.company.imageUrl ? BlobStorageUtil.parseStorageUrl(account.company.imageUrl) : null;
		const customPDFHeaderParseResult = account.company.customPDFHeaderUrl ? BlobStorageUtil.parseStorageUrl(account.company.customPDFHeaderUrl) : null;
		let customPDFHeaderUrl = '';
		if (customPDFHeaderParseResult) {
			customPDFHeaderUrl = await BlobStorageUtil2.generatePresignedGetUrl(customPDFHeaderParseResult.directories, customPDFHeaderParseResult.filename);
		}

		return {
			name: account.company.name,
			id: account.company.id,
			permissions: account.permissions.map((_permission: PermissionBase) => (_permission.permission.toString())),
			accountId: account.id,
			accountTemplate: account.accountTemplate,
			logo: parseResult ? BlobStorageUtil.getStorageUrl(parseResult.directories, parseResult.filename) : '',
			isCompanyAdmin: account.isCompanyAdmin,
			assignableToWorkOrder: account.assignableToWorkOrder,
			assignableAsSuperintendent: account.assignableAsSuperintendent,
			assignableAsProjectManager: account.assignableAsProjectManager,
			assignableAsQAQC: account.assignableAsQAQC,
			assignableAsManagement: account.assignableAsManagement,
			assignableAsTechnician: account.assignableAsTechnician,
			assignableAsAccounting: account.assignableAsAccounting,
			complianceEhsUrl: account.company.complianceEhsUrl,
			areWorkRequestsEnabled: account.company.areWorkRequestsEnabled,
			arePortalFunctionalitiesEnabled: account.company.arePortalFunctionalitiesEnabled,
			isSendingInvoiceNotificationsEnabled: account.company.isSendingInvoiceNotificationsEnabled,
			purchaseOrderNumberPrefix: account.company.purchaseOrderNumberPrefix,
			customPDFHeaderUrl,
			website: account.company.website,
			location: account.company.primaryAddress?.street,
		};
	}
}

/** NOTE: This view model is kept in localstorage on FE. Do not send entire User data from here */
export class UserViewModel {
	organization: OrganizationData;
	user: UserData;
	systemNotifications?: SystemNotificationRequestModel[];

	public static async initialize(user: UserBase, loginMethod: LoginMethod, token: string, systemNotifications: SystemNotificationBase[] = []) {
		const _user = new UserData(user, loginMethod, token);
		const _organization = await OrganizationData.initialize(user.organization, user.accounts);
		const _systemNotifications = SystemNotificationRequestModel.fromModelListToRequestModelList(systemNotifications);

		return {
			user: _user,
			organization: _organization,
			systemNotifications: _systemNotifications,
		};
	}
}
