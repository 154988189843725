import type { TextColor, ColorPalette } from '@acceligentllc/shared/enums/color';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import type { DeliverableReviewTypeEnum } from '@acceligentllc/shared/enums/deliverableReviewType';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type * as DeliverableVM from 'ab-viewModels/deliverable.viewModel';

import type * as UpdateDeliverableSubmissionAPI from 'ab-api/web/deliverable/updateDeliverableSubmission';

class DeliverableStatusFM {
	id: number;
	name: string;
	abbreviation: string;
	description: Nullable<string>;
	statusColor: ColorPalette;
	textColor: TextColor;
	notify: boolean;
	isCompleted: boolean;
	isJobStatus: boolean;
}

export default class DeliverableSubmissionFM {
	id: number;
	statusId: Nullable<number>;
	status: Nullable<DeliverableStatusFM>;
	/** MM-DD-YYYY */
	date: Nullable<string>;
	reviewType: Nullable<DeliverableReviewTypeEnum>;
	notes: Nullable<string>;
	trackingNumber: Nullable<string>;
	trackingLink: Nullable<string>;

	static fromVM = (vm: DeliverableVM.DeliverableSubmissionViewModel): DeliverableSubmissionFM => {
		return {
			id: vm.id,
			statusId: vm.statusId,
			status: vm.status,
			date: vm.date && TimeUtils.formatDate(vm.date, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY),
			reviewType: vm.reviewType,
			notes: vm.notes,
			trackingNumber: vm.trackingNumber,
			trackingLink: vm.trackingLink,
		};
	};

	static toRM = (fm: DeliverableSubmissionFM): UpdateDeliverableSubmissionAPI.W_Deliverable_UpdateDeliverableSubmission_RM => {
		if (!fm.statusId || !fm.reviewType) {
			throw new Error('Deliverable submission invalid');
		}
		return {
			id: fm.id,
			statusId: fm.statusId,
			date: fm.date ? TimeUtils.formatDate(fm.date, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY) : null,
			reviewType: fm.reviewType,
			notes: fm.notes,
			trackingNumber: fm.trackingNumber,
			trackingLink: fm.trackingLink,
		};
	};
}
