import type { ColorPalette } from '@acceligentllc/shared/enums/color';

import type CreateTemporaryEmployeeAssignmentRM from 'ab-requestModels/workOrderTemporaryEmployee/createTemporaryEmployeeAssignment';

import type TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

export class TemporaryEmployeeForm {
	id: number;
	fullName: string;
	uniqueId: string;
	agency: string;
	agencyColor: ColorPalette;

	static fromViewModel(employee: TemporaryEmployeeOptionVM): TemporaryEmployeeForm {
		return {
			id: employee.id,
			fullName: employee.fullName,
			uniqueId: employee.uniqueId,
			agency: employee.agency,
			agencyColor: employee.agencyColor,
		};
	}
}

export interface CreateTemporaryEmployeeForm {
	firstName: string;
	lastName: string;
	agencyId: Nullable<number>;
}

class CreateTemporaryEmployeeAssignmentForm {
	workOrderId: number;
	index: number;
	temporaryEmployee: Nullable<TemporaryEmployeeForm>;
	newTemporaryEmployee: Nullable<CreateTemporaryEmployeeForm>;

	static toRequestModel(form: CreateTemporaryEmployeeAssignmentForm): CreateTemporaryEmployeeAssignmentRM {
		if (form.temporaryEmployee?.id) {
			return {
				index: form.index,
				temporaryEmployeeId: form.temporaryEmployee?.id ?? null,
			};
		} else if (form.newTemporaryEmployee) {
			if (!form.newTemporaryEmployee.agencyId) {
				throw new Error('Agency is required');
			}

			return {
				index: form.index,
				temporaryEmployee: {
					firstName: form.newTemporaryEmployee.firstName,
					lastName: form.newTemporaryEmployee.lastName,
					agencyId: form.newTemporaryEmployee.agencyId,
				},
			};
		}
		throw new Error('Can\'t create CreateTemporaryEmployeeAssignmentRM, invalid form values provided');
	}
}

export default CreateTemporaryEmployeeAssignmentForm;
