import * as React from 'react';
import { Field } from 'redux-form';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import Dropdown from 'af-fields/Dropdown';

interface TimeIntervalOption {
	id: number;
	label: string;
	value: number;
}

interface OwnProps {
	end: number;
	interval: number;
	label: string;
	name: string;
	onChange: (value: number) => void;
	start: number;
	fixed?: boolean;
	className?: string;
	inputClassName?: string;
	isStandalone?: boolean;
}

type Props = OwnProps;

interface State {
	options: TimeIntervalOption[];
}

class TimeIntervalDropdown extends React.PureComponent<Props, State> {

	/** Default duration start time in minutes */
	static DEFAULT_START = 0;
	/** Default duration end time in minutes */
	static DEFAULT_END = 1440;

	static defaultProps: Partial<Props> = {
		start: TimeIntervalDropdown.DEFAULT_START,
		end: TimeIntervalDropdown.DEFAULT_END,
		interval: DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL,
		fixed: true,
	};

	state: State = {
		options: TimeIntervalDropdown.getIntervals(this.props.start, this.props.end, this.props.interval),
	};

	static shouldRecalculateOptions = (prevProps: Props, currentProps: Props) => {
		return prevProps.start !== currentProps.start || prevProps.end !== currentProps.end || prevProps.interval !== currentProps.interval;
	};

	static getIntervals = (start: number, end: number, interval: number) => {
		const result: TimeIntervalOption[] = [];

		for (let startInterval = start, i = 0; startInterval <= end; startInterval += interval, i++) {
			result.push({ id: i, value: startInterval, label: TimeUtils.minutesToHoursAndMinutes(startInterval) });
		}
		return result;
	};

	componentDidUpdate(prevProps: OwnProps): void {
		const { start, end, interval } = this.props;

		if (TimeIntervalDropdown.shouldRecalculateOptions(prevProps, this.props)) {
			this.setState(() => ({ options: TimeIntervalDropdown.getIntervals(start, end, interval) }));
		}
	}

	handleChange = (option: TimeIntervalOption) => {
		const { onChange } = this.props;
		onChange(option?.value);
	};

	render() {
		const { name, label, fixed, className, inputClassName, isStandalone } = this.props;
		const { options } = this.state;

		return (
			<Field
				className={className}
				component={Dropdown}
				defaultValue={options?.[0]}
				fixed={fixed}
				id="id"
				inputClassName={inputClassName}
				isStandalone={isStandalone}
				label={label}
				labelKey="label"
				name={name}
				onValueChange={this.handleChange}
				options={options}
				valueKey="value"
				withCaret={true}
			/>
		);
	}
}

export default TimeIntervalDropdown;
