import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import { downloadCSV } from 'af-utils/csv.utils';

import { bemElement } from 'ab-utils/bem.util';

import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';

import Dropdown from 'af-components/Controls/Dropdown';

import type { WorkSummaryTableSortMeta } from '../Table/values';
import { WORK_SUMMARY_TABLE_SORT_META, WorkSummaryTableSortKey } from '../Table/values';
import WorkSummaryDetailsTable from './WorkSummaryDetailsTable';

interface OwnProps {
	workSummaryDetails: WorkSummaryDetailVM[];
	onBillingStatusToggle: (workSummaryDetail: WorkSummaryDetailVM) => Promise<void>;
	onRowHighlight: (groupToHighlight: Nullable<string>, groupKeyToHighlight: Nullable<string>) => void;
	highlightedGroup: Nullable<string>;
	highlightedGroupKey: Nullable<string>;
	isReadOnly: boolean;
	workOrderCode: string;
	fieldReportId: number;
	onBulkStatusToFalse: () => Promise<void>;
	workSummaryDetailsByGroupKey: Record<string, WorkSummaryDetailVM[]>;
	pathName: string;
	alternativeWorkSummaryDetails: Set<WorkSummaryDetailVM>;
	allowToMarkAllAsNonBillable: boolean;
	canResetJobWorkSummary: boolean;
	resetJobWorkSummary: (fieldReportId: number) => Promise<void>;
}

type Props = OwnProps;

const workSummaryDetailsOptions = Object.values(WORK_SUMMARY_TABLE_SORT_META);

const WorkSummaryDetails: React.FC<Props> = (props) => {
	const {
		workSummaryDetails,
		onBillingStatusToggle,
		onBulkStatusToFalse,
		onRowHighlight,
		highlightedGroup,
		isReadOnly,
		workOrderCode,
		highlightedGroupKey,
		workSummaryDetailsByGroupKey,
		pathName,
		fieldReportId,
		alternativeWorkSummaryDetails,
		allowToMarkAllAsNonBillable,
		canResetJobWorkSummary,
		resetJobWorkSummary,
	} = props;

	const [sortBy, setSortBy] = React.useState<WorkSummaryTableSortKey>(WorkSummaryTableSortKey.TYPE);

	const onWorkSummaryDetailsOptionsChange = React.useCallback(({ id }: WorkSummaryTableSortMeta<WorkSummaryTableSortKey>) => {
		setSortBy(id);
	}, []);

	const onExportClick = React.useCallback(() => {
		const csvData = WorkSummaryDetailVM.toCSVData(workSummaryDetails);
		downloadCSV(csvData, `${workOrderCode}_work_summary_details.csv`);
	}, [workOrderCode, workSummaryDetails]);

	const handleResetJobWorkSummary = React.useCallback(async () => {
		if (!canResetJobWorkSummary) {
			return;
		}
		await resetJobWorkSummary(fieldReportId);
	}, [canResetJobWorkSummary, fieldReportId, resetJobWorkSummary]);

	const containerClassName = bemElement('field-report__work-summary', 'details');
	const headerClassName = bemElement(containerClassName, 'header');
	const headerTitleClassName = bemElement(headerClassName, 'title');
	const actionsClassName = bemElement(containerClassName, 'actions');

	const showHint = sortBy !== WorkSummaryTableSortKey.TYPE;

	return (
		<div className={containerClassName}>
			<div className={headerClassName}>
				<span className={headerTitleClassName}>
					Details
				</span>
				<Button
					icon="download"
					label="Export"
					onClick={onExportClick}
					style="link"
				/>
				<Dropdown
					containerClassName={bemElement('field-report__work-summary__details__header', 'sort-by')}
					defaultValue={workSummaryDetailsOptions[0]}
					label="Sort By"
					labelKey="label"
					onValueChange={onWorkSummaryDetailsOptionsChange}
					options={workSummaryDetailsOptions}
					withBorder={false}
					withCaret={true}
				/>
				{showHint && (
					<span className={bemElement(headerTitleClassName, 'hint')}>
						To enable three-dot menu use sort by type
					</span>
				)}
			</div>
			<WorkSummaryDetailsTable
				alternativeWorkSummaryDetails={alternativeWorkSummaryDetails}
				fieldReportId={fieldReportId}
				highlightedGroup={highlightedGroup}
				highlightedGroupKey={highlightedGroupKey}
				isReadOnly={isReadOnly}
				onBillingStatusToggle={onBillingStatusToggle}
				onRowHighlight={onRowHighlight}
				pathName={pathName}
				sortBy={sortBy}
				workSummaryDetails={workSummaryDetails}
				workSummaryDetailsByGroupKey={workSummaryDetailsByGroupKey}
			/>
			<div className={actionsClassName}>
				{allowToMarkAllAsNonBillable && (
					<Button
						label="Mark all as Non-Billable"
						onClick={onBulkStatusToFalse}
						style="secondary"
					/>
				)}
				{canResetJobWorkSummary && (
					<Button
						label="Reset Job Work Summary"
						onClick={handleResetJobWorkSummary}
						style="secondary"
					/>
				)}
			</div>
		</div>
	);
};

export default React.memo(WorkSummaryDetails);
