import * as React from 'react';
import { Button } from 'react-bootstrap';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import type { getSegmentCount } from 'ab-utils/fieldReport.util';

import CustomModal from 'af-components/CustomModal';

import FieldReportBlock from './FieldReportBlock';
import styles from './styles.module.scss';

type Props = {
	closeModal: () => void;
	showModal: boolean;
	currentFieldReportType: FieldReportTypeSidePanelVM;
	fieldReportTypeTitle: string;
	segmentCountMap: ReturnType<typeof getSegmentCount>;
	instanceCount: number;
	// NEXT PHASE
	// onExportAsPDFClick: (fieldReportTypeIds: number[]) => () => Promise<void>;
};

const FieldReportTypeSidePanelModal: React.FC<Props> = (props) => {
	const { closeModal, showModal, currentFieldReportType, fieldReportTypeTitle, instanceCount, segmentCountMap } = props;

	const fieldReportBlockMapper = React.useCallback((_fieldReportBlock) => {
		const segmentCount = _fieldReportBlock.instanceIndex !== null ? segmentCountMap[_fieldReportBlock.instanceIndex] : 0;

		return (
			<FieldReportBlock
				fieldReportBlock={_fieldReportBlock}
				instanceCount={instanceCount}
				key={_fieldReportBlock.id}
				segmentCount={segmentCount}
			/>
		);
	}, [instanceCount, segmentCountMap]);

	return (
		<CustomModal
			closeModal={closeModal}
			showModal={showModal}
			size="xxl"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={`${fieldReportTypeTitle} (Read-only)`}
			/>
			<CustomModal.Body className={styles['side-panel-modal']}>
				{currentFieldReportType.fieldReportBlocks.map(fieldReportBlockMapper)}
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Close
				</Button>
				{/* <Button
					onClick={onExportAsPDFClick([currentFieldReportType.id])}
					variant="primary"
				>
					Export as PDF
				</Button> */}
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default React.memo(FieldReportTypeSidePanelModal);
