import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as queryString from 'query-string';
import { Button } from '@acceligentllc/storybook';

import { PASSWORD_CATEGORIES, PASSWORD_LENGTH_MIN } from 'ab-common/constants/value';

import type { RootState } from 'af-reducers';

import * as AuthenticationActions from 'af-actions/authentication';

import CLIENT from 'af-constants/routes/client';

import type * as UserRequestModel from 'ab-requestModels/users.requestModel';

import Input from 'af-fields/Input';

import { USER_RESET_PASSWORD } from 'af-constants/reduxForms';

import validate from 'af-root/scenes/Authentication/ResetPassword/validations';

import styles from './styles.module.scss';

type OwnProps = CustomRouteComponentProps;
type FormOwnProps = ConnectedProps<typeof connector> & OwnProps;
type Props = FormOwnProps & InjectedFormProps<UserRequestModel.ResetPassword, FormOwnProps>;

enum PwCategories {
	UPPERCASE = 'uppercase letters',
	LOWERCASE = 'lowercase letters',
	NUMBERS = 'numbers',
	SYMBOLS = 'symbols'
}

class ResetPassword extends React.PureComponent<Props> {

	resetPassword = async (form: UserRequestModel.ResetPassword) => {
		const { history, resetPassword, location, location: { state: { orgAlias } } } = this.props;

		const queryParams: Metadata = queryString.parse(location.search);
		const formWithParams: UserRequestModel.ResetPassword = Object.assign(queryParams, form) as UserRequestModel.ResetPassword;
		formWithParams.orgAlias = orgAlias;
		delete formWithParams.repeatedPassword;
		await resetPassword(formWithParams);
		history.push(CLIENT.AUTH.RESET_PASS_SUCCESS(orgAlias));
	};

	render(): JSX.Element {
		const { handleSubmit, submitting, invalid, formData } = this.props;
		return (
			<div className="form-segment form-segment--mini">
				<div className={`form-box form-box--standalone ${styles.container__padded}`}>
					<h2>Acceligent Password Reset</h2>
					<h4>Please enter new password below.</h4>
					<div>
						<Row className={styles.marginBottom}>
							<Col sm={24}>
								<Field
									component={Input}
									disableErrors={!(formData?.submitErrors && Object.keys(formData?.submitErrors).length)}
									id="password"
									label="New Password *"
									name="password"
									type="password"
								/>
							</Col>
						</Row>
						<Row className={styles.marginBottom}>
							<Col sm={24}>
								<Field
									component={Input}
									id="repeatedPassword"
									label="Repeat New Password *"
									name="repeatedPassword"
									type="password"
								/>
							</Col>
						</Row>
						<Row>
							<Col className={styles.container} sm={24}>
								<div className={`${styles.pwRequirementsElement}`}>Password requirements:</div>
								<div className={`${styles.pwRequirementsContainer}`}>{
									formData?.values?.password?.length >= PASSWORD_LENGTH_MIN ?
										<span className={`${styles.icon} icon-check_circle text-green`} /> :
										<span className={`${styles.icon} icon-dot ${styles.textGrey}`} />
								}
									<p>At least {PASSWORD_LENGTH_MIN} characters long</p>
								</div>
								<div>Contains characters from&nbsp;<strong>at least three</strong>&nbsp;of these categories:</div>
								{Object.keys(PwCategories).map((key) => {
									return <div className={`${styles.pwRequirementsContainer}`} key={key}>
										{validateCategory(formData?.values?.password, PwCategories[key]) ?
											<span className={`${styles.icon} icon-check_circle text-green`} /> :
											<span className={`${styles.icon} icon-dot ${styles.textGrey}`} />
										}
										<p>{PwCategories[key]}</p>
									</div>;
								})}
							</Col>
						</Row>
						<Row className={`${styles.submitButton} row--submit row-padded`}>
							<Col sm={24}>
								<Button
									disabled={invalid}
									fullWidth={true}
									label="Submit"
									onClick={handleSubmit(this.resetPassword)}
									style="primary"
									submitting={submitting}
								/>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

function validateCategory(values: string | undefined, category: PwCategories): boolean {
	const isValid = values && PASSWORD_CATEGORIES[category].test(values);
	return !!isValid;
}

function mapStateToProps(state: RootState) {
	return {
		formData: state.form.resetPasswordForm,
	};
}

function mapDispatchToProps() {
	return {
		resetPassword: AuthenticationActions.resetPassword,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<UserRequestModel.ResetPassword, FormOwnProps>({ form: USER_RESET_PASSWORD, validate })
);

export default enhance(ResetPassword);
