import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import SignButton from './SignButton';

import styles from './styles.module.scss';

interface OwnProps {
	disableStartEndValidation: boolean;
	showRejectButton: boolean;
	showSignButton: boolean;
	onRejectButtonClick: () => void;
	onSignButtonClick: () => void;
	endTime: Nullable<string>;
	startTime: Nullable<string>;
	hasOverlaps: boolean;
}

const TimeSheetApprovalActions = (props: OwnProps) => {
	const { disableStartEndValidation, showSignButton, showRejectButton, onRejectButtonClick, endTime, startTime, onSignButtonClick, hasOverlaps } = props;

	return (
		<div className={styles['approval-actions']}>
			{showRejectButton &&
				<Button
					label="Reject"
					onClick={onRejectButtonClick}
					style="danger"
				/>
			}
			{showSignButton &&
				<SignButton
					disableStartEndValidation={disableStartEndValidation}
					endTime={endTime}
					hasOverlaps={hasOverlaps}
					showSignatureModal={onSignButtonClick}
					startTime={startTime}
				/>
			}
		</div>
	);
};

export default TimeSheetApprovalActions;
