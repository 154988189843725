import * as React from 'react';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import type WorkOrderModalViewModel from 'ab-viewModels/scheduleBoardWorkOrderModal.viewModel';

import Changes from '../Changes';
import { renderWorkOrderInfoData } from '../';

import * as FormattingUtils from 'ab-utils/formatting.util';

import LockedValue from 'af-components/LockedValue';
import SegmentLabel from 'af-components/SegmentLabel';
import { calculateManHourAverage } from 'ab-utils/productionData.util';

interface OwnProps {
	workOrderDataToShow: WorkOrderModalViewModel;
	currentWorkOrderData: WorkOrderModalViewModel;
}

type Props = OwnProps;

const ProductionDataSection: React.FC<Props> = (props) => {

	const { workOrderDataToShow, currentWorkOrderData } = props;

	const isInternal = workOrderDataToShow?.isJobInternal;
	const _sectionHasChanges = sectionHasChanges();

	return (
		!isInternal &&
		<>
			<hr />
			<div className="work-order-info-modal__production-data-container">
				<Changes modifiedData={_sectionHasChanges} />
				<SegmentLabel label="Production data" />
				<div className="work-order-info-modal__production-data">
					<div>
						<LockedValue
							label="Revenue"
							value={renderWorkOrderInfoData(
								FormattingUtils.moneyNormalizer(workOrderDataToShow?.revenue),
								FormattingUtils.moneyNormalizer(currentWorkOrderData?.revenue)
							)}
						/>
					</div>
					<div>
						<LockedValue
							label="Man Hour Average"
							value={renderWorkOrderInfoData(
								manHourAverageValue(workOrderDataToShow),
								manHourAverageValue(currentWorkOrderData)
							)}
						/>
					</div>
					<div>
						<LockedValue
							label="Job Hours"
							value={renderWorkOrderInfoData(workOrderDataToShow?.jobHours ?? '-', currentWorkOrderData?.jobHours ?? '-')}
						/>
					</div>
				</div>
				<div className="work-order-info-modal__production-data">
					<div>
						<LockedValue
							label="Shop Hours"
							value={renderWorkOrderInfoData(workOrderDataToShow?.shopHours ?? '-', currentWorkOrderData?.shopHours ?? '-')}
						/>
					</div>
					<div>
						<LockedValue
							label="Travel Hours"
							value={renderWorkOrderInfoData(workOrderDataToShow?.travelHours ?? '-', currentWorkOrderData?.travelHours ?? '-')}
						/>
					</div>
				</div>
			</div>
		</>
	);

	function sectionHasChanges() {
		return workOrderDataToShow?.revenue !== currentWorkOrderData?.revenue
			|| workOrderDataToShow?.manHourAverage !== currentWorkOrderData?.manHourAverage
			|| workOrderDataToShow?.jobHours !== currentWorkOrderData?.jobHours
			|| workOrderDataToShow?.shopHours !== currentWorkOrderData?.shopHours
			|| workOrderDataToShow?.travelHours !== currentWorkOrderData?.travelHours
			|| workOrderDataToShow?.workDescription !== currentWorkOrderData?.workDescription;
	}

	function manHourAverageValue(workOrderData: WorkOrderModalViewModel) {
		const automaticCalculationAfter = new Date(process.env.AUTO_PROD_DATA_CUTOFF_DATE ?? '');
		const _dueDate: Date = new Date(TimeUtils.formatDate(workOrderData.dueDate, TimeFormat.DB_DATE_ONLY, TimeFormat.DATE_ONLY));

		if (automaticCalculationAfter >= _dueDate) {
			return workOrderData.manHourAverage;
		}

		const manHourAverage = calculateManHourAverage(
			workOrderData.revenue !== null ? Number(workOrderData.revenue) : null,
			workOrderData.jobHours ?? 0,
			workOrderData.travelHours ?? 0,
			workOrderData.shopHours ?? 0
		);

		if (manHourAverage === 'N/A') {
			return manHourAverage;
		}

		return FormattingUtils.moneyNormalizer(manHourAverage);
	}
};

export default ProductionDataSection;
