import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import type { BlockValueType } from '@acceligentllc/shared/utils/fieldReport';

import { bemElement } from 'ab-utils/bem.util';

import Field from './Field';
import { getFormName } from '../helpers';
import type { RequestQueue } from '../../helpers';

interface OwnProps {
	fieldReportId: number;
	blockId: string;
	isPreview: boolean;
	fieldReportTypeId: number;
	fields: string[];
	index: number;
	repeatingCount: number;
	isJustifiable: boolean;
	disabled: boolean;
	onFocus: () => void;
	remove: (index: number) => void;
	change: (fieldName: string, value: BlockValueType) => void;
	focusedBlockId: Nullable<number>;
	setFieldToFocus: (ref: HTMLDivElement) => void;
	removeFocusedField: () => void;
	requestQueue: RequestQueue;
}

type Props = OwnProps;

class RepeatableField extends React.PureComponent<Props> {

	removeField = () => {
		const { remove, index, requestQueue } = this.props;
		requestQueue.add(async () => remove(index));
	};

	renderField = (fieldId: string, key: number) => {
		const {
			index,
			change,
			fieldReportTypeId,
			fieldReportId,
			onFocus,
			blockId,
			disabled,
			isPreview,
			focusedBlockId,
			setFieldToFocus,
			removeFocusedField,
			requestQueue,
		} = this.props;

		return (
			<Field
				blockId={blockId}
				change={change}
				disabled={disabled}
				fieldId={fieldId}
				fieldName={getFormName(fieldId, index)}
				fieldReportId={fieldReportId}
				fieldReportTypeId={fieldReportTypeId}
				focusedBlockId={focusedBlockId}
				isPreview={isPreview}
				key={key}
				onFocus={onFocus}
				removeFocusedField={removeFocusedField}
				requestQueue={requestQueue}
				setFieldToFocus={setFieldToFocus}
			/>
		);
	};

	render() {
		const { fields, repeatingCount, isJustifiable, disabled, isPreview } = this.props;

		const className = bemElement('field-report-block', 'field-array', { justifiable: isJustifiable, preview: isPreview });
		return (
			<>
				<div className={className}>
					{fields.map(this.renderField)}
				</div>
				{(repeatingCount > 1 && !disabled && !isPreview) ?
					<Button
						icon="delete"
						onClick={this.removeField}
						style="link-danger"
					/>
					: <span />
				}
			</>
		);
	}
}

export default RepeatableField;
