import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Button } from '@acceligentllc/storybook';

import FormFieldArrayStatus from '@acceligentllc/shared/enums/formFieldArrayStatus';
import FieldReportAccessRole from '@acceligentllc/shared/enums/fieldReportAccessRole';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type AccessRM from 'ab-requestModels/fieldReport/fieldReportAccess.requestModel';

import type { FieldReportAccessOptionVM } from 'ab-viewModels/employee/fieldReportAccessOption.viewModel';

import ListElement from './ListElement';

export interface OwnProps {
	fieldReportAccessDayDuration: number;
	superintendents: FieldReportAccessOptionVM[];
}

type Props = OwnProps & WrappedFieldArrayProps<AccessRM['accessList'][0]>;

const List = (props: Props) => {
	const {
		fields,
		fieldReportAccessDayDuration,
		superintendents,
	} = props;

	const [defaultDateValue] = React.useState<string>(() => {
		const removeAccessAt = new Date();
		removeAccessAt.setDate(removeAccessAt.getDate() + fieldReportAccessDayDuration);
		return TimeUtils.formatDate(removeAccessAt, TimeFormat.DB_DATE_ONLY);
	});
	const [superintendentOptions, setSuperintendentOptions] = React.useState(superintendents ?? []);

	const create = React.useCallback(() => {
		fields.push({
			role: FieldReportAccessRole.SUPERINTENDENT,
			status: FormFieldArrayStatus.ADDED,
			isAllowed: true,
		} as AccessRM['accessList'][0]);
	}, [fields]);

	const read = React.useCallback((index: number) => {
		return fields.get(index);
	}, [fields]);

	const update = React.useCallback((index: number, value: AccessRM['accessList'][0]) => {
		const old = fields.get(index);

		const removeAccessAt = !!value.removeAccessAt ? TimeUtils.formatDate(value.removeAccessAt, TimeFormat.DB_DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null;
		const next = {
			...old,
			isAllowed: value.isAllowed ?? old.isAllowed,
			removeAccessAt,
			status: old.status === FormFieldArrayStatus.ADDED ? FormFieldArrayStatus.ADDED : FormFieldArrayStatus.EDITED,
		};
		fields.splice(index, 1, next);
	}, [fields]);

	const remove = React.useCallback((index: number) => {
		const old = fields.get(index);
		if (old.status === FormFieldArrayStatus.ADDED) {
			fields.remove(index);
		} else {
			fields.splice(index, 1, {
				...old,
				status: FormFieldArrayStatus.REMOVED,
			});
		}
	}, [fields]);

	React.useEffect(() => {
		const employees = fields.getAll();
		const employeeMap = employees?.reduce((_acc, _emp) => {
			if (_emp.status !== FormFieldArrayStatus.REMOVED) {
				_acc[_emp.employee?.accountId] = true;
			}
			return _acc;
		}, {} as { [accountId: number]: true; }) ?? {};

		setSuperintendentOptions(superintendents.filter((_si) => !employeeMap[_si.accountId]));
	}, [fields, superintendents]);

	const renderEmployee = (field: string, index: number) => (
		<ListElement
			defaultDateValue={defaultDateValue}
			field={field}
			index={index}
			key={index}
			read={read}
			remove={remove}
			superintendents={superintendentOptions}
			update={update}
		/>
	);

	return (
		<>
			<Row className="field-report-access-modal__row field-report-access-modal__row--header">
				<Col sm={6}>Employee</Col>
				<Col sm={6}>Role on Work Order</Col>
				<Col sm={12}>Access to Field Report</Col>
			</Row>
			{fields.map(renderEmployee)}
			<Row>
				<Col sm={6}>
					<Button
						icon="plus"
						label="Add Superintendent"
						onClick={create}
						style="link"
					/>
				</Col>
			</Row>
		</>
	);
};

export default React.memo(List);
