import * as React from 'react';
import { Col } from 'react-bootstrap';
import { compose } from 'redux';
import { FieldArray, formValueSelector } from 'redux-form';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type AssignableResourceType from '@acceligentllc/shared/enums/assignableResourceType';

import type { RootState } from 'af-reducers';

import type { EmployeeOptionVM } from 'ab-viewModels/employee/extendedOption.viewModel';
import type EquipmentViewModel from 'ab-viewModels/equipment.viewModel';
import type TemporaryEmployeeOptionVM from 'ab-viewModels/temporaryEmployee/temporaryEmployeeOption.viewModel';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { WORK_ORDER_FORM } from 'af-constants/reduxForms';

import { isAllowed } from 'ab-utils/auth.util';

import type WorkOrderFM from '../../formModel';
import Section from '../../Shared/Section';

import type { OwnProps as ResourceLookupsProps } from './ResourceLookupArray';
import ResourceLookups from './ResourceLookupArray';

interface OwnProps {
	change: (fieldName: string, value: WorkOrderFM['workOrderResourceLookups']) => void;
	disabled: boolean;
	employees: EmployeeOptionVM[];
	equipment: EquipmentViewModel[];
	isCancelled: boolean;
	temporaryEmployees: TemporaryEmployeeOptionVM[];
	loadEmployees: (isLazyLoaded: boolean) => void;
	loadEquipment: (isLazyLoaded: boolean) => void;
	loadTemporaryEmployees: (isLazyLoaded: boolean) => void;
	resetTimer: () => void;
	showResourceAssignConfirmationModal: (resourceType: AssignableResourceType) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const WorkOrderResources: React.FC<Props> = (props: Props) => {
	const {
		change,
		disabled,
		dueDate,
		employees,
		equipment,
		temporaryEmployees,
		hasManagePerDiemPermission,
		isCancelled,
		loadEmployees,
		loadEquipment,
		loadTemporaryEmployees,
		resetTimer,
		selector,
		showResourceAssignConfirmationModal,
		workOrderId,
	} = props;

	return (
		<Section label="Labor and Equipment">
			<Col sm={24}>
				<FieldArray<ResourceLookupsProps>
					change={change}
					component={ResourceLookups}
					disabled={disabled}
					dueDate={dueDate}
					employees={employees}
					equipment={equipment}
					isAllowedToManagePerDiems={hasManagePerDiemPermission}
					isCancelled={isCancelled}
					loadEmployees={loadEmployees}
					loadEquipment={loadEquipment}
					loadTemporaryEmployees={loadTemporaryEmployees}
					name="workOrderResourceLookups"
					resetTimer={resetTimer}
					selector={selector}
					showResourceAssignConfirmationModal={showResourceAssignConfirmationModal}
					temporaryEmployees={temporaryEmployees}
					workOrderId={workOrderId}
				/>
			</Col>
		</Section>
	);
};

const selector = formValueSelector(WORK_ORDER_FORM);

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManagePerDiemPermission = isAllowed(PagePermissions.COMPANY.PER_DIEMS, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	const {
		id: workOrderId,
		dueDate,
	} = selector(state, 'id', 'dueDate');

	return {
		workOrderId,
		dueDate,
		hasManagePerDiemPermission,
		selector: (fieldName: string) => selector(state, fieldName),
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentClass<OwnProps>>(connector);

export default enhance(WorkOrderResources);
