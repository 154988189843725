import * as React from 'react';

import type { OverlapMeta } from '@acceligentllc/shared/utils/timeSheetEntry';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimelineEntityType from '@acceligentllc/shared/enums/timelineEntityType';

import type { TimeSheetVM } from 'ab-viewModels/timeSheet/timeSheet.viewModel';
import type { TimelineEntitesForAccount } from 'ab-viewModels/timeSheet/timeSheetEntry.viewModel';

import OccupiedSection from './TimelineBarSection/OccupiedSection';
import EntrySection from './TimelineBarSection/EntrySection';
import AdjustableBackground from './AdjustableBackground';
import BarsOnDateChanges from './BarsOnDateChanges';

import styles from './styles.module.scss';
import type { DataForTimeline } from './types';
import { DateChangeManager, calculateDateChangeDataForEntireTimeline, getEntitiesWithMergedOverlaps, getEntitySectionData, totalDurationWithoutGapReducer } from './helpers';

interface Props {
	entities: TimelineEntitesForAccount[];
	overlaps: Nullable<Record<string, OverlapMeta>>;
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'];
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'];
	isManagerOrAdmin: boolean;
	dueDate: string;
}

const entitiesMapper = (
	entities: TimelineEntitesForAccount[],
	overlaps: Nullable<Record<string, OverlapMeta>>,
	employeeApprovalStatus: TimeSheetVM['employeeApprovalStatus'],
	superintendentApprovalStatus: TimeSheetVM['superintendentApprovalStatus'],
	isManagerOrAdmin: boolean,
	totalDurationInMinutesWithoutGaps: number,
	dueDate: string
) => {

	return (tse: TimelineEntitesForAccount, _index: number) => {
		const {
			percentageOfTimeline,
			hasOverlap,
			roundedLeft,
			roundedRight,
			sectionPercentagesForDueDate,
			dateChangesOnSectionInPercentages,
			entityKey,
			nextEntryIsGap,
		} = getEntitySectionData(entities, overlaps, totalDurationInMinutesWithoutGaps, tse, _index, dueDate);

		switch (tse.type) {
			case TimelineEntityType.ENTRY:
				return (
					<EntrySection
						dateChangesOnSectionInPercentages={dateChangesOnSectionInPercentages}
						dueDate={dueDate}
						employeeApprovalStatus={employeeApprovalStatus}
						entity={tse}
						isManagerOrAdmin={isManagerOrAdmin}
						key={entityKey}
						nextEntryIsGap={nextEntryIsGap}
						overlap={hasOverlap}
						percentageOfTimeline={percentageOfTimeline}
						roundedLeft={roundedLeft}
						roundedRight={roundedRight}
						sectionPercentagesForDueDate={sectionPercentagesForDueDate}
						superintendentApprovalStatus={superintendentApprovalStatus}
						workType={tse.entry.workType}
					/>);
			case TimelineEntityType.OCCUPIED:
				return (
					<OccupiedSection
						dateChangesOnSectionInPercentages={dateChangesOnSectionInPercentages}
						dueDate={dueDate}
						entity={tse}
						isManagerOrAdmin={isManagerOrAdmin}
						key={entityKey}
						nextEntryIsGap={nextEntryIsGap}
						overlap={hasOverlap}
						percentageOfTimeline={percentageOfTimeline}
						roundedLeft={roundedLeft}
						roundedRight={roundedRight}
						sectionPercentagesForDueDate={sectionPercentagesForDueDate}
						workOrderCode={tse.entry.workOrderCode}
					/>
				);
			case TimelineEntityType.GAP:
				return;
			default:
				throw new Error('Section of unrecognized type.');
		}
	};
};

const TimelineBar: React.FC<Props> = (props: Props) => {
	const {
		entities,
		overlaps,
		employeeApprovalStatus,
		isManagerOrAdmin,
		superintendentApprovalStatus,
		dueDate,
	} = props;

	const [timelineWidth, setTimelineWidth] = React.useState(0);
	const timelineRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (!timelineRef.current) {
			return;
		}
		setTimelineWidth(timelineRef.current.clientWidth);
	}, []);

	const entitiesWithMergedOverlaps = React.useMemo(() => getEntitiesWithMergedOverlaps(entities, overlaps), [entities, overlaps]);
	const totalDurationInMinutesWithoutGaps = React.useMemo(() => entitiesWithMergedOverlaps.reduce<number>(totalDurationWithoutGapReducer, 0),
		[entitiesWithMergedOverlaps]
	);
	const dateChangeDataForEntireTimeline = React.useMemo(() => calculateDateChangeDataForEntireTimeline(
		entitiesWithMergedOverlaps,
		totalDurationInMinutesWithoutGaps,
		dueDate,
		new DateChangeManager()
	), [dueDate, entitiesWithMergedOverlaps, totalDurationInMinutesWithoutGaps]);

	const dateOtherThanDueDate = getTimelineDate(dateChangeDataForEntireTimeline, entities);
	const dateToShowInBackground = dateOtherThanDueDate ?? dueDate;
	const isTimelineOnDueDate = dateChangeDataForEntireTimeline.wasAnySectionOnDueDate;
	const shouldExpandTimeline = getShouldExpandTimeline(dateChangeDataForEntireTimeline);
	const isDateToShowOnDueDate = getIsDateToShowOnDueDate(dateChangeDataForEntireTimeline);
	const showDate = getShouldShowDate(dateChangeDataForEntireTimeline, shouldExpandTimeline, isTimelineOnDueDate);

	const stripedContainerStyle = getStripedContainerStyle();

	return (
		<>
			{(entities?.length > 0) &&
				<div className={stripedContainerStyle} >
					<div className={styles['timeline__bar-sections-container']} ref={timelineRef}>
						<AdjustableBackground
							date={dateToShowInBackground}
							isDateToShowOnDueDate={isDateToShowOnDueDate}
							isTimelineOnDueDate={isTimelineOnDueDate}
							sectionPercentagesForDueDate={dateChangeDataForEntireTimeline.percentagesForChangeToDueDate}
							showDate={showDate}
						/>
						<BarsOnDateChanges
							dateChangesOnSectionInPercentages={dateChangeDataForEntireTimeline.percentagesOfDateChanges}
							timelineWidth={timelineWidth}
						/>
						{
							entitiesWithMergedOverlaps.map(entitiesMapper(
								entitiesWithMergedOverlaps,
								overlaps,
								employeeApprovalStatus,
								superintendentApprovalStatus,
								isManagerOrAdmin,
								totalDurationInMinutesWithoutGaps,
								dueDate
							))
						}
					</div>
				</div>
			}
		</>
	);

	/**
	 *
	 * @param date ISO_DATETIME
	 */
	function isOnSameDateAsDueDate(date: string) {
		const parsedDate = TimeUtils.formatDate(date, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
		if (parsedDate === dueDate) {
			return true;
		}
		return false;
	}

	function shouldCoverStripesOnLeft() {
		if (!entities.length) {
			throw new Error('No entities to show.');
		}

		return isOnSameDateAsDueDate(entities[0].entry.startTime);
	}

	function shouldCoverStripesOnRight() {
		const totalEntities = entities.length;
		if (!totalEntities) {
			throw new Error('No entities to show.');
		}

		const lastEntityEndTime = entities[totalEntities - 1].entry.endTime;
		if (lastEntityEndTime) {
			return isOnSameDateAsDueDate(lastEntityEndTime);
		}

		return isOnSameDateAsDueDate(entities[totalEntities - 1].entry.startTime);
	}

	function getStripedContainerStyle() {
		const containerStyles = ['timeline__bar-container'];

		if (shouldCoverStripesOnLeft()) {
			containerStyles.push('timeline__bar-container__cover-left');

		}

		if (shouldCoverStripesOnRight()) {
			containerStyles.push('timeline__bar-container__cover-right');
		}

		if (shouldExpandTimeline) {
			containerStyles.push('timeline__bar-container__expand');
		}

		return containerStyles.map((style) => styles[style]).join(' ');
	}

	function getTimelineDate(_dateChangeDataForEntireTimeline: DataForTimeline, _entities: TimelineEntitesForAccount[]) {
		if (_entities.length === 0) {
			return null;
		}
		if (!_dateChangeDataForEntireTimeline.percentagesOfDateChanges.length
			&& !_dateChangeDataForEntireTimeline.wasAnySectionOnDueDate) {
			return TimeUtils.formatDate(_entities[0].entry.startTime, TimeFormat.DB_DATE_ONLY, TimeFormat.ISO_DATETIME);
		}
		return null;
	}

	function getShouldExpandTimeline(_dateChangeDataForEntireTimeline: DataForTimeline) {

		// We want to expand if there are any date changes
		if (_dateChangeDataForEntireTimeline.percentagesOfDateChanges.length) {
			return true;
		}

		// We want to expand if there are no date changes but there was no due date on the timeline
		if (!_dateChangeDataForEntireTimeline.percentagesOfDateChanges.length
			&& !_dateChangeDataForEntireTimeline.wasAnySectionOnDueDate) {
			return true;
		}

		return false;
	}

	function getShouldShowDate(_dateChangeDataForEntireTimeline: DataForTimeline, _shouldExpandTimeline: boolean, _isTimelineOnDueDate: boolean) {
		return (_shouldExpandTimeline && _isTimelineOnDueDate)
			|| (_shouldExpandTimeline && !_dateChangeDataForEntireTimeline.percentagesOfDateChanges.length);
	}

	function getIsDateToShowOnDueDate(_dateChangeDataForEntireTimeline: DataForTimeline) {
		if (!_dateChangeDataForEntireTimeline.percentagesOfDateChanges.length
			&& !_dateChangeDataForEntireTimeline.wasAnySectionOnDueDate) {
			return false;
		}
		return true;
	}
};

export default TimelineBar;
