import * as React from 'react';
import { compose } from 'redux';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type { RootState } from 'af-reducers';

import type CreateEquipmentPlaceholderForm from 'ab-requestModels/scheduleBoardCreateEquipmentPlaceholder.requestModel';

import { EQUIPMENT_PLACEHOLDER_FORM } from 'af-constants/reduxForms';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import Label from 'af-components/LockedValue/Label';

import EquipmentPlaceholderItem from 'af-root/scenes/Company/WorkOrders/Order/Form/ResourceLookups/Equipment/EquipmentPlaceholderItem';

import { validate } from './validation';

interface OwnProps {
	resetTimer: () => void;
	onSubmit: (form: CreateEquipmentPlaceholderForm) => void;
}

type FormProps = InjectedFormProps<CreateEquipmentPlaceholderForm, FormOwnProps>;

interface StateProps {
	workOrderCode: Nullable<string>;
	showModal: boolean;
	initialValues: FormProps['initialValues'];
}

interface DispatchProps {
	setEquipmentPlaceholderModalVisibility: typeof ScheduleBoardActions.setEquipmentPlaceholderModalVisibility;
	setEquipmentPlaceholderModalData: typeof ScheduleBoardActions.setEquipmentPlaceholderModalData;
}

type FormOwnProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

class EquipmentPlaceholderModal extends React.PureComponent<Props> {
	closeModal = () => {
		const { setEquipmentPlaceholderModalVisibility, setEquipmentPlaceholderModalData } = this.props;
		setEquipmentPlaceholderModalVisibility(false);
		setEquipmentPlaceholderModalData({ workOrderCode: null, workOrderId: null, index: null });
	};

	onSubmit = async (form: CreateEquipmentPlaceholderForm) => {
		const { onSubmit } = this.props;
		if (onSubmit) {
			await onSubmit(form);
		}
		this.closeModal();
	};

	render() {
		const { submitting, showModal, workOrderCode, handleSubmit, resetTimer, invalid } = this.props;

		return (
			<CustomModal
				closeModal={this.closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={this.closeModal}
					title={`Add Equipment Placeholder on ${workOrderCode}`}
				/>
				<CustomModal.Body>
					<Label label="Equipment Cost *" />
					<EquipmentPlaceholderItem
						isModal={true}
						name="equipmentCostId"
						propName="equipmentCost"
						resetTimer={resetTimer}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={this.closeModal}
						style="secondary"
					/>
					<SubmitButton
						disabled={invalid}
						label="Save Placeholder"
						onClick={handleSubmit(this.onSubmit)}
						reduxFormSubmitting={submitting}
						submitKey={EQUIPMENT_PLACEHOLDER_FORM}
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { isOpen, workOrderCode, workOrderId, index } = state.scheduleBoard.equipmentPlaceholderModal;
	return {
		showModal: isOpen,
		workOrderCode,
		initialValues: {
			index: index ?? undefined,
			workOrderId: workOrderId ?? undefined,
		},
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		setEquipmentPlaceholderModalVisibility: ScheduleBoardActions.setEquipmentPlaceholderModalVisibility,
		setEquipmentPlaceholderModalData: ScheduleBoardActions.setEquipmentPlaceholderModalData,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps()),
	reduxForm<CreateEquipmentPlaceholderForm, FormOwnProps>({ form: EQUIPMENT_PLACEHOLDER_FORM, enableReinitialize: true, validate })
);

export default enhance(EquipmentPlaceholderModal);
