import type QuantityUnitType from '@acceligentllc/shared/enums/quantityUnit';

import type BillingCodeBase from 'ab-domain/models/billingCode/base';

export class W_BillingCode_FindForJob_VM {
	items: W_BillingCode_FindForJob_VM_Single[];

	constructor(billingCodes: BillingCodeBase[]) {
		this.items = W_BillingCode_FindForJob_VM_Single.bulkConstructor(billingCodes);
	}
}

export class W_BillingCode_FindForJob_VM_Single {
	id: number;
	workRequestId: number;
	lineItemNumber: number;
	customerNumber: Nullable<number>;
	customerId: string;
	ownerNumber: Nullable<string>;
	ownerId: Nullable<string>;
	unit: QuantityUnitType;
	unitPrice: string;
	bidQuantity: Nullable<string>;
	group: Nullable<string>;
	description: string;

	constructor(billingCode: BillingCodeBase) {
		this.id = billingCode.id;
		this.workRequestId = billingCode.workRequestId;
		this.lineItemNumber = billingCode.lineItemNumber;
		this.customerNumber = billingCode.customerNumber;
		this.customerId = billingCode.customerId;
		this.ownerNumber = billingCode.ownerNumber;
		this.ownerId = billingCode.ownerId;
		this.unit = billingCode.unit;
		this.unitPrice = billingCode.unitPrice;
		this.bidQuantity = billingCode.bidQuantity;
		this.group = billingCode.group ?? null;
		this.description = billingCode.description;
	}

	private static _constructorMap = (billingCode: BillingCodeBase) => new W_BillingCode_FindForJob_VM_Single(billingCode);

	static bulkConstructor = (billingCodes: BillingCodeBase[]) => billingCodes.map(W_BillingCode_FindForJob_VM_Single._constructorMap);
}
