import * as React from 'react';
import * as SettingsKeys from 'af-constants/settingsKeys';
import CLIENT from 'af-constants/routes/client';
import type { CellContext, Row } from '@tanstack/react-table';
import type { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom-v5-compat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';
import FieldReportAccessRoleEnum from '@acceligentllc/shared/enums/fieldReportAccessRole';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';
import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';
import { isCalculatedReportDisplayStatusOfInterestForRole } from '@acceligentllc/shared/utils/workOrderReport';
import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';

import type { W_ReportType_FindReportTypeViewTable_VM_Row } from 'ab-api/web/workOrderReport/reportTypeViewTable';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';
import { DEFAULT_TABLE_PAGE_SIZE } from 'ab-common/constants/value';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import TableNameEnum from 'ab-enums/tableName.enum';

import type FieldReportTypeSidePanelVM from 'ab-viewModels/fieldReport/fieldReportTypeSidePanel.viewModel';

import { bemElement } from 'ab-utils/bem.util';
import { getSegmentCount } from 'ab-utils/fieldReport.util';
import { isAllowed } from 'ab-utils/auth.util';

import type { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';
import * as JobActions from 'af-actions/jobs';
import * as ReportTypeActions from 'af-actions/reportType';

import DateFilter from 'af-components/DateFilter';
import Dropdown from 'af-components/Controls/Dropdown';
import LinkCell from 'af-components/Table/Cells/LinkCell';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import TableNew from 'af-components/Table';
import TextCell from 'af-components/Table/Cells/TextCell';
import type { TableProps } from 'af-components/Table/types';
import type { TableRef } from 'af-components/Table';

import DisplayReviewStatusLabel from 'af-root/scenes/Company/FieldReports/Shared/DisplayReviewStatusLabel';
import { withTaskRecovery } from 'af-root/context/taskRecoveryContext';

import * as SettingsUtils from 'af-utils/settings.util';

import FieldReportTypeSidePanel from './SidePanel';
import FieldReportTypeSidePanelModal from './SidePanel/FieldReportTypeModal';
import styles from './styles.module.scss';
import { JobFilterItem } from './JobFilterItem';
import { ReportTypeFilterItem } from './ReportTypeFilterItem';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	reportTypeId: string;
	jobId: string;
	textFilter: string;
}

const connector = connect(mapStateToProps, mapDispatchToProps());

type Props = SettingProps & ConnectedProps<typeof connector>;

const sortableColumns = ['reportTypeName', 'workOrderCode', 'companyName', 'startDate', 'calculatedReportDisplayStatus', 'reportTypeStatus', ''] as const;
type SortableColumn = (typeof sortableColumns)[number];
function isSortableColumn(value: string): value is SortableColumn {
	return sortableColumns.includes(value as SortableColumn);
}

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.REPORT_TYPE_VIEW_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.REPORT_TYPE_VIEW_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.REPORT_TYPE_VIEW_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const setLocalStorageReportType = (reportTypeId: number) => {
	SettingsUtils.setItem(SettingsKeys.REPORT_TYPE_VIEW_REPORT_TYPE(), `${reportTypeId}`, BrowserStorageEnum.LOCAL_STORAGE);
};

const setLocalStorageJobId = (jobId: number) => {
	SettingsUtils.setItem(SettingsKeys.REPORT_TYPE_VIEW_JOB(), `${jobId}`, BrowserStorageEnum.LOCAL_STORAGE);
};

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const isTimeSheetRejected = (timeSheet: W_ReportType_FindReportTypeViewTable_VM_Row['timeSheets'][0]) => timeSheet.approvalStatus === TimeSheetApprovalStatus.REJECTED;

const hasCurrentAccountTimeSheetRejected = (currentAcc: Nullable<number>) => (_ts) => isTimeSheetRejected(_ts) && _ts.accountId === currentAcc;

const ReportTypeViewTable = (props: Props) => {
	const {
		companyData,
		isAccountingOrAdmin,
		isManagement,
		findAllForReportTypeViewTable,
		loadJobs,
		loadReportTypes,
		findFieldReportTypeForSidePanel,
	} = props;

	const location = useLocation();
	const orgAlias = location.state.orgAlias;

	const tableRef = React.useRef<TableRef<W_ReportType_FindReportTypeViewTable_VM_Row>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);

	const [jobFilterOptions, setJobFilterOptions] = React.useState<JobFilterItem[]>([JobFilterItem.DEFAULT_ALL]);
	const [filterByJob, setFilterByJob] = React.useState<number>(props.jobId ? +props.jobId : JobFilterItem.DEFAULT_ALL.id);

	const [reportTypeFilterOptions, setReportTypeFilterOptions] = React.useState<ReportTypeFilterItem[]>([]);
	const [filterByReportType, setFilterByReportType] = React.useState<Nullable<number>>(props.reportTypeId ? +props.reportTypeId : null);
	const [fieldReportTypeToPreview, setFieldReportTypeToPreview] = React.useState<Nullable<FieldReportTypeSidePanelVM>>(null);
	const [showFieldReportTypeModal, setShowFieldReportTypeModal] = React.useState(false);

	const closeFieldReportTypeModal = React.useCallback(() => setShowFieldReportTypeModal(false), []);
	const openFieldReportTypeModal = React.useCallback(() => setShowFieldReportTypeModal(true), []);

	const fetchFiltersData = React.useCallback(async () => {
		const [jobs, reportTypes] = await Promise.all([
			loadJobs(),
			loadReportTypes(),
		]);
		setJobFilterOptions([JobFilterItem.DEFAULT_ALL, ...JobFilterItem.bulkConstructor(jobs)]);

		setReportTypeFilterOptions(ReportTypeFilterItem.bulkConstructor(reportTypes));
	}, [loadJobs, loadReportTypes]);

	React.useEffect(() => {
		fetchFiltersData();
	}, [fetchFiltersData]);

	const selectedJob = React.useMemo(
		() => jobFilterOptions.find((_value) => _value.id === filterByJob),
		[filterByJob, jobFilterOptions]);

	const selectedReportType = React.useMemo(
		() => reportTypeFilterOptions.find((_value) => _value.id === filterByReportType),
		[filterByReportType, reportTypeFilterOptions]);

	const fetchData = React.useCallback(async (tableRequestModel: TableQuery) => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		const { id, desc } = sortBy?.length ? { ...sortBy[0] } : { id: 'workOrderCode', desc: false };
		const sort: SortableColumn = isSortableColumn(id) ? id : '';

		if (!selectedReportType) {
			return undefined;
		}

		const rows = await findAllForReportTypeViewTable(
			startDate,
			endDate,
			page,
			pageSize ?? DEFAULT_TABLE_PAGE_SIZE,
			filterByText ?? '',
			sort,
			!desc,
			selectedJob?.id ?? JobFilterItem.DEFAULT_ALL.id,
			selectedReportType.id
		);

		return rows;

	}, [selectedReportType, findAllForReportTypeViewTable, startDate, endDate, selectedJob?.id]);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const filterTableByJob = React.useCallback((value: JobFilterItem) => {
		setFilterByJob(value.id);
		setLocalStorageJobId(value.id);
	}, []);

	const filterTableByReportType = React.useCallback((value) => {
		setFilterByReportType(value.id);
		setLocalStorageReportType(value.id);
	}, []);

	const renderJobMenuSelectedItem = React.useCallback((_job: JobFilterItem) => {
		return (
			<span className={styles['rtv-table__selected-job']}>
				{_job?.jobCode ?? ''}
			</span>
		);
	}, []);

	const renderJobMenuItem = React.useCallback((optionItem: JobFilterItem) => {
		const { jobCode, title, customerCompany } = optionItem;

		return !!optionItem
			?
			<div>
				<strong>{jobCode}</strong>
				<small><br /></small>
				{!!customerCompany && <small>{customerCompany}</small>}
				{!!title && <small>{(!!customerCompany) && ' |'} {title}</small>}
			</div>
			: <b>All Jobs</b>;
	}, []);

	const renderReportTypeMenuSelectedItem = React.useCallback((_reportType: ReportTypeFilterItem) => {
		return <>{_reportType?.name ? `${_reportType.name}` : ''}</>;
	}, []);

	const renderReportTypeMenuItem = React.useCallback((optionItem: ReportTypeFilterItem) => {
		return !!optionItem
			? <>{optionItem.name}</>
			: <>All report types</>;
	}, []);

	const renderFilter = React.useCallback(() => {

		return (
			<>
				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<JobFilterItem>
						className={styles['rtv-table__filter-button']}
						defaultValue={selectedJob ?? JobFilterItem.DEFAULT_ALL}
						filterable={true}
						filterBy={['jobCode', 'title']}
						id="report-type-view-job-filter"
						labelKey="jobCode"
						onValueChange={filterTableByJob}
						options={jobFilterOptions}
						renderMenuItem={renderJobMenuItem}
						renderSelected={renderJobMenuSelectedItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<ReportTypeFilterItem>
						className={styles['rtv-table__filter-button']}
						defaultValue={selectedReportType ?? null}
						filterable={true}
						filterBy={['name']}
						id="report-type-view-rt-filter"
						labelKey="name"
						onValueChange={filterTableByReportType}
						options={reportTypeFilterOptions}
						placeholder="Select Report Type"
						renderMenuItem={renderReportTypeMenuItem}
						renderSelected={renderReportTypeMenuSelectedItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					areButtonsSmall={true}
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</>
		);
	}, [
		filterTableByJob,
		jobFilterOptions,
		renderJobMenuItem,
		renderJobMenuSelectedItem,
		filterTableByReportType,
		reportTypeFilterOptions,
		renderReportTypeMenuItem,
		renderReportTypeMenuSelectedItem,
		changePeriod,
		endDate,
		filterByDate,
		period,
		startDate,
		selectedReportType,
		selectedJob,
	]);

	const renderDateCell = React.useCallback(() => (cell) => {

		const dbDate = cell.getValue() as string;

		const date = TimeUtils.formatDate(dbDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		return <TextCell value={date} />;
	}, []);

	const renderTextCell = React.useCallback(() => (cell) => {

		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderLinkCell = React.useCallback(() =>
		(_cell: CellContext<W_ReportType_FindReportTypeViewTable_VM_Row, string>) => {
			if (!_cell.cell.row.original.workOrderCode) {
				return (
					<span>
						N/A
					</span>
				);
			}

			const path = _cell.row.original.workOrderId
				? CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(_cell.row.original.workOrderId.toString(), orgAlias, companyData.name)
				: '';

			return (
				<LinkCell
					label={_cell.row.original.workOrderCode!}
					path={path}
				/>
			);
		}, [companyData.name, orgAlias]);

	const renderStatusCell = React.useCallback(() =>
		(_cell: CellContext<W_ReportType_FindReportTypeViewTable_VM_Row, Nullable<CalculatedReportDisplayStatus>>) => {
			if (_cell.row.original.status === WorkOrderStatus.CANCELED) {
				return <TextCell value="N/A" />;
			}
			let role = _cell.row.original.fieldReportAccessRole;
			if (isManagement) {
				role = FieldReportAccessRoleEnum.MANAGEMENT;
			}
			if (isAccountingOrAdmin) {
				role = FieldReportAccessRoleEnum.ACCOUNTING;
			}

			const hasCurrentUserRejectedTimeSheet = _cell.row.original.timeSheets.some(hasCurrentAccountTimeSheetRejected(companyData.accountId));

			let isOfInterest = false;
			if (role) {
				isOfInterest = isCalculatedReportDisplayStatusOfInterestForRole(
					role,
					_cell.row.original.calculatedReportDisplayStatus,
					companyData.accountId,
					_cell.row.original.assignedToIds,
					hasCurrentUserRejectedTimeSheet
				) ?? false;
			}

			return (
				<DisplayReviewStatusLabel
					calculatedReportDisplayStatus={_cell.getValue()}
					isCondensedView={false}
					isOfInterest={isOfInterest}
				/>
			);
		}, [companyData.accountId, isAccountingOrAdmin, isManagement]);

	const columns: TableProps<W_ReportType_FindReportTypeViewTable_VM_Row>['columns'] = React.useMemo(() => [
		{
			id: 'reportTypeName',
			header: 'Report Type',
			accessor: 'reportTypeName',
			cell: renderTextCell(),
			rightAligned: false,
			enableSorting: true,
		},
		{
			id: 'workOrderCode',
			header: 'Field Report',
			accessor: 'workOrderCode',
			cell: renderLinkCell(),
			rightAligned: false,
			enableSorting: true,
		},
		{
			id: 'companyName',
			header: 'Company',
			accessor: 'companyName',
			cell: renderTextCell(),
			rightAligned: false,
			enableSorting: true,
		},
		{
			id: 'startDate',
			header: 'Start Date',
			accessor: 'startDate',
			cell: renderDateCell(),
			rightAligned: false,
			enableSorting: true,
		},
		{
			id: 'calculatedReportDisplayStatus',
			header: 'FR Status',
			accessor: 'calculatedReportDisplayStatus',
			cell: renderStatusCell(),
			rightAligned: false,
			enableSorting: true,
		},
		// TO DO: NEXT PHASE
		// {
		// 	id: 'rtStatus',
		// 	header: 'RT Status',
		// 	accessor: 'rtStatus',
		// 	cell: renderTextCell(),
		// 	rightAligned: false,
		// 	enableSorting: true,
		// },
	], [renderDateCell, renderLinkCell, renderStatusCell, renderTextCell]);

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
	}, [startDate, endDate, period]);

	const [segmentCountMap, instanceCount] = React.useMemo(() => {
		const _segmentCountMap = getSegmentCount(fieldReportTypeToPreview?.fieldReportBlocks ?? []);
		const _instanceCount = Object.keys(_segmentCountMap).length;
		return [_segmentCountMap, _instanceCount];
	}, [fieldReportTypeToPreview?.fieldReportBlocks]);

	const renderSidePanelContext = React.useCallback((selectedRow: Row<W_ReportType_FindReportTypeViewTable_VM_Row>) => {
		return (
			<FieldReportTypeSidePanel
				fieldReportId={selectedRow.original.fieldReportId}
				fieldReportTypeId={selectedRow.original.id}
				fieldReportTypeToPreview={fieldReportTypeToPreview}
				findFieldReportTypeForSidePanel={findFieldReportTypeForSidePanel}
				instanceCount={instanceCount}
				segmentCountMap={segmentCountMap}
				setFieldReportTypeToPreview={setFieldReportTypeToPreview}
			/>
		);
	}, [fieldReportTypeToPreview, findFieldReportTypeForSidePanel, instanceCount, segmentCountMap]);

	const renderSidePanelHeader = React.useCallback((selectedRow: Row<W_ReportType_FindReportTypeViewTable_VM_Row>) => {
		const headerValue = `${selectedRow.original.reportTypeName} ${selectedRow.original.workOrderCode}`;
		return (
			<>
				<span className={styles['side-panel-header']}>
					<span className="icon-fullscreen_enter" onClick={openFieldReportTypeModal} />
					{headerValue}
				</span>
				{fieldReportTypeToPreview && (
					<FieldReportTypeSidePanelModal
						closeModal={closeFieldReportTypeModal}
						currentFieldReportType={fieldReportTypeToPreview}
						fieldReportTypeTitle={headerValue}
						instanceCount={instanceCount}
						// NEXT PHASE
						// onExportAsPDFClick={onExportAsPDFClick}
						segmentCountMap={segmentCountMap}
						showModal={showFieldReportTypeModal}
					/>
				)}
			</>
		);
	}, [closeFieldReportTypeModal, fieldReportTypeToPreview, instanceCount, openFieldReportTypeModal, segmentCountMap, showFieldReportTypeModal]);

	const emptyTableMessage = React.useMemo(() => {
		if (!selectedReportType) {
			return (
				<span className={styles['empty-table-message']}>
					Select Report Type to display data
				</span>
			);
		}

		return undefined;
	}, [selectedReportType]);

	return (
		<TableNew
			additionalFilter={renderFilter}
			columns={columns}
			emptyTableMessage={emptyTableMessage}
			fetch={fetchData}
			filtersClassName={styles['table-filters']}
			hasSearchInput={true}
			offsetHeight={70}
			ref={tableRef}
			renderSidePanelContext={renderSidePanelContext}
			renderSidePanelHeader={renderSidePanelHeader}
			sidePanelVisible={true}
			tableName={TableNameEnum.REPORT_TYPE_VIEW_TABLE}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;

	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}

	const isAccountingOrAdmin = isAllowed(
		PagePermissions.COMPANY.FIELD_REPORT.MANAGE.ACCOUNTING,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);
	const isManagement = isAllowed(PagePermissions.COMPANY.FIELD_REPORT.MANAGE.MANAGEMENT, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasPermissionToEdit = isAccountingOrAdmin
		?? isAllowed(PagePermissions.COMPANY.FIELD_REPORT.FILL, companyData.permissions, companyData.isCompanyAdmin, userData.role);
	const hasPermissionToViewAll = isAccountingOrAdmin
		?? isAllowed(PagePermissions.COMPANY.FIELD_REPORT.VIEW_ALL, companyData.permissions, companyData.isCompanyAdmin, userData.role);

	return {
		userData: userData,
		companyData: companyData,
		isAccountingOrAdmin,
		isManagement,
		hasPermissionToEdit,
		hasPermissionToViewAll,
	};
}

function mapDispatchToProps() {
	return {
		loadJobs: JobActions.getAllJobCodesForJobFilter,
		loadReportTypes: ReportTypeActions.getAllForReportTypeFilter,
		findAllForReportTypeViewTable: FieldReportActions.findAllForReportTypeViewTable,
		findFieldReportTypeForSidePanel: FieldReportActions.findFieldReportTypeForSidePanel,
	};
}

const enhance = compose<React.ComponentClass>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.REPORT_TYPE_VIEW_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.REPORT_TYPE_VIEW_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.REPORT_TYPE_VIEW_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.REPORT_TYPE_VIEW_REPORT_TYPE(),
			mappedName: 'reportTypeId',
			defaultValue: null,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.REPORT_TYPE_VIEW_JOB(),
			mappedName: 'jobId',
			defaultValue: null,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector,
	withTaskRecovery
);

export default enhance(ReportTypeViewTable);
