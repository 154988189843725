import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import * as ScheduleBoardActions from 'af-actions/scheduleBoard';

import { isAllowed } from 'ab-utils/auth.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type ScheduleBoardWorkOrderViewModel from 'ab-socketModels/viewModels/scheduleBoard/scheduleBoardWorkOrder.viewModel';

import CardInfo from './CardInfo';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

interface OwnProps {
	dueDate: string;
	isCalculationsView: boolean;
	isDragging?: boolean;
	lastOpenedOrderCode?: string;
	notificationStatus: Nullable<NotificationStatusEnum>;
	projectManagerName: string;
	supervisorName: string;
	workOrder: ScheduleBoardWorkOrderViewModel;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderInfo: React.FC<Props> = (props) => {
	const {
		workOrder,
		projectManagerName,
		supervisorName,
		scheduleBoardView,
		setCopiedWorkOrderCode,
		isDragging,
		isCalculationsView,
		notificationStatus,
		notificationsEnabled,
		zoomLevel,
		isAllowedToEditWorkOrders,
		isAllowedToViewProdData,
		lastOpenedOrderCode,
	} = props;

	const { workOrderModalId, setWorkOrderModalData } = useScheduleBoardModals();

	const [modalOpenedForCurrentWorkOrder, setModalOpenedForCurrentWorkOrder] = React.useState(false);

	React.useEffect(() => {
		setModalOpenedForCurrentWorkOrder(workOrder?.id === workOrderModalId);
	}, [workOrderModalId, workOrder?.id]);

	// component did mount
	React.useEffect(() => {
		if (!isCalculationsView && lastOpenedOrderCode === workOrder.code) {
			setWorkOrderModalData(workOrder.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (isCalculationsView) {
			return;
		}

		if (modalOpenedForCurrentWorkOrder && (workOrder.locked || workOrder.isDisabled)) {
			setWorkOrderModalData(null);
		}
	}, [isCalculationsView, modalOpenedForCurrentWorkOrder, setWorkOrderModalData, workOrder.isDisabled, workOrder.locked]); //

	const openWorkOrderModal = React.useCallback(async () => {
		if (isCalculationsView) {
			return;
		}
		// fixed syncing error
		if (workOrder?.id) {
			setWorkOrderModalData(workOrder.id);
		}
	}, [isCalculationsView, setWorkOrderModalData, workOrder.id]);

	const isInternal = workOrder.isInternal;

	let className = 'schedule-board-card-order-info';
	className = isInternal ? `${className} internal-card` : className;

	return (
		<div className={className}>
			<CardInfo
				isAllowedToEditWorkOrders={isAllowedToEditWorkOrders}
				isAllowedToViewProdData={isAllowedToViewProdData}
				isCalculationsView={isCalculationsView}
				isDragging={isDragging}
				notificationsEnabled={notificationsEnabled}
				notificationStatus={notificationStatus}
				openOrderInfoModal={openWorkOrderModal}
				projectManagerName={projectManagerName}
				scheduleBoardView={scheduleBoardView}
				setCopiedWorkOrderCode={setCopiedWorkOrderCode}
				supervisorName={supervisorName}
				workOrder={workOrder}
				zoomLevel={zoomLevel}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { scheduleBoardView } = state.scheduleBoard;
	const { companyData, userData } = state.user;
	const { company } = state.company;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const isAllowedToEditWorkOrders: boolean = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const isAllowedToViewProdData: boolean = isAllowed(
		PagePermissions.COMPANY.PROD_DATA.VIEW,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		scheduleBoardView,
		notificationsEnabled: company?.notification?.isEnabled ?? false,
		zoomLevel: state.scheduleBoard.zoomLevel,
		isAllowedToEditWorkOrders,
		isAllowedToViewProdData,
	};
};

const mapDispatchToProps = () => ({
	setCopiedWorkOrderCode: ScheduleBoardActions.setCopiedWorkOrderCode,
});

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector

);
export default enhance(OrderInfo);
