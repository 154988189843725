import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import ResourceStatus from '@acceligentllc/shared/enums/resourceStatus';

import type { SearchableModel } from 'ab-common/dataStructures/scheduleBoardQuery';

import * as TextUtil from 'ab-utils/text.util';

import type LocationBase from 'ab-domain/models/location/base';
import type EquipmentBase from 'ab-domain/models/equipment/base';

import type { ScheduleBoardQueryMatchingFunction } from 'ab-common/dataStructures/scheduleBoardQuery';

import { addToArrayIfExists } from 'ab-utils/array.util';

class ScheduleBoardEmployeeOfficeViewModel {
	id: number;
	nickname: string;
	color: ColorPalette;
	index: number;

	constructor(location: LocationBase) {
		this.id = location.id;
		this.nickname = location.nickname;
		this.color = location.color;
		this.index = location.index;
	}
}

interface ResourceStatusVM {
	id: number;
	name: Nullable<string>;
	index: Nullable<number>;
	available: boolean;
	isDeleted: boolean;
}

export default class ScheduleBoardEquipment implements SearchableModel {
	static matches: ScheduleBoardQueryMatchingFunction<ScheduleBoardEquipment> = TextUtil.matches;

	id: number;
	name: string;
	spec: Nullable<string>; // specification, name stripped in order to save lots of bytes on the schedule board
	office: Nullable<ScheduleBoardEmployeeOfficeViewModel>;
	isDisabled: boolean; // do not need to initialize them on the server side
	isFilteredOnBoard: boolean; // do not need to initialize them on the server side
	isFilteredOnToolbar: boolean; // do not need to initialize them on the server side
	isMatched?: boolean;
	equipmentType: Nullable<string>;
	equipmentGroup: string;
	ecId: number;
	equipmentCategory: string;
	equipmentSubcategory: string;
	color: Nullable<ColorPalette>;
	equipmentStatus: ResourceStatusVM;
	unavailabilityReason: string;
	unavailabilityReasonId: number;
	returnDate: string;
	isDeleted: boolean;
	orderNumber: Nullable<number>;
	showOnScheduleBoard: boolean;

	_desc: string;

	constructor(equipment: EquipmentBase, index?: number) {
		this.id = equipment.id;
		this.name = equipment.code || 'N/A';
		this.spec = equipment.specification;
		this.office = equipment.location && new ScheduleBoardEmployeeOfficeViewModel(equipment.location);
		this.equipmentType = equipment.equipmentCost.category.group?.type ?? null;

		this.color = equipment.equipmentCost.category.categoryColor ?? null;
		this.ecId = equipment.equipmentCost.category.id; // equipmentCategoryId, name is striped in order to save lots of bytes over the data transfer
		this.isDeleted = equipment.status === ResourceStatus.DELETED;

		const searchableLiterals: string[] = [this.name];

		equipment.equipmentCost.category.group?.type && addToArrayIfExists(searchableLiterals, equipment.equipmentCost.category.group.type);
		equipment.equipmentCost.category.group?.name && addToArrayIfExists(searchableLiterals, equipment.equipmentCost.category.group.name);
		equipment.equipmentCost.category.name && addToArrayIfExists(searchableLiterals, equipment.equipmentCost.category.name);
		equipment.equipmentCost.subcategory && addToArrayIfExists(searchableLiterals, equipment.equipmentCost.subcategory);

		this._desc = searchableLiterals.join(' ');

		this.orderNumber = index ?? null;
		this.isMatched = false;

		this.showOnScheduleBoard = equipment?.showOnScheduleBoard;
	}
}

export type ScheduleBoardEquipmentViewModel = { [equipmentId: number]: ScheduleBoardEquipment; };

export type EquipmentAssignments = { [equipmentId: number]: string[]; }; // [equipmentId]: workOrderCode[]

