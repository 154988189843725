import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';
import TimeFormatEnum from '@acceligentllc/shared/enums/timeFormat';
import UserPermission from '@acceligentllc/shared/enums/userPermission';

import * as CompanyActions from 'af-actions/companies';

import type { MemberViewModel } from 'ab-viewModels/member.viewModel';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';
import { AccountPermissionTemplateLabel } from 'ab-enums/accountPermissionTemplates.enum';

import type { TabProps, Column, ItemBlueprint } from 'af-components/Table6';
import Table from 'af-components/Table6';
import DateCell from 'af-components/Table6/Cells/DateCell';
import ImageTag from 'af-components/Image';

import { formatPhoneNumber } from 'ab-utils/phone.util';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import StatusCell from 'af-components/Table6/Cells/StatusCell';

interface PathParams {
	clientAlias: string;
}

interface DispatchProps {
	getMembersForOrganization: typeof CompanyActions.getMembersForOrganizationByAlias;
	removeOrganizationAdminPrivileges: typeof CompanyActions.removeOrganizationAdminPrivileges;
	giveOrganizationAdminPrivileges: typeof CompanyActions.giveOrganizationAdminPrivileges;
}

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = ResolveThunks<DispatchProps> & OwnProps;

const adminRoles = [UserPermission.ADMIN, UserPermission.OWNER];

class CompanyMembersList extends React.PureComponent<Props> {
	columns: Column<MemberViewModel>[] = [
		{
			accessor: 'imageUrl',
			sortable: false,
			width: 100,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl ?? null}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'Unique ID',
			accessor: 'uniqueId',
			className: 'text-strong',
			Cell: ({ original }) => original.uniqueId,

		},
		{
			Header: 'Full Name',
			accessor: 'fullName',
			className: 'text-strong',
			Cell: ({ original }) => original.fullName,
		},
		{
			Header: 'Email Address',
			accessor: 'email',
			Cell: ({ original }) => original.email,
		},
		{
			Header: 'Mobile Phone',
			accessor: 'phoneNumber',
			Cell: ({ original }) => formatPhoneNumber(original.phoneNumber, original.countryCode),
		},
		{
			Header: 'Role',
			accessor: 'role',
			Cell: ({ original }) => original.role,
		},
		{
			Header: 'User Role',
			accessor: 'accountTemplate',
			Cell: ({ original }) => <strong>{AccountPermissionTemplateLabel[original.accountTemplate]}</strong>,
		},
		{
			Header: 'Home Office',
			accessor: 'location.nickname',
			Cell: ({ original }) => original.location?.nickname ?? <EmptyCell />,
		},
		{
			Header: 'Last Login',
			accessor: 'lastLoginAt',
			Cell: ({ original }) => original.lastLoginAt
				? <DateCell date={original.lastLoginAt} format={TimeFormatEnum.DATE_ONLY} isLeftAligned={true} withTime={true} />
				: <EmptyCell />,
		},
		{
			Header: 'Status',
			accessor: 'status',
			filterable: false,
			Cell: ({ original }) => <StatusCell {...original} />,
		},
		{
			Header: 'LMS access',
			accessor: 'activeInLMS',
			filterable: false,
			Cell: ({ original }) => {
				if (original.activeInLMS) {
					return (<span className="text-green">Yes</span>);
				}
				return (<span className="text-grey">No</span>);
			},
		},
		{
			Header: 'SMS Subscribed Status',
			accessor: 'subscriptionStatus',
			sortable: false,
			Cell: ({ original }) => original.subscriptionStatus,
		},
	];

	changeAdminPrivileges = async (original: MemberViewModel) => {
		const { removeOrganizationAdminPrivileges, giveOrganizationAdminPrivileges, match: { params: { clientAlias } } } = this.props;
		if (adminRoles.includes(original.role)) {
			await removeOrganizationAdminPrivileges(original.userId.toString(), clientAlias);
		} else {
			await giveOrganizationAdminPrivileges(original.userId.toString(), clientAlias);
		}
	};

	getMembersForOrganization = (tableRequestModel: TableQuery) => {
		const { getMembersForOrganization, match: { params: { clientAlias } } } = this.props;
		return getMembersForOrganization(tableRequestModel, clientAlias);
	};

	tabs = (): TabProps<MemberViewModel>[] => {
		const { match: { params: { clientAlias } } } = this.props;

		const rowActions: ItemBlueprint<MemberViewModel>[] = [
			{
				label: (original: MemberViewModel) => adminRoles.includes(original.role) ? 'Remove platform admin' : 'Make platform admin',
				action: this.changeAdminPrivileges,
				shouldRefresh: true,
			},
		];

		return [
			{
				label: `${clientAlias} Users`,
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Users',
				fetch: this.getMembersForOrganization,
				rowActions,
			},
		];
	};

	breadcrumbs = () => [{ label: 'Users' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.PLATFORM_MEMBERS}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(): DispatchProps {
	return {
		getMembersForOrganization: CompanyActions.getMembersForOrganizationByAlias,
		removeOrganizationAdminPrivileges: CompanyActions.removeOrganizationAdminPrivileges,
		giveOrganizationAdminPrivileges: CompanyActions.giveOrganizationAdminPrivileges,
	};
}

export default connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps())(CompanyMembersList);
