import * as React from 'react';

import TimeSheetApprovalStatus from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import type TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';

import SignatureStatusLabel from 'af-root/scenes/Company/FieldReports/Shared/SignatureStatusLabel';

import styles from './styles.module.scss';

interface OwnProps {
	fullName: string;
	approvalStatus: Nullable<TimeSheetApprovalStatus>;
	signatureStatus: TimeSheetSignatureStatus;
}

type Props = OwnProps;

const TimeSheet: React.FC<Props> = (props: Props) => {
	const { fullName, approvalStatus, signatureStatus } = props;

	const status = approvalStatus !== TimeSheetApprovalStatus.PENDING
		? approvalStatus
		: signatureStatus;

	return (
		<div className={styles['status-tooltip__time-sheet']}>
			{!!status && (
				<SignatureStatusLabel
					className="work-order-status-summary__time-sheet-label"
					status={status}
				/>
			)}
			<div>{fullName}</div>
		</div>
	);
};

export default React.memo(TimeSheet);
