import * as React from 'react';

import type { CompanyFeatureFlagsEntry } from 'ab-viewModels/companyFeatureFlags.viewModel';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface Props {
	toggleEdit: () => void;
	workOrderNotifications: (Nullable<CompanyFeatureFlagsEntry>)[];
}

const _renderItem = (featureFlag: Nullable<CompanyFeatureFlagsEntry>, index: number): JSX.Element => {
	const _featureFlag = featureFlag ?? {} as CompanyFeatureFlagsEntry;
	return (
		<PreviewItem
			checked={!!_featureFlag.checked}
			hint={_featureFlag.hint}
			id={`accqa__notification-settings__temporary-labor__${_featureFlag.key}`}
			indent={!!_featureFlag.indent}
			key={`${_featureFlag.label}${index}`}
			label={_featureFlag.label}
			showCheckbox={!!_featureFlag.showStatusIcon}
			value={_featureFlag.value ?? undefined}
		/>
	);
};

const TemporaryLaborNotificationsPreview: React.FC<Props> = (props) => {
	const { toggleEdit, workOrderNotifications } = props;

	return (
		<SettingPreview
			buttonContext="notification-settings__temporary-labor"
			containerId="accqa__notification-settings__temporary-labor__container"
			toggleEdit={toggleEdit}
		>
			<div className="company-settings__info-segment">
				{
					workOrderNotifications.map(_renderItem)
				}
			</div>
		</SettingPreview>
	);
};

export default React.memo(TemporaryLaborNotificationsPreview);
