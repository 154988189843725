import * as React from 'react';

import { ColorPalette } from '@acceligentllc/shared/enums/color';

import * as ColorUtils from 'ab-utils/color.util';

import CodeField from 'af-components/CodeField';

class ColorLegend extends React.PureComponent {

	render() {
		return (
			<CodeField>
				<b>Color legend:</b>
				<br />
				{
					Object.keys(ColorPalette).map((_color: ColorPalette, _index) => (
						<div className="color-legend__color-row" key={`${_color}#${_index}`}>
							<div className={`color-legend__color-square ${ColorUtils.getColorBackgroundClass(_color)}`} />
							<span>{_color}</span>
							<br />
						</div>
					))
				}
			</CodeField>
		);
	}
}

export default ColorLegend;
