import type VendorPackageType from '@acceligentllc/shared/enums/vendorPackageType';

import type ItemBase from 'ab-domain/models/item/base';
import type ItemDepartmentBase from 'ab-domain/models/itemDepartment/base';

export class W_Item_FindAllForPurchaseOrderOptions_VM {
	items: W_Item_FindAllForPurchaseOrderOptions_VM_Single[];

	constructor(items: ItemBase[]) {
		this.items = W_Item_FindAllForPurchaseOrderOptions_VM_Single.bulkConstructor(items);
	}
}

class W_Item_FindAllForPurchaseOrderOptions_VM_Single {
	id: number;
	modelNumber: string;
	name: string;
	price: Nullable<number>;
	vendorId: Nullable<number>;
	vendorName: Nullable<string>;
	imageUrl: Nullable<string>;
	partNumber: Nullable<string>;
	packageType: Nullable<VendorPackageType>;
	departmentOptions: W_Item_FindAllForPurchaseOrderOptions_VM_Department[];
	vendorPartNumber: Nullable<string>;

	constructor(item: ItemBase) {
		this.id = item.id;
		this.modelNumber = item.modelNumber;
		this.name = item.name;
		this.price = item.itemVendor?.price ? +item.itemVendor.price : null;
		this.vendorId = item.itemVendor?.vendorId ?? null;
		this.vendorName = item.itemVendor?.vendor.name ?? null;
		this.imageUrl = item.imageUrl;
		this.partNumber = item.itemVendor?.partNumber ?? null;
		this.packageType = item.itemVendor?.packageType ?? null;
		this.vendorPartNumber = item.itemVendor?.partNumber ?? null;

		this.departmentOptions = W_Item_FindAllForPurchaseOrderOptions_VM_Department.bulkConstructor(item.itemDepartments ?? []);
	}

	private static _constructorMap = (item: ItemBase) => new W_Item_FindAllForPurchaseOrderOptions_VM_Single(item);

	static bulkConstructor = (items: ItemBase[]) => items.map(W_Item_FindAllForPurchaseOrderOptions_VM_Single._constructorMap);
}

class W_Item_FindAllForPurchaseOrderOptions_VM_Department {
	id: number;
	itemDepartmentId: number;
	name: string;
	locationName: string;
	locationId: number;
	currentStock: number;

	constructor(itemDepartment: ItemDepartmentBase) {
		this.id = itemDepartment.departmentId;
		this.itemDepartmentId = itemDepartment.id;
		this.name = itemDepartment.department.name;
		this.locationName = itemDepartment.department.location.nickname;
		this.locationId = itemDepartment.department.locationId;
		this.currentStock = +itemDepartment.quantity;
	}

	private static _constructorMap = (itemDepartment: ItemDepartmentBase) => new W_Item_FindAllForPurchaseOrderOptions_VM_Department(itemDepartment);

	static bulkConstructor(itemDepartments: ItemDepartmentBase[]) {
		return itemDepartments.map(W_Item_FindAllForPurchaseOrderOptions_VM_Department._constructorMap);
	}
}
