import type FieldWorkClassificationCodeUpsertRM from 'ab-requestModels/fieldWorkClassificationCode/upsert';

import { isValidTextInput } from '@acceligentllc/shared/utils/text';
/**
 * Rules:
 *  - Allowed characters: alphanumeric, underscore(_), dash(-)
 *  - Must start with alphanumeric
 *  - Must have at least one character
 */
const CODE_REGEXP = /^[a-zA-Z0-9]+\w*\-*\w*$/;
const MIN_CODE_LENGTH = 3;

function _getCodeError(code: string): Nullable<string> {
	const _code = code?.trim() ?? '';

	if (_code.length < MIN_CODE_LENGTH) {
		return `Code needs to have at least ${MIN_CODE_LENGTH} characters`;
	}
	if (!CODE_REGEXP.test(_code)) {
		return 'Code contains invalid characters. Only letters, numbers, underscores(_) and dashes(-) are allowed';
	}

	return null;
}

export function validate(values: FieldWorkClassificationCodeUpsertRM): ValidationErrors {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.code)) {
		errors.code = 'Code is required';
	} else {
		const codeError = _getCodeError(values.code);
		if (codeError) {
			errors.code = codeError;
		}
	}

	return errors;
}
