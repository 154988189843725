import * as React from 'react';
import { compose } from 'redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

import Input from 'af-fields/Input';

import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';

import type OrganizationRequestModel from 'ab-requestModels/organization.requestModel';

import * as OrganizationActions from 'af-actions/organizations';

import { PLATFORM_ORGANIZATION_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { validate } from 'af-root/scenes/Platform/CreateOrganization/validations';

type FormProps = InjectedFormProps<OrganizationRequestModel, FormOwnProps>;

type OwnProps = CustomRouteComponentProps;

interface DispatchProps {
	createOrganization: typeof OrganizationActions.createOrganization;
}

type FormOwnProps = OwnProps & ResolveThunks<DispatchProps>;
type Props = FormOwnProps & FormProps;

class CreateNewOrganization extends React.Component<Props> {

	submit = async (form: OrganizationRequestModel) => {
		const { createOrganization, location: { state: { orgAlias } } } = this.props;

		await createOrganization(form, orgAlias);
	};

	toLowerCase = (val: string) => val?.toLowerCase();

	goBack = () => {
		const {
			location: { state: { orgAlias } },
			history,
		} = this.props;

		history.push(CLIENT.PLATFORM.ALL_ORGANIZATIONS(orgAlias));
	};

	render() {
		const { handleSubmit, submitting, invalid } = this.props;

		return (
			<div className="form-segment form-segment--mini">
				<Breadcrumbs items={[{ label: 'New Organization' }]} />
				<div className="form-box">
					<Form onSubmit={handleSubmit(this.submit)}>
						<Row className="row--padded-top">
							<Col sm={24}>
								<Field
									component={Input}
									label="Organization Name *"
									name="name"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									label="Organization Alias *"
									name="alias"
									normalize={this.toLowerCase}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									label="Owner's First Name *"
									name="firstName"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									label="Owner's Last Name *"
									name="lastName"
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={24}>
								<Field
									component={Input}
									label="Owner's Email Address *"
									name="email"
								/>
							</Col>
						</Row>
						<Row className="row--submit">
							<Button
								label="Cancel"
								onClick={this.goBack}
								style="secondary"
								type="button"
							/>
							<SubmitButton
								disabled={invalid}
								label="Create"
								reduxFormSubmitting={submitting}
								variant="primary"
							/>
						</Row>
					</Form>
				</div>
			</div>
		);
	}

}

function mapDispatchToProps(): DispatchProps {
	return {
		createOrganization: OrganizationActions.createOrganization,
	};
}

const enhance = compose<React.ComponentClass<OwnProps>>(
	connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps()),
	reduxForm<OrganizationRequestModel, FormOwnProps>({ form: PLATFORM_ORGANIZATION_CREATE, enableReinitialize: true, validate })
);

export default enhance(CreateNewOrganization);
