import * as React from 'react';

import RepeatableBlockType from '@acceligentllc/shared/enums/repeatableBlockType';

import type { PCFRBlockVM, PCFRBlockFieldVM } from 'ab-viewModels/report/publicConfirmation.viewModel';

import RepeatingFieldReportBlockField from './RepeatingFieldReportBlockField';

interface OwnProps {
	fieldReportBlock: PCFRBlockVM;
	index: number;
	className: string;
	inverse?: boolean;
}

type Props = OwnProps;

const RepeatingFieldReportBlock: React.FC<Props> = (props: Props) => {
	const {
		fieldReportBlock: {
			fieldReportBlockFields,
			instanceIndex,
			segmentIndex,
			index: blockIndex,
			reportBlock,
		},
		index,
		className,
		inverse = false,
	} = props;

	const renderLabel = React.useMemo(() => (!reportBlock.repeatableBlockType || reportBlock.repeatableBlockType === RepeatableBlockType.DEFAULT)
		, [reportBlock.repeatableBlockType]);

	const _renderBlockField = React.useCallback((_field: PCFRBlockFieldVM) =>
	(
		<>
			<RepeatingFieldReportBlockField
				index={index}
				inverse={inverse}
				key={_field.id}
				renderLabel={renderLabel}
				renderValue={true}
				repeatableBlockType={reportBlock.repeatableBlockType}
				reportBlockField={_field.reportBlockField}
				value={_field.value}
			/>
		</>
	)
		, [index, inverse, renderLabel, reportBlock.repeatableBlockType]);

	return (
		<div
			className={className}
			key={`repeating-block-${index}-${instanceIndex}-${segmentIndex}-${blockIndex}`}
		>
			{fieldReportBlockFields.map(_renderBlockField)}
		</div>
	);
};

export default React.memo(RepeatingFieldReportBlock);
