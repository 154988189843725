import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import styles from './styles.module.scss';

type Props = {
	onEditClick: () => void;
	isInEditMode: boolean;
};

const EditCell: React.FC<Props> = ({ onEditClick, isInEditMode }) => {
	return (
		<span className={styles['edit-cell']}>
			{isInEditMode
				? <Button icon="save" onClick={onEditClick} style="link" />
				: <Button icon="edit" onClick={onEditClick} style="link" />
			}
		</span>
	);
};

export default React.memo(EditCell);
