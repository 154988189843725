import * as React from 'react';
import { Row } from 'react-bootstrap';

import DisplayViewToggle, { DisplayViewToggleLabel } from '@acceligentllc/shared/enums/displayViewToggle';

import type { CompanyViewModel } from 'ab-viewModels/company.viewModel';

import ScheduleBoardSettingsEnum, { ScheduleBoardSettingsHint } from 'ab-enums/scheduleBoardSettings.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import SkillsColorGrid from 'af-components/SkillsColorGrid';
import Label from 'af-components/LockedValue/Label';

import PreviewItem from '../../Shared/PreviewItem';
import SettingPreview from '../../Shared/SettingPreview';

interface OwnProps {
	toggleEdit: () => void;
	company: CompanyViewModel;
}

type Props = OwnProps;

class ScheduleBoardSettingsPreview extends React.PureComponent<Props> {

	render() {
		const { toggleEdit, company } = this.props;
		const {
			displayViewChangeTime,
			displayViewRotationTime,
			cdlInSkillsGrid,
			displayViewType,
			includeNonWorkingDaysOnDisplayView,
		} = company;
		const showDisplayViewRotationTime = displayViewType === DisplayViewToggle.TOGGLE;

		return (
			<SettingPreview toggleEdit={toggleEdit}>
				<div className="company-settings__info-segment">
					<PreviewItem
						checked={true}
						hint={ScheduleBoardSettingsHint.displayViewChangeTime}
						indent={false}
						label={ScheduleBoardSettingsEnum.displayViewChangeTime}
						showCheckbox={false}
						value={TimeOptionUtils.toValueString(displayViewChangeTime)}
					/>
					<PreviewItem
						checked={includeNonWorkingDaysOnDisplayView}
						hint={ScheduleBoardSettingsHint.includeNonWorkingDaysOnDisplayView}
						indent={false}
						label={ScheduleBoardSettingsEnum.includeNonWorkingDaysOnDisplayView}
						showCheckbox={true}
					/>
					<PreviewItem
						checked={true}
						hint={ScheduleBoardSettingsHint.displayViewType}
						indent={false}
						label={ScheduleBoardSettingsEnum.displayViewType}
						showCheckbox={false}
						value={DisplayViewToggleLabel[displayViewType]}
					/>
					{showDisplayViewRotationTime &&
						<PreviewItem
							checked={true}
							hint={ScheduleBoardSettingsHint.displayViewRotationTime}
							indent={false}
							label={ScheduleBoardSettingsEnum.displayViewRotationTime}
							showCheckbox={false}
							value={`${displayViewRotationTime}s`}
						/>
					}
					<PreviewItem
						checked={cdlInSkillsGrid}
						hint={ScheduleBoardSettingsHint.cdlInSkillsGrid}
						indent={false}
						label={ScheduleBoardSettingsEnum.cdlInSkillsGrid}
						showCheckbox={true}
					/>
					<Row className="row--padded">
						<Row className="company-settings__feature-flag">
							<Label
								label="Skills color grid"
								tooltipMessage={ScheduleBoardSettingsHint.skillGrid}
							/>
						</Row>
						<SkillsColorGrid enableCdlIndicator={true} showAll={true} size="large" />
					</Row>
				</div>
			</SettingPreview>
		);
	}
}

export default ScheduleBoardSettingsPreview;
