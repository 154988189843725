import * as React from 'react';
import { Field } from 'redux-form';

import WorkRequestDisposalMethod from '@acceligentllc/shared/enums/workRequestDisposalMethod';

import JobContact from 'af-components/SharedForms/Contacts/ContactWrapper';

import Checkbox from 'af-fields/Checkbox';
import Input from 'af-fields/Input';
import RadioGroup from 'af-fields/RadioGroup';

import { WORK_REQUEST_FORM } from 'af-constants/reduxForms';

import WorkRequestUpsertFM from '../../formModel';
import { formSectionClass, formSectionClassColumn1, formSectionClassColumn2, formSectionClassColumn4, formSectionClassColumn8, formSectionClassRow, formSectionTitleClass } from '../../helpers';

import styles from '../../styles.module.scss';

const DISPOSAL_METHOD_OPTIONS = Object.keys(WorkRequestDisposalMethod).map((_dm) => {
	switch (_dm) {
		case (WorkRequestDisposalMethod.NONE): {
			return { value: _dm, label: 'None' };
		}

		case (WorkRequestDisposalMethod.OFF_SITE_DISPOSAL): {
			return { value: _dm, label: 'Off-site Disposal' };
		}

		case (WorkRequestDisposalMethod.ON_SITE_DISPOSAL): {
			return { value: _dm, label: 'On-site Disposal' };
		}

		case (WorkRequestDisposalMethod.THIRD_PARTY_TRANSPORT): {
			return { value: _dm, label: 'Third Party Transport' };
		}

		default: {
			return { value: _dm, label: 'None' };
		}
	}
});

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	change: (field: string, value: any) => void;
};

const DisposalSection: React.FC<Props> = (props) => {
	const { change } = props;

	const onDisposalMethodChange = React.useCallback((field: string, value) => {
		change(field, value);
	}, [change]);

	const ableToCleanAndWashEquipmentAtCustomerLocationStyle = React.useMemo(() => [formSectionClassColumn4, styles['work-request-form__tab-content__form-section__row__centered-column']].join(' '), []);
	const wasteGeneratedStyle = React.useMemo(() => [formSectionClassColumn1, styles['work-request-form__tab-content__form-section__row__centered-column']].join(' '), []);

	return (
		<div className={formSectionClass}>
			<div className={formSectionTitleClass}>
				Disposal
			</div>
			<div className={formSectionClassRow}>
				<div className={ableToCleanAndWashEquipmentAtCustomerLocationStyle}>
					<Field
						component={Checkbox}
						id="ableToCleanAndWashEquipmentAtCustomerLocation"
						label="Able To Clean and Wash Equipment at Customer Location"
						name={WorkRequestUpsertFM.getAttributeName('ableToCleanAndWashEquipmentAtCustomerLocation')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="cleanAndWashLocation"
						label="Clean and Wash Location"
						name={WorkRequestUpsertFM.getAttributeName('cleanAndWashLocation')}
						placeholder="Enter Clean and Wash Location"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn4}>
					Waste Generated:
				</div>
				<div className={formSectionClassColumn4}>
					Hazardous / Manifest
				</div>
			</div>
			<div className={[formSectionClassRow, styles['work-request-form__waste-types']].join(' ')}>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						id="isWasteGeneratedSolid"
						label="Solid"
						name={WorkRequestUpsertFM.getAttributeName('isWasteGeneratedSolid')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						id="isWasteGeneratedSludge"
						label="Sludge"
						name={WorkRequestUpsertFM.getAttributeName('isWasteGeneratedSludge')}
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Checkbox}
						id="isWasteGeneratedLiquid"
						label="Liquid"
						name={WorkRequestUpsertFM.getAttributeName('isWasteGeneratedLiquid')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						id="isWasteHazardous"
						label="Hazardous"
						name={WorkRequestUpsertFM.getAttributeName('isWasteHazardous')}
					/>
				</div>
				<div className={wasteGeneratedStyle}>
					<Field
						component={Checkbox}
						id="isWasteManifest"
						label="Manifest"
						name={WorkRequestUpsertFM.getAttributeName('isWasteManifest')}
					/>
				</div>
			</div>
			<div className={[formSectionClassRow, styles['work-request-form__waste-types']].join(' ')}>
				Disposal Method
				<div className={formSectionClassColumn8}>
					<RadioGroup
						changeField={onDisposalMethodChange}
						extraClass={styles['work-request-form__radio-group']}
						field={WorkRequestUpsertFM.getAttributeName('disposalMethod')}
						inline={true}
						items={DISPOSAL_METHOD_OPTIONS}
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="wasteDescription"
						label="Waste Description"
						name={WorkRequestUpsertFM.getAttributeName('wasteDescription')}
						placeholder="Enter Waste Description"
						type="text"
					/>
				</div>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="expectedWasteQuantity"
						label="Expected Waste Quantity"
						name={WorkRequestUpsertFM.getAttributeName('expectedWasteQuantity')}
						placeholder="Enter Expected Waste Quantity"
						type="number"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn2}>
					<Field
						component={Input}
						id="disposalInstructions"
						label="Disposal Instructions"
						name={WorkRequestUpsertFM.getAttributeName('disposalInstructions')}
						placeholder="Enter Disposal Instructions"
						type="text"
					/>
				</div>
			</div>
			<div className={formSectionClassRow}>
				<div className={formSectionClassColumn8}>
					Disposal Contact
					<JobContact
						className={styles['work-request-form__contact']}
						contactLabel="Enter Disposal Contact"
						fieldName={WorkRequestUpsertFM.getAttributeName('disposalContact')}
						formName={WORK_REQUEST_FORM}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(DisposalSection);
