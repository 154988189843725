import * as React from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

interface OwnProps {
	toggleEdit: () => void;
	className?: string;
	buttonContext?: string;
	containerId?: string;
}

type Props = React.PropsWithChildren<OwnProps>;

class SettingPreview extends React.PureComponent<Props> {
	static defaultProps: Partial<Props> = {
		className: '',
	};

	render() {
		const { toggleEdit, children, className, containerId, buttonContext } = this.props;

		return (
			<div className="form-box company-settings" id={containerId}>
				<Row className={`company-settings__row ${className}`}>
					{children}
					<div className="company-settings__edit-segment">
						<Button
							context={buttonContext}
							label="Edit"
							onClick={toggleEdit}
							style="secondary"
						/>
					</div>
				</Row>
			</div>
		);
	}
}

export default SettingPreview;
