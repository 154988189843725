import * as React from 'react';
import { compose } from 'redux';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import Textarea from 'af-components/Fields/ResizableTextarea';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';

import * as CompanyActions from 'af-actions/companies';

type OwnProps = {
	showModal: boolean;
	closeModal: () => void;
	currentPrefix: Nullable<string>;
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const PurchaseOrderNumberPrefixEdit: React.FC<Props> = ({ closeModal, showModal, currentPrefix, updatePurchaseOrderNumberPrefix }) => {
	const [prefix, setPrefix] = React.useState(currentPrefix ?? 'PO');

	const handleSubmit = React.useCallback(async () => {
		await updatePurchaseOrderNumberPrefix({ purchaseOrderNumberPrefix: prefix });
		closeModal();
	}, [updatePurchaseOrderNumberPrefix, prefix, closeModal]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Edit Purchase Order Number Prefix"
			/>
			<CustomModal.Body>
				<Textarea
					maxCharacters={10}
					onValueChange={setPrefix}
					showMaxCharactersLabel={true}
					value={prefix}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					label="Cancel"
					onClick={closeModal}
					style="secondary"
				/>
				<SubmitButton
					disabled={!prefix}
					label="Save"
					onClick={handleSubmit}
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		updatePurchaseOrderNumberPrefix: CompanyActions.updatePurchaseOrderNumberPrefix,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(PurchaseOrderNumberPrefixEdit);
