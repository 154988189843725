import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import CustomModal from 'af-components/CustomModal';
import LoadingIndicator from 'af-components/LoadingIndicator';

interface Props {
	onHide: () => void;
}

export default class ModalLoading extends React.PureComponent<Props> {

	render() {
		const { onHide } = this.props;
		return (
			<>
				<CustomModal.Header
					closeModal={onHide}
				>
					<LoadingIndicator color="black" />
				</CustomModal.Header>
				<CustomModal.Body>
					<div className="modal-group current-status">
						<div className="modal-col">
							<div className="modal-flex-row">
								<div className="modal-text-group">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
								<div className="modal-text-group align-right">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<br />
					<div className="modal-group current-status">
						<div className="modal-col">
							<div className="modal-flex-row">
								<div className="modal-text-group">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
								<div className="modal-text-group">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
								<div className="modal-text-group align-right" />
							</div>
						</div>
					</div>
					<div className="modal-group current-status">
						<div className="modal-col">
							<div className="modal-flex-row">
								<div className="modal-text-group">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
								<div className="modal-text-group align-right" />
							</div>
						</div>
					</div>
					<hr />
					<br />
					<div className="modal-group current-status">
						<div className="modal-col">
							<div className="modal-flex-row">
								<div className="modal-text-group">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
								<div className="modal-text-group align-right">
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
									<div className="modal-subtitle"><div className="loading-box-row dark" /></div>
								</div>
							</div>
						</div>
					</div>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Close"
						onClick={onHide}
						style="secondary"
					/>
				</CustomModal.Footer>
			</>
		);
	}

}
