import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import type { CellContext } from '@tanstack/react-table';
import { Button } from '@acceligentllc/storybook';
import { useLocation, useHistory, useRouteMatch } from 'react-router';

import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import ToolStatus, { ToolStatusLabel } from '@acceligentllc/shared/enums/toolStatus';
import ToolState, { ToolStateLabel } from '@acceligentllc/shared/enums/toolState';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';
import type { TableContent } from 'ab-common/dataStructures/tableContent';

import ToolTrackingRecordVM from 'ab-viewModels/toolTrackingRecord/table.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import type { RootState } from 'af-reducers';

import { TOOL_UPDATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ToolActions from 'af-actions/tool';

import { downloadCSV } from 'af-utils/csv.utils';
import { isAllowed } from 'ab-utils/auth.util';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LoadingIndicator from 'af-components/LoadingIndicator';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import Label from 'af-components/Label';
import Tooltip from 'af-components/Tooltip';
import TextCell from 'af-components/Table/Cells/TextCell';

import type FormModel from '../Shared/formModel';
import { validate } from '../Shared/validation';
import type ToolVM from 'ab-viewModels/tool/edit.viewModel';
import ToolInfo from './ToolInformation';
import styles from './styles.module.scss';
import ChangeLocationModal from './ChangeLocation';
import ChangeLocationFormModel from './ChangeLocationFormModel';

interface PathParams {
	id: string;
}

type Props = ConnectedProps<typeof connector>;

const Preview = (props: Props) => {
	const {
		findById,
		companyName,
		fetchToolHistoryTable,
		changeLocation,
		hasManageSmallToolsPermission,
		hasCreateToolRepairsPermission,
	} = props;

	const match = useRouteMatch<PathParams>();
	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;
	const id = match.params.id;

	const tableRef = React.useRef<TableRef<ToolTrackingRecordVM>>(null);
	const [item, setItem] = React.useState<Nullable<ToolVM>>(null);
	const [loading, setLoading] = React.useState(true);
	const [showChangeLocationModal, setShowChangeLocationModal] = React.useState(false);

	const loadStuff = React.useCallback(async () => {
		setLoading(true);
		const _item = await findById(+id);
		setItem(_item);
		setLoading(false);
	}, [findById, id]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	const onRequestToolRepair = React.useCallback(async () => {
		let url = CLIENT.COMPANY.TOOL_REPAIR.CREATE(location.state.orgAlias, companyName);
		url = url.concat(`?toolId=${item!.id}`);

		const latestTrackingRecordEquipmentId = tableRef.current?.getTableData()?.[0]?.equipmentId;
		if (latestTrackingRecordEquipmentId) {
			url = url.concat(`&equipmentId=${latestTrackingRecordEquipmentId}`);
		}

		window.open(url);
	}, [companyName, location.state.orgAlias, item]);

	const closeChangeLocationModal = React.useCallback(() => {
		setShowChangeLocationModal(false);
	}, []);

	const openChangeLocationModal = React.useCallback(async () => {
		setShowChangeLocationModal(true);
	}, []);

	const submitChangeLocation = React.useCallback(async (form: ChangeLocationFormModel) => {
		closeChangeLocationModal();
		await changeLocation(ChangeLocationFormModel.toRM(form), item!.id);
		await loadStuff();
	}, [changeLocation, closeChangeLocationModal, item, loadStuff]);

	const disableChangeLocation = React.useMemo(() =>
		([ToolStatus.IN_REPAIR, ToolStatus.OUT_FOR_REPAIR] as string[]).includes(item?.status ?? '') || !!item?.hasActiveToolRepair || !hasManageSmallToolsPermission
		, [item?.hasActiveToolRepair, item?.status, hasManageSmallToolsPermission]);

	const disableRequestToolRepairLocation = React.useMemo(() =>
		([ToolStatus.IN_REPAIR, ToolStatus.OUT_FOR_REPAIR] as string[]).includes(item?.status ?? '') || !!item?.hasActiveToolRepair || !hasManageSmallToolsPermission
		, [item?.hasActiveToolRepair, item?.status, hasManageSmallToolsPermission]);

	const onExportClick = React.useCallback(async () => {

		const tableRows = tableRef.current!.getTableData();

		const csvData = ToolTrackingRecordVM.toCSVData(tableRows ?? []);

		downloadCSV(csvData, `${companyName}_tool_history_${item!.id}-${TimeUtils.formatDate(new Date(), TimeFormat.FULL_DATE)}.csv`);

	}, [companyName, item]);

	const buttons = React.useMemo(() => {
		const _buttons = [
			{ label: 'Export As CSV', icon: 'download', type: TableButtonType.LINK, hasPermission: true, onClick: onExportClick },
			{ label: 'Change Location', type: TableButtonType.PRIMARY, hasPermission: true, onClick: openChangeLocationModal, disabled: disableChangeLocation },
		];

		if (hasCreateToolRepairsPermission) {
			_buttons.push({ label: 'Request Tool Repair', type: TableButtonType.PRIMARY, hasPermission: true, onClick: onRequestToolRepair, disabled: disableRequestToolRepairLocation });
		}

		return _buttons;
	}, [disableChangeLocation, disableRequestToolRepairLocation, hasCreateToolRepairsPermission, onExportClick, onRequestToolRepair, openChangeLocationModal]);

	const renderStatusCell = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		const status = cell.row.original.status;

		const text = ToolStatusLabel[status];

		switch (status) {
			case ToolStatus.IN_REPAIR:
			case ToolStatus.OUT_FOR_REPAIR:
			case ToolStatus.IN_SERVICE:
				return <Label className={'pending__light'} text={text} />;
			default:
				return <Label className={'completed__light'} text={text} />;
		}
	}, []);

	const renderStateCell = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		const state = cell.row.original.state;

		const text = ToolStateLabel[state];

		switch (state) {
			case ToolState.INACTIVE:
				return <Label className={'inactive__light'} text={text} />;
			default:
				return <Label className={'completed__light'} text={text} />;
		}
	}, []);

	const renderStatusValidCell = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		const statusValidFrom = cell.row.original.validFrom;
		const statusValidTo = cell.row.original.validTo;

		return (
			<div className={styles['valid-cell-container']}>
				<div className={styles['grey-text']}>from</div>
				{` ${statusValidFrom} `}
				{
					!!statusValidTo &&
					<>
						<div className={styles['grey-text']}>to</div>
						{` ${statusValidTo}`}
					</>
				}
			</div>
		);
	}, []);

	const renderLocationTypeCell = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		const locationType = cell.row.original.equipmentId
			? 'Equipment'
			: cell.row.original.locationId
				? 'Office'
				: ([ToolStatus.IN_REPAIR, ToolStatus.OUT_FOR_REPAIR] as string[]).includes(cell.row.original.status) ? 'Repair' : 'Other';
		return <TextCell value={locationType} />;
	}, []);

	const renderToolRepairLinkCell = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		if (!cell.row.original.toolRepairId) {
			return null;
		}

		return (
			<a
				className={styles.link}
				href={CLIENT.COMPANY.TOOL_REPAIR.PREVIEW(`${cell.row.original.toolRepairId}`, orgAlias, companyName)}
				rel="noreferrer"
				target="_blank"
			>
				<span className="icon-external" />
			</a>
		);
	}, [companyName, orgAlias]);

	const renderReason = React.useCallback((cell: CellContext<ToolTrackingRecordVM, string>) => {
		const reason = cell.row.original.reason;
		return <div className={styles.reason}>
			<Tooltip message={reason} >
				<TextCell value={reason ?? 'N/A'} />
			</Tooltip>
		</div>;
	}, []);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<ToolTrackingRecordVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await fetchToolHistoryTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			item!.id
		);
		return result;
	}, [fetchToolHistoryTable, item]);

	const columns: TableProps<ToolTrackingRecordVM>['columns'] = React.useMemo(() => [
		{
			id: 'state',
			accessor: 'state',
			header: 'Status',
			cell: renderStateCell,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'status',
			accessor: 'status',
			header: 'Repair Status',
			cell: renderStatusCell,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'repairDetails',
			accessor: 'repairDetails',
			header: 'Repair Details',
			cell: renderToolRepairLinkCell,
			enableSorting: false,
			enableHiding: true,
			size: 100,
		},
		{
			id: 'statusValid',
			accessor: 'statusValid',
			header: 'Status Valid',
			cell: renderStatusValidCell,
			enableSorting: true,
			enableHiding: true,
			size: 250,
		},
		{
			id: 'locationType',
			accessor: 'locationType',
			header: 'Location Type',
			cell: renderLocationTypeCell,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'locationName',
			accessor: 'locationName',
			header: 'Equipment/Office/Other',
			cell: (cell: CellContext<ToolTrackingRecordVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'trackingNumber',
			accessor: 'trackingNumber',
			header: 'Tracking Number',
			cell: (cell: CellContext<ToolTrackingRecordVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'reason',
			accessor: 'reason',
			header: 'Reason For Transfer',
			cell: renderReason,
			enableSorting: false,
			enableHiding: true,
		},
	], [renderLocationTypeCell, renderReason, renderStateCell, renderStatusCell, renderStatusValidCell, renderToolRepairLinkCell]);

	const goToEdit = React.useCallback(() => {
		history.push(CLIENT.COMPANY.EQUIPMENT.TOOL.EDIT(item?.id.toString(), orgAlias, companyName));
	}, [history, item, orgAlias, companyName]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.EQUIPMENT.TOOL.LIST(orgAlias, companyName) },
					{ label: 'Small Tool' },
				]}
			/>
			{loading || !item ? (
				<div className="form-box form-box--loading-only">
					<LoadingIndicator color="orange" size="big" />
				</div>
			) : (
				<>
					<div className={styles['model-label']}>
						<div className={styles.title}>
							<div className={styles['model-number']}>{item.modelNumber}</div>
							<div className={styles['serial-number']}>({item.serialNumber})</div>
						</div>
						<Button
							disabled={!hasManageSmallToolsPermission}
							label="Edit"
							onClick={goToEdit}
							style="secondary"
						/>
					</div>
					<ToolInfo
						item={item}
					/>
					<div className={styles['table-box']}>
						<div className={styles['table-box-header']}>
							ASSET TRACKING
						</div>
						<TableNew
							buttons={buttons}
							columns={columns}
							defaultPageSize={10}
							fetch={fetchRows}
							fitContentHeight={true}
							hasSearchInput={true}
							ref={tableRef}
							tableName={TableNameEnum.TOOL_HISTORY}
						/>
					</div>
					<ChangeLocationModal
						closeModal={closeChangeLocationModal}
						onSubmit={submitChangeLocation}
						showModal={showChangeLocationModal}
					/>
				</>
			)}
		</div>
	);
};

const formSelector = formValueSelector(TOOL_UPDATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageSmallToolsPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasCreateToolRepairsPermission: boolean = isAllowed(
		PagePermissions.COMPANY.TOOL_REPAIR.CREATE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		hasManageSmallToolsPermission,
		hasCreateToolRepairsPermission,
	};
}

function mapDispatchToProps() {
	return {
		findById: ToolActions.findById,
		fetchToolHistoryTable: ToolActions.fetchToolHistoryTable,
		changeLocation: ToolActions.changeLocation,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	connector,
	reduxForm({
		form: TOOL_UPDATE,
		validate,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
	})
);

export default enhance(Preview);
