import type { ColorPalette } from '@acceligentllc/shared/enums/color';
import type WorkOrderReviewStatus from '@acceligentllc/shared/enums/workOrderReviewStatus';
import type { TimeSheetInternalApprovalStatus } from '@acceligentllc/shared/enums/timeSheetApprovalStatus';
import type TimeSheetEntryWorkType from '@acceligentllc/shared/enums/timeSheetEntryWorkType';
import type TimeSheetSignatureStatus from '@acceligentllc/shared/enums/timeSheetSignatureStatus';
import type WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import type { CalculatedReportDisplayStatus } from '@acceligentllc/shared/enums/reportDisplayStatus';
import type TimeSheetEntryType from '@acceligentllc/shared/enums/timeSheetEntryType';

import * as TimeSheetUtils from '@acceligentllc/shared/utils/timeSheet';
import * as TimeUtils from '@acceligentllc/shared/utils/time';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from '@acceligentllc/shared/constants/value';

import * as BlobStorageUtil from 'ab-utils/blobStorage.util';

import type FieldReportCondensedWorkOrderBase from 'ab-domain/views/fieldReportCondensedWorkOrder/base';

export class W_WorkOrderReport_CondensedViewSingle_VM {
	id: number;
	dueDate: string;
	workOrderCode: string;
	reviewLevel: number;
	reviewStatus: WorkOrderReviewStatus;
	calculatedReportDisplayStatus: CalculatedReportDisplayStatus;
	lastApprovedAt: Nullable<string>;
	lastApprovedById: Nullable<number>;
	lastApprovedBy: Nullable<string>;
	assignedToIds: number[];
	location: W_WorkOrderReport_CondensedViewSingle_VM_Location;
	employees: W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM[];
	revision: number;
	statusBasedRevision: Nullable<number>;
	revisionCreatedAt: Date;
	woUpdatedAt: Date;
	status: WorkOrderStatus;
	workRequestId: number;
	isPartOfProject: boolean;

	public static async initialize(row: FieldReportCondensedWorkOrderBase) {

		let employees: W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM[] = [];
		if (row.employees) {
			employees = await W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM.bulkInitialize(row.employees);
		}

		return {
			id: row.id,
			dueDate: row.dueDate,
			workOrderCode: row.workOrderCode,
			reviewLevel: row.reviewLevel,
			reviewStatus: row.reviewStatus,
			calculatedReportDisplayStatus: row.calculatedReportDisplayStatus,
			lastApprovedAt: row.lastApprovedAt,
			lastApprovedById: row.lastApprovedById,
			lastApprovedBy: row.lastApprovedBy,
			assignedToIds: row.assignedToIds,
			location: new W_WorkOrderReport_CondensedViewSingle_VM_Location(row),
			employees,
			revision: row.revision,
			revisionCreatedAt: row.revisionCreatedAt,
			woUpdatedAt: row.woUpdatedAt,
			status: row.status,
			workRequestId: row.workRequestId,
			isPartOfProject: row.isPartOfProject,
			statusBasedRevision: row?.statusBasedRevision ? row.statusBasedRevision - 1 : null,
		};
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_Location {
	aa1: string;
	street: string;

	constructor(row: FieldReportCondensedWorkOrderBase) {
		this.aa1 = row.aa1;
		this.street = row.street;
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry {
	id: number;
	workType: TimeSheetEntryWorkType;
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	isInActiveShift: boolean;
	type: TimeSheetEntryType;
	equipmentId: Nullable<number>;

	constructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries'][0]) {
		this.id = entry.id;
		this.workType = entry.workType;
		this.startTime = entry.startTime;
		this.endTime = entry.endTime;
		this.isInActiveShift = entry.isInActiveShift;
		this.type = entry.type;
		this.equipmentId = entry.equipmentId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries']) {
		return (entries ?? []).map(W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry._mapConstructor);
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry {
	id: number;
	time: number;
	fieldWorkClassificationCodeId: number;
	classificationCode: string;
	equipmentId: number;
	equipmentCode: string;
	equipmentSpecification: string;
	color: ColorPalette;
	allocatedWorkRequestId: number;

	constructor(timeSplitEntry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries'][0]) {
		this.id = timeSplitEntry.id;
		this.time = TimeUtils.roundTimeDurationToInterval(timeSplitEntry.time, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		this.fieldWorkClassificationCodeId = timeSplitEntry.fieldWorkClassificationCodeId;
		this.classificationCode = timeSplitEntry.classificationCode;
		this.equipmentId = timeSplitEntry.equipmentId;
		this.equipmentCode = timeSplitEntry.equipmentCode;
		this.equipmentSpecification = timeSplitEntry.equipmentSpecification;
		this.color = timeSplitEntry.color;
		this.allocatedWorkRequestId = timeSplitEntry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries']) {
		return (entries ?? []).map(W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry._mapConstructor);
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry {
	id: number;
	time: number;
	workType: TimeSheetEntryWorkType;
	allocatedWorkRequestId: number;

	constructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries'][0]) {
		this.id = entry.id;
		this.time = entry.time;
		this.workType = entry.workType;
		this.allocatedWorkRequestId = entry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries']) {
		return (entries ?? []).map(W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry._mapConstructor);
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM {
	accountId: number;
	fullName: string;
	isTemporaryEmployee: boolean;

	timeSheetOutdated: boolean;
	signatureStatus: TimeSheetSignatureStatus;

	approvalStatus: TimeSheetInternalApprovalStatus;
	approvedBy: Nullable<string>;
	approvedAt: Nullable<string>;
	approvalSignatureUrl: Nullable<string>;

	rejectedBy: Nullable<string>;
	rejectedAt: Nullable<string>;
	rejectReason: Nullable<string>;

	timeSheetId: number;
	timeSheetEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry[];

	timeSplitId: number;
	timeSplitEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry[];

	timeAllocationEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry[];

	public static async initialize(row: FieldReportCondensedWorkOrderBase['employees'][0]) {
		let approvalSignatureUrl: Nullable<string> = null;
		if (row.approvalSignatureStorageContainer && row.approvalSignatureStorageName) {
			approvalSignatureUrl = await BlobStorageUtil.generatePresignedGetUrl(row.approvalSignatureStorageContainer, row.approvalSignatureStorageName);
		}

		return {
			accountId: row.accountId,
			fullName: row.fullName,
			isTemporaryEmployee: row.isTemporaryEmployee,
			timeSheetOutdated: row.timeSheetOutdated,
			signatureStatus: TimeSheetUtils.resolveTimeSheetSignatureStatus({
				approvalStatus: row.approvalStatus,
				parentId: row.parentId,
				signatureId: row.signatureId,
			}),
			approvalStatus: row.approvalStatus,
			approvedBy: row.approvedBy,
			approvedAt: row.approvedAt,
			approvalSignatureUrl,
			rejectedBy: row.rejectedBy,
			rejectedAt: row.rejectedAt,
			rejectReason: row.rejectReason,
			timeSheetId: row.timeSheetId,
			timeSheetEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry.bulkConstructor(row.timeSheetEntries),
			timeSplitId: row.timeSplitId,
			timeSplitEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry.bulkConstructor(row.timeSplitEntries),
			timeAllocationEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry.bulkConstructor(row.timeAllocationEntries),
		};
	}

	public static async bulkInitialize(rows: FieldReportCondensedWorkOrderBase['employees']) {
		return await Promise.all(rows.map(W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM.initialize));
	}
}

