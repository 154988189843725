import type ItemBase from 'ab-domain/models/item/base';

export class W_Item_FindTakenNameList_VM {
	items: W_Item_FindTakenNameList_VM_Single[];

	constructor(items: ItemBase[]) {
		this.items = W_Item_FindTakenNameList_VM_Single.bulkConstructor(items);
	}
}

class W_Item_FindTakenNameList_VM_Single {
	id: number;
	name: string;

	constructor(item: ItemBase) {
		this.id = item.id;
		this.name = item.name;
	}

	private static _mapConstructor = (item: ItemBase) => new W_Item_FindTakenNameList_VM_Single(item);
	static bulkConstructor = (items: ItemBase[]) => items.map(W_Item_FindTakenNameList_VM_Single._mapConstructor);
}
