import * as React from 'react';
import { Table } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';

import TimeFormatEnums from '@acceligentllc/shared/enums/timeFormat';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import type { DeliverableHistoryViewModel } from 'ab-viewModels/deliverableTable.viewModel';

import { HISTORY_MODAL_COL_SPAN } from 'af-constants/values';

import DeliverableStatusLabel from 'af-components/DeliverableStatusLabel';
import CustomModal from 'af-components/CustomModal';

interface Props {
	showModal: boolean;
	closeModal: () => void;
	statusInfo: JSX.Element;
	history: DeliverableHistoryViewModel[];
}

class StatusHistoryModal extends React.PureComponent<Props> {

	render() {
		const { showModal, closeModal, statusInfo, history } = this.props;
		const historyCount = history.length;
		return (
			<CustomModal
				closeModal={closeModal}
				modalStyle="info"
				showModal={showModal}
				size="md"
			>
				<CustomModal.Header
					closeModal={closeModal}
					title="Deliverable Status Change Histor"
				/>
				{statusInfo}
				<CustomModal.Body>
					<Table className="deliverable-status-history-table">
						<thead>
							<tr>
								<th colSpan={HISTORY_MODAL_COL_SPAN.INDEX}>
									<div className="column-title">#</div>
								</th>
								<th colSpan={HISTORY_MODAL_COL_SPAN.STATUS}>
									<div className="column-title">STATUS</div>
								</th>
								<th colSpan={HISTORY_MODAL_COL_SPAN.UPDATED_AT}>
									<div className="column-title">CHANGED AT</div>
								</th>
								<th colSpan={HISTORY_MODAL_COL_SPAN.UPDATED_BY}>
									<div className="column-title">CHANGED BY</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{history.map((_history, _index) => (
								<tr key={_index}>
									<td colSpan={HISTORY_MODAL_COL_SPAN.INDEX}>
										{historyCount - _index}
									</td>
									<td colSpan={HISTORY_MODAL_COL_SPAN.STATUS}>
										{!!_history.status
											? (
												<DeliverableStatusLabel
													abbreviation={_history.status?.abbreviation}
													statusColor={_history.status?.statusColor}
													textColor={_history.status?.textColor}
												/>
											)
											: <></>
										}
									</td>
									<td colSpan={HISTORY_MODAL_COL_SPAN.UPDATED_AT}>
										{(TimeUtils.formatDate(_history.updatedAt, TimeFormatEnums.FULL_DATE_LONG))}
									</td>
									<td colSpan={HISTORY_MODAL_COL_SPAN.UPDATED_BY}>
										{_history.updatedBy.fullName}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						label="Cancel"
						onClick={closeModal}
						style="secondary"
					/>
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default StatusHistoryModal;
