import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import WorkOrderStatus from '@acceligentllc/shared/enums/workOrderStatus';
import NotificationStatusEnum from '@acceligentllc/shared/enums/notificationStatus';

import type { RootState } from 'af-reducers';

import EmployeesDroppable from 'af-root/scenes/Company/ScheduleBoard/DailyView/EmployeesDroppable';

import StatusIcon from 'af-components/StatusIcon';

import ScheduleBoardContext from 'ab-enums/scheduleBoardContext.enum';
import ScheduleBoardProperty from 'ab-enums/scheduleBoardProperty.enum';

import { AVAILABLE_EMPLOYEE_STATUS } from 'ab-common/constants/employee';

import * as ScheduleBoardUtil from 'af-utils/scheduleBoard.util';

interface OwnProps {
	dueDate: string;
	hasReason?: boolean;
	available: boolean;
	groupTitle: Nullable<string>;
	groupId: number;
	isDeleted: boolean;
	isDragAndDropDisabled: boolean;
	hasPermissionsToEditScheduleBoard: boolean;
	hasPermissionsToSendNotifications: boolean;
	openNotifyModal?: (groupId: number) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const EmployeeGroup: React.FC<Props> = (props) => {
	const {
		employeeIds = [],
		dueDate,
		isDeleted,
		hasArrow,
		available = true,
		groupTitle = AVAILABLE_EMPLOYEE_STATUS,
		groupId,
		hasReason = false,
		isShowNotifyAvailableEmployeesDisabled,
		employeesNotified,
		isDragAndDropDisabled,
		hasPermissionsToEditScheduleBoard,
		hasPermissionsToSendNotifications,
		disabled,
		openNotifyModal,
	} = props;

	const openNotificationModal = React.useCallback(() => {
		openNotifyModal?.(groupId);
	}, [groupId, openNotifyModal]);

	if (isDeleted && employeeIds.length === 0) {
		return null;
	}

	const notificationsAvailable = available
		&& !isShowNotifyAvailableEmployeesDisabled
		&& hasPermissionsToSendNotifications;

	return (
		<div className="schedule-board-toolbar-employees-group" key={groupId}>
			<div className="schedule-board-arrow-container">
				<div className={`schedule-board-arrow ${hasArrow ? '--visible' : '--invisible'}`} />
			</div>
			<div className="group-title">
				{
					available ?
						<img src="/images/elements/ic_state_complete.svg" /> :
						<img src="/images/elements/ic_state_incomplete.svg" />
				}
				<span>{groupTitle}</span>
				{
					notificationsAvailable &&
					<span className="notification-status">
						<StatusIcon
							firstRevision={false}
							isSmall={true}
							notificationsEnabled={!isShowNotifyAvailableEmployeesDisabled}
							notificationStatus={employeesNotified ? NotificationStatusEnum.OPENED : NotificationStatusEnum.DELIVERED}
							onClick={openNotificationModal}
							status={WorkOrderStatus.PUBLISHED}
						/>
					</span>
				}
			</div>
			<hr />
			<EmployeesDroppable
				available={available}
				disabled={disabled || isDeleted}
				draggablePrefix={ScheduleBoardContext.TOOLBAR}
				droppableId={ScheduleBoardUtil.generateDroppableId(ScheduleBoardContext.TOOLBAR, ScheduleBoardProperty.EMPLOYEE, dueDate, `${groupId}`, undefined, available)}
				dueDate={dueDate}
				employeeIds={employeeIds}
				hasPermissionsToEditScheduleBoard={hasPermissionsToEditScheduleBoard}
				hasReason={hasReason}
				isDragAndDropDisabled={isDragAndDropDisabled}
				isToolbar={true}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { dueDate, available, groupId } = ownProps;
	const { employees, workOrdersByDateDictionary } = state.scheduleBoard;
	const { company } = state.company;

	const availability = ScheduleBoardUtil.getAvailabilityLabel(available);
	const employeeIds = workOrdersByDateDictionary?.[dueDate]?.toolbarEmployees?.[availability]?.[groupId]?.filter(
		(_empId) => employees?.[_empId]?.account?.assignableToWorkOrder
	) ?? [];
	const { availableNotificationStatuses } = workOrdersByDateDictionary?.[dueDate];

	const hasArrow = employeeIds.some((_id) => employees?.[_id]?.isMatched);

	const employeesNotified = !employeeIds.some((_id) => {
		const emailStatus = availableNotificationStatuses?.[_id]?.emailStatus !== NotificationStatusEnum.OPENED;
		const smsStatus = availableNotificationStatuses?.[_id]?.smsStatus !== NotificationStatusEnum.OPENED;
		return emailStatus && smsStatus;
	});

	return {
		disabled: !state.scheduleBoard.draggedEmployeeId,
		hasArrow,
		employeeIds: employeeIds.filter((_empId) => employees?.[_empId]?.showOnScheduleBoard ?? false),
		employeesNotified,
		isShowNotifyAvailableEmployeesDisabled: !company?.notification?.isEnabled ?? false,
	};
}

const connector = connect(mapStateToProps);

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	connector
);

export default enhance(EmployeeGroup);
