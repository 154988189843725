import * as React from 'react';
import { Button } from '@acceligentllc/storybook';

import type { ModalSize, ModalStyle } from 'af-components/CustomModal';
import CustomModal from 'af-components/CustomModal';

export interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	confirmAction: Nullable<() => void | Promise<void>>;
	title: string | undefined | null;
	body: JSX.Element | string | undefined | null;
	footer?: JSX.Element;
	confirmText?: Nullable<string>;
	size?: ModalSize;
	modalStyle?: ModalStyle;
	closeText?: string;
	id?: string;
	hideOnConfirm?: boolean;
	disabled?: boolean;
	showCancel?: boolean;
	hideConfirmButton?: boolean;
}

type Props = OwnProps;

class ConfirmationModal extends React.PureComponent<Props> {

	static defaultProps: Partial<Props> = {
		modalStyle: 'danger',
		size: 'sm',
		closeText: 'Cancel',
		confirmText: 'Save',
		hideOnConfirm: true,
		showCancel: true,
	};

	onClick = async () => {
		const { confirmAction, closeModal, hideOnConfirm } = this.props;
		if (hideOnConfirm) {
			closeModal?.();
		}
		await confirmAction?.();
	};

	render() {
		const {
			id,
			showModal,
			closeText,
			closeModal,
			confirmText,
			title,
			body,
			size,
			modalStyle,
			footer,
			disabled,
			showCancel,
			hideConfirmButton,
		} = this.props;

		return (
			<CustomModal
				closeModal={closeModal}
				id={id}
				modalStyle={modalStyle}
				showModal={showModal}
				size={size}
			>
				<CustomModal.Header
					closeModal={closeModal}
					title={title}
				/>
				<CustomModal.Body>
					{body}
				</CustomModal.Body>
				<CustomModal.Footer>
					{footer ??
						<>
							{showCancel &&
								<Button
									label={closeText}
									onClick={closeModal}
									style="secondary"
								/>
							}
							{
								!hideConfirmButton && !!confirmText &&
								<Button
									disabled={disabled}
									label={confirmText}
									onClick={this.onClick}
									style={modalStyle === 'danger' ? 'danger' : 'primary'}
								/>
							}
						</>
					}
				</CustomModal.Footer>
			</CustomModal>
		);
	}
}

export default ConfirmationModal;
