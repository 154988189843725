import type AgencyAssociatedWorkersTableBase from 'ab-domain/views/agencyAssociatedWorkersTable/base';

const WORKERS_TABLE_CSV_HEADER = [
	'Name',
	'Total Wo',
];

export default class AgencyAssociatedWorkersTableVM {
	id: number;
	uniqueId: string;
	firstName: string;
	lastName: string;
	agencyId: number;
	totalWorkOrders: number;

	constructor(employee: AgencyAssociatedWorkersTableBase) {
		this.id = employee.id;
		this.uniqueId = employee.uniqueId;
		this.firstName = employee.firstName;
		this.lastName = employee.lastName;
		this.agencyId = employee.agencyId;
		this.totalWorkOrders = +employee.workOrderCount;
	}

	private static _constructorMap = (employee: AgencyAssociatedWorkersTableBase) => new AgencyAssociatedWorkersTableVM(employee);

	static bulkConstructor(employees: AgencyAssociatedWorkersTableBase[]) {
		return employees.map(AgencyAssociatedWorkersTableVM._constructorMap);
	}

	static toCSVData(viewModels: AgencyAssociatedWorkersTableVM[]): string[][] {
		const header: string[] = [...WORKERS_TABLE_CSV_HEADER];
		const rows: string[][] = [];

		viewModels.forEach((_employee) => {
			rows.push([
				`${_employee.lastName},${_employee.firstName}`,
				_employee.totalWorkOrders.toString(),
			]);
		});

		return [header, ...rows];
	}

}
